import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { financeChildApproveAction } from "../actionCreator";
import { fetchPostFinanceApprove } from "../services";

const postFinanceChildApprove = (data: IV2PostAgentFinanceChildApprove) => (
  dispatch: Dispatch
) => {
  dispatch(financeChildApproveAction.request({}));
  return fetchPostFinanceApprove(data)
    .then((res: AxiosResponse<IV2RespAgentFinanceChildApprove>) => {
      dispatch(financeChildApproveAction.success(res));
      return Promise.resolve();
    })
    .catch((error: AxiosError<IAPIResponse>) => {
        dispatch(financeChildApproveAction.failure(error));
        return Promise.reject();
    }
    );
};

export { postFinanceChildApprove };
