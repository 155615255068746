import React, { ChangeEvent, FC, useState } from "react";
import colors from "constants/colors";
import "./inputGroup.style.scss";

const defaultProps: IInputGroup = {
  id: "",
  placeholder: "",
  textFig: "",
  type: "text",
  value: "",
  name: "",
  onChange() { },
  onBlur() { },
  disabled: false,
  backgroundColor: colors.SECONDARY_BG,
  backgroundActiveColor: colors.PRIMARY_BG,
  error: false,
  errorMessage: "error",
  inputButton: false,
  customGroupTextClass: '',
  customInputClass: '',
};

const InputGroupComponent: FC<IInputGroup> = (props) => {
  const {
    id,
    placeholder,
    name,
    type,
    textFig,
    onChange,
    onBlur,
    value,
    disabled,
    backgroundColor,
    backgroundActiveColor,
    error,
    errorMessage,
    customGroupTextClass,
    customInputClass,
  } = props;

  const [isFocus, setIsFocus] = useState(false);
  const [dgColor, setBgColor] = useState(backgroundColor);

  const handleOnBlur = (event: ChangeEvent<HTMLInputElement>) => {
    if (value === "") {
      setIsFocus(false);
      setBgColor(backgroundColor);
    }
    onBlur!(event);
  };

  const handleFocus = () => {
    setIsFocus(true);
    setBgColor(backgroundActiveColor);
  };

  return (
    <>
      <div className="input-group-container">
        <div className={`input-group-prepend`}>
          <span className={`input-group-text p2 ${customGroupTextClass}`}>{textFig}</span>
        </div>
        <input
          id={id}
          type={type}
          name={name}
          value={value}
          style={{ backgroundColor: dgColor }}
          onChange={onChange}
          disabled={disabled}
          className={`base-input p2
            ${backgroundColor}
            ${error ? " border-error" : "border-active"} ${customInputClass}`}
          placeholder={`${isFocus ? placeholder : placeholder}`}
          onFocus={handleFocus}
          onBlur={handleOnBlur}
        />
        {error && (
          <div className={`text-left p2-x`}>
            <h6 className="primary-red-text">{errorMessage}</h6>
          </div>
        )}
      </div>
    </>
  );
};

InputGroupComponent.defaultProps = defaultProps;

export default InputGroupComponent;
