import { noop } from "lodash"
import "./clearBill.style.scss"
import { Modal } from "../.."
import React, { FC, useState } from "react"
import colors from "constants/colors"
import { Button, Input } from "components"
import logo from "assets/images/logo/logothailandbet.png"
import { CODE_TO_NAME } from "constants/variables"
import { number } from "utils"
import {  includes } from "lodash"
const constants = {
  title: "เคลียร์ยอด",
  titleWeb: "ไทยแลนด์เบท",
  titleEng: "Thailand Bet",
  number: 'เลขบิล',
  lotto: 'หวย',
  totalBet: 'ยอดเดิมพัน',
  discount: 'ส่วนลด',
  customerName: 'ชื่อช่วยจำ',
  totalHostShare: 'ยอดรับ/จ่าย',
  save: 'บันทึกเพิ่มเติม',
  saveRequired: 'โปรดกรอกบันทึกเพิ่มเติมเพื่อยืนยัน',
  description: "คุณแน่ใจว่าหรือว่าจะเคลียร์ยอด?",
  actionText: "ตกลง",
  cancel: "ยกเลิก",
  accept: "ตกลง",
};

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: IClearBillModal = {
  title: constants.title,
  description: constants.description,
  actionText: constants.actionText,
  action() {
    noop();
  },
  cancelAction() {
    noop();
  },
};


const ClearBillModal: FC<IClearBillModal & DefaultProps> = (props) => {
  const [note, setNote] = useState('')
  const [confirm, setConfirm] = useState(false)
  const handleSubmit = () => {
    setConfirm(true)
    if (note !== '') {
      const req = {
        agentShareId: props.detailBill!.agentShareId,
        customerId: props.detailBill!.customerId,
        name: props.detailBill!.name,
        agbeneStatus: 'SUCCESS',
        note: note,
      }
      props.action!(
        req
      )
    }
  }

  return (
    <div className="clearbill-modal-container border-rounded">
      <div className="row mr-0">
        <div className="leading-logo-container m2-x">
          <img src={logo} className="logoMenu" alt="" />
        </div>
        <div className="title-text-web">
          <h3 className="title">{constants.titleWeb}</h3>
          <h5 className="tertiary-text sub-title">{constants.titleEng}</h5>
        </div>
      </div>
      <h2 className="title-text secondary-text">{props.title}</h2>

      <div className="container-detail m4-b">
        <div className="row list-summary-container">
          <div className="col-5 d-flex m2-b">
            <div className="body-3b">
              {constants.number}
            </div>
            <div className="d-flex  align-items-center margin-auto">
              <div className="">
                <div className="body-3 overflow-wrap-text">
                  {props.detailBill!.agentShareId}
                </div>
              </div>
            </div>
          </div>
          <div className="col-5 d-flex m2-b">
            <div className="body-3b">
              {constants.lotto}
            </div>
            <div className="d-flex align-items-center margin-auto">
              <div className="body-3">
                {(includes(props.detailBill!.name, "YEGEE") ? `ยี่กี รอบที่ ${Number(props.detailBill!.name.replace("YEGEE_", ''))}` : CODE_TO_NAME[props.detailBill!.name]).replace('หวย', '')}
                {/* {CODE_TO_NAME[props.detailBill!.name]} */}
              </div>
            </div>
          </div>
        </div>
        <div className="row list-summary-container">
          <div className="col-5 d-flex m2-b">
            <div className="body-3b">
              {constants.totalBet}
            </div>
            <div className="d-flex  align-items-center margin-auto">
              <div className="">
                <div className="body-3 overflow-wrap-text">
                  {number.addComma(props.detailBill!.totalBet)}
                </div>
              </div>
            </div>
          </div>
          <div className="col-5 d-flex m2-b">
            <div className="body-3b">
              {constants.discount}
            </div>
            <div className="d-flex align-items-center margin-auto">
              <div className="body-3">
                {number.addComma(props.detailBill!.totalDis)}
              </div>
            </div>
          </div>
        </div>
        <div className="row list-summary-container">
          <div className="col-5 d-flex">
            <div className="body-3b">
              {constants.customerName}
            </div>
            <div className="d-flex  align-items-center margin-auto">
              <div className="">
                <div className="body-3 overflow-wrap-text">
                  {props.detailBill!.customerName}
                </div>
              </div>
            </div>
          </div>
          <div className="col-5 d-flex">
            <div className="body-3b">
              {constants.totalHostShare}
            </div>
            <div className="d-flex align-items-center margin-auto">
              <div className="body-3">
                {number.addComma(number.calculator(`${props.detailBill!.totalHostShare}+${props.detailBill!.totalMeShare}`))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex subtitle-2 m0-b">{constants.save}</div>
      <Input
        id="search-customerName"
        name="search-customerName"
        inputClassName="input-save border"
        placeholder={constants.save}
        backgroundColor={colors.PRIMARY_BG}
        value={note}
        onChange={(e) => {
          setNote(e.target.value)
        }}
        error={confirm && note === ''}
        errorMessage={constants.saveRequired}
        renderError={true}
      />

      <p className="body-semi1 tertiary-text p2-x">{props.description}</p>
      <div className="row justify-content-center">
        <div className="col p0-r">
          <Button
            id="confirm-button"
            type="rectangle"
            onClick={handleSubmit}
            text={constants.accept!}
            textSize={18}
            transitionType="typeOne"
          />
        </div>
        <div className="col">
          <Button
            id="confirm-button"
            type="rectangle"
            backgroundColor={colors.TERTIARY_TEXT}
            backgroundHoverColor={colors.TERTIARY_TEXT}
            borderColor={colors.SECONDARY_TEXT}
            onClick={props.cancelAction}
            text={constants.cancel!}
            transitionType="typeOne"
            textSize={18}
          />
        </div>
      </div>
    </div>
  );
};

ClearBillModal.defaultProps = defaultProps;

const successModal = {
  show: (props: IClearBillModal) => {
    return Modal.event("MODAL_OVER", {
      state: "show",
      extraProps: { size: "md" },
      component: <ClearBillModal {...props} />,
    });
  },
  hide: () => Modal.event("MODAL_OVER", { state: "hide" }),
};

export default successModal;
