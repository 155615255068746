import { connect } from "react-redux";
import NumbersHolesContainer from "./NumbersHoles.container";
import { getMe } from "reduxs/me/me/action";
import { onChangeIsLoaddingAction } from "reduxs/Loader/action";

const mapStateToProps = (state: RootReducers): INumbersHolesContainerProps => {
  return {};
};

const mapDispatchToProps: INumbersHolesContainerAction = {
  getMe,
  onChangeIsLoaddingAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(NumbersHolesContainer);
