import { get } from 'lodash'
import {
   customerState,customerNoteState,postCustomerNoteState
} from './constants'

const customerList = (state: RootReducers) =>
    get(state, "customer.customerList", customerState)

const customerNoteList = (state: RootReducers) =>
    get(state, "customer.customerNoteList", customerNoteState)

const postcustomerNoteList = (state: RootReducers) =>
    get(state, "customer.postcustomerNoteList", postCustomerNoteState)



const customerSelector = {
    customerList,customerNoteList,postcustomerNoteList
}
export default customerSelector