import route from "constants/routes";
import containers from "containers";

const routes: IRoutes[] = [
  {
    name: route.redirected.name,
    path: route.redirected.path,
    component: null,
    private: true,
    exact: true,
  },
  {
    name: route.login.name,
    path: route.login.path,
    component: containers.Login,
    private: false,
    exact: true,
  },
  {
    name: route.main.name,
    path: route.main.path,
    component: containers.Main,
    private: true,
    exact: true,
  },
  {
    name: route.manageNews.name,
    path: route.manageNews.path,
    component: containers.manageNews,
    private: true,
    exact: true,
  },
  {
    name: route.membersDiagram.name,
    path: route.membersDiagram.path,
    component: containers.membersDiagram,
    private: true,
    exact: true,
  },
  {
    name: route.createMember.name,
    path: route.createMember.path,
    component: containers.CreateMember,
    private: true,
    exact: true,
  },
  {
    name: route.credits.name,
    path: route.credits.path,
    component: containers.Credits,
    private: true,
    exact: true,
  },
  {
    name: route.transferCredits.name,
    path: route.transferCredits.path,
    component: containers.TransferCredits,
    private: true,
    exact: true,
  },
  {
    name: route.transactionLists.name,
    path: route.transactionLists.path,
    component: containers.TransactionLists,
    private: true,
    exact: true,
  },
  {
    name: route.TransferConfirmation.name,
    path: route.TransferConfirmation.path,
    component: containers.TransferConfirmation,
    private: true,
    exact: true,
  },
  {
    name: route.transferCreditDescription.name,
    path: route.transferCreditDescription.path,
    component: containers.TransferCreditDescription,
    private: true,
    exact: true,
  },
  {
    name: route.editUserHistory.name,
    path: route.editUserHistory.path,
    component: containers.EditUserHistory,
    private: true,
    exact: true,
  },
  {
    name: route.editUserHistoryDetails.name,
    path: route.editUserHistoryDetails.path,
    component: containers.EditUserHistoryDetails,
    private: true,
    exact: true,
  },
  {
    name: route.resetPassword.name,
    path: route.resetPassword.path,
    component: containers.ResetPassword,
    private: false,
    exact: true,
  },
  {
    name: route.bet.name,
    path: route.bet.path,
    component: containers.Bet,
    private: true,
    exact: true,
  },
  {
    name: route.betMaking.name,
    path: route.betMaking.path,
    component: containers.BetMaking,
    private: true,
  },
  {
    name: route.newBetMaking.name,
    path: route.newBetMaking.path,
    component: containers.NewBetMaking,
    private: true,
  },
  {
    name: route.betHistory.name,
    path: route.betHistory.path,
    component: containers.BetHistory,
    private: true,
  },
  {
    name: route.betMeHistory.name,
    path: route.betMeHistory.path,
    component: containers.BetMeHistory,
    private: true,
  },
  {
    name: route.betReport.name,
    path: route.betReport.path,
    component: containers.BetReport,
    private: true,
  },
  {
    name: route.betLotteryGroup.name,
    path: route.betLotteryGroup.path,
    component: containers.BetLotteryGroup,
    private: true,
  },
  // {
  //   name: route.betSumHistory.name,
  //   path: route.betSumHistory.path,
  //   component: containers.BetSumHistory,
  //   private: true,
  // },
  {
    name: route.betChildHistory.name,
    path: route.betChildHistory.path,
    component: containers.BetChildHistory,
    private: true,
  },
  {
    name: route.betWaitingResult.name,
    path: route.betWaitingResult.path,
    component: containers.BetWaitingResult,
    private: true,
    exact: true,
  },
  {
    name: route.betSummaryResult.name,
    path: route.betSummaryResult.path,
    component: containers.BetSummaryResult,
    private: true,
    exact: true,
  },
  {
    name: route.betResult.name,
    path: route.betResult.path,
    component: containers.BetResult,
    private: true,
    exact: true,
  },
  {
    name: route.lottoResult.name,
    path: route.lottoResult.path,
    component: containers.LottoResult,
    private: true,
    exact: true,
  },
  {
    name: route.lottoResultDetail.name,
    path: route.lottoResultDetail.path,
    component: containers.LottoResultDetail,
    private: true,
  },
  {
    name: route.resetUserPassword.name,
    path: route.resetUserPassword.path,
    component: containers.ResetUserPassword,
    private: true,
    exact: true,
  },
  {
    name: route.betChildren.name,
    path: route.betChildren.path,
    component: containers.BetChildren,
    private: true,
    exact: true,
  },
  {
    name: route.settingRate.name,
    path: route.settingRate.path,
    component: containers.SettingRate,
    private: true,
    exact: true,
  },
  {
    name: route.membersList.name,
    path: route.membersList.path,
    component: containers.MembersList,
    private: true,
    exact: true,
  },
  {
    name: route.memberDetail.name,
    path: route.memberDetail.path,
    component: containers.MemberDetail,
    private: true,
    exact: true,
  },
  {
    name: route.memberDetailNote.name,
    path: route.memberDetailNote.path,
    component: containers.MemberDetailNote,
    private: true,
    exact: true,
  },
  {
    name: route.userDetail.name,
    path: route.userDetail.path,
    component: containers.UserDetail,
    private: true,
    exact: true,
  },
  {
    name: route.memberResetPassword.name,
    path: route.memberResetPassword.path,
    component: containers.MembersResetPassword,
    private: true,
    exact: true,
  },
  {
    name: route.Dashboard.name,
    path: route.Dashboard.path,
    component: containers.Dashboard,
    private: true,
    exact: true,
  },
  {
    name: route.financeSummaryCustomer.name,
    path: route.financeSummaryCustomer.path,
    component: containers.FinanceSumCustomer,
    private: true,
    exact: true,
  },
  {
    name: route.financeCustomerDetails.name,
    path: route.financeCustomerDetails.path,
    component: containers.FinanceCustomerDetails,
    private: true,
    exact: true,
  },
  {
    name: route.userResetPassword.name,
    path: route.userResetPassword.path,
    component: containers.UserResetPassword,
    private: true,
    exact: true,
  },
  {
    name: route.customerList.name,
    path: route.customerList.path,
    component: containers.CustomerList,
    private: true,
    exact: true,
  },
  // {
  //   name: route.financeSummaryChild.name,
  //   path: route.financeSummaryChild.path,
  //   component: containers.FinanceSummaryChild,
  //   private: true,
  //   exact: true,
  // },
  // {
  //   name: route.customerDetail.name,
  //   path: route.customerDetail.path,
  //   component: containers.CustomerDetail,
  //   private: true,
  //   exact: true,
  // },
  // {
  //   name: route.financeChild.name,
  //   path: route.financeChild.path,
  //   component: containers.FinanceChild,
  //   private: true,
  // },
  // {
  //   name: route.financeHost.name,
  //   path: route.financeHost.path,
  //   component: containers.FinanceHostDetail,
  //   private: true,
  //   exact: false,
  // },
  // {
  //   name: route.financeSummaryHost.name,
  //   path: route.financeSummaryHost.path,
  //   component: containers.FinanceSummaryHost,
  //   private: true,
  //   exact: true,
  // },
  {
    name: route.userDetailNote.name,
    path: route.userDetailNote.path,
    component: containers.UserDetailNote,
    private: true,
    exact: true,
  },
  {
    name: route.previewBetBill.name,
    path: route.previewBetBill.path,
    component: containers.PreviewBetBill,
    private: true,
  },
  {
    name: route.numberBanManage.name,
    path: route.numberBanManage.path,
    component: containers.NumberBanManage,
    private: true,
    exact: true,
  },
  {
    name: route.numbersHoles.name,
    path: route.numbersHoles.path,
    component: containers.NumbersHoles,
    private: true,
    exact: true,
  },
  {
    name: route.NumbersHolesAddLottery.name,
    path: route.NumbersHolesAddLottery.path,
    component: containers.NumbersHolesAddLottery,
    private: true,
    exact: true,
  },
  {
    name: route.numbersHolesManage.name,
    path: route.numbersHolesManage.path,
    component: containers.NumbersHolesManage,
    private: true,
  },
  {
    name: route.NumbersBanAddLottery.name,
    path: route.NumbersBanAddLottery.path,
    component: containers.NumbersBanAddLottery,
    exact: true,
    private: true,
  },
  {
    name: route.numberBan.name,
    path: route.numberBan.path,
    component: containers.NumberBan,
    private: true,
  },

];

export default routes;
