import axios from "axios";
import { transformer } from "utils";
import environment from "../../../constants/environment";

const fetchPostMake = (betBodyArr: IAgentBet[][]) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url
    }${environment.endpoint.postMakingBetLotto}`;
  const promises = betBodyArr.map((betBody) => {
    return axios.post(endpoint, betBody);
  });

  return promises;
};

const fetchGetHistory = (
  data: ISearchAgentBetLotteryHistoryListQuery | IQueryPagination
) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url
    }${environment.endpoint.lottoHistory}`;
  return axios.get(`${endpoint}${transformer.urlSearchParams(data)}`);
};

const fetchGetResult = (query: ISearchLudensBetResultQuery) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url
    }${environment.endpoint.lottoBetResult}`;
  return axios.get(`${endpoint}${transformer.urlSearchParams(query)}`);
};

const fetchV2GetResult = (query: IV2QueryAgentLotteryResult | IQueryPagination) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url
    }${environment.endpoint.V2lottoBetResult}`;
  return axios.get(`${endpoint}${transformer.urlSearchParams(query)}`);
};

const fetchGetHistorySummaryDetail = (queryBody: any) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url
    }${environment.endpoint.lottoHistorySummaryDetail}`;
  return axios.post(`${endpoint}`, queryBody);
};

const fetchGetHistorySummary = (
  query: IAgentQueryLotteryHistorySummary | IQueryPagination
) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url
    }${environment.endpoint.lottoHistorySummary}`;
  return axios.get(`${endpoint}${transformer.urlSearchParams(query)}`);
};

//78 api/v2/agent/lottery/bet/customer 	ขอดูภาพรวมการเดิมพันหวยแบบรายลูกค้านอกระบบ
const fetchV2GetBetGroup = (data: IV2QueryAgentLotteryBetGroup | IQueryPagination) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url
    }${environment.endpoint.V2lottoBetGroup}`;
  return axios.get(`${endpoint}${transformer.urlSearchParams(data)}`);
};

const postV2AgentBet = (data: IV2PostAgentLotteryBet[]) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url
    }${environment.endpoint.V2lottoBet}`;
  return axios.post(`${endpoint}`, data);
};

const getV2AgentBet = (data: IQueryPagination | IV2QueryAgentLotteryBet) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.V2lottoBet}`;
  return axios.get(`${endpoint}${transformer.urlSearchParams(data)}`);
};

const fetchGetBetExtraction = (data: IV3QueryGetExtractionAgentBetLottery) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.getAgentBetExtraction}`;
  return axios.get(`${endpoint}${transformer.urlSearchParams(data)}`);
};

const fetchGetBetExtractionDetail = (data: IV3QueryGetDetailExtractionAgentBetLottery) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.getAgentBetExtractionDetail}`;
  return axios.get(`${endpoint}${transformer.urlSearchParams(data)}`);
};

// 103.GET: api/v3/agent/lottery/bet/lottery
const fetchGetLottoBetLotto = (data?: IV3QueryGetLotteryBetAgent) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.getLotteryBetLottery}`;
  return axios.get(`${endpoint}${transformer.urlSearchParams(data)}`);
}

// 104.GET: api/v3/agent/lottery/bet/member
const fetchGetLottoBetMember = (data?: IV3QueryGetLotteryBetMember) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.getLotteryBetMember}`;
  return axios.get(`${endpoint}${transformer.urlSearchParams(data)}`);
}

// 105.GET: api/v3/agent/lottery/report/benefit-analyst
const fetchGetLotteryBetBenefitAnalyst = (data?: IV3QueryGetLotteryBetBenefitAnalyst) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.getLotteryBetBenefitAnalyst}`;
  return axios.get<IAPIResponse<IV3RespGetLotteryBetBenefitAnalyst>>(`${endpoint}${transformer.urlSearchParams(data)}`);
}

export {
  fetchPostMake,
  fetchGetHistory,
  fetchGetResult,
  fetchGetHistorySummary,
  fetchGetHistorySummaryDetail,
  fetchV2GetResult,
  fetchV2GetBetGroup,
  postV2AgentBet,
  getV2AgentBet,
  fetchGetBetExtraction,
  fetchGetBetExtractionDetail,
  fetchGetLottoBetLotto,
  fetchGetLottoBetMember,
  fetchGetLotteryBetBenefitAnalyst
};
