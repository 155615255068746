import './betReport.style.scss'
import colors from 'constants/colors'
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router'
import { CODE_TO_SHORT_NAME } from 'constants/variables'
import {
  get,
  groupBy,
  isEmpty,
  isNil,
  map,
  max,
  orderBy,
  range,
} from 'lodash'
import Countdown from "react-countdown"
import { date, number } from 'utils'
import { Breadcrumb, Dropdown, DatePickerNew, ButtonToggle, Button, InputNumber, LoadingButton, SuccessModal } from 'components'
import { fetchGetLotteryBetBenefitAnalyst } from "reduxs/lotto/bet/services"
import { fetchAgentHoles, fetchAgentHolesLottery, fetchAgentHolesNumber, fetchAgentHolesUpdate, fetchHolesAddAgent } from "reduxs/numbersHoles/services"
import { utcToZonedTime } from 'date-fns-tz'
const listOptionLotto: { [type in TLottoType]: string } = { ...CODE_TO_SHORT_NAME }

const listSortType = [
  {
    label: 'คาดคะเนยอดเสีย มาก > น้อย',
    value: 'benfit_predict_asc'
  },
  {
    label: 'คาดคะเนยอดเสีย น้อย > มาก',
    value: 'benfit_predict_desc'
  },
  {
    label: 'ยอดแทง มาก > น้อย',
    value: 'bet_total_desc'
  },
  {
    label: 'ยอดแทง น้อย > มาก',
    value: 'bet_total_asc'
  },
  {
    label: 'หมายเลข มาก > น้อย',
    value: 'bet_number_desc'
  },
  {
    label: 'หมายเลข น้อย > มาก',
    value: 'bet_number_asc'
  },
]
declare interface ILottoType {
  id: string,
  name: string,
  stateKey: TLottoStateKey
}

declare type TLottoStateKey =
  | "numholfacLimitThreeUp"
  | "numholfacLimitThreeToast"
  | "numholfacLimitTwoUp"
  | "numholfacLimitTwoDown"
  | "numholfacLimitRunUp"
  | "numholfacLimitRunDown"



const constants = {
  mainPageTitle: "ภาพรวม",
  betReportTitle: "คาดคะเนได้/เสีย",
  paperTitle: "คาดคะเนได้/เสีย",
  refresh: 'รีเฟรช',
  settingBet: 'ตั้งสู้รายตัว',
  paperDescription: "",
  dataNotFound: "ไม่พบข้อมูล...",
  RECEIVE: "รับ/สู้",
  resetLotterySettingSuccessful: "บันทึกตั้งสู้สำเร็จ",
};

const mappingThead: ILottoType[] = [
  { id: '', name: "", stateKey: 'numholfacLimitThreeUp' },
  { id: 'threeUp', name: "3 ตัวบน", stateKey: 'numholfacLimitThreeUp' },
  { id: 'threeToast', name: "3 ตัวโต๊ด", stateKey: 'numholfacLimitThreeToast' },
  { id: 'twoUp', name: "2 ตัวบน", stateKey: 'numholfacLimitTwoUp' },
  { id: 'twoDown', name: "2 ตัวล่าง", stateKey: 'numholfacLimitTwoDown' },
  { id: 'runUp', name: "วิ่งบน", stateKey: 'numholfacLimitRunUp' },
  { id: 'runDown', name: "วิ่งล่าง", stateKey: 'numholfacLimitRunDown' },
]

const defaultProps: IBetReportContainerProps & IBetReportContainerAction = {
  timeout: 0
}

export default class BetSumHistoryContainer extends Component<
  IBetReportContainerProps & IBetReportContainerAction & RouteComponentProps & IBetReportContainerState
> {
  static defaultProps = defaultProps

  queryTimeout: NodeJS.Timeout | null = null

  state: IBetReportContainerState = {
    lotteryTime: '',
    querySearch: {
      lotto: "",
      limit: 50,
      date: new Date(),
    },
    queryAgentHolesNumbers: { sortType: 'benfit_predict_desc', lotteryDateAt: date.formatDateTimeZero(new Date()) },
    timeout: 60,
    isRefresh: false,
    lottoRateLimit: {
      threeUp: '',
      threeDown: '',
      threeToast: '',
      twoUp: '',
      twoDown: '',
      twoToast: '',
      runUp: '',
      runDown: '',
    },
    mode: constants.RECEIVE,
    holesLottoTemp: {},
    holesLotto: {},
    holesNumberAgentlist: [],
    agentHolesLottery: {},
    lotteryBetBenefitAnalyst: [
      { type: 'ซื้อ', betTotal: 0, threeUp: 0, threeToast: 0, twoUp: 0, twoDown: 0, runUp: 0, runDown: 0 },
      { type: 'คอม', betTotal: 0, threeUp: 0, threeToast: 0, twoUp: 0, twoDown: 0, runUp: 0, runDown: 0 },
      { type: 'ถูก/รับ', betTotal: 0, threeUp: 0, threeToast: 0, twoUp: 0, twoDown: 0, runUp: 0, runDown: 0 },
      { type: 'เสีย/จ่าย', betTotal: 0, threeUp: 0, threeToast: 0, twoUp: 0, twoDown: 0, runUp: 0, runDown: 0 }
    ]
  }

  componentDidMount() {
    this.startInterval()
  }

  componentDidUpdate(prevProps: IBetReportContainerProps, prevState: IBetReportContainerState) {
    if (this.state.timeout === 1 && prevState.timeout !== 1) {
      if (this.state.querySearch.lotto) {
        this.getAgentHoles(this.state.querySearch.lotto)
        this.getLotteryBetBenefitAnalyst({lotteryName: this.state.querySearch.lotto, lotteryDateAt: this.state.queryAgentHolesNumbers.lotteryDateAt!})
      }

    }
  }

  componentWillUnmount() {
    this.clearLocalInterval()
  }

  startInterval = () => {
    this.clearLocalInterval()
    this.queryTimeout = setInterval(this.handleInterval, 1000)
  }
  handleInterval = () => {
    if (this.state.timeout === 1) {
      this.setState({ timeout: 60 })
      this.startInterval()
    } else if (this.state.isRefresh) {
      this.setState({ timeout: 60, isRefresh: false })
      this.startInterval()
    }
    else {
      this.setState((state: any) => ({ timeout: state.timeout - 1 }))
    }
  }

  clearLocalInterval = () => {
    if (this.queryTimeout !== null) {
      clearInterval(this.queryTimeout)
      this.queryTimeout = null
    }
  }

  onRefreshInterval = () => {
    this.clearLocalInterval()
    this.queryTimeout = setInterval(this.handleInterval, 1000)
    if(this.state.querySearch.lotto && this.state.queryAgentHolesNumbers.lotteryDateAt){
      this.getAgentHoles(this.state.querySearch.lotto)
      this.getLotteryBetBenefitAnalyst({lotteryName: this.state.querySearch.lotto, lotteryDateAt: this.state.queryAgentHolesNumbers.lotteryDateAt})
    }
  }

  getAgentHoles = (lotteryName: string) => {
    fetchAgentHoles({ lotteryName })
      .then((response) => {
        const dataLotto: IV2GetHolesAgent = get(response, 'data.data[0]', {})
        if (!isEmpty(dataLotto)) {
          this.setState({
            holesLottoTemp: dataLotto,
            holesLotto: dataLotto,
            queryAgentHolesNumbers: {
              ...this.state.queryAgentHolesNumbers,
              agentLotsetId: dataLotto.agentLotsetId,
              agentLotsetName: dataLotto.name,
              numholfacId: dataLotto.numholfacId
            }
          }, () => {
            this.getAgentHolesNumber(this.state.queryAgentHolesNumbers)
          })
        }
        else {
          this.getAgentHolesLottery(lotteryName)
        }
      })
      .catch((error) => { })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  getAgentHolesLottery = (lotteryName: string) => {
    fetchAgentHolesLottery({ lotteryName }).then((response) => {
      const dataAgentHolesLottery: IV2GetHolesLotteryAgent = get(response, 'data.data[0]', [])
      if (dataAgentHolesLottery) {
        this.setState({ agentHolesLottery: dataAgentHolesLottery }, () => {
          this.addHolesAgent(dataAgentHolesLottery.agentLotsetId)
        })
      }
    })
      .catch((error) => { console.log(error) })
  }

  addHolesAgent = (agentLotsetId: number) => {
    fetchHolesAddAgent({ agentLotsetId })
      .then((response) => {
        const lottoHolesData: IV2RespPostHolesAddAgent = get(response, 'data.data', {})
        if (lottoHolesData) {
          this.getAgentHoles(this.state.querySearch.lotto)
        }
      })
      .catch((error) => { console.log(error) })
  }


  getAgentHolesNumber = (query: IV2QueryGetHolesNumberAgent) => {
    fetchAgentHolesNumber(query)
      .then((response) => {
        const dataHolesNumberAgent: IV2GetHolesNumberAgent = get(response, 'data.data', [])

        if (dataHolesNumberAgent) {
          this.setState({ holesNumberAgentlist: dataHolesNumberAgent })
        }
      })
      .catch((error) => { })
      .finally(() => {
      })
  }

  updateAgentHoles = (data: IV2UpdateHolesAgent) => {
    fetchAgentHolesUpdate(data)
      .then((response) => {
        SuccessModal.show({
          action: () => SuccessModal.hide(),
          description: constants.resetLotterySettingSuccessful,
        });
        this.getAgentHoles(this.state.querySearch.lotto)
      })
      .catch((error) => { })
  }

  getColor = (totalResult: number) => {
    return isNil(totalResult) ? '' : totalResult > 0 ? colors.TEXT_BLUE : totalResult < 0 ? colors.PRIMARY_RED : ''
  }

  getLotteryBetBenefitAnalyst = (query: IV3QueryGetLotteryBetBenefitAnalyst) => {
    fetchGetLotteryBetBenefitAnalyst(query)
      .then((response) => {
        const betBenefitAnalystData: IV3RespGetLotteryBetBenefitAnalyst = get(response, 'data.data', {})
        if (betBenefitAnalystData) {
          this.setState({
            lotteryTime: betBenefitAnalystData.lotteryEndAt,
            lotteryBetBenefitAnalyst: [
              {
                type: 'ซื้อ',
                threeUp: betBenefitAnalystData.betThreeUp,
                threeToast: betBenefitAnalystData.betThreeToast,
                twoUp: betBenefitAnalystData.betTwoUp,
                twoDown: betBenefitAnalystData.betTwoDown,
                runUp: betBenefitAnalystData.betRunUp,
                runDown: betBenefitAnalystData.betRunDown,
                betTotal: betBenefitAnalystData.betTotal
              },
              {
                type: 'คอม',
                threeUp: betBenefitAnalystData.comThreeUp,
                threeToast: betBenefitAnalystData.comThreeToast,
                twoUp: betBenefitAnalystData.comTwoUp,
                twoDown: betBenefitAnalystData.comTwoDown,
                runUp: betBenefitAnalystData.comRunUp,
                runDown: betBenefitAnalystData.comRunDown,
                betTotal: betBenefitAnalystData.comTotal
              },
              {
                type: 'ถูก/รับ',
                threeUp: betBenefitAnalystData.recvThreeUp,
                threeToast: betBenefitAnalystData.recvThreeToast,
                twoUp: betBenefitAnalystData.recvTwoUp,
                twoDown: betBenefitAnalystData.recvTwoDown,
                runUp: betBenefitAnalystData.recvRunUp,
                runDown: betBenefitAnalystData.recvRunDown,
                betTotal: betBenefitAnalystData.recvTotal
              },
              {
                type: 'เสีย/จ่าย',
                threeUp: betBenefitAnalystData.maxPayThreeUp,
                threeToast: betBenefitAnalystData.maxPayThreeToast,
                twoUp: betBenefitAnalystData.maxPayTwoUp,
                twoDown: betBenefitAnalystData.maxPayTwoDown,
                runUp: betBenefitAnalystData.maxPayRunUp,
                runDown: betBenefitAnalystData.maxPayRunDown,
                betTotal: betBenefitAnalystData.maxPayTotal
              }
            ]
          })
        }
      })
      .catch((error) => {
      })
  }


  renderTableHead = (values: Array<ILottoType>) => {
    const mapHeadTable = map(values, (data: ILottoType, index: number) => {
      return <th className='text-center' key={index}>
        <h5 className={`text-header secondary-text ${index === 0 && "ml-3"}`}>{data.name}</h5>
      </th>
    })
    return mapHeadTable
  }

  renderTableBody = () => {
    const mapData = () => {
      return this.state.lotteryBetBenefitAnalyst.map((item, index) => (
        <tr key={index} className="row-bill-list">
          <td className="text-center">
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0px 7px' }}>
              <span className='text-bold'>{item.type}</span>
              <span style={{ color: this.getColor(item.betTotal) }}>{number.addComma(item.betTotal)}</span>
            </div>
          </td>
          <td className="text-center" style={{ color: this.getColor(item.threeUp) }}>
            <span>{number.decimalsFormatComma(item.threeUp)}</span>
          </td>
          <td className="text-center" style={{ color: this.getColor(item.threeToast) }}>
            <span>{number.decimalsFormatComma(item.threeToast)}</span>
          </td>
          <td className="text-center" style={{ color: this.getColor(item.twoUp) }}>
            <span>{number.decimalsFormatComma(item.twoUp)}</span>
          </td>
          <td className="text-center" style={{ color: this.getColor(item.twoDown) }}>
            <span>{number.decimalsFormatComma(item.twoDown)}</span>
          </td>
          <td className="text-center" style={{ color: this.getColor(item.runUp) }}>
            <span>{number.decimalsFormatComma(item.runUp)}</span>
          </td>
          <td className="text-center" style={{ color: this.getColor(item.runDown) }}>
            <span>{number.decimalsFormatComma(item.runDown)}</span>
          </td>
        </tr>
      ))
    }
    return mapData()
    // return this.props.betGroupV2IsFetching ? loadingTable : !isEmpty(values) ? mapData : dataEmpty
  }

  checkBetTotal = (typeBet: string, totalBet: number) => {
    const limitType: Record<string, number | undefined> = {
      'THREE_UP': this.state.holesLotto.numholfacLimitThreeUp,
      'THREE_TOAST': this.state.holesLotto.numholfacLimitThreeToast,
      'TWO_UP': this.state.holesLotto.numholfacLimitTwoUp,
      'TWO_DOWN': this.state.holesLotto.numholfacLimitTwoDown,
      'RUN_UP': this.state.holesLotto.numholfacLimitRunUp,
      'RUN_DOWN': this.state.holesLotto.numholfacLimitRunDown,
    }
    const limit = limitType[typeBet]
    if (limit === undefined) {
      return false
    }
    return (totalBet >= Number(limit)) && (Math.abs(totalBet) !== totalBet || totalBet !== 0)
  }


  renderTableHolesLotto = (holesNumberAgentlist: IV2GetHolesNumberAgent[]) => {

    const grouped = groupBy(holesNumberAgentlist, 'numholBetType')
    const maxLen = max([
      get(grouped, "THREE_UP.length", 0),
      get(grouped, "THREE_TOAST.length", 0),
      get(grouped, "TWO_UP.length", 0),
      get(grouped, "TWO_DOWN.length", 0),
      get(grouped, "RUN_UP.length", 0),
      get(grouped, "RUN_DOWN.length", 0)
    ])

    const mapData = map(range(0, maxLen as number), (index) => {

      const threeUpData = get(grouped, `THREE_UP.${index}`, undefined)
      const threeToastData = get(grouped, `THREE_TOAST.${index}`, undefined)
      const twoUpData = get(grouped, `TWO_UP.${index}`, undefined)
      const twoDownData = get(grouped, `TWO_DOWN.${index}`, undefined)
      const runUpData = get(grouped, `RUN_UP.${index}`, undefined)
      const runDownData = get(grouped, `RUN_DOWN.${index}`, undefined)

      const stColorReceive = (isLostReceive: boolean) => { return isLostReceive ? 'primary-red-text' : 'primary-text' }
      const getStColorGainAndLose = (data:any) => {
        const benefit = get(data, "numholPredictBenefit", "");
        return benefit > 0 ? 'primary-blue-text' : benefit === 0 ? 'primary-text' : 'primary-red-text';
      };
      const isLostReceiveThreeUp = this.checkBetTotal(get(threeUpData, "numholBetType", ""), get(threeUpData, "numholBetTotal", ""))
      const stColorGainAndLoseThreeUp = getStColorGainAndLose(threeUpData)
      const isLostReceiveThreeToast = this.checkBetTotal(get(threeToastData, "numholBetType", ""), get(threeToastData, "numholBetTotal", ""))
      const stColorGainAndLoseThreeToast = getStColorGainAndLose(threeToastData)
      const isLostReceiveTwoUp = this.checkBetTotal(get(twoUpData, "numholBetType", ""), get(twoUpData, "numholBetTotal", ""))
      const stColorGainAndLoseTwoUp = getStColorGainAndLose(twoUpData)
      const isLostReceiveTwoDown = this.checkBetTotal(get(twoDownData, "numholBetType", ""), get(twoDownData, "numholBetTotal", ""))
      const stColorGainAndLoseTwoDown = getStColorGainAndLose(twoDownData)
      const isLostReceiveRunUp = this.checkBetTotal(get(runUpData, "numholBetType", ""), get(runUpData, "numholBetTotal", ""))
      const stColorGainAndLoseRunUp = getStColorGainAndLose(runUpData)
      const isLostReceiveRunDown = this.checkBetTotal(get(runDownData, "numholBetType", ""), get(runDownData, "numholBetTotal", ""))
      const stColorGainAndLoseRunDown = getStColorGainAndLose(runDownData)

      return (
        <tr
          className={`table-holes-list`}
          key={index}
          style={{ backgroundColor: "#fee18" }}
        >
          <td className="column-box text-center">
            <span>{index + 1}</span>
          </td>
          <td
            className={`column-box ${get(threeUpData, "numholResult", "") > 0 ? "jackpot" : ""}`}
          >
            <div className="item-cell">
              <span className="primary-blue-text">
                {get(threeUpData, "numholNumber", "")}
              </span>
              <span
                className={
                  this.state.mode === constants.RECEIVE
                    ? stColorReceive(isLostReceiveThreeUp)
                    : stColorGainAndLoseThreeUp
                }
              >
                     {this.state.mode === constants.RECEIVE
                  ? isNil(get(threeUpData, "numholBetTotal", null)) ? '' : number.decimalsFormatComma(get(threeUpData, "numholBetTotal", 0))
                  : isNil(get(threeUpData, "numholPredictBenefit", null)) ? '' :  number.decimalsFormatComma(
                      get(threeUpData, "numholPredictBenefit", 0)
                    )}
              </span>
            </div>
          </td>
          <td className={`column-box ${get(threeToastData, "numholResult", "") > 0 ? "jackpot" : ""}`}>
            <div className="item-cell">
              <span className="primary-blue-text">
                {get(threeToastData, "numholNumber", "")}
              </span>
              <span
                className={
                  this.state.mode === constants.RECEIVE
                    ? stColorReceive(isLostReceiveThreeToast)
                    : stColorGainAndLoseThreeToast
                }
              >
                {this.state.mode === constants.RECEIVE
                  ? isNil(get(threeToastData, "numholBetTotal", null)) ? '' : number.decimalsFormatComma(get(threeToastData, "numholBetTotal", 0))
                  : isNil(get(threeToastData, "numholPredictBenefit", null)) ? '':number.decimalsFormatComma(
                    get(threeToastData, "numholPredictBenefit", 0)
                  )}
              </span>
            </div>
          </td>
          <td className={`column-box ${get(twoUpData, "numholResult", "") > 0 ? "jackpot" : ""}`}>
            <div className="item-cell">
              <span className="primary-blue-text">
                {get(twoUpData, "numholNumber", "")}
              </span>
              <span
                className={
                  this.state.mode === constants.RECEIVE
                    ? stColorReceive(isLostReceiveTwoUp)
                    : stColorGainAndLoseTwoUp
                }
              >
                {this.state.mode === constants.RECEIVE
                  ? isNil(get(twoUpData, "numholBetTotal", null)) ? '':number.decimalsFormatComma(get(twoUpData, "numholBetTotal", 0))
                  : isNil(get(twoUpData, "numholPredictBenefit", null)) ? '' :number.decimalsFormatComma(get(twoUpData, "numholPredictBenefit", 0))}
              </span>
            </div>
          </td>
          <td className={`column-box ${get(twoDownData, "numholResult", "") > 0 ? "jackpot" : ""}`}>
            <div className="item-cell">
              <span className="primary-blue-text">
                {get(twoDownData, "numholNumber", "")}
              </span>
              <span
                className={
                  this.state.mode === constants.RECEIVE
                    ? stColorReceive(isLostReceiveTwoDown)
                    : stColorGainAndLoseTwoDown
                }
              >
                {this.state.mode === constants.RECEIVE
                  ? isNil(get(twoDownData, "numholBetTotal", null)) ? '': number.decimalsFormatComma(get(twoDownData, "numholBetTotal", 0))
                  : isNil(get(twoDownData, "numholPredictBenefit", null)) ? '': number.decimalsFormatComma(
                    get(twoDownData, "numholPredictBenefit", 0)
                  )}
              </span>
            </div>
          </td>
          <td className={`column-box ${get(runUpData, "numholResult", "") > 0 ? "jackpot" : ""}`}>
            <div className="item-cell">
              <span className="primary-blue-text">
                {get(runUpData, "numholNumber", "")}
              </span>
              <span
                className={
                  this.state.mode === constants.RECEIVE
                    ? stColorReceive(isLostReceiveRunUp)
                    : stColorGainAndLoseRunUp
                }
              >
                {this.state.mode === constants.RECEIVE
                  ? isNil(get(runUpData, "numholBetTotal", null)) ? '' : number.decimalsFormatComma(get(runUpData, "numholBetTotal", 0))
                  : isNil(get(runUpData, "numholPredictBenefit", null)) ? '' : number.decimalsFormatComma(get(runUpData, "numholPredictBenefit", 0))}
              </span>
            </div>
          </td>
          <td className={`column-box ${get(runDownData, "numholResult", "") > 0 ? "jackpot" : ""}`}>
            <div className="item-cell">
              <span className="primary-blue-text">
                {get(runDownData, "numholNumber", "")}
              </span>
              <span
                className={
                  this.state.mode === constants.RECEIVE
                    ? stColorReceive(isLostReceiveRunDown)
                    : stColorGainAndLoseRunDown
                }
              >
                {this.state.mode === constants.RECEIVE
                  ? isNil(get(runDownData, "numholBetTotal", null)) ? '' : number.decimalsFormatComma(get(runDownData, "numholBetTotal", 0))
                  : isNil(get(runDownData, "numholPredictBenefit", null)) ? '' : number.decimalsFormatComma(
                    get(runDownData, "numholPredictBenefit", 0)
                  )}
              </span>
            </div>
          </td>
        </tr>
      )
    })
    return mapData
  }



  renderInputText = () => {
    const InputNumbers = mappingThead.map((data, index) => {
      if (index === 0) {
        return null
      }
      return <td key={data.id} className="text-center p1-y p1-x" style={{ width: '10%' }}>
        <InputNumber
          renderError={false}
          LoadingButton={this.state.timeout === 1}
          loadingStyles='loading-input'
          id={data.id}
          name={data.name}
          decimalScale={0}
          allowLeadingZeros
          allowNegative={false}
          thousandSeparator
          hidePlaceholder={true}
          value={isEmpty(this.state.holesLottoTemp) ? '' : String(this.state.holesLottoTemp[data.stateKey as TLottoStateKey])}
          backgroundColor={colors.TRANSPARENT}
          placeholder={data.name}
          onValueChange={({ value }) => {
            this.setState({
              holesLottoTemp: {
                ...this.state.holesLottoTemp,
                [data.stateKey]: value
              }
            })
          }
          }
          inputClassName="input-box"
          disabled={false}
        />
      </td>
    })
    return (
      <tr className={`row-bill-total`}>
        <td className="text-center" style={{ width: '10%' }} >
          <div className="save-holes" style={{ padding: '0px 7px' }}>
            <span> {'ตั้งสู้'}</span>
            <span>
              <Button
                id={"refresh"}
                size={"medium"}
                textSize={14}
                text={`บันทึก`}
                disabled={isNil(this.state.holesLotto.agentLotsetId)}
                onClick={() => {
                  const updateData: IV2UpdateHolesAgent = {
                    agentLotsetId: Number(this.state.holesLottoTemp.agentLotsetId!),
                    numholfacId: Number(this.state.holesLottoTemp.numholfacId!),
                    numholfacLimitThreeUp: Number(this.state.holesLottoTemp.numholfacLimitThreeUp!),
                    numholfacLimitThreeToast: Number(this.state.holesLottoTemp.numholfacLimitThreeToast!),
                    numholfacLimitTwoUp: Number(this.state.holesLottoTemp.numholfacLimitTwoUp!),
                    numholfacLimitTwoDown: Number(this.state.holesLottoTemp.numholfacLimitTwoDown!),
                    numholfacLimitRunUp: Number(this.state.holesLottoTemp.numholfacLimitRunUp!),
                    numholfacLimitRunDown: Number(this.state.holesLottoTemp.numholfacLimitRunDown!),
                  }
                  this.updateAgentHoles(updateData)
                }}
              />
            </span>
          </div>
        </td>
        {InputNumbers}
      </tr>
    )
  }


  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.mainPageTitle, active: false, path: "/dashboard" },
      { label: constants.betReportTitle, active: true },
    ];

    const TableHead = this.renderTableHead(mappingThead)
    const TableBody = this.renderTableBody()
    const TableHolesLotto = this.renderTableHolesLotto(this.state.holesNumberAgentlist.slice(0, this.state.querySearch.limit))
    const TableInput = this.renderInputText()

    return (
      <div className="paper-container bet-report-container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="sub-menu" style={{ width: "fit-content" }}>
              <Breadcrumb
                items={navigates}
                handleOnClickItem={(path: string) => this.props.history.replace(path)}
              />
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <div className="paper-title">{constants.paperTitle}</div>
          <div className="sub-title2  m3-t">
            {
              !isEmpty(this.state.lotteryTime) && (
                <Countdown date={utcToZonedTime(new Date(this.state.lotteryTime), 'UTC')}
                  renderer={(props) => (
                    <div className="row">
                      <div className="col p-2">
                        <div className="countdown-number w-100 h-100 d-flex flex-column align-items-center">
                          <h4>{props.days}</h4>
                          <div className="tiny-regular tertiary-text">
                            {'วัน'}
                          </div>
                        </div>
                      </div>
                      <div className="col p-2">
                        <div className="countdown-number w-100 h-100 d-flex flex-column align-items-center">
                          <h4>{props.hours}</h4>
                          <div className="tiny-regular tertiary-text">
                            {'ชม'}
                          </div>
                        </div>
                      </div>
                      <div className="col p-2">
                        <div className="countdown-number w-100 h-100 d-flex flex-column align-items-center">
                          <h4>{props.minutes}</h4>
                          <div className="tiny-regular tertiary-text">
                            {'นาที'}
                          </div>
                        </div>
                      </div>
                      <div className="col p-2">
                        <div className="countdown-number w-100 h-100 d-flex flex-column align-items-center">
                          <h4>{props.seconds}</h4>
                          <div className="tiny-regular tertiary-text">
                            {'วินาที'}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                />)
            }

          </div>
        </div>
        <div className="paper-description">{constants.paperDescription}</div>
        <div className="paper-body">
          <div className="box-shadow">
            <div className='option-search my-3'>
              <div className='row d-flex align-items-center box-search'>
                <div className='col-3 box-search-item'>
                  <div className='dropdown-box'>
                    <Dropdown
                      value={(this.state.querySearch.lotto)}
                      iconStyles={{ color: colors.TERTIARY_TEXT }}
                      valueContainerStyles={{ fontSize: "13px", fontFamily: 'SukhumvitSet-Bold' }}
                      optionStyles={{ fontSize: "13px" }}
                      options={orderBy(Object.keys(listOptionLotto).map((item) => {
                        return { value: item, label: listOptionLotto[item as TLottoType], disabled: false }
                      }),
                        [(i) => {
                          return i.value === 'GOVN' ? 1 : 2
                        }, 'label'], ['asc', 'asc']).filter((i) => i.value !== 'HANOI' && i.value !== 'YEGEE')
                      }
                      controlBackgroundColor={colors.PRIMARY_BG}
                      placeholder={isEmpty(this.state.querySearch.lotto) ? 'เลือกหวย' : listOptionLotto[this.state.querySearch.lotto as TLottoType]}
                      menuStyles={{ width: "100%" }}
                      handleOnChange={(target: IOptions) => {
                        this.setState({
                          querySearch: { ...this.state.querySearch, lotto: target.value }
                        }, () => {
                          this.getAgentHoles(target.value as string)
                          if (this.state.querySearch.lotto && this.state.queryAgentHolesNumbers.lotteryDateAt) {
                            this.getLotteryBetBenefitAnalyst({ lotteryDateAt: this.state.queryAgentHolesNumbers.lotteryDateAt, lotteryName: target.value as string })
                          }
                        })
                      }}
                    />
                  </div>
                </div>
                <div className='col-3 box-search-item'>
                  <DatePickerNew
                    isShowIcon
                    value={this.state.querySearch.date}
                    placeholder="วันเริ่มต้น"
                    format="DD MMM YYYY"
                    editable={false}
                    onChange={(value) => {
                      if (value !== null) {
                        const dateApi = date.formatDateTimeZero(new Date(Number(value)))
                        this.setState(
                          {
                            queryAgentHolesNumbers: { ...this.state.queryAgentHolesNumbers, lotteryDateAt: dateApi },
                            querySearch: {
                              ...this.state.querySearch,
                              date: value,
                            }
                          }, () => {
                            if (this.state.queryAgentHolesNumbers.agentLotsetId) {
                              this.getAgentHolesNumber(this.state.queryAgentHolesNumbers)
                            }
                            if (this.state.querySearch.lotto) {
                              this.getLotteryBetBenefitAnalyst({ lotteryDateAt: dateApi, lotteryName: this.state.querySearch.lotto })
                            }
                          }
                        )
                      }
                    }}
                  />
                </div>
                <div className='col'>
                </div>
              </div>
            </div>
            <div className='border-line m2-b'></div>
            <div className='forecast-search'>
              <div className='d-flex'>
                <ButtonToggle textSwitch={['รับ/สู้', 'ได้/เสีย']} action={(value) => this.setState({ mode: value })} />
                <div className='d-flex'>
                  <h6 className='text-bold m2-x' style={{ paddingTop: '10px', paddingBottom: '10px' }}>{'จำนวน'}</h6>
                  <div className="dropdown-rowPerPage" style={{ width: 120 }}>
                    <Dropdown
                      menuPlacement={"auto"}
                      value={this.state.querySearch.limit}
                      options={[50, 250, 500, 750, 'ทั้งหมด'].map((item) => { return { value: item === 'ทั้งหมด' ? 1000 : item, label: `${item.toString()}`, disabled: false } })}
                      controlBackgroundColor={colors.PRIMARY_BG}
                      placeholder={`${this.state.querySearch.limit}`}
                      menuStyles={{ width: "100p%" }}
                      handleOnChange={(target: IOptions) => {
                        this.setState({
                          querySearch: { ...this.state.querySearch, limit: target.value, }
                        })
                      }}
                    />
                  </div>
                </div>
                <div className='d-flex m2-r'>
                  <h6 className='text-bold m2-x' style={{ paddingTop: '10px', paddingBottom: '10px' }}>{'เรียง'}</h6>
                  <div className="dropdown-rowPerPage">
                    <Dropdown
                      valueContainerStyles={{ width: 210 }}
                      menuPlacement={"auto"}
                      value={this.state.queryAgentHolesNumbers.sortType}
                      placeholder={'เรียงจาก'}
                      options={listSortType.map((item) => { return { value: item.value, label: item.label, disabled: false } })}
                      controlBackgroundColor={colors.PRIMARY_BG}
                      menuStyles={{ width: "100%" }}
                      handleOnChange={(target: IOptions) => {
                        this.setState({
                          queryAgentHolesNumbers: { ...this.state.queryAgentHolesNumbers, sortType: target.value, },
                        }, () => {
                          if (this.state.queryAgentHolesNumbers.agentLotsetId) {
                            this.getAgentHolesNumber(this.state.queryAgentHolesNumbers)
                          }
                        })

                      }}
                    />
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <LoadingButton
                    loading={this.state.timeout === 1}
                    id={"refresh"}
                    size={"moreMedium"}
                    textSize={16}
                    text={constants.refresh}
                    loadingStyle={'refresh-button'}
                    onClick={() => {
                      this.setState({ isRefresh: true })
                      this.onRefreshInterval()
                      // this.startInterval()
                    }}
                  />
                  {<div className="m1-l body-5 secondary-text">{`รีเฟรชใน ${this.state.timeout} วินาที`}</div>}
                </div>
              </div>
              {/* <div>
                <Button
                  id={"add-member"}
                  size={"moreMedium"}
                  disabled={true}
                  textSize={14}
                  text={constants.settingBet}
                />
              </div> */}
            </div>
            <div className='border-line m2-b'></div>
            <div className='row'>
              <div className='col-12'>
                <div className='lotto-bill'>
                  <div className="table-container">
                    <div className="table-responsive-xl">
                      <table className="w-100">
                        <thead className='header-table-lotto-bill'>
                          <tr>{TableHead}</tr>
                        </thead>
                        <tbody>{TableBody}</tbody>
                        <tbody>{TableInput}</tbody>
                        <tbody>{TableHolesLotto}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
