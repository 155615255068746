import { NUMBER } from "constants/regex";
import { string, object } from "yup";

const constants = {
  phoneNumber: "กรุณากรอกหมายเลขโทรศัพท์ 10 หลัก",
  onlyNumber: "กรุณากรอกเฉพาะตัวเลขเท่านั้น",
  bankName: "กรุณากรอกชื่อบัญชีธนาคาร",
  bankNo: "กรุณากรอกหมายเลขบัญชีธนาคาร",
  bank: "กรุณาเลือกธนาคาร",
  commission: "กรุณากรอกค่าคอมมิชชั่น",
  minBet: "กรุณากรอกจำนวนเงินแทงต่ำสุด",
  maxBet: "กรุณากรอกจำนวนเงินแทงสูงสุด",
  betMinOverLimit: "แทงต่ำสุดต้องน้อยกว่าลิมิตที่กำหนดไว้",
  betMaxOverLimit: "แทงสูงสุดต้องน้อยกว่าลิมิตที่กำหนดไว้",
  hostSharePercentageRequired: "กรุณากรอกเปอร์เซ็นต์ถือสู้",
  fixSharePercentageRequired: "กรุณากรอกเปอร์เซ็นต์บังคับสู้",
  childLotsetBetMinRequired: "กรุณากรอกจำกัดแทงต่ำสุด",
  childLotsetBetMaxRequired: "กรุณากรอกจำกัดแทงสูงสุด",
  childLotsetDisRequired: "กรุณากรอกส่วนลด",
  childLotsetBetRateRequired: "กรุณากรอกอัตราจ่าย",
};

const bankLength = (bankType: string) => {
  return `กรุณากรอกเลขบัญชีธนาคารให้ครบ ${
    bankType === "GSB" || bankType === "BAAC" ? "12" : "10"
  } ตัว`;
};

const schema = (meSettingData: IV2GetAgentMeSetting, meData: IV2GetAgentMe) => {
  return object().shape({
    phoneNumber: string()
      .length(10, constants.phoneNumber)
      .required(constants.phoneNumber)
      .matches(NUMBER, constants.onlyNumber),

    bankName: string().required(constants.bankName),

    bankNumber: string()
      .required(constants.bankNo)
      .when("bankType", (bankType: TBankType, schema: any) => {
        return schema.test({
          test: (bankNumber: string) => {
            if (!bankNumber) {
              return false;
            }
            if (bankType === "GSB" || bankType === "BAAC") {
              return bankNumber.length === 12;
            }
            return bankNumber.length === 10;
          },
          message: bankLength(bankType),
        });
      }),

    bankType: string().test(
      "",
      constants.bank,
      (val) => val !== "PleaseChoose"
    ),

    com: string().required(constants.commission),

    betMinLimit: string()
      .required(constants.minBet)
      .test("", constants.betMinOverLimit, (val) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= meSettingData.setBetMaxLimit;
      }),

    betMaxLimit: string()
      .required(constants.maxBet)
      .test("", constants.betMaxOverLimit, (val) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= meSettingData.setBetMaxLimit;
      }),

    hostSharePercentage: string()
      .required(constants.hostSharePercentageRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : (Number(val) || 0) >= Number(ctx.parent.mustReciv) &&
              (Number(val) || 0) <= Number(ctx.parent.meShare);
      }),

    fixSharePercentage: string()
      .required(constants.fixSharePercentageRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : (Number(val) || 0) <= Number(ctx.parent.hostSharePercentage);
      }),

    childLotsetDisThreeUp: string().required(constants.childLotsetDisRequired),
    childLotsetDisThreeToast: string().required(
      constants.childLotsetDisRequired
    ),
    childLotsetDisTwoUp: string().required(constants.childLotsetDisRequired),
    childLotsetDisTwoDown: string().required(constants.childLotsetDisRequired),
    childLotsetDisRunUp: string().required(constants.childLotsetDisRequired),
    childLotsetDisRunDown: string().required(constants.childLotsetDisRequired),

    childLotsetBetMinThreeUp: string()
      .required(constants.childLotsetBetMinRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(ctx.parent.childLotsetBetMaxThreeUpAPI) &&
              Number(val) >= Number(ctx.parent.childLotsetBetMinThreeUpAPI);
      }),
    childLotsetBetMinThreeToast: string()
      .required(constants.childLotsetBetMinRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(ctx.parent.childLotsetBetMaxThreeToastAPI) &&
              Number(val) >= Number(ctx.parent.childLotsetBetMinThreeToastAPI);
      }),
    childLotsetBetMinTwoUp: string()
      .required(constants.childLotsetBetMinRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(ctx.parent.childLotsetBetMaxTwoUpAPI) &&
              Number(val) >= Number(ctx.parent.childLotsetBetMinTwoUpAPI);
      }),
    childLotsetBetMinTwoDown: string()
      .required(constants.childLotsetBetMinRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(ctx.parent.childLotsetBetMaxTwoDownAPI) &&
              Number(val) >= Number(ctx.parent.childLotsetBetMinTwoDownAPI);
      }),
    childLotsetBetMinRunUp: string()
      .required(constants.childLotsetBetMinRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(ctx.parent.childLotsetBetMaxRunUpAPI) &&
              Number(val) >= Number(ctx.parent.childLotsetBetMinRunUpAPI);
      }),
    childLotsetBetMinRunDown: string()
      .required(constants.childLotsetBetMinRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(ctx.parent.childLotsetBetMaxRunDownAPI) &&
              Number(val) >= Number(ctx.parent.childLotsetBetMinRunDownAPI);
      }),

    childLotsetBetMaxThreeUp: string()
      .required(constants.childLotsetBetMaxRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(ctx.parent.childLotsetBetMaxThreeUpAPI);
      }),
    childLotsetBetMaxThreeToast: string()
      .required(constants.childLotsetBetMaxRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(ctx.parent.childLotsetBetMaxThreeToastAPI);
      }),
    childLotsetBetMaxTwoUp: string()
      .required(constants.childLotsetBetMaxRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(ctx.parent.childLotsetBetMaxTwoUpAPI);
      }),
    childLotsetBetMaxTwoDown: string()
      .required(constants.childLotsetBetMaxRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(ctx.parent.childLotsetBetMaxTwoDownAPI);
      }),
    childLotsetBetMaxRunUp: string()
      .required(constants.childLotsetBetMaxRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(ctx.parent.childLotsetBetMaxRunUpAPI);
      }),
    childLotsetBetMaxRunDown: string()
      .required(constants.childLotsetBetMaxRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(ctx.parent.childLotsetBetMaxRunDownAPI);
      }),

    childLotsetBetRateThreeUp: string()
      .required(constants.childLotsetBetRateRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(ctx.parent.childLotsetBetRateThreeUpAPI);
      }),
    childLotsetBetRateThreeToast: string()
      .required(constants.childLotsetBetRateRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(ctx.parent.childLotsetBetRateThreeToastAPI);
      }),
    childLotsetBetRateTwoUp: string()
      .required(constants.childLotsetBetRateRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(ctx.parent.childLotsetBetRateTwoUpAPI);
      }),
    childLotsetBetRateTwoDown: string()
      .required(constants.childLotsetBetRateRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(ctx.parent.childLotsetBetRateTwoDownAPI);
      }),
    childLotsetBetRateRunUp: string()
      .required(constants.childLotsetBetRateRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(ctx.parent.childLotsetBetRateRunUpAPI);
      }),
    childLotsetBetRateRunDown: string()
      .required(constants.childLotsetBetRateRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(ctx.parent.childLotsetBetRateRunDownAPI);
      }),
  });
};

export default schema;
