import { connect } from "react-redux";
// import { pageClick } from "reduxs/editUserHistory/pagination/action";
// import editUserHistorySelector from "../../reduxs/editUserHistory/selector";
import EditUserHistoryDetails from "./EditUserHistoryDetails.container";
// import { mockJSONEditUserHistoryDetails } from "../../reduxs/editUserHistory/json/action";

const mapStateToProps = () => {

};

const mapDispatchToProps = {
  
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUserHistoryDetails);
