import axios from 'axios'
import environment from '../../constants/environment'
import { transformer } from '../../utils'

const getEditUserHistoryEndpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.editUserHistory}`

const cancel = axios.CancelToken.source()

const fetchGetEditUserHistory = (query: IEditUserHistoryQuery) => 
axios.get(`${getEditUserHistoryEndpoint}${transformer.urlSearchParams(query)}`,
{cancelToken: cancel.token})


export {
    fetchGetEditUserHistory
}       