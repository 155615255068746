import { connect } from "react-redux";
import { validate2FA } from "reduxs/authentication/login/action";
import Register2FAContainer from "./Register2FA.container";
import authSelector from "reduxs/authentication/selector";

const mapStateToProps = (state: RootReducers): IRegister2FAContainerProps => {
  const loginState = authSelector.login(state);
  return {
    verify: loginState.data.verify,
    loginCode: loginState.code,
    loginIsFetching: loginState.isFetching,
  };
};

const mapDispatchToProps: IRegister2FAContainerActionProps = { validate2FA };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register2FAContainer);
