import { Breadcrumb, Button, Input, Panginate, SuccessModal } from "components";
import errorModal from "components/Modal/components/Error/Error.component";
import colors from "constants/colors";
import { responseCode } from "constants/response";
import { format } from "date-fns";
import { th } from "date-fns/locale";
import { isEmpty, map, noop } from "lodash";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { number } from "utils";
import "./transferConfirmation.style.scss";

const defaultProps: ITransferConfirmationContainerProps &
  ITransferConfirmationContainerAction = {
  TransferConfirmationLists: [{}],
  getTransferConfirmationLists() {
    noop();
  },
  cancelTransferConfirmationLists() {
    noop();
  },
  postPayAccept() {
    noop();
  },
  getTransferConfirmationListsCode: 0,
  getTransferConfirmationListsError: "",
  getTransferConfirmationListsIsFetching: false,
  PayRequestRestoreState: {},
  postPayAcceptCode: 0,
  postPayAcceptError: "",
  postPayAcceptIsFetching: false,
};
type DefaultProps = Readonly<typeof defaultProps>;

const mapTransferConfirmationTableName: {
  [type in TTransferConfirmationTableName as string]: string;
} = {
  requesterName: "ชื่อผู้ใช้",
  phoneNumber: "เบอร์ติดต่อ",
  updatedAt: "เวลาแจ้งโอน",
  value: "ยอดรับโอน",
};

const constants = {
  transferConfirmedTitle: "บัญชีการเงิน",
  transferConfirmedSubtitle: "รายการรายรับ",
  inputSearchPlaceHolder: "ค้นหารหัสผู้ใช้ / เบอร์ติดต่อ",
  inputTransferPlaceHolder: "ระบุยอดโอน",
  dataNotFound: "ไม่พบข้อมูล...",
  buttonAcceptTitle: "ยืนยันรับโอน",
  buttonRejectTitle: "ปฏิเสธรับโอน",
};

export default class TransferConfirmationContainer extends Component<
  DefaultProps & RouteComponentProps,
  ITransferConfirmationContainerState
> {
  static defaultProps = defaultProps;

  state: ITransferConfirmationContainerState = {
    currentPage: { selected: 1 },
    dataList: [],
    page: {
      limit: 10,
      page: 1,
      search: "",
    },
    queryTimeout: setTimeout(() => {
      noop();
    }, 0),
    selectedUser: {
      id: 0,
      phoneNumber: "",
      requesterName: "",
      requesterUsername: "",
      status: "",
      updatedAt: "",
      value: 0,
    },
    quetyPostPayAccept: {
      id: 0,
      status: "",
    },
  };
  componentDidMount() {
    const { page } = this.state;
    this.props.getTransferConfirmationLists(page);
  }
  componentDidUpdate(prevProps: ITransferConfirmationContainerProps) {
    if (
      prevProps.getTransferConfirmationListsIsFetching !==
        this.props.getTransferConfirmationListsIsFetching &&
      !this.props.getTransferConfirmationListsIsFetching
    ) {
      if (this.props.getTransferConfirmationListsCode === responseCode.OK) {
        this.setState((_, props) => ({
          dataList: props.TransferConfirmationLists.dataList,
        }));
      } else {
        errorModal.show({
          description: this.props.getTransferConfirmationListsError,
          action: errorModal.hide,
        });
      }
    }
    if (
      prevProps.postPayAcceptIsFetching !==
        this.props.postPayAcceptIsFetching &&
      !this.props.postPayAcceptIsFetching
    ) {
      if (this.props.postPayAcceptCode === responseCode.OK) {
        SuccessModal.show({
          description: "",
          action: SuccessModal.hide,
        });
        this.setState(
          (_, props) => ({
            quetyPostPayAccept: props.PayRequestRestoreState,
          }),
          () => this.props.getTransferConfirmationLists(this.state.page)
        );
      } else {
        errorModal.show({
          description: this.props.postPayAcceptError,
          action: errorModal.hide,
        });
      }
    }
  }
  componentWillUnmount() {
    this.props.cancelTransferConfirmationLists();
  }

  handlePageClick = ({ selected: selectedPage }: ISelectPage) => {
    this.setState({ currentPage: { selected: selectedPage } }, () => {
      this.setState(
        (prevState) => ({
          page: {
            ...prevState.page,
            page: prevState.currentPage.selected + 1,
          },
        }),
        () => {
          this.props.getTransferConfirmationLists(this.state.page);
        }
      );
    });
  };

  handleOnChangeSearch = (
    values: string,
    id: React.FormEvent<HTMLInputElement>
  ) => {
    const searchKeys = `${id.currentTarget.id}`;
    this.setState(
      (prevState) => ({
        page: {
          ...prevState.page,
          page: prevState.currentPage.selected + 1,
          [searchKeys]: values,
        },
      }),
      () => {
        clearTimeout(this.state.queryTimeout);
        this.setState({
          queryTimeout: setTimeout(() => {
            this.props.getTransferConfirmationLists(this.state.page);
          }, 2000),
        });
      }
    );
  };

  handlePayAccept = (query: IAgentPayAccept) => {
    this.setState(
      {
        quetyPostPayAccept: query,
      },
      () => {
        const { quetyPostPayAccept } = this.state;
        return this.props.postPayAccept(quetyPostPayAccept);
      }
    );
  };

  tableHead = (values: object) => {
    const lists = map(values, (data: TTransferConfirmationTableName, keys) => (
      <th key={`${data}-${keys}`}>
        <div
          className={`
        ${keys === "value" && "text-right"}
        ${keys === "updatedAt" && "text-center"}
        `}
        >
          <div className="col">{data}</div>
        </div>
      </th>
    ));
    return (
      <>
        {lists}
        <th>
          <div>{""}</div>
        </th>
      </>
    );
  };
  tableBody = (values: IResponseAgentRecvList[]) => {
    const dataEmpty = (
      <tr>
        <td className="text-center color-row p-2" colSpan={7}>
          {constants.dataNotFound}
        </td>
      </tr>
    );
    const mapData = map(
      values,
      (data: IResponseAgentRecvList, index: number) => {
        return (
          <tr
            key={index}
            className={` ${index % 2 === 0 ? "color-row" : ""} w-100`}
          >
            <td className="text-left">
              <span className="col-2 mt-1 pt-1 font-weight-bold">
                {`${data.requesterName}`}
                <span className="font-weight-light">{`(${data.requesterUsername})`}</span>
              </span>
            </td>
            <td className="text-center">
              <span className="col-2">
                {number.formatPhoneNumber(data.phoneNumber)}
              </span>
            </td>
            <td className="text-center">
              <span className="col-2">
                {format(new Date(data.updatedAt), "dd MMM yy HH:mm", {
                  locale: th,
                })}
              </span>
            </td>
            <td className="text-right">
              <span className="col-2 font-weight-bold">
                {number.castToMoney(data.value)}
              </span>
            </td>
            <td>
              <div className="container">
                <div className="row">
                  <div className="col px-0 mx-1 my-1">
                    <Button
                      id={`button-${index}`}
                      text={constants.buttonAcceptTitle}
                      onClick={() =>
                        this.handlePayAccept({
                          id: data.id,
                          status: "PAY_COMPLETE",
                        })
                      }
                    />
                  </div>
                  <div className="col px-0 mx-1 my-1">
                    <Button
                      backgroundColor={colors.PRIMARY_RED}
                      backgroundHoverColor={colors.PRIMARY_RED}
                      id={`button-${index}`}
                      text={constants.buttonRejectTitle}
                      onClick={() =>
                        this.handlePayAccept({
                          id: data.id,
                          status: "PAY_INCOMPLETE",
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </td>
          </tr>
        );
      }
    );
    return !isEmpty(values) ? mapData : dataEmpty;
  };

  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.replace(path);
  };

  render() {
    const { dataList, page, currentPage } = this.state;
    const navigates: IBreadcrumbItem[] = [
      {
        label: constants.transferConfirmedTitle,
        active: false,
        path: `/credits`,
      },
      {
        label: constants.transferConfirmedSubtitle,
        active: true,
      },
    ];
    const HeadTable = this.tableHead(mapTransferConfirmationTableName);
    const TableBody = this.tableBody(dataList);
    return (
      <div className="paper-container transfer-confirm-container">
        <div className="row">
          <div className="col">
            <div className="sub-menu">
              <Breadcrumb
                items={navigates}
                handleOnClickItem={this.handleOnClickBreadcrumb}
              />
            </div>
            <div className="paper-title">
              {constants.transferConfirmedTitle}
            </div>
            <div className="inline pr-3 paper-description">
              {constants.transferConfirmedSubtitle}
            </div>
            <div className="paper-body">
              <div className="row m3-b">
                <div className="col">
                  <div className="box-shadow">
                    <div className="row  d-flex flex-row-reverse">
                      <div className="col-12 col-xs-8 col-md-6 col-lg-4 text-right">
                        <Input
                          id={`search`}
                          name="inputSearchPlaceHolder"
                          type="text"
                          placeholder={constants.inputSearchPlaceHolder}
                          onChange={(e) =>
                            this.handleOnChangeSearch(e.target.value, e)
                          }
                          value={page.search}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col mt-4">
                        <div className="table-container">
                          <div className="table-responsive-xl">
                            <table className="col">
                              <thead>
                                <tr>{HeadTable}</tr>
                              </thead>
                              <tbody>{TableBody}</tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Panginate
                          pageTotal={this.props.TransferConfirmationLists.total}
                          initialPage={page.page - 1}
                          pageRangeDisplayed={currentPage.selected}
                          onPageChange={this.handlePageClick}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
