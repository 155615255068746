import { coreSocketState } from "./constants";
import { combineReducers } from "redux";
import * as socketAction from "./actionCreator";
import { ActionType, createReducer } from "typesafe-actions";

export type socketActionType = ActionType<typeof socketAction>;

const core = createReducer<ICoreSocketState, socketActionType>(coreSocketState)
  .handleAction(socketAction.connectSocketAction, (state: ICoreSocketState) => {
    return {
      ...state,
      connected: false,
    };
  })
  .handleAction(
    socketAction.connectedSocketAction,
    (state: ICoreSocketState) => {
      return {
        connected: true,
      };
    }
  )
  .handleAction(
    socketAction.connectSocketErrorAction,
    (state: ICoreSocketState) => {
      return {
        connected: false,
      };
    }
  )
  .handleAction(
    socketAction.disconnectSocketAction,
    (state: ICoreSocketState) => {
      return {
        connected: false,
      };
    }
  )
  .handleAction(
    socketAction.disconnectedSocketAction,
    (state: ICoreSocketState) => {
      return {
        connected: false,
      };
    }
  )
  .handleAction(socketAction.logSocket, (state: ICoreSocketState) => {
    return {
      connected: false,
    };
  });
export default combineReducers({
  core,
});
