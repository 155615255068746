import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { financeChildMeAction } from "../actionCreator";
import { fetchGetFinanceChildMe } from "../services";

const getFinanceChildMe = (
  query: IQueryPagination & IV2QueryAgentFinanceChildMe
) => (dispatch: Dispatch) => {
  dispatch(financeChildMeAction.request({}));
  return fetchGetFinanceChildMe(query)
    .then(
      (
        res: AxiosResponse<
          IAPIResponse<IPagination<IV2RespAgentFinanceChildMe>>
        >
      ) => {
        dispatch(financeChildMeAction.success(res));
        return Promise.resolve(res.data.data.totalRecord);
      }
    )
    .catch((error: AxiosError<IAPIResponse>) => {
      dispatch(financeChildMeAction.failure(error));
      return Promise.reject();
    });
};

export { getFinanceChildMe };
