import axios from "axios";
import environment from "../../constants/environment";

const fetchPostOtp = (data: ISendOtpAuthen) => {
  const endpoint = `${
    environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.otp}`;
  return axios.post(endpoint, data);
};

export { fetchPostOtp };
