import { noop } from "lodash";
import React, { Component } from "react";
import { HeadMemberTable } from "../HeadMemberTable";
import { ChildMemberTable } from "../ChildMemberTable";
import { CustomerTable } from "../CustomerTable";

const defaultProps: IUserDetailNoteTableMenuListProps = {
  getHostMeData(){
    noop()
  },
  hostMeData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  hostMeCode:0,
  hostMeError:"",
  hostMeIsFetching:false,
  activeMenu: "",
  locationData: 0,
  onload() {},
 
  financeChildMeCode:0,
  financeChildMeError: "",
  financeChildMeIsFetching: false,
  financeChildMeData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  getFinanceChildMe(){
    noop()
  },
  locationDataAgentId:0,

  customerNoteCode: 0,
  customerNoteError: "",
  customerNoteIsFetching: false,
  customerNoteData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  getCustomerNote() {
    noop();
  },
};

class TableMenuList extends Component<IUserDetailNoteTableMenuListProps> {
  static defaultProps = defaultProps;

  renderTable = (activeMenu: string) => {
    if (activeMenu === "หัวสมาชิก") {
      return (
        <HeadMemberTable
          locationData={this.props.locationData}
          onload={this.props.onload}
          getHostMeData={this.props.getHostMeData}
          hostMeData={this.props.hostMeData}
          hostMeCode={this.props.hostMeCode}
          hostMeError={this.props.hostMeError}
          hostMeIsFetching={this.props.hostMeIsFetching}
        />
      );
    }
    if (activeMenu === "ลูกสมาชิก") {
      return (
        <ChildMemberTable
          locationDataAgentId={this.props.locationDataAgentId}
          onload={this.props.onload}
          financeChildMeCode={this.props.financeChildMeCode}
          financeChildMeError={this.props.financeChildMeError}
          financeChildMeIsFetching={this.props.financeChildMeIsFetching}
          financeChildMeData={this.props.financeChildMeData}
          getFinanceChildMe={this.props.getFinanceChildMe}
        />
      );
    }
    if (activeMenu === "ลูกค้า") {
      return (
        <CustomerTable    
          customerNoteCode={this.props.customerNoteCode}
          customerNoteError={this.props.customerNoteError}
          customerNoteIsFetching={this.props.customerNoteIsFetching}
          customerNoteData={this.props.customerNoteData}
          getCustomerNote={this.props.getCustomerNote}   
        />
      );
    }
  };
  render() {
    const activeMenu = this.props.activeMenu;
    return (
      <div
        className={`table-container ${
          activeMenu === "รายการเคลียร์" && "table-clear"
        }`}
      >
        <table className="col ">{this.renderTable(activeMenu)}</table>
      </div>
    );
  }
}

export default TableMenuList;
