import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { getHostAction } from "../actionCreator";
import { fetchGetHost } from "../services";

const getHost = () => (dispatch: Dispatch) => {
    dispatch(getHostAction.request({}))
    return fetchGetHost()
    .then((res: AxiosResponse<IAPIResponse<IV2GetAgentHost>>) => {
        dispatch(getHostAction.success(res))
    })
    .catch((error: AxiosError<IAPIResponse>) => {
        dispatch(getHostAction.failure(error))
    })
}

export {getHost}