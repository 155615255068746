export const NUMBER_BAN_REQUEST = "NUMBER_BAN_REQUEST"
export const NUMBER_BAN_SUCCESS = "NUMBER_BAN_SUCCESS"
export const NUMBER_BAN_FAILURE = "NUMBER_BAN_FAILURE"

export const CANCEL_NUMBER_BAN_REQUEST = "CANCEL_NUMBER_BAN_REQUEST"
export const CANCEL_NUMBER_BAN_SUCCESS = "CANCEL_NUMBER_BAN_SUCCESS"
export const CANCEL_NUMBER_BAN_FAILURE = "CANCEL_NUMBER_BAN_FAILURE"

export const numberBanState : ReducerState<IV2GetRateReduceLotteryAgent> = {
    isFetching: false,
    code: 0,
    data: {
        ratereducefacId : 0,
        ratereducefacAgentLotsetId : 0,
        name : "",
        ratereducefacUserAgentId : 0,
        ratereduceThreeUp : [],
        ratereduceThreeToast : [],
        ratereduceTwoUp : [],
        ratereduceTwoDown : [],
        ratereduceRunUp : [],
        ratereduceRunDown : [],
        ratereducefacBannedAvg : 0,
        ratereducefacCreatedAt : 0,
        ratereducefacUpdatedAt : 0,
    },
    error:"",
}