import React, { FC } from "react";
import colors from "constants/colors";
import "./badge.style.scss";

const defaultProps: IBadge = {
  text: "",
  color: colors.SECONDARY_TEXT,
  backgroundColor: colors.SECONDARY_BG,
  borderColor: "",
  borderStyle: "hidden",
  textColor: "",
  boxShadow: ''
};

const Badge: FC<IBadge> = (props) => {
  const {
    text,
    color,
    renderText,
    backgroundColor,
    borderColor,
    borderStyle,
    textColor,
    customBadgeContainerClass,
    customTextClass,
    boxShadow
  } = props;

  const style = {
    color,
    backgroundColor,
    borderColor,
    borderStyle,
    textColor,
    boxShadow
  };

  const textRender = () => {
    if (typeof renderText === "function") {
      return renderText();
    }
    return text;
  };

  return (
    <div className={`badge-container ${customBadgeContainerClass}`} style={style}>
      <h6 className={customTextClass} style={textColor !== "" ? { color: textColor } : { color: color }}>{textRender()}</h6>
    </div>
  );
};

Badge.defaultProps = defaultProps;

export default Badge;
