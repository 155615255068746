import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { loaderAction } from "reduxs/Loader/actionCreator";
import { getBetGroupAction, getV2AgentBetAction, postV2AgentBetAction } from "../actionCreator";
import { fetchV2GetBetGroup, getV2AgentBet, postV2AgentBet } from "../services";

const getGetBetGroup = (query: IV2QueryAgentLotteryBetGroup | IQueryPagination) => async (dispatch: Dispatch) => {
  // dispatch(loaderAction.request(true));
  dispatch(getBetGroupAction.request({}));
  await fetchV2GetBetGroup(query)
    .then(
      (res: AxiosResponse<IAPIResponse<IPagination<IV2RespAgentLotteryBetGroup>>>) => {
        dispatch(loaderAction.success(false));
        dispatch(getBetGroupAction.success(res));
      }
    )
    .catch((error: AxiosError<IAPIResponse>) => {
      dispatch(loaderAction.success(false));
      dispatch(getBetGroupAction.failure(error));
    });
};


const postAgentBet = (querys: IV2PostAgentLotteryBet[]) => async (dispatch: Dispatch) => {
  // dispatch(loaderAction.request(true));
  dispatch(postV2AgentBetAction.request({}));
  await postV2AgentBet(querys)
    .then(
      (res: AxiosResponse<IAPIResponse<IV2RespAgentLotteryBet>>) => {
        dispatch(loaderAction.success(false));
        dispatch(postV2AgentBetAction.success(res));
      }
    )
    .catch((error: AxiosError<IAPIResponse>) => {
      dispatch(loaderAction.success(false));
      dispatch(postV2AgentBetAction.failure(error));
    });
};

const getAgentBet = (querys: IQueryPagination | IV2QueryAgentLotteryBet) => async (dispatch: Dispatch) => {
  // dispatch(loaderAction.request(true));
  dispatch(getV2AgentBetAction.request({}));
  await getV2AgentBet(querys).then(
    (res: AxiosResponse<IAPIResponse<IPagination<IV2RespAgentLotteryBetMe>>>) => {
      dispatch(loaderAction.success(false));
      dispatch(getV2AgentBetAction.success(res));
    }
  )
    .catch((error: AxiosError<IAPIResponse>) => {
      dispatch(loaderAction.success(false));
      dispatch(getV2AgentBetAction.failure(error));
    });
};

const clearGetAgentBet = () => (dispatch: Dispatch) =>
    dispatch(getV2AgentBetAction.cancel({}))


export { getGetBetGroup, postAgentBet, getV2AgentBet, getAgentBet, clearGetAgentBet };
