import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { getGameAction } from "../actionCreator";
import { fetchGetGame } from "../services";
import { fetchGetYegeeGameList } from "../yegee/services";

const getGame = (slugName: TLottoSlug, date: string, round: string) => async (
  dispatch: Dispatch
) => {
  dispatch(getGameAction.request({}));
  const getGame =
    slugName === "LOTTER_YEGEE"
      ? fetchGetYegeeGameList({ date, round })
      : fetchGetGame(slugName);
  return getGame
    .then((res: AxiosResponse<IAPIResponse<ILottoGame>>) => {
      dispatch(getGameAction.success(res));
    })
    .catch((error: AxiosError<IAPIResponse<ILottoGame>>) =>
      dispatch(getGameAction.failure(error))
    );
};

export { getGame };
