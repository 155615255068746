import { connect } from "react-redux";
import CreditsContainer from "./Credits.container";
import creditsSelector from "../../reduxs/credits/selector";
import { getCredits, clearCredits } from "../../reduxs/credits/credits/action";

const mapStateToProps = (state: RootReducers): ICreditContainerProps => {
  const credits = creditsSelector.credits(state);
  return {
    credits: credits.data,
    getCreditCode: credits.code,
    getCreditError: credits.error,
    getCreditIsFetching: credits.isFetching,
  };
};

const mapDispatchToProps: ICreditContainerActionProps = {
  getCredits: getCredits,
  clearCredits: clearCredits,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditsContainer);
