import { string, object } from "yup";
import * as Yup from "yup";
import {
  NUMBER,
  ENG,
  LOWERCASE,
  SPECIAL_ENG,
  NO_WHITESPACE,
  SPECIAL_ENG_AND_SPECIAL_CHAR,
  // ENG_THAI_AND_SPACE,
  // CHECK_SPECIAL_CHARACTERS,
  CHECK_SPECIAL_CHARACTERS_SPACE,
} from "constants/regex";
import { isEmpty } from "lodash";

const constants = {
  name: "กรุณากรอกชื่อผู้ใช้งาน",
  nameMinLength: "กรุณากรอกชื่อผู้ใช้ 6 ตัวขึ้นไป",

  username: "กรุณากรอกชื่อผู้ใช้",
  usernameMinLength: "กรุณากรอกรหัสผู้ใช้ 6 ตัวขึ้นไป",

  otp: "กรุณากรอก OTP",
  otp_numberOnly_length: "OTP ต้องเป็นตัวเลข 6 หลักเท่านั้น",

  phoneNumber: "กรุณากรอกหมายเลขโทรศัพท์ 10 หลัก",

  onlyNumber: "กรุณากรอกเฉพาะตัวเลขเท่านั้น",
  lowercase: "กรุณากรอกเฉพาะตัวหนังสือตัวเล็ก",
  englishRequire: "กรุณากรอกเฉพาะตัวหนังสือภาษาอังกฤษและตัวเลข",
  englishCharRequire: "กรอกเฉพาะภาษาอังกฤษ ตัวเลข หรือตัวอักษรพิเศษ !@#$%&*",
  line: "กรุณากรอกไอดีไลน์",
  lineMinLength: "กรุณากรอกไอดีไลน์ 4 ตัวขึ้นไป",

  credit_limit: "กรุณากรอกวงเงิน",

  permission: "กรุณาเลือกระดับ",

  helperName: "กรุณากรอกชื่อช่วยจำ",

  password: "กรุณากรอกรหัสผ่าน",
  passwordMin: "รหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัว",
  passwordMax: "รหัสผ่านต้องมีความยาวน้อยกว่า 128 ตัว",

  noSpace: "รหัสผ่านหรือยืนยันรหัสผ่านต้องไม่มีช่องว่าง",

  confirm_password: "กรุณากรอกยืนยันรหัสผ่าน",
  confirm_password_same: "ยืนยันรหัสผ่านต้องตรงกับรหัสผ่าน",
  confirmPasswordMin: "ยืนยันรหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัว",
  confirmPasswordMax: "ยืนยันรหัสผ่านต้องมีความยาวน้อยกว่า 128 ตัว",

  limitCredit: "กรุณากรอกเครดิตที่จำกัดไว้",

  minBet: "กรุณากรอกจำนวนเงินแทงต่ำสุด",

  maxBet: "กรุณากรอกจำนวนเงินแทงสูงสุด",

  commission: "กรุณากรอกค่าคอมมิชชั่น",

  bankName: "กรุณากรอกชื่อบัญชีธนาคาร",

  bankNo: "กรุณากรอกหมายเลขบัญชีธนาคาร",

  bank: "กรุณาเลือกธนาคาร",

  fixSharePercentageLessThanHostSharePercentage:
    "บังคับสู้ต้องไม่มากกว่าถือสู้",

  betMinOverLimit: "แทงต่ำสุดต้องน้อยกว่าลิมิตที่กำหนดไว้",
  betMaxOverLimit: "แทงสูงสุดต้องน้อยกว่าลิมิตที่กำหนดไว้",
  comMaxOverLimit: "ค่าคอมมิชชั่นต้องน้อยกว่าลิมิตที่กำหนดไว้",
  creditOverLimit: "เครดิตต้องน้อยกว่าลิมิตที่กำหนดไว้",
  hostSharePercentageOverLimit: "ถือสู้ต้องอยู่ระหว่างค่าที่กำหนด",
  hostSharePercentageRequired: "กรุณากรอกเปอร์เซ็นต์ถือสู้",
  fixSharePercentageRequired: "กรุณากรอกเปอร์เซ็นต์บังคับสู้",
  childLotsetBetMinRequired: "กรุณากรอกจำกัดแทงต่ำสุด",
  childLotsetBetMaxRequired: "กรุณากรอกจำกัดแทงสูงสุด",
  childLotsetDisRequired: "กรุณากรอกส่วนลด",
  childLotsetBetRateRequired: "กรุณากรอกอัตราจ่าย",
};

const bankLength = (bankType: string) => {
  return `กรุณากรอกเลขบัญชีธนาคารให้ครบ ${bankType === "GSB" || bankType === "BAAC" ? "12" : "10"} ตัว`;
};

const findFirstRepeatedChar =(s:string) =>{ 
  // var ot = "";
  for(let i=0; i<s.length; i++){

    if(s[i]===" " && s[i+1] === " " )  { 
       return true;
     }  
    //   else if(s.indexOf(s[i], i+1) !== -1){
    //    ot = s[i]; 
    //  }
   }

     return false; 

} 

const schema = (
  hostName: string,
  meSettingData: IV2GetAgentMeSetting,
  meData: IV2GetAgentMe
) => {
  return object().shape({
    name: string()
      .required(constants.name)
      .matches(CHECK_SPECIAL_CHARACTERS_SPACE, "ต้องไม่ใช้ตัวอักษรพิเศษ")
      .test("", "ห้ามใส่ช่องว่าง ขึ้นต้น, ตัวสุดท้าย หรือห้ามมีช่องว่างติดกัน 2 ช่อง", function (val) {
        if (!isEmpty(val)) {
          if (val![0] === ' ' || val![val!.length] === ' ') {
            return false
          } else if (findFirstRepeatedChar(String(val))) {
            return false
          } else {
            return true
          }
        } else {
          return false
        }
      })
      .max(20, `${constants.name}ไม่เกิน 20 ตัว`),

    username: string()
      .min(
        6 - hostName.length,
        `กรุณากรอกรหัสผู้ใช้ ${6 - hostName.length} ตัวขึ้นไป`
      )
      .matches(ENG, constants.englishRequire)
      .matches(LOWERCASE, constants.lowercase)
      .required(constants.username),

    otp: string()
      .length(6, constants.otp_numberOnly_length)
      .matches(NUMBER, constants.otp_numberOnly_length)
      .when("phoneNumber",{
        is:(val:string)=>(val ? true:false),
        then:Yup.string().required(constants.otp)
      })
    ,
    phoneNumber: string()
      .length(10, constants.phoneNumber)
      .matches(NUMBER, constants.onlyNumber),

    line: string()
      .matches(SPECIAL_ENG, constants.englishRequire)
      .min(4, `${constants.line} 4 ตัวขึ้นไป`)
      .max(20, `${constants.line}ไม่เกิน 20 ตัว`),

    creditLimit: string()
      .required(constants.credit_limit)
      .test("", constants.creditOverLimit, (val) => {
        return Number(val) <= meData.credit;
      }),

    permission: string().test(
      "",
      constants.permission,
      (val) => val !== "PleaseChoose"
    ),

    password: string()
      .required(constants.password)
      .matches(SPECIAL_ENG_AND_SPECIAL_CHAR, constants.englishCharRequire)
      .test("", constants.noSpace, (val: any) => {
        return val?.match(NO_WHITESPACE);
      })
      .min(6, constants.passwordMin)
      .max(128, constants.passwordMax),

    confirmPassword: string()
      .required(constants.confirm_password)
      .matches(SPECIAL_ENG_AND_SPECIAL_CHAR, constants.englishCharRequire)
      .test("", constants.noSpace, (val: any) => {
        return val?.match(NO_WHITESPACE);
      })
      .min(6, constants.confirmPasswordMin)
      .max(128, constants.confirmPasswordMax)
      .test("", constants.confirm_password_same, (val, ctx) => {
        return val === ctx.parent.password;
      }),

    betMinLimit: string()
      .required(constants.minBet)
      .test("", constants.betMinOverLimit, (val, ctx) => {
        return Number(val) <= Number(ctx.parent.betMaxLimit)
      }),

    betMaxLimit: string()
      .required(constants.maxBet)
      .test("", constants.betMaxOverLimit, (val) => {
        return Number(val) <= meSettingData.setBetMaxLimit;
      }),

    com: string()
      .required(constants.commission),
      // .test("", constants.comMaxOverLimit, (val) => {
      //   return
      //   // return Number(val) <= meSettingData.setComPercentage;
      // }
    
 
    bankNumber: string()
    .when("bankType", (bankType: TBankType| 'PleaseChoose', schema: any) => {
    return schema.test({
      test: (bankNumber: string) => {
        if(bankType!=='PleaseChoose'){
          if (!bankNumber) {
            return true;
          }
          if (bankType === "GSB" || bankType === "BAAC") {
            return bankNumber.length === 12;
          }
          return bankNumber.length === 10;
        }else {
          return true
        }
      },
      message: bankLength(bankType),
      });
    }),

    minBet: string(),

    hostSharePercentage: string()
      // .required(constants.hostSharePercentageRequired)
      .test("", "error", (val, ctx) => {
        return (
          (Number(val) || 0) >= Number(ctx.parent.mustReciv) &&
          (Number(val) || 0) <= Number(ctx.parent.meShare)
        );
      }),

    fixSharePercentage: string()
      // .required(constants.fixSharePercentageRequired)
      .test("", "error", (val, ctx) => {
        return (Number(val) || 0) <= Number(ctx.parent.hostSharePercentage);
      }),

    childLotsetDisThreeUp: string().required(constants.childLotsetDisRequired),
    childLotsetDisThreeToast: string().required(
      constants.childLotsetDisRequired
    ),
    childLotsetDisTwoUp: string().required(constants.childLotsetDisRequired),
    childLotsetDisTwoDown: string().required(constants.childLotsetDisRequired),
    childLotsetDisRunUp: string().required(constants.childLotsetDisRequired),
    childLotsetDisRunDown: string().required(constants.childLotsetDisRequired),

    childLotsetBetMinThreeUp: string()
      .required(constants.childLotsetBetMinRequired)
      .test("", "error", (val, ctx) => {
        return (
          Number(val) <= Number(ctx.parent.childLotsetBetMaxThreeUp) &&
          Number(val) >= Number(ctx.parent.betMinLimit)
        );
      }),
    childLotsetBetMinThreeToast: string()
      .required(constants.childLotsetBetMinRequired)
      .test("", "error", (val, ctx) => {
        return (
          Number(val) <= Number(ctx.parent.childLotsetBetMaxThreeToast) &&
          Number(val) >= Number(ctx.parent.betMinLimit)
        );
      }),
    childLotsetBetMinTwoUp: string()
      .required(constants.childLotsetBetMinRequired)
      .test("", "error", (val, ctx) => {
        return (
          Number(val) <= Number(ctx.parent.childLotsetBetMaxTwoUp) &&
          Number(val) >= Number(ctx.parent.betMinLimit)
        );
      }),
    childLotsetBetMinTwoDown: string()
      .required(constants.childLotsetBetMinRequired)
      .test("", "error", (val, ctx) => {
        return (
          Number(val) <= Number(ctx.parent.childLotsetBetMaxTwoDown) &&
          Number(val) >= Number(ctx.parent.betMinLimit)
        );
      }),
    childLotsetBetMinRunUp: string()
      .required(constants.childLotsetBetMinRequired)
      .test("", "error", (val, ctx) => {
        return (
          Number(val) <= Number(ctx.parent.childLotsetBetMaxRunUp) &&
          Number(val) >= Number(ctx.parent.betMinLimit)
        );
      }),
    childLotsetBetMinRunDown: string()
      .required(constants.childLotsetBetMinRequired)
      .test("", "error", (val, ctx) => {
        return (
          Number(val) <= Number(ctx.parent.childLotsetBetMaxRunDownAPI) &&
          Number(val) >= Number(ctx.parent.betMinLimit)
        );
      }),

    childLotsetBetMaxThreeUp: string()
      .required(constants.childLotsetBetMaxRequired)
      .test("", "error", (val, ctx) => {
        return (Number(val) <= Number(ctx.parent.betMaxLimit) && Number(val) <= meSettingData.setBetMaxLimit);
      }),
    childLotsetBetMaxThreeToast: string()
      .required(constants.childLotsetBetMaxRequired)
      .test("", "error", (val, ctx) => {
        return (Number(val) <= Number(ctx.parent.betMaxLimit) && Number(val) <= meSettingData.setBetMaxLimit);
      }),
    childLotsetBetMaxTwoUp: string()
      .required(constants.childLotsetBetMaxRequired)
      .test("", "error", (val, ctx) => {
        return (Number(val) <= Number(ctx.parent.betMaxLimit) && Number(val) <= meSettingData.setBetMaxLimit);
      }),
    childLotsetBetMaxTwoDown: string()
      .required(constants.childLotsetBetMaxRequired)
      .test("", "error", (val, ctx) => {
        return (Number(val) <= Number(ctx.parent.betMaxLimit) && Number(val) <= meSettingData.setBetMaxLimit);
      }),
    childLotsetBetMaxRunUp: string()
      .required(constants.childLotsetBetMaxRequired)
      .test("", "error", (val, ctx) => {
        return (Number(val) <= Number(ctx.parent.betMaxLimit) && Number(val) <= meSettingData.setBetMaxLimit);
      }),
    childLotsetBetMaxRunDown: string()
      .required(constants.childLotsetBetMaxRequired)
      .test("", "error", (val, ctx) => {
        return (Number(val) <= Number(ctx.parent.betMaxLimit) && Number(val) <= meSettingData.setBetMaxLimit);
      }),

    childLotsetBetRateThreeUp: string()
      .required(constants.childLotsetBetRateRequired)
      .test("", "error", (val, ctx) => {
        return Number(val) <= Number(ctx.parent.childLotsetBetRateThreeUpAPI);
      }),
    childLotsetBetRateThreeToast: string()
      .required(constants.childLotsetBetRateRequired)
      .test("", "error", (val, ctx) => {
        return (
          Number(val) <= Number(ctx.parent.childLotsetBetRateThreeToastAPI)
        );
      }),
    childLotsetBetRateTwoUp: string()
      .required(constants.childLotsetBetRateRequired)
      .test("", "error", (val, ctx) => {
        return Number(val) <= Number(ctx.parent.childLotsetBetRateTwoUpAPI);
      }),
    childLotsetBetRateTwoDown: string()
      .required(constants.childLotsetBetRateRequired)
      .test("", "error", (val, ctx) => {
        return Number(val) <= Number(ctx.parent.childLotsetBetRateTwoDownAPI);
      }),
    childLotsetBetRateRunUp: string()
      .required(constants.childLotsetBetRateRequired)
      .test("", "error", (val, ctx) => {
        return Number(val) <= Number(ctx.parent.childLotsetBetRateRunUpAPI);
      }),
    childLotsetBetRateRunDown: string()
      .required(constants.childLotsetBetRateRequired)
      .test("", "error", (val, ctx) => {
        return Number(val) <= Number(ctx.parent.childLotsetBetRateRunDownAPI);
      }),
  }
  ,[ 
  ["bankName", "bankType"],
  ["bankNumber", "bankType"],
  ["bankName", "bankNumber"]]
  );
};

export default schema;
