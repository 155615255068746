export const MAIN_REQUEST = 'MAIN_REQUEST'
export const MAIN_SUCCESS = 'MAIN_SUCCESS'
export const MAIN_FAILURE = 'MAIN_FAILURE'
export const MAIN_CANCEL = 'MAIN_CANCEL'

export const NEWS_REQUEST = 'NEWS_REQUEST'
export const NEWS_SUCCESS = 'NEWS_SUCCESS'
export const NEWS_FAILURE = 'NEWS_FAILURE'

export const RESETPASSWORD_REQUEST = 'RESETPASSWORD_REQUEST'
export const RESETPASSWORD_SUCCESS = 'RESETPASSWORD_SUCCESS'
export const RESETPASSWORD_FAILURE = 'RESETPASSWORD_FAILURE'

export const mainState: ReducerState<IMain> = {
    isFetching: false,
    code: 0,
    data: {
        username: "host name",
        permission: "MEMBER",
        line: "",
        status: "",
        childCount: 0,
        currency: "",
        betResultTotal: 0,
        betWaitTotal: 0,
        maxBet: 0,
        minBet: 0,
        childCreditTotal: 0,
        credit: 0,
        creditLimit: 0
    },
    error: ''
}

export const newsState: ReducerState<IPagination<INews>> = {
    isFetching: false,
    code: 0,
    data: {
        totalRecord:0,
        page: 1,
        limit: 10,
        total: 0,
        dataList: []
    },
    error: ''
}

export const resetPasswordState: ReducerState<IAPIResponse<object>> = {
    isFetching: false,
    code: 0,
    data: {
        data: {},
        code: 0,
        devMessage: ""
    },
    error: ''
}