import { AxiosError, AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { ActionType, createReducer } from "typesafe-actions";
import { responseConverter } from "../../utils";
import * as lottoAction from "./actionCreator";
import { listState, listStateV2, gameState } from "./constants";
import bet from "./bet/reducers";
import yegee from "./yegee/reducers";
import { isArray } from "lodash";

export type BetActionType = ActionType<typeof lottoAction>;

const list = createReducer<
  ReducerState<IResponseAgentLotteryList[]>,
  BetActionType
>(listState)
  .handleAction(
    lottoAction.getLotteryListAction.request,
    (state: ReducerState<IResponseAgentLotteryList[]>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    lottoAction.getLotteryListAction.success,
    (
      state: ReducerState<IResponseAgentLotteryList[]>,
      action: BetActionType
    ) => {
      const payload: AxiosResponse<IAPIResponse<IResponseAgentLotteryList[]>> =
        action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
      };
    }
  )
  .handleAction(
    lottoAction.getLotteryListAction.failure,
    (
      state: ReducerState<IResponseAgentLotteryList[]>,
      action: BetActionType
    ) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );

const listV2 = createReducer<
  ReducerState<IV2RespAgentLotterySchedule[]>,
  BetActionType
>(listStateV2)
  .handleAction(
    lottoAction.getV2LotteryListAction.request,
    (state: ReducerState<IV2RespAgentLotterySchedule[]>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    lottoAction.getV2LotteryListAction.success,
    (
      state: ReducerState<IV2RespAgentLotterySchedule[]>,
      action: BetActionType
    ) => {
      const payload: AxiosResponse<IAPIResponse<IV2RespAgentLotterySchedule[]>> =
        action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
      };
    }
  )
  .handleAction(
    lottoAction.getV2LotteryListAction.failure,
    (
      state: ReducerState<IV2RespAgentLotterySchedule[]>,
      action: BetActionType
    ) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );

const game = createReducer<ReducerState<ILottoGame>, BetActionType>(gameState)
  .handleAction(
    lottoAction.getGameAction.request,
    (state: ReducerState<ILottoGame>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    lottoAction.getGameAction.success,
    (state: ReducerState<ILottoGame>, action: BetActionType) => {
      const payload: AxiosResponse<IAPIResponse<any>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      const data = isArray(payload.data.data)
        ? payload.data.data[0]
        : payload.data.data;
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: data,
      };
    }
  )
  .handleAction(
    lottoAction.getGameAction.failure,
    (state: ReducerState<ILottoGame>, action: BetActionType) => {
      const payload: AxiosError<IAPIResponse<ILottoGame>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );

export default combineReducers({
  list,
  listV2,
  bet,
  yegee,
  game,
});
