import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { postOwnerSettingLotteryUpdateAction } from "../actionCreator";
import { fetchPostOwnerSettingLotteryUpdate } from "../services";

const postOwnerSettingLotteryUpdate = (data: Array<IV2LotterySetting>) => (dispatch: Dispatch) => {
    dispatch(postOwnerSettingLotteryUpdateAction.request({}))
    return fetchPostOwnerSettingLotteryUpdate(data)
    .then((res: AxiosResponse<{}>) => {
        dispatch(postOwnerSettingLotteryUpdateAction.success(res))
        return Promise.resolve()
    })
    .catch((error: AxiosError<IAPIResponse>) => {
        dispatch(postOwnerSettingLotteryUpdateAction.failure(error))
        return Promise.reject()
    })
}

export { postOwnerSettingLotteryUpdate }