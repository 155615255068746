import { isEmpty } from "lodash";
import React, { useEffect, SFC } from "react";
import TreeChart from "d3-org-chart";
import * as d3 from 'd3';
type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: IOrgChartProps = {
  listDiagram: []
};

const OrgChart: SFC<IOrgChartProps & DefaultProps> = (props) => {
  const { listDiagram } = props
  useEffect(() => {
    if(!isEmpty(listDiagram)) {
      d3.json(
        'https://gist.githubusercontent.com/bumbeishvili/dc0d47bc95ef359fdc75b63cd65edaf2/raw/c33a3a1ef4ba927e3e92b81600c8c6ada345c64b/orgChart.json'
      ).then((data: any) => {
        new TreeChart()
          .marginTop(200)
          .container(".chart-container")
          .data(listDiagram)
          .svgWidth(window.innerWidth)
          .svgHeight(window.innerHeight)
          .initialZoom(0.6)
          .onNodeClick((d: any) => {
            console.log(d + " node clicked");
          })
          .render();
      })
    }
  }, [listDiagram])
  return (
    <>
      <div
        className="chart-container"
        style={{ paddingTop: "0px", height: "1200px" }}
      >
      </div>
    </>
  );
}
export default OrgChart