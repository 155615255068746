import { AxiosError, AxiosResponse } from 'axios'
import { responseConverter } from '../../utils'
import { initialAutocompleteState } from "./constants";
import { combineReducers } from "redux";
import * as autocompleteAction from "./actionCreator";
import { ActionType, createReducer } from "typesafe-actions";

export type AutocompleteActionType = ActionType<typeof autocompleteAction>;
const autocompleteReducers = createReducer<ReducerState<IPagination<IAutocomplete>>, AutocompleteActionType>(initialAutocompleteState)
  .handleAction(autocompleteAction.autocompleteAction.request, (state: ReducerState<IPagination<IAutocomplete>>) => {
    return {
      ...state,
      isFetching: true,
    }
  })
  .handleAction(autocompleteAction.autocompleteAction.success, (state: ReducerState<IPagination<IAutocomplete>>, action: AutocompleteActionType) => {
    const payload: AxiosResponse<IAPIResponse<IPagination<IAutocomplete>>> = action.payload
    const convertedResponse = responseConverter.getMessage(payload)
    return {
      ...state,
      isFetching: false,
      code: convertedResponse.code,
      data: payload.data.data,
    }
  })
  .handleAction(autocompleteAction.autocompleteAction.failure, (state: ReducerState<IPagination<IAutocomplete>>, action: AutocompleteActionType) => {
    const payload: AxiosError<IAPIResponse> = action.payload
    const convertedResponse = responseConverter.getMessage(payload)
    return {
      ...state,
      isFetching: false,
      code: convertedResponse.code,
      error: convertedResponse.message,
    }
  })

export default combineReducers({
  autocompleteReducers,
});
