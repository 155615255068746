import * as socketAction from "reduxs/socket/actionCreator";
import { MiddlewareAPI, Dispatch } from "redux";
import { transformer } from "utils";
import { RootAction } from "typings/reduxs/Actions";
import { getType } from "typesafe-actions";
import project from "constants/environment";
import SocketIOClient from "socket.io-client";
import { updateDataYegeeGameList } from "reduxs/lotto/yegee/gameList/action";
import { updateYegeeSum } from 'reduxs/lotto/yegee/sum/action';
import { lottoAction } from "reduxs/actions";
import { updatePlayedYegeeList } from "reduxs/lotto/yegee/playedList/action";

const onConnect = (handlerStore: MiddlewareAPI<Dispatch, RootReducers>) => (
  event: any
) => {
  handlerStore.dispatch(socketAction.connectedSocketAction());
};

const onDisconnect = (
  handleStore: MiddlewareAPI<Dispatch, RootReducers>
) => () => {
  handleStore.dispatch(socketAction.disconnectedSocketAction());
};

const onError = (handlerStore: MiddlewareAPI<Dispatch, RootReducers>) => (
  error: any
) => {
  handlerStore.dispatch(socketAction.connectSocketErrorAction(error));
};

const onUpdateYegeeGame = (
  handlerStore: MiddlewareAPI<Dispatch, RootReducers>
) => (response: any) => {
  const responseYegeeGameList: APIResponse<ILottoGame[]> =
    typeof response === "string" ? JSON.parse(response) : response;
  const transformed = transformer.camelcaseTransform(
    responseYegeeGameList
  ) as IAPIResponse<ILottoGame[]>;

  updateDataYegeeGameList(transformed)(handlerStore.dispatch)

};

const onUpdateYegeeSum = (handlerStore: MiddlewareAPI<Dispatch, RootReducers>) =>
  (response: any) => {
    const responseYegeeSum: APIResponse<string> = (typeof response === "string")
      ? JSON.parse(response) : response
    const transformed = transformer.camelcaseTransform(responseYegeeSum) as IAPIResponse<string>

    updateYegeeSum(transformed)(handlerStore.dispatch)

  }

const onUpdatePlayedYegeeList = (handlerStore: MiddlewareAPI<Dispatch, RootReducers>) =>
  (response: any) => {
    const responsePlayedYegeeList: APIResponse<IYegeePlay[]> = (typeof response === "string")
    ? JSON.parse(response) : response
    const transformed = transformer.camelcaseTransform(responsePlayedYegeeList) as APIResponse<IYegeePlay[]>
    
    updatePlayedYegeeList(transformed)(handlerStore.dispatch)
  }
  

let socket: SocketIOClient.Socket | null = null;

const socketMiddleware = (store: MiddlewareAPI<Dispatch, RootReducers>) => (
  next: Dispatch
) => (action: RootAction) => {
  if (store.getState().authentication.token.accessToken) {
    switch (action.type) {
      case getType(socketAction.connectSocketAction):
        socket = SocketIOClient(
          project.environments[project.environmentName].endpoint.socket,
          {
            query: { token: store.getState().authentication.token.accessToken },
          }
        );

        if (socket.connected) {
          socket.disconnect();
        }
        socket.connect();
        socket.on("connect", onConnect(store));
        socket.on("disconnect", onDisconnect(store));
        socket.on("error", onError(store));

        socket.on("yegee_game", onUpdateYegeeGame(store));
        break;
      case getType(socketAction.disconnectSocketAction):
        if (socket) {
          if (socket.connected) {
            socket.disconnect();
          }
        }
        break;
      case getType(lottoAction.listenYegeeSumSocketAction):
        socket?.on(`yegee_play_sum_${action.payload.date}${action.payload.round}`, onUpdateYegeeSum(store))
        break;
      case getType(lottoAction.unlistenYegeeSumSocketAction):
        store.dispatch(lottoAction.clearYegeeSumAction({}))
        socket?.off(`yegee_play_sum_${action.payload.date}${action.payload.round}`)
        break;
      case getType(lottoAction.listenPlayedYeegeListSocketAction):
        socket?.on(`yegee_play_list_${action.payload.date}${action.payload.round}`, onUpdatePlayedYegeeList(store))
        break;
      case getType(lottoAction.unlistenPlayedYeegeListSocketAction):
        store.dispatch(lottoAction.clearPlayedYegeeListAction({}))
        socket?.off(`yegee_play_list_${action.payload.date}${action.payload.round}`)
        break;
      default:
        return next(action);
    }
  }

  return next(action);
};

export default socketMiddleware;
