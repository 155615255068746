import {
  CODE_TO_NAME,
  CODE_TO_TYPENAME,
  STATUS_CHILD_BET,
} from "constants/variables";
import { get, isEmpty, noop } from "lodash";
import React, { Component } from "react";
import { dateThai, number } from "utils";
import "./memberBetList.style.scss";
import { Badge } from "components/Badge";
import color from "constants/colors";
import { Panginate } from "components/Panginate";
import { ErrorModal } from "components/Modal";
import { responseCode, responseMessage } from "constants/response";
import { Dropdown, LoadingCircle } from 'components'
import colors from "constants/colors"
import point from "assets/images/global/icon/point.png"
import { transformer } from "utils";
import {  includes } from "lodash"

// import routes from "constants/routes";

const constants = {
  title: "รายการเดิมพันของลูกสมาชิก",
  headTable: [
    { codeName: "หวย", textalign: "text-left pd-frist" },
    { codeName: "ประเภท", textalign: "text-center" },
    { codeName: "เลข", textalign: "text-center" },
    { codeName: "อัตราจ่าย", textalign: "text-right" },
    { codeName: "ถือสู้", textalign: "text-center"  ,style:{paddingLeft:`30px`}},
    { codeName: "แบ่งหุ้น", textalign: "text-right" },
    { codeName: "ยอดเดิมพัน", textalign: "text-right" },
    { codeName: "ส่วนลด", textalign: "text-right"},
    { codeName: "ยอดสุทธิ", textalign: "text-center"  ,style:{paddingLeft:`40px`}},
    { codeName: "ค่าคอม", textalign: "text-center" },
    { codeName: "แพ้/ชนะ", textalign: "text-center" },
    { codeName: "เก็บของ", textalign: "text-center" },
    { codeName: "เวลา", textalign: "text-center" },
    { codeName: "\u00A0", textalign: "text-center" },
  ],
  list: "(จำนวนรายการ)",
  empty: "ไม่มีข้อมูล...",
  rowPerPage: "จำนวนรายการต่อหน้า :",
  memberInfo: "ข้อมูลสมาชิก"
};

const defaultProps: IMemberBetListProps = {
  childBetData: {
    dataList: [],
    limit: 10,
    page: 1,
    total: 0,
    totalRecord: 0,
  },
  getChildBet() {
    noop();
  },
  childBetCode: 0,
  childBetIsFetching: false,
  queryStoreFinance: {
    limit: 10,
    page: 1,
    rowIndex: 0,
  },
  financeChildDataState: [],
  clickRoute(){
    noop()
  },
};

class MemberBetListComponent extends Component<
  IMemberBetListProps,
  IMemberBetListState
> {
  static defaultProps = defaultProps;
  state: IMemberBetListState = {
    dataQuery: {
      limit: 10,
      page: 1,
      childAgentShareId:0,
    },
    currentPage: 1,
    currentRowPerPage: 10,
    startPage: 1,
    endPage: 0,
  };

  componentDidMount() {
    const queryObj: any = transformer.queryToObject();
    this.props.getChildBet({childAgentShareId: queryObj.agentShareId,page:1,limit:10})
  }

  componentDidUpdate(prevProps: IMemberBetListProps) {
    if (
      prevProps.childBetIsFetching !== this.props.childBetIsFetching &&
      !this.props.childBetIsFetching
    ) {
      if (this.props.childBetCode === responseCode.OK) {
        if (isEmpty(this.props.childBetData.dataList)) {
          this.setState({ startPage: 0, endPage: 0 });
        } else {
          this.setState({
            startPage:
              this.state.currentRowPerPage * (this.state.dataQuery.page - 1) +
              1,
            endPage:
              this.state.currentRowPerPage * (this.state.currentPage + 1) >=
              this.props.childBetData.totalRecord
                ? this.props.childBetData.totalRecord
                : this.state.currentRowPerPage * this.state.dataQuery.page,
          });
        }
      } else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            this.props.childBetCode,
            responseMessage()[0]
          ),
        });
      }
    }
  }

  getNextOrPreviousData = () => {
    this.props.getChildBet({childAgentShareId :this.props.financeChildDataState[0].agentShareId , page:1, limit:this.state.dataQuery.limit })
  }

  renderTable = () => {
    const { dataList } = this.props.childBetData;

    const headTable = (
      <thead>
        <tr className="tableHead">
          {constants.headTable.map((data, index: number) => (
            <th key={index} className={data.textalign} style={data.style}>
              <span>{data.codeName}</span>
            </th>
          ))}
        </tr>
      </thead>
    );

    const loadingTable= (
      <tr>
        <td className="text-center" colSpan={15}>
        <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
        </td>
      </tr>
    )
  
    
    const tableBody = (
      <tbody>
        {isEmpty(dataList) ? (
          <tr>
            <td className="text-center color-row p-2" colSpan={15}>
              {constants.empty}
            </td>
          </tr>
        ) : (
          dataList.map((data, index: number) => {
            const stName = get(
              STATUS_CHILD_BET(data.totalBet, data.rate),
              `${data.status}.status`,
              ""
            );
            const stColor = get(
              STATUS_CHILD_BET(data.totalBet, data.rate),
              `${data.status}.color`,
              ""
            );
            return (
              <tr
                id={'un-hover'}
                className={`row-finance-customer`}
                key={index}
                onClick={() => {}}
              >
                <td className="text-left paddingRow">
                  <span className="pd-frist">
                    {(includes(data.name, "YEGEE") ? `ยี่กี รอบที่ ${Number(data.name.replace("YEGEE_", ''))}` : CODE_TO_NAME[data.name]).replace('หวย', '')}

                  </span>
                </td>
                <td className="text-center">
                  <span>
                    {CODE_TO_TYPENAME[data.type as TLottoGameType]}
                  </span>
                </td>
                <td className="text-center">
                  <span>{data.number}</span>
                </td>
                <td className="text-right">
                  <span>{number.addComma(data.rate)}</span>
                </td>
                <td className="text-center">
                  <span style={{paddingLeft:30}}>{number.addComma(data.totalMeBet)}</span>
                </td>
                <td className="text-right">
                  <span className="">{number.addComma(data.totalHostBet)}</span>
                </td>
                <td className="text-right">
                  <span className="" >{number.addComma(data.totalBet)}</span>
                </td>
                <td className="text-right">
                  <span className="">{number.addComma(data.totalDis)}</span>
                </td>
                <td className="text-center">
                  <span className="" style={{paddingLeft:`40px`}}>
                    {number.addComma(number.calculator(`${data.totalBet}-${data.totalDis}`))}
                  </span>
                </td>
                <td style={{ width: "10%" }} className="text-center">
                  <span className="">{number.addComma(data.totalCom)}</span>
                </td>
                <td className="text-center">
                  <span className="" style={{ color: stColor }}>
                    {stName}
                  </span>
                </td>
                <td className="text-center d-flex align-items-center justify-content-center">
                  {data.hostKeep ? (
                    <Badge
                      text="ใช่"
                      customBadgeContainerClass="host-keep-true-badge-container"
                      textColor={color.SECONDARY_GREEN}
                      customTextClass="host-keep-true-badge-text"
                    />
                  ) : (
                    <Badge
                      text="ไม่ใช่"
                      customBadgeContainerClass="host-keep-false-badge-container"
                      textColor={color.TERTIARY_TEXT}
                      customTextClass="host-keep-true-badge-text"
                    />
                  )}
                </td>
                <td className="text-center">
                  <div className="d-flex flex-row align-items-center justify-content-center">
                    <span>{dateThai.formatThai(data.createdAt)}</span>
                  </div>
                </td>
                <td className="text-left d-flex justify-content-center">
                <span>
                <Dropdown
                      menuPlacement="auto"
                      options={ [{value: "memberInfo", label: "ข้อมูลสมาชิก", disabled: false}] }
                      iconOpen={point}
                      iconClose={point}
                      iconStyles={{width:4, height:16}}
                      hoverElementColor={colors.PRIMARY_HOVER}
                      controlBackgroundColor={colors.PRIMARY_BG}
                      menuStyles={{ width: "175px", left: "-150px" }}
                      valueContainerStyles={{padding: "0px",maxWidth: "0px",}}
                      handleOnChange={(target: IOptions) => {
                        this.props.clickRoute(target , data)
                      }}
                  />
                </span>
              </td>
              </tr>
            );
          })
        )}
      </tbody>
    );

    return (
      <div className="table-container">
        <table className="col">
          {headTable}
          { this.props.childBetIsFetching ? loadingTable: tableBody}
        </table>
      </div>
    );
  };

  renderDropDownBox = () => {
    return (
      <>
        <h6>{constants.rowPerPage}</h6>
        <div className="dropdown-rowPerPage">
          <Dropdown
            menuPlacement={"top"}
            options={[
              { value: 5, label: "5", disabled: false },
              { value: 10, label: "10", disabled: false },
              { value: 20, label: "20", disabled: false },
              { value: 50, label: "50", disabled: false },
              { value: 100, label: "100", disabled: false },
            ]}
            controlBackgroundColor={color.PRIMARY_BG}
            placeholder={String(this.state.currentRowPerPage)}
            handleOnChange={(target: IOptions) => {
              this.setState(
                {
                  currentPage: 1,
                  currentRowPerPage: Number(target.value),
                  dataQuery: {
                    ...this.state.dataQuery,
                    page: 1,
                    limit: Number(target.value),
                    childAgentShareId: this.props.financeChildDataState[0].agentShareId
                  },
                },
                () => {
                  this.props.getChildBet(this.state.dataQuery);
                }
              );
            }}
          />
        </div>
      </>
    );
  };

  handleClickChangePage = ({ selected: selectedPage }: ISelectPage) => {
    this.setState({ currentPage: selectedPage }, () => {
      this.setState(
        {
          dataQuery: {
            ...this.state.dataQuery,
            page: this.state.currentPage + 1,
            childAgentShareId : this.props.financeChildDataState[0].agentShareId
          },
        },
        () => {
          this.props.getChildBet(this.state.dataQuery);
        }
      );
    });
  };

  render() {
    const RenderTable = this.renderTable();
    const RenderDropDownBox = this.renderDropDownBox;

    return (
      <div className="member-bet-list-container">
        <div className="row">
          <div className="col-12">
            <div className="title-text">{constants.title}</div>
          </div>
        </div>
        <div className="row m3-t">
          <div className="col-12">{RenderTable}</div>
        </div>


        <div className="row">
          <div className="col d-flex justify-content-end align-items-center">
            <RenderDropDownBox />
          </div>
            <h6 className="d-flex justify-content-end align-items-center">
              {`${this.state.startPage} - ${this.state.endPage} 
                of ${this.props.childBetData.totalRecord || 0}`}
            </h6>
            <div className='col-auto'>
            <Panginate
              pageTotal={this.props.childBetData.total}
              initialPage={this.state.dataQuery.page - 1}
              pageRangeDisplayed={this.state.currentPage}
              onPageChange={this.handleClickChangePage}
            />
            </div>
          </div>
        </div>
    );
  }
}

export default MemberBetListComponent;
