import axios from "axios";
import { transformer } from "utils";
import environment from "../../constants/environment";

const fetchGetLottoSchedule = () => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url
    }${environment.endpoint.lottoSchedule}`;
  return axios.get(endpoint);
};

const fetchGetV2LottoSchedule = (name?: string) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url
    }${environment.endpoint.V2lottoSchedule}`;
  return axios.get(endpoint, { params: { name: name } });
};


const fetchBetResult = (query: ISearchLudensBetResultQuery) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url
    }${environment.endpoint.lottoBetResult}`;
  return axios.get(`${endpoint}${transformer.urlSearchParams(query)}`);
};

const fetchBetHistory = (data: ISearchAgentBetLotteryHistoryListQuery) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url
    }${environment.endpoint.lottoHistory}`;
  return axios.get(`${endpoint}${transformer.urlSearchParams(data)}`);
};

const fetchGetGame = (slugName: TLottoSlug) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url
    }${environment.endpoint.getLottoGame}`;

  const lottoGameQuery = {
    type: slugName,
  };
  return axios.get(`${endpoint}${transformer.urlSearchParams(lottoGameQuery)}`);
};

export { fetchGetLottoSchedule, fetchBetHistory, fetchBetResult, fetchGetGame, fetchGetV2LottoSchedule };
