import { get } from "lodash";
import { yegeeGameHistoryState, yegeeGameListState, yegeeGameState, yegeePlayedListState, yegeePlayState, yegeeSumState } from "./constants";

const gameList = (state: RootReducers): ReducerState<ILottoGame[]> => {
  return get(state, "lotto.yegee.gameList", yegeeGameListState);
};

const gameHistory = (state: RootReducers): ReducerState<IYegeeGameHistory[]> => {
  return get(state, "lotto.yegee.gameHistory", yegeeGameHistoryState)
}

const play = (state: RootReducers): ReducerState<IYegeePlay> => {
  return get(state, "lotto.yegee.play", yegeePlayState)
}

const sum = (state: RootReducers): ReducerState<string> => {
  return get(state, "lotto.yegee.sum", yegeeSumState)
}

const playedList = (state: RootReducers): ReducerState<IYegeePlay[]> => {
  return get(state, "lotto.yegee.playedList", yegeePlayedListState)
}

const game = (state: RootReducers): ReducerState<ILottoGame> => {
  return get(state, "lotto.yegee.game", yegeeGameState)
}


const yegee = {
  gameList,
  gameHistory,
  play,
  sum,
  playedList,
  game
};

export default yegee;
