import React, { Component } from "react";
import { handleOnClickBreadcrumb } from "utils";
import { isNull, noop, map } from "lodash";
import { RouteComponentProps } from "react-router-dom";
import { Breadcrumb, LoadingCircle } from "components";
import "./CustomerDetail.style.scss";
import { TableMenuList } from "./components";
import { th } from "date-fns/locale";
import { format } from "date-fns";
import { date, number } from "utils";

const defaultProps: ICustomerDetailProps & ICustomerDetailActionProps = {
  getMe() {
    noop();
  },
  getMeData: {
    agentId: 0,
    username: "",
    permission: "",
    phoneNumber: "",
    credit: 0,
    bankType: "",
    bankName: "",
    bankNumber: "",
    isAccess: false,
    agentStatus: "",
    name: "",
    creditLimit: 0,
    creditShareLimit: 0,
    betTotal: 0,
    currentBetTotal: 0,
    betCountTotal: 0,
    currentBetCountTotal: 0,
    profitTotal: 0,
    profitBenefitTotal: 0,
    lossTotal: 0,
    lossBenefitTotal: 0,
    childTotal: 0,
    createdAt: "",
    updatedAt: "",
  },
  postCustomerNote() {
    noop();
  },
  getFinanceCustomerTx() {
    noop();
  },
  getCustomer() {
    noop();
  },
  getCustomerNote() {
    noop();
  },
  getGetBetGroup() {
    noop();
  },
  onChangeIsLoaddingAction() { },
  customerCode: 0,
  customerError: "",
  customerIsFetching: false,
  customerData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  customerNoteCode: 0,
  customerNoteError: "",
  customerNoteIsFetching: false,
  customerNoteData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  postcustomerNoteCode: 0,
  postcustomerNoteError: "",
  postcustomerNoteIsFetching: false,
  postcustomerNoteData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  betGroupV2IsFetching: false,
  betGroupV2Code: 0,
  betGroupV2Error: "",
  betGroupV2Data: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  customerTxCode: 0,
  customerTxError: "",
  customerTxIsFetching: false,
  customerTxData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
};

const constants = {
  menuList: ["รายรับรายจ่าย", "การเดิมพัน", "ดูบันทึกประวัติ"],
  title: "ข้อมูลลูกค้า",
  times: "ครั้ง",
  items: "รายการ",
  Baht: "บาท",
  percent: "%",
  updateAt: "อัพเดทเมื่อ",
  nickName: "ชื่อช่วยจำ",
  phoneNumber: "เบอร์ติดต่อ",
  lineId: "ไลน์ไอดี",
  address: "ที่อยู่",
  profitLoss: "กำไร / ขาดทุน",
  currentBetTotal: "ยอดเดิมพันปัจจุบัน",
  allbetTotal: "ยอดเดิมพันทั้งหมด",
  frequencyBet: "จำนวนการเดิมพัน",
  currentResultTotal: "ยอดผลลัพธ์ปัจจุบัน",
  allResultTotal: "ยอดผลลัพธ์ทั้งหมด",
  rowPerPage: "จำนวนรายการต่อหน้า :",
  dataEmpty: "ไม่มีข้อมูล...",
  breadcrumb: {
    main: "ภาพรวม",
    customerList: "รายการลูกค้า",
  },
};

class CustomerDetail extends Component<
  ICustomerDetailProps & ICustomerDetailActionProps & RouteComponentProps
> {
  static defaultProps = defaultProps;
  state = {
    activeMenu: "รายรับรายจ่าย",
    currentPage: 0,
    currentRowPerPage: 10,
    childSort: "",
    dataQuery: {
      limit: 10,
      page: 1,
      customerId: 0,
    },
    startPage: 1,
    endPage: 0,

    queryTimeout: setTimeout(() => {
      noop();
    }, 0),
    reqMemberDetail: {
      id: -99,
    },
    isSearch: false,
  };

  componentDidMount() {
    if (this.props.location.state) {
      this.props.getCustomer({
        limit: 10,
        page: 1,
        customerId: this.props.location.state,
      });

      // this.setState(
      //   {
      //     dataQuery: {
      //       ...this.state.dataQuery,
      //       customerId: this.props.location.state,
      //     },
      //   },
      //   () => {
      //     this.props.getCustomer(pickBy(this.state.dataQuery));
      //   }
      // );
    } else {
      this.props.history.push("/");
    }
    this.props.getMe();
  }

  componentWillUnmount() {
    this.props.location.state = undefined;
  }
  renderLandingMenu = () => {
    const mappingMenu = map(constants.menuList, (name: string) => (
      <div
        className={`m5-r`}
        key={`${name}`}
        id={`${name}`}
        onClick={() => {
          if (this.state.activeMenu !== name) {
            this.setState({ activeMenu: name });
          }
        }}
      >
        <div className="d-flex flex-column">
          <h6 className={this.state.activeMenu === name ? "active-menu" : ""}>
            {name}
          </h6>
        </div>
      </div>
    ));
    return <div className="row p2-l">{mappingMenu}</div>;
  };
  renderTableMenuList = () => {
    return (
      <>
        <TableMenuList
          clickChangeRoute={() => {
            this.props.history.push("/bet-summary-history");
          }}
          clickChangeRoute2={() => {
            this.props.history.push("/finance/summary-customer");
          }}
          activeMenu={this.state.activeMenu}
          locationData={this.props.location.state}
          getBetGroup={this.props.getGetBetGroup}
          onload={this.props.onChangeIsLoaddingAction}
          betgroupCode={this.props.betGroupV2Code}
          betgroupError={this.props.betGroupV2Error}
          betgroupIsFetching={this.props.betGroupV2IsFetching}
          betGroupData={this.props.betGroupV2Data}
          getFinanceCustomerTx={this.props.getFinanceCustomerTx}
          customerTxCode={this.props.customerCode}
          customerTxError={this.props.customerTxError}
          customerTxIsFetching={this.props.customerTxIsFetching}
          customerTxData={this.props.customerTxData}
          getCustomerNote={this.props.getCustomerNote}
          customerNoteCode={this.props.customerNoteCode}
          customerNoteError={this.props.customerNoteError}
          customerNoteIsFetching={this.props.customerNoteIsFetching}
          customerNoteData={this.props.customerNoteData}
          postcustomerNote={this.props.postCustomerNote}
          postcustomerNoteCode={this.props.postcustomerNoteCode}
          postcustomerNoteError={this.props.postcustomerNoteError}
          postcustomerNoteIsFetching={this.props.postcustomerNoteIsFetching}
        />
      </>
    );
  };

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.breadcrumb.main, path: "/", active: false },
      {
        label: constants.breadcrumb.customerList,
        path: "/customer-list",
        active: false,
      },
      {
        label: this.props.customerData.dataList[0]?.customerName,
        active: true,
      },
    ];

    const RenderTableMenuList = this.renderTableMenuList;
    const RenderLadingMenu = this.renderLandingMenu;

    return (
      <div className="paper-container customerDetail-container">
        <div className="row">
          <div className="col">
            <div className="sub-menu">
              <Breadcrumb
                items={navigates}
                handleOnClickItem={handleOnClickBreadcrumb}
              />
            </div>
          </div>
        </div>

        <div className="paper-title titleText">{constants.title}</div>
        <div className="pr-3 paper-description">
          {this.props.customerData.dataList[0]?.customerName}
        </div>

        <div className="paper-body">
          <div className="box-shadow">
            <div className="row">
              <div className="col-12 pr-3 paper-body-title ">
                <div className="d-flex align-items-center">
                  {constants.title}
                  {this.props.customerIsFetching && (<div className="m1-l"><LoadingCircle /> </div>)}
                </div>
              </div>
              <div className="col-12 m1-t ">
                <div className="content-text headText">
                  {constants.updateAt}{" "}
                  {`${format(
                    date.calibratingTime(
                      this.props.customerData.dataList[0]?.customerUpdatedAt,
                      true
                    ),
                    "dd MMM yyyy HH:mm",
                    { locale: th }
                  )}`}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text headText">
                    {constants.nickName}
                  </div>
                  <div className={`${this.props.customerIsFetching ? 'tertiary-text' : 'paper-body-title valueText'} m1-t`}>
                    {this.props.customerData.dataList[0]?.customerName}
                  </div>
                </div>
              </div>

              <div className="col-4 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text headText">
                    {constants.phoneNumber}
                  </div>
                  <div className={`${this.props.customerIsFetching ? 'tertiary-text' : 'paper-body-title valueText'} m1-t`}>
                    {isNull(
                      this.props.customerData.dataList[0]?.customerPhoneNumber
                    )
                      ? "-"
                      : this.props.customerData.dataList[0]
                        ?.customerPhoneNumber}
                  </div>
                </div>
              </div>

              <div className="col-4 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text headText">
                    {constants.lineId}
                  </div>
                  <div className={`${this.props.customerIsFetching ? 'tertiary-text' : 'paper-body-title valueText'} m1-t`}>
                    {isNull(this.props.customerData.dataList[0]?.customerLine)
                      ? "-"
                      : this.props.customerData.dataList[0]?.customerLine}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col  m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text headText">
                    {constants.address}
                  </div>
                  <div className={`${this.props.customerIsFetching ? 'tertiary-text' : 'paper-body-title valueText'} m1-t`}>
                    {isNull(
                      this.props.customerData.dataList[0]?.customerAddress
                    )
                      ? "-"
                      : this.props.customerData.dataList[0]?.customerAddress}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text headText">
                    {constants.profitLoss}
                  </div>
                  <div className={`${this.props.customerIsFetching ? 'tertiary-text' : 'paper-body-title valueText'} m1-t`}>
                    {number.addComma(
                      this.props.customerData.dataList[0]?.customerTotalProfit
                    )}{" "}
                    /{" "}
                    {number.addComma(
                      this.props.customerData.dataList[0]?.customerTotalLoss
                    )}{" "}
                    {constants.Baht}
                  </div>
                </div>
              </div>

              <div className="col-4 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text headText">
                    {constants.currentBetTotal}
                  </div>
                  <div className={`${this.props.customerIsFetching ? 'tertiary-text' : 'paper-body-title valueText'} m1-t`}>
                    {" "}
                    {number.addComma(
                      this.props.customerData.dataList[0]
                        ?.customerCurrentTotalBet
                    )}{" "}
                    {constants.Baht}
                  </div>
                </div>
              </div>

              <div className="col-4 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text headText">
                    {constants.allbetTotal}
                  </div>
                  <div className={`${this.props.customerIsFetching ? 'tertiary-text' : 'paper-body-title valueText'} m1-t`}>
                    {number.addComma(
                      this.props.customerData.dataList[0]?.customerTotalBet
                    )}{" "}
                    {constants.Baht}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text headText">
                    {constants.frequencyBet}
                  </div>
                  <div className={`${this.props.customerIsFetching ? 'tertiary-text' : 'paper-body-title valueText'} m1-t`}>
                    {number.addComma(
                      this.props.customerData.dataList[0]?.customerTotalCountBet
                    )}{" "}
                    {constants.times}
                  </div>
                </div>
              </div>

              <div className="col-4 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text headText">
                    {constants.currentResultTotal}
                  </div>
                  <div className={`${this.props.customerIsFetching ? 'tertiary-text' : 'paper-body-title valueText'} m1-t`}>
                    {number.addComma(
                      this.props.customerData.dataList[0]
                        ?.customerCurrentTotalResult
                    )}{" "}
                    {constants.Baht}
                  </div>
                </div>
              </div>

              <div className="col-4 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text headText">
                    {constants.allResultTotal}
                  </div>
                  <div className={`${this.props.customerIsFetching ? 'tertiary-text' : 'paper-body-title valueText'} m1-t`}>
                    {number.addComma(
                      this.props.customerData.dataList[0]?.customerTotalResult
                    )}{" "}
                    {constants.Baht}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="paper-body">
          <div className="box-shadow">
            <div className="menu-list ">
              <RenderLadingMenu />
            </div>
            <RenderTableMenuList />
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerDetail;
