import axios from "axios";
import { MiddlewareAPI, Dispatch } from "redux";
import ProjectENV from "constants/environment";
import localIpUrl from "local-ip-url";

const axiosMiddleware = ({
  getState,
}: MiddlewareAPI<Dispatch, RootReducers>) => (next: Dispatch) => (
  action: any
) => {
  const token = getState().authentication.token.accessToken;
  const node = ProjectENV.name;
  const deviceId = localIpUrl('public', 'ipv6') || '';
  axios.defaults.headers.common.token = token;
  axios.defaults.headers.common.node = node;
  axios.defaults.headers.common.deviceId = deviceId;
  return next(action);
};

export default axiosMiddleware;
