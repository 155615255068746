import { AxiosError, AxiosResponse } from "axios";
import { createAsyncAction } from "typesafe-actions";
import {
  CREDITS_REQUEST,
  CREDITS_SUCCESS,
  CREDITS_FAILURE,
  CREDITS_CANCEL,
  TRANSACTIONLIST_REQUEST,
  TRANSACTIONLIST_SUCCESS,
  TRANSACTIONLIST_FAILURE,
  TRANSACTIONLIST_CANCEL,
  TRANSFERCREDITS_REQUEST,
  TRANSFERCREDITS_SUCCESS,
  TRANSFERCREDITS_FAILURE,
  TRANSFERCREDITS_CANCEL,
  TRANSFERCREDITDESCRIPTION_REQUEST,
  TRANSFERCREDITDESCRIPTION_SUCCESS,
  TRANSFERCREDITDESCRIPTION_FAILURE,
  TRANSFERCREDITDESCRIPTION_CANCEL,
  TRANSFERCONFIRMATION_REQUEST,
  TRANSFERCONFIRMATION_SUCCESS,
  TRANSFERCONFIRMATION_FAILURE,
  TRANSFERCONFIRMATION_CANCEL,
  PAYREQUEST_REQUEST,
  PAYREQUEST_SUCCESS,
  PAYREQUEST_FAILURE,
  PAYACCEPT_REQUEST,
  PAYACCEPT_SUCCESS,
  PAYACCEPT_FAILURE,
} from "./constants";

const payRequestAction = createAsyncAction(
  PAYREQUEST_REQUEST,
  PAYREQUEST_SUCCESS,
  PAYREQUEST_FAILURE
)<
  any,
  AxiosResponse<IAPIResponse<IAgentPayRequest>>,
  AxiosError<IAPIResponse>
>();

const payAcceptAction = createAsyncAction(
  PAYACCEPT_REQUEST,
  PAYACCEPT_SUCCESS,
  PAYACCEPT_FAILURE
)<
  any,
  AxiosResponse<IAPIResponse<IAgentPayAccept>>,
  AxiosError<IAPIResponse>
>();

const creditAction = createAsyncAction(
  CREDITS_REQUEST,
  CREDITS_SUCCESS,
  CREDITS_FAILURE,
  CREDITS_CANCEL
)<
  any,
  AxiosResponse<IAPIResponse<IResponseAgentFinanceMe>>,
  AxiosError<IAPIResponse>,
  any
>();

const transactionAction = createAsyncAction(
  TRANSACTIONLIST_REQUEST,
  TRANSACTIONLIST_SUCCESS,
  TRANSACTIONLIST_FAILURE,
  TRANSACTIONLIST_CANCEL
)<
  any,
  AxiosResponse<IAPIResponse<IPagination<IResponseAgentFinList>>>,
  AxiosError<IAPIResponse>,
  any
>();

const transferConfirmationAction = createAsyncAction(
  TRANSFERCONFIRMATION_REQUEST,
  TRANSFERCONFIRMATION_SUCCESS,
  TRANSFERCONFIRMATION_FAILURE,
  TRANSFERCONFIRMATION_CANCEL
)<
  any,
  AxiosResponse<IAPIResponse<IPagination<IResponseAgentRecvList>>>,
  AxiosError<IAPIResponse>,
  any
>();

const transferCreditAction = createAsyncAction(
  TRANSFERCREDITS_REQUEST,
  TRANSFERCREDITS_SUCCESS,
  TRANSFERCREDITS_FAILURE,
  TRANSFERCREDITS_CANCEL
)<
  any,
  AxiosResponse<IAPIResponse<IPagination<IResponseAgentFinancePay>>>,
  AxiosError<IAPIResponse>,
  any
>();

const transferCreditDescriptionAction = createAsyncAction(
  TRANSFERCREDITDESCRIPTION_REQUEST,
  TRANSFERCREDITDESCRIPTION_SUCCESS,
  TRANSFERCREDITDESCRIPTION_FAILURE,
  TRANSFERCREDITDESCRIPTION_CANCEL
)<
  any,
  AxiosResponse<IAPIResponse<IResponseAgentFinancePayDetail[]>>,
  AxiosError<IAPIResponse>,
  any
>();

export {
  creditAction,
  transactionAction,
  transferCreditAction,
  transferCreditDescriptionAction,
  payRequestAction,
  transferConfirmationAction,
  payAcceptAction,
};
