import {
  Breadcrumb,
  Button,
  ConfirmModal,
  ErrorModal,
  SuccessModal,
  // FinanceInfo,
  // NewLottoBetConfig,
} from "components";
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { MemberInfo } from "./components/MemberInfo";
import schemeMemberDetail from "./model/scheme";
import { MemberSpecificInfo } from "./components/MemberSpecificInfo";
import "./memberDetail.style.scss";
import { handleOnClickBreadcrumb } from "utils";
import { cloneDeep, isEmpty, mapKeys, noop, pick } from "lodash";
import color from "constants/colors";
import { meDataInitialValue } from "constants/variables";
import { pascalCase } from "change-case";
import { responseCode, responseMessage } from "constants/response";
import routes from "constants/routes";

import { NewLottoBetConfig } from "./components/NewLottoBetConfig";

const constants = {
  breadcrumb: {
    main: "ภาพรวม",
    membersInfo: "ข้อมูลสมาชิก",
    membersList: "รายการสมาชิก",
    addMembers: "tlb-8877-001",
  },
  title: "ข้อมูลสมาชิก",
  description: "บิว (tlb-8877-001)",
  changePassword: "เปลี่ยนรหัสผ่าน",
  saveData: "บันทึกข้อมูล",
  cancel: "ยกเลิก",
  updateConfirm: "คุณแน่ใจหรือว่าจะอัพเดทลูกสมาชิก",
  editSuccess:"แก้ไขข้อมูลสำเร็จ",
  postChildUpdateSuccess: "บันทึกข้อมูลสมาชิกเรียบร้อยแล้ว",
  childSettingLotteryUpdateSuccess: "แก้ไขข้อมูลการตั้งค่าหวยสำเร็จ",
  reset2FA: "รีเซ็ท 2FA ใหม่",
  note:"รายการบันทึกข้อความ",
  postChildCreditUpdateSuccess: "แก้ไขข้อมูลเครดิตลูกสมาชิกสำเร็จ",
  postChildSettingUpdateSuccess: "แก้ไขข้อมูลตั้งค่าสมาชิกสำเร็จ",
};

const defaultProps: IMemberDetailActionProps & IMemberDetailProps = {
  childDetailCode: 0,
  childDetailData: {
    dataList: [],
    limit: 10,
    page: 1,
    total: 1,
    totalRecord: 0,
  },
  childDetailError: "",
  childDetailIsFetching: false,
  getChildDetail() {
    noop();
  },
  onChangeIsLoaddingAction() {
    noop();
  },
  getMe(){noop()},
  meData: meDataInitialValue,
  getMeSetting() {
    noop();
  },
  meSettingCode: 0,
  meSettingData: {
    agentId: 0,
    setBetMaxLimit: 100,
    setBetMinLimit: 1,
    setComPercentage: 0,
  },
  meSettingError: "",
  meSettingIsFetching: false,
  postChildUpdate(data) {
    noop();
  },
  childUpdateCode: 0,
  childUpdateError: "",
  childUpdateIsFetching: false,
  postChildSettingLotteryUpdate(data) {
    noop();
  },
  childSettingLotteryUpdateCode: 0,
  childSettingLotteryUpdateError: "",
  childSettingLotteryUpdateIsFetching: false,
  meSettingLotteryCode: 0,
  getMeSettingLottery() {
    noop();
  },
  meSettingLotteryData: {
    agentId: 0,
    lotterySetting: [],
  },
  meSettingLotteryError: "",
  meSettingLotteryIsFetching: false,
  childSettingUpdateCode: 0,
  childSettingUpdateError: "",
  childSettingUpdateIsFetching: false,
  postChildSettingUpdate(data) {
    noop();
  },
  childCreditUpdateCode: 0,
  childCreditUpdateError: "",
  childCreditUpdateIsFetching: false,
  postChildCreditUpdate(data) {
    noop();
  },
};

class MemberDetailContainer extends Component<
  IMemberDetailActionProps &
    IMemberDetailProps &
    RouteComponentProps<any, any, { memberData: IV2GetAgentChild }>,
  IMemberDetailState
> {
  static defaultProps = defaultProps;
  state: IMemberDetailState = {
    formikState: {
      phoneNumber: "",
      permission: "PleaseChoose",
      isAccess: true,
      username: "",
      name: "",
      line: "",
      creditLimit: 1,
      com: 0,
      bankName: "",
      bankNumber: "",
      bankType: "PleaseChoose",
      betMaxLimit: 120,
      betMinLimit: 1,
    },
  };

  componentDidMount() {
    if (this.props.location.state) {
      const { location } = this.props;
      this.props.onChangeIsLoaddingAction(true);
      this.props.getMeSetting();
      this.props.getChildDetail({
        limit: 10,
        page: 1,
        childAgentId: location.state.childAgentId,
      });
      this.props.getMeSettingLottery();
    } else {
      this.props.history.push("/");
    }
  }

  componentDidUpdate(prevProps: IMemberDetailProps) {
    const { dataList } = this.props.childDetailData;

    /* if (
      prevProps.childCreditUpdateIsFetching !==
        this.props.childCreditUpdateIsFetching &&
      !this.props.childCreditUpdateIsFetching
    ) {
      this.props.onChangeIsLoaddingAction(false);
      if (this.props.childCreditUpdateCode === responseCode.OK) {
        SuccessModal.show({
          action: () => SuccessModal.hide(),
          description: constants.postChildCreditUpdateSuccess,
        });
      } else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: responseMessage()[this.props.childCreditUpdateCode],
        });
      }
    } */

    /* if (
      prevProps.childSettingUpdateIsFetching !==
        this.props.childSettingUpdateIsFetching &&
      !this.props.childSettingUpdateIsFetching
    ) {
      this.props.onChangeIsLoaddingAction(false);
      if (this.props.childSettingUpdateCode === responseCode.OK) {
        SuccessModal.show({
          action: () => SuccessModal.hide(),
          description: constants.postChildSettingUpdateSuccess,
        });
      } else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: responseMessage()[this.props.childCreditUpdateCode],
        });
      }
    } */

    if (
      prevProps.meSettingLotteryIsFetching !==
        this.props.meSettingLotteryIsFetching &&
      !this.props.meSettingLotteryIsFetching
    ) {
      this.props.onChangeIsLoaddingAction(false);
    }

    if (
      prevProps.childUpdateIsFetching !== this.props.childUpdateIsFetching &&
      !this.props.childUpdateIsFetching
    ) {
      
      this.props.onChangeIsLoaddingAction(false);
      if (this.props.childUpdateCode === responseCode.OK) {
        this.props.getMe()
        const { location } = this.props;
        SuccessModal.show({
          action: () => SuccessModal.hide(),
          title: constants.editSuccess,
          description: constants.postChildUpdateSuccess,
        });
        this.props.getChildDetail({
          limit: 10,
          page: 1,
          childAgentId: location.state.childAgentId,
        });
      }
       else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: responseMessage()[this.props.childUpdateCode],
        });
      }
    }

    if (
      prevProps.childDetailIsFetching !== this.props.childDetailIsFetching &&
      !this.props.childDetailIsFetching
    ) {
      this.props.onChangeIsLoaddingAction(false);
    }

    if (
      prevProps.meSettingIsFetching !== this.props.meSettingIsFetching &&
      !this.props.meSettingIsFetching
    ) {
      this.props.onChangeIsLoaddingAction(false);
    }

    if (
      prevProps.childDetailData !== this.props.childDetailData &&
      !isEmpty(this.props.childDetailData)
    ) {
      const clonedFormikState = cloneDeep(this.state.formikState);
      if (!isEmpty(dataList)) {
        clonedFormikState.phoneNumber = dataList[0].childPhoneNumber;
        clonedFormikState.username = dataList[0].childUsername;
        clonedFormikState.name = dataList[0].childName;
        clonedFormikState.isAccess = dataList[0].childIsAccess;
        clonedFormikState.creditLimit = dataList[0].childCreditLimit;
        clonedFormikState.com = dataList[0].childSetComPercentage;
        clonedFormikState.bankType = dataList[0].childBankType;
        clonedFormikState.bankName = dataList[0].childBankName;
        clonedFormikState.bankNumber = dataList[0].childBankNumber;
        clonedFormikState.betMaxLimit = dataList[0].childSetBetMaxLimit;
        clonedFormikState.betMinLimit = dataList[0].childSetBetMinLimit;
        clonedFormikState.line = isEmpty(dataList[0].childLine) ? '' : dataList[0].childLine;
        clonedFormikState.permission = dataList[0].childPermission;

        this.setState({ formikState: clonedFormikState });
      }
    }
  }

  handleRouteClickResetPassword = (
    data: IPassMemberChildDataWithRoute
  ) => {
    this.props.history.push("/member/reset-password", 
      data
    );
  }
  componentWillUnmount() {
    this.props.location.state = undefined
  }

  submitFormik = async (values: IMemberDetailFormikProps) => {
    this.props.onChangeIsLoaddingAction(true);
    const pickedValues = {
      ...pick(values, [
        "phoneNumber",
        "bankType",
        "bankName",
        "permission",
        "bankNumber",
        "isAccess",
        "name",
        "line",
      ]),
    };
    const objMapper = (obj: typeof pickedValues) => {
      let newObj = mapKeys(obj, (value, key) => "child" + pascalCase(key));
      return newObj;
    };
    const changedNamePickedValues = {
      ...objMapper(pickedValues),
      childAgentId: this.props.childDetailData.dataList[0].childAgentId,
    };
    this.props.postChildUpdate(changedNamePickedValues as IV2AgentChildUpdate);
  };

  renderMemberDetailFormik = () => {
    const MemberDetailForm = (
      formikProps: FormikProps<IMemberDetailFormikProps>
    ) => {
      const { dataList } = this.props.childDetailData;

      return (
        <Form>
          <MemberSpecificInfo childDetailData={dataList} loading={this.props.childDetailIsFetching}/>
          <MemberInfo
            {...formikProps}
            childDetailData={dataList}
            meData={this.props.meData}
            postChildCreditUpdate={this.props.postChildCreditUpdate}
            postChildSettingUpdate={this.props.postChildSettingUpdate}
            postChildUpdate={this.props.postChildUpdate}
            loading={this.props.childDetailIsFetching}
          />
          {/* <div className="paper-body">
            <div className="row mt-4">
              <div className="col">
                <FinanceInfo
                  {...formikProps}
                  childDetailData={dataList}
                  isOtpClicked={true}
                  meSettingData={this.props.meSettingData}
                  meData={this.props.meData}
                  mode={"UPDATE"}
                  postChildCreditUpdate={this.props.postChildCreditUpdate}
                  postChildSettingUpdate={this.props.postChildSettingUpdate}
                  getMeSetting={this.props.getMeSetting}
                  getChildDetail={this.props.getChildDetail}
                />
              </div>
            </div>
          </div> */}
          <div className="paper-body">
            <div className="row mt-4">
              <div className="col">
                <NewLottoBetConfig
                  {...(formikProps as FormikProps<any>)}
                  lotterySetting={dataList[0]?.lotterySetting}
                  meSettingData={this.props.meSettingData}

                  creditLimit={0}
                  minBetHost={1}

                  isOtpClicked={true}
                  getChildSelectedLottoState={() => {}}
                  mode={"UPDATE"}
                  childDetailData={dataList}
                  getChildDetail={this.props.getChildDetail}

                  postChildSettingLotteryUpdate={this.props.postChildSettingLotteryUpdate}
                  childSettingLotteryUpdateCode={this.props.childSettingLotteryUpdateCode}
                  childSettingLotteryUpdateIsFetching={this.props.childSettingLotteryUpdateIsFetching}
                  
                  meSettingLotteryData={this.props.meSettingLotteryData}
                  meSettingLotteryCode={this.props.meSettingLotteryCode}
                  meSettingLotteryIsFetching={this.props.meSettingLotteryIsFetching}

                  childCreditUpdateCode ={this.props.childCreditUpdateCode}
                  childSettingUpdateCode ={this.props.childSettingUpdateCode}

                  postChildCreditUpdate={this.props.postChildCreditUpdate}
                  postChildSettingUpdate={this.props.postChildSettingUpdate}
                  getMeSetting={this.props.getMeSetting}

                  meData={this.props.meData}
                  getMe={this.props.getMe}

                  onChangeIsLoaddingAction={this.props.onChangeIsLoaddingAction}
                />
              </div>
            </div>
          </div>
        </Form>
      );
    };
    return (
      <Formik
        initialValues={this.state.formikState}
        enableReinitialize
        validationSchema={schemeMemberDetail(
          this.props.meSettingData,
          this.props.meData
        )}
        onSubmit={(values) => {
          ConfirmModal.show({
            action: () => {
              this.submitFormik(values);
              ConfirmModal.hide();
            },
            cancelAction: () => {
              ConfirmModal.hide();
            },
            description: constants.updateConfirm,
          });
        }}
      >
        {MemberDetailForm}
      </Formik>
    );
  };

  render() {
    const {dataList} = this.props.childDetailData
    const navigates: IBreadcrumbItem[] = [
      { label: constants.breadcrumb.main, path: "/", active: false },
      {
        label: constants.breadcrumb.membersList,
        path: routes.membersList.path,
        active: false,
      },
      { label: dataList[0]?.childUsername,
        active: true },
    ];
    const MemberDetailFormik = this.renderMemberDetailFormik;
    
    return (
      <div className="paper-container member-detail-container">
        <div className="row">
          <div className="col">
            <div className="sub-menu">
              <Breadcrumb
                items={navigates}
                handleOnClickItem={handleOnClickBreadcrumb}
              />
            </div>
          </div>
        </div>
        <div className="paper-title">{constants.title}</div>


        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="paper-description">{`${dataList[0]?.childName} (${dataList[0]?.childUsername})`}</div>
          <div className="d-flex flex-row align-items-center">
            <Button
              id="note"
              text={constants.note}
              borderRadius={"8px"}
              buttonClass={"note-button"}
              backgroundColor={color.SECONDARY_PURPLE}
              textSize={18}
              backgroundHoverColor={color.TERTIARY_PURPLE}
              transitionType="typeOne"
              onClick={() => {
                this.props.history.push( routes.memberDetailNote.path, dataList[0] );
              }}
            />
            {/* <div
              className="change-password-text mr-4"
              onClick={() => {
                ConfirmModal.show({
                  action: ConfirmModal.hide,
                  cancelAction: ConfirmModal.hide,
                })
              }}
            >
              {constants.reset2FA}
            </div> */}
            <div className="change-password-text mr-3" onClick={() => this.handleRouteClickResetPassword({childAgentId:dataList[0]?.childAgentId,childUsername:dataList[0]?.childUsername,childName:dataList[0]?.childName})}>
              {constants.changePassword}
            </div>
          </div>
        </div>
        <MemberDetailFormik />
      </div>
    );
  }
}

export default MemberDetailContainer;
