import React, { FunctionComponent, useState, useEffect } from 'react'
import { Dropdown, ErrorModal, LoadingCircle, ModalPrintBill, Panginate, /* SkeletonLoader */ } from "components"
import colors from 'constants/colors'
import pointIcon from "assets/images/global/icon/point.png";
import { getV2AgentBet } from 'reduxs/lotto/bet/services';
import { fetchGetMe } from 'reduxs/me/services';
import { get, isEmpty, map } from 'lodash';
import { dateThai, number, responseConverter, transformer } from 'utils';
import { LOTTO_GAME_TYPE_NAME, STATUS_BET } from 'constants/variables';
import { useHistory } from 'react-router-dom';
import routes from 'constants/routes';
import { AxiosError, AxiosResponse } from 'axios';
import { responseMessage } from 'constants/response';
import { fetchCustomer } from 'reduxs/customer/services';
import CryptoJS from "crypto-js";
const constants = {
    listBetMeTitle: 'รายการเดิมพันของฉัน',
    lotto: 'หวย',
    type: 'ประเภท',
    number: 'เลข',
    rate: 'อัตราจ่าย',
    keepFight: 'ถือสู้',
    share: 'แบ่งหุ้น',
    totalBet: 'ยอดเดิมพัน',
    discount: 'ส่วนลด',
    total: 'ยอดสุทธิ',
    commission: 'ค่าคอม',
    winloss: 'แพ้/ชนะ',
    keep: 'เก็บของ',
    time: 'เวลา',
    rowPerPage: 'จำนวนรายการต่อหน้า',
    empty: 'ไม่มีข้อมูล...',
    active: 'ใช่',
    inActive: 'ไม่ใช่',

    billed: 'ออกใบเสร็จ',
    betSummary: 'สรุปการเดิมพัน',
    customerDetail: 'ข้อมูลลูกค้า',
    memberClear: 'เคลียร์ยอดลูกสมาชิก',
    customerClear: 'เคลียร์ยอดลูกค้า',
    billCancel: 'ยกเลิกโพย',
}

interface IBetMeListProps {
    agentShareId: string
}

const DEFAULT_LIMIT = 10
const DEFAULT_PAGE = 1
const BetMeListComponent: FunctionComponent<IBetMeListProps> = ({ agentShareId }) => {

    const [betMeList, setBetMeList] = useState<IV2RespAgentLotteryBetMe[]>([])
    const [limit, setLimit] = useState(DEFAULT_LIMIT)
    const [page, setPage] = useState(DEFAULT_PAGE)
    const [total, setTotal] = useState(0)
    const [totalRecord, setTotalRecord] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const history = useHistory()

    useEffect(() => {
        setIsLoading(true)
        getBetMeList(page, limit)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getBetMeList = (pageQuery: number, limitQuery: number) => {
        if (!agentShareId) { return }
        getV2AgentBet({ agentShareId: Number(agentShareId), page: pageQuery, limit: limitQuery })
            .then((response) => { setResponseBetMeList(response.data) })
            .catch((error) => console.log(error))
            .finally(() => {
                setTimeout(() => {
                    setIsLoading(false)
                }, 800)
            })
    }

    const setResponseBetMeList = (response: IAPIResponse<IPagination<IV2RespAgentLotteryBetMe>>) => {
        const balanceResponseData = get(response, 'data.dataList', [])
        const currentPage = get(response, 'data.page', page)
        const limitPage = get(response, 'data.limit', limit)
        const totalPage = get(response, 'data.total', total)
        const totalRecord = get(response, 'data.totalRecord', total)
        setBetMeList(balanceResponseData)
        setPage(currentPage)
        setLimit(limitPage)
        setTotal(totalPage)
        setTotalRecord(totalRecord)
    }

    const resetPagination = () => {
        // setBetMeList([])
        setPage(DEFAULT_PAGE)
        setLimit(DEFAULT_LIMIT)
        setTotal(0)
    }

    const handleOnChangePage = (selectPage: ISelectPage) => {
        getBetMeList(selectPage.selected + 1, limit)
    }

    const handleOnChangeLimit = (selectOptions: IOptions) => {
        resetPagination()
        getBetMeList(DEFAULT_PAGE, selectOptions.value as number)
    }

    const goToCustomerDetail = (customerId: number) => {
        if (!customerId) { return }
        fetchCustomer({ customerId, limit: 1 })
            .then((response) => {
                const customer = get(response, 'data.data.dataList.0', {})
                if (customer) {
                    history.push(routes.customerDetail.path, customer)
                }
            })
            .catch((error: AxiosError<IAPIResponse>) => {
                ErrorModal.show({
                    action: ErrorModal.hide,
                    description: get(responseMessage(), responseConverter.getMessage(error).code, responseMessage()[0]),
                });
            })
    }

    return (
        <div className="paper-body">
            <div className="box-shadow m3-t boxShadow-Border">
                <div className="row">
                    <div className="col d-flex m2-b">
                        <div className="d-flex flex-column paper-body-title leading-wrapper">
                            {constants.listBetMeTitle}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                    </div>
                </div>
                <div className='row'>
                    <div className="col m2-t">
                        <table className='finance-host-table'>
                            <thead>
                                <tr>
                                    <th>{constants.lotto}</th>
                                    <th>{constants.type}</th>
                                    <th>{constants.number}</th>
                                    <th style={{ textAlign: 'right' }}>{constants.rate}</th>
                                    <th style={{ textAlign: 'right' }}>{constants.keepFight}</th>
                                    <th style={{ textAlign: 'right' }}>{constants.share}</th>
                                    <th style={{ textAlign: 'right' }}>{constants.totalBet}</th>
                                    <th style={{ textAlign: 'right' }}>{constants.discount}</th>
                                    <th style={{ textAlign: 'right' }}>{constants.total}</th>
                                    <th style={{ textAlign: 'right' }}>{constants.commission}</th>
                                    <th style={{ textAlign: 'center' }}>{constants.winloss}</th>
                                    <th style={{ textAlign: 'center' }}>{constants.keep}</th>
                                    <th style={{ textAlign: 'center' }}>{constants.time}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading
                                    // ? (<tr><td className="text-center color-row p-3" colSpan={14}><SkeletonLoader customStyle={[[{ col: 12, show: true }]]} /></td></tr>)
                                    ? <tr>
                                        <td className="text-center" colSpan={15}>
                                            <div className="m1-y d-flex justify-content-center"><LoadingCircle /> </div>
                                        </td>
                                    </tr>
                                    : isEmpty(betMeList)
                                        ? (<tr><td className="text-center color-row p-2" colSpan={14}>{constants.empty}</td></tr>)
                                        : (map(betMeList, (data, index) => {
                                            const total = number.calculator(`${data.totalBet || 0}-${data.totalDis || 0}`)
                                            const statusName = get(STATUS_BET, `${data.status}.status`, "");
                                            const statusColor = get(STATUS_BET, `${data.status}.color`, "");
                                            const lottoName = transformer.nameLotto(data.name, false, true)
                                            const lottoType = get(LOTTO_GAME_TYPE_NAME, data.type, "");
                                            const modalPrintBill = () => {
                                                ModalPrintBill.show({
                                                    actionPrintShowPay: () => {
                                                        const dataString = JSON.stringify(data)
                                                        const encrypted = CryptoJS.AES.encrypt(dataString, 'bill-query')
                                                        const urlEncoded = encodeURIComponent(encrypted.toString())
                                                        fetchGetMe().then((res: AxiosResponse<IV2GetAgentMe>) => {
                                                            window.open(`${routes.previewBetBill.exactPath}/${urlEncoded}?showpaid=yes&name=${res.data.username}`)
                                                        })
                                                    },
                                                    actionPrintNotShowPay: () => {
                                                        const dataString = JSON.stringify(data)
                                                        const encrypted = CryptoJS.AES.encrypt(dataString, 'bill-query')
                                                        const urlEncoded = encodeURIComponent(encrypted.toString())
                                                        fetchGetMe().then((res: AxiosResponse<IV2GetAgentMe>) => {
                                                            window.open(`${routes.previewBetBill.exactPath}/${urlEncoded}?name=${res.data.username}`)
                                                        })
                                                    },
                                                    actionCancel: () => {
                                                        ErrorModal.hide()
                                                    },
                                                })
                                            }

                                            const handleOnOpenDropdown = (target: IOptions) => {
                                                if (target.value === 'billed') {
                                                    modalPrintBill()
                                                } else if (target.value === 'bet-summary') {
                                                    history.push(`${routes.betSumHistory.path}`)
                                                } else if (target.value === 'customer-detail') {
                                                    goToCustomerDetail(data.customerId)
                                                } else if (target.value === 'member-clear') {
                                                    history.push(`${routes.financeChild.path}?agent_share_id=${data.agentShareId}`)
                                                } else if (target.value === 'customer-clear') {
                                                    history.push(`${routes.financeCustomerDetails.path}?agent_share_id=${data.agentShareId}`)
                                                } else if (target.value === 'bill-cancel') {
                                                }
                                            }

                                            return (
                                                <tr key={`betme-tx-${index}`}>
                                                    <td>{lottoName}</td>
                                                    <td>{lottoType}</td>
                                                    <td>{data.number}</td>
                                                    <td style={{ textAlign: 'right' }}>{number.addComma(data.rate)}</td>
                                                    <td style={{ textAlign: 'right' }}>{number.addComma(data.totalMeBet)}</td>
                                                    <td style={{ textAlign: 'right' }}>{number.addComma(data.totalHostBet)}</td>
                                                    <td style={{ textAlign: 'right' }}>{number.addComma(data.totalBet)}</td>
                                                    <td style={{ textAlign: 'right' }}>{number.addComma(data.totalDis)}</td>
                                                    <td style={{ textAlign: 'right' }}>{number.addComma(total)}</td>
                                                    <td style={{ textAlign: 'right' }}>{number.addComma(data.totalCom)}</td>
                                                    <td style={{ color: data.status === 'WINNER' ? colors.PRIMARY_TEXT : statusColor, fontFamily: 'SukhumvitSet-Bold', textAlign: 'center' }}>{data.status === 'WINNER' ? number.addComma((data.totalBet || 0) * (data.rate || 0)) : statusName}</td>
                                                    <td>{data.hostKeep
                                                        ? (<div className="status-keep active m-auto">{constants.active}</div>)
                                                        : (<div className="status-keep not m-auto">{constants.inActive}</div>)}</td>
                                                    <td style={{ textAlign: 'center' }}>{dateThai.formatThai(data.createdAt)}</td>
                                                    <td>
                                                        <span>
                                                            <Dropdown
                                                                options={[
                                                                    { value: 'billed', label: constants.billed, disabled: false },
                                                                    { value: 'bet-summary', label: constants.betSummary, disabled: false },
                                                                    { value: 'customer-detail', label: constants.customerDetail, disabled: false },
                                                                    { value: 'member-clear', label: constants.memberClear, disabled: false },
                                                                    { value: 'customer-clear', label: constants.customerClear, disabled: false },
                                                                    { value: 'bill-cancel', label: constants.billCancel, disabled: true },
                                                                ]}
                                                                iconOpen={pointIcon}
                                                                iconClose={pointIcon}
                                                                isManualOpenMenu
                                                                controlBackgroundColor={colors.PRIMARY_BG}
                                                                menuStyles={{ width: "175px", left: "-150px" }}
                                                                valueContainerStyles={{
                                                                    padding: "0px",
                                                                    maxWidth: "0px",
                                                                }}
                                                                handleOnChange={handleOnOpenDropdown}
                                                            />
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        }))}
                            </tbody>
                        </table>

                        <div className='row'>
                            <div className="col d-flex justify-content-end align-items-center m2-t">
                                <h6>{constants.rowPerPage}</h6>
                                <div className="dropdown-rowPerPage">
                                    <Dropdown
                                        menuPlacement={"auto"}
                                        options={[
                                            { value: 5, label: "5", disabled: false },
                                            { value: 10, label: "10", disabled: false },
                                            { value: 20, label: "20", disabled: false },
                                            { value: 50, label: "50", disabled: false },
                                            { value: 100, label: "100", disabled: false },
                                        ]}
                                        controlBackgroundColor={colors.PRIMARY_BG}
                                        placeholder={String(limit)}
                                        handleOnChange={handleOnChangeLimit}
                                    />
                                </div>
                            </div>
                            <h6 className='d-flex justify-content-end align-items-center m2-t'>
                                {`${((page - 1) * limit) + (betMeList.length > 0 ? 1 : 0)} - ${(betMeList.length < (limit * page)) ? totalRecord : (limit * page)} of ${totalRecord}`}
                            </h6>
                            <div className='col-auto m2-t'>
                                <Panginate
                                    pageTotal={total}
                                    initialPage={page - 1}
                                    pageRangeDisplayed={limit}
                                    onPageChange={handleOnChangePage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BetMeListComponent