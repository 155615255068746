import { AxiosError, AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { ActionType, createReducer } from "typesafe-actions";
import { responseConverter } from "utils";
import * as NumberBanAction from "./actionCreator";
import {
    numberBanState
} from "./constants";

export type NumberBanActionType = ActionType<typeof NumberBanAction>;

const numberBan = createReducer<ReducerState<{}>, NumberBanActionType>(numberBanState)
  .handleAction(
    NumberBanAction.getNumberBanLotterytAction.request,
    (state: ReducerState<{}>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    NumberBanAction.getNumberBanLotterytAction.success,
    (state: ReducerState<{}>, action: NumberBanActionType) => {
      const payload: AxiosResponse<IAPIResponse<IV2GetAgentHost>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
        error:"",
      };
    }
  )
  .handleAction(
    NumberBanAction.getNumberBanLotterytAction.failure,
    (state: ReducerState<{}>, action: NumberBanActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );

  const cancelNumberBan = createReducer<ReducerState<{}>, NumberBanActionType>(numberBanState)
  .handleAction(
    NumberBanAction.postCancelNumberBanAction.request,
    (state: ReducerState<{}>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    NumberBanAction.postCancelNumberBanAction.success,
    (state: ReducerState<{}>, action: NumberBanActionType) => {
      const payload: AxiosResponse<IAPIResponse<IV2GetRateReduceLotteryAgent>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
        error:"",
      };
    }
  )
  .handleAction(
    NumberBanAction.postCancelNumberBanAction.failure,
    (state: ReducerState<{}>, action: NumberBanActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );


  export default combineReducers({
    numberBan,
    cancelNumberBan
  });
  