import { get, includes, isEmpty, map, noop, orderBy } from "lodash"
import React, { Component } from "react"
import { RouteComponentProps } from "react-router"
import { Breadcrumb, ErrorModal, LottoCard } from "components"
import colors from "constants/colors"
import routes from "constants/routes"
import {
  LOTTO_FLAG_ALPHA,
  CODE_TO_NAME,
} from "constants/variables"
import { responseCode, responseMessage } from "constants/response"
import LottoFlags from "assets/images/global/flags"
import { date } from "utils"
import './bet.style.scss'
import getUnixTime from "date-fns/getUnixTime"
import { LoadingCircle } from "components"

type DefaultProps = Readonly<typeof defaultProps>
const constants = {
  closedLabel: "ปิดรับ:",
  resultLabel: "ออกผลเมื่อ: ",
  closedStatusLabel: "ปิดรับ",
  lottoShare: "หวยหุ้น",
  lottoYegee: "หวยยี่กี",
  lottoGeneral: "หวยทั่วไป",
  openedYeegeLabel: "เปิดรับ:",
  openedYeegeStatusLabel: "24 ชม.",
  round: "รอบ",
  waitingStatusLabel: "รอเปิดรับแทง",
  betTitle: "แทงหวย",
  betSubTitle: "เดิมพัน",
  placeBet: "ตารางหวย",
  bet: "การเดิมพัน",
  mainPageTitle: "ภาพรวม",
  noAccessStatusLabel: "ห้ามใช้",
};

const defaultProps: IBetContainerProps & IBetActionProps = {
  getDataLotteryList() {
    noop();
  },
  getDataV2LotteryList() {
    noop();
  },
  onChangeIsLoaddingAction() {
    noop();
  },
  getV2DataResult() { noop() },

  getV2ResultData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  getV2ResultIsFetching: false,
  getV2ResultCode: 0,
  getV2ResultError: "",

  lottoScheduleV2Data: [],
  lottoScheduleV2IsFetching: false,
  lottoScheduleV2Code: 0,
  lottoScheduleV2Error: "",
};

class BetContainer extends Component<
  IBetContainerProps & IBetActionProps & DefaultProps & RouteComponentProps
> {
  static defaultProps = defaultProps;
  state: IBetContainerState = {
    genLotto: [],
    shareLotto: [],
    yegeeLotto: [],
    targetLotto: {
      id: 0,
      startTime: "",
      endTime: "",
      mode: "",
      createdAt: "",
      code: "LOTTER_YEGEE",
      status: "UNKNOWN",
    },
    lottoStatus: "OPEN",
    reloadBet: false
  };

  componentDidMount() {
    // this.props.getDataLotteryList();

    this.props.getDataV2LotteryList()
    this.props.getV2DataResult({
      page: 1, limit: 99999
    })
  }

  componentDidUpdate(prevProps: IBetContainerProps) {
    // if (this.props.lottoScheduleIsFetching || this.props.lottoScheduleV2IsFetching) {
    //   this.props.onChangeIsLoaddingAction(true);
    // } else {
    //   this.props.onChangeIsLoaddingAction(false);
    // }

    if (prevProps.lottoScheduleV2IsFetching !== this.props.lottoScheduleV2IsFetching && !this.props.lottoScheduleV2IsFetching) {

      if (this.props.lottoScheduleV2Code !== responseCode.OK && this.props.lottoScheduleV2Error !== "") {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            this.props.lottoScheduleV2Code,
            responseMessage()[0]
          ),
        });
      }

      const lottoShare = this.props.lottoScheduleV2Data.filter(
        (lotto) => lotto.name.includes("SHARE") && !lotto.name.includes("LAO")
      );
      const lottoYegee = this.props.lottoScheduleV2Data.filter((lotto) =>
        lotto.name.includes("YEGEE")
      );
      const lottoGeneral = this.props.lottoScheduleV2Data.filter(
        (lotto) => (!lotto.name.includes("YEGEE") && !lotto.name.includes("SHARE")) || lotto.name.includes("LAO")
      );
      const sortLotto = (item: IV2RespAgentLotterySchedule) => {
        if (item.status === 'WAIT') {
          return 5
        } else {
          if (item.isOpen) {
            if (item.status === 'OPEN' && (this.checkWaitingTime(item.endTime))) {
              return (this.checkWaitingTime(item.startTime)) ? (item.name === 'GOVN' ? 1.5 : 2) : item.name === 'GOVN' ? 0.5 : 1
            } else if (item.status === 'CLOSE') {
              return 3
            } else {
              return 3
            }
          } else {
            return 4
          }
        }
      }

      this.setState({ genLotto: orderBy(lottoGeneral, [(item) => sortLotto(item), "endTime"], ['asc', 'asc']) });
      this.setState({ shareLotto: orderBy(lottoShare, [(item) => sortLotto(item), "endTime"], ['asc', 'asc']) });
      this.setState({ yegeeLotto: orderBy(lottoYegee, [(item) => sortLotto(item), "endTime"], ['asc', 'asc']) });
    }
  }

  checkWaitingTime = (time: string) => {
    return (getUnixTime(date.calibratingTime(time)) > getUnixTime(new Date()))
  }

  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.replace(path);
  };

  checkStatusLotto = (item: IV2RespAgentLotterySchedule): 'result' | 'lotto-close' | 'close' | 'open' | 'wait' => {
    if (item.isOpen) {

      if (item.status === 'OPEN') {
        if (!this.checkWaitingTime(item.endTime)) { return 'result' }
        if (!isEmpty(item.startTime) && this.checkWaitingTime(item.startTime)) {
          return 'wait'
        } else {
          return 'open'
        }
      }
      else if (item.status === 'WAIT') {
        //[ หวย ] => ปิด **ยังไม่เปิดรับแทง
        return 'lotto-close'
      }
      else if (item.status === 'CLOSE') {
        //[ หวย ] => แสดงหวยผล
        return 'result'
      }
      else {
        //[ หวย ] => ไม่เปิด
        return 'lotto-close'
      }
    } else {
      //[ หัว ] => ไม่เปิดให้  **ปิดการใช้งาน
      return 'close'
    }
  };

  sortLottoCurrentOpen = (value: TLottoSlug) => {
      const closeLotto = this.state.yegeeLotto.filter(item => getUnixTime(date.calibratingTime(item.endTime)) < getUnixTime(new Date()))
      let spliceFrist = closeLotto.splice(0, 1)[0]
      closeLotto.push(spliceFrist)
      const openLotto = this.state.yegeeLotto.filter(item => getUnixTime(date.calibratingTime(item.endTime)) > getUnixTime(new Date()))
      this.setState({yegeeLotto:[...openLotto,...closeLotto]})
  }

  renderLottoList = (lottoList: IV2RespAgentLotterySchedule[]) => {
    return map(lottoList, (lotto, index) => {
      let lottoResult: IV2LotteryResult[] = []
      if (this.checkStatusLotto(lotto) === 'result' || this.checkStatusLotto(lotto) === 'lotto-close' || this.checkStatusLotto(lotto) === 'close') {
        lottoResult = get(this.props.getV2ResultData.dataList.filter((item) => item.name === lotto.name), "[0].lottoResult", [])
      }
      /* const subtitle = constants.closedLabel; */
      // this.checkWaitingTime(lotto.startTime,lotto.name)
      const subtitle =
        // ( includes(lotto.name,"YEGEE") || this.checkWaitingTime(lotto.startTime,lotto.name) )
        (this.checkStatusLotto(lotto) === 'wait')
          ? constants.openedYeegeLabel
          : this.checkStatusLotto(lotto) === 'result' ? constants.resultLabel : constants.closedLabel

      const backgroundColor =
        lotto.status === "OPEN"
          ? colors.TERTIARY_GREEN
          : lotto.status === "NOT_ACCESS"
            ? colors.SECONDARY_RED
            : colors.TERTIARY_GREEN;

      const description =
        isEmpty(lotto.endTime)
          ? "-"
          : date.formatThai(this.checkStatusLotto(lotto) === 'wait' ? lotto.startTime : lotto.endTime, 'dd MMM yy HH:mm')

      const isCountingDown = lotto.status === "OPEN" || lotto.status === "NOT_ACCESS";

      // Faking lotto game object
      const lottoGame: ILottoGame = {
        id: 0,
        round: "",
        status: lotto.status as TGameStatus,
        createdAt: lotto.startTime,
        endTime: lotto.endTime,
        startTime: lotto.startTime,
      };

      const navigate =
        lotto.status === "OPEN" ||
          lotto.status === "NOT_ACCESS" ||
          lotto.status === "CLOSE"
          ? lotto.name === "YEGEE"
            ? () =>
              this.props.history.push(
                routes.betChildren.exactPath(lotto.name)
              )
            : () =>
              this.props.history.push(
                routes.newBetMaking.exactPath(lotto.name),
                {
                  selectedLottoGame: lottoGame,
                  targetLotto: {
                    ...lotto,
                    isCountingDown: isCountingDown,
                    closedStatusText: constants.closedStatusLabel,
                    waitingStatusText: constants.waitingStatusLabel,
                    openedStatusText: constants.openedYeegeStatusLabel,
                    description: description,
                    subtitle: subtitle,
                    backgroundColor: backgroundColor,
                    icon: FlagIcon,
                    noAccessStatusText: constants.noAccessStatusLabel,
                  },
                }
              )
          : undefined;

      const FlagIcon = get(LottoFlags[LOTTO_FLAG_ALPHA[includes(lotto.name, "YEGEE") ? "YEGEE" : lotto.name]], "Icon", '');

      const textNameLotto = includes(lotto.name, "YEGEE")
        ? `ยี่กี รอบที่ ${Number(lotto.name.replace("YEGEE_", ''))}`
        : CODE_TO_NAME[lotto.name]

      return (
        <div
          className={`col-auto lotto-box`}
          key={`lotto-${lotto.name}-${index}`}
        >
          <LottoCard
            refreshLotto={this.sortLottoCurrentOpen}
            slug={("LOTTER_" + lotto.name) as TLottoSlug}
            id={`lotto-${lotto.name}`}
            onClick={navigate}
            title={textNameLotto}
            subtitle={subtitle}
            isOpen={lotto.isOpen}
            status={lotto.status}
            isCountingdown={isCountingDown}
            closedStatusText={constants.closedStatusLabel}
            waitingStatusText={constants.waitingStatusLabel}
            openedStatusText={constants.openedYeegeStatusLabel}
            noAccessStatusText={constants.noAccessStatusLabel}
            description={description}
            expire={lotto.endTime}
            start={lotto.startTime}
            icon={FlagIcon}
            lottoResult={lottoResult}
          />
        </div>
      );
    });
  };

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.mainPageTitle, active: false, path: "/dashboard" },
      // { label: constants.bet, active: false, path: "/bet" },
      { label: constants.betTitle, active: true },
    ];

    return (
      <div className="paper-container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="sub-menu" style={{ width: "fit-content" }}>
              <Breadcrumb
                items={navigates}
                handleOnClickItem={this.handleOnClickBreadcrumb}
              />
            </div>
          </div>
        </div>
        <div className="paper-title">{constants.betTitle}</div>
        <div className="paper-description">{constants.placeBet}</div>
        <div className="paper-body">
          <div className="box-shadow bet-container">
            <div className="max-width-box">
              <div className="row mr-xl-4">
                <div className="col-12">
                  <h4>{constants.lottoGeneral}</h4>
                </div>
                {this.props.lottoScheduleV2IsFetching && ( <div className="m2-l m1-t"><LoadingCircle /> </div>)}
                {this.renderLottoList(this.state.genLotto)}
              </div>
              <div className="row m3-t mr-xl-4">
                <div className="col-12">
                  <h4>{constants.lottoShare}</h4>
                </div>
                {this.props.lottoScheduleV2IsFetching && ( <div className="m2-l m1-t"><LoadingCircle /> </div>)}
                {this.renderLottoList(this.state.shareLotto)}
              </div>
              <div className="row m3-t mr-xl-4">
                <div className="col-12">
                  <h4>{constants.lottoYegee}</h4>
                </div>
                {this.props.lottoScheduleV2IsFetching && ( <div className="m2-l m1-t"><LoadingCircle /> </div>)}
                {this.renderLottoList(this.state.yegeeLotto)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BetContainer;
