import React from "react"
import { FunctionComponent } from "react"
import "../../financeSummaryChild.style.scss"
import { number, dateThai } from "utils"
import { STATUS_FINANCE } from "constants/variables"
import { get, isEmpty } from "lodash"
import colors from "constants/colors"
import { Dropdown, LoadingCircle } from 'components'
import point from "assets/images/global/icon/point.png"
const constants = {
  list: "(จำนวนรายการ)",
  empty: "ไม่มีข้อมูล...",
  memberInfo: "ข้อมูลสมาชิก",
  betSummary: "สรุปการเดิมพัน",
}
interface ITableMembers {
  column: Array<{
    codeName: string;
    textalign: string;
  }>;
  dataList: Array<IV2RespAgentFinanceChild>;
  onClick(index: any, data: any): void;
  callBackDropdown(key: IOptions, data: IV2RespAgentFinanceChild): void
  loading: boolean
}

const Table: FunctionComponent<ITableMembers> = ({
  column,
  dataList,
  onClick,
  callBackDropdown,
  loading
}) => {
  const columns = (
    <thead>
      <tr className="tableHead">
        {column.map((data, index: number) => (
          <th key={index} className={data.textalign}>
            {index === 7 ? (
              <div className="d-flex flex-column">
                <span>{data.codeName} </span>
                <div className="remember-name" style={{ lineHeight: "8px" }}>
                  {constants.list}
                </div>
              </div>
            ) : (
              <span>{data.codeName}</span>
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
              
  const handleChildClick =( event:any) => {
    //stop onlick parent
    event.stopPropagation()
  };

  const loadingTable= (
    <tr>
      <td className="text-center" colSpan={15}>
      <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
      </td>
    </tr>
  )
  
  const rows = (
    <tbody>
      {isEmpty(dataList) ? (
        <tr>
          <td className="text-center color-row p-2" colSpan={15}>
            {constants.empty}
          </td>
        </tr>
      ) : (
        dataList.map((data, index: number) => {
          const stName = get(STATUS_FINANCE, `${data.status}.status`, "");
          const stColor = get(STATUS_FINANCE, `${data.status}.color`, "");
          const creditColor = (data: number) =>
            data > 0
              ? colors.SECONDARY_GREEN
              : data < 0
              ? colors.PRIMARY_RED
              : colors.TERTIARY_TEXT;
          return (
            <tr
              className={`row-finance-customer`}
              key={index}
              onClick={() => onClick(index, data)}
            >
              <td className="text-left paddingRow">
                <span className="pd-frist">{data.agentShareId}</span>
              </td>
              <td className="text-left">
                <span>{data.childUsername}</span>
              </td>
              <td className="text-right">
                <span>{number.addComma(number.calculator(`${data.betMe}+${data.betHost}`))}</span>
              </td>
              <td className="text-right">
                <span>{number.addComma(data.discount)}</span>
              </td>
              <td className="text-right">
                <span>{number.addComma(data.com)}</span>
              </td>
              <td className="text-right">
                <span
                  className=""
                  style={{
                    color: data.payHost === 0 ? colors.TERTIARY_TEXT : colors.SECONDARY_GREEN,
                  }}
                >
                  {number.addComma(data.payHost)}
                </span>
              </td>
              <td className="text-right">
                <span
                  className=""
                  style={{ color:  data.recieveHost === 0 ? colors.TERTIARY_TEXT : colors.PRIMARY_RED }}
                >
                  {number.addComma(data.recieveHost)}
                </span>
              </td>
              <td className="text-center">
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <span className="" style={{ color: data.totalPayWait === 0 ? colors.TERTIARY_TEXT : stColor }}>
                    {number.addComma(data.totalPayWait)}
                  </span>
                  <span
                    className=""
                    style={{ color: data.countWait === 0 ? colors.TERTIARY_TEXT : stColor }}
                  >{`(${number.addComma(data.countWait)} รายการ)`}</span>
                </div>
              </td>
              <td style={{ width: "10%" }} className="text-right">
                <span
                  className="text-status"
                  style={{
                    color: creditColor(number.calculator(`${data.payHost}-${data.recieveHost}`)),
                  }}
                >
                  {number.addComma(number.calculator(`${data.payHost}-${data.recieveHost}`))}
                </span>
              </td>
              <td className="text-center">
                <span className="text-status" style={{ color: stColor }}>
                  {stName}
                </span>
              </td>
              <td className="text-center">
                  <span>{dateThai.formatThai(data.createdAt)}</span>
              </td>
              <td className="text-left d-flex justify-content-center">
                <span onClick={handleChildClick}>
                <Dropdown
                      menuPlacement="auto"
                      options={ [{value: "memberInfo", label: "ข้อมูลสมาชิก", disabled: false}, {value: "betSumHistory", label: "สรุปการเดิมพัน", disabled: false}] }
                      iconOpen={point}
                      iconClose={point}
                      iconStyles={{width:4, height:16}}
                      hoverElementColor={colors.PRIMARY_HOVER}
                      controlBackgroundColor={colors.PRIMARY_BG}
                      menuStyles={{ width: "175px", left: "-150px" }}
                      valueContainerStyles={{padding: "0px",maxWidth: "0px",}}
                      handleOnChange={(target: IOptions) => {
                        callBackDropdown(target,data)
                      }}
                  />
                </span>
              </td>
            </tr>
          );
        })
      )}
    </tbody>
  );

  return (
    <div className="table-container">
      <table className="col ">
        {columns}
        { loading ? loadingTable : rows}
      </table>
    </div>
  );
};

export default Table;
