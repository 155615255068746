import { get } from "lodash";
import { listState, gameState } from "./constants";

const list = (state: RootReducers): ReducerState<IResponseAgentLotteryList[]> =>
  get(state, "lotto.list", listState);

const listV2 = (state: RootReducers): ReducerState<IV2RespAgentLotterySchedule[]> =>
  get(state, "lotto.listV2", listState) as any;

const game = (state: RootReducers): ReducerState<ILottoGame> =>
  get(state, "lotto.game", gameState);

const lotto = {
  list,
  listV2,
  game,
};

export default lotto;
