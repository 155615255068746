import collectBank from "assets/images/global/bank";
import {
  Breadcrumb,
  Dropdown,
  Button,
  InputNumber,
  ResponsiveIcon,
  ConfirmModal,
  ErrorModal,
  SuccessModal,
  LoadingCircle,
  LoadingButton,
} from "components";
import color from "constants/colors";
import { CODE_TO_SHORT_NAME, LOTTO_FLAG_ALPHA } from "constants/variables";
import { every, findIndex, flattenDeep, get, groupBy, includes, isEmpty, map, noop, sortBy, } from "lodash";
import React, { Component, createRef, RefObject } from "react";
import { RouteComponentProps } from "react-router-dom";
import "./numberBanManage.style.scss";
import LottoFlags from "assets/images/global/flags";
import calculateBet from "./models/calculateBet";
import { ModalNumberRate } from "components/Modal/components/ModalNumberRate";
import routes from "constants/routes";
import { fetchGetRateReduceLotteryAgent, updateRateReduceLotteryAgentUpdate } from "reduxs/numbersBan/service";
import { responseCode, responseMessage } from "constants/response";
import { AxiosError } from "axios";
import { number } from "utils";
import ConfirmNumberBanMaxhole from "components/Modal/components/ConfirmNumberBanMaxhole/ConfirmNumberBanMaxhole.component";

const constants = {
  mainPageTitle: "ภาพรวม",
  pageCloseNumber: "เลขปิดรับ",
  pageNumberBanManage: "จัดการเลขอั้น",
  pageDescription: "ปรับแต่ง เพิ่ม ลบ เลขอั้น",
  number: "เลข",
  price: "ราคา",
  rate: "อัตราจ่าย (บาท)",
  menuTool: "แถบเครื่องมือ",
  dataNotFound: "ไม่พบข้อมูล",
  fillnumber: "กรุณากรอกตัวเลข",
  twoLength: "กรุณากรอกไม่เกิน 2 ตัว",
  twoMinLength: "กรุณากรอกให้ครบ 2 ตัว",
  threeLength: "กรุณากรอกไม่เกิน 3 ตัว",
  threeMinLength: "กรุณากรอกให้ครบ 3 ตัว",
  fourLength: "กรุณากรอกไม่เกิน 4 ตัว",
  fourMinLength: "กรุณากรอกให้ครบ 4 ตัว",
  confirmGotoNumberManage: "ใช่ ไปหน้าจัดการหลุมเลข",
  confirmCloseReceivedTitle: "ยืนยันการปิดรับเลข",
  confirmCloseReceivedDetail: "คุณต้องการปิดรับเลข?",
  confirmCloseCancleReceivedTitle: "คุณต้องการยกเลิกปิดรับเลข",
  confirmCloseCancleReceivedDetail: "คุณต้องการยกเลิกการปิดรับเลข?",
  confirmMaxHoleTitle: "คุณต้องการยกเลิกปิดรับเลขเต็มหลุม",
  confirmMaxHoleDetail:
    "คุณต้องปรับขนาดหลุมให้สามารถรับแทงได้\nคุณต้องการไปปรับขนาดหลุมใช่หรือไม่?",
  successTitle: "สำเร็จ",
  successDesc: "ปรับราคาเฉพาะเลขสำเร็จ",

  modeRate: [
    { key: "THREE_UP", label: "3 บน" },
    { key: "THREE_TOAST", label: "3 โต๊ด" },
    { key: "TWO_UP", label: "2 บน" },
    { key: "TWO_DOWN", label: "2 ล่าง" },
    { key: "RUN_UP", label: "วิ่งบน" },
    { key: "RUN_DOWN", label: "วิ่งล่าง" },
  ],
  modeBet: [
    {
      key: "THREE_UP",
      label: "3 บน",
      modeButton: [
        { key: "SIXBACK", label: "6 กลับ", mode: "is6BackActivated" },
        { key: "FLIPNUMBER", label: "กลับเลข", mode: "isReverseNumActivated" },
        { key: "allType", label: "เกี่ยวหมด", mode: "isAllRelevantActivated" },
      ],
    },

    {
      key: "THREE_TOAST",
      label: "3 โต๊ด",
      modeButton: [
        { key: "SIXBACK", label: "6 กลับ", mode: "is6BackActivated" },
        { key: "FLIPNUMBER", label: "กลับเลข", mode: "isReverseNumActivated" },
        { key: "allType", label: "เกี่ยวหมด", mode: "isAllRelevantActivated" },
      ],
    },
    {
      key: "TWO_UP",
      label: "2 บน",
      modeButton: [
        { key: "DOOR19", label: "19 ประตู", mode: "is19DoorActivated" },
        { key: "FLIPNUMBER", label: "กลับเลข", mode: "isReverseNumActivated" },
        { key: "allType", label: "เกี่ยวหมด", mode: "isAllRelevantActivated" },
      ],
    },
    {
      key: "TWO_DOWN",
      label: "2 ล่าง",
      modeButton: [
        { key: "DOOR19", label: "19 ประตู", mode: "is19DoorActivated" },
        { key: "FLIPNUMBER", label: "กลับเลข", mode: "isReverseNumActivated" },
        { key: "allType", label: "เกี่ยวหมด", mode: "isAllRelevantActivated" },
      ],
    },
    {
      key: "RUN_UP",
      label: "วิ่งบน",
      modeButton: [{ key: "allType", label: "เกี่ยวหมด", mode: "isAllRelevantActivated" }],
    },

    {
      key: "RUN_DOWN",
      label: "วิ่งล่าง",
      modeButton: [{ key: "allType", label: "เกี่ยวหมด", mode: "isAllRelevantActivated" }],
    },
  ],
  lottoModeTypeList: [
    {
      key: "THREE_TYPE",
      label: "3 ตัว",
      length: 3,
      type: [
        { id: 1, name: "3 บน", type: "THREE_UP" },
        { id: 2, name: "3 โต๊ด", type: "THREE_TOAST" },
      ],
    },
    {
      key: "TWO_TYPE",
      label: "2 ตัว",
      length: 2,
      type: [
        { id: 1, name: "2 บน", type: "TWO_UP" },
        { id: 2, name: "2 ล่าง", type: "TWO_DOWN" },
      ],
    },
    {
      key: "ONE_TYPE",
      label: "เลขวิ่ง",
      length: 1,
      type: [
        { id: 1, name: "วิ่งบน", type: "RUN_UP" },
        { id: 2, name: "วิ่งล่าง", type: "RUN_DOWN" },
      ],
    },
  ],
};

type DefaultProps = Readonly<typeof defaultProps>;
const defaultProps: INumberBanManageContainerProps & INumberBanManageContainerActionProps = {
  getMe() {
    noop();
  },
  getDataV2LotteryList() {
    noop();
  },
  lottoScheduleV2Data: [],
  lottoScheduleV2IsFetching: false,
  lottoScheduleV2Code: 0,
  lottoScheduleV2Error: "",
};

export default class NumberBanManageContainer extends Component<
  DefaultProps & RouteComponentProps
> {
  static defaultProps = defaultProps;

  state: INumberBanManageContainerState = {
    modeBetType: {
      is6BackActivated: false,
      isReverseNumActivated: false,
      is19DoorActivated: false,
      isAllRelevantActivated: false,
    },
    closeReceived: {
      THREE_UP: false,
      THREE_TOAST: false,
      TWO_UP: false,
      TWO_DOWN: false,
      RUN_UP: false,
      RUN_DOWN: false,
    },

    rateReduces: {
      THREE_UP: "",
      THREE_TOAST: "",
      TWO_UP: "",
      TWO_DOWN: "",
      RUN_UP: "",
      RUN_DOWN: "",
    },
    inputNumber: "",
    lottoTypeSelect: "THREE_UP",
    listNumber: [],
    lottoModeTypeSelect: "THREE_TYPE",
    errorInput: false,
    dataRateReduceLottery: [],
    dataFillTypeIsEmpty: [],
    loadingReduceLottery: false,
    loadingUpdateReduceLottery: false,
  };

  container: RefObject<HTMLDivElement> = createRef();
  numberInputRef: RefObject<HTMLInputElement> = createRef();
  inputUpRef: RefObject<HTMLInputElement> = createRef()
  componentDidMount() {
    const { state } = this.props.location
    if (!get(state, "ratereducefacId") || !get(state, "ratereducefacAgentLotsetId") || !get(state, "name")) {
      //goback
      this.props.history.push('/numbers-ban')
    } else {
      this.props.getDataV2LotteryList(state.name);
    }
  }
  componentDidUpdate(prevProps: INumberBanManageContainerProps, prevState: INumberBanManageContainerState) {
    if (prevState.inputNumber !== this.state.inputNumber) {
      if (
        this.state.inputNumber.length > 0 &&
        this.state.inputNumber.length < this.maxLengthInput()
      ) {
        this.setState({ errorInput: true });
      } else {
        this.setState({ errorInput: false });
      }
    }
    if (prevProps.lottoScheduleV2IsFetching !== this.props.lottoScheduleV2IsFetching && !this.props.lottoScheduleV2IsFetching) {
      this.fetchGetLotteryRateReduce()
    }

  }
  maxLengthInput = () => {
    switch (this.state.lottoTypeSelect) {
      case "THREE_UP":
      case "THREE_TOAST":
        return 3;
      case "TWO_UP":
      case "TWO_DOWN":
        return 2;
      default:
        return 1;
    }
  };

  textError = (typeLotto: TLottoGameType) => {
    switch (typeLotto) {
      case "THREE_UP":
      case "THREE_TOAST":
        return constants.threeMinLength;;
      case "TWO_UP":
      case "TWO_DOWN":
        return constants.twoMinLength;;
      default:
        return constants.fillnumber;;
    }
  };

  handleQueryDropdownChange = (modeType: TLottoGameType) => {
    this.resetMode();
    this.setState({ lottoTypeSelect: modeType });
  };

  mapBetMax = (betType: TLottoGameType) => {
    const betTypeToMap: INumberBanManageRateReduce = {
      THREE_UP: "betRateThreeUp",
      THREE_TOAST: "betRateThreeToast",
      TWO_UP: "betRateTwoUp",
      TWO_DOWN: "betRateTwoDown",
      RUN_UP: "betRateRunUp",
      RUN_DOWN: "betRateRunDown"
    }
    return get(this.props.lottoScheduleV2Data[0], get(betTypeToMap, betType) as any, 0)
  }
  findDataInTable = (dataList: IV2UpdateRateReduceNumber[], rateData: IV2RateReduceNumber[]) => {
    const findDataIntable = dataList.map(item => {
      const isFound = rateData.findIndex((i) => i.ratereduceNumber === item.ratereduceNumber)
      if (isFound === -1)
        return false
      else {
        return true
      }
    })
    return (findDataIntable.every(i => i === true))
  }
  handleClickRecievedButton = (type: TLottoGameType) => {
    this.setState({
      closeReceived: {
        ...this.state.closeReceived,
        [type]: !(this.state.closeReceived)[type as keyof INumberBanManageLottoRecievedType],
      },
      rateReduces: {
        ...this.state.rateReduces,
        [type]: ""
      }
    });
  };
  fetchGetLotteryRateReduce = async () => {
    this.setState({loadingReduceLottery: true})
    const query: IV2QueryGetRateReduceLotteryAgent = {
      name: get(this.props.location.state, "name", "LOTTER_YEGEE"),
    }
    await fetchGetRateReduceLotteryAgent(query).then((res) => {
      // console.log(res)
      this.setState({ dataRateReduceLottery: get(res, "data.data", []) }, () => this.fillDataisEmpty())
    }).catch((err: AxiosError<IAPIResponse>) => {
      if (get(err, 'response.data.code', 0) !== responseCode.OK) {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            get(err, 'response.data.code', 0),
            responseMessage()[0]
          ),
        })
      }
    })
      .finally(() => {
        this.setState({loadingReduceLottery: false})
      });
  }

  fetchUpdateLotteryRateReduce = async (dataArray: IV2UpdateRateReduceNumber[], isStatusBanCancel?: boolean) => {
    this.setState({loadingUpdateReduceLottery: true})
    const query: IV2UpdateRateReduceLotteryAgent = {
      ratereducefacId: get(this.props.location.state, "ratereducefacId", 0),
      ratereducefacAgentLotsetId: get(this.props.location.state, "ratereducefacAgentLotsetId", 0),
      name: get(this.props.location.state, "name", "LOTTER_YEGEE"),
      isBanCancel: isStatusBanCancel || false,
      ratereduceArray: dataArray,
    }
    await updateRateReduceLotteryAgentUpdate(query).then((res) => {
      this.resetMode()
      SuccessModal.show({
        action: () => SuccessModal.hide(),
        title: constants.successTitle,
        description: constants.successDesc,
      });
    }).catch((err: AxiosError<IAPIResponse>) => {
      if (get(err, 'response.data.code', 0) !== responseCode.OK) {
        if (get(err, 'response.data.code', 0) === 400148) {
          let datasubmit: IV2UpdateRateReduceNumber[] = []
          datasubmit = this.submitCalculate()
          const errorArray = get(err, 'response.data.data', [])
          ModalNumberRate.show({
            action: (data) => {
              ModalNumberRate.hide();
            },
            cancelAction: () => {
              ModalNumberRate.hide();
            },
            cancelReceiveList: this.cancleObject(),
            closeReceiveList: this.state.closeReceived,
            lottoList: datasubmit,
            isResponseFailure: true,
            errorArray: errorArray,
          }
          );
        } else {
          ErrorModal.show({
            action: ErrorModal.hide,
            description: get(
              responseMessage(),
              get(err, 'response.data.code', 0),
              responseMessage()[0]
            ),
          })
        }
      }
    })
      .finally(() => {
        this.fetchGetLotteryRateReduce()
        this.setState({loadingUpdateReduceLottery: false})
      });
  }
  handleClickButtonModeBet = (type: string) => {
    const mapType = {
      SIXBACK: "is6BackActivated",
      FLIPNUMBER: "isReverseNumActivated",
      allType: "isAllRelevantActivated",
      DOOR19: "is19DoorActivated"
    }
    const keyType = get(mapType, type)
    const valueType = (this.state.modeBetType)[keyType as keyof INumberBanManageLottoModeType]
    this.setState({
      closeReceived: {
        THREE_UP: false,
        THREE_TOAST: false,
        TWO_UP: false,
        TWO_DOWN: false,
        RUN_UP: false,
        RUN_DOWN: false,
      },
      cancelReceived: {
        THREE_UP: false,
        THREE_TOAST: false,
        TWO_UP: false,
        TWO_DOWN: false,
        RUN_UP: false,
        RUN_DOWN: false,
      }
    })
    this.setState({
      modeBetType: {
        ...this.state.modeBetType,
        [keyType]: !valueType
      }
    }, () => {
      if (this.state.inputNumber.length === this.maxLengthInput()) {
        this.setState({ listNumber: this.handleCalculateNumber(this.state.rateReduces!) }, () => {
          this.handleFilterRate()
        })
      }
    })

  };
  resetMode = () => {
    this.setState({
      modeBetType: {
        is6BackActivated: false,
        isReverseNumActivated: false,
        is19DoorActivated: false,
        isAllRelevantActivated: false,
      },
      rateReduces: {
        THREE_UP: "",
        THREE_TOAST: "",
        TWO_UP: "",
        TWO_DOWN: "",
        RUN_UP: "",
        RUN_DOWN: "",
      },
      closeReceived: {
        THREE_UP: false,
        THREE_TOAST: false,
        TWO_UP: false,
        TWO_DOWN: false,
        RUN_UP: false,
        RUN_DOWN: false,
      }, cancelReceived: {
        THREE_UP: false,
        THREE_TOAST: false,
        TWO_UP: false,
        TWO_DOWN: false,
        RUN_UP: false,
        RUN_DOWN: false,
      },
      inputNumber: "",
    });
  };
  renderTableHead = () => {
    const filterType = constants.lottoModeTypeList.filter((i) => i.key === this.state.lottoModeTypeSelect);
    const mapHeadTable = map(filterType[0].type, (data) => {
      return (
        <th key={data.id} style={{ width: "33%" }}>
          <h5 className={`pb-2`}>{data.name}</h5>
        </th>
      );
    });

    return (
      <>
        <tr className="row-title-header">
          <th rowSpan={2} style={{ width: "33%" }}>
            <h5>{constants.number}</h5>
          </th>
          <th colSpan={2} style={{ width: "66%" }}>
            <h5 className={`pt-2`}>{constants.rate}</h5>
          </th>
        </tr>
        <tr className="row-title-header">{mapHeadTable}</tr>
      </>
    );
  };

  handleCalculateNumber = (values: INumberBanManageRateReduce) => {
    if (this.state.modeBetType.isAllRelevantActivated) {
      if (this.state.inputNumber.length === 3) {
        return calculateBet.all3Relevant(this.state.inputNumber, values,
          this.state.closeReceived);
      } else if (this.state.inputNumber.length === 2) {
        return calculateBet.all2Relevant(this.state.inputNumber, values,
          this.state.closeReceived);
      } else {
        return calculateBet.all1Relevant(this.state.inputNumber, values,
          this.state.closeReceived);
      }
    } else {
      if (this.state.modeBetType.is6BackActivated && this.state.inputNumber.length === 3) {
        return calculateBet.sixBack(
          this.state.inputNumber,
          values[this.state.lottoTypeSelect as keyof INumberBanManageRateReduce],
          this.state.lottoTypeSelect,
          this.state.closeReceived
        );
      } else if (this.state.modeBetType.is19DoorActivated && this.state.inputNumber.length === 2) {
        return calculateBet.nineteendoor(
          this.state.inputNumber,
          values[this.state.lottoTypeSelect as keyof INumberBanManageRateReduce],
          this.state.lottoTypeSelect,
          this.state.closeReceived
        );
      } else if (this.state.modeBetType.isReverseNumActivated) {
        return calculateBet.reverseNum(
          this.state.inputNumber,
          values[this.state.lottoTypeSelect as keyof INumberBanManageRateReduce],
          this.state.lottoTypeSelect,
          this.state.closeReceived
        );
      } else {
        let arrResult = [];
        arrResult.push({
          ratereduceBetType: this.state.lottoTypeSelect,
          ratereduceValue: Number(values[this.state.lottoTypeSelect as keyof INumberBanManageRateReduce]),
          ratereduceNumber: this.state.inputNumber,
          ratereduceIsBanned: (this.state.closeReceived)[this.state.lottoTypeSelect as keyof INumberBanManageLottoRecievedType]
        });
        return arrResult;
      }
    }
  };

  checkRatereduceValueDiff(data: IV2UpdateRateReduceNumber[]) {
    if (data.length === 0) {
      return true;
    }
    const first = data[0].ratereduceValue;
    return data.every((item) => item.ratereduceValue === first);
  }
  checkCloseRecievedAllTrue(data: IV2UpdateRateReduceNumber[]) {
    if (data.length === 0) {
      return true;
    }
    return every(data, { ratereduceIsBanned: true })
  }
  mapRatereduceValueToListNumber = (dataRate: IV2UpdateRateReduceNumber[], dataTable: IV2RateReduceNumber[]) => {
    const mapData = dataRate.map((item) => {
      const findData = dataTable.find(i => i.ratereduceNumber === get(item, "ratereduceNumber", ""))
      item.ratereduceValue = get(findData, "ratereduceValue", (this.mapBetMax(item.ratereduceBetType as TLottoGameType)).toString())
      item.ratereduceIsBanned = get(findData, "ratereduceIsBanned", false)
      return item
    })
    return mapData
  }

  handleFilterRate = async () => {
    const flattenDeepDataFill = flattenDeep(this.state.dataFillTypeIsEmpty)
    const groupByArray = groupBy(flattenDeepDataFill, "ratereduceBetType")
    const groupListNumber = groupBy(this.state.listNumber, "ratereduceBetType")

    const progressCheckRateAndCloseReceive = (type: TLottoGameType) => {
      const selectData = groupByArray[type]
      const selectType = groupListNumber[type]
      if (!isEmpty(selectData) && !isEmpty(selectType)) {
        const dataRate = this.mapRatereduceValueToListNumber(selectType, selectData)
        const findData = this.findDataInTable(selectType, selectData)
        const rate = !isEmpty(dataRate) && this.checkRatereduceValueDiff(dataRate) ? dataRate[0].ratereduceValue.toString() : ""
        const closeRecievedStatus = !isEmpty(dataRate) && this.checkCloseRecievedAllTrue(dataRate)
        this.setState({
          rateReduces: {
            ...this.state.rateReduces,
            [type]: findData ? rate : ""
          },
          closeReceived: {
            ...this.state.closeReceived,
            [type]: closeRecievedStatus
          }
        })
      }
    }
    if (this.state.modeBetType.isAllRelevantActivated) {
      async function processLotteryData() {
        const objectType: TLottoGameType[] = ["THREE_UP", "THREE_TOAST", "TWO_UP", "TWO_DOWN", "RUN_UP", "RUN_DOWN"]
        for (let i = 0; i < objectType.length; i++) {
          await progressCheckRateAndCloseReceive(objectType[i])
        }
      }
      processLotteryData()
    }
    else {
      //6กลับ กลับเเลข 19ประตู ปกติ
      progressCheckRateAndCloseReceive(this.state.lottoTypeSelect)
    }
  }
  handleCancelRecieved = (type: TLottoGameType) => {
    const flattenDeepDataFill = flattenDeep(this.state.dataFillTypeIsEmpty)
    const groupByArray = groupBy(flattenDeepDataFill, "ratereduceBetType")
    const eachtype = groupBy(this.state.listNumber, "ratereduceBetType")
    if (isEmpty(groupByArray[type]) && isEmpty(eachtype[type])) {
      return false
    } else if (!isEmpty(groupByArray[type]) && !isEmpty(eachtype[type])) {
      const mapRateToListNumber = this.mapRatereduceValueToListNumber(eachtype[type], groupByArray[type])
      if (!this.state.closeReceived[type as keyof INumberBanManageLottoRecievedType] &&
        this.findDataInTable(eachtype[type], groupByArray[type]) &&
        mapRateToListNumber.every((i: IV2UpdateRateReduceNumber) => i.ratereduceIsBanned === true) &&
        (this.state.rateReduces)![type as keyof INumberBanManageRateReduce].length === 0
      ) {
        return true
      } else {
        return false
      }
    }
  }
  submitCalculate = () => {
    const calculated = this.handleCalculateNumber(this.state.rateReduces!);
    const cancelReviedList = this.cancleObject()
    const dataTable: IV2UpdateRateReduceNumber[] = flattenDeep(this.state.dataFillTypeIsEmpty)

    //handleยกเลิกปิดรับทั้งหมด
    const handleCanCleArray = calculated.map((item: IV2UpdateRateReduceNumber) => {
      const filteritem = dataTable.find(i => i.ratereduceNumber === item.ratereduceNumber && i.ratereduceBetType === item.ratereduceBetType)
      if (cancelReviedList[item.ratereduceBetType as keyof INumberBanManageLottocanCelRecievedType]) {
        item.ratereduceValue = get(filteritem, "ratereduceValue", (this.mapBetMax(item.ratereduceBetType as TLottoGameType)))
        item.ratereduceIsBanned = false
      }
      return item
    })

    //filterData
    let ArrayWillFilter: TLottoGameType[] = []
    const objectType: TLottoGameType[] = ["THREE_UP", "THREE_TOAST", "TWO_UP", "TWO_DOWN", "RUN_UP", "RUN_DOWN"]
    for (let i = 0; i < objectType.length; i++) {
      if (((this.state.rateReduces)![objectType[i] as keyof INumberBanManageRateReduce] === "" &&
        !(this.state.closeReceived)[objectType[i] as keyof INumberBanManageLottocanCelRecievedType] &&
        !cancelReviedList[objectType[i] as keyof INumberBanManageLottocanCelRecievedType])
      ) {
        ArrayWillFilter.push(objectType[i])
      }
    }
    //filter ratereduceBetType is not in ArrayWillFilter
    let filterLottoList: IV2UpdateRateReduceNumber[] = handleCanCleArray.filter(item => !ArrayWillFilter.includes(item.ratereduceBetType as TLottoGameType));

    const resultData = filterLottoList.map((item: IV2UpdateRateReduceNumber) => {
      // handle ratereduceIsBanned is True
      if ((this.state.closeReceived)[item.ratereduceBetType as keyof INumberBanManageLottocanCelRecievedType] === true) {
        const findData = dataTable.find(i => i.ratereduceNumber === get(item, "ratereduceNumber", "") && i.ratereduceBetType === item.ratereduceBetType)
        item.ratereduceValue = get(findData, "ratereduceValue", (this.mapBetMax(item.ratereduceBetType as TLottoGameType)))
      }
      return item
    })
    return resultData

  }
  cancleObject = () => {
    const CancleObject: INumberBanManageLottocanCelRecievedType = {
      THREE_UP: this.handleCancelRecieved("THREE_UP") || false,
      THREE_TOAST: this.handleCancelRecieved("THREE_TOAST") || false,
      TWO_UP: this.handleCancelRecieved("TWO_UP") || false,
      TWO_DOWN: this.handleCancelRecieved("TWO_DOWN") || false,
      RUN_UP: this.handleCancelRecieved("RUN_UP") || false,
      RUN_DOWN: this.handleCancelRecieved("RUN_DOWN") || false,
    }
    return CancleObject
  }

  submitFormik = () => {
    let datasubmit: IV2UpdateRateReduceNumber[] = []
    datasubmit = this.submitCalculate()
    if (datasubmit.length > 0)
      ModalNumberRate.show({
        action: (data) => {
          ModalNumberRate.hide();
          this.fetchUpdateLotteryRateReduce(data)
          // console.log("data", data);
        },
        cancelAction: () => {
          ModalNumberRate.hide();
        },
        closeReceiveList: this.state.closeReceived,
        cancelReceiveList: this.cancleObject(),
        lottoList: datasubmit
      });

  };
  renderTool = () => {
    const modeSelectType = constants.modeBet.filter(
      (item) => item.key === this.state.lottoTypeSelect
    );
    const renderModeRates = this.state.modeBetType.isAllRelevantActivated
      ? constants.modeRate
      : modeSelectType;
    return (
      <>
        <div className="dropdown-border">
          <Dropdown
            menuStyles={{ width: "100%" }}
            options={constants.modeBet.map((item) => {
              return {
                value: item.key,
                label: item.label,
                disabled: false,
              };
            })}
            value={this.state.lottoTypeSelect}
            controlBackgroundColor={color.PRIMARY_BG}
            handleOnChange={(target: IOptions) => {
              this.handleQueryDropdownChange(String(target.value) as TLottoGameType);
            }}
          />
        </div>
        <div className="button-mode-group">
          {modeSelectType[0].modeButton.map((item) => {
            return (
              <React.Fragment key={item.key}>
                <div
                  className={`button-mode ${this.state.lottoTypeSelect === "RUN_UP" ||
                    this.state.lottoTypeSelect === "RUN_DOWN"
                    ? "fullWidth"
                    : ""
                    }`}
                >
                  <Button
                    id={item.key}
                    text={item.label}
                    buttonClass={`${this.state.modeBetType[item.mode as keyof INumberBanManageLottoModeType]
                      ? ""
                      : "not-active"
                      } `}
                    onClick={() => {
                      this.handleClickButtonModeBet(item.key);
                    }}
                    textColor={
                      this.state.modeBetType[item.mode as keyof INumberBanManageLottoModeType]
                        ? color.PRIMARY_BG
                        : color.PRIMARY_GREEN
                    }
                    backgroundColor={
                      this.state.modeBetType[item.mode as keyof INumberBanManageLottoModeType]
                        ? color.PRIMARY_GREEN
                        : color.PRIMARY_BG
                    }
                    borderColor={color.PRIMARY_GREEN}
                    canHover={
                      this.state.modeBetType[item.mode as keyof INumberBanManageLottoModeType]
                        ? true
                        : false
                    }
                  />
                </div>
              </React.Fragment>
            );
          })}
        </div>
        <InputNumber
          id="inputNumber"
          name="inputNumber"
          inputClassName="input-number"
          placeholder="กรอกเลข"
          value={this.state.inputNumber}
          isAllowed={({ value }) => value ? value.length <= this.maxLengthInput() : true}
          decimalScale={0}
          allowNegative={false}
          allowLeadingZeros
          backgroundColor={color.PRIMARY_BG}
          onValueChange={({ value }) => {
            this.setState({
              inputNumber: value,
            }, () => {
              this.state.inputNumber.length === this.maxLengthInput()
                ? this.setState({ listNumber: this.handleCalculateNumber(this.state.rateReduces!) }, () => {
                  this.handleFilterRate()
                })
                : this.setState({
                  listNumber: [],
                  closeReceived: {
                    THREE_UP: false,
                    THREE_TOAST: false,
                    TWO_UP: false,
                    TWO_DOWN: false,
                    RUN_UP: false,
                    RUN_DOWN: false,
                  },
                })
            });
          }}
          errorMessage={this.textError(this.state.lottoTypeSelect)}
          error={this.state.errorInput}
        />

        <div className="price-container m2-t">
          <div className="title m2-b">
            <h5>{constants.price}</h5>
          </div>
          {renderModeRates.map((item) => {
            return (
              <div className="row price-box" key={item.key}>
                <div className="col-2  mt-2">
                  <p>{item.label}</p>
                </div>
                <div className="col-10 ">
                  <div className="input-number-modebet">
                    {(this.state.closeReceived)[item.key as keyof INumberBanManageLottoRecievedType] ? (
                      <>
                        <InputNumber
                          id={`input_${item.key}`}
                          name={`input_${item.key}`}
                          inputClassName={"input-number close-receive"}
                          placeholder={"ปิดรับเลข"}
                          allowNegative={false}
                          allowLeadingZeros={false}
                          backgroundColor={color.PRIMARY_RED}
                          value={"ปิดรับเลข"}
                          disabled={true}
                        />
                        <div className="close-received">
                          <Button
                            id={"ยกเลิกปิดรับ"}
                            text={"ยกเลิกปิดรับ"}
                            size="small"
                            textColor={color.SECONDARY_TEXT}
                            backgroundColor={color.PRIMARY_BG}
                            backgroundHoverColor={color.SECONDARY_BG}
                            onClick={() => {
                              ConfirmModal.show({
                                action: () => {
                                  ConfirmModal.hide();
                                  this.handleClickRecievedButton(item.key as TLottoGameType);
                                },
                                cancelAction: () => {
                                  ConfirmModal.hide();
                                },
                                title: constants.confirmCloseCancleReceivedTitle,
                                description: constants.confirmCloseCancleReceivedDetail,
                                acceptText: "ยืนยัน"
                              });
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <InputNumber
                          id={`input_${item.key}`}
                          name={`input_${item.key}`}
                          inputClassName={"input-number"}
                          placeholder={"กรอกราคาจ่าย"}
                          decimalScale={2}
                          thousandSeparator={true}
                          allowNegative={false}
                          allowLeadingZeros={false}
                          value={((this.state.rateReduces)![item.key as keyof INumberBanManageRateReduce])}
                          onValueChange={({ value }) => {
                            return (
                              this.setState({
                                rateReduces: {
                                  ...this.state.rateReduces,
                                  [item.key]: value.toString()
                                },
                              })
                            )
                          }}
                          backgroundColor={color.PRIMARY_BG}
                          borderColor={color.PRIMARY_GREEN}

                        />
                        <div className="btn-close-receive">
                          <Button
                            id={"ปิดรับ"}
                            text={"กดเพื่อปิดรับ"}
                            size="small"
                            onClick={() => {
                              ConfirmModal.show({
                                action: () => {
                                  ConfirmModal.hide();
                                  this.handleClickRecievedButton(item.key as TLottoGameType);
                                },
                                cancelAction: () => {
                                  ConfirmModal.hide();
                                },
                                title: constants.confirmCloseReceivedTitle,
                                description: constants.confirmCloseReceivedDetail,
                                acceptText: "ยืนยัน"
                              });
                            }}
                            textColor={color.PRIMARY_BG}
                            backgroundColor={color.PRIMARY_RED}
                            backgroundHoverColor={color.SECONDARY_RED}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          <div className="m2-t">
            <LoadingButton
              loading={this.state.loadingUpdateReduceLottery}
              id={"บันทึก"}
              text={"บันทึก"}
              buttonType="submit"
              onClick={() => {
                this.submitFormik();
              }}
              textColor={color.PRIMARY_BG}
              backgroundColor={color.PRIMARY_GREEN}
              borderColor={color.PRIMARY_GREEN}
              disabled={
                this.state.inputNumber.length < this.maxLengthInput() ||
                (this.state.modeBetType.isAllRelevantActivated
                  ?
                  this.state.rateReduces!.THREE_UP === "" &&
                  this.state.rateReduces!.THREE_TOAST === "" &&
                  this.state.rateReduces!.TWO_UP === "" &&
                  this.state.rateReduces!.TWO_DOWN === "" &&
                  this.state.rateReduces!.RUN_UP === "" &&
                  this.state.rateReduces!.RUN_DOWN === "" &&

                  !this.state.closeReceived.THREE_UP &&
                  !this.state.closeReceived.THREE_TOAST &&
                  !this.state.closeReceived.TWO_UP &&
                  !this.state.closeReceived.TWO_DOWN &&
                  !this.state.closeReceived.RUN_UP &&
                  !this.state.closeReceived.RUN_DOWN &&
                  !this.cancleObject().THREE_UP &&
                  !this.cancleObject().THREE_TOAST &&
                  !this.cancleObject().TWO_UP &&
                  !this.cancleObject().TWO_DOWN &&
                  !this.cancleObject().RUN_UP &&
                  !this.cancleObject().RUN_DOWN
                  : !(this.state.closeReceived)[this.state.lottoTypeSelect as keyof INumberBanManageLottoRecievedType] &&
                  ((this.state.rateReduces)![this.state.lottoTypeSelect as keyof INumberBanManageRateReduce]).length === 0 &&
                  !(this.cancleObject())[this.state.lottoTypeSelect as keyof INumberBanManageLottocanCelRecievedType])
              }
            />
          </div>
        </div>
      </>
    );
  };

  fillDataisEmpty = () => {
    const ratereduceThreeUp = get(this.state.dataRateReduceLottery[0], 'ratereduceThreeUp', [])
    const ratereduceThreeToast = get(this.state.dataRateReduceLottery[0], 'ratereduceThreeToast', [])
    const ratereduceTwoUp = get(this.state.dataRateReduceLottery[0], 'ratereduceTwoUp', [])
    const ratereduceTwoDown = get(this.state.dataRateReduceLottery[0], 'ratereduceTwoDown', [])
    const ratereduceRunUp = get(this.state.dataRateReduceLottery[0], 'ratereduceRunUp', [])
    const ratereduceRunDown = get(this.state.dataRateReduceLottery[0], 'ratereduceRunDown', [])
    const groupArray = [...ratereduceThreeUp, ...ratereduceThreeToast, ...ratereduceTwoUp, ...ratereduceTwoDown, ...ratereduceRunUp, ...ratereduceRunDown]
    const groupByArray = groupBy(groupArray, "ratereduceNumber")
    const groupData = Object.values(groupByArray)
    const getOtherLottoType = (item: TLottoGameType) => {
      const lottoTypes: Record<TLottoGameType, TLottoGameType> = {
        THREE_UP: 'THREE_TOAST',
        THREE_TOAST: 'THREE_UP',
        TWO_UP: 'TWO_DOWN',
        TWO_DOWN: 'TWO_UP',
        RUN_UP: 'RUN_DOWN',
        RUN_DOWN: 'RUN_UP',
        FOUR_SUITE: 'TWO_UP',
      }
      return lottoTypes[item]
    }
    const resultGroupData = groupData.map(items => {
      if (items.length < 2) {
        let objectData =
        {
          ratereduceId: -1,
          ratereduceRatereducefacId: -1,
          ratereduceBetType: getOtherLottoType(items[0].ratereduceBetType as TLottoGameType),
          ratereduceNumber: items[0].ratereduceNumber,
          ratereduceValue: this.mapBetMax(getOtherLottoType(items[0].ratereduceBetType as TLottoGameType)),
          ratereduceIsNumholFull: false,
          ratereduceIsBanned: false,
          ratereduceIsHostBanned: false,
          ratereduceCreatedAt: new Date(),
          ratereduceUpdatedAt: new Date(),
        }
        if (objectData.ratereduceBetType.includes("UP")) {
          items = [objectData, ...items]
        } else {
          items = [...items, objectData]
        }
      }
      return items
    })

    const groubByNumber = groupBy(flattenDeep(resultGroupData), "ratereduceNumber")
    const sortData = sortBy(Object.values(groubByNumber), (i => get(i, "[0].ratereduceNumber", 0)))
    this.setState({ dataFillTypeIsEmpty: sortData })
  }
  renderTableBody = () => {
    const dataEmpty = (
      <tr className="tableisEmpty" style={{ height: 42.67 }}>
        <td className="text-center tertiary-text" colSpan={3}>
          {constants.dataNotFound}
        </td>
      </tr>
    )

    const loadingTable= (
      <tr>
        <td className="text-center" colSpan={3}>
        <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
        </td>
      </tr>
    )

    const checkStatusNumber = (item: IV2RateReduceNumber) => {
      if (item.ratereduceIsHostBanned) {
        return "close-from-host"
      } else if (item.ratereduceIsBanned) {
        return "closed";
      } else if (item.ratereduceIsNumholFull) {
        return "max-hole";
      } else if (item.ratereduceValue !== this.mapBetMax(item.ratereduceBetType)) {
        return "red"
      } else {
        return "";
      }
    };
    const checkTextNumber = (item: IV2RateReduceNumber) => {
      if (item.ratereduceIsHostBanned) {
        return "ปิดรับจากหัว";
      } else if (item.ratereduceIsBanned) {
        return "ปิดรับ";
      } else if (item.ratereduceIsNumholFull) {
        return "เต็มหลุม";
      } else {
        return number.decimalsFormatCommaWithOutZero(item.ratereduceValue);
      }
    };
    const checkStatusBothNumber = (item: IV2RateReduceNumber[]) => {
      const itemFirstCol = get(item, "[0]", [])
      const itemSecondCol = get(item, "[1]", [])
      const isHostBanned = (itemFirstCol as any).ratereduceIsHostBanned && (itemSecondCol as any).ratereduceIsHostBanned;
      const isBothBanned = (itemFirstCol as any).ratereduceIsBanned && (itemSecondCol as any).ratereduceIsBanned;
      const isBothNumholFull = (itemFirstCol as any).ratereduceIsNumholFull && (itemSecondCol as any).ratereduceIsNumholFull;

      if (isHostBanned) {
        return "close-from-host";
      }
      else if (isBothBanned && !(itemFirstCol as any).ratereduceIsHostBanned && !(itemSecondCol as any).ratereduceIsHostBanned) {
        return "closed";
      }
      else if (isBothNumholFull && !(itemFirstCol as any).ratereduceIsBanned && !(itemSecondCol as any).ratereduceIsBanned && !(itemFirstCol as any).ratereduceIsHostBanned && !(itemSecondCol as any).ratereduceIsHostBanned) {
        return "max-hole";
      }
      return "";
    };

    const handleClickNumber = (item: IV2RateReduceNumber, isNumberSelect?: boolean) => {
      if (item.ratereduceIsBanned && !item.ratereduceIsHostBanned && !isNumberSelect) {
        ConfirmModal.show({
          action: () => {
            ConfirmModal.hide();
            const queryObject = {
              ratereduceBetType: item.ratereduceBetType,
              ratereduceNumber: item.ratereduceNumber,
              ratereduceValue: item.ratereduceValue,
              ratereduceIsBanned: false
            }
            this.fetchUpdateLotteryRateReduce([queryObject])
          },
          cancelAction: () => {
            ConfirmModal.hide();
          },
          acceptText: "ยืนยัน",
          title: constants.confirmCloseCancleReceivedTitle,
          description: constants.confirmCloseCancleReceivedDetail,
        });
      }
      else if (item.ratereduceIsNumholFull && !item.ratereduceIsHostBanned && !item.ratereduceIsBanned && !isNumberSelect) {
        ConfirmNumberBanMaxhole.show({
          action: () => {
            this.props.history.push(routes.numbersHolesManage.path, {
              agentLotsetId: get(this.props.location.state, "ratereducefacAgentLotsetId", 0),
              type: (item.ratereduceBetType),
              number: (item.ratereduceNumber),
              name: get(this.props.location.state, "name", "LOTTER_YEGEE")
            });
            ConfirmNumberBanMaxhole.hide();
          },
          cancelAction: () => {
            ConfirmNumberBanMaxhole.hide();
          },
          acceptText: constants.confirmGotoNumberManage,
          title: constants.confirmMaxHoleTitle,
          description: constants.confirmMaxHoleDetail,
          whiteSpace: "pre-line"
        });
      }
      else {
        const betType = item.ratereduceBetType

        this.setState({
          inputNumber: item.ratereduceNumber,
          rateReduces: {
            ...this.state.rateReduces,
            [betType]: item.ratereduceValue.toString()
          },
          lottoTypeSelect: item.ratereduceBetType,
        })
      }
    };
    const filterMode = constants.lottoModeTypeList.filter(i => i.key === this.state.lottoModeTypeSelect)
    const lengthType = filterMode[0].length
    const filterData = this.state.dataFillTypeIsEmpty.filter((item: IV2UpdateRateReduceNumber) =>
      get(item, "[0]ratereduceNumber.length", 0) === lengthType
    )
    const mapData = map(filterData, (item: IV2RateReduceNumber[], index) => {
      const IndexUp = get(item, "[0]ratereduceBetType", '').includes("UP") ? 0 : 1
      const IndexDown = IndexUp === 0 ? 1 : 0
      const result = findIndex(this.state.listNumber, ["ratereduceNumber", get(item, "[0]ratereduceNumber")]);

      return (
        <tr className={`row-number-item ${result !== -1 ? "found-item" : ""}`} key={index}>
          <td className="text-center tertiary-text">
            <h5 className={`number ${checkStatusBothNumber(item)}`} onClick={() => handleClickNumber(item[IndexUp], true)}>{item[0].ratereduceNumber}</h5>
          </td>
          <td className="text-center tertiary-text ">
            <div className="item-number" onClick={() => handleClickNumber(item[IndexUp])}>
              <h5 className={`${checkStatusNumber(item[IndexUp])}`}>{checkTextNumber(item[IndexUp])}</h5>
            </div>
          </td>
          <td className="text-center tertiary-text ">
            <div className="item-number" onClick={() => handleClickNumber(item[IndexDown])}>
              <h5 className={`${checkStatusNumber(item[IndexDown])}`}>{checkTextNumber(item[IndexDown])}</h5>
            </div>
          </td>
        </tr>
      );
    });
    return this.state.loadingReduceLottery ? loadingTable : !isEmpty(filterData) ? mapData : dataEmpty
  };

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.mainPageTitle, active: false, path: "/dashboard" },
      { label: constants.pageCloseNumber, active: false, path: "/numbers-ban" },
      { label: constants.pageNumberBanManage, active: true },
    ];
    const TableHead = this.renderTableHead();
    const TableBody = this.renderTableBody();
    const RenderTool = this.renderTool();

    const slug = ("LOTTER_" + get(this.props.location.state, "name", "LOTTER_YEGEE")) as TLottoSlug;
    const flag_bank =
      Object.keys(collectBank).findIndex((item) => "LOTTER_" + item === slug) === -1
        ? ""
        : "flag-bank";

    return (
      <div className="number-ban-manage-container" ref={this.container}>
        <div className="paper-container">
          <div className="paper-body">
            <div className="d-flex sub-menu" style={{ width: "fit-content" }}>
              <Breadcrumb
                items={navigates}
                handleOnClickItem={(path: string) => this.props.history.replace(path)}
              />
            </div>
            <div className="paper-title">{constants.pageNumberBanManage}</div>
            <div className="paper-description">{constants.pageDescription}</div>
            <div className="paper-body">
              <div className="row">
                <div className="col-4">
                  <div className="box-shadow">
                    <div className="title-tool">{constants.menuTool}</div>
                    <>
                      {RenderTool}
                    </>
                  </div>
                </div>
                <div className="col-8">
                  <div className="box-shadow">
                    <div className="box-icon">
                      <ResponsiveIcon
                        icon={
                          get(
                            LottoFlags[
                            LOTTO_FLAG_ALPHA[
                            includes(get(this.props.location.state, "name", "LOTTER_YEGEE"), "YEGEE")
                              ? "YEGEE"
                              : (get(this.props.location.state, "name", "LOTTER_YEGEE") as TLottoType)
                            ]
                            ],
                            "Icon",
                            ""
                          )!
                        }
                        alt="flag"
                        className={`lotto-action-card-flag ${flag_bank} ${slug} `}
                      />
                      <h5 className="lotto-header-item">
                        {includes(get(this.props.location.state, "name", "LOTTER_YEGEE"), "YEGEE")
                          ? `ยี่กี รอบที่ ${Number(get(this.props.location.state, "name", "LOTTER_YEGEE").replace("YEGEE_", ""))}`
                          : CODE_TO_SHORT_NAME[get(this.props.location.state, "name", "LOTTER_YEGEE") as TLottoType]}
                      </h5>

                    </div>
                    <div className="head-title-mode">
                      {constants.lottoModeTypeList.map((item) => {
                        return (
                          <div
                            key={item.key}
                            className={`title-mode ${this.state.lottoModeTypeSelect === item.key ? "open" : ""
                              }`}
                            onClick={() => this.setState({ lottoModeTypeSelect: item.key })}
                          >
                            {item.label}
                          </div>
                        );
                      })}
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="table-container">
                          <div className="table-responseive-xl">
                            <table className="w-100 m2-t">
                              <thead className="header-table">{TableHead}</thead>
                              <tbody>{TableBody}</tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
