import {
  Input,
  InputNumber,
  Button,
  SuccessModal,
  ErrorModal,
  ConfirmModal,
} from "components";
import color from "constants/colors";
import { FormikProps } from "formik";
import React, { Component } from "react";
import "./financeInfo.style.scss";
import Select from "react-select";
import { createMemberDropdownStyles } from "containers/CreateMember/CreateMemberDropdown.styles";
import { findIndex, forOwn, get, isEmpty, mapValues } from "lodash";
import { BANK_TYPE, meDataInitialValue } from "constants/variables";
import { date, number } from "utils";
import ImageBankSet from "assets/images/global/bank";
import { th } from "date-fns/locale";
import { format } from "date-fns";
import { ENG_THAI_AND_SPACE } from "constants/regex";
import routes from "constants/routes";
import { responseCode, responseMessage } from "constants/response";

const constants = {
  limitCredit: "จำกัดเครดิต *",
  limitCreditPlaceholder: (credit: number) =>
    ` / ${number.addComma(credit)} บาท`,
  minBet: "จำกัดแทงต่ำสุด *",
  maxBet: "จำกัดแทงสูงสุด *",
  minBetPlaceholder: (setBetMinLimit: number) =>
    ` / ${number.addComma(setBetMinLimit)} บาท`,
  maxBetPlaceholder: (setBetMaxLimit: number) =>
    ` / ${number.addComma(setBetMaxLimit)} บาท`,
  commission: "คอมมิชชั่น % *",
  bankName: "ชื่อบัญชีธนาคาร *",
  bankNumber: "หมายเลขบััญชีธนาคาร *",
  bank: "ธนาคาร *",
  bankNamePlaceholder: "ชื่อบัญชีธนาคาร",
  bankNumberPlaceholder: "หมายเลขบัญชีธนาคาร",
  bankPlaceholder: "เลือกธนาคาร",
  betMinOverLimit: "แทงต่ำสุดต้องน้อยกว่าลิมิตที่กำหนดไว้",
  titleFinanceInfo: "ข้อมูลการเงิน",
  titleFinanceInfoForMemberDetail: "ตั้งค่าหวย",
  AltTitleFinanceInfo: "ข้อมูลการเดิมพัน",
  lastUpdate: (time: string) => `ข้อมูลเปลี่ยนแปลงล่าสุด ${time}`,
  editFinanceInfo: "แก้ไขข้อมูล",
  resetFinanceINfo: "รีเซ็ตเป็นค่าเดิม",
  resetFinanceInfoSuccess: "รีเซ็ตเป็นค่าเดิมสำเร็จ",
  postOwnerSettingSuccess: "แก้ไขข้อมูลการเดิมพันสำเร็จ",
  postChildSettingAndCreditUpdateSuccess: "แก้ไขข้อมูลลูกสมาชิกสำเร็จ",
  postChildSettingAndCreditUpdateFailure: "แก้ไขข้อมูลลูกสมาชิกไม่สำเร็จ",
  noInfo: "ไม่มีข้อมูล",
  baht: "บาท",
  betMinPlaceholder: "100 บาท",
  betMaxPlaceholder: "15,000,000 บาท",
  comPlaceholder: "50 %"
};

const defaultProps: IFinanceInfoProps = {
  meSettingData: {
    agentId: 0,
    setBetMaxLimit: 100,
    setBetMinLimit: 1,
    setComPercentage: 0,
  },
  meData: meDataInitialValue,
  isOtpClicked: false,
};

class FinanceInfoComponent extends Component<
  FormikProps<ICreateMember | IMemberDetailFormikProps> & IFinanceInfoProps,
  IFinanceInfoState
> {
  static defaultProps = defaultProps;
  state: IFinanceInfoState = {
    isBankTypeSelected: false,
    isEditable: false,
  };

  componentDidUpdate(prevProps: IFinanceInfoProps) {
    if (
      prevProps.ownerSettingIsFetching !== this.props.ownerSettingIsFetching &&
      !this.props.ownerSettingIsFetching
    ) {
      if (this.props.ownerSettingCode === responseCode.OK) {
        this.props.getMeSetting!();
        SuccessModal.show({
          action: () => SuccessModal.hide(),
          description: constants.postOwnerSettingSuccess,
        });
      } else {
        ErrorModal.show({
          action: () => ErrorModal.hide(),
          description: responseMessage()[this.props.ownerSettingCode || 0],
        });
      }
    }
  }

  formatBankTypeSelect(): IOptions[] {
    let formatBankTypeSelect: any[] = [];
    forOwn(BANK_TYPE, function (values, keys) {
      const ImageIcon = get(ImageBankSet, `${keys}.Icon`, "");
      formatBankTypeSelect.push({
        label: (
          <div className="d-flex flex-row">
            {!isEmpty(ImageIcon) && (
              <img src={ImageIcon} alt="bank" className="bank-image" />
            )}
            <div>{values}</div>
          </div>
        ),
        value: keys,
        disabled: false,
        searchableName: `${keys} ${values}`,
      });
    });
    return formatBankTypeSelect;
  }

  renderFinanceInfoTitle = () => {
    const { childDetailData, meData } = this.props;
    if (this.props.mode === "UPDATE") {
      return (
        <div className="d-flex flex-row justify-content-between m3-b">
          <div className="d-flex flex-column">
            <div className="inline pr-3 paper-body-title">
              <div className="titleText">
                {window.location.pathname === routes.userDetail.path
                ? constants.AltTitleFinanceInfo
                : window.location.pathname === routes.memberDetail.path
                ? constants.titleFinanceInfoForMemberDetail
                : constants.titleFinanceInfo}
                </div>
            </div>
            <div className="content-text m1-t">
              {constants.lastUpdate(
                format(
                  date.calibratingTime(
                    window.location.pathname === routes.memberDetail.path
                      ? childDetailData![0]?.childUpdatedAt
                      : get(meData, "updatedAt", ""),
                    true
                  ),
                  "d MMMM yyyy HH:mm",
                  { locale: th }
                )
              )}
            </div>
          </div>
          {!this.state.isEditable &&
            get(this.props.meData, "permission", "") === "OWNER" && (
              <div>
                <Button
                  id="edit-finance-info"
                  text={constants.editFinanceInfo}
                  borderRadius={"8px"}
                  buttonClass={"edit-finance-info-button"}
                  backgroundColor={color.PRIMARY_GREEN}
                  textSize={18}
                  backgroundHoverColor={color.SECONDARY_GREEN}
                  onClick={() => {
                    this.setState({ isEditable: true });
                  }}
                />
              </div>
            )}
        </div>
      );
    }

    return (
      <div className="d-flex flex-row">
        <div className="d-flex flex-column">
          <div className="inline pr-3 paper-body-title">
            {constants.titleFinanceInfo}
          </div>
        </div>
      </div>
    );
  };

  submitFinanceInfo = () => {
    const { errors, touched, childDetailData, values } = this.props;
    if (
      !(
        (!!errors.betMaxLimit && touched.betMaxLimit) ||
        (!!errors.betMinLimit && touched.betMinLimit) ||
        (!!errors.com && touched.com) ||
        (!!errors.creditLimit && touched.creditLimit)
      )
    ) {
      this.setState({ isEditable: false });
      if (window.location.pathname === routes.userDetail.path) {
        this.props.postOwnerSetting!({
          setBetMaxLimit: Number(values.betMaxLimit),
          setBetMinLimit: Number(values.betMinLimit),
          setComPercentage: Number(values.com),
        });
      } else if (window.location.pathname === routes.memberDetail.path) {
        Promise.all([
          this.props.postChildSettingUpdate!({
            childAgentId: childDetailData![0].childAgentId,
            childSetBetMaxLimit: Number(values.betMaxLimit),
            childSetBetMinLimit: Number(values.betMinLimit),
            childSetComPercentage: Number(values.com),
          }),
          this.props.postChildCreditUpdate!({
            childAgentId: childDetailData![0].childAgentId,
            childCreditLimit: Number(values.creditLimit),
          }),
        ])
          .then(() => {
            this.props.getMeSetting!()
            this.props.getChildDetail!({
              limit: 10,
              page: 1,
              childAgentId: childDetailData![0].childAgentId
            })
            SuccessModal.show({
              action: () => SuccessModal.hide(),
              description: constants.postChildSettingAndCreditUpdateSuccess,
            });
          })
          .catch(() => {
            ErrorModal.show({
              action: () => SuccessModal.hide(),
              description: constants.postChildSettingAndCreditUpdateFailure
            })
          });
      }
    }
  };

  resetFinanceInfo = () => {
    const { setValues, childDetailData, values, meSettingData } = this.props;
    if (window.location.pathname === routes.userDetail.path) {
      setValues({
        ...values,
        betMinLimit:
          get(meSettingData, "setBetMinLimit", 0) > 1000
            ? 1000
            : get(meSettingData, "setBetMinLimit", 0),
        betMaxLimit:
          get(meSettingData, "setBetMaxLimit", 0) > 1000
            ? 1000
            : get(meSettingData, "setBetMaxLimit", 0),
        com: get(meSettingData, "setComPercentage", 0),
      });
    } else {
      setValues({
        ...values,
        creditLimit: get(childDetailData, "[0].childCreditLimit", 0),
        betMinLimit:
          get(childDetailData, "[0].childSetBetMinLimit", 0) > 1000
            ? 1000
            : get(childDetailData, "[0].childSetBetMinLimit", 0),
        betMaxLimit:
          get(childDetailData, "[0].childSetBetMaxLimit", 0) > 1000
            ? 1000
            : get(childDetailData, "[0].childSetBetMaxLimit", 0),
        com: get(childDetailData, "[0].childSetComPercentage", 0),
        bankName: get(childDetailData, "[0].childBankName", ""),
        bankType: get(childDetailData, "[0].childBankType", "") as any,
        bankNumber: get(childDetailData, "[0].childBankNumber", ""),
      });
    }

    this.setState({ isEditable: false }, () => {
      SuccessModal.show({
        action: () => SuccessModal.hide(),
        description: constants.resetFinanceInfoSuccess,
      });
    });
  };

  render() {
    const {
      values,
      handleBlur,
      errors,
      touched,
      setFieldValue,
      validateField,
      meSettingData,
      isOtpClicked,
    } = this.props;

    const bankTypeIndex = findIndex(
      this.formatBankTypeSelect(),
      (item: IOptions) => item.value === values.bankType
    );

    const FinanceInfoTitle = this.renderFinanceInfoTitle;

    return (
      <div className="box-shadow finance-info-container">
        <FinanceInfoTitle />
        {window.location.pathname === routes.userDetail.path ? (
          <></>
        ) : (
          <div className="row">
            <div className="col-6">
              <label className="label-input">{constants.limitCredit}</label>
              <div className="d-flex flex-row">
                <div className="creditLimitWrapper w-100">
                  <InputNumber
                    onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value === '0')
                        setFieldValue("creditLimit", '');
                    }}
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value === '')
                        setFieldValue("creditLimit", '0');
                    }}
                    id="creditLimit"
                    name="creditLimit"
                    placeholder={""}
                    value={String(values.creditLimit)}
                    onValueChange={(values) => {
                      setFieldValue("creditLimit", values.value);
                    }}
                    inputClassName="input-limit-credit"
                    backgroundColor={color.TRANSPARENT}
                    allowNegative={false}
                    decimalScale={2}
                    allowLeadingZeros={false}
                    thousandSeparator={true}
                    disabled={
                      this.props.mode === "UPDATE"
                        ? !this.state.isEditable
                        : !isOtpClicked
                    }
                    renderError={false}
                    mode="InputElements"
                    endText={constants.limitCreditPlaceholder(
                      this.props.meData!.credit
                    )}
                    error={!!errors.creditLimit && touched.creditLimit}
                  />
                </div>
              </div>
              <h6 className="primary-red-text mt-1">
                {!!errors.creditLimit && touched.creditLimit
                  ? errors.creditLimit
                  : "\u00A0"}
              </h6>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-4" style={{ marginTop: 6 }}>
            <label className="label-input">{constants.minBet}</label>
            <div className="d-flex flex-row">
              <div className="betMinLimitWrapper w-100">
                <InputNumber
                  id="betMinLimit"
                  name="betMinLimit"
                  placeholder={constants.betMinPlaceholder}
                  value={
                    values.betMinLimit === 0 ? "" : String(values.betMinLimit)
                  }
                  onBlur={(e:React.ChangeEvent<HTMLInputElement>)=>{
                    ConfirmModal.show({
                      action: () => {
                        localStorage.setItem("lotterySettingState",JSON.stringify(
                            get(values,"lotterySetting",[]).map((i:IV2LotterySetting)=>{
                              return mapValues(
                                { ...i, isNotSavedYet:false },
                                (val, key, obj) => {
                                  if (
                                    key.includes("isSaved") ||
                                    key.includes("isNotSavedYet") ||
                                    key.includes("childLotsetName") ||
                                    key.includes("childLotsetKeep") ||
                                    key.includes("childLotsetIsOpen")
                                  ) {
                                    return ((obj as any)[key] = val);
                                  } else {
                                    return ((obj as any)[key] = Number(val));
                                  }
                                }
                              );
                            }),
                        ))
                        ConfirmModal.hide();
                      },
                      cancelAction: () => {
                        ConfirmModal.hide();
                        setFieldValue("lotterySetting",JSON.parse(localStorage.getItem("lotterySettingState") || ""))
                      },
                      description: "การตั้งค่าหวยจะถูกเปลี่ยนทั้งหมด",
                    });
                    handleBlur(e)
                  }}
                  onValueChange={(values) => {
                    setFieldValue("betMinLimit", values.value);
                  }}
                  allowLeadingZeros={false}
                  allowNegative={false}
                  thousandSeparator
                  inputClassName="input-limit-credit"
                  backgroundColor={color.TRANSPARENT}
                  disabled={
                    this.props.mode === "UPDATE"
                      ? !this.state.isEditable
                      : !isOtpClicked
                  }
                  decimalScale={2}
                  renderError={false}
                  mode="InputElements"
                  endText={this.props.meData?.permission === "OWNER" && window.location.pathname === routes.userDetail.path ? constants.baht : constants.minBetPlaceholder(
                    values.betMaxLimit
                  )}
                  error={!!errors.betMinLimit && touched.betMinLimit}
                />
              </div>
            </div>
            <h6 className="primary-red-text mt-1">
              {!!errors.betMinLimit && touched.betMinLimit
                ? errors.betMinLimit
                : "\u00A0"}
            </h6>
          </div>
          <div className="col-4" style={{ marginTop: 6 }}>
            <label className="label-input">{constants.maxBet}</label>
            <div className="d-flex flex-row">
              <div className="betMaxLimitWrapper w-100">
                <InputNumber
                  id="betMaxLimit"
                  name="betMaxLimit"
                  placeholder={constants.betMaxPlaceholder}
                  allowLeadingZeros={false}
                  allowNegative={false}
                  value={
                    values.betMaxLimit === 0 ? "" : String(values.betMaxLimit)
                  }
                  onBlur={handleBlur}
                  onValueChange={(values) => {
                    setFieldValue("betMaxLimit", values.value);
                  }}
                  inputClassName="input-limit-credit"
                  backgroundColor={color.TRANSPARENT}
                  disabled={
                    this.props.mode === "UPDATE"
                      ? !this.state.isEditable
                      : !isOtpClicked
                  }
                  thousandSeparator
                  decimalScale={2}
                  mode="InputElements"
                  renderError={false}
                  endText={this.props.meData?.permission === "OWNER" && window.location.pathname === routes.userDetail.path ? constants.baht : constants.maxBetPlaceholder(
                    meSettingData!.setBetMaxLimit
                  )}
                  error={!!errors.betMaxLimit && touched.betMaxLimit}
                />
              </div>
            </div>
            {!!errors.betMaxLimit && touched.betMaxLimit && (
              <h6 className="primary-red-text mt-1">{errors.betMaxLimit}</h6>
            )}
          </div>
          <div className="col-4" style={{ marginTop: 6 }}>
            <label className="label-input">{constants.commission}</label>
            <InputNumber
              onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value === '0')
                  setFieldValue("com", '');
              }}
              allowNegative={false}
              allowLeadingZeros={false}
              id="com"
              name="com"
              type="text"
              placeholder={constants.comPlaceholder}
              value={values.com}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value === '')
                  setFieldValue("com", '0');
              }}
              onValueChange={(values) => {
                setFieldValue("com", values.value);
              }}
              isNumericString
              errorMessage={errors.com}
              error={!!errors.com && touched.com}
              inputClassName="input-otp"
              backgroundColor={color.TRANSPARENT}
              disabled={
                this.props.mode === "UPDATE"
                  ? !this.state.isEditable
                  : !isOtpClicked
              }
              isAllowed={({ floatValue }: { floatValue: number | undefined }) =>

                floatValue ? floatValue <= 100 : true
              }
              decimalScale={0}
              endText="%"
              mode="InputElements"
            />
            <h6 className="primary-red-text mt-1">
              {!!errors.com && touched.com ? errors.com : "\u00A0"}
            </h6>
          </div>
        </div>

        {window.location.pathname === routes.userDetail.path ? (
          <></>
        ) : (
          <div className="row">
            <div className="col-4" style={{ marginTop: 6 }}>
              <label className="label-input">{constants.bankName}</label>
              <Input
                id="bankName"
                name="bankName"
                type="text"
                placeholder={constants.bankNamePlaceholder}
                value={values.bankName}
                onBlur={handleBlur}
                onChange={(e) => {
                  if (!e.target.value.match(ENG_THAI_AND_SPACE)) {
                    setFieldValue("bankName", e.target.value);
                  }
                }}
                errorMessage={errors.bankName}
                error={!!errors.bankName && touched.bankName}
                inputClassName="input-otp"
                backgroundColor={color.TRANSPARENT}
                disabled={
                  this.props.mode === "UPDATE"
                    ? !this.state.isEditable
                    : !isOtpClicked
                }
              />
            </div>
            <div className="col-4" style={{ marginTop: 6 }}>
              <label className="label-input">{constants.bank}</label>
              <Select
                styles={createMemberDropdownStyles(
                  this.props.mode === "UPDATE"
                    ? !this.state.isEditable
                    : !isOtpClicked
                )}
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder={constants.bankPlaceholder}
                name="bankType"
                options={this.formatBankTypeSelect()}
                value={this.formatBankTypeSelect()[bankTypeIndex]}
                onChange={(e) => {
                  if (values.bankType === "GSB" && e?.value !== "GSB") {
                    const formattedBankNumber = values.bankNumber.substring(
                      0,
                      values.bankNumber.length - 2
                    );
                    setFieldValue("bankNumber", formattedBankNumber);
                    setTimeout(() => {
                      validateField("bankNumber");
                    }, 256);
                  }
                  setFieldValue("bankType", e?.value);
                  this.setState({ isBankTypeSelected: true });
                }}
                getOptionValue={(option) => get(option, "searchableName", "")}
                isDisabled={
                  this.props.mode === "UPDATE"
                    ? !this.state.isEditable
                    : !isOtpClicked
                }
                noOptionsMessage={() => constants.noInfo}
              />
              {!!errors.bankType && touched.bankType && (
                <h6 className="primary-red-text" style={{ marginTop: 6 }}>
                  {errors.bankType}
                </h6>
              )}
            </div>
            <div className="col-4" style={{ marginTop: 6 }}>
              <label className="label-input">{constants.bankNumber}</label>
              <InputNumber
                allowNegative={false}
                decimalScale={0}
                id="bankNumber"
                name="bankNumber"
                type="text"
                placeholder={constants.bankNumberPlaceholder}
                value={values.bankNumber}
                onBlur={handleBlur}
                onChange={(e) => {
                  setFieldValue(
                    "bankNumber",
                    e.target.value.replaceAll(" ", "")
                  );
                }}
                errorMessage={errors.bankNumber}
                error={!!errors.bankNumber && touched.bankNumber}
                inputClassName="input-otp"
                format={
                  values.bankType === "BAAC" || values.bankType === "GSB"
                    ? "# ### #### ####"
                    : "### #### ###"
                }
                backgroundColor={color.TRANSPARENT}
                disabled={
                  this.props.mode === "UPDATE"
                    ? !this.state.isEditable
                    : !isOtpClicked || !this.state.isBankTypeSelected
                }
              />
            </div>
          </div>
        )}

        <div className="row">
          {this.props.mode === "UPDATE" && this.state.isEditable && (
            <>
              <div className="col-12">
                <Button
                  id="submit-finance-info"
                  text={constants.editFinanceInfo}
                  borderRadius={"8px"}
                  buttonClass={"edit-finance-info-button"}
                  textSize={18}
                  onClick={this.submitFinanceInfo}
                />
              </div>
              <div className="col-12" style={{ marginTop: 12 }}>
                <Button
                  id="reset-finance-info"
                  text={constants.resetFinanceINfo}
                  borderRadius={"8px"}
                  buttonClass={"edit-finance-info-button"}
                  textSize={18}
                  backgroundColor={color.TRANSPARENT}
                  backgroundHoverColor={color.TRANSPARENT}
                  textColor={color.SECONDARY_TEXT}
                  onClick={this.resetFinanceInfo}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default FinanceInfoComponent;
