export const GET_NEWSME_REQUEST = 'GET_NEWSME_REQUEST'
export const GET_NEWSME_SUCCESS = 'GET_NEWSME_SUCCESS'
export const GET_NEWSME_FAILURE = 'GET_NEWSME_FAILURE'
export const GET_NEWSME_CANCEL = 'GET_NEWSME_CANCEL'

export const POST_NEWSME_REQUEST = 'POST_NEWSME_REQUEST'
export const POST_NEWSME_SUCCESS = 'POST_NEWSME_SUCCESS'
export const POST_NEWSME_FAILURE = 'POST_NEWSME_FAILURE'
export const POST_NEWSME_CANCEL = 'POST_NEWSME_CANCEL'

export const UPDATE_NEWSME_REQUEST = 'UPDATE_NEWSME_REQUEST'
export const UPDATE_NEWSME_SUCCESS = 'UPDATE_NEWSME_SUCCESS'
export const UPDATE_NEWSME_FAILURE = 'UPDATE_NEWSME_FAILURE'
export const UPDATE_NEWSME_CANCEL = 'UPDATE_NEWSME_CANCEL'

export const DELETE_NEWSME_REQUEST = 'DELETE_NEWSME_REQUEST'
export const DELETE_NEWSME_SUCCESS = 'DELETE_NEWSME_SUCCESS'
export const DELETE_NEWSME_FAILURE = 'DELETE_NEWSME_FAILURE'
export const DELETE_NEWSME_CANCEL = 'DELETE_NEWSME_CANCEL'

export const newsState: ReducerState<IPagination<INews>> = {
    isFetching: false,
    code: 0,
    data: {
        totalRecord:0,
        page: 1,
        limit: 5,
        total: 0,
        dataList:[]
    },
    error: ''
}

export const newsResState: ReducerState<IAPIResponse<object>> = {
    isFetching: false,
    code: 0,
    data: {
        data: {},
        code: 0,
        devMessage: ""
    },
    error: ''
}