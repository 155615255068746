export const GET_RATE_REQUEST = "GET_RATE_REQUEST";
export const GET_RATE_SUCCESS = "GET_RATE_SUCCESS";
export const GET_RATE_FAILURE = "GET_RATE_FAILURE";

export const GET_NUMBER_REQUEST = "GET_NUMBER_REQUEST";
export const GET_NUMBER_SUCCESS = "GET_NUMBER_SUCCESS";
export const GET_NUMBER_FAILURE = "GET_NUMBER_FAILURE";

export const betRateState: ReducerState<IResponseAgentLotteryRateClient[]> = {
  isFetching: false,
  code: 0,
  data: [],
  error: "",
};

export const betNumberState: ReducerState<IResponseAgentLotteryRateNumber[]> = {
  isFetching: false,
  code: 0,
  data: [],
  error: "",
};
