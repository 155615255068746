import { string, object } from "yup";
import { NUMBER, ENG, LOWERCASE, SPECIAL_ENG, CHECK_SPECIAL_CHARACTERS_SPACE } from "constants/regex";
import { isEmpty } from "lodash";
const constants = {
  name: "กรุณากรอกชื่อช่วยจำ",
  phoneNumber: "กรุณากรอกหมายเลขโทรศัพท์ 10 หลัก",
  onlyNumber: "กรุณากรอกเฉพาะตัวเลขเท่านั้น",
  permission: "กรุณาเลือกระดับ",
  lowercase: "กรุณากรอกเฉพาะตัวหนังสือตัวเล็ก",
  englishRequire: "กรุณากรอกเฉพาะตัวหนังสือภาษาอังกฤษและตัวเลข",
  username: "กรุณากรอกชื่อผู้ใช้",
  usernameMinLength: "กรุณากรอกรหัสผู้ใช้ 6 ตัวขึ้นไป",
  line: "กรุณากรอกไอดีไลน์",
  lineMinLength: "กรุณากรอกไอดีไลน์ 4 ตัวขึ้นไป",
  lineMaxLength: "กรุณากรอกไอดีไลน์ ไม่เกิน 20 ตัว",
  commission: "กรุณากรอกค่าคอมมิชชั่น",
  bankName: "กรุณากรอกชื่อบัญชีธนาคาร",
  bankNo: "กรุณากรอกหมายเลขบัญชีธนาคาร",
  bank: "กรุณาเลือกธนาคาร",
  limitCredit: "กรุณากรอกเครดิตที่จำกัดไว้",
  minBet: "กรุณากรอกจำนวนเงินแทงต่ำสุด",
  maxBet: "กรุณากรอกจำนวนเงินแทงสูงสุด",
  credit_limit: "กรุณากรอกวงเงิน",
  fixSharePercentageLessThanHostSharePercentage:
    "บังคับสู้ต้องไม่มากกว่าถือสู้",

  betMinOverLimit: "แทงต่ำสุดต้องน้อยกว่าลิมิตที่กำหนดไว้",
  betMaxOverLimit: "แทงสูงสุดต้องน้อยกว่าลิมิตที่กำหนดไว้",
  creditOverLimit: "เครดิตต้องน้อยกว่าลิมิตที่กำหนดไว้หรือมากกว่า 0",
  hostSharePercentageRequired: "กรุณากรอกเปอร์เซ็นต์ถือสู้",
  fixSharePercentageRequired: "กรุณากรอกเปอร์เซ็นต์บังคับสู้",
  childLotsetBetMinRequired: "กรุณากรอกจำกัดแทงต่ำสุด",
  childLotsetBetMaxRequired: "กรุณากรอกจำกัดแทงสูงสุด",
  childLotsetDisRequired: "กรุณากรอกส่วนลด",
  childLotsetBetRateRequired: "กรุณากรอกอัตราจ่าย",
  comMaxOverLimit: "ค่าคอมมิชชั่นต้องน้อยกว่าลิมิตที่กำหนดไว้",
};

const bankLength = (bankType: string) => {
  return `กรุณากรอกเลขบัญชีธนาคารให้ครบ ${bankType === "GSB" || bankType === "BAAC" ? "12" : "10"
    } ตัว`;
};

const findFirstRepeatedChar =(s:string) =>{ 
  // var ot = "";
  for(let i=0; i<s.length; i++){

    if(s[i]===" " && s[i+1] === " " )  { 
       return true;
     }  
    //   else if(s.indexOf(s[i], i+1) !== -1){
    //    ot = s[i]; 
    //  }
   }

     return false; 

} 
const schema = (meSettingData: IV2GetAgentMeSetting, meData: IV2GetAgentMe) => {
  return object().shape({
    name: string()
      .required(constants.name)
      .matches(CHECK_SPECIAL_CHARACTERS_SPACE, "ต้องไม่ใช้ตัวอักษรพิเศษ")
      .test("", "ห้ามใส่ช่องว่าง ขึ้นต้น, ตัวสุดท้าย หรือห้ามมีช่องว่างติดกัน 2 ช่อง", function (val) {
        if (!isEmpty(val)) {
          if (val![0] === ' ' || val![val!.length] === ' ') {
            return false
          } else if (findFirstRepeatedChar(String(val))) {
            return false
          } else {
            return true
          }
        } else {
          return false
        }
      })
      .max(20, `${constants.name}ไม่เกิน 20 ตัว`)
      .required(constants.name),

    phoneNumber: string()
      .nullable()
      .length(10, constants.phoneNumber)
      .matches(NUMBER, constants.onlyNumber),

    permission: string().test(
      "",
      constants.permission,
      (val) => val !== "PleaseChoose"
    ),

    creditLimit: string()
      .required(constants.credit_limit)
      .test("", constants.creditOverLimit, (val) => {
        return Number(val) <= meData.credit;
      }),

    betMinLimit: string()
      .required(constants.minBet)
      .test("", constants.betMinOverLimit, (val) => {
        return Number(val) <= meSettingData.setBetMaxLimit;
      }),

    betMaxLimit: string()
      .required(constants.maxBet)
      .test("", constants.betMaxOverLimit, (val) => {
        return Number(val) <= meSettingData.setBetMaxLimit;
      }),

    username: string()
      .required(constants.username)
      .matches(ENG, constants.englishRequire)
      .matches(LOWERCASE, constants.lowercase),

    line: string()
      .matches(SPECIAL_ENG, constants.englishRequire)
      .max(20, `${constants.lineMaxLength}`)
      .min(4, constants.lineMinLength),

    com: string()
      .required(constants.commission),
      // .test("", constants.comMaxOverLimit, (val) => {
      //   return Number(val) <= meSettingData.setComPercentage;
      // }),

 
    bankNumber: string()
      .nullable()
      .when("bankType", (bankType: TBankType| 'PleaseChoose', schema: any) => {
      return schema.test({
        test: (bankNumber: string) => {
          if(bankType!=='PleaseChoose'){
            if (!bankNumber) {
              return true
            }
            if (bankType === "GSB" || bankType === "BAAC") {
              return bankNumber.length === 12;
            }
            return bankNumber.length === 10;
          }else {
            return true
          }
        },
        message: bankLength(bankType),
        });
      }),

    
    hostSharePercentage: string()
      .required(constants.hostSharePercentageRequired)
      .test("", "error", (val, ctx) => {
        return (
          (Number(val) || 0) >= Number(ctx.parent.mustReciv) &&
          (Number(val) || 0) <= Number(ctx.parent.meShare)
        );
      }),

    fixSharePercentage: string()
      .required(constants.fixSharePercentageRequired)
      .test("", "error", (val, ctx) => {
        return (Number(val) || 0) <= Number(ctx.parent.hostSharePercentage);
      }),

    childLotsetDisThreeUp: string().required(constants.childLotsetDisRequired),
    childLotsetDisThreeToast: string().required(constants.childLotsetDisRequired),
    childLotsetDisTwoUp: string().required(constants.childLotsetDisRequired),
    childLotsetDisTwoDown: string().required(constants.childLotsetDisRequired),
    childLotsetDisRunUp: string().required(constants.childLotsetDisRequired),
    childLotsetDisRunDown: string().required(constants.childLotsetDisRequired),

    childLotsetBetMinThreeUp: string().required(constants.childLotsetBetMinRequired).test("", "error", (val, ctx) => {
      return (
        Number(val) <= Number(ctx.parent.childLotsetBetMaxThreeUp) &&
        Number(val) >= Number(ctx.parent.betMinLimit)
      );
    }),
    childLotsetBetMinThreeToast: string().required(constants.childLotsetBetMinRequired).test("", "error", (val, ctx) => {
      return (
        Number(val) <= Number(ctx.parent.childLotsetBetMaxThreeToast) &&
        Number(val) >= Number(ctx.parent.betMinLimit)
      );
    }),
    childLotsetBetMinTwoUp: string().required(constants.childLotsetBetMinRequired).test("", "error", (val, ctx) => {
      return (
        Number(val) <= Number(ctx.parent.childLotsetBetMaxTwoUp) &&
        Number(val) >= Number(ctx.parent.betMinLimit)
      );
    }),
    childLotsetBetMinTwoDown: string().required(constants.childLotsetBetMinRequired).test("", "error", (val, ctx) => {
      return (
        Number(val) <= Number(ctx.parent.childLotsetBetMaxTwoDown) &&
        Number(val) >= Number(ctx.parent.betMinLimit)
      );
    }),
    childLotsetBetMinRunUp: string().required(constants.childLotsetBetMinRequired).test("", "error", (val, ctx) => {
      return (
        Number(val) <= Number(ctx.parent.childLotsetBetMaxRunUp) &&
        Number(val) >= Number(ctx.parent.betMinLimit)
      );
    }),
    childLotsetBetMinRunDown: string().required(constants.childLotsetBetMinRequired).test("", "error", (val, ctx) => {
      return (
        Number(val) <= Number(ctx.parent.childLotsetBetMaxRunDown) &&
        Number(val) >= Number(ctx.parent.betMinLimit)
      );
    }),

    childLotsetBetMaxThreeUp: string().required(constants.childLotsetBetMaxRequired).test("", "error", (val, ctx) => {
      return Number(val) <= Number(ctx.parent.betMaxLimit);
    }),
    childLotsetBetMaxThreeToast: string().required(constants.childLotsetBetMaxRequired).test("", "error", (val, ctx) => {
      return Number(val) <= Number(ctx.parent.betMaxLimit);
    }),
    childLotsetBetMaxTwoUp: string().required(constants.childLotsetBetMaxRequired).test("", "error", (val, ctx) => {
      return Number(val) <= Number(ctx.parent.betMaxLimit);
    }),
    childLotsetBetMaxTwoDown: string().required(constants.childLotsetBetMaxRequired).test("", "error", (val, ctx) => {
      return Number(val) <= Number(ctx.parent.betMaxLimit);
    }),
    childLotsetBetMaxRunUp: string().required(constants.childLotsetBetMaxRequired).test("", "error", (val, ctx) => {
      return Number(val) <= Number(ctx.parent.betMaxLimit);
    }),
    childLotsetBetMaxRunDown: string().required(constants.childLotsetBetMaxRequired).test("", "error", (val, ctx) => {
      return Number(val) <= Number(ctx.parent.betMaxLimit);
    }),

    childLotsetBetRateThreeUp: string().required(constants.childLotsetBetRateRequired).test("", "error", (val, ctx) => {
      return Number(val) <= Number(ctx.parent.childLotsetBetRateThreeUpAPI);
    }),
    childLotsetBetRateThreeToast: string().required(constants.childLotsetBetRateRequired).test("", "error", (val, ctx) => {
      return Number(val) <= Number(ctx.parent.childLotsetBetRateThreeToastAPI);
    }),
    childLotsetBetRateTwoUp: string().required(constants.childLotsetBetRateRequired).test("", "error", (val, ctx) => {
      return Number(val) <= Number(ctx.parent.childLotsetBetRateTwoUpAPI);
    }),
    childLotsetBetRateTwoDown: string().required(constants.childLotsetBetRateRequired).test("", "error", (val, ctx) => {
      return Number(val) <= Number(ctx.parent.childLotsetBetRateTwoDownAPI);
    }),
    childLotsetBetRateRunUp: string().required(constants.childLotsetBetRateRequired).test("", "error", (val, ctx) => {
      return Number(val) <= Number(ctx.parent.childLotsetBetRateRunUpAPI);
    }),
    childLotsetBetRateRunDown: string().required(constants.childLotsetBetRateRequired).test("", "error", (val, ctx) => {
      return Number(val) <= Number(ctx.parent.childLotsetBetRateRunDownAPI);
    }),
  } ,[ 
    ["bankName", "bankType"],
    ["bankNumber", "bankType"],
    ["bankName", "bankNumber"]]);
};

export default schema;
