import axios from "axios";
import { transformer } from "utils";
import environment from "../../constants/environment";

const fetchPostChild = (data: IV2PostAgentChild) => {
  const endpoint = `${
    environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.postChild}`;
  return axios.post(`${endpoint}`, data);
};

const fetchGetChildDetail = (
  query: IQueryPagination & IV2QueryGetAgentChildDetail
) => {
  const endpoint = `${
    environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.getChildDetail}`;
  return axios.get(`${endpoint}${transformer.urlSearchParams(query)}`);
};

const fetchPostChildSettingLotteryUpdate = (
  data: IV2PostAgentChildSettingLotteryUpdate
) => {
  const endpoint = `${
    environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.postChildSettingLotteryUpdate}`;
  return axios.post(`${endpoint}`, data);
};

const fetchPostChildUpdate = (data: IV2AgentChildUpdate) => {
  const endpoint = `${
    environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.postChildUpdate}`;
  return axios.post(`${endpoint}`, data);
};

const fetchPostChildResetPasswod = (data: IV2AgentChildPasswordUpdate) => {
  const endpoint = `${
    environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.childResetPassword}`;
  return axios.post(`${endpoint}`, data);
};

const fetchPostChildSettingUpdate = (data: IV2PostAgentChildSettingUpdate) => {
  const endpoint = `${
    environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.postChildSettingUpdate}`;
  return axios.post(`${endpoint}`, data);
};

const fetchPostChildCreditUpdate = (data: IV2AgentChildCreditUpdate) => {
  const endpoint = `${
    environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.postChildCreditUpdate}`;
  return axios.post(`${endpoint}`, data);
};

const fetchGetChildBet = (query: IQueryPagination & IV2QueryAgentChildBet) => {
  const endpoint = `${
    environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.getChildBet}`;
  return axios.get(`${endpoint}${transformer.urlSearchParams(query)}`);
};

export {
  fetchPostChild,
  fetchGetChildDetail,
  fetchPostChildSettingLotteryUpdate,
  fetchPostChildUpdate,
  fetchPostChildSettingUpdate,
  fetchPostChildCreditUpdate,
  fetchPostChildResetPasswod,
  fetchGetChildBet
};
