import { AxiosError, AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import { fetPostTransferCreditDescription } from '../services'
import { transferCreditDescriptionAction } from '../actionCreator'
import { loaderAction } from '../../Loader/actionCreator'

const getTransferDescription = (body: IAgentFinancePayDetailQuery) => async (dispatch: Dispatch) => {
    dispatch(loaderAction.success(true))
    dispatch(transferCreditDescriptionAction.request({}))
    await fetPostTransferCreditDescription(body)
        .then((response: AxiosResponse<IAPIResponse<IResponseAgentFinancePayDetail[]>>) => {
            dispatch(loaderAction.success(false))
            return dispatch(transferCreditDescriptionAction.success(response))
        })
        .catch((error: AxiosError<APIResponse>) => {
            dispatch(loaderAction.success(false))
            return dispatch(transferCreditDescriptionAction.failure(error))
        })
}

const cancelTransferDescription = () => (dispatch: Dispatch) =>
    dispatch(transferCreditDescriptionAction.cancel({}))

export {
    getTransferDescription,
    cancelTransferDescription,
}