import axios from "axios";
import { transformer } from "utils";
import environment from "../../../constants/environment";

const fetchGetYegeeGameList = (query?: ILottoRoundQuery) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.getYegeeGame}`;

  return axios.get(`${endpoint}${transformer.urlSearchParams(query)}`);
};

const fetchPostYegeeGameHistory = (request: IYegeeGameHistoryRequest) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.urlLuden}${environment.endpoint.postYegeeGameHistory}`
  return axios.post(endpoint, request)
}

const fetchPostYegeePlay = (request: IYegeePlayRequest) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.urlLuden}${environment.endpoint.postYegeePlay}`

  return axios.post(endpoint, request)
}

const fetchGetYegeeSum = (query: ILottoRoundQuery) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.urlLuden}${environment.endpoint.getYegeeSum}`

  return axios.get(`${endpoint}${transformer.urlSearchParams(query)}`)
}

const fetchGetPlayedYegeeList = (query: ILottoRoundQuery) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.urlLuden}${environment.endpoint.getPlayedYegeeList}`

  return axios.get(`${endpoint}${transformer.urlSearchParams(query)}`)
}

const fetchGetYegeeGame = (query: ILottoRoundQuery) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.urlLuden}${environment.endpoint.getYegeeGame}`

  return axios.get(`${endpoint}${transformer.urlSearchParams(query)}`)
}

export {
  fetchGetYegeeGameList, fetchPostYegeeGameHistory,
  fetchPostYegeePlay,
  fetchGetYegeeSum,
  fetchGetPlayedYegeeList,
  fetchGetYegeeGame
};
