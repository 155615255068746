import { AxiosError, AxiosResponse } from 'axios'
import { createAsyncAction } from 'typesafe-actions'
import {
    MAIN_REQUEST,
    MAIN_SUCCESS,
    MAIN_FAILURE,
    MAIN_CANCEL,

    NEWS_REQUEST,
    NEWS_SUCCESS,
    NEWS_FAILURE,

    RESETPASSWORD_REQUEST,
    RESETPASSWORD_SUCCESS,
    RESETPASSWORD_FAILURE
} from './constants'

const mainAction = createAsyncAction(
    MAIN_REQUEST,
    MAIN_SUCCESS,
    MAIN_FAILURE,
    MAIN_CANCEL,
)<any, AxiosResponse<IAPIResponse<IMain>>, AxiosError<IAPIResponse>, any>()

const newsAction = createAsyncAction(
    NEWS_REQUEST,
    NEWS_SUCCESS,
    NEWS_FAILURE,
)<any, AxiosResponse<IAPIResponse<IPagination<INews>>>, AxiosError<IAPIResponse>>()

const resetPasswordAction = createAsyncAction(
    RESETPASSWORD_REQUEST,
    RESETPASSWORD_SUCCESS,
    RESETPASSWORD_FAILURE
)<any, AxiosResponse<IAPIResponse<object>>, AxiosError<IAPIResponse>>()

export {
    mainAction,
    newsAction,
    resetPasswordAction
}