export const CUSTOMER_REQUEST = 'CUSTOMER_REQUEST'
export const CUSTOMER_SUCCESS = 'CUSTOMER_SUCCESS'
export const CUSTOMER_FAILURE = 'CUSTOMER_FAILURE'

export const CUSTOMER_NOTE_REQUEST = 'CUSTOMER_NOTE_REQUEST'
export const CUSTOMER_NOTE_SUCCESS = 'CUSTOMER_NOTE_SUCCESS'
export const CUSTOMER_NOTE_FAILURE = 'CUSTOMER_NOTE_FAILURE'

export const POST_CUSTOMER_NOTE_REQUEST = 'POST_CUSTOMER_NOTE_REQUEST'
export const POST_CUSTOMER_NOTE_SUCCESS = 'POST_CUSTOMER_NOTE_SUCCESS'
export const POST_CUSTOMER_NOTE_FAILURE = 'POST_CUSTOMER_NOTE_FAILURE'

export const customerState: ReducerState<IPagination<IV2RespAgentCustomer>> = {
    isFetching: false,
    code: 0,
    data: {
        totalRecord:0,
        page: 1,
        limit: 10,
        total: 0,
        dataList: []
    },
    error: ''
}


export const customerNoteState: ReducerState<IPagination<IV2RespAgentCustomerNote>> = {
    isFetching: false,
    code: 0,
    data: {
        totalRecord:0,
        page: 1,
        limit: 10,
        total: 0,
        dataList: []
    },
    error: ''
}

export const postCustomerNoteState: ReducerState<IPagination<IV2RespPostAgentCustomerNote>> = {
    isFetching: false,
    code: 0,
    data: {
        totalRecord:0,
        page: 1,
        limit: 10,
        total: 0,
        dataList: []
    },
    error: ''
}
