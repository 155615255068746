import { isEmpty, noop } from "lodash";
import "./ModalAddNote.style.scss";
import { Modal } from "../..";
import React, { FC } from "react";
import logo from "assets/images/logo/logothailandbet.png";
const constants = {
  title: "ออกใบเสร็จ",
  titleWeb: "ไทยแลนด์เบท",
  titleEng: "Thailand Bet",
  description: "โปรดเลือกรูปแบบใบเสร็จ",
  actionText: "ตกลง",
};

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: IModalPrintBill = {
  description: constants.description,
  actionText: constants.actionText,
  action() { noop() },
  children:<></>
};

const ModalAddNote: FC<IModalPrintBill & DefaultProps> = (props) => {
  return (
    <div className="addNote-modal-container border-rounded">
       <div className="row mr-0">
        <div className="leading-logo-container m2-x">
          <img src={logo} className="logoMenu" alt="" />
        </div>
        <div className="title-text-web">
          <h3 className="title">{constants.titleWeb}</h3>
          <h5 className="tertiary-text sub-title">{constants.titleEng}</h5>
        </div>
      </div>
      <div className="description-text-addNote">{props.description}</div>
      {props.children}
 
    </div>
  );
};

ModalAddNote.defaultProps = defaultProps;

const modalAddNote = {
  show: (props: IModalPrintBill) => {
    return Modal.event("MODAL_OVER", {
      state: "show",
      extraProps: { size: isEmpty(props.size)?"md":props.size },
      component: <ModalAddNote {...props} />,
    });
  },
  hide: () => Modal.event("MODAL_OVER", { state: "hide" }),
};

export default modalAddNote;
