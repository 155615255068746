import React, { Component } from "react";
import colors from "constants/colors";
import { get, isEmpty, noop, pickBy } from "lodash";
import {
  SkeletonLoader,
  Panginate,
  Dropdown,
  ErrorModal,
  Button,
  SuccessModal,
  LoadingCircle
} from "components";
import { th } from "date-fns/locale";
import { format } from "date-fns";
import { date } from "utils";
import { responseCode, responseMessage } from "constants/response";
import modalAddNote from "components/Modal/components/AddNote/ModalAddNote.component";
import "./NoteTable.style.scss"


const constants = {
  addNoteSuccess: 'เพิ่มข้อความสำเร็จ',
  saveNoteSuccess: "บันทึกข้อความเรียบร้อย",
  rowPerPage: "จำนวนรายการต่อหน้า :",
  dataEmpty: "ไม่มีข้อมูล...",
  headTable: [
    { codeName: "วันที่", textalign: "text-left paddingtableHead ", style: { width: `15%` } },
    { codeName: "ข้อความ", textalign: "text-left" },
  ],
};

const defaultProps: INoteTableProps = {
  postcustomerNote() {
    noop()
  },
  getCustomerNote() {
    noop()
  },
  locationData: 0,
  onload() { },
  customerNoteIsFetching: false,
  customerNoteCode: 0,
  customerNoteError: "",
  customerNoteData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  postcustomerNoteCode: 0,
  postcustomerNoteError: "",
  postcustomerNoteIsFetching: false
};

class NoteTable extends Component<INoteTableProps> {
  static defaultProps = defaultProps;
  state = {
    textAreaValue: "",
    currentPage: 0,
    currentRowPerPage: 10,
    childSort: "",
    dataQuery: {
      limit: 10,
      page: 1,
      customerId: 0,
      customerName: "",
    },
    startPage: 1,
    endPage: 0,

    queryTimeout: setTimeout(() => {
      noop();
    }, 0),
    reqMemberDetail: {
      id: -99,
    },
    isSearch: false,
  };
  componentDidMount() {
    this.setState({ dataQuery: { ...this.state.dataQuery, customerId: this.props.locationData } },
      () => {
        this.props.getCustomerNote({
          customerId: this.state.dataQuery.customerId,
        });
      })


  }
  componentDidUpdate(prevProps: INoteTableProps) {
    if (this.props.customerNoteIsFetching) {
      this.props.onload(true);
    } else {
      this.props.onload(false);
    }

    if (
      prevProps.postcustomerNoteIsFetching !== this.props.postcustomerNoteIsFetching &&
      !this.props.postcustomerNoteIsFetching
    ) {
      if (
        this.props.postcustomerNoteCode === responseCode.OK &&
        this.props.postcustomerNoteError === ""
      ) {
        SuccessModal.show({
          action: () => {
            SuccessModal.hide();
          },
          title: constants.addNoteSuccess,
          description: constants.saveNoteSuccess,
        });
        this.props.getCustomerNote(pickBy(this.state.dataQuery))
        this.setState({ ...this.state, textAreaValue: "" })
      }
    }

    if (
      prevProps.customerNoteIsFetching !== this.props.customerNoteIsFetching &&
      !this.props.customerNoteIsFetching
    ) {
      if (
        this.props.customerNoteCode !== responseCode.OK &&
        this.props.customerNoteError !== ""
      ) {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            this.props.customerNoteCode,
            responseMessage()[0]
          ),
        });
      }
      if (this.props.customerNoteCode === responseCode.OK) {
        if (isEmpty(this.props.customerNoteData.dataList)) {
          this.setState({ startPage: 0, endPage: 0 });
        } else {
          this.setState({
            startPage:
              this.state.currentRowPerPage * (this.state.dataQuery.page - 1) +
              1,
            endPage:
              this.state.currentRowPerPage * (this.state.currentPage + 1) >=
                this.props.customerNoteData.totalRecord
                ? this.props.customerNoteData.totalRecord
                : this.state.currentRowPerPage * this.state.dataQuery.page,
          });
        }
      }
    }
  }


  handleClickChangePage = ({ selected: selectedPage }: ISelectPage) => {
    this.setState({ currentPage: selectedPage }, () => {
      this.setState(
        {
          dataQuery: {
            ...this.state.dataQuery,
            page: this.state.currentPage + 1,
          },
        },
        () => {
          this.props.getCustomerNote(pickBy(this.state.dataQuery));
        }
      );
    });
  };

  tableMembersBody = () => {
    if (this.state.isSearch) {
      return (
        <tr className={`row-members-list color-row`}>
          <td className="text-center color-row p-2" colSpan={15}>
            <SkeletonLoader customStyle={[[{ col: 12, show: true }]]} />
          </td>
        </tr>
      );
    } else {
      const loadingTable = (
        <tr>
          <td className="text-center" colSpan={15}>
            <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
          </td>
        </tr>
      )
      const dataEmpty = (
        <tr>
          <td className="text-center" colSpan={15}>
            {constants.dataEmpty}
          </td>
        </tr>
      )
      const mapData = this.props.customerNoteData.dataList.map((data: IV2RespAgentCustomerNote, index: number) => {
        return (
          (
            <tr className={`row-members-list `} key={index}>
              <td className="text-left paddingRow">
                <span className="paddingtableHead">
                  {`${format(
                    date.calibratingTime(data.noteCreatedAt, true),
                    "dd MMM yyyy HH:mm",
                    { locale: th }
                  )}`}
                </span>
              </td>
              <td className="text-left paddingRow">
                <span className="text-break"> {get(data, 'message', "-")}</span>
              </td>

            </tr>
          )
        )
      })
      return this.props.customerNoteIsFetching ? loadingTable : !isEmpty(mapData) ? mapData : dataEmpty
    }
  };
  renderTableMembers = () => (
    <div className="table-container">
      <div className="table-responsive-xl">
        <table className="col ">
          <thead className="">
            <tr className="tableHead">
              {constants.headTable.map((data, index: number) => (
                <th key={index} className={data.textalign} style={data.style}>
                  <span>{data.codeName}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{this.tableMembersBody()}</tbody>
        </table>
      </div>
    </div>
  );

  handleChange(event: string) {
    this.setState({ ...this.state, textAreaValue: event }
    );
  }
  modalAddNote = () => {
    modalAddNote.show({
      action: () => { modalAddNote.hide() },
      description: 'บันทึกข้อความ',
      children: <>
        <div className="row ">
          <div className="m1-y col-12">
            <textarea
              rows={4}
              cols={58}
              placeholder={"ข้อความ, บันทึก หรือเตือนความจำ"}
              defaultValue={this.state.textAreaValue}
              onChange={(e) => this.handleChange(e.target.value)}
            />


          </div>
          <div className="col-6 first ">  <Button
            id="success-ok-button"
            type="rectangle"
            textSize={18}
            onClick={() => {
              if (this.state.textAreaValue === "") {
                return <span>โปรดกรอกบันทึก</span>
              }
              else {
                this.props.postcustomerNote({ customerId: this.props.locationData, message: this.state.textAreaValue })
              }
            }}
            text="บันทึก"
            transitionType="typeOne"
          />
          </div>
          <div className="col-6  last ">
            <Button
              id=""
              type="rectangle"
              onClick={() => {
                modalAddNote.hide()
              }}
              backgroundHoverColor={"#A7ABB7"}
              textSize={18}
              backgroundColor={"#A7ABB7"}
              text="ยกเลิก"
              transitionType="typeOne"
            />
          </div>


        </div>

      </>
    })



  }

  render() {
    const RenderTableMembers = this.renderTableMembers();
    return (
      <div className="noteTable-container">
        <div className="row">
          <div className="col-1 m3-t">
            <Button
              id={"add-member"}
              size={"moreMediumSmallHeight"}
              textSize={18}
              text={"เพิ่มบันทึกข้อความ"}
              onClick={() => { this.modalAddNote() }}
            />
          </div>
        </div>

        <div className="row ">
          <div className="col m3-t">{RenderTableMembers}</div>
        </div>
        <div className="row">
          <div className="col d-flex justify-content-end align-items-center ">
            <h5 className="rowperpageFont">{constants.rowPerPage}</h5>
            <div className="dropdown-rowPerPage ">
              <Dropdown
                menuPlacement={"top"}
                options={[
                  { value: 5, label: "5", disabled: false },
                  { value: 10, label: "10", disabled: false },
                  { value: 20, label: "20", disabled: false },
                  { value: 50, label: "50", disabled: false },
                  { value: 100, label: "100", disabled: false },
                ]}
                controlBackgroundColor={colors.PRIMARY_BG}
                placeholder={String(this.state.currentRowPerPage)}
                handleOnChange={(target: IOptions) => {
                  this.setState(
                    {
                      currentPage: 0,
                      currentRowPerPage: Number(target.value),
                      dataQuery: {
                        ...this.state.dataQuery,
                        page: 1,
                        limit: Number(target.value),
                      },
                    },
                    () => {
                      this.props.getCustomerNote(pickBy(this.state.dataQuery));
                    }
                  );
                }}
              />
            </div>
          </div>
          <h5 className="d-flex justify-content-end align-items-center rowperpageFont">
            {`${this.state.startPage} - ${this.state.endPage}  of ${this.props.customerNoteData.totalRecord}`}
          </h5>
          <div className="col-auto">
            <Panginate
              pageTotal={this.props.customerNoteData.total}
              initialPage={this.state.dataQuery.page - 1}
              pageRangeDisplayed={this.state.currentPage}
              onPageChange={this.handleClickChangePage}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default NoteTable;
