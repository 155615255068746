import axios from "axios"
import environment from "../../constants/environment"


// API agent 95
const postAddBanLottery = (query : IV2PostRateReduceAddAgentLottery) => {
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.postAgentBanAddLottery}`
    return axios.post<IAPIResponse<IV2RespPostRateReduceAddAgentLottery>>(`${endpoint}`,query)
}



// API agent 96
const fetchAgentBanLottery = () => {
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.getAgentBanAddLottery}`
    return axios.get<IAPIResponse<IV2GetRateReduceLotteryAgentAdd[]>>(endpoint)
}


//API agent 97 
const getAgentBanLottery = () => {
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.getAgentBanLottery}`
    return axios.get<IAPIResponse<IV2GetRateReduceLotteryAgent[]>>(endpoint)
}

//API agent 98
const postCancelNumberBanUpdate = (query : IV2UpdateRateReduceLotteryAgent) => {
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.postCancelNumberBan}`
    return axios.post<IAPIResponse<IV2GetRateReduceLotteryAgent>>(`${endpoint}`,query)

}

export{
    fetchAgentBanLottery,
    postAddBanLottery,
    getAgentBanLottery,
    postCancelNumberBanUpdate
}