import { connect } from "react-redux"
import FinanceSummaryHost from "./FinanceSummaryHost.container"
import { getFinanceHost} from "../../reduxs/finance/host/action"
import financeSelector from "../../reduxs/finance/selector"
import { onChangeIsLoaddingAction } from "../../reduxs/Loader/action"

const mapStateToProps = (state: RootReducers): IFinanceSummaryHostProps => {
  const financeHostSelector= financeSelector.financeHost(state)
  return {
    financeHostCode: financeHostSelector.code,
    financeHostError: financeHostSelector.error,
    financeHostIsFetching: financeHostSelector.isFetching,
    financeHostData: financeHostSelector.data,
  };
};

const mapDispatchToProps: IFinanceSummaryHostActionProps = {
  getFinanceHost,
  onChangeIsLoaddingAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinanceSummaryHost);
