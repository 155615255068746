import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { getRateAction } from "../actionCreator";
import { fetchGetRate } from "../services";

const getRate = () => async (dispatch: Dispatch) => {
  dispatch(getRateAction.request({}));
  return fetchGetRate()
    .then(
      (res: AxiosResponse<IAPIResponse<IResponseAgentLotteryRateClient[]>>) => {
        dispatch(getRateAction.success(res));
      }
    )
    .catch(
      (error: AxiosError<IAPIResponse<IResponseAgentLotteryRateClient[]>>) =>
        dispatch(getRateAction.failure(error))
    );
};

export { getRate };
