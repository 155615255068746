import { noop } from "lodash";
import React, { FC } from "react";
import "./buttonRadio.style.scss";

const defaultProps: IRadio = {
  datas: [],
  value:"",
  name: "radio",
  className:"",
  classNameRadio:"",
  onChange() {noop()},
  onClick() {noop()},
};

const RadioComponent: FC<IRadio> = (props) => {
  const { datas, name, className, classNameRadio, value, onChange, onClick } = props;

  let loopRadio = datas.map((data: IDataRadio, index: number) => (
    <div className={`container-radio d-flex ${classNameRadio}`} key={index}>
      <input
        type="radio"
        id={data.id}
        name={name}
        value={data.value}
        className="regular-radio"
        checked={data.value===value}
        onChange={()=>{onChange!(data.value)}}
        onClick={()=>{onClick!(data.value)}}
      />
      <label className="label-input-radio" htmlFor={data.id}>
        {data.text}
      </label>
    </div>
  ));

  return <div className={className}>{loopRadio}</div>;
};

RadioComponent.defaultProps = defaultProps

export default RadioComponent;
