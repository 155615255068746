import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { postChildAction } from "../actionCreator";
import { fetchPostChild } from "../services";

const postChild = (data: IV2PostAgentChild) => (dispatch: Dispatch) => {
    dispatch(postChildAction.request({}))
    return fetchPostChild(data)
    .then((res: AxiosResponse<{}>) => {
        dispatch(postChildAction.success(res))
    })
    .catch((error: AxiosError<IAPIResponse>) => {
        dispatch(postChildAction.failure(error))
    })
}

export { postChild }