import axios from "axios";
import environment from "../../constants/environment";
// import CryptoJS from "crypto-js";
// import ProjectENV from "constants/environment";

// const encryptPassword = (password: string) => {
//   const encryptedPassword = CryptoJS.AES.encrypt(
//     password,
//     ProjectENV.environments[environment.environmentName].endpoint.aesSecretKey
//   ).toString();
//   return encryptedPassword
// }

const fetchLogin = (data: IAgentLogin) => {
  // const encryptedPassword = encryptPassword(data.password)
  const loginData: IAgentLogin = {
    username: data.username,
    password: data.password,
  };
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url
    }${environment.endpoint.login}`;

  return axios.post(endpoint, loginData);
};

const fetchCreateOTP = (data: ICreateOTPRequest) => {
  const createOTPData: ICreateOTPRequest = {
    phoneNumber: data.phoneNumber,
  };
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url
    }${environment.endpoint.otp}`;
  return axios.post(endpoint, createOTPData);
};

const fetchLoginInitialPassword = (data: IAgentInitialPassword) => {
  const createLoginInitialData: IAgentInitialPassword = {
    password: data.password,
    confirmPassword: data.confirmPassword,
  };
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url
    }${environment.endpoint.loginInitialPassword}`;
  return axios.post(endpoint, createLoginInitialData);
};

const fetchLoginPassword = (data: IAgentInitialPassword) => {
  const createLoginPasswordData: IAgentInitialPassword = {
    password: data.password,
    confirmPassword: data.confirmPassword,
  };
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url
    }${environment.endpoint.loginPassword}`;
  return axios.post(endpoint, createLoginPasswordData);
};

const fetchPostRegister2FA = () => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.postRegister2FA}`;
  return axios.post(endpoint)
}

const fetchPostVerify2FA = (password: string) => {
  const password2FA = {
    token: password
  }

  const endpoint = `${environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.postVerify2FA}`
  return axios.post(endpoint, password2FA)
}

const fetchPostValidate2FA = (password: string) => {
  const password2FA = {
    token: password
  }

  const endpoint = `${environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.postValidate2FA}`
  return axios.post(endpoint, password2FA)
}

export {
  fetchLogin,
  fetchCreateOTP,
  fetchLoginInitialPassword,
  fetchLoginPassword,
  fetchPostRegister2FA,
  fetchPostVerify2FA,
  fetchPostValidate2FA
};
