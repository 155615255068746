import { connect } from "react-redux";
import CustomerDetail from "./CustomerDetail.container";
import { onChangeIsLoaddingAction } from "../../reduxs/Loader/action"
import { getCustomer,getCustomerNote,postCustomerNote } from "reduxs/customer/action";
import customerSelector from "reduxs/customer/selector";
import { getGetBetGroup } from "reduxs/lotto/bet/bet/action";
import betGroup from "reduxs/lotto/bet/selector";
import { getFinanceCustomerTx } from "reduxs/finance/customer/action";
import fianceCustomerSelector from "reduxs/finance/selector";
import { getMe } from "reduxs/me/me/action";
import membersSelector from "../../reduxs/members/selector"


const mapStateToProps =(state: RootReducers):ICustomerDetailProps=>{
    const customerList = customerSelector.customerList(state)
    const customerNoteList = customerSelector.customerNoteList(state)
    const betGroupV2 = betGroup.betGroup(state);
    const customerTx = fianceCustomerSelector.fianceCustomerTx(state);
    const postcustomerNoteList = customerSelector.postcustomerNoteList(state)
    const getMeList = membersSelector.me(state)

    return {
        customerCode: customerList.code,
        customerError:customerList.error,
        customerIsFetching: customerList.isFetching,
        customerData: customerList.data as any,

        customerNoteCode: customerNoteList.code,
        customerNoteError:customerNoteList.error,
        customerNoteIsFetching: customerNoteList.isFetching,
        customerNoteData: customerNoteList.data,

        betGroupV2Data: betGroupV2.data,
        betGroupV2IsFetching: betGroupV2.isFetching,
        betGroupV2Code: betGroupV2.code,
        betGroupV2Error: betGroupV2.error,

        customerTxCode: customerTx.code,
        customerTxError:customerTx.error,
        customerTxIsFetching: customerTx.isFetching,
        customerTxData: customerTx.data,

        postcustomerNoteCode: postcustomerNoteList.code,
        postcustomerNoteError:postcustomerNoteList.error,
        postcustomerNoteIsFetching: postcustomerNoteList.isFetching,
        postcustomerNoteData: postcustomerNoteList.data,

        getMeData :  (getMeList as any)?.data,

      }
}
const mapDispatchToProps:ICustomerDetailActionProps = {
    getMe,
    postCustomerNote:postCustomerNote,
    getCustomerNote:getCustomerNote,
    getFinanceCustomerTx:getFinanceCustomerTx,
    getGetBetGroup:getGetBetGroup,
    getCustomer: getCustomer,
    onChangeIsLoaddingAction: onChangeIsLoaddingAction,
}


export default connect(mapStateToProps,mapDispatchToProps)(CustomerDetail)