import React, { FC } from "react";
import { isEmpty, noop } from "lodash";
import { Modal } from "../..";
import "./cancelBet.style.scss";
import { Button } from "components";
import logo from "assets/images/logo/logothailandbet.png";
import colors from "constants/colors";

const constants = {
  title: "คุณแน่ใจหรือไม่",
  description: "คุณแน่ใจหรือว่าจะเปลี่ยนรหัสผ่าน",
  actionText: "เข้าใจแล้ว",
  titleWeb: "ไทยแลนด์เบท",
  titleEng: "Thailand Bet",
  cancel: "ยกเลิก",
  accept: "ตกลง",
};

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: IInfoModal = {
  title: constants.title,
  description: constants.description,
  actionText: constants.actionText,
  acceptText: 'ตกลง',
  action() {
    noop();
  },
  cancelAction() {
    noop();
  },
  titleStyle: ''
};

const CancelBetModal: FC<IInfoModal & DefaultProps> = (props) => {
  return (
    <div className="cancel-bet-modal-container border-rounded">
      <div className="row mr-0">
        <div className="leading-logo-container m2-x">
          <img src={logo} className="logoMenu" alt="logo Thailand bet" />
        </div>
        <div className="title-text-web">
          <h3 className="title">{constants.titleWeb}</h3>
          <h5 className="tertiary-text sub-title">{constants.titleEng}</h5>
        </div>
      </div>
      <h2 className={`title-text secondary-text ${props.textSmall&&"text-small"} whitespace ${props.titleStyle}`} style={{marginBottom:39}}>{props.title}</h2>
      <p className={`body-semi1 tertiary-text ${props.textSmall&&"text-small"}`} style={{marginBottom:41}}>{props.description}</p>
      <div className="row justify-content-center">
        <div className="col pr-0">
          <Button
            size="mediumHeight44"
            id="confirm-button"
            type="rectangle"
            onClick={props.action}
            text={props.acceptText!}
            textSize={18}
            transitionType="typeOne"
          />
        </div>
        <div className="col">
          <Button
            size="mediumHeight44"
            id="confirm-button"
            type="rectangle"
            backgroundColor={colors.TERTIARY_TEXT}
            backgroundHoverColor={colors.TERTIARY_TEXT}
            borderColor={colors.SECONDARY_TEXT}
            onClick={props.cancelAction}
            text={constants.cancel!}
            transitionType="typeOne"
            textSize={18}
          />
        </div>
      </div>
    </div>
  );
};

CancelBetModal.defaultProps = defaultProps;

const cancelBetModal = {
  show: (props: IInfoModal) => {
    return Modal.event("MODAL_OVER", {
      state: "show",
      extraProps: { size: isEmpty(props.size)?"md":props.size },
      component: <CancelBetModal {...props} />,
    });
  },
  hide: () => Modal.event("MODAL_OVER", { state: "hide" }),
};

export default cancelBetModal;
