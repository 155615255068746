export const AUTOCOMPLETE_REQUEST = "AUTOCOMPLETE_REQUEST";
export const AUTOCOMPLETE_SUCCESS = "AUTOCOMPLETE_SUCCESS";
export const AUTOCOMPLETE_FAILURE = "AUTOCOMPLETE_FAILURE";

export const initialAutocompleteState: ReducerState<IPagination<IAutocomplete>> = {
  isFetching: false,
  code: 0,
  data: {
    totalRecord:0,
    page: 1,
    limit: 10,
    total: 0,
    dataList: []
  },
  error: ''
}