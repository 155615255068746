export const POST_CHILD_REQUEST = "POST_CHILD_REQUEST";
export const POST_CHILD_SUCCESS = "POST_CHILD_SUCCESS";
export const POST_CHILD_FAILURE = "POST_CHILD_FAILURE";

export const GET_CHILD_DETAIL_REQUEST = "GET_CHILD_DETAIL_REQUEST";
export const GET_CHILD_DETAIL_SUCCESS = "GET_CHILD_DETAIL_SUCCESS";
export const GET_CHILD_DETAIL_FAILURE = "GET_CHILD_DETAIL_FAILURE";

export const GET_CHILD_BET_REQUEST = "GET_CHILD_BET_REQUEST";
export const GET_CHILD_BET_SUCCESS = "GET_CHILD_BET_SUCCESS";
export const GET_CHILD_BET_FAILURE = "GET_CHILD_BET_FAILURE";

export const POST_CHILD_SETTING_LOTTERY_UPDATE_REQUEST = "POST_CHILD_SETTING_LOTTERY_UPDATE_REQUEST"
export const POST_CHILD_SETTING_LOTTERY_UPDATE_SUCCESS = "POST_CHILD_SETTING_LOTTERY_UPDATE_SUCCESS"
export const POST_CHILD_SETTING_LOTTERY_UPDATE_FAILURE = "POST_CHILD_SETTING_LOTTERY_UPDATE_FAILURE"

export const POST_CHILD_UPDATE_REQUEST = "POST_CHILD_UPDATE_REQUEST"
export const POST_CHILD_UPDATE_SUCCESS = "POST_CHILD_UPDATE_SUCCESS"
export const POST_CHILD_UPDATE_FAILURE = "POST_CHILD_UPDATE_FAILURE"

export const POST_CHILD_RESET_PASSWORD_REQUEST = "POST_CHILD_RESET_PASSWORD_REQUEST"
export const POST_CHILD_RESET_PASSWORD_SUCCESS = "POST_CHILD_RESET_PASSWORD_SUCCESS"
export const POST_CHILD_RESET_PASSWORD_FAILURE = "POST_CHILD_RESET_PASSWORD_FAILURE"
export const POST_CHILD_SETTING_UPDATE_REQUEST = "POST_CHILD_SETTING_UPDATE_REQUEST"
export const POST_CHILD_SETTING_UPDATE_SUCCESS = "POST_CHILD_SETTING_UPDATE_SUCCESS"
export const POST_CHILD_SETTING_UPDATE_FAILURE = "POST_CHILD_SETTING_UPDATE_FAILURE"

export const POST_CHILD_CREDIT_UPDATE_REQUEST = "POST_CHILD_CREDIT_UPDATE_REQUEST"
export const POST_CHILD_CREDIT_UPDATE_SUCCESS = "POST_CHILD_CREDIT_UPDATE_SUCCESS"
export const POST_CHILD_CREDIT_UPDATE_FAILURE = "POST_CHILD_CREDIT_UPDATE_FAILURE"

export const childState: ReducerState<{}> = {
  isFetching: false,
  code: 0,
  data: {},
  error: "",
};

export const childDetailState: ReducerState<
  IPagination<
    IV2GetAgentChild &
    IV2GetAgentChildSettingUpdate &
    IV2PostAgentChildSettingLotteryUpdate
  >
> = {
  isFetching: false,
  code: 0,
  data: {
    dataList: [],
    limit: 10,
    page: 1,
    total: 1,
    totalRecord: 0,
  },
  error: "",
};

export const childBetState: ReducerState<IPagination<IV2RespAgentChildBet>> = {
  isFetching: false,
  code: 0,
  data: {
    dataList: [],
    limit: 10,
    page: 1,
    total: 1,
    totalRecord: 0,
  },
  error: "",
};

export const childSettingLotteryUpdateState: ReducerState<{}> = {
  isFetching: false,
  code: 0,
  data: {},
  error: "",
}

export const childUpdateState: ReducerState<{}> = {
  isFetching: false,
  code: 0,
  data: {},
  error: "",
}

export const childResetPasswordState: ReducerState<{}> = {
  isFetching: false,
  code: 0,
  data: {},
  error: "",
}
export const childSettingUpdateState: ReducerState<{}> = {
  isFetching: false,
  code: 0,
  data: {},
  error: "",
}

export const childCreditUpdateState: ReducerState<{}> = {
  isFetching: false,
  code: 0,
  data: {},
  error: "",
}
