import { Modal } from "components/Modal";
import { get, isEmpty, isNull, noop } from "lodash";
import React, { FC } from "react";
// import "./reportPay.style.scss";
import logo from "assets/images/logo/logothailandbet.png";
import { number } from "utils";
import { BANK_TYPE } from "constants/variables";
import { Input } from "components/Input";
import { useState } from "react";
import color from "constants/colors";
import { Button } from "components/Button";
import "../PaidNotice/paidNotice.style.scss";


const constants = {
  title: "แจ้งจ่าย",
  titleWeb: "ไทยแลนด์เบท",
  titleEng: "Thailand Bet",
  agentShareId: "เลขบิล:",
  payChild: "ยอด:",
  note: "ข้อมูลเพิ่มเติมถึงลูกสมาชิก",
  line: "ไอดีไลน์:",
  phoneNumber: "เบอร์:",
  createdAt: "เวลาแจ้ง:",
  description: "คุณแน่ใจหรือว่าจะแจ้งจ่าย?",
  accept: "ยืนยัน",
  subTitle: "บัญชีรับเงิน",
  bankType: "ธนาคาร:",
  bankNumber: "เลขที่บัญชี:",
  bankName: "ชื่อบัญชี:",
  saveRequired: "โปรดกรอกบันทึกเพิ่มเติมเพื่อยืนยัน",
  cancel: "ยกเลิก",
};

const defaultProps: IReportPayModal = {
  title: constants.title,
  description: constants.description,
  action() {
    noop();
  },
  cancelAction() {
    noop();
  },
  queryObj: {
    agentShareId: 0,
    childAgentId: 0,
  }
};


const ReportPayModal: FC<IReportPayModal> = (props) => {
  const [note, setNote] = useState("");
  const [confirm, setConfirm] = useState(false);
  const handleSubmit = () => {
    setConfirm(true);
    if (note !== "" && props.detailBill) {
      const calPayBill = number.calculator(`${props.detailBill?.payHost}-${props.detailBill?.recieveHost}`)
      const castToPayNumber = (calPayBill < 0) ? (calPayBill*(-1)) : 0 
      const req: IV2PostAgentFinanceChildRequest = {
        agentShareId: props.queryObj.agentShareId,
        pay: Math.floor(castToPayNumber * 100) / 100,
        note,
      };
      props.action!(req);
    }
  };

  const replaceBankType = (data:string)=>{
    if(data==="-"||isNull(data)||isEmpty(data)){
      return "-"
    }
    var dataNew =  data.slice(6)
    return dataNew
  }
  const numberSpace =(number:string)=>{

    if (number.length === 10) {
      var number1 = number.slice(0, 3);
      var number2 = number.slice(3, 7);
      var number3 = number.slice(7, 10);
      return number1 + " " + number2 + " " + number3
    }

    if (number.length === 12) {
      var number4 = number.slice(0, 1);
      var number5 = number.slice(1, 4);
      var number6 = number.slice(4, 8);
      var number7 = number.slice(8, 12);
      return (
        number4 +
        " " +
        number5 +
        " " +
        number6 +
        " " +
        number7 
   
      );
    }
    if (number === "-"||isNull(number)||isEmpty(number)) {
      return "-";
    }
  }

  const calPayBill =  number.calculator(`${props.detailBill?.payHost}-${props.detailBill?.recieveHost}`)
  
  return (

  <div className="clearbill-modal-container border-rounded">
  <div className="row mr-0">
    <div className="leading-logo-container m2-x">
      <img src={logo} className="logoMenu" alt="" />
    </div>
    <div className="title-text-web">
      <h3 className="title">{constants.titleWeb}</h3>
      <h5 className="tertiary-text sub-title">{constants.titleEng}</h5>
    </div>
  </div>
  <h2 className="title-text secondary-text">{constants.title}</h2>

  <div className="container-detail m4-b">
    <div className="row list-summary-container no-gutters">
      <div className="col-12 d-flex m2-b flex-row">
        <div className="body-3b ellipsis-text" style={{ width: "14%" }}>
          {constants.agentShareId}
        </div>
        <div className="body-3 ellipsis-text" style={{ width: "36%" }}>
             {props.detailBill?.agentShareId}
        </div>
        <div className="body-3b ellipsis-text" style={{ width: "10%" }}>
          {constants.payChild}
        </div>
        <div className="body-3 ellipsis-text" style={{ width: "40%" }}>
        {number.addComma(calPayBill < 0 ? (calPayBill*(-1)) : 0)}
        </div>
      </div>
      <div className="col-12 d-flex m2-b flex-row">
        <div className="body-3b ellipsis-text" style={{ width: "14%" }}>
          {constants.line}
        </div>
        <div className="body-3" style={{ width: "36%", wordBreak: 'break-word', paddingRight: 8, textAlign: 'left' }}>
        {isEmpty(get(props.detailBill, "childLine","-"))||isNull(get(props.detailBill, "childLine","-")) ? "-":props.detailBill?.childLine}
        </div>
        <div className="body-3b ellipsis-text" style={{ width: "10%" }}>
          {constants.phoneNumber}
        </div>
        <div className="body-3 ellipsis-text" style={{ width: "40%" }}>
        {isEmpty(get(props.detailBill, "childPhoneNumber","-"))||isNull(get(props.detailBill, "childPhoneNumber","-")) ? "-":props.detailBill?.childPhoneNumber}
        </div>
      </div>
    </div>
  </div>
  <div className="secondary-text clear-bill-finance-child-subtitle-text">
    {constants.subTitle}
  </div>
  <div className="container-detail m4-b">
    <div className="row list-summary-container no-gutters">
      <div className="col-12 d-flex m2-b flex-row">
        <div className="body-3b ellipsis-text" style={{ width: "14%" }}>
          {constants.bankType}
        </div>
        <div className="body-3 ellipsis-text" style={{ width: "36%" }}>
        {replaceBankType(BANK_TYPE[get(props.detailBill, "childBankType", "GHBANK")])}
        </div>
        <div className="body-3b ellipsis-text" style={{ width: "18%" }}>
          {constants.bankNumber}
        </div>
        <div className="body-3 ellipsis-text" style={{ width: "32%" }}>
        {numberSpace(String(isEmpty(get(props.detailBill, "childBankNumber","-"))||isNull(get(props.detailBill, "childBankNumber","-")) ? "-":get(props.detailBill, "childBankNumber","-")))}
        </div>
      </div>
      <div className="col-12 d-flex m2-b flex-row">
        <div className="body-3b ellipsis-text" style={{ width: "14%" }}>
          {constants.bankName}
        </div>
        <div className="body-3" style={{ width: "86%", wordBreak: 'break-word', textAlign: 'left', paddingRight: 32 }}>
        {isEmpty(get(props.detailBill, "childBankName","-"))||isNull(get(props.detailBill, "childBankName","-")) ? "-":props.detailBill?.childBankName}
        </div>
      </div>
    </div>
  </div>
  <div className="d-flex subtitle-2 m0-b">{constants.note}</div>
  <Input
    id="search-customerName"
    name="search-customerName"
    inputClassName="input-save border"
    placeholder={constants.note}
    backgroundColor={color.PRIMARY_BG}
    value={note}
    onChange={(e) => {
      setNote(e.target.value);
    }}
    error={confirm && note === ""}
    errorMessage={constants.saveRequired}
    renderError={true}
  />

  <p className="body-semi1 tertiary-text my-4">{constants.description}</p>
  <div className="row justify-content-center">
    <div className="col p0-r">
      <Button
        id="confirm-button"
        type="rectangle"
        onClick={handleSubmit}
        text={constants.accept!}
        textSize={18}
        textColor={color.PRIMARY_BG}
        transitionType="typeOne"
      />
    </div>
    <div className="col">
      <Button
        id="confirm-button"
        type="rectangle"
        backgroundColor={color.TERTIARY_TEXT}
        backgroundHoverColor={color.TERTIARY_TEXT}
        borderColor={color.SECONDARY_TEXT}
        onClick={props.cancelAction}
        text={constants.cancel!}
        transitionType="typeOne"
        textSize={18}
        textColor={color.PRIMARY_BG}
      />
    </div>
  </div>
</div>
);
};

ReportPayModal.defaultProps = defaultProps;

const modal = {
  show: (props: IReportPayModal) => {
    return Modal.event("MODAL_OVER", {
      state: "show",
      extraProps: { size: "md" },
      component: <ReportPayModal {...props} />,
    });
  },
  hide: () => Modal.event("MODAL_OVER", { state: "hide" }),
};

export default modal;
