import { AxiosError, AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { ActionType, createReducer } from "typesafe-actions";
import { responseConverter } from "utils";
import { financeChildState, financeCustomerState, financeCustomerTxResState, financeCustomerTxState, financeChildMeState, financeChildApproveState, financeChildRequestState, financeHostState, financeHostMeState } from "./constants"
import * as financeAction from './actionCreator'
import { includes } from "lodash";

export type FinanceActionType = ActionType<typeof financeAction>


const financeCustomer = createReducer<
  ReducerState<IPagination<IV2RespAgentFinanceCustomer>>, FinanceActionType>(financeCustomerState)
  .handleAction(
    financeAction.financeCustomerAction.request,
    (state: ReducerState<IPagination<IV2RespAgentFinanceCustomer>>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    financeAction.financeCustomerAction.success,
    (state: ReducerState<IPagination<IV2RespAgentFinanceCustomer>>, action: FinanceActionType) => {
      const payload: AxiosResponse<IAPIResponse<IPagination<IV2RespAgentFinanceCustomer>>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
        error: "",
      };
    }
  )
  .handleAction(
    financeAction.financeCustomerAction.failure,
    (state: ReducerState<IPagination<IV2RespAgentFinanceCustomer>>, action: FinanceActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )

const financeChild = createReducer<
  ReducerState<IPagination<IV2RespAgentFinanceChild>>, FinanceActionType>(financeChildState)
  .handleAction(
    financeAction.financeChildAction.request,
    (state: ReducerState<IPagination<IV2RespAgentFinanceChild>>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    financeAction.financeChildAction.success,
    (state: ReducerState<IPagination<IV2RespAgentFinanceChild>>, action: FinanceActionType) => {
      const payload: AxiosResponse<IAPIResponse<IPagination<IV2RespAgentFinanceChild>>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: { ...state.data, ...payload.data.data },
        error: "",
      };
    }
  )
  .handleAction(
    financeAction.financeChildAction.failure,
    (state: ReducerState<IPagination<IV2RespAgentFinanceChild>>, action: FinanceActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )

const financeChildMe = createReducer<
  ReducerfinanceChildState<IPagination<IV2RespAgentFinanceChildMe>>, FinanceActionType>(financeChildMeState)
  .handleAction(
    financeAction.financeChildMeAction.request,
    (state: ReducerfinanceChildState<IPagination<IV2RespAgentFinanceChildMe>>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    financeAction.financeChildMeAction.success,
    (state: ReducerfinanceChildState<IPagination<IV2RespAgentFinanceChildMe>>, action: FinanceActionType) => {
      const payload: AxiosResponse<IAPIResponse<IPagination<IV2RespAgentFinanceChildMe>>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data:  includes(payload.config.url,'child_agent_id') ?  {...state.data}:  payload.data.data,
        error: "",
        dataReseive:  includes(payload.config.url,'child_agent_id') ?  payload.data.data :  {...state.dataReseive}
      };
    }
  )
  .handleAction(
    financeAction.financeChildMeAction.failure,
    (state: ReducerfinanceChildState<IPagination<IV2RespAgentFinanceChildMe>>, action: FinanceActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )
const financeChildApprove = createReducer<ReducerState<IV2RespAgentFinanceChildApprove>, FinanceActionType>(financeChildApproveState)
  .handleAction(financeAction.financeChildApproveAction.request, (state: ReducerState<IV2RespAgentFinanceChildApprove>) => {
    return {
      ...state,
      isFetching: true,
    }
  })
  .handleAction(financeAction.financeChildApproveAction.success, (state: ReducerState<IV2RespAgentFinanceChildApprove>, action: FinanceActionType) => {
    const payload: AxiosResponse<IAPIResponse<IV2RespAgentFinanceChildApprove>> = action.payload
    const convertedResponse = responseConverter.getMessage(payload)
    return {
      ...state,
      isFetching: false,
      code: convertedResponse.code,
      data: { ...state.data, ...payload.data.data },
      error: "",
    }
  })
  .handleAction(financeAction.financeChildApproveAction.failure, (state: ReducerState<IV2RespAgentFinanceChildApprove>, action: FinanceActionType) => {
    const payload: AxiosError<IAPIResponse> = action.payload
    const convertedResponse = responseConverter.getMessage(payload)

    return {
      ...state,
      isFetching: false,
      code: convertedResponse.code,
      error: convertedResponse.message,
    }
  })

const financeCustomerTx = createReducer<ReducerState<IPagination<IV2RespAgentFinanceCustomerTx>>, FinanceActionType>(financeCustomerTxState)
  .handleAction(
    financeAction.financeCustomerTxAction.request,
    (state: ReducerState<IPagination<IV2RespAgentFinanceCustomerTx>>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    financeAction.financeCustomerTxAction.success,
    (state: ReducerState<IPagination<IV2RespAgentFinanceCustomerTx>>, action: FinanceActionType) => {
      const payload: AxiosResponse<IAPIResponse<IPagination<IV2RespAgentFinanceCustomerTx>>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
      };
    }
  )
  .handleAction(
    financeAction.financeCustomerTxAction.failure,
    (state: ReducerState<IPagination<IV2RespAgentFinanceCustomerTx>>, action: FinanceActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )


const financeHost = createReducer<
  ReducerState<IPagination<IV2RespAgentFinanceHost>>, FinanceActionType>(financeHostState)
  .handleAction(
    financeAction.financeHostAction.request,
    (state: ReducerState<IPagination<IV2RespAgentFinanceHost>>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    financeAction.financeHostAction.success,
    (state: ReducerState<IPagination<IV2RespAgentFinanceHost>>, action: FinanceActionType) => {
      const payload: AxiosResponse<IAPIResponse<IPagination<IV2RespAgentFinanceHost>>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
        error: "",
      };
    }
  )
  .handleAction(
    financeAction.financeHostAction.failure,
    (state: ReducerState<IPagination<IV2RespAgentFinanceHost>>, action: FinanceActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )

const financeCustomerPostTx = createReducer<ReducerState<IAPIResponse<object>>, FinanceActionType>(financeCustomerTxResState)
  .handleAction(financeAction.financeCustomerpostTxAction.request, (state: ReducerState<IAPIResponse<object>>) => {
    return {
      ...state,
      isFetching: true,
    }
  })
  .handleAction(financeAction.financeCustomerpostTxAction.success, (state: ReducerState<IAPIResponse<object>>, action: FinanceActionType) => {
    const payload: AxiosResponse<IAPIResponse<IAPIResponse<object>>> = action.payload
    const convertedResponse = responseConverter.getMessage(payload)
    return {
      ...state,
      isFetching: false,
      code: convertedResponse.code,
      data: payload.data,
    }
  })
  .handleAction(financeAction.financeCustomerpostTxAction.failure, (state: ReducerState<IAPIResponse<object>>, action: FinanceActionType) => {
    const payload: AxiosError<IAPIResponse> = action.payload
    const convertedResponse = responseConverter.getMessage(payload)

    return {
      ...state,
      isFetching: false,
      code: convertedResponse.code,
      error: convertedResponse.message,
    }
  })
  .handleAction(financeAction.financeCustomerpostTxAction.cancel, () => financeCustomerTxResState)

    const financeChildRequest = createReducer<ReducerState<IV2RespAgentFinanceChildRequest>, FinanceActionType>(financeChildRequestState)
    .handleAction(financeAction.financeChildRequestAction.request, (state: ReducerState<IV2RespAgentFinanceChildRequest>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(financeAction.financeChildRequestAction.success, (state: ReducerState<IV2RespAgentFinanceChildRequest>, action: FinanceActionType) => {
        const payload: AxiosResponse<IAPIResponse<IV2RespAgentFinanceChildRequest>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data: {...state.data, ...payload.data.data},
            error: "",
        }
    })
    .handleAction(financeAction.financeChildRequestAction.failure, (state: ReducerState<IV2RespAgentFinanceChildRequest>, action: FinanceActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)

        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        }
    })

    const financeHostMe = createReducer<
  ReducerState<IPagination<IV2RespAgentFinanceHostMe>>,FinanceActionType>(financeHostMeState)
  .handleAction(
    financeAction.financeHostMeAction.request,
    (state: ReducerState<IPagination<IV2RespAgentFinanceHostMe>>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    financeAction.financeHostMeAction.success,
    (state: ReducerState<IPagination<IV2RespAgentFinanceHostMe>>, action: FinanceActionType) => {
      const payload: AxiosResponse<IAPIResponse<IPagination<IV2RespAgentFinanceHostMe>>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
      };
    }
  )
  .handleAction(
    financeAction.financeHostMeAction.failure,
    (state: ReducerState<IPagination<IV2RespAgentFinanceHostMe>>, action: FinanceActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )

export default combineReducers({
  financeCustomer,
  financeChild,
  financeCustomerTx,
  financeCustomerPostTx,
  financeChildMe,
  financeChildApprove,
  financeChildRequest,
  financeHostMe,
  financeHost,
});
