import { connect } from "react-redux"
import BetLotteryGroupContainer from "./BetLotteryGroup.container"
import { onChangeIsLoaddingAction } from "reduxs/Loader/action"
import { getBetExtraction, getBetExtractionDetail } from "reduxs/lotto/bet/group/action"
import betGroup from "reduxs/lotto/bet/selector"
import { getDataV2LotteryList } from "reduxs/lotto/list/action"
import lottoSelector from "reduxs/lotto/selector"
import { postCancelBill } from "reduxs/cancelBill/action"
const mapStateToProps = (state: RootReducers): BetLotteryGroupContainerProps => {
  const getAgentBetExtraction = betGroup.getBetExtraction(state)
  const getAgentBetExtractionDetail = betGroup.getBetExtractionDetail(state)
  const lottoScheduleV2 = lottoSelector.listV2(state);
  return {
    getBetExtractionData :getAgentBetExtraction.data,
    getBetExtractionIsFetching :getAgentBetExtraction.isFetching,
    getBetExtractionCode :getAgentBetExtraction.code,
    getBetExtractionError :getAgentBetExtraction.error,
    meData: state.me.me.data,
    getBetExtractionDetailData :getAgentBetExtractionDetail.data,
    getBetExtractionDetailIsFetching :getAgentBetExtractionDetail.isFetching,
    getBetExtractionDetailCode :getAgentBetExtractionDetail.code,
    getBetExtractionDetailError :getAgentBetExtractionDetail.error,
    lottoScheduleV2Data: lottoScheduleV2.data,
    lottoScheduleV2IsFetching: lottoScheduleV2.isFetching,
    lottoScheduleV2Code: lottoScheduleV2.code,
    lottoScheduleV2Error: lottoScheduleV2.error,

    postCancelBillCode: state.cancelBill.cancelBill.code,
    postCancelBillIsFetching : state.cancelBill.cancelBill.isFetching,
    postCancelBillError: state.cancelBill.cancelBill.error,
  };
};

const mapDispatchToProps: BetLotteryGroupContainerAction = {
  onChangeIsLoaddingAction,
  getBetExtraction,
  getBetExtractionDetail,
  getDataV2LotteryList,
  postCancelBill
};

export default connect(mapStateToProps, mapDispatchToProps)(BetLotteryGroupContainer)
