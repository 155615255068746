import { AxiosError, AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { ActionType, createReducer } from "typesafe-actions";
import { responseConverter } from "../../utils";
import * as creditAction from "./actionCreator";
import {
  creditsState,
  transactionListsState,
  transferCreditListState,
  transferCreditDescriptionState,
  PayRequestState,
  transferConfirmationListState,
  PayAcceptState,
} from "./constants";

export type CreditActionType = ActionType<typeof creditAction>;
const credits = createReducer<
  ReducerState<IResponseAgentFinanceMe>,
  CreditActionType
>(creditsState)
  .handleAction(
    creditAction.creditAction.request,
    (state: ReducerState<IResponseAgentFinanceMe>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    creditAction.creditAction.success,
    (
      state: ReducerState<IResponseAgentFinanceMe>,
      action: CreditActionType
    ) => {
      const payload: AxiosResponse<IAPIResponse<IResponseAgentFinanceMe>> =
        action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
      };
    }
  )
  .handleAction(
    creditAction.creditAction.failure,
    (
      state: ReducerState<IResponseAgentFinanceMe>,
      action: CreditActionType
    ) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )
  .handleAction(creditAction.creditAction.cancel, () => creditsState);

const transferCreditDescription = createReducer<
  ReducerState<IResponseAgentFinancePayDetail[]>
>(transferCreditDescriptionState)
  .handleAction(
    creditAction.transferCreditDescriptionAction.request,
    (state: ReducerState<IResponseAgentFinancePayDetail[]>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    creditAction.transferCreditDescriptionAction.success,
    (
      state: ReducerState<IResponseAgentFinancePayDetail[]>,
      action: CreditActionType
    ) => {
      const payload: AxiosResponse<
        IAPIResponse<IResponseAgentFinancePayDetail[]>
      > = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
      };
    }
  )
  .handleAction(
    creditAction.transferCreditDescriptionAction.failure,
    (
      state: ReducerState<IResponseAgentFinancePayDetail[]>,
      action: CreditActionType
    ) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )
  .handleAction(
    creditAction.transferCreditDescriptionAction.cancel,
    () => transferCreditDescriptionState
  );

const transactionLists = createReducer<
  ReducerState<IPagination<IResponseAgentFinList>>,
  CreditActionType
>(transactionListsState)
  .handleAction(
    creditAction.transactionAction.request,
    (state: ReducerState<IPagination<IResponseAgentFinList>>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    creditAction.transactionAction.success,
    (
      state: ReducerState<IPagination<IResponseAgentFinList>>,
      action: CreditActionType
    ) => {
      const payload: AxiosResponse<
        IAPIResponse<IPagination<IResponseAgentFinList>>
      > = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
      };
    }
  )
  .handleAction(
    creditAction.transactionAction.failure,
    (
      state: ReducerState<IPagination<IResponseAgentFinList>>,
      action: CreditActionType
    ) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )
  .handleAction(
    creditAction.transferCreditAction.cancel,
    () => transactionListsState
  );

const transferCredits = createReducer<
  ReducerState<IPagination<IResponseAgentFinancePay>>,
  CreditActionType
>(transferCreditListState)
  .handleAction(
    creditAction.transferCreditAction.request,
    (state: ReducerState<IPagination<IResponseAgentFinancePay>>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    creditAction.transferCreditAction.success,
    (
      state: ReducerState<IPagination<IResponseAgentFinancePay>>,
      action: CreditActionType
    ) => {
      const payload: AxiosResponse<
        IAPIResponse<IPagination<IResponseAgentFinancePay>>
      > = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
      };
    }
  )
  .handleAction(
    creditAction.transferCreditAction.failure,
    (
      state: ReducerState<IPagination<IResponseAgentFinancePay>>,
      action: CreditActionType
    ) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )
  .handleAction(
    creditAction.transferCreditAction.cancel,
    () => transferCreditListState
  );

const transferConfirmations = createReducer<
  ReducerState<IPagination<IResponseAgentRecvList>>,
  CreditActionType
>(transferConfirmationListState)
  .handleAction(
    creditAction.transferConfirmationAction.request,
    (state: ReducerState<IPagination<IResponseAgentRecvList>>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    creditAction.transferConfirmationAction.success,
    (
      state: ReducerState<IPagination<IResponseAgentRecvList>>,
      action: CreditActionType
    ) => {
      const payload: AxiosResponse<
        IAPIResponse<IPagination<IResponseAgentRecvList>>
      > = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
      };
    }
  )
  .handleAction(
    creditAction.transferConfirmationAction.failure,
    (
      state: ReducerState<IPagination<IResponseAgentRecvList>>,
      action: CreditActionType
    ) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )
  .handleAction(
    creditAction.transferConfirmationAction.cancel,
    () => transferConfirmationListState
  );

const payRequests = createReducer<
  ReducerState<IAgentPayRequest>,
  CreditActionType
>(PayRequestState)
  .handleAction(
    creditAction.payRequestAction.request,
    (state: ReducerState<IAgentPayRequest>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    creditAction.payRequestAction.success,
    (state: ReducerState<IAgentPayRequest>, action: CreditActionType) => {
      const payload: AxiosResponse<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
      };
    }
  )
  .handleAction(
    creditAction.payRequestAction.failure,
    (state: ReducerState<IAgentPayRequest>, action: CreditActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );

const payAccepts = createReducer<
  ReducerState<IAgentPayAccept>,
  CreditActionType
>(PayAcceptState)
  .handleAction(
    creditAction.payAcceptAction.request,
    (state: ReducerState<IAgentPayAccept>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    creditAction.payAcceptAction.success,
    (state: ReducerState<IAgentPayAccept>, action: CreditActionType) => {
      const payload: AxiosResponse<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
      };
    }
  )
  .handleAction(
    creditAction.payAcceptAction.failure,
    (state: ReducerState<IAgentPayAccept>, action: CreditActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );

export default combineReducers({
  payAccepts,
  payRequests,
  credits,
  transactionLists,
  transferCredits,
  transferCreditDescription,
  transferConfirmations,
});
