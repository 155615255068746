import React, { Component, createRef, RefObject } from "react";
import { ButtonGroup, Input, NumberPad } from "components";
import "./makingLotto.style.scss";
import colors from "constants/colors";
import { get, noop, map, isEqual, chunk } from "lodash";
import {
  LOTTO_GAME_TYPES,
  LOTTO_GAME_TYPE_LENGTH,
  LOTTO_GAME_TYPE_NAME,
} from "constants/variables";
import { number } from "utils";

const constants = {
  switchNumberMode: "กลับเลข",
  numberpadMode: "โหมดแป้นตัวเลข",
  numbersetMode: "โหมดชุดตัวเลข",
  betRate: "บาทละ",
  suitRate: "ชุดละ",
  gameType: "ประเภทการแทง",
  placeholderGameType: "เลือกประเภทการแทง",
  placeholderNumber: (numberSet: number) => `เลข ${numberSet} ตัว`,
  placeholoerGameMode: "กรอกตัวเลข 5 หลัก"
};

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: IMakingLottoComponentProps = {
  lottos: [],
  betRates: [],
  gameSlug: "LOTTER_YEGEE",
  onAddedNumber() {
    noop();
  },
  lottoStatus: "UNKNOWN",
  round:"",
  keyDown:""
};

enum GAMETYPE {
  TWO_UP = "TWO_UP",
  TWO_DOWN = "TWO_DOWN",
  THREE_UP = "THREE_UP",
  THREE_TOAST = "THREE_TOAST",
  FOUR_SUITE = "FOUR_SUITE",
  RUN_UP = "RUN_UP",
  RUN_DOWN = "RUN_DOWN"
}

class MakingLotto extends Component<
  IMakingLottoComponentProps & DefaultProps,
  IMakingLottoComponentState
> {
  numberLotto: RefObject<HTMLInputElement> = createRef()
  static defaultProps = defaultProps;
  state: IMakingLottoComponentState = {
    animated: false,
    numberSet: "",
    gameType: [get(LOTTO_GAME_TYPES, `${this.props.gameSlug}.0`, "THREE_UP")],
    inputMode: "NUMBERPAD",
    isSwitchedNumber: false,
    buttonGroupState: false,
  };

  componentDidMount() {
    if (this.props.lottoStatus === "NOT_ACCESS") {
      this.setState({ inputMode: "" });
    }
    this.numberLotto.current?.focus()
  }

  componentDidUpdate(prevProps:IMakingLottoComponentProps){
    if (this.props.keyDown !== prevProps.keyDown) {
      switch(this.props.keyDown){
        case ("ControlLeft"):
        case ("ControlRight"):
          this.handleOnChangeSwitchMode()
          this.numberLotto.current?.focus()
          break;
        case ("ShiftLeft"):
        case ("ShiftRight"):
          if (!(get(this.state.gameType,"[0]")==="RUN_UP"||get(this.state.gameType,"[0]")==="RUN_DOWN")) {
            this.handleOnChangeInputMode()
          }
          break;
        case ("F2"):
          this.setState({ numberSet: "" })
          const lengthGameType = this.state.gameType.length
          const gameType = get(this.state.gameType,"[0]")
          if (lengthGameType===2 && (gameType===GAMETYPE.THREE_UP|| gameType===GAMETYPE.THREE_TOAST)) {
            this.setState({gameType:[GAMETYPE.TWO_UP]})
          }else if (lengthGameType===2 && (gameType===GAMETYPE.TWO_UP|| gameType===GAMETYPE.TWO_DOWN)) {
            this.setState({gameType:[GAMETYPE.RUN_UP]})
          }else if (lengthGameType===2 && (gameType===GAMETYPE.RUN_UP|| gameType===GAMETYPE.RUN_DOWN)) {
            this.setState({gameType:[GAMETYPE.THREE_UP]})
          }else if (lengthGameType===1 && gameType===GAMETYPE.THREE_UP) {
            this.setState({gameType:[GAMETYPE.THREE_TOAST]})
          }else if (lengthGameType===1 && gameType===GAMETYPE.THREE_TOAST) {
            this.setState({gameType:[GAMETYPE.THREE_UP,GAMETYPE.THREE_TOAST]})
          }else if (lengthGameType===1 && gameType===GAMETYPE.TWO_UP) {
            this.setState({gameType:[GAMETYPE.TWO_DOWN]})
          }else if (lengthGameType===1 && gameType===GAMETYPE.TWO_DOWN) {
            this.setState({gameType:[GAMETYPE.TWO_UP,GAMETYPE.TWO_DOWN]})
          }else if (lengthGameType===1 && gameType===GAMETYPE.RUN_UP) {
            this.setState({gameType:[GAMETYPE.RUN_DOWN]})
          }else if (lengthGameType===1 && gameType===GAMETYPE.RUN_DOWN) {
            this.setState({gameType:[GAMETYPE.RUN_UP,GAMETYPE.RUN_DOWN]})
          }
          this.numberLotto.current?.focus()
          break;
      }
    }
  }

  handleOnClickNumberPad = (num: number) => {
    this.numberLotto.current?.focus()
    const gameType: TLottoGameType = get(this.state.gameType, "0", "") as any;
    const length = LOTTO_GAME_TYPE_LENGTH[gameType] || 0;
    const newValue = this.state.numberSet.concat(String(num));

    if (num === -1) {
      this.setState({ numberSet: this.state.numberSet.slice(0, -1) });
    } else if (this.state.numberSet.length < length ) {
      this.setState({ numberSet: newValue }, () => {
        this.handleOnAddNumber()
      });
    }
  };

  handleOnAddNumber = () => {
    if (this.isRejectAddingNumber()) {
      return;
    }
    const lottoNumbers: ILottoNumber[] = map(
      this.state.gameType,
      (gameType) => ({
        bet: this.state.numberSet,
        type: gameType,
      })
    );
    this.props.onAddedNumber(lottoNumbers, "ADD", this.state.isSwitchedNumber);
    // this.numberLotto.current?.blur()
    this.setState({ animated: true }, () => {
      const timeoutInstance = setTimeout(() => {
        this.setState({ numberSet: "", animated: false }, () => {
          clearTimeout(timeoutInstance);
        });
      }, 768);
    //   const timeoutOnfocus = setTimeout(() => {
    //     this.numberLotto.current?.focus()
    //     clearTimeout(timeoutOnfocus);
    //   }, 1500);
    });
  };

  isRejectAddingNumber = () => {
    const gameType: TLottoGameType = get(this.state.gameType, "0", "") as any;
    const length = LOTTO_GAME_TYPE_LENGTH[gameType] || 0;
    return this.state.numberSet.length < length;
  };

  renderLottoGameTypes = () => {
    const gameList = LOTTO_GAME_TYPES[this.props.gameSlug];
    const borderRadius = "4px";

    const LottoGameTypesComponent = map(gameList, (gameType, gameTypeIndex) => {
      const isCurrentActive = this.state.gameType.includes(gameType);

      const combinedBetRateType = `${this.props.gameSlug}_${gameType}`;
      const betRate = get(
        this.props.betRates.filter((rate) => rate.type === combinedBetRateType),
        "0",
        {}
      );
      const rateAsMoney = number.castToMoney(Number(betRate.rate || "0"));
      const betRateText = `${
        this.props.gameSlug === "LOTTER_LAO_SUITE"
          ? constants.suitRate
          : constants.betRate
      } ${rateAsMoney}`;

      const NormalCase = () => {
        return (
          <div
            className={`${
              this.props.gameSlug === "LOTTER_LAO_SUITE" ? "col-12" : "col-6"
            }`}
            key={`lotto-game-type-${gameTypeIndex}-${gameType}`}
          >
            <div className="d-flex">
              <ButtonGroup
                id={`lotto-game-type-${gameType}`}
                text={
                  <>
                    <h4 className="secondary-green-text">
                      {LOTTO_GAME_TYPE_NAME[gameType]}
                    </h4>
                    <h6 className="subtitle-2 tertiary-text">{betRateText}</h6>
                  </>
                }
                paddingY={8}
                forceState={isCurrentActive}
                defaultState={isCurrentActive}
                onChangeState={(state) => {
                  this.numberLotto.current?.focus()
                  if (state) {
                    let gameTypes: TLottoGameType[] = [];
                    if (this.state.gameType.length <= 0) {
                      gameTypes = [gameType];
                    } else if (this.state.gameType.length > 1) {
                      if (this.state.gameType.includes(gameType)) {
                        gameTypes = this.state.gameType.filter(
                          (type) => !isEqual(type, gameType)
                        );
                      } else {
                        gameTypes = [gameType];
                      }
                    } else if (this.state.gameType.length < 2) {
                      if (this.state.gameType.includes(gameType)) {
                        gameTypes = this.state.gameType;
                      } else {
                        const gameTypeState: TLottoGameType = get(
                          this.state.gameType,
                          "0",
                          ""
                        ) as any;
                        const lengthOriginal =
                          LOTTO_GAME_TYPE_LENGTH[gameTypeState] || 0;
                        const lengthTarget = LOTTO_GAME_TYPE_LENGTH[gameType];
                        if (lengthOriginal === lengthTarget) {
                          gameTypes = [...this.state.gameType, gameType];
                        } else {
                          gameTypes = [gameType];
                        }
                      }
                    } else {
                      gameTypes = [];
                    }
                    return this.setState({
                      gameType: gameTypes,
                      numberSet: "",
                      inputMode:
                        gameType === "RUN_DOWN" ||
                        gameType === "RUN_UP" ||
                        gameType === "FOUR_SUITE"
                          ? "NUMBERPAD"
                          : this.state.inputMode,
                    });
                  } else {
                    if (this.state.gameType.length > 1) {
                      if (this.state.gameType.includes(gameType)) {
                        const gameTypes = this.state.gameType.filter(
                          (type) => !isEqual(type, gameType)
                        );
                        return this.setState({
                          gameType: gameTypes,
                          numberSet: "",
                          inputMode:
                            gameType === "RUN_DOWN" ||
                            gameType === "RUN_UP" ||
                            gameType === "FOUR_SUITE"
                              ? "NUMBERPAD"
                              : this.state.inputMode,
                        });
                      }
                    }
                  }
                }}
                backgroundColor={colors.PRIMARY_BG}
                borderRadius={borderRadius}
                stylename="group"
                gameTypes={this.state.gameType}
                gameTypesIndex={gameTypeIndex}
              />
            </div>
          </div>
        );
      };

      const NotAccessCase = () => {
        return (
          <div
            className={`${
              this.props.gameSlug === "LOTTER_LAO_SUITE" ? "col-12" : "col-6"
            }`}
            key={`lotto-game-type-${gameTypeIndex}-${gameType}`}
          >
            <div className="d-flex">
              <ButtonGroup
                id={`lotto-game-type-${gameType}`}
                text={
                  <>
                    <h4 className="secondary-green-text">
                      {LOTTO_GAME_TYPE_NAME[gameType]}
                    </h4>
                    <h6 className="subtitle-2 tertiary-text">{betRateText}</h6>
                  </>
                }
                paddingY={8}
                backgroundColor={colors.PRIMARY_BG}
                borderRadius={borderRadius}
                stylename="groupNoAccess"
                gameTypes={this.state.gameType}
                gameTypesIndex={gameTypeIndex}
                isNotAccessCase={true}
              />
            </div>
          </div>
        );
      };
      if (this.props.lottoStatus === "NOT_ACCESS") {
        return NotAccessCase();
      } else {
        return NormalCase();
      }
    });
    const LottoGameTypes = chunk(LottoGameTypesComponent, 2).map(
      (LottoGameTypesComponent, index) => {
        return (
          <div
            className="row m1-t no-gutters"
            style={{
              backgroundColor: colors.TERTIARY_GREEN,
              borderRadius: `${borderRadius}`,
            }}
            key={index}
          >
            {LottoGameTypesComponent}
          </div>
        );
      }
    );
    return <>{LottoGameTypes}</>;
  };

  renderLottoNumber = () => {
    const gameType: TLottoGameType = get(this.state.gameType, "0", "") as any;
    const length = LOTTO_GAME_TYPE_LENGTH[gameType] || 0;
    return (
      <>
        <Input
          id={"numberSet"}
          name="numberSet"
          value={this.state.numberSet}
          backgroundColor={colors.TRANSPARENT}
          backgroundActiveColor={colors.TRANSPARENT}
          placeholder={constants.placeholderNumber(length)}
          ref={this.numberLotto}
          onChange={(e) =>
            {
              const gameType: TLottoGameType = get(this.state.gameType, "0", "") as any;
              const length = LOTTO_GAME_TYPE_LENGTH[gameType] || 0;
              if (this.state.numberSet.toString().length < length && Number(e.target.value)>=0) {
                this.setState({ numberSet: e.target.value.replace(/\D/g, "") }, () => {
                  this.handleOnAddNumber();
                })
              }
            }
          }
        >
        </Input>
      </>
    );
  };

  renderInputMode = () => {
    switch (this.state.inputMode) {
      case "NUMBERPAD":
        const LottoNumbersets = this.renderLottoNumber;
        return (
          <>
            <div className="row m4-t">
              <div className="col selected-number-ditgit">
                <LottoNumbersets />
              </div>
            </div>
            <div className="row m3-t">
              <div className="col">
                <NumberPad onNumberPresses={this.handleOnClickNumberPad} />
              </div>
            </div>
          </>
        );
      case "NUMBERSET":
        return (
          <>
            <div className="row m4-t">
              <div className="col">Coming Soon</div>
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  handleOnChangeSwitchMode = () =>
    this.setState({ isSwitchedNumber: !this.state.isSwitchedNumber });

  handleOnChangeInputMode = () => {
    if (this.state.inputMode === "NUMBERPAD") {
      return this.setState({ inputMode: "NUMBERSET", numberSet: "" });
    }
    return this.setState({ inputMode: "NUMBERPAD", numberSet: "" },()=>{
      this.numberLotto.current?.focus()
    });
  };

  render() {
    const GameInput = this.renderInputMode;
    const LottoGameTypes = this.renderLottoGameTypes;
    return (
      <div className="makingLotto-componants">
        <LottoGameTypes />
        {this.state.gameType.includes("RUN_DOWN") ||
        this.state.gameType.includes("RUN_UP") ||
        this.state.gameType.includes("FOUR_SUITE") ||
        this.props.lottoStatus === "NOT_ACCESS" ? (
          <></>
        ) : (
          <>
            <div className="row m2-t justify-content-around">
              <div className="col-5 text-center">
                <ButtonGroup
                  paddingX={15}
                  paddingY={5}
                  id="switch-number-mode"
                  text={constants.switchNumberMode}
                  onChangeState={this.handleOnChangeSwitchMode}
                  isActive={this.state.isSwitchedNumber}
                  isActiveCtrl={true}
                />
              </div>
              <div className="col-5 text-center">
                <ButtonGroup
                  paddingX={15}
                  paddingY={5}
                  id="changeto-number-set-mode"
                  text={
                    this.state.inputMode === "NUMBERSET"
                      ? constants.numberpadMode
                      : constants.numbersetMode
                  }
                  onChangeState={this.handleOnChangeInputMode}
                  isActiveCtrl={true}
                  isActive={this.state.inputMode === "NUMBERSET"}
                />
              </div>
            </div>
          </>
        )}
        <GameInput />
      </div>
    );
  }
}

export default MakingLotto;
