import React, { useState } from "react"
import { FunctionComponent } from "react"
import "../betSummaryResult.style.scss"
import { isEmpty, map, pick } from "lodash"
import { LoadingCircle } from "components"
import { fetchGetLottoBetMember } from "reduxs/lotto/bet/services"
import TableCollapsible from "./TableCollapsible"

const constants = {
    dataNotFound: "ไม่พบข้อมูล...",
}
interface ITableLottoBetLotto {
    localQuery: IV3QueryGetExtractionAgentBetLottery
    lotteryList: IV3LotteryBetAgentLotteryList[]
    childNestedList: SummaryChildListDetail[]
    userName: string
    loading: boolean
    handleOnClickChild: (childAgentId: number, lotteryName: string, memberUsername: string, memberPermission: string) => void
}

const SumTotalBetLotto: FunctionComponent<ITableLottoBetLotto> = ({
    localQuery,
    lotteryList,
    childNestedList,
    loading,
    userName,
    handleOnClickChild
}) => {

    const [isLoading, setIsLoading] = useState(false)
    const defaultMemberList: IV3RespGetLotteryBetMember = { memberList: [] }
    const [lotteryMemberList, setLotteryMemberList] = useState<{ [key in string]: IV3RespGetLotteryBetMember }>({ "DEFAULT": defaultMemberList })

    const getLottoBetMember = (lotteryName: string) => {
        let query = {}
        if(childNestedList.length > 1) {
            query = {childAgentId: childNestedList[childNestedList.length -1].childAgentId }
        }
        query = {
            ...query,
            lotteryName,
            betTransactionStatus: ['WINNER', 'LOSER'],
            ...pick(localQuery, ['startCreatedAt', 'endCreatedAt'])
        }
        fetchGetLottoBetMember({...query,lotteryName: lotteryName, betTransactionStatus: ['WINNER', 'LOSER'] })
            .then((response) => {
                if (response) {
                    const data = response.data.data;
                    setLotteryMemberList(prevState => ({
                        ...prevState,
                        [lotteryName]: data
                    }))
                }
            })
            .catch((error) => console.log(error))
            .finally(() => {
                setTimeout(() => {
                    setIsLoading(false)
                }, 300)
            })
    }

    const TableHead = () => {
        return <thead className='header-table table-bordered'>
            <tr className="text-center">
                <th rowSpan={2} colSpan={2}>ชนิดหวย</th>
                <th rowSpan={2}>ยอดทั้งหมด</th>
                <th colSpan={4}>สมาชิก</th>
                <th colSpan={4}>{userName}</th>
                <th colSpan={4}>บริษัท</th>
            </tr>
            <tr className="text-center">
                <th>ส่งออก</th>
                <th>ถูกรางวัล</th>
                <th>คอม</th>
                <th>รวม</th>
                <th>ถือหุ้น</th>
                <th>ถูกรางวัล</th>
                <th>คอม</th>
                <th>รวม</th>
                <th>ถือหุ้น</th>
                <th>ถูกรางวัล</th>
                <th>คอม</th>
                <th>รวม</th>
            </tr>
        </thead>
    }


    const tableBody = () => {
        const dataEmpty = (
            <tr>
                <td className="text-center" colSpan={15}>
                    {constants.dataNotFound}
                </td>
            </tr>
        )
        const loadingTable = (
            <tr>
                <td className="text-center" colSpan={15}>
                    <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
                </td>
            </tr>
        )
        const mapData = map(lotteryList, (item: IV3LotteryBetAgentLotteryList, index: number) => {
            return (
                <TableCollapsible
                    key={index}
                    handleOnClickChild={handleOnClickChild}
                    action={getLottoBetMember}
                    userName={userName}
                    loading={isLoading}
                    lottoBet={item}
                    lotteryMemberList={lotteryMemberList[`${item.lotteryName}`]}
                />
            )
        })
        return loading ? loadingTable : !isEmpty(lotteryList) ? mapData : dataEmpty
    }

    return (
        <div className="table-responsive-xl mb-3">
            <table className="w-100">
                <TableHead />
                <tbody className='table-bordered'>{tableBody()}</tbody>
            </table>
        </div>
    )
}

export default SumTotalBetLotto
