import {
  Breadcrumb,
  Button,
  ErrorModal,
  InputNumber,
  LabelBox,
  SuccessModal,
} from "components";
import errorModal from "components/Modal/components/Error/Error.component";
import { LOTTO_SLUG_NAME } from "constants/variables";
import { get, map, noop } from "lodash";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { number } from "utils";
import { format } from "date-fns";
import "./transferCreditDescription.style.scss";
import color from "constants/colors";
import { responseCode } from "constants/response";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const defaultProps: ITransferCreditDescriptionContainerProps &
  ITransferCreditDescriptionContainerAction = {
  TransferCreditDescriptionLists: [{}],
  cancelTransferCreditDescriptionLists() {
    noop();
  },
  getTransferCreditDescriptionLists() {
    noop();
  },
  postPayRequest() {
    noop();
  },
  PostPayRequestCode: 0,
  postPayRequestError: "",
  postPayRequestIsFetching: false,
  getTransferCreditDescriptionListsCode: 0,
  getTransferCreditDescriptionListsError: "",
  getTransferCreditDescriptionListsIsFetching: false,
};

type DefaultProps = Readonly<typeof defaultProps>;

const constants = {
  TransferConfirmationTitle: "บัญชีการเงิน",
  TransferConfirmationSubtitle: "โอนยอด",
  TransferConfirmationSubtitleFormat: (id: string, username: string) =>
    `โอนยอด: ${id}(${username})`,
  inputTransferTitle: "ยอดโอน",
  buttonTransferTitle: "โอนยอด",
  inputPlaceholder: "กรอกจำนวนยอดที่ต้องการโอน",
  update: "อัพเดท",
};

const mapHeaderTable: Object[] = [{ name: "รายการเดิมพัน" }, { name: "ยอด" }];

const gameType: { [type in string]: string } = {
  GAME_CASINO_JOKER_A_NIGHT_OUT: "Joker เกม A Night Out",
  GAME_CASINO_THBGAME_TAO_PAUND: "THB เกม เต๋าปั่น",
};

const betType = LOTTO_SLUG_NAME;

const titleType: { [type in string]: string } = {
  LOTTER: "หวยออนไลน์",
  GAME: "เกมส์",
};

export default class TransferCreditDescriptionContainer extends Component<
  DefaultProps & RouteComponentProps<{}, {}, IResponseAgentFinancePay>,
  ITransferCreditDescriptionContainerState
> {
  static defaultProps = defaultProps;

  state: ITransferCreditDescriptionContainerState = {
    rowCollapse: {},
    dataList: {
      id: 0,
      name: "",
      username: "",
      creditLimitDiff: "",
      phoneNumber: "",
      payBalance: 0,
    },
    dataPayDetail: [],
    createBody: {
      id: 0,
      balance: "",
    },
    postBody: {
      balance: 0,
      id: 0,
    },
  };
  componentDidMount() {
    this.setState(
      (_, props) => ({
        dataList: {
          creditLimitDiff: props.location.state.creditLimitDiff,
          id: props.location.state.id,
          name: props.location.state.name,
          payBalance: props.location.state.payBalance,
          phoneNumber: props.location.state.phoneNumber,
          username: props.location.state.username,
        },
      }),
      () =>
        this.state.dataList.id !== 0
          ? this.props.getTransferCreditDescriptionLists({
              id: this.state.dataList.id,
            })
          : this.props.history.replace("/transferCredits")
    );
  }

  componentDidUpdate(prevProps: ITransferCreditDescriptionContainerProps) {
    if (
      prevProps.getTransferCreditDescriptionListsIsFetching !==
        this.props.getTransferCreditDescriptionListsIsFetching &&
      !this.props.getTransferCreditDescriptionListsIsFetching
    ) {
      if (
        this.props.getTransferCreditDescriptionListsCode === responseCode.OK
      ) {
        const initialKey: { [key in number]: boolean } = {};
        this.setState(
          (_, props) => ({
            dataPayDetail: props.TransferCreditDescriptionLists,
          }),
          () => {
            Object.keys(this.state.dataPayDetail).forEach(
              (_, index) => (initialKey[index] = true)
            );
            return this.setState({ rowCollapse: initialKey });
          }
        );
      } else {
        ErrorModal.show({
          description: this.props.getTransferCreditDescriptionListsError,
          action: errorModal.hide,
        });
      }
    }
    if (
      prevProps.postPayRequestIsFetching !==
        this.props.postPayRequestIsFetching &&
      !this.props.postPayRequestIsFetching
    )
      if (this.props.PostPayRequestCode === responseCode.OK) {
        SuccessModal.show({
          description: "",
          action: SuccessModal.hide,
        });
        this.props.history.replace("/transferCredits");
      } else {
        errorModal.show({
          description: this.props.postPayRequestError,
          action: errorModal.hide,
        });
      }
  }

  handlenChangePay = (values: string, id: number) => {
    this.setState({
      createBody: {
        id,
        balance: values,
      },
    });
  };

  handleSubmitPay = () => {
    this.setState(
      (prevState) => ({
        postBody: {
          ...prevState.createBody,
          balance: Number(prevState.createBody.balance),
        },
      }),
      () => {
        const { postBody } = this.state;
        this.props.postPayRequest(postBody);
      }
    );
  };

  renderDescription = (values: IResponseAgentFinancePay) => {
    const mapMenu: { [type in IResponseAgentFinancePay as string]: string } = {
      id: "รหัสผู้ใช้",
      name: "ชื่อผู้ใช้",
      username: "ชื่อผู้ใช้",
      phoneNumber: "เบอร์โทร",
      payBalance: "ยอดโอน",
      creditLimitDiff: "เครดิตจำกัดรวม",
    };
    const createData: IResponseAgentFinancePay = {
      id: values.id,
      name: values.name,
      username: values.username,
      phoneNumber: values.phoneNumber,
      payBalance: values.payBalance,
      creditLimitDiff: values.creditLimitDiff,
    };
    const { dataList, createBody } = this.state;
    const mapLabel = map(
      createData,
      (value, key) =>
        key !== "name" && (
          <div className="col" key={`items-${key}`}>
            <LabelBox
              colorTextValue={key === "payBalance" ? color.PRIMARY_RED : ""}
              children={`text-right`}
              textLabel={mapMenu[key]}
              textValue={
                key !== "username"
                  ? key === "payBalance" || key === "creditLimitDiff"
                    ? number.castToMoney(value as number)
                    : value
                  : `${values.name} (${values.username})`
              }
            />
          </div>
        )
    );
    return (
      <>
        <div className="row">{mapLabel}</div>
        <div className="row mt-5">
          <div className="col-5 pr-0">
            <label className="label-input mx-1">
              {constants.inputTransferTitle}
            </label>
            <InputNumber
              thousandSeparator
              allowNegative={false}
              decimalScale={2}
              type="text"
              id={String(dataList.id)}
              name="name"
              placeholder={constants.inputPlaceholder}
              onValueChange={({ value }) =>
                this.handlenChangePay(value, dataList.id)
              }
              value={get(createBody, "balance", "")}
            />
          </div>
          <div className="col-2 pt-2 mt-4">
            <div className="d-flex">
              <Button
                disabled={createBody.balance !== "" ? false : true}
                size="large"
                id={String(dataList.id)}
                text={constants.buttonTransferTitle}
                buttonType="submit"
                onClick={this.handleSubmitPay}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  renderHeaderTable = (values: Object) =>
    map(values, (data, keys) => (
      <th
        key={`table-elements-${keys}`}
        className={`py-3 px-4 ${
          data.name !== "รายการเดิมพัน" ? "text-right col-4" : "text-left col-8"
        }`}
        >
        {data.name}
      </th>
    ));

  rowCollapseHandler = (index: number) =>
    this.setState((ps) => ({
      rowCollapse: { ...ps.rowCollapse, [index]: !ps.rowCollapse[index] },
    }));

  renderDetailsTable = (values: IResponseAgentFinancePayDetail[]) => {
    const mapDetail = map(values, (dataList, index) => {
      return (
        <React.Fragment key={`desc-${index}`}>
          <tr>
            <td
              className="px-4 can-click align-top"
              onClick={() => this.rowCollapseHandler(index)}
              colSpan={2}
            >
              <div className="row">
                <div className="col">
                  <div className="desc-table-title">
                    {titleType[dataList.name]}{" "}
                    <span>
                      {" "}
                      ({constants.update}{" "}
                      {format(
                        new Date(dataList.updatedAt),
                        "dd/MM/yyyy HH:mm:ss"
                      )}
                      )
                    </span>
                  </div>
                </div>
                <div className="col desc-table-title text-right d-flex in-line justify-content-end align-items-baseline">
                  <div className="p1-r">{number.castToMoney(dataList.balance)}</div>
                  <div
                    className={`chevron-right-icon ${
                      this.state.rowCollapse[index] ? "expanded" : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                </div>
              </div>
            </td>
          </tr>
          {map(dataList.typePayDetailList, (typeBet, i) => (
            <tr key={`typeBet-Name-${i}`}>
              <td className="px-4" colSpan={2}>
                <div className={`row hidden-row ${this.state.rowCollapse[index] && 'expanded p1-t'}`}>
                  <div className="col">
                    <div className="desc-table-subtitle">
                      {typeBet.name.startsWith("GAME_")
                        ? gameType[typeBet.name]
                        : betType[typeBet.name]}
                    </div>
                  </div>
                  <div className="col">
                    <div className="desc-table-subtitle text-right">
                      {number.castToMoney(typeBet.balance)}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </React.Fragment>
      );
    });
    return mapDetail;
  };

  renderTableElement = () => {
    const RenderHeaderTable = this.renderHeaderTable(mapHeaderTable);
    const RenderDetailsTable = this.renderDetailsTable(
      this.state.dataPayDetail
    );
    return (
      <div className="table-container">
        <div className="table-responsive-xl">
          <table className="col">
            <thead>
              <tr className="color-row">{RenderHeaderTable}</tr>
            </thead>
            <tbody>{RenderDetailsTable}</tbody>
          </table>
        </div>
      </div>
    );
  };

  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.replace(path);
  };

  render() {
    const { dataList } = this.state;
    const navigates: IBreadcrumbItem[] = [
      {
        label: constants.TransferConfirmationTitle,
        active: false,
        path: `/credits`,
      },
      {
        label: constants.TransferConfirmationSubtitle,
        active: false,
        path: `/transferCredits`,
      },
      {
        label: `${dataList.name} (${dataList.username})`,
        active: true,
        path: `/transferCreditDescription`,
      },
    ];
    const RenderDesc = this.renderDescription(dataList);
    const RenderTableElement = this.renderTableElement;
    return (
      <div className="paper-container transfercreditsdesc-container">
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="sub-menu">
                <Breadcrumb
                  items={navigates}
                  handleOnClickItem={this.handleOnClickBreadcrumb}
                />
              </div>
            </div>
            <div className="paper-title">
              {constants.TransferConfirmationTitle}
            </div>
            <div className="inline pr-3 paper-description">
              {constants.TransferConfirmationSubtitleFormat(
                this.state.dataList.name,
                this.state.dataList.username
              )}
            </div>
            <div className="paper-body">
              <div className="row m3-b">
                <div className="col">
                  <div className="box-shadow">
                    {RenderDesc}
                    <div className="row">
                      <div className="col mt-4">
                        <RenderTableElement />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
