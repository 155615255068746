import axios from 'axios'
import environment from '../../constants/environment'
import { transformer } from 'utils'
const fetchMemberSummary = () => {
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.memberSummary}`
    return axios.get(endpoint)
}

//59.GET: api/v2/agent/child ขอดูข้อมูลสมาชิก
const fetchMemberChild = (query: IV2QueryGetAgentChild | IQueryPagination) => {
    const convertQueryToString = new URLSearchParams(transformer.snakecaseTransform(query)).toString()
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.childMembers}`
    return axios.get(`${endpoint}?${convertQueryToString}`)

}

//71.GET: api/v2/agent/child/setting 		ดูข้อมูลตั้งค่าภาพรวมสมาชิก
const fetchChildSetting = (query: IV2QueryAgentChildSettingLottery) => {
    const convertQueryToString = new URLSearchParams(transformer.snakecaseTransform(query)).toString()
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.getChildSetting}`
    return axios.get(`${endpoint}?${convertQueryToString}`)
}

//72.GET: api/v2/agent/child/setting/lottery 	ดูข้อมูลตั้งค่าหวยสมาชิก
const fetchChildSettingLottery = (query: IV2QueryAgentChildSettingLottery) => {
    const convertQueryToString = new URLSearchParams(transformer.snakecaseTransform(query)).toString()
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.getChildSettingLottery}`
    return axios.get(`${endpoint}?${convertQueryToString}`)
}


//67.GET: api/v2/agent/finance/summary  ขอสรุปข้อมูลการเงิน
const fetchFinanceSummary = () => {
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.financeSummary}`
    return axios.get(endpoint)
}

const fetchMembersDiagram = () => {
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.memberDiagram}`
    return axios.get(endpoint)
}

const fetchListMembers = (data: IListMembersRequest) => {
    const params = {
        params: {
            search: data.search,
            page: data.page,
            limit: data.limit
        }
    }
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.memberList}`
    return axios.get(endpoint, params)
}

const fetchMemberUpdate = (data: IAgentUpdate) => {
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.memberUpdate}`
    return axios.post(endpoint, data)
}

const fetchMemberRegister = (data: ICreateMember) => {
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.memberRegister}`
    return axios.post(endpoint, data)
}

const fetchMemberDetail = (data: IAgentMemberDetail) => {
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.memberDetail}`
    return axios.post(endpoint, data)
}

const fetchMemberDelete = (data: IAgentMemberDetail) => {
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.memberDelete}`
    return axios.post(endpoint, data)
}
const fetchMemberRePassword = (data: IAgentMemberDetail) => {
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.memberRePassword}`
    return axios.post(endpoint, data)
}

export {
    fetchFinanceSummary,
    fetchMemberSummary,
    fetchListMembers,
    fetchMemberUpdate,
    fetchMemberRegister,
    fetchMembersDiagram,
    fetchMemberDetail,
    fetchMemberDelete,
    fetchMemberRePassword,
    fetchMemberChild,
    fetchChildSettingLottery,
    fetchChildSetting
}