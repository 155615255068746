import { createBrowserHistory } from 'history';
import { combineReducers, Reducer, AnyAction } from "redux";
import authentication from "./authentication/reducers";
import editUserHistory from "./editUserHistory/reducers";
import main from "./main/reducers";
import credits from "./credits/reducers";
import members from "./members/reducers";
import menu from "./menu/reducers";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import manageNews from "./manageNewsMe/reducers";
import Loader from "./Loader/reducers";
import Autocomplete from "./autocomplete/reducers";
import { connectRouter } from "connected-react-router";
import lotto from "./lotto/reducers";
import socket from "./socket/reducers";
import me from './me/reducers';
import otp from './otp/reducers';
import child from './child/reducers';
import environment from "constants/environment";
import owner from './owner/reducers';
import host from './host/reducers'
import customer from './customer/reducers'
import cancelBill from './cancelBill/reducer'
import pastePooi  from './lotto/bet/pastePooi/reducer';
import numberBan from './numbersBan/reducer'
import modePrint from './modalPrint/reducers'
import finance from './finance/reducers'
const persistConfig = {
  key: environment.name,
  storage,
  whitelist: ["authentication", "menu", "autocomplete", "me", 'modePrint'],
};

const authPersistConfig = {
  key: "authentication",
  storage,
  whitelist: ["token"],
};

const menuPersistConfig = {
  key: "menu",
  storage,
};

const autocompletePersistConfig = {
  key: "autocomplete",
  storage,
};

const mePersistConfig = {
  key: 'me',
  storage,
  whitelist: ["me"]
}
const modePrintPersistConfig = {
  key: "modePrint",
  storage,
  whitelist: ["isMode"],
};


export const history = createBrowserHistory();

const appReducer = (history: History) =>
  combineReducers({
    authentication: persistReducer(authPersistConfig, authentication),
    editUserHistory,
    main,
    credits,
    members,
    lotto,
    manageNews,
    Loader,
    menu: persistReducer(menuPersistConfig, menu),
    router: connectRouter(history),
    Autocomplete: persistReducer(autocompletePersistConfig, Autocomplete),
    socket,
    me: persistReducer(mePersistConfig, me),
    otp,
    child,
    owner,
    host,
    customer,
    finance,
    cancelBill,
    pastePooi,
    numberBan,
    modePrint: persistReducer(modePrintPersistConfig, modePrint),
  });

  const rootReducer: Reducer = (state: any, action: AnyAction) => {
    if (action.type === "LOGOUT_SUCCESS") {
      localStorage.removeItem('persist:obey')
      localStorage.removeItem('lotterySettingState')
      localStorage.removeItem('lotterySettingMemberDetailState')
      localStorage.removeItem('betMaxMinLimit')
      // localStorage.clear()
      window.location.reload()
      window.location.href = '/'
      return appReducer(history)(undefined, action)
    }
    return appReducer(history)(state, action)
  }
  
export default persistReducer(persistConfig, rootReducer);
