import { connect } from "react-redux";
import NumbersBanAddLotteryContainer from "./NumbersBanAddLottery.container";


const mapStateToProps = (state: RootReducers) => {
  return {}
}
const mapDispatchToProps = {
}


export default connect(mapStateToProps,mapDispatchToProps)(NumbersBanAddLotteryContainer)







