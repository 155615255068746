import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { loaderAction } from "reduxs/Loader/actionCreator";
import { getHistoryAction } from "../actionCreator";
import { fetchGetHistory } from "../services";

const getDataHistory = (
  query: ISearchAgentBetLotteryHistoryListQuery | IQueryPagination
) => async (dispatch: Dispatch) => {
  dispatch(loaderAction.success(true));
  dispatch(getHistoryAction.request({}));
  await fetchGetHistory(query)
    .then(
      (
        res: AxiosResponse<
          IAPIResponse<IPagination<IResponseAgentBetLotteryHistoryList>>
        >
      ) => {
        dispatch(loaderAction.success(false));
        dispatch(getHistoryAction.success(res));
      }
    )
    .catch((error: AxiosError<IAPIResponse>) => {
      dispatch(loaderAction.success(false));
      dispatch(getHistoryAction.failure(error));
    });
};
export { getDataHistory };
