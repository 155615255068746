import React, { FC } from "react";
import ReactPaginate from "react-paginate";
import "./Panginate.style.scss";

import arrowRight from "assets/images/global/icon/arrowRight.png";
import arrowLeft from "assets/images/global/icon/arrowLeft.png";
import { isEmpty } from "lodash";

const defaultProps: IPanginate = {
  pageTotal: 0,
  initialPage: 0,
  pageRangeDisplayed: 2,
  marginPagesDisplayed: 0,
  previousLabel: "",
  nextLabel: "",
  breakLabel: "",
  onPageChange() {},
};

const PanginateComponent: FC<IPanginate> = (props) => {
  const {
    pageTotal,
    initialPage,
    pageRangeDisplayed,
    marginPagesDisplayed,
    previousLabel,
    nextLabel,
    breakLabel,
    onPageChange,
  } = props;

  return (
    <div className="panginate">
      <ReactPaginate
        disableInitialCallback={true}
        forcePage={initialPage}
        pageCount={pageTotal}
        initialPage={initialPage}
        pageRangeDisplayed={pageRangeDisplayed + 1 === pageTotal ? 3 : 2}
        marginPagesDisplayed={Number(marginPagesDisplayed)}
        previousLabel={isEmpty(previousLabel)?<><img src={arrowLeft} className="icon arrowLeft" alt="arrowLeft" /></>:previousLabel}
        nextLabel={isEmpty(nextLabel)?<><img src={arrowRight} className="icon arrowRight" alt="arrowRight" /></>:nextLabel}
        breakLabel={breakLabel}
        onPageChange={onPageChange}
        containerClassName={"pagination"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"pagination__link--disabled"}
        activeClassName={"pagination__link--active"}
      />
    </div>
  );
};

PanginateComponent.defaultProps = defaultProps;

export default PanginateComponent;
