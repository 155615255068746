import {
  Button,
  Input,
  InputElements,
  InputNumber,
  LoadingCircle,
  SuccessModal,
} from "components";
import color from "constants/colors";
import { FormikProps } from "formik";
import React from "react";
import { Component } from "react";
import "./memberInfo.style.scss";
import Select from "react-select";
import {
  THAI_LEVEL_TYPE_PREMISSION,
  meDataInitialValue,
  STATUS_SELECT,
  BANK_TYPE,
  LEVEL_TYPE_CHANGE_WORD
} from "constants/variables";
import { createMemberDropdownStyles } from "containers/CreateMember/CreateMemberDropdown.styles";
import { memberDetailDropdownStyles } from "containers/MemberDetail/MemberDetailDropdown.styles";
import { findIndex, forOwn, get, isEmpty, isNull, noop } from "lodash";
import { date } from "utils";
import { th } from "date-fns/locale";
import { format } from "date-fns";
import { ENG_THAI_AND_SPACE } from "constants/regex";
import ImageBankSet from "assets/images/global/bank";

const constants = {
  memberInfoTitle: "ข้อมูลผู้ใช้",
  phoneNumber: "เบอร์ติดต่อ",
  placeholderPhoneNumber: "เบอร์ติดต่อ",
  permission: "สิทธิการใช้งาน *",
  level: "สิทธ์",
  isAccess: "สถานะการใช้งาน *",
  username: "Username *",
  placeholderUsername: "ชื่อผู้ใช้",
  helperName: "ชื่อช่วยจำ *",
  line: "ไลน์ไอดี",
  placeholderLine: "ไลน์ไอดี",
  lastUpdate: (time: string) => `ข้อมูลเปลี่ยนแปลงล่าสุด ${time}`,
  editMemberInfo: "แก้ไขข้อมูล",
  resetMemberInfo: "รีเซ็ตเป็นค่าเดิม",
  resetMemberInfoSuccess: "รีเซ็ตเป็นค่าเดิมสำเร็จ",

  noInfo: "ไม่มีข้อมูล",
  bankName: "ชื่อบัญชีธนาคาร",
  bankNumber: "หมายเลขบัญชีธนาคาร",
  bank: "ธนาคาร",
  bankNamePlaceholder: "ชื่อบัญชีธนาคาร",
  bankNumberPlaceholder: "หมายเลขบัญชีธนาคาร",
  bankPlaceholder: "เลือกธนาคาร",
};

const defaultProps: IMemberInfoProps = {
  childDetailData: [],
  meData: meDataInitialValue,
  postChildCreditUpdate(data) {
    noop();
  },
  postChildSettingUpdate(data) {
    noop();
  },
  postChildUpdate() {
    noop();
  },
  loading: false
};

class MemberInfoComponent extends Component<
  IMemberInfoProps & FormikProps<IMemberDetailFormikProps>,
  IMemberInfoState
> {
  static defaultProps = defaultProps;
  state: IMemberInfoState = {
    isEditable: false,
    isBankTypeSelected: false
  };

  permissionList = () => {
    const permissionList =
      THAI_LEVEL_TYPE_PREMISSION[
      (this.props.meData.permission as LEVEL_TYPE_PREMISSION) || "AGENT"
      ];
    // console.log(get(this.props.childDetailData,'[0].childPermission',''))
    const optionedPermissionList = permissionList.map((item) => {
      // console.log(item)
      return {
        value: item,
        label: item,
        disabled: false,
      };
    });
    return optionedPermissionList;
  };

  statusList = () => {
    let formattedStatusSelect: IOptions[] = [];
    forOwn(STATUS_SELECT, function (values, keys) {
      formattedStatusSelect.push({
        label: values,
        value: keys === "OPEN" ? true : false,
        disabled: false,
      });
    });
    return formattedStatusSelect;
  };
  submitMemberInfo = () => {
    const { errors, touched, childDetailData, values } = this.props;
    if (
      !(
        (!!errors.phoneNumber && touched.phoneNumber) ||
        (!!errors.bankType ) ||
        (!!errors.bankName ) ||
        (!!errors.permission && touched.permission) ||
        (!!errors.bankNumber ) ||
        (!!errors.isAccess && touched.isAccess) ||
        (!!errors.name && touched.name) ||
        (!!errors.line && touched.line)
      )
    ) {
      const numberedPostChildBody = {
        childAgentId: childDetailData[0].childAgentId,
        childBankName: values.bankName,
        childBankNumber: values.bankNumber,
        childBankType: values.bankType as TBankType,
        childIsAccess: values.isAccess,
        childName: values.name,
        childPermission: values.permission,
        childLine: values.line,
        childPhoneNumber: values.phoneNumber,
      }
      const postChildUpdateData =  Object.entries(numberedPostChildBody).reduce((obj, [key, val]) => {
        if(isNull(val)){
          delete (obj as any)[key];
        }
         else  {
          (obj as any)[key] = val;
        }
        return obj;
      }, {});
      this.props.postChildUpdate(postChildUpdateData as IV2AgentChildUpdate)
      this.setState({ isEditable: false })
    }
  };

  resetMemberInfo = () => {
    const { setValues, childDetailData, values } = this.props;
    setValues({
      ...values,
      bankType: childDetailData[0].childBankType,
      bankNumber: childDetailData[0].childBankNumber,
      bankName:childDetailData[0].childBankName,
      phoneNumber: childDetailData[0].childPhoneNumber,
      permission: childDetailData[0].childPermission,
      isAccess: childDetailData[0].childIsAccess,
      username: childDetailData[0].childUsername,
      name: childDetailData[0].childName,
      line: isNull(childDetailData[0].childLine)? "":childDetailData[0].childLine, //แก้แดง
    });
    this.setState({ isEditable: false,isBankTypeSelected:false }, () => {
      SuccessModal.show({
        action: () => SuccessModal.hide(),
        description: constants.resetMemberInfoSuccess,
      });
    });
  };

  formatBankTypeSelect(): IOptions[] {
    let formatBankTypeSelect: any[] = [];
    forOwn(BANK_TYPE, function (values, keys) {
      const ImageIcon = get(ImageBankSet, `${keys}.Icon`, "");
      formatBankTypeSelect.push({
        label: (
          <div className="d-flex flex-row">
            {!isEmpty(ImageIcon) && (
              <img src={ImageIcon} alt="bank" className="bank-image" />
            )}
            <div>{values}</div>
          </div>
        ),
        value: keys,
        disabled: false,
        searchableName: `${keys} ${values}`,
      });
    });
    return formatBankTypeSelect;
  }

  render() {
    const {
      values,
      errors,
      handleBlur,
      touched,
      setFieldValue,
      handleChange,
      validateField,
      // meData,
      childDetailData,
      loading
    } = this.props;

    const bankTypeIndex = findIndex(
      this.formatBankTypeSelect(),
      (item: IOptions) => item.value === values.bankType
    );

    const childIsAccessIndex = findIndex(
      this.statusList(),
      (item: IOptions) => {
        return item.value === values.isAccess
      }
    );

    // const permissionListIndex = findIndex(
    //   this.permissionList(),
    //   (item: IOptions) => {
    //     return item.value === values.permission
    //   }
    // );
const defaultBankType={
  label: 
  <div style={{color: "#808080"}}>
    {constants.bankPlaceholder}
  </div>
  ,
  value: "",
  disabled: false,
}
    return (
      <div className="paper-body member-info-container">
        <div className="row mt-4">
          <div className="col">
            <div className="box-shadow">
              <div className="row">
                <div className="col-12 pr-3 paper-body-title d-flex flex-row justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="paper-body-title">
                    <div className="d-flex align-items-center">
                    {constants.memberInfoTitle}
                    {loading && (<div className="m1-l"><LoadingCircle /> </div>)}
                  </div>
                    </div>
                    <div className="content-text m1-t">
                      {constants.lastUpdate(
                        format(
                          date.calibratingTime(
                            childDetailData[0]?.childUpdatedAt,
                            true
                          ),
                          "d MMM yyyy HH:mm",
                          { locale: th }
                        )
                      )}
                    </div>
                  </div>
                  {!this.state.isEditable && (
                    <div>
                      <Button
                        id="edit-member-info"
                        text={constants.editMemberInfo}
                        borderRadius={"8px"}
                        buttonClass={"edit-member-info-button"}
                        backgroundColor={color.PRIMARY_GREEN}
                        textSize={18}
                        backgroundHoverColor={color.SECONDARY_GREEN}
                        onClick={() => {
                          this.setState({ isEditable: true });
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="row m3-t">
                <div className="col-4">
                  <label className="label-input">{constants.phoneNumber}</label>
                  <InputNumber
                    style={{ width: "100%" }}
                    id="phoneNumber"
                    name="phoneNumber"
                    format="### ### ####"
                    decimalScale={0}
                    allowLeadingZeros
                    allowNegative={false}
                    value={values.phoneNumber}
                    onBlur={handleBlur}
                    errorMessage={errors.phoneNumber}
                    backgroundColor={color.TRANSPARENT}
                    placeholder={constants.placeholderPhoneNumber}
                    error={!!errors.phoneNumber && touched.phoneNumber}
                    onValueChange={({ value }) =>
                      setFieldValue("phoneNumber", value)
                    }
                    inputClassName="input-otp"
                    disabled={!this.state.isEditable}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <label className="label-input">{constants.permission}</label>
                  <Select
                    styles={memberDetailDropdownStyles(true)}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    placeholder={constants.level}
                    name="permission"
                    id="permission"
                    value={{
                      value: LEVEL_TYPE_CHANGE_WORD[get(this.props.childDetailData,'[0].childPermission','') as TLevelType],
                      label: LEVEL_TYPE_CHANGE_WORD[get(this.props.childDetailData,'[0].childPermission','') as TLevelType],
                      disabled: false,
                    }}
                    options={this.permissionList()}
                    onChange={(e) => {
                      setFieldValue("permission", e?.value);
                    }}
                    isDisabled={true}
                    noOptionsMessage={() => constants.noInfo}
                  />
                  <h6 className="primary-red-text" style={{ marginTop: 6 }}>
                    {!!errors.permission && touched.permission
                      ? errors.permission
                      : "\u00A0"}
                  </h6>
                </div>
                <div className="col-4">
                  <label className="label-input">{constants.isAccess}</label>
                  <Select
                    styles={memberDetailDropdownStyles(!this.state.isEditable)}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    placeholder={""}
                    name="isAccess"
                    options={this.statusList()}
                    value={this.statusList()[childIsAccessIndex]}
                    onChange={(e) => {
                      setFieldValue("isAccess", e!.value);
                    }}
                    isDisabled={!this.state.isEditable}
                    noOptionsMessage={() => constants.noInfo}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <label className="label-input">{constants.username}</label>
                  <div className="position-relative">
                    <InputElements
                      id="username"
                      name="username"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.username}
                      placeholder={constants.placeholderUsername}
                      error={!!errors.username && touched.username}
                      errorMessage={errors.username}
                      backgroundColor={color.TRANSPARENT}
                      inputClassName="input-username"
                      disabled={true}
                    // startText={meData.username}
                    />
                  </div>
                  <h6 className="primary-red-text mt-1">
                    {!!errors.username && touched.username
                      ? errors.username
                      : "\u00A0"}
                  </h6>
                </div>
                <div className="col-4">
                  <label className="label-input">{constants.helperName}</label>
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    placeholder={""}
                    inputClassName="input-otp"
                    backgroundColor={color.TRANSPARENT}
                    onChange={handleChange}
                    value={values.name}
                    onBlur={handleBlur}
                    errorMessage={errors.name}
                    error={!!errors.name && touched.name}
                    disabled={!this.state.isEditable}
                  />
                </div>
                <div className="col-4">
                  <label className="label-input">{constants.line}</label>
                  <Input
                    id="line"
                    name="line"
                    type="text"
                    placeholder={constants.placeholderLine}
                    value={values.line}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errorMessage={errors.line}
                    error={!!errors.line && touched.line}
                    inputClassName="input-otp"
                    backgroundColor={color.TRANSPARENT}
                    disabled={!this.state.isEditable}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-4" style={{ marginTop: 6 }}>
                  <label className="label-input">{constants.bankName}</label>
                  <Input
                    id="bankName"
                    name="bankName"
                    type="text"
                    placeholder={constants.bankNamePlaceholder}
                    value={isNull(values.bankName)? "": values.bankName} //แก้แดง
                    onBlur={handleBlur}
                    onChange={(e) => {
                      if (!e.target.value.match(ENG_THAI_AND_SPACE)) {
                        setFieldValue("bankName", e.target.value);
                      }
                    }}
                    errorMessage={errors.bankName}
                    error={!!errors.bankName}
                    inputClassName="input-otp"
                    backgroundColor={color.TRANSPARENT}
                    disabled={!this.state.isEditable}
                  />
                </div>
                <div className="col-4" style={{ marginTop: 6 }}>
                  <label className="label-input">{constants.bank}</label>
                  <Select
                    styles={createMemberDropdownStyles(!this.state.isEditable)}
                    components={{ IndicatorSeparator: () => null }}
                    placeholder={constants.bankPlaceholder}
                    name="bankType"
                    options={this.formatBankTypeSelect()}
                    value={isNull(values.bankType)? defaultBankType:this.formatBankTypeSelect()[bankTypeIndex]}
                    onChange={(e) => {
                      if (values.bankType === "GSB" && e?.value !== "GSB") {
                        const formattedBankNumber = values.bankNumber.substring(
                          0, values.bankNumber.length - 2
                        );
                        setFieldValue("bankNumber", formattedBankNumber);
                        setTimeout(() => {
                          validateField("bankNumber");
                        }, 256);
                      }
                      setFieldValue("bankType", e?.value);
                      this.setState({ isBankTypeSelected: true });
                    }}
                    getOptionValue={(option) => get(option, "searchableName", "")}
                    isDisabled={!this.state.isEditable}
                    noOptionsMessage={() => constants.noInfo}
                  />
                  {!!errors.bankType && (
                    <h6 className="primary-red-text" style={{ marginTop: 6 }}>
                      {errors.bankType}
                    </h6>
                  )}
                </div>
                <div className="col-4" style={{ marginTop: 6 }}>
                  <label className="label-input">{constants.bankNumber}</label>
                  <InputNumber
                    allowNegative={false}
                    decimalScale={0}
                    id="bankNumber"
                    name="bankNumber"
                    type="text"
                    placeholder={constants.bankNumberPlaceholder}
                    value={values.bankNumber}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        "bankNumber",
                        e.target.value.replaceAll(" ", "")
                      );
                    }}
                    errorMessage={errors.bankNumber}
                    error={!!errors.bankNumber}
                    inputClassName="input-otp"
                    format={
                      values.bankType === "BAAC" || values.bankType === "GSB"
                        ? "# ### #### ####"
                        : "### #### ###"
                    }
                    backgroundColor={color.TRANSPARENT}
                    disabled={!this.state.isEditable || (values.bankType ===null &&!this.state.isBankTypeSelected)}
                  />
                </div>
              </div>
              {this.state.isEditable && (
                <>
                  <div className="row">
                    <div className="col-12">
                      <Button
                        id="submit-member-info"
                        text={constants.editMemberInfo}
                        borderRadius={"8px"}
                        buttonClass={"edit-member-info-button"}
                        textSize={18}
                        onClick={this.submitMemberInfo}
                      />
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: 12 }}>
                    <div className="col-12">
                      <Button
                        id="reset-member-info"
                        text={constants.resetMemberInfo}
                        borderRadius={"8px"}
                        buttonClass={"edit-member-info-button"}
                        textSize={18}
                        backgroundColor={color.TRANSPARENT}
                        backgroundHoverColor={color.TRANSPARENT}
                        textColor={color.SECONDARY_TEXT}
                        onClick={this.resetMemberInfo}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MemberInfoComponent;
