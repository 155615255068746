import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { getMeSettingLotteryAction } from "../actionCreator";
import { fetchGetMeSettingLottery } from "../services";

const getMeSettingLottery = () => (dispatch: Dispatch) => {
  dispatch(getMeSettingLotteryAction.request({}));
  return fetchGetMeSettingLottery()
    .then((res: AxiosResponse<IV2AgentMeSettingLottery>) => {
      dispatch(getMeSettingLotteryAction.success(res));
    })
    .catch((error: AxiosError<IAPIResponse>) =>
      dispatch(getMeSettingLotteryAction.failure(error))
    );
};

export { getMeSettingLottery };
