import React from "react"
import { number, dateThai } from "utils"
import { get, isEmpty, /* noop */ } from "lodash"
import { Dropdown, LoadingCircle, /* Button */ } from 'components'
import colors from 'constants/colors'
import { FunctionComponent } from "react"
import "../../financeCustomerDetails.style.scss"
import point from "assets/images/global/icon/point.png"
import { STATUS_BET, FINANCE_CUSTOMER_LIST, CODE_TO_TYPENAME } from "constants/variables"

const constants = {
  empty: 'โปรดกดที่รายการในตารางสรุปยอดเพื่อดูข้อมูลเดิมพัน',
  clear: 'เคลียร์',
  active: 'ใช่',
  inActive: 'ไม่ใช่'
}
interface ITableMembers {
  column: Array<{
    codeName: string
    textalign: string
  }>
  dataList: Array<IV2RespAgentLotteryBetMe>
  onClick(data: IV2RespAgentLotteryBetMe): void
  callBackDropdown(key: IOptions,data:IV2RespAgentLotteryBetMe): void
  loading: boolean
}

const Table: FunctionComponent<ITableMembers> = ({
  column,
  dataList,
  onClick,
  callBackDropdown,
  loading
}) => {

  const loadingTable = (
    <tr>
      <td className="text-center" colSpan={15}>
        <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
      </td>
    </tr>
  )
  
  
  const columns = (
    <thead>
      <tr className="tableHead">
        {column.map((data, index: number) => (
          <th key={index} className={data.textalign}>
            <span>{data.codeName}</span>
          </th>
        ))}
      </tr>
    </thead>
  )


  const rows = (
    <tbody>
      {
        isEmpty(dataList) ?
          <tr>
            <td className="text-center color-row tertiary-text body-4 p-2" colSpan={15}>
              {constants.empty}
            </td>
          </tr>
          :
          dataList.map((data, index: number) => {
            const stName =  data.status === 'WINNER' ? number.addComma(data.totalBet * data.rate) : get(STATUS_BET, `${data.status}.status`, '')
            const stColor = data.status === 'WINNER' ? colors.SECONDARY_TEXT : get(STATUS_BET, `${data.status}.color`, '')
            return (
              <tr id={'bet-customer'} className={`row-finance-customer disable-cursor`} key={index} onClick={() => onClick(data)}>
                <td className="text-left paddingRow">
                  <span className="pd-frist">{CODE_TO_TYPENAME[data.type]}</span>
                </td>
                <td className="text-right">
                  <span>{data.number}</span>
                </td>
                <td className="text-right">
                  <span>{number.addComma(data.rate)}</span>
                </td>
                <td className="text-right">
                  <span>{number.addComma(data.totalMeBet)}</span>
                </td>
                <td className="text-right">
                  <span>{number.addComma(data.totalHostBet)}</span>
                </td>
                <td className="text-right">
                  <span>{number.addComma(data.totalBet)}</span>
                </td>
                <td className="text-right">
                  <span>{number.addComma(data.totalDis)}</span>
                </td>
                <td className="text-right">
                  <span>{number.addComma(number.calculator(`${data.totalBet}-${data.totalDis}`))}</span>
                </td>
                <td className="text-right">
                  <span >{number.addComma(data.totalCom)}</span>
                </td>
                <td className="text-center">
                  <span className="text-status" style={{ color: stColor }}>{stName}</span>
                </td>
                <td className="text-center">
                  {data.hostKeep ? (
                    <div className="status-keep active">{constants.active}</div>
                  ) : (
                    <div className="status-keep not">
                      {constants.inActive}
                    </div>
                  )}
                </td>
                <td className="text-center">
                  <span>{dateThai.formatThai(data.createdAt)}</span>
                </td>
                <td className="text-left d-flex justify-content-center">
                  <span>
                    <Dropdown
                      isManualOpenMenu={true}
                      menuPlacement="auto"
                      options={Object.keys(FINANCE_CUSTOMER_LIST).map((item) => { return { value: item, label: FINANCE_CUSTOMER_LIST[item], disabled: item === 'CancelBill' } })}
                      iconOpen={point}
                      iconClose={point}
                      hoverElementColor={colors.PRIMARY_HOVER}
                      controlBackgroundColor={colors.PRIMARY_BG}
                      menuStyles={{ width: "175px", left: "-150px" }}
                      valueContainerStyles={{padding: "0px",maxWidth: "0px",}}
                      handleOnChange={(target: IOptions) => {
                      callBackDropdown(target,data)
                      }}
                    />
                  </span>
                </td>
              </tr>
            )
          })
      }
    </tbody>
  )

  return (
    <div className="table-container">
      <table className="col ">
        {columns}
        {loading ?  loadingTable: rows}
      </table >
    </div>
  )
}

export default Table
