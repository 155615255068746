import { connect } from "react-redux";
import UserDetailContainer from "./UserDetail.container";
import { postOwnerUpdate } from "reduxs/owner/update/action";
import { getMe } from "reduxs/me/me/action";
import { getMeSetting } from "reduxs/me/setting/action";
import { postOwnerSetting } from "reduxs/owner/setting/action";
import {postOwnerSettingLotteryUpdate} from "reduxs/owner/settingLotteryUpdate/action"
import { getMeSettingLottery } from "reduxs/me/settingLottery/action"
import { onChangeIsLoaddingAction } from "../../reduxs/Loader/action";
import {getHost} from 'reduxs/host/host/action';

const mapStateToProps = (state: RootReducers): IUserDetailProps => {
  return {
    meData: state.me.me.data,
    meIsFetching: state.me.me.isFetching,

    ownerUpdateIsFetching: state.owner.update.isFetching,
    ownerUpdateCode: state.owner.update.code,
    ownerUpdateError: state.owner.update.error,

    meSettingCode: state.me.setting.code,
    meSettingError: state.me.setting.error,
    meSettingIsFetching: state.me.setting.isFetching,
    meSettingData: state.me.setting.data,

    ownerSettingIsFetching: state.owner.setting.isFetching,
    ownerSettingCode: state.owner.setting.code,
    ownerSettingError: state.owner.setting.error,

    ownerSettingLotteryUpdateIsFetching: state.owner.settingLotteryUpdate.isFetching,
    ownerSettingLotteryUpdateCode: state.owner.settingLotteryUpdate.code,
    ownerSettingLotteryUpdateError: state.owner.settingLotteryUpdate.error,

    meSettingLotteryCode: state.me.settingLottery.code,
    meSettingLotteryError: state.me.settingLottery.error,
    meSettingLotteryIsFetching: state.me.settingLottery.isFetching,
    meSettingLotteryData: state.me.settingLottery.data,

    hostCode: state.host.host.code,
    hostError: state.host.host.error,
    hostIsFetching: state.host.host.isFetching,
    hostData: state.host.host.data,
  };
};

const mapDispatchToProps: IUserDetailActionProps = {
  onChangeIsLoaddingAction,
  postOwnerUpdate,
  getMe,
  getMeSetting,
  postOwnerSetting,
  postOwnerSettingLotteryUpdate,
  getMeSettingLottery,
  getHost,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDetailContainer);
