import { padStart, replace } from "lodash";
import { DECIMAL_DITGITS, COMMA } from "constants/regex";
import { evaluate, format } from "mathjs";

const castToInteger = (numberString: string = "") => {
  const result = numberString.replace(COMMA, "").replace(DECIMAL_DITGITS, "");
  return result;
};
const padNumber = (value: string, ditgit: number) => {
  const padString = "0";
  return padStart(value, ditgit, padString);
};
const numberFormat = (data: string) => {
  return Intl.NumberFormat().format(Number(data));
};

const decimalsFormat = (data: string) => {
  const noCommaData = data.replace(COMMA, "");
  return Number(noCommaData);
};

const calNumberTwoFixed = (num1: number, num2: number): number => {
  return (Math.floor(num1 * 100) - Math.floor(num2 * 100)) / 100;
};

const decimalsFormatComma = (data: string | number, Digits: number = 2) => {
  return Intl.NumberFormat("en-US", {
    minimumFractionDigits: Digits,
    maximumFractionDigits: Digits,
  }).format(Number(data));
};

function formatPhoneNumber(phoneNumberString: string) {
  var cleaned = phoneNumberString.replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + "-" + match[2] + "-" + match[3];
  }
  return phoneNumberString;
}

function formatPhoneNumberSpace(phoneNumberString: string) {
  var cleaned = phoneNumberString.replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + " " + match[2] + " " + match[3];
  }
  return phoneNumberString;
}

const Percentage = (num: string) => {
  let cleaned = num.replace(/\D/g, "");
  if (Number(cleaned) > 100) {
    return 100;
  } else if (Number(cleaned) < 0 || cleaned === "") {
    return 0;
  } else {
    return Number(num);
  }
};

const castToMoney = (value: number, spacing?: boolean) => {
  const locals = "th-TH";
  const currency = "THB";
  const money = new Intl.NumberFormat(locals, {
    style: "currency",
    currency,
  }).format(value || 0);
  if (spacing) {
    return money.replace(/^(\D+)/, "$1 ");
  }
  return money;
};

const addComma = (value: number = 0) => ` ${new Intl.NumberFormat("th-TH").format(value || 0)} `;

// Example calculator("1+2")
const calculator = (operations: string) => {
  try {
    if (operations) {
      const replacePlus = replace(operations, "++", "+");
      const replaceMinus = replace(replacePlus, "--", "+");
      if (replaceMinus) {
        const rawResult = evaluate(replaceMinus);
        const precisionResult = format(rawResult, { precision: 14 });
        return precisionResult ? Number(precisionResult) : 0;
      }
    }
    return 0;
  } catch {
    return 0;
  }
};
const decimalsFormatCommaWithOutZero = (data: string | number) => {
  const result = Number(data)
    .toFixed(2)
    .replace(/\.00$/, "")
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return result;
};

const InputTextWithCommas = (x: string) => {
  var result = parseFloat(x.replace(/,/g, "")).toLocaleString("en-US");
  const withoutCommas = result.replace(/,/g, "");
  if (isNaN(parseFloat(withoutCommas))) {
    return;
  }
  return result;
};
const obj = {
  padNumber,
  castToInteger,
  numberFormat,
  decimalsFormat,
  decimalsFormatComma,
  formatPhoneNumber,
  formatPhoneNumberSpace,
  Percentage,
  castToMoney,
  addComma,
  calNumberTwoFixed,
  calculator,
  decimalsFormatCommaWithOutZero,
  InputTextWithCommas,
};
export default obj;
