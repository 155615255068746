import { connect } from "react-redux"
import BetWaitingResultContainer from "./BetWaitingResult.container"
import { onChangeIsLoaddingAction } from "reduxs/Loader/action"
import { getBetExtraction, getBetExtractionDetail } from "reduxs/lotto/bet/group/action"
import betGroup from "reduxs/lotto/bet/selector"
import meSelector from "../../reduxs/me/selector"
import lottoSelector from "reduxs/lotto/selector"
const mapStateToProps = (state: RootReducers): IBetWaitingResultContainerProps => {
  const getMeList = meSelector.me(state)
  const getAgentBetExtraction = betGroup.getBetExtraction(state)
  const getAgentBetExtractionDetail = betGroup.getBetExtractionDetail(state)
  const lottoScheduleV2 = lottoSelector.listV2(state)
  return {
    userMe: (getMeList as any)?.data,
    getBetExtractionData :getAgentBetExtraction.data,
    getBetExtractionIsFetching :getAgentBetExtraction.isFetching,
    getBetExtractionCode :getAgentBetExtraction.code,
    getBetExtractionError :getAgentBetExtraction.error,

    getBetExtractionDetailData :getAgentBetExtractionDetail.data,
    getBetExtractionDetailIsFetching :getAgentBetExtractionDetail.isFetching,
    getBetExtractionDetailCode :getAgentBetExtractionDetail.code,
    getBetExtractionDetailError :getAgentBetExtractionDetail.error,

    lottoScheduleV2Data: lottoScheduleV2.data,
    lottoScheduleV2IsFetching: lottoScheduleV2.isFetching,
    lottoScheduleV2Code: lottoScheduleV2.code,
    lottoScheduleV2Error: lottoScheduleV2.error,
  }
}

const mapDispatchToProps: IBetWaitingResultContainerAction = {
  onChangeIsLoaddingAction,
  getBetExtraction,
  getBetExtractionDetail,
}

export default connect(mapStateToProps, mapDispatchToProps)(BetWaitingResultContainer)
