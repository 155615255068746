import { connect } from "react-redux";
import FinanceSummaryChildContainer from "./FinanceSummaryChild.container";
import { getFinanceChild } from "../../reduxs/finance/child/action";
import { onChangeIsLoaddingAction } from "../../reduxs/Loader/action";

const mapStateToProps = (state: RootReducers): IFinanceSummaryChildProps => {
  return {
    financeChildCode: state.finance.financeChild.code,
    financeChildError: state.finance.financeChild.error,
    financeChildIsFetching: state.finance.financeChild.isFetching,
    financeChildData: state.finance.financeChild.data,
  };
};

const mapDispatchToProps: IFinanceSummaryChildActionProps = {
  getFinanceChild,
  onChangeIsLoaddingAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinanceSummaryChildContainer);
