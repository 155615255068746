import { Login } from "./Home";
import { Main } from "./Main";
import { MembersList } from "./MembersList";
import { CreateMember } from "./CreateMember";
import { BetHistory } from "./BetHistory";
import { Credits } from "./Credits";
import { TransactionLists } from "./TransactionLists";
import { TransferCredits, TransferCreditDescription } from "./TransferCredits";
import { membersDiagram } from "./membersDiagram";
import { EditUserHistory } from "./EditUserHistory";
import { EditUserHistoryDetails } from "./EditUserHistoryDetails";
import { ResetPassword } from "./HomeResetPassword";
import { TransferConfirmation } from "./TransferConfirmation";
import { BetMaking } from "./BetMaking";
import { NewBetMaking } from "./NewBetMaking";
import { Bet } from "./Bet";
import { manageNews } from "./manageNews";
import { Loader } from "./Loader";
import { BetResult } from "./BetResult";
import { ResetUserPassword } from "./ResetUserPassword";
import { BetChildren } from "./BetChildren";
import { Register2FA } from "./Register2FA";
import { SettingRate } from "./SettingRate";
import { MemberDetail } from "./MemberDetail";
import { MembersResetPassword } from "./MemberResetPassword";
import { LottoResult } from "./lottoResult";
import { LottoResultDetail } from "./lottoResultDetail";
import { UserDetail } from "./UserDetail";
import { Dashboard } from "./Dashboard";
import { UserResetPassword } from "./UserResetPassword";
import { CustomerList } from "./CustomerList";
import { CustomerDetail } from "./CustomerDetail";
import FinanceSumCustomer from "./FinanceSumCustomer";
import FinanceCustomerDetails from "./FinanceCustomerDetails";
import FinanceSummaryChild from "./FinanceSummaryChild";
import FinanceChild from "./FinanceChild";
import { BetMeHistory } from "./BetMeHistory";
import { BetChildHistory } from "./BetChildHistory";
import FinanceSummaryHost from './FinanceSummaryHost'
import { FinanceHostDetail } from './FinanceHostDetail'
import { BetSumHistory } from './BetSumHistory'
import { UserDetailNote } from './UserDetailNote'
import { MemberDetailNote } from "./MemberDetailNote"
import { PreviewBetBill } from "./PreviewBetBill"
import { NumberBanManage } from "./NumberBanManage";
import { NumbersHolesAddLottery } from "./NumbersHolesAddLottery"
import { NumbersHolesManage } from "./NumbersHolesManage"
import { NumbersHoles } from "./NumbersHoles";
import { NumberBan } from './NumberBan'
import { NumbersBanAddLottery } from './NumbersBanAddLottery'
import BetLotteryGroup from "./BetLotteryGroup"
import BetReport from "./BetReport"
import BetWaitingResult from "./BetWaitingResult"
import BetSummaryResult from "./BetSummaryResult"

const containers = {
  Login,
  Main,
  MembersList,
  CreateMember,
  Credits,
  EditUserHistory,
  TransferCredits,
  TransactionLists,
  membersDiagram,
  EditUserHistoryDetails,
  TransferCreditDescription,
  ResetPassword,
  BetMaking,
  NewBetMaking,
  Bet,
  TransferConfirmation,
  manageNews,
  Loader,
  BetResult,
  BetHistory,
  ResetUserPassword,
  BetChildren,
  Register2FA,
  SettingRate,
  MemberDetail,
  MembersResetPassword,
  LottoResult,
  LottoResultDetail,
  UserDetail,
  Dashboard,
  UserResetPassword,
  CustomerList,
  CustomerDetail,
  FinanceSumCustomer,
  FinanceCustomerDetails,
  FinanceSummaryChild,
  FinanceChild,
  BetMeHistory,
  BetChildHistory,
  BetSumHistory,
  FinanceHostDetail,
  UserDetailNote,
  MemberDetailNote,
  FinanceSummaryHost,
  PreviewBetBill,
  NumberBanManage,
  NumbersHolesAddLottery,
  NumbersHolesManage,
  NumbersHoles,
  NumberBan,
  NumbersBanAddLottery,
  BetLotteryGroup,
  BetReport,
  BetWaitingResult,
  BetSummaryResult
};
export default containers;
