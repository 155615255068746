import { AxiosError, AxiosResponse } from "axios";
import { createAsyncAction } from "typesafe-actions";
import { POST_OTP_FAILURE, POST_OTP_REQUEST, POST_OTP_SUCCESS } from "./constants";

const postOtpAction = createAsyncAction(
    POST_OTP_REQUEST,
    POST_OTP_SUCCESS,
    POST_OTP_FAILURE,
)<any, AxiosResponse<IResponseOtpAuthen>, AxiosError<IAPIResponse>>()

export { postOtpAction }