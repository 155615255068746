import { Breadcrumb, Button, Dropdown, Panginate, ModalAddNote, LoadingCircle } from "components";
import colors from "constants/colors";
import routes from "constants/routes";
import { get, isEmpty, map, noop } from "lodash";
import React from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { date } from "utils";
import { MemberSpecificInfo } from "../MemberDetail/components/MemberSpecificInfo";
import './memberDetailNote.style.scss'

const constants = {
  breadcrumb: {
    main: "ภาพรวม",
    membersInfo: "ข้อมูลสมาชิก",
    membersList: "รายการสมาชิก",
    addMembers: "tlb-8877-001",
    note:"บันทึกประวัติ"
  },
  title: "ข้อมูลสมาชิก",
  changePassword: "เปลี่ยนรหัสผ่าน",
  rowPerPage:"จำนวนรายการต่อหน้า : ",
  dataNotFound: "ไม่พบข้อมูล...",
  tableHead:["วันที่","ข้อความ"]
};

const defaultProps: IMemberDetailNoteActionProps & IMemberDetailNoteProps = {
  onChangeIsLoaddingAction() {},
  getFinanceChildMe() {noop()},
  financeChildMeCode: 0,
  financeChildMeData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  financeChildMeError: "",
  financeChildMeIsFetching: false,
};

class MemberDetailContainer extends Component<
  IMemberDetailNoteActionProps & IMemberDetailNoteProps &
  RouteComponentProps<any, any, { memberData: IV2GetAgentChild }>,
  IMemberDetailNoteState
> {
  static defaultProps = defaultProps;
  state: IMemberDetailNoteState = {
    currentPage: { selected: 1 },
    page: {
      page: 1,
      limit: 10
    },
    textAreaValue:""
  };

  componentDidMount() {
    if (isEmpty(this.childDetailData())) {
      this.props.history.push(routes.membersList.path)
    }else{
      this.fetchGetData()
    }
  }

  componentDidUpdate(prevProps: IMemberDetailNoteProps) {
  }

  componentWillUnmount() {
    this.props.location.state = undefined
  }

  childDetailData = () => this.props.location.state

  fetchGetData=()=>{
    this.props.getFinanceChildMe({
      ...this.state.page,
      childAgentId:get(this.childDetailData(),"childAgentId",'')
    })
  }

  modalAddNote=()=>{
    ModalAddNote.show({
      action:()=>{ ModalAddNote.hide() },
      description: 'บันทึกข้อความ',
      children:<>
      <div className="row ">
        <div className="m1-y col-12">
          <textarea
            rows={4} 
            cols={58}
            placeholder={"ข้อความ, บันทึก หรือเตือนความจำ"}
            defaultValue={this.state.textAreaValue}
            onChange={(e) => this.setState({textAreaValue:e.target.value})}
          />
        </div>
        <div className="col-6 first">
          <Button
            id="success-ok-button"
            type="rectangle"
            textSize={18}
            onClick={()=>{
              if(this.state.textAreaValue === ""){
                return <span>โปรดกรอกบันทึกข้อความ</span>
              }
              else{
            //  this.props.postcustomerNote({customerId:this.props.locationData,message:this.state.textAreaValue})
              }
            }}
            text="บันทึก"
            transitionType="typeOne"
          />
        </div>
        <div className="col-6 last">
          <Button
            id=""
            type="rectangle"
            onClick={()=>ModalAddNote.hide()}
            backgroundHoverColor={colors.TERTIARY_TEXT}
            textSize={18}
            backgroundColor={colors.TERTIARY_TEXT}
            text="ยกเลิก"
            transitionType="typeOne"
          />
        </div>
      </div>
      </>
    })
  }

  renderTableHead = () => {
    const mapHeadTable = map(
      constants.tableHead,
      (data: string, index: number) => {
        let classText :string = ''
        if ( index===0 || index===1 ) { classText='text-left' }
        return <th className={`${classText} ${index===0&&"col-3"}`} key={index}>
            <h5 className={`text-header secondary-text ${index===0&&"ml-3"}`}>{data}</h5>
          </th>
        }
    );
    return mapHeadTable;
  };

  renderTableBody = () => {
    const dataEmpty = (
      <tr>
        <td className="text-center" colSpan={15}>
          {constants.dataNotFound}
        </td>
      </tr>
    )
    const loadingTable= (
      <tr>
        <td className="text-center" colSpan={15}>
        <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
        </td>
      </tr>
    )
    
    const mapData = map(this.props.financeChildMeData.dataList, (item: IV2RespAgentFinanceChildMe, index: number) => {
      return (
        <tr
          className={`row-bill-list primary-bg`}
          key={index}
          style={{height: 36}}
        >
          <td className="text-left col-3 pl-3">
            <span>{date.formatThai(item.createdAt, 'dd MMM yyyy HH:mm')}</span>
          </td>
          <td className="text-left">
            <span>{item.note}</span>
          </td>
        </tr>
      );
    });
    return this.props.financeChildMeIsFetching ? loadingTable : !isEmpty(this.props.financeChildMeData.dataList) ? mapData : dataEmpty;

  };

  textPageNow =()=>{
    // จำนวนข้อมูล * หน้าที่เปิด
    let endPage = this.props.financeChildMeData.limit*this.state.page.page
    // เลขลำดับท้าย - จำนวนข้อมูล+1
    let startPage = (endPage)-this.props.financeChildMeData.limit+1
    let total  = this.props.financeChildMeData.totalRecord
    return `${ startPage > total ? total : startPage }-${ endPage > total ? total : endPage } of ${ total }`
  }

  handleClickChangePage = ({ selected: selectedPage }: ISelectPage) => {
    this.setState({ currentPage: { selected: selectedPage } }, () => {
      this.setState({
          page: { ...this.state.page,page: this.state.currentPage.selected + 1 },
        },
        () => {this.fetchGetData()}
      )
    })
  }

  render() {
    const labelUsername = get(this.childDetailData(),"childUsername",'')
    const navigates: IBreadcrumbItem[] = [
      { label: constants.breadcrumb.main, path: "/", active: false },
      {
        label: constants.breadcrumb.membersList,
        path: routes.membersList.path,
        active: false,
      },
      {
        label: labelUsername,
        path: routes.memberDetail.path,
        active: false,
      },
      { label: constants.breadcrumb.note, active: true },
    ];

    const RenderTableHead = this.renderTableHead()
    const RenderTableBody = this.renderTableBody()
    
    return (
      <div className="paper-container member-detail-notes-container">
        <div className="row">
          <div className="col">
            <div className="sub-menu">
              <Breadcrumb
                items={navigates}
                handleOnClickItem={(path)=>{               
                  this.props.history.push(path,path===navigates[2].path?{
                    childAgentId: this.props.location.state.childAgentId,
                    childUsername: this.props.location.state.childUsername,
                    childName: this.props.location.state.childName,
                   
                  }:{})
                }}
              />
            </div>
          </div>
        </div>
        <div className="paper-title">{constants.title}</div>

          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="paper-description">
              {`${get(this.childDetailData(),"childName",'')} (${get(this.childDetailData(),"childUsername",'')})`}
            </div>
            <div className="d-flex flex-row align-items-center">
              <div
                className="change-password-text pr-3"
                onClick={()=>{
                  this.props.history.push(routes.memberResetPassword.path, {
                    childAgentId:this.childDetailData().childAgentId,
                    childUsername:this.childDetailData().childUsername,
                    childName:this.childDetailData().childName
                  });
                }}
              >
                {constants.changePassword}
              </div>
            </div>
          </div>

          <div className="paper-body">
            <MemberSpecificInfo childDetailData={[this.childDetailData()]} loading={this.props.financeChildMeIsFetching}/>

            <div className="box-shadow mt-3">

              <div className="row">
                <div className="col">
                  {/* <Button
                    id={"add-member"}
                    size={"moreMediumSmallHeight"}
                    textSize={18}
                    text={"เพิ่มบันทึกข้อความ"}
                    onClick={() => {this.modalAddNote()}}
                  /> */}
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-12'>
                <div className='table-notes'>
                  <div className="table-container">
                  <div className="table-responsive-xl">
                    <table className="w-100">
                      <thead className='header-table-notes'>
                        <tr>{RenderTableHead}</tr>
                      </thead>
                      <tbody>{RenderTableBody}</tbody>
                    </table>
                  </div>
                  </div>
                </div>
                </div>
              </div>

              <div className='row mt-2'>
                <div className="col d-flex justify-content-end align-items-center">
                  <h6 className='text-bold secondary-text'>{constants.rowPerPage}</h6>
                  <div className="dropdown-rowPerPage">
                    <Dropdown
                      value={this.state.page.limit}
                      menuPlacement={"top"}
                      options={ [5,10,25,50,100].map((item)=>{return { value: item, label: item.toString(), disabled: false }}) }
                      controlBackgroundColor={colors.PRIMARY_BG}
                      placeholder={this.props.financeChildMeData.limit.toString()}
                      menuStyles={{width:"100%"}}
                      handleOnChange={(target: IOptions) => {
                        this.setState({ page: {...this.state.page, limit:Number(target.value), page: 1}
                        }, () => {this.fetchGetData()} )
                      }}
                    />
                  </div>
                </div>
                <h6 className='text-bold d-flex justify-content-end align-items-center'>
                  {this.textPageNow()}
                </h6>
                <div className='col-auto d-flex align-items-center'>
                  <Panginate
                    pageTotal={this.props.financeChildMeData.total}
                    initialPage={get(this.state.page,'page',0) - 1}
                    pageRangeDisplayed={0}
                    onPageChange={this.handleClickChangePage}
                  />
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }
}

export default MemberDetailContainer;
