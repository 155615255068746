import { AxiosError, AxiosResponse } from 'axios'
import { createAsyncAction } from 'typesafe-actions'
import {
    GET_EDIT_USER_LIST_REQUEST,
    GET_EDIT_USER_LIST_SUCCESS,
    GET_EDIT_USER_LIST_FAILURE,
    CLEAR_EDIT_USER_LIST,
} from './constants'

const getEditUserHistoryAction = createAsyncAction(
    GET_EDIT_USER_LIST_REQUEST,
    GET_EDIT_USER_LIST_SUCCESS,
    GET_EDIT_USER_LIST_FAILURE,
    CLEAR_EDIT_USER_LIST,
)<any, AxiosResponse<IAPIResponse<IPagination<IEditUserHistory>>>, AxiosError<IAPIResponse>, any>()

export {
    getEditUserHistoryAction,
}