import { AxiosError, AxiosResponse } from "axios";
import { ActionType, createReducer } from "typesafe-actions";
import { responseConverter } from "utils";
import * as otpAction from "./actionCreator";
import { otpState } from "./constants";

export type OtpActionType = ActionType<typeof otpAction>;

const otp = createReducer<ReducerState<IResponseOtpAuthen>, OtpActionType>(
  otpState
)
  .handleAction(
    otpAction.postOtpAction.request,
    (state: ReducerState<IResponseOtpAuthen>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    otpAction.postOtpAction.success,
    (state: ReducerState<IResponseOtpAuthen>, action: OtpActionType) => {
      const payload: AxiosResponse<IAPIResponse<IResponseOtpAuthen>> =
        action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: { ...state.data, ...payload.data.data },
        error: "",
      };
    }
  )
  .handleAction(
    otpAction.postOtpAction.failure,
    (state: ReducerState<IResponseOtpAuthen>, action: OtpActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );

export default otp
