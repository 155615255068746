import { NUMBER } from "constants/regex";
import { string, object } from "yup";

const constants = {
  phoneNumber: "กรุณากรอกหมายเลขโทรศัพท์ 10 หลัก",
  onlyNumber: "กรุณากรอกเฉพาะตัวเลขเท่านั้น",
  bankName: "กรุณากรอกชื่อบัญชีธนาคาร",
  bankNo: "กรุณากรอกหมายเลขบัญชีธนาคาร",
  bank: "กรุณาเลือกธนาคาร",
  commission: "กรุณากรอกค่าคอมมิชชั่น",
  minBet: "กรุณากรอกจำนวนเงินแทงต่ำสุด",
  maxBet: "กรุณากรอกจำนวนเงินแทงสูงสุด",
  betMinOverLimit: "แทงต่ำสุดต้อง น้อยกว่า จำกัดแทงสูงสุด ที่กำหนดไว้",
  betMaxOverLimit: "แทงสูงสุดต้องน้อยกว่าลิมิตที่กำหนดไว้",
  hostSharePercentageRequired: "กรุณากรอกเปอร์เซ็นต์ถือสู้",
  fixSharePercentageRequired: "กรุณากรอกเปอร์เซ็นต์บังคับสู้",
  childLotsetBetMinRequired: "กรุณากรอกจำกัดแทงต่ำสุด",
  childLotsetBetMaxRequired: "กรุณากรอกจำกัดแทงสูงสุด",
  childLotsetDisRequired: "กรุณากรอกส่วนลด",
  childLotsetBetRateRequired: "กรุณากรอกอัตราจ่าย",
};

const bankLength = (bankType: string) => {
  return `กรุณากรอกเลขบัญชีธนาคารให้ครบ ${bankType === "GSB" || bankType === "BAAC" ? "12" : "10"
    } ตัว`;
};

const schema = (meSettingData: IV2GetAgentMeSetting, meData: IV2GetAgentMe) => {
  return object().shape({
    phoneNumber: string()
    .nullable()
    .length(10, constants.phoneNumber)
    .matches(NUMBER, constants.onlyNumber),

    bankNumber: string()
      .nullable()
      .when("bankType", (bankType: TBankType| 'PleaseChoose', schema: any) => {
      return schema.test({
        test: (bankNumber: string) => {
          if(bankType!=='PleaseChoose'){
            if (!bankNumber) {
              return true
            }
            if (bankType === "GSB" || bankType === "BAAC") {
              return bankNumber.length === 12;
            }
            return bankNumber.length === 10;
          }else {
            return true
          }
        },
        message: bankLength(bankType),
        });
      }),

   
    com: string().required(constants.commission),

    betMinLimit: string()
      .required(constants.minBet)
      .test("", constants.betMinOverLimit, (val, chlid) => {
        return meData.permission === "OWNER"
          ? Number(val) <= chlid.parent.betMaxLimit
          : Number(val) <= meSettingData.setBetMaxLimit
      }),

    betMaxLimit: string()
      .required(constants.maxBet)
      .test("", constants.betMaxOverLimit, (val, chlid) => {
        return meData.permission === "OWNER"
          ? Number(val) >= chlid.parent.betMinLimit
          : Number(val) <= meSettingData.setBetMaxLimit
      }),

    hostSharePercentage: string()
      .required(constants.hostSharePercentageRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : (Number(val) || 0) >= Number(ctx.parent.mustReciv) &&
          (Number(val) || 0) <= Number(ctx.parent.meShare);
      }),

    fixSharePercentage: string()
      .required(constants.fixSharePercentageRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : (Number(val) || 0) <= Number(ctx.parent.hostSharePercentage);
      }),

    childLotsetDisThreeUp: string().required(constants.childLotsetDisRequired),
    childLotsetDisThreeToast: string().required(
      constants.childLotsetDisRequired
    ),
    childLotsetDisTwoUp: string().required(constants.childLotsetDisRequired),
    childLotsetDisTwoDown: string().required(constants.childLotsetDisRequired),
    childLotsetDisRunUp: string().required(constants.childLotsetDisRequired),
    childLotsetDisRunDown: string().required(constants.childLotsetDisRequired),

    childLotsetBetMinThreeUp: string()
      .required(constants.childLotsetBetMinRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(ctx.parent.childLotsetBetMaxThreeUp) && Number(val) >= Number(ctx.parent.betMinLimit);
      }),
    childLotsetBetMinThreeToast: string()
      .required(constants.childLotsetBetMinRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(ctx.parent.childLotsetBetMaxThreeToast) && Number(val) >= Number(ctx.parent.betMinLimit);
      }),
    childLotsetBetMinTwoUp: string()
      .required(constants.childLotsetBetMinRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(ctx.parent.childLotsetBetMaxTwoUp) && Number(val) >= Number(ctx.parent.betMinLimit);
      }),
    childLotsetBetMinTwoDown: string()
      .required(constants.childLotsetBetMinRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(ctx.parent.childLotsetBetMaxTwoDown) && Number(val) >= Number(ctx.parent.betMinLimit);
      }),
    childLotsetBetMinRunUp: string()
      .required(constants.childLotsetBetMinRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(ctx.parent.childLotsetBetMaxRunUp) && Number(val) >= Number(ctx.parent.betMinLimit);
      }),
    childLotsetBetMinRunDown: string()
      .required(constants.childLotsetBetMinRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(ctx.parent.childLotsetBetMaxRunDown) && Number(val) >= Number(ctx.parent.betMinLimit);
      }),

    childLotsetBetMaxThreeUp: string()
      .required(constants.childLotsetBetMaxRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(meSettingData.setBetMaxLimit);
      }),
    childLotsetBetMaxThreeToast: string()
      .required(constants.childLotsetBetMaxRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(meSettingData.setBetMaxLimit);
      }),
    childLotsetBetMaxTwoUp: string()
      .required(constants.childLotsetBetMaxRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(meSettingData.setBetMaxLimit);
      }),
    childLotsetBetMaxTwoDown: string()
      .required(constants.childLotsetBetMaxRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(meSettingData.setBetMaxLimit);
      }),
    childLotsetBetMaxRunUp: string()
      .required(constants.childLotsetBetMaxRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(meSettingData.setBetMaxLimit);
      }),
    childLotsetBetMaxRunDown: string()
      .required(constants.childLotsetBetMaxRequired)
      .test("", "error", (val, ctx) => {
        return meData.permission === "OWNER"
          ? true
          : Number(val) <= Number(meSettingData.setBetMaxLimit);
      }),

    childLotsetBetRateThreeUp: string()
      .required(constants.childLotsetBetRateRequired)
      .test("", "error", (val, ctx) => {
        return Number(val) <= 9999.99;
      }),
    childLotsetBetRateThreeToast: string()
      .required(constants.childLotsetBetRateRequired)
      .test("", "error", (val, ctx) => {
        return Number(val) <= 9999.99;
      }),
    childLotsetBetRateTwoUp: string()
      .required(constants.childLotsetBetRateRequired)
      .test("", "error", (val, ctx) => {
        return Number(val) <= 9999.99;
      }),
    childLotsetBetRateTwoDown: string()
      .required(constants.childLotsetBetRateRequired)
      .test("", "error", (val, ctx) => {
        return Number(val) <= 9999.99;
      }),
    childLotsetBetRateRunUp: string()
      .required(constants.childLotsetBetRateRequired)
      .test("", "error", (val, ctx) => {
        return Number(val) <= 9999.99;
      }),
    childLotsetBetRateRunDown: string()
      .required(constants.childLotsetBetRateRequired)
      .test("", "error", (val, ctx) => {
        return Number(val) <= 9999.99;
      }),
  });
};

export default schema;
