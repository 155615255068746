import React, { FC } from "react";
import { isEmpty, noop } from "lodash";
import { Modal } from "../..";
import "./announceNewLotto.style.scss";
import { Button, ResponsiveIcon } from "components";
import logo from "assets/images/logo/logothailandbet.png";
import LottoFlags from "assets/images/global/flags";
const constants = {
  title: "คุณแน่ใจหรือไม่",
  description: "คุณแน่ใจหรือว่าจะเปลี่ยนรหัสผ่าน",
  actionText: "เข้าใจแล้ว",
  titleWeb: "ไทยแลนด์เบท",
  titleEng: "Thailand Bet",
  cancel: "ยกเลิก",
  accept: "ตกลง",
};

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: IInfoModal = {
  title: constants.title,
  description: constants.description,
  actionText: constants.actionText,
  acceptText: 'ตกลง',
  action() {
    noop();
  },
  cancelAction() {
    noop();
  },
  titleStyle: ''
};

const AnnounceNewLottoModal: FC<IInfoModal & DefaultProps> = (props) => {
  return (
    <div className="announce-new-lotto-container border-rounded">
      <div className="row mr-0">
        <div className="leading-logo-container m2-x">
          <img src={logo} className="logoMenu" alt="logo Thailand bet" />
        </div>
        <div className="title-text-web">
          <h3 className="title">{constants.titleWeb}</h3>
          <h5 className="tertiary-text sub-title">{constants.titleEng}</h5>
        </div>
      </div>
      <h2 className={`title-text secondary-text m2-b ${props.textSmall&&"text-small"} whitespace ${props.titleStyle}`}>{props.title}</h2>
      <div className="d-flex flex-row mb-2 justify-content-center">
          <div className='m1-x'>
            <ResponsiveIcon
              alt="announce-icon"
              className="announce-checked-icon"
              icon={LottoFlags['VNM'].Icon}
            />
          </div>
          <div className='m1-x'>
            <ResponsiveIcon
              alt="announce-icon"
              className="announce-checked-icon"
              icon={LottoFlags['USA'].Icon}
            />
          </div>
        </div>
      <div className="my-2">
          <h3 className="description-text">
            {`เรียนท่านสมาชิกทุกท่าน ระบบได้ทำการเพิ่มหวยใหม่ `}
            <span className='primary-green-text'>ฮานอยตรุษจีน</span>
            {' '}
            และ
            {' '}
            <span className='primary-green-text'>ดาวน์โจนสตาร์</span>
            {' '}
            ขอให้ทุกท่านสนุกและเพลิดเพลิน
          </h3>
        </div>
      <div className="row justify-content-center">
        <div className="col pr-0">
          <Button
            size="mediumHeight44"
            id="confirm-button"
            type="rectangle"
            onClick={props.action}
            text={props.acceptText!}
            textSize={18}
            transitionType="typeOne"
          />
        </div>
      </div>
    </div>
  );
};

AnnounceNewLottoModal.defaultProps = defaultProps;

const announceNewLottoModal = {
  show: (props: IInfoModal) => {
    return Modal.event("MODAL_OVER", {
      state: "show",
      extraProps: { size: isEmpty(props.size)?"md":props.size },
      component: <AnnounceNewLottoModal {...props} />,
    });
  },
  hide: () => Modal.event("MODAL_OVER", { state: "hide" }),
};

export default announceNewLottoModal;
