import { connect } from "react-redux";
import TransferCreditsContainer from "./TransferCredits.container";
import transferCreditSelector from "../../reduxs/credits/selector";
import {
  getTransferCredit,
  cancelTranfersCredits,
} from "../../reduxs/credits/transferCredits/action";
import { postPayRequest } from "../../reduxs/credits/payRequest/action";

const mapStateToProps = (
  state: RootReducers
): ITransferCreditContainerProps => {
  const transferCredits = transferCreditSelector.transferCredits(state);
  const payRequests = transferCreditSelector.payRequests(state);
  return {
    getTransferCreditListsIsFetching: transferCredits.isFetching,
    TransferCreditLists: transferCredits.data,
    getTransferCreditListsCode: transferCredits.code,
    getTransferCreditListsError: transferCredits.error,
    postPayRequestIsFetching: payRequests.isFetching,
    PayRequestRestoreState: payRequests.data,
    PostPayRequestCode: payRequests.code,
    postPayRequestError: payRequests.error,
  };
};

const mapDispatchToProps: ITransferCreditContainerAction = {
  getTransferCreditLists: (query) => getTransferCredit(query),
  cancelTransferCreditLists: cancelTranfersCredits,
  postPayRequest: (body) => postPayRequest(body),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransferCreditsContainer);
