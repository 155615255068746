export const GET_HOST_REQUEST = "GET_HOST_REQUEST";
export const GET_HOST_SUCCESS = "GET_HOST_SUCCESS";
export const GET_HOST_FAILURE = "GET_HOST_FAILURE"

export const hostState: ReducerState<IV2GetAgentHost> = {
    isFetching: false,
    code: 0,
    error: "",
    data: {
        hostAgentId: 0,
        hostBankName: "",
        hostBankNumber: "",
        hostBankType: "",
        hostLine: "",
        hostPhoneNumber: "",
        hostUsername: ""
    }
}