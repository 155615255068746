import { AxiosError, AxiosResponse } from "axios";
import { createAsyncAction } from "typesafe-actions";
import {
  POST_OWNER_SETTING_FAILURE,
  POST_OWNER_SETTING_LOTTERY_UPDATE_FAILURE,
  POST_OWNER_SETTING_LOTTERY_UPDATE_REQUEST,
  POST_OWNER_SETTING_LOTTERY_UPDATE_SUCCESS,
  POST_OWNER_SETTING_REQUEST,
  POST_OWNER_SETTING_SUCCESS,
  POST_OWNER_UPDATE_FAILURE,
  POST_OWNER_UPDATE_REQUEST,
  POST_OWNER_UPDATE_SUCCESS,
} from "./constants";

const postOwnerUpdateAction = createAsyncAction(
  POST_OWNER_UPDATE_REQUEST,
  POST_OWNER_UPDATE_SUCCESS,
  POST_OWNER_UPDATE_FAILURE
)<any, AxiosResponse<{}>, AxiosError<IAPIResponse>>();

const postOwnerSettingAction = createAsyncAction(
  POST_OWNER_SETTING_REQUEST,
  POST_OWNER_SETTING_SUCCESS,
  POST_OWNER_SETTING_FAILURE
)<any, AxiosResponse<{}>, AxiosError<IAPIResponse>>();

const postOwnerSettingLotteryUpdateAction = createAsyncAction(
  POST_OWNER_SETTING_LOTTERY_UPDATE_REQUEST,
  POST_OWNER_SETTING_LOTTERY_UPDATE_SUCCESS,
  POST_OWNER_SETTING_LOTTERY_UPDATE_FAILURE
)<any, AxiosResponse<{}>, AxiosError<IAPIResponse>>();

export { postOwnerUpdateAction, postOwnerSettingAction, postOwnerSettingLotteryUpdateAction };
