export const MAKE_BET_LOTTO_REQUEST = "MAKE_BET_LOTTO_REQUEST";
export const MAKE_BET_LOTTO_SUCCESS = "MAKE_BET_LOTTO_SUCCESS";
export const MAKE_BET_LOTTO_FAILURE = "MAKE_BET_LOTTO_FAILURE";

export const GET_HISTORY_REQUEST = "GET_HISTORY_REQUEST";
export const GET_HISTORY_SUCCESS = "GET_HISTORY_SUCCESS";
export const GET_HISTORY_FAILURE = "GET_HISTORY_FAILURE";

export const GET_V2_BET_GROUP_REQUEST = "GET_V2_BET_GROUP_REQUEST";
export const GET_V2_BET_GROUP_SUCCESS = "GET_V2_BET_GROUP_SUCCESS";
export const GET_V2_BET_GROUP_FAILURE = "GET_V2_BET_GROUP_FAILURE";

export const POST_V2_AGENT_BET_REQUEST = "POST_V2_AGENT_BET_REQUEST";
export const POST_V2_AGENT_BET_SUCCESS = "POST_V2_AGENT_BET_SUCCESS";
export const POST_V2_AGENT_BET_FAILURE = "POST_V2_AGENT_BET_FAILURE";

export const GET_V2_AGENT_BET_REQUEST = "GET_V2_AGENT_BET_REQUEST";
export const GET_V2_AGENT_BET_SUCCESS = "GET_V2_AGENT_BET_SUCCESS";
export const GET_V2_AGENT_BET_FAILURE = "GET_V2_AGENT_BET_FAILURE";
export const GET_V2_AGENT_BET_CLEAR = "GET_V2_AGENT_BET_CLEAR"; 

export const GET_HISTORY_SUMMARY_DETAIL_REQUEST = "GET_HISTORY_SUMMARY_DETAIL_REQUEST";
export const GET_HISTORY_SUMMARY_DETAIL_SUCCESS = "GET_HISTORY_SUMMARY_DETAIL_SUCCESS";
export const GET_HISTORY_SUMMARY_DETAIL_FAILURE = "GET_HISTORY_SUMMARY_DETAIL_FAILURE";
export const CLEAR_HISTORY_SUMMARY_DETAIL = "CLEAR_HISTORY_SUMMARY_DETAIL";

export const GET_HISTORY_SUMMARY_REQUEST = "GET_HISTORY_SUMMARY_REQUEST";
export const GET_HISTORY_SUMMARY_SUCCESS = "GET_HISTORY_SUMMARY_SUCCESS";
export const GET_HISTORY_SUMMARY_FAILURE = "GET_HISTORY_SUMMARY_FAILURE";
export const CLEAR_HISTORY_SUMMARY = "CLEAR_HISTORY_SUMMARY";

export const GET_RESULT_REQUEST = "GET_RESULT_REQUEST";
export const GET_RESULT_SUCCESS = "GET_RESULT_SUCCESS";
export const GET_RESULT_FAILURE = "GET_RESULT_FAILURE";

export const CLEAR_BET_RESULT = "CLEAR_BET_RESULT";

export const GET_V2_RESULT_REQUEST = "GET_V2_RESULT_REQUEST";
export const GET_V2_RESULT_SUCCESS = "GET_V2_RESULT_SUCCESS";
export const GET_V2_RESULT_FAILURE = "GET_V2_RESULT_FAILURE";

export const CLEAR_V2_BET_RESULT = "CLEAR_BET_RESULT";

export const GET_V3_BET_EXTRACTION_REQUEST = "GET_V3_BET_EXTRACTION_REQUEST"
export const GET_V3_BET_EXTRACTION_SUCCESS = "GET_V3_BET_EXTRACTION_SUCCESS"
export const GET_V3_BET_EXTRACTION_FAILURE = "GET_V3_BET_EXTRACTION_FAILURE"

export const GET_V3_BET_EXTRACTION_DETAIL_REQUEST = "GET_V3_BET_EXTRACTION_DETAIL_REQUEST"
export const GET_V3_BET_EXTRACTION_DETAIL_SUCCESS = "GET_V3_BET_EXTRACTION_DETAIL_SUCCESS"
export const GET_V3_BET_EXTRACTION_DETAIL_FAILURE = "GET_V3_BET_EXTRACTION_DETAIL_FAILURE"

export const makeState: ReducerState<any> = {
  isFetching: false,
  code: 0,
  data: [],
  error: "",
};

export const historySummaryDetailState: ReducerState<IResponseAgentQueryLotteryHistorySummaryDetail> = {
  isFetching: false,
  code: 0,
  data: {
    id: 0,
    remark: '',
    code: 'LOTTER_GOVN',
    betTotal: 0,
    result: 0,
    status: '',
    lotteryGroupList: [],
    createdAt: '',
  },
  error: '',
}
export const historySummaryState: ReducerState<
  IPagination<IResponseAgentQueryLotteryHistorySummary>
> = {
  isFetching: false,
  code: 0,
  data: {
    totalRecord: 0,
    page: 1,
    limit: 10,
    total: 0,
    dataList: [],
  },
  error: "",
};

export const historyState: ReducerState<
  IPagination<IResponseAgentBetLotteryHistoryList>
> = {
  isFetching: false,
  code: 0,
  data: {
    totalRecord: 0,
    page: 1,
    limit: 10,
    total: 0,
    dataList: [],
  },
  error: "",
};

export const resultState: ReducerState<IResponseLudensBetResult[]> = {
  isFetching: false,
  code: 0,
  data: [],
  error: "",
};

export const betGroupState: ReducerState<
  IPagination<IV2RespAgentLotteryBetGroup>
> = {
  isFetching: false,
  code: 0,
  data: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  error: "",
};

export const getBetState: ReducerState<
  IPagination<IV2RespAgentLotteryBetMe>
> = {
  isFetching: false,
  code: 0,
  data: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  error: "",
};

export const getV2ResultState: ReducerState<IPagination<IV2RespAgentLotteryResult>> = {
  isFetching: false,
  code: 0,
  data: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  error: "",
};



export const betState: ReducerState<IV2RespAgentLotteryBet> = {
  isFetching: false,
  code: 0,
  data: {
    agentShareId: 0,
    remarkId: 0,
    agentBenefitList: []
  },
  error: "",
};

export const betExtractionState: ReducerState<IV3ExtractionGroupingLottery[]> = {
  isFetching: false,
  code: 0,
  data: [],
  error: "",
};

export const betExtractionDetailState: ReducerState<IV3RespGetDetailExtractionAgentBetLottery[]> = {
  isFetching: false,
  code: 0,
  data: [],
  error: "",
};


