import { Button, ErrorModal } from 'components'
import routes from 'constants/routes'
import React, { useState, FunctionComponent, useMemo, useEffect } from 'react'
import { useParams, Redirect, useLocation } from 'react-router-dom'
import pdfMake, { TCreatedPdf } from "pdfmake/build/pdfmake";
import docDefinition from '../NewBetMaking/docDefinition'
import { getV2AgentBet } from 'reduxs/lotto/bet/services';
import { fetchGetV2LottoSchedule } from "reduxs/lotto/services";
import { addMinutes, format } from 'date-fns';
import { date, responseConverter, transformer } from 'utils';
import { AxiosError, AxiosResponse } from 'axios';
import { get, isEmpty, maxBy } from 'lodash';
import { responseMessage } from 'constants/response';
import CryptoJS from "crypto-js";
import './previewBetBill.style.scss'


const constants = {
    pageTitle: 'ใบเสร็จ',
    member: 'สมาชิก',
    lottoDate: 'งวดที่',

    print: 'พิมพ์',
    download: 'ดาวน์โหลด',
}

function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

const PreviewBetBill: FunctionComponent = () => {

    const { code } = useParams<{ code: string }>()
    const query = useQuery()
    const [memberName, setMemberName] = useState('')
    const [lottoDate, setLottoDate] = useState('')
    const [pdfGenerator, setGeneratorPDF] = useState<TCreatedPdf | null>(null)
    const [billUrl, setBillUrl] = useState('')
    const [fileName ,setFileName] = useState('')

    useEffect(() => {
        if (code) {
            const urlDecoded = decodeURIComponent(code)
            const decrypted = CryptoJS.AES.decrypt(urlDecoded, 'bill-query')
            if (decrypted) {
                const show = query.get('showpaid')
                const isShowPaid = !isEmpty(show)
                const filterData = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8)) as IV2RespAgentLotteryBetMe
                if (filterData.customerId > 0) {
                    setMemberName(get(filterData, 'customerName', ''))
                    getBillDetail(filterData, isShowPaid)
                } else {
                    window.close()
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getBillDetail = (data: IV2RespAgentLotteryBetMe, showPay: boolean) => {
        const startTime = get(data,"startTime",'')
        const endTime = get(data,"endTime",'')
        getV2AgentBet({
            limit: 999999,
            name: data.name,
            customerId: data.customerId,
            agentShareId: data.agentShareId,
            startTime: !isEmpty(startTime)?startTime:(!isEmpty(data.createdAt) ?format(date.calibratingTime(data.createdAt), "yyyy'-'MM'-'dd'T'HH:mm:ss") : ''),
            endTime: !isEmpty(endTime)?endTime:(!isEmpty(data.createdAt)  ?format(addMinutes(date.calibratingTime(data.createdAt), 1), "yyyy'-'MM'-'dd'T'HH:mm:ss"): ''),
        }).then((res: AxiosResponse<IAPIResponse<IPagination<IV2RespAgentLotteryBetMe>>>) => {
            const dataList = res.data.data.dataList
            const username =  query.get('name')
            if (!isEmpty(dataList)) {
                const dataName = transformer.nameLotto(get(dataList,"[0].name") as any,true,true)
                const dataCustomerName = get(dataList,"[0].customerName")
                const dateNow = date.formatThai(get(dataList,"[0].createdAt"), 'ddMMyyyy')
                const nameFile = `${dataName}_${dataCustomerName}_${dateNow}`
                setFileName(nameFile)
                let status = get(dataList, "[0].status", '')
                let round = ''
                let createdPDF 
                if (status !== 'WAIT') {
                    round = get(maxBy(dataList, (i) => i.updatedAt), "updatedAt", '')
                    round = date.formatThai(round, 'dd MMMM yyyy')
                    setLottoDate(round)
                     createdPDF = pdfMake.createPdf(docDefinition(dataList, showPay, round, username!,  data.agentShareId) as any)
                     setGeneratorPDF(createdPDF)
                     createdPDF.getDataUrl((dataUrl) => setBillUrl(dataUrl))
                }
                else{
                    fetchGetV2LottoSchedule()
                    .then((res: AxiosResponse<IAPIResponse<IV2RespAgentLotterySchedule[]>>) => {
                        const nameForRound = get(data, "nameForRound", '')
                        let findData = res.data.data.filter((i)=>i.name===(!isEmpty(nameForRound)?nameForRound:data.name))
                        if (isEmpty(findData)) {
                            round = "ไม่พบข้อมูลงวด"
                        }else{
                            round = date.formatThai(findData[0].endTime, 'dd MMMM yyyy')
                        }
                        setLottoDate(round)
                        createdPDF = pdfMake.createPdf(docDefinition(dataList, showPay, round, username!,  data.agentShareId) as any)
                        setGeneratorPDF(createdPDF)
                        createdPDF.getDataUrl((dataUrl) => setBillUrl(dataUrl))
                    })
                    .catch((error: AxiosError<IAPIResponse>) => {
                        ErrorModal.show({
                            action: window.close,
                            description: get(responseMessage(), responseConverter.getMessage(error).code, responseMessage()[0]),
                        });
                    })
                  }
            
            }
        })
            .catch((error: AxiosError<IAPIResponse>) => {
                ErrorModal.show({
                    action: window.close,
                    description: get(responseMessage(), responseConverter.getMessage(error).code, responseMessage()[0]),
                });
            })
    }

    if (!code) { return <Redirect to={routes.Dashboard.path} /> }

    return (
        <div className="paper-container finance-host-detail-container">
            <div className='paper-with-button-container'>
                <div>
                    <div className="paper-title">{constants.pageTitle}</div>
                    <div className="pr-3 paper-description page-subtitle">{constants.member} <span className='bill-number-value'>{memberName}</span> {constants.lottoDate} {lottoDate}</div>
                </div>
                <div className='bill-indeacator-button-container'>
                    <Button
                        id="prev-button-id"
                        size="moreMedium"
                        textSize={18}
                        text={constants.print}
                        onClick={() => { if (pdfGenerator) { pdfGenerator.print() } }} />
                    <Button
                        id="next-button-id"
                        size="moreMedium"
                        textSize={18}
                        text={constants.download}
                        onClick={() => { if (pdfGenerator) { pdfGenerator.download(fileName) } }} />
                </div>
            </div>
            <div className="paper-body">
                {billUrl && (<iframe src={`${billUrl}#zoom=fitH&toolbar=0`} title="billing" width="100%" style={{ height: 'calc(100vh - 300px)' }} name="ggwp" />)}
            </div>
        </div>
    )
}

export default PreviewBetBill