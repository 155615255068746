import { connect } from "react-redux";
import Dashboard from "./Dashboard.container";
import { getMeSetting } from "reduxs/me/setting/action"
import { onChangeIsLoaddingAction } from "../../reduxs/Loader/action"
import meSelector from "../../reduxs/me/selector"
import hostSelector from "reduxs/host/selector";
import { getHost } from "reduxs/host/host/action";
import { getMe } from "reduxs/me/me/action";

const mapStateToProps =(state: RootReducers)=>{
 const getMeList = meSelector.me(state)
 const getHostData = hostSelector.hostList(state)
    return {
    getMeData :  (getMeList as any)?.data,
    getMeDataIsFetching: getMeList.isFetching,
    getHostData : getHostData.data,
    getHostDataIsFetching : getHostData.isFetching,
    }
}
const mapDispatchToProps: IDashboardActionProps = {
    getMe,
    getMeSetting: getMeSetting,
    getHost:getHost,
    onChangeIsLoaddingAction: onChangeIsLoaddingAction,
}


export default connect(mapStateToProps,mapDispatchToProps)(Dashboard)