import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { postOtpAction } from "./actionCreator";
import { fetchPostOtp } from "./services";

const postOtp = (data: ISendOtpAuthen) => (dispatch: Dispatch) => {
    dispatch(postOtpAction.request({}))
    return fetchPostOtp(data)
    .then((res: AxiosResponse<IResponseOtpAuthen>) => {
        dispatch(postOtpAction.success(res))
    })
    .catch((error: AxiosError<IAPIResponse>) => {
        dispatch(postOtpAction.failure(error))
    })
}

export { postOtp }