import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { getChildDetailAction } from "../actionCreator";
import { fetchGetChildDetail } from '../services'

const getChildDetail = (query: IQueryPagination & IV2QueryGetAgentChildDetail) => (dispatch: Dispatch) => {
    dispatch(getChildDetailAction.request({}))
    return fetchGetChildDetail(query)
    .then((res: AxiosResponse<IPagination<
        IV2GetAgentChild &
          IV2GetAgentChildSettingUpdate &
          IV2PostAgentChildSettingLotteryUpdate
      >>) => {
        dispatch(getChildDetailAction.success(res))
    })
    .catch((error: AxiosError<IAPIResponse>) => {
        dispatch(getChildDetailAction.failure(error))
    })
}

export { getChildDetail }