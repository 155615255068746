import { initialisLoadingState } from "./constants";
import { combineReducers } from "redux";
import * as loaderAction from "./actionCreator";
import { ActionType, createReducer } from "typesafe-actions";

export type LoaderActionType = ActionType<typeof loaderAction>;
const loaderReducers = createReducer<ILoadingActiveControl, LoaderActionType>(initialisLoadingState)
  .handleAction(loaderAction.loaderAction.success,(state: ILoadingActiveControl, action: LoaderActionType) => {
      const payload: boolean = action.payload;
      return {
        ...state,
        isLoading: payload,
      };
    }
  )
  .handleAction(loaderAction.loaderAction.failure,(state: ILoadingActiveControl, action: LoaderActionType) => state
  );

export default combineReducers({
  loaderReducers,
});
