import React from "react";
import { FC, useRef } from "react";
import { NumberFormatProps } from "react-number-format";
import "./inputSplit.style.scss";

const InputSplit: FC<IInputElements & NumberFormatProps & any> = (props) => {
  const { type, placeholder, format, ...inputProps } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div
      className={`input-split-wrapper w-100 ${inputProps.disabled ? "disabled" : ""
        } ${inputProps.error ? "error" : ""}`}
      onClick={() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }}
    >
      <div style={{ display: "flex", alignItems: "center", width: '100%' }}>
      <input
        onFocus={inputProps.onFocus}
        ref={inputRef}
        className={`text-box ${inputProps.disabled && "disabled"}`}
        onChange={inputProps.onChange}
        // style={{ width: `${(String(inputProps.value).length) + 2}ch` }}
        // style={{ width: `${(lengthValue === 0 ? get(props, "placeholder", '').length : lengthValue) + 2}ch` }}
        value={inputProps.value || undefined}
        name={inputProps.name}
        id={inputProps.id}
        onBlur={inputProps.onBlur}
        disabled={inputProps.disabled}
        placeholder={placeholder}
        type={type}
      />
      <div className={`span-end-text-box ${inputProps.disabled && "disabled"}`}>
        {inputProps.endText}
      </div>
    </div>
    </div>
  );
};

export default InputSplit
