import { AxiosError, AxiosResponse } from "axios";
import { createAsyncAction } from "typesafe-actions";
import {
  GET_RATE_REQUEST,
  GET_RATE_SUCCESS,
  GET_RATE_FAILURE,
  GET_NUMBER_REQUEST,
  GET_NUMBER_SUCCESS,
  GET_NUMBER_FAILURE,
} from "./constants";

const getRateAction = createAsyncAction(
  GET_RATE_REQUEST,
  GET_RATE_SUCCESS,
  GET_RATE_FAILURE
)<
  any,
  AxiosResponse<IAPIResponse<IResponseAgentLotteryRateClient[]>>,
  AxiosError<IAPIResponse<IResponseAgentLotteryRateClient[]>>
>();

const getNumberAction = createAsyncAction(
  GET_NUMBER_REQUEST,
  GET_NUMBER_SUCCESS,
  GET_NUMBER_FAILURE
)<
  any,
  AxiosResponse<IAPIResponse<IResponseAgentLotteryRateNumber[]>>,
  AxiosError<IAPIResponse>
>();

export { getRateAction, getNumberAction };
