import React, { FC, useState } from "react";

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: IPopout = {};

const Popout: FC<IPopout & DefaultProps> = ({
  children,
  Target,
  PopoutMenuProps,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  const Menu = (props: any) => {
    const shadow = "hsla(218, 50%, 10%, 0.1)";
    return (
      <>
        <div
          style={{
            backgroundColor: "white",
            borderRadius: 6,
            boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
            marginTop: 6,
            zIndex: 2,
            marginLeft: 1,
            width: "130px",
            padding: "0px",
            minHeight: 38,
            minWidth: 100,
            top: 0,
            right: 0,
            position: "absolute",
            ...PopoutMenuProps,
          }}
          {...props}
        />
      </>
    );
  };

  const Dropdown = ({
    children,
    isOpen,
    target,
  }: {
    children: any;
    isOpen: boolean;
    target: any;
    onClose: any;
  }) => {
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
          toggleOpen();
        }}
        className="position-relative"
      >
        <div>{target}</div>
        {isOpen ? <Menu>{children}</Menu> : null}
      </div>
    );
  };

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={() => {
        toggleOpen();
      }}
      target={Target}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
          toggleOpen();
        }}
      >
        {children}
      </div>
    </Dropdown>
  );
};

Popout.defaultProps = defaultProps;

export default Popout;
