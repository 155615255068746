import { Breadcrumb, ButtonRadio, DatePickerNew, Dropdown, Input, LoadingCircle, Panginate } from 'components';
import { CODE_TO_NAME, LOTTO_GAME_TYPE_NAME, } from 'constants/variables';
import { endOfWeek, format, startOfWeek } from 'date-fns';
import {
  get,
  includes,
  isEmpty,
  isNull,
  map,
  noop,
  orderBy,
} from 'lodash';
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router';
import { date, number, transformer } from 'utils';
import { thai_th } from "constants/thai_th"
import point from "assets/images/global/icon/point.png";
import './betChildHistory.style.scss'
import colors from 'constants/colors';
import routes from 'constants/routes';

const listOptionSearchRow1: { [type in string]: string } = {
  TODAY:"วันนี้",
  THIS_WEEK:"สัปดาห์นี้",
  MONTH:"เดือน",
}

const listOptionSearchRow2: { [type in string]: string } = {
  YESTERDAY:"เมื่อวาน",
  LAST_WEEK:"สัปดาห์ที่แล้ว",
  SINCE:"ตั้งแต่",
}

const listOptionStatus: { [type in IStatusBetMe|"All"]: string } = {
  All:"ทั้งหมด",
  WAIT:"รอผล",
  WINNER:"ถูกรางวัล",
  LOSER:"ไม่ถูกรางวัล",
  CANCEL:"ยกเลิก",
}

const listActionItem : {[type in string] : string} = {
  CustomerList:"ข้อมูลลูกค้า",   //ไปหน้าข้อมูลลูกค้า
  FinanceHost:"เคลียร์ยอดหัวสมาชิก",  //ไปหน้า
  FinanceChild:"เคลียร์ยอดลูกสมาชิก",  //ไปหน้า
  FinanceCustomer:"เคลียร์ยอดลูกค้า",  //ไปหน้า
}

const listOptionLotto : { [type in TLottoType|"All"]: string } = {...{All:"ทั้งหมด"},...CODE_TO_NAME}

type DefaultProps = Readonly<typeof defaultProps>;
const constants = {
   mainPageTitle:"ภาพรวม",
   betTitle:"การเดิมพัน",
   betMeTitle:"รายการเดิมพันสมาชิก",

   paperTitle:"รายการเดิมพันสมาชิก",
   paperDescription: "รายการเดิมพันของสายทั้งหมดของคุณ",

   dataNotFound: "ไม่พบข้อมูล...",

   rowPerPage:"จำนวนรายการต่อหน้า : ",
   optionSearch:"ตัวเลือกการค้นหา"
};
 
const mappingTheadLottoฺBill: IHeaderLottoTable[] = [
   { id: 1, name: "ชื่อยูสสมาชิก" },
   { id: 2, name: "ชื่อช่วยจำ" },
   { id: 3, name: "หวย" },
   { id: 4, name: "ประเภท" },
   { id: 5, name: "เลข" },
   { id: 6, name: "อัตราจ่าย" },
   { id: 7, name: "ถือสู้" },
   { id: 8, name: "แบ่งหุ้น" },
   { id: 9, name: "ยอดเดิมพัน" },
   { id: 10, name: "ส่วนลด" },
   { id: 11, name: "ยอด" },
   { id: 12, name: "ค่าคอม" },
   { id: 13, name: "แพ้/ชนะ" },
   { id: 14, name: "เวลา" },
   { id: 15, name: "" },
];

const defaultProps: IBetChildHistoryContainerProps & IBetChildHistoryContainerAction = {
   onChangeIsLoaddingAction() { noop() },
   getChildBet() { noop() },

   getChildBetData : {
      page: 1,
      limit: 10,
      total: 0,
      totalRecord: 0,
      dataList: [],
   },
   getChildBetIsFetching : false,
   getChildBetCode : 0,
   getChildBetError : "",
};

export default class BetChildHistoryContainer extends Component<
    IBetChildHistoryContainerProps & IBetChildHistoryContainerAction & DefaultProps & RouteComponentProps
> {
   static defaultProps = defaultProps;

   queryTimeout: NodeJS.Timeout | null = null;

   state: IBetChildHistoryContainerState = {
    currentPage: { selected: 1 },
    page: {
      page: 1,
      limit: 10
    },
    selectedOptionSearch:"",
    searchMonths:-1,
    dateStart:null,
    dateEnd:null,
    querySearch:{
      customerName: "",

      customerId:"",
      childUsername: "",
      childName: "",

      startTime: "",
      endTime: "",
      number: "",
      status:"",
      name: "",


    },
   };

  componentDidMount(){
    this.fetchGetData()
  }

  componentDidUpdate(prevProps:IBetChildHistoryContainerProps){

  }

  fetchGetData=()=>{
    this.props.getChildBet({
      ...this.state.page,
      ...this.state.querySearch
    })
  }

  renderTableHead = (values: Array<IHeaderLottoTable>,subName: string) => {
    const mapHeadTable = map(
      values,
      (data: IHeaderLottoTable, index: number) => {
        let classText :string = ''
        if ( index===4 || index===12 || index===13 ) {
          classText='text-center'
        }else if ( index===5 || index===6 || index===7 || index===8 || index===9 || index===10 || index===11 ) {
          classText='text-right'
        }else if ( index===0 || index===1 || index===2 ||  index===3 ) {
          classText='text-left'
        }
        return <th className={`${classText}`} key={index}>
            <h5 className={`text-header secondary-text ${index===0&&'ml-3'}`}>{data.name}</h5>
            {index===0&&<h5 className='text-left sub-name secondary-text ml-3'>{subName}</h5>}
          </th>
        }
    );
    return mapHeadTable;
  };

  textStatus=(status:IStatusBetMe,win:number)=>{
    switch (status) {
      case "WINNER":
        return number.decimalsFormatComma(win)
      case "WAIT":
        return 'รอผล'
      case "CANCEL":
        return 'ยกเลิก'
      case "LOSER":
        return 'ไม่ถูกรางวัล'
      default:
        return status
    }
  }

  renderTableBillBody = (values: IV2RespAgentChildBet[]) => {
    const dataEmpty = (
      <tr>
        <td className="text-center" colSpan={15}>
          {constants.dataNotFound}
        </td>
      </tr>
    )
    const loadingTable= (
      <tr>
        <td className="text-center" colSpan={15}>
        <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
        </td>
      </tr>
    )
    
    const mapData = map(values, (item: IV2RespAgentChildBet, index: number) => {
      // createdAt: format(addYears(date.calibratingTime(item.createdAt),543), "dd MMM yyyy", {locale: th}),
      return (
        <tr
          className={`row-bill-list primary-bg`}
          key={index}
          // onClick={() => this.handleClickUser(data)}
        >
          <td className="text-left pl-3">
              <span>{item.childUsername}</span><br/>
              <span className='subName'>{item.childName}</span>
            </td>
            <td className="text-left">
              <span>{item.customerName}</span>
            </td>
            <td className="text-left">
              <span>{(CODE_TO_NAME[includes(item.name, "YEGEE") ? "YEGEE" : item.name].replace("หวย",'')) + (includes(item.name, "YEGEE") ? ` รอบที่ ${item.name.replace("YEGEE_", '')}` : "")}</span>
            </td>
            <td className="text-left">
              <span>{LOTTO_GAME_TYPE_NAME[item.type]}</span>
            </td>

            <td className="text-center">
              <span>{item.number}</span>
            </td>
            <td className="text-right">
              <span>{number.decimalsFormatComma(item.rate)}</span>
            </td>
            <td className="text-right">
              <span>{number.decimalsFormatComma(item.totalMeBet)}</span>
            </td>
            <td className="text-right">
              <span>{number.decimalsFormatComma(item.totalHostBet)}</span>
            </td>
            <td className="text-right">
              <span>{number.decimalsFormatComma(item.totalBet)}</span>
            </td>
            <td className="text-right">
              <span>{number.decimalsFormatComma(item.totalDis)}</span>
            </td>
            <td className="text-right">
              <span>{number.decimalsFormatComma(item.totalBet - item.totalDis)}</span>
            </td>
            <td className="text-right">
              <span>{number.decimalsFormatComma(item.totalCom)}</span>
            </td>
            <td className="text-center">
              <span className={`${item.status} text-status`}>{this.textStatus(item.status,item.totalBet*item.rate)}</span>
            </td>
            <td className="text-center">
            <span>{date.formatThai(item.createdAt, 'dd MMM yyyy')}</span>
            </td>
            <td className="text-left">
              <span>
                <Dropdown
                  menuPlacement='auto'
                  options={ Object.keys(listActionItem).map((item)=>{return { value: item, label: listActionItem[item], disabled: item==='CancelBill' }}) }
                  iconOpen={point}
                  iconClose={point}
                  isManualOpenMenu
                  controlBackgroundColor={colors.PRIMARY_BG}
                  menuStyles={{width:"175px",left:"-140px"}}
                  valueContainerStyles={{     padding: "0px",
                  maxWidth: "0px",}}
                  handleOnChange={(target: IOptions) => {
                    if (target.value==='CustomerList') {
                      this.props.history.push( routes.customerList.path+transformer.urlSearchParams({customerId:item.customerId}))
                    }
                    else if (target.value==='FinanceHost') {
                      this.props.history.push( routes.financeHost.path+transformer.urlSearchParams({agentShareId:item.agentShareId}))
                    }else if (target.value==='FinanceChild') {
                      this.props.history.push( routes.financeChild.path+`/?agentShareId=${item.agentShareId}`)
                    }else if (target.value==='FinanceCustomer') {
                      this.props.history.push( routes.financeCustomerDetails.path+transformer.urlSearchParams({agentShareId:item.agentShareId}), {rowIndex:index})
                    }
                  }}
                />
              </span>
            </td>

        </tr>
      );
    });
    return this.props.getChildBetIsFetching ? loadingTable : !isEmpty(values) ? mapData : dataEmpty;
  };

  handleClickChangePage = ({ selected: selectedPage }: ISelectPage) => {
    this.setState({ currentPage: { selected: selectedPage } }, () => {
      this.setState({
          page: { ...this.state.page,page: this.state.currentPage.selected + 1 },
        },
        () => {this.fetchGetData()}
      )
    })
  }

  handleClickButtonRadio = (val : string) => {
    if (this.state.selectedOptionSearch===val) {
      this.setState( {
        page: { ...this.state.page,page: 1 },
        selectedOptionSearch: '',
        searchMonths:-1,
        dateStart:null,
        dateEnd:null,
        querySearch: {
          ...this.state.querySearch,
          startTime: "",
          endTime: ""
        }
      }, () => {this.fetchGetData()} )
    }
  }

  handleOnChangeButtonRadio = (val : ISelectedOptionSearch) => {
    let startDate:Date = new Date()
    let endDate:Date = new Date()
    this.setState({dateStart:null, dateEnd:null, searchMonths:-1})
    if (val==="MONTH" || val==="SINCE") {
      this.setState( { selectedOptionSearch: val,page: { ...this.state.page,page: 1 } } )
    }else{
      if (val==="TODAY") {
        startDate = new Date()
        endDate = new Date()
      }else if (val==="YESTERDAY") {
        startDate = new Date(new Date().setDate(new Date().getDate()-1));
        endDate = new Date(new Date().setDate(new Date().getDate()-1));
      }else if (val==="THIS_WEEK") {
        startDate = new Date(startOfWeek(new Date(), {weekStartsOn: 0}));
        endDate = new Date(endOfWeek(new Date(), {weekStartsOn: 0}));
      }else if (val==="LAST_WEEK") {
        startDate = new Date(startOfWeek(new Date(new Date().setDate(new Date().getDate()-7)), {weekStartsOn: 0}));
        endDate = new Date(endOfWeek(new Date(new Date().setDate(new Date().getDate()-7)), {weekStartsOn: 0}));
      }
      this.setState(
        { page: { ...this.state.page,page: 1 },
          selectedOptionSearch: val,
          querySearch: {
            ...this.state.querySearch,
            startTime: format( (startDate), "ddMMyyyy" ),
            endTime: format( (endDate), "ddMMyyyy" )
          }
        }, () => {this.fetchGetData()}
      )
    }
  }

  textPageNow =()=>{
    // จำนวนข้อมูล * หน้าที่เปิด
    let endPage = this.props.getChildBetData.limit*this.state.page.page
    // เลขลำดับท้าย - จำนวนข้อมูล+1
    let startPage = (endPage)-this.props.getChildBetData.limit+1
    let total  = this.props.getChildBetData.totalRecord
    return `${ startPage > total ? total : startPage }-${ endPage > total ? total : endPage } of ${ total }`
  }

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.mainPageTitle, active: false, path: "/dashboard" },
      // { label: constants.betTitle, active: false, path: "/bet" },
      { label: constants.betMeTitle, active: true },
    ];

    const TableHeadBill = this.renderTableHead(mappingTheadLottoฺBill,"ชื่อช่วยจำสมาชิก");
    const TableBodyeBill = this.renderTableBillBody(this.props.getChildBetData.dataList);

    return (
      <div className="paper-container bet-child-history-container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="sub-menu" style={{ width: "fit-content" }}>
              <Breadcrumb
                items={navigates}
                handleOnClickItem={(path: string) => this.props.history.replace(path)}
              />
            </div>
          </div>
        </div>
        <div className="paper-title">{constants.paperTitle}</div>
        <div className="paper-description">{constants.paperDescription}</div>
        <div className="paper-body">
          <div className="box-shadow">
            {/* <h4>{constants.betMeTitle}</h4> */}
            <h5 className='text-bold'>รายการเดิมพัน</h5>
            <div className='option-search my-3'>
              <div className='row'>
                <div className='col-12'>
                  <h6 className='text-bold'>{constants.optionSearch}</h6>
                </div>
              </div>
              <div className='row mt-2 d-flex align-items-center box-search'>
                <div className='box-buttonRadio ml-3'>
                  <ButtonRadio
                    value={this.state.selectedOptionSearch}
                    datas={
                      Object.keys(listOptionSearchRow1).map((item,index)=>{
                        return { id: item, value: item, text: listOptionSearchRow1[item as ISelectedOptionSearch] }
                      })
                    }
                    className="row justify-content-between"
                    classNameRadio='buttonRadio-item px-0'
                    name="listOptionSearch"
                    onClick={this.handleClickButtonRadio}
                    onChange={this.handleOnChangeButtonRadio}
                  />
                </div>
                <div className='col-3 box-search-item'>
                  <div className={`dropdown-box ${this.state.selectedOptionSearch!=="MONTH"&&"disable"}`}>
                    <Dropdown
                      value={this.state.searchMonths===-1?undefined:this.state.searchMonths}
                      options={ (thai_th.months).map((item,index)=>{ return { value: index, label: item[0], disabled: false }}) }
                      controlBackgroundColor={this.state.selectedOptionSearch!=="MONTH"?colors.SECONDARY_BG:colors.PRIMARY_BG}
                      placeholder="ค้นหาเดือน"
                      disable={this.state.selectedOptionSearch!=="MONTH"}
                      menuStyles={{width:"100%"}}
                      handleOnChange={(target: IOptions) => {
                        let startDate = new Date(new Date().getFullYear()-(new Date().getMonth() as any < target.value?1:0), Number(target.value) , 1)
                        let endDate = new Date(new Date().getFullYear()-(new Date().getMonth() as any < target.value?1:0), Number(target.value) + 1, 0)
                        this.setState(
                          { searchMonths: target.value,
                            page: { ...this.state.page,page: 1 },
                            querySearch: {
                              ...this.state.querySearch,
                              startTime: format( (startDate), "ddMMyyyy" ),
                              endTime: format( (endDate), "ddMMyyyy" )
                            }
                          }, () => {this.fetchGetData()}
                        )
                      }}
                    />
                  </div>
                </div>
                <div className='col-3 box-search-item'>
                  <div className='dropdown-box'>
                    <Dropdown
                      value={(this.state.querySearch.name)}
                      valueContainerStyles={{fontSize:"13px"}}
                      iconStyles={{color:colors.TERTIARY_TEXT}}
                      optionStyles={{fontSize:"13px"}}
                      options={ orderBy(Object.keys(listOptionLotto).map((item)=>{return { value: item, label: listOptionLotto[item as TLottoType].replace("หวย",''), disabled: false }}),[(i)=>{return i.value==='All'?0:i.value==='GOVN'?1:2},'label'], ['asc', 'asc']).filter((i)=>i.value!=='HANOI') }
                      controlBackgroundColor={colors.PRIMARY_BG}
                      placeholder={isEmpty(this.state.querySearch.name)?'หวย':listOptionLotto[this.state.querySearch.name as TLottoType]}
                      menuStyles={{width:"100%"}}
                      handleOnChange={(target: IOptions) => {
                        this.setState({
                            page: { ...this.state.page,page: 1 },
                            querySearch:{...this.state.querySearch, name:target.value==="All"?"":target.value}
                          },
                          () => {this.fetchGetData()}
                        )
                      }}
                    />
                  </div>
                </div>
                <div className='col-3 box-search-item'>
                  <Input
                    id="search-number"
                    name="search-number"
                    inputClassName="input-search"
                    placeholder="เลขที่เดิมพัน"
                    backgroundColor={colors.PRIMARY_BG}
                    value={this.state.querySearch.number}
                    onChange={(e) => {
                      if(e.target.value.length <=3 ){
                        this.setState({
                          page: { ...this.state.page,page: 1 },
                          querySearch:{...this.state.querySearch,number:e.target.value.replace(/[^0-9]/g, '')}
                        }, ()=>{
                          clearTimeout(this.queryTimeout!)
                          this.queryTimeout=setTimeout(()=>{
                            this.fetchGetData()
                          },1000)
                        }
                        )
                      }
                    }}
                    renderError={false}
                  />
                </div>
                <div className='col'>
                  <Input
                    id="search-customerId"
                    name="search-customerId"
                    inputClassName="input-search"
                    placeholder="รหัสของชื่อช่วยจำ"
                    backgroundColor={colors.PRIMARY_BG}
                    value={this.state.querySearch.customerId?.toString()}
                    onChange={(e) => {
                      this.setState({
                        page: { ...this.state.page,page: 1 },
                        querySearch:{...this.state.querySearch,customerId:e.target.value}
                      }, ()=>{
                          clearTimeout(this.queryTimeout!)
                          this.queryTimeout=setTimeout(()=>{
                            this.fetchGetData()
                          },1000)
                        }
                      )
                    }}
                    renderError={false}
                  />
                </div>
              </div>
              <div className='row mt-2 d-flex align-items-center box-search'>
                <div className='box-buttonRadio ml-3'>
                  <ButtonRadio
                    value={this.state.selectedOptionSearch}
                    datas={
                      Object.keys(listOptionSearchRow2).map((item,index)=>{
                        return {
                          id: item.toString(),
                          value: item,
                          text: listOptionSearchRow2[item as ISelectedOptionSearch]
                        }
                      })
                    }
                    className="row justify-content-between"
                    classNameRadio='buttonRadio-item px-0'
                    name="listOptionSearch"
                    onClick={this.handleClickButtonRadio}
                    onChange={this.handleOnChangeButtonRadio}
                  />
                </div>
                <div className='col-3 box-search-item'>
                  <DatePickerNew
                    isShowIcon
                    value={this.state.dateStart}
                    placeholder="วันเริ่มต้น"
                    format= "DD MMM YYYY"
                    editable={false}
                    // render={<img src={calendar}  alt="calendar-icon"/>}
                    onChange={(value:any) => {
                      if (value !== null) {
                        let startDate = new Date(Number(value))
                        this.setState(
                          { dateStart: value,
                            page: { ...this.state.page,page: 1 },
                            dateEnd: isNull(this.state.dateEnd)?new Date():new Date(this.state.dateEnd),
                            querySearch: {
                              ...this.state.querySearch,
                              startTime: format( startDate, "ddMMyyyy" ),
                              endTime: format( isNull(this.state.dateEnd)?new Date():new Date(this.state.dateEnd), "ddMMyyyy" )
                            }
                          }, () => {this.fetchGetData()}
                        )
                      }
                    }}
                    // plugins={[<TimePicker hideSeconds />]}
                    // errorMessage={t(get(formik, "errors.publishedDate", "global.notError")!)}
                    disabled={this.state.selectedOptionSearch!=="SINCE"}
                  />
                </div>
                <div className='col-3 box-search-item'>
                  <DatePickerNew
                    isShowIcon
                    value={this.state.dateEnd}
                    placeholder="วันสิ้นสุด"
                    format= "DD MMM YYYY"
                    editable={false}
                    onChange={(value:any) => {
                      if (value !== null) {
                        let startDate = new Date(Number(value))
                        this.setState(
                          { dateEnd: value,
                            page: { ...this.state.page,page: 1 },
                            querySearch: {
                              ...this.state.querySearch,
                              startTime: format( isNull(this.state.dateStart)?new Date():new Date(this.state.dateStart), "ddMMyyyy" ),
                              endTime: format( startDate, "ddMMyyyy" ),
                            }
                          }, () => {this.fetchGetData()}
                        )
                      }
                    }}
                    // plugins={[<TimePicker hideSeconds />]}
                    // errorMessage={t(get(formik, "errors.publishedDate", "global.notError")!)}
                    disabled={this.state.selectedOptionSearch!=="SINCE" || isNull(this.state.dateStart)}
                  />
                </div>
                <div className='col-3 box-search-item'>
                  <div className='dropdown-box'>
                  <Dropdown
                    value={(this.state.querySearch.status)}
                    valueContainerStyles={{fontSize:"13px"}}
                    iconStyles={{color:colors.TERTIARY_TEXT}}
                    optionStyles={{fontSize:"13px"}}
                    options={ Object.keys(listOptionStatus).map((item)=>{return { value: item, label: listOptionStatus[item as IStatusBetMe|"All"], disabled: false }}) }
                    controlBackgroundColor={colors.PRIMARY_BG}
                    placeholder={isEmpty(this.state.querySearch.status)?'ผลการเดิมพัน':listOptionStatus[this.state.querySearch.status as IStatusBetMe|"All"]}
                    menuStyles={{width:"100%"}}
                    handleOnChange={(target: IOptions) => {
                      this.setState({
                        page: { ...this.state.page,page: 1 },
                        querySearch:{...this.state.querySearch,status:target.value==="All"?"":target.value}
                      }, () => {this.fetchGetData()} )
                    }}
                  />
                  </div>
                </div>
                <div className='col'>
                  <Input
                    id="search-customerName"
                    name="search-customerName"
                    inputClassName="input-search"
                    placeholder="ชื่อช่วยจำ"
                    backgroundColor={colors.PRIMARY_BG}
                    value={this.state.querySearch.customerName}
                    onChange={(e) => {
                      this.setState({
                        page: { ...this.state.page,page: 1 },
                        querySearch:{...this.state.querySearch,customerName:e.target.value}
                      }, ()=>{
                          clearTimeout(this.queryTimeout!)
                          this.queryTimeout=setTimeout(()=>{
                            this.fetchGetData()
                          },1000)
                        }
                      )
                    }}
                    renderError={false}
                  />
                </div>
              </div>
              <div className='row mt-2 d-flex align-items-center box-search'>
                <div className='col pr-2'>
                  <Input
                    id="search-childUsername"
                    name="search-childUsername"
                    inputClassName="input-search"
                    placeholder="ชื่อยูสสมาชิก"
                    backgroundColor={colors.PRIMARY_BG}
                    value={this.state.querySearch.childUsername}
                    onChange={(e) => {
                      this.setState({
                        page: { ...this.state.page,page: 1 },
                        querySearch:{...this.state.querySearch,childUsername:e.target.value}
                      }, ()=>{
                          clearTimeout(this.queryTimeout!)
                          this.queryTimeout=setTimeout(()=>{
                            this.fetchGetData()
                          },1000)
                        }
                      )
                    }}
                    renderError={false}
                  />
                </div>
                <div className='col pl-2'>
                  <Input
                    id="search-childName"
                    name="search-childName"
                    inputClassName="input-search"
                    placeholder="ชื่อช่วยจำสมาชิก"
                    backgroundColor={colors.PRIMARY_BG}
                    value={this.state.querySearch.childName}
                    onChange={(e) => {
                      this.setState({
                        page: { ...this.state.page,page: 1 },
                        querySearch:{...this.state.querySearch,childName:e.target.value}
                      }, ()=>{
                          clearTimeout(this.queryTimeout!)
                          this.queryTimeout=setTimeout(()=>{
                            this.fetchGetData()
                          },1000)
                        }
                      )
                    }}
                    renderError={false}
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
              <div className='lotto-bill'>
                <div className="table-container">
                <div className="table-responsive-xl">
                  <table className="w-100">
                      <thead className='header-table-lotto-bill'>
                        <tr>{TableHeadBill}</tr>
                      </thead>
                      <tbody>{TableBodyeBill}</tbody>
                  </table>
                </div>
                </div>
              </div>
              </div>
            </div>
            <div className='row mt-2'>
              <div className="col d-flex justify-content-end align-items-center">
                <h6 className='text-bold secondary-text'>{constants.rowPerPage}</h6>
                <div className="dropdown-rowPerPage">
                  <Dropdown
                    menuPlacement={"top"}
                    options={ [5,10,25,50,100].map((item)=>{return { value: item, label: item.toString(), disabled: false }}) }
                    controlBackgroundColor={colors.PRIMARY_BG}
                    placeholder={this.props.getChildBetData.limit.toString()}
                    menuStyles={{width:"100%"}}
                    handleOnChange={(target: IOptions) => {
                      this.setState({
                        page: {...this.state.page, limit:target.value, page: 1}
                      }, () => {this.fetchGetData()} )
                    }}
                  />
                </div>
              </div>
              <h6 className='d-flex justify-content-end align-items-center'>
                {this.textPageNow()}
              </h6>
              <div className='col-auto d-flex align-items-center'>
                <Panginate
                  pageTotal={this.props.getChildBetData.total}
                  initialPage={get(this.state.page,'page',0) - 1}
                  pageRangeDisplayed={0}
                  onPageChange={this.handleClickChangePage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
