import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import {
  getPlayedYegeeListAction,
  listenPlayedYeegeListSocketAction,
  unlistenPlayedYeegeListSocketAction,
  updatePlayedYegeeListAction,
  clearPlayedYegeeListAction,
} from "../actionCreator";
import { fetchGetPlayedYegeeList } from "../services";

const getPlayedYeegeList = (query: ILottoRoundQuery) => (
  dispatch: Dispatch
) => {
  dispatch(getPlayedYegeeListAction.request({}));
  return fetchGetPlayedYegeeList(query)
    .then((res: AxiosResponse<IAPIResponse<IYegeePlay[]>>) => {
      dispatch(getPlayedYegeeListAction.success(res));
    })
    .catch((error: AxiosError<IAPIResponse>) => {
      dispatch(getPlayedYegeeListAction.failure(error));
    });
};

const updatePlayedYegeeList = (res: IAPIResponse<IYegeePlay[]>) => (
  dispatch: Dispatch
) => {
  dispatch(updatePlayedYegeeListAction.request({}));
  dispatch(updatePlayedYegeeListAction.success(res));
};

const listenPlayedYeegeListSocket = (query: ILottoRoundQuery) => (
  dispatch: Dispatch
) => {
  dispatch(listenPlayedYeegeListSocketAction(query));
};

const unlistenPlayedYeegeListSocket = (query: ILottoRoundQuery) => (
  dispatch: Dispatch
) => {
  dispatch(unlistenPlayedYeegeListSocketAction(query));
};

const clearPlayedYeegeList = () => (dispatch: Dispatch) => {
  dispatch(clearPlayedYegeeListAction({}));
};

export {
    getPlayedYeegeList,
    updatePlayedYegeeList,
    listenPlayedYeegeListSocket,
    unlistenPlayedYeegeListSocket,
    clearPlayedYeegeList
}