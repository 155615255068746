import { connect } from "react-redux";
import NumbersHolesManage from "./NumbersHolesManage.container";
import { onChangeIsLoaddingAction } from "../../reduxs/Loader/action"

const mapStateToProps =(state: RootReducers):INumberHolesManageContainerProps=>{
    return {}
}
const mapDispatchToProps:INumberHolesManageContainerActionProps = {
    onChangeIsLoaddingAction: onChangeIsLoaddingAction,
}


export default connect(mapStateToProps,mapDispatchToProps)(NumbersHolesManage)