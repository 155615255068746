export const GET_ME_SETTING_REQUEST = "GET_ME_SETTING_REQUEST";
export const GET_ME_SETTING_SUCCESS = "GET_ME_SETTING_SUCCESS";
export const GET_ME_SETTING_FAILURE = "GET_ME_SETTING_FAILURE";

export const GET_ME_REQUEST = "GET_ME_REQUEST";
export const GET_ME_SUCCESS = "GET_ME_SUCCESS";
export const GET_ME_FAILURE = "GET_ME_FAILURE";

export const GET_ME_SETTING_LOTTERY_REQUEST = 'GET_ME_SETTING_LOTTERY_REQUEST';
export const GET_ME_SETTING_LOTTERY_SUCCESS = 'GET_ME_SETTING_LOTTERY_SUCCESS';
export const GET_ME_SETTING_LOTTERY_FAILURE = 'GET_ME_SETTING_LOTTERY_FAILURE';

export const meSettingState: ReducerState<IV2GetAgentMeSetting> = {
  isFetching: false,
  code: 0,
  data: {
    agentId: 0,
    setBetMaxLimit: 100,
    setBetMinLimit: 1,
    setComPercentage: 0,
  },
  error: "",
};

export const meState: ReducerState<IV2GetAgentMe> = {
  isFetching: false,
  code: 0,
  data: {
    agentId: 0,
    agentStatus: "",
    bankName: "",
    bankNumber: "",
    bankType: "PleaseChoose",
    betCountTotal: 0,
    betTotal: 0,
    childTotal: 0,
    createdAt: "",
    credit: 0,
    creditLimit: 0,
    creditShareLimit: 0,
    currentBetCountTotal: 0,
    currentBetTotal: 0,
    isAccess: false,
    lossBenefitTotal: 0,
    lossTotal: 0,
    name: "",
    permission: "",
    phoneNumber: "",
    profitBenefitTotal: 0,
    profitTotal: 0,
    updatedAt: "",
    username: "",
  },
  error: "",
}

export const meSettingLotteryState: ReducerState<IV2AgentMeSettingLottery> = {
  isFetching: false,
  code: 0,
  data: {
    agentId: 0,
    lotterySetting: []
  },
  error: ""
}