import { get } from "lodash";
import {
  historyState,
  makeState,
  resultState,
  historySummaryDetailState,
  historySummaryState,
  betGroupState,
  getBetState,
} from "./constants";

const make = (state: RootReducers): ReducerState<any> => {
  return get(state, "lotto.bet.make", makeState);
};

const history = (
  state: RootReducers
): ReducerState<APIPagination<IResponseAgentBetLotteryHistoryList>> =>
  get(state, "lotto.bet.history", historyState);

const historySummary = (
  state: RootReducers
): ReducerState<APIPagination<IResponseAgentQueryLotteryHistorySummary>> =>
  get(state, "lotto.bet.historySummary", historySummaryState);

const result = (
  state: RootReducers
): ReducerState<IResponseLudensBetResult[]> =>
  get(state, "lotto.bet.result", resultState);

const historySummaryDetail = (
  state: RootReducers
): ReducerState<IResponseAgentQueryLotteryHistorySummaryDetail> =>
  get(state, "lotto.bet.historySummaryDetail", historySummaryDetailState);

const betGroup = (
  state: RootReducers
): ReducerState<APIPagination<IV2RespAgentLotteryBetGroup>> =>
  get(state, "lotto.bet.betGroup", betGroupState);

const postAgentBet = (
  state: RootReducers
): ReducerState<IV2RespAgentLotteryBet> =>
  get(state, "lotto.bet.postV2AgentBet", betGroupState) as any;

const getAgentBet = (
  state: RootReducers
): ReducerState<APIPagination<IV2RespAgentLotteryBetMe>> =>
  get(state, "lotto.bet.getV2AgentBet", getBetState);

const getV2Result = (
  state: RootReducers
): ReducerState<IPagination<IV2RespAgentLotteryResult>> =>
  get(state, "lotto.bet.resultV2", resultState) as any;

const getBetExtraction = (
  state: RootReducers
): ReducerState<IV3ExtractionGroupingLottery[]> =>
  get(state, "lotto.bet.getV3BetExtraction", resultState) as any;

const getBetExtractionDetail = (
  state: RootReducers
): ReducerState<IV3RespGetDetailExtractionAgentBetLottery[]> =>
  get(state, "lotto.bet.getV3BetExtractionDetail", resultState) as any;




const bet = {
  historySummaryDetail,
  historySummary,
  history,
  result,
  make,
  betGroup,
  postAgentBet,
  getAgentBet,
  getV2Result,
  getBetExtraction,
  getBetExtractionDetail
};

export default bet;
