export const GET_FINANCE_CUSTOMER_REQUEST = "GET_FINANCE_CUSTOMER_REQUEST"
export const GET_FINANCE_CUSTOMER_SUCCESS = "GET_FINANCE_CUSTOMER_SUCCESS"
export const GET_FINANCE_CUSTOMER_FAILURE = "GET_FINANCE_CUSTOMER_FAILURE"
export const GET_FINANCE_CUSTOMER_CLEAR = "GET_FINANCE_CUSTOMER_CLEAR"
export const GET_FINANCE_CUSTOMER_TX_REQUEST = "GET_FINANCE_CUSTOMER_TX_REQUEST"
export const GET_FINANCE_CUSTOMER_TX_SUCCESS = "GET_FINANCE_CUSTOMER_TX_SUCCESS"
export const GET_FINANCE_CUSTOMER_TX_FAILURE = "GET_FINANCE_CUSTOMER_TX_FAILURE"

export const POST_FINANCE_CUSTOMER_TX_REQUEST = "POST_FINANCE_CUSTOMER_TX_REQUEST"
export const POST_FINANCE_CUSTOMER_TX_SUCCESS = "POST_FINANCE_CUSTOMER_TX_SUCCESS"
export const POST_FINANCE_CUSTOMER_TX_FAILURE = "POST_FINANCE_CUSTOMER_TX_FAILURE"
export const POST_FINANCE_CUSTOMER_TX_CLEAR = "POST_FINANCE_CUSTOMER_TX_CLEAR"

export const GET_FINANCE_CHILD_REQUEST = "GET_FINANCE_CHILD_REQUEST"
export const GET_FINANCE_CHILD_SUCCESS = "GET_FINANCE_CHILD_SUCCESS"
export const GET_FINANCE_CHILD_FAILURE = "GET_FINANCE_CHILD_FAILURE"

export const GET_FINANCE_CHILD_ME_REQUEST = "GET_FINANCE_CHILD_ME_REQUEST"
export const GET_FINANCE_CHILD_ME_SUCCESS = "GET_FINANCE_CHILD_ME_SUCCESS"
export const GET_FINANCE_CHILD_ME_FAILURE = "GET_FINANCE_CHILD_ME_FAILURE"

export const GET_FINANCE_HOST_ME_REQUEST = "GET_FINANCE_HOST_ME_REQUEST";
export const GET_FINANCE_HOST_ME_SUCCESS = "GET_FINANCE_HOST_ME_SUCCESS";
export const GET_FINANCE_HOST_ME_FAILURE = "GET_FINANCE_HOST_ME_FAILURE";

export const POST_FINANCE_CHILD_APPROVE_REQUEST = "POST_FINANCE_CHILD_APPROVE_REQUEST"
export const POST_FINANCE_CHILD_APPROVE_SUCCESS = "POST_FINANCE_CHILD_APPROVE_SUCCESS"
export const POST_FINANCE_CHILD_APPROVE_FAILURE = "POST_FINANCE_CHILD_APPROVE_FAILURE"

export const POST_FINANCE_CHILD_REQUEST_REQUEST = "POST_FINANCE_CHILD_REQUEST_REQUEST"
export const POST_FINANCE_CHILD_REQUEST_SUCCESS = "POST_FINANCE_CHILD_REQUEST_SUCCESS"
export const POST_FINANCE_CHILD_REQUEST_FAILURE = "POST_FINANCE_CHILD_REQUEST_FAILURE"

export const   POST_FINANCE_HOST_REQUEST = "POST_FINANCE_HOST_REQUEST"
export const   POST_FINANCE_HOST_SUCCESS = "POST_FINANCE_HOST_SUCCESS"
export const   POST_FINANCE_HOST_FAILURE = "POST_FINANCE_HOST_FAILURE"


export const financeCustomerState: ReducerState<
  IPagination<IV2RespAgentFinanceCustomer>
> = {
  isFetching: false,
  code: 0,
  data: {
    totalRecord: 0,
    page: 1,
    limit: 10,
    total: 0,
    dataList: [],
  },
  error: "",
};

export const financeCustomerTxState: ReducerState<IPagination<IV2RespAgentFinanceCustomerTx>> = {
  isFetching: false,
  code: 0,
  data: {
      totalRecord:0,
      page: 1,
      limit: 10,
      total: 0,
      dataList: []
  },
  error: ''
}

export const financeChildState: ReducerState< IPagination<IV2RespAgentFinanceChild>> = {
  isFetching: false,
  code: 0,
  data: {
    page: 1,
    limit: 10,
    total: 0,
    dataList: [],
    totalRecord: 0,
  },
  error: "",
};

export const financeChildMeState: ReducerfinanceChildState<
  IPagination<IV2RespAgentFinanceChildMe>
> = {
  isFetching: false,
  code: 0,
  data: {
    page: 1,
    limit: 10,
    total: 0,
    dataList: [],
    totalRecord: 0,
  },
  error: "",
  dataReseive: {
    page: 1,
    limit: 10,
    total: 0,
    dataList: [],
    totalRecord: 0,
  }
};

export const financeHostMeState: ReducerfinanceChildState<
  IPagination<IV2RespAgentFinanceHostMe>
> = {
  isFetching: false,
  code: 0,
  data: {
    page: 1,
    limit: 10,
    total: 0,
    dataList: [],
    totalRecord: 0,
  },
  error: "",
  dataReseive: {
    page: 1,
    limit: 10,
    total: 0,
    dataList: [],
    totalRecord: 0,
  }
};

export const financeCustomerTxResState: ReducerState<IAPIResponse<object>> = {
  isFetching: false,
  code: 0,
  data: {
      data: {},
      code: 0,
      devMessage: ""
  },
  error: ''
}

export const financeChildApproveState: ReducerState<IV2RespAgentFinanceChildApprove> = {
  isFetching: false,
  code: 0,
  data: {
      agentPayTxId: 0,
  },
  error: ''
}

export const financeChildRequestState: ReducerState<IV2RespAgentFinanceChildRequest> = {
  isFetching: false,
  code: 0,
  data: {
    agentPayTxId: 0,
  },
  error: ''
}

export const financeHostState: ReducerState<
  IPagination<IV2RespAgentFinanceHost>
> = {
  isFetching: false,
  code: 0,
  data: {
    totalRecord: 0,
    page: 1,
    limit: 10,
    total: 0,
    dataList: [],
  },
  error: "",
};
