import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { fetPostPayAccept } from "../services";
import { payAcceptAction } from "../actionCreator";
import { loaderAction } from "../../Loader/actionCreator";

const postPayAccept = (body: IAgentPayAccept) => async (
  dispatch: Dispatch
) => {
  dispatch(loaderAction.success(true));
  dispatch(payAcceptAction.request({}));
  await fetPostPayAccept(body)
    .then((response: AxiosResponse<IAPIResponse<IAgentPayAccept>>) => {
      dispatch(loaderAction.success(false));
      return dispatch(payAcceptAction.success(response));
    })
    .catch((error: AxiosError<APIResponse>) => {
      dispatch(loaderAction.success(false));
      return dispatch(payAcceptAction.failure(error));
    });
};

export { postPayAccept };
