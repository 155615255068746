import { get, includes, isNil, map, noop } from "lodash"
import { FC, useState } from "react"
import colors from "constants/colors"
import { number } from "utils"
import { Badge, CheckBox, ResponsiveIcon } from "components"
import { CODE_TO_NAME, LEVEL_TYPE_CHANGE_WORD, LOTTO_FLAG_ALPHA } from "constants/variables"
import LottoFlags from "assets/images/global/flags"
import React from "react"
import color from "constants/colors"


declare interface ITransactionItemCollapsible {
  lottoBet: IV3LotteryBetAgentLotteryList
  lotteryMemberList: IV3RespGetLotteryBetMember
  userName: string
  loading: boolean
  onClick?(): void
  action?(lottoName: string): void
  handleOnClickChild(childAgentId: number, lotteryName: string, memberUsername: string, memberPermission: string): void
}

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: ITransactionItemCollapsible = {
  lottoBet: {
    lotteryName: '',
    memberTotalBet: 0,
    memberSend: 0,
    memberCom: 0,
    memberWin: 0,
    memberBalance: 0,
    meShare: 0,
    meCom: 0,
    meBalance: 0,
    meShareWin: 0,
    hostShare: 0,
    hostShareWin: 0,
    hostCom: 0,
    hostBalance: 0,
  },
  lotteryMemberList: { memberList: [] },
  userName: '',
  loading: false,
  action(lottoName: string) { noop() },
  onClick() { noop() },
  handleOnClickChild(childAgentId: number, lotteryName: string, memberUsername: string, memberPermission: string) { noop() }
}

const TableCollapsible: FC<ITransactionItemCollapsible & DefaultProps> = (props) => {
  const [isExpand, setExpand] = useState<boolean>(false)
  const [isShowName, setIsShowName] = useState(false)
  const { lottoBet, lotteryMemberList, userName, action, handleOnClickChild } = props

  const getColor = (totalResult: number) => {
    return isNil(totalResult) ? '' : totalResult > 0 ? colors.DARK_GREEN_TEXT : totalResult < 0 ? colors.PRIMARY_RED : ''
  }

  const TableFooterMember = () => {
    const result: any = lotteryMemberList.memberList.reduce((accumulator: any, currentItem: any) => {
      return {
        memberTotalBet: accumulator.memberTotalBet + currentItem.memberTotalBet,
        memberSend: accumulator.memberSend + currentItem.memberSend,
        memberCom: accumulator.memberCom + currentItem.memberCom,
        memberBalance: accumulator.memberBalance + currentItem.memberBalance,
        meShare: accumulator.meShare + currentItem.meShare,
        meCom: accumulator.meCom + currentItem.meCom,
        meBalance: accumulator.meBalance + currentItem.meBalance,
        hostShare: accumulator.hostShare + currentItem.hostShare,
        hostCom: accumulator.hostCom + currentItem.hostCom,
        hostBalance: accumulator.hostBalance + currentItem.hostBalance
      };
    }, {
      memberTotalBet: 0,
      memberSend: 0,
      memberCom: 0,
      memberBalance: 0,
      meShare: 0,
      meCom: 0,
      meBalance: 0,
      hostShare: 0,
      hostCom: 0,
      hostBalance: 0
    })
    const totalSum: any = {}

    for (const key in result) {
      totalSum[key] = number.decimalsFormatComma(result[key].toFixed(2))
    }
    return <tr className="text-center">
      <th className="header-table" colSpan={2}>รวม</th>
      <td style={{ color: colors.DARK_GREEN_TEXT }}>{totalSum.memberTotalBet}</td>
      <td style={{ color: getColor(parseFloat(totalSum.memberSend.replace(/,/g, ""))) }}>{totalSum.memberSend}</td>
      <td style={{ color: getColor(parseFloat(totalSum.memberCom.replace(/,/g, ""))) }}>{totalSum.memberCom}</td>
      <td style={{ color: getColor(parseFloat(totalSum.memberBalance.replace(/,/g, ""))) }}>{totalSum.memberBalance}</td>
      <td style={{ color: getColor(parseFloat(totalSum.meShare.replace(/,/g, ""))) }}>{totalSum.meShare}</td>
      <td style={{ color: getColor(parseFloat(totalSum.meCom.replace(/,/g, ""))) }}>{totalSum.meCom}</td>
      <td style={{ color: getColor(parseFloat(totalSum.meBalance.replace(/,/g, ""))) }}>{totalSum.meBalance}</td>
      <td style={{ color: getColor(parseFloat(totalSum.hostShare.replace(/,/g, ""))) }}>{totalSum.hostShare}</td>
      <td style={{ color: getColor(parseFloat(totalSum.hostCom.replace(/,/g, ""))) }}>{totalSum.hostCom}</td>
      <td style={{ color: getColor(parseFloat(totalSum.hostBalance.replace(/,/g, ""))) }}>{totalSum.hostBalance}</td>
    </tr>
  }
  const TableHeaderMember = () => {
    return (
      <>
        <tr className="text-center">
          <th rowSpan={2} style={{ width: '250px' }}>ชื่อเข้าใช้งาน
            <span>
              <div className="d-flex justify-content-center align-items-center">
                <CheckBox
                  className={'size-box'}
                  id={`${lottoBet.lotteryName}`}
                  value={isShowName}
                  onClick={() => {
                    setIsShowName(!isShowName)
                  }}
                />
                แสดงชื่อ
              </div>
            </span>
          </th>
          <th rowSpan={2}>ระดับ</th>
          <th rowSpan={2}>ยอดทั้งหมด</th>
          <th colSpan={3}>สมาชิก</th>
          <th colSpan={3}>{userName}</th>
          <th colSpan={3}>บริษัท</th>
        </tr>
        <tr className="text-center">
          <th >ส่งออก</th>
          <th>คอม</th>
          <th>รวม</th>
          <th>ถือหุ้น</th>
          <th>คอม</th>
          <th>รวม</th>
          <th>ถือหุ้น</th>
          <th>คอม</th>
          <th>รวม</th>
        </tr>
      </>
    )
  }

  const TableItemCollapsible = (lottoBetName: string) => {
    const mapData = map(lotteryMemberList.memberList, (item: IV3LotteryBetMemberList, index: number) => {

      return <tr className="row-item-hover" key={`${item.memberUsername} ${index}`}>
        <td className='text-left item-collapsible' onClick={() => handleOnClickChild(item.memberId, lottoBetName, item.memberUsername, item.memberPermission)}>
          <div className="d-flex align-items-center">
            {item.memberUsername}
            {isShowName &&
              <span className={'d-flex ml-1 body-4 gray-text font-weight-bold'}>
                {'('}
                <div className="ellipsis-text">{`${item.memberName}`}</div>
                {')'}
              </span>}
          </div>
        </td>
        <td className='text-center'>
          {
            <div className="d-flex justify-content-center">
              <Badge
                boxShadow={'0 2px 4px rgba(0, 0, 0, 0.2)'}
                backgroundColor={color.PRIMARY_GREEN}
                text={LEVEL_TYPE_CHANGE_WORD[item.memberPermission as TLevelType]}
                customBadgeContainerClass="host-keep-true-badge-container"
                textColor={color.PRIMARY_BG}
                customTextClass="host-keep-true-badge-text"
              />
            </div>
          }
        </td>
        <td style={{ color: colors.DARK_GREEN_TEXT }}>{number.decimalsFormatComma(item.memberTotalBet)}</td>
        <td style={{ color: getColor(item.memberSend) }}>{number.decimalsFormatComma(item.memberSend)}</td>
        <td style={{ color: getColor(item.memberCom) }}>{number.decimalsFormatComma(item.memberCom)}</td>
        <td style={{ color: getColor(item.memberBalance) }}>{number.decimalsFormatComma(item.memberBalance)}</td>
        <td style={{ color: getColor(item.meShare) }}>{number.decimalsFormatComma(item.meShare)}</td>
        <td style={{ color: getColor(item.meCom) }}>{number.decimalsFormatComma(item.meCom)}</td>
        <td style={{ color: getColor(item.meBalance) }}>{number.decimalsFormatComma(item.meBalance)}</td>
        <td style={{ color: getColor(item.hostShare) }}>{number.decimalsFormatComma(item.hostShare)}</td>
        <td style={{ color: getColor(item.hostCom) }}>{number.decimalsFormatComma(item.hostCom)}</td>
        <td style={{ color: getColor(item.hostBalance) }}>{number.decimalsFormatComma(item.hostBalance)}</td>
      </tr>
    })
    return (
      <th key={userName} colSpan={12}>
        <table className="w-100 box-shadow-border">
          <thead className='header-table table-bordered'>
            <TableHeaderMember />
            {mapData}
            <TableFooterMember />
          </thead>
        </table>
      </th>
    )
  }

  const slug = lottoBet.lotteryName as TLottoSlug
  const textNameLotto = includes(lottoBet.lotteryName, "YEGEE")
    ? `ยี่กี รอบที่ ${Number(lottoBet.lotteryName.replace("YEGEE_", ''))}`
    : CODE_TO_NAME[lottoBet.lotteryName as TLottoType]

  const handleClick = () => {
    setExpand(!isExpand)
    if(!isExpand){
      action!(lottoBet.lotteryName)
    }
  }
  return (
    <React.Fragment>
      <tr className="row-item-hover">
        <td className='text-center'>
          <div className="box-icon" onClick={handleClick}>
            <ResponsiveIcon
              icon={
                get(
                  LottoFlags[
                  LOTTO_FLAG_ALPHA[
                  includes(lottoBet.lotteryName, "YEGEE")
                    ? "YEGEE"
                    : (lottoBet.lotteryName as TLottoType)
                  ]
                  ],
                  "Icon",
                  ""
                )!
              }
              alt="flag"
              className={`lotto-action-card-flag ${slug}`}
            />
          </div>
        </td>
        <td className='text-left item-collapsible' onClick={handleClick}>{textNameLotto}</td>
        <td style={{ color: colors.DARK_GREEN_TEXT }}>{number.decimalsFormatComma(lottoBet.memberTotalBet)}</td>
        <td style={{ color: getColor(lottoBet.memberSend) }}>{number.decimalsFormatComma(lottoBet.memberSend)}</td>
        <td style={{ color: getColor(lottoBet.memberCom) }}>{number.decimalsFormatComma(lottoBet.memberCom)}</td>
        <td style={{ color: getColor(lottoBet.memberBalance) }}>{number.decimalsFormatComma(lottoBet.memberBalance)}</td>
        <td style={{ color: getColor(lottoBet.meShare) }}>{number.decimalsFormatComma(lottoBet.meShare)}</td>
        <td style={{ color: getColor(lottoBet.meCom) }}>{number.decimalsFormatComma(lottoBet.meCom)}</td>
        <td style={{ color: getColor(lottoBet.meBalance) }}>{number.decimalsFormatComma(lottoBet.meBalance)}</td>
        <td style={{ color: getColor(lottoBet.hostShare) }}>{number.decimalsFormatComma(lottoBet.hostShare)}</td>
        <td style={{ color: getColor(lottoBet.hostCom) }}>{number.decimalsFormatComma(lottoBet.hostCom)}</td>
        <td style={{ color: getColor(lottoBet.hostBalance) }}>{number.decimalsFormatComma(lottoBet.hostBalance)}</td>
      </tr>
      {isExpand && TableItemCollapsible(lottoBet.lotteryName)}
    </React.Fragment>
  )

}

TableCollapsible.defaultProps = defaultProps
export default TableCollapsible