import React, { SFC } from "react";
import { noop } from "lodash";
import "./aLink.style.scss";
import colors from "constants/colors";

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: IALink = {
  id: "",
  fontSize: 14,
  bold: false,
  color: colors.PRIMARY_GREEN,
  disabled: false,
  underline: false,
  onClick() {
    noop();
  },
  path: "",
};

const ALink: SFC<IALink & DefaultProps> = (props) => {
  const {
    id,
    bold,
    color,
    onClick,
    fontSize,
    disabled,
    underline,
    children,
    path,
  } = props;
  const disabledClass = disabled ? "disabled-alink" : "";
  const underlineClass = underline ? "underline" : "";
  const pathClass = path ? "path" : "";
  const wrapperClass = bold
    ? `button-link-text-bold ${disabledClass} ${underlineClass}`
    : `button-link-text ${disabledClass} ${underlineClass}`;
  const style = {
    color: disabled ? colors.PRIMARY_GREEN : color,
    fontSize,
  };

  const handleOnClick = () => (disabled ? onClick!() : onClick!());

  return (
    <span
      id={id}
      onClick={handleOnClick}
      className={wrapperClass + pathClass}
      style={style}
    >
      {children}
    </span>
  );
};

ALink.defaultProps = defaultProps;

export default ALink;
