export const CANCEL_REQUEST = 'CANCEL_REQUEST'
export const CANCEL_SUCCESS = 'CANCEL_SUCCESS'
export const CANCEL_FAILURE = 'CANCEL_FAILURE'

export const cancelBillState : ReducerState<IV2PostAgentLotteryRefund>={
    isFetching:false,
    code:0,
    data:{
        agentShareId:0,
        customerId:0,
        name:"",
    },
    error:"",

}