import { Breadcrumb, Dropdown, ErrorModal, Input, ModalPrintBill, Panginate,CancelBetModal,SuccessModal, LoadingCircle } from 'components';
import { CODE_TO_NAME, meDataInitialValue } from 'constants/variables';
import { addSeconds, format } from 'date-fns';
import {
  get,
  isEmpty,
  map,
  noop,
  orderBy,
} from 'lodash';
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router';
import { date, number,/*  responseConverter, */ transformer } from 'utils';
import point from "assets/images/global/icon/point.png";
// import pdfMake from "pdfmake/build/pdfmake";
// import docDefinition from "../NewBetMaking/docDefinition";
import { responseCode, responseMessage } from 'constants/response';
import './betSumHistory.style.scss'
import colors from 'constants/colors';
// import { getV2AgentBet } from 'reduxs/lotto/bet/services';
// import { AxiosError, AxiosResponse } from 'axios';
// import { responseMessage } from 'constants/response';
import CryptoJS from "crypto-js";
import routes from 'constants/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const listBillStatus: { [type in IStatusBill|"All"]: string } = {
  All:"ทั้งหมด",
  WAIT:"ยังไม่เคลียร์",
  CONDITION:"ทำรายการค้างไว้",
  SUCCESS:"เคลียร์",
}

const listActionItem : {[type in string] : string} = {
  BillPrint:"ออกใบเสร็จ",  //ออกบิล
  BetMeHistory:"รายละเอียดการเดิมพัน",  // ไปหน้า รายละเอียดการเดิมพัน
  CustomerList:"ข้อมูลลูกค้า",   //ไปหน้าข้อมูลลูกค้า
  FinanceHost:"เคลียร์ยอดหัวสมาชิก",  //ไปหน้า
  FinanceChild:"เคลียร์ยอดลูกสมาชิก",  //ไปหน้า
  FinanceCustomer:"เคลียร์ยอดลูกค้า",  //ไปหน้า
  CancelBill:"ยกเลิกโพย",
}

const listOptionLotto : { [type in TLottoType|"All"]: string } = {...{All:"ทั้งหมด"},...CODE_TO_NAME}

type DefaultProps = Readonly<typeof defaultProps>;
const constants = {
   mainPageTitle:"ภาพรวม",
  //  betTitle:"การเดิมพัน",
   betMeTitle:"สรุปการเดิมพัน",

   paperTitle:"สรุปการเดิมพัน",
   paperDescription: "จัดกลุ่มการเดิมพันตามชื่อช่วยจำ",

   dataNotFound: "ไม่พบข้อมูล...",

   rowPerPage:"จำนวนรายการต่อหน้า : ",
   optionSearch:"ตัวเลือกการค้นหา",
   cancleBet:"ยืนยันยกเลิกโพย",
   confirmCancleBet: "ข้อมูลการเดิมพันของลูกค้าในงวดดังกล่าวจะถูกยกเลิกด้วยทั้งหมด คุณแน่ใจที่จะยกเลิกโพยหรือไม่",
   titlePooiSuccess: 'สำเร็จ',
  cancelPooiSuccess: "ยกเลิกโพยสำเร็จ",
};
 
const mappingTheadLottoฺBill: IHeaderLottoTable[] = [
   { id: 1, name: "เลขบิล" },
   { id: 2, name: "ชื่อช่วยจำ" },
   { id: 3, name: "ถือสู้ทั้งหมด" },
   { id: 4, name: "แบ่งหุ้นทั้งหมด" },
   { id: 5, name: "ยอดเดิมพันทั้งหมด" },
   { id: 6, name: "คอมทั้งหมด" },
   { id: 7, name: "ส่วนลดทั้งหมด" },
   { id: 8, name: "จำนวนรายการ" },
   { id: 9, name: "ยอดรับ/จ่าย" },
   { id: 10, name: "เวลา" },
   { id: 11, name: "" },
];

const defaultProps: IBetSumHistoryContainerProps & IBetSumHistoryContainerAction = {
   onChangeIsLoaddingAction() { noop() },
   getAgentBet() { noop() },
   getGetBetGroup() { noop() },
   getDataV2LotteryList() { noop() },
   postCancelBill(){noop()},
   getMe(){noop()},
   meData: meDataInitialValue,
   getAgentData : {
      page: 1,
      limit: 10,
      total: 0,
      totalRecord: 0,
      dataList: [],
   },
   getAgentIsFetching : false,
   getAgentCode : 0,
   getAgentError : "",

   betGroupV2Data: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  betGroupV2IsFetching: false,
  betGroupV2Code: 0,
  betGroupV2Error: "",

  lottoScheduleV2Data: [],
  lottoScheduleV2IsFetching: false,
  lottoScheduleV2Code: 0,
  lottoScheduleV2Error: "",
  postCancelBillCode:0,
  postCancelBillError:"",
  postCancelBillIsFetching:false,
};

export default class BetSumHistoryContainer extends Component<
    IBetSumHistoryContainerProps & IBetSumHistoryContainerAction & DefaultProps & RouteComponentProps
> {
   static defaultProps = defaultProps;

   queryTimeout: NodeJS.Timeout | null = null;

   state: IBetSumHistoryContainerState = {
    currentPage: { selected: 1 },
    page: {
      page: 1,
      limit: 10
    },
    selectedOptionSearch:"",
    searchMonths:-1,
    dateStart:null,
    dateEnd:null,
    querySearch:{
      customerName: "",
      startTime: "",
      endTime: "",
      number: "",
      status:"",
      name: "",
    },
   };

  componentDidMount(){
    this.props.getDataV2LotteryList()
    let locationState =  this.props.location.state
    const name = (get(locationState,"name",''))
    const customerId = (get(locationState,"customerId",''))
    const agentShareId = (get(locationState,"agentShareId",''))

    if ( (!isEmpty(locationState)) && (!isEmpty(name)) && (!isEmpty(customerId)) ) {
      this.setState({querySearch:{ ...this.state.querySearch, name:name }},()=>{
        this.props.getGetBetGroup({
          ...this.state.page,
          ...this.state.querySearch,
          customerId:customerId
        })
      })  
    }
    else if ( (!isEmpty(locationState)) &&  agentShareId ) {
        this.props.getGetBetGroup({
          ...this.state.page,
          ...this.state.querySearch,
          agentShareId: agentShareId
        })
      }
    else{
      this.fetchGetData()
    }
  }

  componentDidUpdate(prevProps:IBetSumHistoryContainerProps){
    if((prevProps.postCancelBillIsFetching!== this.props.postCancelBillIsFetching) && !this.props.postCancelBillIsFetching){
      if (this.props.postCancelBillCode!==responseCode.OK && this.props.postCancelBillError !== ""){
      ErrorModal.show({
          action: () => ErrorModal.hide(),
          description: get(
            responseMessage(),
            this.props.postCancelBillCode,
            responseMessage()[0]
          ),
      })
      }else{
        SuccessModal.show({
          action: () => {
            SuccessModal.hide();
            this.props.getMe()
          },
          title: constants.titlePooiSuccess,
          description: constants.cancelPooiSuccess,
        });
      }
    }
  }

  fetchGetData=()=>{
    this.props.getGetBetGroup({
      ...this.state.page,
      ...this.state.querySearch
    })
  }

  renderTableHead = (values: Array<IHeaderLottoTable>) => {
    const mapHeadTable = map(
      values,
      (data: IHeaderLottoTable, index: number) => {
        let classText :string = ''
        if ( index===9 || index===10 ) {
          classText='text-center'
        }else if ( index>=2 && index<=8) {
          classText='text-right'
        }else if ( index===0 || index===1 ) {
          classText='text-left'
        }
        return <th className={`${classText}`} key={index}>
            <h5 className={`text-header secondary-text ${index===0&&"ml-3"}`}>{data.name}</h5>
          </th>
        }
    );
    return mapHeadTable;
  };

  renderTableBillBody = (values: IV2RespAgentLotteryBetGroup[]) => {
    const dataEmpty = (
      <tr>
        <td className="text-center" colSpan={15}>
          {constants.dataNotFound}
        </td>
      </tr>
    )
    const loadingTable= (
      <tr>
        <td className="text-center" colSpan={15}>
        <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
        </td>
      </tr>
    )
    
    const mapData = map(values, (item: IV2RespAgentLotteryBetGroup, index: number) => {
      // createdAt: format(addYears(date.calibratingTime(item.createdAt),543), "dd MMM yyyy", {locale: th}),
      let clacResult = item.totalHostShare+item.totalMeShare
      return (
        <tr
          className={`row-bill-list ${item.customerPayStatus === "CANCEL" ? 'secondary-red-bg' : ' primary-bg'}`}
          key={index}
          // onClick={() => this.handleClickUser(data)}
        >
            <td className="text-left pl-3">
            {
                item.customerPayStatus === "CANCEL" && (
                  <FontAwesomeIcon
                  id={"cancel" + index}
                  className={`num-icon p1-r`}
                  icon={faTimes}
                />
                )
              }
              <span>{item.agentShareId}</span>
            </td>
            <td className="text-left">
              <span>{item.customerName}</span>
            </td>
            <td className="text-right">
              <span>{number.decimalsFormatComma(item.totalMeBet)}</span>
            </td>
            <td className="text-right">
              <span>{number.decimalsFormatComma(item.totalHostBet)}</span>
            </td>
            <td className="text-right">
              <span>{number.decimalsFormatComma(item.totalBet)}</span>
            </td>
            <td className="text-right">
              <span>{number.decimalsFormatComma(item.totalCom)}</span>
            </td>
            <td className="text-right">
              <span>{number.decimalsFormatComma(item.totalDis)}</span>
            </td>
            <td className="text-right">
              <span>{item.totalCountBet}</span>
            </td>
            <td className={`text-right ${clacResult<0?"primary-red-text":clacResult>0?"primary-green-text":""}`}>
              <span>{number.decimalsFormatComma(clacResult)}</span>
            </td>
            <td className="text-center">
              <span>{date.formatThai(item.createdAt, 'dd MMM yyyy')}</span>
            </td>
            <td className="text-letf">
              <span>
                <Dropdown
                  options={ Object.keys(listActionItem).map((menu)=>{return { value: menu, label: listActionItem[menu],disabled: menu === 'CancelBill' ? item.customerPayStatus=== "WAIT" ? false : true : false }}) }
                  iconOpen={point}
                  iconClose={point}
                  iconStyles={{color:colors.TERTIARY_TEXT}}
                  isManualOpenMenu
                  controlBackgroundColor={item.customerPayStatus === "CANCEL" ? colors.SECONDARY_RED : colors.PRIMARY_BG}
                  menuStyles={{width:"175px",left:"-150px"}}
                  valueContainerStyles={{     padding: "0px",
                  maxWidth: "0px",}}
                  handleOnChange={(target: IOptions) => {
                    if (target.value==='BillPrint') {
                      this.modalPrintBill({
                        nameForRound:item.name,
                        customerId:item.customerId,
                        agentShareId:item.agentShareId,
                        startTime:format(date.calibratingTime(item.createdAt), "yyyy'-'MM'-'dd'T'HH:mm:ss"),
                        endTime:format(addSeconds(date.calibratingTime(item.updatedAt), 30), "yyyy'-'MM'-'dd'T'HH:mm:ss"),
                      })
                    }else if (target.value==='BetMeHistory') {
                      this.props.history.push( routes.betMeHistory.path , {
                        customerId:item.customerId.toString(),
                        customerName:item.customerName,
                        createdAt:item.createdAt,
                      })
                    }else if (target.value==='CustomerList') {
                      this.props.history.push( routes.customerList.path+transformer.urlSearchParams({customerId:item.customerId}))                    
                    }
                    else if (target.value==='FinanceHost') {
                      this.props.history.push(`${routes.financeHost.exactPath}/${item.agentShareId}`)
                      // this.props.history.push( routes.financeHost.path+transformer.urlSearchParams({agentShareId:item.agentShareId}))
                    }else if (target.value==='FinanceChild') {
                      this.props.history.push( routes.financeChild.path+`/?agentShareId=${item.agentShareId}`)
                    }else if (target.value==='FinanceCustomer') {
                      this.props.history.push( routes.financeCustomerDetails.path+transformer.urlSearchParams({agentShareId:item.agentShareId}), {rowIndex:index})
                    }
                    else if (target.value==='CancelBill') {
                      CancelBetModal.show({
                        size:"md",
                        title:constants.cancleBet,
                        description:constants.confirmCancleBet,
                        action:()=>{
                            this.props.postCancelBill({agentShareId:item.agentShareId,customerId:item.customerId ,name:item.name, mode: 'CANCEL_PER_CLOTTERY'})
                            CancelBetModal.hide()
                        },
                        cancelAction:CancelBetModal.hide
                      })
                    }
                  }}
                />
              </span>
            </td>

        </tr>
      );
    });
    return this.props.betGroupV2IsFetching ? loadingTable : !isEmpty(values) ? mapData : dataEmpty
  };

  modalPrintBill=(data: IV2QueryAgentLotteryBet & {nameForRound:string})=>{
    ModalPrintBill.show({
      actionPrintShowPay: () => {
          const dataString = JSON.stringify(data)
          const encrypted = CryptoJS.AES.encrypt(dataString, 'bill-query')
          const urlEncoded = encodeURIComponent(encrypted.toString())
          window.open(`${routes.previewBetBill.exactPath}/${urlEncoded}?showpaid=yes&name=${this.props.meData.username}`)
      },
      actionPrintNotShowPay: () => {
          const dataString = JSON.stringify(data)
          const encrypted = CryptoJS.AES.encrypt(dataString, 'bill-query')
          const urlEncoded = encodeURIComponent(encrypted.toString())
          window.open(`${routes.previewBetBill.exactPath}/${urlEncoded}?name=${this.props.meData.username}`)
      },
      actionCancel: () => {
          ErrorModal.hide()
      },
  })
  }

  // getDataBill = (data: IV2QueryAgentLotteryBet,showPay:boolean)=>{
  //   getV2AgentBet({
  //     limit:999999,
  //     name: data.name,
  //     customerId: data.customerId,
  //     agentShareId: data.agentShareId,
  //     startTime:  format(date.calibratingTime(data.startTime!), "yyyy'-'MM'-'dd'T'HH:mm:ss") ,
  //     endTime:  format(addSeconds(date.calibratingTime(data.endTime!),30), "yyyy'-'MM'-'dd'T'HH:mm:ss") ,
  //   }).then((res: AxiosResponse<IAPIResponse<IPagination<IV2RespAgentLotteryBetMe>>>)=>{
  //     const dataList = res.data.data.dataList
  //     if(!isEmpty(dataList)){
  //       const dataName = transformer.nameLotto(get(dataList,"[0].name"),true,true)
  //       const dataCustomerName = get(dataList,"[0].customerName")
  //       const dateNow = format(addYears(date.calibratingTime(get(dataList,"[0].createdAt")),543), "ddMMyyyy")
  //       const nameFile = `${dataName}_${dataCustomerName}_${dateNow}`

  //       let status = get(dataList,"[0].status",'')
  //       let round = ''
  //       if (status!=='WAIT') {
  //         round = get(maxBy(dataList,(i) => i.updatedAt),"updatedAt",'')
  //         round = format(addYears(date.calibratingTime(round),543), "dd MMMM yyyy", {locale: th})
  //       }else{
  //         let findData = this.props.lottoScheduleV2Data.filter((i)=>i.name===data.name)
  //         if (isEmpty(findData)) {
  //           round = "ไม่พบข้อมูลงวด"
  //         }else{
  //           round = format(addYears(date.calibratingTime(findData[0].endTime),543), "dd MMMM yyyy", {locale: th})
  //         }
  //       }
  //       pdfMake.createPdf(docDefinition(dataList,showPay,round) as any).download(nameFile)
  //     }
  //   })
  //   .catch((error: AxiosError<IAPIResponse>)=>{
  //     ErrorModal.show({
  //       action: ErrorModal.hide,
  //       description: get( responseMessage(), responseConverter.getMessage(error).code, responseMessage()[0] ),
  //     });
  //   })
  // }

  handleClickChangePage = ({ selected: selectedPage }: ISelectPage) => {
    this.setState({ currentPage: { selected: selectedPage } }, () => {
      this.setState({
          page: { ...this.state.page,page: this.state.currentPage.selected + 1 },
        },
        () => {this.fetchGetData()}
      )
    })
  }

  textPageNow =()=>{
    // จำนวนข้อมูล * หน้าที่เปิด
    let endPage = this.props.betGroupV2Data.limit*this.state.page.page
    // เลขลำดับท้าย - จำนวนข้อมูล+1
    let startPage = (endPage)-this.props.betGroupV2Data.limit+1
    let total  = this.props.betGroupV2Data.totalRecord
    return `${ startPage > total ? total : startPage }-${ endPage > total ? total : endPage } of ${ total }`
  }

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.mainPageTitle, active: false, path: "/dashboard" },
      // { label: constants.betTitle, active: false, path: "/bet" },
      { label: constants.betMeTitle, active: true },
    ];

    const TableHeadBill = this.renderTableHead(mappingTheadLottoฺBill);
    const TableBodyeBill = this.renderTableBillBody(this.props.betGroupV2Data.dataList);

    return (
      <div className="paper-container betSum-history-container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="sub-menu" style={{ width: "fit-content" }}>
              <Breadcrumb
                items={navigates}
                handleOnClickItem={(path: string) => this.props.history.replace(path)}
              />
            </div>
          </div>
        </div>
        <div className="paper-title">{constants.paperTitle}</div>
        <div className="paper-description">{constants.paperDescription}</div>
        <div className="paper-body">
          <div className="box-shadow">
            <h5 className='text-bold'>รายการกลุ่มเดิมพัน</h5>
            <div className='option-search my-3'>
              <div className='row'>
                <div className='col-12'>
                  <h6 className='text-bold'>{constants.optionSearch}</h6>
                </div>
              </div>
              <div className='row mt-2 d-flex align-items-center box-search'>
                <div className='col-3 box-search-item'>
                  <div className='dropdown-box'>
                    <Dropdown
                      value={(this.state.querySearch.name)}
                      iconStyles={{color:colors.TERTIARY_TEXT}}
                      valueContainerStyles={{fontSize:"13px"}}
                      optionStyles={{fontSize:"13px"}}
                      options={ orderBy(Object.keys(listOptionLotto).map((item)=>{return { value: item, label: listOptionLotto[item as TLottoType].replace("หวย",''), disabled: false }}),[(i)=>{return i.value==='All'?0:i.value==='GOVN'?1:2},'label'], ['asc', 'asc']).filter((i)=>i.value!=='HANOI') }
                      controlBackgroundColor={colors.PRIMARY_BG}
                      placeholder={isEmpty(this.state.querySearch.name)?'หวย':listOptionLotto[this.state.querySearch.name as TLottoType]}
                      menuStyles={{width:"100%"}}
                      handleOnChange={(target: IOptions) => {
                        this.setState({
                            page: { ...this.state.page,page: 1 },
                            querySearch:{...this.state.querySearch, name:target.value==="All"?"":target.value}
                          },
                          () => {this.fetchGetData()}
                        )
                      }}
                    />
                  </div>
                </div>
                <div className='col-3 box-search-item'>
                  <Input
                    id="search-number"
                    name="search-number"
                    inputClassName="input-search"
                    placeholder="เลขที่เดิมพัน"
                    backgroundColor={colors.PRIMARY_BG}
                    value={this.state.querySearch.number}
                    onChange={(e) => {
                      if(e.target.value.length <=3 ){
                        this.setState({
                          page: { ...this.state.page,page: 1 },
                          querySearch:{...this.state.querySearch,number:e.target.value.replace(/[^0-9]/g, '')}
                        }, ()=>{
                          clearTimeout(this.queryTimeout!)
                          this.queryTimeout=setTimeout(()=>{
                            this.fetchGetData()
                          },1000)
                        })
                      }
                    }}
                    renderError={false}
                  />
                </div>
                <div className='col-3 box-search-item'>
                  <div className='dropdown-box'>
                  <Dropdown
                    value={(this.state.querySearch.status)}
                    valueContainerStyles={{fontSize:"13px"}}
                    iconStyles={{color:colors.TERTIARY_TEXT}}
                    optionStyles={{fontSize:"13px"}}
                    options={ Object.keys(listBillStatus).map((item)=>{return { value: item, label: listBillStatus[item as IStatusBill|"All"], disabled: false }}) }
                    controlBackgroundColor={colors.PRIMARY_BG}
                    placeholder={isEmpty(this.state.querySearch.status)?'สถานะบิล':listBillStatus[this.state.querySearch.status as IStatusBill|"All"]}
                    menuStyles={{width:"100%"}}
                    handleOnChange={(target: IOptions) => {
                      this.setState({
                        page: { ...this.state.page,page: 1 },
                        querySearch:{...this.state.querySearch,status:target.value==="All"?"":target.value}
                      }, () => {this.fetchGetData()} )
                    }}
                  />
                  </div>
                </div>
                <div className='col'>
                  <Input
                    id="search-customerName"
                    name="search-customerName"
                    inputClassName="input-search"
                    placeholder="ชื่อช่วยจำ"
                    backgroundColor={colors.PRIMARY_BG}
                    value={this.state.querySearch.customerName}
                    onChange={(e) => {
                      this.setState({
                        page: { ...this.state.page,page: 1 },
                        querySearch:{...this.state.querySearch,customerName:e.target.value}
                      }, ()=>{
                          clearTimeout(this.queryTimeout!)
                          this.queryTimeout=setTimeout(()=>{
                            this.fetchGetData()
                          },1000)
                        }
                      )
                    }}
                    renderError={false}
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
              <div className='lotto-bill'>
                <div className="table-container">
                <div className="table-responsive-xl">
                  <table className="w-100">
                      <thead className='header-table-lotto-bill'>
                        <tr>{TableHeadBill}</tr>
                      </thead>
                      <tbody>{TableBodyeBill}</tbody>
                  </table>
                </div>
                </div>
              </div>
              </div>
            </div>
            <div className='row mt-2'>
              <div className="col d-flex justify-content-end align-items-center">
                <h6 className='text-bold secondary-text'>{constants.rowPerPage}</h6>
                <div className="dropdown-rowPerPage">
                  <Dropdown
                    menuPlacement={"top"}
                    options={ [5,10,20,50,100].map((item)=>{return { value: item, label: item.toString(), disabled: false }}) }
                    controlBackgroundColor={colors.PRIMARY_BG}
                    placeholder={this.props.betGroupV2Data.limit.toString()}
                    menuStyles={{width:"100%"}}
                    handleOnChange={(target: IOptions) => {
                      this.setState({
                        page: {...this.state.page, limit:target.value, page: 1}
                      }, () => {this.fetchGetData()} )
                    }}
                  />
                </div>
              </div>
              <h6 className='text-bold d-flex justify-content-end align-items-center secondary-text'>
                {this.textPageNow()}
              </h6>
              <div className='col-auto d-flex align-items-center'>
                <Panginate
                  pageTotal={this.props.betGroupV2Data.total}
                  initialPage={get(this.state.page,'page',0) - 1}
                  pageRangeDisplayed={0}
                  onPageChange={this.handleClickChangePage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
