import React, { FunctionComponent, useCallback, useState, useEffect } from 'react'
import { Button, Dropdown, LoadingCircle, Panginate, /* SkeletonLoader */ } from "components"
import { TabMenuComponent } from '../TabMenu'
import { fetchGetFinanceHostBalance, fetchPostFinanceHostApprove, fetchPostFinanceHostRequest } from 'reduxs/finance/services'
import { useDispatch, useSelector } from 'react-redux'
import { get, isEmpty, map, sum } from 'lodash'
import colors from "constants/colors"
import { dateThai, number } from 'utils'
import { STATUS_FINANCE } from 'constants/variables'
import color from 'constants/colors'
import {
    ClearBillFinanceChildModal,
    PaidNoticeModal,
    ContactModal,
    ErrorModal,
    SuccessModal,
} from 'components/Modal'
import { fetchGetHost } from 'reduxs/host/services'
import { responseMessage } from 'constants/response'
import { AxiosError } from 'axios'
import { useParams } from 'react-router-dom'
import { getMe } from 'reduxs/me/me/action'

const constants = {
    tabIncomeLabel: 'รายการรับ',
    tabPaidLabel: 'รายการจ่าย',

    billNumber: 'เลขรายการ',
    billName: 'เจ้าของรายการ',
    detail: 'ข้อมูลเพิ่มเติม',
    total: 'ยอด',
    status: 'สถานะ',
    trasactionTime: 'เวลาแจ้ง',
    verifyTime: 'เวลาตรวจ',

    clear: 'เคลียร์',
    contact: 'ติดต่อ',
    waitVerify: 'รอการตรวจสอบ',
    cancel: 'ยกเลิก',
    rowPerPage: 'จำนวนรายการต่อหน้า',

    empty: 'ไม่มีข้อมูล...',
    paidNotice: 'แจ้งจ่าย',
    clearSuccess: 'เคลียร์ยอดสำเร็จ',
    cancelClearSuccess: 'เคลียร์ยอดสำเร็จ',
    paidNoticeSuccess: 'แจ้งจ่าย',

    pleaseCheckPaidList: 'โปรดตรวจสอบรายการแจ้งจ่าย',
    agree: 'เข้าใจแล้ว',
}

interface IBalanceListProps {
    financeDetailData: IV2RespAgentFinanceHost | null
    onRefreshData: () => void
}

const DEFAULT_LIMIT = 10
const DEFAULT_PAGE = 1
const BalanceListComponent: FunctionComponent<IBalanceListProps> = ({ financeDetailData, onRefreshData }) => {

    const [tabs, setTabs] = useState([{ name: constants.tabIncomeLabel, count: 0 }, { name: constants.tabPaidLabel, count: 0 }])
    const [currentTab, setCurrentTab] = useState(constants.tabIncomeLabel)
    const { agentId, permission } = useSelector((state) => get(state, 'me.me.data', {})) as any
    const [balanceData, setBalanceData] = useState<IV2RespAgentFinanceHostMe[]>([])
    const [hostData, setHostData] = useState<IV2GetAgentHost | null>(null)
    const [limit, setLimit] = useState(DEFAULT_LIMIT)
    const [page, setPage] = useState(DEFAULT_PAGE)
    const [total, setTotal] = useState(0)
    const [totalRecord, setTotalRecord] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const { id: agentShareId } = useParams<{ id: string }>()
    const dispatch = useDispatch()

    const handleOnChangeTab = useCallback((tab) => {
        setCurrentTab(tab)
        resetPagination()
        setIsLoading(true)
        getBalanceData(DEFAULT_PAGE, DEFAULT_LIMIT, tab)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTab])

    useEffect(() => {
        getMe()(dispatch)
        getHostData()
        getBalanceData(page, limit, currentTab);
        (async () => {
            // const tabIncomeCount = await getCountNumber(constants.tabIncomeLabel)
            // const tabPaidCount = await getCountNumber(constants.tabPaidLabel)
            setTabs([{ name: constants.tabIncomeLabel, count: 0 }, { name: constants.tabPaidLabel, count: 0 }])
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getHostData = () => {
        fetchGetHost()
            .then((response) => {
                if (get(response, 'data.data', null)) {
                    setHostData(response.data.data)
                }
            })
            .catch((error) => console.log(error))
    }

    const getBalanceData = (pageQuery: number, limitQuery: number, tabQuery: string, status?: Exclude<TTxStatus, 'PENDING'>) => {
        if (agentId && agentShareId) {
            fetchGetFinanceHostBalance({
                limit: limitQuery,
                page: pageQuery,
                hostAgentShareId: Number(agentShareId),
                ...(status ? { status } : {}),
                ...(tabQuery === constants.tabIncomeLabel
                    ? { isNotHostAgentId: agentId }
                    : { hostAgentId: agentId })
            })
                .then((response) => {
                    setResponseBalanceData(response.data)
                })
                .catch((error) => console.log(error))
                .finally(() => {
                    setTimeout(() => {
                        setIsLoading(false)
                    }, 800)
                })
        }
    }

    // const getCountNumber = async (tabQuery: string, status: Exclude<TTxStatus, 'PENDING'> = 'WAIT'): Promise<number> => {
    //     try {
    //         if (agentId) {
    //             const response = await fetchGetFinanceHostBalance({
    //                 limit: 99999,
    //                 status,
    //                 ...(tabQuery === constants.tabIncomeLabel
    //                     ? { isNotHostAgentId: agentId, hostAgentShareId: Number(agentShareId) }
    //                     : { hostAgentId: agentId, hostAgentShareId: Number(agentShareId), })
    //             })
    //             const dataList = get(response, 'data.data.dataList', [])
    //             return dataList.length || 0
    //         }
    //         return 0
    //     } catch (error) {
    //         return 0
    //     }
    // }

    const setResponseBalanceData = (response: IAPIResponse<IPagination<IV2RespAgentFinanceHostMe>>) => {
        const balanceResponseData = get(response, 'data.dataList', [])
        const currentPage = get(response, 'data.page', page)
        const limitPage = get(response, 'data.limit', limit)
        const totalPage = get(response, 'data.total', total)
        const totalRecord = get(response, 'data.totalRecord', total)
        setBalanceData(balanceResponseData)
        setPage(currentPage)
        setLimit(limitPage)
        setTotal(totalPage)
        setTotalRecord(totalRecord)
    }

    const resetPagination = () => {
        // setBalanceData([])
        setPage(DEFAULT_PAGE)
        setLimit(DEFAULT_LIMIT)
        setTotal(0)
    }

    const handleOnChangePage = (selectPage: ISelectPage) => {
        getBalanceData(selectPage.selected + 1, limit, currentTab)
    }

    const handleOnChangeLimit = (selectOptions: IOptions) => {
        resetPagination()
        getBalanceData(DEFAULT_PAGE, selectOptions.value as number, currentTab);

        (async () => {
            // const tabIncomeCount = await getCountNumber(constants.tabIncomeLabel)
            // const tabPaidCount = await getCountNumber(constants.tabPaidLabel)
            setTabs([{ name: constants.tabIncomeLabel, count: 0 }, { name: constants.tabPaidLabel, count: 0 }])
        })()
    }

    const billingClear = (data: IV2PostAgentFinanceChildApprove) => {
        const castToHostData: IV2PostAgentFinanceHostApprove = { ...data, status: data.status || 'FAIL' }
        fetchPostFinanceHostApprove(castToHostData)
            .then(() => {
                getMe()(dispatch)
                SuccessModal.show({
                    action: () => {
                        SuccessModal.hide()
                        getBalanceData(page, limit, currentTab)
                        onRefreshData()
                    },
                    description: data.status === 'SUCCESS' ? constants.clearSuccess : constants.cancelClearSuccess,
                });
            })
            .catch((error: AxiosError<IAPIResponse>) => {
                ErrorModal.show({
                    action: ErrorModal.hide,
                    description: get(
                        responseMessage(),
                        get(error, 'response.data.code', 0),
                        responseMessage()[0]
                    ),
                });
            })
    }

    const paidNotice = (note: string) => {
        if (!note) { return }
        if (!financeDetailData) { return }

        const payTotal = number.calculator(`${financeDetailData.recieveHost || 0}-${financeDetailData.payHost || 0}`)
        const castToPayNumber = payTotal < 0 ? payTotal * -1 : payTotal
        fetchPostFinanceHostRequest({
            agentShareId: Number(financeDetailData.agentShareId),
            pay: Math.floor(castToPayNumber * 100) / 100,
            note
        })
            .then(() => {
                SuccessModal.show({
                    action: () => {
                        SuccessModal.hide()
                        getBalanceData(page, limit, currentTab)
                        onRefreshData()
                    },
                    description: constants.paidNoticeSuccess,
                });
            })
            .catch((error: AxiosError<IAPIResponse>) => {
                const errorCode = get(error, 'response.data.code', 0)
                switch (errorCode) {
                    case 400108:
                        SuccessModal.show({
                            titleStyle: 'pre-line custom-title-modal',
                            action: () => {
                                SuccessModal.hide()
                            },
                            title: get(responseMessage(), 400108, responseMessage()[0]),
                            description: constants.pleaseCheckPaidList,
                            actionText: constants.agree,
                            classTextTitle: "primary-text "
                        })
                        break
                    case 401162:
                        SuccessModal.show({
                            titleStyle: 'pre-line custom-title-modal-smaller',
                            action: () => {
                                SuccessModal.hide()
                            },
                            title: get(responseMessage(), 401162, responseMessage()[0]),
                            description: "",
                            actionText: constants.agree,
                            classTextTitle: "primary-text "
                        })
                        break
                    default:
                        ErrorModal.show({
                            action: ErrorModal.hide,
                            description: get(
                                responseMessage(),
                                errorCode,
                                responseMessage()[0]
                            ),
                        })
                }
            })
    }

    const handleOnBillClear = (selectedHostFinanceData: IV2RespAgentFinanceHostMe) => {
        if (!hostData) { return }
        // Note: To reuse ClearBillFinanceChildModal in same layout
        const castToFinanceChild: IV2RespAgentFinanceChildMe = {
            ...selectedHostFinanceData,
            agentPayTxId: selectedHostFinanceData.agentPayTxId,
            childAgentShareId: selectedHostFinanceData.hostAgentShareId,
            childAgentId: selectedHostFinanceData.hostAgentId,
            childUsername: selectedHostFinanceData.hostUsername,
        }
        const billMemberData: IMemberBillData = {
            line: hostData.hostLine,
            phoneNumber: hostData.hostPhoneNumber,
            bankType: hostData.hostBankType as TBankType,
            bankNumber: hostData.hostBankNumber,
            bankName: hostData.hostBankName,
        }
        ClearBillFinanceChildModal.show({
            detailBill: { ...castToFinanceChild, ...billMemberData },
            action: billingClear,
            cancelAction: () => {
                ClearBillFinanceChildModal.hide();
            },
        });
    }

    const handleOnInfo = (selectedHostFinanceData: IV2RespAgentFinanceHostMe) => {
        if (!hostData) { return }
        // Note: To reuse ClearBillFinanceChildModal in same layout
        const castToFinanceChild: IV2RespAgentFinanceChildMe = {
            ...selectedHostFinanceData,
            agentPayTxId: selectedHostFinanceData.agentPayTxId,
            childAgentShareId: selectedHostFinanceData.hostAgentShareId,
            childAgentId: selectedHostFinanceData.hostAgentId,
            childUsername: selectedHostFinanceData.hostUsername,
        }
        const billMemberData: IMemberBillData = {
            line: hostData.hostLine,
            phoneNumber: hostData.hostPhoneNumber,
            bankType: hostData.hostBankType as TBankType,
            bankNumber: hostData.hostBankNumber,
            bankName: hostData.hostBankName,
        }
        ContactModal.show({
            detailBill: { ...castToFinanceChild, ...billMemberData },
            cancelAction: () => {
                ContactModal.hide();
            },
        });
    }

    const handleOnPayNotice = () => {
        if (!hostData) { return }
        if (!financeDetailData) { return }
        if (hostData.hostBankType === "") { return }

        const payTotal = number.calculator(`${financeDetailData.recieveHost}-${financeDetailData.payHost}`)
        if (payTotal < 0) {
            const castToPayNumber = (payTotal < 0) ? (payTotal*(-1)) : 0 
            PaidNoticeModal.show({
                action: paidNotice,
                cancelAction: PaidNoticeModal.hide,
                data: {
                    agentPayTxId: Number(financeDetailData.agentShareId),
                    pay: Math.floor(castToPayNumber * 100) / 100,
                    line: hostData.hostLine,
                    phoneNumber: hostData.hostPhoneNumber,
                    bankType: hostData.hostBankType,
                    bankNumber: hostData.hostBankNumber,
                    bankName: hostData.hostBankName,
                }
            })
        }
    }

    const payTotal = sum([financeDetailData?.recieveHost || 0, -(financeDetailData?.payHost || 0)])

    return (
        <div className="paper-body">
            <div className="box-shadow m3-t boxShadow-Border pt-0">
                <div className="menu-list p3-b">
                    <TabMenuComponent tabs={tabs} onChangeTab={handleOnChangeTab} />
                    <div className='mt-3'>
                        {(permission !== 'OWNER')
                            && (currentTab === constants.tabPaidLabel)
                            && (payTotal < 0)
                            && (
                                <Button
                                    id={`clear-button`}
                                    textSize={14}
                                    size="moreMedium"
                                    disabled={get(financeDetailData, "status") === 'SUCCESS'
                                        // (find(tabs, ['name', constants.tabPaidLabel])?.count || 0) > 0
                                    }
                                    text={constants.paidNotice}
                                    backgroundColor={color.PRIMARY_PURPLE}
                                    backgroundHoverColor={color.SECONDARY_PURPLE}
                                    onClick={handleOnPayNotice} />
                            )}
                    </div>
                </div>

                <div className='row'>
                    <div className="col">
                        <table className='finance-host-table'>
                            <thead>
                                <tr>
                                    <th>{constants.billNumber}</th>
                                    <th>{constants.billName}</th>
                                    <th>{constants.detail}</th>
                                    <th>{constants.total}</th>
                                    <th>{constants.status}</th>
                                    <th>{constants.trasactionTime}</th>
                                    <th>{constants.verifyTime}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading
                                    // ? (<tr><td className="text-center color-row p-3" colSpan={8}><SkeletonLoader customStyle={[[{ col: 12, show: true }]]} /></td></tr>)
                                    ? <tr>
                                        <td className="text-center" colSpan={15}>
                                            <div className="m1-y d-flex justify-content-center"><LoadingCircle /> </div>
                                        </td>
                                    </tr>
                                    : isEmpty(balanceData)
                                        ? (<tr><td className="text-center color-row p-2" colSpan={8}>{constants.empty}</td></tr>)
                                        : (map(balanceData, (data, index) => {
                                            const statusName = get(STATUS_FINANCE, `${data.status}.status`, "");
                                            const statusColor = get(STATUS_FINANCE, `${data.status}.color`, "");
                                            return (
                                                <tr key={`balance-tx-${index}`}>
                                                    <td>{data.agentPayTxId}</td>
                                                    <td>{data.hostUsername}</td>
                                                    <td style={{ maxWidth: 300, wordBreak: 'break-word' }}>{data.note}</td>
                                                    <td>{number.addComma(data.pay || 0)}</td>
                                                    <td style={{ color: statusColor, fontFamily: 'SukhumvitSet-Bold' }}>{statusName}</td>
                                                    <td>{dateThai.formatThai(data.createdAt)}</td>
                                                    <td>{dateThai.formatThai(data.updatedAt)}</td>
                                                    <td className='py-2'>
                                                        <Button
                                                            disabled={data.status !== 'WAIT'}
                                                            id={`clear-button`}
                                                            textSize={14}
                                                            size="small"
                                                            text={currentTab === constants.tabIncomeLabel ? constants.clear : constants.contact}
                                                            onClick={() => currentTab === constants.tabIncomeLabel ? handleOnBillClear(data) : handleOnInfo(data)} />
                                                    </td>
                                                </tr>
                                            )
                                        }))
                                }
                            </tbody>
                        </table>
                        <div className='row'>
                            <div className="col d-flex justify-content-end align-items-center m2-t">
                                <h6>{constants.rowPerPage}</h6>
                                <div className="dropdown-rowPerPage">
                                    <Dropdown
                                        menuPlacement={"auto"}
                                        options={[
                                            { value: 5, label: "5", disabled: false },
                                            { value: 10, label: "10", disabled: false },
                                            { value: 20, label: "20", disabled: false },
                                            { value: 50, label: "50", disabled: false },
                                            { value: 100, label: "100", disabled: false },
                                        ]}
                                        controlBackgroundColor={colors.PRIMARY_BG}
                                        placeholder={String(limit)}
                                        handleOnChange={handleOnChangeLimit}
                                    />
                                </div>
                            </div>
                            <h6 className='d-flex justify-content-end align-items-center m2-t'>
                                {`${((page - 1) * limit) + (balanceData.length > 0 ? 1 : 0)} - ${(balanceData.length < (limit * page)) ? totalRecord : (limit * page)} of ${totalRecord}`}
                            </h6>
                            <div className='col-auto m2-t'>
                                <Panginate
                                    pageTotal={total}
                                    initialPage={page - 1}
                                    pageRangeDisplayed={limit}
                                    onPageChange={handleOnChangePage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BalanceListComponent
