import { string, object, ObjectSchema, ref } from "yup";

const requiredMessages = {
  password: "กรุณากรอกรหัสผ่านใหม่",
  confirmPassword: "กรุณายืนยันรหัสผ่านใหม่",
};

const lengthMessages = {
  password: "รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร",
  confirmPassword: "ยืนยันรหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร",
};

const scheme: ObjectSchema<IResetUserPasswordRequest & any> = object().shape({
  password: string()
    .required(requiredMessages.password)
    .min(6, lengthMessages.password),
  confirm_password: string()
    .required(requiredMessages.confirmPassword)
    .oneOf([ref("password"), null], requiredMessages.confirmPassword)
    .min(6, lengthMessages.confirmPassword),
});

export default scheme;
