import React from "react";
import "./userDetails.style.scss";

const userDetailsComponent = ({ user }: UserDetailsAppProps) => {
  return (
    <div className="box-shadow mb-4 col-5">
      <div className="paper-body-title mb-3 EditUserHis">ข้อมูลผู้ใช้</div>
      <textarea
        disabled
        className="subtitle-2"
        value={`ผู้ใช้: ${user}
รายละเอียด: .......`}
      ></textarea>
    </div>
  );
};

export default userDetailsComponent;
