import { Dispatch } from 'redux'
import { logoutAction } from '../actionCreator'
import { responseCode } from '../../../constants/response'
import { setToken } from "../token/action"
import { mainAction } from '../../main/actionCreator'


const logout = () => (dispatch: Dispatch) => {
    dispatch(logoutAction.request({}))
    try {
        dispatch(logoutAction.success(responseCode.OK))
        setToken({ token: '', verify: false })(dispatch)
        dispatch(mainAction.cancel({}))
    } catch {
        dispatch(logoutAction.failure(responseCode.BAD_REQUEST))
    }
}

export {
    logout,
}