import React, { Component, createRef, RefObject } from "react";
import "./summary.style.scss";
import { interactive } from "utils"
import {
  Button,
  InputNumber,
  Input,
  ResponsiveIcon,
  ConfirmModal,
} from "components";
import {
  concat,
  filter,
  get,
  groupBy,
  isEmpty,
  keys,
  map,
  noop,
  orderBy,
  omit,
  reduce,
  reverse,
  sum,
  trim,
  findIndex,
  split,
} from "lodash";
import colors from "constants/colors";
import { LOTTO_GAME_TYPE_NAME } from "constants/variables";

import CloseIcon from "assets/images/global/closePink/closePink.png";
import CloseIcon2x from "assets/images/global/closePink/closePink@2x.png";
import CloseIcon3x from "assets/images/global/closePink/closePink@3x.png";
import { number } from "utils";
import { BetEditModal } from "./components/BetEdit";

const constants = {
  betListTitle: "ขั้นตอน 1: รายการเดิมพัน",
  remark: "ชื่อโพย :",
  defaultValue: "เดิมพันราคาเท่ากัน :",
  totalToPay: "เดิมพันทั้งหมด :",
  totalBenefit: "เมื่อชนะทั้งหมด :",
  makeLotto: "เพิ่มโพย",
  clean: "ล้างรายการ",
  summaryPollTitle: "ขั้นตอน 2: สรุปรายการโพยทั้งหมด",
  placeBet: "แทงรอบบิลนี้",
  placeBetWarning: "*กรุณาตรวจสอบรายการแทง ไม่สามารถคืนโพยได้ในทุกกรณี",
  placeBetConfirmation: "คุณแน่ใจหรือว่าจะแทงรอบบิลนี้",
};

const mappingThead: ISummaryTableHead[] = [
  { id: 1, name: "เลข" },
  { id: 2, name: "ราคา" },
  { id: 3, name: "บาทละ" },
  { id: 4, name: "ชนะได้" },
];

const defaultProps: ILottoPaymentSummaryProps = {
  betRates: [],
  lottoList: [],
  lottoSlug: undefined,
  onBetListChanged() {
    noop();
  },
  onClickBet() {
    noop();
  },
  onClickPoll() {
    noop();
  },
  onTotalNumberBet() {
    noop();
  },

  getAutocompleteAction() {
    noop();
  },
  addRemarkSuccess(){
    noop();
  },
  listAutocomplete: [],
  enterStep: "STEP_1",
  keyDown:""
};

const DEFAULT_GAME_VALUE = "1";
const LAO_SULT_FIXED_GAME_VALUE = "120";

class SummaryLottoComponent extends Component<
  ILottoPaymentSummaryProps,
  ILottoPaymentSummaryState
> {
  static defaultProps = defaultProps;

  bottomNumberRef: RefObject<HTMLTableSectionElement> = createRef();
  remarkInputRef: RefObject<HTMLInputElement> = createRef()
  refSummaryPoll: RefObject<HTMLDivElement> = createRef()
  refAutocomplete: RefObject<HTMLLIElement> = createRef()

  state: ILottoPaymentSummaryState = {
    betList: [],
    remark: "",
    defaultValue:
      this.props.lottoSlug === "LOTTER_LAO_SUITE"
        ? LAO_SULT_FIXED_GAME_VALUE
        : DEFAULT_GAME_VALUE,
    pollList: [],
    benefitValue: "",
    betBodyArr: [],
    searchAutocomplete: {
      page: 1,
      limit: 10,
      text: "",
    },
    showAutocomplete: false,
    listAutocomplete: [],
    pollListAutocomplete: [],
    selectAutocomplete:0
  };

  componentDidMount() {
    this.props.getAutocompleteAction(this.state.searchAutocomplete);
    this.setState({ betList: this.props.lottoList });
  }

  componentDidUpdate(prevProps: ILottoPaymentSummaryProps) {

    if (this.props.enterStep!==prevProps.enterStep) {
      if (this.props.enterStep==="STEP_1") {
        this.remarkInputRef.current?.focus()
      }else if (this.props.enterStep==="STEP_2") {
        if ((!(this.state.betList.length <= 0 ||this.state.remark.length <= 0 ||this.props.lottoList.some((lotto) =>lotto.value === "" || Number(lotto.value) <= 0))) && !this.state.showAutocomplete) {
          this.remarkInputRef.current?.blur()
          this.handleOnClickBet()
        }else{
          this.props.addRemarkSuccess(false)
        }
      }else if (this.props.enterStep==="SUMMARY_POLL") {
        setTimeout(() => {
          interactive.scrollTo(this.refSummaryPoll)
        }, 128)
      }else if ((prevProps.enterStep==="SUMMARY_POLL"||prevProps.enterStep==="STEP_2") && this.props.enterStep==="STEP_3" && !isEmpty(this.state.betBodyArr)) {
        this.handleOnClickPlacePoll()
      }
    }

    if (this.props.keyDown!==prevProps.keyDown) {
      if (this.state.showAutocomplete) {
        if (this.props.keyDown==="ArrowDown" && this.state.listAutocomplete.length>this.state.selectAutocomplete+1) {
          this.setState({selectAutocomplete:this.state.selectAutocomplete+1})
        }
        if (this.props.keyDown==="ArrowUp" && this.state.selectAutocomplete-1>=0) {
          this.setState({selectAutocomplete:this.state.selectAutocomplete-1})
        }
        setTimeout(() => {
          if (this.refAutocomplete.current) {
            this.refAutocomplete.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'start',
            })
          }
        }, 10)
        if (this.props.keyDown==="NumpadEnter" || this.props.keyDown==="Enter") {
          setTimeout(() => {
            this.changeAutocomplete(get(this.state.listAutocomplete,`${[this.state.selectAutocomplete]}.text`,""));
          }, 10)
        }
      }
    }

    if (
      prevProps.lottoList !== this.props.lottoList ||
      this.props.lottoList !== this.state.betList
    ) {
      this.setState({ betList: this.props.lottoList }, () => {
        this.handleScrollToBottomNumber();
      });
    }

    if (prevProps.listAutocomplete !== this.props.listAutocomplete) {
      this.setState(
        {
          listAutocomplete: reverse(
            orderBy(this.props.listAutocomplete, "ratingStat")
          ),
        },
        () => {
          let listNodeRemark: IAutocomplete[] = [
            ...this.props.listAutocomplete,
          ];
          this.state.pollListAutocomplete.map((data) => {
            let indexNode = findIndex(
              listNodeRemark,
              (x) => x.text === data.text
            );
            if (indexNode !== -1) {
              listNodeRemark[indexNode].ratingStat =
                listNodeRemark[indexNode].ratingStat + data.ratingStat;
            } else {
              listNodeRemark.push(data);
            }
            return listNodeRemark;
          });
          this.setState({
            listAutocomplete: this.sortListAutocomplete(
              listNodeRemark,
              this.state.remark
            ),
          });
        }
      );
    }
  }

  sortListAutocomplete = (
    list: IAutocomplete[],
    text: string
  ): IAutocomplete[] => {
    if (text.length > 0) {
      const regex = new RegExp(`^${this.state.remark}`, "i");
      list = list.filter((v) => regex.test(v.text));
    }
    return reverse(orderBy(list, "ratingStat"));
  };

  componentWillUnmount() {
    BetEditModal.hide();
  }

  handleOnRemove = (seq: number) => {
    const newBetList: ILottoNumber[] = filter<ILottoNumber>(
      this.state.betList,
      (_, index) => index !== seq
    );
    this.setState({ betList: newBetList }, () => {
      this.props.onBetListChanged!(newBetList);
    });
  };

  handleOnChangevalue = (value: string, seq: number) => {
    const betArray: ILottoNumber[] = filter<ILottoNumber>(
      this.state.betList,
      (_, index) => index === seq
    );
    const betObject: ILottoNumber = get(betArray, "0", {}) as any;
    const newObject: ILottoNumber = { ...betObject, value };
    const newBetList: ILottoNumber[] = this.state.betList;
    newBetList[seq] = newObject;
    this.setState({ betList: newBetList });
  };

  calculateBenefitValue = (
    betValueString: string = "0",
    rate: string = "0"
  ) => {
    if (this.props.lottoSlug === "LOTTER_LAO_SUITE") {
      return number.castToMoney(Number(rate));
    }
    const betValue = Number(number.decimalsFormat(betValueString)) || 0;
    const calculatedBenefit = Number(rate) * betValue;
    return number.castToMoney(calculatedBenefit);
  };

  renderTableHead = (values: Array<ISummaryTableHead>) => {
    const mapHeadTable = map(
      values,
      (data: ISummaryTableHead, index: number) => {
        return (
          <th className={`text-right`} key={index} colSpan={2}>
            <span className="col">{data.name}</span>
          </th>
        );
      }
    );
    return mapHeadTable;
  };

  handleOnDefaultValueBlur = () => {
    const newBetList = map(this.props.lottoList, (item) => ({
      ...item,
      value: this.state.defaultValue,
    }));
    const finalBetNumberList = newBetList.filter(
      (bet) => !isEmpty(bet.value) && Number(bet.value) >= 0
    );
    this.setState({ betList: finalBetNumberList }, () => {
      this.props.onBetListChanged!(finalBetNumberList);
    });
  };

  calculateTotalValue = () => {
    const totally: number = reduce(
      this.state.betList,
      (prev, curr) => {
        const betValue = Number(number.decimalsFormat(curr.value || "0"));
        return sum([prev, betValue]);
      },
      0
    );
    return totally;
  };

  calculateTotalBenefit = () => {
    const totally: number = reduce(
      this.state.betList, 
      (prev, curr) => {
        const splitedType = split(curr.slug!, "_");
        const lotterType = split(
          curr.slug!,
          "_",
          get(splitedType, "length", 0) - 1
        ).reduce((prevType, currType) => `${prevType}_${currType}`);
        const betType = `${lotterType}_${curr.type}`;
        const betRateValue =
          isEmpty(curr.rate) || Number(curr.rate) <= 0
            ? get(
                this.props.betRates.filter((rate) => rate.type === betType),
                "0.rate",
                "0"
              )
            : curr.rate;
        const betValue = number.decimalsFormat(curr.value || "0");
        const total =
          this.props.lottoSlug === "LOTTER_LAO_SUITE"
            ? Number(betRateValue)
            : Number(betRateValue) * Number(number.decimalsFormat(betValue.toString()));
        return sum([prev, total]);
      },
      0
    );
    return number.castToMoney(totally);
  };

  notAllowZeroValues = (values: any) => {
    const { floatValue } = values;
    return floatValue! > 0;
  };

  handleScrollToBottomNumber = () => {
    if (this.bottomNumberRef) {
      this.bottomNumberRef.current?.scrollTo({
        top: this.bottomNumberRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  renderLottoList = (betList: ILottoNumber[]) => {
    const groupingLottoListObject: {
      [name in TLottoGameType]?: (ILottoNumber & { seq?: number })[];
    } = groupBy<ILottoNumber & { seq?: number }>(
      betList.map((bet: ILottoNumber, betIndex: number) => ({
        ...bet,
        seq: betIndex,
      })),
      "type"
    );

    const renderTableBodyItems = (values: Array<ILottoNumber>) => {
      const mapData = keys(groupingLottoListObject).map(
        (lottos, lottosIndex) => {
          const LottoListComponent = groupingLottoListObject[
            lottos as TLottoGameType
          ]?.map((lotto, lottosIndex, lottosListComponent) => {
            const splitedType = split(lotto.slug!, "_");
            const lotterType = split(
              lotto.slug!,
              "_",
              get(splitedType, "length", 0) - 1
            ).reduce((prev, curr) => `${prev}_${curr}`)
            const betType = `${lotterType}_${lotto.type}`;
            const betRateValue =
              isEmpty(lotto.rate) || Number(lotto.rate) <= 0
                ? get(
                    this.props.betRates.filter((rate) => rate.type === betType),
                    "0.rate",
                    "0"
                  )
                : lotto.rate;
            return (
              <tr
                className={`${
                  lottosIndex % 2 === 0 ? "color-row" : ""
                } mapped-data`}
                key={`lotto-${lotto.type}-${lottosIndex}`}
              >
                <td className="text-right" colSpan={2}>
                  <span className="col" id="number">
                    {lotto.bet}
                  </span>
                </td>
                <td className="text-right" colSpan={2}>
                  <InputNumber
                    allowLeadingZeros={false}
                    prefix="฿ "
                    thousandSeparator
                    allowNegative={false}
                    decimalScale={0}
                    id={`values-${lotto.seq}`}
                    name={`values-${lotto.seq}`}
                    disabled={this.props.lottoSlug === "LOTTER_LAO_SUITE"}
                    backgroundColor={colors.PRIMARY_BG}
                    onValueChange={({ value }) =>{
                      if(Number(value)>0){
                        this.handleOnChangevalue(value, lotto.seq!)
                      }else{
                        this.handleOnChangevalue('1', lotto.seq!)
                      }
                    }}
                    value={lotto.value}
                  />
                </td>
                <td className="text-right" colSpan={2}>
                  <span className="col">
                    {number.castToMoney(Number(betRateValue))}
                  </span>
                </td>
                <td className="text-right" colSpan={2}>
                  <div className="delete-lotto-button-container d-flex align-items-center">
                    <div className="overflow-auto w-75 body-7  m1-r">
                      {this.calculateBenefitValue(
                        lotto.value || "0",
                        betRateValue || "0"
                      )}
                    </div>
                    <div
                      className="d-flex justify-content-end w-25 h-100"
                      onClick={() => this.handleOnRemove(lotto.seq!)}
                    >
                      <ResponsiveIcon
                        icon={{
                          x1: CloseIcon,
                          x2: CloseIcon2x,
                          x3: CloseIcon3x,
                        }}
                        className="delete-lotto-button-icon"
                        alt="delete-lotto-icon"
                      />
                    </div>
                  </div>
                </td>
              </tr>
            );
          });
          return (
            <React.Fragment key={`lotto-type-${lottosIndex}`}>
              <tr className="mapped-data">
                <td className="text-left pl-3 color-row-3 p-2" colSpan={8}>
                  <h6>{LOTTO_GAME_TYPE_NAME[lottos as TLottoGameType]}</h6>
                </td>
              </tr>
              {LottoListComponent}
            </React.Fragment>
          );
        }
      );

      const renderBody = /* !isEmpty(values) ? */ mapData; /* : dataEmpty */
      return renderBody;
    };
    return <>{renderTableBodyItems(this.state.betList)}</>;
  };

  renderBetListTotal = () => {
    return (
      <>
        <tr className={"color-row"}>
          <td className="text-right" colSpan={1}>
            <span className="col" id="note">
              {constants.remark}
            </span>
          </td>
          <td className="text-right AutoCompleteText" colSpan={7}>
            <Input
              id="note"
              name="note"
              ref={this.remarkInputRef}
              backgroundColor={colors.PRIMARY_BG}
              borderColor={colors.TERTIARY_TEXT}
              onChange={(e) => {
                this.onTextAutocomplete(e.target.value);
              }}
              value={this.state.remark}
              onFocus={() => {
                this.onTextAutocomplete(this.state.remark);
              }}
              onBlur={(e) => {
                let noteValue = trim(e.target.value);
                this.setState({ remark: noteValue });
                this.setState({ showAutocomplete: false });
              }}
            />
            {this.state.showAutocomplete && this.renderListAutocomplete()}
          </td>
        </tr>
        <tr className={"color-row-2"}>
          <td className="text-right" colSpan={6}>
            <span className="col" id="defaultValue">
              {constants.defaultValue}
            </span>
          </td>
          <td className="text-right" colSpan={2}>
            <InputNumber
              allowLeadingZeros={false}
              prefix="฿ "
              thousandSeparator
              decimalScale={0}
              allowNegative={false}
              id={`maked-all-value`}
              name="maked-all-value"
              backgroundColor={colors.PRIMARY_BG}
              onValueChange={({ value }) =>{
                if(Number(value)>0){
                  this.setState({ defaultValue: value })
                }else{
                  this.setState({ defaultValue: '1' })
                }
              }}
              onBlur={this.handleOnDefaultValueBlur}
              value={this.state.defaultValue}
            />
          </td>
        </tr>
        <tr className={"color-row"}>
          <td className="text-right" colSpan={6}>
            <span className="col" id="defaultValue">
              {constants.totalToPay}
            </span>
          </td>
          <td className="text-right" colSpan={2}>
            <div className="body-7 overflow-auto">
              {number.castToMoney(this.calculateTotalValue())}
            </div>
          </td>
        </tr>
        <tr className={"color-row-2"}>
          <td className="text-right" colSpan={6}>
            <span className="col" id="defaultValue">
              {constants.totalBenefit}
            </span>
          </td>
          <td className="text-right" colSpan={2}>
            <div className="body-7 overflow-auto">
              {this.calculateTotalBenefit()}
            </div>
          </td>
        </tr>
      </>
    );
  };

  handleOnClickBet = () => {
    let indexPoll = this.state.pollListAutocomplete.findIndex(
      (obj) => obj.text === this.state.remark
    );
    let pollListItems = [...this.state.pollListAutocomplete];
    if (indexPoll !== -1) {
      pollListItems[indexPoll] = {
        ...pollListItems[indexPoll],
        ratingStat: pollListItems[indexPoll].ratingStat + 1,
      };
    } else {
      pollListItems.push({
        text: this.state.remark,
        ratingStat: 1,
        updatedAt: Date.now().toString(),
      });
    }
    this.setState({
      pollListAutocomplete: this.sortListAutocomplete(pollListItems, ""),
    });

    const poll: IPoll = {
      betList: this.state.betList,
      remark: this.state.remark,
      totalValue: this.calculateTotalValue(),
    };
    const polls = concat(this.state.pollList, poll);

    const pollsWithSeq = polls.map((poll, pollIndex) => {
      return { ...poll, seq: pollIndex };
    });
    this.setState({ remark: "" });
    this.setState({ pollList: pollsWithSeq }, () => {
      this.handleOnCleanList();
      this.makeBetBodyArray();
      this.props.onTotalNumberBet(
        this.state.betBodyArr,
        this.calculateTotalPollValue()
      );
    });
  };

  handleOnClickPlacePoll = () => {
    ConfirmModal.show({
      action: () => {
        this.props.onClickBet(this.state.betBodyArr);
        this.props.onTotalNumberBet(
          this.state.betBodyArr,
          this.calculateTotalPollValue()
        );
      },
      cancelAction: () => {
        ConfirmModal.hide();
      },
      description: constants.placeBetConfirmation,
    });
  };

  makeBetBodyArray = () => {
    let betBodyArr: IAgentBet[][] = [];
    this.state.pollList.forEach((poll) => {
      let clonedBetList = [...poll.betList];
      clonedBetList = clonedBetList.map((clonedBet) =>
        omit(clonedBet, ["rate"])
      );
      let betBody: IAgentBet[] = clonedBetList.map((bet) => {
        return { ...bet, remark: poll.remark };
      });
      betBodyArr.push(betBody);
    });
    this.setState({ betBodyArr: betBodyArr });
  };

  handleOnCleanList = () => {
    this.setState({ betList: [] }, () => {
      this.props.onBetListChanged!([]);
    });
  };

  renderBetList = () => {
    const TableHead = this.renderTableHead(mappingThead);
    const LottoListComponent = this.renderLottoList(this.state.betList);
    const BetListTotalComponent = this.renderBetListTotal;
    return (
      <div className="box-shadow overflow-hidden summary-container">
        <div className="m2-t">
          <div className="row">
            <div className="col">
              <div className="summary-maked-list-container">
                <div className="row">
                  <div className="col">
                    <div className="d-flex justify-content-between">
                      <h3>{constants.betListTitle}</h3>
                    </div>
                  </div>
                </div>
                <div className="row m2-t">
                  <div className="col">
                    <div className="table-container">
                      <div className="table-responsive-xl">
                        <table className="col mapped-data">
                          <thead className="mapped-data">
                            <tr>{TableHead}</tr>
                          </thead>
                          <tbody
                            className="mapped-data"
                            ref={this.bottomNumberRef}
                          >
                            {LottoListComponent}
                          </tbody>
                        </table>
                        <table className="col">
                          <thead>
                            <></>
                          </thead>
                          <tbody>
                            <BetListTotalComponent />
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m2-t">
                  <div className="col-12 text-center">
                    <Button
                      id="maked-lotto-submit-button"
                      text={constants.makeLotto}
                      transitionType="typeOne"
                      onClick={this.handleOnClickBet}
                      disabled={
                        this.state.betList.length <= 0 ||
                        this.state.remark.length <= 0 ||
                        this.props.lottoList.some(
                          (lotto) =>
                            lotto.value === "" || Number(lotto.value) <= 0
                        )
                      }
                    />
                  </div>
                </div>
                <div className="row m2-t justify-content-center">
                  <div className="col-auto">
                    <Button
                      id="delete-all-lotto"
                      text={constants.clean}
                      transitionType="typeOne"
                      textColor={colors.PRIMARY_RED}
                      onClick={this.handleOnCleanList}
                      canHover={false}
                      backgroundColor={colors.PRIMARY_BG}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  handleEditPollConfirm = (
    betList: ILottoNumber[],
    remark: string,
    targetRow: number,
    totalValue: number
  ) => {
    if (isEmpty(betList)) {
      this.handleDeletePoll(targetRow);
    } else {
      let pollList = [...this.state.pollList];
      let poll = { ...pollList[targetRow] };
      poll.betList = betList;
      poll.remark = remark;
      poll.totalValue = totalValue;
      pollList[targetRow] = poll;
      this.setState({ pollList: pollList }, () => {
        this.makeBetBodyArray();
      });
    }
  };

  handleDeletePoll = (targetRow?: number) => {
    if (targetRow !== undefined) {
      this.setState(
        {
          pollList: this.state.pollList.filter(
            (poll) => poll.seq !== targetRow
          ),
        },
        () => {
          const pollsWithSeq = this.state.pollList.map((poll, pollIndex) => {
            return { ...poll, seq: pollIndex };
          });
          this.setState({ pollList: pollsWithSeq }, () => {
            this.makeBetBodyArray();
          });
        }
      );
    } else {
      this.setState({ pollList: [] }, () => {
        this.makeBetBodyArray();
      });
    }
  };

  handleOnClickPoll = (targetRow: number) => {
    // Todo
    /* this.props.onClickPoll(poll); */
    BetEditModal.show({
      data: this.state.pollList[targetRow],
      betRates: this.props.betRates,
      lottoSlug: this.props.lottoSlug!,
      benefitValue: this.state.benefitValue,

      onConfirm: (
        betList: ILottoNumber[],
        note: string,
        totalValue: number
      ) => {
        this.handleEditPollConfirm(betList, note, targetRow, totalValue);
        this.props.onTotalNumberBet(
          this.state.betBodyArr,
          this.calculateTotalPollValue()
        );
        BetEditModal.hide();
      },
      onDelete: () => {
        this.handleDeletePoll(targetRow);
        this.props.onTotalNumberBet(
          this.state.betBodyArr,
          this.calculateTotalPollValue()
        );
        BetEditModal.hide();
      },
      onCancel: BetEditModal.hide,
    });
  };

  renderSummaryPollTable = () => {
    const renderTableBodyItems = (values: Array<IPoll>) => {
      /* const dataEmpty = (
        <tr>
          <td className="text-center color-row p-2" colSpan={8}>
            ไม่มีข้อมูล...
          </td>
        </tr>
      ); */
      const mapData = this.state.pollList.map((poll, pollIndex) => {
        return (
          <tr
            key={pollIndex}
            className="color-row summary-row mapped-data"
            onClick={(e) =>
              this.handleOnClickPoll(e.currentTarget.rowIndex - 1)
            }
          >
            <td className="text-right" colSpan={1}>
              <span className="col" id="number">
                {pollIndex + 1}
              </span>
            </td>
            <td colSpan={5}>
              <div className="body-4">{poll.remark}</div>
            </td>
            <td colSpan={2}>
              <div className="body-7 text-right overflow-auto">
                {number.castToMoney(poll.totalValue)}
              </div>
            </td>
          </tr>
        );
      });
      const renderBody = /* !isEmpty(values) ? */ mapData; /* : dataEmpty */
      return (
        <div className="summary-container">
          <div className="table-container">
            <div className="table-responsive-xl">
              <table className="col m2-t mapped-data">
                <thead className="mapped-data">
                  <tr></tr>
                </thead>
                <tbody className="mapped-data">{renderBody}</tbody>
              </table>
              <table className="col">
                <thead>
                  <></>
                </thead>
                <tbody>
                  <tr className={"color-row mapped-data"}>
                    <td className="text-right" colSpan={6}>
                      <span className="col" id="defaultValue">
                        {constants.totalToPay}
                      </span>
                    </td>
                    <td className="text-right" colSpan={2}>
                      <div className="body-7 overflow-auto">
                        {this.calculateTotalPollValue()}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    };
    return <>{renderTableBodyItems(this.state.pollList)}</>;
  };

  calculateTotalPollValue = () => {
    const totally: number = reduce(
      this.state.pollList,
      (prev, curr) => {
        const betValue = curr.totalValue || 0;
        return sum([prev, betValue]);
      },
      0
    );

    return number.castToMoney(totally);
  };

  renderSummaryPoll = () => {
    const SummaryPollTable = this.renderSummaryPollTable;

    return (
      <div className="box-shadow overflow-hidden m4-t">
        <div className="m2-t">
          <div className="row">
            <div className="col">
              <div className="summary-maked-list-container">
                <div className="row">
                  <div className="col">
                    <h3>{constants.summaryPollTitle}</h3>
                    <h3>({this.state.pollList.length} รายการ)</h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <SummaryPollTable />
                  </div>
                </div>
                <div className="row m2-t">
                  <div
                    className="col body-3"
                    style={{ color: colors.TERTIARY_TEXT }}
                  >
                    {constants.placeBetWarning}
                  </div>
                </div>
                <div className="row m2-t"  ref={this.refSummaryPoll}>
                  <div className="col">
                    <Button
                      id="place-bet-submit-button"
                      text={constants.placeBet}
                      transitionType="typeOne"
                      onClick={this.handleOnClickPlacePoll}
                      disabled={isEmpty(this.state.betBodyArr)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  onTextAutocomplete = (valueText: string) => {
    this.setState({ showAutocomplete: true });
    this.props.getAutocompleteAction({
      ...this.state.searchAutocomplete,
      text: valueText,
    });
    let value = valueText;
    this.setState({ remark: value });
  };
  changeAutocomplete = (valueText: string) => {
    let value = trim(valueText);
    this.setState({ remark: value });
    this.setState({ showAutocomplete: false });
  };
  renderListAutocomplete = () => {
    if (this.state.listAutocomplete.length <= 0) {
      return null;
    }
    return (
      <ul className="text-right">
        {this.state.listAutocomplete.map((item, index) => (
          <li
            className={`"text-right" ${this.state.selectAutocomplete===index&&"select"}`}
            onMouseDown={() => {
              this.changeAutocomplete(item.text);
            }}
            key={index}
            ref={this.state.selectAutocomplete===index?this.refAutocomplete:null}
          >
            {item.text}
          </li>
        ))}
      </ul>
    );
  };

  render() {
    const BetList = this.renderBetList;
    const SummaryPoll = this.renderSummaryPoll;
    return (
      <>
        <BetList />
        <SummaryPoll />
      </>
    );
  }
}

export default SummaryLottoComponent;
