import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { ResponsiveIcon } from "components";
import "./collapse.style.scss";

const defaultProps: ICollapse = {
  name: "",
  isExpand: false,
  icon: "",
  onClick() {},
  classHeader: "",
  hiddenOverflow: false,
};

const CollapseComponent: FC<ICollapse> = (props) => {
  const {
    isExpand,
    onClick,
    name,
    children,
    classHeader,
    icon,
    hiddenOverflow,
  } = props;
  return (
    <>
      <div
        className={`col d-flex user-info-item-wrapper ${classHeader}`}
        onClick={onClick}
      >
        {name === "" ? (
          <></>
        ) : (
          <>
            <div className="col d-flex user-info-leading-wrapper align-items-center">
              {icon && (
                <ResponsiveIcon
                  icon={icon}
                  alt={name}
                  className="lotto-action-card-flag inline"
                />
              )}
              <h4 className="inline">{name}</h4>
            </div>
            <div className="d-flex user-info-chevron-right-icon">
              <FontAwesomeIcon
                icon={faChevronRight}
                className={`chevron-right-icon ${isExpand ? "expanded" : ""}`}
              />
            </div>
          </>
        )}
      </div>
      <div
        className={`user-info-description-container ${
          hiddenOverflow && "overflow-hidden"
        } ${isExpand ? "expanded" : ""} custom-scrollbar`}
      >
        <div className="d-flex flex-column user-info-description-wrapper">
          {children}
        </div>
      </div>
    </>
  );
};

CollapseComponent.defaultProps = defaultProps;

export default CollapseComponent;
