import { Breadcrumb, ErrorModal, LoadingCircle } from 'components'
import { CODE_TO_NAME, meDataInitialValue } from 'constants/variables'
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router'
import './betWaitingResult.style.scss'
import SumTotalBetLotto from "./components/SumTotalBetLotto"
import SumTotalBetMemberList from "./components/SumTotalBetMemberList"
import { fetchGetLottoBetLotto, fetchGetLottoBetMember } from 'reduxs/lotto/bet/services'
import BreadcrumbMember from './components/BreadcrumbMember/BreadcrumbMember.component'
import { IBreadcrumbMemberItem } from './components/BreadcrumbMember/BreadcrumbMember.component'
import BetLotteryGroupRow from './components/BetLotteryGroup/BetLotteryGroupRow'
import { find, get, includes, isEmpty, map, noop, omit } from 'lodash'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import BetSumTotal from './components/BetLotteryGroup/BetSumTotal'
import { responseCode, responseMessage } from 'constants/response'
import { DetailBetGroup } from 'components/Modal'



type DefaultProps = Readonly<typeof defaultProps>
const constants = {
  mainPageTitle: "ภาพรวม",
  betTitle: "รอผลเดิมพัน",
  subTitle: "รายละเอียดยอดผลหวยทั้งหมด",
  dataNotFound: "ไม่พบข้อมูล...",
  titlePooiSuccess: "สำเร็จ",
  cancelPooiSuccess: "ยกเลิกโพยสำเร็จ",
}

const defaultProps: IBetWaitingResultContainerProps & IBetWaitingResultContainerAction = {
  userMe: meDataInitialValue,
  onChangeIsLoaddingAction() { noop() },
  getBetExtraction() { noop() },
  getBetExtractionDetail() { noop() },
  getBetExtractionData: [],
  getBetExtractionIsFetching: false,
  getBetExtractionCode: 0,
  getBetExtractionError: "",

  getBetExtractionDetailData: [],
  getBetExtractionDetailIsFetching: false,
  getBetExtractionDetailCode: 0,
  getBetExtractionDetailError: "",

  lottoScheduleV2Data: [],
  lottoScheduleV2IsFetching: false,
  lottoScheduleV2Code: 0,
  lottoScheduleV2Error: "",
}
export default class BetWaitingResultContainer extends Component<
  IBetWaitingResultContainerProps &
  IBetWaitingResultContainerAction &
  DefaultProps &
  RouteComponentProps<
    {},
    {}
  >,
  IBetWaitingResultContainerState> {
  static defaultProps = defaultProps

  state: IBetWaitingResultContainerState = {
    responseLottoBetLotto: { lotteryList: [] },
    responseLottoBetMember: { memberList: [] },
    isLoadingBetList: false,
    isLoadingBetMember: false,
    isLoadingTableGroup: false,
    manageChildList: [{ userName: this.props.userMe.username, lotteryName: '', childAgentId: 0 }],
    nameBetDetail: 'GOVN',
    stBillGroup: '',
    querySearch: {
      betTransactionStatus: 'WAIT',
      startCreatedAt: format(startOfMonth(new Date()), "yyyy'-'MM'-'dd'T'00:00:00"),
      endCreatedAt: format(endOfMonth(new Date()), "yyyy'-'MM'-'dd'T'23:59:59"),
    },
    isShowTableGroup: false
  }

  componentDidMount() {
    this.getLottoBetLotto({ ...this.state.querySearch })
    this.getLottoBetMember({ ...this.state.querySearch })
  }

  componentDidUpdate(prevProps: IBetWaitingResultContainerProps) {

    if (prevProps.getBetExtractionIsFetching !== this.props.getBetExtractionIsFetching
      && !this.props.getBetExtractionIsFetching) {
      if (this.props.getBetExtractionCode === responseCode.OK) {
        this.setState({ isLoadingTableGroup: true, isShowTableGroup: true }, () => {
          setTimeout(() => {
            this.setState({ isLoadingTableGroup: false })
          }, 1000);
        });
      }
    }
    if (prevProps.getBetExtractionDetailIsFetching !== this.props.getBetExtractionDetailIsFetching
      && !this.props.getBetExtractionDetailIsFetching) {
      this.props.onChangeIsLoaddingAction(false)
      if (this.props.getBetExtractionDetailCode === responseCode.OK) {
        const lottoSchedule = find(this.props.lottoScheduleV2Data, { 'name': this.state.nameBetDetail })
        DetailBetGroup.show({
          cancelAction: () => {
            DetailBetGroup.hide()
          },
          nameLotto: this.state.nameBetDetail,
          dataBetDetail: this.props.getBetExtractionDetailData,
          stLottoSchedule: get(lottoSchedule, 'status', 'OPEN'),
          stBillGroup: this.state.stBillGroup
        });
      }
      else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            this.props.getBetExtractionDetailCode,
            responseMessage()[0]
          ),
        })
      }
    }
  }


  getLottoBetMember = (query?: IV3QueryGetLotteryBetMember) => {
    this.setState({ isLoadingBetMember: true })
    fetchGetLottoBetMember(query)
      .then((response) => {
        this.setState({ responseLottoBetMember: response.data.data })
      })
      .catch((error) => {
        this.setState({ responseLottoBetMember: { memberList: [] } })
      })
      .finally(() => {
        setTimeout(() => {
          this.setState({ isLoadingBetMember: false })
        }, 1000)
      })
  }

  getLottoBetLotto = (query?: IV3QueryGetLotteryBetAgent) => {
    this.setState({ isLoadingBetList: true })
    fetchGetLottoBetLotto(query)
      .then((response) => {
        this.setState({ responseLottoBetLotto: response.data.data })
      })
      .catch((error) => {
        this.setState({ responseLottoBetLotto: { lotteryList: [] } })
      })
      .finally(() => {
        setTimeout(() => {
          this.setState({ isLoadingBetList: false })
        }, 1000)
      })
  }

  handleOnClickLottoChild = (childAgentId: number, lotteryName: string, memberUsername: string, memberPermission: string) => {
    if (memberPermission !== 'AGENT') {
      this.setState(prevState => {
        const memberList = { userName: memberUsername, lotteryName, childAgentId }
        const newManageChildList: any = [...prevState.manageChildList, memberList]
        return { manageChildList: newManageChildList }
      }, () => {
        this.getLottoBetLotto({ ...this.state.querySearch, childAgentId, lotteryName: [lotteryName] })
        this.getLottoBetMember({ ...this.state.querySearch, childAgentId, lotteryName: [lotteryName] })
      })
    }
    else {
      const queryData = {
        ...this.state.querySearch,
        childAgentId: childAgentId,
        lotteryName: lotteryName,
        betTransactionStatus: "WAIT"
      }
      this.setState(prevState => {
        const memberList = { userName: memberUsername, lotteryName, childAgentId }
        const newManageChildList = [...prevState.manageChildList, memberList]
        return {
          manageChildList: newManageChildList,
          querySearch: queryData,
          isLoadingTableGroup: true
        }
      }, () => {
        this.props.getBetExtraction(queryData)
      })
    }
  }

  handleOnClickSumTotalChild = (childAgentId: number, memberUsername: string, memberPermission: string) => {
    if (memberPermission !== 'AGENT') {
      this.setState(prevState => {
        const memberList = { userName: memberUsername, lotteryName: '', childAgentId }
        const newManageChildList = [...prevState.manageChildList, memberList]
        return { manageChildList: newManageChildList }
      }, () => {
        this.getLottoBetLotto({ ...this.state.querySearch, childAgentId })
        this.getLottoBetMember({ ...this.state.querySearch, childAgentId })
      })
    }
    else {
      const queryData = {
        ...this.state.querySearch,
        childAgentId: childAgentId,
        betTransactionStatus: "WAIT",
        lotteryName: this.state.manageChildList[this.state.manageChildList.length -1].lotteryName
      }
      
      this.setState(prevState => {
        const memberList = { userName: memberUsername, lotteryName: '', childAgentId }
        const newManageChildList = [...prevState.manageChildList, memberList]
        return {
          manageChildList: newManageChildList,
          querySearch: queryData,
          isLoadingTableGroup: true
        }
      }, () => {
        this.props.getBetExtraction(queryData)
      })
    }
  }


  transformDataAndSetActiveLabel(data: ChildListDetail[]): IBreadcrumbMemberItem[] {
    const transformedData = data.map((item, index) => {
      const key = item.userName
      const isActive = index !== data.length - 1
      return { label: key, active: isActive, data: item, index }
    })
    return transformedData
  }

  handleOnClickBreadcrumbBet = (query: ChildListDetail, index: number) => {
    const { childAgentId, lotteryName } = query
    if (query.childAgentId === 0) {
      let queryData = omit(this.state.querySearch, ['lotteryName', 'childAgentId'])
      this.getLottoBetLotto(queryData)
      this.getLottoBetMember(queryData)
      this.setState({ manageChildList: this.state.manageChildList.slice(0, 1), isShowTableGroup: false, querySearch: queryData })
    }
    else {
      const lottoQuery = lotteryName ? { ...this.state.querySearch, lotteryName: [lotteryName], childAgentId } : { ...this.state.querySearch, childAgentId }
      this.getLottoBetLotto(lottoQuery)
      this.getLottoBetMember(lottoQuery)
      this.setState({ manageChildList: this.state.manageChildList.slice(0, index + 1), isShowTableGroup: false })
    }
  }

  getNameHeaderTable = () => {
    return this.state.manageChildList.length > 1 ? this.state.manageChildList[this.state.manageChildList.length - 1].userName : this.props.userMe.username
  }

  detailBetGroup = (query: IV3QueryGetDetailExtractionAgentBetLottery) => {
    const childAgentId = this.state.manageChildList[this.state.manageChildList.length - 1].childAgentId
    this.setState({ nameBetDetail: query.name as TLottoType, stBillGroup: query.betTransactionStatus! })
    this.props.getBetExtractionDetail({ ...query, childAgentId })
    this.props.onChangeIsLoaddingAction(true)
  }

  renderTableMembers = () => {
    const dataEmpty = (
      <table>
        <tbody>
          <tr>
            <td className="text-center" colSpan={15}>
              {'ไม่พบข้อมูล...'}
            </td>
          </tr>
        </tbody>
      </table>
    )
    const loadingTable = (
      <table>
        <tbody>
          <tr>
            <td className="text-center" colSpan={15}>
              <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
            </td>
          </tr>
        </tbody>
      </table>
    )

    const mapData = map(this.props.getBetExtractionData, (item: IV3ExtractionGroupingLottery, index: number) => {
      return (
        <div key={index}>
          <h5 className="primary-text m1-b m1-t" key={index}>
            {(CODE_TO_NAME[includes(item.name, "YEGEE") ? "YEGEE" : item.name]).replace("หวย", "") + (includes(item.name, "YEGEE")
              ? ` รอบที่ ${item.name.replace("YEGEE_", '')}`
              : "")}
          </h5>
          <BetLotteryGroupRow
            loading={this.props.getBetExtractionIsFetching}
            lottoSchedule={this.props.lottoScheduleV2Data}
            trxData={item.trx}
            onClick={this.detailBetGroup}
          />
        </div>
      )
    })
    const renderTable = this.props.getBetExtractionIsFetching ? loadingTable : !isEmpty(mapData) ? mapData : dataEmpty
    return <div className='m1-b'>{renderTable}</div>
  }

  renderSumTotal = (position: number) => {
    return (
      <div className='m1-t'>
        <BetSumTotal
          position={position}
          lottoSchedule={this.props.lottoScheduleV2Data}
          trxData={this.props.getBetExtractionData}
        />
      </div>
    )
  }

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.mainPageTitle, active: false, path: "/dashboard" },
      { label: constants.betTitle, active: true },
    ]

    const TableMembersRow = this.renderTableMembers

    const navigatesMemberList: IBreadcrumbMemberItem[] = this.transformDataAndSetActiveLabel(this.state.manageChildList)
    return (
      <div className="paper-container bet-waiting-result-container">
        <div className="row">
          <div className="col-12">
            <div className="sub-menu">
              <Breadcrumb
                items={navigates}
                handleOnClickItem={(path: string) => this.props.history.replace(path)}
              />
            </div>
          </div>
        </div>
        <div className="paper-title">{constants.betTitle}</div>
        <div className="paper-description">{constants.subTitle}</div>
        <div className="paper-body">
          <div className="box-shadow">
            <div className="row">
              <div className="col-12">
                <div className='box-user'>
                  <BreadcrumbMember
                    items={navigatesMemberList}
                    handleOnClickItem={(query: ChildListDetail, index: number) => this.handleOnClickBreadcrumbBet(query, index)}
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                {
                  !this.state.isShowTableGroup
                    ? <div className="summary-table-container mt-3">
                      <SumTotalBetLotto userName={this.getNameHeaderTable()} childNestedList={this.state.manageChildList} lotteryList={this.state.responseLottoBetLotto.lotteryList} handleOnClickChild={this.handleOnClickLottoChild} loading={this.state.isLoadingBetList} />
                      <SumTotalBetMemberList userName={this.getNameHeaderTable()} lotteryฺBetMemberList={this.state.responseLottoBetMember.memberList} handleOnClickChild={this.handleOnClickSumTotalChild} loading={this.state.isLoadingBetMember} />
                    </div>
                    : this.state.isLoadingTableGroup ?
                      <div className="m2-l m1-t"> <LoadingCircle /> </div>
                      : <div className='lotto-bill'>
                        {this.renderSumTotal(0)}
                        <TableMembersRow />
                        {this.renderSumTotal(this.props.getBetExtractionData.length - 1)}
                      </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
