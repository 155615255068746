import {
  Button,
  ErrorModal,
  Input,
  InputNumber,
  SuccessModal,
} from "components";
import color from "constants/colors";
import { ENG_THAI_AND_SPACE } from "constants/regex";
import { BANK_TYPE, meDataInitialValue } from "constants/variables";
import { format } from "date-fns";
import { th } from "date-fns/locale";
import { FormikProps } from "formik";
import { findIndex, forOwn, get, isEmpty, isNull, noop } from "lodash";
import React, { Component } from "react";
import { date } from "utils";
import "./userInfo.style.scss";
import ImageBankSet from "assets/images/global/bank";
import Select from "react-select";
import { createMemberDropdownStyles } from "containers/CreateMember/CreateMemberDropdown.styles";
import { responseCode, responseMessage } from "constants/response";

const constants = {
  noInfo:"ไม่มีข้อมูล",
  userInfoTitle: "ข้อมูลผู้ใช้",
  lastUpdate: (time: string) => `ข้อมูลเปลี่ยนแปลงล่าสุด ${time}`,
  editUserInfo: "แก้ไขข้อมูล",
  phoneNumber: "เบอร์ติดต่อ",
  placeholderPhoneNumber: "เบอร์ติดต่อ",
  bankName: "ชื่อบัญชีธนาคาร",
  bankNamePlaceholder: "ชื่อบัญชีธนาคาร",
  bankNumber: "หมายเลขบััญชีธนาคาร",
  bankNumberPlaceholder: "หมายเลขบัญชีธนาคาร",
  bank: "ธนาคาร",
  bankPlaceholder: "เลือกธนาคาร",
  resetUserInfo: "รีเซ็ตเป็นค่าเดิม",
  resetUserInfoSuccess: "รีเซ็ตเป็นค่าเดิมสำเร็จ",
  postOwnerUpdateSuccess: "แก้ไขข้อมูลผู้ใช้สำเร็จ",
};

const defaultProps: IUserInfoProps = {
  meData: meDataInitialValue,
  postOwnerUpdate() {
    noop();
  },
  postOwnerCode: 0,
  postOwnerIsFetching: false,
  getMe() {
    noop();
  },
};

class UserInfoComponent extends Component<
  IUserInfoProps & FormikProps<IUserDetailFormikProps>,
  IUserInfoState
> {
  static defaultProps = defaultProps;
  state: IUserInfoState = {
    isEditable: false,
    isBankTypeSelected: false,
  };

  componentDidUpdate(prevProps: IUserInfoProps) {
    if (
      prevProps.postOwnerIsFetching !== this.props.postOwnerIsFetching &&
      !this.props.postOwnerIsFetching
    ) {
      if (this.props.postOwnerCode === responseCode.OK) {
        this.setState({ isEditable: false }, () => {
          this.props.getMe();
          SuccessModal.show({
            action: () => SuccessModal.hide(),
            description: constants.postOwnerUpdateSuccess,
          });
        });
      } else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: responseMessage()[this.props.postOwnerCode || 0],
        });
      }
    }
  }

  formatBankTypeSelect(): IOptions[] {
    let formatBankTypeSelect: any[] = [];
    forOwn(BANK_TYPE, function (values, keys) {
      const ImageIcon = get(ImageBankSet, `${keys}.Icon`, "");
      formatBankTypeSelect.push({
        label: (
          <div className="d-flex flex-row">
            {!isEmpty(ImageIcon) && (
              <img src={ImageIcon} alt="bank" className="bank-image" />
            )}
            <div>{values}</div>
          </div>
        ),
        value: keys,
        disabled: false,
        searchableName: `${keys} ${values}`,
      });
    });
    return formatBankTypeSelect;
  }

  submitUserInfo = () => {
    const { validateForm, setTouched, touched, values, meData } = this.props;
    validateForm().then((err) => {
      setTouched({
        ...touched,
        bankNumber: true,
        bankName: true,
        bankType: true,
        phoneNumber: true,
      });
      if (isEmpty(err)) {
        const PostChildBody = {
          bankName: values.bankName,
          bankNumber: values.bankNumber,
          bankType: values.bankType,
          phoneNumber: values.phoneNumber,
          name: meData.name,
        }
        const postOwnerUpdateData =  Object.entries(PostChildBody).reduce((obj, [key, val]) => {
          if(isNull(val)){
            delete (obj as any)[key];
          }
           else  {
            (obj as any)[key] = val;
          }
          return obj;
        }, {});
        this.props.postOwnerUpdate(postOwnerUpdateData as IV2AgentOwnerUpdate);
      }
    });
  };

  resetUserInfo = () => {
    const { setValues, meData, values, setTouched, touched } = this.props;
    setValues({
      ...values,
      bankName: meData.bankName,
      bankNumber: meData.bankNumber,
      bankType: meData.bankType,
      phoneNumber: meData.phoneNumber,
    });
    setTouched({
      ...touched,
      bankName: false,
      bankNumber: false,
      bankType: false,
    });
    this.setState({ isEditable: false,isBankTypeSelected:false }, () => {
      SuccessModal.show({
        action: () => SuccessModal.hide(),
        description: constants.resetUserInfoSuccess,
      });
    });
  };

  render() {
    const {
      meData,
      values,
      handleBlur,
      setFieldValue,
      errors,
      touched,
      validateField,
    } = this.props;

    const bankTypeIndex = findIndex(
      this.formatBankTypeSelect(),
      (item: IOptions) => item.value === values.bankType
    );
    const defaultBankType={
      label: 
      <div style={{color: "#808080"}}>
        {constants.bankPlaceholder}
      </div>
      ,
      value: "",
      disabled: false,
    }
    return (
      <div className="paper-body user-info-container">
        <div className="row mt-4">
          <div className="col">
            <div className="box-shadow">
              <div className="row">
                <div className="col-12 pr-3 paper-body-title d-flex flex-row justify-content-between">
                  <div className="d-flex flex-column">
                    <div className="paper-body-title">
                    <div className="userInfoHeadText">{constants.userInfoTitle}</div>                        
                    </div>
                    <div className="content-text m1-t primary-text">
                      {constants.lastUpdate(
                        format(
                          date.calibratingTime(meData.updatedAt, true),
                          "dd MMM yyyy HH:mm",
                          { locale: th }
                        )
                      )}
                    </div>
                  </div>
                  {!this.state.isEditable && get(this.props.meData, "permission", "") === "OWNER" && (
                    <div>
                      <Button
                        id="edit-user-info"
                        text={constants.editUserInfo}
                        borderRadius={"8px"}
                        buttonClass={"edit-user-info-button"}
                        backgroundColor={color.PRIMARY_GREEN}
                        textSize={18}
                        backgroundHoverColor={color.SECONDARY_GREEN}
                        onClick={() => {
                          this.setState({ isEditable: true });
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="row m3-t">
                <div className="col-4">
                  <label className="label-input">{constants.phoneNumber}</label>
                  <InputNumber
                    style={{ width: "100%" }}
                    id="phoneNumber"
                    name="phoneNumber"
                    format="### ### ####"
                    decimalScale={0}
                    allowLeadingZeros
                    allowNegative={false}
                    value={values.phoneNumber}
                    onBlur={handleBlur}
                    errorMessage={errors.phoneNumber}
                    backgroundColor={color.TRANSPARENT}
                    placeholder={constants.placeholderPhoneNumber}
                    error={!!errors.phoneNumber && touched.phoneNumber}
                    onValueChange={({ value }) =>
                      setFieldValue("phoneNumber", value)
                    }
                    inputClassName="input-otp"
                    disabled={!this.state.isEditable}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <label className="label-input">{constants.bankName}</label>
                  <Input
                    id="bankName"
                    name="bankName"
                    type="text"
                    placeholder={constants.bankNamePlaceholder}
                    value={isNull(values.bankName)? "":values.bankName}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      if (!e.target.value.match(ENG_THAI_AND_SPACE)) {
                        setFieldValue("bankName", e.target.value);
                      }
                    }}
                    errorMessage={errors.bankName}
                    error={!!errors.bankName }
                    inputClassName="input-otp"
                    backgroundColor={color.TRANSPARENT}
                    disabled={!this.state.isEditable}
                  />
                </div>
                <div className="col-4">
                  <label className="label-input">{constants.bank}</label>
                  <Select
                    styles={createMemberDropdownStyles(!this.state.isEditable)}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    placeholder={constants.bankPlaceholder}
                    name="bankType"
                    options={this.formatBankTypeSelect()}
                    noOptionsMessage={() => constants.noInfo}
                    value={isNull(values.bankType)? defaultBankType:this.formatBankTypeSelect()[bankTypeIndex]}
                    onChange={(e) => {
                      if (values.bankType === "GSB" && e?.value !== "GSB") {
                        const formattedBankNumber = values.bankNumber.substring(
                          0,
                          values.bankNumber.length - 2
                        );
                        setFieldValue("bankNumber", formattedBankNumber);
                        setTimeout(() => {
                          validateField("bankNumber");
                        }, 256);
                      }
                      setFieldValue("bankType", e?.value);
                      this.setState({ isBankTypeSelected: true });
                    }}
                    getOptionValue={(option) =>
                      get(option, "searchableName", "")
                    }
                    isDisabled={!this.state.isEditable}
                  />
                </div>
                <div className="col-4">
                  <label className="label-input">{constants.bankNumber}</label>
                  <InputNumber
                    allowNegative={false}
                    decimalScale={0}
                    id="bankNumber"
                    name="bankNumber"
                    type="text"
                    placeholder={constants.bankNumberPlaceholder}
                    value={values.bankNumber}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        "bankNumber",
                        e.target.value.replaceAll(" ", "")
                      );
                    }}
                    errorMessage={errors.bankNumber}
                    error={!!errors.bankNumber}
                    inputClassName="input-otp"
                    format={
                      values.bankType === "BAAC" || values.bankType === "GSB"
                        ? "### # ##### ###"
                        : "### # ##### #"
                    }
                    backgroundColor={color.TRANSPARENT}
                    disabled={!this.state.isEditable || (values.bankType ===null &&!this.state.isBankTypeSelected)}
                  />
                </div>
              </div>
              <div className="row">
                {this.state.isEditable && (
                  <>
                    <div className="col-12">
                      <Button
                        id="submit-finance-info"
                        text={constants.editUserInfo}
                        borderRadius={"8px"}
                        buttonClass={"edit-user-info-button"}
                        textSize={18}
                        onClick={this.submitUserInfo}
                      />
                    </div>
                    <div className="col-12" style={{ marginTop: 12 }}>
                      <Button
                        id="reset-finance-info"
                        text={constants.resetUserInfo}
                        borderRadius={"8px"}
                        buttonClass={"edit-user-info-button"}
                        textSize={18}
                        backgroundColor={color.TRANSPARENT}
                        backgroundHoverColor={color.TRANSPARENT}
                        textColor={color.SECONDARY_TEXT}
                        onClick={this.resetUserInfo}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserInfoComponent;
