import React, { Component, createRef, RefObject } from "react";
import { FormikProps, Form } from "formik";
import "./login.style.scss";
import { Button, Input } from "components";
import LogoThailandBet from "assets/images/logo/Thailandbet-01.png";
import environment from "constants/environment";

const constants = {
  welcome: "ยินดีต้อนรับเข้าสู่",
  agentName: "ระบบ ไทยแลนด์เบท เอเจ้นท์",
  agentNotFound: "หากเข้าสู่ระบบไม่ได้ กรุณาติดต่อแอดมิน",
  userNotFound: "ไม่พบชื่อผู้ใช้ในระบบ",
  title: `ยินดีต้อนรับสู่ ThailandBet เว็บแทงหวยออนไลน์ ที่ดีที่สุด เดิมพันง่าย จ่ายเงินจริง
  เล่นได้ทุกที่ทุกเวลา หากต้องสร้างรายได้กับการเดิมพัน โปรดีที่สุด จ่ายชัวร์ 100%`,
  loginButtonText: "เข้าสู่ระบบ",
};

type DefaultProps = Readonly<typeof defaultProps>;
const defaultProps: ILoginComponentProps & ILoginComponentActionProps = {
};

class LoginComponent extends Component<
  FormikProps<ILoginForm> &
    ILoginComponentProps &
    ILoginComponentActionProps &
    DefaultProps
> {
  divOTPInputRef: RefObject<HTMLDivElement> = createRef();
  otpInputRef: RefObject<HTMLInputElement> = createRef();

  static defaultProps = defaultProps;

  state: ILoginComponentStates = {};

  render() {
    const { errors, touched, values, handleChange, handleBlur } = this.props;
    return (
      <Form>
        <div className="login-container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-5 text-center p-3 p-sm-5 order-2 order-lg-1">
              <div className="m2-b m4-t">
                <h1 className="header-text">{constants.welcome}</h1>
                <h3 className="tertiary-text agentName mb-5">
                  {constants.agentName}
                </h3>
              </div>
              <div className="row">
                <div className="col">
                  <Input
                    id="username"
                    name="username"
                    label="Username"
                    placeholder="ชื่อผู้ใช้ (ยูสเซอร์เนม)"
                    value={values.username}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={!!errors.username && touched.username}
                    errorMessage={errors.username}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Input
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    placeholder="รหัสผ่าน"
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={!!errors.password && touched.password}
                    errorMessage={errors.password}
                  />
                </div>
              </div>
              <div className="m3-t m3-b btn-transition">
                <Button
                  id="login"
                  size="large"
                  text={constants.loginButtonText}
                  buttonType="submit"
                  transitionType="typeOne"
                />
              </div>
              <h6 className="gray-text mt-2">
                {constants.agentNotFound}{" "}
                {`v.${environment.packageVersion}`}
              </h6>
            </div>
            <div className="col-12 col-lg-7 order-1 order-lg-2 box-right">
              <div className="d-flex justify-content-center box-img">
                <img
                  src={LogoThailandBet}
                  className="login-logo"
                  alt="Logo ThailandBet"
                />
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}
export default LoginComponent;
