import { AxiosError, AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import { mainAction } from '../actionCreator'
import { fetchMain } from '../services'

const getDataMain = () => (dispatch: Dispatch) =>{
    dispatch(mainAction.request({}))
    return fetchMain()
        .then((res:AxiosResponse<IAPIResponse<IMain>>) => {
            dispatch(mainAction.success(res))
        })
        .catch((error: AxiosError<IAPIResponse>) => dispatch(mainAction.failure(error)))
    }

export {
    getDataMain
}