import { AxiosError, AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { ActionType, createReducer } from "typesafe-actions";
import { responseConverter } from "utils";
import * as childAction from "./actionCreator";
import {
  childCreditUpdateState,
  childDetailState,
  childSettingLotteryUpdateState,
  childSettingUpdateState,
  childState,
  childResetPasswordState,
  childUpdateState,
  childBetState,
} from "./constants";

export type ChildActionType = ActionType<typeof childAction>;
const child = createReducer<ReducerState<{}>, ChildActionType>(childState)
  .handleAction(
    childAction.postChildAction.request,
    (state: ReducerState<{}>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    childAction.postChildAction.success,
    (state: ReducerState<{}>, action: ChildActionType) => {
      const payload: AxiosResponse<IAPIResponse<{}>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: { ...state.data, ...payload.data.data },
        error: "",
      };
    }
  )
  .handleAction(
    childAction.postChildAction.failure,
    (state: ReducerState<{}>, action: ChildActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );

const childDetail = createReducer<
  ReducerState<
    IPagination<
      IV2GetAgentChild &
      IV2GetAgentChildSettingUpdate &
      IV2PostAgentChildSettingLotteryUpdate
    >
  >,
  ChildActionType
>(childDetailState)
  .handleAction(
    childAction.getChildDetailAction.request,
    (
      state: ReducerState<
        IPagination<
          IV2GetAgentChild &
          IV2GetAgentChildSettingUpdate &
          IV2PostAgentChildSettingLotteryUpdate
        >
      >
    ) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    childAction.getChildDetailAction.success,
    (
      state: ReducerState<
        IPagination<
          IV2GetAgentChild &
          IV2GetAgentChildSettingUpdate &
          IV2PostAgentChildSettingLotteryUpdate
        >
      >,
      action: ChildActionType
    ) => {
      const payload: AxiosResponse<
        IAPIResponse<
          IPagination<
            IV2GetAgentChild &
            IV2GetAgentChildSettingUpdate &
            IV2PostAgentChildSettingLotteryUpdate
          >
        >
      > = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: { ...state.data, ...payload.data.data },
      };
    }
  )
  .handleAction(
    childAction.getChildDetailAction.failure,
    (
      state: ReducerState<
        IPagination<
          IV2GetAgentChild &
          IV2GetAgentChildSettingUpdate &
          IV2PostAgentChildSettingLotteryUpdate
        >
      >,
      action: ChildActionType
    ) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );

const getChildBet = createReducer<ReducerState<IPagination<IV2RespAgentChildBet>>, ChildActionType>(childBetState)
  .handleAction(
    childAction.getChildBetAction.request,
    (state: ReducerState<IPagination<IV2RespAgentChildBet>>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    childAction.getChildBetAction.success,
    (
      state: ReducerState<IPagination<IV2RespAgentChildBet>>,
      action: ChildActionType
    ) => {
      const payload: AxiosResponse<IAPIResponse<IPagination<IV2RespAgentChildBet>>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: { ...state.data, ...payload.data.data },
      };
    }
  )
  .handleAction(
    childAction.getChildBetAction.failure,
    (
      state: ReducerState<IPagination<IV2RespAgentChildBet>>,
      action: ChildActionType
    ) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );

const childSettingLotteryUpdate = createReducer<
  ReducerState<{}>,
  ChildActionType
>(childSettingLotteryUpdateState)
  .handleAction(
    childAction.postChildSettingLotteryUpdateAction.request,
    (state: ReducerState<{}>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    childAction.postChildSettingLotteryUpdateAction.success,
    (state: ReducerState<{}>, action: ChildActionType) => {
      const payload: AxiosResponse<IAPIResponse<{}>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: { ...state.data, ...payload.data.data },
        error: "",
      };
    }
  )
  .handleAction(
    childAction.postChildSettingLotteryUpdateAction.failure,
    (state: ReducerState<{}>, action: ChildActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );

const childResetPassword = createReducer<ReducerState<{}>, ChildActionType>(childResetPasswordState)
  .handleAction(
    childAction.postChildResetPasswordAction.request,
    (state: ReducerState<{}>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    childAction.postChildResetPasswordAction.success,
    (state: ReducerState<{}>, action: ChildActionType) => {
      const payload: AxiosResponse<IAPIResponse<{}>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: { ...state.data, ...payload.data.data },
        error: "",
      };
    }
  )
  .handleAction(
    childAction.postChildResetPasswordAction.failure,
    (state: ReducerState<{}>, action: ChildActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );

const childUpdate = createReducer<ReducerState<{}>, ChildActionType>(
  childUpdateState
)
  .handleAction(
    childAction.postChildUpdateAction.request,
    (state: ReducerState<{}>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    childAction.postChildUpdateAction.success,
    (state: ReducerState<{}>, action: ChildActionType) => {
      const payload: AxiosResponse<IAPIResponse<{}>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: { ...state.data, ...payload.data.data },
      };
    }
  )
  .handleAction(
    childAction.postChildUpdateAction.failure,
    (state: ReducerState<{}>, action: ChildActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );

const childSettingUpdate = createReducer<ReducerState<{}>, ChildActionType>(
  childSettingUpdateState
)
  .handleAction(
    childAction.postChildSettingUpdateAction.request,
    (state: ReducerState<{}>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    childAction.postChildSettingUpdateAction.success,
    (state: ReducerState<{}>, action: ChildActionType) => {
      const payload: AxiosResponse<IAPIResponse<{}>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: { ...state.data, ...payload.data.data },
      };
    }
  )
  .handleAction(
    childAction.postChildSettingUpdateAction.failure,
    (state: ReducerState<{}>, action: ChildActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );

const childCreditUpdate = createReducer<ReducerState<{}>, ChildActionType>(
  childCreditUpdateState
)
  .handleAction(
    childAction.postChildCreditUpdateAction.request,
    (state: ReducerState<{}>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    childAction.postChildCreditUpdateAction.success,
    (state: ReducerState<{}>, action: ChildActionType) => {
      const payload: AxiosResponse<IAPIResponse<{}>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: { ...state.data, ...payload.data.data },
      };
    }
  )
  .handleAction(
    childAction.postChildCreditUpdateAction.failure,
    (state: ReducerState<{}>, action: ChildActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );

export default combineReducers({
  child,
  getChildBet,
  childDetail,
  childSettingLotteryUpdate,
  childUpdate,
  childSettingUpdate,
  childCreditUpdate,
  childResetPassword,
});
