import React, { FC } from "react";
import { Input, InputElements, InputSplit } from "components";
import NumberFormat, { NumberFormatProps } from "react-number-format";

const InputNumberComponent: FC<IInput & NumberFormatProps & IInputElements> = (props) => {
  const { placeholder, format, onValueChange, onFocus, onBlur, ...inputProps  } = props;

  return (
    <NumberFormat
      {...inputProps}
      format={format}
      onFocus={onFocus}
      onBlur={onBlur}
      onValueChange={onValueChange}
      // hintText={placeholder}
      placeholder={placeholder}
      customInput={inputProps.mode === "InputElements" ? (InputElements) as any : inputProps.mode === 'InputSplit' ? (InputSplit) : Input}
    />
  );
};

export default InputNumberComponent;
