import { Modal, Button } from "components";
import color from "constants/colors";
import { noop } from "lodash";
import React, { Component, createRef, RefObject } from "react";
import "./authentication2FA.style.scss";
import { ReactComponent as GoogleAuthen } from "assets/images/global/2FA/google-authen.svg";
import OtpInput from "react-otp-input";
const constants = {
  title: "โปรดระบุรหัส 2FA (ตัวเลข 6 หลัก)",
  description: "Google Authentication Code",
  accept: "ตกลง",
  cancel: "ยกเลิก",
  invalidVerificationCode: "2FA Verification Code ไม่ถูกต้อง",
};

type DefaultProps = Readonly<typeof defaultProps>;
const defaultProps: IAuthentication2FAModalProps = {
  action() {
    noop();
  },
  cancelAction() {
    noop();
  },
  verify2FA() {
    noop();
  },
  verify: false,
};

class Authentication2FAModal extends Component<
  IAuthentication2FAModalProps & DefaultProps,
  IAuthentication2FAModalState
> {
  static defaultProps = defaultProps;

  otpInputRef: RefObject<OtpInput> = createRef();

  state: IAuthentication2FAModalState = {
    passwordGGAuthen: "",
    isCallFunc: true,
    hasErrored: false,
    loginCode: 0,
    loginError: "",
    loginIsFetching: false,
  };

  componentDidUpdate() {
    if (this.state.isCallFunc) {
      if (this.state.passwordGGAuthen.length === 6) {
        this.setState({ isCallFunc: false });
        this.props.verify2FA(this.state.passwordGGAuthen, this.handle2FAError);
      }
    }
  }

  handle2FAError = () => {
    this.setState(({passwordGGAuthen: ""}))
    this.otpInputRef.current?.focusInput(0);
    this.setState({hasErrored: true})
  }

  handleGoogleAuthenChange = (passwordGGAuthen: number) => {
    this.setState({ passwordGGAuthen: passwordGGAuthen.toString() });
    if (this.state.hasErrored) {
      this.setState({ hasErrored: false });
    }
  };

  handleSubmitPassword2FA = () => {
    this.props.verify2FA(this.state.passwordGGAuthen, this.handle2FAError);
  };

  render() {
    return (
      <div className="container">
        <div className="authentication2FA-modal-container border-rounded">
          <div className="row">
            <div className="col pre-wrap">
              <span> </span>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-3 d-flex justify-content-end">
              <GoogleAuthen />
            </div>
            <div className="col-9">
              <h4>{constants.title}</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <></>
            </div>
            <div className="col-9">
              <div className="subtitle-2 tertiary-text">
                <p>{constants.description}</p>
              </div>
            </div>
          </div>
          <div className="row m4-b m2-t gg-authen">
            <div className="col overflow-hidden d-flex justify-content-center">
              <OtpInput
                value={this.state.passwordGGAuthen}
                onChange={this.handleGoogleAuthenChange}
                numInputs={6}
                inputStyle={"otp-input"}
                separator={
                  <span className="include-whitespace">{"       "}</span>
                }
                isInputNum={true}
                focusStyle="otp-input-focus"
                shouldAutoFocus={true}
                hasErrored={this.state.hasErrored}
                errorStyle="otp-input-error"
                ref={this.otpInputRef}
              />
            </div>
          </div>
          {this.state.hasErrored ? (
            <div className="text-center m2-b primary-red-text">
              {constants.invalidVerificationCode}
            </div>
          ) : (
            <></>
          )}
          <div className="row m2-b">
            <div className="col">
              <Button
                id="authentication2FA-cancel-button"
                type="rectangle"
                backgroundColor={color.TERTIARY_TEXT}
                backgroundHoverColor={color.TERTIARY_TEXT}
                borderColor={color.SECONDARY_TEXT}
                onClick={this.props.cancelAction}
                text={constants.cancel}
              />
            </div>
            <div className="col">
              <Button
                id="authentication2FA-ok-button"
                type="rectangle"
                onClick={this.handleSubmitPassword2FA}
                text={constants.accept}
                transitionType="typeOne"
                disabled={
                  this.state.passwordGGAuthen.length !== 6 ? true : false
                }
              />
            </div>
          </div>
          <div className="row m2-b">
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }
}

Authentication2FAModal.defaultProps = defaultProps;

const authentication2FAModal = {
  show: (props: IAuthentication2FAModalProps) => {
    return Modal.event("MODAL_OVER", {
      state: "show",
      extraProps: { size: "md" },
      component: <Authentication2FAModal {...props} />,
    });
  },
  hide: () => Modal.event("MODAL_OVER", { state: "hide" }),
};

export default authentication2FAModal;
