import { connect } from "react-redux"
import MainContainer from "./Main.container"
import mainSelector from "../../reduxs/main/selector"
import { getDataMain } from "../../reduxs/main/main/action"
import { getDataNews } from "../../reduxs/main/news/action"
import { onChangeIsLoaddingAction } from "../../reduxs/Loader/action"

const mapStateToProps = (state: RootReducers): IMainProps => {
  const mainState = mainSelector.main(state)
  const newsState = mainSelector.news(state)

  return {
    mainCode: mainState.code,
    mainError: mainState.error,
    mainIsFetching: mainState.isFetching,
    mainData: mainState.data,

    newsCode: newsState.code,
    newsError: newsState.error,
    newsIsFetching: newsState.isFetching,
    newsData: newsState.data,
  }
}
const mapDispatchToProps: IMainActionProps = {
  getDataMain: getDataMain,
  getDataNews: getDataNews,
  onChangeIsLoaddingAction: onChangeIsLoaddingAction,
}
export default connect(mapStateToProps, mapDispatchToProps)(MainContainer)
