import React, { Component } from "react";
import "./Loader.style.scss";

const defaultProps: ILoaderProps = {
  isLoading: false,
  isCenter: false
};

class LoaderComponent extends Component<ILoaderProps, ILoaderState> {
  static defaultProps = defaultProps;
  state: ILoaderState = {
    loading: false,
  };

  componentDidMount() {
    this.setState({ loading: false });
  }

  componentDidUpdate(prevProps: ILoaderProps) {
    if (prevProps.isLoading !== this.props.isLoading) {
      this.setState({ loading: this.props.isLoading });
    }
  }
  containerClass =()=>(`
    loader-container 
    ${this.state.loading? "show": "hide"}
    ${this.props.isCenter? "center-windows": "right-windows"}
    `);

  render() {
    return (
      <div className={this.containerClass()}>
        <div className={`lds-ellipsis `}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
}

export default LoaderComponent;
