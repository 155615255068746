import { Button, Input, InputNumber, Modal, ResponsiveIcon } from "components";
import color from "constants/colors";
import { LOTTO_GAME_TYPE_NAME } from "constants/variables";
import {
  clone,
  filter,
  get,
  groupBy,
  isEmpty,
  keys,
  map,
  noop,
  reduce,
  split,
  sum,
  trim,
} from "lodash";
import React, { Component } from "react";
import { number } from "utils";
import "./betEdit.style.scss";
import CloseIcon from "assets/images/global/closePink/closePink.png";
import CloseIcon2x from "assets/images/global/closePink/closePink@2x.png";
import CloseIcon3x from "assets/images/global/closePink/closePink@3x.png";

const constants = {
  editPoll: "แก้ไขโพย",
  cancelEditPoll: "ยกเลิกแก้ไข",
  deletePoll: "ลบโพย",
  editBetList: "แก้ไขรายการเดิมพัน",
  baht: "บาทละ",
  remark: "ชื่อโพย :",
  defaultValue: "เดิมพันราคาเท่ากัน :",
  totalBenefit: "เมื่อชนะทั้งหมด :",
  totalToPay: "เดิมพันทั้งหมด :",
};

const mappingThead: ISummaryTableHead[] = [
  { id: 1, name: "เลข" },
  { id: 2, name: "ราคา" },
  { id: 3, name: "บาทละ" },
  { id: 4, name: "ชนะได้" },
];

const defaultProps: IBetEditProps = {
  data: {
    betList: [],
    remark: "",
    totalValue: 0,
  },
  betRates: [],
  lottoSlug: "LOTTER_YEGEE",
  benefitValue: "",

  onConfirm: () => {
    noop();
  },
  onCancel: () => {
    noop();
  },
  onDelete: () => {
    noop();
  },
};

const DEFAULT_GAME_VALUE = "1.00";
const LAO_SULT_FIXED_GAME_VALUE = "120";

class BetEdit extends Component<IBetEditProps, IBetEditState> {
  static defaultProps = defaultProps;

  state: IBetEditState = {
    betList: [],
    remark: "",
    totalValue: 0.0,
    defaultValue:
      this.props.lottoSlug === "LOTTER_LAO_SUITE"
        ? LAO_SULT_FIXED_GAME_VALUE
        : DEFAULT_GAME_VALUE,
  };

  componentDidMount() {
    const betList = clone(this.props.data.betList);
    const remark = clone(this.props.data.remark);
    const totalValue = clone(this.props.data.totalValue);
    this.setState({ betList: betList });
    this.setState({ remark: remark });
    this.setState({ totalValue: totalValue });
  }

  calculateModalBenefitValue = (
    betValueString: string = "0",
    rate: string = "0"
  ) => {
    if (this.props.lottoSlug === "LOTTER_LAO_SUITE") {
      return number.castToMoney(Number(rate));
    }
    const betValue = Number(number.decimalsFormat(betValueString)) || 0;
    const calculatedBenefit = Number(rate) * betValue;
    return number.castToMoney(calculatedBenefit);
  };

  handleModalOnChangeValue = (value: string, seq: number) => {
    const betArray: ILottoNumber[] = filter<ILottoNumber>(
      this.state.betList,
      (_, index) => index === seq
    );
    const betObject: ILottoNumber = get(betArray, "0", {}) as any;
    const newObject: ILottoNumber = { ...betObject, value };
    const newBetList: ILottoNumber[] = this.state.betList;
    newBetList[seq] = newObject;
    this.setState({ betList: newBetList });
  };

  handleModalOnRemove = (seq: number) => {
    /* if (this.state.betList.length > 1) {
      const newBetList: ILottoNumber[] = filter<ILottoNumber>(
        this.state.betList,
        (_, index) => index !== seq
      );
      this.setState({ betList: newBetList });
    } */
    const newBetList: ILottoNumber[] = filter<ILottoNumber>(
      this.state.betList,
      (_, index) => index !== seq
    );
    this.setState({ betList: newBetList });
  };

  renderTableHead = (values: Array<ISummaryTableHead>) => {
    const mapHeadTable = map(
      values,
      (data: ISummaryTableHead, index: number) => {
        return (
          <th className={`text-right`} key={index} colSpan={2}>
            <span className="col">{data.name}</span>
          </th>
        );
      }
    );
    return mapHeadTable;
  };

  handleModalOnDefaultValueBlur = () => {
    const newBetList = map(this.state.betList, (item) => ({
      ...item,
      value: this.state.defaultValue,
    }));
    const finalBetNumberList = newBetList.filter(
      (bet) => !isEmpty(bet.value) && Number(bet.value) >= 0
    );
    this.setState({ betList: finalBetNumberList });
  };

  modalNotAllowZeroValues = (values: any) => {
    const { floatValue } = values;
    return floatValue! > 0;
  };

  renderLottoList = (betList: ILottoNumber[]) => {
    const groupingLottoListObject: {
      [name in TLottoGameType]?: (ILottoNumber & { seq?: number })[];
    } = groupBy<ILottoNumber & { seq?: number }>(
      betList.map((bet, betIndex) => ({ ...bet, seq: betIndex })),
      "type"
    );

    const renderTableBodyItems = (values: Array<ILottoNumber>) => {
      const mapData = keys(groupingLottoListObject).map(
        (lottos, lottosIndex) => {
          const LottoListComponent = groupingLottoListObject[
            lottos as TLottoGameType
          ]?.map((lotto, lottosIndex) => {
            const splitedType = split(lotto.slug!, "_");
            const lotterType = split(
              lotto.slug!,
              "_",
              get(splitedType, "length", 0) - 1
            ).reduce((prev, curr) => `${prev}_${curr}`)
            const betType = `${lotterType}_${lotto.type}`;
            const betRateValue =
              isEmpty(lotto.rate) || Number(lotto.rate) <= 0
                ? get(
                  this.props.betRates.filter((rate) => rate.type === betType),
                  "0.rate",
                  "0"
                )
                : lotto.rate;
            return (
              <tr
                className={`${lottosIndex % 2 === 0 ? "color-row" : ""
                  } mapped-data`}
                key={`lotto-${lotto.type}-${lottosIndex}`}
              >
                <td className="text-right" colSpan={2}>
                  <span className="col" id="number">
                    {lotto.bet}
                  </span>
                </td>
                <td className="text-right" colSpan={2}>
                  <InputNumber
                    allowLeadingZeros={false}
                    prefix="฿ "
                    thousandSeparator
                    allowNegative={false}
                    decimalScale={0}
                    id={`values-${lotto.seq}`}
                    name={`values-${lotto.seq}`}
                    disabled={this.props.lottoSlug === "LOTTER_LAO_SUITE"}
                    backgroundColor={color.PRIMARY_BG}
                    onValueChange={({ value }) =>
                      this.handleModalOnChangeValue(value, lotto.seq!)
                    }
                    value={lotto.value}
                    isAllowed={this.modalNotAllowZeroValues}
                  />
                </td>
                <td className="text-right" colSpan={2}>
                  <span className="col">
                    {number.castToMoney(Number(betRateValue))}
                  </span>
                </td>
                <td className="text-right" colSpan={2}>
                  <div className="delete-lotto-button-container d-flex align-items-center">
                    <div className="overflow-auto w-75 body-7 m1-r">
                      {this.calculateModalBenefitValue(
                        lotto.value || "0",
                        betRateValue || "0"
                      )}
                    </div>
                    <div
                      className="d-flex justify-content-end w-25 h-100"
                      onClick={() => this.handleModalOnRemove(lotto.seq!)}
                    >
                      <ResponsiveIcon
                        icon={{
                          x1: CloseIcon,
                          x2: CloseIcon2x,
                          x3: CloseIcon3x,
                        }}
                        className="delete-lotto-button-icon"
                        alt="delete-lotto-icon"
                      />
                    </div>
                  </div>
                </td>
              </tr>
            );
          });
          return (
            <React.Fragment key={`lotto-type-${lottosIndex}`}>
              <tr className="mapped-data">
                <td className="text-left pl-3 color-row-3 p-2" colSpan={8}>
                  <h6>{LOTTO_GAME_TYPE_NAME[lottos as TLottoGameType]}</h6>
                </td>
              </tr>
              {LottoListComponent}
            </React.Fragment>
          );
        }
      );

      const renderBody = mapData;
      return renderBody;
    };
    return <>{renderTableBodyItems(this.state.betList)}</>;
  };

  handleOnChangeNote = (noteValue: string) => {
    this.setState({ remark: noteValue });
  };

  calculateModalTotalValue = () => {
    const totally: number = reduce(
      this.state.betList,
      (prev, curr) => {
        const betValue = Number(number.decimalsFormat(curr.value || "0"));
        return sum([prev, betValue]);
      },
      0
    );
    return totally;
  };

  calculateModalTotalBenefit = () => {
    const totally: number = reduce(
      this.state.betList,
      (prev, curr) => {
        const splitedType = split(curr.slug!, "_");
        const lotterType = split(
          curr.slug!,
          "_",
          get(splitedType, "length", 0) - 1
        ).reduce((prevType, currType) => `${prevType}_${currType}`);
        const betType = `${lotterType}_${curr.type}`;
        const betRateValue =
          isEmpty(curr.rate) || Number(curr.rate) <= 0
            ? get(
              this.props.betRates.filter((rate) => rate.type === betType),
              "0.rate",
              "0"
            )
            : curr.rate;
        const betValue = number.decimalsFormat(curr.value || "0");
        const total =
          this.props.lottoSlug === "LOTTER_LAO_SUITE"
            ? Number(betRateValue)
            : Number(betRateValue) * betValue;
        return sum([prev, total]);
      },
      0
    );
    return number.castToMoney(totally);
  };

  renderBetListTotal = () => {
    return (
      <>
        <tr className={"color-row"}>
          <td className="text-right" colSpan={1}>
            <span className="col" id="remark">
              {constants.remark}
            </span>
          </td>
          <td className="text-right" colSpan={7}>
            <Input
              id="note"
              name="note"
              backgroundColor={color.PRIMARY_BG}
              borderColor={color.TERTIARY_TEXT}
              onChange={(e) => this.handleOnChangeNote(e.target.value)}
              onBlur={(e) => {
                let noteValue = trim(e.target.value)
                this.setState({remark: noteValue})
              }}
              value={this.state.remark}
            />
          </td>
        </tr>
        <tr className={"color-row-2"}>
          <td className="text-right" colSpan={6}>
            <span className="col" id="defaultValue">
              {constants.defaultValue}
            </span>
          </td>
          <td className="text-right" colSpan={2}>
            <InputNumber
              allowLeadingZeros={false}
              prefix="฿ "
              thousandSeparator
              decimalScale={0}
              allowNegative={false}
              id={`maked-all-value`}
              name="maked-all-value"
              backgroundColor={color.PRIMARY_BG}
              onValueChange={({ value }) =>
                this.setState({ defaultValue: value })
              }
              onBlur={this.handleModalOnDefaultValueBlur}
              value={this.state.defaultValue}
              isAllowed={this.modalNotAllowZeroValues}
            />
          </td>
        </tr>
        <tr className={"color-row"}>
          <td className="text-right" colSpan={6}>
            <span className="col" id="defaultValue">
              {constants.totalToPay}
            </span>
          </td>
          <td className="text-right" colSpan={2}>
            <div className="body-7 overflow-auto">
              {number.castToMoney(this.calculateModalTotalValue())}
            </div>
          </td>
        </tr>
        <tr className={"color-row-2"}>
          <td className="text-right" colSpan={6}>
            <span className="col" id="defaultValue">
              {constants.totalBenefit}
            </span>
          </td>
          <td className="text-right" colSpan={2}>
            <div className="body-7 overflow-auto">
              {this.calculateModalTotalBenefit()}
            </div>
          </td>
        </tr>
      </>
    );
  };
  render() {
    const TableHead = this.renderTableHead(mappingThead);
    const LottoListComponent = this.renderLottoList(this.state.betList);
    const BetListTotalComponent = this.renderBetListTotal;

    return (
      <div className="bet-edit-lotto-modal-container">
        <div className="box-shadow">
          <div className="container">
            <div className="row m2-b">
              <div className="col">
                <div className="d-flex flex-row justify-content-between overflow-auto">
                  <h3>
                    {constants.editBetList} #{this.props.data.seq! + 1}
                  </h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="summary-container">
                  <div className="table-container">
                    <div className="table-responsive-xl">
                      <table className="col mapped-data">
                        <thead className="mapped-data">
                          <tr>{TableHead}</tr>
                        </thead>
                        <tbody className="mapped-data">
                          {LottoListComponent}
                        </tbody>
                      </table>
                      <table className="col">
                        <thead>
                          <></>
                        </thead>
                        <tbody>
                          <BetListTotalComponent />
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row m2-t">
              <div className="col">
                <Button
                  id="edit-poll-submit-button"
                  text={constants.editPoll}
                  transitionType="typeOne"
                  onClick={() =>
                    this.props.onConfirm(
                      this.state.betList,
                      this.state.remark,
                      this.calculateModalTotalValue()
                    )
                  }
                  disabled= {this.state.remark.length <= 0}
                />
              </div>
            </div>
            <div className="row m1-t justify-content-center">
              <div className="col-auto">
                <Button
                  id="cancel-edit-poll-submit-button"
                  text={constants.cancelEditPoll}
                  transitionType="typeOne"
                  textColor={color.SECONDARY_TEXT}
                  onClick={this.props.onCancel}
                  canHover={false}
                  backgroundColor={color.PRIMARY_BG}
                />
              </div>
            </div>
            <div className="row m1-t justify-content-center">
              <div className="col-auto">
                <Button
                  id="delete-poll-submit-button"
                  text={constants.deletePoll}
                  transitionType="typeOne"
                  textColor={color.PRIMARY_RED}
                  onClick={this.props.onDelete}
                  canHover={false}
                  backgroundColor={color.PRIMARY_BG}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BetEdit.defaultProps = defaultProps;

const BetEditModal = {
  show: (props: IBetEditProps) => {
    return Modal.event("MODAL", {
      state: "show",
      extraProps: { size: "lg" },
      component: <BetEdit {...props} />,
    });
  },
  hide: () => Modal.event("MODAL", { state: "hide" }),
};

export default BetEditModal;
