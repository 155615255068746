import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { getLotteryListAction, getV2LotteryListAction } from "../actionCreator";
import { fetchGetLottoSchedule, fetchGetV2LottoSchedule } from "../services";

const getDataLotteryList = () => async (dispatch: Dispatch) => {
  dispatch(getLotteryListAction.request({}));
  return fetchGetLottoSchedule()
    .then((res: AxiosResponse<IAPIResponse<IResponseAgentLotteryList[]>>) => {
      dispatch(getLotteryListAction.success(res));
    })
    .catch((error: AxiosError<IAPIResponse>) =>
      dispatch(getLotteryListAction.failure(error))
    );
};

const getDataV2LotteryList = (name?: string) => async (dispatch: Dispatch) => {
  dispatch(getV2LotteryListAction.request({}));
  return fetchGetV2LottoSchedule(name)
    .then((res: AxiosResponse<IAPIResponse<IV2RespAgentLotterySchedule[]>>) => {
      dispatch(getV2LotteryListAction.success(res));
    })
    .catch((error: AxiosError<IAPIResponse>) =>
      dispatch(getV2LotteryListAction.failure(error))
    );
};

export { getDataLotteryList, getDataV2LotteryList };
