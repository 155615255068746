import colors from 'constants/colors'
import { number } from 'utils';


export const LEVEL_TYPE_CHANGE_WORD: { [type in TLevelType]: string } = {
  MEMBER: "Member",
  SUPER_AGENT: "Master",
  AGENT: "Agent",
  OWNER: "Owner",
  SENIOR: "Senior"
};

export const LEVEL_TYPE_PREMISSION: { [type in LEVEL_TYPE_PREMISSION]: string[] } = {
  OWNER: ["SENIOR", "SUPER_AGENT", "AGENT"],
  SUPER_AGENT: ["AGENT"],
  AGENT: [],
  MEMBER: [],
  SENIOR: [],
};

export const THAI_LEVEL_TYPE_PREMISSION: { [type in LEVEL_TYPE_PREMISSION]: string[] } = {
  OWNER: ["Master", "Agent"],
  SUPER_AGENT: ["Agent"],
  AGENT: [],
  MEMBER: [],
  SENIOR: [],
};


export const STATUS_BET: { [type in IStatusBetMe | "All"]: { status: string, color: string } } = {
  All: { status: 'ทั้งหมด', color: colors.TERTIARY_TEXT },
  WAIT: { status: 'รอผล', color: colors.PRIMARY_YELLOW },
  WINNER: { status: 'ถูกรางวัล', color: colors.SECONDARY_GREEN },
  LOSER: { status: 'ไม่ถูกรางวัล', color: colors.PRIMARY_RED },
  CANCEL: { status: 'ยกเลิก', color: colors.TERTIARY_TEXT },
}

export const STATUS_FINANCE: { [type in TFinanceStatus | "All"]: { status: string, color: string } } = {
  All: { status: 'ทั้งหมด', color: colors.TERTIARY_TEXT },
  SUCCESS: { status: 'เคลียร์', color: colors.SECONDARY_GREEN },
  CONDITION: { status: 'ติดไว้ก่อน', color: colors.PRIMARY_YELLOW },
  WAIT: { status: 'ยังไม่เคลียร์', color: colors.TERTIARY_TEXT },
  PENDING: { status: 'ทำรายการค้างไว้', color: colors.PRIMARY_YELLOW }
}


export const STATUS_FINANCE_ME: { [type in TFinanceStatusMe | "All"]: { status: string, color: string } } = {
  All: { status: 'ทั้งหมด', color: colors.TERTIARY_TEXT },
  WAIT: { status: 'ยังไม่เคลียร์', color: colors.TERTIARY_TEXT },
  SUCCESS: { status: 'เคลียร์', color: colors.SECONDARY_GREEN },
  CANCEL: { status: 'ยังไม่เคลียร์', color: colors.TERTIARY_TEXT }
}

export const STATUS_FINANCE_SUMMARY: { [type in TFinanceStatusSummary]: { status: string, color: string } } = {
  All: { status: 'ทั้งหมด', color: colors.TERTIARY_TEXT },
  WAIT: { status: 'ยังไม่เคลียร์', color: colors.TERTIARY_TEXT },
  SUCCESS: { status: 'เคลียร์', color: colors.SECONDARY_GREEN },
}

export const STATUS_CHILD_BET = (totalBet: number = 0, rate: number = 0): { [type in TChildBetStatus]: { status: string, color: string } } => {
  return {
    WINNER: { status: number.addComma(totalBet * rate), color: colors.SECONDARY_TEXT },
    LOSER: { status: 'ไม่ถูกรางวัล', color: colors.PRIMARY_RED },
    WAIT: { status: 'รอผล', color: colors.PRIMARY_YELLOW },
    CANCEL: { status: 'ยกเลิก', color: colors.SECONDARY_TEXT }
  }
}

export const FINANCE_CUSTOMER_LIST: { [type in string]: string } = {
  BillPrint: "ออกใบเสร็จ",
  BetSumHistory: "สรุปการเดิมพัน",
  CustomerList: "ข้อมูลชื่อลูกค้า",
  FinanceHost: "เคลียร์ยอดหัวสมาชิก",
  FinanceChild: "เคลียร์ยอดลูกสมาชิก",
  CancelBill: "ยกเลิกโพย",
}
export const FINANCE_HOST_LIST: { [type in string]: string } = {
  BetSumHistory: "สรุปการเดิมพัน",
  FinanceHost: "เคลียร์ยอดหัวสมาชิก",
  FinanceChild: "เคลียร์ยอดลูกสมาชิก",
  CancelBill: "ยกเลิกโพย",
}

export const STATUS_SELECT: { [type in TStatus]: string } = {
  CLOSE: "ปิดใช้งาน",
  OPEN: "เปิดให้ใช้งาน",
};

export const STATUS: { [type in TStatus]: string } = {
  CLOSE: "ปิด",
  OPEN: "เปิด",
};

export const REMAINDER_HOLD_SELECT: { [type in TRemainderHold]: string } = {
  KEEP: "เก็บ",
  NOT_KEEP: 'ไม่เก็บ',
}

export const KEY_PAY_RATE = {
  GOVN: "หวยรัฐบาล",
  LAO_SUITE: "หวยลาว",
  GSB: "หวยออมสิน",
  LOTTORY_NET: "กลุ่มหวยหุ้น",
  LOTTORY_4D: "กลุ่มหวย 4D",
};

export const AGENT_FINANCE_TYPE: { [type in TAgentFinanceType]: string } = {
  BALANCE_INCOME: "รับยอด",
  BALANCE_OUTCOME: 'ส่งยอด',
  PAY_COMPLETE: "ปิดยอด",
  PAY_INCOMPLETE: "ปฏิเสธยอด",
  UPGRADE_LIMIT_CREDIT_CHILD: "เพิ่มเครดิตจำกัดลูกสาย",
  DOWNGRADE_LIMIT_CREDIT_CHILD: "ลดเครดิตจำกัดลูกสาย",
}

export const LOTTO_SLUG_NAME: { [type in TLottoSlug]: string } = {
  LOTTER_YEGEE: "หวยยี่กี",
  LOTTER_GOVN: "หวยรัฐบาล",
  LOTTER_BAAC: "หวยธกส",
  LOTTER_GSB: "หวยออมสิน",
  LOTTER_NAT_SHARE_LAO: "หวยลาว",
  LOTTER_LAO_SUITE: "หวยลาว (เลขชุด)",
  LOTTER_TH_SHARE_MORNING: "หวยหุ้นไทยเช้า",
  LOTTER_TH_SHARE_MIDDAY: "หวยหุ้นไทยเที่ยง",
  LOTTER_TH_SHARE_AFTERNOON: "หวยหุ้นไทยบ่าย",
  LOTTER_TH_SHARE_EVENING: "หวยหุ้นไทยเย็น",
  LOTTER_NAT_SHARE_DOWNJON: "หวยหุ้นดาวน์โจนส์",
  LOTTER_NAT_SHARE_EGYPT: "หวยหุ้นต่างประเทศอียิปต์",
  LOTTER_NAT_SHARE_GERMANY: "หวยหุ้นต่างประเทศเยอรมัน",
  LOTTER_NAT_SHARE_NIKAII_MORNING: "หวยหุ้นนิเคอิรอบเช้า",
  LOTTER_NAT_SHARE_NIKAII_AFTERNOON: "หวยหุ้นนิเคอิรอบบ่าย",
  LOTTER_NAT_SHARE_CHINA_MORNING: "หวยหุ้นจีนรอบเช้า",
  LOTTER_NAT_SHARE_CHINA_AFTERNOON: "หวยหุ้นจีนรอบบ่าย",
  LOTTER_NAT_SHARE_TAIWAN: "หวยหุ้นไต้หวัน",
  LOTTER_NAT_SHARE_KOREA: "หวยหุ้นเกาหลี",
  LOTTER_NAT_SHARE_SINGAPORE: "หวยหุ้นสิงคโปร์",
  LOTTER_NAT_SHARE_INDIA: "หวยหุ้นอินเดีย",
  LOTTER_NAT_SHARE_HANOI_SPECIAL: "หวยหุ้นฮานอยพิเศษ",
  LOTTER_NAT_SHARE_MALAY: "หวยหุ้นมาเลย์",
  LOTTER_NAT_SHARE_VIETNAM_HANOI: "หวยหุ้นเวียดนาม / ฮานอย",
  LOTTER_NAT_SHARE_VIETNAM_HANOI_VIP: "หวยหุ้นเวียดนาม / ฮานอย VIP",
  LOTTER_NAT_SHARE_HANOI_4D: "หวยหุ้นฮานอย (4D)",
  LOTTER_NAT_SHARE_RUSSIA: "หวยหุ้นรัสเซีย",
  LOTTER_NAT_SHARE_ENGLISH: "หวยหุ้นอังกฤษ",
  LOTTER_NAT_SHARE_HUNGSENG_MORNING: "หวยหุ้นฮั่งเส็งรอบเช้า",
  LOTTER_NAT_SHARE_HUNGSENG_AFTERNOON: "หวยหุ้นฮั่งเส็งรอบบ่าย",

  LOTTER_LAO_HD: "หวยลาว HD",
  LOTTER_HANOI_TV: "หวยฮานอย TV",
  LOTTER_LAO_VIP: "หวยลาว VIP",
  LOTTER_LAO_STAR: "หวยลาวสตาร์",
  LOTTER_HANOI_RED_CROSS: "หวยฮานอย กาชาด",
  LOTTER_HANOI_SAMAKKEE: "หวยฮานอยสามัคคี",
  LOTTER_HANOI_PHATTANA: "หวยฮานอยพัฒนา",
  LOTTER_LAO_SAMAKKEE: "หวยลาวสามัคคี",
  LOTTER_LAO_ASEAN: "หวยลาวอาเซียน",
  LOTTER_LAO_STAR_VIP: "หวยลาวสตาร์ VIP",
  LOTTER_ENGLISH_VIP: "หวยอังกฤษ VIP",
  LOTTER_GERMANY_VIP: "หวยเยอรมัน VIP",
  LOTTER_LAO_RED_CROSS: "หวยลาว กาชาด",
  LOTTER_RUSSIA_VIP: "หวยรัฐเซีย VIP",
  LOTTER_DOWNJON_VIP: "หวยดาวโจนส์ VIP",
  LOTTER_HANOI_ASEAN: "หวยฮานอยอาเซียน",
  LOTTER_LAO_TV: "หวยลาว TV",
  LOTTER_HANOI_HD: "หวยฮานอย HD",
  LOTTER_HANOI_STAR: "หวยฮานอยสตาร์",
  LOTTER_HANOI: "หวยฮานอย",
  LOTTER_MALAY: "หวยมาเลเซีย",
  LOTTER_NAT_SHARE_HUNGSENG_MORNING_VIP: "หวยหุ้นฮั่งเส็งรอบเช้า VIP",
  LOTTER_NAT_SHARE_HUNGSENG_AFTERNOON_VIP: "หวยหุ้นฮั่งเส็งรอบบ่าย VIP",
  LOTTER_NAT_SHARE_CHINA_MORNING_VIP: "หวยหุ้นจีนรอบเช้า VIP",
  LOTTER_NAT_SHARE_CHINA_AFTERNOON_VIP: "หวยหุ้นจีนรอบบ่าย VIP",
  LOTTER_NAT_SHARE_SINGAPORE_VIP: "หวยหุ้นสิงคโปร์ VIP",
  LOTTER_NAT_SHARE_KOREA_VIP: "หวยหุ้นเกาหลี VIP",
  LOTTER_NAT_SHARE_TAIWAN_VIP: "หวยหุ้นไต้หวัน VIP",
  LOTTER_NAT_SHARE_NIKAII_MORNING_VIP: "หวยหุ้นนิเคอิรอบเช้า VIP",
  LOTTER_NAT_SHARE_NIKAII_AFTERNOON_VIP: "หวยหุ้นนิเคอิรอบบ่าย VIP",
  LOTTER_HANOI_EXTRA: "ฮานอยเอ็กซ์ตร้า",
  LOTTER_HANOI_CHINESE_NEW_YEAR: 'หวยฮานอยตรุษจีน',
  LOTTER_NAT_SHARE_DOWNJON_STAR: 'หวยหุ้นดาวน์โจน สตาร์'
};

export const BET_TYPE_NAME: { [type in TBetType]: string } = {
  LOTTERY: "หวยออนไลน์",
  GAME: "เกมพนัน",
}

export const AGENT_STATUS_TO_NAME: { [type in TAgentStatus]: string } = {
  PRE_REGISTERED: "พึ่งสมัครสมาชิก",
  LOGIN_OTP: "เข้าสู้ระบบด้วย OTP",
  NORMAL: "ปกติ",
  PASSWORD_EXPIRED: "รหัสหมดอายุ",
  WAIT_AUTHEN: "รอการยืนยันตัวตน",
  WAIT_SECRET: "รอการเปิดใช้งาน 2FA",
  VERIFY: "ตรวจสอบ 2FA",
}

export const CODE_TO_NAME: { [type in TLottoType]: string } = {
  LAO_VIP: "หวยลาว VIP",
  YEGEE: "หวยยี่กี",
  GOVN: "หวยรัฐบาล",
  BAAC: "หวยธกส",
  GSB: "หวยออมสิน",
  NAT_SHARE_LAO: "หวยลาว",
  LAO_SUITE: "หวยลาว (เลขชุด)",
  TH_SHARE_MORNING: "หุ้นไทยเช้า",
  TH_SHARE_MIDDAY: "หุ้นไทยเที่ยง",
  TH_SHARE_AFTERNOON: "หุ้นไทยบ่าย",
  TH_SHARE_EVENING: "หุ้นไทยเย็น",
  NAT_SHARE_DOWNJON: "หุ้นดาวโจนส์",
  NAT_SHARE_EGYPT: "หุ้นต่างประเทศอียิปต์",
  NAT_SHARE_GERMANY: "หุ้นต่างประเทศเยอรมัน",
  NAT_SHARE_NIKAII_MORNING: "หุ้นนิเคอิรอบเช้า",
  NAT_SHARE_NIKAII_AFTERNOON: "หุ้นนิเคอิรอบบ่าย",
  NAT_SHARE_CHINA_MORNING: "หุ้นจีนรอบเช้า",
  NAT_SHARE_CHINA_AFTERNOON: "หุ้นจีนรอบบ่าย",
  NAT_SHARE_TAIWAN: "หุ้นไต้หวัน",
  NAT_SHARE_KOREA: "หุ้นเกาหลี",
  NAT_SHARE_SINGAPORE: "หุ้นสิงคโปร์",
  NAT_SHARE_INDIA: "หุ้นอินเดีย",
  NAT_SHARE_HANOI_SPECIAL: "หุ้นฮานอยพิเศษ",
  NAT_SHARE_MALAY: "หุ้นมาเลย์",
  NAT_SHARE_VIETNAM_HANOI: "หุ้นฮานอย",
  NAT_SHARE_VIETNAM_HANOI_VIP: "หุ้นฮานอย VIP",
  NAT_SHARE_HANOI_4D: "หุ้นฮานอย (4D)",
  NAT_SHARE_RUSSIA: "หุ้นรัสเซีย",
  NAT_SHARE_ENGLISH: "หุ้นอังกฤษ",
  NAT_SHARE_HUNGSENG_MORNING: "หุ้นฮั่งเส็งรอบเช้า",
  NAT_SHARE_HUNGSENG_AFTERNOON: "หุ้นฮั่งเส็งรอบบ่าย",
  LAO_HD: "หวยลาว HD",
  HANOI_TV: "หวยฮานอย TV",
  LAO_STAR: "หวยลาวสตาร์",
  HANOI_RED_CROSS: "หวยฮานอย กาชาด",
  HANOI_SAMAKKEE: "หวยฮานอยสามัคคี",
  HANOI_PHATTANA: "หวยฮานอยพัฒนา",
  LAO_SAMAKKEE: "หวยลาวสามัคคี",
  LAO_ASEAN: "หวยลาวอาเซียน",
  LAO_STAR_VIP: "หวยลาวสตาร์ VIP",
  ENGLISH_VIP: "หวยอังกฤษ VIP",
  GERMANY_VIP: "หวยเยอรมัน VIP",
  LAO_RED_CROSS: "หวยลาว กาชาด",
  RUSSIA_VIP: "หวยรัสเซีย VIP",
  DOWNJON_VIP: "หวยดาวโจนส์ VIP",
  HANOI_ASEAN: "หวยฮานอยอาเซียน",
  LAO_TV: "หวยลาว TV",
  HANOI_HD: "หวยฮานอย HD",
  HANOI_STAR: "หวยฮานอยสตาร์",
  HANOI: "หวยฮานอย",
  MALAY: "หวยมาเลเซีย",
  NAT_SHARE_HUNGSENG_MORNING_VIP: "หุ้นฮั่งเส็งรอบเช้า VIP",
  NAT_SHARE_HUNGSENG_AFTERNOON_VIP: "หุ้นฮั่งเส็งรอบบ่าย VIP",
  NAT_SHARE_CHINA_MORNING_VIP: "หุ้นจีนรอบเช้า VIP",
  NAT_SHARE_CHINA_AFTERNOON_VIP: "หุ้นจีนรอบบ่าย VIP",
  NAT_SHARE_SINGAPORE_VIP: "หุ้นสิงคโปร์ VIP",
  NAT_SHARE_KOREA_VIP: "หุ้นเกาหลี VIP",
  NAT_SHARE_TAIWAN_VIP: "หุ้นไต้หวัน VIP",
  NAT_SHARE_NIKAII_MORNING_VIP: "หุ้นนิเคอิรอบเช้า VIP",
  NAT_SHARE_NIKAII_AFTERNOON_VIP: "หุ้นนิเคอิรอบบ่าย VIP",
  HANOI_EXTRA: "หวยฮานอยเอ็กซ์ตร้า",
  HANOI_CHINESE_NEW_YEAR: 'หวยฮานอยตรุษจีน',
  NAT_SHARE_DOWNJON_STAR: 'หวยหุ้นดาวน์โจน สตาร์'
};

export const CODE_TO_SHORT_NAME: { [type in TLottoType]: string } = {
  LAO_VIP: "ลาว VIP",
  YEGEE: "ยี่กี",
  GOVN: "รัฐบาล",
  BAAC: "ธกส",
  GSB: "ออมสิน",
  NAT_SHARE_LAO: "ลาว",
  LAO_SUITE: "ลาว (เลขชุด)",
  TH_SHARE_MORNING: "หุ้นไทยเช้า",
  TH_SHARE_MIDDAY: "หุ้นไทยเที่ยง",
  TH_SHARE_AFTERNOON: "หุ้นไทยบ่าย",
  TH_SHARE_EVENING: "หุ้นไทยเย็น",
  NAT_SHARE_DOWNJON: "หุ้นดาวโจนส์",
  NAT_SHARE_EGYPT: "หุ้นต่างประเทศอียิปต์",
  NAT_SHARE_GERMANY: "หุ้นต่างประเทศเยอรมัน",
  NAT_SHARE_NIKAII_MORNING: "หุ้นนิเคอิรอบเช้า",
  NAT_SHARE_NIKAII_AFTERNOON: "หุ้นนิเคอิรอบบ่าย",
  NAT_SHARE_CHINA_MORNING: "หุ้นจีนรอบเช้า",
  NAT_SHARE_CHINA_AFTERNOON: "หุ้นจีนรอบบ่าย",
  NAT_SHARE_TAIWAN: "หุ้นไต้หวัน",
  NAT_SHARE_KOREA: "หุ้นเกาหลี",
  NAT_SHARE_SINGAPORE: "หุ้นสิงคโปร์",
  NAT_SHARE_INDIA: "หุ้นอินเดีย",
  NAT_SHARE_HANOI_SPECIAL: "หุ้นฮานอยพิเศษ",
  NAT_SHARE_MALAY: "หุ้นมาเลย์",
  NAT_SHARE_VIETNAM_HANOI: "หุ้นฮานอย",
  NAT_SHARE_VIETNAM_HANOI_VIP: "หุ้นฮานอย VIP",
  NAT_SHARE_HANOI_4D: "หุ้นฮานอย (4D)",
  NAT_SHARE_RUSSIA: "หุ้นรัสเซีย",
  NAT_SHARE_ENGLISH: "หุ้นอังกฤษ",
  NAT_SHARE_HUNGSENG_MORNING: "หุ้นฮั่งเส็งรอบเช้า",
  NAT_SHARE_HUNGSENG_AFTERNOON: "หุ้นฮั่งเส็งรอบบ่าย",
  LAO_HD: "ลาว HD",
  HANOI_TV: "ฮานอย TV",
  LAO_STAR: "ลาวสตาร์",
  HANOI_RED_CROSS: "ฮานอย กาชาด",
  HANOI_SAMAKKEE: "ฮานอยสามัคคี",
  HANOI_PHATTANA: "ฮานอยพัฒนา",
  LAO_SAMAKKEE: "ลาวสามัคคี",
  LAO_ASEAN: "ลาวอาเซียน",
  LAO_STAR_VIP: "ลาวสตาร์ VIP",
  ENGLISH_VIP: "อังกฤษ VIP",
  GERMANY_VIP: "เยอรมัน VIP",
  LAO_RED_CROSS: "ลาว กาชาด",
  RUSSIA_VIP: "รัสเซีย VIP",
  DOWNJON_VIP: "ดาวโจนส์ VIP",
  HANOI_ASEAN: "ฮานอยอาเซียน",
  LAO_TV: "ลาว TV",
  HANOI_HD: "ฮานอย HD",
  HANOI_STAR: "ฮานอยสตาร์",
  HANOI: "ฮานอย",
  MALAY: "มาเลเซีย",
  NAT_SHARE_HUNGSENG_MORNING_VIP: "หุ้นฮั่งเส็งรอบเช้า VIP",
  NAT_SHARE_HUNGSENG_AFTERNOON_VIP: "หุ้นฮั่งเส็งรอบบ่าย VIP",
  NAT_SHARE_CHINA_MORNING_VIP: "หุ้นจีนรอบเช้า VIP",
  NAT_SHARE_CHINA_AFTERNOON_VIP: "หุ้นจีนรอบบ่าย VIP",
  NAT_SHARE_SINGAPORE_VIP: "หุ้นสิงคโปร์ VIP",
  NAT_SHARE_KOREA_VIP: "หุ้นเกาหลี VIP",
  NAT_SHARE_TAIWAN_VIP: "หุ้นไต้หวัน VIP",
  NAT_SHARE_NIKAII_MORNING_VIP: "หุ้นนิเคอิรอบเช้า VIP",
  NAT_SHARE_NIKAII_AFTERNOON_VIP: "หุ้นนิเคอิรอบบ่าย VIP",
  HANOI_EXTRA: "ฮานอยเอ็กซ์ตร้า",
  HANOI_CHINESE_NEW_YEAR: 'หวยฮานอยตรุษจีน',
  NAT_SHARE_DOWNJON_STAR: 'หวยหุ้นดาวน์โจน สตาร์'
};

export const LOTTO_FLAG_ALPHA: { [type in TLottoType]: TFlag } = {
  LAO_VIP: "LAO",
  GOVN: "THA",
  BAAC: "BAAC",
  GSB: "GSB",
  TH_SHARE_MORNING: "THA",
  TH_SHARE_MIDDAY: "THA",
  TH_SHARE_AFTERNOON: "THA",
  TH_SHARE_EVENING: "THA",
  NAT_SHARE_DOWNJON: "USA",
  NAT_SHARE_EGYPT: "EGY",
  NAT_SHARE_GERMANY: "DEU",
  NAT_SHARE_NIKAII_MORNING: "JPN",
  NAT_SHARE_NIKAII_AFTERNOON: "JPN",
  NAT_SHARE_CHINA_MORNING: "CHN",
  NAT_SHARE_CHINA_AFTERNOON: "CHN",
  NAT_SHARE_TAIWAN: "TWN",
  NAT_SHARE_KOREA: "KOR",
  NAT_SHARE_SINGAPORE: "SGP",
  NAT_SHARE_INDIA: "IND",
  NAT_SHARE_HANOI_SPECIAL: "VNM",
  NAT_SHARE_MALAY: "MYS",
  NAT_SHARE_VIETNAM_HANOI: "VNM",
  NAT_SHARE_VIETNAM_HANOI_VIP: "VNM",
  NAT_SHARE_HANOI_4D: "VNM",
  NAT_SHARE_RUSSIA: "RUS",
  NAT_SHARE_ENGLISH: "GBR",
  NAT_SHARE_HUNGSENG_MORNING: "HKG",
  NAT_SHARE_HUNGSENG_AFTERNOON: "HKG",
  NAT_SHARE_LAO: "LAO",
  LAO_SUITE: "LAO",
  YEGEE: "THA",

  LAO_HD: "LAO",
  HANOI_TV: "VNM",
  LAO_STAR: "LAO",
  HANOI_RED_CROSS: "VNM",
  HANOI_SAMAKKEE: "VNM",
  HANOI_PHATTANA: "VNM",
  LAO_SAMAKKEE: "LAO",
  LAO_ASEAN: "LAO",
  LAO_STAR_VIP: "LAO",
  ENGLISH_VIP: "GBR",
  GERMANY_VIP: "DEU",
  LAO_RED_CROSS: "LAO",
  RUSSIA_VIP: "RUS",
  DOWNJON_VIP: "USA",
  HANOI_ASEAN: "VNM",
  LAO_TV: "LAO",
  HANOI_HD: "VNM",
  HANOI_STAR: "VNM",
  HANOI: "VNM",
  MALAY: "MYS",
  NAT_SHARE_HUNGSENG_MORNING_VIP: "HKG",
  NAT_SHARE_HUNGSENG_AFTERNOON_VIP: "HKG",
  NAT_SHARE_CHINA_MORNING_VIP: "CHN",
  NAT_SHARE_CHINA_AFTERNOON_VIP: "CHN",
  NAT_SHARE_SINGAPORE_VIP: "SGP",
  NAT_SHARE_KOREA_VIP: "KOR",
  NAT_SHARE_TAIWAN_VIP: "TWN",
  NAT_SHARE_NIKAII_MORNING_VIP: "JPN",
  NAT_SHARE_NIKAII_AFTERNOON_VIP: "JPN",
  HANOI_EXTRA: "VNM",
  HANOI_CHINESE_NEW_YEAR: 'VNM',
  NAT_SHARE_DOWNJON_STAR: 'USA'
};

export const LOTTO_SLUG_TO_TYPE: { [type in TLottoSlug]: TLottoType } = {
  LOTTER_YEGEE: "YEGEE",
  LOTTER_GOVN: "GOVN",
  LOTTER_BAAC: "BAAC",
  LOTTER_GSB: "GSB",
  LOTTER_NAT_SHARE_LAO: "NAT_SHARE_LAO",
  LOTTER_LAO_SUITE: "LAO_SUITE",
  LOTTER_TH_SHARE_MORNING: "TH_SHARE_MORNING",
  LOTTER_TH_SHARE_MIDDAY: "TH_SHARE_MIDDAY",
  LOTTER_TH_SHARE_AFTERNOON: "TH_SHARE_AFTERNOON",
  LOTTER_TH_SHARE_EVENING: "TH_SHARE_EVENING",
  LOTTER_NAT_SHARE_DOWNJON: "NAT_SHARE_DOWNJON",
  LOTTER_NAT_SHARE_EGYPT: "NAT_SHARE_EGYPT",
  LOTTER_NAT_SHARE_GERMANY: "NAT_SHARE_GERMANY",
  LOTTER_NAT_SHARE_NIKAII_MORNING: "NAT_SHARE_NIKAII_MORNING",
  LOTTER_NAT_SHARE_NIKAII_AFTERNOON: "NAT_SHARE_NIKAII_AFTERNOON",
  LOTTER_NAT_SHARE_CHINA_MORNING: "NAT_SHARE_CHINA_MORNING",
  LOTTER_NAT_SHARE_CHINA_AFTERNOON: "NAT_SHARE_CHINA_AFTERNOON",
  LOTTER_NAT_SHARE_TAIWAN: "NAT_SHARE_TAIWAN",
  LOTTER_NAT_SHARE_KOREA: "NAT_SHARE_KOREA",
  LOTTER_NAT_SHARE_SINGAPORE: "NAT_SHARE_SINGAPORE",
  LOTTER_NAT_SHARE_INDIA: "NAT_SHARE_INDIA",
  LOTTER_LAO_VIP: "LAO_VIP",
  LOTTER_NAT_SHARE_HANOI_SPECIAL: "NAT_SHARE_HANOI_SPECIAL",
  LOTTER_NAT_SHARE_MALAY: "NAT_SHARE_MALAY",
  LOTTER_NAT_SHARE_VIETNAM_HANOI: "NAT_SHARE_VIETNAM_HANOI",
  LOTTER_NAT_SHARE_VIETNAM_HANOI_VIP: "NAT_SHARE_VIETNAM_HANOI_VIP",
  LOTTER_NAT_SHARE_HANOI_4D: "NAT_SHARE_HANOI_4D",
  LOTTER_NAT_SHARE_RUSSIA: "NAT_SHARE_RUSSIA",
  LOTTER_NAT_SHARE_ENGLISH: "NAT_SHARE_ENGLISH",
  LOTTER_NAT_SHARE_HUNGSENG_MORNING: "NAT_SHARE_HUNGSENG_MORNING",
  LOTTER_NAT_SHARE_HUNGSENG_AFTERNOON: "NAT_SHARE_HUNGSENG_AFTERNOON",

  LOTTER_LAO_HD: "LAO_HD",
  LOTTER_HANOI_TV: "HANOI_TV",
  LOTTER_LAO_STAR: "LAO_STAR",
  LOTTER_HANOI_RED_CROSS: "HANOI_RED_CROSS",
  LOTTER_HANOI_SAMAKKEE: "HANOI_SAMAKKEE",
  LOTTER_HANOI_PHATTANA: "HANOI_PHATTANA",
  LOTTER_LAO_SAMAKKEE: "LAO_SAMAKKEE",
  LOTTER_LAO_ASEAN: "LAO_ASEAN",
  LOTTER_LAO_STAR_VIP: "LAO_STAR_VIP",
  LOTTER_ENGLISH_VIP: "ENGLISH_VIP",
  LOTTER_GERMANY_VIP: "GERMANY_VIP",
  LOTTER_LAO_RED_CROSS: "LAO_RED_CROSS",
  LOTTER_RUSSIA_VIP: "RUSSIA_VIP",
  LOTTER_DOWNJON_VIP: "DOWNJON_VIP",
  LOTTER_HANOI_ASEAN: "HANOI_ASEAN",
  LOTTER_LAO_TV: "LAO_TV",
  LOTTER_HANOI_HD: "HANOI_HD",
  LOTTER_HANOI_STAR: "HANOI_STAR",
  LOTTER_HANOI: "HANOI",
  LOTTER_MALAY: "MALAY",
  LOTTER_NAT_SHARE_HUNGSENG_MORNING_VIP: "NAT_SHARE_HUNGSENG_MORNING_VIP",
  LOTTER_NAT_SHARE_HUNGSENG_AFTERNOON_VIP: "NAT_SHARE_HUNGSENG_AFTERNOON_VIP",
  LOTTER_NAT_SHARE_CHINA_MORNING_VIP: "NAT_SHARE_CHINA_MORNING_VIP",
  LOTTER_NAT_SHARE_CHINA_AFTERNOON_VIP: "NAT_SHARE_CHINA_AFTERNOON_VIP",
  LOTTER_NAT_SHARE_SINGAPORE_VIP: "NAT_SHARE_SINGAPORE_VIP",
  LOTTER_NAT_SHARE_KOREA_VIP: "NAT_SHARE_KOREA_VIP",
  LOTTER_NAT_SHARE_TAIWAN_VIP: "NAT_SHARE_TAIWAN_VIP",
  LOTTER_NAT_SHARE_NIKAII_MORNING_VIP: "NAT_SHARE_NIKAII_MORNING_VIP",
  LOTTER_NAT_SHARE_NIKAII_AFTERNOON_VIP: "NAT_SHARE_NIKAII_AFTERNOON_VIP",
  LOTTER_HANOI_EXTRA: "HANOI_EXTRA",
  LOTTER_HANOI_CHINESE_NEW_YEAR: 'HANOI_CHINESE_NEW_YEAR',
  LOTTER_NAT_SHARE_DOWNJON_STAR: 'NAT_SHARE_DOWNJON_STAR'
};

export const CODE_TO_TYPENAME: { [type in TBetResult]: string } = {
  TWO_UP: "สองตัวบน",
  TWO_DOWN: "สองตัวล่าง",
  THREE_UP: "สามตัวบน",
  THREE_FRONT: "สามตัวหน้า",
  THREE_BACK: "สามตัวหลัง",
  THREE_TOAST: "สามตัวโต๊ด",
  FOUR_SUITE: "เลขชุดสี่ตัว",
  RUN_UP: "วิ่งบน",
  RUN_DOWN: "วิ่งล่าง",
  ONE_AWARD: "รางวัลที่ 1",
};

export const CODE_TO_TYPENAME_LOWER_CASE: { [type in TBetResultLowerCase]: string } = {
  two_up: "สองตัวบน",
  two_down: "สองตัวล่าง",
  three_up: "สามตัวบน",
  three_front: "สามตัวหน้า",
  three_back: "สามตัวหลัง",
  three_toast: "สามตัวโต๊ด",
  four_suite: "เลขชุดสี่ตัว",
  run_up: "วิ่งบน",
  run_down: "วิ่งล่าง",
  one_award: "รางวัลที่ 1",
};

export const LOTTO_GAME_TYPES: { [type in TLottoSlug]: TLottoGameType[] } = {
  LOTTER_YEGEE: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_GOVN: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_BAAC: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_GSB: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_LAO_SUITE: ["FOUR_SUITE"],
  LOTTER_NAT_SHARE_LAO: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_TH_SHARE_MORNING: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_TH_SHARE_MIDDAY: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_TH_SHARE_AFTERNOON: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_TH_SHARE_EVENING: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_DOWNJON: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_EGYPT: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_GERMANY: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_NIKAII_MORNING: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_NIKAII_AFTERNOON: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_CHINA_MORNING: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_LAO_VIP: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_CHINA_AFTERNOON: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_TAIWAN: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_KOREA: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_SINGAPORE: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_INDIA: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_HANOI_SPECIAL: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_MALAY: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_VIETNAM_HANOI: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_VIETNAM_HANOI_VIP: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_HANOI_4D: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_RUSSIA: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_ENGLISH: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_HUNGSENG_MORNING: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_HUNGSENG_AFTERNOON: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],

  LOTTER_LAO_HD: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_HANOI_TV: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_LAO_STAR: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_HANOI_RED_CROSS: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_HANOI_SAMAKKEE: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_HANOI_PHATTANA: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_LAO_SAMAKKEE: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_LAO_ASEAN: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_LAO_STAR_VIP: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_ENGLISH_VIP: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_GERMANY_VIP: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_LAO_RED_CROSS: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_RUSSIA_VIP: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_DOWNJON_VIP: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_HANOI_ASEAN: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_LAO_TV: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_HANOI_HD: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_HANOI_STAR: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_HANOI: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_MALAY: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_HUNGSENG_MORNING_VIP: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_HUNGSENG_AFTERNOON_VIP: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_CHINA_MORNING_VIP: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_CHINA_AFTERNOON_VIP: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_SINGAPORE_VIP: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_KOREA_VIP: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_TAIWAN_VIP: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_NIKAII_MORNING_VIP: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_NIKAII_AFTERNOON_VIP: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_HANOI_EXTRA: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_HANOI_CHINESE_NEW_YEAR: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ],
  LOTTER_NAT_SHARE_DOWNJON_STAR: [
    "THREE_UP",
    "THREE_TOAST",
    "TWO_UP",
    "TWO_DOWN",
    "RUN_UP",
    "RUN_DOWN",
  ]
};

export const LOTTO_GAME_TYPE_LENGTH: { [type in TLottoGameType]: number } = {
  TWO_UP: 2,
  TWO_DOWN: 2,
  THREE_UP: 3,
  THREE_TOAST: 3,
  FOUR_SUITE: 4,
  RUN_UP: 1,
  RUN_DOWN: 1,
  // TODO:
  // ONE_AWARD: 6,
  // YEGEE_PLAY_AWARD: 5,
};

export const LOTTO_GAME_TYPE_NAME: { [type in TLottoGameType]: string } = {
  TWO_UP: "สองตัวบน",
  TWO_DOWN: "สองตัวล่าง",
  THREE_UP: "สามตัวบน",
  THREE_TOAST: "สามตัวโต๊ด",
  FOUR_SUITE: "เลขชุดสี่ตัว",
  RUN_UP: "วิ่งบน",
  RUN_DOWN: "วิ่งล่าง",
  // TODO:
  // ONE_AWARD: 'รางวัลที่ 1',
  // YEGEE_PLAY_AWARD: 'รางวัลยิงเลขลำดับที่',
};

export const TRANSACTION_STATUS: { [type in TTransactionStatus]: string } = {
  SUCCESS: 'สำเร็จ',
  FAIL: 'ไม่สำเร็จ',
  WAIT: 'รอผลออก',
  RESERVE: 'จอง',
  WINNER: 'ได้',
  LOSER: 'เสีย',
  CANCEL: 'คืนเงืน หรือ โมฆะ',
  PMPG_GOLD_AWARD: 'รางวัลกองเงินกองทองยุค ทอง',
  PMPG_SILVER_AWARD: 'รางวัลกองเงินกองทองยุค เงิน',
  PMPG_BRONZE_AWARD: 'รางวัลกองเงินกองทองยุค ทองแดง',
  PMPG_TIN_AWARD: 'รางวัลกองเงินกองทองยุค ดีบุก',
}

export const CODE_TO_TYPENAME_NUMBER: { [type in TBetResult]: string } = {
  TWO_UP: "2 ตัวบน",
  TWO_DOWN: "2 ตัวล่าง",
  THREE_UP: "3 ตัวบน",
  THREE_FRONT: "3 ตัวหน้า",
  THREE_BACK: "3 ตัวหลัง",
  THREE_TOAST: "3 ตัวโต๊ด",
  FOUR_SUITE: "เลขชุดสี่ตัว",
  RUN_UP: "วิ่งบน",
  RUN_DOWN: "วิ่งล่าง",
  ONE_AWARD: "รางวัลที่ 1",
};

export const BANK_TYPE: { [type in TBankType]: string } = {
  BBL: 'ธนาคารกรุงเทพ',
  TTB: 'ทีเอ็มบีธนชาต',
  KBANK: 'ธนาคารกสิกร',
  KTB: 'ธนาคารกรุงไทย',
  SCB: 'ธนาคารไทยพาณิชย์',
  BAY: 'ธนาคารกรุงศรีอยุธยา',
  KKP: 'ธนาคารเกียรตินาคิน',
  CIMBT: 'ธนาคารซีไอเอ็มบีไทย',
  TISCO: 'ธนาคารทิสโก้',
  UOBT: 'ธนาคารยูโอบี',
  TCD: 'ธนาคารไทยเครดิตเพื่อรายย่อย',
  ICBCT: 'ธนาคารไอซีบีซี (ไทย)',
  GSB: '	ธนาคารออมสิน',
  ISBT: 'ธนาคารอิสลามแห่งประเทศไทย',
  BAAC: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
  GHBANK: "ธนาคารอาคารสงเคราะห์",
}


export const meDataInitialValue: IV2GetAgentMe = {
  agentId: 0,
  agentStatus: "",
  bankName: "",
  bankNumber: "",
  bankType: "PleaseChoose",
  betCountTotal: 0,
  betTotal: 0,
  childTotal: 0,
  createdAt: "",
  credit: 0,
  creditLimit: 0,
  creditShareLimit: 0,
  currentBetCountTotal: 0,
  currentBetTotal: 0,
  isAccess: false,
  lossBenefitTotal: 0,
  lossTotal: 0,
  name: "",
  permission: "",
  phoneNumber: "",
  profitBenefitTotal: 0,
  profitTotal: 0,
  updatedAt: "",
  username: "",
}

export const AGENT_STATUS: { [type in TAgentStatus]: string } = {
  PRE_REGISTERED: 'พึ่งสมัครสมาชิก',
  LOGIN_OTP: 'เข้าสู่ระบบด้วย OTP',
  NORMAL: 'ปกติ',
  PASSWORD_EXPIRED: 'รหัสหมดอายุ',
  WAIT_AUTHEN: 'รอการยืนยันตัวตน',
  WAIT_SECRET: 'รอการเปิดใช้งาน 2FA',
  VERIFY: 'ตรวจสอบ 2FA',
}

export const PERMISSION: { [type in TPermissionStatus]: string } = {
  OWNER: 'Owner',
  SUPER_AGENT: 'Master',
  AGENT: 'Agent',
  MEMBER: 'Member',
  UNKNOWN: 'ไม่พบข้อมูล'
};


export const AGENT_REFUND_MODE: { [type in TAgentRefundMode]: string } = {
  CANCEL_PER_TIMES: 'CANCEL_PER_TIMES',
  CANCEL_PER_CLOTTERY: 'CANCEL_PER_CLOTTERY',
};

