import { connect } from "react-redux";
import LottoResultContainer from "./LottoResult.container";
import { onChangeIsLoaddingAction } from "reduxs/Loader/action";
import { getV2DataResult } from "reduxs/lotto/bet/result/action";
import bet from "reduxs/lotto/bet/selector";

const mapStateToProps = (state: RootReducers): ILottoResultContainerProps => {
  const getV2Result = bet.getV2Result(state);
  return {
    getV2ResultData: getV2Result.data,
    getV2ResultIsFetching: getV2Result.isFetching,
    getV2ResultCode: getV2Result.code,
    getV2ResultError: getV2Result.error,
  };
};

const mapDispatchToProps: ILottoResultContainerAction = {
  onChangeIsLoaddingAction,
  getV2DataResult
};

export default connect(mapStateToProps, mapDispatchToProps)(LottoResultContainer);
