import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { postChildSettingLotteryUpdateAction } from "../actionCreator";
import { fetchPostChildSettingLotteryUpdate } from "../services";

const postChildSettingLotteryUpdate = (data: IV2PostAgentChildSettingLotteryUpdate) => (dispatch: Dispatch) => {
    dispatch(postChildSettingLotteryUpdateAction.request({}))
    return fetchPostChildSettingLotteryUpdate(data)
    .then((res: AxiosResponse<{}>) => {
        dispatch(postChildSettingLotteryUpdateAction.success(res))
        return Promise.resolve()
    })
    .catch((error: AxiosError<IAPIResponse>) => {
        dispatch(postChildSettingLotteryUpdateAction.failure(error))
        return Promise.reject()
    })
}

export { postChildSettingLotteryUpdate }