import { connect } from "react-redux"
import MembersListContainer from "./FinanceSumCustomer.container"
import financeSelector from "../../reduxs/finance/selector"
import { getFinanceCustomer} from "../../reduxs/finance/customer/action"
import { onChangeIsLoaddingAction } from "../../reduxs/Loader/action"

const mapStateToProps = (state: RootReducers): IFinanceCustomerProps => {
  const financeCustomerData = financeSelector.fianceCustomer(state)

  return {
    financeCustomerCode: financeCustomerData.code,
    financeCustomerError:financeCustomerData.error,
    financeCustomerIsFetching: financeCustomerData.isFetching,
    financeCustomerData: financeCustomerData.data,
  }
}
const mapDispatchToProps: IFinanceCustomerActionProps = {
  getFinanceCustomer: getFinanceCustomer,
  onChangeIsLoaddingAction: onChangeIsLoaddingAction,
}
export default connect(mapStateToProps, mapDispatchToProps)(MembersListContainer)
