import React, { Component } from "react";
import { Input, Button } from "components";
import "./resetUserPassword.style.scss";
import ResetPassword from "assets/images/logo/resetpassword.png";
import { FormikProps, Form } from "formik";
import { withRouter, RouteComponentProps } from "react-router-dom";
import colors from 'constants/colors'

const constants = {
  title:"เปลี่ยนรหัสผ่านใหม่ที่ต้องการ",
  subTitel:"กรุณาเปลี่ยนรหัสผ่านใหม่",
  resetPasswordButtonText: "ยืนยันเปลี่ยนรหัสผ่าน",
  backMainPage:"กลับสู่หน้าหลัก",
  password:"รหัสผ่านใหม่",
  confirm_password:"ยืนยันรหัสผ่านไหม่",
};

class ResetUserPasswordComponent extends Component<
  FormikProps<IResetUserPasswordRequest> &
    RouteComponentProps<{}, {}, {}>
> {
  returnToMainPage = () => {
    const { history, location } = this.props;
    history.push("/dashboard", { from: location });
  };

  renderChangePassword = () => {
    const { errors, touched, values, handleChange, handleBlur } = this.props;

    return (
      <div className="reset-password-box-shadow d-flex flex-column align-items-center justify-content-center text-center min-vh-sm-100">
        <div className="p4-t d-flex flex-column justify-content-center p4-b">
          <img
            src={ResetPassword}
            alt="Reset Password Icon"
            className="img-fluid"
          />
        </div>
        <div className="body-3 p1-b">
          {constants.title}
        </div>
        <div className="body-4 tertiary-text p4-b">
          {constants.subTitel}
        </div>
        <div className="w-75 p3-b">
          <Input
            id="password"
            name="password"
            label="password"
            placeholder={constants.password}
            value={values.password}
            onBlur={handleBlur}
            onChange={handleChange}
            error={!!errors.password && touched.password}
            errorMessage={errors.password}
            type="password"
          />
        </div>
        <div className="w-75 p4-b">
          <Input
            id="confirm_password"
            name="confirm_password"
            label="confirm_password"
            placeholder={constants.confirm_password}
            value={values.confirm_password}
            onBlur={handleBlur}
            onChange={handleChange}
            error={!!errors.confirm_password && touched.confirm_password}
            errorMessage={errors.confirm_password}
            type="password"
          />
        </div>
        <div className="w-75 p3-b">
          <Button
            id="login"
            buttonType="submit"
            text={constants.resetPasswordButtonText}
            size="large"
            transitionType="typeOne"
          />
        </div>
        <div className="">
          <Button
            id="return"
            text={constants.backMainPage}
            size="medium"
            type="underline"
            textColor={colors.SECONDARY_TEXT}
            backgroundColor={colors.TRANSPARENT}
            backgroundHoverColor={colors.TRANSPARENT}
            canHover={false}
            onClick={this.returnToMainPage}
          />
        </div>
      </div>
    );
  };

  render() {
    const ChangePassword = this.renderChangePassword;
    return (
      <Form>
        <div className="container-fluid">
          <div className="row justify-content-center min-vh-100 secondary-bg">
            <div className="col-12 col-sm-9 col-xl-5 p-0 min-vh-sm-100 ">
              <div className="reset-password-container no-margin-left p-0 min-vh-sm-100 d-flex flex-column justify-content-center">
                <div className="reset-password-body m-0 min-vh-sm-100">
                  <ChangePassword />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

export default withRouter(ResetUserPasswordComponent);
