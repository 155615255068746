import React, { Component } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "bootstrap/dist/css/bootstrap.css";
import "./billSummaryList.style.scss";
import { get, isEmpty, noop } from "lodash";
import color from "constants/colors";
import { dateThai, number } from "utils";
import { STATUS_FINANCE } from "constants/variables";
import { LoadingCircle } from "components/LoadingCircle";

const constants = {
  reactTabs: {
    fromTable: "จากตาราง",
    share: "การแบ่งหุ้น",
    clearList: "รายการเคลียร์",
  },
  headTable: [
    { codeName: "ถือสู้", textalign: "text-center pd-frist" },
    { codeName: "แบ่งหุ้น", textalign: "text-center" },
    { codeName: "ยอดเดิมพัน", textalign: "text-center" },
    { codeName: "ส่วนลด", textalign: "text-center" },
    { codeName: "ค่าคอม", textalign: "text-center" },
    { codeName: "ยอดรับ", textalign: "text-center" },
    { codeName: "ยอดจ่าย", textalign: "text-center" },
    { codeName: "ยอดทั้งหมดที่รอตรวจสอบ", textalign: "text-center" },
    { codeName: "สรุปกำไร / ขาดทุน", textalign: "text-center" },
    { codeName: "สถานะ", textalign: "text-center" },
    { codeName: "เวลา", textalign: "text-center" },
  ],
  headTableShare: [
    { codeName: "ผลถือสู้", textalign: "text-center pd-frist" },
    { codeName: "ผลแบ่งหุ้น", textalign: "text-center" },
    { codeName: "ยอดจ่ายหัวสมาชิก", textalign: "text-center" },
    { codeName: "ยอดจ่ายลูกสมาชิก/ชื่อช่วยจำ", textalign: "text-center" },
    { codeName: "ยอดรับหัวสมาชิก", textalign: "text-center" },
    { codeName: "ยอดรับลูกสมาชิก/ชื่อช่วยจำ", textalign: "text-center" },
  ],
  headTableContact: [
    { codeName: "จำนวนชื่อช่วยจำ", textalign: "text-center pd-frist" },
    { codeName: "จำนวนยังไม่รับ/จ่าย", textalign: "text-center" },
    { codeName: "จำนวนรับ/จ่ายแล้ว", textalign: "text-center" },
  ],
  list: "(จำนวนรายการ)",
  empty: "ไม่มีข้อมูล...",
};

const defaultProps: IBillSummaryListProps = {
  dataList: [],
  onClick() {
    noop();
  },
  loading: false
};

class BillSummaryListComponent extends Component<
  IBillSummaryListProps,
  IBillSummaryListState
> {
  static defaultProps = defaultProps;
  state: IBillSummaryListState = {};

  getCreditColor = (data: number) => {
    const creditColor =
      data > 0
        ? color.SECONDARY_GREEN
        : data < 0
        ? color.PRIMARY_RED
        : color.TERTIARY_TEXT;
    return creditColor;
  };

  renderFromTable = () => {
    const { dataList, onClick, loading } = this.props;
    const RenderTableHeader = this.renderTableHeader(constants.headTable)

    const loadingTable = (
      <tbody>
        <tr>
          <td className="text-center" colSpan={15}>
            <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
          </td>
        </tr>
      </tbody>
    )
    
    const content = (
      <tbody>
        {isEmpty(dataList) ? (
          <tr>
            <td className="text-center color-row p-2" colSpan={15}>
              {constants.empty}
            </td>
          </tr>
        ) : (
          dataList.map((data, index: number) => {
            const stName = get(STATUS_FINANCE, `${data.status}.status`, "");
            const stColor = get(STATUS_FINANCE, `${data.status}.color`, "");
            return (
              <tr
                id={'un-hover'}
                className={`row-finance-customer`}
                key={index}
                onClick={() => onClick(index, data)}
              >
                <td className="text-center paddingRow">
                  <span className="pd-frist">
                    {number.addComma(data.betHost)}
                  </span>
                </td>
                <td className="text-center ">
                  <span>{number.addComma(data.betMe)}</span>
                </td>
                <td className="text-center">
                  <span>{number.addComma(number.calculator(`${data.betMe}+${data.betHost}`))}</span>
                </td>
                <td className="text-center">
                  <span>{number.addComma(data.discount)}</span>
                </td>
                <td className="text-center">
                  <span>{number.addComma(data.com)}</span>
                </td>
                <td className="text-center">
                  <span
                    className=""
                    style={{
                     color: data.payHost !== 0 ? color.SECONDARY_GREEN : color.TERTIARY_TEXT,

                    }}
                  >
                    {number.addComma(data.payHost)}
                  </span>
                </td>
                <td className="text-center">
                  <span
                    className=""
                    style={{
                      color: data.recieveHost !== 0 ? color.PRIMARY_RED : color.TERTIARY_TEXT,
                    }}
                  >
                    {number.addComma(data.recieveHost)}
                  </span>
                </td>

                <td className="text-center">
                  <div className="d-flex flex-column">
                    <span
                      className="text-status"
                      style={{ color: data.totalPayWait === 0 ? color.TERTIARY_TEXT : stColor }}
                    >
                      {number.addComma(data.totalPayWait)}
                    </span>
                    <span className="text-status" style={{ color: data.countWait === 0 ? color.TERTIARY_TEXT : stColor }}>
                      {`(${number.addComma(data.countWait)} รายการ)`}
                    </span>
                  </div>
                </td>
                <td className="text-center">
                  <span
                    style={{
                      color: this.getCreditColor(
                        data.payHost - data.recieveHost
                      ),
                    }}
                  >
                    {number.addComma(number.calculator(`${data.payHost}-${data.recieveHost}`))}
                    
                  </span>
                </td>
                <td className="text-center">
                  <span className="text-status" style={{ color: stColor }}>
                    {stName}
                  </span>
                </td>
                <td className="text-center">
                  <div className="d-flex flex-row align-items-center justify-content-center">
                    <span>{dateThai.formatThai(data.createdAt)}</span>
                  </div>
                </td>
              </tr>
            );
          })
        )}
      </tbody>
    );

    return (
      <div className="table-container">
        <table className="col">
          {RenderTableHeader}
          {loading ? loadingTable :content}
        </table>
      </div>
    );
  };

  renderTableHeader = (headTable: {codeName: string, textalign: string}[]) => {
    return (
      <thead>
        <tr className="tableHead">
          {headTable.map((data, index: number) => (
            <th key={index} className={data.textalign}>
              {index === 7 ? (
                <div className="d-flex flex-column">
                  <span>{data.codeName} </span>
                  <div className="remember-name" style={{ lineHeight: "8px" }}>
                    {constants.list}
                  </div>
                </div>
              ) : (
                <span>{data.codeName}</span>
              )}
            </th>
          ))}
        </tr>
      </thead>
    )
  }

  renderShare = () => {
    const {dataList, onClick, loading} = this.props
    const RenderTableHeader = this.renderTableHeader(constants.headTableShare)

    const loadingTable = (
      <tbody>
        <tr>
          <td className="text-center" colSpan={15}>
            <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
          </td>
        </tr>
      </tbody>
    )

    const content = (
      <tbody>
        {isEmpty(dataList) ? (
          <tr>
            <td className="text-center color-row p-2" colSpan={15}>
              {constants.empty}
            </td>
          </tr>
        ) : (
          dataList.map((data, index: number) => {
            return (
              <tr
                className={`row-finance-customer`}
                key={index}
                onClick={() => onClick(index, data)}
              >
                <td className="text-center paddingRow">
                  <span className="pd-frist" style={{color: this.getCreditColor(data.resultHost)}}>
                    {number.addComma(data.resultHost)}
                  </span>
                </td>
                <td className="text-center " >
                  <span style={{color: this.getCreditColor(data.resultMe-(data.resultMe*2))}}>{number.addComma(data.resultMe-(data.resultMe*2))}</span>
                </td>
                <td className="text-center">
                  <span style={{color: this.getCreditColor(0)}}>{number.addComma(0)}</span>
                </td>
                <td className="text-center" >
                  <span style={{color: data.recieveHost > 0 ? color.PRIMARY_RED :color.TERTIARY_TEXT}}>{number.addComma(data.recieveHost)}</span>
                </td>
                <td className="text-center" >
                  <span style={{color: this.getCreditColor(0)}}>{number.addComma(0)}</span>
                </td>
                <td className="text-center">
                  <span
                    className=""
                    style={{
                      color: this.getCreditColor(
                        data.payHost
                      ),
                    }}
                  >
                    {number.addComma(data.payHost)}
                  </span>
                </td>
              </tr>
            );
          })
        )}
      </tbody>
    );

    return (
      <div className="table-container">
        <table className="col">
          {RenderTableHeader}
          {loading ? loadingTable :content}
        </table>
      </div>
    )
  }

  renderContact = () => {
    const {dataList, onClick, loading} = this.props
    const RenderTableHeader = this.renderTableHeader(constants.headTableContact)

    const loadingTable = (
      <tbody>
        <tr>
          <td className="text-center" colSpan={15}>
            <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
          </td>
        </tr>
      </tbody>
    )

    const content = (
      <tbody>
        {isEmpty(dataList) ? (
          <tr>
            <td className="text-center color-row p-2" colSpan={15}>
              {constants.empty}
            </td>
          </tr>
        ) : (
          dataList.map((data, index: number) => {
            return (
              <tr
                className={`row-finance-customer`}
                key={index}
                onClick={() => onClick(index, data)}
              >
                <td className="text-center paddingRow">
                  <span className="pd-frist">
                    {`${number.addComma(data.totalCustomer)} รายการ`}
                  </span>
                </td>
                <td className="text-center ">
                  <span>{`${number.addComma(data.countWait)} รายการ`}</span>
                </td>
                <td className="text-center">
                  <span>{`${number.addComma(data.totalPay)} รายการ`}</span>
                </td>
              </tr>
            );
          })
        )}
      </tbody>
    );

    return (
      <div className="table-container">
        <table className="col">
          {RenderTableHeader}
          {loading ? loadingTable :content}
        </table>
      </div>
    )
  }

  render() {
    const RenderFromTable = this.renderFromTable();
    const RenderShare = this.renderShare();
    const RenderContact = this.renderContact();
    return (
      <div className="row bill-summary-list-container">
        <div className="col-12">
          <Tabs
            defaultActiveKey="fromTable"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="fromTable" title={constants.reactTabs.fromTable}>
              {RenderFromTable}
            </Tab>
            <Tab eventKey="share" title={constants.reactTabs.share}>
              {RenderShare}
            </Tab>
            <Tab eventKey="clearList" title={constants.reactTabs.clearList}>
              {RenderContact}
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default BillSummaryListComponent;
