import axios from "axios";
import environment from "../../constants/environment";

const fetchPostOwnerUpdate = (data: IV2AgentOwnerUpdate) => {
  const endpoint = `${
    environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.postOwnerUpdate}`;
  return axios.post(`${endpoint}`, data);
};

const fetchPostOwnerSetting = (data: IV2PostAgentOwnerSettingUpdate) => {
  const endpoint = `${
    environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.postOwnerSetting}`;
  return axios.post(`${endpoint}`, data);
};

const fetchPostOwnerSettingLotteryUpdate = (data: Array<IV2LotterySetting>) => {
  const endpoint = `${
    environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.postOwnerSettingLotteryUpdate}`;
  return axios.post(`${endpoint}`, data);
};

export { fetchPostOwnerUpdate, fetchPostOwnerSetting, fetchPostOwnerSettingLotteryUpdate };
