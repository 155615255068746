import {
  CODE_TO_TYPENAME,
  LOTTO_FLAG_ALPHA,
  LOTTO_SLUG_NAME,
  LOTTO_SLUG_TO_TYPE,
  TRANSACTION_STATUS,
} from "constants/variables";
import React, { FC } from "react";
import "./historySummary.style.scss";
import LottoFlags from "assets/images/global/flags";
import { Button, Modal, ResponsiveIcon } from "components";
import { format } from "date-fns";
import { th } from "date-fns/locale";
import { map, noop } from "lodash";
import { number } from "utils";
import color from "constants/colors";

const constants = {
  billName: (name: string) => `${name}ฝากแทง`,
  totalBet: "เดิมพันทั้งหมด :",
  profits: "กำไร/ขาดทุน :",
  mockDate: format(new Date(), "dd MMM yy HH:mm", { locale: th }),
  lottoName: "LOTTER_YEGEE",
};

const talbeMenu = {
  betNumber: "เลข",
  betValue: "ราคา",
  betRate: "บาทละ",
  betResult: "ผลการเดิมพัน",
  betProfit: "",
};

const defaultProps: IBetHistorySummaryModalProps = {
  data: {
    id: 0,
    remark: "",
    code: "LOTTER_GOVN",
    betTotal: 0,
    result: 0,
    status: "",
    lotteryGroupList: [],
    createdAt: "",
  },
  onCancel: () => {
    noop();
  },
};
const RenderTableElement = (data: Array<ILotteryGroup>) => {
  return (
    <div className="table-container">
      <div className="row mx-1">
        <table className="col">
          {map(data, (betType, index) => (
            <React.Fragment key={`${index}`}>
              <thead key={`betType-items-${index}`}>
                <tr className="color-row">
                  <th colSpan={5} className="text-left p2-x p1-y">
                    <h4>{CODE_TO_TYPENAME[betType.type as TBetResult]}</h4>
                  </th>
                </tr>
                <tr className="text-left color-row border-bottom">
                  {map(talbeMenu, (value, key) => (
                    <th
                      scope={`col`}
                      key={`menu-${key}`}
                      className={`p2-x p1-y ${
                        key === "betRate" ||
                        key === "betValue" ||
                        key === "betResult" ||
                        key === "betProfit"
                          ? "text-right"
                          : ""
                      }
                `}
                    >
                      <span>{value}</span>
                    </th>
                  ))}
                </tr>
              </thead>
              {map(betType.lotteryList, (betList, index) => {
                return (
                  <tbody key={`betList-items-${index}`}>
                    <tr className={`${index % 2 !== 0 ? "color-row" : ""}`}>
                      <td className="p2-x p1-y">
                        <h4>{betList.number}</h4>
                      </td>
                      <td className="p2-x p1-y text-right">
                        <span>{number.castToMoney(Number(betList.value))}</span>
                      </td>
                      <td className="p2-x p1-y text-right">
                        <span>{number.castToMoney(Number(betList.rate))}</span>
                      </td>
                      <td
                        className={`p2-x p1-y text-right 
                  `}
                      >
                        <span
                          className={`${
                            betList.status === "WINNER"
                              ? "text-success"
                              : betList.status === "WAIT"
                              ? "text-warning"
                              : betList.status === "LOSER"
                              ? "text-danger"
                              : ""
                          }`}
                        >
                          {
                            TRANSACTION_STATUS[
                              betList.status as TTransactionStatus
                            ]
                          }
                        </span>
                      </td>
                      <td className="p2-x p1-y text-right">
                        <span>
                          {number.castToMoney(Number(betList.result))}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </React.Fragment>
          ))}
        </table>
      </div>
    </div>
  );
};

const RenderBetHistorySummaryElement = (
  data: IResponseAgentQueryLotteryHistorySummaryDetail
) => {
  return (
    <div className="row">
      <div className="col">
        <table className="col pl-0 pr-1">
          <thead>
            <></>
          </thead>
          <tbody>
            <tr className={"color-row"}>
              <td className="text-right border-bottom" colSpan={7}>
                <span className="col" id="defaultValue">
                  {constants.totalBet}
                </span>
              </td>
              <td className="text-right border-bottom" style={{width: '18%'}} colSpan={1}>
                <div className="body-7">
                  {number.castToMoney(data.betTotal)}
                </div>
              </td>
            </tr>
            <tr className={"color-row-2"}>
              <td className="text-right border-bottom" colSpan={7}>
                <span className="col" id="defaultValue">
                  {constants.profits}
                </span>
              </td>
              <td className="text-right border-bottom" style={{width: '18%'}} colSpan={1}>
                <div
                  className={`body-7 ${
                    data.result >= 0 ? "text-success" : "text-danger"
                  }`}
                >
                  {number.castToMoney(data.result)}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const HistorySummaryComponent: FC<IBetHistorySummaryModalProps> = (props) => {
  const flagIcon =
    LottoFlags[
      LOTTO_FLAG_ALPHA[LOTTO_SLUG_TO_TYPE[props.data.code as TLottoSlug]]
    ].Icon;
  return (
    <div className="history-summary-container center">
      <div className="paper-title">{constants.billName(props.data.remark)}</div>
      <div className="paper-body">
        <div className="row">
          <div className="col">
            <div className="paper-description">
              <div className="box-shadow">
                <div className="header-column">
                  <ResponsiveIcon
                    icon={flagIcon}
                    alt={`flag`}
                    className="lotto-flag-icon m1-r"
                  />
                  <div className="title">
                    <span className="paper-body-title">
                      {LOTTO_SLUG_NAME[props.data.code as TLottoSlug]}
                    </span>
                    <br />
                    <span className="paper-body-subtitle">
                      {format(
                        new Date(props.data.createdAt),
                        "d MMM yy kk:mm",
                        { locale: th }
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m2-t">
          <div className="col h-100">
            <RenderTableElement {...props.data.lotteryGroupList} />
          </div>
        </div>
        <div className="container bottom-section px-3">
          <RenderBetHistorySummaryElement {...props.data} />
          <div className="row">
            <div className="col" />
            <div className="col-2 text-right pr-0">
              <Button
                id={`back`}
                text={`ยกเลิก`}
                size="large"
                onClick={props.onCancel}
                backgroundColor={color.PRIMARY_BG}
                textColor={color.PRIMARY_TEXT}
                canHover={false}
                type="normal"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HistorySummaryComponent.defaultProps = defaultProps;

const HistorySummayModal = {
  show: (props: IBetHistorySummaryModalProps) =>
    Modal.event("MODAL", {
      state: "show",
      component: <HistorySummaryComponent {...props} />,
    }),
  hide: () => {Modal.event("MODAL", { state: "hide" })},
};

export default HistorySummayModal;
