import { date, number } from "utils";
import colors from "constants/colors";
import React, { Component, createRef, RefObject } from "react";
import {
  cloneDeep,
  differenceWith,
  findIndex,
  forOwn,
  get,
  isEmpty,
  isEqual,
  mapValues,
  omit,
  pick,
} from "lodash";
import {
  ResponsiveIcon,
  InputNumber,
  Button,
  SuccessModal,
  ConfirmModal,
  ErrorModal,
} from "components";
import {
  CODE_TO_NAME,
  LOTTO_FLAG_ALPHA,
  STATUS,
  REMAINDER_HOLD_SELECT,
  LOTTO_GAME_TYPES,
  CODE_TO_TYPENAME_NUMBER,
  meDataInitialValue,
} from "constants/variables";
import "./NewLottoBetConfig.style.scss";
import Select from "react-select";
import { createMemberDropdownStyles } from "containers/CreateMember/CreateMemberDropdown.styles";
import { FormikProps } from "formik";
import LottoFlags from "assets/images/global/flags";
import { pascalCase } from "change-case";
import routes from "constants/routes";
import { responseMessage } from "constants/response";
import { format } from "date-fns";
import { th } from "date-fns/locale";
import color from "constants/colors";

const constants = {
  settingBet: "ตั้งค่าการแบ่งหุ้น / เก็บของ",
  payRate: "อัตราจ่าย",
  payRatePercentage: "อัตราจ่าย",
  lotteryBetConfig: "คัดลอกข้อมูลจากผู้ใช้",
  userPlaceholder: "ค้นหาผู้ใช้",
  status: "ใช้งาน",
  storage: "เก็บของ",
  percen1: "ถือสู้ % ",
  percen1Note: (mustReciv: number, meShare: number) =>
    `(ไม่ต่ำกว่า ${mustReciv}% และไม่มากกว่า ${meShare}%)`,
  percen2: "บังคับสู้ % ",
  percen2Note: "(ไม่มากกว่าเปอร์เซ็นต์ถือสู้)",
  percen1Placeholder: "0",
  percen2Placeholder: "0",
  codeToTypeNameSetting: "ตั้งค่าแต่ละประเภท",
  allDiscountPercentage: "ส่วนลดทั้งหมด %",
  allDiscountPercentagePlaceholder: "ส่วนลด %",
  betRate: "บาทละ",
  betRateLimit: (limit: number) => ` / ${number.addComma(limit)} บาท`,
  betRateLimitError: (limit: number) =>
    `อัตราจ่ายต้องไม่มากกว่า ${number.addComma(limit)} บาท`,
  discountPercentage: "ส่วนลด %",
  discountPercentagePlaceholder: "0 %",
  minBet: "จำกัดแทงต่ำสุด",
  maxBet: "จำกัดแทงสูงสุด",
  minBetPlaceholder: (minLimit: number) =>
    ` / ${number.addComma(minLimit)} บาท`,
  maxBetPlaceholder: (maxLimit: number) =>
    ` / ${number.addComma(maxLimit)} บาท`,
  reset: "รีเซ็ต",
  saveBetSetting: "บันทึกตั้งค่าหวย",
  betSetting: "ตั้งค่าหวย",
  hostSharePercentagePlaceholder: (limit: number) => ` / ${limit} %`,
  saveLotterySettingSuccessful: "บันทึกการตั้งค่าหวยสำเร็จ",
  resetLotterySettingSuccessful: "รีเซ็ตการตั้งค่าหวยสำเร็จ",
  saved: "บันทึกแล้ว",
  editLotterySuccessful: "แก้ไขข้อมูลสำเร็จ",
  isNotSavedYet: "ยังไม่ได้บันทึก",
  isChangeLottery: "คุณแน่ใจหรือว่าจะเปลี่ยนประเภทของหวย",
  childSettingLotteryUpdateSuccess: "แก้ไขข้อมูลการตั้งค่าหวยสำเร็จ",
  lastUpdate: (time: string) => `ข้อมูลเปลี่ยนแปลงล่าสุด ${time}`,
  editLottery: "แก้ไขข้อมูล",
  postOwnerSettingLotteryUpdateSuccess: "แก้ไขข้อมูลการตั้งค่าหวยสำเร็จ",
  lotterySettingParameterIncomplete: "กรุณากรอกข้อมูลตั้งค่าหวยให้ถูกต้อง",
  noInfo: "ไม่มีข้อมูล",
  baht: "บาท",
};

const selectedProps = [
  "childLotsetBetRateRunDown",
  "childLotsetBetRateRunUp",
  "childLotsetBetRateThreeToast",
  "childLotsetBetRateThreeUp",
  "childLotsetBetRateTwoDown",
  "childLotsetBetRateTwoUp",
  "childLotsetDisRunDown",
  "childLotsetDisRunUp",
  "childLotsetDisThreeToast",
  "childLotsetDisThreeUp",
  "childLotsetDisTwoDown",
  "childLotsetDisTwoUp",
  "childLotsetShareComRunDown",
  "childLotsetShareComRunUp",
  "childLotsetShareComThreeToast",
  "childLotsetShareComThreeUp",
  "childLotsetShareComTwoDown",
  "childLotsetShareComTwoUp",
  "childLotsetBetMinThreeUp",
  "childLotsetBetMinThreeToast",
  "childLotsetBetMinTwoUp",
  "childLotsetBetMinTwoDown",
  "childLotsetBetMinRunUp",
  "childLotsetBetMinRunDown",
  "childLotsetBetMaxThreeUp",
  "childLotsetBetMaxThreeToast",
  "childLotsetBetMaxTwoUp",
  "childLotsetBetMaxTwoDown",
  "childLotsetBetMaxRunUp",
  "childLotsetBetMaxRunDown",
  "childLotsetIsOpen",
  "childLotsetKeep",
];

const defaultProps: INewLottoBetConfigProps = {
  onChangeLottoConfig() {},
  creditLimit: 0,
  minBetHost: 0,
  isOtpClicked: false,
  lotterySetting: [],
  getChildSelectedLottoState() {},
  mode: "CREATE",
  meSettingLotteryCode: 0,
  meSettingLotteryData: {
    agentId: 0,
    lotterySetting: [],
  },
  meSettingLotteryIsFetching: false,
  meData: meDataInitialValue,
  meSettingData: {
    agentId: 0,
    setBetMaxLimit: 100,
    setBetMinLimit: 1,
    setComPercentage: 0,
  },
};
class NewLottoBetConfigComponent extends Component<
  FormikProps<ICreateMember> & INewLottoBetConfigProps
> {
  static defaultProps = defaultProps;
  userContainerRef: RefObject<HTMLDivElement> = createRef();

  state: INewLottoBetConfigState = {
    isChecked: false,
    selectedLotto: [],
    lotterySettingState: [],
    isEditable: false,
    localStorageLotterySetting:[],

    nodeLotterySettingState: [],

    // nodeBetMaxLimit:0,
    // nodeBetMinLimit:0,
  };

  componentDidMount() {
    if (window.location.pathname !== routes.userDetail.path) {
      this.setState({ isEditable: true });
    }
  }

  componentDidUpdate(
    prevProps: FormikProps<ICreateMember> & INewLottoBetConfigProps,
    prevState: INewLottoBetConfigState
  ) {
    if (prevProps.initialValues !== this.props.initialValues) {
      this.getLotterySetting();
    }

    if (
      prevProps.lotterySetting !== this.props.lotterySetting &&
      this.props.mode === "CREATE"
    ) {
      this.getLotterySetting();
    }

    if (
      prevProps.values !== this.props.values &&
      this.props.mode === "UPDATE" &&
      !isEmpty(prevState.selectedLotto)
    ) {
      const clonedLotterySettingState = cloneDeep(
        this.state.lotterySettingState
      );
      const selectedLottoIndex = findIndex(
        this.props.lotterySetting,
        (item) =>
          item.childLotsetName === this.state.selectedLotto[0]?.childLotsetName
      );
      const clonedSelectedLotto = cloneDeep(this.state.selectedLotto);
      const pickedClonedLotterySettingState = pick(this.props.values, [
        ...selectedProps,
        ...["fixSharePercentage", "hostSharePercentage"],
      ]);

      const numberedPickedClonedLotterySettingState = mapValues(
        pickedClonedLotterySettingState,
        (val, key, obj) => {
          if (
            key.includes("Dis") ||
            key.includes("Bet") ||
            key.includes("fix") ||
            key.includes("host")
          ) {
            return ((obj as any)[key] = Number(val));
          } else {
            return ((obj as any)[key] = val);
          }
        }
      );

      const pickedSelectedLotto = pick(clonedSelectedLotto[0], [
        ...selectedProps,
      ]);
      const addedPickedSelectedLotto = {
        ...pickedSelectedLotto,
        ...{
          fixSharePercentage: clonedSelectedLotto[0].childLotsetMustReciv,
          hostSharePercentage: window.location.pathname === routes.userDetail.path ? clonedSelectedLotto[0].childLotsetMeShare : clonedSelectedLotto[0].childLotsetMustReciv,
        },
      };

      clonedLotterySettingState[selectedLottoIndex].isNotSavedYet = !isEqual(
        numberedPickedClonedLotterySettingState,
        addedPickedSelectedLotto
      );
      /* clonedLotterySettingState[selectedLottoIndex].isSaved = false; */
      this.setState(
        {
          selectedLotto: [clonedLotterySettingState[selectedLottoIndex]],
        },
        () => {
          this.setState({ lotterySettingState: clonedLotterySettingState });
        }
      );
    }

    if (
      prevState.selectedLotto !== this.state.selectedLotto &&
      !isEmpty(prevState.selectedLotto) &&
      this.state.selectedLotto[0] &&
      prevState.selectedLotto[0] &&
      this.props.mode === "CREATE"
    ) {
      const clonedLotterySettingState = cloneDeep(
        this.state.lotterySettingState
      );

      const previouslySelectedLottoIndex = findIndex(
        this.props.lotterySetting,
        (item) =>
          item.childLotsetName === prevState.selectedLotto[0]?.childLotsetName
      );
      clonedLotterySettingState[previouslySelectedLottoIndex] =
        prevState.selectedLotto[0];
      this.setState({ lotterySettingState: clonedLotterySettingState }, () => {
        this.props.validateForm();
      });
    }
  }

  getLotterySetting = () => {
    const storedLotterySettingState =
      window.location.pathname === routes.userDetail.path
        ? localStorage.getItem("lotterySettingUserDetailState") || ""
        : window.location.pathname === routes.memberDetail.path
        ? localStorage.getItem("lotterySettingMemberDetailState") || ""
        : localStorage.getItem("lotterySettingState") || "";
    if (isEmpty(storedLotterySettingState)) {
      const clonedLotterySetting = this.loopToSetDefaultChildLotsetLimit(
        this.props.lotterySetting
      );
      clonedLotterySetting.forEach((item) => {
        item.isSaved = false;
        item.isNotSavedYet = false;
      });
      this.setDefaultLotterySetting(clonedLotterySetting);
    } else {
      const parsedLotterySetting: IV2LotterySetting[] = JSON.parse(
        storedLotterySettingState
      );
      this.setDefaultLotterySetting(parsedLotterySetting);
    }
  };

  setDefaultLotterySetting = (defaultSetting: IV2LotterySetting[]) => {
    this.setState({ lotterySettingState: defaultSetting }, () => {
      this.onClickLotteryCard(0, defaultSetting[0], false, true);
      this.props.setFieldValue("lotterySetting", defaultSetting);
      this.setState({ selectedLotto: [defaultSetting[0]] }, () => {});
    });
  };

  handleScrollToTop = () => {
    if (this.userContainerRef.current) {
      this.userContainerRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  renderLotteryCard = (data: IV2LotterySetting) => {
    const RenderBadge = () => {
      if (data.isNotSavedYet) {
        return (
          <div className="is-not-saved-yet-lottery-setting-text mt-1">
            {constants.isNotSavedYet}
          </div>
        );
      }
      if (data.isSaved) {
        return (
          <div className="save-lottery-setting-text mt-1">
            {constants.saved}
          </div>
        );
      }
      return <></>;
    };

    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div
          className={`${
            data.childLotsetIsOpen ? "badge" : "badge-close"
          } d-flex align-items-center justify-content-center`}
        >
          <div
            className={`${
              data.childLotsetIsOpen ? "badge-text" : "badge-text-close"
            }`}
          >{`${data.childLotsetIsOpen ? "เปิด" : "ปิด"}`}</div>
        </div>
        <RenderBadge />
      </div>
    );
  };

  afterClickLotteryCard = (
    index: number,
    data: IV2LotterySetting,
    shouldScroll: boolean = true,
    isFirstTimeClicked: boolean = false
  ) => {
    const { /* setTouched, touched, */ setValues, values } = this.props;
    if (shouldScroll) {
      setTimeout(() => {
        this.handleScrollToTop();
      }, 256);
    }

    /*  setTouched({ ...touched, hostSharePercentage: false, fixSharePercentage: false }); */

    if (data && !isEmpty(this.props.lotterySetting)) {
      setValues({
        ...values,
        childLotsetIsOpen: data.childLotsetIsOpen,
        childLotsetKeep: data.childLotsetKeep,
        hostSharePercentage: data.childLotsetMeShare,
        fixSharePercentage: data.childLotsetMustReciv,
        discountAll: "",

        childLotsetBetRateRunDown: data.childLotsetBetRateRunDown,
        childLotsetBetRateRunUp: data.childLotsetBetRateRunUp,
        childLotsetBetRateTwoDown: data.childLotsetBetRateTwoDown,
        childLotsetBetRateTwoUp: data.childLotsetBetRateTwoUp,
        childLotsetBetRateThreeToast: data.childLotsetBetRateThreeToast,
        childLotsetBetRateThreeUp: data.childLotsetBetRateThreeUp,

        childLotsetDisRunDown: data.childLotsetDisRunDown,
        childLotsetDisRunUp: data.childLotsetDisRunUp,
        childLotsetDisTwoDown: data.childLotsetDisTwoDown,
        childLotsetDisTwoUp: data.childLotsetDisTwoUp,
        childLotsetDisThreeToast: data.childLotsetDisThreeToast,
        childLotsetDisThreeUp: data.childLotsetDisThreeUp,

        childLotsetShareComRunDown: data.childLotsetShareComRunDown,
        childLotsetShareComRunUp: data.childLotsetShareComRunUp,
        childLotsetShareComThreeToast:data.childLotsetShareComThreeToast,
        childLotsetShareComThreeUp: data.childLotsetShareComThreeUp,
        childLotsetShareComTwoDown:data.childLotsetShareComTwoDown,
        childLotsetShareComTwoUp: data.childLotsetShareComTwoUp,


        childLotsetBetMinThreeUp: data.childLotsetBetMinThreeUp,
        childLotsetBetMinThreeToast: data.childLotsetBetMinThreeToast,
        childLotsetBetMinTwoUp: data.childLotsetBetMinTwoUp,
        childLotsetBetMinTwoDown: data.childLotsetBetMinTwoDown,
        childLotsetBetMinRunUp: data.childLotsetBetMinRunUp,
        childLotsetBetMinRunDown: data.childLotsetBetMinRunDown,
        childLotsetBetMaxThreeUp: data.childLotsetBetMaxThreeUp,
        childLotsetBetMaxThreeToast: data.childLotsetBetMaxThreeToast,
        childLotsetBetMaxTwoUp: data.childLotsetBetMaxTwoUp,
        childLotsetBetMaxTwoDown: data.childLotsetBetMaxTwoDown,
        childLotsetBetMaxRunUp: data.childLotsetBetMaxRunUp,
        childLotsetBetMaxRunDown: data.childLotsetBetMaxRunDown,
        meShare: this.props.lotterySetting[index].childLotsetMeShare,
        mustReciv: this.props.lotterySetting[index].childLotsetMustReciv,
        childLotsetBetMinThreeUpAPI: this.props.lotterySetting[index]
          .childLotsetBetMinThreeUp,
        childLotsetBetMinThreeToastAPI: this.props.lotterySetting[index]
          .childLotsetBetMinThreeToast,
        childLotsetBetMinTwoUpAPI: this.props.lotterySetting[index]
          .childLotsetBetMinTwoUp,
        childLotsetBetMinTwoDownAPI: this.props.lotterySetting[index]
          .childLotsetBetMinTwoDown,
        childLotsetBetMinRunUpAPI: this.props.lotterySetting[index]
          .childLotsetBetMinRunUp,
        childLotsetBetMinRunDownAPI: this.props.lotterySetting[index]
          .childLotsetBetMinRunDown,
        childLotsetBetMaxThreeUpAPI: this.props.lotterySetting[index]
          .childLotsetBetMaxThreeUp,
        childLotsetBetMaxThreeToastAPI: this.props.lotterySetting[index]
          .childLotsetBetMaxThreeToast,
        childLotsetBetMaxTwoUpAPI: this.props.lotterySetting[index]
          .childLotsetBetMaxTwoUp,
        childLotsetBetMaxTwoDownAPI: this.props.lotterySetting[index]
          .childLotsetBetMaxTwoDown,
        childLotsetBetMaxRunUpAPI: this.props.lotterySetting[index]
          .childLotsetBetMaxRunUp,
        childLotsetBetMaxRunDownAPI: this.props.lotterySetting[index]
          .childLotsetBetMaxRunDown,

        childLotsetBetRateThreeUpAPI:
          this.props.meSettingLotteryData.lotterySetting[index]
            ?.childLotsetBetRateThreeUp || 0,
        childLotsetBetRateThreeToastAPI:
          this.props.meSettingLotteryData.lotterySetting[index]
            ?.childLotsetBetRateThreeToast || 0,
        childLotsetBetRateTwoUpAPI:
          this.props.meSettingLotteryData.lotterySetting[index]
            ?.childLotsetBetRateTwoUp || 0,
        childLotsetBetRateTwoDownAPI:
          this.props.meSettingLotteryData.lotterySetting[index]
            ?.childLotsetBetRateTwoDown || 0,
        childLotsetBetRateRunUpAPI:
          this.props.meSettingLotteryData.lotterySetting[index]
            ?.childLotsetBetRateRunUp || 0,
        childLotsetBetRateRunDownAPI:
          this.props.meSettingLotteryData.lotterySetting[index]
            ?.childLotsetBetRateRunDown || 0,
      });
    }

    const clonedSelectedLotto = cloneDeep(this.state.selectedLotto);
    clonedSelectedLotto[0] = this.state.lotterySettingState[index];
    this.setState({ selectedLotto: clonedSelectedLotto });
  };

  onClickLotteryCard = (
    index: number,
    data: IV2LotterySetting,
    shouldScroll: boolean = true,
    isFirstTimeClicked: boolean = false
  ) => {
    const { mode } = this.props;
    if (
      (mode === "UPDATE" &&
        !isFirstTimeClicked &&
        !this.state.selectedLotto[0].isSaved) ||
      (this.state.selectedLotto[0]?.isNotSavedYet && !isFirstTimeClicked)
    ) {
      ConfirmModal.show({
        action: () => {
          this.afterClickLotteryCard(index, data, shouldScroll);
          ConfirmModal.hide();
        },
        cancelAction: () => {
          ConfirmModal.hide();
        },
        description: constants.isChangeLottery,
      });
    } else if (
      mode === "CREATE" ||
      isFirstTimeClicked ||
      this.state.selectedLotto[0].isSaved
    ) {
      this.afterClickLotteryCard(index, data, shouldScroll, isFirstTimeClicked);
    }
  };

  renderLotteryList = () => {
    const selectedLottoIndex = findIndex(
      this.props.lotterySetting,
      (item) =>
        item.childLotsetName === this.state.selectedLotto[0]?.childLotsetName
    );
    return this.state.lotterySettingState.map((data, index) => {
      let FlagIcon: string;
      if (data.childLotsetName.includes("YEGEE")) {
        FlagIcon = LottoFlags["THA"].Icon;
      } else {
        FlagIcon = LottoFlags[LOTTO_FLAG_ALPHA[data.childLotsetName]]?.Icon;
      }
      return (
        <div
          onClick={() => this.onClickLotteryCard(index, data)}
          className={`lottery-card ${!data.childLotsetIsOpen && "closed"} ${
            selectedLottoIndex === index && "active"
          } d-flex flex-row align-items-center justify-content-between`}
          key={index}
        >
          <div className="d-flex flex-row align-items-center">
            <ResponsiveIcon icon={FlagIcon} alt="flag" className="flag" />
            <div
              className={`code-name-text ${
                !data.childLotsetIsOpen && "closed"
              }`}
            >
              {data.childLotsetName.includes("YEGEE")
                ? `${CODE_TO_NAME["YEGEE"]}รอบที่ ${Number(
                    data.childLotsetName.split("_")[1]
                  )}`
                : CODE_TO_NAME[data.childLotsetName]}
            </div>
          </div>
          {this.renderLotteryCard(data)}
        </div>
      );
    });
  };

  renderLotteryConfig = () => {
    if (!isEmpty(this.state.selectedLotto) && this.state.selectedLotto[0]) {
      const {
        childLotsetName,
        childLotsetIsOpen,
      } = this.state.selectedLotto[0];
      const selectedLottoIndex = findIndex(
        this.props.lotterySetting,
        (item) =>
          item.childLotsetName === this.state.selectedLotto[0]?.childLotsetName
      );
      const {
        values,
        setFieldValue,
        handleBlur,
        errors,
        touched,
        setValues,
      } = this.props;

      const RenderCodeToTypeNameSetting = this.renderCodeToTypeNameSetting();
      const formatStatusSelect = () => {
        let formattedStatusSelect: any[] = [];
        forOwn(STATUS, function (values, keys) {
          formattedStatusSelect.push({
            label: values,
            value: keys === "OPEN" ? true : false,
            disabled: false,
          });
        });
        return formattedStatusSelect;
      };
      const formattedStatusSelect = formatStatusSelect();

      const formatRemainderHoldSelect = () => {
        let formatRemainderHoldSelect: any[] = [];
        forOwn(REMAINDER_HOLD_SELECT, function (values, keys) {
          formatRemainderHoldSelect.push({
            label: values,
            value: keys === "KEEP" ? true : false,
            disabled: false,
          });
        });
        return formatRemainderHoldSelect;
      };
      const formattedRemainderHoldSelect = formatRemainderHoldSelect();

      return (
        <>
          <div className="row">
            <div className="col-12">
              <h4 className="lottery-config-name-text">
                {childLotsetName.includes("YEGEE")
                  ? `${CODE_TO_NAME["YEGEE"]}รอบที่ ${Number(
                      childLotsetName.split("_")[1]
                    )}`
                  : CODE_TO_NAME[childLotsetName]}
              </h4>
            </div>
            <div className="col-5 mt-4">
              <label className="label-input">{constants.status}</label>
              <Select
                styles={createMemberDropdownStyles(
                  !this.props.isOtpClicked || !this.state.isEditable
                )}
                components={{
                  IndicatorSeparator: () => null,
                }}
                name="childLotsetIsOpen"
                options={formattedStatusSelect}
                value={
                  formattedStatusSelect[
                    findIndex(
                      formattedStatusSelect,
                      (item) => item.value === values.childLotsetIsOpen
                    )
                  ]
                }
                onChange={(e) => {
                  setFieldValue("childLotsetIsOpen", e.value);
                  if (this.props.mode === "CREATE") {
                    const clonedSelectedLotto = cloneDeep(
                      this.state.selectedLotto
                    );
                    clonedSelectedLotto[0].childLotsetIsOpen = e.value;
                    this.setState({ selectedLotto: clonedSelectedLotto });
                  }
                }}
                isDisabled={!this.props.isOtpClicked || !this.state.isEditable}
                noOptionsMessage={() => constants.noInfo}
              />
            </div>
            <div className="col-7 mt-4"></div>
            <div className="col-2 mt-4">
              <label className="label-input">{constants.storage}</label>
              <Select
                styles={createMemberDropdownStyles(
                  !childLotsetIsOpen ||
                    !this.props.isOtpClicked ||
                    !this.state.isEditable
                )}
                components={{
                  IndicatorSeparator: () => null,
                }}
                name="childLotsetKeep"
                options={formattedRemainderHoldSelect}
                value={
                  formattedRemainderHoldSelect[
                    findIndex(
                      formattedRemainderHoldSelect,
                      (item) => item.value === values.childLotsetKeep
                    )
                  ]
                }
                onChange={(e) => {
                  setFieldValue("childLotsetKeep", e.value);
                  if (this.props.mode === "CREATE") {
                    const clonedSelectedLotto = cloneDeep(
                      this.state.selectedLotto
                    );
                    clonedSelectedLotto[0].childLotsetKeep = e.value;
                    this.setState({ selectedLotto: clonedSelectedLotto });
                  }
                }}
                isDisabled={
                  !childLotsetIsOpen ||
                  !this.props.isOtpClicked ||
                  !this.state.isEditable
                }
                noOptionsMessage={() => constants.noInfo}
              />
            </div>
            <div className="col-5 mt-4">
              <label className="label-input">
                {constants.percen1}
                {this.props.meData.permission === "OWNER" &&
                window.location.pathname === routes.userDetail.path ? (
                  <></>
                ) : (
                  <span className="label-input-note">
                    {!isEmpty(this.props.lotterySetting)
                      ? constants.percen1Note(
                          this.props.lotterySetting[selectedLottoIndex]
                            .childLotsetMustReciv,
                          this.props.lotterySetting[selectedLottoIndex]
                            .childLotsetMeShare
                        )
                      : constants.percen1Note(0, 0)}
                  </span>
                )}
              </label>
              <div className="d-flex flex-row">
                <div className="hostSharePercentageWrapper w-100">
                  <InputNumber
                    id="hostSharePercentage"
                    name="hostSharePercentage"
                    value={values.hostSharePercentage}
                    placeholder={constants.percen1Placeholder}
                    onBlur={handleBlur}
                    onValueChange={(values) => {
                      setFieldValue("hostSharePercentage", values.value);
                      if (this.props.mode === "CREATE") {
                        const clonedSelectedLotto = cloneDeep(
                          this.state.selectedLotto
                        );
                        clonedSelectedLotto[0].childLotsetMeShare = Number(
                          values.value
                        );
                        this.setState({ selectedLotto: clonedSelectedLotto });
                      }
                    }}
                    isNumericString
                    inputClassName="minBet"
                    backgroundColor={colors.TRANSPARENT}
                    decimalScale={2}
                    allowNegative={false}
                    isAllowed={({ floatValue }) =>
                      floatValue ? floatValue <= 100 : true
                    }
                    disabled={
                      !childLotsetIsOpen ||
                      !this.props.isOtpClicked ||
                      !this.state.isEditable
                    }
                    renderError={false}
                    mode={"InputElements"}
                    endText={
                      this.props.meData?.permission === "OWNER" &&
                      window.location.pathname === routes.userDetail.path
                        ? "%"
                        : constants.hostSharePercentagePlaceholder(
                            !isEmpty(this.props.lotterySetting)
                              ? this.props.lotterySetting[selectedLottoIndex]
                                  .childLotsetMeShare
                              : 0
                          )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-5 mt-4">
              <label className="label-input">
                {constants.percen2}
                {this.props.meData.permission === "OWNER" &&
                window.location.pathname === routes.userDetail.path ? (
                  <></>
                ) : (
                  <span className="label-input-note">
                    {constants.percen2Note}
                  </span>
                )}
              </label>
              <div className="d-flex flex-row">
                <div className="fixSharePercentageWrapper w-100">
                  <InputNumber
                    id="fixSharePercentage"
                    name="fixSharePercentage"
                    value={values.fixSharePercentage}
                    placeholder={constants.percen2Placeholder}
                    onBlur={handleBlur}
                    onValueChange={(values) => {
                      setFieldValue("fixSharePercentage", values.value);
                      if (this.props.mode === "CREATE") {
                        const clonedSelectedLotto = cloneDeep(
                          this.state.selectedLotto
                        );
                        clonedSelectedLotto[0].childLotsetMustReciv = Number(
                          values.value
                        );
                        this.setState({ selectedLotto: clonedSelectedLotto });
                      }
                    }}
                    isNumericString
                    inputClassName="minBet"
                    backgroundColor={colors.TRANSPARENT}
                    decimalScale={2}
                    allowNegative={false}
                    isAllowed={({ floatValue }) =>
                      floatValue ? floatValue <= 100 : true
                    }
                    disabled={
                      !childLotsetIsOpen ||
                      !this.props.isOtpClicked ||
                      !this.state.isEditable
                    }
                    renderError={false}
                    endText={constants.hostSharePercentagePlaceholder(
                      values.hostSharePercentage
                    )}
                    mode="InputElements"
                  />
                </div>
              </div>
            </div>
            <div className="col-2" />
            <div className="col-5">
              <h6 className="primary-red-text" style={{ marginTop: 6 }}>
                {!!errors.hostSharePercentage && touched.hostSharePercentage
                  ? errors.hostSharePercentage === "error"
                    ? `ถือสู้ต้องไม่ต่ำกว่า ${values.mustReciv}% และไม่มากกว่า ${values.meShare} %`
                    : errors.hostSharePercentage
                  : "\u00A0"}
              </h6>
            </div>
            <div className="col-5">
              <h6 className="primary-red-text" style={{ marginTop: 6 }}>
                {!!errors.fixSharePercentage && touched.fixSharePercentage
                  ? errors.fixSharePercentage === "error"
                    ? `บังคับสู้ต้องไม่มากกว่าเปอร์เซ็นต์ถือสู้`
                    : errors.fixSharePercentage
                  : "\u00A0"}
              </h6>
            </div>
            <div className="col-12" style={{ marginTop: 6 }}>
              <label className="label-input">
                {constants.codeToTypeNameSetting}
              </label>
            </div>
            <div className="col-5 mt-4">
              <label className="label-input">
                {constants.allDiscountPercentage}
              </label>
              <InputNumber
                id="discountAll"
                name="discountAll"
                value={values.discountAll}
                placeholder={constants.allDiscountPercentagePlaceholder}
                onBlur={handleBlur}
                onChange={(e) => {
                  const convertedTargetValue = isEmpty(e.target.value)
                    ? 0
                    : e.target.value;
                  setValues({
                    ...values,
                    childLotsetDisRunDown: convertedTargetValue,
                    childLotsetDisRunUp: convertedTargetValue,
                    childLotsetDisThreeToast: convertedTargetValue,
                    childLotsetDisThreeUp: convertedTargetValue,
                    childLotsetDisTwoDown: convertedTargetValue,
                    childLotsetDisTwoUp: convertedTargetValue,
                    discountAll: e.target.value,
                  });

                  if (this.props.mode === "CREATE") {
                    const clonedSelectedLotto = cloneDeep(
                      this.state.selectedLotto
                    );
                    const DisPropArr = Object.keys(
                      clonedSelectedLotto[0]
                    ).filter((key) => key.includes("Dis"));

                    DisPropArr.forEach((val, _) => {
                      (clonedSelectedLotto[0] as any)[val] = Number(
                        convertedTargetValue
                      );
                    });
                    this.setState({ selectedLotto: clonedSelectedLotto });
                  }
                }}
                errorMessage={errors.discountAll}
                error={!!errors.discountAll && touched.discountAll}
                inputClassName="input-otp"
                backgroundColor={colors.TRANSPARENT}
                allowNegative={false}
                decimalScale={2}
                isAllowed={({ floatValue }) =>
                  floatValue ? floatValue <= 100 : true
                }
                disabled={!this.props.isOtpClicked || !this.state.isEditable}
                mode="InputElements"
                endText="%"
              />
            </div>
            <div className="col-7 mt-4"></div>
            {RenderCodeToTypeNameSetting}
            {this.state.isEditable && (
              <div className="col-12 m4-t">
                <div className="d-flex flex-row justify-content-end">
                  <div className="m2-r">
                    <Button
                      id="reset-new-lotto-bet-config"
                      text={constants.reset}
                      textColor={colors.TERTIARY_TEXT}
                      textSize={18}
                      buttonClass="reset-button"
                      onClick={() => {
                        this.resetLotterySetting();
                      }}
                    />
                  </div>
                  <div>
                    <Button
                      id="save-new-lotto-bet-config"
                      text={constants.saveBetSetting}
                      textSize={18}
                      buttonClass="save-button"
                      onClick={() => {
                        this.saveLotterySetting();
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      );
    }
    return <></>;
  };

  checkIfAllDiscountEqual = () => {
    let DisValArr: number[] = [];
    const clonedSelectedLotto = cloneDeep(this.state.selectedLotto);
    const DisPropArr = Object.keys(clonedSelectedLotto[0]).filter((key) =>
      key.includes("Dis")
    );
    DisPropArr.forEach((val, index) => {
      DisValArr.push(Number((clonedSelectedLotto[0] as any)[val]));
    });
    const isAllDiscountEqual = DisValArr.every(
      (currentVal, _, arr) => currentVal === arr[0]
    );
    return isAllDiscountEqual;
  };

  loopToSetDefaultChildLotsetLimit = (lotterySetting: IV2LotterySetting[]) => {
    const clonedLotterySetting = cloneDeep(lotterySetting);
    clonedLotterySetting.forEach((item, index) => {
      if (window.location.pathname === routes.createMember.path) {
        item.childLotsetDisRunDown = 0;
        item.childLotsetDisRunUp = 0;
        item.childLotsetDisThreeToast = 0;
        item.childLotsetDisThreeUp = 0;
        item.childLotsetDisTwoDown = 0;
        item.childLotsetDisTwoUp = 0;
      }
      item.childLotsetMeShare = window.location.pathname === routes.userDetail.path ? item.childLotsetMeShare : item.childLotsetMustReciv;
      item.childLotsetBetMaxThreeUp =
        item.childLotsetBetMaxThreeUp >= 1000
          ? 1000
          : item.childLotsetBetMaxThreeUp;
      item.childLotsetBetMaxThreeToast =
        item.childLotsetBetMaxThreeToast >= 1000
          ? 1000
          : item.childLotsetBetMaxThreeToast;
      item.childLotsetBetMaxTwoUp =
        item.childLotsetBetMaxTwoUp >= 1000
          ? 1000
          : item.childLotsetBetMaxTwoUp;
      item.childLotsetBetMaxTwoDown =
        item.childLotsetBetMaxTwoDown >= 1000
          ? 1000
          : item.childLotsetBetMaxTwoDown;
      item.childLotsetBetMaxRunUp =
        item.childLotsetBetMaxRunUp >= 1000
          ? 1000
          : item.childLotsetBetMaxRunUp;
      item.childLotsetBetMaxRunDown =
        item.childLotsetBetMaxRunDown >= 1000
          ? 1000
          : item.childLotsetBetMaxRunDown;
    });
    return clonedLotterySetting;
  };

  resetLotterySetting = () => {
    const { setValues, values, mode, childDetailData } = this.props;

    if (window.location.pathname === routes.userDetail.path) {
      this.setState({ isEditable: false });
    }

    const selectedLottoIndex = findIndex(
      this.props.lotterySetting,
      (item) =>
        item.childLotsetName === this.state.selectedLotto[0]?.childLotsetName
    );

    const clonedLotterySetting =
      mode === "CREATE"
        ? this.loopToSetDefaultChildLotsetLimit(this.props.lotterySetting)
        : this.loopToSetDefaultChildLotsetLimit(
            childDetailData![0].lotterySetting || []
          );

    clonedLotterySetting.forEach((item) => {
      item.isSaved = false;
      item.isNotSavedYet = false;
    });

    const clonedSelectedLotto = cloneDeep(this.state.selectedLotto);
    clonedSelectedLotto[0] = clonedLotterySetting[selectedLottoIndex];

    const clonedLotterySettingState = cloneDeep(this.state.lotterySettingState);
    clonedLotterySettingState[selectedLottoIndex] = clonedSelectedLotto[0];

    this.setState({ lotterySettingState: clonedLotterySetting }, () => {
      if (window.location.pathname === routes.createMember.path) {
        localStorage.setItem(
          "lotterySettingState",
          JSON.stringify(clonedLotterySetting)
        );
      } else if (window.location.pathname === routes.memberDetail.path) {
        localStorage.setItem(
          "lotterySettingMemberDetailState",
          JSON.stringify(clonedLotterySetting)
        );
      } else if (window.location.pathname === routes.userDetail.path) {
        /* localStorage.setItem(
          "lotterySettingUserDetailState",
          JSON.stringify(clonedLotterySetting)
        ); */
      }
    });
    const originalValue = clonedLotterySetting[selectedLottoIndex];

    this.setState({ selectedLotto: clonedSelectedLotto }, () => {
      setValues({
        ...values,
        childLotsetBetRateRunDown: originalValue.childLotsetBetRateRunDown,
        childLotsetBetRateRunUp: originalValue.childLotsetBetRateRunUp,
        childLotsetBetRateThreeToast:
          originalValue.childLotsetBetRateThreeToast,
        childLotsetBetRateThreeUp: originalValue.childLotsetBetRateThreeUp,
        childLotsetBetRateTwoDown: originalValue.childLotsetBetRateTwoDown,
        childLotsetBetRateTwoUp: originalValue.childLotsetBetRateTwoUp,
        childLotsetDisRunDown: originalValue.childLotsetDisRunDown,
        childLotsetDisRunUp: originalValue.childLotsetDisRunUp,
        childLotsetDisThreeToast: originalValue.childLotsetDisThreeToast,
        childLotsetDisThreeUp: originalValue.childLotsetDisThreeUp,
        childLotsetDisTwoDown: originalValue.childLotsetDisTwoDown,
        childLotsetDisTwoUp: originalValue.childLotsetDisTwoUp,

        childLotsetShareComThreeUp: originalValue.childLotsetShareComThreeUp,
        childLotsetShareComThreeToast: originalValue.childLotsetShareComThreeToast,
        childLotsetShareComTwoUp: originalValue.childLotsetShareComTwoUp,
        childLotsetShareComTwoDown: originalValue.childLotsetShareComTwoDown,
        childLotsetShareComRunUp: originalValue.childLotsetShareComRunUp,
        childLotsetShareComRunDown: originalValue.childLotsetShareComRunDown,

        childLotsetBetMinThreeUp: originalValue.childLotsetBetMinThreeUp,
        childLotsetBetMinThreeToast: originalValue.childLotsetBetMinThreeToast,
        childLotsetBetMinTwoUp: originalValue.childLotsetBetMinTwoUp,
        childLotsetBetMinTwoDown: originalValue.childLotsetBetMinTwoDown,
        childLotsetBetMinRunUp: originalValue.childLotsetBetMinRunUp,
        childLotsetBetMinRunDown: originalValue.childLotsetBetMinRunDown,
        childLotsetBetMaxThreeUp: originalValue.childLotsetBetMaxThreeUp,
        childLotsetBetMaxThreeToast: originalValue.childLotsetBetMaxThreeToast,
        childLotsetBetMaxTwoUp: originalValue.childLotsetBetMaxTwoUp,
        childLotsetBetMaxTwoDown: originalValue.childLotsetBetMaxTwoDown,
        childLotsetBetMaxRunUp: originalValue.childLotsetBetMaxRunUp,
        childLotsetBetMaxRunDown: originalValue.childLotsetBetMaxRunDown,
        hostSharePercentage: window.location.pathname === routes.userDetail.path ? originalValue.childLotsetMeShare : originalValue.childLotsetMustReciv,
        fixSharePercentage: originalValue.childLotsetMustReciv,
        discountAll: "",
        childLotsetIsOpen: originalValue.childLotsetIsOpen,
        childLotsetKeep: originalValue.childLotsetKeep,
        lotterySetting: clonedLotterySetting,
      });
      SuccessModal.show({
        action: () => SuccessModal.hide(),
        description: constants.resetLotterySettingSuccessful,
      });
    });
  };

  saveLotterySetting = () => {
    const { setFieldValue, errors, touched } = this.props;

    if (
      (!!errors.hostSharePercentage && touched.hostSharePercentage) ||
      (!!errors.fixSharePercentage && touched.fixSharePercentage) ||
      (!!errors.childLotsetDisThreeUp && touched.childLotsetDisThreeUp) ||
      (!!errors.childLotsetDisThreeToast && touched.childLotsetDisThreeToast) ||
      (!!errors.childLotsetDisTwoDown && touched.childLotsetDisTwoDown) ||
      (!!errors.childLotsetDisTwoUp && touched.childLotsetDisTwoUp) ||
      (!!errors.childLotsetDisRunUp && touched.childLotsetDisRunUp) ||
      (!!errors.childLotsetDisRunDown && touched.childLotsetDisRunDown) ||

      (!!errors.childLotsetShareComThreeUp && touched.childLotsetShareComThreeUp) ||
      (!!errors.childLotsetShareComThreeToast && touched.childLotsetShareComThreeToast) ||
      (!!errors.childLotsetShareComTwoDown && touched.childLotsetShareComTwoDown) ||
      (!!errors.childLotsetShareComTwoUp && touched.childLotsetShareComTwoUp) ||
      (!!errors.childLotsetShareComRunUp && touched.childLotsetShareComRunUp) ||
      (!!errors.childLotsetShareComRunDown && touched.childLotsetShareComRunDown) ||

      (!!errors.childLotsetBetMinThreeUp && touched.childLotsetBetMinThreeUp) ||
      (!!errors.childLotsetBetMinThreeToast &&
        touched.childLotsetBetMinThreeToast) ||
      (!!errors.childLotsetBetMinTwoDown && touched.childLotsetBetMinTwoDown) ||
      (!!errors.childLotsetBetMinTwoUp && touched.childLotsetBetMinTwoUp) ||
      (!!errors.childLotsetBetMinRunUp && touched.childLotsetBetMinRunUp) ||
      (!!errors.childLotsetBetMinRunDown && touched.childLotsetBetMinRunDown) ||
      (!!errors.childLotsetBetMaxThreeUp && touched.childLotsetBetMaxThreeUp) ||
      (!!errors.childLotsetBetMaxThreeToast &&
        touched.childLotsetBetMaxThreeToast) ||
      (!!errors.childLotsetBetMaxTwoDown && touched.childLotsetBetMaxTwoDown) ||
      (!!errors.childLotsetBetMaxTwoUp && touched.childLotsetBetMaxTwoUp) ||
      (!!errors.childLotsetBetMaxRunUp && touched.childLotsetBetMaxRunUp) ||
      (!!errors.childLotsetBetMaxRunDown && touched.childLotsetBetMaxRunDown)
    ) {
      ErrorModal.show({
        action: () => {
          ErrorModal.hide();
        },
        description: constants.lotterySettingParameterIncomplete,
      });
      this.handleScrollToTop();
    } else {
      if (window.location.pathname === routes.userDetail.path) {
        this.setState({ isEditable: false });
      }

      const selectedLottoIndex = findIndex(
        this.props.lotterySetting,
        (item) =>
          item.childLotsetName === this.state.selectedLotto[0]?.childLotsetName
      );
      const clonedSelectedLotto = cloneDeep(this.state.selectedLotto);
      const clonedLotterySetting = cloneDeep(this.state.lotterySettingState);
      clonedLotterySetting[selectedLottoIndex] = clonedSelectedLotto[0];

      if (window.location.pathname === routes.createMember.path) {
        SuccessModal.show({
          title: constants.editLotterySuccessful,
          action: () => SuccessModal.hide(),
          description: constants.saveLotterySettingSuccessful,
        });

        const storedLotterySettingState =
          localStorage.getItem("lotterySettingState") || "";

        if (isEmpty(storedLotterySettingState)) {
          clonedLotterySetting[selectedLottoIndex].isSaved = true;
          clonedLotterySetting[selectedLottoIndex].isNotSavedYet = false;
        } else {
          const parsedLotterySetting: IV2LotterySetting[] = JSON.parse(
            storedLotterySettingState
          );
          const differentLotterySetting = differenceWith(
            clonedLotterySetting,
            parsedLotterySetting,
            isEqual
          );
          let indexes: number[] = [];
          clonedLotterySetting.forEach((item, index) => {
            if (differentLotterySetting.includes(item)) {
              indexes.push(index);
            }
          });
          indexes.forEach((item) => {
            clonedLotterySetting[item].isSaved = true;
            clonedLotterySetting[item].isNotSavedYet = false;
          });
        }

        this.setState(
          { selectedLotto: [clonedLotterySetting[selectedLottoIndex]] },
          () => {
            this.setState({ lotterySettingState: clonedLotterySetting }, () => {
              setFieldValue("lotterySetting", clonedLotterySetting);
              localStorage.setItem(
                "lotterySettingState",
                JSON.stringify(clonedLotterySetting)
              );
            });
          }
        );
      } else if (window.location.pathname === routes.userDetail.path) {
        const omittedClonedLotterySetting = clonedLotterySetting.map((item) => {
          return omit(item, ["isNotSavedYet", "isSaved"]);
        });
        this.props.onChangeIsLoaddingAction!(true);
        this.props.postOwnerSettingLotteryUpdate!(omittedClonedLotterySetting)
          .then(() => {
            this.props.getMeSettingLottery!().then(() => {
              clonedLotterySetting[selectedLottoIndex].isSaved = true;
              clonedLotterySetting[selectedLottoIndex].isNotSavedYet = false;
              this.setState(
                { selectedLotto: [clonedLotterySetting[selectedLottoIndex]] },
                () => {
                  this.setState(
                    { lotterySettingState: clonedLotterySetting },
                    () => {
                      setFieldValue("lotterySetting", clonedLotterySetting);
                    }
                  );
                }
              );
              SuccessModal.show({
                action: () => SuccessModal.hide(),
                description: constants.postOwnerSettingLotteryUpdateSuccess,
              });
              this.props.onChangeIsLoaddingAction!(false);
            });
          })
          .catch(() => {
            this.props.onChangeIsLoaddingAction!(false);
            ErrorModal.show({
              action: ErrorModal.hide,
              description: responseMessage()[
                this.props.ownerSettingLotteryUpdateCode || 0
              ],
            });
          });
      } else if (window.location.pathname === routes.memberDetail.path) {
        const pickedLotterySetting = pick(this.props.values, [
          ...selectedProps,
        ]);
        const addedPickedLotterySetting = {
          ...pickedLotterySetting,
          ...{
            childLotsetMeShare: Number(this.props.values.hostSharePercentage),
            childLotsetMustReciv: Number(this.props.values.fixSharePercentage),
            childLotsetName: this.state.selectedLotto[0].childLotsetName,
          },
        };
        const numberedAddedPickLotterySetting = mapValues(
          addedPickedLotterySetting,
          (val, key, obj) => {
            if (
              key.includes("Dis") ||
              key.includes("Bet") ||
              key.includes("fix") ||
              key.includes("host")
            ) {
              return ((obj as any)[key] = Number(val));
            } else {
              return ((obj as any)[key] = val);
            }
          }
        );

        clonedLotterySetting[
          selectedLottoIndex
        ] = numberedAddedPickLotterySetting as IV2LotterySetting;

        this.props.onChangeIsLoaddingAction!(true);
        this.props.postChildSettingLotteryUpdate!({
          childAgentId: this.props.childDetailData![0]?.childAgentId || 0,
          lotterySetting: clonedLotterySetting,
        })
          .then(() => {
            this.props.onChangeIsLoaddingAction!(false);
            SuccessModal.show({
              title: constants.editLotterySuccessful,
              action: () => SuccessModal.hide(),
              description: constants.saveLotterySettingSuccessful,
            });
            clonedLotterySetting[selectedLottoIndex].isSaved = true;
            clonedLotterySetting[selectedLottoIndex].isNotSavedYet = false;
            this.setState(
              { selectedLotto: [clonedLotterySetting[selectedLottoIndex]] },
              () => {
                this.setState(
                  { lotterySettingState: clonedLotterySetting },
                  () => {
                    this.props.setFieldValue(
                      "lotterySetting",
                      clonedLotterySetting
                    );

                    localStorage.setItem(
                      "lotterySettingMemberDetailState",
                      JSON.stringify(clonedLotterySetting)
                    );
                  }
                );
                this.props.getChildDetail!({
                  childAgentId:
                    this.props.childDetailData![0]?.childAgentId || 0,
                  limit: 10,
                  page: 1,
                });
              }
            );
          })
          .catch(() => {
            this.props.onChangeIsLoaddingAction!(false);
            ErrorModal.show({
              action: ErrorModal.hide,
              description: responseMessage()[
                this.props.childSettingLotteryUpdateCode || 0
              ],
            });
          });
      }
    }
  };

  renderCodeToTypeNameSetting = () => {
    const { setFieldValue, handleBlur, values, errors, touched } = this.props;
    const { childLotsetName } = this.state.selectedLotto[0];

    const selectedLottoIndex = findIndex(
      this.props.lotterySetting,
      (item) =>
        item.childLotsetName === this.state.selectedLotto[0]?.childLotsetName
    );

    return LOTTO_GAME_TYPES[
      `LOTTER_${
        childLotsetName.includes("YEGEE") ? "YEGEE" : childLotsetName
      }` as TLottoSlug
    ].map((item, index) => {
      return (
        <React.Fragment key={index}>
          <div className="col-12" style={{ marginTop: 6 }}>
            <div className="d-flex flex-row align-items-start justify-content-between h-100">
              <div
                style={{ width: "7%" }}
                className="h-100 d-flex align-items-center"
              >
                <label className="label-input">
                  {CODE_TO_TYPENAME_NUMBER[item]}
                </label>
              </div>
              <div style={{ width: "21%" }}>
                <label className="label-input">
                  {constants.payRatePercentage}
                </label>
                <div className="d-flex flex-row">
                  <div style={{ width: "100%" }}>
                    <InputNumber
                      id={`childLotsetBetRate${pascalCase(item)}`}
                      name={`childLotsetBetRate${pascalCase(item)}`}
                      value={
                        (values as any)[`childLotsetBetRate${pascalCase(item)}`]
                      }
                      isNumericString
                      thousandSeparator
                      allowNegative={false}
                      onBlur={handleBlur}
                      onValueChange={(values) => {
                        setFieldValue(
                          `childLotsetBetRate${pascalCase(item)}`,
                          values.value
                        );
                        if (this.props.mode === "CREATE") {
                          const clonedSelectedLotto = cloneDeep(
                            this.state.selectedLotto
                          );
                          (clonedSelectedLotto[0] as any)[
                            `childLotsetBetRate${pascalCase(item)}`
                          ] = Number(values.value);
                          this.setState({ selectedLotto: clonedSelectedLotto });
                        }
                      }}
                      backgroundColor={colors.TRANSPARENT}
                      inputClassName="payRatePercentage"
                      disabled={
                        !this.props.isOtpClicked || !this.state.isEditable
                      }
                      renderError={false}
                      mode="InputElements"
                      startText={constants.betRate}
                      endText={
                        this.props.meData?.permission === "OWNER" &&
                        window.location.pathname === routes.userDetail.path
                          ? ""
                          : constants.betRateLimit(
                              get(
                                this.props.meSettingLotteryData.lotterySetting[
                                  selectedLottoIndex
                                ],
                                `[childLotsetBetRate${pascalCase(item)}]`,
                                0
                              )
                            )
                      }
                    />
                  </div>
                </div>
                <h6 className="primary-red-text m1-t">
                  {!!(errors as any)[`childLotsetBetRate${pascalCase(item)}`] &&
                  (touched as any)[`childLotsetBetRate${pascalCase(item)}`]
                    ? (errors as any)[
                        `childLotsetBetRate${pascalCase(item)}`
                      ] !== "error"
                      ? (errors as any)[`childLotsetBetRate${pascalCase(item)}`]
                      : constants.betRateLimitError(
                          get(
                            this.props.meSettingLotteryData.lotterySetting[
                              selectedLottoIndex
                            ],
                            `[childLotsetBetRate${pascalCase(item)}]`,
                            0
                          )
                        )
                    : ""}
                </h6>
              </div>
              <div style={{ width: "21%" }}>
                <label className="label-input">
                  {constants.discountPercentage}
                </label>
                <InputNumber
                  id={`childLotsetDis${pascalCase(item)}`}
                  name={`childLotsetDis${pascalCase(item)}`}
                  value={(values as any)[`childLotsetDis${pascalCase(item)}`]}
                  thousandSeparator
                  isNumericString
                  allowNegative={false}
                  onBlur={handleBlur}
                  disabled={!this.props.isOtpClicked || !this.state.isEditable}
                  placeholder={constants.discountPercentagePlaceholder}
                  onValueChange={(values) => {
                    setFieldValue(
                      `childLotsetDis${pascalCase(item)}`,
                      values.value
                    );
                    if (this.props.mode === "CREATE") {
                      const clonedSelectedLotto = cloneDeep(
                        this.state.selectedLotto
                      );
                      (clonedSelectedLotto[0] as any)[
                        `childLotsetDis${pascalCase(item)}`
                      ] = Number(values.value);
                      this.setState({ selectedLotto: clonedSelectedLotto });
                    }
                  }}
                  backgroundColor={colors.TRANSPARENT}
                  inputClassName="input-otp"
                  renderError={false}
                  isAllowed={({ floatValue }) =>
                    floatValue ? floatValue <= 100 : true
                  }
                  error={
                    !!(errors as any)[`childLotsetDis${pascalCase(item)}`] &&
                    (touched as any)[`childLotsetDis${pascalCase(item)}`]
                  }
                  mode="InputElements"
                  endText="%"
                />
                <h6 className="primary-red-text m1-t">
                  {!!(errors as any)[`childLotsetDis${pascalCase(item)}`] &&
                  (touched as any)[`childLotsetDis${pascalCase(item)}`]
                    ? (errors as any)[`childLotsetDis${pascalCase(item)}`]
                    : ""}
                </h6>
              </div>
              <div style={{ width: "21%" }}>
                <label className="label-input">{constants.minBet}</label>
                <div className="d-flex flex-row">
                  <div
                    className={`childLotsetBetMin${pascalCase(
                      item
                    )}Wrapper w-100`}
                  >
                    <InputNumber
                      id={`minBet-${index}`}
                      name={`childLotsetBetMin${pascalCase(item)}`}
                      value={
                        (values as any)[`childLotsetBetMin${pascalCase(item)}`]
                      }
                      thousandSeparator
                      allowNegative={false}
                      onBlur={handleBlur}
                      onValueChange={(values) => {
                        setFieldValue(
                          `childLotsetBetMin${pascalCase(item)}`,
                          values.value
                        );
                        const clonedSelectedLotto = cloneDeep(
                          this.state.selectedLotto
                        );
                        (clonedSelectedLotto[0] as any)[
                          `childLotsetBetMin${pascalCase(item)}`
                        ] = Number(values.value);
                        this.setState({ selectedLotto: clonedSelectedLotto });
                      }}
                      backgroundColor={colors.TRANSPARENT}
                      inputClassName="minBet"
                      disabled={
                        !this.props.isOtpClicked || !this.state.isEditable
                      }
                      renderError={false}
                      mode="InputElements"
                      endText={
                        this.props.meData?.permission === "OWNER" &&
                        window.location.pathname === routes.userDetail.path
                          ? constants.baht
                          : constants.minBetPlaceholder(
                              !isEmpty(this.props.lotterySetting)
                                ? (this.props.lotterySetting[
                                    selectedLottoIndex
                                  ] as any)[
                                    `childLotsetBetMax${pascalCase(item)}`
                                  ]
                                : ""
                            )
                      }
                      error={
                        !!(errors as any)[
                          `childLotsetBetMin${pascalCase(item)}`
                        ] &&
                        (touched as any)[`childLotsetBetMin${pascalCase(item)}`]
                      }
                    />
                  </div>
                </div>
                {!!(errors as any)[`childLotsetBetMin${pascalCase(item)}`] &&
                (touched as any)[`childLotsetBetMin${pascalCase(item)}`] ? (
                  <h6 className="primary-red-text m1-t">
                    {(errors as any)[`childLotsetBetMin${pascalCase(item)}`] !==
                    "error"
                      ? (errors as any)[`childLotsetBetMin${pascalCase(item)}`]
                      : `แทงต่ำสุดต้องไม่ต่ำกว่า ${number.addComma(
                          (values as any)[
                            `childLotsetBetMin${pascalCase(item)}API`
                          ]
                        )} บาท และไม่มากกว่า ${number.addComma(
                          (values as any)[
                            `childLotsetBetMax${pascalCase(item)}API`
                          ]
                        )} บาท`}
                  </h6>
                ) : (
                  <h6 className="primary-red-text m1-t">&nbsp;</h6>
                )}
              </div>
              <div style={{ width: "21%" }}>
                <label className="label-input">{constants.maxBet}</label>
                <div className="d-flex flex-row">
                  <div
                    className={`childLotsetBetMax${pascalCase(
                      item
                    )}Wrapper w-100`}
                  >
                    <InputNumber
                      id={`maxBet-${index}`}
                      name={`childLotsetBetMax${pascalCase(item)}`}
                      value={
                        (values as any)[`childLotsetBetMax${pascalCase(item)}`]
                      }
                      allowNegative={false}
                      onBlur={handleBlur}
                      thousandSeparator
                      onValueChange={(values) => {
                        setFieldValue(
                          `childLotsetBetMax${pascalCase(item)}`,
                          values.value
                        );
                        const clonedSelectedLotto = cloneDeep(
                          this.state.selectedLotto
                        );
                        (clonedSelectedLotto[0] as any)[
                          `childLotsetBetMax${pascalCase(item)}`
                        ] = Number(values.value);
                        this.setState({ selectedLotto: clonedSelectedLotto });
                      }}
                      backgroundColor={colors.TRANSPARENT}
                      inputClassName="minBet"
                      disabled={
                        !this.props.isOtpClicked || !this.state.isEditable
                      }
                      renderError={false}
                      mode="InputElements"
                      endText={
                        this.props.meData?.permission === "OWNER" &&
                        window.location.pathname === routes.userDetail.path
                          ? constants.baht
                          : constants.minBetPlaceholder(
                              !isEmpty(this.props.lotterySetting)
                                ? (this.props.lotterySetting[
                                    selectedLottoIndex
                                  ] as any)[
                                    `childLotsetBetMax${pascalCase(item)}`
                                  ]
                                : ""
                            )
                      }
                      error={
                        !!(errors as any)[
                          `childLotsetBetMax${pascalCase(item)}`
                        ] &&
                        (touched as any)[`childLotsetBetMax${pascalCase(item)}`]
                      }
                    />
                  </div>
                </div>
                {!!(errors as any)[`childLotsetBetMax${pascalCase(item)}`] &&
                (touched as any)[`childLotsetBetMax${pascalCase(item)}`] ? (
                  <h6 className="primary-red-text m1-t">
                    {(errors as any)[`childLotsetBetMax${pascalCase(item)}`] !==
                    "error"
                      ? (errors as any)[`childLotsetBetMax${pascalCase(item)}`]
                      : `แทงสูงสุดต้องไม่มากกว่า ${number.addComma(
                          (values as any)[
                            `childLotsetBetMax${pascalCase(item)}API`
                          ]
                        )} บาท`}
                  </h6>
                ) : (
                  <h6 className="primary-red-text m1-t">&nbsp;</h6>
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    });
  };

  render() {
    const RenderLotteryList = this.renderLotteryList();
    const RenderLotteryConfig = this.renderLotteryConfig();

    return (
      <div className="box-shadow new-lottobet-container">
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column">
            <div
              className="inline pr-3 paper-body-title"
              ref={this.userContainerRef}
            >
              {constants.betSetting}
            </div>
            {window.location.pathname === routes.userDetail.path && (
              <div className="content-text m1-t">
                {constants.lastUpdate(
                  format(
                    date.calibratingTime(
                      get(this.props.meData, "updatedAt", ""),
                      true
                    ),
                    "d MMMM yyyy HH:mm",
                    { locale: th }
                  )
                )}
              </div>
            )}
          </div>
          {!this.state.isEditable &&
            get(this.props.meData, "permission", "") === "OWNER" &&
            window.location.pathname === routes.userDetail.path && (
              <div>
                <Button
                  id="edit-lottery"
                  text={constants.editLottery}
                  borderRadius={"8px"}
                  backgroundColor={color.PRIMARY_GREEN}
                  textSize={18}
                  backgroundHoverColor={color.SECONDARY_GREEN}
                  onClick={() => {
                    this.setState({ isEditable: true });
                  }}
                  buttonClass={"edit-lottery-button"}
                />
              </div>
            )}
        </div>

        <div className="row">
          <div className="col-7 mt-4">
            <label className="label-input">{constants.lotteryBetConfig}</label>
            <div style={{ width: "96.5%" }}>
              <Select
                styles={createMemberDropdownStyles(
                  !this.props.isOtpClicked || !this.state.isEditable
                )}
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder={constants.userPlaceholder}
                name="user"
                noOptionsMessage={() => constants.noInfo}
                isDisabled={!this.props.isOtpClicked || !this.state.isEditable}
              />
            </div>
          </div>
          <div className="col-5 mt-4"></div>
          <div className="col-3 mt-4">
            <div className="lottery-list-container">{RenderLotteryList}</div>
          </div>
          <div className="col-9 mt-4">{RenderLotteryConfig}</div>
        </div>
      </div>
    );
  }
}
export default NewLottoBetConfigComponent;
