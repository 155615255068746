import { createAction } from 'typesafe-actions'
import {
    MODE_PRINT_BET,
} from './constants'


const modePrintBetAction = createAction(MODE_PRINT_BET)<boolean>()

export {
    modePrintBetAction
}