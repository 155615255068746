import React, { useState } from "react"
import { FunctionComponent } from "react"
import "../betWaitingResult.style.scss"
import { number } from "utils"
import { Badge, CheckBox, LoadingCircle } from "components"
import color from "constants/colors"
import { isEmpty, isNil, map } from "lodash"
import colors from "constants/colors"
import { LEVEL_TYPE_CHANGE_WORD } from "constants/variables"


const constants = {
    dataNotFound: 'ไม่พบข้อมูล...',
}
interface ITabLottoMemberList {
    lotteryฺBetMemberList: IV3LotteryBetMemberList[]
    userName: string
    loading: boolean
    handleOnClickChild: (childAgentId: number, memberUsername: string, memberPermission: string)=> void
}


const SumTotalBetMemberList: FunctionComponent<ITabLottoMemberList> = ({
    lotteryฺBetMemberList,
    loading,
    userName,
    handleOnClickChild
}) => {
    const [isShowName, setIsShowName] = useState(false)
    const getColor = (totalResult: number) => {
        return isNil(totalResult) ? '' : totalResult > 0 ? colors.DARK_GREEN_TEXT : totalResult < 0 ? colors.PRIMARY_RED : ''
    }

    const dataEmpty = (
        <tr>
            <td className="text-center" colSpan={15}>
                {constants.dataNotFound}
            </td>
        </tr>
    )
    const loadingTable = (
        <tr>
            <td className="text-center" colSpan={15}>
                <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
            </td>
        </tr>
    )


    const TableHead = () => {
        return <thead className='header-table table-bordered'>
            <tr className="text-center">
                <th rowSpan={2} style={{ width: '250px' }}>ชื่อเข้าใช้งาน
                    <span>
                        <div className="d-flex justify-content-center align-items-center">
                            <CheckBox
                                className={'size-box'}
                                id={`show-name`}
                                value={isShowName}
                                onClick={() => {
                                    setIsShowName(!isShowName)
                                }}
                            />
                            แสดงชื่อ
                        </div>
                    </span>
                </th>
                <th rowSpan={2}>ระดับ</th>
                <th rowSpan={2}>ยอดทั้งหมด</th>
                <th colSpan={3}>สมาชิก</th>
                <th colSpan={3}>{userName}</th>
                <th colSpan={3}>บริษัท</th>
            </tr>
            <tr className="text-center">
                <th >ส่งออก</th>
                <th>คอม</th>
                <th>รวม</th>
                <th>ถือหุ้น</th>
                <th>คอม</th>
                <th>รวม</th>
                <th>ถือหุ้น</th>
                <th>คอม</th>
                <th>รวม</th>
            </tr>
        </thead>
    }


    const tableBody = () => {
        const mapData = map(lotteryฺBetMemberList, (item: IV3LotteryBetMemberList, index: number) => {
            
            return (
                <tr className='row-item-hover' key={`${item.memberUsername} ${index}`}>
                    <td className='text-left item-collapsible' onClick={()=>handleOnClickChild(item.memberId, item.memberUsername, item.memberPermission)}>
                        <div className="d-flex align-items-center">
                            {item.memberUsername}
                            {isShowName &&
                                <span className={'d-flex ml-1 body-4 gray-text font-weight-bold'}>
                                    {'('}
                                    <div className="ellipsis-text">{`${item.memberName}`}</div>
                                    {')'}
                                </span>}
                        </div>
                    </td>
                    <td className='text-center'>
                        {
                            <div className="d-flex justify-content-center">
                                <Badge
                                    boxShadow={'0 2px 4px rgba(0, 0, 0, 0.2)'}
                                    backgroundColor={color.PRIMARY_GREEN}
                                    text={LEVEL_TYPE_CHANGE_WORD[item.memberPermission as TLevelType]}
                                    customBadgeContainerClass="host-keep-true-badge-container"
                                    textColor={color.PRIMARY_BG}
                                    customTextClass="host-keep-true-badge-text"
                                />
                            </div>
                        }
                    </td>
                    <td style={{ color: colors.DARK_GREEN_TEXT }}>{number.decimalsFormatComma(item.memberTotalBet)}</td>
                    <td style={{ color: getColor(item.memberSend) }}>{number.decimalsFormatComma(item.memberSend)}</td>
                    <td style={{ color: getColor(item.memberCom) }}>{number.decimalsFormatComma(item.memberCom)}</td>
                    <td style={{ color: getColor(item.memberBalance) }} >{number.decimalsFormatComma(item.memberBalance)}</td>
                    <td style={{ color: getColor(item.meShare) }}>{number.decimalsFormatComma(item.meShare)}</td>
                    <td style={{ color: getColor(item.meCom) }} >{number.decimalsFormatComma(item.meCom)}</td>
                    <td style={{ color: getColor(item.meBalance) }}>{number.decimalsFormatComma(item.meBalance)}</td>
                    <td style={{ color: getColor(item.hostShare) }}>{number.decimalsFormatComma(item.hostShare)}</td>
                    <td style={{ color: getColor(item.hostCom) }}>{number.decimalsFormatComma(item.hostCom)}</td>
                    <td style={{ color: getColor(item.hostBalance) }}>{number.decimalsFormatComma(item.hostBalance)}</td>
                </tr>
            )
        })

        return loading ? loadingTable : !isEmpty(mapData) ? mapData : dataEmpty
    }

    const Tfooter = () => {
            const result: any = lotteryฺBetMemberList.reduce((accumulator, currentItem) => {
                return {
                    memberTotalBet: accumulator.memberTotalBet + currentItem.memberTotalBet,
                    memberSend: accumulator.memberSend + currentItem.memberSend,
                    memberCom: accumulator.memberCom + currentItem.memberCom,
                    memberBalance: accumulator.memberBalance + currentItem.memberBalance,
                    meShare: accumulator.meShare + currentItem.meShare,
                    meCom: accumulator.meCom + currentItem.meCom,
                    meBalance: accumulator.meBalance  + currentItem.meBalance,
                    hostShare: accumulator.hostShare + currentItem.hostShare,
                    hostCom: accumulator.hostCom + currentItem.hostCom,
                    hostBalance: accumulator.hostBalance + currentItem.hostBalance
                };
            }, {
                memberTotalBet: 0,
                memberSend: 0,
                memberCom: 0,
                memberBalance: 0,
                meShare: 0,
                meCom: 0,
                meBalance: 0,
                hostShare: 0,
                hostCom: 0,
                hostBalance: 0
            })
            const totalSum: any = {}

            for (const key in result) {
                totalSum[key] = number.decimalsFormatComma(result[key].toFixed(2))
            }
           const tableFooter =  <tfoot className='table-bordered'>
                <tr className="text-center">
                    <th className="header-table" colSpan={2}>รวม</th>
                    <td style={{ color: colors.DARK_GREEN_TEXT }}>{(totalSum.memberTotalBet)}</td>
                    <td style={{ color: getColor(parseFloat(totalSum.memberSend.replace(/,/g, "")))}}>{totalSum.memberSend}</td>
                    <td style={{ color: getColor(parseFloat(totalSum.memberCom.replace(/,/g, "")))}}>{totalSum.memberCom}</td>
                    <td style={{ color: getColor(parseFloat(totalSum.memberBalance.replace(/,/g, "")))}}>{totalSum.memberBalance}</td>
                    <td style={{ color: getColor(parseFloat(totalSum.meShare.replace(/,/g, "")))}}>{totalSum.meShare}</td>
                    <td style={{ color: getColor(parseFloat(totalSum.meCom.replace(/,/g, "")))}}>{totalSum.meCom}</td>
                    <td style={{ color: getColor(parseFloat(totalSum.meBalance.replace(/,/g, "")))}}>{totalSum.meBalance}</td>
                    <td style={{ color: getColor(parseFloat(totalSum.hostShare.replace(/,/g, "")))}}>{totalSum.hostShare}</td>
                    <td style={{ color: getColor(parseFloat(totalSum.hostCom.replace(/,/g, "")))}}>{totalSum.hostCom}</td>
                    <td style={{ color: getColor(parseFloat(totalSum.hostBalance.replace(/,/g, "")))}}>{totalSum.hostBalance }</td>
                </tr>
            </tfoot>
            return loading ? <></> : tableFooter
    }

    return (
        <>
            <h5 className="mb-2">รวมทั้งหมด</h5>
            <div className="table-responsive-xl">
                <table className="w-100">
                    <TableHead />
                    <tbody className='table-bordered'>{tableBody()}</tbody>
                    {!isEmpty(lotteryฺBetMemberList) && <Tfooter />}
                </table>
            </div>
        </>
    )
}

export default SumTotalBetMemberList
