import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPen, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { every, findIndex, get, includes, isEmpty, isEqual, isUndefined } from "lodash";
import { number, responseConverter } from "utils";
import { addAgentHolesNumbers, updateAgentHolesNumbers } from "reduxs/numbersHoles/services";
import { AxiosError, AxiosResponse } from "axios";
import { ConfirmModal, ErrorModal } from "components";
import { responseMessage } from "constants/response";
import { CHECK_ONLY_NUMBER, COMMA } from "constants/regex";

interface Props {
  isShowAccordion: boolean;
  onPressAddNumberSpecialHandler: () => void;
  setGroupNumberSpecialList: (data: INumbersHolesGroupData[]) => void;
  groupNumberSpecialList: INumbersHolesGroupData[];
  typeLotto: INumbersHolesType;
  fetchGetHolesNumberAgent: (
    didmount: boolean,
    dataItem?: INumbersHolesGroupData,
    index?: number,
    fetchDataLotto?: boolean
  ) => void;
  handleCancel: (action: string) => void;
  fetchNumbersHolesLotto: () => void;
  onChangeIsLoaddingAction: (data: boolean) => void;
  loading: boolean
}

const constants = {
  number: "เลข",
  numholes: "เลขในหลุม",
  holeSize: "ขนาดหลุม",
  addSpecialNumber: "เพิ่มเลขพิเศษ",
  SpecialNumber: "เลขพิเศษ",
  ErrorModalIsExist: "เลขพิเศษก่อนหน้า มีอยู่แล้ว",
  deleteConfirmHeader: "ยืนยันการลบเลขพิเศษ",
  deleteConfirmDesc: "คุณยืนยันการลบข้อมูล?",
  deleteconfirmHeaderSpecialNumber: "ยกเลิกการเพิ่มเลขพิเศษ",
  deleteconfirmDescSpecialNumber: "คุณยืนยันการยกเลิกเลขพิเศษใหม่นี้?",
};
const AccordionItemComponent = (props: Props) => {
  const {
    isShowAccordion,
    onPressAddNumberSpecialHandler,
    groupNumberSpecialList,
    setGroupNumberSpecialList,
    typeLotto,
    fetchGetHolesNumberAgent,
    handleCancel,
    fetchNumbersHolesLotto,
    onChangeIsLoaddingAction,
    // loading
  } = props;

  const mappingTypeNumbersHoles: IHeaderNumbersHolesTable[] = [
    { id: 1, name: "3 บน", type: "THREE_UP", length: 3, typeUP: true },
    { id: 2, name: "3 โต๊ด", type: "THREE_TOAST", length: 3, typeUP: false },
    { id: 3, name: "2 บน", type: "TWO_UP", length: 2, typeUP: true },
    { id: 4, name: "2 ล่าง", type: "TWO_DOWN", length: 2, typeUP: false },
    { id: 5, name: "วิ่งบน", type: "RUN_UP", length: 1, typeUP: true },
    { id: 6, name: "วิ่งล่าง", type: "RUN_DOWN", length: 1, typeUP: false },
  ];

  const AddNumberSpecialIcon = (): JSX.Element => {
    return (
      <div className="add-number-special-item " onClick={onPressAddNumberSpecialHandler}>
        <div className="icon-item add">
          <FontAwesomeIcon icon={faPlus} />
        </div>
        <span className="ml-2">{constants.addSpecialNumber}</span>
      </div>
    );
  };
  //API agent 92 เพิ่มเลขพิเศษในหลุม
  const fetchPostAddAgentHolesNumbers = (
    dataItem: INumbersHolesGroupData,
    index?: number,
    indexNewNumberSpecial: number = -1
  ) => {
    onChangeIsLoaddingAction(true);
    let ArrayIndex = indexNewNumberSpecial >= 0 ? [indexNewNumberSpecial === 0 ? 0 : 1] : [0, 1];
    const queries = ArrayIndex.map((i) => {
      let query: IV2PostAddHolesNumberAgent = {
        numholfacId: get(dataItem, `tempData[${i}].numholfacId`, 0),
        numholBetType: get(dataItem, `tempData[${i}].numholBetType`, "THREE_UP"),
        numholBetLimit: Number(get(dataItem, `tempData[${i}].numholBetLimit`, 0)),
        numholNumber:
          (indexNewNumberSpecial >= 0
            ? get(dataItem, `tempData[${i}].numholNumber`, 0)!
            : get(dataItem, "tempNumber", 0)) as any
      };
      return query;
    });
    const processPromise = async () => {
      let responseArray: AxiosResponse<IAPIResponse<IV2RespUpdateHolesNumberAgent>>[] = [];
      for (let query of queries) {
        const res: AxiosResponse<
          IAPIResponse<IV2RespUpdateHolesNumberAgent>
        > = await addAgentHolesNumbers(query);
        responseArray.push(res);
      }
      fetchGetHolesNumberAgent(false, dataItem, index, true);
      removeDataNewNumberSpecial(dataItem, index!);
      responseArray.forEach((item: AxiosError<IAPIResponse> | AxiosResponse<IAPIResponse>) => {
        if (every(responseArray, ({ data: { code } }) => code !== 200000)) {
          ErrorModal.show({
            action: ErrorModal.hide,
            description: get(
              responseMessage(),
              responseConverter.getMessage(item).code,
              responseMessage()[0]
            ),
          });
        }
      });
    };
    processPromise();
  };
  //API agent  อัพเดทเลขพิเศษในหลุม
  const fetchUpdateAgentHolesNumbers = (
    dataItem: INumbersHolesGroupData,
    isStrict?: boolean,
    indexChange: number = -1
  ) => {
    onChangeIsLoaddingAction(true);
    const tempDataIndex = dataItem.tempData.findIndex((i) => includes(i.numholBetType, "UP"));
    //toggle indexChange
    let indexNewNumberSpecial = findIndex(dataItem.tempData, (i) => isUndefined(i.numholId));
    if (tempDataIndex === 1 && isEmpty(dataItem.tempData[indexNewNumberSpecial])) {
      if (indexChange === 0) {
        indexChange = 1;
      } else if (indexChange === 1) {
        indexChange = 0;
      }
    }

    let ArrayObject = indexChange >= 0 ? [indexChange === 0 ? 0 : 1] : [0, 1];
    const queries = ArrayObject.map((i) => {
      const query: IV2PostUpdateHolesNumberAgent = {
        numholId: get(dataItem, `tempData[${i}].numholId`, null) as any,
        numholBetLimit: Number(get(dataItem, `tempData[${i}].numholBetLimit`, 0)),
        numholIsStrict: isStrict ? true : false,
      };
      return query;
    });

    const processPromise = async () => {
      let responseArray: AxiosResponse<IAPIResponse<IV2RespUpdateHolesNumberAgent>>[] = [];
      for (let query of queries) {
        const res: AxiosResponse<
          IAPIResponse<IV2RespUpdateHolesNumberAgent>
        > = await updateAgentHolesNumbers(query);
        responseArray.push(res);
      }
      fetchGetHolesNumberAgent(false);
      handleCancel("UPDATE_NUMBERSPECIAL");
      fetchNumbersHolesLotto();
      responseArray.forEach((item: AxiosError<IAPIResponse> | AxiosResponse<IAPIResponse>) => {
        if (every(responseArray, ({ data: { code } }) => code !== 200000)) {
          ErrorModal.show({
            action: ErrorModal.hide,
            description: get(
              responseMessage(),
              responseConverter.getMessage(item).code,
              responseMessage()[0]
            ),
          });
        }
      });
    };
    processPromise();
  };

  const setOnEdit = (key: boolean, index: number) => {
    let items = [...groupNumberSpecialList];
    items[index].isEdit = key;
    setGroupNumberSpecialList(items);
  };

  const findTypeWithIndex = (lengthType: number, indexUPDOWN: number) => {
    switch (lengthType) {
      case 3:
        return indexUPDOWN === 0 ? "THREE_UP" : "THREE_TOAST";
      case 2:
        return indexUPDOWN === 0 ? "TWO_UP" : "TWO_DOWN";
      case 1:
        return indexUPDOWN === 0 ? "RUN_UP" : "RUN_DOWN";
      default:
        return "INVALID";
    }
  };
  const onPressEditedHandler = (dataItem: INumbersHolesGroupData, index: number) => {
    const dataIndex = dataItem.numbers.findIndex((i) => includes(i.numholBetType, "UP"));
    const tempDataIndex = dataItem.tempData.findIndex((i) => includes(i.numholBetType, "UP"));
    let indexDataUp = dataIndex === 0 ? 0 : 1;
    let indexDataDown = dataIndex === 0 ? 1 : 0;
    let indexTempDataUp = tempDataIndex === 0 ? 0 : 1;
    let indexTempDataDown = tempDataIndex === 0 ? 1 : 0;

    let checkIndexUp = isEqual(
      get(dataItem, `numbers[${indexDataUp}].numholBetLimit`, 0),
      get(dataItem, `tempData[${indexTempDataUp}].numholBetLimit`, 0)
    );
    let checkIndexDown = isEqual(
      get(dataItem, `numbers[${indexDataDown}].numholBetLimit`, 0),
      get(dataItem, `tempData[${indexTempDataDown}].numholBetLimit`, 0)
    );

    let indexNewNumberSpecial = findIndex(dataItem.tempData, (i) => isUndefined(i.numholId));
    let indexChange = -1;
    if (indexNewNumberSpecial !== -1) {
      indexChange = indexNewNumberSpecial === 0 ? 1 : 0;
      fetchPostAddAgentHolesNumbers(dataItem, index, indexNewNumberSpecial);
      let checkIndexUpdate = isEqual(
        get(dataItem, `numbers[${indexChange}].numholBetLimit`, 0),
        get(dataItem, `tempData[${indexChange}].numholBetLimit`, 0)
      );
      if (!checkIndexUpdate) {
        fetchUpdateAgentHolesNumbers(dataItem, true, indexChange);
      }
    } else if (checkIndexUp && checkIndexDown) {
    } else if (checkIndexUp || checkIndexDown) {
      indexChange = !checkIndexUp ? 0 : 1;
      fetchUpdateAgentHolesNumbers(dataItem, true, indexChange);
    } else {
      fetchUpdateAgentHolesNumbers(dataItem, true);
    }
    setOnEdit(false, index);
  };
  const onPressAddHandlerArray = (dataItem: INumbersHolesGroupData, index: number) => {
    if (dataItem.tempNumber.length > 0) {
      let arr: object = {};
      if (dataItem.tempNumber.length === 3) {
        arr = { THREE_UP: typeLotto.THREE_UP, THREE_TOAST: typeLotto.THREE_TOAST };
      } else if (dataItem.tempNumber.length === 2) {
        arr = { TWO_UP: typeLotto.TWO_UP, TWO_DOWN: typeLotto.TWO_DOWN };
      } else if (dataItem.tempNumber.length === 1) {
        arr = { RUN_UP: typeLotto.RUN_UP, RUN_DOWN: typeLotto.RUN_DOWN };
      }

      const ArrayObjectNumber: IV2PostAddHolesNumberAgent[] = Object.keys(arr).map((i, index) => {
        const checkBetLimitWithNumberLength = get(arr, i, 0);
        let numberObject: IV2PostAddHolesNumberAgent = {
          numholfacId: dataItem.tempData[index].numholfacId,
          numholBetType: i as TLottoGameType,
          numholBetLimit: checkBetLimitWithNumberLength,
          numholNumber: dataItem.tempNumber,
        };
        return numberObject;
      });

      const result = findIndex(groupNumberSpecialList, (i) => {
        return i.tempData[0].numholNumber === ArrayObjectNumber[0].numholNumber;
      });

      if (result === -1) {
        let items = [...groupNumberSpecialList];
        items[index].tempData = ArrayObjectNumber;
        items[index].numbers = ArrayObjectNumber;
        setOnEdit(true, index);
        setGroupNumberSpecialList(items);
      } else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: constants.ErrorModalIsExist,
        });
      }
    }
  };

  const handleChangeInput = (value: string, index: number, typeLotto: string) => {
    value = value.replace(COMMA, "");
    if (Number(value) > 999999999) {
      value = "1000000000";
    }
    //input only number
    const isnum = CHECK_ONLY_NUMBER.test(value);
    if (value.length !== 0 && !isnum) {
      return;
    }
    let items: INumbersHolesGroupData[] = [...groupNumberSpecialList];
    let tempArrayData = [...items[index].tempData];
    const editingIndex = tempArrayData.findIndex((i) => includes(i.numholBetType, typeLotto));
    tempArrayData[editingIndex] = {
      ...items[index].tempData[editingIndex],
      numholBetLimit: Number(value),
    };

    let item: INumbersHolesGroupData = {
      ...items[index],
      tempData: tempArrayData,
    };
    items[index].tempData = item.tempData;

    setGroupNumberSpecialList(items);
  };
  const handleChangeInputNumber = (value: string, index: number) => {
    const isnum = CHECK_ONLY_NUMBER.test(value);
    if (value.length !== 0 && !isnum) {
      return;
    }
    let items = [...groupNumberSpecialList];
    items[index].tempNumber = value;
    setGroupNumberSpecialList(items);
  };

  const handleOnEdit = (dataItem: INumbersHolesGroupData, index: number) => {
    let items = [...groupNumberSpecialList];
    items[index].tempData = items[index].numbers;
    setGroupNumberSpecialList(items);
    if (dataItem.tempData.length < 2) {
      const dataIndex = includes(dataItem.numbers[0].numholBetType, "UP");
      let indexNewNumber = dataIndex ? 1 : 0;
      const findTypeLength = includes(dataItem.numbers[0].numholBetType, "THREE")
        ? 3
        : includes(dataItem.numbers[0].numholBetType, "TWO")
        ? 2
        : 1;

      let typeIsMiss = findTypeWithIndex(findTypeLength, indexNewNumber)!;
      let object: IV2GetHolesNumberAgent = {
        numholfacId: dataItem.tempData[0].numholfacId,
        numholBetType: typeIsMiss as TLottoGameType,
        numholBetLimit: typeLotto[typeIsMiss! as keyof INumbersHolesType],
        numholNumber: dataItem.tempData[0].numholNumber,
      };
      let items = [...groupNumberSpecialList];
      items[index].tempData = items[index].tempData.concat(object);
      items[index].numbers = items[index].numbers.concat(object);
      setGroupNumberSpecialList(items);
    }
    setOnEdit(true, index);
  };
  const removeDatafromArray = (index: number) => {
    let cloneData = [...groupNumberSpecialList];
    cloneData.splice(index, 1);
    setGroupNumberSpecialList(cloneData);
  };

  const removeDataNewNumberSpecial = (dataItem: INumbersHolesGroupData, index: number) => {
    let indexNewNumberSpecial = findIndex(dataItem.tempData, (i) => isUndefined(i.numholId));
    let items = [...groupNumberSpecialList];
    if (indexNewNumberSpecial !== -1) {
      items[index].tempData = items[index].tempData.splice(indexNewNumberSpecial - 1, 1);
      items[index].numbers = items[index].numbers.splice(indexNewNumberSpecial - 1, 1);
      setGroupNumberSpecialList(items);
      setOnEdit(false, index);
    }
  };
  const RenderTableHead = (): JSX.Element => {
    return (
      <>
        <tr>
          <th rowSpan={2} className="text-left th-number" style={{ width: "8%" }}>
            <h5 className={`text-header secondary-text`}>{constants.number}</h5>
          </th>
          {mappingTypeNumbersHoles.map((item, index) => {
            return (
              <th colSpan={2} className="text-center" key={index} style={{ width: "15%" }}>
                <h5 className={`text-header secondary-text`}>{item.name}</h5>
              </th>
            );
          })}
          <th rowSpan={2} className="text-right " style={{ width: "6%" }}></th>
        </tr>
        <tr>
          {mappingTypeNumbersHoles.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <th className="text-center">{constants.numholes}</th>
                <th className="text-center">{constants.holeSize}</th>
              </React.Fragment>
            );
          })}
        </tr>
      </>
    );
  };
  const renderHolesList = (dataItem: INumbersHolesGroupData, index: number) => {
    return (
      <>
        {mappingTypeNumbersHoles.map((el, i) => {
          const tempDataIndex = dataItem.tempData.findIndex((i) =>
            includes(i.numholBetType, el.type)
          );
          const dataIndex = dataItem.numbers.findIndex((i) => includes(i.numholBetType, el.type));
          const checkNumholNumberLength =
            get(dataItem, "numbers[0].numholNumber.length", 0) === el.length;
          const numholBetTotal = isEmpty(get(dataItem, `numbers[${dataIndex}]`))
            ? "-"
            : get(dataItem, `numbers[${dataIndex}].numholBetTotal`, 0).toLocaleString();
          const numholBetLimit = isEmpty(get(dataItem, `numbers[${dataIndex}]`))
            ? "-"
            : get(dataItem, `numbers[${dataIndex}].numholBetLimit`, 0).toLocaleString();
          const valuesInput = number.InputTextWithCommas(
            get(dataItem, `tempData[${tempDataIndex}].numholBetLimit`, 0).toString()
          );

          return (
            <React.Fragment key={el.id}>
              {/* เลขในหลุม */}
              {checkNumholNumberLength ? (
                <td
                  className={`number-inhole ${
                    get(dataItem, `numbers[${dataIndex}].numholIsMax`, false)
                      ? "maxhole-number-in-holes"
                      : ""
                  }`}
                >
                  <span>{numholBetTotal}</span>
                </td>
              ) : (
                <td className={`number-inhole `}>
                  <span>-</span>
                </td>
              )}

              {/* ขนาดหลุม */}
              {checkNumholNumberLength ? (
                <>
                  {dataItem.isEdit ? (
                    <td
                      className={`number-inhole-input ${
                        get(dataItem, `numbers[${dataIndex}].numholIsMax`, false) ? "maxhole" : ""
                      } `}
                    >
                      <input
                        type="text"
                        value={valuesInput}
                        onChange={(e) => handleChangeInput(e.target.value, index, el.type)}
                      />
                    </td>
                  ) : (
                    <td
                      className={`number-inhole ${
                        get(dataItem, `numbers[${dataIndex}].numholIsMax`, false) ? "maxhole" : ""
                      }`}
                    >
                      <span style={{ fontWeight: 600 }}>{numholBetLimit}</span>
                    </td>
                  )}
                </>
              ) : (
                <td className={`number-inhole`}>
                  <span>-</span>
                </td>
              )}
            </React.Fragment>
          );
        })}
      </>
    );
  };
  const renderIcon = (dataItem: INumbersHolesGroupData, index: number) => {
    const handleDataIsNewOrEdit = () => {
      if (dataItem.isNew && !dataItem.isEdit) {
        onPressAddHandlerArray(dataItem, index);
      } else if (dataItem.isNew && dataItem.isEdit) {
        fetchPostAddAgentHolesNumbers(dataItem, index);
      } else {
        onPressEditedHandler(dataItem, index);
      }
    };
    const handleRemoveIcon = () => {
      if (dataItem.isNew) {
        ConfirmModal.show({
          action: () => {
            removeDatafromArray(index);
            ConfirmModal.hide();
          },
          cancelAction: () => {
            ConfirmModal.hide();
          },
          description: constants.deleteconfirmDescSpecialNumber,
          title: constants.deleteconfirmHeaderSpecialNumber,
        });
      } else if (dataItem.isEdit) {
        let indexNewNumberSpecial = findIndex(dataItem.tempData, (i) => isUndefined(i.numholId));
        if (!isEmpty(dataItem.tempData[indexNewNumberSpecial])) {
          removeDataNewNumberSpecial(dataItem, index);
        } else {
          setOnEdit(false, index);
          let items = [...groupNumberSpecialList];
          items[index].tempData = items[index].numbers;
          setGroupNumberSpecialList(items);
        }
      } else {
        let items = [...groupNumberSpecialList];
        items[index].tempData = items[index].numbers;
        setGroupNumberSpecialList(items);
        ConfirmModal.show({
          action: () => {
            if (dataItem.tempData.length < 2) {
              fetchUpdateAgentHolesNumbers(dataItem, false, 0);
            } else {
              fetchUpdateAgentHolesNumbers(dataItem, false);
            }
            ConfirmModal.hide();
          },
          cancelAction: () => {
            ConfirmModal.hide();
          },
          description: constants.deleteConfirmDesc,
          title: constants.deleteConfirmHeader,
        });
      }
    };
    return (
      <>
        <td className="text-right">
          <div
            className="d-flex align-items-center justify-content-end"
            style={{ paddingRight: 13.33 }}
          >
            {dataItem.isEdit || dataItem.isNew ? (
              <div
                className={`icon-item check ${
                  dataItem.tempNumber.length > 0 || !dataItem.isNew ? "" : "disable"
                }`}
                onClick={handleDataIsNewOrEdit}
              >
                <FontAwesomeIcon icon={faCheck} />
              </div>
            ) : (
              <div className="icon-item edit" onClick={() => handleOnEdit(dataItem, index)}>
                <FontAwesomeIcon icon={faPen} />
              </div>
            )}
            <div className="icon-item remove ml-2" onClick={handleRemoveIcon}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
        </td>
      </>
    );
  };
  const RenderTableBody = (): JSX.Element => {
    return (
      <>
        {!isEmpty(groupNumberSpecialList) &&
          groupNumberSpecialList.map((dataItem: INumbersHolesGroupData, index: number) => {
            return (
              <React.Fragment key={index}>
                <tr className={`row-accordion-item`} style={{ height: 50 }}>
                  {dataItem.isNew ? (
                    <td className={`text-left td-input`} id="number">
                      <input
                        type="text"
                        className="text-left"
                        maxLength={3}
                        value={dataItem.tempNumber}
                        onChange={(e) => handleChangeInputNumber(e.target.value, index)}
                        disabled={dataItem.isEdit}
                      />
                    </td>
                  ) : (
                    <>
                      <td className={`text-left th-number`} id="number">
                        <span>{get(dataItem, "numbers[0].numholNumber", 0)}</span>
                      </td>
                    </>
                  )}
                  {renderHolesList(dataItem, index)}
                  {renderIcon(dataItem, index)}
                </tr>
              </React.Fragment>
            );
          })}
      </>
    );
  };

  return (
    <>
      {isShowAccordion && (
        <tr className="row-accordion">
          <td colSpan={8}>
            <div className="row text-center">
              <div className="col-12">
                <div className="table-container">
                  <div className="title-header d-flex my-3">
                    <div className="title ml-3">{constants.SpecialNumber}</div>
                    <AddNumberSpecialIcon />
                  </div>
                  {!isEmpty(groupNumberSpecialList) && (
                    <div className="table-responseive-xl">
                      <table className="w-100">
                        <thead className="border-bottom" style={{ height: 50 }}>
                          <RenderTableHead />
                        </thead>
                        <tbody>{RenderTableBody()}</tbody>
                      </table>
                      <div
                        className="d-flex justify-content-end border-bottom"
                        style={{ height: 50 }}
                      >
                        <AddNumberSpecialIcon />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default AccordionItemComponent;
