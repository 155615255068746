import { AxiosError, AxiosResponse } from "axios";
import { createAsyncAction } from "typesafe-actions";
import { GET_HOST_FAILURE, GET_HOST_REQUEST, GET_HOST_SUCCESS } from "./constants";

const getHostAction = createAsyncAction(
    GET_HOST_REQUEST,
    GET_HOST_SUCCESS,
    GET_HOST_FAILURE
)<any, AxiosResponse<IAPIResponse<IV2GetAgentHost>>, AxiosError<IAPIResponse>>()

export { getHostAction }