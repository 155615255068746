import { get } from 'lodash'
import {
    hostState
} from './constants'

const hostList = (state: RootReducers): ReducerState<IV2GetAgentHost> =>
    get(state, "host.host", hostState)


const hostSelector = {
    hostList
}
export default hostSelector