import * as Yup from "yup";
import { NO_WHITESPACE, SPECIAL_ENG_AND_SPECIAL_CHAR } from "constants/regex";

const constants = {
  englishRequire: "กรอกเฉพาะภาษาอังกฤษ ตัวเลข หรือตัวอักษรพิเศษ !@#$%&*",
}


const scheme = Yup.object().shape({
  password: Yup.string()
    .required("กรุณากรอกรหัสผ่าน")
    .matches(SPECIAL_ENG_AND_SPECIAL_CHAR, constants.englishRequire)
    .test("", "รหัสผ่านหรือยืนยันรหัสผ่านต้องไม่มีช่องว่าง", (val: any) => {
      return val?.match(NO_WHITESPACE)
    })
    .min(6, "กรุณาใส่รหัสผ่านอย่างน้อย 6 ตัวอักษร")
    .max(128,"ยืนยันรหัสผ่านต้องมีความยาวน้อยกว่า 128 ตัว"),
    confirmpassword: Yup.string()
    .required("")
    .matches(SPECIAL_ENG_AND_SPECIAL_CHAR, constants.englishRequire)
    .test("", "ยืนยันรหัสผ่านต้องไม่มีช่องว่าง", (val: any) => {
      return val?.match(NO_WHITESPACE)
    })
    .min(6, "กรุณาใส่รหัสผ่านอย่างน้อย 6 ตัวอักษร")
    .max(128, "ยืนยันรหัสผ่านต้องมีความยาวน้อยกว่า 128 ตัว")
    .when("password", {
      is: (val: string) => (val && val.length > 5 ? true : false),
      then: Yup.string().oneOf([Yup.ref("password")], "รหัสผ่านไม่ตรงกัน"),
    }),
});


export default scheme;
