import { AxiosError, AxiosResponse } from 'axios'
import { createAsyncAction } from 'typesafe-actions'
import {
    CUSTOMER_REQUEST,
    CUSTOMER_SUCCESS ,
    CUSTOMER_FAILURE ,

    CUSTOMER_NOTE_REQUEST,
    CUSTOMER_NOTE_SUCCESS ,
    CUSTOMER_NOTE_FAILURE ,

    POST_CUSTOMER_NOTE_REQUEST,
    POST_CUSTOMER_NOTE_SUCCESS,
    POST_CUSTOMER_NOTE_FAILURE,

} from "./constants"

const customerAction = createAsyncAction(
    CUSTOMER_REQUEST,
    CUSTOMER_SUCCESS,
    CUSTOMER_FAILURE,
)<any, AxiosResponse<IAPIResponse<IPagination<IV2RespAgentCustomer>>>, AxiosError<IAPIResponse>>()

const customerNoteAction = createAsyncAction(
    CUSTOMER_NOTE_REQUEST,
    CUSTOMER_NOTE_SUCCESS,
    CUSTOMER_NOTE_FAILURE,
)<any, AxiosResponse<IAPIResponse<IV2RespAgentCustomerNote>>, AxiosError<IAPIResponse>>()

const postcustomerNoteAction = createAsyncAction(
    POST_CUSTOMER_NOTE_REQUEST,
    POST_CUSTOMER_NOTE_SUCCESS,
    POST_CUSTOMER_NOTE_FAILURE,
)<any, AxiosResponse<IAPIResponse<IV2RespPostAgentCustomerNote>>, AxiosError<IAPIResponse>>()


export {
    customerAction,
    customerNoteAction,
    postcustomerNoteAction
}