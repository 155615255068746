import { Breadcrumb, ErrorModal, LoadingCircle, LottoCard } from 'components';
// import colors from 'constants/colors';
import { responseCode, responseMessage } from 'constants/response';
import { CODE_TO_NAME, LOTTO_FLAG_ALPHA } from 'constants/variables';
import { get, includes, isEmpty, map, noop, orderBy } from 'lodash';
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router';
import { date } from 'utils';
import LottoFlags from "assets/images/global/flags";
import Arrows from "assets/images/global/icon/arrows.png";
import './lottoResultDetail.style.scss'

type DefaultProps = Readonly<typeof defaultProps>;
const constants = {
   mainPageTitle: "ภาพรวม",
   betTitle: "การเดิมพัน",
   resultTitle: "ผลหวย",
   tabelLotto: "ตารางหวย",
   lottoShare: "หวยหุ้น",
   lottoYegee: "หวยยี่กี",
   lottoGeneral: "หวยทั่วไป",
};


const defaultProps: ILottoResultContainerProps & ILottoResultContainerAction = {
   onChangeIsLoaddingAction() { noop() },
   getV2DataResult() { noop() },

   getV2ResultData: {
      page: 1,
      limit: 10,
      total: 0,
      totalRecord: 0,
      dataList: [],
   },
   getV2ResultIsFetching: false,
   getV2ResultCode: 0,
   getV2ResultError: "",
};

export default class LottoResultContainer extends Component<
   ILottoResultContainerProps & ILottoResultContainerAction & DefaultProps & RouteComponentProps<{ type: TLottoType }>
> {
   static defaultProps = defaultProps;
   state: ILottoResultContainerState = {
      genLotto: [],
      shareLotto: [],
      yegeeLotto: [],

      limit: 12
   };

   componentDidMount() {
      this.props.getV2DataResult({
         page: 1, limit: this.state.limit,
         name: this.props.match.params.type
      })
   }

   componentDidUpdate(prevProps: ILottoResultContainerProps) {
      if (prevProps.getV2ResultIsFetching !== this.props.getV2ResultIsFetching && !this.props.getV2ResultIsFetching) {

         if (this.props.getV2ResultCode !== responseCode.OK && this.props.getV2ResultError !== "") {
            ErrorModal.show({
               action: ErrorModal.hide,
               description: get(responseMessage(), this.props.getV2ResultCode, responseMessage()[0]),
            });
         }

         const lottoShare = this.props.getV2ResultData.dataList.filter(
            (lotto) => lotto.name.includes("SHARE") && !lotto.name.includes("LAO")
         );
         const lottoYegee = this.props.getV2ResultData.dataList.filter((lotto) =>
            lotto.name.includes("YEGEE")
         );
         const lottoGeneral = this.props.getV2ResultData.dataList.filter(
            (lotto) => (!lotto.name.includes("YEGEE") && !lotto.name.includes("SHARE")) || lotto.name.includes("LAO")
         );
         this.setState({
            genLotto: lottoGeneral,
            shareLotto: lottoShare,
            yegeeLotto: lottoYegee
         });
      }
   }

   renderLottoList = (lottoList: IV2RespAgentLotteryResult[]) => {
      return map(lottoList, (lotto, index) => {
         const description =
            isEmpty(lotto.createdAt) ? "-"
               : date.formatThai(lotto.createdAt, 'dd MMM yy HH:mm')

         const FlagIcon = get(LottoFlags[LOTTO_FLAG_ALPHA[includes(lotto.name, "YEGEE") ? "YEGEE" : lotto.name]], "Icon", '');

         const textNameLotto = includes(lotto.name, "YEGEE")
            ? `ยี่กี รอบที่ ${Number(lotto.name.replace("YEGEE_", ''))}`
            : CODE_TO_NAME[lotto.name]

         return (
            <div
               className="col-auto lotto-box"
               key={`lotto-${lotto.name}-${index}`}
            >
               <LottoCard
                  slug={("LOTTER_" + lotto.name) as TLottoSlug}
                  id={`lotto-${lotto.name}`}
                  onClick={() => { }}
                  title={textNameLotto}
                  subtitle='ออกผลเมื่อ: '
                  description={description}
                  isOpen={true}
                  status={'OPEN'}
                  icon={FlagIcon}
                  lottoResult={orderBy(lotto.lottoResult, [(item) => includes(item.type, "THREE") || includes(item.type, "FOUR") ? 1 : 2], ['asc'])}
               />
            </div>
         );
      });
   };

   render() {
      let lottoName = this.props.match.params.type
      const navigates: IBreadcrumbItem[] = [
         { label: constants.mainPageTitle, active: false, path: "/dashboard" },
         // { label: constants.betTitle, active: false, path: "/bet" },
         { label: constants.resultTitle, active: false, path: "/lotto-result" },
         { label: includes(lottoName, "YEGEE") ? `ยี่กี รอบที่ ${Number(lottoName.replace("YEGEE_", ''))}` : CODE_TO_NAME[lottoName].replace("", ''), active: true },
      ];
      return (
         <div className="paper-container">
            <div className="row">
               <div className="col-12 col-md-6">
                  <div className="sub-menu" style={{ width: "fit-content" }}>
                     <Breadcrumb
                        items={navigates}
                        handleOnClickItem={(path: string) => this.props.history.replace(path)}
                     />
                  </div>
               </div>
            </div>
            <div className="paper-title">{includes(lottoName, "YEGEE") ? `ยี่กี รอบที่ ${Number(lottoName.replace("YEGEE_", ''))}` : CODE_TO_NAME[lottoName]}</div>
            <div className="paper-description">{constants.tabelLotto}</div>
            <div className="paper-body">
               <div className="box-shadow">
                  <div className='lotto-result-detail-container max-width-box'>
                     {!isEmpty(this.state.genLotto) && <div className="row mr-xl-4">
                        <div className="col-12">
                           <div className="d-flex align-items-center">
                              <h4>{constants.lottoGeneral}</h4>
                              {this.props.getV2ResultIsFetching && (<div className="m1-l"><LoadingCircle /> </div>)}
                           </div>
                        </div>
                        {this.renderLottoList(this.state.genLotto)}
                     </div>
                     }
                     {!isEmpty(this.state.shareLotto) && <div className="row mr-xl-4">
                        <div className="col-12">
                           <div className="d-flex align-items-center">
                              <h4>{constants.lottoShare}</h4>
                              {this.props.getV2ResultIsFetching && (<div className="m1-l"><LoadingCircle /> </div>)}
                           </div>
                        </div>
                        {this.renderLottoList(this.state.shareLotto)}
                     </div>
                     }
                     {!isEmpty(this.state.yegeeLotto) && <div className="row mr-xl-4">
                        <div className="col-12">
                           <div className="d-flex align-items-center">
                              <h4>{constants.lottoYegee}</h4>
                              {this.props.getV2ResultIsFetching && (<div className="m1-l"><LoadingCircle /> </div>)}
                           </div>
                        </div>
                        {this.renderLottoList(this.state.yegeeLotto)}
                     </div>
                     }
                     {(this.props.getV2ResultData.total > 1) &&
                        <div className='see-more-box d-flex justify-content-center'>
                          {this.props.getV2ResultIsFetching 
                          ? <div className="m1-l"><LoadingCircle /> </div> 
                          : <h6 className='see-more-text' onClick={() => {
                              this.setState({ limit: this.state.limit + 12 }, () => {
                                 this.props.getV2DataResult({
                                    page: 1, limit: this.state.limit,
                                    name: this.props.match.params.type
                                 })
                              })
                           }}>ดูข้อมูลเพิ่ม <img src={Arrows} alt="Arrows" /></h6>} 
                        </div>
                     }
                  </div>
               </div>
            </div>
         </div>
      )
   }
}
