import "./membersList.style.scss";
import colors from "constants/colors";
import React, { Component } from "react";
import { get, isEmpty, isNull, noop, pickBy } from "lodash";
import { RouteComponentProps } from "react-router-dom";
import { number, handleOnClickBreadcrumb, dateThai } from "utils";
import {
  LabelBox,
  Button,
  Input,
  Breadcrumb,
  SkeletonLoader,
  ErrorModal,
  Panginate,
  Dropdown,
  LoadingCircle,
} from "components";
import { AGENT_STATUS } from "constants/variables";
import { responseCode, responseMessage } from "constants/response";
import routes from "constants/routes";
import { meDataInitialValue } from "constants/variables";
import {  transformer } from 'utils';


const defaultProps: IMembersProps & IMembersActionProps = {
  getMe(){
    noop()
  },
  getChildMembers() {
    noop();
  },
  getFinanceSummary() {
    noop();
  },
  meData: meDataInitialValue,
  agentChildCode: 0,
  agentChildError: "",
  agentChildIsFetching: false,
  agentChildData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  getFinanceData: {
    myCreditTotal: 0,
    myCreditLimit: 0,
    myChildTotal: 0,
    myChildCreditTotal: 0,
    myProfitTotal: 0,
    myHostProfitTotal: 0,
    myLossTotal: 0,
    myHostLossTotal: 0,
    myBetTotal: 0,
    myCurrentBetTotal: 0,
    myChildBetTotal: 0,
    myChildCurrentBetTotal: 0,
    myChildComTotal: 0,
    myChildCurrentComTotal: 0,
    myChildBetCountTotal: 0,
    myChildCurrentBetCountTotal: 0,
  },
  financeDataIsFetching: false,
  mainData: {
    username: "",
    permission: "MEMBER",
    line: "",
    status: "",
    childCount: 0,
    currency: "THB",
    betResultTotal: 0,
    betWaitTotal: 0,
    maxBet: 0,
    minBet: 0,
    childCreditTotal: 0,
    credit: 0,
    creditLimit: "",
  },
};

const constants = {
  open:"เปิดใช้งาน",
  credits:'เครดิตมอบให้',
  titlePage: "ข้อมูลสมาชิก",
  descriptionPage: "รายการสมาชิก",
  addMember: "เพิ่มสมาชิก",
  times: "ครั้ง",
  humans: "คน",
  baht: "บาท",
  childCount: "จำนวนลูกสมาชิก",
  totalRemaining: "เครดิตคงเหลือทั้งหมด",
  betTotal: "ยอดเดิมพันทั้งหมด",
  totalCommission: "ยอดคอมมิชชั่นทั้งหมด",
  frequencyBet: "จำนวนครั้งเดิมพันทั้งหมด",
  status: "สถานะ",
  available: "เปิดใช้งาน",
  availableActive: "เปิดให้ใช้",
  availableInActive: "ปิดใช้",
  search: "ค้นหา",
  sort: "เรียงโดย",
  inputSearch: "ค้นหาสมาชิก",
  rowPerPage: "จำนวนรายการต่อหน้า :",
  dataEmpty: "ไม่มีข้อมูล...",
  btnEdit: "แก้ไข",
  headTable: [
    { codeName: "Username", textalign: "text-left paddingtableHead", style:{width:`10%`}},
    { codeName: "ชื่อช่วยจำ", textalign: "text-left" ,style:{width:`10%`} },
    { codeName: "เบอร์ติดต่อ", textalign: "text-left" ,style:{width:`10%`}},
    { codeName: "เครดิตมอบให้", textalign: "text-right" ,style:{width:`9%`}},
    { codeName: "ยอดรวมเดิมพันทั้งหมด", textalign: "text-right",style:{width:`15%`} },
    { codeName: "จำนวนสมาชิก", textalign: "text-left paddingtableCol" },
    { codeName: "เปิดให้ใช้งาน", textalign: "text-left" },
    { codeName: "สถานะ", textalign: "text-left" },
    { codeName: "อัพเดทล่าสุด", textalign: "text-left" },
  ],
  breadcrumb: {
    main: "ภาพรวม",
    membersList: "รายการสมาชิก",
  },
};

class MembersListContainer extends Component<
  IMembersProps &
    IMembersActionProps &
    RouteComponentProps<any, any, { memberData: IV2GetAgentChild }> & {
      history: History;
    },
  IMembersContainerState
> {
  static defaultProps = defaultProps;
  state: IMembersContainerState = {
    dataTest: [],
    currentPage: 0,
    currentRowPerPage: 10,
    childAgentStatusDropdownPlaceholder: "",
    childIsAccessDropdownPlaceholder: "",
    childSort: "",
    dataQuery: {
      childAgentId:0,
      childAgentStatus: "",
      childIsAccess: "",
      limit: 10,
      page: 1,
      search: "",
      sortChildCreditLimit: "",
      sortChildBetTotal: "",
      sortChildIsAccess: "",
      sortChildAgentStatus: "",
      sortChildUpdatedAt: "DESC",
      sortChildName: "",
    },
    startPage: 1,
    endPage: 0,
    queryTimeout: setTimeout(() => {
      noop();
    }, 0),
    reqMemberDetail: {
      id: -99,
    },
    isSearch: false,
  };

  componentDidMount() {
    if(this.props.location.search){ 
      const queryParam = transformer.queryToObject()
      this.setState({
        dataQuery: {
          ...this.state.dataQuery,
          childAgentId: Number(queryParam.child_agent_id)
        },
      }, () => {
        this.props.getChildMembers( pickBy(this.state.dataQuery))
      })
}else{
  this.props.getChildMembers( pickBy(this.state.dataQuery))
}
    
    if(this.props.meData.permission === "AGENT"){
      this.props.history.push(routes.Dashboard.path)
    }
    this.props.getMe()
    this.props.getFinanceSummary();
    
  }
  componentDidUpdate(prevProps: IMembersProps) {
    if (
      prevProps.agentChildIsFetching !== this.props.agentChildIsFetching &&
      !this.props.agentChildIsFetching
    ) {
      if (
        this.props.agentChildCode !== responseCode.OK &&
        this.props.agentChildError !== ""
      ) {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            this.props.agentChildCode,
            responseMessage()[0]
          ),
        });
      }
      if (this.props.agentChildCode === responseCode.OK) {
        if (isEmpty(this.props.agentChildData.dataList)) {
          this.setState({ startPage: 0, endPage: 0 });
        } else {
          this.setState({
            startPage:
              this.state.currentRowPerPage * (this.state.dataQuery.page - 1) +
              1,
            endPage:
              this.state.currentRowPerPage * (this.state.currentPage + 1) >=
              this.props.agentChildData.totalRecord
                ? this.props.agentChildData.totalRecord
                : this.state.currentRowPerPage * this.state.dataQuery.page,
          });
        }
      }
    }
  }

  handleQueryDropdownChange = (dropdown: string, queryValue: string) => {
    this.setState(
      {
        currentPage: 0,
        childAgentStatusDropdownPlaceholder: dropdown,
        dataQuery: {
          ...this.state.dataQuery,
          childAgentId:0,
          childAgentStatus: queryValue as TAgentStatus,
          page: 1,
        },
      },
      () => {
        this.props.getChildMembers(pickBy(this.state.dataQuery));
      }
    );
  };

  handleQuery2DropdownChange = (
    dropdown: string,
    queryValue: boolean | string
  ) => {
    this.setState(
      {
        currentPage: 0,
        childIsAccessDropdownPlaceholder: dropdown,
        dataQuery: {
          ...this.state.dataQuery,
          childIsAccess: queryValue,
          page: 1,
        },
      },
      () => {
        this.props.getChildMembers(pickBy(this.state.dataQuery));
      }
    );
  };

  handleSortDropdownChange = (
    dropdown: string,
    sort: string,
    orderBy: String
  ) => {
    const resetDataSort = {
      sortChildCreditLimit: "",
      sortChildAgentStatus: "",
      sortChildBetTotal: "",
      sortChildIsAccess: "",
      sortChildName: "",
      sortChildUpdatedAt: "",
    };
    this.setState(
      {
        currentPage: 0,
        childSort: `${dropdown}`,
        dataQuery: {
          ...this.state.dataQuery,
          ...resetDataSort,
          [sort]: `${orderBy}`,
          page: 1,
        },
      },
      () => {
        this.props.getChildMembers(pickBy(this.state.dataQuery));
      }
    );
  };

  handleClickChangePage = ({ selected: selectedPage }: ISelectPage) => {
    this.setState({ currentPage: selectedPage }, () => {
      this.setState(
        {
          dataQuery: {
            ...this.state.dataQuery,
            page: this.state.currentPage + 1,
          },
        },
        () => {
          this.props.getChildMembers(pickBy(this.state.dataQuery));
        }
      );
    });
  };

  handleOnChangeSearch = (value: string) => {
    this.setState(
      {
        dataQuery: {
          ...this.state.dataQuery,
          search: value,
        },
        isSearch: true,
      },
      () => {
        clearTimeout(this.state.queryTimeout);
        this.setState({
          queryTimeout: setTimeout(() => {
            this.setState({
              isSearch: false,
            });
            this.props.getChildMembers(pickBy(this.state.dataQuery));
          }, 1000),
        });
      }
    );
  };
  onClickRowMembersList = (index: number, data: IV2GetAgentChild) => {
    this.props.history.push(routes.memberDetail.path, data);
  };
  tableMembersBody = () => {
    if (this.state.isSearch) {
      return (
        <tr className={`row-members-list color-row`}>
          <td className="text-center color-row p-2" colSpan={15}>
            <SkeletonLoader customStyle={[[{ col: 12, show: true }]]} />
          </td>
        </tr>
      );
    } else {
      const loadingTable= (
        <tr>
          <td className="text-center" colSpan={15}>
          <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
          </td>
        </tr>
      )
      
      const dataEmpty = (
        <tr>
          <td className="text-center" colSpan={15}>
            {constants.dataEmpty}
          </td>
        </tr>
      )
      
      const mapData = this.props.agentChildData.dataList.map((data, index: number) => {

        return (
          <tr
            className={`row-members-list `}
            key={index}
            onClick={() => this.onClickRowMembersList(index, data)}
          >
            <td className="text-left paddingRow">
              <span className="paddingtableHead">{data.childUsername}</span>
            </td>
            <td className="text-left ">
              <span>{data.childName}</span>
            </td>
            <td className="text-left">
              <span>{isNull(data.childPhoneNumber)? '-':number.formatPhoneNumberSpace(data.childPhoneNumber)}</span>
            </td>
            <td className="text-right">
              <span>{number.addComma(data.childCreditLimit)} บาท </span>
            </td>
            <td className="text-right">
              <span>{number.addComma(data.childBetTotal)} บาท</span>
            </td>
            <td className="text-left">
              <span className="paddingtableCol">{data.childChildTotal} คน</span>
            </td>
            <td className="text-left">
              {data.childIsAccess === true ? (
                <div className="statusActive">{constants.availableActive}</div>
              ) : (
                <div className="statusInActive">
                  {constants.availableInActive} 
                </div>
              )}
            </td>
            <td className="text-left">
              <span>{AGENT_STATUS[data.childAgentStatus]}</span>
            </td>
            <td className="text-left">
              <span>{dateThai.formatThai(data.childUpdatedAt)}</span>
            </td>
          </tr>
        )
      })
      return this.props.agentChildIsFetching ? loadingTable : !isEmpty(mapData) ? mapData : dataEmpty;
    }
  };

  renderSummary = () => (
    <div className="paper-body">
      <div className="box-shadow">
        <div className="row">
          <div className="col-3">
            <LabelBox
              customTextLabel={"label-text-label"}
              customTextValue={"label-text-value"}
              colorTextValue={`${this.props.financeDataIsFetching ? colors.TERTIARY_TEXT : colors.PRIMARY_TEXT}`}
              textLabel={constants.childCount}
              textValue={`${number.addComma(
                this.props.getFinanceData.myChildTotal
              )}`}
            />
          </div>
          <div className="col-3">
            {" "}
            <LabelBox
              customTextLabel={"label-text-label"}
              customTextValue={"label-text-value"}
              colorTextValue={`${this.props.financeDataIsFetching ? colors.TERTIARY_TEXT : colors.PRIMARY_TEXT}`}
              textLabel={constants.totalRemaining}
              textValue={`${number.addComma(
                this.props.getFinanceData.myChildCreditTotal
              )} ${constants.baht}`}
            />
          </div>
          <div className="col-3">
            {" "}
            <LabelBox
              customTextLabel={"label-text-label"}
              customTextValue={"label-text-value"}
              colorTextValue={`${this.props.financeDataIsFetching ? colors.TERTIARY_TEXT : colors.PRIMARY_TEXT}`}
              textLabel={constants.betTotal}
              textValue={`${number.addComma(
                this.props.getFinanceData.myChildBetTotal
              )} ${constants.baht}`}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3 m3-t">
            {" "}
            <LabelBox
              customTextLabel={"label-text-label"}
              customTextValue={"label-text-value"}
              colorTextValue={`${this.props.financeDataIsFetching ? colors.TERTIARY_TEXT : colors.PRIMARY_TEXT}`}
              textLabel={constants.totalCommission}
              textValue={`${number.addComma(
                this.props.getFinanceData.myChildComTotal
              )} ${constants.baht}`}
            />
          </div>
          <div className="col-3 m3-t">
            {" "}
            <LabelBox
              customTextLabel={"label-text-label"}
              customTextValue={"label-text-value"}
              colorTextValue={`${this.props.financeDataIsFetching ? colors.TERTIARY_TEXT : colors.PRIMARY_TEXT}`}
              textLabel={constants.frequencyBet}
              textValue={`${number.addComma(
                this.props.getFinanceData.myChildBetCountTotal
              )} ${constants.times}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
  renderFilter = () => (
    <div className="filterBox">
      <div className="row ">
        <div className="col-2 firstCol">
          <div className="head-text-filter-box">{constants.status}</div>
          <div className="dropdownBorder" style={{ marginTop: "4px" }}>
            <Dropdown
              menuStyles={{ width: '100%' }}
              options={[
                {
                  value: "",
                  label: "ทั้งหมด",
                  disabled: false,
                },
                { value: "NORMAL", label: "ปกติ", disabled: false },
                {
                  value: "PRE_REGISTERED",
                  label: "พึ่งสมัครสมาชิก",
                  disabled: false,
                },
                {
                  value: "LOGIN_OTP",
                  label: "เข้าสู่ระบบด้วย OTP",
                  disabled: false,
                },

                {
                  value: "PASSWORD_EXPIRED",
                  label: "รหัสหมดอายุ",
                  disabled: false,
                },
                {
                  value: "WAIT_AUTHEN",
                  label: "รอการยืนยันตัวตน",
                  disabled: false,
                },
                {
                  value: "WAIT_SECRET",
                  label: "รอการเปิดใช้งาน 2FA",
                  disabled: false,
                },
                { value: "VERIFY", label: "ตรวจสอบ 2FA", disabled: false },
              ]}
              handleOnChange={(target: IOptions) => {
                this.handleQueryDropdownChange(
                  String(target.label),
                  String(target.value)
                );
              }}
              controlBackgroundColor={colors.PRIMARY_BG}
              placeholder={
                this.state.childAgentStatusDropdownPlaceholder === ""
              ? <span className="placeholderDropdownA7A7">ทั้งหมด</span> :
        <span className="placeholderDropdown">
          {get(AGENT_STATUS,`[${this.state.dataQuery.childAgentStatus}]`,'ทั้งหมด')}</span>
              }
            />
          </div>
        </div>
        <div className="col-2 ">
          <div className="head-text-filter-box">{constants.available}</div>
          <div className="dropdownBorder" style={{ marginTop: "4px" }}>
            <Dropdown
              menuStyles={{ width: '100%' }}
              options={[
                {
                  value: "",
                  label: "ทั้งหมด",
                  disabled: false,
                },
                { value: true, label: "เปิดใช้งาน", disabled: false },
                { value: false, label: "ห้ามไม่ให้ใช้งาน", disabled: false },
              ]}
              handleOnChange={(target: IOptions) => {
                this.handleQuery2DropdownChange(
                  String(target.label),
                  String(target.value)
                );
              }}
              controlBackgroundColor={colors.PRIMARY_BG}
              placeholder={
                this.state.childIsAccessDropdownPlaceholder === ""
                   ? <span className="placeholderDropdownA7A7">{constants.open}</span>
                  : <span className="placeholderDropdown">{this.state.childIsAccessDropdownPlaceholder}</span>
              }
            />
          </div>
        </div>
        <div className="col">
          <div className="search-box">
            <div className="head-text-filter-box">{constants.search}</div>
            <div className="inputBox ">
              <Input
                backgroundColor={colors.PRIMARY_BG}
                borderRadius="4px"
                inputClassName="inputtt"
                setPadding="6px"
                placeholder="ชื่อช่วยจำ, เบอร์ติดต่อ, ชื่อผู้ใช้"
                size={"large"}
                value={this.state.dataQuery.search}
                id={""}
                name={""}
                onChange={(e) => {
                  this.handleOnChangeSearch(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-2 lastCol">
          <div className="head-text-filter-box">{constants.sort}</div>
          <div className="dropdownBorder2">
            <Dropdown
              menuStyles={{ width: '100%' }}
              options={[
                {
                  value: "DESC",
                  label: "อัพเดทล่าสุด",
                  searchableName: "sortChildUpdatedAt",
                  disabled: false,
                },
                {
                  value: "ASC",
                  label: "สถานะ",
                  searchableName: "sortChildAgentStatus",
                  disabled: false,
                },

                {
                  value: "ASC",
                  label: "ชื่อช่วยจำ",
                  searchableName: "sortChildName",
                  disabled: false,
                },
                {
                  value: "DESC",
                  label: "เปิดใช้งาน",
                  searchableName: "sortChildIsAccess",
                  disabled: false,
                },

                {
                  value: "DESC",
                  label: "จำกัดเครดิตสูงสุด",
                  searchableName: "sortChildCreditLimit",
                  disabled: false,
                },
                {
                  value: "ASC",
                  label: "จำกัดเครดิตต่ำสุด",
                  searchableName: "sortChildCreditLimit",
                  disabled: false,
                },
                {
                  value: "DESC",
                  label: "ยอดเดิมพันสูงสุด",
                  searchableName: "sortChildBetTotal",
                  disabled: false,
                },
                {
                  value: "ASC",
                  label: "ยอดเดิมพันต่ำสุด",
                  searchableName: "sortChildBetTotal",
                  disabled: false,
                },
              ]}
              controlBackgroundColor={colors.PRIMARY_BG}
              handleOnChange={(target: IOptions) => {
                this.handleSortDropdownChange(
                  String(target.label),   
                  String(target.searchableName),
                  String(target.value)
                );
              }}
              placeholder={
                this.state.childSort === ""
                  ? <span className="placeholderDropdownA7A7">{constants.credits}</span>
                  : <span className="placeholderDropdown">{this.state.childSort}</span>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
  renderTableMembers = () => (
    <div className="table-container">
      <div className="table-responsive-xl">
        <table className="col ">
          <thead className="">
            <tr className="tableHead">
              {constants.headTable.map((data, index: number) => (
                <th key={index} className={data.textalign} style={data.style}>
                  <span>{data.codeName}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{this.tableMembersBody()}</tbody>
        </table>
      </div>
    </div>
  );

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.breadcrumb.main, path: "/", active: false },
      { label: constants.breadcrumb.membersList, active: true },
    ];

    const RenderFilter = this.renderFilter();
    const RenderSummary = this.renderSummary();
    const RenderTableMembers = this.renderTableMembers();
    return (
      <>
        <div className="paper-container member-container">
          <div className="row">
            <div className="col">
              <div className="sub-menu">
                <Breadcrumb
                  items={navigates}
                  handleOnClickItem={handleOnClickBreadcrumb}
                />
              </div>
            </div>
          </div>
          <div className="paper-title titleText">{constants.titlePage}</div>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="paper-description">{constants.descriptionPage}</div>
            <div className="d-flex flex-row align-items-center">
              <Button
                id={"add-member"}
                size={"moreMedium"}
                textSize={18}
                text={constants.addMember}
                onClick={() =>{
                  localStorage.removeItem('lotterySettingState')
                  localStorage.removeItem('betMaxMinLimit')
                  this.props.history.push(routes.createMember.path)
                }}
              />
            </div>
          </div>

          <div className="paper-body ">
            {RenderSummary}
            <div className="box-shadow m3-t boxShadow-Border">
              <div className="row">
                <div className="col">{RenderFilter}</div>
              </div>
              <div className="row ">
                <div className="col">{RenderTableMembers}</div>
              </div>

              <div className="row">
                <div className="col d-flex justify-content-end align-items-center ">
                  <h5 className="rowperpageFont">{constants.rowPerPage}</h5>
                  <div className="dropdown-rowPerPage ">
                    <Dropdown
                      menuPlacement={"top"}
                      options={[
                        { value: 5, label: "5", disabled: false },
                        { value: 10, label: "10", disabled: false },
                        { value: 20, label: "20", disabled: false },
                        { value: 50, label: "50", disabled: false },
                        { value: 100, label: "100", disabled: false },
                      ]}
                      controlBackgroundColor={colors.PRIMARY_BG}
                      placeholder={String(this.state.currentRowPerPage)}
                      handleOnChange={(target: IOptions) => {
                        this.setState(
                          {
                            currentPage: 0,
                            currentRowPerPage: Number(target.value),
                            dataQuery: {
                              ...this.state.dataQuery,
                              page: 1,
                              limit: Number(target.value),
                            },
                          },
                          () => {
                            this.props.getChildMembers(
                              pickBy(this.state.dataQuery)
                            );
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <h5 className="d-flex justify-content-end align-items-center rowperpageFont">
                  {`${this.state.startPage} - ${this.state.endPage} 
                       of ${this.props.agentChildData.totalRecord}`}
                </h5>
                <div className="col-auto">
                  <Panginate
                    pageTotal={this.props.agentChildData.total}
                    initialPage={this.state.dataQuery.page - 1}
                    pageRangeDisplayed={this.state.currentPage}
                    onPageChange={this.handleClickChangePage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MembersListContainer;
