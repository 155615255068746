import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { getChildBetAction } from "../actionCreator";
import { fetchGetChildBet } from '../services'

const getChildBet = (query: IQueryPagination & IV2QueryAgentChildBet) => (dispatch: Dispatch) => {
    dispatch(getChildBetAction.request({}))
    return fetchGetChildBet(query)
        .then((res: AxiosResponse<IPagination<IV2RespAgentChildBet>>) => {
            dispatch(getChildBetAction.success(res))
        })
        .catch((error: AxiosError<IAPIResponse>) => {
            dispatch(getChildBetAction.failure(error))
        })
}

export { getChildBet }