import React, { Component } from "react";
import colors from "constants/colors";
import { get, isEmpty, noop, pickBy } from "lodash";
import {
  SkeletonLoader,
  Panginate,
  Dropdown,
  ErrorModal,
  LoadingCircle,

} from "components";
import { th } from "date-fns/locale";
import { format } from "date-fns";
import { date } from "utils";
import { responseCode, responseMessage } from "constants/response";
import "./HeadMemberTable.style.scss"

const constants = {
  rowPerPage: "จำนวนรายการต่อหน้า :",
  dataEmpty: "ไม่มีข้อมูล...",
  headTable: [
    { codeName: "วันที่", textalign: "text-left paddingtableHead", style: { width: `17%` } },
    { codeName: "ยูส", textalign: "text-left", style: { width: `25%` } },
    { codeName: "ข้อความ", textalign: "text-left", },
  ],
};

const defaultProps = {
  locationData: 0,
  onload() { },
  getHostMeData() {
    noop()
  },
  hostMeData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  hostMeCode: 0,
  hostMeError: "",
  hostMeIsFetching: false,


};

class HeadMemberTable extends Component<IHeadMemberTableProps> {
  static defaultProps = defaultProps;
  state = {
    currentPage: 0,
    currentRowPerPage: 10,
    childSort: "",
    dataQuery: {
      limit: 10,
      page: 1,
      hostAgentId: 0,
    },
    startPage: 1,
    endPage: 0,

    queryTimeout: setTimeout(() => {
      noop();
    }, 0),
    reqMemberDetail: {
      id: -99,
    },
    isSearch: false,
  };
  componentDidMount() {
    this.setState({ dataQuery: { ...this.state.dataQuery, hostAgentId: this.props.locationData } },
      () => {
        this.props.getHostMeData({
          hostAgentId: this.state.dataQuery.hostAgentId,
        });
      })

  }
  componentDidUpdate(prevProps: IHeadMemberTableProps) {
    if (this.props.hostMeIsFetching) {
      this.props.onload(true);
    } else {
      this.props.onload(false);
    }
    if (
      prevProps.hostMeIsFetching !== this.props.hostMeIsFetching &&
      !this.props.hostMeIsFetching
    ) {
      if (
        this.props.hostMeCode !== responseCode.OK &&
        this.props.hostMeError !== ""
      ) {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            this.props.hostMeCode,
            responseMessage()[0]
          ),
        });
      }


      if (this.props.hostMeCode === responseCode.OK) {
        if (isEmpty(this.props.hostMeData.dataList)) {
          this.setState({ startPage: 0, endPage: 0 });
        } else {
          this.setState({
            startPage:
              this.state.currentRowPerPage * (this.state.dataQuery.page - 1) +
              1,
            endPage:
              this.state.currentRowPerPage * (this.state.currentPage + 1) >=
                this.props.hostMeData.totalRecord
                ? this.props.hostMeData.totalRecord
                : this.state.currentRowPerPage * this.state.dataQuery.page,
          });
        }
      }
    }
  }

  handleClickChangePage = ({ selected: selectedPage }: ISelectPage) => {
    this.setState({ currentPage: selectedPage }, () => {
      this.setState(
        {
          dataQuery: {
            ...this.state.dataQuery,
            page: this.state.currentPage + 1,
          },
        },
        () => {
          this.props.getHostMeData(pickBy(this.state.dataQuery));
        }
      );
    });
  };

  tableMembersBody = () => {


    if (this.state.isSearch) {
      return (
        <tr className={`row-members-list color-row`}>
          <td className="text-center color-row p-2" colSpan={15}>
            <SkeletonLoader customStyle={[[{ col: 12, show: true }]]} />
          </td>
        </tr>
      );
    } else {
      const loadingTable= (
        <tr>
          <td className="text-center" colSpan={15}>
          <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
          </td>
        </tr>
      )
      
      const dataEmpty = (
        <tr>
          <td className="text-center" colSpan={15}>
            {constants.dataEmpty}
          </td>
        </tr>
      )
      
      const mapData = this.props.hostMeData.dataList.map((data, index: number) => {
        return (
          <tr className={`row-members-list `} key={index}>
            <td className="text-left paddingRow">
              <span className="paddingtableHead">
                {" "}
                {`${format(
                  date.calibratingTime(data.createdAt, true),
                  "d MMM yyyy HH:mm",
                  { locale: th }
                )}`}
              </span>
            </td>
            <td className="text-left ">
              <span> {get(data, 'hostUsername', '-')}</span>
            </td>
            <td className="text-left">
              <span>
                {get(data, 'note', '-')}
              </span>
            </td>
          </tr>
        )
      })
      return this.props.hostMeIsFetching ? loadingTable : !isEmpty(mapData) ? mapData : dataEmpty;
    }
  }

  renderTableMembers = () => (
    <div className="table-container">
      <div className="table-responsive-xl">
        <table className="col ">
          <thead className="">
            <tr className="tableHead">
              {constants.headTable.map((data, index: number) => (
                <th key={index} className={data.textalign} style={data.style} >
                  <span>{data.codeName}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{this.tableMembersBody()}</tbody>
        </table>
      </div>
    </div>
  );

  render() {
    const RenderTableMembers = this.renderTableMembers();
    return (
      <div className="headMember-container">

        <div className="row ">
          <div className="col m3-t">{RenderTableMembers}</div>
        </div>
        <div className="row">
          <div className="col d-flex justify-content-end align-items-center ">
            <h5 className="rowperpageFont">{constants.rowPerPage}</h5>
            <div className="dropdown-rowPerPage ">
              <Dropdown
                menuPlacement={"top"}
                options={[
                  { value: 5, label: "5", disabled: false },
                  { value: 10, label: "10", disabled: false },
                  { value: 20, label: "20", disabled: false },
                  { value: 50, label: "50", disabled: false },
                  { value: 100, label: "100", disabled: false },
                ]}
                controlBackgroundColor={colors.PRIMARY_BG}
                placeholder={String(this.state.currentRowPerPage)}
                handleOnChange={(target: IOptions) => {
                  this.setState(
                    {
                      currentPage: 0,
                      currentRowPerPage: Number(target.value),
                      dataQuery: {
                        ...this.state.dataQuery,
                        page: 1,
                        limit: Number(target.value),
                      },
                    },
                    () => {
                      this.props.getHostMeData(pickBy(this.state.dataQuery));
                    }
                  );
                }}
              />
            </div>
          </div>
          <h5 className="d-flex justify-content-end align-items-center rowperpageFont">
            {`${this.state.startPage} - ${this.state.endPage}  of ${this.props.hostMeData.totalRecord}`}
          </h5>
          <div className="col-auto">
            <Panginate
              pageTotal={this.props.hostMeData.total}
              initialPage={this.state.dataQuery.page - 1}
              pageRangeDisplayed={this.state.currentPage}
              onPageChange={this.handleClickChangePage}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default HeadMemberTable;
