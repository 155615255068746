import { AxiosError, AxiosResponse } from "axios";
import { createAsyncAction } from "typesafe-actions";
import {
  GET_ME_FAILURE,
  GET_ME_REQUEST,
  GET_ME_SETTING_FAILURE,
  GET_ME_SETTING_LOTTERY_FAILURE,
  GET_ME_SETTING_LOTTERY_REQUEST,
  GET_ME_SETTING_LOTTERY_SUCCESS,
  GET_ME_SETTING_REQUEST,
  GET_ME_SETTING_SUCCESS,
  GET_ME_SUCCESS,
} from "./constants";

const getMeSettingAction = createAsyncAction(
  GET_ME_SETTING_REQUEST,
  GET_ME_SETTING_SUCCESS,
  GET_ME_SETTING_FAILURE
)<any, AxiosResponse<IV2GetAgentMeSetting>, AxiosError<IAPIResponse>>();

const getMeAction = createAsyncAction(
  GET_ME_REQUEST,
  GET_ME_SUCCESS,
  GET_ME_FAILURE,
)<any, AxiosResponse<IV2GetAgentMe>, AxiosError<IAPIResponse>>()

const getMeSettingLotteryAction = createAsyncAction(
  GET_ME_SETTING_LOTTERY_REQUEST,
  GET_ME_SETTING_LOTTERY_SUCCESS,
  GET_ME_SETTING_LOTTERY_FAILURE,
)<any, AxiosResponse<IV2AgentMeSettingLottery>, AxiosError<IAPIResponse>>()

export { getMeSettingAction, getMeAction, getMeSettingLotteryAction };
