import "./NumbersHolesManage.style.scss"
// import colors from "constants/colors"
import React, { Component } from "react"
import { find, findIndex, get, isNull } from "lodash"
import { RouteComponentProps } from "react-router-dom"
import { date, handleOnClickBreadcrumb, number, transformer } from "utils"
import { Breadcrumb, ErrorModal, ResponsiveIcon } from "components"
import collectBank from "assets/images/global/bank"
import LottoFlags from "assets/images/global/flags"
import { LOTTO_FLAG_ALPHA } from "constants/variables"
import routes from "constants/routes"
import { NumbersHolesManageList } from "./components/NumbersHolesManageList"
import { addAgentHolesNumbers, fetchAgentHoles, fetchAgentHolesNumber, updateAgentHolesNumbers } from "reduxs/numbersHoles/services"
import { responseCode, responseMessage } from "constants/response"
import { AxiosError } from "axios"
import obj from "utils/number"

const defaultProps: INumberHolesManageContainerProps &
  INumberHolesManageContainerActionProps = {
  onChangeIsLoaddingAction() {},
}

const constants = {
  placeholderSearch: "ชื่อผู้ใช้",
  titlePage: "รายละเอียดหลุม",
  descriptionPage: "ตั้งค่ารายละเอียดหลุม",
  edit: "แก้ไข",
  dataEmpty: "ไม่มีข้อมูล...",
  clickToChangeLotto: "คลิกเพื่อเปลี่ยนหวย",
  up: "บน",
  toast: "โต้ด",
  down: "ล่าง",
  three: "สามตัว",
  two: "สองตัว",
  run: "วิ่ง",
  runTypes: ['RUN_UP', 'RUN_DOWN'] as TLottoGameType[],
  twoTypes: ['TWO_UP', 'TWO_DOWN'] as TLottoGameType[],
  threeTypes: ['THREE_UP', 'THREE_TOAST'] as TLottoGameType[],
  breadcrumb: {
    main: "ภาพรวม",
    numbersHoles: "หลุมเลข",
    numbersHolesManage: "รายละเอียดหลุม",
  },
}

class NumbersHolesManage extends Component<
  INumberHolesManageContainerProps &
    INumberHolesManageContainerActionProps &
    RouteComponentProps
> {
  static defaultProps = defaultProps
  state: INumberHolesManageContainerState = {
    numbersHolesDataList: [],
    lottoHoleDetail: null,
    loadingNumHolesList: false
  }

  componentDidMount() {
    const { state } = this.props.location;
    if (!get(state, 'numholfacId') && !get(state, 'agentLotsetId')){
      this.handleGoBack()
    } else {
      this.getNumbersHolesAll()
    }
  }

  handleGoBack = () => {
    this.props.history.replace(routes.numbersHoles.path)
  }

  renderLottoDetail() {
    const targetLotto: TLottoType = get(this.props.location.state, 'name', 'YEGEE')
    const lottoName = transformer.nameLotto(targetLotto, true, true)
    let flagIcon: string
    if (targetLotto.includes('YEGEE')) {
      flagIcon = LottoFlags['THA'].Icon
    } else {
      flagIcon = LottoFlags[LOTTO_FLAG_ALPHA[targetLotto]]?.Icon
    }
    const isFlagBank =
      Object.keys(collectBank).findIndex((item) => item === targetLotto) === -1
        ? ''
        : 'flag-bank';
    return (
      <div className="detail-lotto">
        <div
          className="d-flex align-items-center detail-lotto-wrapper"
          onClick={() => this.handleGoBack()}
        >
          <ResponsiveIcon
            icon={flagIcon}
            alt="flag"
            className={`lotto-flag ${isFlagBank}`}
          />
          <div className="lotto-desc">
            <div>{lottoName}</div>
            <div>{constants.clickToChangeLotto}</div>
          </div>
        </div>
      </div>
    )
  }

  getNumbersHolesSpecialDataList = async () => {
    const agentLotsetId = get(this.props.location.state, 'agentLotsetId', null)
    const numholfacId = get(this.props.location.state, 'numholfacId', null)
    const query: IV2QueryGetHolesNumberAgent = {
      ...(agentLotsetId && { agentLotsetId }),
      ...(numholfacId && { numholfacId }),
    }
    let numbersHolesSpecialDataList: IV2GetHolesNumberAgent[] = []
    this.setState({loadingNumHolesList: true})
    await fetchAgentHolesNumber(query)
      .then((res) => {
        numbersHolesSpecialDataList = res.data.data
      })
      .catch((err: AxiosError<IAPIResponse>) => {
        if (get(err, 'response.data.code', 0) !== responseCode.OK) {
          ErrorModal.show({
            action: ErrorModal.hide,
            description: get(
              responseMessage(),
              get(err, 'response.data.code', 0),
              responseMessage()[0]
            ),
          })
        }
      })
      this.setState({loadingNumHolesList: false})
    return numbersHolesSpecialDataList
  }

  getHolesAgent = async () => {
    await fetchAgentHoles().then((res) => {
      const lottoHoleDetail = find(res.data.data, {
        agentLotsetId: this.props.location.state.agentLotsetId,
      })
      const numholfacId = get(lottoHoleDetail, 'numholfacId', null);
      if (!numholfacId) {
        this.handleGoBack()
      }
      const lottoLimit: INumbersHolesType = {
        THREE_UP: get(lottoHoleDetail, 'numholfacLimitThreeUp', 0),
        THREE_TOAST: get(lottoHoleDetail, 'numholfacLimitThreeToast', 0),
        TWO_UP: get(lottoHoleDetail, 'numholfacLimitTwoUp', 0),
        TWO_DOWN: get(lottoHoleDetail, 'numholfacLimitTwoDown', 0),
        RUN_UP: get(lottoHoleDetail, 'numholfacLimitRunUp', 0),
        RUN_DOWN: get(lottoHoleDetail, 'numholfacLimitRunDown', 0),
      }

      this.setState({
        lottoHoleDetail: {
          lottoLimit,
          numholfacId,
        },
      })
    })
  }

  createNumbersHolesList = (type: TLottoGameType, length: number) => {
    const { lottoHoleDetail } = this.state
    const { agentLotsetId, name, numholfacId, lottoLimit } = this.props.location.state
    
    const numLimit = get(lottoLimit, type) || get(lottoHoleDetail?.lottoLimit, type)
    const newNumholfacId = numholfacId || lottoHoleDetail?.numholfacId
    const nowDate = date.formatDateTimeForApi(new Date())
    const digit = this.getNumberDigit(type)
    const defaultNumberList = Array.from({ length }, (_, i) => ({
      agentLotsetId: agentLotsetId,
      agentLotsetName: name,
      numholfacId: newNumholfacId,
      numholId: null,
      numholBetType: type,
      numholBetLimit: numLimit,
      numholBetTotal: 0,
      numholNumber: this.formatPadNumber(i, digit),
      numholIsStrict: true,
      numholIsMax: false,
      numholCreatedAt: nowDate,
      numholUpdatedAt: nowDate,
    }))
    
    return defaultNumberList
  }

  formatPadNumber = (input: number | string, digit: number) => {
    const formattedNum = number.padNumber(String(input), digit)
    return formattedNum
  }

  getNumbersHolesAll = async () => {
    let numbersHolesAll: IV2GetHolesNumberAgent[] = [...this.state.numbersHolesDataList]
  
    const { state } = this.props.location
    const { lottoHoleDetail } = this.state
    if (
      !get(state, 'lottoLimit', lottoHoleDetail?.lottoLimit) ||
      !get(state, 'numholfacId', lottoHoleDetail?.numholfacId)
    ) {
      await this.getHolesAgent();
    }
    const numbersHolesSpecial = await this.getNumbersHolesSpecialDataList()
    //จะสร้างข้อมูลเปล่าๆใหม่เฉพาะตอนที่พึ่งเข้าครั้งแรก
    if (numbersHolesAll.length === 0){
      const lottoGameTypes: {type: TLottoGameType, length: number}[] = [
        { type: 'RUN_UP', length: 10 },
        { type: 'RUN_DOWN', length: 10 },
        { type: 'TWO_UP', length: 100 },
        { type: 'TWO_DOWN', length: 100 },
        { type: 'THREE_UP', length: 1000 },
        { type: 'THREE_TOAST', length: 1000 },
      ]
      numbersHolesAll = lottoGameTypes.map(
        ({ type, length }) => this.createNumbersHolesList(type, length)
      ).flat()
    }
    for (const number of numbersHolesSpecial) {
      const updateNumberIndex = findIndex(
        numbersHolesAll,
        (obj) =>
          obj.numholNumber === number.numholNumber &&
          obj.numholBetType === number.numholBetType
      )
      if (updateNumberIndex !== -1) {
        numbersHolesAll[updateNumberIndex] = number
      }
    }
    this.setState({ numbersHolesDataList: numbersHolesAll })
  }

  getNumbersHolesListByType = (type: TLottoGameType) => {
    const filteredNumbersHolesList = this.state.numbersHolesDataList.filter(
      (data) => data.numholBetType === type
    )
    
    return filteredNumbersHolesList
  }

  getNumberDigit = (lottoType: TLottoGameType) => {
    let digit: number = 0
    if (lottoType.includes('THREE')) {
      digit = 3
    } else if (lottoType.includes('TWO')) {
      digit = 2
    } else if (lottoType.includes('RUN')) {
      digit = 1
    }
    return digit
  }

  handleNumberHoleEdit = async (
    lottoType: TLottoGameType,
    value: { [key in number]: INumberHolesManageListEditData },
  ) => {
    let errorList = []

    for (const number in value) {
      const editData = value[number]
      const numberDigit = this.getNumberDigit(lottoType)
      const paddedNumber = this.formatPadNumber(number, numberDigit)
      const betLimit = obj.decimalsFormat(editData.editInput)
      const numholfacId = get(
        this.props.location.state,
        'numholfacId',
        this.state.lottoHoleDetail?.numholfacId
      )
      if (!isNull(editData.numholId)) {
        try {
          await updateAgentHolesNumbers({
            numholId: editData.numholId!,
            numholBetLimit: betLimit,
            numholIsStrict: true,
          })
        } catch {
          errorList.push(paddedNumber)
        }
      } else {
        try {
          await addAgentHolesNumbers({
            numholfacId: numholfacId,
            numholBetLimit: betLimit,
            numholNumber: paddedNumber,
            numholBetType: lottoType,
          });
        } catch {
          errorList.push(paddedNumber)
        }
      }
    }
    this.getNumbersHolesAll()
    if (errorList.length > 0) {
      ErrorModal.show({
        action: ErrorModal.hide,
        description: `เกิดข้อผิดพลาดในการแก้ไขเลข`,
      })
    }
  }

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.breadcrumb.main, path: '/', active: false },
      {
        label: constants.breadcrumb.numbersHoles,
        path: '/numbers-holes',
        active: false,
      },
      { label: constants.breadcrumb.numbersHolesManage, active: true },
    ]

    const defaultQuery: IDefaultQueryNumbersHolesManage = {
      number: get(this.props.location.state, 'number', null),
      type: get(this.props.location.state, 'type', null)
    }

    return (
      <div className="paper-container numberhole-manage-container">
        <div className="row">
          <div className="col">
            <div className="sub-menu">
              <Breadcrumb
                items={navigates}
                handleOnClickItem={handleOnClickBreadcrumb}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="paper-title titleText">{constants.titlePage}</div>
            <div className="paper-description">{constants.descriptionPage}</div>
          </div>
          <div className="col">{this.renderLottoDetail()}</div>
        </div>
        <div className="row m3-t">
          <div className="col">
            <NumbersHolesManageList
              loading={this.state.loadingNumHolesList}
              key={constants.three}
              title={constants.three}
              defaultQuery={
                constants.threeTypes.includes(defaultQuery.type)
                  ? defaultQuery
                  : null
              }
              numbersHolesData={[
                this.getNumbersHolesListByType('THREE_UP'),
                this.getNumbersHolesListByType('THREE_TOAST'),
              ]}
              menuTitle={constants.threeTypes}
              onSubmit={this.handleNumberHoleEdit}
              onGetNewData={this.getNumbersHolesAll}
            />
          </div>
          <div className="col">
            <NumbersHolesManageList
              loading={this.state.loadingNumHolesList}
              key={constants.two}
              title={constants.two}
              defaultQuery={
                constants.twoTypes.includes(defaultQuery.type)
                  ? defaultQuery
                  : null
              }
              numbersHolesData={[
                this.getNumbersHolesListByType('TWO_UP'),
                this.getNumbersHolesListByType('TWO_DOWN'),
              ]}
              menuTitle={['TWO_UP', 'TWO_DOWN']}
              onSubmit={this.handleNumberHoleEdit}
              onGetNewData={this.getNumbersHolesAll}
            />
          </div>
          <div className="col">
            <NumbersHolesManageList
              loading={this.state.loadingNumHolesList}
              key={constants.run}
              title={constants.run}
              defaultQuery={
                constants.runTypes.includes(defaultQuery.type)
                  ? defaultQuery
                  : null
              }
              numbersHolesData={[
                this.getNumbersHolesListByType('RUN_UP'),
                this.getNumbersHolesListByType('RUN_DOWN'),
              ]}
              menuTitle={['RUN_UP', 'RUN_DOWN']}
              onSubmit={this.handleNumberHoleEdit}
              onGetNewData={this.getNumbersHolesAll}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default NumbersHolesManage
