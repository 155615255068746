import color from "constants/colors";
import { CSSObjectWithLabel, GroupBase, StylesConfig } from "react-select";

export const createMemberDropdownStyles = (isSelectDisabled: boolean): StylesConfig<IOptions, false, GroupBase<IOptions>> => {
    return {
        control: (provided: CSSObjectWithLabel, state) => ({
            ...provided,
            backgroundColor: isSelectDisabled ? color.rgba(color.PRIMARY_BORDER_COLOR, 0.1) : color.TRANSPARENT,
            height: 42,
            borderColor: state.isFocused ? color.PRIMARY_GREEN : color.PRIMARY_BORDER_COLOR,
            pointerEvents: 'auto',
            cursor: isSelectDisabled ? 'no-drop' : 'pointer',
            boxShadow: state.isFocused ? `0px 0px 0px 1px ${color.PRIMARY_GREEN}` : 'none',
            '&:hover': {
                borderColor: state.isFocused ? color.PRIMARY_GREEN : undefined
            }
        }),
        option: (provided, state) => ({
            ...provided,
            cursor: state.isDisabled ? 'no-drop': 'pointer',
            backgroundColor: state.isSelected ? color.SECONDARY_GREEN : state.isFocused ? color.rgba(color.SECONDARY_GREEN, 0.3) : undefined,
            color: state.isDisabled? color.TERTIARY_TEXT : color.PRIMARY_TEXT
        })
    }
}