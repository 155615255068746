import { connect } from "react-redux";
import MemberDetailNoteContainer from "./MemberDetailNote.container";
import { onChangeIsLoaddingAction } from "../../reduxs/Loader/action"
import { getFinanceChildMe } from "../../reduxs/finance/childMe/action";

const mapStateToProps = (state: RootReducers): IMemberDetailNoteProps => {
  return {
    financeChildMeCode: state.finance.financeChildMe.code,
    financeChildMeError: state.finance.financeChildMe.error,
    financeChildMeIsFetching: state.finance.financeChildMe.isFetching,
    financeChildMeData: state.finance.financeChildMe.dataReseive,
  };
};

const mapDispatchToProps: IMemberDetailNoteActionProps = {
  onChangeIsLoaddingAction,
  getFinanceChildMe
};

export default connect( mapStateToProps, mapDispatchToProps )(MemberDetailNoteContainer);
