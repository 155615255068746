export const GET_LOTTERY_LIST_REQUEST = "GET_LOTTERY_LIST_REQUEST";
export const GET_LOTTERY_LIST_SUCCESS = "GET_LOTTERY_LIST_SUCCESS";
export const GET_LOTTERY_LIST_FAILURE = "GET_LOTTERY_LIST_FAILURE";

export const GET_V2_LOTTERY_LIST_REQUEST = "GET_V2_LOTTERY_LIST_REQUEST";
export const GET_V2_LOTTERY_LIST_SUCCESS = "GET_V2_LOTTERY_LIST_SUCCESS";
export const GET_V2_LOTTERY_LIST_FAILURE = "GET_V2_LOTTERY_LIST_FAILURE";

export const GET_GAME_REQUEST = "GET_GAME_REQUEST";
export const GET_GAME_SUCCESS = "GET_GAME_SUCCESS";
export const GET_GAME_FAILURE = "GET_GAME_FAILURE";

export const listState: ReducerState<IResponseAgentLotteryList[]> = {
  isFetching: false,
  code: 0,
  data: [],
  error: "",
};

export const listStateV2: ReducerState<IV2RespAgentLotterySchedule[]> = {
  isFetching: false,
  code: 0,
  data: [],
  error: "",
};

export const gameState: ReducerState<ILottoGame> = {
  isFetching: false,
  code: 0,
  data: {
    id: 0,
    round: "",
    status: "UNKNOWN",
    createdAt: "",
    endTime: "",
    startTime: "",
  },
  error: "",
};
