import { get } from 'lodash'
import { financeCustomerState,financeCustomerTxState ,financeCustomerTxResState, financeHostState } from './constants'


const fianceCustomer = (state: RootReducers): ReducerState<APIPagination<IV2RespAgentFinanceCustomer>> =>
    get(state, 'finance.financeCustomer', financeCustomerState)

    const fianceCustomerTx = (state: RootReducers): ReducerState<APIPagination<IV2RespAgentFinanceCustomerTx>> =>
    get(state, 'finance.financeCustomerTx', financeCustomerTxState)

const postfinanceCustomerPostTx = (state: RootReducers): ReducerState<IAPIResponse<object>> =>
    get(state, 'finance.financeCustomerPostTx', financeCustomerTxResState)

const financeHost = (state: RootReducers): ReducerState<APIPagination<IV2RespAgentFinanceHost>> =>
    get(state, 'finance.financeHost', financeHostState)


const fianceCustomerSelector = {
    fianceCustomer,
    postfinanceCustomerPostTx,
    financeHost,
    fianceCustomerTx,
    
}
export default fianceCustomerSelector