import emiter from 'configs/emiter'
import Core from './Modal.component'

const event = (eventName: TEvent, modalProps: IModalStateProps) => {
  return emiter.emit<IModalStateProps>(eventName, modalProps)
}

const modelCore = {
  Core,
  event,
}
export default modelCore