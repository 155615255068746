const routes = {
  redirected: {
    name: "redirected",
    path: "/redirected",
    exactPath: "/redirected",
  },
  createMember: {
    name: "createMember",
    path: "/create-member",
    exactPath: "/create-member",
  },
  login: { name: "login", path: "/", exactPath: "/login" },
  main: { name: "main", path: "/main", exactPath: "/main" },
  members: { name: "members", path: "/members", exactPath: "/members" },
  membersList: {
    name: "membersList",
    path: "/members-list",
    exactPath: "/members-list",
  },
  betMeHistory: {
    name: "betMeHistory",
    path: "/bet-me-history",
    exactPath: "/bet-me-history",
  },
  betReport: {
    name: "betReport",
    path: "/bet-report",
    exactPath: "/bet-report",
  },
  betLotteryGroup: {
    name: "betLotteryGroup",
    path: "/bet-lottery-group",
    exactPath: "/bet-lottery-group",
  },
  // betSumHistory: {
  //   name: "betSumHistory",
  //   path: "/bet-summary-history",
  //   exactPath: "/bet-summary-history",
  // },
  betChildHistory: {
    name: "betChildHistory",
    path: "/bet-child-history",
    exactPath: "/bet-child-history",
  },
  lottoResult: {
    name: "lottoResult",
    path: "/lotto-result",
    exactPath: "/lotto-result",
  },
  lottoResultDetail: {
    name: "lotto-result-detail",
    path: "/lotto-result/lotto-result-detail/:type",
    exactPath: (type: string) => `/lotto-result/lotto-result-detail/${type}`,
  },
  membersDiagram: {
    name: "membersDiagram",
    path: "/membersDiagram",
    exactPath: "/membersDiagram",
  },
  credits: { name: "credits", path: "/credits", exactPath: "/credits" },
  transferCredits: {
    name: "transferCredits",
    path: "/transferCredits",
    exactPath: "/transferCredits",
  },
  transferCreditDescription: {
    name: "transferCreditDescription",
    path: "/transferCreditDescription",
    exactPath: "/transferCreditDescription",
  },
  transactionLists: {
    name: "transactionLists",
    path: "/transactionLists",
    exactPath: "/transactionLists",
  },
  TransferConfirmation: {
    name: "transferConfirmation",
    path: "/transferConfirmation",
    exactPath: "/transferConfirmation",
  },
  editUserHistory: {
    name: "editUserHistory",
    path: "/editUserHistory",
    exactPath: "/editUserHistory",
  },
  editUserHistoryDetails: {
    name: "editUserHistoryDetails",
    path: "/editUserHistoryDetails",
    exactPath: "/editUserHistoryDetails",
  },
  resetPassword: {
    name: "resetPassword",
    path: "/reset-password",
    exactPath: "/reset-password",
  },
  bet: {
    name: "bet",
    path: "/bet",
    exactPath: "/lotto",
  },
  betChildren: {
    name: "bet-children",
    path: "/bet/:type",
    exactPath: (type: string) => `/bet/${type}`,
  },
  betMaking: {
    name: "bet-making",
    path: "/bet/making/:type",
    exactPath: (type: string) => `/bet/making/${type}`,
  },
  newBetMaking: {
    name: "new-bet-making",
    path: "/bet/new-making/:type",
    exactPath: (type: string) => `/bet/new-making/${type}`,
  },
  manageNews: {
    name: "manageNews",
    path: "/manageNews",
    exactPath: "/manageNews",
  },
  betHistory: {
    name: "betHistory",
    path: "/betHistory",
    exactPath: "/betHistory",
  },
  betWaitingResult: {
    name: "betWaitingResult",
    path: "/bet-waiting-result",
    exactPath: "/bet-waiting-result",
  },
  betSummaryResult: {
    name: "betSummaryResult",
    path: "/bet-summary-result",
    exactPath: "/bet-summary-result",
  },
  betResult: {
    name: "betResult",
    path: "/betResult",
    exactPath: "/betResult",
  },
  resetUserPassword: {
    name: "resetUserPassword",
    path: "/resetUserPassword",
    exactPath: "/resetUserPassword",
  },
  settingRate: {
    name: "setting-rate",
    path: "/setting/rate",
    exactPath: "/setting/rate",
  },
  memberDetail: {
    name: "memberDetail",
    path: "/member/detail",
    exactPath: "/member/detail",
  },
  memberDetailNote: {
    name: "memberDetailNote",
    path: "/member/detail/note",
    exactPath: "/member/detail/note",
  },
  userDetail: {
    name: "userDetail",
    path: "/user/detail",
    exactPath: "/user/detail",
  },
  memberResetPassword: {
    name: "memberResetPassword",
    path: "/member/reset-password",
    exactPath: "/member/reset-password",
  },
  Dashboard: {
    name: "dashboard",
    path: "/dashboard",
    exactPath: "/dashboard",
  },
  financeSummaryCustomer: {
    name: "financeSummaryCustomer",
    path: "/finance/summary-customer",
    exactPath: "/finance/summary-customer",
  },
  financeCustomerDetails: {
    name: "financeCustomerDetail",
    path: "/finance-customer",
    exactPath: "/finance-customer",
  },
  userResetPassword: {
    name: "userResetPassword",
    path: "/user/reset-password",
    exactPath: "/user/reset-password",
  },
  customerList: {
    name: "customer-list",
    path: "/customer-list",
    exactPath: "/customer-list",
  },
  customerDetail: {
    name: "customerDetail",
    path: "/customer/detail",
    exactPath: "/customer/detail",
  },
  // financeSummaryChild: {
  //   name: "financeSummaryChild",
  //   path: "/finance/summary-child",
  //   exactPath: "/finance/summary-child",
  // },
  // financeChild: {
  //   name: "financeChild",
  //   path: "/finance/child",
  //   exactPath: (agentShareId: number, childAgentId: number) =>
  //     `/finance/child/?agentShareId=${agentShareId}&childAgentId=${childAgentId}`,
  // },
  // financeHost: {
  //   name: "financeHostDetail",
  //   path: "/finance/host/:id",
  //   exactPath: "/finance/host",
  // },
  userDetailNote: {
    name: "userDetailNote",
    path: "/user/detail/note",
    exactPath: "/user/detail/note",
  },
  // financeSummaryHost: {
  //   name: "financeSummaryHost",
  //   path: "/finance/host",
  //   exactPath: "/finance/host",
  // },
  previewBetBill: {
    name: "previewBetBill",
    path: "/preview/bet/bill/:code",
    exactPath: "/preview/bet/bill",
  },
  NumbersHolesAddLottery: {
    name: "numbersHolesAddLottery",
    path: "/numbers-holes/add-lottery",
    exactPath: "/numbers-holes/add-lottery",
  },
  numbersHoles: {
    name: "numbersHoles",
    path: "/numbers-holes",
    exactPath: "/numbers-holes",
  },
  numbersHolesManage: {
    name: "numbersHolesManage",
    path: "/numbers-holes/manage",
    exactPath: "/numbers-holes/manage",
  },
  numberBan: {
    name: "numberBan",
    path: "/numbers-ban",
    exactPath: "/numbers-ban",
  },
  numberBanManage: {
    name: "numberBanManage",
    path: "/numbers-ban/manage",
    exactPath: "/numbers-ban/manage",
  },
  NumbersBanAddLottery: {
    name: "numbersBanAddLottery",
    path: "/numbers-ban/add-lottery",
    exactPath: "/numbers-ban/add-lottery",
  },
  
};
export default routes;
