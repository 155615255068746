import { noop } from "lodash";
import "./success.style.scss";
import { Modal } from "../../";
import React, { FC } from "react";
import { Button } from "components";
import logo from "assets/images/logo/logothailandbet.png";
const constants = {
  title: "สำเร็จ",
  titleWeb: "ไทยแลนด์เบท",
  titleEng: "Thailand Bet",
  description: "กรุณารอการตรวจสอบสักครู่",
  actionText: "ตกลง",
};

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: ISuccessModal = {
  title: constants.title,
  description: constants.description,
  actionText: constants.actionText,
  classTextTitle:'secondary-green-text',
  action() {
    noop();
  },
  titleStyle: ''
};

const SuccessModal: FC<ISuccessModal & DefaultProps> = (props) => {
  return (
    <div className="success-modal-container border-rounded">
      <div className="row mr-0">
        <div className="leading-logo-container m2-x">
          <img src={logo} className="logoMenu" alt="" />
        </div>
        <div className="title-text-web">
          <h3 className="title">{constants.titleWeb}</h3>
          <h5 className="tertiary-text sub-title">{constants.titleEng}</h5>
        </div>
      </div>
      <h2 className={`title-text ${props.classTextTitle} whitespace ${props.titleStyle}`}>{props.title}</h2>
      <p className="body-semi1 tertiary-text mb-5">{props.description}</p>
      <div className="row justify-content-center">
        <div className="col-8">
          <Button
          id="success-ok-button"
          type="rectangle"
          onClick={props.action}
          text={props.actionText!}
          transitionType="typeOne"
          textSize={18}
        />
        </div>
      </div>
    </div>
  );
};

SuccessModal.defaultProps = defaultProps;

const successModal = {
  show: (props: ISuccessModal) => {
    return Modal.event("MODAL_OVER", {
      state: "show",
      extraProps: { size: "md" },
      component: <SuccessModal {...props} />,
    });
  },
  hide: () => Modal.event("MODAL_OVER", { state: "hide" }),
};

export default successModal;
