import { Dispatch } from 'redux'
import { AxiosError, AxiosResponse } from 'axios'
import { fetchMembersDiagram } from '../services'
import { memberDiagramsAction } from '../actionCreator'

const getMembersDiagram = () => (dispatch: Dispatch) => {
    dispatch(memberDiagramsAction.request({}))
    return fetchMembersDiagram()
        .then((res: AxiosResponse<IAPIResponse<[]>>) => {
            dispatch(memberDiagramsAction.success(res))
        })
        .catch((error: AxiosError<IAPIResponse>) => dispatch(memberDiagramsAction.failure(error)))
}
export {
    getMembersDiagram
}