import axios, {
  AxiosRequestConfig,
  AxiosTransformer,
  AxiosResponse,
  AxiosError,
} from "axios";
import { get } from "lodash";
import { AnyAction, Store } from "redux";
import { transformer, apiResponse } from "../utils";
import { responseCode, responseMessage } from "constants/response";
import { history } from "configs/store";
import { ThunkDispatch } from "redux-thunk";
import { setPasswordExpired } from "reduxs/authentication/login/action";
import { logout } from "reduxs/authentication/logout/action";
import { ErrorModal } from "components";

const transformResponse: AxiosTransformer = transformer.camelcaseTransform;
const transformRequest: AxiosTransformer = (data) =>
  JSON.stringify(transformer.snakecaseTransform(data));

const requestInterceptor = (
  config: AxiosRequestConfig
): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
  const configure: AxiosRequestConfig = {
    ...config,
    headers: {
      ...config.headers,
    },
    transformResponse,
    transformRequest,
    url: config.url?.replace(/([^:])(\/\/)/g, "$1/"),
  };
  return configure;
};

const errorRequestHandler = (error: any) => Promise.reject(error);

const responseInterceptor = (store: Store<RootReducers>) => (
  response: AxiosResponse<APIResponse>
): AxiosResponse<APIResponse> => {
  const data = apiResponse.converter(response);
  return { ...response, data };
};

const errorResponseHandler = (store: Store<RootReducers>) => (
  error: AxiosError<APIResponse>
) => {
  const errorResponse: APIResponse<IResponseAPIPasswordExpired> = apiResponse.converter(
    error
  );
  const axiosResponse: AxiosResponse<APIResponse> = {
    data: errorResponse,
    status: errorResponse.code,
    statusText: errorResponse.devMessage,
    headers: get(error, "response.headers", {}),
    config: get(error, "response.config", {}),
    request: get(error, "response.request", {}),
  };
  const errorConverted: AxiosError<APIResponse> = {
    ...error,
    response: axiosResponse,
  };
  if (errorResponse.code === responseCode.PASSWORD_EXPIRE) {
    (store.dispatch as ThunkDispatch<RootReducers, never, AnyAction>)(
      setPasswordExpired(errorResponse, history)
    );
  }
  if (errorResponse.code === responseCode.UNAUTHORIZED) {
    if (window.location.pathname !== '/') {
      ErrorModal.show({
        action: () => {
          ErrorModal.hide();
          (store.dispatch as ThunkDispatch<RootReducers, never, AnyAction>)(logout())
        },
        description: get(
          responseMessage(), errorResponse.code, responseMessage()[0]
        ),
      });
    }

  }
  return Promise.reject(errorConverted);
};

const intercepterConfiguration = (config: any, store: Store<RootReducers>) => {
  axios.defaults.responseType = "json";
  axios.defaults.headers["Content-Type"] = "application/json";
  axios.interceptors.request.use(requestInterceptor, errorRequestHandler);
  axios.interceptors.response.use(
    responseInterceptor(store),
    errorResponseHandler(store)
  );
  axios.defaults.timeout = 60000;
};
export default intercepterConfiguration;
