// import routes from "constants/routes"
import colors from "constants/colors"
import React, { Component } from "react"
import "./financeCustomerDetails.style.scss"
import { TableSumGroup, TableMenuList, TableBetGroup } from "./components"
import { RouteComponentProps } from "react-router-dom"
import { findIndex, get, isEmpty, map,/*  maxBy, */ noop, omit, pickBy } from "lodash"
import { /* date, */ handleOnClickBreadcrumb, /* responseConverter, */ transformer } from "utils"
import { responseCode, responseMessage } from "constants/response"
import { STATUS_FINANCE, CODE_TO_NAME, meDataInitialValue, STATUS_FINANCE_SUMMARY } from "constants/variables"
import { number } from "utils"
import toast from 'react-hot-toast'
// import 'react-toastify/dist/ReactToastify.css'
// // import th from 'date-fns/locale/th';
import {
  Input,
  Breadcrumb,
  ErrorModal,
  ClearBillModal,
  Panginate,
  Dropdown,
  Button,
  SuccessModal,
  ConfirmModal,
  ModalPrintBill,
  CancelBetModal,
} from "components"
import routes from "constants/routes"
import CryptoJS from "crypto-js";
import { fetchV2GetBetGroup } from "reduxs/lotto/bet/services"
import { AxiosError, AxiosResponse } from "axios"
import { PostFinanceCustomerTx } from "reduxs/finance/services"
// import { getV2AgentBet } from "reduxs/lotto/bet/services"
// import { addMinutes, addYears, format } from "date-fns"
// import { AxiosError, AxiosResponse } from "axios"
// import docDefinition from "containers/NewBetMaking/docDefinition"
// import pdfMake from "pdfmake/build/pdfmake"

const listOptionLotto: { [type in TLottoType | "All"]: string } = { ...{ All: "ทั้งหมด" }, ...CODE_TO_NAME }

const defaultProps: IFinanceCustomerDetailsProps & IFinanceCustomerDetailsActionProps = {
  getMe() { noop() },
  postFinanceCustomerTx() { noop() },
  getFinanceCustomer() { noop() },
  getGetBetGroup() { noop() },
  getAgentBet() { noop() },
  clearGetAgentBet() { noop() },
  clearPostFinanceCustomerTx() { noop() },
  onChangeIsLoaddingAction() { noop() },
  getDataV2LotteryList() { noop() },
  postCancelBill() { noop() },
  meData: meDataInitialValue,
  financeCustomerCode: 0,
  financeCustomerError: "",
  financeCustomerIsFetching: false,
  financeCustomerData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  betGroupIsFetching: false,
  betGroupCode: 0,
  betGroupError: "",
  betGroupData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  agentData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  agentIsFetching: false,
  agentCode: 0,
  agentError: "",
  financeCustomerTxPostCode: 0,
  financeCustomerTxPostIsFetching: false,
  financeCustomerTxPostError: '',
  financeCustomerTxPostData: {},

  lottoScheduleV2Data: [],
  lottoScheduleV2IsFetching: false,
  lottoScheduleV2Code: 0,
  lottoScheduleV2Error: "",

  postCancelBillCode: 0,
  postCancelBillError: "",
  postCancelBillIsFetching: false,
}

const constants = {
  title: "เคลียร์ยอดลูกค้า",
  subTitle: "เลขบิล",
  menuList: ['จากตาราง', 'การแบ่งหุ้น', 'รายการเคลียร์'],
  listCustomer: 'รายการสรุปยอดลูกค้า',
  listBetCustomer: 'รายการเดิมพันของลูกค้า',
  status: "สถานะ",
  resultPay: 'ยอดรับ/จ่ายคงเหลือ',
  remember: 'ชื่อช่วยจำ',
  clearBillFisnish: 'เคลียร์ยอดสำเร็จ',
  confirmNextBill: 'บิลปัจจุบันคือ รายการบิลสุดท้ายรายการถัดไปของท่าน จะกลับไปเริ่มต้นบิลที่ หนึ่งใหม่อีกครั้ง',
  prev: 'ก่อนหน้า',
  next: 'ถัดไป',
  sort: "ตัวเลือกการค้นหา",
  rowPerPage: "จำนวนรายการต่อหน้า :",
  dataEmpty: "ไม่มีข้อมูล...",
  titlePooiSuccess: 'สำเร็จ',
  cancelPooiSuccess: "ยกเลิกโพยสำเร็จ",
  headSumCustomerTable: [
    { codeName: "ชื่อช่วยจำ", textalign: "text-left pd-frist" },
    { codeName: "หวย", textalign: "text-left" },
    { codeName: "จำนวนรายการ", textalign: "text-right" },
    { codeName: "ยอดเดิมพัน", textalign: "text-right" },
    { codeName: "ส่วนลด", textalign: "text-right" },
    { codeName: "ค่าคอม", textalign: "text-right" },
    { codeName: "ยอดรับ/จ่าย", textalign: "text-right" },
    { codeName: "สถานะ", textalign: "text-center" },
    { codeName: "เวลา", textalign: "text-center" },
    { codeName: "clearAll", textalign: "text-center" },
    { codeName: "", textalign: "text-center" },
  ],
  headBetTable: [
    { codeName: "ประเภท", textalign: "text-left pd-frist" },
    { codeName: "เลข", textalign: "text-right" },
    { codeName: "อัตราจ่าย", textalign: "text-right" },
    { codeName: "ถือสู้", textalign: "text-right" },
    { codeName: "แบ่งหุ้น", textalign: "text-right" },
    { codeName: "ยอดเดิมพัน", textalign: "text-right" },
    { codeName: "ส่วนลด", textalign: "text-right" },
    { codeName: "ยอดสุทธิ", textalign: "text-right" },
    { codeName: "ค่าคอม", textalign: "text-right" },
    { codeName: "แพ้/ชนะ", textalign: "text-center" },
    { codeName: "เก็บของ", textalign: "text-center" },
    { codeName: "เวลา", textalign: "text-center" },
    { codeName: "", textalign: "text-center" },
  ],
  headTable: [
    { codeName: "ถือสู้", textalign: "text-center" },
    { codeName: "แบ่งหุ้น", textalign: "text-center" },
    { codeName: "ยอดเดิมพัน", textalign: "text-center" },
    { codeName: "ส่วนลด", textalign: "text-center" },
    { codeName: "ค่าคอม", textalign: "text-center" },
    { codeName: "ยอดรับ", textalign: "text-center" },
    { codeName: "ยอดจ่าย", textalign: "text-center" },
    { codeName: "สรุปกำไร/ขาดทุน", textalign: "text-center" },
    { codeName: "สถานะ", textalign: "text-center" },
    { codeName: "เวลา", textalign: "text-center" },
  ],
  headShareTable: [
    { codeName: "ผลถือสู้", textalign: "text-center" },
    { codeName: "ผลแบ่งหุ้น", textalign: "text-center" },
    { codeName: "ยอดจ่ายหัวสมาชิก", textalign: "text-center" },
    { codeName: "ยอดจ่ายลูกค้า/ชื่อช่วยจำ", textalign: "text-center" },
    { codeName: "ยอดรับหัวสมาชิก", textalign: "text-center" },
    { codeName: "ยอดรับลูกค้า/ชื่อช่วยจำ", textalign: "text-center" },
  ],
  headClearTable: [
    { codeName: "จำนวนชื่อช่วยจำ", textalign: "text-center" },
    { codeName: "จำนวนยังไม่รับ/จ่าย", textalign: "text-center" },
    { codeName: "จำนวนรับ/จ่ายแล้ว", textalign: "text-center" },
  ],
  statusMembers: {
    title: "สถานะสมาชิก",
  },
  breadcrumb: {
    main: "ภาพรวม",
    payCustomer: 'รับ/จ่ายลูกค้า',
    clearBill: "เคลียร์ยอดลูกค้า",
  },
  cancleBet: "ยืนยันยกเลิกโพย",
  confirmCancleBet: "ข้อมูลการเดิมพันของลูกค้าในงวดดังกล่าวจะถูกยกเลิกด้วยทั้งหมด คุณแน่ใจที่จะยกเลิกโพยหรือไม่",
}

class MembersListContainer extends Component<
  IFinanceCustomerDetailsProps &
  IFinanceCustomerDetailsActionProps &
  RouteComponentProps<any, any, { memberData: IV2GetAgentChild }> & { history: History },
  IFinanceCustomerDetailsState
> {
  static defaultProps = defaultProps
  queryTimeout: NodeJS.Timeout | null = null;
  state: IFinanceCustomerDetailsState = {
    activeRowColor: null,
    actionButton: '',
    isFristBill: false,
    totalRecord: 0,
    agentShareIdParam: 0,
    financeByAgentId: [],
    currentPageBetGroup: 1,
    currentBetGroupRowPerPage: 5,
    currentPageBetAgent: 1,
    currentBetAgentRowPerPage: 10,
    statusActive: "",
    statusLotto: '',
    queryStoreFinance: {
      limit: 9999,
      page: 1,
      status: '',
      startTime: "",
      endTime: "",
      agentShareId: 0,
    },
    dataQuery: {
      limit: 9999,
      page: 1,
      status: '',
      startTime: "",
      endTime: "",
      agentShareId: 0,
    },
    queryBetGroup: {
      name: '',
      customerId: 0,
      customerName: '',
      agentShareId: 0,
      number: '',
      status: '',
      page: 1,
      limit: 5,
    },
    queryAgentBet: {
      page: 0,
      limit: 0,
      customerId: 0,
      agentShareId: 0,
      startTime: '',
      endTime: "",
      customerName: "",
      number: "",
      status: "",
      name: "",
    },
    startPage: 1,
    endPage: 0,
    startPageBetAgent: 0,
    endPageBetAgent: 0,
    agentShareId: 0,
    activeMenu: 'จากตาราง',
    dataClearAllBetGroup: [],
    customerName: '',
  }


  getFinanceCustomer = () => {
    this.props.getFinanceCustomer(pickBy(this.state.dataQuery))
  }
  getFinanceCustomerStore = () => {
    var query = omit(this.state.queryStoreFinance, ['agent_share_id', 'rowIndex']);
    this.props.getFinanceCustomer(query)
  }

  getBetGroup = () => {
    this.setState({ activeRowColor: null })
    this.props.getGetBetGroup(this.state.queryBetGroup)
  }

  getAllBetGroup = async () => {
    fetchV2GetBetGroup({ ...this.state.queryBetGroup, limit: 9999 }).then(
      (res: AxiosResponse<IAPIResponse<IPagination<IV2RespAgentLotteryBetGroup>>>) => {
        return this.setState({ dataClearAllBetGroup: res.data.data.dataList })
      }
    ).catch((error: AxiosError<IAPIResponse>) => {
      return []
    })
  }

  componentDidMount() {

    this.props.getDataV2LotteryList()
    const queryParam = transformer.queryToObject()
    const locationState: IV2QueryAgentFinanceCustomer & IQueryPagination & { rowIndex: number } = this.props.location.state

    this.setState({
      queryStoreFinance: { ...locationState, limit: 99999 },
      isFristBill: get(locationState, 'rowIndex', 0) === 0 ? true : false,
      totalRecord: get(locationState, 'totalRecord', 0)
    })
    this.setState({
      agentShareIdParam: Number(queryParam.agent_share_id),
      dataQuery: {
        ...this.state.dataQuery,
        agentShareId: Number(queryParam.agent_share_id)
      },
      queryBetGroup: {
        ...this.state.queryBetGroup,
        agentShareId: Number(queryParam.agent_share_id)
      }
    }, () => {
      this.getAllBetGroup()
      this.getFinanceCustomer()
      this.getBetGroup()
    })
  }

  onModalLastBill = () => {
    ConfirmModal.show({
      size: "md",
      acceptText: 'ยืนยัน',
      title: constants.confirmNextBill,
      description: 'ยืนยันไปที่บิลแรก',
      action: () => {
        const agentFristId = get(this.props.financeCustomerData, 'dataList[0].agentShareId', 0)
        this.setState({ actionButton: '' })
        const query = omit(this.state.queryStoreFinance, ['agent_share_id', 'rowIndex'])
        this.props.getFinanceCustomer({ ...query, page: 1, limit: 10, agentShareId: agentFristId })
        ConfirmModal.hide()

      },
      cancelAction: () => {
        ConfirmModal.hide()
      }
    })
  }

  componentDidUpdate(prevProps: IFinanceCustomerDetailsProps) {
    if (
      prevProps.financeCustomerIsFetching !== this.props.financeCustomerIsFetching &&
      !this.props.financeCustomerIsFetching
    ) {
      this.props.onChangeIsLoaddingAction(false)
      if (this.props.financeCustomerCode === responseCode.OK) {

        if (!isEmpty(this.props.financeCustomerData.dataList)) {
          //only bill
          if (this.props.financeCustomerData.dataList.length === 1) {
            if (this.state.actionButton === 'NEXT') {
              this.onModalLastBill()
            }
            this.setState({
              financeByAgentId: this.props.financeCustomerData.dataList,
              agentShareIdParam: get(this.props.financeCustomerData, 'dataList[0].agentShareId', 0),
              isFristBill: true
            })
          }
          else {
            const totalFinanceBill = this.props.financeCustomerData.totalRecord
            let curentFinance = findIndex(this.props.financeCustomerData.dataList, { agentShareId: this.state.agentShareIdParam })
            curentFinance = this.state.actionButton === 'NEXT' ? curentFinance + 1 : curentFinance - 1
            this.setState({ isFristBill: 0 === curentFinance ? true : false })
            //last bill
            if (curentFinance === totalFinanceBill) {
              this.onModalLastBill()
            }
            else if (this.state.actionButton === 'NEXT') {

              const financeNext = this.props.financeCustomerData.dataList[curentFinance]
              this.setState({
                financeByAgentId: [financeNext],
                agentShareIdParam: financeNext.agentShareId,
                queryBetGroup: { ...this.state.queryBetGroup, agentShareId: financeNext.agentShareId }
              }, () => {
                this.getBetGroup()
              })
            }
            else if (this.state.actionButton === 'PREV') {
              const financeNext = this.props.financeCustomerData.dataList[curentFinance]
              this.setState({
                financeByAgentId: [financeNext],
                agentShareIdParam: financeNext.agentShareId,
                queryBetGroup: { ...this.state.queryBetGroup, agentShareId: financeNext.agentShareId }
              }, () => {
                this.getBetGroup()
              })

            }

          }
        }
      }
      else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            this.props.financeCustomerCode,
            responseMessage()[0]
          ),
        });
      }
    }

    //Bet group
    if (prevProps.betGroupIsFetching !== this.props.betGroupIsFetching && !this.props.betGroupIsFetching) {
      this.props.onChangeIsLoaddingAction(false)
      if (this.props.betGroupCode === responseCode.OK
        && this.props.betGroupError === '') {
        if (isEmpty(this.props.betGroupData.dataList)) {
          this.setState({ startPage: 0, endPage: 0 });
        }
        else {
          this.setState({
            startPage:
              this.state.currentBetGroupRowPerPage * (this.state.queryBetGroup.page - 1) +
              1,
            endPage:
              this.state.currentBetGroupRowPerPage * (this.state.currentPageBetGroup + 1) >=
                this.props.betGroupData.totalRecord
                ? this.props.betGroupData.totalRecord
                : this.state.currentBetGroupRowPerPage * this.state.queryBetGroup.page,
          });
        }
      }
      else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            this.props.betGroupCode,
            responseMessage()[0]
          ),
        })
      }
    }

    //Agent group
    if (prevProps.agentIsFetching !== this.props.agentIsFetching && !this.props.agentIsFetching) {
      this.props.onChangeIsLoaddingAction(false);
      if (this.props.agentCode === responseCode.OK
        && this.props.agentError === '') {
        if (isEmpty(this.props.agentData.dataList)) {
          this.setState({ startPageBetAgent: 0, endPageBetAgent: 0 });
        }
        else {
          this.setState({
            startPageBetAgent:
              this.state.currentBetAgentRowPerPage * (this.state.queryAgentBet.page - 1) +
              1,
            endPageBetAgent:
              this.state.currentBetAgentRowPerPage * (this.state.currentPageBetAgent) >=
                this.props.agentData.totalRecord
                ? this.props.agentData.totalRecord
                : this.state.currentBetAgentRowPerPage * this.state.queryAgentBet.page,
          });
        }
      }
      else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            this.props.betGroupCode,
            responseMessage()[0]
          ),
        })
      }
    }

    //Finance Tx
    if (prevProps.financeCustomerTxPostIsFetching !== this.props.financeCustomerTxPostIsFetching && !this.props.financeCustomerTxPostIsFetching) {
      if (this.props.financeCustomerTxPostCode === responseCode.OK && this.props.financeCustomerTxPostError === '') {
        toast.success(`เคลียร์บิล ${this.state.customerName} สำเร็จ!`, { duration: 5000 })
        this.getBetGroup()
        this.getFinanceCustomer()
        this.props.getMe()
      }
      else {
        toast.error(
          <div className="finance-customer-detail-container">
            <div className="wrapper">
              <div className="child1">เคลียร์บิล</div>
              <div className="child2">
                <div className="flex">
                  <div className="el">{`${this.state.customerName}`}</div>
                </div>
              </div>
              <div className="child3">ไม่สำเร็จ!</div>
            </div>
           </div>
          , { duration: 5000 }
        )
        this.getBetGroup()
      }
    }

    if ((prevProps.postCancelBillIsFetching !== this.props.postCancelBillIsFetching) && !this.props.postCancelBillIsFetching) {
      if (this.props.postCancelBillCode !== responseCode.OK && this.props.postCancelBillError !== "") {
        ErrorModal.show({
          action: () => ErrorModal.hide(),
          description: get(
            responseMessage(),
            this.props.postCancelBillCode,
            responseMessage()[0]
          ),
        })
      } else {
        SuccessModal.show({
          action: () => {
            SuccessModal.hide()
            this.props.getMe()
            this.props.getAgentBet(this.state.queryAgentBet)
          },
          title: constants.titlePooiSuccess,
          description: constants.cancelPooiSuccess,
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.clearGetAgentBet()
    this.props.clearPostFinanceCustomerTx()
  }



  handleQueryStatusBetGroup = (value: string) => {
    this.setState(
      {
        statusActive: value,
        queryBetGroup: {

          ...this.state.queryBetGroup,
          status: value === 'All' ? '' : value,
          name: this.state.queryBetGroup.name,
          page: 1,
        },
      },
      () => {
        this.getBetGroup()
        this.getAllBetGroup()
      }
    )
  }

  handleClickChangePageAgent = ({ selected: selectedPage }: ISelectPage) => {
    this.setState({ currentPageBetAgent: selectedPage }, () => {
      this.setState(
        {
          queryAgentBet: {
            ...this.state.queryAgentBet,
            page: this.state.currentPageBetAgent + 1,
          },
        },
        () => {
          this.props.getAgentBet(this.state.queryAgentBet)
        }
      )
    })
  }

  handleClickChangePageBetGroup = ({ selected: selectedPage }: ISelectPage) => {
    this.setState({ currentPageBetGroup: selectedPage }, () => {
      this.setState(
        {
          queryBetGroup: {
            ...this.state.queryBetGroup,
            page: this.state.currentPageBetGroup + 1,
          },
        },
        () => {
          this.props.getGetBetGroup(this.state.queryBetGroup)
        }
      )
    })
  }


  sendClearBill = (data: IV2PayAgentFinanceCustomerTx) => {
    this.props.postFinanceCustomerTx(data)
    ClearBillModal.hide();
  }


  onClickClearBill = (data: IV2RespAgentLotteryBetGroup) => {
    this.setState({ customerName: data.customerName})
    ClearBillModal.show({
      detailBill: data,
      action: this.sendClearBill,
      cancelAction: () => {
        ClearBillModal.hide();
      },
    })
  }

  onClickClearAllBill = (dataList: Array<IV2RespAgentLotteryBetGroup>) => {

    ConfirmModal.show({
      size: "md",
      title: "ยืนยันการเคลียร์บิลทั้งหมด",
      description: "คุณแน่ใจหรือไม่?",
      action: () => {
        let successReq = 0
        let countReq = 0
        dataList.forEach((data) => {
          const dataClear: IV2PayAgentFinanceCustomerTx = {
            agentShareId: data.agentShareId,
            customerId: data.customerId,
            name: data.name,
            agbeneStatus: 'SUCCESS',
            note: '',
          }
          PostFinanceCustomerTx(dataClear).then(
            (res: AxiosResponse<IAPIResponse<object>>) => {
              if (res.data.code === responseCode.OK) {
                successReq++
              }
            }
          ).catch((error: AxiosError<IAPIResponse>) => {
            toast.error(
              <div className="finance-customer-detail-container">
                <div className="wrapper">
                  <div className="child1">เคลียร์บิล</div>
                  <div className="child2">
                    <div className="flex">
                      <div className="el">{`${data.customerName}`}</div>
                    </div>
                  </div>
                  <div className="child3">ไม่สำเร็จ!</div>
                </div>
               </div>
              , { duration: 5000 }
            )
          }).finally(() => {
            countReq++
            if(successReq === dataList.length){
            toast.success("เคลียร์บิลทั้งหมด สำเร็จ!", { duration: 5000 })
            }
            if(countReq === dataList.length){
              this.getBetGroup()
              this.getAllBetGroup()
              this.getFinanceCustomer()
              this.props.getMe()
            }
          })
        })
        ConfirmModal.hide()
      },
      cancelAction: ConfirmModal.hide,
    })
  }



  onClickRowSumCustomer = (data: IV2RespAgentLotteryBetGroup, index: number) => {
    const queryData = {
      ...this.state.queryAgentBet,
      limit: 10,
      page: 1,
      agentShareId: data.agentShareId,
      customerName: data.customerName,
      name: data.name
    }
    this.setState({ queryAgentBet: queryData, activeRowColor: index }, () => {
      this.props.getGetBetGroup(this.state.queryBetGroup)
      this.props.getAgentBet(queryData)
    })
  }
  renderFilter = () => (
    <>
      <div className="subtitle-2">{constants.sort}</div>
      <div className="row ">
        <div className="col-4 ">
          <div className="dropdown-border">
            <Dropdown
              menuStyles={{ width: "100%" }}
              options={Object.keys(listOptionLotto).map((item) => { return { value: item, label: listOptionLotto[item as TLottoType], disabled: false } })}
              controlBackgroundColor={colors.PRIMARY_BG}
              placeholder={'หวย'}
              value={this.state.statusLotto}
              handleOnChange={(target: IOptions) => {
                this.setState(
                  { statusLotto: String(target.value), queryBetGroup: { ...this.state.queryBetGroup, page: 1, name: target.value === "All" ? "" : target.value } },
                  () => {
                    this.getBetGroup()
                    this.getAllBetGroup()
                  }
                )
              }}
            />
          </div>
        </div>
        <div className="col-4 px-0">
          <div className="dropdown-border">
            <Dropdown
              menuStyles={{ width: '100%' }}
              options={Object.keys(STATUS_FINANCE_SUMMARY).map((item) => {
                return { value: item, label: get(STATUS_FINANCE_SUMMARY, `${item}.status`, ''), disabled: false }
              }
              )}
              controlBackgroundColor={colors.PRIMARY_BG}
              handleOnChange={(target: IOptions) => {
                this.handleQueryStatusBetGroup(
                  String(target.value)
                )
              }}
              value={this.state.statusActive}
              placeholder={'สถานะ'}
            />
          </div>
        </div>
        <div className='col-4 input-box'>
          <Input
            id="search-customerName"
            name="search-customerName"
            inputClassName="border"
            placeholder={constants.remember}
            setPadding="6px"
            backgroundColor={colors.PRIMARY_BG}
            value={this.state.queryBetGroup.customerName}
            onChange={(e) => {
              this.setState({ queryBetGroup: { ...this.state.queryBetGroup, customerName: e.target.value } },
                () => {
                  clearTimeout(this.queryTimeout!)
                  this.queryTimeout = setTimeout(() => {
                    this.getBetGroup()
                    this.getAllBetGroup()
                  }, 1000)
                }
              )
            }}
            renderError={false}
          />
        </div>
      </div>
    </>
  )



  renderTableSumGroup = () => {
    return (
      <TableSumGroup
        loading={this.props.betGroupIsFetching}
        dataClearAll={this.state.dataClearAllBetGroup}
        onClearAll={this.onClickClearAllBill}
        column={constants.headSumCustomerTable}
        dataList={this.props.betGroupData.dataList}
        onClick={this.onClickClearBill}
        onClickRow={this.onClickRowSumCustomer}
        callBackDropdown={this.callBackDropdownSumGroup}
        activeRow={this.state.activeRowColor!}
      />
    )
  }



  callBackDropdownSumGroup = (target: IOptions, data: IV2RespAgentLotteryBetGroup | IV2RespAgentLotteryBetMe) => {
    if (target.value === 'BillPrint') {
      this.modalPrintBill(data)
    } else if (target.value === 'BetSumHistory') {
      this.props.history.push(routes.betSumHistory.path, ({
        customerName: data.customerName,
        customerId: data.customerId,
        name: data.name,
      }))
    } else if (target.value === 'CustomerList') {
      this.props.history.push(routes.customerList.path + transformer.urlSearchParams({ customerId: data.customerId }))
    } else if (target.value === 'FinanceHost') {
      this.props.history.push(routes.financeHost.path + transformer.urlSearchParams({ agentShareId: data.agentShareId }))
    } else if (target.value === 'FinanceChild') {
      this.props.history.push(routes.financeSummaryChild.path + transformer.urlSearchParams({ agentShareId: data.agentShareId }))
    } else if (target.value === 'CancelBill') {
      CancelBetModal.show({
        size: "md",
        title: constants.cancleBet,
        description: constants.confirmCancleBet,
        action: () => {
          this.props.postCancelBill({
            agentShareId: data.agentShareId
            , customerId: data.customerId
            , name: data.name
            , mode: 'CANCEL_PER_CLOTTERY'
          })
          CancelBetModal.hide()
          this.props.getMe()
        },
        cancelAction: CancelBetModal.hide
      })
    }
  }

  // modalPrintBill = (data: IV2RespAgentLotteryBetGroup | IV2RespAgentLotteryBetMe) => {
  //   ModalPrintBill.show({
  //     actionPrintShowPay: () => {
  //       this.getDataBill(data, true)
  //     },
  //     actionPrintNotShowPay: () => {
  //       this.getDataBill(data, false)
  //     },
  //     actionCancel: () => {
  //       ErrorModal.hide()
  //     },
  //   })
  // }


  modalPrintBill = (data: IV2RespAgentLotteryBetGroup | IV2RespAgentLotteryBetMe) => {
    ModalPrintBill.show({
      actionPrintShowPay: () => {
        const dataString = JSON.stringify(data)
        const encrypted = CryptoJS.AES.encrypt(dataString, 'bill-query')
        const urlEncoded = encodeURIComponent(encrypted.toString())
        window.open(`${routes.previewBetBill.exactPath}/${urlEncoded}?showpaid=yes&name=${this.props.meData.username}`)
      },
      actionPrintNotShowPay: () => {
        const dataString = JSON.stringify(data)
        const encrypted = CryptoJS.AES.encrypt(dataString, 'bill-query')
        const urlEncoded = encodeURIComponent(encrypted.toString())
        window.open(`${routes.previewBetBill.exactPath}/${urlEncoded}?name=${this.props.meData.username}`)
      },
      actionCancel: () => {
        ErrorModal.hide()
      },
    })
  }

  // getDataBill = (data: IV2RespAgentLotteryBetGroup | IV2RespAgentLotteryBetMe, showPay: boolean) => {
  //   getV2AgentBet({
  //     limit: 999999,
  //     name: data.name,
  //     customerId: data.customerId,
  //     agentShareId: data.agentShareId,
  //     startTime: format(date.calibratingTime(data.createdAt), "yyyy'-'MM'-'dd'T'HH:mm:ss"),
  //     endTime: format(addMinutes(date.calibratingTime(data.createdAt), 1), "yyyy'-'MM'-'dd'T'HH:mm:ss"),
  //   }).then((res: AxiosResponse<IAPIResponse<IPagination<IV2RespAgentLotteryBetMe>>>) => {
  //     const dataList = res.data.data.dataList
  //     if (!isEmpty(dataList)) {
  //       const dataName = transformer.nameLotto(get(dataList,"[0].name"),true,true)
  //       const dataCustomerName = get(dataList,"[0].customerName")
  //       const dateNow = format(addYears(date.calibratingTime(get(dataList,"[0].createdAt")),543), "ddMMyyyy")
  //       const nameFile = `${dataName}_${dataCustomerName}_${dateNow}`

  //       let status = get(dataList, "[0].status", '')
  //       let round = ''
  //       if (status !== 'WAIT') {
  //         round = get(maxBy(dataList, (i) => i.updatedAt), "updatedAt", '')
  //         round = format(addYears(date.calibratingTime(round), 543), "dd MMMM yyyy", { locale: th })
  //       } else {
  //         let findData = this.props.lottoScheduleV2Data.filter((i) => i.name === data.name)
  //         if (isEmpty(findData)) {
  //           round = "ไม่พบข้อมูลงวด"
  //         } else {
  //           round = format(addYears(date.calibratingTime(findData[0].endTime), 543), "dd MMMM yyyy", { locale: th })
  //         }
  //       }
  //       pdfMake.createPdf(docDefinition(dataList, showPay, round) as any).download(nameFile)
  //     }
  //   })
  //     .catch((error: AxiosError<IAPIResponse>) => {
  //       ErrorModal.show({
  //         action: ErrorModal.hide,
  //         description: get(responseMessage(), responseConverter.getMessage(error).code, responseMessage()[0]),
  //       });
  //     })
  // }

  renderTableBet = () => {
    return (
      <TableBetGroup
        loading={this.props.agentIsFetching}
        callBackDropdown={this.callBackDropdownSumGroup}
        column={constants.headBetTable}
        dataList={this.props.agentData.dataList}
        onClick={() => { }}
      />
    )
  }

  renderTableMenuList = () => {
    return (
      <>
        <TableMenuList
          loading={this.props.financeCustomerIsFetching}
          activeMenu={this.state.activeMenu}
          column={constants.headTable}
          columnShare={constants.headShareTable}
          columnClear={constants.headClearTable}
          dataList={this.state.financeByAgentId}
          onClick={() => { }}
        />
      </>
    )
  }

  renderDropDownSumCustomer = () => {
    return (
      <>
        <h6>{constants.rowPerPage}</h6>
        <div className="dropdown-rowPerPage">
          <Dropdown
            menuPlacement={"auto"}
            options={[
              { value: 5, label: "5", disabled: false },
              { value: 10, label: "10", disabled: false },
              { value: 20, label: "20", disabled: false },
              { value: 50, label: "50", disabled: false },
              { value: 100, label: "100", disabled: false },
            ]}
            controlBackgroundColor={colors.PRIMARY_BG}
            placeholder={String(this.state.currentBetGroupRowPerPage)}
            handleOnChange={(target: IOptions) => {
              this.setState(
                {
                  currentPageBetGroup: 1,
                  currentBetGroupRowPerPage: Number(target.value),
                  queryBetGroup: {
                    ...this.state.queryBetGroup,
                    page: 1,
                    limit: Number(target.value),
                  },
                },
                () => {
                  this.getBetGroup()
                }
              );
            }}
          />
        </div>
      </>
    )
  }

  renderDropDownAgentBetCustomer = () => {
    return (
      <>
        <h6>{constants.rowPerPage}</h6>
        <div className="dropdown-rowPerPage">
          <Dropdown
            menuPlacement={"auto"}
            options={[
              { value: 5, label: "5", disabled: false },
              { value: 10, label: "10", disabled: false },
              { value: 20, label: "20", disabled: false },
              { value: 50, label: "50", disabled: false },
              { value: 100, label: "100", disabled: false },
            ]}
            controlBackgroundColor={colors.PRIMARY_BG}
            placeholder={String(this.state.currentBetAgentRowPerPage)}
            handleOnChange={(target: IOptions) => {
              this.setState(
                {
                  currentPageBetAgent: 1,
                  currentBetAgentRowPerPage: Number(target.value),
                  queryAgentBet: {
                    ...this.state.queryAgentBet,
                    page: 1,
                    limit: Number(target.value),
                  },
                },
                () => {
                  this.props.getAgentBet(this.state.queryAgentBet)
                }
              );
            }}
          />
        </div>
      </>
    )
  }

  renderLandingMenu = () => {
    const mappingMenu = map(constants.menuList, (name) => (
      <div className={`m5-r`} key={`${name}`} id={`${name}`}
        onClick={() => {
          if (this.state.activeMenu !== name) {
            this.setState({ activeMenu: name })
          }
        }}
      >
        <div className="d-flex flex-column">
          <h6 className={this.state.activeMenu === name ? 'active-menu' : ''}>
            {name}
          </h6 >
        </div>
      </div >
    ))
    return <div className="row p2-l">{mappingMenu}</div>
  }

  renderTitleStatus = () => {
    const financeByAgentId: IV2RespAgentFinanceCustomer = this.state.financeByAgentId[0]
    const totalOverdue = get(financeByAgentId, 'totalOverdue', 0)
    const stName = get(STATUS_FINANCE, `${get(financeByAgentId, 'status', 'WAIT')}.status`, '')
    const stColor = get(STATUS_FINANCE, `${get(financeByAgentId, 'status', 'WAIT')}.color`, '')
    const totalOverdueColor = (totalOverdue) > 0 ? colors.SECONDARY_GREEN
      : totalOverdue < 0 ? colors.PRIMARY_RED : colors.TERTIARY_TEXT

    return (
      <div className="row list-summary-container">
        <div className="col d-flex">
          <div className="d-flex flex-column paper-body-title leading-wrapper">
            {constants.listCustomer}
          </div>
          <div className="d-flex align-items-center">
            <div className="d-flex flex-column align-items-center m2-r">
              <div className="body-3b ">
                {constants.status}
              </div>
              <span className="body-0b" style={{ color: stColor }}>
                {stName}
              </span>
            </div>
            <div className="d-flex flex-column align-items-center">
              <div className="body-3b">
                {constants.resultPay}
              </div>
              <span className="body-0b" style={{ color: totalOverdueColor }}>
                {number.addComma(totalOverdue)}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderNextBill = () => {
    if (this.state.isFristBill && this.state.totalRecord === 1) {
      return <></>
    }
    else if (!this.state.isFristBill) {
      return (
        <>
          <div>
            <Button
              id="prev-bill"
              text={constants.prev}
              borderRadius={"8px"}
              buttonClass={"button-bill"}
              backgroundColor={colors.PRIMARY_GREEN}
              textSize={18}
              backgroundHoverColor={colors.SECONDARY_GREEN}
              transitionType="typeOne"
              onClick={() => {
                this.setState({ actionButton: 'PREV' })
                this.getFinanceCustomerStore()
              }}
            />
          </div>
          <div className="m2-l">
            <Button
              id="next-bill"
              text={constants.next}
              borderRadius={"8px"}
              buttonClass={"button-bill"}
              backgroundColor={colors.PRIMARY_GREEN}
              textSize={18}
              backgroundHoverColor={colors.SECONDARY_GREEN}
              transitionType="typeOne"
              onClick={() => {
                this.setState({ actionButton: 'NEXT' })
                this.getFinanceCustomerStore()
              }}
            />
          </div>
        </>
      )
    }
    else {
      return (
        <div className="m2-l">
          <Button
            id="next-bill"
            text={constants.next}
            borderRadius={"8px"}
            buttonClass={"button-bill"}
            backgroundColor={colors.PRIMARY_GREEN}
            textSize={18}
            backgroundHoverColor={colors.SECONDARY_GREEN}
            transitionType="typeOne"
            onClick={() => {
              this.setState({ actionButton: 'NEXT' })
              this.getFinanceCustomerStore()
            }}
          />
        </div>
      )
    }

  }




  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.breadcrumb.main, path: "/", active: false },
      { label: constants.breadcrumb.payCustomer, path: "/finance/summary-customer", active: false },
      { label: constants.breadcrumb.clearBill, active: true },
    ]

    const RenderFilter = this.renderFilter()
    const RenderTitleStatus = this.renderTitleStatus
    const RenderTableMenuList = this.renderTableMenuList
    const RenderTableMembers = this.renderTableSumGroup
    const RenderTableBet = this.renderTableBet
    const RenderDropDownSumCustomer = this.renderDropDownSumCustomer
    const RenderDropDownAgentBetCustomer = this.renderDropDownAgentBetCustomer
    const RenderLadingMenu = this.renderLandingMenu
    const RenderNextBill = this.renderNextBill
    // const RenderDetailList = this.renderDetailList

    return (
      <>
        <div className="paper-container finance-customer-detail-container">
          <div className="row">
            <div className="col">
              <div className="sub-menu">
                <Breadcrumb
                  items={navigates}
                  handleOnClickItem={handleOnClickBreadcrumb}
                />
              </div>
            </div>
          </div>
          <div className="paper-title">{constants.title}</div>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="paper-description tertiary-text body-3b">{`${constants.subTitle}`} <span className="tertiary-text body-3">{`${this.state.agentShareIdParam}`}</span></div>
            <div className="d-flex flex-row align-items-center">
              <RenderNextBill />
            </div>
          </div>
          <div className="paper-body">
            <div className="card-wrapper">
              <div className="menu-list p3-b">
                <RenderLadingMenu />
              </div>
              <RenderTableMenuList />
            </div>
          </div>
          {this.props.meData.permission !== "OWNER" ? <div>
            <div className="paper-body">
              <div className="row mt-3">
                <div className="col">
                  <div className="box-shadow">
                    <RenderTitleStatus />

                    <div className="row m2-b">
                      <div className="col">{RenderFilter}</div>
                    </div>
                    <div className="row ">
                      <div className="col"><RenderTableMembers /></div>
                    </div>
                    <div className='row'>
                      <div className="col d-flex justify-content-end align-items-center m2-t">
                        <RenderDropDownSumCustomer />
                      </div>
                      <h6 className='d-flex justify-content-end align-items-center m2-t'>
                        {`${this.state.startPage} - ${this.state.endPage} 
                       of ${this.props.betGroupData.totalRecord}`}
                      </h6>
                      <div className='col-auto'>
                        <Panginate
                          pageTotal={this.props.betGroupData.total}
                          initialPage={this.state.queryBetGroup.page - 1}
                          pageRangeDisplayed={this.state.currentPageBetGroup}
                          onPageChange={this.handleClickChangePageBetGroup}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="paper-body">
              <div className="row mt-3">
                <div className="col">
                  <div className="box-shadow">
                    <div className="row list-summary-container">
                      <div className="col d-flex m2-b">
                        <div className="d-flex flex-column paper-body-title leading-wrapper">
                          {constants.listBetCustomer}
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col"><RenderTableBet /></div>
                    </div>
                    <div className='row'>
                      <div className="col d-flex justify-content-end align-items-center m2-t">
                        <RenderDropDownAgentBetCustomer />
                      </div>
                      <h6 className='d-flex justify-content-end align-items-center m2-t'>
                        {`${this.state.startPageBetAgent} - ${this.state.endPageBetAgent} 
                       of ${this.props.agentData.totalRecord}`}
                      </h6>
                      <div className='col-auto'>
                        <Panginate
                          pageTotal={this.props.agentData.total}
                          initialPage={this.state.queryAgentBet.page - 1}
                          pageRangeDisplayed={this.state.currentPageBetAgent}
                          onPageChange={this.handleClickChangePageAgent}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
            : <></>}
        </div>
      </>
    )
  }
}

export default MembersListContainer
