import { AxiosError, AxiosResponse } from "axios";
import { cloneDeep, zipWith } from "lodash";
import { Dispatch } from "redux";
import { getNumberAction } from "../actionCreator";
import { fetchGetNumber } from "../services";

const getNumber = (querys: ISearchAgentLotteryRateNumber[]) => async (
  dispatch: Dispatch
) => {
  dispatch(getNumberAction.request({}));
  return Promise.all(fetchGetNumber(querys))
    .then(
      (
        responses: AxiosResponse<
          IAPIResponse<IResponseAgentLotteryRateNumber[]>
        >[]
      ) => {
        const convertedResponse = convertResponse(responses, querys);
        dispatch(getNumberAction.success(convertedResponse));
      }
    )
    .catch(
      (error: AxiosError<IAPIResponse<IResponseAgentLotteryRateNumber[]>>) => {
        dispatch(getNumberAction.failure(error));
      }
    );
};

const convertResponse = (
  responses: AxiosResponse<IAPIResponse<IResponseAgentLotteryRateNumber[]>>[],
  querys: ISearchAgentLotteryRateNumber[]
) => {
  let clonedResponse = cloneDeep(responses[0]);
  const lotters = responses.map((response) => response.data.data);
  const convertedLotters = zipWith(lotters, querys, (lotter, query) => {
    return {
      ...lotter,
      number: query.number,
      type: query.type,
      code: query.code,
    };
  });
  clonedResponse.data.data = convertedLotters;
  return clonedResponse;
};

export { getNumber };
