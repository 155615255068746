import { autocompleteAction } from './actionCreator'
import { AxiosError, AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import { fetchAutocomplete } from './services'

const getAutocompleteAction = (req: IAutocompleteRequest) => (dispatch: Dispatch) => {
    dispatch(autocompleteAction.request({}))
    return fetchAutocomplete(req)
        .then((res: AxiosResponse<IAPIResponse<IPagination<IAutocomplete>>>) => {
            dispatch(autocompleteAction.success(res))
        })
        .catch((error: AxiosError<IAPIResponse>) => dispatch(autocompleteAction.failure(error)))
}

export {
    getAutocompleteAction
}



