import React, { Component } from "react";
import colors from "constants/colors";
import { get, isEmpty, noop, pickBy } from "lodash";
import {
  SkeletonLoader,
  Panginate,
  Dropdown,
  ErrorModal,
  Button,
  LoadingCircle,
} from "components";
import { th } from "date-fns/locale";
import { format } from "date-fns";
import { date, number } from "utils";
import { CODE_TO_SHORT_NAME } from "constants/variables";
import { responseCode, responseMessage } from "constants/response";
import "./incomeExpenseTable.style.scss"

const constants = {
  rowPerPage: "จำนวนรายการต่อหน้า :",
  times: "ครั้ง",
  items: "รายการ",
  Baht: "บาท",
  percent: "%",
  dataEmpty: "ไม่มีข้อมูล...",
  headTable: [
    { codeName: "วันที่", textalign: "text-left paddingtableHead" ,style:{width:`10%`}},
    { codeName: "หวย", textalign: "text-left" ,style:{width:`13%`} },
    { codeName: "จำนวนรายการ", textalign: "text-left" ,style:{width:`9%`} },
    { codeName: "คอมมิชชั่น", textalign: "text-left" ,style:{width:`7%`} },
    { codeName: "ส่วนลด", textalign: "text-left " ,style:{width:`5%`} },
    { codeName: "ยอดเดิมพันทั้งหมด", textalign: "text-right " ,style:{width:`10%`} },
    { codeName: "แบ่งหัวสมาชิก", textalign: "text-right" ,style:{width:`13%`} },
    { codeName: "แบ่งตัวเอง", textalign: "text-right " ,style:{width:`10%`}},
    { codeName: "ยอดรับ", textalign: "text-right",style:{width:`10%`} },
    { codeName: "ยอดจ่าย", textalign: "text-right paddingTableLastCol" },
  ],
};

const defaultProps: IIncomeExpenseTableProps = {
    getBetGroup(){
        noop()
    },
  onChangeIsLoaddingAction() {},
  locationData:0,
  onload(){},
  betgroupIsFetching: false,
  betgroupCode: 0,
  betgroupError: "",
  betGroupData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  clickChangeRoute(){
    noop()
  },
};

class incomeExpenseTable extends Component<IIncomeExpenseTableProps> {
  static defaultProps = defaultProps;
  state = {
    currentPage: 0,
    currentRowPerPage: 10,
    childSort: "",
    dataQuery: {
      limit: 10,
      page: 1,
      customerId: 0,
    },
    startPage: 1,
    endPage: 0,

    queryTimeout: setTimeout(() => {
      noop();
    }, 0),
    reqMemberDetail: {
      id: -99,
    },
    isSearch: false,
  };
  componentDidMount() {
    this.setState({dataQuery:{...this.state.dataQuery,customerId:this.props.locationData}},
      ()=> {
        this.props.getBetGroup({
          customerId: this.state.dataQuery.customerId,
        });
      })
  
  }
  componentDidUpdate(prevProps: IIncomeExpenseTableProps) {
    if (this.props.betgroupIsFetching) {
      this.props.onload(true);
    } else {
      this.props.onload(false);
    }
    if (
      prevProps.betgroupIsFetching !== this.props.betgroupIsFetching &&
      !this.props.betgroupIsFetching
    ) {
      if (
        this.props.betgroupCode !== responseCode.OK &&
        this.props.betgroupError !== ""
      ) {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            this.props.betgroupCode,
            responseMessage()[0]
          ),
        });
      }
      if (this.props.betgroupCode === responseCode.OK) {
        if (isEmpty(this.props.betGroupData.dataList)) {
          this.setState({ startPage: 0, endPage: 0 });
        } else {
          this.setState({
            startPage:
              this.state.currentRowPerPage * (this.state.dataQuery.page - 1) +
              1,
            endPage:
              this.state.currentRowPerPage * (this.state.currentPage + 1) >=
              this.props.betGroupData.totalRecord
                ? this.props.betGroupData.totalRecord
                : this.state.currentRowPerPage * this.state.dataQuery.page,
          });
        }
      }
    }
  }

  handleClickChangePage = ({ selected: selectedPage }: ISelectPage) => {
    this.setState({ currentPage: selectedPage }, () => {
      this.setState(
        {
          dataQuery: {
            ...this.state.dataQuery,
            page: this.state.currentPage + 1,
          },
        },
        () => {
          this.props.getBetGroup(pickBy(this.state.dataQuery));
        }
      );
    });
  };

  tableMembersBody = () => {
    if (this.state.isSearch) {
      return (
        <tr className={`row-members-list color-row`}>
          <td className="text-center color-row p-2" colSpan={15}>
            <SkeletonLoader customStyle={[[{ col: 12, show: true }]]} />
          </td>
        </tr>
      );
    } else {
      const loadingTable = (
        <tr>
          <td className="text-center" colSpan={15}>
            <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
          </td>
        </tr>
      )
      const dataEmpty = (
        <tr>
          <td className="text-center" colSpan={15}>
            {constants.dataEmpty}
          </td>
        </tr>
      )
      const mapData = this.props.betGroupData.dataList.map((data, index: number) => {
        return (
          <tr className={`row-members-list `} key={index}>
            <td className="text-left paddingRow">
              <span className="paddingtableHead">
                {" "}
                {`${format(
                  date.calibratingTime(data.createdAt, true),
                  "dd MMM yyyy HH:mm",
                  { locale: th }
                )}`}
              </span>
            </td>
            <td className="text-left ">
              <span> {get(CODE_TO_SHORT_NAME, `[${data.name}]`, "")}</span>
            </td>
            <td className="text-left">
              <span>
                {data.totalCountBet} {constants.items}
              </span>
            </td>
            <td className="text-left">
              <span>
                {data.totalCom} 
              </span>
            </td>
            <td className="text-left">
              <span>
                {data.totalDis} 
              </span>
            </td>
            <td className="text-right">
              <span className="paddingtableCol">
                {number.addComma(get(data, "totalBet", 0))} {constants.Baht}
              </span>
            </td>
  
            <td className="text-right">
              <span>
                {number.addComma(get(data, "totalHostShare", 0))} {constants.Baht}
              </span>
            </td>
            <td className="text-right">
              <span >
                {number.addComma(get(data, "totalMeShare", 0))} {constants.Baht}
              </span>
            </td>
            <td className="text-right">
              <span>
                { number.calculator(`${data.totalHostShare}+${data.totalMeShare}`) >= 0 ?       
                number.addComma(number.calculator(`${data.totalHostShare}+${data.totalMeShare}`))
              : 0} {constants.Baht}
              </span>
            </td>
            <td className="text-right paddingTableLastCol">
              <span>
                { number.calculator(`${data.totalHostShare}+${data.totalMeShare}`) < 0 ? 
                number.addComma(number.calculator(`${data.totalHostShare}+${data.totalMeShare}`))
                : 0} {constants.Baht}
              </span>
            </td>
          </tr>
        )
      });
      return this.props.betgroupIsFetching ? loadingTable : !isEmpty(mapData) ? mapData : dataEmpty
    }
  };
  renderTableMembers = () => (
    <div className="table-container">
      <div className="table-responsive-xl">
        <table className="col ">
          <thead className="">
            <tr className="tableHead">
              {constants.headTable.map((data, index: number) => (
                <th key={index} className={data.textalign} style={data.style}>
                  <span>{data.codeName}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{this.tableMembersBody()}</tbody>
        </table>
      </div>
    </div>
  );

  render() {
    const RenderTableMembers = this.renderTableMembers();
    return (
      <div className="incomeExpense-container">
          <div className="row m3-t">
            <div className="col-1">
              <Button
                id={"add-member"}
                size={"moreMediumLongHeight"}
                textSize={18}
                text={"ดูข้อมูลสรุปรายรับรายจ่าย"}
                onClick={() => {this.props.clickChangeRoute()}}
              />
            </div>
          </div>

          <div className="row ">
            <div className="col m3-t">{RenderTableMembers}</div>
          </div>
          <div className="row">
            <div className="col d-flex justify-content-end align-items-center ">
              <h5 className="rowperpageFont">{constants.rowPerPage}</h5>
              <div className="dropdown-rowPerPage ">
                <Dropdown
                  menuPlacement={"top"}
                  options={[
                    { value: 5, label: "5", disabled: false },
                    { value: 10, label: "10", disabled: false },
                    { value: 20, label: "20", disabled: false },
                    { value: 50, label: "50", disabled: false },
                    { value: 100, label: "100", disabled: false },
                  ]}
                  controlBackgroundColor={colors.PRIMARY_BG}
                  placeholder={String(this.state.currentRowPerPage)}
                  handleOnChange={(target: IOptions) => {
                    this.setState(
                      {
                        currentPage: 0,
                        currentRowPerPage: Number(target.value),
                        dataQuery: {
                          ...this.state.dataQuery,
                          page: 1,
                          limit: Number(target.value),
                        },
                      },
                      () => {
                        this.props.getBetGroup(pickBy(this.state.dataQuery));
                      }
                    );
                  }}
                />
              </div>
            </div>
            <h5 className="d-flex justify-content-end align-items-center rowperpageFont">
              {`${this.state.startPage} - ${this.state.endPage}  of ${this.props.betGroupData.totalRecord}`}
            </h5>
            <div className="col-auto">
              <Panginate
                pageTotal={this.props.betGroupData.total}
                initialPage={this.state.dataQuery.page - 1}
                pageRangeDisplayed={this.state.currentPage}
                onPageChange={this.handleClickChangePage}
              />
            </div>
          </div>
        </div>
    );
  }
}

export default incomeExpenseTable;
