import React, { Component, createRef, RefObject } from "react";
import { Input, Button, InputElements } from "components";
import "./resetPassword.style.scss";
import NewUser from "assets/images/logo/newuser.png";
import ResetPassword from "assets/images/logo/resetpassword.png";
import { FormikProps, Form } from "formik";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const constants = {
  resetPasswordButtonText: "ยืนยันเปลี่ยนรหัสผ่าน",
  newPassword: "รหัสผ่านใหม่",
};

type DefaultProps = Readonly<typeof defaultProps>;
const defaultProps: IResetPasswordComponentProps &
  IResetPasswordComponentActionProps = {};

class ResetPasswordComponent extends Component<
  FormikProps<IResetPasswordForm> &
    IResetPasswordComponentProps &
    IResetPasswordComponentActionProps &
    DefaultProps &
    RouteComponentProps<{}, {}, {}>,
  IResetPasswordComponentStates
> {
  divOTPInputRef: RefObject<HTMLDivElement> = createRef();
  otpInputRef: RefObject<HTMLInputElement> = createRef();

  static defaultProps = defaultProps;

  state: IResetPasswordComponentStates = {
    hidePass:false,
    hideConfirmPass:false
  };

  returnToLoginPage = () => {
    const { history, location } = this.props;

    history.push("/", { from: location });
  };

  renderPreRegistered = () => {
    const { errors, touched, values, handleChange, handleBlur } = this.props;
    return (
      <div className="reset-password-box-shadow d-flex flex-column align-items-center justify-content-center h-100 text-center min-vh-sm-100">
        <div className="p4-t d-flex flex-column justify-content-center h-30 p4-b">
          <img src={NewUser} alt="Reset Password Icon" className="img-fluid" />
        </div>
        <div className="body-3 p2-b">
          กรุณาเปลี่ยนรหัสผ่านใหม่ เมื่อมีการเข้าใช้ระบบในครั้งแรก
        </div>
        <div className="body-4 tertiary-text p1-b">
          เมื่อมีการเปลี่ยนรหัสผ่านใหม่ในครั้งแรกแล้วโดยทางเรามีนโยบายให้
        </div>
        <div className="body-4 tertiary-text p4-b">
          เปลี่ยนรหัสผ่านทุกๆ 30 วัน เพื่อความปลอดภัย
        </div>
        <div className="w-75 p3-b input-password">
          <InputElements
            id="password"
            name="password"
            label="password"
            placeholder="รหัสผ่านใหม่"
            value={values.password}
            onBlur={handleBlur}
            onChange={handleChange}
            error={!!errors.password && touched.password}
            errorMessage={errors.password}
            type={this.state.hidePass?"text":"password"}
            endText={<FontAwesomeIcon
              className="icon-eye"
              icon={this.state.hidePass?faEye:faEyeSlash}
              onClick={() => {
                this.setState({ hidePass: !this.state.hidePass });
              }}
            />}
          />
        </div>
        <div className="w-75 p3-b input-password">
          <InputElements
            id="confirmpassword"
            name="confirmPassword"
            label="Confirmpassword"
            placeholder="ยืนยันรหัสผ่านใหม่"
            value={values.confirmPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            error={!!errors.confirmPassword && touched.confirmPassword}
            errorMessage={errors.confirmPassword}
            type={this.state.hideConfirmPass?"text":"password"}
            endText={<FontAwesomeIcon
              className="icon-eye"
              icon={this.state.hideConfirmPass?faEye:faEyeSlash}
              onClick={() => {
                this.setState({ hideConfirmPass: !this.state.hideConfirmPass });
              }}
            />}
          />
          <h6 className="primary-red-text text-left mt-1">
            {!!errors.confirmPassword && touched.confirmPassword
              ? errors.confirmPassword
              : "\u00A0"}
          </h6>
        </div>

        <div className="w-75 p1-b">
          <Button
            id="login"
            text={constants.resetPasswordButtonText}
            buttonType="submit"
            size="large"
            transitionType="typeOne"
          />
        </div>
        <div className="p3-b">
          <Button
            id="return"
            text="กลับสู่หน้าเข้าสู่ระบบ"
            size="medium"
            textColor="#424856"
            backgroundColor="#fff"
            canHover={false}
            onClick={this.returnToLoginPage}
          />
        </div>
      </div>
    );
  };

  renderPasswordExpired = () => {
    const { errors, touched, values, handleChange, handleBlur } = this.props;

    return (
      <div className="reset-password-box-shadow d-flex flex-column align-items-center justify-content-center text-center min-vh-sm-100">
        <div className="p4-t d-flex flex-column justify-content-center p4-b">
          <img
            src={ResetPassword}
            alt="Reset Password Icon"
            className="img-fluid"
          />
        </div>
        <div className="body-3 p1-b">
          ทางเราได้มีนโยบายเปลี่ยนแปลงรหัสผ่านทุกๆ 30 วัน
        </div>
        <div className="body-3 p2-b">เพื่อความปลอดภัยในการใช้งานระบบ</div>
        <div className="body-4 tertiary-text p4-b">
          กรุณาเปลี่ยนรหัสผ่านใหม่
        </div>
        <div className="w-75 p3-b">
          <Input
            id="password"
            name="password"
            label="password"
            placeholder={constants.newPassword}
            value={values.password}
            onBlur={handleBlur}
            onChange={handleChange}
            error={!!errors.password && touched.password}
            errorMessage={errors.password}
            type="password"
          />
        </div>
        <div className="w-75 p4-b">
          <Input
            id="confirmpassword"
            name="confirmPassword"
            label="Confirmpassword"
            placeholder={constants.resetPasswordButtonText}
            value={values.confirmPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            error={!!errors.confirmPassword && touched.confirmPassword}
            errorMessage={errors.confirmPassword}
            type="password"
          />
        </div>
        <div className="w-75 p3-b">
          <Button
            id="login"
            text={constants.resetPasswordButtonText}
            buttonType="submit"
            size="large"
            transitionType="typeOne"
          />
        </div>
        <div className="">
          <Button
            id="return"
            text="กลับสู่หน้าเข้าสู่ระบบ"
            size="medium"
            textColor="#424856"
            backgroundColor="#fff"
            canHover={false}
            onClick={this.returnToLoginPage}
          />
        </div>
      </div>
    );
  };

  render() {
    const PreRegistered = this.renderPreRegistered;
    const PasswordExpired = this.renderPasswordExpired;
    return (
      <Form>
        <div className="container-fluid">
          <div className="row justify-content-center min-vh-100 secondary-bg">
            <div className="col-12 col-sm-9 col-xl-5 p-0 min-vh-sm-100 ">
              <div className="reset-password-container no-margin-left p-0 min-vh-sm-100 d-flex flex-column justify-content-center">
                <div className="reset-password-body m-0 min-vh-sm-100">
                  {this.props.status === "PRE_REGISTERED" && <PreRegistered />}
                  {this.props.status === "PASSWORD_EXPIRED" && (
                    <PasswordExpired />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

export default withRouter(ResetPasswordComponent);
