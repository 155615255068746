import { connect } from "react-redux"
import { getMe } from "reduxs/me/me/action"
import betGroup from "reduxs/lotto/bet/selector"
import lottoSelector from "reduxs/lotto/selector"
import BetMakingContainer from "./BetMaking.container"
import { modalPrintBet } from "reduxs/modalPrint/action"
import rateSelector from "reduxs/lotto/bet/rate/selector"
import { postCancelBill } from "reduxs/cancelBill/action"
import { getNumber } from "reduxs/lotto/bet/rate/number/action"
import { getDataV2LotteryList } from "reduxs/lotto/list/action"
import { onChangeIsLoaddingAction } from "reduxs/Loader/action"
import { getV2DataResult } from "reduxs/lotto/bet/result/action"
import { getGetBetGroup,postAgentBet,getAgentBet } from "reduxs/lotto/bet/bet/action"


const mapStateToProps = (state: RootReducers): INewBetMakingContainerProps => {
  const betNumberRates = rateSelector.betNumber(state)
  const lottoScheduleV2 = lottoSelector.listV2(state)
  const betGroupV2 = betGroup.betGroup(state)
  const postAgentBet = betGroup.postAgentBet(state)
  const getAgentBet = betGroup.getAgentBet(state)
  const getV2Result = betGroup.getV2Result(state)

  return {
    betNumberRatesData: betNumberRates.data,
    betNumberRatesIsFetching: betNumberRates.isFetching,
    betNumberRatesCode: betNumberRates.code,

    lottoScheduleV2Data: lottoScheduleV2.data,
    lottoScheduleV2IsFetching: lottoScheduleV2.isFetching,
    lottoScheduleV2Code: lottoScheduleV2.code,
    lottoScheduleV2Error: lottoScheduleV2.error,
    
    meData: state.me.me.data,
    betGroupV2Data: betGroupV2.data,
    betGroupV2IsFetching: betGroupV2.isFetching,
    betGroupV2Code: betGroupV2.code,
    betGroupV2Error: betGroupV2.error,

    postAgentBetData: postAgentBet.data,
    postAgentBetIsFetching: postAgentBet.isFetching,
    postAgentBetCode: postAgentBet.code,
    postAgentBetError: postAgentBet.error,

    getAgentData :getAgentBet.data,
    getAgentIsFetching :getAgentBet.isFetching,
    getAgentCode :getAgentBet.code,
    getAgentError :getAgentBet.error,

    getV2ResultData: getV2Result.data,
    getV2ResultIsFetching: getV2Result.isFetching,
    getV2ResultCode: getV2Result.code,
    getV2ResultError: getV2Result.error,

    postCancelBillCode: state.cancelBill.cancelBill.code,
    postCancelBillIsFetching : state.cancelBill.cancelBill.isFetching,
    postCancelBillError: state.cancelBill.cancelBill.error,
    
    modePrint: state.modePrint.modePrintBet.isShow,
  }
}

const mapDispatchToProps: INewBetMakingContainerActionProps = {
  getNumber,
  getDataV2LotteryList,
  getGetBetGroup,
  onChangeIsLoaddingAction,
  postAgentBet,
  getAgentBet,
  getV2DataResult,
  getMe,
  postCancelBill,
  modalPrintBet,
};
export default connect(mapStateToProps, mapDispatchToProps)(BetMakingContainer)
