import { connect } from "react-redux";
import BetSumHistoryContainer from "./BetSumHistory.container";
import { onChangeIsLoaddingAction } from "reduxs/Loader/action";
import { getAgentBet, getGetBetGroup } from "reduxs/lotto/bet/bet/action";
import betGroup from "reduxs/lotto/bet/selector";
import lottoSelector from "reduxs/lotto/selector";
import { getDataV2LotteryList } from "reduxs/lotto/list/action";
import { postCancelBill } from "reduxs/cancelBill/action";
import { getMe } from "reduxs/me/me/action";


const mapStateToProps = (state: RootReducers): IBetSumHistoryContainerProps => {
  const getAgentBet = betGroup.getAgentBet(state);
  const betGroupV2 = betGroup.betGroup(state);
  const lottoScheduleV2 = lottoSelector.listV2(state);

  return {
    getAgentData :getAgentBet.data,
    getAgentIsFetching :getAgentBet.isFetching,
    getAgentCode :getAgentBet.code,
    getAgentError :getAgentBet.error,
    meData: state.me.me.data,
    betGroupV2Data: betGroupV2.data,
    betGroupV2IsFetching: betGroupV2.isFetching,
    betGroupV2Code: betGroupV2.code,
    betGroupV2Error: betGroupV2.error,

    lottoScheduleV2Data: lottoScheduleV2.data,
    lottoScheduleV2IsFetching: lottoScheduleV2.isFetching,
    lottoScheduleV2Code: lottoScheduleV2.code,
    lottoScheduleV2Error: lottoScheduleV2.error,

    postCancelBillCode: state.cancelBill.cancelBill.code,
    postCancelBillIsFetching : state.cancelBill.cancelBill.isFetching,
    postCancelBillError: state.cancelBill.cancelBill.error,
  };
};

const mapDispatchToProps: IBetSumHistoryContainerAction = {
  onChangeIsLoaddingAction,
  getAgentBet,
  getGetBetGroup,
  getDataV2LotteryList,
  postCancelBill,
  getMe,
};

export default connect(mapStateToProps, mapDispatchToProps)(BetSumHistoryContainer);
