import { AxiosError , AxiosResponse } from 'axios'
import { createAsyncAction } from 'typesafe-actions'
import {
    CANCEL_REQUEST,
    CANCEL_SUCCESS,
    CANCEL_FAILURE,
} from './constants'

const cancelBillAction = createAsyncAction(
    CANCEL_REQUEST,
    CANCEL_SUCCESS,
    CANCEL_FAILURE,
)<any, AxiosResponse<IAPIResponse<IV2PostAgentLotteryRefund>>, AxiosError<IAPIResponse>>()

export { 
    cancelBillAction,
}