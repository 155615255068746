import { get } from 'lodash'
import { newsState,newsResState } from './constants'

const news = (state: RootReducers) : ReducerState<IPagination<INews>> =>
    get(state, 'manageNews.dataNewsMe', newsState)

const addNews = (state: RootReducers) : ReducerState<IAPIResponse<object>> =>
    get(state, 'manageNews.addNewsMe', newsResState)
const updateNews = (state: RootReducers) : ReducerState<IAPIResponse<object>> =>
    get(state, 'manageNews.updateNewsMe', newsResState)
const deleteNews = (state: RootReducers) : ReducerState<IAPIResponse<object>> =>
    get(state, 'manageNews.deleteNewsMe', newsResState)

const mainSelector = {
    news,
    addNews,
    updateNews,
    deleteNews
}
export default mainSelector