import { AxiosError, AxiosResponse } from "axios";
import { get, isEmpty } from "lodash";
import { responseMessage } from "../constants/response";

const constants = {
  defaultError: "กรุณาลองใหม่อีกครั้ง",
};

const getMessage = (
  response: AxiosError<IAPIResponse> | AxiosResponse<IAPIResponse>
): IResponseConverter => {
  if (isEmpty(get(response, "response", {}))) {
    // Succes case
    const axiosResponse = response as AxiosResponse<IAPIResponse>;
    const messageCode: number = get(
      axiosResponse,
      "data.code",
      get(response, "status", 0)
    );
    const code: number = get(response, "data.code", get(response, "status", 0));
    return {
      code,
      message: get(responseMessage(), messageCode, constants.defaultError),
    };
  } else {
    // Error case
    const axiosResponse = response as AxiosError<IAPIResponse>;
    const messageCode: number = get(
      axiosResponse,
      "response.data.code",
      get(response, "response.status", Number(get(response, "code", 0)))
    );
    const code: number = get(
      response,
      "response.status",
      Number(get(response, "code", 0))
    );
    const devMessage = get(axiosResponse, "request.response.devMessage", "")
    let formattedDevMessage = ""
    if (code === 401144) {
      (devMessage as string[]).forEach((val) => {
        const lotteryName = val.split(" ")[5] as TLottoType
        const betType = val.split(" ")[3] as TBetResultLowerCase
        formattedDevMessage += get(responseMessage(lotteryName, betType), messageCode, constants.defaultError)
      })
    } else {
      formattedDevMessage = get(responseMessage(), messageCode, constants.defaultError)
    }
    return {
      code,
      message: formattedDevMessage,
    };
  }
};

const responseConverter = { getMessage };
export default responseConverter;
