import { connect } from "react-redux";
import UserDetailNoteContainer from "./UserDetailNote.container";
import { postOwnerUpdate } from "reduxs/owner/update/action";
import { getMe } from "reduxs/me/me/action";
import { getMeSetting } from "reduxs/me/setting/action";
import { postOwnerSetting } from "reduxs/owner/setting/action";
import { postOwnerSettingLotteryUpdate } from "reduxs/owner/settingLotteryUpdate/action";
import { getMeSettingLottery } from "reduxs/me/settingLottery/action";
import { onChangeIsLoaddingAction } from "../../reduxs/Loader/action";
import { getHost } from "reduxs/host/host/action";
import { getFinanceHostMe } from "reduxs/finance/host/action";
import { getFinanceChildMe } from "../../reduxs/finance/childMe/action";
import { getCustomerNote } from "reduxs/customer/action";
import customerSelector from "reduxs/customer/selector";

const mapStateToProps = (state: RootReducers): IUserDetailNoteProps => {
  const customerNoteList = customerSelector.customerNoteList(state);

  return {
    customerNoteCode: customerNoteList.code,
    customerNoteError: customerNoteList.error,
    customerNoteIsFetching: customerNoteList.isFetching,
    customerNoteData: customerNoteList.data,

    financeChildMeCode: state.finance.financeChildMe.code,
    financeChildMeError: state.finance.financeChildMe.error,
    financeChildMeIsFetching: state.finance.financeChildMe.isFetching,
    financeChildMeData: state.finance.financeChildMe.data,

    hostMeCode: state.finance.financeHostMe.code,
    hostMeError: state.finance.financeHostMe.error,
    hostMeIsFetching: state.finance.financeHostMe.isFetching,
    hostMeData: state.finance.financeHostMe.data,

    meData: state.me.me.data,
    meIsFetching: state.me.me.isFetching,
    
    ownerUpdateIsFetching: state.owner.update.isFetching,
    ownerUpdateCode: state.owner.update.code,
    ownerUpdateError: state.owner.update.error,

    meSettingCode: state.me.setting.code,
    meSettingError: state.me.setting.error,
    meSettingIsFetching: state.me.setting.isFetching,
    meSettingData: state.me.setting.data,

    ownerSettingIsFetching: state.owner.setting.isFetching,
    ownerSettingCode: state.owner.setting.code,
    ownerSettingError: state.owner.setting.error,

    ownerSettingLotteryUpdateIsFetching:
      state.owner.settingLotteryUpdate.isFetching,
    ownerSettingLotteryUpdateCode: state.owner.settingLotteryUpdate.code,
    ownerSettingLotteryUpdateError: state.owner.settingLotteryUpdate.error,

    meSettingLotteryCode: state.me.settingLottery.code,
    meSettingLotteryError: state.me.settingLottery.error,
    meSettingLotteryIsFetching: state.me.settingLottery.isFetching,
    meSettingLotteryData: state.me.settingLottery.data,

    hostCode: state.host.host.code,
    hostError: state.host.host.error,
    hostIsFetching: state.host.host.isFetching,
    hostData: state.host.host.data,
  };
};

const mapDispatchToProps: IUserDetailNoteActionProps = {
  getCustomerNote,
  getFinanceChildMe,
  getFinanceHostMe: getFinanceHostMe,
  onChangeIsLoaddingAction,
  postOwnerUpdate,
  getMe,
  getMeSetting,
  postOwnerSetting,
  postOwnerSettingLotteryUpdate,
  getMeSettingLottery,
  getHost,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDetailNoteContainer);
