import React, { Component } from "react"
import { News } from "./components/News"
import { responseCode } from "constants/response"
import { number, handleOnClickBreadcrumb } from "utils"
import { Link, RouteComponentProps } from "react-router-dom"
import {
  LabelBox,
  Button,
  Breadcrumb,
  ErrorModal,
  Panginate,
} from "components"
import confirmModal from "components/Modal/components/Confirm/Confirm.component"

const constants = {
  title: "ภาพรวม",
  description: "ข้อมูลทั่วไป",
  infoUser: {
    name: "ข้อมูลผู้ใช้",
    btnChangePassword: "เปลี่ยนรหัสผ่าน",
    btnManageNews: "จัดการข่าวสาร",
    username: "รหัสผู้ใช้",
    rankUser: "ระดับผู้ใช้",
    totalMembers: "จำนวนสมาชิกทั้งหมด",
  },
  infoCredits: {
    name: "ข้อมูลการเงิน",
    btnTransfer: "โอนยอด",
    currency: "สกุลเงิน",
    totalCredits: "เครดิตรวมสมาชิก",
    balanceCredits: "เครดิตคงเหลือ",
    balanceMember: "ยอดสมาชิก",
    betAmount: "ยอดเดิมพันคงค้าง",
    limitedCredit: "เครดิตจำกัด",
    betMaxMin: "การเดิมพันต่ำสุด/สูงสุด",
  },
  news: "ข่าวสารจากระบบ",
  breadcrumb: {
    main: "ภาพรวม",
    generalInfo: "ข้อมูลทั่วไป"
  },
}

const defaultProps: IMainProps & IMainActionProps = {
  getDataMain() { },
  getDataNews() { },
  onChangeIsLoaddingAction() { },
  mainCode: 0,
  mainError: "",
  mainIsFetching: false,
  mainData: {
    username: "",
    permission: "MEMBER",
    line: "",
    status: "",
    childCount: 0,
    currency: "THB",
    betResultTotal: 0,
    betWaitTotal: 0,
    maxBet: 0,
    minBet: 0,
    childCreditTotal: 0,
    credit: 0,
    creditLimit: "",
  },
  newsCode: 0,
  newsError: "",
  newsIsFetching: false,
  newsData: {
    totalRecord:0,
    page: 1,
    limit: 5,
    total: 0,
    dataList: [],
  },
}

class MainContainer extends Component<
  IMainProps & IMainActionProps & RouteComponentProps
> {
  static defaultProps = defaultProps
  state: IMainDataState = {
    news: [],
    currentPage: { selected: 1 },
    pageNews: {
      page: 1,
      limit: 5,
    },
  }

  componentDidMount() {
    /* this.props.getDataMain() */
    confirmModal.hide();
  }

  componentDidUpdate(prevProps: IMainProps) {
    if (this.props.mainIsFetching || this.props.newsIsFetching) {
      this.props.onChangeIsLoaddingAction(true)
    } else {
      this.props.onChangeIsLoaddingAction(false)
    }

    if (
      prevProps.mainIsFetching !== this.props.mainIsFetching &&
      !this.props.mainIsFetching
    ) {
      if (this.props.mainCode !== responseCode.OK) {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: this.props.mainError,
        })
      }
    }

    if (
      prevProps.newsIsFetching !== this.props.newsIsFetching &&
      !this.props.newsIsFetching
    ) {
      if (this.props.newsCode === responseCode.OK) {
        if (prevProps.newsData !== this.props.newsData) {
          this.setState({ news: this.props.newsData.dataList })
        }
      } else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: this.props.mainError,
        })
      }
    }
  }

  handleClickChangePage = ({ selected: selectedPage }: ISelectPage) => {
    this.setState({
      currentPage: { selected: selectedPage },
      pageNews: {
        ...this.state.pageNews,
        page: this.state.currentPage.selected + 1,
      },
    })
    // this.props.getDataNews(this.state.pageNews)
  }

  navigateToResetPassword = () => {
    this.props.history.push("/resetUserPassword")
  }
  navigateToCredits = () => {
    this.props.history.push("/transferCredits")
  }

  renderUserInfo = () => (
    <div className="box-shadow">
      <div className="inline pr-3 paper-body-title">
        {constants.infoUser.name}
      </div>
      <div className="inline paper-btn-title">
        <Button
          id="profile"
          text={constants.infoUser.btnChangePassword}
          onClick={this.navigateToResetPassword}
        />
      </div>

      <div className="row">
        <div className="col-12">
          <LabelBox
            textLabel={constants.infoUser.username}
            textValue={this.props.mainData.username}
          />
        </div>
        <div className="col-12">
          <LabelBox
            textLabel={constants.infoUser.rankUser}
            textValue={this.props.mainData.permission}
          />
        </div>
        <div className="col-12">
          <LabelBox
            textLabel={constants.infoUser.totalMembers}
            textValue={number.numberFormat(
              String(this.props.mainData.childCount)
            )}
          />
        </div>
      </div>
    </div>
  )

  renderFinancialInfo = () => (
    <div className="box-shadow">
      <div className="inline pr-3 paper-body-title">
        {constants.infoCredits.name}
      </div>
      <div className="inline paper-btn-title">
        <Button
          id="finance"
          text={constants.infoCredits.btnTransfer}
          onClick={this.navigateToCredits}
        />
      </div>

      <div className="row">
        <div className="col-4">
          <LabelBox
            textLabel={constants.infoCredits.currency}
            textValue={this.props.mainData.currency}
          />
        </div>
        <div className="col-4">
          <LabelBox
            textLabel={constants.infoCredits.totalCredits}
            textValue={number.decimalsFormatComma(
              String(this.props.mainData.childCreditTotal)
            )}
          />
        </div>
        <div className="col-4">
          <LabelBox
            textLabel={constants.infoCredits.balanceCredits}
            textValue={number.decimalsFormatComma(
              String(this.props.mainData.credit)
            )}
          />
        </div>
        <div className="col-4">
          <LabelBox
            textLabel={constants.infoCredits.balanceMember}
            textValue={number.numberFormat(
              String(this.props.mainData.betResultTotal)
            )}
          />
        </div>
        <div className="col-4">
          <LabelBox
            textLabel={constants.infoCredits.betAmount}
            textValue={number.decimalsFormatComma(
              String(this.props.mainData.betWaitTotal)
            )}
          />
        </div>
        <div className="col-4">
          <LabelBox
            textLabel={constants.infoCredits.limitedCredit}
            textValue={number.decimalsFormatComma(
              String(this.props.mainData.creditLimit)
            )}
          />
        </div>
        <div className="col-4">
          <LabelBox
            textLabel={constants.infoCredits.betMaxMin}
            textValue={`${number.decimalsFormatComma(
              String(this.props.mainData.minBet)
            )} / ${number.decimalsFormatComma(
              String(this.props.mainData.maxBet)
            )}`}
          />
        </div>
      </div>
    </div>
  )

  renderNews = () => (
    <div className="col">
      <div className="box-shadow">
        <div className="inline pr-3 paper-body-title">
          {constants.news}
        </div>
        {this.props.mainData.permission !== "MEMBER" && (<div className="inline paper-btn-title">
          <Link to="/manageNews">
            <Button
              id="profile"
              text={constants.infoUser.btnManageNews}
            />
          </Link>
        </div>)}
        <News news={this.state.news} />
        <div className="row">
          <div className="col-12">
            <Panginate
              pageTotal={this.props.newsData.total}
              initialPage={this.state.pageNews.page - 1}
              pageRangeDisplayed={this.state.currentPage.selected}
              onPageChange={this.handleClickChangePage}
            />
          </div>
        </div>
      </div>
    </div>
  )

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.breadcrumb.main, active: false },
      { label: constants.breadcrumb.generalInfo, active: true },
    ]
    const RenderUserInfo = this.renderUserInfo()
    const RenderFinancialInfo = this.renderFinancialInfo()
    const RenderNews = this.renderNews()
    return (
      <>
        <div className="paper-container main-container">
          <div className="row">
            <div className="col">
              <div className="sub-menu">
                <Breadcrumb
                  items={navigates}
                  handleOnClickItem={handleOnClickBreadcrumb}
                />
              </div>
            </div>
          </div>
          <div className="paper-title">{constants.title}</div>
          <div className="paper-description">{constants.description}</div>
          <div className="paper-body">
            <div className="row">
              <div className="col-4 my-3">
                {RenderUserInfo}
              </div>
              <div className="col-8 my-3">
                {RenderFinancialInfo}
              </div>
            </div>
            <div className="row mt-4">
              {RenderNews}
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default MainContainer
