import axios from "axios";
import thunk from "redux-thunk";
import rootReducers from "../reduxs/reducers";
import { persistStore } from "redux-persist";
import axiosMiddleware from "../middleware/axios";
import environment from "../constants/environment";
import { createStore, applyMiddleware, Store } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import socketMiddleware from "middleware/socket";

export const history = createBrowserHistory();

const enhancer = [
  axiosMiddleware,
  thunk.withExtraArgument(axios),
  socketMiddleware,
];

const composedEnhancer =
  environment.name !== "production"
    ? composeWithDevTools(
        applyMiddleware(routerMiddleware(history), ...enhancer)
      )
    : applyMiddleware(routerMiddleware(history), ...enhancer);

export default function configureStore(initialState?: RootReducers) {
  //@ts-ignore
  const store: Store<RootReducers> = createStore(rootReducers, {}, composedEnhancer);
  const persistor = persistStore(store);
  return { store, persistor };
}
