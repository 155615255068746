import { Breadcrumb, Button, Collapse, LottoCard } from "components";
import errorModal from "components/Modal/components/Error/Error.component";
import color from "constants/colors";
import { responseCode } from "constants/response";
import {
  CODE_TO_TYPENAME,
  LOTTO_FLAG_ALPHA,
  LOTTO_GAME_TYPE_NAME,
  LOTTO_SLUG_NAME,
  LOTTO_SLUG_TO_TYPE,
  TRANSACTION_STATUS,
} from "constants/variables";
import { get, isEmpty, map, noop } from "lodash";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { number } from "utils";
import { format } from "date-fns";

import LottoFlags from "assets/images/global/flags";
import "./betResult.style.scss";
import { th } from "date-fns/locale";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const defaultProps: IBetResultContainerAction & IBetResultContainerProps = {
  lottoGameCode: 0,
  lottoGameError: "",
  lottoGameIsFetching: false,
  betHistoryLists: {},
  getBetHistoryListsCode: 0,
  getBetHistoryListsError: "",
  getBetHistoryListsIsFetching: false,
  betResultLists: [],
  getBetResultCode: 0,
  getBetResultError: "",
  gameHistoryData: [],
  getBetResultIsFetching: false,
  gameHistoryCode: 0,
  gameHistoryError: "",
  gameHistoryIsFetching: false,
  getGame() {
    noop();
  },
  getBetHistoryLists() {
    noop();
  },
  getBetResultLists() {
    noop();
  },
  postYegeeGameHistory() {
    noop();
  },
  clearYegeeGameHistory() {
    noop();
  },
  lottoGameData: {
    createdAt: "",
    endTime: "",
    id: 0,
    round: "",
    startTime: "",
    status: "UNKNOWN",
  },
};

type DefaultProps = Readonly<typeof defaultProps>;

const constants = {
  page: {
    title: "การเดิมพัน",
    titlePath: "เดิมพัน",
    subTitle: (lottoType: TLottoSlug) => `แทง${LOTTO_SLUG_NAME[lottoType]}`,
  },
  bet: {
    betResultTitle: "ผลหวย",
    betTotalTitle: "รายการบิลทั้งหมด",
    betProcessing: "กำลังประมวลผล...",
    betToTotalBalance: "กำไร/ขาดทุน",
    betOf: (name: string) => `โพยของ ${name}`,
    buttonPrint: "ออกใบเสร็จ",
  },
  dataNotFound: "ไม่พบข้อมูล...",
  gameHistoryTitle: "สถิติการออกย้อนหลัง",
};

const emptyResult = [
  {
    type: "THREE_UP",
    number: "XXX",
  },
  {
    type: "TWO_DOWN",
    number: "XX",
  },
  {
    type: "TWO_UP",
    number: "XXX",
  },
];

const tableTitleName: { [name in IBetResultTableTitle]: string } = {
  indexList: "รายการ",
  betResult: "ผลเดิมพัน",
};

export default class BetResultContainer extends Component<
  DefaultProps &
    RouteComponentProps<
      { type: TLottoSlug },
      {},
      IBetMakingContainerRouteProps
    >,
  IBetResultContainerState
> {
  state: IBetResultContainerState = {
    createKeys: {},
    rowCollapse: {},
    gameHistoryIsCollapse: false,
    currentPage: { selected: 1 },
    dataList: [],
    page: {
      type: "",
      search: "",
      round: "",
      page: 1,
      limit: 1000000,
    },
    queryBetResult: {
      date: format(new Date(), "ddMMyyyy"),
      round: "001",
      type: "LOTTER_GOVN",
    },
    betResultData: [],
    lottoCard: {
      id: 0,
      code: "LOTTER_GOVN",
      mode: "",
      status: "OPEN",
      startTime: "",
      endTime: "",
      updatedAt: "",
      isCountingDown: true,
      closedStatusText: "",
      waitingStatusText: "",
      openedStatusText: "",
      description: "",
      subtitle: "",
      backgroundColor: color.TERTIARY_GREEN,
    },
    queryTimer: setTimeout(() => {noop()}, 0),
  };

  componentDidMount() {
    this.setState(
      (prevState, props) => ({
        lottoCard: {
          ...get(props.location.state, "targetLotto", prevState.lottoCard),
        },
        queryBetResult: {
          ...prevState.queryBetResult,
          type: props.location.state.targetLotto!.code!,
          round: number.padNumber(
            props.location.state.targetLotto!.round!
              ? props.location.state.targetLotto!.round!
              : "0",
            3
          ),
          date: `${prevState.queryBetResult.date}%${number.padNumber(
            props.location.state.targetLotto!.round!
              ? props.location.state.targetLotto!.round!
              : "0",
            3
          )}`,
        },
        page: {
          ...prevState.page,
          round: props.location.state.targetLotto!.round!,
          type: props.location.state.targetLotto!.code!,
        },
      }),
      () => {
        this.props.getBetResultLists(this.state.queryBetResult);
        this.props.getBetHistoryLists(this.state.page);
        this.props.getGame(
          this.state.queryBetResult.type,
          this.state.queryBetResult.date,
          this.state.queryBetResult.round
        );
        if (this.state.lottoCard.code === "LOTTER_YEGEE") {
          this.props.postYegeeGameHistory({
            day: "5",
            round: this.state.queryBetResult.round,
          });
        }
      }
    );
  }

  componentDidUpdate(prevProps: IBetResultContainerProps) {
    if (
      prevProps.getBetHistoryListsIsFetching !==
        this.props.getBetHistoryListsIsFetching &&
      !this.props.getBetHistoryListsIsFetching
    ) {
      if (this.props.getBetHistoryListsCode === responseCode.OK) {
        this.setState(
          (_, props) => ({
            dataList: props.betHistoryLists.dataList,
          }),
          () => {
            this.createDataComponent(this.state.dataList);
          }
        );
      } else {
        errorModal.show({
          description: this.props.getBetHistoryListsError,
          action: errorModal.hide,
        });
      }
    }
    if (
      prevProps.getBetResultIsFetching !== this.props.getBetResultIsFetching &&
      !this.props.getBetResultIsFetching
    ) {
      if (this.props.getBetResultCode === responseCode.OK) {
        clearTimeout(this.state.queryTimer)
       if(isEmpty(this.props.betResultLists)) {
         this.setState(_ => ({
           queryTimer: setInterval(() => {
            this.props.getBetResultLists(this.state.queryBetResult);
           }, 120000)
         }))
       } else {
         this.setState((_, props) => ({
           betResultData: [...props.betResultLists],
         }));
       }
      } else {
        errorModal.show({
          description: this.props.getBetResultError,
          action: errorModal.hide,
        });
      }
    }
    if (
      prevProps.lottoGameIsFetching !== this.props.lottoGameIsFetching &&
      !this.props.lottoGameIsFetching
    ) {
      if (this.props.lottoGameCode === responseCode.OK) {

      } else {
        errorModal.show({
          description: this.props.lottoGameError,
          action: errorModal.hide,
        });
      }
    }
  }

  numbersComponent = (numberString: string, type: TLottoGameType) => {
    return () => (
      <div className="d-flex flex-column flex align-items-center justify-content-center">
        <h6 className="primary-green-text">{CODE_TO_TYPENAME[type]}</h6>
        <h3>{numberString}</h3>
      </div>
    );
  };

  createDataComponent = (data: IResponseAgentBetLotteryHistoryList[]) => {
    const createKeys: {
      [type in string]: { balance: number; betList: IAgentLotteryBetTx[] };
    } = {};
    const initialKey: { [key in number]: boolean } = {};
    data.forEach((data) => {
      data.lottoBetList.forEach((lottoBets) => {
        if (lottoBets.status === "WINNER") {
          if (!(lottoBets.remark in createKeys))
            createKeys[lottoBets.remark] = {
              balance: 0,
              betList: [],
            };
          createKeys[lottoBets.remark].balance -=
            lottoBets.result - lottoBets.value;
          createKeys[lottoBets.remark].betList.push(lottoBets);
        } else if (lottoBets.status === "LOSER") {
          if (!(lottoBets.remark in createKeys))
            createKeys[lottoBets.remark] = {
              balance: 0,
              betList: [],
            };
          createKeys[lottoBets.remark].balance += lottoBets.value;
          createKeys[lottoBets.remark].betList.push(lottoBets);
        } else {
          if (!(lottoBets.remark in createKeys))
            createKeys[lottoBets.remark] = {
              balance: 0,
              betList: [],
            };
          createKeys[lottoBets.remark].betList.push(lottoBets);
        }
      });
    });
    Object.keys(createKeys).forEach((_, index) => (initialKey[index] = true));
    return this.setState({ rowCollapse: initialKey, createKeys: createKeys });
  };

  rowCollapseHandler = (index: number) =>
    this.setState((ps) => ({
      rowCollapse: { ...ps.rowCollapse, [index]: !ps.rowCollapse[index] },
    }));

  renderHistoryGameComponent = () =>
    this.props.gameHistoryData.map(
      (game: IYegeeGameHistory, historyIndex: number) => {
        const NumberThreeUp = this.numbersComponent(game.threeUp, "THREE_UP");
        const NumberTwoUp = this.numbersComponent(game.twoUp, "TWO_UP");
        const NumberTwoDown = this.numbersComponent(game.twoDown, "TWO_DOWN");
        return (
          <div className={`row mt-4`} key={`game-history-${historyIndex}`}>
            <div className="col">
              <div className="p1-b">
                <h5 className="flex">
                  {"วันที่"}{" "}
                  {format(new Date(game.createdAt), "d MMM", { locale: th })}
                </h5>
              </div>
              <div className="d-flex flex-row m1-b">
                <NumberThreeUp />
                <NumberTwoUp />
                <NumberTwoDown />
              </div>
              <div className="d-flex flex-column m1-t">
                <div className="d-flex flex-row">
                  <h6 className="flex text-center tertiary-text">
                    {"อันดับรายได้ของผู้ที่ชนะ"}
                  </h6>
                </div>
              </div>
              <div className="d-flex flex-column">
                <div className="d-flex flex-row">
                  <h6 className="flex text-center">
                    <span className="tertiary-text">1:</span>{" "}
                    {game.totalOneBetResult}
                  </h6>
                  <h6 className="flex text-center">
                    <span className="tertiary-text">2:</span>{" "}
                    {game.totalTwoBetResult}
                  </h6>
                  <h6 className="flex text-center">
                    <span className="tertiary-text">3:</span>{" "}
                    {game.totalThreeBetResult}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        );
      }
    );

  renderTableTitle = (data: {}) =>
    map(data, (dataList, index: IBetResultTableTitle) => (
      <th
        className={`${index !== "indexList" ? "text-right" : "text-left"}`}
        key={`key-${index}`}
        id={index}
      >
        <div className="body-1 p2-y p2-x">{dataList}</div>
      </th>
    ));

  renderTableData = () => {
    const renderData = map(
      Object.keys(this.state.createKeys),
      (keys, index) => {
        return (
          <React.Fragment key={`${index}-${keys}`}>
            <tr
              id={`${keys}`}
              className="color-row header-column can-click"
              onClick={() => this.rowCollapseHandler(index)}
            >
              <td className="p2-x">{constants.bet.betOf(keys)}</td>
              <td className="text-right p2-x align-top">
                <div className="d-flex in-line justify-content-end align-items-baseline">
                  <div className="p1-r">
                    {number.castToMoney(this.state.createKeys[keys].balance)}
                  </div>
                  <div
                    className={`chevron-right-icon ${
                      this.state.rowCollapse[index] ? "expanded" : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={2} className={``}>
                {map(this.state.createKeys[keys].betList, (dataObj, objKey) => (
                  <div
                    key={`bet-List${objKey}`}
                    className={`row hidden-row ${
                      this.state.rowCollapse[index] && "expanded p1-t"
                    }`}
                  >
                    <div className="col-2 text-center font-weight-bold">
                      {dataObj.number}
                    </div>
                    <div className="col-2">
                      {LOTTO_GAME_TYPE_NAME[dataObj.type]}
                    </div>
                    <div className="col-2">
                      {number.castToMoney(dataObj.value)}
                    </div>
                    <div className="col-2 text-muted">
                      {number.castToMoney(dataObj.rate)}
                    </div>
                    <div className="col-2">
                      {number.castToMoney(dataObj.result)}
                    </div>
                    <div
                      className={`col-2 text-right pr-4
                  ${dataObj.status === "WINNER" ? "text-danger" : ""}
                  ${dataObj.status === "LOSER" ? "text-success" : ""}
                  ${dataObj.status === "WAIT" ? "text-warning" : ""}
                  }
                  `}
                    >
                      {`${TRANSACTION_STATUS[dataObj.status]} `}
                    </div>
                  </div>
                ))}
              </td>
            </tr>
            <tr>
              <td>{""}</td>
              <td colSpan={1} className={``}>
                <div
                  className={`hidden-row col-6 float-right ${
                    this.state.rowCollapse[index] && "expanded p1-y pr-2"
                  }`}
                >
                  <Button
                    id={`button-${index}`}
                    text={constants.bet.buttonPrint}
                    buttonType={"submit"}
                  />
                </div>
              </td>
            </tr>
          </React.Fragment>
        );
      }
    );

    const emptyData = (
      <tr>
        <td className="text-center color-row p-2" colSpan={7}>
          {constants.dataNotFound}
        </td>
      </tr>
    );
    return !isEmpty(get(this.state.dataList[0], "lottoBetList", []))
      ? renderData
      : emptyData;
  };

  renderBetResultCard = (data: IResponseLudensBetResult[]) => {
    return !isEmpty(data)
      ? map(data, (dataList, index) => (
          <div
            className={`${index % 3 === 0 ? "col-12" : "col-6"} p4-b`}
            key={`${index}-${dataList.id}`}
          >
            <div className="title">
              {LOTTO_GAME_TYPE_NAME[dataList.valueType as TLottoGameType]}
            </div>
            <div className="number">{get(dataList, "value", "XXX")}</div>
          </div>
        ))
      : map(emptyResult, (dataList, index) => (
          <div
            className={`${index % 3 === 0 ? "col-12" : "col-6"} p4-b`}
            key={`${index}-MOCK`}
          >
            <div className="title">
              {LOTTO_GAME_TYPE_NAME[dataList.type as TLottoGameType]}
            </div>
            <div className="number">{dataList.number}</div>
          </div>
        ));
  };

  renderBetResultElement = (
    data: IResponseAgentBetLotteryHistoryList[],
    betResult: IResponseLudensBetResult[]
  ) => {
    const RenterTableTitle = this.renderTableTitle(tableTitleName);
    const RenderTableData = this.renderTableData();
    const RenderBetResult = this.renderBetResultCard(betResult);
    const RenderGameHistory = this.renderHistoryGameComponent();
    const resultBet = (
      <div className="row">
        <div className="col-4 px-0">
          <div className="col-12">
            <div className="box-shadow">
              <div className="paper-title pt-0 mt-0">
                {constants.bet.betResultTitle}
              </div>
              <div className="p1-b pt-5">
                <div className="row result-number">{RenderBetResult}</div>
              </div>
            </div>
          </div>
          {this.state.lottoCard.code === "LOTTER_YEGEE" && (
            <div className="col-12">
              <div className="paper-body">
                <div className="box-shadow history-yegee">
                  <Collapse
                    isExpand={this.state.gameHistoryIsCollapse}
                    hiddenOverflow
                    onClick={() =>
                      this.setState((ps) => ({
                        gameHistoryIsCollapse: !ps.gameHistoryIsCollapse,
                      }))
                    }
                    name={constants.gameHistoryTitle}
                  >
                    {RenderGameHistory}
                  </Collapse>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="col-8 px-0">
          <div className="col-12">
            <div className="box-shadow">
              <div className="paper-title pt-0 mt-0">
                {constants.bet.betTotalTitle}
              </div>
              <div className="font-weight-bolder pt-0 mt-0 float-right">
                {constants.bet.betToTotalBalance}
              </div>
              <br />
              <div className="font-weight-bolder text-danger pt-0 mt-0 float-right">
                {number.castToMoney(get(data[0], "balance", 0))}
              </div>
              <div className="row mt-5 d-flex">
                <div className="col">
                  <div className="table-container">
                    <div className="table-responsive-xl tableFixHead">
                      <table className="table ">
                        <thead>
                          <tr>{RenterTableTitle}</tr>
                        </thead>
                        <tbody>{RenderTableData}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    return resultBet;
  };

  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.replace(path);
  };
  componentWillUnmount() {
    this.props.clearYegeeGameHistory();
  }
  render() {
    const { lottoCard, dataList, betResultData } = this.state;
    const flagIcon =
      LottoFlags[LOTTO_FLAG_ALPHA[LOTTO_SLUG_TO_TYPE[lottoCard.code]]].Icon;
    const navigates: IBreadcrumbItem[] = [
      { label: constants.page.title, active: false, path: `/bet` },
      { label: constants.page.titlePath, active: false, path: `/bet` },
      {
        label: constants.page.subTitle(lottoCard.code),
        active: true,
      },
    ];
    const RenderBetResultElement = this.renderBetResultElement(
      dataList,
      betResultData
    );
    return (
      <div className="paper-container">
        <div className="col">
          <div className="bet-result-container">
            <div className="row">
              <div className="col">
                <div className="sub-menu">
                  <Breadcrumb
                    items={navigates}
                    handleOnClickItem={this.handleOnClickBreadcrumb}
                  />
                </div>
              </div>
            </div>
            <div className="paper-title">{constants.page.title}</div>
            <div className="inline pr-3 paper-description">
              {constants.page.subTitle(lottoCard.code)}
            </div>
            <div className="paper-body">
              <div className="row m4-t">
                <div className="col-12 px-0">
                  <div className="col-8 col-lg-6 col-xl-4 m3-t">
                    <LottoCard
                      slug={lottoCard!.code}
                      id={`lotto-${lottoCard!.code}`}
                      title={
                        lottoCard!.code === "LOTTER_YEGEE"
                          ? `${LOTTO_SLUG_NAME[lottoCard!.code]} \t ${
                              lottoCard!.yegeeRound
                            }`
                          : LOTTO_SLUG_NAME[lottoCard!.code]
                      }
                      subtitle={lottoCard!.subtitle!}
                      backgroundColor={lottoCard!.backgroundColor}
                      status={this.props.lottoGameData.status}
                      isCountingdown={lottoCard!.isCountingDown}
                      closedStatusText={lottoCard!.closedStatusText}
                      waitingStatusText={lottoCard!.waitingStatusText}
                      openedStatusText={lottoCard!.openedStatusText}
                      description={lottoCard!.description}
                      expire={this.props.lottoGameData.endTime}
                      start={this.props.lottoGameData.startTime}
                      icon={flagIcon}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3">{RenderBetResultElement}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
