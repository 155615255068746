export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const LOGIN_REQUEST_INITIAL_PASSWORD = "LOGIN_REQUEST_INITIAL_PASSWORD";
export const LOGIN_SUCCESS_INITIAL_PASSWORD = "LOGIN_SUCCESS_INITIAL_PASSWORD";
export const LOGIN_FAILURE_INITIAL_PASSWORD = "LOGIN_FAILURE_INITIAL_PASSWORD";

export const LOGIN_REQUEST_PASSWORD = "LOGIN_REQUEST_PASSWORD";
export const LOGIN_SUCCESS_PASSWORD = "LOGIN_SUCCESS_PASSWORD";
export const LOGIN_FAILURE_PASSWORD = "LOGIN_FAILURE_PASSWORD";

export const CREATE_OTP_REQUEST = "CREATE_OTP_REQUEST";
export const CREATE_OTP_SUCESS = "CREATE_OTP_SUCESS";
export const CREATE_OTP_FAILURE = "CREATE_OTP_FAILURE";

export const SET_AUTHENTINATION_KEY_REQUEST = "SET_AUTHENTINATION_KEY_REQUEST";
export const SET_AUTHENTINATION_KEY_SUCCESS = "SET_AUTHENTINATION_KEY_SUCCESS";
export const SET_AUTHENTINATION_KEY_FAILURE = "SET_AUTHENTINATION_KEY_FAILURE";

export const RESET_LOGIN = "RESET_LOGIN";

export const RESET_AUTHENTICATION_KEY = "RESET_AUTHENTICATION_KEY";

export const RESET_OTP = "RESET_OTP";

export const SET_PASSWORD_EXPIRED_REQUEST = "SET_PASSWORD_EXPIRED_REQUEST";
export const SET_PASSWORD_EXPIRED_SUCCESS = "SET_PASSWORD_EXPIRED_SUCCESS";
export const SET_PASSWORD_EXPIRED_FAILURE = "SET_PASSWORD_EXPIRED_FAILURE";

export const REGISTER_2FA_REQUEST = "REGISTER_2FA_REQUEST";
export const REGISTER_2FA_SUCCESS = "REGISTER_2FA_SUCCESS";
export const REGISTER_2FA_FAILURE = "REGISTER_2FA_FAILURE";

export const VERIFY_2FA_REQUEST = "VERIFY_2FA_REQUEST";
export const VERIFY_2FA_SUCCESS = "VERIFY_2FA_SUCCESS";
export const VERIFY_2FA_FAILURE = "VERIFY_2FA_FAILURE";

export const VALIDATE_2FA_REQUEST = "VALIDATE_2FA_REQUEST";
export const VALIDATE_2FA_SUCCESS = "VALIDATE_2FA_SUCCESS";
export const VALIDATE_2FA_FAILURE = "VALIDATE_2FA_FAILURE";

export const loginState: ReducerState<IResponseAgentLogin> = {
  isFetching: false,
  code: 0,
  data: {
    token: "",
    status: "UNKNOWN",
    verify: false,
  },
  error: "",
};

export const logoutState: ReducerState<ILogout> = {
  isFetching: false,
  code: 0,
  data: {},
  error: "",
};

export const tokenState: IToken = {
  accessToken: "",
  refreshToken: "",
};
