import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { postMakeAction } from "../actionCreator";
import { fetchPostMake } from "../services";

const postMake = (postData: IAgentBet[][]) => async (dispatch: Dispatch) => {
  dispatch(postMakeAction.request({}));
  return Promise.all(fetchPostMake(postData))
    .then((responses: AxiosResponse<IAPIResponse<any>>[]) => {
      dispatch(postMakeAction.success(responses[0]));
    })
    .catch((error: AxiosError<IAPIResponse>) => {
      dispatch(postMakeAction.failure(error));
    });
};

export { postMake };
