import { connect } from "react-redux";
import BetMeHistoryContainer from "./BetMeHistory.container";
import { onChangeIsLoaddingAction } from "reduxs/Loader/action";
import { getAgentBet } from "reduxs/lotto/bet/bet/action";
import betGroup from "reduxs/lotto/bet/selector";
import lottoSelector from "reduxs/lotto/selector";
import { getDataV2LotteryList } from "reduxs/lotto/list/action";
import { postCancelBill } from "reduxs/cancelBill/action";
import { getMe } from "reduxs/me/me/action"
const mapStateToProps = (state: RootReducers): IBetMeHistoryContainerProps => {
  const getAgentBet = betGroup.getAgentBet(state);
  const lottoScheduleV2 = lottoSelector.listV2(state);
  return {
    getAgentData :getAgentBet.data,
    getAgentIsFetching :getAgentBet.isFetching,
    getAgentCode :getAgentBet.code,
    getAgentError :getAgentBet.error,
    meData: state.me.me.data,
    lottoScheduleV2Data: lottoScheduleV2.data,
    lottoScheduleV2IsFetching: lottoScheduleV2.isFetching,
    lottoScheduleV2Code: lottoScheduleV2.code,
    lottoScheduleV2Error: lottoScheduleV2.error,

    postCancelBillCode: state.cancelBill.cancelBill.code,
    postCancelBillIsFetching : state.cancelBill.cancelBill.isFetching,
    postCancelBillError: state.cancelBill.cancelBill.error,
  };
};

const mapDispatchToProps: IBetMeHistoryContainerAction = {
  onChangeIsLoaddingAction,
  getAgentBet,
  getDataV2LotteryList,
  postCancelBill,
  getMe,
};

export default connect(mapStateToProps, mapDispatchToProps)(BetMeHistoryContainer);
