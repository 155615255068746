import { connect } from "react-redux"
import membersSelector from "../../reduxs/members/selector"
import CreateMemberContainer from "./CreateMember.container"
import {
  postMemberUpdate,
  postMemberRegister,
  postMemberDelete,
  postMemberRePassword
} from "../../reduxs/members/membersCRUD/action"
import { getDataMain } from "../../reduxs/main/main/action"
import { createOTP } from "reduxs/authentication/login/action"
import { getMemberDetail } from "../../reduxs/members/membersCRUD/action"
import { onChangeIsLoaddingAction } from "../../reduxs/Loader/action"
import { getMeSetting } from "reduxs/me/setting/action"
import { postOtp } from "reduxs/otp/actions"
import { getMeSettingLottery } from "reduxs/me/settingLottery/action"
import { postChild } from "reduxs/child/child/action"
import childSelector from "reduxs/child/selector"
const mapStateToProps = (state: RootReducers): IEditUserProps => {
  const memberUpdateState = membersSelector.memberUpdate(state)
  const memberRegisterState = membersSelector.memberRegister(state)
  const memberDetailState = membersSelector.membersDetail(state)
  const memberDeleteState = membersSelector.memberDelete(state)
  const memberRePasswordState = childSelector.memberRePassword(state)

  return {
    memberUpdateCode: memberUpdateState.code,
    memberUpdateIsFetching: memberUpdateState.isFetching,
    memberUpdateError: memberUpdateState.error,
    memberUpdateData: memberUpdateState.data,

    memberRegisterCode: memberRegisterState.code,
    memberRegisterIsFetching: memberRegisterState.isFetching,
    memberRegisterError: memberRegisterState.error,
    memberRegisterData: memberRegisterState.data,

    memberDetailCode: memberDetailState.code,
    memberDetailIsFetching: memberDetailState.isFetching,
    memberDetailError: memberDetailState.error,
    memberDetailData: memberDetailState.data,

    memberDeleteCode: memberDeleteState.code,
    memberDeleteIsFetching: memberDeleteState.isFetching,
    memberDeleteError: memberDeleteState.error,
    memberDeleteData: memberDeleteState.data,

    memberRePasswordCode: memberRePasswordState.code,
    memberRePasswordIsFetching: memberRePasswordState.isFetching,
    memberRePasswordError: memberRePasswordState.error,
    memberRePasswordData: memberRePasswordState.data,

    mainCode: state.main.dataMain.code,
    mainError: state.main.dataMain.error,
    mainIsFetching: state.main.dataMain.isFetching,
    mainData: state.main.dataMain.data,

    meSettingCode: state.me.setting.code,
    meSettingError: state.me.setting.error,
    meSettingIsFetching: state.me.setting.isFetching,
    meSettingData: state.me.setting.data,

    meCode: state.me.me.code,
    meError: state.me.me.error,
    meIsFetching: state.me.me.isFetching,
    meData: state.me.me.data,

    otpCode: state.otp.code,
    otpError: state.otp.error,
    otpIsFetching: state.otp.isFetching,
    otpData: state.otp.data,

    childCode: state.child.child.code,
    childError: state.child.child.error,
    childIsFetching: state.child.child.isFetching,
    childData: state.child.child.data,

    meSettingLotteryCode: state.me.settingLottery.code,
    meSettingLotteryError: state.me.settingLottery.error,
    meSettingLotteryIsFetching: state.me.settingLottery.isFetching,
    meSettingLotteryData: state.me.settingLottery.data,
  }
}
const mapDispatchToProps: IEditUserActionProps = {
  postRegisterUser: postMemberRegister,
  postMemberDelete: postMemberDelete,
  postMemberUpdate: postMemberUpdate,
  postMemberRePassword: postMemberRePassword,
  getDataMain: getDataMain,
  createOTP: createOTP,
  getMemberDetail: getMemberDetail,
  onChangeIsLoaddingAction: onChangeIsLoaddingAction,
  getMeSetting: getMeSetting,
  postOtp: postOtp,
  postChild: postChild,
  getMeSettingLottery: getMeSettingLottery,
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateMemberContainer)
