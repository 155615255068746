import { ESCAPED_TIMEZONE } from "constants/regex";
import { addMinutes, intervalToDuration, parseISO } from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";
import { get, isEmpty, replace, slice } from "lodash";
import th from 'date-fns/locale/th';
import moment from "moment";

const remainingOTPTime = (/* startDate: number | Date */) => {
  let duration = intervalToDuration({
    start: new Date(),
    end: addMinutes(new Date(), 10),
  });
  return duration;
};

const calibratingTime = (timeasString: string, isBuddhistEra: boolean = false): Date => {
  const localTime = new Date();
  const localISODate = new Date().toISOString();

  if (isEmpty(timeasString)) {
    return localTime;
  }

  const localTimeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localDate = utcToZonedTime(localISODate, localTimeZoneString);
  const localTimezoneArray = format(localDate, "yyyy-MM-dd'T'HH:mm:ss XXX", {
    timeZone: localTimeZoneString,
  }).match(ESCAPED_TIMEZONE);

  const escapedDate = utcToZonedTime(timeasString, "UTC");
  const escapedTimezoneArray = format(
    escapedDate,
    "yyyy-MM-dd'T'HH:mm:ss XXX",
    {
      timeZone: localTimeZoneString,
    }
  ).match(ESCAPED_TIMEZONE);
  const servertime = get(escapedTimezoneArray, "0", "");
  const localTimeZone = get(localTimezoneArray, "1", "");
  if (isEmpty(servertime)) {
    return localTime;
  }

  const calibratedTimeString = `${servertime}${localTimeZone}`;
  const calibratedTime = parseISO(calibratedTimeString);
  if (isBuddhistEra) {
    calibratedTime.setFullYear(calibratedTime.getFullYear() + 543)
    return calibratedTime
  }
  return calibratedTime;
};

const formatDateThai = (date: string) => {

  const convertDate = new Date(date)

  const result = convertDate.toLocaleDateString('th-TH', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  })

  return { result }

}
const formatDateTimeForApi = (date: string | Date) => {
  if (!date)
    return ''
  const ISOTime = moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD[T]HH:mm:ss[Z]')

  return ISOTime
}

const formatDateTimeZero = (date: string | Date) => {
  if (!date)
    return ''
  const ISOTime = moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD[T]00:00:00[Z]')

  return ISOTime
}


const formatThai = (date: string, formatText: string) => {
  try {
    const calibratedDate = calibratingTime(date)
    const year = calibratedDate.getFullYear() + 543

    const d = format(calibratedDate, 'd', { locale: th })
    const dd = format(calibratedDate, 'dd', { locale: th })
    const MM = format(calibratedDate, 'MM', { locale: th })
    const MMM = format(calibratedDate, 'MMM', { locale: th })
    const MMMM = format(calibratedDate, 'MMMM', { locale: th })
    const HH = format(calibratedDate, 'HH', { locale: th })
    const mm = format(calibratedDate, 'mm', { locale: th })
    const ss = format(calibratedDate, 'ss', { locale: th })

    const textWithoutYear = replace(formatText, 'dd', dd).replace('d', d).replace('MMMM', MMMM).replace('MMM', MMM).replace('MM', MM).replace('HH', HH).replace('mm', mm).replace('ss', ss)

    const year2ditgit = slice(String(year), 2, 5).join('')
    const formatedYear = replace(textWithoutYear, 'yyyy', String(year)).replace('yy', year2ditgit)
    return formatedYear
  } catch (error) {
    console.log('Error format thai: ', error)
    return ''
  }
}

const dateUtils = {
  remainingOTPTime,
  calibratingTime,
  formatDateThai,
  formatDateTimeForApi,
  formatDateTimeZero,
  formatThai,
};

export default dateUtils;
/* import moment, { Moment } from "moment-timezone";
import { ESCAPED_TIMEZONE } from "constants/regex";
import { isEmpty, get } from "lodash";

const convertTimeToMoment = (
  hours: string = "00",
  minutes: string = "00"
): Moment => {
  const momentTime = moment(`${hours}:${minutes}`, "HH:mm");
  return momentTime;
};

const calibratingTime = (timeasString?: string): Moment => {
  const localTime = moment().local();
  if (isEmpty(timeasString)) {
    return localTime;
  }

  const localTimezoneArray = localTime.format().match(ESCAPED_TIMEZONE);
  const escapedTimezoneArray = timeasString!.match(ESCAPED_TIMEZONE);

  const servertime = get(escapedTimezoneArray, "0", "");
  const localTimezone = get(localTimezoneArray, "1", "");
  if (isEmpty(servertime)) {
    return localTime;
  }

  const calibratedTimeString = `${servertime}${localTimezone}`;
  const calibratedTime = moment(calibratedTimeString).local();

  return calibratedTime;
};

const localMachineTime = (): any => {
  const localTime = moment().local();
  const localTimezoneArray = localTime.format().match(ESCAPED_TIMEZONE);

  const formattedLocalTime = get(localTimezoneArray, "0", "");

  return formattedLocalTime;
};

const dateUtils = {
  convertTimeToMoment,
  calibratingTime,
  localMachineTime,
};

export default dateUtils; */
