const environment: IProjectEnviroment = {
  name: "obey",
  packageVersion: process.env.REACT_APP_OBEY_PACKAGE_VERSION || "1.0.0",
  buildVersion: process.env.REACT_APP_OBEY_BUILD_VERSION || "dev",
  environmentName: process.env.REACT_APP_OBEY_ENV || "development",
  endpoint: {
    login: "/api/agent/login",
    loginOTP: "/api/agent/login/otp",
    loginInitialPassword: "/api/agent/initial-password",
    otp: "/api/agent/otp",
    loginPassword: "/api/agent/password",
    getAllUser: "/api/user/all",
    updateUser: "/api/tc/user",
    credits: "/api/agent/finance/me",
    transectionLists: "/api/agent/finance/list",
    transferCredits: "/api/agent/finance/pay/list",
    transferConfirmations: "/api/agent/finance/receive/list",
    transferCreditDescription: "/api/agent/finance/pay/detail",
    main: "/api/agent/me",
    resetPassword: "/api/agent/reset-password",
    news: "/api/agent/news",
    newsme: "/api/agent/news/me",
    newscreate: "/api/agent/news/me/create",
    newsupdate: "/api/agent/news/me/update",
    newsdelete: "/api/agent/news/me/delete",
    childMembers: "/api/v2/agent/child",
    childResetPassword: "/api/v2/agent/child/password/update",
    memberSummary: "/api/agent/member/summary",
    financeSummary: "/api/v2/agent/finance/summary",
    postRegister: "/api/agent/member/register",
    editUser: "/api/agent/edituser",
    listmembers: "/api/agent/member/list",
    memberList: "/api/agent/member/list",
    memberRegister: "/api/agent/member/register",
    memberUpdate: "/api/agent/member/update",
    memberDetail: "/api/agent/member/detail",
    memberDelete: "/api/agent/member/delete",
    memberRePassword: "/api/agent/member/password",
    memberDiagram: "/api/agent/member/diagram",
    editUserHistory: "/api/agent/activity/list",
    mockEditUserHistoryDetails:
      "https://my-json-server.typicode.com/P-Buranakitsin/mockHistoryDetailsTable/db",
    agentLogin: "/api/agent/login",
    lottoSchedule: "/api/agent/lottery/all",
    V2lottoSchedule: "/api/v2/agent/lottery/schedule",
    getBetRate: "/api/agent/lottery/rate/client",
    getNumberRate: "/api/agent/lottery/rate-number",
    getLottoGame: "/api/agent/lottery/all",
    postMakingBetLotto: "/api/agent/bet",
    payRequest: "/api/agent/finance/pay/request",
    payAccept: "/api/agent/finance/pay/accept",
    autocomplete: "/api/agent/bet/autocomplete",
    lottoHistory: "/api/agent/bet/lottery/history",
    lottoBetResult: "/api/agent/lottery/result",
    postRegister2FA: "/api/agent/2fa/register",
    postVerify2FA: "/api/agent/2fa/verify",
    postValidate2FA: "/api/agent/2fa/validate",
    getYegeeGame: "/api/agent/lottery/yegee/play/game",
    lottoHistorySummaryDetail: "/api/agent/lottery/history/summary/detail",
    lottoHistorySummary: "/api/agent/lottery/history/summary",
    // Luden APIs
    postYegeeGameHistory: "/api/lotter/yegee/play/history",
    postYegeePlay: "/api/lotter/yegee/play",
    getYegeeSum: "/api/lotter/yegee/play/sum",
    getPlayedYegeeList: "/api/lotter/yegee/play/all",

    // Agent V2 APIs
    getCustomer: "/api/v2/agent/customer",
    getHost: "/api/v2/agent/host",
    getMeSetting: "/api/v2/agent/me/setting",
    getMe: "/api/v2/agent/me",
    postChild: "/api/v2/agent/child",
    getChildBet: "/api/v2/agent/child/bet",
    getChildDetail: "/api/v2/agent/child/detail",
    getMeSettingLottery: "/api/v2/agent/me/setting/lottery",
    postChildSettingLotteryUpdate: "/api/v2/agent/child/setting/lottery/update",
    getChildSettingLottery: "/api/v2/agent/child/setting/lottery",
    getChildSetting: "/api/v2/agent/child/setting",
    postChildUpdate: "/api/v2/agent/child/update",
    postChildSettingUpdate: "/api/v2/agent/child/setting/update",
    postChildCreditUpdate: "/api/v2/agent/child/credit/update",
    postOwnerUpdate: "/api/v2/agent/owner/update",
    postOwnerSetting: "/api/v2/agent/owner/setting",
    V2lottoBetResult: "/api/v2/agent/lottery/result",
    V2lottoBetGroup: "/api/v2/agent/lottery/bet/customer",
    V2lottoBet: "/api/v2/agent/lottery/bet",
    postOwnerSettingLotteryUpdate: "/api/v2/agent/owner/setting/lottery/update",
    getFinanceCustomer: "/api/v2/agent/finance/customer",
    getFinanceChild: "/api/v2/agent/finance/child",
    getFinanceCustomerTx: "/api/v2/agent/finance/customer/tx",
    getCustomerNote: "/api/v2/agent/customer/note",
    getFinanceChildMe: "/api/v2/agent/finance/child/me",
    postFinanceCustomerTx: "/api/v2/agent/finance/customer/tx",
    postFinanceApprove: "/api/v2/agent/finance/child/approve",
    postFinanceRequest: "/api/v2/agent/finance/child/request",
    getFinanceHost: "/api/v2/agent/finance/host",
    getFinanceHostMe: "/api/v2/agent/finance/host/me",
    postFinanceHostApprove: "/api/v2/agent/finance/host/approve",
    postFinanceHostRequest: "/api/v2/agent/finance/host/request",

    getAgentHoles: "/api/v2/agent/holes",
    getAgentHolesLottery: "/api/v2/agent/holes/lottery",
    getAgentHolesNumber: "/api/v2/agent/holes/numbers",
    postHolesAddAgent: "/api/v2/agent/holes/add",
    postAgentHoleUpdate: "/api/v2/agent/holes/update",
    postAgentHolesDelete: "/api/v2/agent/holes/delete",
    postAgentHolesNumbersAdd: "/api/v2/agent/holes/numbers/add",
    postAgentHolesNumbersUpdate: "/api/v2/agent/holes/numbers/update",
    postCancelBill: "/api/v2/agent/lottery/refund",
    postPastePooi: "/api/v2/agent/lottery/replace/extract",

    getAgentBanAddLottery:"/api/v2/agent/lottery/ratereduce/lottery",
    postAgentBanAddLottery:"/api/v2/agent/lottery/ratereduce/add",
    getAgentBanLottery: "/api/v2/agent/lottery/ratereduce",
    postCancelNumberBan: "/api/v2/agent/lottery/ratereduce/update",
    getAgentLotteryRateReduce:"/api/v2/agent/lottery/ratereduce",
    postAgentLotteryRateReduceUpdate:"/api/v2/agent/lottery/ratereduce/update",
    postAgentLotteryRateReduceHost:"/api/v2/agent/lottery/ratereduce/host",

    getAgentBetExtraction: '/api/v3/agent/bet/lottery/extraction',
    getAgentBetExtractionDetail  : '/api/v3/agent/bet/lottery/extraction/detail',
    getLotteryBetLottery: '/api/v3/agent/lottery/bet/lottery',
    getLotteryBetMember: '/api/v3/agent/lottery/bet/member',
    getLotteryBetBenefitAnalyst: '/api/v3/agent/lottery/report/benefit-analyst'
  },
  environments: {
    development: {
      name: "development",
      endpoint: {
        url: "https://spdevapi.tlbetmantra.com",
        socket: "https://devapi.tlbetmantra.com",
        aesSecretKey: process.env.REACT_APP_AES_SECRET_KEY || "",
        urlTest: "https://my-json-server.typicode.com",
        urlMock: "http://biws.ddns.net/parasite/api/agent/mockup",
        urlLuden: "https://devapi.tlbetmantra.com",
        urlStagingLuden: "https://stapi.tlbetmantra.com",
      },
    },
    release: {
      name: "release",
      endpoint: {
        url: "https://spstapi.tlbetmantra.com",
        urlLuden: "https://stapi.tlbetmantra.com",
        socket: "https://stapi.tlbetmantra.com",
        urlMock: "http://biws.ddns.net/parasite/api/agent/mockup",
        aesSecretKey: process.env.REACT_APP_AES_SECRET_KEY || "",
      },
    },
    production: {
      name: "production",
      endpoint: {
        url: "https://spreadapi.tlbetmantra.com",
        socket: "https://prodapi.tlbetmantra.com",
        aesSecretKey: process.env.REACT_APP_AES_SECRET_KEY || "",
      },
    },
  },
};
export default environment;
