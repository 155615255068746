// import colors from 'constants/colors'
import React, { FC } from 'react'
import SkeletonLoader from "tiny-skeleton-loader-react";

declare interface ISkeletonLoaderProps {
	customStyle: {
		col?: number,
		height?: string,
		background?: string,
		radius?: string,
		circle?: boolean;
		block?: boolean;
		show?: boolean;
	}[][]
}

const defaultProps: ISkeletonLoaderProps = {
	customStyle: [],
}

const SkeletonComponent: FC<ISkeletonLoaderProps> = ({
	customStyle
}) => {

	const ListLoader = () => {
		if (customStyle.length > 0) {
			const SkeletonList = customStyle.map((rowProps, rowIndex) => {
				const colList = rowProps.map((columnProps, columnIndex) => {
					const columnClass = `col-${columnProps.col}`
					if (!columnProps.show) {
                        return (<div className={columnClass} key={`column-${columnIndex}`} />)
                    }
					return (
						<div className={columnClass} key={`column-${columnIndex}`}>
							<SkeletonLoader
								width="100%"
							/>
						</div>
					)
				})
				return (<div className="row 0-b" key={`row-${rowIndex}`}>{colList}</div>)
			})
			return (<>{SkeletonList}</>)
		}
		return (<div>
			<SkeletonLoader
				width="60%"
			/>
		</div>)
	}
	return (
		<div className="row">
			<div className="col">
				<ListLoader />
			</div>
		</div>
	)
}

SkeletonComponent.defaultProps = defaultProps

export default SkeletonComponent