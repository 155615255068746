import { AxiosError, AxiosResponse } from 'axios'
import { createAsyncAction } from 'typesafe-actions'
import {
    GET_NEWSME_REQUEST,
    GET_NEWSME_SUCCESS,
    GET_NEWSME_FAILURE,

    POST_NEWSME_REQUEST,
    POST_NEWSME_SUCCESS,
    POST_NEWSME_FAILURE,

    UPDATE_NEWSME_REQUEST,
    UPDATE_NEWSME_SUCCESS,
    UPDATE_NEWSME_FAILURE,

    DELETE_NEWSME_REQUEST,
    DELETE_NEWSME_SUCCESS,
    DELETE_NEWSME_FAILURE,
} from './constants'

const newsMeGetAction = createAsyncAction(
    GET_NEWSME_REQUEST,
    GET_NEWSME_SUCCESS,
    GET_NEWSME_FAILURE,
)<any, AxiosResponse<IAPIResponse<IPagination<INews>>>, AxiosError<IAPIResponse>>()

const newsMeAddAction = createAsyncAction(
    POST_NEWSME_REQUEST,
    POST_NEWSME_SUCCESS,
    POST_NEWSME_FAILURE,
)<any, AxiosResponse<IAPIResponse<object>>, AxiosError<IAPIResponse>>()

const newsMeUpdateAction = createAsyncAction(
    UPDATE_NEWSME_REQUEST,
    UPDATE_NEWSME_SUCCESS,
    UPDATE_NEWSME_FAILURE,
)<any, AxiosResponse<IAPIResponse<object>>, AxiosError<IAPIResponse>>()

const newsMeDeleteAction = createAsyncAction(
    DELETE_NEWSME_REQUEST,
    DELETE_NEWSME_SUCCESS,
    DELETE_NEWSME_FAILURE,
)<any, AxiosResponse<IAPIResponse<object>>, AxiosError<IAPIResponse>>()

export {
    newsMeGetAction,
    newsMeAddAction,
    newsMeUpdateAction,
    newsMeDeleteAction
}