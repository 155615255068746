import React from "react";
import {
    Breadcrumb,
    Button,
    Input,InputElements,
    ErrorModal,
    SuccessModal,
    LoadingButton,
  } from "components"
  import color from "constants/colors"
import { RouteComponentProps } from "react-router-dom"
import { noop, isEmpty, get } from "lodash"
import scheme from "./model/scheme";
import { Formik } from "formik"
import { responseCode, responseMessage } from "constants/response"
import { Component } from "react"
import { handleOnClickBreadcrumb } from "utils"
import "./UserResetPassword.style.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";


const constants = {
  cancel:"ยกเลิก",
  buttonChangePassword:"บันทึกเปลี่ยนรหัสผ่าน",
  confirmPassword:"ยืนยันรหัสผ่าน",
  password:"รหัสผ่าน",
  Username: "ชื่อผู้ใช้ *",
  Password: "รหัสผ่าน *",
  ConfirmPassword: "ยืนยันรหัสผ่าน *",
  title: "เปลี่ยนรหัสผ่าน",
  titlePwSuccess: 'เปลี่ยนรหัสผ่านสำเร็จ',
  changePasswordSuccess: "บันทึกเปลี่ยนรหัสผ่านเรียบร้อย",
  titleContent: "เปลี่ยนรหัสผ่าน",
  breadcrumb: {
    changePassword: "เปลี่ยนรหัสผ่าน",
    userdDetail: "ข้อมูลส่วนตัว",
    main: "ภาพรวม",
  },
};
type DefaultProps = Readonly<typeof defaultProps>
const defaultProps: IUserResetPasswordActionProps &
IUserResetPasswordProps = {
  postResetPassword(){
    noop()
  },
  onChangeIsLoaddingAction() {
    noop();
  },
  UserResetPassCode: 0,
  UserResetPassError:"",
  UserResetPassIsFetching: false,
};
class UsersResetPassword extends Component <
IUserResetPasswordActionProps&IUserResetPasswordProps &DefaultProps & RouteComponentProps >

{
static defaultProps = defaultProps;
state = {
  hidePass:false,
  hideConfirmPass:false
};
componentDidUpdate(prevProps: IUserResetPasswordProps) {
  if (
    prevProps.UserResetPassIsFetching !==
      this.props.UserResetPassIsFetching &&
    !this.props.UserResetPassIsFetching
  ) {
    if (
      this.props.UserResetPassCode === responseCode.OK &&
      this.props.UserResetPassError === ""
    ) {
      SuccessModal.show({
        action: () => {
          SuccessModal.hide();
          this.handleRouteClick(
          );
        },
        title: constants.titlePwSuccess,
        description: constants.changePasswordSuccess,
      });
    } else {
      ErrorModal.show({
        action: () => {
          ErrorModal.hide();
        },
        description: get(
          responseMessage(),
          this.props.UserResetPassCode,
          responseMessage()[400000]        ),
      });
    }
  }
}



  handleRouteClick = () => {
    this.props.history.push("/user/detail", 
    
    );
  };

  render() {
    const navigates: IBreadcrumbItem[] = [
        { label: constants.breadcrumb.main, path: "/", active: false },
        {
          label: constants.breadcrumb.userdDetail,
          path: "/user/detail",
          active: false,
        },
        { label: constants.breadcrumb.changePassword, active: true },
      ];

    return (
         <Formik
        initialValues={{
          password: "",
          confirmpassword: "",
        }}
        validationSchema={scheme}
        onSubmit={(values, { resetForm }) => {
          this.props.postResetPassword({password:values.password,confirm_password:values.confirmpassword})
          resetForm();
        }}
      >
        {({
          values,
          errors,
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
        }) => {
          return (
        <div className="paper-container userResetPassword-container">
        <div className="row">
          <div className="col">
            <div className="sub-menu">
              <Breadcrumb
                items={navigates}
                handleOnClickItem={handleOnClickBreadcrumb}
              />
            </div>
          </div>
        </div>

        <div className="paper-title titleText">{constants.title}</div>
        <div className="d-flex flex-row justify-content-between">
          <div className="paper-description">
           {this.props.location.state.username}
          </div>
        </div>
        {/* ---------------------- content */}
        <div className="paper-body member-specific-info-container">
          <div className="row mt-4">
            <div className="col">
              <div className="box-shadow">
                <div className="row">
                  <div className="col-12 m1-t">
                    <div className="paper-body-title">
                      {constants.titleContent}
                    </div>
                  </div>
                </div>

                <div className="row p3-t">
                  <div className="col-4">
                    <label className="label-input">
                      {constants.Username}
                    </label>
                    <div className="position-relative">
                      <Input
                        id="username"
                        name="username"
                        inputClassName="input-username"
                        value={this.props.location.state.username}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <label className="label-input">
                      {constants.Password}
                    </label>
                    <div className="input-password"> <InputElements
                      id="password"
                      name="password"
                      // type="password"                    
                      placeholder={constants.password}
                      inputClassName="input-username"
                      backgroundColor={color.TRANSPARENT}
                      onBlur={handleBlur}
                      value={values.password}
                      onChange={handleChange}
                      errorMessage={errors.password}
                      error={!!errors.password && touched.password}
                      type={this.state.hidePass?"text":"password"}
                      endText={<FontAwesomeIcon
                        className="icon-eye"
                        icon={this.state.hidePass?faEye:faEyeSlash}
                        onClick={() => {
                          this.setState({ hidePass: !this.state.hidePass });
                        }}
                      />}
                    /> <h6 className="primary-red-text text-left mt-1">
                    {!!errors.password && touched.password
                      ? errors.password
                      : "\u00A0"}
                  </h6>
                    </div>
                   
                  </div>
                  <div className="col-4">
                    <label className="label-input">
                      {constants.ConfirmPassword}
                    </label>
                      <div className="input-password"><InputElements
                      id="password"
                      // type="password"
                      name="confirmpassword"                  
                      placeholder={constants.confirmPassword}                
                      inputClassName="input-username"
                      backgroundColor={color.TRANSPARENT}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.confirmpassword}
                      errorMessage={errors.confirmpassword}
                      error={!!errors.confirmpassword && touched.password}
                      type={this.state.hideConfirmPass?"text":"password"}
                      endText={<FontAwesomeIcon
                        className="icon-eye"
                        icon={this.state.hideConfirmPass?faEye:faEyeSlash}
                        onClick={() => {
                          this.setState({ hideConfirmPass: !this.state.hideConfirmPass });
                        }}
                      />}
                    />
                     <h6 className="primary-red-text text-left mt-1">
                      {!!errors.confirmpassword && touched.confirmpassword
                        ? errors.confirmpassword
                        : "\u00A0"}
                    </h6>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col">
              <LoadingButton
                loading={this.props.UserResetPassIsFetching}
                buttonType="submit"
                id="saveMemberDetail"
                size="large"
                textSize={18}
                text={constants.buttonChangePassword}
                onClick={() => handleSubmit()}
                disabled={
                  isEmpty() &&
                  (values.password === "" ||
                    values.confirmpassword === "")
                }
              />
            </div>
          </div>
          <div className="row mt-2 justify-content-center">
            <div className="col-auto ">
              <Button
                id="reset"
                text={constants.cancel}
                type="underline"
                textSize={18}
                textColor={color.PRIMARY_GREEN}
                backgroundColor={color.TRANSPARENT}
                backgroundHoverColor={color.TRANSPARENT}
                onClick={() =>
                  this.handleRouteClick()
                }
              />
            </div>
          </div>
        </div>
      </div>
         );
        }}
      </Formik>
    );
  }
}
export default UsersResetPassword;
