import React from "react";
import { noop, isEmpty, get } from "lodash";
import { Component } from "react";
import {
  Breadcrumb,
  Button,
  Input,InputElements,
  ErrorModal,
  SuccessModal,
} from "components";
import "./MembersResetPassword.style.scss";
import color from "constants/colors";
import { RouteComponentProps } from "react-router-dom";
import scheme from "./models/scheme";
import { Formik } from "formik";
import { responseCode, responseMessage } from "constants/response";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const constants = {
  cancel:"ยกเลิก",
  buttonChangePassword:"บันทึกเปลี่ยนรหัสผ่าน",
  confirmPassword:"ยืนยันรหัสผ่าน",
  password:"รหัสผ่าน",
  childUsername: "ชื่อผู้ใช้ *",
  childPassword: "รหัสผ่าน *",
  childConfirmPassword: "ยืนยันรหัสผ่าน *",
  title: "ข้อมูลสมาชิก",
  titlePwSuccess: 'เปลี่ยนรหัสผ่านสำเร็จ',
  changePasswordSuccess: "บันทึกเปลี่ยนรหัสผ่านเรียบร้อย",
  titleContent: "เปลี่ยนรหัสผ่าน",
  breadcrumb: {
    changePassword: "เปลี่ยนรหัสผ่าน",
    membersList: "รายการสมาชิก",
    main: "ภาพรวม",
  },
};
type DefaultProps = Readonly<typeof defaultProps>;
const defaultProps: IMemberResetPasswordActionProps &
  IMemberChildResetPasswordProps = {
  onChangeIsLoaddingAction() {
    noop();
  },
  postChildResetPassword() {
    noop();
  },
  childResetPassCode: 0,
  childResetPassError: "",
  childResetPassIsFetching: false,
  childResetPassData: {},
};
class MembersResetPassword extends Component<
  IMemberResetPasswordActionProps &
    IMemberChildResetPasswordProps &
    DefaultProps &
    RouteComponentProps,
  IMemberChildResetPasswordContainerState
> {
  static defaultProps = defaultProps;
  state = {
    hidePass:false,
    hideConfirmPass:false
  };
  componentDidMount() {
  }

  componentDidUpdate(prevProps: IMemberChildResetPasswordProps) {
    if (
      prevProps.childResetPassIsFetching !==
        this.props.childResetPassIsFetching &&
      !this.props.childResetPassIsFetching
    ) {
      this.props.onChangeIsLoaddingAction(false);

      if (
        this.props.childResetPassCode === responseCode.OK &&
        this.props.childResetPassError === ""
      ) {
        SuccessModal.show({
          action: () => {
            SuccessModal.hide();
            this.handleRouteClick(
              this.props.location.state.childAgentId,
            );
          },
          title: constants.titlePwSuccess,
          description: constants.changePasswordSuccess,
        });
      } else {
        ErrorModal.show({
          action: () => {
            ErrorModal.hide();
          },
          description: get(
            responseMessage(),
            this.props.childResetPassCode,
            responseMessage()[400000]            ),
        });
      }
    }
  }

  handleRouteClick = (childAgentId: number) => {
    this.props.history.push("/member/detail", 
      {childAgentId},
    );
  };

  render() {
    const labelUsername = this.props.location.state.childUsername
    const navigates: IBreadcrumbItem[] = [
      { label: constants.breadcrumb.main, path: "/", active: false },
      {
        label: constants.breadcrumb.membersList,
        path: "/members-list",
        active: false,
      },
      {
        label: labelUsername,
        path: "/member/detail",
        active: false,
      },
      { label: constants.breadcrumb.changePassword, active: true },
    ];

    return (
      <Formik
        initialValues={{
          password: "",
          confirmpassword: "",
        }}
        validationSchema={scheme}
        onSubmit={(values, { resetForm }) => {
          this.props.onChangeIsLoaddingAction(true);
          this.props.postChildResetPassword({
            childAgentId: this.props.location.state.childAgentId,
            childPassword: values.password,
          });
          resetForm();
        }}
      >
        {({
          values,
          errors,
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
        }) => {
          return (
            <div className="paper-container memberResetPassword-container">
              <div className="row">
                <div className="col">
                  <div className="sub-menu">
                    <Breadcrumb
                      items={navigates}
                      handleOnClickItem={(path)=>{               
                        this.props.history.push(path,path===navigates[2].path?{
                          childAgentId: this.props.location.state.childAgentId,
                          childUsername: this.props.location.state.childUsername,
                          childName: this.props.location.state.childName,
                         
                        }:{})
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="paper-title titlePage">{constants.title}</div>
              <div className="d-flex flex-row justify-content-between">
                <div className="paper-description">
                  {`${get(this.props.location.state, "childName", "")}  (${
                    this.props.location.state.childUsername
                  })`}
                </div>
              </div>
              {/* ---------------------- content */}
              <div className="paper-body member-specific-info-container">
                <div className="row mt-4">
                  <div className="col">
                    <div className="box-shadow">
                      <div className="row">
                        <div className="col-12 m1-t">
                          <div className="paper-body-title">
                            {constants.titleContent}
                          </div>
                        </div>
                      </div>

                      <div className="row p3-t">
                        <div className="col-4">
                          <label className="label-input">
                            {constants.childUsername}
                          </label>
                          <div className="position-relative">
                            <Input
                              
                              id="username"
                              name="username"
                              inputClassName="input-username"
                              value={this.props.location.state.childUsername}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <label className="label-input">
                            {constants.childPassword}
                          </label>
                          <div className="input-password">
                          <InputElements
                            id="password"
                            name="password"
                            // type="password"
                            onBlur={handleBlur}
                            placeholder={constants.password}
                            inputClassName="input-username"
                            backgroundColor={color.TRANSPARENT}
                            value={values.password}
                            onChange={handleChange}
                            errorMessage={errors.password}
                            error={!!errors.password && touched.password}
                            type={this.state.hidePass?"text":"password"}
                            endText={<FontAwesomeIcon
                            className="icon-eye"
                            icon={this.state.hidePass?faEye:faEyeSlash}
                            onClick={() => {
                              this.setState({ hidePass: !this.state.hidePass });
                            }}
                            />}
                            /> 
                            <h6 className="primary-red-text text-left mt-1">
                            {!!errors.password && touched.password
                              ? errors.password
                              : "\u00A0"}
                          </h6>
                          </div>
                         
                        </div>
                        <div className="col-4">
                          <label className="label-input">
                            {constants.childConfirmPassword}
                          </label>
                          <div className="input-password">
                          <InputElements
                            id="password"
                            // type="password"
                            name="confirmpassword"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={constants.confirmPassword}
                            value={values.confirmpassword}
                            inputClassName="input-username"
                            backgroundColor={color.TRANSPARENT}
                            errorMessage={errors.confirmpassword}
                            error={!!errors.confirmpassword && touched.password}
                            type={this.state.hideConfirmPass?"text":"password"}
                            endText={<FontAwesomeIcon
                              className="icon-eye"
                              icon={this.state.hideConfirmPass?faEye:faEyeSlash}
                              onClick={() => {
                                this.setState({ hideConfirmPass: !this.state.hideConfirmPass });
                              }}
                            />}
                          />
                           <h6 className="primary-red-text text-left mt-1">
                            {!!errors.confirmpassword && touched.confirmpassword
                              ? errors.confirmpassword
                              : "\u00A0"}
                          </h6>
                          </div>
                         
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col">
                    <Button
                      buttonType="submit"
                      id="saveMemberDetail"
                      size="large"
                      textSize={18}
                      text={constants.buttonChangePassword}
                      onClick={() => handleSubmit()}
                      disabled={
                        isEmpty() &&
                        (values.password === "" ||
                          values.confirmpassword === "")
                      }
                    />
                  </div>
                </div>
                <div className="row mt-2 justify-content-center">
                  <div className="col-auto ">
                    <Button
                      id="reset"
                      text={constants.cancel}
                      type="underline"
                      textSize={18}
                      textColor={color.PRIMARY_GREEN}
                      backgroundColor={color.TRANSPARENT}
                      backgroundHoverColor={color.TRANSPARENT}
                      onClick={() =>
                        this.handleRouteClick(
                       this.props.location.state.childAgentId,
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    );
  }
}
export default MembersResetPassword;
