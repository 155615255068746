import { connect } from "react-redux";
import RootContainer from "./Root.container";
import { resetLogin } from "reduxs/authentication/login/action";
import { logout } from "reduxs/authentication/logout/action";
import menuSelector from "reduxs/menu/selector";
import { getDataMain } from "reduxs/main/main/action";
import { onChangeMenuAction, onChangeShowMenuAction } from "reduxs/menu/action";
import authSelector from "reduxs/authentication/selector";
import { connectSocketAction } from "reduxs/socket/actionCreator";
import { getMe } from "reduxs/me/me/action";

const mapStateToProps = (state: RootReducers): IRootProps => {
  const menuState = menuSelector.menu(state);
  const showMenuState = menuSelector.showMenu(state);
  const loginState = authSelector.login(state);
  return {
    accessToken: state.authentication.token.accessToken,
    menuActive: menuState.menuActive,
    isShowMenu: showMenuState.isShowMenu,
    userStatus: state.authentication.login.data.status,
    pathname: state.router.location.pathname,
    mainCode: state.main.dataMain.code,
    mainError: state.main.dataMain.error,
    mainIsFetching: state.main.dataMain.isFetching,
    mainData: state.main.dataMain.data,
    loginCode: loginState.code,
    verify: loginState.data.verify,
    meData: state.me.me.data,
  };
};

const mapDispatchToProos: IRootActionProps = {
  getMe,
  onChangeMenu: onChangeMenuAction,
  onChangeShowMenu: onChangeShowMenuAction,
  resetLogin: resetLogin,
  logout: logout,
  getDataMain: getDataMain,
  connectSocket: connectSocketAction,
};
export default connect(mapStateToProps, mapDispatchToProos)(RootContainer);
