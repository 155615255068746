import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import {
  getYeegeGameListAction,
  updateYegeeGameListAction,
} from "../actionCreator";
import { fetchGetYegeeGameList } from "../services";

const getDataYegeeGameList = () => async (dispatch: Dispatch) => {
  dispatch(getYeegeGameListAction.request({}));
  return fetchGetYegeeGameList()
    .then((res: AxiosResponse<IAPIResponse<ILottoGame[]>>) => {
      dispatch(getYeegeGameListAction.success(res));
    })
    .catch((error: AxiosError<IAPIResponse>) =>
      dispatch(getYeegeGameListAction.failure(error))
    );
};

const updateDataYegeeGameList = (res: IAPIResponse<ILottoGame[]>) => (
  dispatch: Dispatch
) => {
  dispatch(updateYegeeGameListAction.request({}));
  dispatch(updateYegeeGameListAction.success(res));
};

export { getDataYegeeGameList, updateDataYegeeGameList };
