import React, { FC, useRef, useEffect, useState } from "react";
import { isEmpty , isNull } from "lodash";
import "./datepicker.style.scss";
import DatePicker, {
  CalendarProps,
  DateObject,
  DatePickerProps,
} from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/yellow.css";
import { thai_th } from "./thai_th"
import thai from "./thai"
import DatePickerHeader from "react-multi-date-picker/plugins/date_picker_header"
import Toolbar from "react-multi-date-picker/plugins/toolbar"
import color from "constants/colors";
import InputIcon from "react-multi-date-picker/components/input_icon"
// import Plus from "assets/images/global/icon/plus.png";
// import i18n from "i18n";
type DefaultProps = Readonly<typeof defaultProps>;

declare interface IDatePickerNew {
  errorMessage?: string;
  format?: string;
  error?: boolean;
  maxDate?: string | number | Date | DateObject | undefined;
  width?: string;
  flag?: boolean;
  hasErrorMessage?: boolean;
  isShowIcon?: boolean;
  btnClear?: boolean;
  onClear?():void
}

const defaultProps: IDatePickerNew = {
  errorMessage: "",
  format: "DD MMM YYYY HH:mm",
  hasErrorMessage: true,
};

const IDatePicker: FC<IDatePickerNew & DefaultProps & CalendarProps & DatePickerProps> = (props) => {
  const ref: any = useRef();
  const {
    errorMessage,
    format,
    error,
    maxDate,
    width,
    hasErrorMessage,
    value,
    disabled
  } = props;

  const [mustClose
    // , setMustClose
  ] = useState(false);

  useEffect(() => {
    function handleClickOutside(e: any) {
      if (e.target.innerHTML.length !== undefined && e.target.innerText !== undefined) {
        if (ref.current) {
          if (ref.current.textContent.split('DateClose').length === 1 && e.target.innerHTML.length !== 0) {
            if (e.target.innerHTML.length <= 2 && (!isNaN(Number(e.target.innerHTML)) || e.target.innerText.length <= 2)) {

              if (!e.target.innerHTML.includes('arrow')) {
                ref.current.closeCalendar();
              }
            }
          }
        }

      }
    }
    window.addEventListener("click", handleClickOutside);
    return () => window.removeEventListener("click", handleClickOutside);
  }, [mustClose]);

  const propLocale = () => {
    // if (i18n.language === 'th') {
      return { locale: thai_th, calendar: thai }
    // } else {
    //   return {}
    // }
  }

  return (
    <div className={`date-picker-container ${disabled && 'disabled'}`}>
      {/* @ts-ignore */}
      <DatePicker
        onOpenPickNewDate={false}
        buttons={true}
        fixMainPosition={true}
        calendarPosition="bottom-center"
        style={{ width: `${width}%` }}
        className={`date-picker-input yellow`}
        inputClass={`rmdp-input ${error && "error"} ${disabled && 'disabled'}`}
        render={
          props.isShowIcon ?
          <InputIcon placeholder={props.placeholder} className={`rmdp-input ${error && "error"} ${disabled && 'disabled'} ${isNull(value) && "placeholder"}`} /> 
          :undefined
        }
        plugins={[
          <Toolbar position="bottom"
            sort={props.btnClear?["deselect","close"]:[]}
            names={{
              today: "select today",
              deselect: "ล้างวันที่",
              close: "ปิด",
            }}
            className="clear"
          />,
        <DatePickerHeader 
          position="top" 
          size="medium"
          style={{ backgroundColor: color.PRIMARY_GREEN }} 
        />]}
        format={format}
        maxDate={maxDate}
        value={value}
        // ref={ref}
        // calendar={thai}
        // locale={thai_th}
        {...props}
        {...propLocale()}
      />
      {/* <div className="box-icon" onClick={props.onClear}>
        <img src={Plus} alt="Plus" className="close-icon"/>
      </div> */}
      {hasErrorMessage && (
        <div className="input-error-message">
          {!isEmpty(errorMessage) && errorMessage}
        </div>
      )}
    </div>
  );
};

IDatePicker.defaultProps = defaultProps; 

export default IDatePicker;
