import { connect } from "react-redux"
import MembersListContainer from "./MembersList.container"
import membersSelector from "../../reduxs/members/selector"
import { getChildMembers , getFinanceSummary} from "../../reduxs/members/memberSummary/action"
import { getMe } from "reduxs/me/me/action";
const mapStateToProps = (state: RootReducers): IMembersProps => {
  const agentChildList = membersSelector.memberChild(state)
  const financeSummaryList = membersSelector.financeSummary(state)
  return {
    meData: state.me.me.data,
    agentChildCode: agentChildList.code,
    agentChildError:agentChildList.error,
    agentChildIsFetching: agentChildList.isFetching,
    agentChildData: agentChildList.data,
    getFinanceData : financeSummaryList.data,
    financeDataIsFetching: financeSummaryList.isFetching,
    mainData: state.main.dataMain.data
  }
}
const mapDispatchToProps: IMembersActionProps = {
  getMe,
  getChildMembers: getChildMembers,
  getFinanceSummary : getFinanceSummary,

}
export default connect(mapStateToProps, mapDispatchToProps)(MembersListContainer)
