import BetContainer from "./Bet.container";
import { connect } from "react-redux";
import lottoSelector from "reduxs/lotto/selector";
import { getDataLotteryList,getDataV2LotteryList } from "reduxs/lotto/list/action";
import { onChangeIsLoaddingAction } from "reduxs/Loader/action";
import { getV2DataResult } from "reduxs/lotto/bet/result/action";
import bet from "reduxs/lotto/bet/selector";

const mapStateToProps = (state: RootReducers): IBetContainerProps => {
  const lottoScheduleV2 = lottoSelector.listV2(state);
  const getV2Result = bet.getV2Result(state);
  return {

    lottoScheduleV2Data: lottoScheduleV2.data,
    lottoScheduleV2IsFetching: lottoScheduleV2.isFetching,
    lottoScheduleV2Code: lottoScheduleV2.code,
    lottoScheduleV2Error: lottoScheduleV2.error,

    getV2ResultData: getV2Result.data,
    getV2ResultIsFetching: getV2Result.isFetching,
    getV2ResultCode: getV2Result.code,
    getV2ResultError: getV2Result.error,
  };
};

const mapDispatchToProps: IBetActionProps = {
  getDataLotteryList,
  onChangeIsLoaddingAction,
  getDataV2LotteryList,
  getV2DataResult
};

export default connect(mapStateToProps, mapDispatchToProps)(BetContainer);
