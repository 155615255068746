import { connect } from "react-redux";
import ResetUserPasswordComponent from "./ResetUserPassword.container";
import main from "reduxs/main/selector";
import { onChangeIsLoaddingAction } from "reduxs/Loader/action";
import { postResetPassword } from "reduxs/main/resetPassword/action";

const mapStateToProps = (state: RootReducers): IResetUserPasswordProps => {
  const mainState = main.resetPassword(state);
  return {
    resetPasswordCode: mainState.code,
    resetPasswordIsFetching: mainState.isFetching,
    resetPasswordError: mainState.error,
    resetPasswordData: mainState.data
  };
};
const mapDispatchToProps: IResetUserPasswordActionProps = {
  onChangeIsLoaddingAction: onChangeIsLoaddingAction,
  resetPassword: postResetPassword
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetUserPasswordComponent);
