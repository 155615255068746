import { get } from "lodash";
import {
  creditsState,
  transactionListsState,
  transferCreditDescriptionState,
  transferCreditListState,
  transferConfirmationListState,
  PayRequestState,
  PayAcceptState,
} from "./constants";

const credits = (state: RootReducers): ReducerState<IResponseAgentFinanceMe> =>
  get(state, "credits.credits", creditsState);
const transactionLists = (state: RootReducers): ReducerState<Array<any>> =>
  get(state, "credits.transactionLists", transactionListsState) as any;
const transferCredits = (
  state: RootReducers
): ReducerState<APIPagination<IResponseAgentFinancePay>> =>
  get(state, "credits.transferCredits", transferCreditListState);
const transferConfirmations = (
  state: RootReducers
): ReducerState<APIPagination<IResponseAgentRecvList>> =>
  get(state, "credits.transferConfirmations", transferConfirmationListState);
const transferCreditDescription = (
  state: RootReducers
): ReducerState<Array<IResponseAgentFinancePayDetail>> =>
  get(
    state,
    "credits.transferCreditDescription",
    transferCreditDescriptionState
  );
const payRequests = (state: RootReducers): ReducerState<IAgentPayRequest> =>
  get(state, "credits.payRequests", PayRequestState);

const payAccepts = (state: RootReducers): ReducerState<IAgentPayAccept> =>
  get(state, "credits.payAccepts", PayAcceptState);

const creditSelector = {
  credits,
  transactionLists,
  transferCredits,
  transferCreditDescription,
  payRequests,
  transferConfirmations,
  payAccepts,
};
export default creditSelector;
