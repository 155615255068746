import { connect } from "react-redux";
import BetMakingContainer from "./BetMaking.container";
import { getRate } from "reduxs/lotto/bet/rate/rate/action";
import { getNumber } from "reduxs/lotto/bet/rate/number/action";
import { getGame } from "reduxs/lotto/game/action";
import listAutocompleteSelector from "../../reduxs/autocomplete/selector";
import { onChangeIsLoaddingAction } from "reduxs/Loader/action";
import { postMake } from "reduxs/lotto/bet/make/action";
import { getAutocompleteAction } from "../../reduxs/autocomplete/action";
import rateSelector from "reduxs/lotto/bet/rate/selector";
import lottoSelector from "reduxs/lotto/selector";
import betSelector from "reduxs/lotto/bet/selector";
import yegeeSelector from "reduxs/lotto/yegee/selector";
import { clearBetResult } from "reduxs/lotto/bet/result/action";
import {clearYegeeGameHistory ,postYegeeGameHistory} from "reduxs/lotto/yegee/gameHistory/action"

const mapStateToProps = (state: RootReducers): IBetMakingContainerProps => {
  const betRates = rateSelector.betRate(state);
  const betNumberRates = rateSelector.betNumber(state);
  const lottoGame = lottoSelector.game(state);
  const makingBetLotto = betSelector.make(state);
  const listAutocompleteState = listAutocompleteSelector.Autocomplete(state);
  const gameHistory = yegeeSelector.gameHistory(state);
  return {
    betRatesData: betRates.data,
    betRatesIsFetching: betRates.isFetching,

    betNumberRatesData: betNumberRates.data,
    betNumberRatesIsFetching: betNumberRates.isFetching,
    betNumberRatesCode: betNumberRates.code,

    lottoGameData: lottoGame.data,
    lottoGameIsFetching: lottoGame.isFetching,
    lottoGameCode: lottoGame.code,

    makingBetLottoData: makingBetLotto.data,
    makingBetLottoIsFetching: makingBetLotto.isFetching,
    makingBetLottoCode: makingBetLotto.code,
    makingBetLottoError: makingBetLotto.error,

    listAutocompleteCode: listAutocompleteState.code,
    listAutocompleteError: listAutocompleteState.error,
    listAutocompleteIsFetching: listAutocompleteState.isFetching,
    listAutocompleteData: listAutocompleteState.data,

    gameHistoryCode: gameHistory.code,
    gameHistoryError: gameHistory.error,
    gameHistoryIsFetching: gameHistory.isFetching,
    gameHistoryData: gameHistory.data,
  };
};

const mapDispatchToProps: IBetMakingContainerActionProps = {
  getGame,
  getRate,
  getNumber,
  onChangeIsLoaddingAction,
  postMake,
  getAutocompleteAction,
  clearBetResult,
  clearYegeeGameHistory,
  postYegeeGameHistory
};
export default connect(mapStateToProps, mapDispatchToProps)(BetMakingContainer);
