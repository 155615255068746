import { AxiosError, AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import { resetPasswordAction } from '../actionCreator'
import { resetPassword } from '../services'

const postResetPassword = (dataReq: IResetUserPasswordRequest) => (dispatch: Dispatch) => {
    dispatch(resetPasswordAction.request({}))
    return resetPassword(dataReq)
        .then((res: AxiosResponse<IAPIResponse<object>>) => {
            dispatch(resetPasswordAction.success(res))
        })
        .catch((error: AxiosError<IAPIResponse>) => dispatch(resetPasswordAction.failure(error)))
}

export {
    postResetPassword
}