import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { postChildCreditUpdateAction } from "../actionCreator";
import { fetchPostChildCreditUpdate } from "../services";

const postChildCreditUpdate = (data: IV2AgentChildCreditUpdate) => (dispatch: Dispatch) => {
    dispatch(postChildCreditUpdateAction.request({}))
    return fetchPostChildCreditUpdate(data)
    .then((res: AxiosResponse<{}>) => {
        dispatch(postChildCreditUpdateAction.success(res))
        return Promise.resolve()
    })
    .catch((error: AxiosError<IAPIResponse>) => {
        dispatch(postChildCreditUpdateAction.failure(error))
        return Promise.reject()
    })
}

export { postChildCreditUpdate }