import React, { Component } from "react";
import colors from "constants/colors";
import { get, isEmpty, noop, pickBy } from "lodash";
import {
  Input,
  SkeletonLoader,
  Panginate,
  Dropdown,
  ErrorModal,
  Button,
  LoadingCircle,
} from "components";
import { th } from "date-fns/locale";
import { format } from "date-fns";
import { date, number } from "utils";
import { responseCode, responseMessage } from "constants/response";
import "./BetTable.style.scss";


const constants = { 
  winner:"ถูกรางวัล",
  lose:"ไม่ถูกรางวัล",
  wait:"รอผล",
  cancel:"ยกเลิก",
  success:"สำเร็จ",
  condtion:"มีเงื่อนไข หรือ ติดไว้ก่อน",
  waitprocess:"รอทำรายการ",
  nameLottery:"ชื่อหวย",
  betStatus:"สถานะเดิมพัน",
  paymentStatus:"สถานะเก็บเงิน",
  search:"ค้นหา",
  placeholderSearch:"ค้นหาเลขที่",
  rowPerPage: "จำนวนรายการต่อหน้า :",
  times: "ครั้ง",
  items: "รายการ",
  Baht: "บาท",
  percent: "%",
  dataEmpty: "ไม่มีข้อมูล...",
  headTable: [
    { codeName: "เลขที่", textalign: "text-left paddingtableHead" ,style:{width:`10%`}},
    { codeName: "วันที่", textalign: "text-left" ,style:{width:`20%`} },
    // { codeName: "หวย", textalign: "text-left",style:{width:`13%`} },
    // { codeName: "เลข", textalign: "text-left" ,style:{width:`8%`} },
    { codeName: "สถานะเดิมพัน", textalign: "text-left " ,style:{width:`20%`}},
    { codeName: "สถานะเก็บเงิน", textalign: "text-left " ,style:{width:`10%`}},
    { codeName: "ยอดเดิมพันทั้งหมด", textalign: "text-right"  },
    { codeName: "อัตราจ่าย", textalign: "text-right paddingTableLastCol"  },
  ],
};

const defaultProps: IBetTableProps = {
  getFinanceCustomerTx() {
    noop();
  },
  locationData: 0,
  onload() {},
  customerTxIsFetching: false,
  customerTxCode: 0,
  customerTxError: "",
  customerTxData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
 clickChangeRoute(){
   noop()
 }
};

class BetTable extends Component<IBetTableProps > {
  static defaultProps = defaultProps;
  state = {
    betStatusDropdownPlaceholder:"",
    paymentStatusDropdownPlaceholder:"",
    currentPage: 0,
    currentRowPerPage: 10,
    childSort: "",
    dataQuery: {
      limit: 10,
      page: 1,
      customerId: 0,
      agbeneBtName: "",
      agbeneBtStatus: "",
      agentShareId:"",
      status: "",
    },
    startPage: 1,
    endPage: 0,

    queryTimeout: setTimeout(() => {
      noop();
    }, 0),
    reqMemberDetail: {
      id: -99,
    },
    isSearch: false,
  };
  componentDidMount() {

    this.setState(
      { dataQuery: {...this.state.dataQuery, customerId: this.props.locationData } },
      () => {
        this.props.getFinanceCustomerTx({
          customerId: this.state.dataQuery.customerId,
        });
      }
    );
  }
  componentDidUpdate(prevProps: IBetTableProps) {
    if (this.props.customerTxIsFetching) {
      this.props.onload(true);
    } else {
      this.props.onload(false);
    }
    if (
      prevProps.customerTxIsFetching !== this.props.customerTxIsFetching &&
      !this.props.customerTxIsFetching
    ) {
      if (
        this.props.customerTxCode !== responseCode.OK &&
        this.props.customerTxError !== ""
      ) {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            this.props.customerTxCode,
            responseMessage()[0]
          ),
        });
      }
      if (this.props.customerTxCode === responseCode.OK) {
        if (isEmpty(this.props.customerTxData.dataList)) {
          this.setState({ startPage: 0, endPage: 0 });
        } else {
          this.setState({
            startPage:
              this.state.currentRowPerPage * (this.state.dataQuery.page - 1) +
              1,
            endPage:
              this.state.currentRowPerPage * (this.state.currentPage + 1) >=
              this.props.customerTxData.totalRecord
                ? this.props.customerTxData.totalRecord
                : this.state.currentRowPerPage * this.state.dataQuery.page,
          });
        }
      }
    }
  }




  handleQueryDropdownChange = (dropdown: string, queryValue: string) => {
    this.setState(
      {
        currentPage: 0,
        childAgentStatusDropdownPlaceholder: dropdown,
        dataQuery: {
          ...this.state.dataQuery,
          childAgentStatus: queryValue as TAgentStatus,
          page: 1,
        },
      },
      () => {
        this.props.getFinanceCustomerTx(pickBy(this.state.dataQuery));
      }
    );
  };

  handleQueryDropdownBetStatusChange = (dropdown: string, queryValue: string) => {
    this.setState(
      {
        currentPage: 0,
        betStatusDropdownPlaceholder: dropdown,
        dataQuery: {
          ...this.state.dataQuery,
          agbeneBtStatus: queryValue,
          page: 1,
        },
      },
      () => {
        this.props.getFinanceCustomerTx(pickBy(this.state.dataQuery));
      }
    );
  };

  handleQueryDropdownStatusPaymentChange = (dropdown: string, queryValue: string) => {
    this.setState(
      {
        currentPage: 0,
        paymentStatusDropdownPlaceholder: dropdown,
        dataQuery: {
          ...this.state.dataQuery,
          status: queryValue ,
          page: 1,
        },
      },
      () => {
        this.props.getFinanceCustomerTx(pickBy(this.state.dataQuery));
      }
    );
  };
  handleOnChangeSearch = (value: string) => {
    this.setState(
      {
        dataQuery: {
          ...this.state.dataQuery,
          agentShareId: value,
        },
        isSearch: true,
      },
      () => {
        clearTimeout(this.state.queryTimeout);
        this.setState({
          queryTimeout: setTimeout(() => {
            this.setState({
              isSearch: false,
            });
            this.props.getFinanceCustomerTx(pickBy(this.state.dataQuery))
          }, 1000),
        });
      }
    );
  };

  handleClickChangePage = ({ selected: selectedPage }: ISelectPage) => {
    this.setState({ currentPage: selectedPage }, () => {
      this.setState(
        {
          dataQuery: {
            ...this.state.dataQuery,
            page: this.state.currentPage + 1,
          },
        },
        () => {
          this.props.getFinanceCustomerTx(pickBy(this.state.dataQuery));
        }
      );
    });
  };

  renderFilter = () => (
    <div className="filterBox">
      <div className="row ">
        {/* <div className="col-2 firstCol">
          <div className="head-text-filter-box">{constants.nameLottery}</div>
          <div className="dropdownBorder2">
            <Dropdown
              menuStyles={{ width: 230 }}
              options={[
                {
                  value: "",
                  label: "ทั้งหมด",
                  searchableName: "",
                  disabled: false,
                },
                
              ]}
              controlBackgroundColor={colors.PRIMARY_BG}
              handleOnChange={(target: IOptions) => {
              }}
              placeholder={
                // this.state.childSort === "" ? "ชื่อ" : this.state.childSort
                "ทั้งหมด"
              }
            />
          </div>
        </div> */}
      
        <div className="col-2 firstCol">
          <div className="head-text-filter-box">{constants.betStatus}</div>
          <div className="dropdownBorder2">
            <Dropdown
              menuStyles={{ width: '100%' }}
              options={[
                {
                  value: "",
                  label: "ทั้งหมด",
                  searchableName: "",
                  disabled: false,
                },
                {
                  value: "",
                  label: "ถูกรางวัล",
                  searchableName: "WINNER",
                  disabled: false,
                },
                {
                  value: "",
                  label: "ไม่ถูกรางวัล",
                  searchableName: "LOSER",
                  disabled: false,
                },
                {
                  value: "",
                  label: "รอผล",
                  searchableName: "WAIT",
                  disabled: false,
                },
                {
                  value: "",
                  label: "ยกเลิก",
                  searchableName: "CANCEL",
                  disabled: false,
                },
                
              ]}
              controlBackgroundColor={colors.PRIMARY_BG}
              handleOnChange={(target: IOptions) => {
                this.handleQueryDropdownBetStatusChange(
                  String(target.label),
                  String(target.searchableName)
                );
              }}
              placeholder={
                this.state.betStatusDropdownPlaceholder === "" ? "ทั้งหมด" : this.state.betStatusDropdownPlaceholder
              }
            />
          </div>
        </div>
        <div className="col-2 ">
          <div className="head-text-filter-box">{constants.paymentStatus}</div>
          <div className="dropdownBorder2">
            <Dropdown
              menuStyles={{ width: '100%' }}
              options={[
                {
                  value: "",
                  label: "ทั้งหมด",
                  searchableName: "",
                  disabled: false,
                },
                {
                  value: "",
                  label: "สำเร็จ",
                  searchableName: "SUCCESS",
                  disabled: false,
                },
                // {
                //   value: "",
                //   label: "มีเงื่อนไข หรือ ติดไว้ก่อน",
                //   searchableName: "CONDITION",
                //   disabled: false,
                // },
                {
                  value: "",
                  label: "รอทำรายการ",
                  searchableName: "WAIT",
                  disabled: false,
                },
                
              ]}
              controlBackgroundColor={colors.PRIMARY_BG}
              handleOnChange={(target: IOptions) => {
                this.handleQueryDropdownStatusPaymentChange(
                  String(target.label),
                  String(target.searchableName)
                );
              }}
              placeholder={
                 this.state.paymentStatusDropdownPlaceholder === "" ? "ทั้งหมด" : this.state.paymentStatusDropdownPlaceholder
              }
            />
          </div>
        </div>
      <div className="col lastCol">
        <div className="search-box">
          <div className="head-text-filter-box">{constants.search}</div>
          <div className="inputBox">
            <Input
              backgroundColor={colors.PRIMARY_BG}
              borderRadius="4px"
              inputClassName="inputtt"
              setPadding="6px"
              placeholder={constants.placeholderSearch}
              size={"large"}
              value={this.state.dataQuery.agentShareId}
              id={""}
              name={""}
              onChange={(e) => {
                this.handleOnChangeSearch(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      </div>
    </div>
  );


  renderSwitchStatus(status: String) {
    switch (status) {
      case "WINNER":
        return <div className="statusWin">{constants.winner}</div>;
      case "LOSER":
        return <div className="statusLose">{constants.lose}</div>;
      case "WAIT":
        return <div className="statusWait">{constants.wait}</div>;
      case "CANCEL":
        return <div className="statusCancel">{constants.cancel}</div>;
      default:
        return "";
    }
  }

  renderSwitchStatusPayment(status: String) {
    switch (status) {
      case "SUCCESS":
        return <div className="statusSuccess">{constants.success}</div>;
      case "CONDITION":
        return (
          <div className="statusCondition">{constants.condtion}</div>
        );
      case "WAIT":
        return <div className="statusWaitPay">{constants.waitprocess}</div>;
      default:
        return "";
    }
  }

  tableMembersBody = () => {
    if (this.state.isSearch) {
      return (
        <tr className={`row-members-list color-row`}>
          <td className="text-center color-row p-2" colSpan={15}>
            <SkeletonLoader customStyle={[[{ col: 12, show: true }]]} />
          </td>
        </tr>
      );
    } else {
      const loadingTable = (
        <tr>
          <td className="text-center" colSpan={15}>
            <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
          </td>
        </tr>
      )
      const dataEmpty = (
        <tr>
          <td className="text-center" colSpan={15}>
            {constants.dataEmpty}
          </td>
        </tr>
      )
      const mapData =  this.props.customerTxData.dataList.map(
        (data: IV2RespAgentFinanceCustomerTx, index: number) => {

          return (
            <tr className={`row-members-list `} key={index}>
              <td className="text-left paddingRow">
                <span className="paddingtableHead"> 
                {data.agentShareId}
                </span>
              </td>
              <td className="text-left ">
                <span>
                  {`${format(
                    date.calibratingTime(data.agbeneCreatedAt, true),
                    "dd MMM yyyy HH:mm",
                    { locale: th }
                  )}`}
                </span>
              </td>
              {/* <td className="text-left">
                <span>{get(CODE_TO_SHORT_NAME, `[${data.agbeneBtName}]`, "")}</span>
              </td>
              <td className="text-left">
                <span>{data.agbeneBtNumber}</span>
              </td> */}
              <td className="text-left">
                <span>{this.renderSwitchStatus(data.agbeneBtStatus)}</span>
              </td>
              <td className="text-left">
                <span>{this.renderSwitchStatusPayment(data.agbeneStatus)}</span>
              </td>
  
              <td className="text-right">
                <span>
                  {number.addComma(data.agbeneBetValueTotal)} {constants.Baht}
                </span>
              </td>
              <td className="text-right paddingTableLastCol">
                <span>
                  {number.addComma(data.agbeneRate)} {constants.Baht}
                </span>
              </td>
            </tr>
          )
        }
      );
      return this.props.customerTxIsFetching ? loadingTable : !isEmpty(mapData) ? mapData : dataEmpty
    }
  };
  renderTableMembers = () => (
    <div className="table-container">
      <div className="table-responsive-xl">
        <table className="col ">
          <thead className="">
            <tr className="tableHead">
              {constants.headTable.map((data, index: number) => (
                <th key={index} className={data.textalign} style={data.style}>
                  <span>{data.codeName}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{this.tableMembersBody()}</tbody>
        </table>
      </div>
    </div>
  );

  render() {
    const RenderFilter = this.renderFilter();
    const RenderTableMembers = this.renderTableMembers();
    return (
      <div className="betTable-container">
          <div className="row m3-t">
            <div className="col-1">
              <Button
                id={"add-member"}
                size={"moreMediumNormalHeight"}
                textSize={18}
                text={"ดูข้อมูลสรุปการเดิมพัน"}
                onClick={() => {this.props.clickChangeRoute()}}
              />
            </div>
          </div>

          <div className="row">
            <div className="col m3-t">{RenderFilter}</div>
          </div>

          <div className="row ">
            <div className="col ">{RenderTableMembers}</div>
          </div>
          <div className="row">
            <div className="col d-flex justify-content-end align-items-center ">
              <h5 className="rowperpageFont">{constants.rowPerPage}</h5>
              <div className="dropdown-rowPerPage ">
                <Dropdown
                  menuPlacement={"top"}
                  options={[
                    { value: 5, label: "5", disabled: false },
                    { value: 10, label: "10", disabled: false },
                    { value: 20, label: "20", disabled: false },
                    { value: 50, label: "50", disabled: false },
                    { value: 100, label: "100", disabled: false },
                  ]}
                  controlBackgroundColor={colors.PRIMARY_BG}
                  placeholder={String(this.state.currentRowPerPage)}
                  handleOnChange={(target: IOptions) => {
                    this.setState(
                      {
                        currentPage: 0,
                        currentRowPerPage: Number(target.value),
                        dataQuery: {
                          ...this.state.dataQuery,
                          page: 1,
                          limit: Number(target.value),
                        },
                      },
                      () => {
                        this.props.getFinanceCustomerTx(
                          pickBy(this.state.dataQuery)
                        );
                      }
                    );
                  }}
                />
              </div>
            </div>
            <h5 className="d-flex justify-content-end align-items-center rowperpageFont">
              {`${this.state.startPage} - ${this.state.endPage}  of ${this.props.customerTxData.totalRecord}`}
            </h5>
            <div className="col-auto">
              <Panginate
                pageTotal={this.props.customerTxData.total}
                initialPage={this.state.dataQuery.page - 1}
                pageRangeDisplayed={this.state.currentPage}
                onPageChange={this.handleClickChangePage}
              />
            </div>
          </div>
        </div>
    );
  }
}

export default BetTable;
