import { Breadcrumb, ButtonRadio, DatePickerNew, Dropdown, ErrorModal, Input, ModalPrintBill, Panginate, CancelBetModal, SuccessModal, LoadingCircle } from 'components';
import { CODE_TO_NAME, LOTTO_GAME_TYPE_NAME, meDataInitialValue } from 'constants/variables';
import { addMinutes, endOfWeek, format, startOfWeek, subMinutes } from 'date-fns';
import {
  get,
  includes,
  isEmpty,
  isNull,
  map,
  // maxBy,
  noop,
  orderBy,
} from 'lodash';
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router';
import { date, number,/*  responseConverter, */ transformer } from 'utils';
import { thai_th } from "constants/thai_th"
import point from "assets/images/global/icon/point.png";
// import pdfMake from "pdfmake/build/pdfmake";
// import docDefinition from "../NewBetMaking/docDefinition";

import './betMeHistory.style.scss'
import colors from 'constants/colors';
// import { getV2AgentBet } from 'reduxs/lotto/bet/services';
// import { AxiosError, AxiosResponse } from 'axios';
// import { responseMessage } from 'constants/response';
import routes from 'constants/routes';
import CryptoJS from "crypto-js";
import { responseMessage } from 'constants/response';
const listOptionSearchRow1: { [type in string]: string } = {
  TODAY: "วันนี้",
  THIS_WEEK: "สัปดาห์นี้",
  MONTH: "เดือน",
}

const listOptionSearchRow2: { [type in string]: string } = {
  YESTERDAY: "เมื่อวาน",
  LAST_WEEK: "สัปดาห์ที่แล้ว",
  SINCE: "ตั้งแต่",
}

const listOptionStatus: { [type in IStatusBetMe | "All"]: string } = {
  All: "ทั้งหมด",
  WAIT: "รอผล",
  WINNER: "ถูกรางวัล",
  LOSER: "ไม่ถูกรางวัล",
  CANCEL: "ยกเลิก",
}

const listActionItem: { [type in string]: string } = {
  BillPrint: "ออกใบเสร็จ",  //ออกบิล
  BetSumHistory: "สรุปการเดิมพัน",  // ไปหน้าสรุปการเดิมพัน
  CustomerList: "ข้อมูลลูกค้า",   //ไปหน้าข้อมูลลูกค้า
  FinanceHost: "เคลียร์ยอดหัวสมาชิก",  //ไปหน้า
  FinanceChild: "เคลียร์ยอดลูกสมาชิก",  //ไปหน้า
  FinanceCustomer: "เคลียร์ยอดลูกค้า",  //ไปหน้า
  CancelBill: "ยกเลิกโพยทั้งบิล",
}

const listOptionLotto: { [type in TLottoType | "All"]: string } = { ...{ All: "ทั้งหมด" }, ...CODE_TO_NAME }

type DefaultProps = Readonly<typeof defaultProps>;
const constants = {
  mainPageTitle: "ภาพรวม",
  //  betTitle:"การเดิมพัน",
  betMeTitle: "รายการเดิมพัน",

  paperTitle: "รายการเดิมพัน",
  paperDescription: "รายการเดิมพันแยกตามตัวเลข",

  dataNotFound: "ไม่พบข้อมูล...",

  rowPerPage: "จำนวนรายการต่อหน้า : ",
  optionSearch: "ตัวเลือกการค้นหา",

  cancleBet: "ยืนยันยกเลิกโพย",
  confirmCancleBet: "ข้อมูลการเดิมพันของลูกค้าในงวดดังกล่าวจะถูกยกเลิกด้วยทั้งหมด คุณแน่ใจที่จะยกเลิกโพยหรือไม่",
  titlePooiSuccess: "สำเร็จ",
  cancelPooiSuccess: "ยกเลิกโพยสำเร็จ",
};

const mappingTheadLottoฺBill: IHeaderLottoTable[] = [
  { id: 1, name: "เลขบิล" },
  { id: 2, name: "ชื่อช่วยจำ" },
  { id: 3, name: "หวย" },
  { id: 4, name: "ประเภท" },
  { id: 5, name: "เลข" },
  { id: 6, name: "อัตราจ่าย" },
  { id: 7, name: "ถือสู้" },
  { id: 8, name: "แบ่งหุ้น" },
  { id: 9, name: "ยอดเดิมพัน" },
  { id: 10, name: "ส่วนลด" },
  { id: 11, name: "ยอด" },
  { id: 12, name: "ค่าคอม" },
  { id: 13, name: "แพ้/ชนะ" },
  { id: 14, name: "เวลา" },
  { id: 15, name: "" },
];

const defaultProps: IBetMeHistoryContainerProps & IBetMeHistoryContainerAction = {
  onChangeIsLoaddingAction() { noop() },
  getAgentBet() { noop() },
  getDataV2LotteryList() { noop() },
  postCancelBill() { noop() },
  getMe() {
    noop();
  },
  meData: meDataInitialValue,
  getAgentData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  getAgentIsFetching: false,
  getAgentCode: 0,
  getAgentError: "",

  lottoScheduleV2Data: [],
  lottoScheduleV2IsFetching: false,
  lottoScheduleV2Code: 0,
  lottoScheduleV2Error: "",

  postCancelBillCode: 0,
  postCancelBillError: "",
  postCancelBillIsFetching: false,
};

export default class BetMeHistoryContainer extends Component<
  IBetMeHistoryContainerProps & IBetMeHistoryContainerAction & DefaultProps & RouteComponentProps
> {
  static defaultProps = defaultProps;

  queryTimeout: NodeJS.Timeout | null = null;

  state: IBetMeHistoryContainerState = {
    currentPage: { selected: 1 },
    page: {
      page: 1,
      limit: 10
    },
    selectedOptionSearch: "",
    searchMonths: -1,
    dateStart: null,
    dateEnd: null,
    querySearch: {
      customerName: "",
      startTime: "",
      endTime: "",
      number: "",
      status: "",
      name: "",
    },
  };

  componentDidMount() {
    this.props.getDataV2LotteryList()
    let locationState = this.props.location.state
    const createdAt = (get(locationState, "createdAt", ''))
    const customerName = (get(locationState, "customerName", ''))
    const customerId = (get(locationState, "customerId", ''))
    if ((!isEmpty(locationState)) && (!isEmpty(createdAt)) && (!isEmpty(customerId)) && (!isEmpty(customerName))) {
      this.setState({
        querySearch: {
          ...this.state.querySearch,
          customerName: customerName,
          startTime: format(subMinutes(date.calibratingTime(createdAt), 1), "yyyy'-'MM'-'dd'T'HH:mm:ss"),
          endTime: format(addMinutes(date.calibratingTime(createdAt), 1), "yyyy'-'MM'-'dd'T'HH:mm:ss"),
        }
      }, () => {
        this.props.getAgentBet({
          ...this.state.page,
          ...this.state.querySearch,
          customerId: customerId
        })
      })
    } else {
      this.fetchGetData()
    }
  }

  componentDidUpdate(prevProps: IBetMeHistoryContainerProps) {
    if ((prevProps.postCancelBillIsFetching !== this.props.postCancelBillIsFetching) && !this.props.postCancelBillIsFetching) {
      if (this.props.postCancelBillError !== "") {
        ErrorModal.show({
          action: () => ErrorModal.hide(),
          description: get(
            responseMessage(),
            this.props.postCancelBillCode,
            responseMessage()[0]
          ),
        });
      }
      else{
        SuccessModal.show({
          action: () => {
            SuccessModal.hide()
            this.props.getMe()
            this.fetchGetData()
          },
          title: constants.titlePooiSuccess,
          description: constants.cancelPooiSuccess,
        })
      }
    }
  }

  fetchGetData = () => {
    this.props.getAgentBet({
      ...this.state.page,
      ...this.state.querySearch
    })
  }

  renderTableHead = (values: Array<IHeaderLottoTable>) => {
    const mapHeadTable = map(
      values,
      (data: IHeaderLottoTable, index: number) => {
        let classText: string = ''
        if (index === 4 || index === 12 || index === 13) {
          classText = 'text-center'
        } else if (index === 5 || index === 6 || index === 7 || index === 8 || index === 9 || index === 10 || index === 11) {
          classText = 'text-right'
        } else if (index === 0 || index === 1 || index === 2 || index === 3) {
          classText = 'text-left'
        }
        return <th className={`${classText}`} key={index}>
          <h5 className={`text-header secondary-text ${index === 0 && "ml-3"}`}>{data.name}</h5>
        </th>
      }
    );
    return mapHeadTable;
  };

  textStatus = (status: IStatusBetMe, win: number) => {
    switch (status) {
      case "WINNER":
        return number.decimalsFormatComma(win)
      case "WAIT":
        return 'รอผล'
      case "CANCEL":
        return 'ยกเลิก'
      case "LOSER":
        return 'ไม่ถูกรางวัล'
      default:
        return status
    }
  }

  renderTableBillBody = (values: IV2RespAgentLotteryBetMe[]) => {
    const dataEmpty = (
      <tr>
        <td className="text-center" colSpan={15}>
          {constants.dataNotFound}
        </td>
      </tr>
    );

    const loadingTable= (
      <tr>
        <td className="text-center" colSpan={15}>
        <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
        </td>
      </tr>
    )
    
    const mapData = map(values, (item: IV2RespAgentLotteryBetMe, index: number) => {
      let data = {
        cuscustomerId: item.customerId,
        agentShareId: item.agentShareId,
        customerName: item.customerName,
        name: item.name,
        type: item.type,
        number: item.number,
        rate: item.rate,
        totalBet: item.totalBet,
        totalMeBet: item.totalMeBet,
        totalHostBet: item.totalHostBet,
        totalDis: item.totalDis,
        pay: item.totalBet - item.totalDis,
        totalCom: item.totalCom,
        // status: index%4===1?"WINNER":index%4===2?"WAIT":index%4===3?"CANCEL":"LOSER" /*item.status*/,
        status: item.status,
        createdAt: date.formatThai(item.createdAt, 'dd MMM yyyy')
      }
      return (
        <tr
          className={`row-bill-list primary-bg`}
          key={index}
          style={{ height: 36 }}
        // onClick={() => this.handleClickUser(data)}
        >
          <td className="text-left pl-3">
            <span>{data.agentShareId}</span>
          </td>
          <td className="text-left">
            <span>{data.customerName}</span>
          </td>
          <td className="text-left">
            <span>{(CODE_TO_NAME[includes(data.name, "YEGEE") ? "YEGEE" : data.name]).replace("หวย", "") + (includes(data.name, "YEGEE") ? ` รอบที่ ${data.name.replace("YEGEE_", '')}` : "")}</span>
          </td>
          <td className="text-left">
            <span>{LOTTO_GAME_TYPE_NAME[data.type]}</span>
          </td>
          <td className="text-center">
            <span>{data.number}</span>
          </td>
          <td className="text-right">
            <span>{number.decimalsFormatComma(data.rate)}</span>
          </td>

          <td className="text-right">
            <span>{number.decimalsFormatComma(data.totalMeBet)}</span>
          </td>
          <td className="text-right">
            <span>{number.decimalsFormatComma(data.totalHostBet)}</span>
          </td>
          <td className="text-right">
            <span>{number.decimalsFormatComma(data.totalBet)}</span>
          </td>
          <td className="text-right">
            <span>{number.decimalsFormatComma(data.totalDis)}</span>
          </td>
          <td className="text-right">
            <span>{number.decimalsFormatComma(data.pay)}</span>
          </td>
          <td className="text-right">
            <span>{number.decimalsFormatComma(data.totalCom)}</span>
          </td>
          <td className="text-center">
            <span className={`${data.status} text-status`}>{this.textStatus(data.status, data.totalBet * data.rate)}</span>
          </td>
          <td className="text-center">
            <span>{data.createdAt}</span>
          </td>
          <td className="text-letf">
            <span>
              <Dropdown
                options={Object.keys(listActionItem).map((item) => {
                  return { value: item, label: listActionItem[item], disabled: data.status === 'CANCEL' && item === 'CancelBill'}
                })}
                iconOpen={point}
                iconClose={point}
                isManualOpenMenu
                controlBackgroundColor={colors.PRIMARY_BG}
                menuStyles={{ width: "175px", left: "-150px" }}
                valueContainerStyles={{
                  padding: "0px",
                  maxWidth: "0px",
                }}
                handleOnChange={(target: IOptions) => {
                  if (target.value === 'BillPrint') {
                    this.modalPrintBill(item)
                  } else if (target.value === 'BetSumHistory') {
                    this.props.history.push(routes.betSumHistory.path, {
                      customerId: item.customerId.toString(),
                      name: item.name,
                    })
                  } else if (target.value === 'CustomerList') {
                    this.props.history.push(routes.customerList.path + transformer.urlSearchParams({ customerId: item.customerId }))
                  }
                  else if (target.value === 'FinanceHost') {
                    this.props.history.push(routes.financeHost.path + transformer.urlSearchParams({ agentShareId: item.agentShareId }))
                  } else if (target.value === 'FinanceChild') {
                    this.props.history.push(routes.financeChild.path + `/?agentShareId=${item.agentShareId}`)
                  } else if (target.value === 'FinanceCustomer') {
                    this.props.history.push(routes.financeCustomerDetails.path + transformer.urlSearchParams({ agentShareId: item.agentShareId }), { rowIndex: index })
                  }
                  else if (target.value === 'CancelBill') {
                    CancelBetModal.show({
                      size: "md",
                      title: constants.cancleBet,
                      description: constants.confirmCancleBet,
                      action: () => {
                        this.props.postCancelBill({
                          agentShareId: data.agentShareId
                          , customerId: data.cuscustomerId
                          , name: data.name
                          , mode: 'CANCEL_PER_CLOTTERY'
                        })
                        CancelBetModal.hide()
                      },
                      cancelAction: CancelBetModal.hide
                    })
                  }
                }}
              />
            </span>
          </td>

        </tr>
      );
    });
    return this.props.getAgentIsFetching ? loadingTable : !isEmpty(values) ? mapData : dataEmpty
  };

  modalPrintBill = (data: IV2RespAgentLotteryBetMe) => {
    ModalPrintBill.show({
      actionPrintShowPay: () => {
        const dataString = JSON.stringify(data)
        const encrypted = CryptoJS.AES.encrypt(dataString, 'bill-query')
        const urlEncoded = encodeURIComponent(encrypted.toString())
        window.open(`${routes.previewBetBill.exactPath}/${urlEncoded}?showpaid=yes&name=${this.props.meData.username}`)
      },
      actionPrintNotShowPay: () => {
        const dataString = JSON.stringify(data)
        const encrypted = CryptoJS.AES.encrypt(dataString, 'bill-query')
        const urlEncoded = encodeURIComponent(encrypted.toString())
        window.open(`${routes.previewBetBill.exactPath}/${urlEncoded}?name=${this.props.meData.username}`)
      },
      actionCancel: () => {
        ErrorModal.hide()
      },
    })
  }

  // getDataBill = (data: IV2RespAgentLotteryBetMe,showPay:boolean)=>{
  //   getV2AgentBet({
  //     limit:999999,
  //     name: data.name,
  //     customerId: data.customerId,
  //     agentShareId: data.agentShareId,
  //     startTime:  format(date.calibratingTime(data.createdAt), "yyyy'-'MM'-'dd'T'HH:mm:ss") ,
  //     endTime:  format(addMinutes(date.calibratingTime(data.createdAt),1), "yyyy'-'MM'-'dd'T'HH:mm:ss") ,
  //   }).then((res: AxiosResponse<IAPIResponse<IPagination<IV2RespAgentLotteryBetMe>>>)=>{
  //     const dataList = res.data.data.dataList
  //     if(!isEmpty(dataList)){
  //       const dataName = transformer.nameLotto(get(dataList,"[0].name"),true,true)
  //       const dataCustomerName = get(dataList,"[0].customerName")
  //       const dateNow = format(addYears(date.calibratingTime(get(dataList,"[0].createdAt")),543), "ddMMyyyy")
  //       const nameFile = `${dataName}_${dataCustomerName}_${dateNow}`

  //       let status = get(dataList,"[0].status",'')
  //       let round = ''
  //       if (status!=='WAIT') {
  //         round = get(maxBy(dataList,(i) => i.updatedAt),"updatedAt",'')
  //         round = format(addYears(date.calibratingTime(round),543), "dd MMMM yyyy", {locale: th})
  //       }else{
  //         let findData = this.props.lottoScheduleV2Data.filter((i)=>i.name===data.name)
  //         if (isEmpty(findData)) {
  //           round = "ไม่พบข้อมูลงวด"
  //         }else{
  //           round = format(addYears(date.calibratingTime(findData[0].endTime),543), "dd MMMM yyyy", {locale: th})
  //         }
  //       }
  //       pdfMake.createPdf(docDefinition(dataList,showPay,round) as any).download(nameFile)
  //     }
  //   })
  //   .catch((error: AxiosError<IAPIResponse>)=>{
  //     ErrorModal.show({
  //       action: ErrorModal.hide,
  //       description: get( responseMessage(), responseConverter.getMessage(error).code, responseMessage()[0] ),
  //     });
  //   })
  // }

  handleClickChangePage = ({ selected: selectedPage }: ISelectPage) => {
    this.setState({ currentPage: { selected: selectedPage } }, () => {
      this.setState({
        page: { ...this.state.page, page: this.state.currentPage.selected + 1 },
      },
        () => { this.fetchGetData() }
      )
    })
  }

  handleClickButtonRadio = (val: string) => {
    if (this.state.selectedOptionSearch === val) {
      this.setState({
        page: { ...this.state.page, page: 1 },
        selectedOptionSearch: '',
        searchMonths: -1,
        dateStart: null,
        dateEnd: null,
        querySearch: {
          ...this.state.querySearch,
          startTime: "",
          endTime: ""
        }
      }, () => { this.fetchGetData() })
    }
  }

  handleOnChangeButtonRadio = (val: ISelectedOptionSearch) => {
    let startDate: Date = new Date()
    let endDate: Date = new Date()
    this.setState({ dateStart: null, dateEnd: null, searchMonths: -1 })
    if (val === "MONTH" || val === "SINCE") {
      this.setState({ selectedOptionSearch: val, page: { ...this.state.page, page: 1 } })
    } else {
      if (val === "TODAY") {
        startDate = new Date()
        endDate = new Date()
      } else if (val === "YESTERDAY") {
        startDate = new Date(new Date().setDate(new Date().getDate() - 1));
        endDate = new Date(new Date().setDate(new Date().getDate() - 1));
      } else if (val === "THIS_WEEK") {
        startDate = new Date(startOfWeek(new Date(), { weekStartsOn: 0 }));
        endDate = new Date(endOfWeek(new Date(), { weekStartsOn: 0 }));
      } else if (val === "LAST_WEEK") {
        startDate = new Date(startOfWeek(new Date(new Date().setDate(new Date().getDate() - 7)), { weekStartsOn: 0 }));
        endDate = new Date(endOfWeek(new Date(new Date().setDate(new Date().getDate() - 7)), { weekStartsOn: 0 }));
      }
      this.setState(
        {
          page: { ...this.state.page, page: 1 },
          selectedOptionSearch: val,
          querySearch: {
            ...this.state.querySearch,
            startTime: format((startDate), "yyyy'-'MM'-'dd'T'00:00:00"),
            endTime: format((endDate), "yyyy'-'MM'-'dd'T'23:59:59")
          }
        }, () => { this.fetchGetData() }
      )
    }
  }

  textPageNow = () => {
    // จำนวนข้อมูล * หน้าที่เปิด
    let endPage = this.props.getAgentData.limit * this.state.page.page
    // เลขลำดับท้าย - จำนวนข้อมูล+1
    let startPage = (endPage) - this.props.getAgentData.limit + 1
    let total = this.props.getAgentData.totalRecord
    return `${startPage > total ? total : startPage}-${endPage > total ? total : endPage} of ${total}`
  }

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.mainPageTitle, active: false, path: "/dashboard" },
      // { label: constants.betTitle, active: false, path: "/bet" },
      { label: constants.betMeTitle, active: true },
    ];

    const TableHeadBill = this.renderTableHead(mappingTheadLottoฺBill);
    const TableBodyeBill = this.renderTableBillBody(this.props.getAgentData.dataList);

    return (
      <div className="paper-container betMe-history-container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="sub-menu" style={{ width: "fit-content" }}>
              <Breadcrumb
                items={navigates}
                handleOnClickItem={(path: string) => this.props.history.replace(path)}
              />
            </div>
          </div>
        </div>
        <div className="paper-title">{constants.paperTitle}</div>
        <div className="paper-description">{constants.paperDescription}</div>
        <div className="paper-body">
          <div className="box-shadow">
            <h5 className='text-bold'>{constants.betMeTitle}</h5>
            <div className='option-search my-3'>
              <div className='row'>
                <div className='col-12'>
                  <h6 className='text-bold'>{constants.optionSearch}</h6>
                </div>
              </div>
              <div className='row mt-2 d-flex align-items-center box-search'>
                <div className='box-buttonRadio ml-3'>
                  <ButtonRadio
                    value={this.state.selectedOptionSearch}
                    datas={
                      Object.keys(listOptionSearchRow1).map((item, index) => {
                        return { id: item, value: item, text: listOptionSearchRow1[item as ISelectedOptionSearch] }
                      })
                    }
                    className="row justify-content-between"
                    classNameRadio='buttonRadio-item px-0'
                    name="listOptionSearch"
                    onClick={this.handleClickButtonRadio}
                    onChange={this.handleOnChangeButtonRadio}
                  />
                </div>
                <div className='col-3 box-search-item'>
                  <div className={`dropdown-box ${this.state.selectedOptionSearch !== "MONTH" && "disable"}`}>
                    <Dropdown
                      value={this.state.searchMonths === -1 ? undefined : this.state.searchMonths}
                      options={(thai_th.months).map((item, index) => { return { value: index, label: item[0], disabled: false } })}
                      controlBackgroundColor={this.state.selectedOptionSearch !== "MONTH" ? colors.SECONDARY_BG : colors.PRIMARY_BG}
                      placeholder="ค้นหาเดือน"
                      disable={this.state.selectedOptionSearch !== "MONTH"}
                      menuStyles={{ width: "100%" }}
                      handleOnChange={(target: IOptions) => {
                        let startDate = new Date(new Date().getFullYear() - (new Date().getMonth() < Number(target.value) ? 1 : 0), Number(target.value), 1)
                        let endDate = new Date(new Date().getFullYear() - (new Date().getMonth() < Number(target.value) ? 1 : 0), Number(target.value) + 1, 0)
                        this.setState(
                          {
                            searchMonths: target.value,
                            page: { ...this.state.page, page: 1 },
                            querySearch: {
                              ...this.state.querySearch,
                              startTime: format((startDate), "yyyy'-'MM'-'dd'T'00:00:00"),
                              endTime: format((endDate), "yyyy'-'MM'-'dd'T'23:59:59")
                            }
                          }, () => { this.fetchGetData() }
                        )
                      }}
                    />
                  </div>
                </div>
                <div className='col-3 box-search-item'>
                  <div className='dropdown-box'>
                    <Dropdown
                      value={(this.state.querySearch.name)}
                      valueContainerStyles={{ fontSize: "13px" }}
                      iconStyles={{ color: colors.TERTIARY_TEXT }}
                      optionStyles={{ fontSize: "13px" }}
                      options={orderBy(Object.keys(listOptionLotto).map((item) => { return { value: item, label: listOptionLotto[item as TLottoType].replace("หวย", ''), disabled: false } }), [(i) => { return i.value === 'All' ? 0 : i.value === 'GOVN' ? 1 : 2 }, 'label'], ['asc', 'asc']).filter((i) => i.value !== 'HANOI')}
                      controlBackgroundColor={colors.PRIMARY_BG}
                      placeholder={isEmpty(this.state.querySearch.name) ? 'หวย' : listOptionLotto[this.state.querySearch.name as TLottoType]}
                      menuStyles={{ width: "100%" }}
                      handleOnChange={(target: IOptions) => {
                        this.setState({
                          page: { ...this.state.page, page: 1 },
                          querySearch: { ...this.state.querySearch, name: target.value === "All" ? "" : target.value }
                        },
                          () => { this.fetchGetData() }
                        )
                      }}
                    />
                  </div>
                </div>
                <div className='col-3 box-search-item'>
                  <Input
                    id="search-number"
                    name="search-number"
                    inputClassName="input-search"
                    placeholder="เลขที่เดิมพัน"
                    backgroundColor={colors.PRIMARY_BG}
                    value={this.state.querySearch.number}
                    onChange={(e) => {
                      if (e.target.value.length <= 3) {
                        this.setState({
                          page: { ...this.state.page, page: 1 },
                          querySearch: { ...this.state.querySearch, number: e.target.value.replace(/[^0-9]/g, '') }
                        }, () => {
                          clearTimeout(this.queryTimeout!)
                          this.queryTimeout = setTimeout(() => {
                            this.fetchGetData()
                          }, 1000)
                        })
                      }
                    }}
                    renderError={false}
                  />
                </div>
              </div>
              <div className='row mt-2 d-flex align-items-center box-search'>
                <div className='box-buttonRadio ml-3'>
                  <ButtonRadio
                    value={this.state.selectedOptionSearch}
                    datas={
                      Object.keys(listOptionSearchRow2).map((item, index) => {
                        return {
                          id: item.toString(),
                          value: item,
                          text: listOptionSearchRow2[item as ISelectedOptionSearch]
                        }
                      })
                    }
                    className="row justify-content-between"
                    classNameRadio='buttonRadio-item px-0'
                    name="listOptionSearch"
                    onClick={this.handleClickButtonRadio}
                    onChange={this.handleOnChangeButtonRadio}
                  />
                </div>
                <div className='col-3 box-search-item'>
                  <DatePickerNew
                    isShowIcon
                    value={this.state.dateStart}
                    placeholder="วันเริ่มต้น"
                    format="DD MMM YYYY"
                    editable={false}
                    // render={<img src={calendar}  alt="calendar-icon"/>}
                    onChange={(value: any) => {
                      if (value !== null) {
                        let startDate = new Date(Number(value))
                        this.setState(
                          {
                            dateStart: value,
                            page: { ...this.state.page, page: 1 },
                            dateEnd: isNull(this.state.dateEnd) ? new Date() : new Date(this.state.dateEnd),
                            querySearch: {
                              ...this.state.querySearch,
                              startTime: format(startDate, "yyyy'-'MM'-'dd'T'00:00:00"),
                              endTime: format(isNull(this.state.dateEnd) ? new Date() : new Date(this.state.dateEnd), "yyyy'-'MM'-'dd'T'23:59:59")
                            }
                          }, () => { this.fetchGetData() }
                        )
                      }
                    }}
                    // plugins={[<TimePicker hideSeconds />]}
                    // errorMessage={t(get(formik, "errors.publishedDate", "global.notError")!)}
                    disabled={this.state.selectedOptionSearch !== "SINCE"}
                  />
                </div>
                <div className='col-3 box-search-item'>
                  <DatePickerNew
                    isShowIcon
                    value={this.state.dateEnd}
                    placeholder="วันสิ้นสุด"
                    format="DD MMM YYYY"
                    editable={false}
                    onChange={(value: any) => {
                      if (value !== null) {
                        let startDate = new Date(Number(value))
                        this.setState(
                          {
                            dateEnd: value,
                            page: { ...this.state.page, page: 1 },
                            querySearch: {
                              ...this.state.querySearch,
                              startTime: format(isNull(this.state.dateStart) ? new Date() : new Date(this.state.dateStart), "yyyy'-'MM'-'dd'T'00:00:00"),
                              endTime: format(startDate, "yyyy'-'MM'-'dd'T'23:59:59"),
                            }
                          }, () => { this.fetchGetData() }
                        )
                      }
                    }}
                    // plugins={[<TimePicker hideSeconds />]}
                    // errorMessage={t(get(formik, "errors.publishedDate", "global.notError")!)}
                    disabled={this.state.selectedOptionSearch !== "SINCE" || isNull(this.state.dateStart)}
                  />
                </div>
                <div className='col-3 box-search-item'>
                  <div className='dropdown-box'>
                    <Dropdown
                      value={(this.state.querySearch.status)}
                      valueContainerStyles={{ fontSize: "13px" }}
                      iconStyles={{ color: colors.TERTIARY_TEXT }}
                      optionStyles={{ fontSize: "13px" }}
                      options={Object.keys(listOptionStatus).map((item) => { return { value: item, label: listOptionStatus[item as IStatusBetMe | "All"], disabled: false } })}
                      controlBackgroundColor={colors.PRIMARY_BG}
                      placeholder={isEmpty(this.state.querySearch.status) ? 'ผลการเดิมพัน' : listOptionStatus[this.state.querySearch.status as IStatusBetMe | "All"]}
                      menuStyles={{ width: "100%" }}
                      handleOnChange={(target: IOptions) => {
                        this.setState({
                          page: { ...this.state.page, page: 1 },
                          querySearch: { ...this.state.querySearch, status: target.value === "All" ? "" : target.value }
                        }, () => { this.fetchGetData() })
                      }}
                    />
                  </div>
                </div>
                <div className='col'>
                  <Input
                    id="search-customerName"
                    name="search-customerName"
                    inputClassName="input-search"
                    placeholder="ชื่อช่วยจำ"
                    backgroundColor={colors.PRIMARY_BG}
                    value={this.state.querySearch.customerName}
                    onChange={(e) => {
                      this.setState({
                        page: { ...this.state.page, page: 1 },
                        querySearch: { ...this.state.querySearch, customerName: e.target.value }
                      }, () => {
                        clearTimeout(this.queryTimeout!)
                        this.queryTimeout = setTimeout(() => {
                          this.fetchGetData()
                        }, 1000)
                      }
                      )
                    }}
                    renderError={false}
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='lotto-bill'>
                  <div className="table-container">
                    <div className="table-responsive-xl">
                      <table className="w-100">
                        <thead className='header-table-lotto-bill'>
                          <tr>{TableHeadBill}</tr>
                        </thead>
                        <tbody>{TableBodyeBill}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row mt-2'>
              <div className="col d-flex justify-content-end align-items-center">
                <h6 className='text-bold secondary-text'>{constants.rowPerPage}</h6>
                <div className="dropdown-rowPerPage">
                  <Dropdown
                    menuPlacement={"top"}
                    options={[5, 10, 25, 50, 100].map((item) => { return { value: item, label: item.toString(), disabled: false } })}
                    controlBackgroundColor={colors.PRIMARY_BG}
                    placeholder={this.props.getAgentData.limit.toString()}
                    menuStyles={{ width: "100%" }}
                    handleOnChange={(target: IOptions) => {
                      this.setState({
                        page: { ...this.state.page, limit: target.value, page: 1 }
                      }, () => { this.fetchGetData() })
                    }}
                  />
                </div>
              </div>
              <h6 className='text-bold d-flex justify-content-end align-items-center'>
                {this.textPageNow()}
              </h6>
              <div className='col-auto d-flex align-items-center'>
                <Panginate
                  pageTotal={this.props.getAgentData.total}
                  initialPage={get(this.state.page, 'page', 0) - 1}
                  pageRangeDisplayed={0}
                  onPageChange={this.handleClickChangePage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
