import routes from 'constants/routes'
import { RefObject } from 'react'
import { transformer } from './'

const scrollTo = (divRef: RefObject<HTMLDivElement>) => {
    if (divRef.current) {
        divRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start',
        })
    }
}

const openLink = <T>(query: T) => {
    return `${window.location.origin}${routes.redirected.path}${transformer.urlSearchParams(query)}`
}

const obj = {
    scrollTo,
    openLink,
}

export default obj