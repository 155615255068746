import { connect } from "react-redux";
import LoaderContainer from "./Loader.container";
import loaderSelector from "../../reduxs/Loader/selector";

const mapStateToProps = (state: RootReducers): ILoaderProps => {
  const loaderState = loaderSelector.isLoading(state);
  return { isLoading: loaderState.isLoading };
};

export default connect(mapStateToProps, {})(LoaderContainer) as any;
