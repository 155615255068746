import { ConfirmModal, Dropdown, ErrorModal, ResponsiveIcon } from "components";
import React from "react";

import { CODE_TO_SHORT_NAME, LOTTO_FLAG_ALPHA } from "constants/variables";
import point from "assets/images/global/icon/point.png";
import routes from "constants/routes";
import { FunctionComponent } from "react";
import LottoFlags from "assets/images/global/flags";
import { useState, useEffect } from "react";
import colors from "constants/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faPen,
  faEye,
  faTimes,
  faPlus,
  faCheck,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { filter, get, groupBy, includes, isEqual } from "lodash";

import collectBank from "assets/images/global/bank";
import { AccordionItemCoomponent } from "./components/AccordionItem";
import {
  fetchAgentHolesUpdate,
  fetchHolesAddAgent,
  fetchAgentHolesNumber,
  deleteAgentHoles,
} from "reduxs/numbersHoles/services";
import { AxiosError } from "axios";
import { responseMessage } from "constants/response";
import { responseConverter } from "utils";
import { CHECK_ONLY_NUMBER, COMMA } from "constants/regex";
interface Props {
  data: IV2GetHolesAgent;
  isStatusNewLotto: boolean;
  fetchNumbersHolesLotto: () => void;
  isCurrentLotto: boolean;
  handleCancel: (action: string) => void;
  onChangeIsLoaddingAction: (data: boolean) => void;
  loading: boolean
}

const constants = {
  deleteConfirmHeader: "ยืนยันการลบหลุมเลข",
  deleteConfirmDesc: "คุณยืนยันการลบข้อมูล? ข้อมูลหลุมเลขทั้งหมดจะหายไป",
};

const NumberHolesListComponent: FunctionComponent<Props> = ({
  loading,
  data,
  isStatusNewLotto,
  fetchNumbersHolesLotto,
  isCurrentLotto,
  handleCancel,
  onChangeIsLoaddingAction,
}) => {
  const NUMBERSHOLES_EDIT_LIST: {
    name: string;
    icon: IconDefinition;
    value: string;
  }[] = [
    {
      name: "รายละเอียดหลุม",
      icon: faEye,
      value: "NumbersHolesDetail",
    },
    {
      name: "แก้ไขขนาดหลุม",
      icon: faPen,
      value: "Edit_NumbersHoles",
    },
    {
      name: "เพิ่มเลขพิเศษ",
      icon: faPlus,
      value: "AddNumber_NumbersHoles",
    },
    {
      name: "ลบหลุมเลข",
      icon: faTimes,
      value: "Delete_NumbersHoles",
    },
  ];
  const mappingTypeNumbersHoles: IHeaderNumbersHolesTable[] = [
    { id: 1, name: "3 บน", type: "THREE_UP" },
    { id: 2, name: "3 โต๊ด", type: "THREE_TOAST" },
    { id: 3, name: "2 บน", type: "TWO_UP" },
    { id: 4, name: "2 ล่าง", type: "TWO_DOWN" },
    { id: 5, name: "วิ่งบน", type: "RUN_UP" },
    { id: 6, name: "วิ่งล่าง", type: "RUN_DOWN" },
  ];
  const initialTypeLottoState = {
    THREE_UP: get(data, "numholfacLimitThreeUp", 0),
    THREE_TOAST: get(data, "numholfacLimitThreeToast", 0),
    TWO_UP: get(data, "numholfacLimitTwoUp", 0),
    TWO_DOWN: get(data, "numholfacLimitTwoDown", 0),
    RUN_UP: get(data, "numholfacLimitRunUp", 0),
    RUN_DOWN: get(data, "numholfacLimitRunDown", 0),
  };
  const [onEditHoles, setOnEditHoles] = useState(isStatusNewLotto || false);
  const [checkInput, setCheckInput] = useState(false);
  const [isShowAccordion, setIsShowAccordion] = useState(false);
  const [groupNumberSpecialList, setGroupNumberSpecialList] = useState<INumbersHolesGroupData[]>(
    []
  );
  const [typeLotto, settypeLotto] = useState<INumbersHolesType>(initialTypeLottoState);
  const history = useHistory();

  const slug = ("LOTTER_" + data.name) as TLottoSlug;
  const flag_bank =
    Object.keys(collectBank).findIndex((item) => "LOTTER_" + item === slug) === -1
      ? ""
      : "flag-bank";

  useEffect(() => {
    settypeLotto(initialTypeLottoState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  useEffect(() => {
    isShowAccordion && fetchGetHolesNumberAgent(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowAccordion]);
  // useEffect(() => {
  //   console.log(groupNumberSpecialList);
  // }, [groupNumberSpecialList]);
  const GroupData = (data: IV2GetHolesNumberAgent[]) => {
    const result = groupBy(data, (x) => x.numholNumber);
    const Arrayresult = data!.map((item: IV2GetHolesNumberAgent, index: number) => {
      return result[data![index].numholNumber];
    });
    const uniqueArray = Arrayresult.filter(
      (item, index, self) =>
        self.findIndex((t) => t[0].numholNumber === item[0].numholNumber) === index
    );
    const Arraydata: INumbersHolesGroupData[] = uniqueArray.map((item) => {
      let ObjectNew: INumbersHolesGroupData = {
        isNew: false,
        isEdit: false,
        tempNumber: "",
        numbers: item,
        tempData: item,
      };
      return ObjectNew;
    });
    return Arraydata;
  };

  const onClickDropdown = (target: IOptions) => {
    const valueToFunctionMap = {
      NumbersHolesDetail: () =>
        history.push(routes.numbersHolesManage.path, {
          agentLotsetId: data.agentLotsetId,
          name: data.name,
          numholfacId: data.numholfacId,
          lottoLimit: typeLotto,
        }),
      Edit_NumbersHoles: () => setOnEditHoles(true),
      AddNumber_NumbersHoles: () => {
        setIsShowAccordion(true);
        onPressAddNumberSpecialHandler();
      },
      Delete_NumbersHoles: () => onPressDeleteNumberSpecialHandler(),
    };
    const func = get(valueToFunctionMap, target.value as string, "NumbersHolesDetail");
    if (typeof func === 'function') {
      (func as any)();
    }
  };

  const handleDeleteNumbersHoles = (data: IV2DeleteHolesAgent) => {
    onChangeIsLoaddingAction(true);
    const query: IV2DeleteHolesAgent = {
      agentLotsetId: data.agentLotsetId,
      numholfacId: data.numholfacId,
    };
    deleteAgentHoles(query)
      .then((response) => {
        handleCancel("DELETE");
        fetchNumbersHolesLotto();
      })
      .catch((error: AxiosError<IAPIResponse>) => {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            responseConverter.getMessage(error).code,
            responseMessage()[0]
          ),
        });
      });
  };
  const fetchPostHolesAddAgent = (data: IV2GetHolesAgent) => {
    onChangeIsLoaddingAction(true);
    const query: IV2PostHolesAddAgent = { agentLotsetId: data.agentLotsetId };
    fetchHolesAddAgent(query)
      .then((response) => {
        handleCancel("ADD");
        history.replace({ ...history, state: undefined });
        fetchPostHolesUpdateAgent(response.data.data!);
      })
      .catch((error: AxiosError<IAPIResponse>) => {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            responseConverter.getMessage(error).code,
            responseMessage()[0]
          ),
        });
      });
  };
  const fetchPostHolesUpdateAgent = (data: IV2RespPostHolesAddAgent) => {
    onChangeIsLoaddingAction(true);
    const query: IV2UpdateHolesAgent = {
      agentLotsetId: data.agentLotsetId,
      numholfacId: data.numholfacId,
      numholfacLimitThreeUp: typeLotto.THREE_UP,
      numholfacLimitThreeToast: typeLotto.THREE_TOAST,
      numholfacLimitTwoUp: typeLotto.TWO_UP,
      numholfacLimitTwoDown: typeLotto.TWO_DOWN,
      numholfacLimitRunUp: typeLotto.RUN_UP,
      numholfacLimitRunDown: typeLotto.RUN_DOWN,
    };
    fetchAgentHolesUpdate(query)
      .then((response) => {
        handleCancel("UPDATE");
        fetchNumbersHolesLotto();
        setOnEditHoles(false);
      })
      .catch((error: AxiosError<IAPIResponse>) => {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            responseConverter.getMessage(error).code,
            responseMessage()[0]
          ),
        });
      });
  };

  // 89.GET: api/v2/agent/holes/numbers ขอข้อมูลเลขในหลุม
  const fetchGetHolesNumberAgent = (
    didmount: boolean,
    dataItem?: INumbersHolesGroupData,
    index: number = -1,
    fetchLotto?: boolean
  ) => {
    onChangeIsLoaddingAction(true);
    const query = {
      agentLotsetId: data.agentLotsetId,
      numholfacId: data.numholfacId,
    };
    !isStatusNewLotto &&
      fetchAgentHolesNumber(query)
        .then((response) => {
          let checkNumberIsStrict = response.data.data!.filter((i) => i.numholIsStrict);
          let result: INumbersHolesGroupData[] = GroupData(checkNumberIsStrict);
          if (didmount && !isCurrentLotto) {
            setGroupNumberSpecialList(result);
          } else {
            let groupDatanoIsnew = filter(groupNumberSpecialList, { isNew: false });
            let ArrayTempGroup = groupBy(groupDatanoIsnew, (x) => x.numbers[0].numholNumber);
            const mapResToTemp = result.map((item) => {
              let dataGroup = get(ArrayTempGroup, item.numbers[0].numholNumber, [item]);
              dataGroup[0].numbers = item.numbers;
              return dataGroup[0];
            });
            let dataFilter = filter(groupNumberSpecialList, (item) => {
              return item.isNew;
            });
            let cloneData = [...dataFilter];
            if (index !== -1) {
              cloneData.splice(index!, 1);
            }
            let datatotal = [...cloneData, ...mapResToTemp];
            setGroupNumberSpecialList(datatotal);
          }
        })
        .catch((error: AxiosError<IAPIResponse>) => {
          ErrorModal.show({
            action: ErrorModal.hide,
            description: get(
              responseMessage(),
              responseConverter.getMessage(error).code,
              responseMessage()[0]
            ),
          });
        })
        .finally(() => {
          if (fetchLotto) {
            handleCancel("ADD_NUMBERSPECIAL");
            fetchNumbersHolesLotto();
          }
          onChangeIsLoaddingAction(false);
        });
  };

  const handleClickLotto = () => {
    if (isStatusNewLotto && get(data, "agentLotsetId", "") !== "") {
      fetchPostHolesAddAgent(data);
    } else {
      if (isEqual(initialTypeLottoState, typeLotto)) {
        setOnEditHoles(false);
      } else {
        fetchPostHolesUpdateAgent(data);
      }
    }
  };

  const handleChangeType = (e: { target: HTMLInputElement }) => {
    let { name, value } = e.target;
    value = value.replace(COMMA, "");
    if (Number(value) > 999999999) {
      value = "1000000000";
    }
    //input only number
    const isnum = CHECK_ONLY_NUMBER.test(value);
    if (value.length !== 0 && !isnum) {
      return;
    }
    settypeLotto((prev: INumbersHolesType) => ({
      ...prev,
      [name]: Number(value),
    }));
  };
  const onPressAddNumberSpecialHandler = () => {
    let addNumberObject: IV2GetHolesNumberAgent = {
      numholfacId: data.numholfacId,
      numholBetType: "THREE_UP",
      numholBetLimit: 0,
      numholNumber: "",
    };

    let arrayData = [addNumberObject, addNumberObject];
    setGroupNumberSpecialList((prev): INumbersHolesGroupData[] => [
      {
        isNew: true,
        isEdit: false,
        tempNumber: "",
        numbers: arrayData,
        tempData: arrayData,
      },
      ...prev,
    ]);
  };

  const onPressDeleteNumberSpecialHandler = () => {
    ConfirmModal.show({
      action: () => {
        handleDeleteNumbersHoles(data);
        ConfirmModal.hide();
      },
      cancelAction: () => {
        ConfirmModal.hide();
      },
      description: constants.deleteConfirmDesc,
      title: constants.deleteConfirmHeader,
      whiteSpace: "nowrap",
    });
  };

  const handleClickCancle = () => {
    if (isStatusNewLotto) {
      handleCancel("CANCEL");
      history.replace({ ...history, state: undefined });
    }
    settypeLotto(initialTypeLottoState);
    setOnEditHoles(false);
  };
  const renderTypeLotto = () => {
    return (
      <>
        {mappingTypeNumbersHoles.map((item) => {
          const valueTypeLotto = Number(
            typeLotto[item.type as keyof INumbersHolesType]
          ).toLocaleString();
          const spanTypeLotto = typeLotto[item.type as keyof INumbersHolesType].toLocaleString();
          return (
            <React.Fragment key={item.id}>
              {onEditHoles ? (
                <td className="text-right td-input" key={item.id}>
                  <input
                    type="text"
                    name={item.type}
                    value={valueTypeLotto}
                    onChange={handleChangeType}
                    onMouseDown={(e) => {
                      setCheckInput(true);
                      e.stopPropagation();
                    }}
                  />
                </td>
              ) : (
                <td className="text-right td-span" key={item.id}>
                  <span>{spanTypeLotto}</span>
                </td>
              )}
            </React.Fragment>
          );
        })}
      </>
    );
  };
  const renderDropDown = () => {
    return (
      <>
        {onEditHoles ? (
          <div
            className="d-flex align-items-center justify-content-end"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="icon-item check" onClick={handleClickLotto}>
              <FontAwesomeIcon icon={faCheck} />
            </div>
            <div className="icon-item remove ml-2" onClick={handleClickCancle}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
        ) : (
          <div onClick={(e) => e.stopPropagation()}>
            <Dropdown
              isManualOpenMenu
              options={NUMBERSHOLES_EDIT_LIST.map((item) => {
                return {
                  value: item.value,
                  label: (
                    <div className="dropdowncontainer">
                      <FontAwesomeIcon icon={item.icon} className="dropdowniconItem" />
                      {item.name}
                    </div>
                  ),
                  disabled: false,
                };
              })}
              iconOpen={point}
              iconClose={point}
              iconStyles={{ width: 4, height: 16 }}
              controlBackgroundColor={colors.PRIMARY_BG}
              menuStyles={{ width: "160px", left: "-170px", top: "0px" }}
              valueContainerStyles={{
                padding: "0px",
                maxWidth: "0px",
              }}
              handleOnChange={(target: IOptions) => {
                onClickDropdown(target);
              }}
            />
          </div>
        )}
      </>
    );
  };
  return (
    <>
      <tr
        className={`row-numbersholes-list ${isShowAccordion ? "borderBtmNone" : ""}`}
        key={data.agentLotsetId}
        style={{ height: 42.67 }}
        onMouseDown={() => {
          setCheckInput(false);
        }}
        onClick={(e) => {
          if (!checkInput) !isStatusNewLotto && setIsShowAccordion(!isShowAccordion);
        }}
      >
        <td className="text-left">
          <div className="lotto-item pl-3">
            <div className={`arrowIcon mr-3 ${isShowAccordion ? "open" : ""}`}>
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
            <div className="box-icon">
              <ResponsiveIcon
                icon={
                  get(
                    LottoFlags[
                      LOTTO_FLAG_ALPHA[
                        includes(data.name, "YEGEE") ? "YEGEE" : (data.name as TLottoType)
                      ]
                    ],
                    "Icon",
                    ""
                  )!
                }
                alt="flag"
                className={`lotto-action-card-flag ${flag_bank} ${slug} `}
              />
            </div>
            <div className="d-flex flex-column align-items-start">
              <span className="lotto-header-item">
                {includes(data.name, "YEGEE")
                  ? `ยี่กี รอบที่ ${Number(data.name.replace("YEGEE_", ""))}`
                  : CODE_TO_SHORT_NAME[data.name as TLottoType]}
              </span>
              {get(data, "countSpecialNumber", 0) > 0 && (
                <span className="special-number">{`
                 ${get(data, "countSpecialNumber", 0)}
                 เลขพิเศษ`}</span>
              )}
            </div>
          </div>
        </td>
        {renderTypeLotto()}
        <td className="text-center">
          <div className="d-flex align-items-center justify-content-between">
            <span
              className={`${
                get(data, "totalActiveNumberPercentage", 0) === 100 ? "max-percentage" : ""
              } `}
              style={{ paddingLeft: "47%" }}
            >{`${get(data, "totalActiveNumberPercentage", 0)}%`}</span>
            <div
              className="d-flex align-items-center justify-content-end"
              style={{ paddingRight: 13.33 }}
            >
              {renderDropDown()}
            </div>
          </div>
        </td>
      </tr>

      <AccordionItemCoomponent
        loading={loading}
        isShowAccordion={isShowAccordion}
        groupNumberSpecialList={groupNumberSpecialList}
        setGroupNumberSpecialList={setGroupNumberSpecialList}
        typeLotto={typeLotto}
        onPressAddNumberSpecialHandler={onPressAddNumberSpecialHandler}
        fetchGetHolesNumberAgent={fetchGetHolesNumberAgent}
        handleCancel={handleCancel}
        fetchNumbersHolesLotto={fetchNumbersHolesLotto}
        onChangeIsLoaddingAction={onChangeIsLoaddingAction}
      />
    </>
  );
};

export default NumberHolesListComponent;
