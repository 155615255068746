import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { getAgentBanLottery, postCancelNumberBanUpdate } from "./services"
import { getNumberBanLotterytAction,postCancelNumberBanAction} from "./actionCreator"

const getBanLottery = () => (dispatch: Dispatch) => {
    dispatch(getNumberBanLotterytAction.request({}))
    return getAgentBanLottery()
    .then((res:AxiosResponse<IAPIResponse<IV2GetRateReduceLotteryAgent[]>>) =>{
        dispatch(getNumberBanLotterytAction.success(res))
    })
    .catch((error: AxiosError<IAPIResponse>) => dispatch(getNumberBanLotterytAction.failure(error)))
}

const postCancelNumberBan = (query: IV2UpdateRateReduceLotteryAgent) => (dispatch : Dispatch) => {
    dispatch(postCancelNumberBanAction.request({}))
    return postCancelNumberBanUpdate(query)
    .then((res:AxiosResponse<IAPIResponse<IV2GetRateReduceLotteryAgent>>) => {
        dispatch(postCancelNumberBanAction.success(res))
    })
    .catch((error: AxiosError<IAPIResponse>) => dispatch(postCancelNumberBanAction.failure(error)))
}

export{
    getBanLottery,
    postCancelNumberBan
}