import React, { ChangeEvent, Component, createRef, RefObject } from "react"
import { noop } from "lodash"
import "./manageNews.style.scss"
import colors from "constants/colors"
import { News } from "../Main/components/News"
import { responseCode } from "constants/response"
import { RouteComponentProps } from "react-router-dom"
import { interactive, handleOnClickBreadcrumb } from "utils"
import {
  Button,
  TextArea,
  Breadcrumb,
  ConfirmModal,
  SuccessModal,
  ErrorModal,
  Panginate,
} from "components"
import color from "constants/colors"

const constants = {
  submit: 'ยืนยัน',
  title: "ภาพรวม",
  description: "จัดการข่าวสาร",
  newaList: "ข่าวสารจากระบบ",
  createNew: "เพิ่มข่าวสาร",
  placeholderTextArea: "กรอกข่าวสารที่นี่.",
  updateNew: "อัพเดทข่าวสาร",
  reset: "รีเซ็ตข้อมูล",
  delete: "ลบข่าวสาร",

  createSuccess: "เพิ่มข่าวสารสำเร็จ",
  createConfirm: "คุณแน่ใจหรือว่าจะเพิ่มข่าวสาร",
  createError: "เพิ่มข่าวสารไม่สำเร็จ",

  deleteSuccess: "ลบข่าวสารสำเร็จ",
  deleteConfirm: "คุณแน่ใจหรือว่าจะลบข่าวสาร",
  deleteError: "ลบข่าวสารไม่สำเร็จ",

  updateSuccess: "อัพเดทข่าวสารสำเร็จ",
  updateConfirm: "คุณแน่ใจหรือว่าจะอัพเดทข่าวสาร",
  updateError: "อัพเดทข่าวสารไม่สำเร็จ",

  breadcrumb:{
    main:"ภาพรวม",
    news:"ข่าวสาร"
  },
}

type DefaultProps = Readonly<typeof defaultProps>
const defaultProps: IManageNewsActionProps & IManageNewsProps = {
  getNewsMe() {
    noop()
  },
  addNewsMe() {
    noop()
  },
  updateNewsMe() {
    noop()
  },
  deleteNewsMe() {
    noop()
  },
  getDataMain() {
    noop()
  },
  onChangeIsLoaddingAction() {
    noop()
  },
  newsMeCode: 0,
  newsMeError: "",
  newsMeIsFetching: false,
  newsMeData: {
    totalRecord:0,
    page: 1,
    limit: 5,
    total: 0,
    dataList: [],
  },

  newsMeAddCode: 0,
  newsMeAddIsFetching: false,
  newsMeAddError: "",
  newsMeAddData: {
    data: {},
    code: 0,
    devMessage: "",
  },

  newsMeUpdateCode: 0,
  newsMeUpdateIsFetching: false,
  newsMeUpdateError: "",
  newsMeUpdateData: {
    data: {},
    code: 0,
    devMessage: "",
  },

  newsMeDeleteCode: 0,
  newsMeDeleteIsFetching: false,
  newsMeDeleteError: "",
  newsMeDeleteData: {
    data: {},
    code: 0,
    devMessage: "",
  },

  mainCode: 0,
  mainError: "",
  mainIsFetching: false,
  mainData: {
    username: "",
    permission: "MEMBER",
    line: "",
    status: "",
    childCount: 0,
    currency: "THB",
    betResultTotal: 0,
    betWaitTotal: 0,
    maxBet: 0,
    minBet: 0,
    childCreditTotal: 0,
    credit: 0,
    creditLimit: "",
  },
}

class ManageNewsContainer extends Component<
  IManageNewsProps & IManageNewsActionProps & DefaultProps & RouteComponentProps
> {
  divAddNewsRef: RefObject<HTMLDivElement> = createRef()
  addNewsRef: RefObject<HTMLInputElement> = createRef()
  state: IManageNewsDataState = {
    AddNews: "",
    idUpdate: -99,
    currentPage: { selected: 1 },
    onRef: false,
    reqNewsMe: {
      search: "",
      page: 1,
      limit: 5,
      permission: "MEMBER",
      broadcastCondition: "<",
    },
  }

  componentDidMount() {
    this.props.getDataMain()
    setTimeout(() => {
      interactive.scrollTo(this.divAddNewsRef)
    }, 128)
  }
  componentDidUpdate(prevProps: IManageNewsProps) {
    if (
      this.props.mainIsFetching ||
      this.props.newsMeIsFetching ||
      this.props.newsMeAddIsFetching ||
      this.props.newsMeUpdateIsFetching ||
      this.props.newsMeDeleteIsFetching
    ) {
      this.props.onChangeIsLoaddingAction(true)
    } else {
      this.props.onChangeIsLoaddingAction(false)
    }

    if (
      prevProps.mainData !== this.props.mainData &&
      prevProps.mainIsFetching !== this.props.mainIsFetching &&
      !this.props.mainIsFetching
    ) {
      if (this.props.mainData.permission==="MEMBER") {
        this.props.history.replace("/dashboard")
      }
      if (this.props.mainCode === responseCode.OK) {
        this.setState(
          {
            reqNewsMe: {
              ...this.state.reqNewsMe,
              permission: this.props.mainData.permission,
            },
          },
          () => {
            this.props.getNewsMe(this.state.reqNewsMe)
          }
        )
      } else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: this.props.mainError,
        })
      }
    }

    if (
      prevProps.newsMeAddIsFetching !== this.props.newsMeAddIsFetching &&
      !this.props.newsMeAddIsFetching
    ) {
      if (this.props.newsMeAddData.code === responseCode.OK) {
        SuccessModal.show({
          action: SuccessModal.hide,
          description: constants.createSuccess,
        })
        this.props.getNewsMe(this.state.reqNewsMe)
        this.clearData()
      } else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: constants.createError,
        })
      }
    }
    if (
      prevProps.newsMeUpdateIsFetching !== this.props.newsMeUpdateIsFetching &&
      !this.props.newsMeUpdateIsFetching
    ) {
      if (this.props.newsMeUpdateData.code === responseCode.OK) {
        SuccessModal.show({
          action: SuccessModal.hide,
          description: constants.updateSuccess,
        })
        this.props.getNewsMe(this.state.reqNewsMe)
        this.clearData()
      } else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: constants.updateError,
        })
      }
    }
    if (
      prevProps.newsMeDeleteIsFetching !== this.props.newsMeDeleteIsFetching &&
      !this.props.newsMeDeleteIsFetching
    ) {
      if (this.props.newsMeDeleteData.code === responseCode.OK) {
        SuccessModal.show({
          action: SuccessModal.hide,
          description: constants.deleteSuccess,
        })
        this.props.getNewsMe(this.state.reqNewsMe)
        this.clearData()
      } else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: constants.deleteError,
        })
      }
    }
  }
  isCreate = () => (this.state.idUpdate === -99)
  handleChangeMsg = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ AddNews: event.target.value })
  }
  handleClickAddNews = () => {
    if (this.isCreate()) {
      this.props.addNewsMe({
        message: this.state.AddNews,
        permission: this.props.mainData.permission,
        broadcastCondition: "<",
      })
    } else {
      this.props.updateNewsMe({
        id: this.state.idUpdate,
        message: this.state.AddNews,
        permission: this.props.mainData.permission,
        broadcastCondition: "<",
      })
    }
  }
  handleScrollToAddNews = () => {
    if (this.divAddNewsRef.current) {
      this.setState({ onRef: true })
      this.divAddNewsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      })
    }
    this.clearData()
  }
  clearData = () => {
    this.setState({ AddNews: "" })
    this.setState({ idUpdate: -99 })
  }
  handleClickChangePage = ({ selected: selectedPage }: ISelectPage) => {
    this.setState({ currentPage: { selected: selectedPage } }, () => {
      this.setState(
        {
          reqNewsMe: {
            ...this.state.reqNewsMe,
            page: this.state.currentPage.selected + 1,
          },
        },
        () => {
          this.props.getNewsMe(this.state.reqNewsMe)
        }
      )
    })
  }
  handleclickSelectNews = (id: number, msg: string) => {
    this.setState({ idUpdate: id })
    this.setState({ AddNews: msg })
  }
  renderNewsList = () => (
    <div className="col">
      <div className="box-shadow">
        <div className="inline pr-3 paper-body-title">
          {constants.newaList}
        </div>
        <div className="inline paper-btn-title">
          <Button
            id="profile"
            text={constants.createNew}
            onClick={this.handleScrollToAddNews}
          />
        </div>
        <div className="list-manage-new">
        <News
          news={this.props.newsMeData.dataList}
          clickSelectNews={this.handleclickSelectNews}
          idFocus={this.state.idUpdate}
        />
        </div>
        <div className="row">
          <div className="col-12">
            <Panginate
              pageTotal={this.props.newsMeData.total}
              initialPage={this.state.reqNewsMe.page - 1}
              pageRangeDisplayed={this.state.currentPage.selected}
              onPageChange={this.handleClickChangePage}
            />
          </div>
        </div>
      </div>
    </div>
  )
  renderAddNews = () => (
    <div className="col">
      <div className="box-shadow">
        <div ref={this.divAddNewsRef} className="paper-body-title mb-3">
          {this.isCreate()? constants.createNew: constants.updateNew}
        </div>
        <TextArea
          id="newsMe"
          name="newsMe"
          placeholder={constants.placeholderTextArea}
          ref={this.addNewsRef.current?.focus()}
          value={this.state.AddNews}
          onfocus={this.state.onRef}
          disabled={false}
          onBlur={() => {
            this.setState({ onRef: false })
          }}
          onChange={this.handleChangeMsg}
        />
        <div className="row pt-3">
          <div className="col btn-add">
            <Button
              id="addNews"
              text={`${
                this.isCreate()
                  ? constants.submit
                  : constants.updateNew
              }`}
              onClick={() => {
                ConfirmModal.show({
                  action: () => {
                    this.handleClickAddNews()
                    ConfirmModal.hide()
                  },
                  cancelAction: () => {
                    ConfirmModal.hide()
                  },
                  description:
                  this.isCreate()
                      ? constants.createConfirm
                      : constants.updateConfirm,
                })
              }}
              disabled={this.state.AddNews === ""}
            />
          </div>
        </div>
        <div className="row pt-3">
          <div className="col btn-add">
            <Button
              id="reset"
              text={constants.reset}
              type="underline"
              disabled={this.state.AddNews === ""}
              textColor={colors.SECONDARY_TEXT}
              backgroundColor={color.TRANSPARENT}
              backgroundHoverColor={color.TRANSPARENT}
              onClick={() => {
                this.clearData()
                if (this.divAddNewsRef.current) {
                  this.setState({ onRef: true })
                }
              }}
            />
          </div>
        </div>
        {!this.isCreate() && (
          <div className="row">
            <div className="col">
              <Button
                id="delete"
                text={constants.delete}
                disabled={this.isCreate()}
                textColor={colors.PRIMARY_RED}
                backgroundColor={color.TRANSPARENT}
                backgroundHoverColor={color.TRANSPARENT}
                type="underline"
                onClick={() => {
                  ConfirmModal.show({
                    action: () => {
                      this.props.deleteNewsMe({
                        id: this.state.idUpdate,
                      })
                      ConfirmModal.hide()
                    },
                    cancelAction: () => {
                      ConfirmModal.hide()
                    },
                    description: constants.deleteConfirm,
                  })
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
  
  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.breadcrumb.main, active: false, path: "/dashboard" },
      { label: constants.breadcrumb.news, active: true },
    ]
    const RenderNewsList = this.renderNewsList()
    const RenderAddNews = this.renderAddNews()
    return (
      <div className="paper-container manageNew-container">
        <div className="row">
          <div className="col">
            <div className="sub-menu">
              <Breadcrumb
                items={navigates}
                handleOnClickItem={handleOnClickBreadcrumb}
              />
            </div>
          </div>
        </div>
        <div className="paper-title">{constants.title}</div>
        <div className="paper-description">{constants.description}</div>
        <div className="paper-body">
          <div className="row">
            {RenderNewsList}
          </div>
          <div className="row mt-4">
            {RenderAddNews}
          </div>
        </div>
      </div>
    )
  }
}
export default ManageNewsContainer
