import { AxiosError, AxiosResponse } from "axios";
import { get } from "lodash";
import { combineReducers } from "redux";
import { ActionType, createReducer } from "typesafe-actions";
import { responseConverter } from "../../utils";
import * as authenticationAction from "./actionCreator";
import { loginState, tokenState } from "./constants";

export type AuthenticationActionType = ActionType<typeof authenticationAction>;
type loginResponse =
  | IResponseAgentLogin
  | IResponseAgentInitialPassword
  | IResponse2FARegister
  | IResponse2FAVerify;

const login = createReducer<
  ReducerState<loginResponse>,
  AuthenticationActionType
>(loginState)
  .handleAction(
    authenticationAction.loginAction.request,
    (state: ReducerState<loginResponse>) => {
      return {
        ...state,
        isFetching: true,
        error: "",
      };
    }
  )
  .handleAction(
    authenticationAction.loginAction.success,
    (state: ReducerState<loginResponse>, action: AuthenticationActionType) => {
      const payload: AxiosResponse<IAPIResponse<IResponseAgentLogin>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: { ...state.data, token: payload.data.data.token, status: payload.data.data.status || "UNKNOWN" },
      };
    }
  )
  .handleAction(
    authenticationAction.loginAction.failure,
    (state: ReducerState<any>, action: AuthenticationActionType) => {
      const payload: AxiosError<IAPIResponse<IResponseAgentLogin>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);

      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: { ...state.data, ...get(payload, "response.data.data"), verify: false },
        error: convertedResponse.message,
      };
    }
  )
  .handleAction(
    authenticationAction.resetLoginAction,
    (state: ReducerState<loginResponse>) => {
      return loginState
    }
  )
  .handleAction(
    authenticationAction.loginInitialPasswordAction.request,
    (state: ReducerState<loginResponse>) => {
      return {
        ...state,
        isFetching: true,
        error: "",
      };
    }
  )
  .handleAction(
    authenticationAction.loginInitialPasswordAction.success,
    (state: ReducerState<loginResponse>, action: AuthenticationActionType) => {
      const payload: AxiosResponse<IAPIResponse<IResponseAgentInitialPassword>> =
        action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
      };
    }
  )
  .handleAction(
    authenticationAction.loginInitialPasswordAction.failure,
    (state: ReducerState<loginResponse>, action: AuthenticationActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )
  .handleAction(
    authenticationAction.createOTPAction.request,
    (state: ReducerState<loginResponse>) => {
      return {
        ...state,
        isFetching: true,
        error: "",
      };
    }
  )
  .handleAction(
    authenticationAction.createOTPAction.success,
    (state: ReducerState<loginResponse>, action: AuthenticationActionType) => {
      const payload: AxiosResponse<IAPIResponse<ICreateOTP>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: { ...state.data, smsRef: payload.data.data.smsRef },
      };
    }
  )
  .handleAction(
    authenticationAction.createOTPAction.failure,
    (state: ReducerState<loginResponse>, action: AuthenticationActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )
  .handleAction(
    authenticationAction.loginPasswordAction.request,
    (state: ReducerState<loginResponse>) => {
      return {
        ...state,
        isFetching: true,
        error: "",
      };
    }
  )
  .handleAction(
    authenticationAction.loginPasswordAction.success,
    (state: ReducerState<loginResponse>, action: AuthenticationActionType) => {
      const payload: AxiosResponse<IAPIResponse<IResponseAgentPassword>> =
        action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: { ...state.data, status: payload.data.data.status },
      };
    }
  )
  .handleAction(
    authenticationAction.loginPasswordAction.failure,
    (state: ReducerState<loginResponse>, action: AuthenticationActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )
  .handleAction(
    authenticationAction.setPasswordExpiredAction.request,
    (state: ReducerState<loginResponse>) => {
      return {
        ...state,
        isFetching: true
      }
    }
  )
  .handleAction(
    authenticationAction.setPasswordExpiredAction.success,
    (state: ReducerState<loginResponse>, action: AuthenticationActionType) => {
      const payload: APIResponse<IResponseAPIPasswordExpired> = action.payload;
      return {
        ...state,
        data: { ...state.data, status: payload.data.status, verify: payload.data.verify },
        code: payload.code,
        error: payload.devMessage
      }
    }
  )
  .handleAction(
    authenticationAction.setPasswordExpiredAction.failure,
    (state: ReducerState<loginResponse>, action: AuthenticationActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )
  .handleAction(
    authenticationAction.register2FAAction.request,
    (state: ReducerState<loginResponse>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    authenticationAction.register2FAAction.success,
    (state: ReducerState<loginResponse>, action: AuthenticationActionType) => {
      const payload: AxiosResponse<IAPIResponse<IResponse2FARegister>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: {
          ...state.data,
          qrcode: payload.data.data.qrcode,
          secret: payload.data.data.secret,
        },
      };
    }
  )
  .handleAction(
    authenticationAction.register2FAAction.failure,
    (state: ReducerState<loginResponse>, action: AuthenticationActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )
  .handleAction(
    authenticationAction.verify2FAAction.success,
    (state: ReducerState<loginResponse>, action: AuthenticationActionType) => {
      const payload: IResponse2FAVerify = action.payload
      return {
        ...state,
        data: {
          ...state.data,
          verify: payload.verify
        }
      }
    }
  )
  .handleAction(
    authenticationAction.validate2FAAction.request,
    (state: ReducerState<loginResponse>) => {
      return {
        ...state,
        isFetching: true
      }
    }
  )
  .handleAction(
    authenticationAction.validate2FAAction.success,
    (state: ReducerState<loginResponse>, action: AuthenticationActionType) => {
      const payload: AxiosResponse<IAPIResponse<IResponse2FAVerify>> = action.payload
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: {
          ...state.data,
          verify: payload.data.data.verify
        }
      }
    }
  )
  .handleAction(
    authenticationAction.validate2FAAction.failure,
    (state: ReducerState<loginResponse>, action: AuthenticationActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload
      const convertedResponse = responseConverter.getMessage(payload)
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message
      }
    }
  )

const token = createReducer<IToken, AuthenticationActionType>(tokenState)
  .handleAction(
    authenticationAction.setAuthenticationAction.success,
    (state: IToken, action: AuthenticationActionType) => {
      const payload: IResponseAgentLogin = action.payload;
      return {
        accessToken: payload.token!,
        refreshToken: payload.token!,
      };
    }
  )
  .handleAction(
    authenticationAction.setAuthenticationAction.failure,
    (state: IToken, action: AuthenticationActionType) => state
  )
  .handleAction(authenticationAction.resetAuthenticationKeyAction, () => {
    return tokenState;
  });
export default combineReducers({
  login,
  token,
});
