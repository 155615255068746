import { AxiosError, AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import { listMembersAction, membersAction, agentChildAction, financeSummaryAction } from '../actionCreator'
import { fetchFinanceSummary, fetchMemberSummary, fetchListMembers, fetchMemberChild } from '../services'

const getDataMembers = () => (dispatch: Dispatch) => {
    dispatch(membersAction.request({}))
    return fetchMemberSummary()
        .then((res: AxiosResponse<IAPIResponse<IMembers>>) => {
            dispatch(membersAction.success(res))
        })
        .catch((error: AxiosError<IAPIResponse>) => dispatch(membersAction.failure(error)))
}

const getDataListMembers = (reqData: IListMembersRequest) => (dispatch: Dispatch) => {
    dispatch(listMembersAction.request({}))
    return fetchListMembers(reqData)
        .then((res: AxiosResponse<IAPIResponse<IPagination<IListMembers>>>) => {
            dispatch(listMembersAction.success(res))
        })
        .catch((error: AxiosError<IAPIResponse>) => dispatch(listMembersAction.failure(error)))
}


const getChildMembers = (query: IV2QueryGetAgentChild & IQueryPagination) => (dispatch: Dispatch) => {
    dispatch(agentChildAction.request({}))
    return fetchMemberChild(query)
        .then((res: AxiosResponse<IAPIResponse<IPagination<IV2GetAgentChild>>>) => {
            dispatch(agentChildAction.success(res))
            return Promise.resolve(res.data.data.dataList[0])
        })
        .catch((error: AxiosError<IAPIResponse>) => {
            dispatch(agentChildAction.failure(error))
            return Promise.reject()
        })
}

const getFinanceSummary = () => (dispatch: Dispatch) => {
    dispatch(financeSummaryAction.request({}))
    return fetchFinanceSummary()
        .then((res: AxiosResponse<IAPIResponse>) => {
            dispatch(financeSummaryAction.success(res))
        })
        .catch((error: AxiosError<IAPIResponse>) => dispatch(financeSummaryAction.failure(error)))
}

export {
    getFinanceSummary,
    getDataMembers,
    getDataListMembers,
    getChildMembers
}