import { Breadcrumb } from "components";
import { LOTTO_GAME_TYPES } from "constants/variables";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { SettingRateConfig } from "./components";
import "./settingRate.style.scss";

type DefaultProps = Readonly<typeof defaultProps>;

const constants = {
  settingRateTitle: "อัตราจ่าย",
  settingRateSubTitle: "อัตราจ่าย",
  titleBet: "หวยออนไลน์",
};

const defaultProps: ISettingRateContainerProps &
  ISettingRateContainerActionProps = {};

class SettingRateContainer extends Component<
  ISettingRateContainerProps &
    ISettingRateContainerActionProps &
    DefaultProps &
    RouteComponentProps
> {
  static defaultProps = defaultProps;
  state: ISettingRateContainerState = {
    settingRateConfig: [],
  };

  componentDidMount() {
    this.generateInitial();
  }

  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.replace(path);
  };

  generateInitial = () => {
    let settingRateConfig = [];
    for (const [key, lottoType] of Object.entries(LOTTO_GAME_TYPES)) {
      let rateConfList = [];
      let objectSettingRateConf: ISettingRateConfig = {
        name: `${key.split("LOTTER_")[1]}` as TLottoType,
        status: "CLOSE",
        rateConfig: [],
      };
      for (const lotto of lottoType) {
        let rateConf = {
          name: lotto,
        };
        rateConfList.push(rateConf);
      }
      objectSettingRateConf.rateConfig = rateConfList;
      settingRateConfig.push(objectSettingRateConf);
    }
    this.setState({
      settingRateConfig: settingRateConfig,
    });
  };

  render() {
    console.log(this.state);
    const navigates: IBreadcrumbItem[] = [
      {
        label: constants.settingRateTitle,
        active: false,
        path: "/setting/rate",
      },
      { label: constants.settingRateSubTitle, active: true },
    ];

    return (
      <div className="paper-container setting-rate-container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="sub-menu" style={{ width: "fit-content" }}>
              <Breadcrumb
                items={navigates}
                handleOnClickItem={this.handleOnClickBreadcrumb}
              />
            </div>
          </div>
        </div>
        <div className="paper-title">{constants.settingRateTitle}</div>
        <div className="paper-description">{constants.settingRateSubTitle}</div>
        <div className="paper-body">
          <div className="row">
            <div className="col">
              <div className="box-shadow">
                <div className="paper-body-title">{constants.titleBet}</div>
                <SettingRateConfig
                  settingRateConfig={this.state.settingRateConfig}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SettingRateContainer;
