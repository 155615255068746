import axios from 'axios'
import environment from '../../constants/environment'

const fetchAutocomplete = (data: IAutocompleteRequest) => {
    const params = {
        params: {
            page: data.page,
            limit: data.limit,
            text: data.text
        }
    }
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.autocomplete}`
    return axios.get(endpoint, params)
}
export {
    fetchAutocomplete,
}