import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { clearBetResultAction, getResultAction, getV2ResultAction } from "../actionCreator";
import { fetchGetResult, fetchV2GetResult } from "../services";
import { loaderAction } from "../../../Loader/actionCreator";

const getDataResult = (query: ISearchLudensBetResultQuery) => async (
  dispatch: Dispatch
) => {
  dispatch(loaderAction.success(true));
  dispatch(getResultAction.request({}));
  await fetchGetResult(query)
    .then((res: AxiosResponse<IAPIResponse<IResponseLudensBetResult[]>>) => {
      dispatch(loaderAction.success(false));
      dispatch(getResultAction.success(res));
    })
    .catch((error: AxiosError<IAPIResponse>) => {
      dispatch(loaderAction.success(false));
      dispatch(getResultAction.failure(error));
    });
};

const clearBetResult = () => (dispatch: Dispatch) => {
  dispatch(clearBetResultAction({}))
}

const getV2DataResult = (query: IV2QueryAgentLotteryResult | IQueryPagination) => async (
  dispatch: Dispatch
) => {
  // dispatch(loaderAction.success(true));
  dispatch(getV2ResultAction.request({}));
  await fetchV2GetResult(query)
    .then((res: AxiosResponse<IAPIResponse<IPagination<IV2RespAgentLotteryResult>>>) => {
      // dispatch(loaderAction.success(false));
      dispatch(getV2ResultAction.success(res));
    })
    .catch((error: AxiosError<IAPIResponse>) => {
      // dispatch(loaderAction.success(false));
      dispatch(getV2ResultAction.failure(error));
    });
};


export { getDataResult, clearBetResult, getV2DataResult };
