import { connect } from "react-redux"
import { onChangeIsLoaddingAction } from "../../reduxs/Loader/action"
import UsersResetPassword from "./UserResetPassword.container"
import { postResetPassword } from "reduxs/main/resetPassword/action"
import mainSelector from "reduxs/main/selector"

const mapStateToProps = (state: RootReducers):IUserResetPasswordProps  => {
  const resetPass = mainSelector.resetPassword(state)
  return {
    UserResetPassCode: resetPass.code,
    UserResetPassError:resetPass.error,
    UserResetPassIsFetching: resetPass.isFetching,
  }
  }
const mapDispatchToProps: IUserResetPasswordActionProps  = {
    postResetPassword:postResetPassword,
    onChangeIsLoaddingAction,

}

export default connect(mapStateToProps,mapDispatchToProps)(UsersResetPassword)