import { AxiosError, AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { ActionType, createReducer } from "typesafe-actions";
import { responseConverter } from "../../utils";
import * as newsMeAction from "./actionCreator";
import { newsState, newsResState } from "./constants";

export type NewsMeActionType = ActionType<typeof newsMeAction>;
const dataNewsMe = createReducer<
  ReducerState<IPagination<INews>>,
  NewsMeActionType
>(newsState)
  .handleAction(
    newsMeAction.newsMeGetAction.request,
    (state: ReducerState<IPagination<INews>>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    newsMeAction.newsMeGetAction.success,
    (state: ReducerState<IPagination<INews>>, action: NewsMeActionType) => {
      const payload: AxiosResponse<IAPIResponse<IPagination<INews>>> =
        action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
      };
    }
  )
  .handleAction(
    newsMeAction.newsMeGetAction.failure,
    (state: ReducerState<IPagination<INews>>, action: NewsMeActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );

const addNewsMe = createReducer<
  ReducerState<IAPIResponse<object>>,
  NewsMeActionType
>(newsResState)
  .handleAction(
    newsMeAction.newsMeAddAction.request,
    (state: ReducerState<IAPIResponse<object>>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    newsMeAction.newsMeAddAction.success,
    (state: ReducerState<IAPIResponse<object>>, action: NewsMeActionType) => {
      const payload: AxiosResponse<IAPIResponse<IAPIResponse<object>>> =
        action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data,
      };
    }
  )
  .handleAction(
    newsMeAction.newsMeAddAction.failure,
    (state: ReducerState<IAPIResponse<object>>, action: NewsMeActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);

      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );

const updateNewsMe = createReducer<
  ReducerState<IAPIResponse<object>>,
  NewsMeActionType
>(newsResState)
  .handleAction(
    newsMeAction.newsMeUpdateAction.request,
    (state: ReducerState<IAPIResponse<object>>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    newsMeAction.newsMeUpdateAction.success,
    (state: ReducerState<IAPIResponse<object>>, action: NewsMeActionType) => {
      const payload: AxiosResponse<IAPIResponse<IAPIResponse<object>>> =
        action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data,
      };
    }
  )
  .handleAction(
    newsMeAction.newsMeUpdateAction.failure,
    (state: ReducerState<IAPIResponse<object>>, action: NewsMeActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);

      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );

const deleteNewsMe = createReducer<
  ReducerState<IAPIResponse<object>>,
  NewsMeActionType
>(newsResState)
  .handleAction(
    newsMeAction.newsMeDeleteAction.request,
    (state: ReducerState<IAPIResponse<object>>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    newsMeAction.newsMeDeleteAction.success,
    (state: ReducerState<IAPIResponse<object>>, action: NewsMeActionType) => {
      const payload: AxiosResponse<IAPIResponse<IAPIResponse<object>>> =
        action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data,
      };
    }
  )
  .handleAction(
    newsMeAction.newsMeDeleteAction.failure,
    (state: ReducerState<IAPIResponse<object>>, action: NewsMeActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);

      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );

export default combineReducers({
  dataNewsMe,
  addNewsMe,
  updateNewsMe,
  deleteNewsMe,
});
