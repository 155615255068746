export const CONNECT_SOCKET = "CONNECT_SOCKET";
export const CONNECTED_SOCKET = "CONNECTED_SOCKET";
export const CONNECT_SOCKET_ERROR = "CONNECT_SOCKET_ERROR";
export const DISCONNECT_SOCKET = "DISCONNECT_SOCKET";
export const DISCONNECTED_SOCKET = "DISCONNECTED_SOCKET";
export const LOG_SOCKET = "LOG_SOCKET";

export const coreSocketState: ICoreSocketState = {
  connected: false,
};
