import { connect } from "react-redux";
import BetChildHistory from "./BetChildHistory.container";
import { onChangeIsLoaddingAction } from "reduxs/Loader/action";
import { getChildBet } from "reduxs/child/childBet/action";

const mapStateToProps = (state: RootReducers): IBetChildHistoryContainerProps => {
  return {
    getChildBetData : state.child.getChildBet.data,
    getChildBetIsFetching : state.child.getChildBet.isFetching,
    getChildBetCode : state.child.getChildBet.code,
    getChildBetError : state.child.getChildBet.error,
  };
};

const mapDispatchToProps: IBetChildHistoryContainerAction = {
  onChangeIsLoaddingAction,
  getChildBet
};

export default connect(mapStateToProps, mapDispatchToProps)(BetChildHistory);
