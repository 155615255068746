import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Breadcrumb, DatePicker, Input, Panginate, TextArea } from "components";
import { get, isEmpty, map, noop } from "lodash";
import { format } from "date-fns";
import "./editUserHistory.style.scss";
import color from "constants/colors";
import errorModal from "components/Modal/components/Error/Error.component";
import { responseCode } from "constants/response";

const defaultProps: IEditUserHistoryContainerProps &
  IEditUserHistoryContainerActionProps = {
  getEditUserHistoryListCode: 0,
  getEditUserHistoryListError: "",
  getEditUserHistoryListIsFetching: false,
  getEditUserHistoryListData: [],
  getditUserHistory() {
    noop();
  },
  clearEditUserHistory() {
    noop();
  },
  mockJSONEditUserHistory() {
    noop();
  },
  pageClick() {
    noop();
  },
  pagination: {
    allPageData: [],
    currentPage: {
      selected: 0,
    },
    pageCount: 0,
  },
};

const mappingThead: IEditUserHistoryTable[] = [
  { id: 1, name: "รหัสผู้ใช้" },
  { id: 2, name: "วันที่แก้ไขล่าสุด" },
  { id: 3, name: "พฤติกรรม" },
  { id: 4, name: "รายละเอียด" },
];

const constants = {
  editUserHistoryTitle: "ข้อมูลสมาชิก",
  editUserHistorySubTitle: "ประวัติการแก้ไขสมาชิก",
  inputSearchTitle: "ค้นหาสมาชิก / พฤติกรรม / รายละเอียด",
  textArea: {
    title: "ข้อมูลผู้ใช้",
    placeHolderTitle: "ผู้ใช้ :",
    placeHolderSubtitle: "รายละเอียด :",
  },
  dataNotFound: "ไม่พบข้อมูล...",
  modal: {
    title: "เกิดข้อ",
  },
};
class EditUserHistoryContainer extends Component<
  IEditUserHistoryContainerProps &
    IEditUserHistoryContainerActionProps &
    RouteComponentProps,
  IEditUserHistoryContainerState
> {
  static defaultProps = defaultProps;
  state: IEditUserHistoryContainerState = {
    tempDate: {
      end_date: "",
      start_date: "",
    },
    selectedUser: {
      behavior: "",
      message: "",
      updatedAt: "",
      username: "",
    },
    currentPage: { selected: 1 },
    editUserHistoryList: [],
    page: {
      search: "",
      start_date: "",
      end_date: "",
      page: 1,
      limit: 10,
    },
    queryTimeout: setTimeout(() => {
      noop();
    }, 0),
    setColor: "",
    setBorder: "",
  };

  componentDidMount() {
    this.props.getditUserHistory(this.state.page);
  }

  componentDidUpdate(prevProps: IEditUserHistoryContainerProps) {
    if (
      prevProps.getEditUserHistoryListIsFetching !==
        this.props.getEditUserHistoryListIsFetching &&
      !this.props.getEditUserHistoryListIsFetching
    )
      if (this.props.getEditUserHistoryListCode === responseCode.OK) {
        this.setState((prevState, props) => ({
          editUserHistoryList: props.getEditUserHistoryListData.dataList,
          page: {
            ...prevState.page,
            page: prevState.currentPage.selected,
          },
        }));
      } else {
        errorModal.show({
          description: this.props.getEditUserHistoryListError,
          action: errorModal.hide,
        });
      }
  }

  componentWillUnmount() {
    this.props.clearEditUserHistory();
  }

  handleClickUser = (value: IEditUserHistory) => {
    this.setState({
      selectedUser: { ...value },
      setColor: color.TERTIARY_GREEN,
      setBorder: color.PRIMARY_GREEN,
    });
  };

  handlePageClick = ({ selected: selectedPage }: ISelectPage) => {
    this.setState({ currentPage: { selected: selectedPage } }, () => {
      this.setState(
        (prevState) => ({
          page: {
            ...prevState.page,
            page: prevState.currentPage.selected + 1,
          },
        }),
        () => {
          this.props.getditUserHistory(this.state.page);
        }
      );
    });
  };

  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.replace(path);
  };

  renderTableHead = (values: Array<IEditUserHistoryTable>) => {
    const mapHeadTable = map(
      values,
      (data: IEditUserHistoryTable, index: number) => (
        <th className={`text-left ${index === 3 && "w-50"}`} key={index}>
          <span>{data.name}</span>
        </th>
      )
    );
    return mapHeadTable;
  };

  renderTableBody = (values: IEditUserHistory[]) => {
    const dataEmpty = (
      <tr>
        <td className="text-center row-members-list color-row p-2" colSpan={7}>
          {constants.dataNotFound}
        </td>
      </tr>
    );
    const mapData = map(values, (data: IEditUserHistory, index: number) => {
      return (
        <tr
          className={`row-members-list ${index % 2 === 0 ? "color-row" : ""}`}
          key={index}
          onClick={() => this.handleClickUser(data)}
        >
          <td className="text-left p2-l">
            <span>{data.username}</span>
          </td>
          <td className="text-left">
            <span>
              {format(new Date(data.updatedAt), "dd/MM/yyyy")}
            </span>
          </td>
          <td className="text-left">
            <span>{data.behavior}</span>
          </td>
          <td className="text-left">
            <span>{data.message}</span>
          </td>
        </tr>
      );
    });
    const renderBody = !isEmpty(values) ? mapData : dataEmpty;
    return renderBody;
  };

  handleOnChangeSearch = (values: string | Date, id: string) => {
    const searchKeys = `${id}`;
    const formatText =
      searchKeys !== "search"
        ? values !== null
          ? format(new Date(values), "ddMMyyyy")
          : ""
        : values;
    this.setState(
      (prevState) => ({
        tempDate: {
          ...prevState.tempDate,
          [searchKeys]:
            searchKeys !== "search"
              ? values
              : prevState.tempDate[searchKeys as "start_date" | "end_date"],
        },
        page: {
          ...prevState.page,
          page: this.state.currentPage.selected + 1,
          [searchKeys]: formatText,
        },
      }),
      () => {
        clearTimeout(this.state.queryTimeout);
        this.setState({
          queryTimeout: setTimeout(() => {
            this.setState(
              {
                selectedUser: {
                  behavior: "",
                  message: "",
                  updatedAt: "",
                  username: "",
                },
                setColor: color.SECONDARY_BG,
                setBorder: "",
              },
              () => this.props.getditUserHistory(this.state.page)
            );
          }, 2000),
        });
      }
    );
  };
  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.editUserHistoryTitle, active: false, path: '/members' },
      { label: constants.editUserHistorySubTitle, active: true },
    ];
    const TableBody = this.renderTableBody(
      this.props.getEditUserHistoryListData.dataList
    );
    const TableHead = this.renderTableHead(mappingThead);
    const { username } = this.state.selectedUser;
    return (
      <>
        <div className="paper-container history-container">
          <div className="row">
            <div className="sub-menu">
              <Breadcrumb
                items={navigates}
                handleOnClickItem={this.handleOnClickBreadcrumb}
              />
            </div>
          </div>
          <div className="paper-title">{constants.editUserHistoryTitle}</div>
          <div className="pr-3 paper-description">
            {constants.editUserHistorySubTitle}
          </div>
          <div className="paper-body">
            <div className="row m3-b">
              <div className="col-5">
                <div className="box-shadow">
                  <div className="text-area-title mt-0 mb-2">
                    {constants.textArea.title}
                  </div>
                  <TextArea
                    id={`message`}
                    name={`message`}
                    placeholder={`${constants.textArea.placeHolderTitle} ${get(
                      this.state.selectedUser,
                      "username",
                      {}
                    )} \n${constants.textArea.placeHolderSubtitle} ${get(
                      this.state.selectedUser,
                      "message",
                      {}
                    )}`}
                    borderHighlight={username ? color.SECONDARY_GREEN : ""}
                    backgroundHighlight={username ? color.TERTIARY_GREEN : ""}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="box-shadow">
              <div className="row">
                <div className="col">
                  <Input
                    id="search"
                    name="search"
                    type="text"
                    placeholder={constants.inputSearchTitle}
                    value={this.state.page.search}
                    onChange={(e) =>
                      this.handleOnChangeSearch(
                        e.target.value,
                        e.currentTarget.id
                      )
                    }
                  />
                </div>
                <div className="col">
                  <DatePicker
                    onChange={(val: Date) =>
                      this.handleOnChangeSearch(val, "start_date")
                    }
                    value={this.state.tempDate.start_date}
                  />
                </div>
                <p className="align-middle">-</p>
                <div className="col">
                  <DatePicker
                    onChange={(val: Date) =>
                      this.handleOnChangeSearch(val, "end_date")
                    }
                    value={this.state.tempDate.end_date}
                  />
                </div>
              </div>
              <div className="row mt-4 h-100">
                <div className="col">
                  <div className="table-container">
                    <div className="table-responsive-xl">
                      <table className="col">
                        <thead>
                          <tr>{TableHead}</tr>
                        </thead>
                        <tbody>{TableBody}</tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <Panginate
                        pageTotal={
                          this.props.getEditUserHistoryListData.total || 0
                        }
                        initialPage={this.state.page.page - 1}
                        pageRangeDisplayed={this.state.currentPage.selected}
                        onPageChange={this.handlePageClick}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default EditUserHistoryContainer;
