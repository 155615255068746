import React, { FC, useState, useEffect } from "react";
import { noop } from "lodash";
import colors from "constants/colors";
import "./buttonGroup.style.scss";

const defaultProps: IButtonGroup = {
  id: "",
  text: "",
  stylename: "normal",
  defaultState: false,
  color: colors.PRIMARY_BG,
  backgroundColor: colors.SECONDARY_PURPLE,
  onChangeState() {
    noop();
  },
  borderRadius: "2px",
  gameTypes: [],
  gameTypesIndex: 0,
  isNotAccessCase: false,
  isActive: false,
  isActiveCtrl: false,
};

const ButtonGroup: FC<IButtonGroup> = ({
  id,
  text,
  stylename,
  color,
  forceState,
  paddingX,
  paddingY,
  defaultState,
  backgroundColor,
  borderRadius,
  onChangeState,
  gameTypes,
  gameTypesIndex,
  isNotAccessCase,
  isActive,
  isActiveCtrl,
}) => {
  const [state, setState] = useState<boolean>(defaultState!);

  useEffect(() => {
    if (typeof forceState !== "undefined") {
      setState(forceState);
    }
  }, [forceState]);

  const handleOnStateChange = () => {
    const stateHandler = isActiveCtrl ? !isActive : !state;
    setState(stateHandler);
    onChangeState!(stateHandler);
  };

  const isForceState = typeof forceState !== "undefined";
  const usedState = isForceState ? forceState : isActiveCtrl ? isActive : state;

  const isFixedPaddingX = typeof paddingX === "number";
  const isFixedPaddingY = typeof paddingY === "number";

  const getGroupButtonClassName = () => {
    if (stylename === "group") {
      if (usedState) {
        if (gameTypes?.length === 2) {
          if (gameTypesIndex! % 2 === 0) {
            return "two-button-group-style-group-selected-left-container";
          }
          return "two-button-group-style-group-selected-right-container";
        }
        return "button-group-style-group-selected-container";
      }
      return "button-group-style-group-container";
    }
    if (stylename === "groupNoAccess") {
      return "button-group-not-access-container";
    }
    return "button-group-container";
  };

  const groupButtonClassName: string = getGroupButtonClassName().concat(
    " m-auto text-center p4-x"
  );

  return (
    <div
      id={id}
      className={groupButtonClassName}
      style={{
        backgroundColor: usedState
          ? stylename === "outline"
            ? "transparent"
            : backgroundColor
          : "transparent",
        color:
          stylename === "outline"
            ? usedState
              ? backgroundColor
              : color
            : usedState
            ? color
            : backgroundColor,
        borderColor:
          stylename === "outline"
            ? usedState
              ? backgroundColor
              : "transparent"
            : backgroundColor,
        paddingLeft: isFixedPaddingX ? paddingX : "unset",
        paddingRight: isFixedPaddingX ? paddingX : "unset",
        paddingTop: isFixedPaddingY ? paddingY : "unset",
        paddingBottom: isFixedPaddingY ? paddingY : "unset",
        flex: isFixedPaddingX ? "unset" : 1,
        borderRadius: `${borderRadius}`,
      }}
      onClick={!isNotAccessCase ? handleOnStateChange : () => {}}
    >
      {text}
    </div>
  );
};

ButtonGroup.defaultProps = defaultProps;

export default ButtonGroup;
