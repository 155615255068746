import React, { FC } from "react";
import colors from "constants/colors";
import "./LabelBox.style.scss";

const defaultProps: ILabelBox = {
  textLabel: "",
  textValue: "",
  customTextLabel: "",
  customTextValue: "",
  colorTextLabel: colors.TERTIARY_TEXT,
  colorTextValue: colors.SECONDARY_TEXT,
};

const LabelBoxComponent: FC<ILabelBox> = (props) => {
  const {
    textLabel,
    textValue,
    colorTextLabel,
    colorTextValue,
    customTextLabel,
    customTextValue,
  } = props;
  return (
    <div className="labelbox-component mt-3">
      <h6
        style={{ color: colorTextLabel }}
        className={`text-label mb-1 ${customTextLabel}`}
      >
        {textLabel}
      </h6>
      <h6
        style={{ color: colorTextValue }}
        className={`text-label mb-1 ${customTextValue}`}
      >
        {textValue}
      </h6>
    </div>
  );
};

LabelBoxComponent.defaultProps = defaultProps;

export default LabelBoxComponent;
