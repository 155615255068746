import React, { Component } from "react"
import "./news.style.scss"
import { isEmpty } from "lodash"
import { format } from "date-fns"

const constants = {
  empty: "ไม่มีข้อมูล...",
  dateTime:"วัน / เวลา",
  msg:"ข้อความ"
}

type DefaultProps = Readonly<typeof defaultProps>
const defaultProps: INewlistProps = {
  news: [],
  idFocus: -99,
  clickSelectNews() {},
}
class NewsComponent extends Component<DefaultProps & INewlistProps> {
  static defaultProps = defaultProps

  renderListNews = () => {
    if (isEmpty(this.props.news)) {
      return (
        <div className="row underline top-line pt-2 pb-2 text-center">
          <div className="col-10">
            <h6 className="text-news">{constants.empty}</h6>
          </div>
        </div>
      )
    }
    return this.props.news.map((data, index) => (
      <div
        className={`row underline item-news pt-2 pb-2 ${
          index === 0 ? "top-line" : ""
        } ${this.props.idFocus === data.id ? "focus-News" : ""}`}
        onClick={() => {
          this.props.clickSelectNews!(data.id, data.message)
        }}
        key={index}
      >
        <div className="d-flex col-2 date-time">
          <h6 className="text-news align-self-center">
            {format(new Date(data.createdAt), "dd/MM/yyyy HH:mm a")}
          </h6>
        </div>
        <div className="col-10 ">
          <h6 className="text-news text-over">{data.message}</h6>
        </div>
      </div>
    ))
  }

  render() {
    const RenderListNews = this.renderListNews()
    return (
      <div className="news-container">
        <div className="row mt-3 pb-2 px-3">
          <div className="col-2 date-time">
            <h5 className="text-head">{constants.dateTime}</h5>
          </div>
          <div className="col-10">
            <h5 className="text-head">{constants.msg}</h5>
          </div>
        </div>
        <div className="list-news px-3">{RenderListNews}</div>
      </div>
    )
  }
}
export default NewsComponent
