export const ON_CHANGE_MENU_REQUEST = "ON_CHANGE_MENU_REQUEST";
export const ON_CHANGE_MENU_SUCCESS = "ON_CHANGE_MENU_SUCCESS";
export const CLEAR_MENU = "CLEAR_MENU";

export const ON_CHANGE_SHOW_MENU_REQUEST = "ON_CHANGE_SHOW_MENU_REQUEST";
export const ON_CHANGE_SHOW_MENU_SUCCESS = "ON_CHANGE_SHOW_MENU_SUCCESS";
export const CLEAR_SHOW_MENU = "CLEAR_SHOW_MENU";

export const initialMenuState: IMenuActiveControl = {
  menuActive: "main",
};
export const initialShowMenuState: IShowMenu = {
  isShowMenu: false,
};
