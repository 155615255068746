import { connect } from "react-redux";
import TransactionListsContainer from "./TransactionLists.container";
import transactionSelector from "../../reduxs/credits/selector";
import {
  getTransaction,
  cancelTransaction,
} from "../../reduxs/credits/transactions/action";
const mapStateToProps = (
  state: RootReducers
): ITransactionListsContainerProps => {
  const transactionLists = transactionSelector.transactionLists(state);
  return {
    getTransactionListsIsFetching: transactionLists.isFetching,
    TransactionLists: transactionLists.data,
    getTransactionListsCode: transactionLists.code,
    getTransactionListsError: transactionLists.error,
  };
};

const mapDispatchToProps: ITransactionListsContainerAction = {
  getTransactionLists: (query) => getTransaction(query),
  cancelTransactionLists: cancelTransaction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionListsContainer);
