import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { financeChildAction } from "../actionCreator";
import { fetchGetFinanceChild } from "../services";

const getFinanceChild = (
  query: IQueryPagination & IV2QueryAgentFinanceChild
) => (dispatch: Dispatch) => {
  dispatch(financeChildAction.request({}));
  return fetchGetFinanceChild(query)
    .then(
      (
        res: AxiosResponse<IAPIResponse<IPagination<IV2RespAgentFinanceChild>>>
      ) => {
        dispatch(financeChildAction.success(res));
        return Promise.resolve(res.data.data.dataList[0])
      }
    )
    .catch((error: AxiosError<IAPIResponse>) => {
        dispatch(financeChildAction.failure(error))
        return Promise.reject()
    }     
    );
};

export { getFinanceChild };
