import axios, { AxiosResponse } from "axios"
import environment from "../../constants/environment"
import { transformer } from 'utils'
//48.GET: api/v2/agent/finance/customer ดูยอดจ่าย ลูกค้านอกระบบ
const fetchGetFinanceCustomer = (query: IQueryPagination | IV2QueryAgentFinanceCustomer) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.getFinanceCustomer}`
  return axios.get(`${endpoint}${transformer.urlSearchParams(query)}`)
}
//47.GET: api/v2/agent/finance/child ขอข้อมูลรายจ่าย รายรับ ระหว่างตัวเองกับลูกสมาชิก
const fetchGetFinanceChild = (query: IQueryPagination & IV2QueryAgentFinanceChild) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.getFinanceChild}`
  return axios.get(`${endpoint}${transformer.urlSearchParams(query)}`)
}

//80.GET: api/v2/agent/finance/customer/tx  ดูรายละเอียดรายรับ รายจ่ายระหว่างตัวเองกับผู้ใช้นอกระบบ
const fetchGetFinanceCustomerTx = (query: IQueryPagination | IV2QueryAgentFinanceCustomerTx) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.getFinanceCustomerTx}`
  return axios.get(`${endpoint}${transformer.urlSearchParams(query)}`)
}

//50.GET: api/v2/agent/finance/child/me ขอดูรายการจ่ายและรับชำระยอด จากลูกสมาชิก
const fetchGetFinanceChildMe = (query: IQueryPagination & IV2QueryAgentFinanceChildMe) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.getFinanceChildMe}`
  return axios.get(`${endpoint}${transformer.urlSearchParams(query)}`)
}

//51.GET: api/v2/agent/finance/host/me ขอดูรายการจ่ายและรับชำระยอด ของตนเองระหว่างตนเองกับหัวสมาชิก
const fetchGetFinanceHostMe = (query: IQueryPagination & IV2QueryAgentFinanceHostMe) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.getFinanceHostMe}`
  return axios.get(`${endpoint}${transformer.urlSearchParams(query)}`)
}

//81.POST: api/v2/agent/finance/customer/tx จ่ายหรือรับจ่ายผู้ใช้นอกระบบ
const PostFinanceCustomerTx = (data: IV2PayAgentFinanceCustomerTx) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.postFinanceCustomerTx}`
  return axios.post(endpoint, data)
}
const fetchPostFinanceApprove = (data: IV2PostAgentFinanceChildApprove) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.postFinanceApprove}`
  return axios.post(endpoint, data)
}
const fetchPostFinanceRequest = (data: IV2PostAgentFinanceChildRequest) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.postFinanceRequest}`
  return axios.post(endpoint, data)
}


//46.GET: api/v2/agent/finance/host ขอข้อมูลรายจ่าย รายรับ ระหว่างตัวเองกับหัวสมาชิก
const fetchGetFinanceHost = (query: Partial<IQueryPagination & IV2QueryAgentFinanceHost>): Promise<AxiosResponse<IAPIResponse<IPagination<IV2RespAgentFinanceHost>>>> => {
  const convertQueryToString = new URLSearchParams(transformer.snakecaseTransform(query)).toString()
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.getFinanceHost}`
  return axios.get(`${endpoint}?${convertQueryToString}`)
}

//51.GET: api/v2/agent/finance/host/me ขอดูรายการจ่ายและรับชำระยอด ของตนเองระหว่างตนเองกับหัวสมาชิก
const fetchGetFinanceHostBalance = (query: Partial<IQueryPagination & IV2QueryAgentFinanceHostMe>): Promise<AxiosResponse<IAPIResponse<IPagination<IV2RespAgentFinanceHostMe>>>> => {
  const convertQueryToString = new URLSearchParams(transformer.snakecaseTransform(query)).toString()
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.getFinanceHostMe}`
  return axios.get(`${endpoint}?${convertQueryToString}`)
}

// 53.POST: api/v2/agent/finance/host/approve
const fetchPostFinanceHostApprove = (data: IV2PostAgentFinanceHostApprove) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.postFinanceHostApprove}`
  return axios.post(endpoint, data)
}

// 49.POST: api/v2/agent/finance/host/request
const fetchPostFinanceHostRequest = (data: IV2PostAgentFinanceHostRequest) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.postFinanceHostRequest}`
  return axios.post(endpoint, data)
}



export {
  fetchGetFinanceChildMe,
  fetchGetFinanceCustomer,
  fetchGetFinanceChild,
  fetchGetFinanceHost,
  fetchPostFinanceApprove,
  fetchGetFinanceHostBalance,
  PostFinanceCustomerTx,
  fetchGetFinanceCustomerTx,
  fetchPostFinanceHostApprove,
  fetchPostFinanceHostRequest,
  fetchPostFinanceRequest,
  fetchGetFinanceHostMe
}
