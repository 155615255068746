import { createAction } from "typesafe-actions";
import {
  CONNECT_SOCKET,
  CONNECTED_SOCKET,
  CONNECT_SOCKET_ERROR,
  DISCONNECT_SOCKET,
  DISCONNECTED_SOCKET,
  LOG_SOCKET,
} from "./constants";

const connectSocketAction = createAction(CONNECT_SOCKET)<undefined>();

const connectedSocketAction = createAction(CONNECTED_SOCKET)<undefined>();

const connectSocketErrorAction = createAction(CONNECT_SOCKET_ERROR)<any>();

const disconnectSocketAction = createAction(DISCONNECT_SOCKET)<undefined>();

const disconnectedSocketAction = createAction(DISCONNECTED_SOCKET)<undefined>();

const logSocket = createAction(LOG_SOCKET)<any>();

export {
  logSocket,
  connectSocketAction,
  connectedSocketAction,
  connectSocketErrorAction,
  disconnectSocketAction,
  disconnectedSocketAction,
};
