import colors from 'constants/colors'
import './betLotteryGroup.style.scss'
import React, { Component } from 'react'
import { thai_th } from "constants/thai_th"
import { RouteComponentProps } from 'react-router'
import { find, get, includes, isEmpty, isNull, map, noop, } from 'lodash'
import { CODE_TO_NAME, meDataInitialValue } from 'constants/variables'
import { endOfWeek, format, startOfWeek, } from 'date-fns'
import { Breadcrumb, ButtonRadio, DatePickerNew, Dropdown, ErrorModal, LoadingCircle } from 'components'
import BetLotteryGroupRow from "./components/BetLotteryGroupRow"
import BetSumTotal from "./components/BetSumTotal"
import { responseCode, responseMessage } from "constants/response"
import { DetailBetGroup, SuccessModal } from "components/Modal"
const listOptionSearchRow1: { [type in string]: string } = {
  TODAY: "วันนี้",
  THIS_WEEK: "สัปดาห์นี้",
  MONTH: "เดือน",
}
const listOptionSearchRow2: { [type in string]: string } = {
  YESTERDAY: "เมื่อวาน",
  LAST_WEEK: "สัปดาห์ที่แล้ว",
  SINCE: "ตั้งแต่",
}

type DefaultProps = Readonly<typeof defaultProps>
const constants = {
  mainPageTitle: "ภาพรวม",
  betMeTitle: "รายการเดิมพัน",
  paperTitle: "รายการแทง (ตามชนิดหวย)",
  paperDescription: "แยกรายการตามชนิดหวย",
  dataNotFound: "ไม่พบข้อมูล...",
  rowPerPage: "จำนวนรายการต่อหน้า : ",
  optionSearch: "ตัวเลือกการค้นหา",
  titlePooiSuccess: "สำเร็จ",
  cancelPooiSuccess: "ยกเลิกโพยสำเร็จ",
};

const defaultProps: BetLotteryGroupContainerProps & BetLotteryGroupContainerAction = {
  postCancelBill() {noop()},
  onChangeIsLoaddingAction() { noop() },
  getBetExtraction() { noop() },
  getBetExtractionDetail() { noop() },
  getDataV2LotteryList() { noop() },
  meData: meDataInitialValue,
  getBetExtractionData: [],
  getBetExtractionIsFetching: false,
  getBetExtractionCode: 0,
  getBetExtractionError: "",
  getBetExtractionDetailData: [],
  getBetExtractionDetailIsFetching: false,
  getBetExtractionDetailCode: 0,
  getBetExtractionDetailError: "",
  lottoScheduleV2Data: [],
  lottoScheduleV2IsFetching: false,
  lottoScheduleV2Code: 0,
  lottoScheduleV2Error: "",
  postCancelBillCode : 0,
  postCancelBillIsFetching: false,
  postCancelBillError: "",
};

export default class BetMeHistoryContainer extends Component<
  BetLotteryGroupContainerProps & BetLotteryGroupContainerAction & DefaultProps & RouteComponentProps
> {
  static defaultProps = defaultProps;

  queryTimeout: NodeJS.Timeout | null = null;
  state: ILotteryGroupContainerState = {
    selectedOptionSearch: "TODAY",
    searchMonths: -1,
    dateStart: null,
    dateEnd: null,
    querySearch: {
      startCreatedAt: format(new Date(), "yyyy'-'MM'-'dd'T'00:00:00"),
      endCreatedAt: format(new Date(), "yyyy'-'MM'-'dd'T'23:59:59"),
    },
    nameBetDetail: 'GOVN',
    stBillGroup: '',
  };

  componentDidMount() {
    this.props.getDataV2LotteryList()
    this.props.getBetExtraction(this.state.querySearch)
  }

  componentDidUpdate(prevProps: BetLotteryGroupContainerProps) {
    if (prevProps.getBetExtractionIsFetching !== this.props.getBetExtractionIsFetching
      && !this.props.getBetExtractionIsFetching) {
      if (this.props.getBetExtractionCode !== responseCode.OK) {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            this.props.getBetExtractionCode,
            responseMessage()[0]
          ),
        });
      }
    }

    if (prevProps.lottoScheduleV2IsFetching !== this.props.lottoScheduleV2IsFetching
      && !this.props.lottoScheduleV2IsFetching) {
      this.props.onChangeIsLoaddingAction(false)
      if (this.props.lottoScheduleV2Code !== responseCode.OK) {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            this.props.lottoScheduleV2Code,
            responseMessage()[0]
          ),
        });
      }
    }

    if (prevProps.getBetExtractionDetailIsFetching !== this.props.getBetExtractionDetailIsFetching
      && !this.props.getBetExtractionDetailIsFetching) {
        this.props.onChangeIsLoaddingAction(false)
      if (this.props.getBetExtractionDetailCode === responseCode.OK) {
        const lottoSchedule = find(this.props.lottoScheduleV2Data, { 'name': this.state.nameBetDetail })
        DetailBetGroup.show({
          cancelAction: () => {
            DetailBetGroup.hide()
          },
          nameLotto: this.state.nameBetDetail,
          dataBetDetail: this.props.getBetExtractionDetailData,
          stLottoSchedule: get(lottoSchedule, 'status', 'OPEN'),
          stBillGroup: this.state.stBillGroup
        });
      }
      else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            this.props.getBetExtractionDetailCode,
            responseMessage()[0]
          ),
        });
      }
    }

    if (
      prevProps.postCancelBillIsFetching !==
      this.props.postCancelBillIsFetching &&
      !this.props.postCancelBillIsFetching
    ) {
      if (this.props.postCancelBillError !== "") {
        ErrorModal.show({
          action: () => ErrorModal.hide(),
          description: get(
            responseMessage(),
            this.props.postCancelBillCode,
            responseMessage()[0]
          ),
        })
      } else {
        SuccessModal.show({
          action: () => {
            SuccessModal.hide()
            this.props.getBetExtraction(this.state.querySearch)
          },
          title: constants.titlePooiSuccess,
          description: constants.cancelPooiSuccess,
        })
      }
    }
  }

  fetchGetData = () => {
    this.props.getBetExtraction({
      ...this.state.querySearch
    })
  }

  handleClickButtonRadio = (val: string) => {
    if (this.state.selectedOptionSearch === val) {
      this.setState({
        selectedOptionSearch: '',
        searchMonths: -1,
        dateStart: null,
        dateEnd: null,
        querySearch: {
          ...this.state.querySearch,
          startCreatedAt: "",
          endCreatedAt: ""
        }
      }, () => { this.fetchGetData() })
    }
  }

  handleOnChangeButtonRadio = (val: ISelectedOptionSearch) => {
    let startDate: Date = new Date()
    let endDate: Date = new Date()
    this.setState({ dateStart: null, dateEnd: null, searchMonths: -1 })
    if (val === "MONTH" || val === "SINCE") {
      this.setState({ selectedOptionSearch: val })
    } else {
      if (val === "TODAY") {
        startDate = new Date()
        endDate = new Date()
      } else if (val === "YESTERDAY") {
        startDate = new Date(new Date().setDate(new Date().getDate() - 1))
        endDate = new Date(new Date().setDate(new Date().getDate() - 1))
      } else if (val === "THIS_WEEK") {
        startDate = new Date(startOfWeek(new Date(), { weekStartsOn: 0 }))
        endDate = new Date(endOfWeek(new Date(), { weekStartsOn: 0 }))
      } else if (val === "LAST_WEEK") {
        startDate = new Date(startOfWeek(new Date(new Date().setDate(new Date().getDate() - 7)), { weekStartsOn: 0 }))
        endDate = new Date(endOfWeek(new Date(new Date().setDate(new Date().getDate() - 7)), { weekStartsOn: 0 }))
      }
      this.setState(
        {
          selectedOptionSearch: val,
          querySearch: {
            ...this.state.querySearch,
            startCreatedAt: format((startDate), "yyyy'-'MM'-'dd'T'00:00:00"),
            endCreatedAt: format((endDate), "yyyy'-'MM'-'dd'T'23:59:59")
          }
        }, () => { this.fetchGetData() }
      )
    }
  }


  detailBetGroup = (query: IV3QueryGetDetailExtractionAgentBetLottery & { status: string }) => {
    this.setState({ nameBetDetail: query.name, stBillGroup: query.status})
    this.props.getBetExtractionDetail(query)
    this.props.onChangeIsLoaddingAction(true)
  }

  renderSumTotal = (position: number) => {
    return (
      <div key={'sum-bet-group'}>
        <BetSumTotal
          position={position}
          lottoSchedule={this.props.lottoScheduleV2Data}
          trxData={this.props.getBetExtractionData}
        />
      </div>
    )
  }
  renderTableMembers = () => {
    const dataEmpty = (
      <table>
        <tbody>
          <tr>
            <td className="text-center" colSpan={15}>
              {'ไม่พบข้อมูล...'}
            </td>
          </tr>
        </tbody>
      </table>
    )
    const loadingTable = (
      <table>
        <tbody>
          <tr>
            <td className="text-center" colSpan={15}>
              <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
            </td>
          </tr>
        </tbody>
      </table>
    )

    const mapData = map(this.props.getBetExtractionData, (item: IV3ExtractionGroupingLottery, index: number) => {
      return (
        <div key={index}>
          <h5 className="primary-text m1-b m1-t" key={index}>
            {(CODE_TO_NAME[includes(item.name, "YEGEE") ? "YEGEE" : item.name]).replace("หวย", "") + (includes(item.name, "YEGEE")
              ? ` รอบที่ ${item.name.replace("YEGEE_", '')}`
              : "")}
          </h5>
          <BetLotteryGroupRow
            loading={this.props.getBetExtractionIsFetching}
            lottoSchedule={this.props.lottoScheduleV2Data}
            trxData={item.trx}
            onClick={this.detailBetGroup}
            onCancelBill={this.props.postCancelBill}
          />
        </div>
      )
    })
    const renderTable = this.props.getBetExtractionIsFetching ?  loadingTable : !isEmpty(mapData) ? mapData : dataEmpty
    return <>{renderTable}</>
  }

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.mainPageTitle, active: false, path: "/dashboard" },
      // { label: constants.betTitle, active: false, path: "/bet" },
      { label: constants.paperTitle, active: true },
    ]

    const TableMembersRow = this.renderTableMembers
    return (
      <div className="paper-container bet-group-container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="sub-menu" style={{ width: "fit-content" }}>
              <Breadcrumb
                items={navigates}
                handleOnClickItem={(path: string) => this.props.history.replace(path)}
              />
            </div>
          </div>
        </div>
        <div className="paper-title">{constants.paperTitle}</div>
        <div className="paper-description">{constants.paperDescription}</div>
        <div className="paper-body">
          <div className="box-shadow">
            <h5 className='text-bold'>{constants.paperDescription}</h5>
            <div className='option-search my-3'>
              <div className='row'>
                <div className='col-12'>
                  <h6 className='text-bold'>{constants.optionSearch}</h6>
                </div>
              </div>
              <div className='row mt-2 d-flex align-items-center box-search'>
                <div className='box-buttonRadio ml-3'>
                  <ButtonRadio
                    value={this.state.selectedOptionSearch}
                    datas={
                      Object.keys(listOptionSearchRow1).map((item, index) => {
                        return { id: item, value: item, text: listOptionSearchRow1[item as ISelectedOptionSearch] }
                      })
                    }
                    className="row justify-content-between"
                    classNameRadio='buttonRadio-item px-0'
                    name="listOptionSearch"
                    onClick={this.handleClickButtonRadio}
                    onChange={this.handleOnChangeButtonRadio}
                  />
                </div>
                <div className='col-3 box-search-item'>
                  <div className={`dropdown-box ${this.state.selectedOptionSearch !== "MONTH" && "disable"}`}>
                    <Dropdown
                      value={this.state.searchMonths === -1 ? undefined : this.state.searchMonths}
                      options={(thai_th.months).map((item, index) => { return { value: index, label: item[0], disabled: false } })}
                      controlBackgroundColor={this.state.selectedOptionSearch !== "MONTH" ? colors.SECONDARY_BG : colors.PRIMARY_BG}
                      placeholder="ค้นหาเดือน"
                      disable={this.state.selectedOptionSearch !== "MONTH"}
                      menuStyles={{ width: "100%" }}
                      handleOnChange={(target: IOptions) => {
                        let startDate = new Date(new Date().getFullYear() - (new Date().getMonth() < Number(target.value) ? 1 : 0), Number(target.value), 1)
                        let endDate = new Date(new Date().getFullYear() - (new Date().getMonth() < Number(target.value) ? 1 : 0), Number(target.value) + 1, 0)
                        this.setState(
                          {
                            searchMonths: target.value,
                            querySearch: {
                              ...this.state.querySearch,
                              startCreatedAt: format((startDate), "yyyy'-'MM'-'dd'T'00:00:00"),
                              endCreatedAt: format((endDate), "yyyy'-'MM'-'dd'T'23:59:59")
                            }
                          }, () => { this.fetchGetData() }
                        )
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='row mt-2 d-flex align-items-center box-search'>
                <div className='box-buttonRadio ml-3'>
                  <ButtonRadio
                    value={this.state.selectedOptionSearch}
                    datas={
                      Object.keys(listOptionSearchRow2).map((item, index) => {
                        return {
                          id: item.toString(),
                          value: item,
                          text: listOptionSearchRow2[item as ISelectedOptionSearch]
                        }
                      })
                    }
                    className="row justify-content-between"
                    classNameRadio='buttonRadio-item px-0'
                    name="listOptionSearch"
                    onClick={this.handleClickButtonRadio}
                    onChange={this.handleOnChangeButtonRadio}
                  />
                </div>
                <div className='col-3 box-search-item'>
                  <DatePickerNew
                    isShowIcon
                    value={this.state.dateStart}
                    placeholder="วันเริ่มต้น"
                    format="DD MMM YYYY"
                    editable={false}
                    onChange={(value: any) => {
                      if (value !== null) {
                        let startDate = new Date(Number(value))
                        this.setState(
                          {
                            dateStart: value,
                            dateEnd: isNull(this.state.dateEnd) ? new Date() : new Date(this.state.dateEnd),
                            querySearch: {
                              ...this.state.querySearch,
                              startCreatedAt: format(startDate, "yyyy'-'MM'-'dd'T'00:00:00"),
                              endCreatedAt: format(isNull(this.state.dateEnd) ? new Date() : new Date(this.state.dateEnd), "yyyy'-'MM'-'dd'T'23:59:59")
                            }
                          }, () => { this.fetchGetData() }
                        )
                      }
                    }}
                    disabled={this.state.selectedOptionSearch !== "SINCE"}
                  />
                </div>
                <div className='col-3 box-search-item'>
                  <DatePickerNew
                    isShowIcon
                    value={this.state.dateEnd}
                    placeholder="วันสิ้นสุด"
                    format="DD MMM YYYY"
                    editable={false}
                    onChange={(value: any) => {
                      if (value !== null) {
                        let startDate = new Date(Number(value))
                        this.setState(
                          {
                            dateEnd: value,
                            querySearch: {
                              ...this.state.querySearch,
                              startCreatedAt: format(isNull(this.state.dateStart) ? new Date() : new Date(this.state.dateStart), "yyyy'-'MM'-'dd'T'00:00:00"),
                              endCreatedAt: format(startDate, "yyyy'-'MM'-'dd'T'23:59:59"),
                            }
                          }, () => { this.fetchGetData() }
                        )
                      }
                    }}
                    disabled={this.state.selectedOptionSearch !== "SINCE" || isNull(this.state.dateStart)}
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='lotto-bill'>
                  {this.renderSumTotal(0)}
                  {/* <SumTotal/> */}
                  <TableMembersRow />
                  <div className='m1-t'>
                    {this.renderSumTotal(this.props.getBetExtractionData.length - 1)}
                    {/* <SumTotal position={0} /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
