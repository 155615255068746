import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { fetPostPayRequest } from "../services";
import { payRequestAction } from "../actionCreator";
import { loaderAction } from "../../Loader/actionCreator";

const postPayRequest = (body: IAgentPayRequest) => async (
  dispatch: Dispatch
) => {
  dispatch(loaderAction.success(true));
  dispatch(payRequestAction.request({}));
  await fetPostPayRequest(body)
    .then((response: AxiosResponse<IAPIResponse<IAgentPayRequest>>) => {
      dispatch(loaderAction.success(false));
      return dispatch(payRequestAction.success(response));
    })
    .catch((error: AxiosError<APIResponse>) => {
      dispatch(loaderAction.success(false));
      return dispatch(payRequestAction.failure(error));
    });
};

export { postPayRequest };
