import { connect } from "react-redux";
import authSelector from "reduxs/authentication/selector";
import HomeResetPasswordContainer from "./HomeResetPassword.container";
import {
  loginInitialPassword,
  loginPassword,
} from "reduxs/authentication/login/action";
import { onChangeIsLoaddingAction } from "reduxs/Loader/action";

const mapStateToProps = (state: RootReducers): IResetPasswordContainerProps => {
  const loginState = authSelector.login(state);
  return {
    loginCode: loginState.code,
    loginError: loginState.error,
    loginIsFetching: loginState.isFetching,
    userStatus: loginState.data.status!,
    qrcode: loginState.data.qrcode || "",
    secret: loginState.data.secret || "",
    verify: loginState.data.verify,
  };
};

const mapDispatchToProps: IResetPasswordContainerActionProps = {
  loginInitialPassword,
  loginPassword,
  onChangeIsLoaddingAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeResetPasswordContainer);
