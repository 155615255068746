import React, { FC, useEffect, useState } from "react";
import { isEmpty, noop } from "lodash";
import "./button.style.scss";
import colors from "constants/colors";

const defaultProps: IButton = {
  id: "",
  text: "",
  onFocus() {},
  onClick() {},
  size: "medium",
  type: "normal",
  disabled: false,
  buttonType: "button",
  backgroundColor: colors.PRIMARY_GREEN,
  backgroundHoverColor: colors.SECONDARY_GREEN,
  borderRadius: "8px",
  textColor: colors.PRIMARY_BG,
  canHover: true,
  transitionType: "",
  borderColor: "",
  buttonClass: "",
  textSize: 14,
  lineHeight: undefined,
};

const Button: FC<IButton> = (props) => {
  const {
    id,
    text,
    type,
    size,
    onClick,
    disabled,
    buttonType,
    backgroundColor,
    backgroundHoverColor,
    borderRadius,
    textColor,
    borderColor,
    canHover,
    transitionType,
    children,
    buttonClass,
    textSize,
    onFocus,
    lineHeight,
  } = props;
  const [hoverStates, setHoverStates] = useState({
    hovered: false,
    hoverColor: backgroundColor,
  });

  useEffect(() => {
    setHoverStates({
      hovered: hoverStates.hovered,
      hoverColor: backgroundColor,
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [backgroundColor])

  const handleOnMouseEnter = () => {
    setHoverStates({
      ...hoverStates,
      hoverColor: backgroundHoverColor,
      hovered: true,
    });
  };

  const handleOnMouseLeave = () => {
    setHoverStates({
      ...hoverStates,
      hoverColor: backgroundColor,
      hovered: false,
    });
  };

  let buttonStyle = {
    borderRadius: borderRadius,
    backgroundColor: hoverStates.hoverColor,
  };

  const containerClass = `btn
    ${type}
    ${size}
    ${disabled ? "disabled-btn" : ""}
    ${transitionType === "typeOne" ? "btn-transition" : ""}
    ${hoverStates.hovered && !isEmpty(borderColor) ? "" : "hide-border"}
    ${canHover ? "" : "cannot-hover"}
    ${buttonClass}`;

  const textClass = `text-btn 
    ${type === "underline" && !disabled ? "underline" : ""}`;

  return (
    <>
      <button
        onMouseLeave={handleOnMouseLeave}
        onMouseEnter={handleOnMouseEnter}
        onClick={disabled ? noop : onClick}
        id={id}
        type={buttonType}
        style={buttonStyle}
        className={containerClass}
        ref={(element) => {
          if (element)
            element.style.setProperty(
              "border-color",
              `${borderColor}`,
              "important"
            );
        }}
        onFocus={onFocus}
      >
        <div className={textClass} style={{ color: textColor, fontSize: textSize , lineHeight: lineHeight}}>
          {text}{children}
        </div>
      </button>
    </>
  );
};

Button.defaultProps = defaultProps;

export default Button;
