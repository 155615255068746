import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { loaderAction } from "reduxs/Loader/actionCreator";
import { getHistorySummaryAction } from "../actionCreator";
import { fetchGetHistorySummary } from "../services";

const getDataHistorySummary = (
  query: IAgentQueryLotteryHistorySummary | IQueryPagination
) => async (dispatch: Dispatch) => {
  dispatch(loaderAction.success(true));
  dispatch(getHistorySummaryAction.request({}));
  await fetchGetHistorySummary(query)
    .then(
      (
        res: AxiosResponse<
          IAPIResponse<IPagination<IResponseAgentQueryLotteryHistorySummary>>
        >
      ) => {
        dispatch(loaderAction.success(false));
        dispatch(getHistorySummaryAction.success(res));
      }
    )
    .catch((error: AxiosError<IAPIResponse>) => {
      dispatch(loaderAction.success(false));
      dispatch(getHistorySummaryAction.failure(error));
    });
};

const clearDataHistorySummary = () => (dispatch: Dispatch) =>
  dispatch(getHistorySummaryAction.cancel({}));

export { getDataHistorySummary, clearDataHistorySummary };
