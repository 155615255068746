import { AxiosError, AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { ActionType, createReducer } from "typesafe-actions";
import { responseConverter } from "utils";
import * as ownerAction from "./actionCreator";
import { ownerSettingLotteryUpdateState, ownerSettingState, ownerUpdateState } from "./constants";

export type OwnerActionType = ActionType<typeof ownerAction>;

const update = createReducer<ReducerState<{}>, OwnerActionType>(
  ownerUpdateState
)
  .handleAction(
    ownerAction.postOwnerUpdateAction.request,
    (state: ReducerState<{}>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    ownerAction.postOwnerUpdateAction.success,
    (state: ReducerState<{}>, action: OwnerActionType) => {
      const payload: AxiosResponse<IAPIResponse<{}>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: { ...state.data, ...payload.data.data },
        error: "",
      };
    }
  )
  .handleAction(
    ownerAction.postOwnerUpdateAction.failure,
    (state: ReducerState<{}>, action: OwnerActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );

  const setting = createReducer<ReducerState<{}>, OwnerActionType>(
    ownerSettingState
  )
    .handleAction(
      ownerAction.postOwnerSettingAction.request,
      (state: ReducerState<{}>) => {
        return {
          ...state,
          isFetching: true,
        };
      }
    )
    .handleAction(
      ownerAction.postOwnerSettingAction.success,
      (state: ReducerState<{}>, action: OwnerActionType) => {
        const payload: AxiosResponse<IAPIResponse<{}>> = action.payload;
        const convertedResponse = responseConverter.getMessage(payload);
        return {
          ...state,
          isFetching: false,
          code: convertedResponse.code,
          data: { ...state.data, ...payload.data.data },
          error: "",
        };
      }
    )
    .handleAction(
      ownerAction.postOwnerSettingAction.failure,
      (state: ReducerState<{}>, action: OwnerActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload;
        const convertedResponse = responseConverter.getMessage(payload);
        return {
          ...state,
          isFetching: false,
          code: convertedResponse.code,
          error: convertedResponse.message,
        };
      }
    );

    const settingLotteryUpdate = createReducer<ReducerState<{}>, OwnerActionType>(
      ownerSettingLotteryUpdateState
    )
      .handleAction(
        ownerAction.postOwnerSettingLotteryUpdateAction.request,
        (state: ReducerState<{}>) => {
          return {
            ...state,
            isFetching: true,
          };
        }
      )
      .handleAction(
        ownerAction.postOwnerSettingLotteryUpdateAction.success,
        (state: ReducerState<{}>, action: OwnerActionType) => {
          const payload: AxiosResponse<IAPIResponse<{}>> = action.payload;
          const convertedResponse = responseConverter.getMessage(payload);
          return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data: { ...state.data, ...payload.data.data },
            error: "",
          };
        }
      )
      .handleAction(
        ownerAction.postOwnerSettingLotteryUpdateAction.failure,
        (state: ReducerState<{}>, action: OwnerActionType) => {
          const payload: AxiosError<IAPIResponse> = action.payload;
          const convertedResponse = responseConverter.getMessage(payload);
          return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
          };
        }
      );

export default combineReducers({
  update,
  setting,
  settingLotteryUpdate
});
