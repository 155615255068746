import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { financeChildRequestAction } from "../actionCreator";
import { fetchPostFinanceRequest } from "../services";

const postFinanceChildRequest = (data: IV2PostAgentFinanceChildRequest) => (
  dispatch: Dispatch
) => {
  dispatch(financeChildRequestAction.request({}));
  return fetchPostFinanceRequest(data)
    .then((res: AxiosResponse<IV2RespAgentFinanceChildRequest>) => {
      dispatch(financeChildRequestAction.success(res));
    })
    .catch((error: AxiosError<IAPIResponse>) =>
      dispatch(financeChildRequestAction.failure(error))
    );
};

export { postFinanceChildRequest };
