import "./register2FAForm.style.scss";
import React, { Component } from "react";
import { Form, FormikProps } from "formik";
import { ResponsiveIcon, Input, Button, AlertNotification } from "components";
import Phone from "assets/images/global/2FA/img-phone.svg";
import Lock from "assets/images/global/2FA/lock.svg";
import color from "constants/colors";
import { ReactComponent as Copy } from "assets/images/global/2FA/copy.svg";
import { Carousel } from "./components/index";
import { RouteComponentProps, withRouter } from "react-router";
import { EUserStatus } from "utils/enum";
import copy from "copy-to-clipboard";

const constants = {
  authentication: "2-FACTOR AUTHENTICATION",
  step1: "ขั้นตอนที่ 1",
  step1Description: `เปิดแอปเพื่อสแกน QR CODE และเก็บรหัสสำรองสแกน QR CODE ในแอป GOOGLE AUTHENTICATOR`,
  step1Alternative: `หากไม่สามารถสแกน QR CODE โปรดเพิ่มคีย์ ด้านล่างนี้ด้วยตนเองลงในแอป`,
  step1Warning:
    "โปรดเก็บคีย์นี้ไว้บนกระดาษ คีย์นี้จะช่วยให้คุณสามารถ กู้คืน\n2-FACTOR AUTHENTICATION ได้ใน กรณีที่โทรศัพท์สูญหาย",
  step2: "ขั้นตอนที่ 2",
  step2Description: "ใส่รหัส 6 หลักที่ได้จากแอป",
  sixDigits: "ตัวเลข 6 หลัก",
  confirm: "ยืนยัน",
  cancel: "ยกเลิก",
  copied: "คัดลอกแล้ว!",
};

type DefaultProps = Readonly<typeof defaultProps>;
const defaultProps: IRegister2FAComponentProps &
  IRegister2FAComponentActionProps = {};

class Register2FAComponent extends Component<
  FormikProps<IRegister2FAForm> &
    IRegister2FAComponentActionProps &
    IRegister2FAComponentProps &
    DefaultProps &
    RouteComponentProps<{}, {}, IRouteLogin>,
  IRegister2FAComponentState
> {
  static defaultProps = defaultProps;

  state: IRegister2FAComponentState = {
    secret: "xxxxxxxxxxx",
    qrcode: "",
  };
  componentDidMount(): void {
    const { location, action } = this.props.history;
    if (location.state) {
      if (
        location.state.userStatus !== EUserStatus.WAIT_SECRET ||
        action === "POP"
      ) {
        this.props.history.push("/", { from: this.props.location });
      }
      this.setState({ secret: location.state.secret || "" });
      this.setState({ qrcode: location.state.qrcode || "" });
    } else {
      this.props.history.push("/", { from: this.props.location });
    }
  }

  renderRegister2FALeftColumn = () => {
    return (
      <div className="col-6 body-3 d-flex flex-column">
        <div className="row">
          <div className="col-12 text-center">
            <p>{constants.authentication}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Carousel />
          </div>
        </div>
        <div className="row flex-grow-1">
          <div className="col-12 d-flex justify-content-center m-t-2FA">
            <ResponsiveIcon
              icon={Phone}
              alt="phone"
              className="phone-icon w-25"
            />
          </div>
        </div>
      </div>
    );
  };

  renderQRCode = (data: string) => {
    return <img src={`${data}`} alt="qrcode" />;
  };

  onPressCopy = (link: string) => {
    copy(link);
    AlertNotification.show({
      text: constants.copied,
      countdown: true,
      textColor: color.PRIMARY_BG,
    });
  };

  renderRegister2FARightColumn = () => {
    const {
      errors,
      touched,
      values,
      handleChange,
      handleBlur,
      isValid,
    } = this.props;
    const QRCode = this.renderQRCode;

    return (
      <div className="col-6">
        <div className="box-shadow overflow-hidden secondary-bg rounded-0 h-100 d-flex flex-column">
          <div className="row">
            <div className="col-2 d-flex align-items-center justify-content-end pr-0">
              <ResponsiveIcon icon={Lock} alt="lock" className="lock-icon" />
            </div>
            <div className="col-10 d-flex align-items-center">
              <div className="body-3 font-weight-bold pre-wrap">
                {constants.step1}
              </div>
            </div>
          </div>
          <div className="row m1-t">
            <div className="col-2">
              <></>
            </div>
            <div className="col-10">
              <div className="body-4 pre-wrap">
                {constants.step1Description}
              </div>
            </div>
          </div>
          <div className="row m2-t">
            <div className="col-12 d-flex justify-content-center">
              {QRCode(this.state.qrcode)}
            </div>
          </div>
          <div className="row m2-t">
            <div className="col-2">
              <></>
            </div>
            <div className="col-10">
              <div className="body-4 pre-wrap">
                {constants.step1Alternative}
              </div>
            </div>
          </div>
          <div className="row m2-t">
            <div className="col-2">
              <></>
            </div>
            <div
              className="col-10 primary-bg d-flex"
              style={{ backgroundClip: "content-box" }}
            >
              <div className="w-75">
                <Input
                  id="2FAkey"
                  name="2FAKey"
                  label="Key2FA"
                  value={this.state.secret}
                  disabled={true}
                  setPadding="3px"
                  backgroundColor={color.PRIMARY_BG}
                />
              </div>
              <div className="w-25 d-flex align-items-center justify-content-center">
                <Copy
                  className="copy-icon"
                  onClick={() => this.onPressCopy(this.state.secret)}
                />
              </div>
            </div>
          </div>
          <div className="row m2-t">
            <div className="col-2">
              <></>
            </div>
            <div className="col-10">
              <div className="body-4-red pre-wrap">
                {constants.step1Warning}
              </div>
            </div>
          </div>
          <div className="row m4-t m-t-2FA-2">
            <div className="col-2 d-flex align-items-center justify-content-end pr-0">
              <ResponsiveIcon icon={Lock} alt="lock" className="lock-icon" />
            </div>
            <div className="col-10 body-3 font-weight-bold">
              {constants.step2}
            </div>
          </div>
          <div className="row">
            <div className="col-2">
              <></>
            </div>
            <div className="col-10">
              <div className="body-4 pre-wrap">
                {constants.step2Description}
              </div>
            </div>
          </div>
          <div className="row m2-t">
            <div className="col-2">
              <></>
            </div>
            <div className="col-10">
              <Input
                id="password2FA"
                name="password2FA"
                label="Password2FA"
                placeholder={constants.sixDigits}
                value={values.password2FA}
                onBlur={handleBlur}
                onChange={handleChange}
                error={!!errors.password2FA && touched.password2FA}
                errorMessage={errors.password2FA}
                type="password2FA"
                setPadding="3px"
                backgroundColor={color.PRIMARY_BG}
                borderWidth="0px"
              />
            </div>
          </div>
          <div className="row m4-t justify-content-end flex-grow-1 align-items-end">
            <div className="col-auto">
              <Button
                id="cancel-2FA"
                textColor={color.SECONDARY_TEXT}
                size="large"
                text={constants.cancel}
                canHover={false}
                backgroundColor={color.SECONDARY_BG}
                onClick={this.returnToLoginPage}
              />
            </div>
            <div className="col-5">
              <Button
                id="confirm-2FA"
                buttonType="submit"
                size="large"
                text={constants.confirm}
                transitionType="typeOne"
                disabled={isValid ? false : true}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  returnToLoginPage = () => {
    const { history, location } = this.props;

    history.push("/", { from: location });
  };

  renderRegister2FA = () => {
    const Register2FALeftColumn = this.renderRegister2FALeftColumn;
    const Register2FARightColumn = this.renderRegister2FARightColumn;

    return (
      <div className="paper-public-body">
        <div className="box-shadow overflow-hidden">
          <div className="row">
            <Register2FALeftColumn />
            <Register2FARightColumn />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const Register2FA = this.renderRegister2FA;

    return (
      <Form>
        <div className="paper-public-container register-2FA-container">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-7">
              <Register2FA />
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

export default withRouter(Register2FAComponent);
