export const GET_EDIT_USER_LIST_REQUEST = 'GET_EDIT_USER_LIST_REQUEST'
export const GET_EDIT_USER_LIST_SUCCESS = 'GET_EDIT_USER_LIST_SUCCESS'
export const GET_EDIT_USER_LIST_FAILURE = 'GET_EDIT_USER_LIST_FAILURE'
export const CLEAR_EDIT_USER_LIST = 'CLEAR_EDIT_USER_LIST'

export const getEditUserHistoryState: ReducerState<IEditUserHistory> = {
    isFetching: false,
    code: 0,
    data: {
        behavior: '',
        updatedAt: '',
        username: '',
        message: '',
    },
    error: ''
}

export const getEditUserHistoryListState: ReducerState<IPagination<IEditUserHistory>> = {
    isFetching: false,
    code: 0,
    data: {
        totalRecord:0,
        dataList: [],
        total: 0,
        limit: 0,
        page: 0,
    },
    error: ''
}
