import { AxiosError, AxiosResponse } from "axios";
import { createAction, createAsyncAction } from "typesafe-actions";
import {
  GET_YEGEE_GAME_LIST_REQUEST,
  GET_YEGEE_GAME_LIST_SUCCESS,
  GET_YEGEE_GAME_LIST_FAILURE,
  UPDATE_YEGEE_GAME_LIST_REQUEST,
  UPDATE_YEGEE_GAME_LIST_SUCCESS,
  UPDATE_YEGEE_GAME_LIST_FAILURE,
  POST_YEGEE_GAME_HISTORY_REQUEST,
  POST_YEGEE_GAME_HISTORY_SUCCESS,
  POST_YEGEE_GAME_HISTORY_FAILURE,
  POST_YEGEE_GAME_HISTORY_CANCEL,
  CLEAR_YEGEE_GAME_HISTORY,
  POST_PLAY_YEGEE_GAME_REQUEST,
  POST_PLAY_YEGEE_GAME_SUCCESS,
  POST_PLAY_YEGEE_GAME_FAILURE,
  POST_PLAY_YEGEE_GAME_CANCEL,
  GET_YEGEE_SUM_REQUEST,
  GET_YEGEE_SUM_SUCCESS,
  GET_YEGEE_SUM_FAILURE,
  GET_YEGEE_SUM_CANCEL,
  LISTEN_YEGEE_SUM_SOCKET,
  UNLISTEN_YEGEE_SUM_SOCKET,
  UPDATE_YEGEE_SUM_REQUEST,
  UPDATE_YEGEE_SUM_SUCCESS,
  UPDATE_YEGEE_SUM_FAILURE,
  CLEAR_YEGEE_SUM,
  GET_PLAYED_YEGEE_LIST_REQUEST,
  GET_PLAYED_YEGEE_LIST_SUCCESS,
  GET_PLAYED_YEGEE_LIST_FAILURE,
  GET_PLAYED_YEGEE_LIST_CANCEL,
  LISTEN_PLAYED_YEGEE_LIST_SOCKET,
  UNLISTEN_PLAYED_YEGEE_LIST_SOCKET,
  UPDATE_PLAYED_YEGEE_LIST_REQUEST,
  UPDATE_PLAYED_YEGEE_LIST_SUCCESS,
  UPDATE_PLAYED_YEGEE_LIST_FAILURE,
  CLEAR_PLAYED_YEGEE_LIST,
  GET_YEGEE_GAME_REQUEST,
  GET_YEGEE_GAME_SUCCESS,
  GET_YEGEE_GAME_FAILURE,
  GET_YEGEE_GAME_CANCEL,
  UPDATE_YEGEE_GAME_REQUEST,
  UPDATE_YEGEE_GAME_SUCCESS,
  UPDATE_YEGEE_GAME_FAILURE,
} from "./constants";

const getYeegeGameListAction = createAsyncAction(
  GET_YEGEE_GAME_LIST_REQUEST,
  GET_YEGEE_GAME_LIST_SUCCESS,
  GET_YEGEE_GAME_LIST_FAILURE
)<any, AxiosResponse<IAPIResponse<ILottoGame[]>>, AxiosError<IAPIResponse>>();

const updateYegeeGameListAction = createAsyncAction(
  UPDATE_YEGEE_GAME_LIST_REQUEST,
  UPDATE_YEGEE_GAME_LIST_SUCCESS,
  UPDATE_YEGEE_GAME_LIST_FAILURE
)<any, IAPIResponse<ILottoGame[]>, any>();

const postYegeeGameHistoryAction = createAsyncAction(
  POST_YEGEE_GAME_HISTORY_REQUEST,
  POST_YEGEE_GAME_HISTORY_SUCCESS,
  POST_YEGEE_GAME_HISTORY_FAILURE,
  POST_YEGEE_GAME_HISTORY_CANCEL,
)<any, AxiosResponse<IAPIResponse<IYegeeGameHistory>>, AxiosError<IAPIResponse>, any>()

const postYegeePlayAction = createAsyncAction(
  POST_PLAY_YEGEE_GAME_REQUEST,
  POST_PLAY_YEGEE_GAME_SUCCESS,
  POST_PLAY_YEGEE_GAME_FAILURE,
  POST_PLAY_YEGEE_GAME_CANCEL,
)<any, AxiosResponse<IAPIResponse<IYegeePlay>>, AxiosError<IAPIResponse>, any>()

const clearYegeeGameHistoryAction = createAction(CLEAR_YEGEE_GAME_HISTORY)<any>();

const getYegeeSumAction = createAsyncAction(
  GET_YEGEE_SUM_REQUEST,
  GET_YEGEE_SUM_SUCCESS,
  GET_YEGEE_SUM_FAILURE,
  GET_YEGEE_SUM_CANCEL
)<any, AxiosResponse<IAPIResponse<string>>, AxiosError<IAPIResponse>, any>()

const listenYegeeSumSocketAction = createAction(LISTEN_YEGEE_SUM_SOCKET)<ILottoRoundQuery>()

const unlistenYegeeSumSocketAction = createAction(UNLISTEN_YEGEE_SUM_SOCKET)<ILottoRoundQuery>()

const updateYegeeSumAction = createAsyncAction(
  UPDATE_YEGEE_SUM_REQUEST,
  UPDATE_YEGEE_SUM_SUCCESS,
  UPDATE_YEGEE_SUM_FAILURE,
)<any, IAPIResponse<string>, any>()

const clearYegeeSumAction = createAction(CLEAR_YEGEE_SUM)<any>()

const getPlayedYegeeListAction = createAsyncAction(
  GET_PLAYED_YEGEE_LIST_REQUEST,
  GET_PLAYED_YEGEE_LIST_SUCCESS,
  GET_PLAYED_YEGEE_LIST_FAILURE,
  GET_PLAYED_YEGEE_LIST_CANCEL
)<any, AxiosResponse<IAPIResponse<IYegeePlay[]>>, AxiosError<IAPIResponse>, any>()

const listenPlayedYeegeListSocketAction = createAction(
  LISTEN_PLAYED_YEGEE_LIST_SOCKET
)<ILottoRoundQuery>()

const unlistenPlayedYeegeListSocketAction = createAction(
  UNLISTEN_PLAYED_YEGEE_LIST_SOCKET
)<ILottoRoundQuery>()

const updatePlayedYegeeListAction = createAsyncAction(
  UPDATE_PLAYED_YEGEE_LIST_REQUEST,
  UPDATE_PLAYED_YEGEE_LIST_SUCCESS,
  UPDATE_PLAYED_YEGEE_LIST_FAILURE
)<any, IAPIResponse<IYegeePlay[]>, any>()

const clearPlayedYegeeListAction = createAction(CLEAR_PLAYED_YEGEE_LIST)<any>()

const getYegeeGameAction = createAsyncAction(
  GET_YEGEE_GAME_REQUEST,
  GET_YEGEE_GAME_SUCCESS,
  GET_YEGEE_GAME_FAILURE,
  GET_YEGEE_GAME_CANCEL
)<any, AxiosResponse<IAPIResponse<ILottoGame>>, AxiosError<IAPIResponse>>()

const updateYegeeGameAction = createAsyncAction(
  UPDATE_YEGEE_GAME_REQUEST,
  UPDATE_YEGEE_GAME_SUCCESS,
  UPDATE_YEGEE_GAME_FAILURE,
)<any, IAPIResponse<ILottoGame>, any>()

export {
  getYeegeGameListAction,
  updateYegeeGameListAction,
  postYegeeGameHistoryAction,
  clearYegeeGameHistoryAction,
  postYegeePlayAction,
  getYegeeSumAction,
  listenYegeeSumSocketAction,
  unlistenYegeeSumSocketAction,
  updateYegeeSumAction,
  clearYegeeSumAction,
  getPlayedYegeeListAction,
  listenPlayedYeegeListSocketAction,
  unlistenPlayedYeegeListSocketAction,
  updatePlayedYegeeListAction,
  clearPlayedYegeeListAction,
  getYegeeGameAction,
  updateYegeeGameAction
};
