import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { fetchTransferCredits } from "../services";
import { transferCreditAction } from "../actionCreator";
import { loaderAction } from "../../Loader/actionCreator";

const getTransferCredit = (query: ISearchAgentFinancePayListQuery) => async (
  dispatch: Dispatch
) => {
  dispatch(loaderAction.success(true));
  dispatch(transferCreditAction.request({}));
  await fetchTransferCredits(query)
    .then(
      (
        response: AxiosResponse<
          IAPIResponse<IPagination<IResponseAgentFinancePay>>
        >
      ) => {
        dispatch(loaderAction.success(false));
        return dispatch(transferCreditAction.success(response));
      }
    )
    .catch((error: AxiosError<APIResponse>) => {
      dispatch(loaderAction.success(false));
      return dispatch(transferCreditAction.failure(error));
    });
};

const cancelTranfersCredits = () => (dispatch: Dispatch) =>
  dispatch(transferCreditAction.cancel({}));

export { getTransferCredit, cancelTranfersCredits };
