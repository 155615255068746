import axios from "axios"
import { transformer } from "utils"
import environment from "../../constants/environment"

//API agent 86
const fetchAgentHoles = (query?: IV2QueryAgentHoles) => {
  const convertQueryToString = new URLSearchParams(transformer.snakecaseTransform(query)).toString()
  const endpoint = `${
    environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.getAgentHoles}`
  return axios.get<IAPIResponse<IV2GetHolesAgent[]>>(`${endpoint}?${convertQueryToString}`)
}

//API agent 87
const fetchAgentHolesLottery = (query?: IV2QueryGetHolesLotteryAgent) => {
  const convertQueryToString = new URLSearchParams(transformer.snakecaseTransform(query)).toString()
  const endpoint = `${
    environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.getAgentHolesLottery}`
  return axios.get<IAPIResponse<IV2GetHolesLotteryAgent[]>>(`${endpoint}?${convertQueryToString}`)
}
//88.POST: api/v2/agent/holes/addเพิ่มหลุมเลข

const fetchHolesAddAgent=(query:IV2PostHolesAddAgent)=>{
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.postHolesAddAgent}`
  return axios.post<IAPIResponse<IV2RespPostHolesAddAgent>>(`${endpoint}`,query)
}

//API agent 89
const fetchAgentHolesNumber = (query: IV2QueryGetHolesNumberAgent) => {
  const convertQueryToString = new URLSearchParams(transformer.snakecaseTransform(query)).toString()
  const endpoint = `${
    environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.getAgentHolesNumber}`
  return axios.get<IAPIResponse<IV2GetHolesNumberAgent[]>>(`${endpoint}?${convertQueryToString}`)
}

// 90.POST: api/v2/agent/holes/update แก้ไขหลุมเลข
const fetchAgentHolesUpdate = (query:IV2UpdateHolesAgent)=>{
  const endpoint = `${
    environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.postAgentHoleUpdate}`
  return axios.post<IAPIResponse<IV2RespUpdateHolesAgent>>(`${endpoint}`,query)
}

//API agent 91
const deleteAgentHoles = (data: IV2DeleteHolesAgent) => {
  const endpoint = `${
    environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.postAgentHolesDelete}`
  return axios.post<IAPIResponse<IV2DeleteHolesAgent>>(endpoint, data)
}

//API agent 92
const addAgentHolesNumbers = (data: IV2PostAddHolesNumberAgent) => {
  const endpoint = `${
    environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.postAgentHolesNumbersAdd}`
  return axios.post<IAPIResponse<IV2RespUpdateHolesNumberAgent>>(endpoint, data)
}

//API agent 93
const updateAgentHolesNumbers = (data: IV2PostUpdateHolesNumberAgent) => {
  const endpoint = `${
    environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.postAgentHolesNumbersUpdate}`
  return axios.post<IAPIResponse<IV2RespPostAddHolesNumberAgent>>(endpoint, data)
}

export {
  fetchAgentHoles,
  fetchAgentHolesLottery,
  fetchAgentHolesNumber,
  deleteAgentHoles,
  addAgentHolesNumbers,
  updateAgentHolesNumbers,
  fetchHolesAddAgent,
  fetchAgentHolesUpdate,
}
