import React, { Component } from "react";
import { Tab, Tabs } from "react-bootstrap";
import "./receiveAndPayList.style.scss";
import "bootstrap/dist/css/bootstrap.css";
import { get, isEmpty, noop, omit, sum } from "lodash";
import { dateThai, number, transformer } from "utils";
import { meDataInitialValue, STATUS_FINANCE_ME } from "constants/variables";
import { Dropdown, Panginate, ErrorModal, LoadingCircle } from "components";
import color from "constants/colors";
import { Button } from "components";
import { responseCode, responseMessage } from "constants/response";
import {
  ClearBillFinanceChildModal,
  ContactModal,
  ReportPayModal,
  SuccessModal,
} from "components/Modal";
import { fetchMemberChild, } from "reduxs/members/services"
import { fetchGetFinanceChild, } from "reduxs/finance/services"
import { AxiosResponse } from "axios";
const constants = {
  pleaseCheckPaidList: 'โปรดตรวจสอบรายการแจ้งจ่าย',
  agree: 'เข้าใจแล้ว',
  reactTabs: {
    receive: "รายการรับ",
    pay: "รายการจ่าย",
  },
  list: "(จำนวนรายการ)",
  empty: "ไม่มีข้อมูล...",
  headTableReceive: [
    { codeName: "เลขรายการ", textalign: "text-left pd-frist" },
    { codeName: "เจ้าของรายการ", textalign: "text-left" },
    { codeName: "ข้อมูลเพิ่มเติม", textalign: "text-left" },
    { codeName: "ยอด", textalign: "text-center" },
    { codeName: "สถานะ", textalign: "text-center" },
    { codeName: "เวลาแจ้ง", textalign: "text-center" },
    { codeName: "เวลาตรวจ", textalign: "text-center" },
    { codeName: "\u00A0", textalign: "text-center" },
  ],
  rowPerPage: "จำนวนรายการต่อหน้า :",
  reportPay: "แจ้งจ่าย",
  clear: "เคลียร์",
  postFinanceChildApproveSuccess: "เคลียร์ยอดสำเร็จ",
  contact: "ติดต่อ",
  postFinanceChildRequestSuccess: "แจ้งจ่ายสำเร็จ",
};

const defaultProps: IReceiveAndPayListProps = {
  financeChildProps: {
    isFetching: false,
    code: 0,
    data: {
      page: 1,
      limit: 10,
      total: 0,
      dataList: [],
      totalRecord: 0,
    },
    error: "",
  },
  dataReseive: {
    dataList: [],
    limit: 10,
    page: 1,
    total: 0,
    totalRecord: 0,
  },
  financeChildMeData: {
    dataList: [],
    limit: 10,
    page: 1,
    total: 0,
    totalRecord: 0,
  },
  getFinanceChildMe() {
    noop();
  },
  getFinanceChild() {
    noop();
  },
  financeChildMeCode: 0,
  financeChildMeIsFetching: false,
  meData: meDataInitialValue,
  agentChildCode: 0,
  agentChildData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  agentChildIsFetching: false,
  getChildMembers() {
    noop();
  },
  financeChildApproveCode: 0,
  financeChildApproveData: {
    agentPayTxId: 0,
  },
  financeChildApproveIsFetching: false,
  postFinanceChildApprove() {
    noop();
  },
  postFinanceChildRequest() {
    noop();
  },
  financeChildRequestCode: 0,
  financeChildRequestData: {
    agentPayTxId: 0,
  },
  financeChildRequestIsFetching: false,
  queryObj: {
    agentShareId: 0,
    childAgentId: 0,
  },
  queryStoreFinance: {
    limit: 10,
    page: 1,
    rowIndex: 0,
  },
  financeChildDataState: [],
  onRefreshData() {
    noop()
  }
};

class ReceiveAndPayListComponent extends Component<
  IReceiveAndPayListProps,
  IReceiveAndPayListState
> {
  static defaultProps = defaultProps;
  state: IReceiveAndPayListState = {
    dataQuery: {
      limit: 10,
      page: 1,
    },
    currentPage: 1,
    currentRowPerPage: 10,
    startPage: 1,
    endPage: 0,
    startPageReseive: 1,
    endPageReseive: 0,
    tabKey: "receive",
    totalRecord: {
      pay: 0,
      receive: 0,
    },
    financeChildMeDataState: [],
    blockPayButton: false,
  };

  componentDidMount() {
    const queryObj: any = transformer.queryToObject();
    this.setState(
      {
        dataQuery: {
          ...this.state.dataQuery,
          childAgentId: queryObj.childAgentId,
          childAgentShareId: queryObj.agentShareId,
          agentId: this.props.meData.agentId,
        },
      },
      () => {
        this.props
          .getFinanceChildMe({
            agentId: this.props.meData.agentId,
            page: 1,
            limit: 10,
            childAgentShareId: queryObj.agentShareId,
          })
          .then((payRecord: number) => {
            return Promise.all([
              this.props.getFinanceChildMe({
                childAgentId: queryObj.childAgentId,
                page: 1,
                limit: 10,
                childAgentShareId: queryObj.agentShareId,
              }),
              payRecord,
            ]);
          })
          .then((records: number[]) => {
            this.setState({

              totalRecord: {
                ...this.state.totalRecord,
                receive: records[0],
                pay: records[1],
              },
            });
          });
      }
    );
  }

  componentDidUpdate(prevProps: IReceiveAndPayListProps) {
    if (
      prevProps.financeChildMeIsFetching !==
      this.props.financeChildMeIsFetching &&
      !this.props.financeChildMeIsFetching
    ) {
      if (this.props.financeChildMeCode === responseCode.OK) {
        if (this.props.financeChildDataState[0]?.status === "SUCCESS") {
          this.setState({ blockPayButton: true })
        } else {
          this.setState({ blockPayButton: false })
        }
        if (isEmpty(this.props.financeChildMeData.dataList)) {
          this.setState({
            startPage: 0,
            endPage: 0,
            financeChildMeDataState: this.props.financeChildMeData.dataList,
          });
        } else {
          this.setState({
            startPage:
              this.state.currentRowPerPage * (this.state.dataQuery.page - 1) +
              1,
            endPage:
              this.state.currentRowPerPage * (this.state.currentPage + 1) >=
                this.props.financeChildMeData.totalRecord
                ? this.props.financeChildMeData.totalRecord
                : this.state.currentRowPerPage * this.state.dataQuery.page,
            totalRecord: {
              ...this.state.totalRecord,
              // receive: this.props.dataReseive.totalRecord,
              pay: this.props.financeChildMeData.totalRecord,
            },
            financeChildMeDataState: this.props.financeChildMeData.dataList,
          });
        }

        if (isEmpty(this.props.dataReseive.dataList)) {
          this.setState({
            startPageReseive: 0,
            endPageReseive: 0,
            // financeChildMeDataState: this.props.financeChildMeData.dataList,
          });
        } else {
          this.setState({
            startPageReseive:
              this.state.currentRowPerPage * (this.state.dataQuery.page - 1) +
              1,
            endPageReseive:
              this.state.currentRowPerPage * (this.state.currentPage + 1) >=
                this.props.dataReseive.totalRecord
                ? this.props.dataReseive.totalRecord
                : this.state.currentRowPerPage * this.state.dataQuery.page,
            totalRecord: {
              ...this.state.totalRecord,
              receive: this.props.dataReseive.totalRecord,
              // pay: this.props.financeChildMeData.totalRecord,
            },
            // financeChildMeDataState: this.props.financeChildMeData.dataList,
          });
        }


      } else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            this.props.financeChildMeCode,
            responseMessage()[0]
          ),
        });
      }
    }

    if (
      prevProps.financeChildApproveIsFetching !==
      this.props.financeChildApproveIsFetching &&
      !this.props.financeChildApproveIsFetching
    ) {
      if (this.props.financeChildApproveCode === responseCode.OK) {
        ClearBillFinanceChildModal.hide();
        this.props.getFinanceChildMe({
          childAgentId: this.props.financeChildDataState[0].childAgentId,
          page: 1,
          limit: this.state.currentRowPerPage,
          childAgentShareId: this.props.financeChildDataState[0].agentShareId,
        });
        SuccessModal.show({
          action: () => {
            SuccessModal.hide()
            this.props.onRefreshData(this.props.financeChildDataState[0].agentShareId)
          },
          description: constants.postFinanceChildApproveSuccess,
        });
      } else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            this.props.financeChildApproveCode,
            responseMessage()[0]
          ),
        });
      }
    }


  }

  getNextOrPreviousData = () => {
    this.props
      .getFinanceChildMe({
        childAgentId: this.props.financeChildDataState[0].childAgentId,
        page: 1,
        limit: this.state.currentRowPerPage,
        childAgentShareId: this.props.financeChildDataState[0].agentShareId,
      })
      .then((payRecord: number) => {
        return Promise.all([
          this.props.getFinanceChildMe({
            agentId: this.props.meData.agentId,
            page: 1,
            limit: this.state.currentRowPerPage,
            childAgentShareId: this.props.financeChildDataState[0].agentShareId,
          }),
          payRecord,
        ]);
      })
      .then((records: number[]) => {
        this.setState({
          totalRecord: {
            ...this.state.totalRecord,
            receive: records[1],
            pay: records[0],
          },
        });
      });
  };

  renderTableHeader = (
    headTable: { codeName: string; textalign: string }[]
  ) => {
    return (
      <thead>
        <tr className="tableHead">
          {headTable.map((data, index: number) => (
            <th key={index} className={data.textalign}>
              <span>{data.codeName}</span>
            </th>
          ))}
        </tr>
      </thead>
    );
  };



  sendClearBill = (data: IV2PostAgentFinanceChildApprove) => {
    this.props.postFinanceChildApprove(data)
  };

  sendPayReport = (data: IV2PostAgentFinanceChildRequest) => {
    this.props
      .postFinanceChildRequest(data)
      .then(() => {
        ClearBillFinanceChildModal.hide();
        this.props.getFinanceChildMe({
          agentId: this.props.meData.agentId,
          limit: 10,
          page: 1,
          childAgentShareId: this.props.financeChildDataState[0].agentShareId,
        });
      })
      .then(() => {
        if (this.props.financeChildRequestCode === responseCode.OK)
          SuccessModal.show({
            action: () => {
              SuccessModal.hide()
              this.props.onRefreshData(this.props.financeChildDataState[0].agentShareId)
            },
            description: constants.postFinanceChildRequestSuccess,
          });
        else if (this.props.financeChildRequestCode === 400133) {
          SuccessModal.show({
            titleStyle: 'pre-line custom-title-modal',
            action: () => {
              SuccessModal.hide()
            },
            title: get(responseMessage(), 400108, responseMessage()[0]), //เอาเออเร่อเมสเสจมาจาก108
            description: constants.pleaseCheckPaidList,
            actionText: constants.agree,
            classTextTitle: "primary-text "
          })
        }
        else {
          ErrorModal.show({
            action: ErrorModal.hide,
            description: get(
              responseMessage(),
              this.props.financeChildRequestCode,
              responseMessage()[0]
            ),
          })
        }
      })
      .catch(() => {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            this.props.financeChildRequestCode,
            responseMessage()[0]
          ),
        });
      });
  };

  onClickClearReceiveButton = (data: IV2RespAgentFinanceChildMe) => {
    this.props
      .getChildMembers({ childAgentId: data.childAgentId })
      .then((res: IV2GetAgentChild) => {
        const billMemberData: IMemberBillData = {
          line: res.childLine,
          phoneNumber: res.childPhoneNumber,
          bankType: res.childBankType,
          bankNumber: res.childBankNumber,
          bankName: res.childBankName,
        }
        ClearBillFinanceChildModal.show({
          detailBill: { ...data, ...billMemberData },
          action: this.sendClearBill,
          cancelAction: () => {
            ClearBillFinanceChildModal.hide();
          },
        });
      })
      .catch(() => {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            this.props.financeChildMeCode,
            responseMessage()[0]
          ),
        });
      });
  };

  onClickContactPayButton = (data: IV2RespAgentFinanceChildMe) => {
    this.props
      .getChildMembers({ childAgentId: this.props.queryObj.childAgentId })
      .then((res: IV2GetAgentChild) => {
        const billMemberData: IMemberBillData = {
          line: res.childLine,
          phoneNumber: res.childPhoneNumber,
          bankType: res.childBankType,
          bankNumber: res.childBankNumber,
          bankName: res.childBankName,
        }
        ContactModal.show({
          detailBill: { ...data, ...billMemberData },
          cancelAction: () => {
            ClearBillFinanceChildModal.hide();
          },
        });
      })
      .catch(() => {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            this.props.financeChildMeCode,
            responseMessage()[0]
          ),
        });
      });
  };

  handleReportPay = () => {
    Promise.all([
      fetchMemberChild({ childAgentId: this.props.queryObj.childAgentId }),
      fetchGetFinanceChild({
        agentShareId: this.props.queryObj.agentShareId,
        limit: 10,
        page: 1,
      })
    ])
      .then((res: [AxiosResponse<IAPIResponse<IPagination<IV2GetAgentChild>>>, AxiosResponse<IAPIResponse<IPagination<IV2RespAgentFinanceChild>>>]) => {
        ReportPayModal.show({
          detailBill: { ...res[0].data.data.dataList[0], ...res[1].data.data.dataList[0] },
          action: this.sendPayReport,
          cancelAction: () => {
            ReportPayModal.hide();
          },
          queryObj: this.props.queryObj,

        });
      })
      .catch(() => {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            this.props.agentChildCode,
            responseMessage()[0]
          ),
        });
      });
  };

  renderTableBody = () => {

    const dataList = this.state.tabKey === 'receive' ? this.props.dataReseive.dataList : this.props.financeChildMeData.dataList
    return (
      <tbody>
        {isEmpty(dataList) ? (
          <tr>
            <td className="text-center p-2" colSpan={15}>
              {constants.empty}
            </td>
          </tr>
        ) : (
          dataList.map((data, index: number) => {
            const stName = get(STATUS_FINANCE_ME, `${data.status}.status`, "");
            const stColor = get(STATUS_FINANCE_ME, `${data.status}.color`, "");
            return (
              <tr
                id={'un-hover'}
                className={`row-finance-customer`}
                key={index}
                onClick={() => { }}
              >
                <td className="text-left paddingRow">
                  <span className="pd-frist">
                    {number.addComma(data.agentPayTxId)}
                  </span>
                </td>
                <td className="text-left ">
                  <span>{data.childUsername}</span>
                </td>
                <td className="text-left">
                  <span>{data.note}</span>
                </td>
                <td className="text-center">
                  <span>{number.addComma(data.pay)}</span>
                </td>
                <td className="text-center">
                  <span className="text-status" style={{ color: stColor }}>
                    {stName}
                  </span>
                </td>
                <td className="text-center">
                  <span className="">
                    {dateThai.formatThai(data.createdAt)}
                  </span>
                </td>
                <td className="text-center">
                  <span className="">
                    {dateThai.formatThai(data.updatedAt)}
                  </span>
                </td>
                <td className="text-center">
                  {this.state.tabKey === "pay" ? (
                    <Button
                      buttonClass="clear-receive-button"
                      id={`clear-receive-${index}`}
                      text={constants.contact}
                      transitionType="typeOne"
                      onClick={() => this.onClickContactPayButton(data)}
                      disabled={data.status !== "WAIT"}
                    />
                  ) : (
                    <Button
                      buttonClass="clear-receive-button"
                      id={`clear-receive-${index}`}
                      text={constants.clear}
                      transitionType="typeOne"
                      onClick={() => this.onClickClearReceiveButton(data)}
                      disabled={data.status !== "WAIT"}
                    />
                  )}
                </td>
              </tr>
            );
          })
        )}
      </tbody>
    );
  };


  renderReceiveTable = () => {
    const RenderTableHeader = this.renderTableHeader(
      constants.headTableReceive
    );
    const RenderTableBody = this.renderTableBody();

    const loadingTable = (
      <tbody>
        <tr>
          <td className="text-center" colSpan={15}>
            <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
          </td>
        </tr>
      </tbody>
    )

    return (
      <div className="table-container">
        <table className="col">
          {RenderTableHeader}
          {this.props.financeChildMeIsFetching ? loadingTable : RenderTableBody}
        </table>
      </div>
    );
  };

  renderPayTable = () => {
    const RenderTableHeader = this.renderTableHeader(
      constants.headTableReceive
    );
    const RenderTableBody = this.renderTableBody();

    const loadingTable = (
      <tbody>
        <tr>
          <td className="text-center" colSpan={15}>
            <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
          </td>
        </tr>
      </tbody>
    )

    return (
      <div className="table-container">
        <table className="col">
          {RenderTableHeader}
          {this.props.financeChildMeIsFetching ? loadingTable : RenderTableBody}
        </table>
      </div>
    );
  };

  renderDropDownBox = () => {
    return (
      <>
        <h6>{constants.rowPerPage}</h6>
        <div className="dropdown-rowPerPage">
          <Dropdown
            menuPlacement={"top"}
            options={[
              { value: 5, label: "5", disabled: false },
              { value: 10, label: "10", disabled: false },
              { value: 20, label: "20", disabled: false },
              { value: 50, label: "50", disabled: false },
              { value: 100, label: "100", disabled: false },
            ]}
            controlBackgroundColor={color.PRIMARY_BG}
            placeholder={String(this.state.currentRowPerPage)}
            handleOnChange={(target: IOptions) => {
              let omittedDataQuery;
              if (this.state.tabKey === "pay") {
                omittedDataQuery = omit(this.state.dataQuery, ["childAgentId"]);
              } else {
                omittedDataQuery = omit(this.state.dataQuery, ["agentId"]);
              }

              this.setState(
                {
                  currentPage: 1,
                  currentRowPerPage: Number(target.value),
                  dataQuery: {
                    ...omittedDataQuery,
                    page: 1,
                    limit: Number(target.value),
                    childAgentShareId: this.props.financeChildDataState[0].agentShareId
                  },
                },
                () => {
                  this.props.getFinanceChildMe(this.state.dataQuery);
                }
              );
            }}
          />
        </div>
      </>
    );
  };

  handleClickChangePage = ({ selected: selectedPage }: ISelectPage) => {
    this.setState({ currentPage: selectedPage }, () => {
      this.setState(
        {
          dataQuery: {
            ...this.state.dataQuery,
            page: this.state.currentPage + 1,
          },
        },
        () => {
          this.props.getFinanceChildMe(this.state.dataQuery);
        }
      );
    });
  };

  renderPagination = (totalRecord: number) => {
    const RenderDropDownBox = this.renderDropDownBox;
    return (
      <div className="col d-flex justify-content-end align-items-center">
        <RenderDropDownBox />
        <h6 className="d-flex justify-content-end align-items-center m3-l">
          {this.state.tabKey === 'receive' ? `${this.state.startPageReseive} - ${this.state.endPageReseive} of ${totalRecord}` :
            `${this.state.startPage} - ${this.state.endPage} of ${totalRecord}`}
        </h6>
        <div className='col-auto'>
          <Panginate
            pageTotal={this.props.financeChildMeData.total}
            initialPage={this.state.dataQuery.page - 1}
            pageRangeDisplayed={this.state.currentPage}
            onPageChange={this.handleClickChangePage}
          />
        </div>
      </div>
    );
  };

  render() {
    const RenderReceiveTable = this.renderReceiveTable();
    const RenderPayTable = this.renderPayTable();

    return (
      <div className="row receive-and-paylist-container">
        <div className="col-12">
          <div className="">
            <Tabs
              id="controlled-tab-example"
              activeKey={this.state.tabKey}
              onSelect={(k) => {
                if (k === "pay") {
                  const omittedDataQuery = omit(this.state.dataQuery, [
                    "childAgentId",
                  ]);
                  omittedDataQuery.agentId = this.props.meData.agentId;
                  omittedDataQuery.childAgentShareId = this.props.financeChildDataState[0].agentShareId;
                  this.setState({ dataQuery: omittedDataQuery }, () => {
                    this.props.getFinanceChildMe(this.state.dataQuery)
                  });
                } else {
                  const omittedDataQuery = omit(this.state.dataQuery, [
                    "agentId",
                  ]);
                  omittedDataQuery.childAgentId = this.props.financeChildDataState[0].childAgentId;
                  omittedDataQuery.childAgentShareId = this.props.financeChildDataState[0].agentShareId;
                  this.setState({ dataQuery: omittedDataQuery }, () => {
                    this.props.getFinanceChildMe(this.state.dataQuery);
                  });
                }
                this.setState({ tabKey: k || "" });
              }}
              className="mb-3"
            >
              <Tab
                eventKey="receive"
                title={
                  <div>
                    {constants.reactTabs.receive}
                    {/* <span className="receive-no-text">
                      {this.state.totalRecord.receive}
                    </span> */}
                  </div>
                }
              >
                {RenderReceiveTable}
                {this.renderPagination(this.state.totalRecord.receive)}
              </Tab>
              <Tab
                eventKey="pay"
                title={
                  <div className="d-flex flex-row justify-content-between">
                    <div>
                      {constants.reactTabs.pay}
                      {/* <span className="receive-no-text">
                        {this.state.totalRecord.pay}
                      </span> */}
                    </div>
                  </div>
                }
              >
                {RenderPayTable}
                {this.renderPagination(this.state.totalRecord.pay)}
                {sum([this.props.financeChildDataState[0]?.payHost || 0, -(this.props.financeChildDataState[0]?.recieveHost || 0)]) >= 0 ?
                  <></>
                  :
                  <Button
                    id="report-pay"
                    text={constants.reportPay}
                    buttonClass="report-pay-button"
                    transitionType="typeOne"
                    onClick={() => {
                      this.handleReportPay();
                    }}
                    textSize={18}
                    backgroundColor={color.PRIMARY_PURPLE}
                    backgroundHoverColor={color.SECONDARY_PURPLE}
                    disabled={this.state.blockPayButton}
                  />}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

export default ReceiveAndPayListComponent;
