import { noop, get, isEmpty, isNull } from "lodash";
import React, { Component } from "react";
import "./hostInfo.style.scss";
import {  number } from "utils";
import { BANK_TYPE } from "../../../../constants/variables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { meDataInitialValue } from "constants/variables";
import { LoadingCircle } from "components";


const constants = {
  hostInfoTitle: "ข้อมูลหัวสมาชิก",
  username: "ชื่อผู้ใช้ในระบบ",
  phoneNumber: "เบอร์โทรศัพท์",
  line: "ไลน์ไอดี",
  bankType: "ธนาคาร",
  bankName: "ชื่อเจ้าของบัญชี",
  bankNumber: "หมายเลขบัญชี",
};

const defaultProps = {
  meData: meDataInitialValue,
  getHost() {
    noop();
  },
  hostCode: 0,
  hostData: {
    hostAgentId: 0,
    hostBankName: "",
    hostBankNumber: "",
    hostBankType: "",
    hostLine: "",
    hostPhoneNumber: "",
    hostUsername: "",
  },
  hostError: "",
  hostIsFetching: false,
};

class HostInfoComponent extends Component<IHostInfoProps, IHostInfoState> {
  static defaultProps = defaultProps;
  state: IHostInfoState = {
    clickShowPass : false
  };

  componentDidMount() {
    this.props.getHost();

  }
  showNumberSpace(number:string){
    var last1Digit = number.slice(-1)
    var last2Digit = number.slice(-3,-1)
    if(number.length === 10){
        var number1 = number.slice(0,3)
        var number2 = number.slice(3,4)
        var number3 = number.slice(4,7)
      return (number1+" "+number2+" "+number3+last2Digit+" "+last1Digit)}
  
    if(number.length === 12){
      var number4 = number.slice(0,3)
      var number5 = number.slice(3,4)
      var number6 = number.slice(4,9)
      return (number4+" "+number5+" "+number6+" "+last2Digit+" "+last1Digit)}
  
      if(number.length === 15){
        var number7 = number.slice(0,3)
        var number8 = number.slice(3,4)
        var number9 = number.slice(4,9)
        var number10 = number.slice(9,12)
        return (number7+" "+number8+" "+number9+" "+number10+" "+last2Digit+" "+last1Digit)}

      if(number === "-"){
        return "-"
      }
  }
  numberSpace( number:string ) {
    var last1Digit = number.slice(-1)
    var last2Digit = number.slice(-3,-1)
    if(number.length === 10){
        var number1 = number.slice(0,3)
        number1 = number1.replace(number1, 'xxx')
        var number2 = number.slice(3,4)
        number2 = number2.replace(number2, 'x')
        var number3 = number.slice(4,7)
        number3 = number3.replace(number3, 'xxx')
      return (number1+" "+number2+" "+number3+last2Digit+" "+last1Digit)}
  
    if(number.length === 12){
      var number4 = number.slice(0,3)
      number4 = number4.replace(number4, 'xxx')
      var number5 = number.slice(3,4)
      number5 = number5.replace(number5, 'x')
      var number6 = number.slice(4,9)
      number6 = number6.replace(number6, 'xxxxx')
      return (number4+" "+number5+" "+number6+" "+last2Digit+" "+last1Digit)}
  
      if(number.length === 15){
        var number7 = number.slice(0,3)
        number7 = number7.replace(number7, 'xxx')
        var number8 = number.slice(3,4)
        number8 = number8.replace(number8, 'x')
        var number9 = number.slice(4,9)
        number9 = number9.replace(number9, 'xxxxx')
        var number10 = number.slice(9,12)
        number10 = number10.replace(number10, 'xxx')
        return (number7+" "+number8+" "+number9+" "+number10+" "+last2Digit+" "+last1Digit)}

        if(number === "-"){
          return "-"
        }
    };
  
    replaceBankType = (data:string)=>{
      if(data==="-"||isNull(data)||isEmpty(data)){
        return "-"
      }
      var dataNew =  data.slice(6)
      return dataNew
    }

  render() {
    const { hostData,hostIsFetching} = this.props;
    return (
      <div className="paper-body host-info-container">
        <div className="row mt-4">
          <div className="col">
            <div className="box-shadow">
              <div className="row">
                <div className="col-12 pr-3 paper-body-title">
                  <div className="d-flex align-items-center">
                  <div className="titleHeadText">  {constants.hostInfoTitle}</div>
                  {hostIsFetching && ( <div className="m1-l"><LoadingCircle /> </div>)}
                </div>
                </div>
              </div>
              <div className="row m3-t">
                <div className="col-3">
                  <div className="d-flex flex-column">
                    <div className="content-text">{constants.username}</div>
                    <div className={`${hostIsFetching ? 'tertiary-text' : 'paper-body-title'} mt-1`}>
                    {isEmpty(get(hostData,'hostUsername','-')) ? '-' : hostData.hostUsername }
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="d-flex flex-column">
                    <div className="content-text">{constants.phoneNumber}</div>
                    <div className={`${hostIsFetching ? 'tertiary-text' : 'paper-body-title'} mt-1`}>
                    {number.formatPhoneNumberSpace((isEmpty(get(hostData,'hostPhoneNumber','-')))||(isNull(get(hostData,'hostPhoneNumber','-'))) ? '-' : hostData.hostPhoneNumber)}                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="d-flex flex-column">
                    <div className="content-text">{constants.line}</div>
                    <div className={`${hostIsFetching ? 'tertiary-text' : 'paper-body-title'} mt-1`}>                     
                    {isEmpty(get(hostData,'hostLine','-')) ? '-' : hostData.hostLine}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row m3-t">
                <div className="col-3">
                  <div className="d-flex flex-column">
                    <div className="content-text">{constants.bankType}</div>
                    <div className={`${hostIsFetching ? 'tertiary-text' : 'paper-body-title'} mt-1`}>
                    {this.replaceBankType(
                        get(
                        BANK_TYPE,
                        `[${hostData.hostBankType}]`,
                        "-"
                         )
                      )}

                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="d-flex flex-column">
                    <div className="content-text">{constants.bankName}</div>
                    <div className={`${hostIsFetching ? 'tertiary-text' : 'paper-body-title'} mt-1`}>
                    {isEmpty(get(hostData,'hostBankName','-'))||isNull(get(hostData,'hostBankName','-')) ? '-' : hostData.hostBankName}
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="d-flex flex-column">
                    <div className="content-text">{constants.bankNumber}</div>
                    <div className={`${hostIsFetching ? 'tertiary-text' : 'paper-body-title'} mt-1`}>
                    {this.state.clickShowPass === false ? (
                      <div className="boxWidth d-flex justify-content-between" style={{ width: (!isNull(hostData.hostBankNumber)||!isEmpty(hostData.hostBankNumber))&&hostData.hostBankNumber.length === 10 ? 130:  155}} > 
                        {this.numberSpace(
                          isEmpty(
                            get(hostData, "hostBankNumber", "-")
                          )|| isNull(
                            get(hostData, "hostBankNumber", "-")
                          )
                            ? "-"
                            : hostData.hostBankNumber
                        )}{" "}

                      <span className="iconHover">
                          {this.props.meData.permission === "OWNER" || isEmpty(get(hostData, "hostBankNumber", "-"))||isNull(get(hostData, "hostBankNumber", "-")) 
                           ? (
                            ""
                          ) : (
                            <div >
                            <FontAwesomeIcon
                              icon={faEye}
                              onClick={() => {
                                this.setState({ clickShowPass: true });
                              }}
                            />
                            </div>
                          )}
                          
                        </span>
                       
                      </div>
                    ) : (
                      <div className="boxWidth  d-flex justify-content-between" style={{ width: (!isNull(hostData.hostBankNumber)||!isEmpty(hostData.hostBankNumber))&&hostData.hostBankNumber.length === 10 ? 130:  155}} >
                        {this.showNumberSpace(
                          isEmpty(
                            get(hostData, "hostBankNumber", "-")
                          )||isNull(
                            get(hostData, "hostBankNumber", "-")
                          )
                            ? "-"
                            : hostData.hostBankNumber
                        )}
                         <span className="iconHover">
                          {this.props.meData.permission === "OWNER"  || isEmpty(get(hostData, "hostBankNumber", "-"))||isNull(get(hostData, "hostBankNumber", "-")) 
                          ? (
                            ""
                          ) : (
                            <div >
                            <FontAwesomeIcon
                              icon={faEye}
                              onClick={() => {
                                this.setState({ clickShowPass: false });
                              }}
                            />
                            </div>
                          )}
                        </span>                        
                      </div>
                    )}
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HostInfoComponent;
