import { AxiosError, AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import { newsAction } from '../actionCreator'
import { fetchNews } from '../services'


const getDataNews = (req:INewsRequest) => (dispatch: Dispatch) =>{
    dispatch(newsAction.request({}))
    return fetchNews(req)
        .then((res:AxiosResponse<IAPIResponse<IPagination<INews>>>) => {
            dispatch(newsAction.success(res))
        })
        .catch((error: AxiosError<IAPIResponse>) => dispatch(newsAction.failure(error)))
    }

export {
    getDataNews
}