import { connect } from "react-redux";
import TransferCreditDescriptionContainer from "./TransferCreditDescription.container";
import transferCreditDescriptionSelector from "../../../../reduxs/credits/selector";
import { postPayRequest } from "../../../../reduxs/credits/payRequest/action";
import {
  cancelTransferDescription,
  getTransferDescription,
} from "../../../../reduxs/credits/transferCreditDescription/action";

const mapStateToProps = (
  state: RootReducers
): ITransferCreditDescriptionContainerProps => {
  const transferCreditDescription = transferCreditDescriptionSelector.transferCreditDescription(
    state
  );
  const payRequests = transferCreditDescriptionSelector.payRequests(state)
  return {
    getTransferCreditDescriptionListsIsFetching:
      transferCreditDescription.isFetching,
    TransferCreditDescriptionLists: transferCreditDescription.data,
    getTransferCreditDescriptionListsCode: transferCreditDescription.code,
    getTransferCreditDescriptionListsError: transferCreditDescription.error,
    postPayRequestIsFetching: payRequests.isFetching,
    PostPayRequestCode: payRequests.code,
    postPayRequestError: payRequests.error,
  };
};

const mapDispatchToProps: ITransferCreditDescriptionContainerAction = {
  getTransferCreditDescriptionLists: (body) => getTransferDescription(body),
  cancelTransferCreditDescriptionLists: cancelTransferDescription,
  postPayRequest: (body) => postPayRequest(body),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransferCreditDescriptionContainer);
