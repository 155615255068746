import { connect } from "react-redux";
import CustomerList from "./CustomerList.container";
import { onChangeIsLoaddingAction } from "../../reduxs/Loader/action"
import { getCustomer } from "reduxs/customer/action";
import customerSelector from "reduxs/customer/selector";

const mapStateToProps =(state: RootReducers):ICustomerProps=>{
    const customerList = customerSelector.customerList(state)
    return {
        customerCode: customerList.code,
        customerError:customerList.error,
        customerIsFetching: customerList.isFetching,
        customerData: customerList.data as any,

      }
}
const mapDispatchToProps:ICustomerActionProps = {
    getCustomer: getCustomer,
    onChangeIsLoaddingAction: onChangeIsLoaddingAction,
}


export default connect(mapStateToProps,mapDispatchToProps)(CustomerList)