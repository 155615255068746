
import { childResetPasswordState } from 'reduxs/child/constants'
import { get } from 'lodash'

const memberRePassword = (state: RootReducers): ReducerState<IAPIResponse<object>> =>
get(state, 'child.childResetPassword', childResetPasswordState) as any


const childSelector = {
    memberRePassword,
}
export default childSelector