import { AxiosError, AxiosResponse } from "axios";
import { createAsyncAction } from "typesafe-actions";
import {
  GET_LOTTERY_LIST_REQUEST,
  GET_LOTTERY_LIST_SUCCESS,
  GET_LOTTERY_LIST_FAILURE,

  GET_V2_LOTTERY_LIST_REQUEST,
  GET_V2_LOTTERY_LIST_SUCCESS,
  GET_V2_LOTTERY_LIST_FAILURE,

  GET_GAME_REQUEST,
  GET_GAME_SUCCESS,
  GET_GAME_FAILURE,
} from "./constants";
import {
  getYeegeGameListAction,
  updateYegeeGameListAction,
  postYegeeGameHistoryAction,
  clearYegeeGameHistoryAction,
  clearYegeeSumAction,
  getYegeeSumAction,
  listenYegeeSumSocketAction,
  postYegeePlayAction,
  unlistenYegeeSumSocketAction,
  updateYegeeSumAction,
  clearPlayedYegeeListAction,
  getPlayedYegeeListAction,
  listenPlayedYeegeListSocketAction,
  unlistenPlayedYeegeListSocketAction,
  updatePlayedYegeeListAction
} from "./yegee/actionCreator";
import {
  postMakeAction,
  getHistoryAction,
  getResultAction,
  getRateAction,
  getNumberAction,
} from "./bet/actionCreator";

const getLotteryListAction = createAsyncAction(
  GET_LOTTERY_LIST_REQUEST,
  GET_LOTTERY_LIST_SUCCESS,
  GET_LOTTERY_LIST_FAILURE
)<
  any,
  AxiosResponse<IAPIResponse<IResponseAgentLotteryList[]>>,
  AxiosError<IAPIResponse>
>();

const getV2LotteryListAction = createAsyncAction(
  GET_V2_LOTTERY_LIST_REQUEST,
  GET_V2_LOTTERY_LIST_SUCCESS,
  GET_V2_LOTTERY_LIST_FAILURE
)<
  any,
  AxiosResponse<IAPIResponse<IV2RespAgentLotterySchedule[]>>,
  AxiosError<IAPIResponse>
>();


const getGameAction = createAsyncAction(
  GET_GAME_REQUEST,
  GET_GAME_SUCCESS,
  GET_GAME_FAILURE
)<
  any,
  AxiosResponse<IAPIResponse<ILottoGame>>,
  AxiosError<IAPIResponse<ILottoGame>>
>();

export {
  getLotteryListAction,
  getV2LotteryListAction,
  getGameAction,
  getYeegeGameListAction,
  updateYegeeGameListAction,
  postMakeAction,
  getHistoryAction,
  getResultAction,
  getRateAction,
  getNumberAction,
  postYegeeGameHistoryAction,
  clearYegeeGameHistoryAction,
  clearYegeeSumAction,
  getYegeeSumAction,
  listenYegeeSumSocketAction,
  postYegeePlayAction,
  unlistenYegeeSumSocketAction,
  updateYegeeSumAction,
  clearPlayedYegeeListAction,
  getPlayedYegeeListAction,
  listenPlayedYeegeListSocketAction,
  unlistenPlayedYeegeListSocketAction,
  updatePlayedYegeeListAction
};
