import axios from "axios";
import environment from "../../../../constants/environment";

const fetchGetRate = () => {
  const endpoint = `${
    environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.getBetRate}`;

  return axios.get(endpoint);
};

const fetchGetNumber = (querys: ISearchAgentLotteryRateNumber[]) => {
  const endpoint = `${
    environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.getNumberRate}`;
  const promises = querys.map((query) => {
    const NumberRateQuery = {
      code: query.code,
      number: query.number,
      type: query.type,
    };
    return axios.get(endpoint, { params: NumberRateQuery });
  });
  return promises;
};

export { fetchGetRate, fetchGetNumber };
