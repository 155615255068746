import { cloneDeep, filter, get, isEmpty, noop } from "lodash";
import "./modalNumberRate.style.scss";
import { Modal } from "../..";
import React, { FC, useState } from "react";
import colors from "constants/colors";
import { Button } from "components";
import logo from "assets/images/logo/logothailandbet.png";
import { number } from "utils";
import { useEffect } from "react";
const constants = {
  title: "ยืนยันการบันทึกข้อมูล",
  titleError: "พบข้อผิดพลาด \nไม่สามารถบันทึกข้อมูลได้",
  textError: "อัตราจ่ายเกินสูงสุดกว่าที่กำหนด",
  back: "กลับไปปรับอัตราจ่าย",
  titleWeb: "ไทยแลนด์เบท",
  titleEng: "Thailand Bet",
  number: "เลขบิล",
  lotto: "หวย",
  totalBet: "ยอดเดิมพัน",
  description: "คุณต้องการปรับอัตราจ่ายในงวดนี้ใช่หรือไม่?",
  actionText: "ตกลง",
  cancel: "ยกเลิก",
  closeRecieveAll: "ปิดรับทั้งหมด",
  cancelRecieveAll: "ยกเลิกปิดรับทั้งหมด",
  accept: "ใช่ บันทึกข้อมูล",
  errorMaxrate: "อัตราจ่ายเกินสูงสุด",
  confirmCloseCancleReceivedTitle: "คุณต้องการยกเลิกปิดรับเลข",
  confirmCloseCancleReceivedDetail: "คุณต้องการยกเลิกการปิดรับเลข??",
  modeBet: [
    { key: "THREE_UP", label: "3 บน", },
    { key: "THREE_TOAST", label: "3 โต๊ด", },
    { key: "TWO_UP", label: "2 บน", },
    { key: "TWO_DOWN", label: "2 ล่าง", },
    { key: "RUN_UP", label: "วิ่งบน", },
    { key: "RUN_DOWN", label: "วิ่งล่าง", },
  ],
};

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: INumberBanManageModal = {
  title: constants.title,
  description: constants.description,
  actionText: constants.actionText,
  action() {
    noop();
  },
  cancelAction() {
    noop();
  },
  closeReceiveList: {
    THREE_UP: false,
    THREE_TOAST: false,
    TWO_UP: false,
    TWO_DOWN: false,
    RUN_UP: false,
    RUN_DOWN: false,
  },
  cancelReceiveList: {
    THREE_UP: false,
    THREE_TOAST: false,
    TWO_UP: false,
    TWO_DOWN: false,
    RUN_UP: false,
    RUN_DOWN: false,
  },
};

const ModalNumberRate: FC<INumberBanManageModal & DefaultProps> = (props) => {
  const [lottoList, setLottoList] = useState<IV2UpdateRateReduceNumber[]>(props.lottoList!);
  const [closeReceiveList, /*setCloseReceiveList*/] = useState<INumberBanManageLottoRecievedType>(
    props.closeReceiveList
  );
  const [cancelReceiveList, /*setCloseReceiveList*/] = useState<INumberBanManageLottoRecievedType>(
    props.cancelReceiveList
  );
  // const [cancelReceiveList, /*setCloseReceiveList*/] = useState<INumberBanManageLottoRecievedType>(
  //   props.cancelReceiveList
  // );
  useEffect(() => {
    detailModal(lottoList);
    // eslint-disable-next-line
  }, [lottoList]);


  const checkRatereduceValueDiff = (data: IV2UpdateRateReduceNumber[]) => {
    if (data.length === 0) {
      return true;
    }
    const first = data[0].ratereduceValue;
    return data.every((item) => item.ratereduceValue === first);
  }
  const handleClick = (data: IV2UpdateRateReduceNumber[], index: number) => {
    if (!props.isResponseFailure) {
      let newListNumber = cloneDeep(data);
      newListNumber.splice(index, 1);
      setLottoList(newListNumber);
    }
  };
  const detailModal = (data: IV2UpdateRateReduceNumber[]) => {
    if (data.length === 0) {
      props.cancelAction!();
    } else {
      return (
        <>
          <div className="box-list-bet">
            {constants.modeBet.map((element, index) => {
              const dataFilter = filter(data, ["ratereduceBetType", element.key])
              const rateDetails = checkRatereduceValueDiff(dataFilter) ?
                `${number.decimalsFormatCommaWithOutZero(get(dataFilter[0], "ratereduceValue", 0))} บ.`
                : ""

              let indexFailStatus: any = []
              let closeRecieve: boolean = false
              let cancelRecieve: boolean = false
              if (dataFilter.length > 0 && !isEmpty(dataFilter[0])) {
                indexFailStatus = props.errorArray?.filter(i => i === dataFilter[0]!.ratereduceBetType)
                closeRecieve = (closeReceiveList! as any)[dataFilter[0]!.ratereduceBetType];
                cancelRecieve = (cancelReceiveList! as any)[dataFilter[0]!.ratereduceBetType];
              }


              // const cancelReceive = (cancelReceiveList! as any)[(dataFilter[0]?.ratereduceBetType)];

              return dataFilter.length ? (
                <div className="box-items" key={index}>
                  <div className="box-title">
                    <span
                    // style={{ paddingRight: checkRatereduceValueDiff(dataFilter) ? "0px" : "20px" }}
                    >
                      {`${element.label} ${rateDetails}`} </span>
                    {closeRecieve && (
                      <span
                        className="errorCloseRecieve"
                      >
                        {constants.closeRecieveAll}
                      </span>
                    )}
                    {cancelRecieve && (
                      <span
                        className="errorCancelReceive"
                      >
                        {constants.cancelRecieveAll}
                      </span>
                    )}
                    {props.isResponseFailure && !isEmpty(indexFailStatus) && (
                      <span className="errorText">{constants.textError}</span>
                    )}
                  </div>
                  <div className="row">
                    <div className="col list-bet">
                      {data!.map((item: IV2UpdateRateReduceNumber, index: number) => {
                        return element.key === item.ratereduceBetType ? (
                          <div
                            className={`number-bet length-${item.ratereduceNumber.length} ${closeRecieve ? "closeRecieve" : ""
                              } ${props.isResponseFailure && !isEmpty(indexFailStatus) ? "fail" : ""}
                            `}
                            key={index}
                            onClick={() => {
                              handleClick(data, index);
                            }}
                          >
                            {item.ratereduceNumber}
                          </div>
                        ) : (
                          <React.Fragment key={index}></React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                <React.Fragment key={index}></React.Fragment>
              );
            })}
          </div>
        </>
      );
    }
  };
  return (
    <div className="numberrate-modal-container border-rounded" >
      <div className="row mr-0">
        <div className="leading-logo-container m2-x">
          <img src={logo} className="logoMenu" alt="" />
        </div>
        <div className="title-text-web">
          <h3 className="title">{constants.titleWeb}</h3>
          <h5 className="tertiary-text sub-title">{constants.titleEng}</h5>
        </div>
      </div>
      <h2 className="title-text secondary-text" style={{ whiteSpace: "pre-line" }}>{props.isResponseFailure ? constants.titleError : props.title}</h2>
      {detailModal(lottoList)}
      <p className="body-semi1 tertiary-text p2-x">{props.isResponseFailure ? "" : props.description}</p>
      <div className="row justify-content-center">
        {props.isResponseFailure ? (<>
          <Button
            id="confirm-button"
            type="rectangle"
            onClick={() => props.cancelAction!()}
            text={constants.back!}
            textSize={18}
            transitionType="typeOne"
          />
        </>) : (<>
          <div className="col p0-r">
            <Button
              id="confirm-button"
              type="rectangle"
              onClick={() => props.action!(lottoList)}
              text={constants.accept!}
              textSize={18}
              transitionType="typeOne"
            />
          </div>
          <div className="col">
            <Button
              id="confirm-button"
              type="rectangle"
              backgroundColor={colors.TERTIARY_TEXT}
              backgroundHoverColor={colors.TERTIARY_TEXT}
              borderColor={colors.SECONDARY_TEXT}
              onClick={props.cancelAction}
              text={constants.cancel!}
              transitionType="typeOne"
              textSize={18}
            />
          </div>
        </>)}

      </div>
    </div>
  );
};

ModalNumberRate.defaultProps = defaultProps;

const successModal = {
  show: (props: INumberBanManageModal) => {
    return Modal.event("MODAL_OVER", {
      state: "show",
      extraProps: { size: "md" },
      component: <ModalNumberRate {...props} />,
    });
  },
  hide: () => Modal.event("MODAL_OVER", { state: "hide" }),
};

export default successModal;
