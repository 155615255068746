import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { loaderAction } from "reduxs/Loader/actionCreator";
import { getHistorySummaryDetailAction } from "../actionCreator";
import { fetchGetHistorySummaryDetail } from "../services";

const getDataHistorySummaryDetail = (
  queryBody: IAgentQueryLotteryHistorySummaryDetail
) => async (dispatch: Dispatch) => {
  dispatch(loaderAction.success(true));
  dispatch(getHistorySummaryDetailAction.request({}));
  await fetchGetHistorySummaryDetail(queryBody)
    .then(
      (
        res: AxiosResponse<
          IAPIResponse<IResponseAgentQueryLotteryHistorySummaryDetail>
        >
      ) => {
        dispatch(loaderAction.success(false));
        dispatch(getHistorySummaryDetailAction.success(res));
      }
    )
    .catch((error: AxiosError<IAPIResponse>) => {
      dispatch(loaderAction.success(false));
      dispatch(getHistorySummaryDetailAction.failure(error));
    });
};
const clearDataHistorySummaryDetail = () => (dispatch: Dispatch) =>
  dispatch(getHistorySummaryDetailAction.cancel({}));
export { getDataHistorySummaryDetail, clearDataHistorySummaryDetail };
