import { AxiosError, AxiosResponse } from 'axios'
import { combineReducers } from 'redux'
import { ActionType, createReducer } from 'typesafe-actions'
import { responseConverter } from '../../utils'
import * as customerActionConst from './actionCreator'
import {
    customerState,customerNoteState,postCustomerNoteState
} from './constants'

export type customersActionType = ActionType<typeof customerActionConst>


const customerList = createReducer<
  ReducerState<IPagination<IV2RespAgentCustomer>>,customersActionType>(customerState)
  .handleAction(
    customerActionConst.customerAction.request,
    (state: ReducerState<IPagination<IV2RespAgentCustomer>>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    customerActionConst.customerAction.success,
    (state: ReducerState<IPagination<IV2RespAgentCustomer>>, action: customersActionType) => {
      const payload: AxiosResponse<IAPIResponse<IPagination<IV2RespAgentCustomer>>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
      };
    }
  )
  .handleAction(
    customerActionConst.customerAction.failure,
    (state: ReducerState<IPagination<IV2RespAgentCustomer>>, action: customersActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )


  const customerNoteList = createReducer<
  ReducerState<IPagination<IV2RespAgentCustomerNote>>,customersActionType>(customerNoteState)
  .handleAction(
    customerActionConst.customerNoteAction.request,
    (state: ReducerState<IPagination<IV2RespAgentCustomerNote>>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    customerActionConst.customerNoteAction.success,
    (state: ReducerState<IPagination<IV2RespAgentCustomerNote>>, action: customersActionType) => {
      const payload: AxiosResponse<IAPIResponse<IPagination<IV2RespAgentCustomerNote>>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
      };
    }
  )
  .handleAction(
    customerActionConst.customerNoteAction.failure,
    (state: ReducerState<IPagination<IV2RespAgentCustomerNote>>, action: customersActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )

  const postcustomerNoteList = createReducer<
  ReducerState<IPagination<IV2RespPostAgentCustomerNote>>,customersActionType>(postCustomerNoteState)
  .handleAction(
    customerActionConst.postcustomerNoteAction.request,
    (state: ReducerState<IPagination<IV2RespPostAgentCustomerNote>>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    customerActionConst.postcustomerNoteAction.success,
    (state: ReducerState<IPagination<IV2RespPostAgentCustomerNote>>, action: customersActionType) => {
      const payload: AxiosResponse<IAPIResponse<IPagination<IV2RespPostAgentCustomerNote>>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
        noteId: payload.data.data,
      };
    }
  )
  .handleAction(
    customerActionConst.postcustomerNoteAction.failure,
    (state: ReducerState<IPagination<IV2RespPostAgentCustomerNote>>, action: customersActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )

  export default combineReducers({
    customerList,
    customerNoteList,
    postcustomerNoteList
})