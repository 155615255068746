export const POST_OTP_REQUEST = 'POST_OTP_REQUEST';
export const POST_OTP_SUCCESS = 'POST_OTP_SUCCESS';
export const POST_OTP_FAILURE = 'POST_OTP_FAILURE';

export const otpState: ReducerState<IResponseOtpAuthen> = {
    isFetching: false,
    code: 0,
    data: {
        sendCount: 0,
        smsRef: ''
    },
    error: ''
}