import React, { Component, createRef, RefObject } from "react";
import {
  Breadcrumb,
  LottoCard,
  ErrorModal,
  SuccessModal,
  ResponsiveIcon,
  Collapse,
} from "components";
import "./betMaking.style.scss";
import {
  noop,
  map,
  split,
  reverse,
  sum,
  uniq,
  isArray,
  isEmpty,
  find,
  get,
  values,
  isEqual,
} from "lodash";
import { CODE_TO_TYPENAME, LOTTO_SLUG_NAME } from "constants/variables";
import { MakingLotto, Summary } from "./components";
import { RouteComponentProps } from "react-router-dom";
import {
  differenceInSeconds,
  fromUnixTime,
  getUnixTime,
  format,
} from "date-fns";
import { th } from "date-fns/locale";
import { date, number } from "utils";
import { responseCode, responseMessage } from "constants/response";
import routes from "constants/routes";
import Clock from "assets/images/global/time/img-clock.svg";

const constants = {
  closedLabel: "เวลาที่ปิดรับ: ",
  openedOn: "เปิดแทงใน",
  timeups: "หมดเวลา",
  remainingTime: "เวลาคงเหลือ: ",
  processing: "กำลังประมวลผล...",
  openedOnFull: "หวยจะเปิดแทงใน ",
  betTitle: "การเดิมพัน",
  betSubTitle: "เดิมพัน",
  betSuccess: "แทงรอบบิลนี้สำเร็จ",
};
const DEFAULT_GAME_VALUE = "1";
const LAO_SULT_FIXED_GAME_VALUE = "120";

type DefaultProps = Readonly<typeof defaultProps>;
const defaultProps: IBetMakingContainerProps &
  IBetMakingContainerActionProps = {
  getGame() {
    noop();
  },
  getRate() {
    noop();
  },
  getNumber() {
    noop();
  },
  onChangeIsLoaddingAction() {
    noop();
  },
  postMake() {
    noop();
  },
  getAutocompleteAction() {
    noop();
  },
  postYegeeGameHistory() {
    noop();
  },
  betNumberRatesData: [],
  betNumberRatesIsFetching: false,
  betRatesData: [],
  betRatesIsFetching: false,
  betNumberRatesCode: 0,
  lottoGameIsFetching: false,
  lottoGameCode: 0,
  lottoGameData: {
    id: 0,
    round: "",
    endTime: "",
    startTime: "",
    createdAt: "",
    status: "UNKNOWN",
  },
  makingBetLottoData: [],
  makingBetLottoIsFetching: false,
  makingBetLottoCode: 0,
  makingBetLottoError: "",

  listAutocompleteCode: 0,
  listAutocompleteError: "",
  listAutocompleteIsFetching: false,
  listAutocompleteData: {
    totalRecord:10,
    page: 1,
    limit: 10,
    total: 0,
    dataList: [],
  },
  gameHistoryCode: 0,
  gameHistoryError: "",
  gameHistoryIsFetching: false,
  gameHistoryData: [],

  clearBetResult() {
    noop();
  },
  clearYegeeGameHistory() {
    noop();
  },
};

class BetMakingContainer extends Component<
  IBetMakingContainerProps &
    IBetMakingContainerActionProps &
    DefaultProps &
    RouteComponentProps<
      { type: TLottoSlug },
      any,
      IBetMakingContainerRouteProps
    >,
  IBetMakingContainerState
> {
  static defaultProps = defaultProps;
  betMakingContainerRef: RefObject<HTMLDivElement> = createRef();
  summaryContainerRef: RefObject<Summary> = React.createRef();

  intervalId: NodeJS.Timeout | null = null;
  timeoutId: NodeJS.Timeout | null = null;

  state: IBetMakingContainerState = {
    numberList: [],
    temporaryNumberList: {
      filter: [],
      adding: [],
    },
    defaultGameValue: "1.00",
    remainingTime: {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
    targetLotto: {
      id: 0,
      startTime: "",
      endTime: "",
      mode: "",
      updatedAt: "",
      code: "LOTTER_YEGEE",
      status: "UNKNOWN",
    },
    onLottoProcessing: false,
    lottoStatus: "UNKNOWN",
    totalNumberBet: 0,
    totalBetPrice: "฿ 0.00",
    isExpandHistoryYegee: false,
    keyDownMakingLotto: "",
    enterStep: "STEP_3",
  };

  componentDidMount() {
    this.handleScrollToTop();
    const locationState: IBetMakingContainerRouteProps = this.props.location
      .state;
    const gameRound = number.padNumber(
      locationState.selectedLottoGame?.round!,
      3
    );
    const game = locationState.selectedLottoGame;
    const gameDate = format(date.calibratingTime(game?.endTime!), "ddMMyyyy");

    this.props.postYegeeGameHistory({ day: "5", round: gameRound });

    if (
      !isEmpty(get(this.props.location, "state.selectedLottoGame", {})) &&
      !isEmpty(get(this.props.match, "params.type", ""))
    ) {
      this.props.onChangeIsLoaddingAction(true);

      const slugName = this.props.match.params.type;
      this.setState({ defaultGameValue: LAO_SULT_FIXED_GAME_VALUE });
      /* const slugNameQuery = slugName.split("_").pop() as TLottoSlug; */
      this.props.getGame(slugName, gameDate, gameRound);
      this.props.getRate();
      this.setState({
        numberList: locationState.betList || [],
        targetLotto: locationState.targetLotto!,
        defaultGameValue:
          slugName === "LOTTER_LAO_SUITE"
            ? LAO_SULT_FIXED_GAME_VALUE
            : DEFAULT_GAME_VALUE,
      });
    } else {
      return this.props.history.goBack();
    }
  }

  componentDidUpdate(prevProps: IBetMakingContainerProps) {
    document.onkeydown = (keyDownEvent) => {
      this.setState({ keyDownMakingLotto: keyDownEvent.code }, () => {
        this.setState({ keyDownMakingLotto: "" });
      });
      if (
        keyDownEvent.code === "NumpadEnter" ||
        keyDownEvent.code === "Enter"
      ) {
        switch (this.state.enterStep) {
          case "STEP_1":
            this.setState({ enterStep: "STEP_2" });
            break;
          case "STEP_2":
            this.setState({ enterStep: "STEP_3" });
            break;
          case "STEP_3":
            this.setState({ enterStep: "STEP_1" });
            break;
          case "SUMMARY_POLL":
            this.setState({ enterStep: "STEP_3" });
            break;
          case "ADD_NUMBER":
            this.setState({ enterStep: "STEP_1" });
            break;
        }
      }
      if (keyDownEvent.code === "F2") {
        this.setState({ enterStep: "ADD_NUMBER" });
      }
      if (keyDownEvent.code === "Space") {
        this.setState({ enterStep: "SUMMARY_POLL" });
      }
    };

    if (
      prevProps.lottoGameIsFetching !== this.props.lottoGameIsFetching &&
      !this.props.lottoGameIsFetching
    ) {
      if (this.props.lottoGameCode === responseCode.OK) {
        const { lottoGameData } = this.props;
        this.setState({
          targetLotto: {
            ...this.state.targetLotto,
            ...lottoGameData,
          },
        });
        const slugName = this.props.match.params.type;
        this.setState({ lottoStatus: this.props.lottoGameData.status }, () => {
          const currentUnixTime = getUnixTime(new Date());

          const startUnixTime = getUnixTime(
            date.calibratingTime(this.props.lottoGameData.startTime)
          );

          const expire =
            slugName === "LOTTER_YEGEE"
              ? this.props.lottoGameData.endTime
              : currentUnixTime > startUnixTime
              ? this.props.lottoGameData.endTime
              : this.props.lottoGameData.startTime;
          const status: TGameStatus =
            this.props.lottoGameData.status === "OPEN" &&
            slugName !== "LOTTER_YEGEE"
              ? currentUnixTime > startUnixTime
                ? "OPEN"
                : "WAIT"
              : this.props.lottoGameData.status;
          if (
            status === "OPEN" ||
            status === "WAIT" ||
            status === "NOT_ACCESS"
          ) {
            this.props.onChangeIsLoaddingAction(false);
            const momentEndAt = date.calibratingTime(expire!);
            const duration = differenceInSeconds(
              momentEndAt,
              fromUnixTime(currentUnixTime)
            );
            const hours = Math.floor((duration / 3600) % 24);
            const minutes = Math.floor((duration / 60) % 60);
            const seconds = Math.floor(duration % 60);
            if (hours! > 0 || minutes! > 0 || seconds! > 0) {
              this.countingDown(expire!);
              if (slugName === "LOTTER_YEGEE") {
                const lottoGame = this.props.lottoGameData;
                const gameDate = format(
                  date.calibratingTime(lottoGame.endTime),
                  "ddMMyyyy"
                );
                const gameRound = number.padNumber(lottoGame.round, 3);
                const gameQuery = { date: gameDate, round: gameRound };
                console.log(gameQuery);
                this.props.postYegeeGameHistory({ day: "5", round: gameRound });
              }
              this.setState({ lottoStatus: status });
            } else if (status === "OPEN") {
              this.setState({ lottoStatus: "CLOSE" }, () => {
                this.handleGetBetResult();
              });
            }
          } else {
            this.handleGetBetResult();
          }
        });
      } else {
        this.props.history.goBack();
      }
    }

    if (
      prevProps.betNumberRatesIsFetching !==
        this.props.betNumberRatesIsFetching &&
      !this.props.betNumberRatesIsFetching
    ) {
      this.props.onChangeIsLoaddingAction(false);
      if (this.props.betNumberRatesCode === responseCode.OK) {
        const responsedNumberRate = this.props.betNumberRatesData;
        const { adding, filter } = this.state.temporaryNumberList;

        const numberlistWithRate = map<ILottoNumber, ILottoNumber>(
          adding,
          (numberNonRate) => {
            const findRate = find<ISearchAgentLotteryRateNumber>(
              responsedNumberRate,
              {
                number: numberNonRate.bet,
                type: numberNonRate.type,
              }
            );
            const rate = get(findRate, "rate", "0");
            return { ...numberNonRate, rate };
          }
        );

        this.setState({ numberList: [...filter, ...numberlistWithRate] }, () =>
          this.props.onChangeIsLoaddingAction(false)
        );
      } else {
        this.props.onChangeIsLoaddingAction(false);
      }
    }

    if (
      prevProps.listAutocompleteIsFetching !==
        this.props.listAutocompleteIsFetching &&
      !this.props.listAutocompleteIsFetching
    ) {
      if (this.props.listAutocompleteCode !== responseCode.OK) {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: this.props.listAutocompleteError,
        });
      }
    }

    // if (prevProps.getYeegeSumIsFetching !== this.props.getYeegeSumIsFetching
    //   && !this.props.getYeegeSumIsFetching) {
    //   if (this.state.lottoStatus === 'OPEN') {
    //     const lottoGame = this.state.lottoStatus
    //     // const gameDate = date.calibratingTime(lottoGame.endTime).format('DDMMYYYY')
    //     // const gameRound = number.padNumber(lottoGame.round, 3)
    //     const locationState: IBetMakingContainerRouteProps = this.props.location.state;
    //     const gameRound = number.padNumber(locationState.selectedLottoGame?.round!,3);
    //     const game = locationState.selectedLottoGame;
    //     const gameDate = format(date.calibratingTime(game?.endTime!), "ddMMyyyy");

    //     const gameQuery = { date: gameDate, round: gameRound }
    //     this.props.listenYeegeSum(gameQuery)
    //   }
    // }

    if (
      prevProps.makingBetLottoIsFetching !==
        this.props.makingBetLottoIsFetching &&
      !this.props.makingBetLottoIsFetching
    ) {
      this.props.onChangeIsLoaddingAction(false);
      if (this.props.makingBetLottoCode === responseCode.OK) {
        this.summaryContainerRef.current?.handleDeletePoll();
        SuccessModal.show({
          action: () => {
            this.setState({ numberList: [] }, () => {
              SuccessModal.hide();
              const slugName = this.props.match.params.type;
              if (slugName === "LOTTER_YEGEE") {
                this.handleOnClickBreadcrumb(
                  routes.betChildren.exactPath(this.props.match.params.type)
                );
              } else {
                setTimeout(() => {
                  this.handleScrollToTop();
                }, 512);
                const locationState: IBetMakingContainerRouteProps = this.props
                  .location.state;
                const game = locationState.selectedLottoGame;
                this.props.history.replace(
                  routes.betMaking.exactPath(this.props.match.params.type),
                  {
                    selectedLottoGame: game,
                    targetLotto: this.state.targetLotto,
                  }
                );
              }
            });
          },
          description: constants.betSuccess,
        });
      } else {
        ErrorModal.show({
          action: () => {
            ErrorModal.hide();
          },
          description: get(
            responseMessage(),
            this.props.makingBetLottoCode,
            responseMessage()[0]
          ),
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.onChangeIsLoaddingAction(false);
    this.clearLocalInterval();
    this.props.clearBetResult();
    const slugName = this.props.match.params.type;
    if (slugName === "LOTTER_YEGEE") {
      this.props.clearYegeeGameHistory();
    }

    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
    this.props.onChangeIsLoaddingAction(false);
    this.clearLocalTimeout();
  }

  addRemarkSuccess = (Success: boolean) => {
    !Success && this.setState({ enterStep: "STEP_1" });
  };

  handleScrollToTop = () => {
    if (this.betMakingContainerRef.current) {
      this.betMakingContainerRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  handleGetBetResult = () => {
    this.setState({ onLottoProcessing: true }, () => {
      this.props.onChangeIsLoaddingAction(false);
    });
  };

  countingDown = (endingTime: string) => {
    this.clearLocalInterval();
    const lottoGame = this.props.lottoGameData;
    const momentEndAt = date.calibratingTime(endingTime);

    this.intervalId = setInterval(() => {
      const diiferentSecond = differenceInSeconds(momentEndAt, new Date());
      const days = Math.floor(diiferentSecond / 86400);
      const hours = Math.floor((diiferentSecond / 3600) % 24);
      const minutes = Math.floor((diiferentSecond / 60) % 60);
      const seconds = Math.floor(diiferentSecond % 60);
      if (hours! <= 0 && minutes! <= 0 && seconds! < 0) {
        this.clearLocalInterval();
        const slugName = this.props.match.params.type;
        const gameDate = format(
          date.calibratingTime(lottoGame.endTime),
          "ddMMyyyy"
        );
        const gameRound = number.padNumber(lottoGame.round, 3);

        if (this.state.lottoStatus === "WAIT") {
          this.props.onChangeIsLoaddingAction(true);
          this.props.getGame(slugName, gameDate, gameRound);
        } else if (this.state.lottoStatus === "OPEN") {
          this.setState({ onLottoProcessing: true }, () => {
            this.setState({ lottoStatus: "CLOSE" });
          });
        } else {
          this.setState({ onLottoProcessing: true }, () => {
            if (slugName === "LOTTER_YEGEE") {
              console.log("coming soon");
            }
            // Todo
            /* setTimeout(() => {
              this.props.onChangeIsLoaddingAction(true);
              this.props.getLottoGame(slugName, gameDate, gameRound);
            }, 5000); */
          });
        }
      } else if (isNaN(hours!) || isNaN(minutes!) || isNaN(seconds!)) {
        this.setState(
          { remainingTime: { days: 0, hours: 0, minutes: 0, seconds: 0 } },
          () => {
            this.clearLocalInterval();
          }
        );
      } else {
        this.setState({
          remainingTime: {
            days: days!,
            hours: hours!,
            minutes: minutes!,
            seconds: seconds!,
          },
        });
      }
    }, 1000);
  };

  clearLocalInterval = () => {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
    }
  };

  clearLocalTimeout = () => {
    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId);
    }
  };

  renderLottoCard = () => {
    const lotto = this.state.targetLotto;
    let lottoCardTitle;
    if (lotto.code === "LOTTER_YEGEE") {
      lottoCardTitle = `${LOTTO_SLUG_NAME[lotto.code]}${lotto.yegeeRound}`;
    } else {
      lottoCardTitle = `${LOTTO_SLUG_NAME[lotto.code]}`;
    }

    return (
      <div className="col-8 col-lg-6 col-xl-4 m3-t" key={`lotto-${lotto.code}`}>
        <LottoCard
          slug={lotto.code}
          id={`lotto-${lotto.code}`}
          title={lottoCardTitle}
          subtitle={lotto.subtitle!}
          backgroundColor={lotto.backgroundColor}
          status={lotto.status}
          isCountingdown={lotto.isCountingDown}
          closedStatusText={lotto.closedStatusText}
          waitingStatusText={lotto.waitingStatusText}
          openedStatusText={lotto.openedStatusText}
          description={lotto.description}
          expire={lotto.endTime}
          start={lotto.startTime}
          icon={lotto.icon}
          noAccessStatusText={lotto.noAccessStatusText}
        />
      </div>
    );
  };

  handleOnAddLottoNumber = (
    lottoNumber: ILottoNumber | ILottoNumber[],
    state: "ADD" | "REMOVE",
    isSwitchedNumber: boolean = false
  ) => {
    const lottoNumbering = (): ILottoNumber | ILottoNumber[] => {
      this.setState({ enterStep: "ADD_NUMBER" });
      if (isSwitchedNumber) {
        const lottoNumberArray = (
          pureLottoNumber: ILottoNumber
        ): ILottoNumber[] => {
          const numberAsArray = split(pureLottoNumber.bet, "");
          if (numberAsArray.length === 2) {
            if (isEqual(numberAsArray, uniq(numberAsArray))) {
              return [
                pureLottoNumber,
                {
                  ...pureLottoNumber,
                  bet: String(sum(reverse(numberAsArray))),
                },
              ];
            }
          } else if (numberAsArray.length === 3) {
            return uniq<string>([
              `${numberAsArray[0]}${numberAsArray[1]}${numberAsArray[2]}`,
              `${numberAsArray[0]}${numberAsArray[2]}${numberAsArray[1]}`,
              `${numberAsArray[1]}${numberAsArray[0]}${numberAsArray[2]}`,
              `${numberAsArray[1]}${numberAsArray[2]}${numberAsArray[0]}`,
              `${numberAsArray[2]}${numberAsArray[0]}${numberAsArray[1]}`,
              `${numberAsArray[2]}${numberAsArray[1]}${numberAsArray[0]}`,
            ]).map((numb) => ({ ...pureLottoNumber, bet: numb }));
          }
          return [pureLottoNumber];
        };

        if (isArray(lottoNumber)) {
          const temporaryList: ILottoNumber[] = [];
          lottoNumber.forEach((lot) => {
            const lottos = lottoNumberArray(lot);
            lottos.forEach((lotto) => temporaryList.push(lotto));
          });
          return temporaryList;
        } else {
          return lottoNumberArray(lottoNumber);
        }
      }
      return lottoNumber;
    };
    const lotterNumbers = lottoNumbering();
    const finding = (numb: ILottoNumber) =>
      isArray(lotterNumbers)
        ? isEmpty(find(lotterNumbers, { bet: numb.bet, type: numb.type }))
        : !(numb.bet === lotterNumbers.bet && numb.type === lotterNumbers.type);

    const newNumberList = this.state.numberList.filter(finding);
    const slugName = this.props.match.params.type;

    if (state === "ADD") {
      if (isArray(lotterNumbers)) {
        const addNumberList = lotterNumbers.map((numb) => ({
          ...numb,
          value: this.state.defaultGameValue,
          slug: this.generateGameSlug(),
        }));
        if (slugName === "LOTTER_YEGEE") {
          this.setState({ numberList: [...newNumberList, ...addNumberList] });
        } else {
          this.props.onChangeIsLoaddingAction(true);
          this.setState(
            {
              temporaryNumberList: {
                filter: newNumberList,
                adding: addNumberList,
              },
            },
            () => {
              const numbersQuery: ISearchAgentLotteryRateNumber[] = map(
                addNumberList,
                (numb) => ({
                  code: slugName,
                  number: numb.bet,
                  type: numb.type,
                })
              );
              this.props.getNumber(numbersQuery);
            }
          );
        }
      } else {
        if (slugName === "LOTTER_YEGEE") {
          this.setState({
            numberList: [
              ...newNumberList,
              {
                ...lotterNumbers,
                value: this.state.defaultGameValue,
                slug: this.generateGameSlug(),
              },
            ],
          });
        } else {
          this.setState(
            {
              temporaryNumberList: {
                filter: newNumberList,
                adding: [
                  {
                    ...lotterNumbers,
                    value: this.state.defaultGameValue,
                    slug: this.generateGameSlug(),
                  },
                ],
              },
            },
            () => {
              this.props.getNumber([
                {
                  code: slugName,
                  number: lotterNumbers.bet,
                  type: lotterNumbers.type,
                },
              ]);
            }
          );
        }
      }
    } else {
      this.setState({ numberList: newNumberList });
    }
  };

  generateGameSlug = () => {
    const slugName = this.props.match.params.type;
    const gameround = this.props.lottoGameData.round;
    const currentTime = format(new Date(), "ddMMyyyyHHmm");
    return `${slugName}_${currentTime}${number.padNumber(gameround, 3)}`;
  };

  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.replace(path);
  };

  handleOnBetListChanged = (lottoList: ILottoNumber[]) => {
    this.setState({ numberList: lottoList });
  };

  handleOnMakingPoll = (poll: IPoll) => {
    console.log("coming soon", poll);
  };

  handleOnMakingBetLotto = (betBodyArr: IAgentBet[][]) => {
    this.props.postMake(betBodyArr);

    // Todo
    /* const slugName = this.props.match.params.type;
    const lottos = lottoList
      .map((lotto) => ({
        ...lotto,
        value: number.decimalsFormat(lotto.value!).toString(),
      }))
      .filter((lotto) => LOTTO_GAME_TYPES[slugName].includes(lotto.type!));
    console.log("submit", JSON.stringify(lottos));
    ConfirmationModal.show({
      data: lottos,
      betRates: this.props.betRatesData,
      lottoSlug: slugName,
      onCancel: ConfirmationModal.hide,
      onConfirm: () => {
        this.props.onChangeIsLoaddingAction(true);
        this.props.makingBetLotto("lotter/bet", "200000", lottos);
      },
    }); */
  };

  handleBetBanner = (betBodyArr: IAgentBet[][], totalBetPrice: string) => {
    this.setState({ totalBetPrice: totalBetPrice });
    if (!isEmpty(betBodyArr)) {
      const flatBetBodyArr = betBodyArr.reduce((prev, curr) =>
        prev.concat(curr)
      );
      this.setState({ totalNumberBet: flatBetBodyArr.length });
    } else {
      this.setState({ totalNumberBet: 0 });
    }
  };

  renderSummaryMode = () => {
    const slugName = this.props.match.params.type;
    if (this.state.onLottoProcessing) {
      return <></>;
    } else if (this.state.lottoStatus === "CLOSE") {
      if (slugName === "LOTTER_YEGEE") {
        return <></>;
      }
      return <></>;
    } else if (this.state.lottoStatus === "OPEN") {
      return (
        <div className="col-12 col-lg-7">
          <div className="paper-body">
            <Summary
              lottoSlug={slugName}
              betRates={this.props.betRatesData}
              lottoList={this.state.numberList}
              onBetListChanged={this.handleOnBetListChanged}
              onClickBet={this.handleOnMakingBetLotto}
              onClickPoll={(e) => this.handleOnMakingPoll(e)}
              onTotalNumberBet={this.handleBetBanner}
              getAutocompleteAction={this.props.getAutocompleteAction}
              listAutocomplete={this.props.listAutocompleteData.dataList}
              ref={this.summaryContainerRef}
              enterStep={this.state.enterStep}
              addRemarkSuccess={this.addRemarkSuccess}
              keyDown={this.state.keyDownMakingLotto}
            />
          </div>
        </div>
      );
    }
    return <></>;
  };

  renderBetBanner = () => {
    if (this.state.onLottoProcessing) {
      return <></>;
    } else {
      return (
        <div className="bet-container">
          <div className="bet-text-wrapper">
            <div className="body-1 primary-purple-text">
              จำนวนแทง {this.state.totalNumberBet} ตัวเลข
            </div>
            <div className="d-flex flex-row justify-content-end body-5">
              ราคารวม {this.state.totalBetPrice}
            </div>
          </div>
        </div>
      );
    }
  };

  renderGameMode = () => {
    this.clearLocalTimeout();
    const slugName = this.props.match.params.type;
    if (this.state.onLottoProcessing) {
      const { history, location } = this.props;
      if (location.state) {
        const locationState = {
          targetLotto: location.state.targetLotto,
        };
        this.timeoutId = setTimeout(() => {
          history.push("/betResult", locationState);
        }, 10000);
      }
      return (
        <div className="text-center">
          <h4>{constants.processing}</h4>
        </div>
      );
    } else if (this.state.lottoStatus === "CLOSE") {
      console.log("coming soon", slugName);
    } else if (this.state.lottoStatus === "OPEN") {
      // case 'GAME' coming soon
      return (
        <>
          <MakingLotto
            lottoStatus={this.state.lottoStatus}
            lottos={this.state.numberList}
            gameSlug={this.props.match.params.type}
            betRates={this.props.betRatesData}
            onAddedNumber={this.handleOnAddLottoNumber}
            round={this.props.lottoGameData.round}
            keyDown={this.state.keyDownMakingLotto}
          />
        </>
      );
    } else if (this.state.lottoStatus === "WAIT") {
      return this.renderWaitMake();
    } else if (this.state.lottoStatus === "NOT_ACCESS") {
      // Todo: In case want to show "จะเปิดรับแทงในอีก ..." to owner
      /*  const lotto = this.state.targetLotto;
      const slug = lotto.code;
      const start = lotto.startTime;
      const isCountingDown = lotto.isCountingDown;
      const resultTiming =
        getUnixTime(date.calibratingTime(start!)) - getUnixTime(new Date());
      const isAvailable = slug === "LOTTER_YEGEE" || resultTiming < 0; */
      return (
        <>
          {/* {!isAvailable && isCountingDown && this.renderWaitMake()} */}
          <MakingLotto
            lottoStatus={this.state.lottoStatus}
            lottos={this.state.numberList}
            gameSlug={this.props.match.params.type}
            betRates={this.props.betRatesData}
            onAddedNumber={this.handleOnAddLottoNumber}
            round={this.props.lottoGameData.round}
            keyDown={this.state.keyDownMakingLotto}
          />
        </>
      );
    }
    return <></>;
  };

  numbersComponent = (numberString: string, type: TLottoGameType) => {
    return () => (
      <div className="d-flex flex-column flex align-items-center justify-content-center">
        <h6 className="primary-green-text">{CODE_TO_TYPENAME[type]}</h6>
        <h3>{numberString}</h3>
      </div>
    );
  };

  renderWaitMake = () => {
    const sumtime = sum(values(this.state.remainingTime));
    const remainingTime =
      sumtime > 0
        ? this.state.remainingTime.days > 0
          ? `${
              this.state.lottoStatus === "WAIT"
                ? `${constants.openedOnFull}`
                : ""
            }${number.padNumber(
              String(this.state.remainingTime.days),
              2
            )} วัน ${number.padNumber(
              String(this.state.remainingTime.hours),
              2
            )} : ${number.padNumber(
              String(this.state.remainingTime.minutes),
              2
            )} : ${number.padNumber(
              String(this.state.remainingTime.seconds),
              2
            )}`
          : `${
              this.state.lottoStatus === "WAIT"
                ? `${constants.openedOnFull} `
                : ""
            }${number.padNumber(
              String(this.state.remainingTime.hours),
              2
            )} : ${number.padNumber(
              String(this.state.remainingTime.minutes),
              2
            )} : ${number.padNumber(
              String(this.state.remainingTime.seconds),
              2
            )}`
        : "-";
    return <h4 className="text-center">{remainingTime}</h4>;
  };

  shortcutKey = () => (
    <div className="box-shadow overflow-hidden Shortcut-key">
      <h2>คีย์ลัด</h2>
      <div className="row">
        <div className="col-6 col-lg-12">
          <div className="row mt-1">
            <div className="col d-flex align-items-center">
              <div className="key-box">F2</div>
              <div className="text-key-1">
                <ul>
                  <li>เลือกประเภทหวย กดครั้งถัดไป*เพื่อเลือก ประเภทคู่หวย</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mt-1">
            <div className="col d-flex align-items-center">
              <div className="key-box">CTRL</div>
              <div className="text-key-2">
                <ul>
                  <li>เลือกโหมดกลับเลข</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mt-1">
            <div className="col d-flex align-items-center">
              <div className="key-box">Shift</div>
              <div className="text-key-2">
                <ul>
                  <li>เลือกโหมดชุดตัวเลข</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mt-1">
            <div className="col d-flex align-items-center">
              <div className="enter-box"></div>
              <div className="key-box">Enter</div>
              <div className="text-key">
                <ul>
                  <li>กด 1* ครั้ง พิมพ์ชื่อโพย</li>
                  <li>กด 2* ครั้ง เพิ่มโพย</li>
                  <li>กด 3* ครั้ง เพื่อแทงรอบบิล</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="row mt-1">
            <div className="col d-flex align-items-center">
              <div className="key-box text-center">
                Space
                <br />
                bar
              </div>
              <div className="text-key">
                <ul>
                  <li>Scroll ขั้นตอนที่ 1 ไปยัง2 </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  render() {
    const SummaryModeComponent = this.renderSummaryMode;
    const GameModeComponent = this.renderGameMode;
    const ShortcutKeyComponent = this.shortcutKey;
    // Backup
    /* const MakingLottoComponent = this.renderMakingLotto; */
    const BetBanner = this.renderBetBanner;

    const sumtime = sum(values(this.state.remainingTime));

    const remainingTime =
      this.state.lottoStatus === "OPEN" ||
      this.state.lottoStatus === "WAIT" ||
      this.state.lottoStatus === "NOT_ACCESS"
        ? sumtime >= 0
          ? this.state.remainingTime.days > 0
            ? `${
                this.state.lottoStatus === "WAIT"
                  ? `${constants.openedOn} `
                  : ""
              }${number.padNumber(
                String(this.state.remainingTime.days),
                2
              )} วัน ${number.padNumber(
                String(this.state.remainingTime.hours),
                2
              )} : ${number.padNumber(
                String(this.state.remainingTime.minutes),
                2
              )} : ${number.padNumber(
                String(this.state.remainingTime.seconds),
                2
              )}`
            : `${
                this.state.lottoStatus === "WAIT" ? `${constants.openedOn}` : ""
              }${
                !this.state.onLottoProcessing
                  ? `${constants.remainingTime} ${number.padNumber(
                      String(this.state.remainingTime.hours),
                      2
                    )} : ${number.padNumber(
                      String(this.state.remainingTime.minutes),
                      2
                    )} : ${number.padNumber(
                      String(this.state.remainingTime.seconds),
                      2
                    )}`
                  : ""
              }`
          : "-"
        : this.state.lottoStatus === "CLOSE"
        ? constants.timeups
        : "-";

    const RenderRemainingTime = () => {
      if (!this.state.onLottoProcessing && lotto.status !== "NOT_ACCESS") {
        return <>{remainingTime}</>;
      }
      return <></>;
    };
    // For rendering wait case for user owner
    const lotto = this.state.targetLotto;
    /* const slug = lotto.code;
    const start = lotto.startTime; */
    /* const isCountingDown = lotto.isCountingDown;
    const resultTiming =
      getUnixTime(date.calibratingTime(start!)) - getUnixTime(new Date());
    const isAvailable = slug === "LOTTER_YEGEE" || resultTiming < 0; */

    const navigatesDetails: IBreadcrumbItem[] =
      lotto.code === "LOTTER_YEGEE"
        ? [
            { label: constants.betTitle, active: false, path: "/bet" },
            { label: constants.betSubTitle, active: false, path: "/bet" },
            {
              label: `รอบ${LOTTO_SLUG_NAME["LOTTER_YEGEE"]}`,
              active: false,
              path: "/bet/LOTTER_YEGEE",
            },
            {
              label: `${LOTTO_SLUG_NAME[this.state.targetLotto.code]}`,
              active: true,
            },
          ]
        : [
            { label: constants.betTitle, active: false, path: "/bet" },
            { label: constants.betSubTitle, active: false, path: "/bet" },
            {
              label: `${LOTTO_SLUG_NAME[this.state.targetLotto.code]}`,
              active: true,
            },
          ];

    let historyComponent = this.props.gameHistoryData.map(
      (game: IYegeeGameHistory, historyIndex: number) => {
        const NumberThreeUp = this.numbersComponent(game.threeUp, "THREE_UP");
        const NumberTwoUp = this.numbersComponent(game.twoUp, "TWO_UP");
        const NumberTwoDown = this.numbersComponent(game.twoDown, "TWO_DOWN");
        return (
          <div className={`row mt-4`} key={`game-history-${historyIndex}`}>
            <div className="col">
              <div className="p1-b">
                <h5 className="flex">
                  {"วันที่"}{" "}
                  {format(new Date(game.createdAt), "d MMM", { locale: th })}
                </h5>
              </div>
              <div className="d-flex flex-row m1-b">
                <NumberThreeUp />
                <NumberTwoUp />
                <NumberTwoDown />
              </div>
              <div className="d-flex flex-column m1-t">
                <div className="d-flex flex-row">
                  <h6 className="flex text-center tertiary-text">
                    {"อันดับรายได้ของผู้ที่ชนะ"}
                  </h6>
                </div>
              </div>
              <div className="d-flex flex-column">
                <div className="d-flex flex-row">
                  <h6 className="flex text-center">
                    <span className="tertiary-text">1:</span>{" "}
                    {game.totalOneBetResult}
                  </h6>
                  <h6 className="flex text-center">
                    <span className="tertiary-text">2:</span>{" "}
                    {game.totalTwoBetResult}
                  </h6>
                  <h6 className="flex text-center">
                    <span className="tertiary-text">3:</span>{" "}
                    {game.totalThreeBetResult}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        );
      }
    );

    return (
      <>
        <div className="paper-container" ref={this.betMakingContainerRef}>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="sub-menu " style={{ width: "fit-content" }}>
                <Breadcrumb
                  items={navigatesDetails}
                  handleOnClickItem={this.handleOnClickBreadcrumb}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-md-end mt-2 mt-md-0">
              <BetBanner />
            </div>
          </div>
          <div className="paper-title">การเดิมพัน</div>
          <div className="paper-description">แทงหวย</div>

          <div>
            <div className="row m4-t ">
              {this.renderLottoCard()}
              <div className="col-12 col-lg-6 col-xl-8 m3-t d-flex justify-content-lg-end align-items-end">
                {!this.state.onLottoProcessing &&
                lotto.status !== "NOT_ACCESS" ? (
                  <ResponsiveIcon
                    icon={Clock}
                    alt="clock"
                    className="clock-lotto-icon m1-r"
                  />
                ) : (
                  ""
                )}
                <div className="body-6">{RenderRemainingTime()}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-5">
                {this.props.match.params.type === "LOTTER_YEGEE" &&
                  this.state.lottoStatus === "OPEN" && (
                    <div className="paper-body">
                      <div className={`box-shadow history-yegee`}>
                        <Collapse
                          isExpand={this.state.isExpandHistoryYegee}
                          name={`${"สถิติการออกย้อนหลัง"}`}
                          onClick={() => {
                            this.setState({
                              isExpandHistoryYegee: !this.state
                                .isExpandHistoryYegee,
                            });
                          }}
                          classHeader="p1"
                        >
                          {historyComponent}
                        </Collapse>
                      </div>
                    </div>
                  )}
                <div className="paper-body">
                  <div className="box-shadow overflow-hidden">
                    <GameModeComponent />
                  </div>
                </div>
                {this.state.lottoStatus === "OPEN" && (
                  <div className="paper-body">
                    <ShortcutKeyComponent />
                  </div>
                )}
              </div>
              <SummaryModeComponent />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default BetMakingContainer;
