import { Formik, FormikProps } from "formik";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import "./register2FA.style.scss";
import initialRegister2FA from "./model/initialValues";
import schemeRegister2FA from "./model/scheme";
import Register2FAComponent from "./components";
import { ErrorModal } from "components";
import { noop } from "lodash";

const constants = {
  validateFail: "รหัส 2FA ไม่ถูกต้อง",
  validateSuccess: "รหัส 2FA ถูกต้อง",
};

const Register2FA = Register2FAComponent.Register2FAComponent;

type DefaultProps = Readonly<typeof defaultProps>;
const defaultProps: IRegister2FAContainerProps &
  IRegister2FAContainerActionProps = {
  validate2FA() {
    noop()
  },
  verify: false,
  loginIsFetching: false,
  loginCode: 0,
};

class Register2FAContainer extends Component<
  IRegister2FAContainerProps &
    IRegister2FAContainerActionProps &
    DefaultProps &
    RouteComponentProps<{}, {}, IRouteLogin>
> {
  static defaultProps = defaultProps;
  state: IRegister2FAContainerState = {};

  componentDidUpdate(prevProps: IRegister2FAContainerProps) {
    if (
      prevProps.loginIsFetching !== this.props.loginIsFetching &&
      !this.props.loginIsFetching
    ) {
      if (!this.props.verify) {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: constants.validateFail,
        });
      } else {
        this.props.history.push("/dashboard", {from: this.props.location})
      }
    }
  }

  onSubmitRegister2FA = (
    values: IRegister2FAForm,
  ) => {
    // this.props.verify does not change in the component because redirect in root.container.tsx
    this.props.validate2FA(values.password2FA)
  };

  Register2FAFormik = () => {
    const Register2FAForm = (formProps: FormikProps<IRegister2FAForm>) => {
      return <Register2FA {...formProps} />;
    };
    return (
      <Formik
        initialValues={initialRegister2FA}
        validationSchema={schemeRegister2FA}
        enableReinitialize
        onSubmit={this.onSubmitRegister2FA}
      >
        {Register2FAForm}
      </Formik>
    );
  };

  render() {
    const Register2FAFormik = this.Register2FAFormik;
    return (
      <div>
        <Register2FAFormik />
      </div>
    );
  }
}

export default Register2FAContainer;
