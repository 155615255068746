import { Modal } from "components/Modal";
import React, { FC } from "react";
import "./contact.style.scss";
import logo from "assets/images/logo/logothailandbet.png";
import { get, isEmpty, isNull, noop } from "lodash";
import { dateThai, number } from "utils";
import { Button } from "components/Button";
import color from "constants/colors";

const constants = {
  title: "ติดต่อ",
  titleWeb: "ไทยแลนด์เบท",
  titleEng: "Thailand Bet",
  agentPayTaxId: "เลขรายการ:",
  pay: "ยอด:",
  note: "ข้อมูลเพิ่มเติม:",
  line: "ไอดีไลน์:",
  phoneNumber: "เบอร์:",
  createdAt: "เวลาแจ้ง:",
  description: "คุณติดต่อผู้รับเงินแล้วใช่ไหม?",
  accept: "เรียบร้อย",
};

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: IContactModal = {
  title: constants.title,
  description: constants.description,
  action() {
    noop();
  },
  cancelAction() {
    noop();
  },
};

const ContactModal: FC<IContactModal & DefaultProps> = (props) => {
  return (
    <div className="contact-modal-container border-rounded">
      <div className="row mr-0">
        <div className="leading-logo-container m2-x">
          <img src={logo} className="logoMenu" alt="" />
        </div>
        <div className="title-text-web">
          <h3 className="title">{constants.titleWeb}</h3>
          <h5 className="tertiary-text sub-title">{constants.titleEng}</h5>
        </div>
      </div>
      <h2 className="title-text secondary-text">{props.title}</h2>

      <div className="container-detail m4-b">
        <div className="row list-summary-container no-gutters">
          <div className="col-12 d-flex m2-b flex-row">
            <div className="body-3b ellipsis-text" style={{ width: "14%" }}>
              {constants.agentPayTaxId}
            </div>
            <div className="body-3 ellipsis-text text-left pr-2" style={{ width: "14%", whiteSpace: 'unset' }}>
              {props.detailBill?.agentPayTxId}
            </div>
            <div className="body-3b ellipsis-text" style={{ width: "8%" }}>
              {constants.pay}
            </div>
            <div className="body-3 ellipsis-text text-left pr-2" style={{ width: "20%", whiteSpace: 'unset' }}>
              {number.addComma(props.detailBill?.pay)}
            </div>
            <div className="body-3b ellipsis-text" style={{ width: "16%" }}>
              {constants.note}
            </div>
            <div className="body-3 ellipsis-text text-left pr-2" style={{ width: "19%", whiteSpace: 'unset' }}>
              {props.detailBill?.note}
            </div>
          </div>
          <div className="col-12 d-flex m2-b flex-row">
            <div className="body-3b ellipsis-text" style={{ width: "10%" }}>
              {constants.line}
            </div>
            <div className="body-3 ellipsis-text text-left pr-2" style={{ width: "18%", whiteSpace: 'unset' }}>
              {isEmpty(get(props.detailBill, "line","-"))||isNull(get(props.detailBill, "line","-")) ? "-":props.detailBill?.line}
            </div>
            <div className="body-3b ellipsis-text" style={{ width: "8%" }}>
              {constants.phoneNumber}
            </div>
            <div className="body-3 ellipsis-text text-left pr-2" style={{ width: "50%", whiteSpace: 'unset' }}>
              {isEmpty(get(props.detailBill, "phoneNumber","-"))||isNull(get(props.detailBill, "phoneNumber","-")) ? "-":props.detailBill?.phoneNumber}
            </div>
          </div>
        </div>
        <div className="row list-summary-container">
          <div className="col-12 d-flex m2-b flex-row">
            <div className="body-3b ellipsis-text" style={{ width: "10%" }}>
              {constants.createdAt}
            </div>
            <div className="body-3 ellipsis-text text-left pr-2" style={{ width: "50%", whiteSpace: 'unset' }}>
              {props.detailBill
                ? dateThai.formatThai(props.detailBill?.createdAt)
                : ""}
            </div>
          </div>
        </div>
      </div>
      <p className="body-semi1 tertiary-text p2-x">{props.description}</p>
      <div className="row justify-content-center">
        <div className="col-4 p0-r">
          <Button
            id="confirm-button"
            type="rectangle"
            onClick={props.cancelAction}
            text={constants.accept!}
            textSize={18}
            textColor={color.PRIMARY_BG}
            transitionType="typeOne"
          />
        </div>
      </div>
    </div>
  );
};

ContactModal.defaultProps = defaultProps;

const modal = {
  show: (props: IContactModal) => {
    return Modal.event("MODAL_OVER", {
      state: "show",
      extraProps: { size: "lg" },
      component: <ContactModal {...props} />,
    });
  },
  hide: () => Modal.event("MODAL_OVER", { state: "hide" }),
};

export default modal;
