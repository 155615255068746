import { number } from "utils"
import colors from "constants/colors"
import React, { Component, createRef, RefObject } from "react"
import {
  cloneDeep,
  differenceWith,
  findIndex,
  forOwn,
  get,
  isEmpty,
  isEqual,
  isNull,
  omit,
  sum,
} from "lodash"
import {
  ResponsiveIcon,
  InputNumber,
  Button,
  SuccessModal,
  ErrorModal,
  Input,
  ConfirmModal,
  LoadingCircle,
} from "components"
import {
  CODE_TO_NAME,
  LOTTO_FLAG_ALPHA,
  STATUS,
  REMAINDER_HOLD_SELECT,
  LOTTO_GAME_TYPES,
  CODE_TO_TYPENAME_NUMBER,
  meDataInitialValue,
  BANK_TYPE,
} from "constants/variables"
import "./NewLottoBetConfig.style.scss"
import "./financeInfo.style.scss"
import Select from "react-select"
import { createMemberDropdownStyles } from "containers/CreateMember/CreateMemberDropdown.styles"
import { FormikProps } from "formik"
import LottoFlags from "assets/images/global/flags"
import Cancel from "assets/images/global/icon/cancel.png"
import { pascalCase } from "change-case"
import routes from "constants/routes"
import color from "constants/colors"
import ImageBankSet from "assets/images/global/bank"
import { ENG_THAI_AND_SPACE } from "constants/regex"
import { fetchChildSetting, fetchChildSettingLottery, fetchMemberChild } from "reduxs/members/services"
import { AxiosResponse } from "axios"
import Tooltip from "rc-tooltip";
import questionMark from "assets/images/logo/question-mark.png"
import "rc-tooltip/assets/bootstrap_white.css";

const constants = {
  settingBet: "ตั้งค่าการแบ่งหุ้น / เก็บของ",
  payRate: "อัตราจ่าย",
  payRatePercentage: "อัตราจ่าย",
  lotteryBetConfig: "คัดลอกข้อมูลจากผู้ใช้",
  userPlaceholder: "ค้นหาผู้ใช้",
  status: "ใช้งาน",
  storage: "เก็บของ",
  percen1: "ถือสู้ % ",
  percen1Note: (mustReciv: number, meShare: number) =>
    `(ไม่ต่ำกว่า ${mustReciv}% และไม่มากกว่า ${meShare}%)`,
  percen2: "บังคับสู้ % ",
  percen2Note: "(ไม่มากกว่าเปอร์เซ็นต์ถือสู้)",
  percen1Placeholder: "",
  percen2Placeholder: "",
  codeToTypeNameSetting: "ตั้งค่าแต่ละประเภท",
  allDiscountPercentage: "ตั้งส่วนลดทั้งหมดในครั้งเดียว",
  allDiscountPercentagePlaceholder: "ส่วนลดทั้งหมด",
  betRate: "บาทละ",
  betRateLimit: (limit: number) => ` / ${number.addComma(limit)} บาท`,
  betRateLimitError: (limit: number) =>
    `อัตราจ่ายต้องไม่มากกว่า ${number.addComma(limit)} บาท`,
  discountPercentage: "ส่วนลด %",
  discountPercentagePlaceholder: "",
  minBet: "จำกัดแทงต่ำสุด",
  maxBet: "จำกัดแทงสูงสุด",
  minBetPlaceholder: (minLimit: number) =>
    ` / ${number.addComma(minLimit)}`,
  maxBetPlaceholder: (maxLimit: number) =>
    ` / ${number.addComma(maxLimit)}`,
  maxComPlaceholder: (maxCom: number) =>
    ` / ${number.addComma(maxCom)} %`,
  reset: "รีเซ็ต",
  saveBetSetting: "บันทึกตั้งค่าหวย",
  betSetting: "ตั้งค่าหวย",
  hostSharePercentagePlaceholder: (limit: number) => ` / ${limit} %`,
  saveLotterySettingSuccessful: "บันทึกการตั้งค่าหวยสำเร็จ",
  resetLotterySettingSuccessful: "รีเซ็ตการตั้งค่าหวยสำเร็จ",
  saved: "บันทึกแล้ว",
  editLotterySuccessful: "แก้ไขข้อมูลสำเร็จ",
  isNotSavedYet: "ยังไม่ได้บันทึก",
  isChangeLottery: "คุณแน่ใจหรือว่าจะเปลี่ยนประเภทของหวย",
  childSettingLotteryUpdateSuccess: "แก้ไขข้อมูลการตั้งค่าหวยสำเร็จ",
  lastUpdate: (time: string) => `ข้อมูลเปลี่ยนแปลงล่าสุด ${time}`,
  editLottery: "แก้ไขข้อมูล",
  postOwnerSettingLotteryUpdateSuccess: "แก้ไขข้อมูลการตั้งค่าหวยสำเร็จ",
  lotterySettingParameterIncomplete: "กรุณากรอกข้อมูลตั้งค่าหวยให้ถูกต้อง",
  noInfo: "ไม่มีข้อมูล",
  baht: "บาท",

  limitCredit: "จำกัดเครดิต *",
  limitCreditPlaceholder: (credit: number) =>
    ` / ${number.addComma(credit)} บาท`,
  labelNatcommission: 'หุ้นคอมมิชชั่น',
  commission: "คอมมิชชั่นขาย % *",
  bankName: "ชื่อบัญชีธนาคาร",
  bankNumber: "หมายเลขบััญชีธนาคาร",
  bank: "ธนาคาร",
  bankNamePlaceholder: "ชื่อบัญชีธนาคาร",
  bankNumberPlaceholder: "หมายเลขบัญชีธนาคาร",
  bankPlaceholder: "เลือกธนาคาร",
  betMinOverLimit: "แทงต่ำสุดต้องน้อยกว่าลิมิตที่กำหนดไว้",
  titleFinanceInfo: "ข้อมูลการเงิน",
  AltTitleFinanceInfo: "ข้อมูลการเดิมพัน",
  editFinanceInfo: "แก้ไขข้อมูล",
  resetFinanceINfo: "รีเซ็ตเป็นค่าเดิม",
  resetFinanceInfoSuccess: "รีเซ็ตเป็นค่าเดิมสำเร็จ",
  postOwnerSettingSuccess: "แก้ไขข้อมูลการเดิมพันสำเร็จ",
  postChildSettingAndCreditUpdateSuccess: "แก้ไขข้อมูลลูกสมาชิกสำเร็จ",
  postChildSettingAndCreditUpdateFailure: "แก้ไขข้อมูลลูกสมาชิกไม่สำเร็จ",
  betMinPlaceholder: "0 บาท",
  betMaxPlaceholder: "0 บาท",
  comPlaceholder: "",

  tooltipAllDiscount: 'ตั้งส่วนลดทั้งหมดในครั้งเดียว ท่านสามารถกำหนดส่วนลดทุกประเภทแทงได้ในครั้งเดียวในช่องดังกล่าว',
  tooltipPayRate: (rateType: string) =>
    `อัตราจ่ายหวยสำหรับ ${rateType} ท่านสามารถตั้งอัตราจ่ายเองได้โดยไม่เกินจากที่หัวสมาชิกจำกัดไว้ได้`,
  tooltipDiscount: 'ส่วนลดเมื่อซื้อหวย คนที่ตั้งค่าส่วนลดเป็นคนรับผิดชอบค่าใช้จ่าย คิดเป็นเปอร์เซนต์จากยอดขาย',
  tooltipCommission: 'หุ้นคอมมิชชั่น ช่วยออกค่าคอมมิชชั่นเมื่อสมาชิกมียอดจ่ายคอมมิชชั่นกับสมาชิกของเขา คิดเป็นเปอร์เซนต์จากยอดขาย',
  tooltipMinLimit: 'จำกัดแทงต่ำสุดกับสมาชิก เมื่อสมาชิกขายหวย จะไม่สามารถขายในราคาที่ต่ำกว่าที่ตั้งไว้ได้ โดยไม่สามารถตั้งได้เกินที่หัวสมาชิกกำหนดไว้ได้',
  tooltipMaxLimit: 'จำกัดแทงสูงสุดกับสมาชิก เมื่อสมาชิกขายหวย จะไม่สามารถขายในราคาที่สูงกว่าที่ตั้งไว้ได้ โดยไม่สามารถตั้งได้เกินที่หัวสมาชิกกำหนดไว้ได้',
};

// const selectedProps = [
//   "childLotsetBetRateRunDown",
//   "childLotsetBetRateRunUp",
//   "childLotsetBetRateThreeToast",
//   "childLotsetBetRateThreeUp",
//   "childLotsetBetRateTwoDown",
//   "childLotsetBetRateTwoUp",
//   "childLotsetDisRunDown",
//   "childLotsetDisRunUp",
//   "childLotsetDisThreeToast",
//   "childLotsetDisThreeUp",
//   "childLotsetDisTwoDown",
//   "childLotsetDisTwoUp",
//   "childLotsetBetMinThreeUp",
//   "childLotsetBetMinThreeToast",
//   "childLotsetBetMinTwoUp",
//   "childLotsetBetMinTwoDown",
//   "childLotsetBetMinRunUp",
//   "childLotsetBetMinRunDown",
//   "childLotsetBetMaxThreeUp",
//   "childLotsetBetMaxThreeToast",
//   "childLotsetBetMaxTwoUp",
//   "childLotsetBetMaxTwoDown",
//   "childLotsetBetMaxRunUp",
//   "childLotsetBetMaxRunDown",
//   "childLotsetIsOpen",
//   "childLotsetKeep",
//   "childLotsetName"
// ];

const defaultProps: INewLottoBetConfigProps = {
  onChangeLottoConfig() { },
  creditLimit: 0,
  minBetHost: 0,
  isOtpClicked: false,
  lotterySetting: [],
  getChildSelectedLottoState() { },
  mode: "CREATE",
  meSettingLotteryCode: 0,
  meSettingLotteryData: {
    agentId: 0,
    lotterySetting: [],
  },
  meSettingLotteryIsFetching: false,
  meData: meDataInitialValue,
  meSettingData: {
    agentId: 0,
    setBetMaxLimit: 100,
    setBetMinLimit: 1,
    setComPercentage: 0,
  },
};
class NewLottoBetConfigComponent extends Component<
  FormikProps<ICreateMember> & INewLottoBetConfigProps & IFinanceInfoProps
> {
  static defaultProps = defaultProps;
  userContainerRef: RefObject<HTMLDivElement> = createRef();

  state: INewLottoBetConfigState & IFinanceInfoState = {
    isChecked: false,
    selectedLotto: [],
    lotterySettingState: [],
    isEditable: true,
    localStorageLotterySetting: [],
    nodeLotterySettingState: [],

    listChildCopySetting: [],
    isCopySetting: false,
    dataCopySetting: {
      childAgentId: 0,
      childSetBetMaxLimit: 0,
      childSetBetMinLimit: 0,
      childSetComPercentage: 0,
      lotterySetting: []
    },
    idChildSelect: -1,

    nodeBetMaxLimit: 0,
    nodeBetMinLimit: 0,

    // IFinanceInfoState
    isBankTypeSelected: false,
    search: '',
  };

  componentDidMount() {
    this.props.onChangeIsLoaddingAction!(true);
    fetchMemberChild({ page: 1, limit: 999999 })
      .then((res: AxiosResponse<IAPIResponse<IPagination<IV2GetAgentChild>>>) => {
        this.setState({ listChildCopySetting: res.data.data.dataList })
      })
      .catch(() => {
        ErrorModal.show({
          description: "คำร้องขอรายชื่อสมาชิกผิดพลาด",
          action: () => ErrorModal.hide()
        })
      })
      .finally(() => {
        this.props.onChangeIsLoaddingAction!(false);
      })
  }

  componentDidUpdate(
    prevProps: FormikProps<ICreateMember> & INewLottoBetConfigProps & IFinanceInfoProps,
    prevState: INewLottoBetConfigState
  ) {
    // IFinanceInfoState

    // if (
    //   prevProps.ownerSettingIsFetching !== this.props.ownerSettingIsFetching &&
    //   !this.props.ownerSettingIsFetching
    // ) {
    //   if (this.props.ownerSettingCode === responseCode.OK) {
    //     this.props.getMeSetting!();
    //     SuccessModal.show({
    //       action: () => SuccessModal.hide(),
    //       description: constants.postOwnerSettingSuccess,
    //     });
    //   } else {
    //     ErrorModal.show({
    //       action: () => ErrorModal.hide(),
    //       description: responseMessage()[this.props.ownerSettingCode || 0],
    //     });
    //   }
    // }


    //ดึงค่า max min มาใส่ node
    if ((this.props.meSettingLotteryIsFetching !== prevProps.meSettingLotteryIsFetching) && !this.props.meSettingLotteryIsFetching) {
      this.defaultLotterySetting();

      let maxMinStorage = localStorage.getItem("betMaxMinLimit") || ""
      if (!isEmpty(maxMinStorage)) {
        this.setState({
          nodeBetMinLimit: get(JSON.parse(maxMinStorage!), "min", 0),
          nodeBetMaxLimit: get(JSON.parse(maxMinStorage!), "max", 0)
        })
        this.props.setFieldValue("betMinLimit", get(JSON.parse(maxMinStorage!), "min", 0))
        this.props.setFieldValue("betMaxLimit", get(JSON.parse(maxMinStorage!), "max", 0))
      } else {
        // เช็คค่าเริ่มต้น มีใช่ตอน reset ด้วย
        this.setState({
          nodeBetMinLimit: this.props.meSettingData.setBetMinLimit > 1000 ? 1000 : this.props.meSettingData.setBetMinLimit,
          nodeBetMaxLimit: this.props.meSettingData.setBetMaxLimit > 1000 ? 1000 : this.props.meSettingData.setBetMaxLimit
        }, () => {
          this.props.setFieldValue("betMinLimit", this.state.nodeBetMinLimit)
          this.props.setFieldValue("betMaxLimit", this.state.nodeBetMaxLimit)
        })
      }
    }

    //ทำเมื่อมีการแก้ค่า value ของ formik   และ  lotterySettingState ต้องไม่ว่าง(ดักมันจะเข้าตอนแรก)
    if ((prevProps.values !== this.props.values && !isEmpty(this.state.lotterySettingState))
      && (prevProps.values.betMaxLimit === this.props.values.betMaxLimit)
      && (prevProps.values.betMinLimit === this.props.values.betMinLimit)
    ) {

      let indexSettingState = findIndex(this.state.lotterySettingState, (item: any) => item.childLotsetName === this.state.selectedLotto[0]?.childLotsetName);
      let checkStateSelected = isEqual(
        omit(this.state.lotterySettingState[indexSettingState], ["isNotSavedYet", "isSaved"]),
        omit(this.state.selectedLotto[0], ["isNotSavedYet", "isSaved"]),
      )
      let checkNodeLotto = isEqual(
        omit(this.state.nodeLotterySettingState[indexSettingState], ["isNotSavedYet", "isSaved"]),
        omit(this.state.selectedLotto[0], ["isNotSavedYet", "isSaved"])
      )

      // ถ้า lotterySettingState เท่ากับ selectedLotto
      if (checkStateSelected) {


      } else {
        // State ไม่เหมือนกัน select
        let lottoState = this.state.lotterySettingState
        let setChangeData = this.state.selectedLotto[0]


        //บันทึกแล้ว
        if (this.state.selectedLotto[0].isSaved) {
          setChangeData = { ...this.state.selectedLotto[0], isSaved: false, isNotSavedYet: true }

          // ไม่บันทึกเป็น true
        } else if (this.state.selectedLotto[0].isNotSavedYet) {
          if (checkNodeLotto) {
            setChangeData = { ...this.state.selectedLotto[0], isNotSavedYet: false }
          }
        } else {
          setChangeData = { ...this.state.selectedLotto[0], isNotSavedYet: true }
        }

        lottoState.splice(indexSettingState, 1, setChangeData)
        this.setState({ lotterySettingState: lottoState, selectedLotto: [setChangeData] })
      }



    }
  }

  loopSetMaxLimit = (lotterySetting: IV2LotterySetting[]) => {
    const clonedLotterySetting = cloneDeep(lotterySetting);
    const NumberBetMaxLimit = Number(this.props.values.betMaxLimit)
    clonedLotterySetting.forEach((item, index) => {
      // item.isNotSavedYet = true;
      item.childLotsetBetMaxThreeUp = 1000 >= NumberBetMaxLimit ? NumberBetMaxLimit : NumberBetMaxLimit <= this.props.meSettingData.setBetMaxLimit ? NumberBetMaxLimit : 1000;
      item.childLotsetBetMaxThreeToast = 1000 >= NumberBetMaxLimit ? NumberBetMaxLimit : NumberBetMaxLimit <= this.props.meSettingData.setBetMaxLimit ? NumberBetMaxLimit : 1000;
      item.childLotsetBetMaxTwoUp = 1000 >= NumberBetMaxLimit ? NumberBetMaxLimit : NumberBetMaxLimit <= this.props.meSettingData.setBetMaxLimit ? NumberBetMaxLimit : 1000;
      item.childLotsetBetMaxTwoDown = 1000 >= NumberBetMaxLimit ? NumberBetMaxLimit : NumberBetMaxLimit <= this.props.meSettingData.setBetMaxLimit ? NumberBetMaxLimit : 1000;
      item.childLotsetBetMaxRunUp = 1000 >= NumberBetMaxLimit ? NumberBetMaxLimit : NumberBetMaxLimit <= this.props.meSettingData.setBetMaxLimit ? NumberBetMaxLimit : 1000;
      item.childLotsetBetMaxRunDown = 1000 >= NumberBetMaxLimit ? NumberBetMaxLimit : NumberBetMaxLimit <= this.props.meSettingData.setBetMaxLimit ? NumberBetMaxLimit : 1000;

      item.childLotsetBetMinThreeUp = item.childLotsetBetMinThreeUp > item.childLotsetBetMaxThreeUp ? item.childLotsetBetMaxThreeUp : item.childLotsetBetMinThreeUp;
      item.childLotsetBetMinThreeToast = item.childLotsetBetMinThreeToast > item.childLotsetBetMaxThreeToast ? item.childLotsetBetMaxThreeToast : item.childLotsetBetMinThreeToast;
      item.childLotsetBetMinTwoUp = item.childLotsetBetMinTwoUp > item.childLotsetBetMaxTwoUp ? item.childLotsetBetMaxTwoUp : item.childLotsetBetMinTwoUp;
      item.childLotsetBetMinTwoDown = item.childLotsetBetMinTwoDown > item.childLotsetBetMaxTwoDown ? item.childLotsetBetMaxTwoDown : item.childLotsetBetMinTwoDown;
      item.childLotsetBetMinRunUp = item.childLotsetBetMinRunUp > item.childLotsetBetMaxRunUp ? item.childLotsetBetMaxRunUp : item.childLotsetBetMinRunUp;
      item.childLotsetBetMinRunDown = item.childLotsetBetMinRunDown > item.childLotsetBetMaxRunDown ? item.childLotsetBetMaxRunDown : item.childLotsetBetMinRunDown;
    });
    return clonedLotterySetting;
  };
  loopSetMinLimit = (lotterySetting: IV2LotterySetting[]) => {
    const clonedLotterySetting = cloneDeep(lotterySetting);
    const NumberBetMinLimit = Number(this.props.values.betMinLimit)
    clonedLotterySetting.forEach((item, index) => {
      // item.isNotSavedYet = true;
      item.childLotsetBetMinThreeUp = NumberBetMinLimit >= item.childLotsetBetMaxThreeUp
        ? item.childLotsetBetMaxThreeUp
        : NumberBetMinLimit < this.props.meSettingData.setBetMinLimit
          ? this.props.meSettingData.setBetMinLimit
          : NumberBetMinLimit
      item.childLotsetBetMinThreeToast = NumberBetMinLimit >= item.childLotsetBetMaxThreeToast
        ? item.childLotsetBetMaxThreeToast
        : NumberBetMinLimit < this.props.meSettingData.setBetMinLimit
          ? this.props.meSettingData.setBetMinLimit
          : NumberBetMinLimit
      item.childLotsetBetMinTwoUp = NumberBetMinLimit >= item.childLotsetBetMaxTwoUp
        ? item.childLotsetBetMaxTwoUp
        : NumberBetMinLimit < this.props.meSettingData.setBetMinLimit
          ? this.props.meSettingData.setBetMinLimit
          : NumberBetMinLimit
      item.childLotsetBetMinTwoDown = NumberBetMinLimit >= item.childLotsetBetMaxTwoDown
        ? item.childLotsetBetMaxTwoDown
        : NumberBetMinLimit < this.props.meSettingData.setBetMinLimit
          ? this.props.meSettingData.setBetMinLimit
          : NumberBetMinLimit
      item.childLotsetBetMinRunUp = NumberBetMinLimit >= item.childLotsetBetMaxRunUp
        ? item.childLotsetBetMaxRunUp
        : NumberBetMinLimit < this.props.meSettingData.setBetMinLimit
          ? this.props.meSettingData.setBetMinLimit
          : NumberBetMinLimit
      item.childLotsetBetMinRunDown = NumberBetMinLimit >= item.childLotsetBetMaxRunDown
        ? item.childLotsetBetMaxRunDown
        : NumberBetMinLimit < this.props.meSettingData.setBetMinLimit
          ? this.props.meSettingData.setBetMinLimit
          : NumberBetMinLimit
    });
    return clonedLotterySetting;
  };

  defaultLotterySetting = () => {
    const storedLotterySettingState = localStorage.getItem("lotterySettingState") || "";
    if (isEmpty(storedLotterySettingState)) {
      const defaultLotterySetting = this.setDefaultChildLotsetLimit(this.props.lotterySetting);
      this.setDefaultLotterySetting(defaultLotterySetting);
    } else {
      const parsedLotterySetting: IV2LotterySetting[] = JSON.parse(storedLotterySettingState);
      this.setDefaultLotterySetting(parsedLotterySetting);
    }
  };

  setDefaultLotterySetting = (defaultSetting: IV2LotterySetting[]) => {
    this.setState({ lotterySettingState: defaultSetting, nodeLotterySettingState: cloneDeep(defaultSetting) },
      () => {
        this.onClickLotteryCard(0, defaultSetting[0], false, true);
        this.props.setFieldValue("lotterySetting", defaultSetting);
      });
  };

  onClickLotteryCard = (
    index: number,
    data: IV2LotterySetting,
    shouldScroll: boolean = true,
    isFirstTimeClicked: boolean = false
  ) => {
    // if ((this.state.selectedLotto[0]?.isNotSavedYet && !isFirstTimeClicked)) {
    //   this.afterClickLotteryCard(index, data, shouldScroll);
    // }
    // if (  isFirstTimeClicked || this.state.selectedLotto[0].isSaved ) {
    //   this.afterClickLotteryCard(index, data, shouldScroll, isFirstTimeClicked);
    // }
    this.afterClickLotteryCard(index, data, shouldScroll, isFirstTimeClicked);
  };

  afterClickLotteryCard = (
    index: number,
    data: IV2LotterySetting,
    shouldScroll: boolean = true,
    isFirstTimeClicked: boolean = false
  ) => {
    const { /* setTouched, touched, */ setValues, values } = this.props;
    if (shouldScroll) {
      setTimeout(() => {
        this.handleScrollToTop();
      }, 256);
    }

    if (data && !isEmpty(this.props.lotterySetting)) {
      setValues({
        ...values,
        childLotsetIsOpen: data.childLotsetIsOpen,
        childLotsetKeep: data.childLotsetKeep,
        hostSharePercentage: data.childLotsetMeShare,
        fixSharePercentage: data.childLotsetMustReciv,
        discountAll: this.checkIfAllDiscountEqual([
          data.childLotsetDisRunDown,
          data.childLotsetDisRunUp,
          data.childLotsetDisTwoDown,
          data.childLotsetDisTwoUp,
          data.childLotsetDisThreeToast,
          data.childLotsetDisThreeUp]
        ) ? data.childLotsetDisThreeUp : '-0',

        childLotsetBetRateRunDown: data.childLotsetBetRateRunDown,
        childLotsetBetRateRunUp: data.childLotsetBetRateRunUp,
        childLotsetBetRateTwoDown: data.childLotsetBetRateTwoDown,
        childLotsetBetRateTwoUp: data.childLotsetBetRateTwoUp,
        childLotsetBetRateThreeToast: data.childLotsetBetRateThreeToast,
        childLotsetBetRateThreeUp: data.childLotsetBetRateThreeUp,

        childLotsetDisRunDown: data.childLotsetDisRunDown,
        childLotsetDisRunUp: data.childLotsetDisRunUp,
        childLotsetDisTwoDown: data.childLotsetDisTwoDown,
        childLotsetDisTwoUp: data.childLotsetDisTwoUp,
        childLotsetDisThreeToast: data.childLotsetDisThreeToast,
        childLotsetDisThreeUp: data.childLotsetDisThreeUp,


        childLotsetShareComRunDown: data.childLotsetShareComRunDown,
        childLotsetShareComRunUp:  data.childLotsetShareComRunUp,
        childLotsetShareComThreeToast: data.childLotsetShareComThreeToast,
        childLotsetShareComThreeUp: data.childLotsetShareComThreeUp,
        childLotsetShareComTwoDown: data.childLotsetShareComTwoDown,
        childLotsetShareComTwoUp: data.childLotsetShareComTwoUp,

        childLotsetBetMinThreeUp: data.childLotsetBetMinThreeUp,
        childLotsetBetMinThreeToast: data.childLotsetBetMinThreeToast,
        childLotsetBetMinTwoUp: data.childLotsetBetMinTwoUp,
        childLotsetBetMinTwoDown: data.childLotsetBetMinTwoDown,
        childLotsetBetMinRunUp: data.childLotsetBetMinRunUp,
        childLotsetBetMinRunDown: data.childLotsetBetMinRunDown,
        childLotsetBetMaxThreeUp: data.childLotsetBetMaxThreeUp,
        childLotsetBetMaxThreeToast: data.childLotsetBetMaxThreeToast,
        childLotsetBetMaxTwoUp: data.childLotsetBetMaxTwoUp,
        childLotsetBetMaxTwoDown: data.childLotsetBetMaxTwoDown,
        childLotsetBetMaxRunUp: data.childLotsetBetMaxRunUp,
        childLotsetBetMaxRunDown: data.childLotsetBetMaxRunDown,
        meShare: this.props.lotterySetting[index].childLotsetMeShare,
        mustReciv: this.props.lotterySetting[index].childLotsetMustReciv,
        childLotsetBetMinThreeUpAPI: this.props.lotterySetting[index]
          .childLotsetBetMinThreeUp,
        childLotsetBetMinThreeToastAPI: this.props.lotterySetting[index]
          .childLotsetBetMinThreeToast,
        childLotsetBetMinTwoUpAPI: this.props.lotterySetting[index]
          .childLotsetBetMinTwoUp,
        childLotsetBetMinTwoDownAPI: this.props.lotterySetting[index]
          .childLotsetBetMinTwoDown,
        childLotsetBetMinRunUpAPI: this.props.lotterySetting[index]
          .childLotsetBetMinRunUp,
        childLotsetBetMinRunDownAPI: this.props.lotterySetting[index]
          .childLotsetBetMinRunDown,
        childLotsetBetMaxThreeUpAPI: this.props.lotterySetting[index]
          .childLotsetBetMaxThreeUp,
        childLotsetBetMaxThreeToastAPI: this.props.lotterySetting[index]
          .childLotsetBetMaxThreeToast,
        childLotsetBetMaxTwoUpAPI: this.props.lotterySetting[index]
          .childLotsetBetMaxTwoUp,
        childLotsetBetMaxTwoDownAPI: this.props.lotterySetting[index]
          .childLotsetBetMaxTwoDown,
        childLotsetBetMaxRunUpAPI: this.props.lotterySetting[index]
          .childLotsetBetMaxRunUp,
        childLotsetBetMaxRunDownAPI: this.props.lotterySetting[index]
          .childLotsetBetMaxRunDown,

        childLotsetBetRateThreeUpAPI:
          this.props.meSettingLotteryData.lotterySetting[index]
            ?.childLotsetBetRateThreeUp || 0,
        childLotsetBetRateThreeToastAPI:
          this.props.meSettingLotteryData.lotterySetting[index]
            ?.childLotsetBetRateThreeToast || 0,
        childLotsetBetRateTwoUpAPI:
          this.props.meSettingLotteryData.lotterySetting[index]
            ?.childLotsetBetRateTwoUp || 0,
        childLotsetBetRateTwoDownAPI:
          this.props.meSettingLotteryData.lotterySetting[index]
            ?.childLotsetBetRateTwoDown || 0,
        childLotsetBetRateRunUpAPI:
          this.props.meSettingLotteryData.lotterySetting[index]
            ?.childLotsetBetRateRunUp || 0,
        childLotsetBetRateRunDownAPI:
          this.props.meSettingLotteryData.lotterySetting[index]
            ?.childLotsetBetRateRunDown || 0,
      });
    }

    if (get(this.state.selectedLotto, "[0].childLotsetName", '') !== get(this.state.lotterySettingState[index], "childLotsetName", '')) {
      // set selectedLotto ตอนเปลี่ยนหวย 
      this.setState({ selectedLotto: [this.state.lotterySettingState[index]] });
    }
  };

  handleScrollToTop = () => {
    if (this.userContainerRef.current) {
      this.userContainerRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  renderLotteryCard = (data: IV2LotterySetting) => {
    const RenderBadge = () => {
      if (data.isNotSavedYet) {
        return (
          <div className="is-not-saved-yet-lottery-setting-text mt-1">
            {constants.isNotSavedYet}
          </div>
        );
      }
      if (data.isSaved) {
        return (
          <div className="save-lottery-setting-text mt-1">
            {constants.saved}
          </div>
        );
      }
      return <></>;
    };

    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div
          className={`${data.childLotsetIsOpen ? "badge" : "badge-close"
            } d-flex align-items-center justify-content-center`}
        >
          <div
            className={`${data.childLotsetIsOpen ? "badge-text" : "badge-text-close"
              }`}
          >{`${data.childLotsetIsOpen ? "เปิด" : "ปิด"}`}</div>
        </div>
        <RenderBadge />
      </div>
    );
  };



  renderLotteryList = () => {
    const selectedLottoIndex = findIndex(
      this.props.lotterySetting,
      (item) => item.childLotsetName === this.state.selectedLotto[0]?.childLotsetName
    );

    return this.state.lotterySettingState.map((data, index) => {
      let FlagIcon: string;
      if (data.childLotsetName.includes("YEGEE")) {
        FlagIcon = LottoFlags["THA"].Icon;
      } else {
        FlagIcon = LottoFlags[LOTTO_FLAG_ALPHA[data.childLotsetName]]?.Icon;
      }
      return (
        <div
          onClick={() => {
            this.props.validateForm()
            if (!this.checkSettingLotto()) {
              this.props.setTouched({});
              this.props.setErrors({});
              this.onClickLotteryCard(index, data)
            } else {
              ErrorModal.show({
                description: "กรุณาตั้งค่าหวยให้ถูกต้อง",
                action: () => ErrorModal.hide()
              })
            }

          }}
          className={`lottery-card ${!data.childLotsetIsOpen && "closed"} ${selectedLottoIndex === index && "active"
            } d-flex flex-row align-items-center justify-content-between`}
          key={index}
        >
          <div className="d-flex flex-row align-items-center">
            <ResponsiveIcon icon={FlagIcon} alt="flag" className="flag" />
            <div
              className={`code-name-text ${!data.childLotsetIsOpen && "closed"
                }`}
            >
              {data.childLotsetName.includes("YEGEE")
                ? `${CODE_TO_NAME["YEGEE"]}รอบที่ ${Number(
                  data.childLotsetName.split("_")[1]
                )}`
                : CODE_TO_NAME[data.childLotsetName]}
            </div>
          </div>
          {this.renderLotteryCard(data)}
        </div>
      );
    });
  };

  renderLotteryConfig = () => {
    if (!isEmpty(this.state.selectedLotto) && this.state.selectedLotto[0]) {
      const {
        childLotsetName,
        childLotsetIsOpen,
      } = this.state.selectedLotto[0];
      const meSettingLotto = this.props.meSettingLotteryData.lotterySetting.find((i) => i.childLotsetName === childLotsetName)
      const selectedLottoIndex = findIndex(
        this.props.lotterySetting,
        (item) => item.childLotsetName === this.state.selectedLotto[0]?.childLotsetName
      );
      const {
        values,
        setFieldValue,
        handleBlur,
        errors,
        touched,
        setValues,
      } = this.props;

      const RenderCodeToTypeNameSetting = this.renderCodeToTypeNameSetting();
      const formatStatusSelect = () => {
        let formattedStatusSelect: any[] = [];
        forOwn(STATUS, function (values, keys) {
          formattedStatusSelect.push({
            label: values,
            value: keys === "OPEN" ? true : false,
            disabled: false,
          });
        });
        return formattedStatusSelect;
      };
      const formattedStatusSelect = formatStatusSelect();

      const formatRemainderHoldSelect = () => {
        let formatRemainderHoldSelect: any[] = [];
        forOwn(REMAINDER_HOLD_SELECT, function (values, keys) {
          formatRemainderHoldSelect.push({
            label: values,
            value: keys === "KEEP" ? true : false,
            disabled: false,
          });
        });
        return formatRemainderHoldSelect;
      };
      const formattedRemainderHoldSelect = formatRemainderHoldSelect();

      return (
        <>
          <div className="row">
            <div className="col-12">
              <h4 className="lottery-config-name-text">
                {childLotsetName.includes("YEGEE")
                  ? `${CODE_TO_NAME["YEGEE"]}รอบที่ ${Number(
                    childLotsetName.split("_")[1]
                  )}`
                  : CODE_TO_NAME[childLotsetName]}
              </h4>
            </div>
            <div className="col-5 mt-4">
              <label className="label-input">{constants.status}</label>
              <Select
                styles={createMemberDropdownStyles(
                  !this.state.isEditable || (!childLotsetIsOpen && !meSettingLotto?.childLotsetIsOpen)
                )}
                components={{
                  IndicatorSeparator: () => null,
                }}
                name="childLotsetIsOpen"
                options={formattedStatusSelect}
                value={
                  formattedStatusSelect[
                  findIndex(
                    formattedStatusSelect,
                    (item) => item.value === values.childLotsetIsOpen
                  )
                  ]
                }
                onChange={(e) => {
                  setFieldValue("childLotsetIsOpen", e.value);
                  if (this.props.mode === "CREATE") {
                    const clonedSelectedLotto = cloneDeep(
                      this.state.selectedLotto
                    );
                    clonedSelectedLotto[0].childLotsetIsOpen = e.value;
                    this.setState({ selectedLotto: clonedSelectedLotto });
                  }
                }}
                isDisabled={!this.state.isEditable || (!childLotsetIsOpen && !meSettingLotto?.childLotsetIsOpen)}
                noOptionsMessage={() => constants.noInfo}
              />
            </div>
            <div className="col-7 mt-4"></div>
            <div className="col-2 mt-4">
              <label className="label-input">{constants.storage}</label>
              <Select
                styles={createMemberDropdownStyles(
                  !this.state.isEditable
                )}
                components={{
                  IndicatorSeparator: () => null,
                }}
                name="childLotsetKeep"
                options={formattedRemainderHoldSelect}
                value={
                  formattedRemainderHoldSelect[
                  findIndex(
                    formattedRemainderHoldSelect,
                    (item) => item.value === values.childLotsetKeep
                  )
                  ]
                }
                onChange={(e) => {
                  setFieldValue("childLotsetKeep", e.value);
                  if (this.props.mode === "CREATE") {
                    const clonedSelectedLotto = cloneDeep(
                      this.state.selectedLotto
                    );
                    clonedSelectedLotto[0].childLotsetKeep = e.value;
                    this.setState({ selectedLotto: clonedSelectedLotto });
                  }
                }}
                isDisabled={
                  !this.state.isEditable
                }
                noOptionsMessage={() => constants.noInfo}
              />
            </div>
            <div className="col-5 mt-4">
              <label className="label-input">
                {constants.percen1}
                {this.props.meData.permission === "OWNER" &&
                  window.location.pathname === routes.userDetail.path ? (
                  <></>
                ) : (
                  <span className="label-input-note">
                    {!isEmpty(this.props.lotterySetting)
                      ? constants.percen1Note(
                        this.props.lotterySetting[selectedLottoIndex]
                          .childLotsetMustReciv,
                        this.props.lotterySetting[selectedLottoIndex]
                          .childLotsetMeShare
                      )
                      : constants.percen1Note(0, 0)}
                  </span>
                )}
              </label>
              <div className="d-flex flex-row">
                <div className="hostSharePercentageWrapper w-100">
                  <InputNumber
                    onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {

                      if (e.target.value === '0') {
                        this.props.setTouched({
                          ...this.props.touched,
                          fixSharePercentage: false,
                        })
                        setFieldValue("hostSharePercentage", '');
                      }

                    }}
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value === '')
                        setFieldValue("hostSharePercentage", '0');
                      handleBlur(e)

                    }}
                    id="hostSharePercentage"
                    name="hostSharePercentage"
                    value={values.hostSharePercentage}
                    placeholder={constants.percen1Placeholder}
                    // onBlur={handleBlur}
                    onValueChange={(values) => {
                      setFieldValue("hostSharePercentage", values.value);
                      if (this.props.mode === "CREATE") {
                        const clonedSelectedLotto = cloneDeep(
                          this.state.selectedLotto
                        );
                        clonedSelectedLotto[0].childLotsetMeShare = Number(
                          values.value
                        );
                        this.setState({ selectedLotto: clonedSelectedLotto });
                      }
                    }}
                    isNumericString
                    inputClassName="minBet"
                    backgroundColor={colors.TRANSPARENT}
                    decimalScale={2}
                    allowNegative={false}
                    isAllowed={({ floatValue }) =>
                      floatValue ? floatValue <= 100 : true
                    }
                    disabled={
                      !this.state.isEditable
                    }
                    renderError={false}
                    mode={"InputElements"}
                    endText={
                      this.props.meData?.permission === "OWNER" &&
                        window.location.pathname === routes.userDetail.path
                        ? "%"
                        : constants.hostSharePercentagePlaceholder(
                          !isEmpty(this.props.lotterySetting)
                            ? this.props.lotterySetting[selectedLottoIndex]
                              .childLotsetMeShare
                            : 0
                        )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-5 mt-4">
              <label className="label-input">
                {constants.percen2}
                {this.props.meData.permission === "OWNER" &&
                  window.location.pathname === routes.userDetail.path ? (
                  <></>
                ) : (
                  <span className="label-input-note">
                    {constants.percen2Note}
                  </span>
                )}
              </label>
              <div className="d-flex flex-row">
                <div className="fixSharePercentageWrapper w-100">
                  <InputNumber
                    onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value === '0')
                        setFieldValue("fixSharePercentage", '');
                    }}
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value === '')
                        setFieldValue("fixSharePercentage", '0');
                      handleBlur(e)
                    }}
                    id="fixSharePercentage"
                    name="fixSharePercentage"
                    value={values.fixSharePercentage}
                    placeholder={constants.percen2Placeholder}
                    onValueChange={(values) => {
                      setFieldValue("fixSharePercentage", values.value);
                      if (this.props.mode === "CREATE") {
                        const clonedSelectedLotto = cloneDeep(
                          this.state.selectedLotto
                        );
                        clonedSelectedLotto[0].childLotsetMustReciv = Number(
                          values.value
                        );
                        this.setState({ selectedLotto: clonedSelectedLotto });
                      }
                    }}
                    isNumericString
                    inputClassName="minBet"
                    backgroundColor={colors.TRANSPARENT}
                    decimalScale={2}
                    allowNegative={false}
                    isAllowed={({ floatValue }) =>
                      floatValue ? floatValue <= 100 : true
                    }
                    disabled={
                      !this.state.isEditable
                    }
                    renderError={false}
                    endText={constants.hostSharePercentagePlaceholder(
                      values.hostSharePercentage
                    )}
                    mode="InputElements"
                  />
                </div>
              </div>
            </div>
            <div className="col-2" />
            <div className="col-5">
              <h6 className="primary-red-text" style={{ marginTop: 6 }}>
                {!!errors.hostSharePercentage && touched.hostSharePercentage
                  ? errors.hostSharePercentage === "error"
                    ? `ถือสู้ต้องไม่ต่ำกว่า ${values.mustReciv}% และไม่มากกว่า ${values.meShare} %`
                    : errors.hostSharePercentage
                  : "\u00A0"}
              </h6>
            </div>
            <div className="col-5">
              <h6 className="primary-red-text" style={{ marginTop: 6 }}>
                {!!errors.fixSharePercentage && touched.fixSharePercentage
                  ? errors.fixSharePercentage === "error"
                    ? `บังคับสู้ต้องไม่มากกว่าเปอร์เซ็นต์ถือสู้`
                    : errors.fixSharePercentage
                  : "\u00A0"}
              </h6>
            </div>
            <div className="col-12" style={{ marginTop: 6 }}>
              <label className="label-input">
                {constants.codeToTypeNameSetting}
              </label>
            </div>
            <div className="col-5 mt-4">
              {this.labelTooltip(constants.allDiscountPercentage, constants.tooltipAllDiscount)}
              <InputNumber
                id="discountAll"
                name="discountAll"
                value={values.discountAll}
                placeholder={constants.allDiscountPercentagePlaceholder}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value === '') {
                    setFieldValue("discountAll", '0');
                  }
                  handleBlur(e)
                }}
                onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value === '0') {
                    setFieldValue("discountAll", '');
                  }
                }}
                onValueChange={(e) => {
                  if (e.value !== '-0') {
                    const convertedTargetValue = e.value === '' || Number(e.value) === 0 ? 0 : e.value;
                    // if (e.value!=='') {
                    setValues({
                      ...values,
                      childLotsetDisRunDown: Number(convertedTargetValue),
                      childLotsetDisRunUp: Number(convertedTargetValue),
                      childLotsetDisThreeToast: Number(convertedTargetValue),
                      childLotsetDisThreeUp: Number(convertedTargetValue),
                      childLotsetDisTwoDown: Number(convertedTargetValue),
                      childLotsetDisTwoUp: Number(convertedTargetValue),
                      discountAll: e.value === '' ? '' : Number(convertedTargetValue),
                    });

                    if (this.props.mode === "CREATE") {
                      const clonedSelectedLotto = cloneDeep(
                        this.state.selectedLotto
                      );
                      const DisPropArr = Object.keys(
                        clonedSelectedLotto[0]
                      ).filter((key) => key.includes("Dis"));

                      DisPropArr.forEach((val, _) => {
                        (clonedSelectedLotto[0] as any)[val] = Number(
                          convertedTargetValue
                        );
                      });
                      this.setState({ selectedLotto: clonedSelectedLotto });
                    }
                    // }
                  }

                }}
                allowLeadingZeros={false}
                errorMessage={errors.discountAll}
                error={!!errors.discountAll && touched.discountAll}
                inputClassName="input-otp"
                backgroundColor={colors.TRANSPARENT}
                allowNegative={false}
                decimalScale={values.discountAll < 0 ? 0 : 2}
                isAllowed={({ floatValue }) =>
                  floatValue ? floatValue <= 100 : true
                }
                disabled={!this.state.isEditable}
                mode="InputSplit"
                endText="%"
              />
            </div>
            <div className="col-7 mt-4"></div>
            {RenderCodeToTypeNameSetting}
          </div>
        </>
      );
    }
    return <></>;
  };

  checkIfAllDiscountEqual = (data: number[]) => {
    let AllDis = true
    for (let index = 0; index < data.length; index++) {
      AllDis = data[index] === data[index - 1]
      if (data[index] !== (sum(data) / data.length)) {
        AllDis = false
        return false
      }
    }
    return AllDis
  };

  setDefaultChildLotsetLimit = (lotterySetting: IV2LotterySetting[]) => {
    const clonedLotterySetting = cloneDeep(lotterySetting);
    clonedLotterySetting.forEach((item, index) => {
      if (window.location.pathname === routes.createMember.path) {
        item.childLotsetDisRunDown = 0;
        item.childLotsetDisRunUp = 0;
        item.childLotsetDisThreeToast = 0;
        item.childLotsetDisThreeUp = 0;
        item.childLotsetDisTwoDown = 0;
        item.childLotsetDisTwoUp = 0;
      }
      item.childLotsetMeShare = item.childLotsetMustReciv;
      item.childLotsetMustReciv = 0

      item.childLotsetBetMaxThreeUp =
        item.childLotsetBetMaxThreeUp >= 1000
          ? 1000
          : item.childLotsetBetMaxThreeUp;
      item.childLotsetBetMaxThreeToast =
        item.childLotsetBetMaxThreeToast >= 1000
          ? 1000
          : item.childLotsetBetMaxThreeToast;
      item.childLotsetBetMaxTwoUp =
        item.childLotsetBetMaxTwoUp >= 1000
          ? 1000
          : item.childLotsetBetMaxTwoUp;
      item.childLotsetBetMaxTwoDown =
        item.childLotsetBetMaxTwoDown >= 1000
          ? 1000
          : item.childLotsetBetMaxTwoDown;
      item.childLotsetBetMaxRunUp =
        item.childLotsetBetMaxRunUp >= 1000
          ? 1000
          : item.childLotsetBetMaxRunUp;
      item.childLotsetBetMaxRunDown =
        item.childLotsetBetMaxRunDown >= 1000
          ? 1000
          : item.childLotsetBetMaxRunDown;

      item.childLotsetBetRateThreeUp = 1
      item.childLotsetBetRateThreeToast = 1
      item.childLotsetBetRateTwoUp = 1
      item.childLotsetBetRateTwoDown = 1
      item.childLotsetBetRateRunUp = 1
      item.childLotsetBetRateRunDown = 1
      item.childLotsetIsOpen = false;
      item.isSaved = false;
      item.isNotSavedYet = false;
    });
    return clonedLotterySetting;
  };

  setCopyChildLotset = (lotterySetting: IV2LotterySetting[]) => {
    const clonedLotterySetting = cloneDeep(lotterySetting);
    clonedLotterySetting.forEach((item, index) => {
      item.isSaved = false;
      item.isNotSavedYet = false;
    });
    this.setState({ lotterySettingState: clonedLotterySetting, nodeLotterySettingState: cloneDeep(clonedLotterySetting) },
      () => {
        let index = 0
        let data = clonedLotterySetting[0]
        const dataCopySetting = get(this.state.dataCopySetting, "lotterySetting", [])
        if (data && !isEmpty(dataCopySetting)) {
          this.props.setValues({
            ...this.props.values,
            lotterySetting: clonedLotterySetting,
            com: get(this.state.dataCopySetting, "childSetComPercentage", 0),

            //set NODE ด้วยข้างล่าง
            betMaxLimit: get(this.state.dataCopySetting, "childSetBetMaxLimit", 1000),
            betMinLimit: get(this.state.dataCopySetting, "childSetBetMinLimit", 1),
            childLotsetIsOpen: data.childLotsetIsOpen,
            childLotsetKeep: data.childLotsetKeep,
            hostSharePercentage: data.childLotsetMeShare,
            fixSharePercentage: data.childLotsetMustReciv,
            discountAll: this.checkIfAllDiscountEqual([
              data.childLotsetDisRunDown,
              data.childLotsetDisRunUp,
              data.childLotsetDisTwoDown,
              data.childLotsetDisTwoUp,
              data.childLotsetDisThreeToast,
              data.childLotsetDisThreeUp]
            ) ? data.childLotsetDisThreeUp : '-0',

            
            childLotsetBetRateRunDown: data.childLotsetBetRateRunDown,
            childLotsetBetRateRunUp: data.childLotsetBetRateRunUp,
            childLotsetBetRateTwoDown: data.childLotsetBetRateTwoDown,
            childLotsetBetRateTwoUp: data.childLotsetBetRateTwoUp,
            childLotsetBetRateThreeToast: data.childLotsetBetRateThreeToast,
            childLotsetBetRateThreeUp: data.childLotsetBetRateThreeUp,
            
            childLotsetDisRunDown: data.childLotsetDisRunDown,
            childLotsetDisRunUp: data.childLotsetDisRunUp,
            childLotsetDisTwoDown: data.childLotsetDisTwoDown,
            childLotsetDisTwoUp: data.childLotsetDisTwoUp,
            childLotsetDisThreeToast: data.childLotsetDisThreeToast,
            childLotsetDisThreeUp: data.childLotsetDisThreeUp,

            childLotsetShareComRunDown: data.childLotsetShareComRunDown,
            childLotsetShareComRunUp: data.childLotsetShareComRunUp,
            childLotsetShareComThreeToast:data.childLotsetShareComThreeToast,
            childLotsetShareComThreeUp: data.childLotsetShareComThreeUp,
            childLotsetShareComTwoDown:data.childLotsetShareComTwoDown,
            childLotsetShareComTwoUp: data.childLotsetShareComTwoUp,

            childLotsetBetMinThreeUp: data.childLotsetBetMinThreeUp,
            childLotsetBetMinThreeToast: data.childLotsetBetMinThreeToast,
            childLotsetBetMinTwoUp: data.childLotsetBetMinTwoUp,
            childLotsetBetMinTwoDown: data.childLotsetBetMinTwoDown,
            childLotsetBetMinRunUp: data.childLotsetBetMinRunUp,
            childLotsetBetMinRunDown: data.childLotsetBetMinRunDown,
            childLotsetBetMaxThreeUp: data.childLotsetBetMaxThreeUp,
            childLotsetBetMaxThreeToast: data.childLotsetBetMaxThreeToast,
            childLotsetBetMaxTwoUp: data.childLotsetBetMaxTwoUp,
            childLotsetBetMaxTwoDown: data.childLotsetBetMaxTwoDown,
            childLotsetBetMaxRunUp: data.childLotsetBetMaxRunUp,
            childLotsetBetMaxRunDown: data.childLotsetBetMaxRunDown,
          });
        }
        this.setState({
          selectedLotto: [this.state.lotterySettingState[index]],
          nodeBetMaxLimit: get(this.state.dataCopySetting, "childSetBetMaxLimit", 1000),
          nodeBetMinLimit: get(this.state.dataCopySetting, "childSetBetMinLimit", 1)
        }, () => {
          SuccessModal.show({
            description: this.state.isCopySetting ? constants.resetLotterySettingSuccessful : "คัดลอกข้อมูลจากผู้ใช้สำเร็จ",
            action: () => SuccessModal.hide()
          })
          this.props.validateForm()
          this.setState({ isCopySetting: true })
        });
      });
  };

  resetLotterySetting = () => {
    const { setValues, values } = this.props;

    const selectedLottoIndex = findIndex(
      this.props.lotterySetting, (item) => item.childLotsetName === this.state.selectedLotto[0]?.childLotsetName
    );

    const clonedLotterySetting = this.setDefaultChildLotsetLimit(this.props.lotterySetting)

    const clonedSelectedLotto = cloneDeep(this.state.selectedLotto);
    clonedSelectedLotto[0] = clonedLotterySetting[selectedLottoIndex];

    const clonedLotterySettingState = cloneDeep(this.state.lotterySettingState);
    clonedLotterySettingState[selectedLottoIndex] = clonedSelectedLotto[0];

    this.setState({ lotterySettingState: clonedLotterySetting, nodeLotterySettingState: cloneDeep(clonedLotterySetting) }, () => {
      localStorage.setItem("lotterySettingState", JSON.stringify(clonedLotterySetting));
    });

    const originalValue = clonedLotterySetting[selectedLottoIndex];

    this.setState({
      selectedLotto: clonedSelectedLotto,
      // เช็คใช้เงื่อนไขเหมือน setDefault ครั้งแรกใน DidMount
      nodeBetMinLimit: this.props.meSettingData.setBetMinLimit > 1000 ? 1000 : this.props.meSettingData.setBetMinLimit,
      nodeBetMaxLimit: this.props.meSettingData.setBetMaxLimit > 1000 ? 1000 : this.props.meSettingData.setBetMaxLimit
    }, () => {
      localStorage.setItem("betMaxMinLimit", JSON.stringify({ min: this.state.nodeBetMinLimit, max: this.state.nodeBetMaxLimit }));
      setValues({
        ...values,
        com: 0,
        childLotsetBetRateRunDown: originalValue.childLotsetBetRateRunDown,
        childLotsetBetRateRunUp: originalValue.childLotsetBetRateRunUp,
        childLotsetBetRateThreeToast: originalValue.childLotsetBetRateThreeToast,
        childLotsetBetRateThreeUp: originalValue.childLotsetBetRateThreeUp,
        childLotsetBetRateTwoDown: originalValue.childLotsetBetRateTwoDown,
        childLotsetBetRateTwoUp: originalValue.childLotsetBetRateTwoUp,
        childLotsetDisRunDown: originalValue.childLotsetDisRunDown,
        childLotsetDisRunUp: originalValue.childLotsetDisRunUp,
        childLotsetDisThreeToast: originalValue.childLotsetDisThreeToast,
        childLotsetDisThreeUp: originalValue.childLotsetDisThreeUp,
        childLotsetDisTwoDown: originalValue.childLotsetDisTwoDown,
        childLotsetDisTwoUp: originalValue.childLotsetDisTwoUp,

        childLotsetShareComThreeUp: 0,
        childLotsetShareComThreeToast: 0,
        childLotsetShareComTwoUp: 0,
        childLotsetShareComTwoDown: 0,
        childLotsetShareComRunUp: 0,
        childLotsetShareComRunDown: 0,


        childLotsetBetMinThreeUp: originalValue.childLotsetBetMinThreeUp,
        childLotsetBetMinThreeToast: originalValue.childLotsetBetMinThreeToast,
        childLotsetBetMinTwoUp: originalValue.childLotsetBetMinTwoUp,
        childLotsetBetMinTwoDown: originalValue.childLotsetBetMinTwoDown,
        childLotsetBetMinRunUp: originalValue.childLotsetBetMinRunUp,
        childLotsetBetMinRunDown: originalValue.childLotsetBetMinRunDown,
        childLotsetBetMaxThreeUp: originalValue.childLotsetBetMaxThreeUp,
        childLotsetBetMaxThreeToast: originalValue.childLotsetBetMaxThreeToast,
        childLotsetBetMaxTwoUp: originalValue.childLotsetBetMaxTwoUp,
        childLotsetBetMaxTwoDown: originalValue.childLotsetBetMaxTwoDown,
        childLotsetBetMaxRunUp: originalValue.childLotsetBetMaxRunUp,
        childLotsetBetMaxRunDown: originalValue.childLotsetBetMaxRunDown,
        hostSharePercentage: window.location.pathname === routes.userDetail.path ? originalValue.childLotsetMeShare : originalValue.childLotsetMustReciv,
        fixSharePercentage: originalValue.childLotsetMustReciv,
        discountAll: "",
        childLotsetIsOpen: originalValue.childLotsetIsOpen,
        childLotsetKeep: originalValue.childLotsetKeep,
        lotterySetting: clonedLotterySetting,

        betMinLimit: this.state.nodeBetMinLimit,
        betMaxLimit: this.state.nodeBetMaxLimit,
      });
      SuccessModal.show({
        action: () => SuccessModal.hide(),
        description: constants.resetLotterySettingSuccessful,
      });
      this.props.setTouched({});
      this.props.setErrors({});
    });
  };

  checkSettingLotto = () => {
    const { errors, touched } = this.props;
    return (
      (!!errors.hostSharePercentage && touched.hostSharePercentage)
      || (!!errors.fixSharePercentage && touched.fixSharePercentage)
      || (!!errors.childLotsetDisThreeUp && touched.childLotsetDisThreeUp)
      || (!!errors.childLotsetDisThreeToast && touched.childLotsetDisThreeToast)
      || (!!errors.childLotsetDisTwoDown && touched.childLotsetDisTwoDown)
      || (!!errors.childLotsetDisTwoUp && touched.childLotsetDisTwoUp)
      || (!!errors.childLotsetDisRunUp && touched.childLotsetDisRunUp)
      || (!!errors.childLotsetDisRunDown && touched.childLotsetDisRunDown)
      
      || (!!errors.childLotsetShareComThreeUp && touched.childLotsetShareComThreeUp)
      || (!!errors.childLotsetShareComThreeToast && touched.childLotsetShareComThreeToast)
      || (!!errors.childLotsetShareComTwoDown && touched.childLotsetShareComTwoDown)
      || (!!errors.childLotsetShareComTwoUp && touched.childLotsetShareComTwoUp)
      || (!!errors.childLotsetShareComRunUp && touched.childLotsetShareComRunUp)
      || (!!errors.childLotsetShareComRunDown && touched.childLotsetShareComRunDown)
      
      || (!!errors.childLotsetBetMinThreeUp && touched.childLotsetBetMinThreeUp)
      || (!!errors.childLotsetBetMinThreeToast && touched.childLotsetBetMinThreeToast)
      || (!!errors.childLotsetBetMinTwoDown && touched.childLotsetBetMinTwoDown)
      || (!!errors.childLotsetBetMinTwoUp && touched.childLotsetBetMinTwoUp)
      || (!!errors.childLotsetBetMinRunUp && touched.childLotsetBetMinRunUp)
      || (!!errors.childLotsetBetMinRunDown && touched.childLotsetBetMinRunDown)
      || (!!errors.childLotsetBetMaxThreeUp && touched.childLotsetBetMaxThreeUp)
      || (!!errors.childLotsetBetMaxThreeToast && touched.childLotsetBetMaxThreeToast)
      || (!!errors.childLotsetBetMaxTwoDown && touched.childLotsetBetMaxTwoDown)
      || (!!errors.childLotsetBetMaxTwoUp && touched.childLotsetBetMaxTwoUp)
      || (!!errors.childLotsetBetMaxRunUp && touched.childLotsetBetMaxRunUp)
      || (!!errors.childLotsetBetMaxRunDown && touched.childLotsetBetMaxRunDown)
      || (!!errors.childLotsetBetRateThreeUp && touched.childLotsetBetRateThreeUp)
      || (!!errors.childLotsetBetRateThreeToast && touched.childLotsetBetRateThreeToast)
      || (!!errors.childLotsetBetRateTwoUp && touched.childLotsetBetRateTwoUp)
      || (!!errors.childLotsetBetRateTwoDown && touched.childLotsetBetRateTwoDown)
      || (!!errors.childLotsetBetRateRunUp && touched.childLotsetBetRateRunUp)
      || (!!errors.childLotsetBetRateRunDown && touched.childLotsetBetRateRunDown)
      || (!!errors.betMaxLimit && touched.betMaxLimit)
      || (!!errors.betMinLimit && touched.betMinLimit)
      || (!!errors.com && touched.com)
      || (!!errors.creditLimit && touched.creditLimit)
    )
  }

  saveLotterySetting = () => {
    const { setFieldValue } = this.props;

    if (this.checkSettingLotto()) {
      ErrorModal.show({
        action: () => { ErrorModal.hide() },
        description: constants.lotterySettingParameterIncomplete,
      });
      this.handleScrollToTop();
    } else {
      const selectedLottoIndex = findIndex(
        this.props.lotterySetting, (item) => item.childLotsetName === this.state.selectedLotto[0]?.childLotsetName
      );
      const clonedSelectedLotto = cloneDeep(this.state.selectedLotto);
      const clonedLotterySetting = cloneDeep(this.state.lotterySettingState);
      clonedLotterySetting[selectedLottoIndex] = clonedSelectedLotto[0];

      SuccessModal.show({
        title: constants.editLotterySuccessful,
        action: () => SuccessModal.hide(),
        description: constants.saveLotterySettingSuccessful,
      });

      const differentLotterySetting = differenceWith(clonedLotterySetting, this.state.nodeLotterySettingState, isEqual);

      let indexes: number[] = [];
      clonedLotterySetting.forEach((item, index) => {
        if (differentLotterySetting.includes(item)) {
          indexes.push(index)
        }
      });
      indexes.forEach((item) => {
        clonedLotterySetting[item].isSaved = true;
        clonedLotterySetting[item].isNotSavedYet = false;
      });

      this.setState(
        { selectedLotto: [clonedLotterySetting[selectedLottoIndex]] },
        () => {
          this.setState({
            lotterySettingState: clonedLotterySetting,
            nodeLotterySettingState: cloneDeep(clonedLotterySetting)
          }, () => {
            setFieldValue("lotterySetting", clonedLotterySetting);
            localStorage.setItem("lotterySettingState", JSON.stringify(clonedLotterySetting));
          });
        }
      );
    }
  };

  handleBetMaxLotto = (selectedLottoIndex: number, item: TLottoGameType, values: any) => {
    if (this.state.nodeBetMaxLimit !== values.betMaxLimit) {
      return Number(this.state.nodeBetMaxLimit)
    } else {
      if (values.betMaxLimit > this.props.meSettingData.setBetMaxLimit || Number(this.state.nodeBetMaxLimit) > this.props.meSettingData.setBetMaxLimit) {
        return this.props.meSettingData.setBetMaxLimit
      } else {
        return values.betMaxLimit
      }
    }
  }

  labelTooltip = (labelTxt: string, LabelTooltip: string) => {
    return <div style={{ display: "flex", marginBottom: '5px', alignItems: "center", width: '100%', justifyContent: 'space-between' }}>
      <label className="label-input">
        {labelTxt}
      </label>
      <Tooltip
        overlay={
          <div
            style={{
              wordWrap: "break-word",
              maxWidth: "250px",
              opacity: 0.9,
              backgroundColor: "fff",
              color: colors.PRIMARY_TEXT,
            }}
          >
            {LabelTooltip}
          </div>
        }
        arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
      >
        <img
          alt="question-mark"
          src={questionMark}
          className="question-mark-icon"
        />
      </Tooltip>
    </div>
  }

  renderCodeToTypeNameSetting = () => {
    const { setFieldValue, handleBlur, values, errors, touched, setFieldTouched } = this.props;
    const { childLotsetName } = this.state.selectedLotto[0];

    const selectedLottoIndex = findIndex(
      this.props.lotterySetting,
      (item) =>
        item.childLotsetName === this.state.selectedLotto[0]?.childLotsetName
    );

    return LOTTO_GAME_TYPES[
      `LOTTER_${childLotsetName.includes("YEGEE") ? "YEGEE" : childLotsetName
      }` as TLottoSlug
    ].map((item, index) => {
      return (
        <React.Fragment key={index}>
          <div className="col-12" style={{ marginTop: 6 }}>
            <div className="d-flex flex-row align-items-start justify-content-between h-100">
              <div
                style={{ width: "5%" }}
                className="h-100 d-flex align-items-center"
              >
                <label className="label-input">
                  {CODE_TO_TYPENAME_NUMBER[item]}
                </label>
              </div>
              <div style={{ width: "17%" }}>
                {this.labelTooltip(constants.payRatePercentage, constants.tooltipPayRate(CODE_TO_TYPENAME_NUMBER[item]))}
                <div className="d-flex flex-row">
                  <div style={{ width: "100%" }}>
                    <InputNumber
                      id={`childLotsetBetRate${pascalCase(item)}`}
                      name={`childLotsetBetRate${pascalCase(item)}`}
                      value={
                        (values as any)[`childLotsetBetRate${pascalCase(item)}`]
                      }
                      isNumericString
                      thousandSeparator
                      allowNegative={false}
                      onBlur={handleBlur}
                      onValueChange={(values) => {
                        setFieldValue(
                          `childLotsetBetRate${pascalCase(item)}`,
                          values.value
                        );
                        if (this.props.mode === "CREATE") {
                          const clonedSelectedLotto = cloneDeep(
                            this.state.selectedLotto
                          );
                          (clonedSelectedLotto[0] as any)[
                            `childLotsetBetRate${pascalCase(item)}`
                          ] = Number(values.value);
                          this.setState({ selectedLotto: clonedSelectedLotto });
                        }
                      }}
                      backgroundColor={colors.TRANSPARENT}
                      inputClassName="payRatePercentage"
                      disabled={
                        !this.state.isEditable
                      }
                      decimalScale={2}
                      renderError={false}
                      mode="InputSplit"
                      endText={
                        this.props.meData?.permission === "OWNER" &&
                          window.location.pathname === routes.userDetail.path
                          ? ""
                          : get(
                            this.props.meSettingLotteryData.lotterySetting[
                            selectedLottoIndex
                            ],
                            `[childLotsetBetRate${pascalCase(item)}]`,
                            0
                          )

                      }
                    />
                  </div>
                </div>
                <h6 className="primary-red-text m1-t">
                  {!!(errors as any)[`childLotsetBetRate${pascalCase(item)}`] &&
                    (touched as any)[`childLotsetBetRate${pascalCase(item)}`]
                    ? (errors as any)[
                      `childLotsetBetRate${pascalCase(item)}`
                    ] !== "error"
                      ? (errors as any)[`childLotsetBetRate${pascalCase(item)}`]
                      : constants.betRateLimitError(
                        get(
                          this.props.meSettingLotteryData.lotterySetting[
                          selectedLottoIndex
                          ],
                          `[childLotsetBetRate${pascalCase(item)}]`,
                          0
                        )
                      )
                    : ""}
                </h6>
              </div>
              <div style={{ width: "17%" }}>
                {this.labelTooltip(constants.discountPercentage, constants.tooltipDiscount)}
                <InputNumber
                  id={`childLotsetDis${pascalCase(item)}`}
                  name={`childLotsetDis${pascalCase(item)}`}
                  value={(values as any)[`childLotsetDis${pascalCase(item)}`]}
                  decimalScale={2}
                  allowNegative={false}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value === '') {
                      setFieldValue(
                        `childLotsetDis${pascalCase(item)}`,
                        Number(0)
                      );
                    }
                    handleBlur(e)
                  }}
                  onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value === '0') {
                      setFieldValue(
                        `childLotsetDis${pascalCase(item)}`,
                        ''
                      );
                    }
                  }}
                  disabled={!this.state.isEditable}
                  placeholder={constants.discountPercentagePlaceholder}
                  onValueChange={(values) => {
                    if (values.value === '') {
                      setFieldValue(`childLotsetDis${pascalCase(item)}`, '');
                    }
                    else {
                      setFieldValue(`childLotsetDis${pascalCase(item)}`,
                        Number(values.value));
                    }

                    if (this.props.mode === "CREATE") {
                      const clonedSelectedLotto = cloneDeep(
                        this.state.selectedLotto
                      );
                      (clonedSelectedLotto[0] as any)[
                        `childLotsetDis${pascalCase(item)}`
                      ] = Number(values.value);
                      this.setState({ selectedLotto: clonedSelectedLotto });
                    }
                  }}
                  backgroundColor={colors.TRANSPARENT}
                  inputClassName="input-otp"
                  renderError={false}
                  isAllowed={({ floatValue }) =>
                    floatValue ? floatValue <= 100 : true
                  }
                  error={
                    !!(errors as any)[`childLotsetDis${pascalCase(item)}`] &&
                    (touched as any)[`childLotsetDis${pascalCase(item)}`]
                  }
                  mode="InputSplit"
                  endText="%"
                />
                <h6 className="primary-red-text m1-t">
                  {!!(errors as any)[`childLotsetDis${pascalCase(item)}`] &&
                    (touched as any)[`childLotsetDis${pascalCase(item)}`]
                    ? (errors as any)[`childLotsetDis${pascalCase(item)}`]
                    : ""}
                </h6>
              </div>
                  
              <div style={{ width: "17%" }}>
                {this.labelTooltip(constants.labelNatcommission, constants.tooltipCommission)}
                <InputNumber
                  id={`childLotsetShareCom${pascalCase(item)}`}
                  name={`childLotsetShareCom${pascalCase(item)}`}
                  value={(values as any)[`childLotsetShareCom${pascalCase(item)}`]}
                  decimalScale={2}
                  allowNegative={false}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value === '') {
                      setFieldValue(
                        `childLotsetShareCom${pascalCase(item)}`,
                        Number(0)
                      );
                    }
                    handleBlur(e)
                  }}
                  onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value === '0') {
                      setFieldValue(
                        `childLotsetShareCom${pascalCase(item)}`,
                        ''
                      );
                    }
                  }}
                  disabled={!this.state.isEditable}
                  placeholder={constants.discountPercentagePlaceholder}
                  onValueChange={(values) => {
                    if (values.value === '') {
                      setFieldValue(`childLotsetShareCom${pascalCase(item)}`, '');
                    }
                    else {
                      setFieldValue(`childLotsetShareCom${pascalCase(item)}`,
                        Number(values.value));
                    }

                    if (this.props.mode === "CREATE") {
                      const clonedSelectedLotto = cloneDeep(
                        this.state.selectedLotto
                      );
                      (clonedSelectedLotto[0] as any)[
                        `childLotsetShareCom${pascalCase(item)}`
                      ] = Number(values.value);
                      this.setState({ selectedLotto: clonedSelectedLotto });
                    }
                  }}
                  backgroundColor={colors.TRANSPARENT}
                  renderError={false}
                  isAllowed={({ floatValue }) =>
                    floatValue ? floatValue <= 100 : true
                  }
                  error={
                    !!(errors as any)[`childLotsetShareCom${pascalCase(item)}`] &&
                    (touched as any)[`childLotsetShareCom${pascalCase(item)}`]
                  }
                  mode="InputSplit"
                  endText="%"
                />
                <h6 className="primary-red-text m1-t">
                  {!!(errors as any)[`childLotsetShareCom${pascalCase(item)}`] &&
                    (touched as any)[`childLotsetShareCom${pascalCase(item)}`]
                    ? (errors as any)[`childLotsetShareCom${pascalCase(item)}`]
                    : ""}
                </h6>
              </div>

              <div style={{ width: "17%" }}>
                {this.labelTooltip(constants.minBet, constants.tooltipMinLimit)}
                <div className="d-flex flex-row">
                  <div
                    className={`childLotsetBetMin${pascalCase(
                      item
                    )}Wrapper w-100`}
                  >
                    <InputNumber
                      id={`minBet-${index}`}
                      name={`childLotsetBetMin${pascalCase(item)}`}
                      value={
                        (values as any)[`childLotsetBetMin${pascalCase(item)}`]
                      }
                      thousandSeparator
                      allowNegative={false}
                      onBlur={handleBlur}
                      onValueChange={(values) => {
                        setFieldValue(
                          `childLotsetBetMin${pascalCase(item)}`,
                          values.value
                        );
                        const clonedSelectedLotto = cloneDeep(
                          this.state.selectedLotto
                        );
                        (clonedSelectedLotto[0] as any)[
                          `childLotsetBetMin${pascalCase(item)}`
                        ] = Number(values.value);
                        this.setState({ selectedLotto: clonedSelectedLotto });
                      }}
                      backgroundColor={colors.TRANSPARENT}
                      inputClassName="minBet"
                      disabled={
                        !this.state.isEditable
                      }
                      decimalScale={2}
                      renderError={false}
                      mode="InputSplit"
                      endText={!isEmpty(this.props.lotterySetting) ? (values as any)[`childLotsetBetMax${pascalCase(item)}`] : ""}
                      error={
                        !!(errors as any)[
                        `childLotsetBetMin${pascalCase(item)}`
                        ] &&
                        (touched as any)[`childLotsetBetMin${pascalCase(item)}`]
                      }
                    />
                  </div>
                </div>
                {!!(errors as any)[`childLotsetBetMin${pascalCase(item)}`] &&
                  (touched as any)[`childLotsetBetMin${pascalCase(item)}`] ? (
                  <h6 className="primary-red-text m1-t">
                    {(errors as any)[`childLotsetBetMin${pascalCase(item)}`] !==
                      "error"
                      ? (errors as any)[`childLotsetBetMin${pascalCase(item)}`]
                      : `แทงต่ำสุดต้องไม่ต่ำกว่า ${number.addComma(
                        (values as any)[
                        `betMinLimit`
                        ]
                      )} บาท และไม่มากกว่า ${number.addComma(
                        (values as any)[
                        `childLotsetBetMax${pascalCase(item)}`
                        ]
                      )} บาท`}
                  </h6>
                ) : (
                  <h6 className="primary-red-text m1-t">&nbsp;</h6>
                )}
              </div>
              <div style={{ width: "17%" }}>
                {this.labelTooltip(constants.maxBet, constants.tooltipMaxLimit)}
                <div className="d-flex flex-row">
                  <div
                    className={`childLotsetBetMax${pascalCase(
                      item
                    )}Wrapper w-100`}
                  >
                    <InputNumber
                      id={`maxBet-${index}`}
                      name={`childLotsetBetMax${pascalCase(item)}`}
                      value={(values as any)[`childLotsetBetMax${pascalCase(item)}`]}
                      allowNegative={false}
                      onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const clonedSelectedLotto = cloneDeep(this.state.selectedLotto);
                        const max = (values as any)[`childLotsetBetMax${pascalCase(item)}`]
                        if (Number(max) < Number((this.props.values as any)[`childLotsetBetMin${pascalCase(item)}`])) {
                          setFieldValue(`childLotsetBetMin${pascalCase(item)}`, max);
                          (clonedSelectedLotto[0] as any)[`childLotsetBetMin${pascalCase(item)}`] = Number(max);
                          setFieldTouched(`childLotsetBetMin${pascalCase(item)}`, true)
                        }
                        handleBlur(event)
                      }}
                      thousandSeparator
                      onValueChange={(values) => {
                        setFieldValue(
                          `childLotsetBetMax${pascalCase(item)}`, values.value
                        );
                        const clonedSelectedLotto = cloneDeep(
                          this.state.selectedLotto
                        );
                        (clonedSelectedLotto[0] as any)[
                          `childLotsetBetMax${pascalCase(item)}`
                        ] = Number(values.value);
                        this.setState({ selectedLotto: clonedSelectedLotto });
                      }}
                      backgroundColor={colors.TRANSPARENT}
                      inputClassName="minBet"
                      disabled={
                        !this.state.isEditable
                      }
                      decimalScale={2}
                      renderError={false}
                      mode="InputSplit"
                      endText={this.handleBetMaxLotto(selectedLottoIndex, item, values)}
                      error={
                        !!(errors as any)[
                        `childLotsetBetMax${pascalCase(item)}`
                        ] &&
                        (touched as any)[`childLotsetBetMax${pascalCase(item)}`]
                      }
                    />
                  </div>
                </div>
                {!!(errors as any)[`childLotsetBetMax${pascalCase(item)}`] &&
                  (touched as any)[`childLotsetBetMax${pascalCase(item)}`] ? (
                  <h6 className="primary-red-text m1-t">
                    {(errors as any)[`childLotsetBetMax${pascalCase(item)}`] !==
                      "error"
                      ? (errors as any)[`childLotsetBetMax${pascalCase(item)}`]
                      : `แทงสูงสุดต้องไม่มากกว่า ${number.addComma((values as any)['betMaxLimit'] < this.props.meSettingData.setBetMaxLimit
                        ? (values as any)['betMaxLimit']
                        : this.props.meSettingData.setBetMaxLimit
                      )} บาท`}
                  </h6>
                ) : (
                  <h6 className="primary-red-text m1-t">&nbsp;</h6>
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    });
  };

  formatBankTypeSelect(): IOptions[] {
    let formatBankTypeSelect: any[] = [];
    forOwn(BANK_TYPE, function (values, keys) {
      const ImageIcon = get(ImageBankSet, `${keys}.Icon`, "");
      formatBankTypeSelect.push({
        label: (
          <div className="d-flex flex-row">
            {!isEmpty(ImageIcon) && (
              <img src={ImageIcon} alt="bank" className="bank-image" />
            )}
            <div>{values}</div>
          </div>
        ),
        value: keys,
        disabled: false,
        searchableName: `${keys} ${values}`,
      });
    });
    return formatBankTypeSelect;
  }

  renderFinanceInfoTitle = () => {
    return (
      <div className="d-flex flex-row">
        <div className="d-flex flex-column">
          <div className="inline pr-3 paper-body-title">
            {constants.titleFinanceInfo}
          </div>
        </div>
      </div>
    );
  };

  handleMinBet = () => {
    this.props.setTouched({
      childLotsetBetMinThreeUp: false,
      childLotsetBetMinThreeToast: false,
      childLotsetBetMinTwoUp: false,
      childLotsetBetMinTwoDown: false,
      childLotsetBetMinRunUp: false,
      childLotsetBetMinRunDown: false,
    })

    const clonedLotterySetting = this.loopSetMinLimit(this.state.nodeLotterySettingState);
    let indexSettingState = findIndex(clonedLotterySetting, (item: any) => item.childLotsetName === this.state.selectedLotto[0]?.childLotsetName);
    this.setState({
      nodeBetMinLimit: this.props.values.betMinLimit,
      nodeLotterySettingState: cloneDeep(clonedLotterySetting),
      lotterySettingState: cloneDeep(this.state.lotterySettingState).map((i, index) => {
        return {
          ...i,
          //เช็คกรณีแก้ min แล้วไปแก้ ต่ำสุดข้างบน ไม่ควรขึ้น ยังไม่มันทึก
          isNotSavedYet: false,
          childLotsetBetMinThreeUp: clonedLotterySetting[index].childLotsetBetMinThreeUp,
          childLotsetBetMinThreeToast: clonedLotterySetting[index].childLotsetBetMinThreeToast,
          childLotsetBetMinTwoUp: clonedLotterySetting[index].childLotsetBetMinTwoUp,
          childLotsetBetMinTwoDown: clonedLotterySetting[index].childLotsetBetMinTwoDown,
          childLotsetBetMinRunUp: clonedLotterySetting[index].childLotsetBetMinRunUp,
          childLotsetBetMinRunDown: clonedLotterySetting[index].childLotsetBetMinRunDown,

          childLotsetBetMaxThreeUp: clonedLotterySetting[index].childLotsetBetMinThreeUp > i.childLotsetBetMaxThreeUp ? clonedLotterySetting[index].childLotsetBetMinThreeUp : i.childLotsetBetMaxThreeUp,
          childLotsetBetMaxThreeToast: clonedLotterySetting[index].childLotsetBetMinThreeToast > i.childLotsetBetMaxThreeToast ? clonedLotterySetting[index].childLotsetBetMinThreeToast : i.childLotsetBetMaxThreeToast,
          childLotsetBetMaxTwoUp: clonedLotterySetting[index].childLotsetBetMinTwoUp > i.childLotsetBetMaxTwoUp ? clonedLotterySetting[index].childLotsetBetMinTwoUp : i.childLotsetBetMaxTwoUp,
          childLotsetBetMaxTwoDown: clonedLotterySetting[index].childLotsetBetMinTwoDown > i.childLotsetBetMaxTwoDown ? clonedLotterySetting[index].childLotsetBetMinTwoDown : i.childLotsetBetMaxTwoDown,
          childLotsetBetMaxRunUp: clonedLotterySetting[index].childLotsetBetMinRunUp > i.childLotsetBetMaxRunUp ? clonedLotterySetting[index].childLotsetBetMinRunUp : i.childLotsetBetMaxRunUp,
          childLotsetBetMaxRunDown: clonedLotterySetting[index].childLotsetBetMinRunDown > i.childLotsetBetMaxRunDown ? clonedLotterySetting[index].childLotsetBetMinRunDown : i.childLotsetBetMaxRunDown,
        }
      })
    }, () => {
      this.setState({ selectedLotto: [this.state.lotterySettingState[indexSettingState]] })
      this.afterClickLotteryCard(indexSettingState, this.state.lotterySettingState[indexSettingState], false)
      this.props.setFieldValue("lotterySetting", clonedLotterySetting);
      localStorage.setItem("lotterySettingState", JSON.stringify(clonedLotterySetting));
      localStorage.setItem("betMaxMinLimit", JSON.stringify({ min: this.state.nodeBetMinLimit, max: this.state.nodeBetMaxLimit }));
    })
  }

  handleMaxBet = () => {
    //ดันค่า min ลงตาม max
    if (Number(this.props.values.betMinLimit) > Number(this.props.values.betMaxLimit)) {
      this.props.setFieldValue("betMinLimit", this.props.values.betMaxLimit);
      this.setState({ nodeBetMinLimit: this.props.values.betMaxLimit })
    }
    const clonedLotterySetting = this.loopSetMaxLimit(this.state.nodeLotterySettingState);
    let indexSettingState = findIndex(clonedLotterySetting, (item: any) => item.childLotsetName === this.state.selectedLotto[0]?.childLotsetName);

    this.setState({
      nodeBetMaxLimit: this.props.values.betMaxLimit,
      nodeLotterySettingState: cloneDeep(clonedLotterySetting),
      lotterySettingState: (this.state.lotterySettingState).map((i, index) => {
        return {
          ...i,
          childLotsetBetMaxThreeUp: clonedLotterySetting[index].childLotsetBetMaxThreeUp,
          childLotsetBetMaxThreeToast: clonedLotterySetting[index].childLotsetBetMaxThreeToast,
          childLotsetBetMaxTwoUp: clonedLotterySetting[index].childLotsetBetMaxTwoUp,
          childLotsetBetMaxTwoDown: clonedLotterySetting[index].childLotsetBetMaxTwoDown,
          childLotsetBetMaxRunUp: clonedLotterySetting[index].childLotsetBetMaxRunUp,
          childLotsetBetMaxRunDown: clonedLotterySetting[index].childLotsetBetMaxRunDown,

          childLotsetBetMinThreeUp: i.childLotsetBetMinThreeUp > clonedLotterySetting[index].childLotsetBetMaxThreeUp ? clonedLotterySetting[index].childLotsetBetMaxThreeUp : i.childLotsetBetMinThreeUp,
          childLotsetBetMinThreeToast: i.childLotsetBetMinThreeToast > clonedLotterySetting[index].childLotsetBetMaxThreeToast ? clonedLotterySetting[index].childLotsetBetMaxThreeToast : i.childLotsetBetMinThreeToast,
          childLotsetBetMinTwoUp: i.childLotsetBetMinTwoUp > clonedLotterySetting[index].childLotsetBetMaxTwoUp ? clonedLotterySetting[index].childLotsetBetMaxTwoUp : i.childLotsetBetMinTwoUp,
          childLotsetBetMinTwoDown: i.childLotsetBetMinTwoDown > clonedLotterySetting[index].childLotsetBetMaxTwoDown ? clonedLotterySetting[index].childLotsetBetMaxTwoDown : i.childLotsetBetMinTwoDown,
          childLotsetBetMinRunUp: i.childLotsetBetMinRunUp > clonedLotterySetting[index].childLotsetBetMaxRunUp ? clonedLotterySetting[index].childLotsetBetMaxRunUp : i.childLotsetBetMinRunUp,
          childLotsetBetMinRunDown: i.childLotsetBetMinRunDown > clonedLotterySetting[index].childLotsetBetMaxRunDown ? clonedLotterySetting[index].childLotsetBetMaxRunDown : i.childLotsetBetMinRunDown,
        }
      })
    }, () => {
      this.setState({ selectedLotto: [this.state.lotterySettingState[indexSettingState]] })
      this.afterClickLotteryCard(indexSettingState, this.state.lotterySettingState[indexSettingState], false)
      this.props.setFieldValue("lotterySetting", clonedLotterySetting);
      localStorage.setItem("lotterySettingState", JSON.stringify(clonedLotterySetting));
      localStorage.setItem("betMaxMinLimit", JSON.stringify({ min: this.state.nodeBetMinLimit, max: this.state.nodeBetMaxLimit }));
    })
  }

  listNameChildOption = () => this.state.listChildCopySetting?.map((item) => {
    return { label: item.childName, value: item.childAgentId, disabled: false }
  })

  render() {
    const RenderLotteryList = this.renderLotteryList().filter(
      (Lotto) => {
        return Lotto.props.children[0].props.children[1].props.children.toLowerCase().indexOf(
          this.state.search!.toLowerCase()) !== -1
      }
    )
    const RenderLotteryConfig = this.renderLotteryConfig();

    const {
      values,
      handleBlur,
      errors,
      touched,
      setFieldValue,
      validateField,
      meSettingData,
      // isOtpClicked,
    } = this.props;

    const bankTypeIndex = findIndex(
      this.formatBankTypeSelect(),
      (item: IOptions) => item.value === values.bankType
    );
    const FinanceInfoTitle = this.renderFinanceInfoTitle;

    return (<>
      <div className="box-shadow finance-info-container-create">
        <FinanceInfoTitle />
        <div className="row">
          <div className="col-4" style={{ marginTop: 6 }}>
            <label className="label-input">{constants.bankName}</label>
            <Input
              id="bankName"
              name="bankName"
              type="text"
              placeholder={constants.bankNamePlaceholder}
              value={values.bankName}
              onBlur={handleBlur}
              onChange={(e) => {
                if (!e.target.value.match(ENG_THAI_AND_SPACE)) {
                  setFieldValue("bankName", e.target.value);
                }
              }}
              errorMessage={errors.bankName}
              error={!!errors.bankName && touched.bankName}
              inputClassName="input-otp"
              backgroundColor={color.TRANSPARENT}
            />
          </div>
          <div className="col-4" style={{ marginTop: 6 }}>
            <label className="label-input">{constants.bank}</label>
            <Select
              styles={createMemberDropdownStyles(
                this.props.mode === "UPDATE"
                && !this.state.isEditable
              )}
              components={{
                IndicatorSeparator: () => null,
              }}
              placeholder={constants.bankPlaceholder}
              name="bankType"
              options={this.formatBankTypeSelect()}
              value={this.formatBankTypeSelect()[bankTypeIndex]}
              onChange={(e) => {
                if (values.bankType === "GSB" && e?.value !== "GSB") {
                  const formattedBankNumber = values.bankNumber.substring(
                    0,
                    values.bankNumber.length - 2
                  );
                  setFieldValue("bankNumber", formattedBankNumber);
                  setTimeout(() => {
                    validateField("bankNumber");
                  }, 256);
                }
                setFieldValue("bankType", e?.value);
                this.setState({ isBankTypeSelected: true });
              }}
              getOptionValue={(option) => get(option, "searchableName", "")}
              noOptionsMessage={() => constants.noInfo}
            />
            {!!errors.bankType && touched.bankType && (
              <h6 className="primary-red-text" style={{ marginTop: 6 }}>
                {errors.bankType}
              </h6>
            )}
          </div>
          <div className="col-4" style={{ marginTop: 6 }}>
            <label className="label-input">{constants.bankNumber}</label>
            <InputNumber
              allowNegative={false}
              decimalScale={0}
              id="bankNumber"
              name="bankNumber"
              type="text"
              placeholder={constants.bankNumberPlaceholder}
              value={values.bankNumber}
              onBlur={handleBlur}
              onChange={(e) => {
                setFieldValue(
                  "bankNumber",
                  e.target.value.replaceAll(" ", "")
                );
              }}
              errorMessage={errors.bankNumber}
              error={!!errors.bankNumber && touched.bankNumber}
              inputClassName="input-otp"
              format={
                values.bankType === "BAAC" || values.bankType === "GSB"
                  ? "# ### #### ####"
                  : "### #### ###"
              }
              backgroundColor={color.TRANSPARENT}
              disabled={!this.state.isBankTypeSelected}
            />
          </div>
        </div>
      </div>

      <div className="box-shadow new-lottobet-container-create mt-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column">
            <div
              className="inline pr-3 paper-body-title"
              ref={this.userContainerRef}
            >
              <div className="d-flex align-items-center">
                <div className="headText">{constants.betSetting}</div>
                {this.props.meSettingLotteryIsFetching && (<div className="m1-l"><LoadingCircle /> </div>)}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-7 mt-4">
            <label className="label-input">{constants.lotteryBetConfig}</label>
            <div style={{ width: "96.5%" }}>
              <Select
                value={this.state.idChildSelect !== -1 ? {
                  label: this.listNameChildOption()?.find((item) => this.state.idChildSelect === item.value)?.label || constants.userPlaceholder,
                  value: -1, disabled: false
                } : null}
                options={this.listNameChildOption()}
                styles={createMemberDropdownStyles(!this.state.isEditable)}
                components={{ IndicatorSeparator: () => null }}
                placeholder={constants.userPlaceholder}
                name="user"
                noOptionsMessage={() => constants.noInfo}
                isDisabled={!this.state.isEditable}
                isClearable={this.state.idChildSelect !== -1}
                onChange={(val) => {
                  // ยกเลิกคัดลอก
                  if (isNull(val) || isEmpty(val)) {
                    ConfirmModal.show({
                      action: () => {
                        this.setState({ isCopySetting: false, idChildSelect: -1 })
                        this.resetLotterySetting();
                        ConfirmModal.hide();
                      },
                      cancelAction: () => {
                        ConfirmModal.hide();
                      },
                      description: `ต้องการยกเลิกคัดลอกข้อมูลใช่หรือไม่`,
                    });
                  } else {
                    ConfirmModal.show({
                      action: () => {
                        this.setState({ idChildSelect: val.value })
                        this.props.onChangeIsLoaddingAction!(true);
                        Promise.all([
                          fetchChildSetting({ childAgentId: Number(val?.value) }),
                          fetchChildSettingLottery({ childAgentId: Number(val?.value) })
                        ])
                          .then((res) => {
                            this.props.setTouched({});
                            this.props.setErrors({});
                            this.setState({
                              dataCopySetting: {
                                ...res[0].data.data,
                                ...res[1].data.data
                              }
                            }, () => {
                              this.setCopyChildLotset(get(this.state.dataCopySetting, "lotterySetting", []))
                            }
                            )
                          })
                          .catch(() => {
                            ErrorModal.show({
                              description: "คัดลอกข้อมูลจากผู้ใช้ไม่สำเร็จ",
                              action: () => ErrorModal.hide()
                            })
                          })
                          .finally(() => {
                            this.props.onChangeIsLoaddingAction!(false);
                          })
                        ConfirmModal.hide();
                      },
                      cancelAction: () => {
                        ConfirmModal.hide();
                      },
                      description: `คัดลอกข้อมูลจาก ${val?.label}`,
                    });
                  }
                }}
              />
            </div>
          </div>
          <div className="col-5 mt-4"></div>
        </div>

        <div className="row mt-4">
          <div className="col-6">
            <label className="label-input">{constants.limitCredit}</label>
            <div className="d-flex flex-row">
              <div className="creditLimitWrapper w-100">
                <InputNumber
                  onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value === '0')
                      setFieldValue("creditLimit", '');
                  }}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value === '')
                      setFieldValue("creditLimit", '0');
                    handleBlur(e)
                  }}
                  id="creditLimit"
                  name="creditLimit"
                  placeholder={""}
                  value={String(values.creditLimit)}
                  onValueChange={(values) => {

                    setFieldValue("creditLimit", values.value);
                  }}
                  inputClassName="input-limit-credit"
                  backgroundColor={color.TRANSPARENT}
                  allowNegative={false}
                  decimalScale={2}
                  allowLeadingZeros={false}
                  thousandSeparator={true}
                  renderError={false}
                  mode="InputElements"
                  endText={constants.limitCreditPlaceholder(
                    this.props.meData!.credit
                  )}
                  error={!!errors.creditLimit && touched.creditLimit}
                />
              </div>
            </div>
            <h6 className="primary-red-text mt-1">
              {!!errors.creditLimit && touched.creditLimit
                ? errors.creditLimit
                : "\u00A0"}
            </h6>
          </div>
        </div>

        <div className="row">
          <div className="col-4" style={{ marginTop: 6 }}>
            <label className="label-input">{constants.minBet + ' *'}
              <span className="label-input-note">
                {` (ไม่ต่ำกว่า ${number.addComma(this.props.meSettingData.setBetMinLimit)} บาท)`}
              </span>
            </label>


            <div className="d-flex flex-row">
              <div className="betMinLimitWrapper w-100">
                <InputNumber
                  id="betMinLimit"
                  name="betMinLimit"
                  placeholder={constants.betMinPlaceholder}
                  value={
                    values.betMinLimit === 0 ? "" : String(values.betMinLimit)
                  }
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (Number(this.props.values.betMinLimit) !== Number(this.state.nodeBetMinLimit)) {

                      //เช็คถ้าน้อยกว่า api ให้กลับไปค่า api
                      if (Number(this.props.meSettingData.setBetMinLimit) > Number(this.props.values.betMinLimit)) {
                        setFieldValue("betMinLimit", Number(meSettingData.setBetMinLimit));
                      }

                      //เช็คถ้ามากกว่า max ต้องไปเกิน max
                      if (Number(this.props.values.betMinLimit) > Number(this.props.values.betMaxLimit)) {
                        this.props.setFieldValue("betMinLimit", this.props.values.betMaxLimit);
                      }
                      ConfirmModal.show({
                        action: () => {
                          this.handleMinBet()
                          ConfirmModal.hide();
                        },
                        cancelAction: () => {
                          setFieldValue("betMinLimit", this.state.nodeBetMinLimit);
                          ConfirmModal.hide();
                        },
                        description: "การตั้งค่า จำกัดแทงต่ำสุด จะถูกเปลี่ยนทุกหวย",
                      });
                    }
                    handleBlur(e)
                  }}
                  onValueChange={(values) => {
                    setFieldValue("betMinLimit", values.value);
                  }}
                  allowLeadingZeros={false}
                  allowNegative={false}
                  thousandSeparator
                  inputClassName="input-limit-credit"
                  backgroundColor={color.TRANSPARENT}
                  decimalScale={2}
                  renderError={false}
                  mode="InputElements"
                  endText={constants.minBetPlaceholder(values.betMaxLimit)}
                  error={!!errors.betMinLimit && touched.betMinLimit}
                />
              </div>
            </div>
            <h6 className="primary-red-text mt-1">
              {!!errors.betMinLimit && touched.betMinLimit
                ? errors.betMinLimit
                : "\u00A0"}
            </h6>
          </div>
          <div className="col-4" style={{ marginTop: 6 }}>
            <label className="label-input">{constants.maxBet + ' *'}</label>
            <div className="d-flex flex-row">
              <div className="betMaxLimitWrapper w-100">
                <InputNumber
                  id="betMaxLimit"
                  name="betMaxLimit"
                  placeholder={constants.betMaxPlaceholder}
                  allowLeadingZeros={false}
                  allowNegative={false}
                  value={
                    values.betMaxLimit === 0 ? "" : String(values.betMaxLimit)
                  }
                  // onBlur={handleBlur}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (Number(this.props.values.betMaxLimit) !== Number(this.state.nodeBetMaxLimit)) {

                      //เช็คถ้าน้อยกว่า min api ให้กลับไปค่า api
                      if (Number(this.props.meSettingData.setBetMinLimit) > Number(this.props.values.betMaxLimit)) {
                        setFieldValue("betMaxLimit", Number(meSettingData.setBetMinLimit));
                      }
                      //เช็คถ้ามากกว่า max api ให้กลับไปค่า api
                      if (Number(this.props.meSettingData.setBetMaxLimit) < Number(this.props.values.betMaxLimit)) {
                        setFieldValue("betMaxLimit", Number(this.props.meSettingData.setBetMaxLimit));
                      }

                      ConfirmModal.show({
                        action: () => {
                          this.handleMaxBet()
                          ConfirmModal.hide();
                        },
                        cancelAction: () => {
                          setFieldValue("betMaxLimit", this.state.nodeBetMaxLimit);
                          ConfirmModal.hide();
                        },
                        description: "การตั้งค่า จำกัดแทงสูงสุด จะถูกเปลี่ยนทุกหวย",
                      });
                    }
                    // handleBlur(e)
                  }}
                  onValueChange={(values) => {
                    setFieldValue("betMaxLimit", values.value);
                  }}
                  inputClassName="input-limit-credit"
                  backgroundColor={color.TRANSPARENT}
                  thousandSeparator
                  decimalScale={2}
                  mode="InputElements"
                  renderError={false}
                  endText={constants.maxBetPlaceholder(meSettingData!.setBetMaxLimit)}
                  error={!!errors.betMaxLimit && touched.betMaxLimit}
                />
              </div>
            </div>
            {!!errors.betMaxLimit && touched.betMaxLimit && (
              <h6 className="primary-red-text mt-1">{errors.betMaxLimit}</h6>
            )}
          </div>
          <div className="col-4" style={{ marginTop: 6 }}>
            <label className="label-input">{constants.commission}</label>
            <InputNumber
              onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value === '0')
                  setFieldValue("com", '');
              }}
              allowNegative={false}
              allowLeadingZeros={false}
              id="com"
              name="com"
              type="text"
              placeholder={constants.comPlaceholder}
              value={values.com}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value === '') {
                  setFieldValue("com", '0');
                }
                handleBlur(e)
              }}
              onValueChange={(values) => {
                setFieldValue("com", values.value);
              }}
              isNumericString
              errorMessage={errors.com}
              error={!!errors.com && touched.com}
              inputClassName="input-otp"
              backgroundColor={color.TRANSPARENT}
              isAllowed={({ floatValue }: { floatValue: number | undefined }) =>

                floatValue ? floatValue <= 100 : true
              }
              decimalScale={2}
              // endText={constants.maxComPlaceholder(meSettingData!.setComPercentage)}
              mode="InputElements"
            />
            <h6 className="primary-red-text mt-1">
              {!!errors.com && touched.com ? errors.com : "\u00A0"}
            </h6>
          </div>
        </div>

        <div className="row">
          <div className="col-3 mt-4">
            <div className="lottery-list-search">
              <Input
                bgContainer={colors.PRIMARY_BG}
                borderWidth="1px"
                borderStyle="solid"
                borderColor={"#919EAB"}
                renderError={false}
                backgroundColor={colors.PRIMARY_BG}
                borderRadius="4px"
                inputClassName="input-search border-active:focus"
                setPadding="6px"
                placeholder="ชื่อหวย"
                value={this.state.search}
                id={""}
                name={""}
                onChange={(e) => {
                  this.setState({ search: e.target.value })
                }
                }
              />
              <div
                className="clear-icon-container"
                onClick={() => {
                  this.setState({ search: '' })
                }}
              >
                <img src={Cancel} alt={"undo-icon"} className="undo-icon" />
              </div>
            </div>
            {
              this.props.meSettingLotteryIsFetching && (
                <div className="d-flex justify-content-center">
                  <LoadingCircle />
                </div>
              )
            }
            <div className="lottery-list-container">{RenderLotteryList}</div>
          </div>
          <div className="col-9 mt-4">{RenderLotteryConfig}</div>

        </div>
        {this.state.isEditable && (
          <div className="row m4-t">
            {/* <div className="d-flex flex-row justify-content-end"> */}
            <div className="col">
              <Button
                id="reset-new-lotto-bet-config"
                text={constants.reset}
                textColor={colors.TERTIARY_TEXT}
                textSize={18}
                buttonClass="reset-button"
                onClick={() => {
                  ConfirmModal.show({
                    action: () => {
                      ConfirmModal.hide();
                      if (this.state.isCopySetting && !isEmpty(get(this.state.dataCopySetting, "lotterySetting", []))) {
                        this.setCopyChildLotset(get(this.state.dataCopySetting, "lotterySetting", []));
                      } else {
                        this.resetLotterySetting();
                      }
                    },
                    cancelAction: () => {
                      ConfirmModal.hide();
                    },
                    description: "การตั้งค่าจะถูกรีเซ็ตทุกหวย",
                  });
                }}
              />
            </div>
            <div className="col">
              <Button
                id="save-new-lotto-bet-config"
                text={constants.saveBetSetting}
                textSize={18}
                buttonClass="save-button"
                onClick={() => {
                  this.saveLotterySetting();
                }}
              />
            </div>
            {/* </div> */}
          </div>
        )}
      </div>
    </>);
  }
}
export default NewLottoBetConfigComponent;
