import SettingRateContainer from "./SettingRate.container";
import { connect } from "react-redux";

const mapStateToProps = (state: RootReducers): ISettingRateContainerProps => {
  return {};
};

const mapDispatchToProps: ISettingRateContainerActionProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingRateContainer);
