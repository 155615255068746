import { Breadcrumb, ErrorModal, LottoCard } from "components";
// import colors from 'constants/colors'
import { responseCode, responseMessage } from "constants/response";
import { CODE_TO_NAME, LOTTO_FLAG_ALPHA } from "constants/variables";
// import { addYears, format } from 'date-fns'
// import th from 'date-fns/locale/th'
import { get, includes, map, noop } from "lodash";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
// import { date } from 'utils'
import LottoFlags from "assets/images/global/flags";
import routes from "constants/routes";
import "./NumbersHolesAddLottery.style.scss";
import { fetchAgentHolesLottery } from "reduxs/numbersHoles/services";
import { AxiosError } from "axios";

type DefaultProps = Readonly<typeof defaultProps>;
const constants = {
  mainPageTitle: "ภาพรวม",
  betTitle: "การเดิมพัน",
  resultTitle: "ผลหวย",
  tabelLotto: "ตารางหวย",
  lottoShare: "หวยหุ้น",
  lottoYegee: "หวยยี่กี",
  lottoGeneral: "หวยทั่วไป",

  numberHole: "หลุมเลข",
  addLottery: "เพิ่มหวย",
  chooseLottorforControlHole: "เลือกหวยเพื่อควบคุมด้วยหลุมเลข",
};

const defaultProps: INumbersHolesAddLotteryProps &
  INumbersHolesAddLotteryAction = {
  onChangeIsLoaddingAction() {
    noop();
  }
};

export default class NumbersHolesAddLottery extends Component<
  INumbersHolesAddLotteryProps &
    INumbersHolesAddLotteryAction &
    DefaultProps &
    RouteComponentProps
> {
  static defaultProps = defaultProps;
  state: INumbersHolesAddLotteryState = {
    genLotto: [],
    shareLotto: [],
    yegeeLotto: [],
  };

  componentDidMount() {
    this.getHolesLotteryAgent();
  }

  getHolesLotteryAgent = async () => {
    this.props.onChangeIsLoaddingAction(true)
      await fetchAgentHolesLottery().then(res => {
        const lottoShare = res.data.data.filter(
          (lotto) => lotto.name.includes("SHARE") && !lotto.name.includes("LAO")
        );
        const lottoYegee = res.data.data.filter((lotto) => lotto.name.includes("YEGEE"));
        const lottoGeneral = res.data.data.filter(
          (lotto) =>
            (!lotto.name.includes("YEGEE") && !lotto.name.includes("SHARE")) ||
            lotto.name.includes("LAO")
        );
        this.setState({
          genLotto: lottoGeneral,
          shareLotto: lottoShare,
          yegeeLotto: lottoYegee,
        });
      }).catch ((err: AxiosError<IAPIResponse>) =>{
      if (get(err, 'response.data.code', 0) !== responseCode.OK) {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            get(err, 'response.data.code', 0),
            responseMessage()[0]
          ),
        });
      }
    })
    this.props.onChangeIsLoaddingAction(false)
  };

  renderLottoList = (lottoList: IV2GetHolesLotteryAgent[]) => {
    return map(lottoList, (lotto, index) => {
      const FlagIcon = get(
        LottoFlags[
          LOTTO_FLAG_ALPHA[includes(lotto.name, "YEGEE") ? "YEGEE" : lotto.name]
        ],
        "Icon",
        ""
      );

      const textNameLotto = includes(lotto.name, "YEGEE")
        ? `ยี่กี รอบที่ ${Number(lotto.name.replace("YEGEE_", ""))}`
        : CODE_TO_NAME[lotto.name];

      return (
        <div
          className="col-auto lotto-box"
          key={`lotto-${lotto.name}-${index}`}
        >
          <LottoCard
            slug={("LOTTER_" + lotto.name) as TLottoSlug}
            id={`lotto-${lotto.name}`}
            onClick={() => {
              this.props.history.push(routes.numbersHoles.path, { agentLotsetId:lotto.agentLotsetId,name:lotto.name });
            }}
            title={textNameLotto}
            subtitle={""}
            isOpen={true}
            status={lotto.isHoldAdded ? 'CLOSE' : 'OPEN'}
            icon={FlagIcon}
          />
        </div>
      );
    });
  };

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.mainPageTitle, active: false, path: "/dashboard" },
      { label: constants.numberHole, path: "/numbers-holes" , active: false },
      { label: constants.addLottery, active: true },
    ];
    return (
      <div className="paper-container lotto-result-container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="sub-menu" style={{ width: "fit-content" }}>
              <Breadcrumb
                items={navigates}
                handleOnClickItem={(path: string) =>
                  this.props.history.replace(path)
                }
              />
            </div>
          </div>
        </div>
        <div className="paper-title">{constants.addLottery}</div>
        <div className="paper-description">
          {constants.chooseLottorforControlHole}
        </div>
        <div className="paper-body">
          <div className="box-shadow">
            <div className="max-width-box">
              <div className="row mr-xl-4">
                <div className="col-12">
                  <h4>{constants.lottoGeneral}</h4>
                </div>
                {this.renderLottoList(this.state.genLotto)}
              </div>
              <div className="row m3-t mr-xl-4">
                <div className="col-12">
                  <h4>{constants.lottoShare}</h4>
                </div>
                {this.renderLottoList(this.state.shareLotto)}
              </div>
              <div className="row m3-t mr-xl-4">
                <div className="col-12">
                  <h4>{constants.lottoYegee}</h4>
                </div>
                {this.renderLottoList(this.state.yegeeLotto)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
