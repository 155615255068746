import axios from 'axios'
import environment from '../../constants/environment'

const fetchGetNewsMe = (data: ISearchAgentNewsMe) => {
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.newsme}`
    const params = {
        params: {
            page: data.page,
            limit: data.limit,
            permission: data.permission,
            broadcast_condition: data.broadcastCondition,
            created_at: ""
        }
    }
    return axios.get(endpoint, params)
}

const fetchAddNewsMe = (data: ICreateAgentNewsMe) => {
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.newscreate}`
    const body = {
        message: data.message,
        permission: data.permission,
        broadcastCondition: data.broadcastCondition
    }
    return axios.post(endpoint, body)
}

const fetchUpdateNewsMe = (data: ICreateAgentNewsMe & IUpdateDeleteAgentNewsMe) => {
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.newsupdate}`
    const body = {
        message: data.message,
        permission: data.permission,
        broadcastCondition: data.broadcastCondition,
        id: data.id
    }
    return axios.post(endpoint, body)
}

const fetchDeleteNewsMe = (data: IUpdateDeleteAgentNewsMe) => {
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.newsdelete}`
    const body = {
        id: data.id,
    }
    return axios.post(endpoint, body)
}

export {
    fetchGetNewsMe,
    fetchAddNewsMe,
    fetchUpdateNewsMe,
    fetchDeleteNewsMe
}