import { Dispatch } from 'redux'
import { loaderAction } from './actionCreator'

const onChangeIsLoaddingAction = (data: boolean) => (dispatch: Dispatch) => {
      dispatch(loaderAction.request({}))
      try {
          dispatch(loaderAction.success(data))
      } catch {
          dispatch(loaderAction.failure({}))
      }
  }

export {
  onChangeIsLoaddingAction
}