import { Formik, FormikProps } from "formik";
import React, { Component } from "react";
import HomeComponents from "./components";
import initialLogin from "./model/initialValues";
import schemeLogin from "./model/scheme";
import { get, noop, trim } from "lodash";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ErrorModal } from "components";
import { responseCode, responseMessage } from "constants/response";

const Login = HomeComponents.Login;

type DefaultProps = Readonly<typeof defaultProps>;
const defaultProps: ILoginContainerProps & ILoginContainerActionProps = {
  login() {
    noop()
  },
  resetLogin() {
    noop();
  },
  resetAuthenticationKey() {
    noop();
  },
  loginCode: 0,
  loginError: "",
  loginIsFetching: false,
  userStatus: "UNKNOWN",
  verify: false,
};

class HomeContainer extends Component<
  ILoginContainerProps &
    ILoginContainerActionProps &
    RouteComponentProps<{}, {}, IRouteLogin> &
    DefaultProps,
  ILoginContainerState
> {
  static defaultProps = defaultProps;

  state: ILoginContainerState = {};

  componentDidMount() {
    // this.props.resetLogin();
    // this.props.resetAuthenticationKey();
  }

  componentDidUpdate(prevProps: ILoginContainerProps) {
    if (
      prevProps.loginIsFetching !== this.props.loginIsFetching &&
      !this.props.loginIsFetching
    ) {
      if (
        this.props.loginCode !== responseCode.OK &&
        this.props.loginError !== "" &&
        this.props.loginCode !== responseCode.ALREADY_REGISTER2FA
      ) {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            this.props.loginCode!,
            responseMessage()[0]
          ),
        });
      }
    }
  }

  onSubmitLogin = async (values: IAgentLogin) => {
    const logindata = {
      username: trim(values.username),
      password: trim(values.password),
    };
    const {history, location} = this.props

    this.props.login(logindata, history, location)
  };

  LoginFormik = () => {
    const LoginForm = (formProps: FormikProps<ILoginForm>) => {
      return <Login {...formProps} />;
    };
    return (
      <Formik
        initialValues={initialLogin}
        validationSchema={schemeLogin}
        enableReinitialize
        onSubmit={this.onSubmitLogin}
      >
        {LoginForm}
      </Formik>
    );
  };

  render() {
    const LoginFormik = this.LoginFormik;
    return (
      <div>
        <LoginFormik />
      </div>
    );
  }
}

export default withRouter(HomeContainer);
