import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Badge, HistoryBox } from "components";
import { UserDetails } from "./components/UserDetails";
import colors from "constants/colors";

const defaultProps: IEditUserHistoryDetailsContainerProps<IEditUserHistory> &
  IEditUserHistoryDetailsActionProps = {
  mockJSONEditUserHistoryDetails() {},
  pagination: {
    allPageData: [],
    currentPage: {
      selected: 0,
    },
    pageCount: 0,
    currentPageData: [],
  },
  pageClick() {},
};

class EditUserHistoryDetails extends Component<
  IEditUserHistoryDetailsContainerProps<IEditUserHistory> &
    IEditUserHistoryDetailsActionProps &
    RouteComponentProps<{}, {}, IEditUserHistoryDetailsLocationState>,
  IEditUserHistoryDetailsContainerState
> {
  static defaultProps = defaultProps;
  state: IEditUserHistoryDetailsContainerState = {
    user: "",
  };

  static getDerivedStateFromProps(nextProps: any) {
    return {
      user: nextProps.location.state.user,
    };
  }

  componentDidMount() {
    this.props.mockJSONEditUserHistoryDetails();
  }

  // DRY
  handlePageClick = ({
    selected: selectedPage,
  }: IEditUserHistorySelectPage) => {
    this.props.pageClick(selectedPage);
  };

  render() {
    return (
      <>
        <div className="col-9 secondary-bg">
          <div className="paper-container historyBox-container">
            <div className="row">
              <div className="col">
                <div className="sub-menu">
                  <Badge
                    text={`ข้อมูลสมาชิก > ประวัติการแก้ไขสมาชิก > ${this.state.user} `}
                    backgroundColor={colors.TERTIARY_GREEN}
                    color={colors.PRIMARY_GREEN}
                  />
                </div>
              </div>
            </div>
            <div className="paper-title">ข้อมูลสมาชิก</div>
            <div className="pr-3 paper-description">ประวัติการแก้ไขสมาชิก</div>
            <div className="paper-body">
              <div className="row mt-4 h-100">
                <div className="col">
                  <UserDetails user={this.props.location.state.user} />
                  <HistoryBox
                    type="editUserHistoryDetails"
                    pagination={this.props.pagination}
                    handlePageClick={this.handlePageClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(EditUserHistoryDetails);
