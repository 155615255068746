import { createAsyncAction } from 'typesafe-actions'
import {
    LOADING_REQUSET,
    LOADING_SHOW,
    LOADING_HIDE
} from './constants'


const loaderAction = createAsyncAction(
    LOADING_REQUSET,
    LOADING_SHOW,
    LOADING_HIDE
)<any, boolean, any>()

export {
    loaderAction
}