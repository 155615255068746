import { get } from 'lodash'
import {
    meState,
} from './constants'



const me = (state: RootReducers): ReducerState<IV2GetAgentMe>=>
    get(state, 'me.me', meState)

const membersSelector = {
    me,
}
export default membersSelector