import {
  Breadcrumb,
  Button,
  CheckBox,
  ErrorModal,
  Input,
  ResponsiveIcon,
  ModalPrintBill,
  SuccessModal,
  ConfirmModal,
  InputNumber,
  CancelBetModal,
  ConfirmNumberBanWarningModal,
} from "components"
import {
  CODE_TO_NAME,
  CODE_TO_TYPENAME,
  CODE_TO_TYPENAME_NUMBER,
  meDataInitialValue,
} from "constants/variables"
import React, { Component, createRef, RefObject } from "react"
import "./betMaking.style.scss"
import { RouteComponentProps } from "react-router-dom"
import Clock from "assets/images/global/time/img-clock.svg"
import Bin from "assets/images/global/icon/bin.png"
import Plus from "assets/images/global/icon/plus.png"
import Tap from "assets/images/global/icon/two-arrows.png"
import {
  camelCase,
  get,
  groupBy,
  includes,
  isEmpty,
  isNaN,
  map,
  noop,
  reverse,
  sortBy,
  split,
  sum,
  sumBy,
  uniq,
} from "lodash"
import colors from "constants/colors"
import { date, number, transformer } from "utils"
import getUnixTime from "date-fns/getUnixTime"
import { differenceInSeconds } from "date-fns"
import { responseCode, responseMessage } from "constants/response"
import pdfMake from "pdfmake/build/pdfmake"
import docDefinition from "./docDefinition"
import collectBank from "assets/images/global/bank"
import routes from "constants/routes"
import CryptoJS from "crypto-js"
import { NumbersBanTable } from "./components/NumbersBanTable"
import { fetchRateReduceLotteryAgentHost } from "reduxs/numbersBan/service"
import { AxiosError } from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash, faTimes } from "@fortawesome/free-solid-svg-icons"
import { LoadingCircle } from "components"

const constants = {
  mainPageTitle: "ภาพรวม",
  bet: "การเดิมพัน",
  betSubTitle: "แทงหวย",
  confirmTitle: "ยืนยันรายการ",
  betSuccess: "แทงรอบบิลนี้สำเร็จ",
  dataNotFound: "ไม่พบข้อมูล...",
  day: "วัน",
  timeups: "หมดเวลา",
  errorRemark: "โปรดกรอกชื่อช่วยจำ(ชื่อลูกค้า)เพื่อบันทึก",
  errorListBill: "ยังไม่ได้เพิ่มบิล",
  tesxtConfirmNotAddBillTitle: "ข้อมูลที่ยังไม่นำเข้าบิล จะหายไปทันที",
  tesxtConfirmNotAddBillDescription: "คุณแน่ใจหรือว่าจะเปลี่ยนโหมด",
  tesxtConfirmCancel: "คุณแน่ใจหรือว่าจะยกเลิกรายการ",
  tesxtConfirmClear: "คุณแน่ใจหรือว่าจะล้างตาราง",
  tesxtConfirmSave: "คุณแน่ใจหรือว่าจะบันทึก",
  pleasBet: "โปรดกรอกราคาเพื่อเพิ่มบิล",
  titlePooiSuccess: "สำเร็จ",
  cancelPooiSuccess: "ยกเลิกโพยสำเร็จ",
  modeBet: [
    { key: "TWO", label: "2 ตัว" },
    { key: "THREE", label: "3 ตัว" },
    { key: "SIXBACK", label: "6 กลับ" },
    { key: "19DOOR", label: "19 ประตู" },
    { key: "RUNNUMBER", label: "เลขวิ่ง" },
    { key: "WINNUMBER", label: "วินเลข" },
  ],
  cancleBet: "ยืนยันยกเลิกโพย",
  confirmCancleBet:
    "ข้อมูลการเดิมพันของลูกค้าในงวดดังกล่าวจะถูกยกเลิกด้วยทั้งหมด คุณแน่ใจที่จะยกเลิกโพยหรือไม่",
  menuList: ["แทงเร็ว", "ตาราง", "วางโพย"],
  pastePooi: "วางโพย",
  numberDouble: [
    "00",
    "11",
    "22",
    "33",
    "44",
    "55",
    "66",
    "77",
    "88",
    "99",
  ],
  numberTong: [
    "000",
    "111",
    "222",
    "333",
    "444",
    "555",
    "666",
    "777",
    "888",
    "999",
  ]
}

const mappingTheadLottoRate: IHeaderLottoTable[] = [
  { id: 1, name: "ประเภท" },
  { id: 2, name: "จ่าย" },
  { id: 3, name: "ลด(%)" },
  { id: 4, name: "ขั้นต่ำ" },
]

const mappingTheadLottoHistory: IHeaderLottoTable[] = [
  { id: 1, name: "สถิติผลรางวัล" },
  { id: 2, name: "5 งวดล่าสุด" },
  { id: 3, name: "3 ตัวบน" },
  { id: 4, name: "2 ตัวบน" },
  { id: 5, name: "2 ตัวล่าง" },
]

const mappingTheadLottoPopular: IHeaderLottoTable[] = [
  { id: 1, name: "เลขมาแรง" },
  { id: 2, name: "ลดราคา" },
  { id: 3, name: "เลขปิดรับ" },
]

const listLottoPopular: ILottoPopularTable[] = [
  { type: "THREE_UP", sale: ["-"], close: ["-"] },
  { type: "THREE_TOAST", sale: ["-"], close: ["-"] },
  { type: "TWO_UP", sale: ["-"], close: ["-"] },
  { type: "TWO_DOWN", sale: ["-"], close: ["-"] },
  { type: "RUN_UP", sale: ["-"], close: ["-"] },
  { type: "RUN_DOWN", sale: ["-"], close: ["-"] },
]

const mappingTheadLottoฺBill: IHeaderLottoTable[] = [
  { id: 1, name: "#" },
  { id: 2, name: "เวลาแทง" },
  { id: 3, name: "ตลาด" },
  { id: 4, name: "รายการ" },
  { id: 5, name: "บาท" },
  { id: 6, name: "หมายเหตุ" },
  { id: 7, name: "ลบโพย" },
]

const mappingTheadConfirmฺBill: IHeaderLottoTable[] = [
  { id: 1, name: "ประเภท" },
  { id: 2, name: "หมายเลข" },
  { id: 3, name: "ยอดเดิมพัน" },
  { id: 4, name: "อัตราจ่าย" },
  { id: 5, name: "ส่วนลด" },
  { id: 6, name: "รวม" },
  { id: 7, name: "ลบเลข" },
]

type DefaultProps = Readonly<typeof defaultProps>
const defaultProps: INewBetMakingContainerActionProps &
  INewBetMakingContainerProps = {
  getNumber() {
    noop()
  },
  getDataV2LotteryList() {
    noop()
  },
  getGetBetGroup() {
    noop()
  },
  onChangeIsLoaddingAction() {
    noop()
  },
  postAgentBet() {
    noop()
  },
  getAgentBet() {
    noop()
  },
  getV2DataResult() {
    noop()
  },
  getMe() {
    noop()
  },
  postCancelBill() {
    noop()
  },
  modalPrintBet() {
    noop()
  },
  meData: meDataInitialValue,
  getV2ResultData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  getV2ResultIsFetching: false,
  getV2ResultCode: 0,
  getV2ResultError: "",

  betNumberRatesData: [],
  betNumberRatesIsFetching: false,
  betNumberRatesCode: 0,

  lottoScheduleV2Data: [],
  lottoScheduleV2IsFetching: false,
  lottoScheduleV2Code: 0,
  lottoScheduleV2Error: "",

  postAgentBetData: {
    agentShareId: 0,
    remarkId: 0,
    agentBenefitList: [],
  },
  postAgentBetIsFetching: false,
  postAgentBetCode: 0,
  postAgentBetError: "",

  betGroupV2Data: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  betGroupV2IsFetching: false,
  betGroupV2Code: 0,
  betGroupV2Error: "",

  getAgentData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  getAgentIsFetching: false,
  getAgentCode: 0,
  getAgentError: "",
  postCancelBillCode: 0,
  postCancelBillError: "",
  postCancelBillIsFetching: false,

  modePrint: false,
}

class BetMakingContainer extends Component<
  DefaultProps &
  RouteComponentProps<
    { type: TLottoSlug },
    any,
    IBetMakingContainerRouteProps
  > &
  INewBetMakingContainerState
> {
  state: INewBetMakingContainerState = {
    isLoadingfix: false,
    modeBet: "TWO",
    listNumber: [],
    listNumberTemp: [],
    inputNumber: "",
    errorInputNumber: "",
    clickAddbill: false,

    inputWinNumber: "",
    winTwoNumber: true,
    winDouble: false,
    listBill: [],
    inputUp: "",
    inputDown: "",
    inputToast: "",
    inputDownToast: "",
    errorInputUp: "",
    errorInputDown: "",
    errorInputToast: "",
    errorInputDownToast: "",
    timeCounting: {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
    listNumberBet: [],
    remark: "",
    errorRemark: "",
    errorBillList: "",
    previewMode: false,
    isShowAdvice: true,
    showPay: false,
    activeMenu: "แทงเร็ว",
    textAreaValue: "",
    dataRateReduceLottery: {
      name: '',
      ratereduceThreeUp: [],
      ratereduceThreeToast: [],
      ratereduceTwoUp: [],
      ratereduceTwoDown: [],
      ratereduceRunUp: [],
      ratereduceRunDown: [],
    },
    isOverBetMax: 0,

  }
  intervalId: NodeJS.Timeout | null = null

  container: RefObject<HTMLDivElement> = createRef()
  iconCloseRef: RefObject<HTMLButtonElement> = createRef()
  numberInputRef: RefObject<HTMLInputElement> = createRef()
  inputUpRef: RefObject<HTMLInputElement> = createRef()
  inputDownRef: RefObject<HTMLInputElement> = createRef()
  inputToastRef: RefObject<HTMLInputElement> = createRef()
  inputDownToastRef: RefObject<HTMLInputElement> = createRef()
  inputTextArea: RefObject<HTMLTextAreaElement> = createRef()

  componentDidMount() {
    this.props.getMe()
    this.props.getDataV2LotteryList(this.props.match.params.type)
    this.props.getV2DataResult({
      page: 1,
      limit: 5,
      name: this.props.match.params.type as TLottoType,
    })
    this.props.getGetBetGroup({
      page: 1,
      limit: 5,
    })
    this.fetchGetLotteryRateReduce()
  }

  componentDidUpdate(
    prevProps: INewBetMakingContainerProps,
    prevState: INewBetMakingContainerState
  ) {

    if (prevState.previewMode !== this.state.previewMode) {
      if (this.state.previewMode === false) {
        this.setState({ isOverBetMax: 0 })
      }
      else {
        this.state.listNumberBet.map((item) => {
          if (item.money > (get(
            this.props.lottoScheduleV2Data,
            `[0].${camelCase("bet_max_" + item.type)}`,
            0
          ))) {
            this.setState((prevState: INewBetMakingContainerState) => ({
              isOverBetMax: prevState.isOverBetMax + 1,
            }))
          }
          return ''
        })
      }
    }

    if (
      this.props.betNumberRatesIsFetching ||
      this.props.lottoScheduleV2IsFetching ||
      this.props.betGroupV2IsFetching ||
      this.props.postAgentBetIsFetching ||
      this.props.getAgentIsFetching
    ) {
      // this.props.onChangeIsLoaddingAction(true)
    } else {
      // this.props.onChangeIsLoaddingAction(false)
    }

    if (
      prevState.listNumber !== this.state.listNumber ||
      prevState.clickAddbill !== this.state.clickAddbill
    ) {
      setTimeout(() => {
        this.setState({
          errorInputNumber: isEmpty(this.state.listNumber)
            ? "โปรดกรอกเลขเพื่อเพิ่มบิล"
            : "",
        })
      }, 100)
    }

    if (prevState.listBill !== this.state.listBill) {
      // this.checkListBill()
      this.setListBet()
    }

    if (
      prevProps.postAgentBetIsFetching !== this.props.postAgentBetIsFetching &&
      !this.props.postAgentBetIsFetching
    ) {
      if (
        this.props.postAgentBetCode === responseCode.OK &&
        this.props.postAgentBetError === ""
      ) {
        this.setState({isLoadingfix: false})
        this.props.getMe()
        SuccessModal.show({
          title: "สำเร็จ",
          description: "เดิมพันสำเร็จ",
          action: () => {
            if (!this.props.modePrint) {
              const dataBill = {
                limit: 999999,
                // status:'WAIT',
                customerId: this.props.postAgentBetData.remarkId,
                name: this.props.match.params.type as TLottoType,
                agentShareId: this.props.postAgentBetData.agentShareId,
              }
              ModalPrintBill.show({
                actionPrintShowPay: () => {
                  const dataString = JSON.stringify(dataBill)
                  const encrypted = CryptoJS.AES.encrypt(
                    dataString,
                    "bill-query"
                  )
                  const urlEncoded = encodeURIComponent(encrypted.toString())
                  window.open(
                    `${routes.previewBetBill.exactPath}/${urlEncoded}?showpaid=yes&name=${this.props.meData.username}`
                  )
                },
                actionPrintNotShowPay: () => {
                  const dataString = JSON.stringify(dataBill)
                  const encrypted = CryptoJS.AES.encrypt(
                    dataString,
                    "bill-query"
                  )
                  const urlEncoded = encodeURIComponent(encrypted.toString())
                  window.open(
                    `${routes.previewBetBill.exactPath}/${urlEncoded}?name=${this.props.meData.username}`
                  )
                },
                actionCancel: () => {
                  this.props.getGetBetGroup({ page: 1, limit: 5 })
                  this.setState({
                    listBill: [],
                    listNumberBet: [],
                    previewMode: false,
                    listNumber: [],
                    remark: "",
                  })
                  ErrorModal.hide()
                },
                actionModePrint: (isMode: boolean) => {
                  this.props.modalPrintBet(isMode)
                }
              })
            }
            else {
              this.props.getGetBetGroup({ page: 1, limit: 5 });
              this.setState({
                listBill: [],
                listNumberBet: [],
                previewMode: false,
                listNumber: [],
                remark: "",
              });
              SuccessModal.hide()
            }

            // ModalPrintBill.show({
            //   actionPrintShowPay:()=>{
            //     this.setState({showPay:true},()=>{
            //       this.props.getAgentBet(dataBill)
            //     })
            //   },
            //   actionPrintNotShowPay:()=>{
            //     this.setState({showPay:false},()=>{
            //       this.props.getAgentBet(dataBill)
            //     })
            //   },
            //   actionCancel:()=>{
            //     this.props.getGetBetGroup({ page: 1, limit: 5 })
            //     this.setState({listBill:[],listNumberBet:[],previewMode:false,listNumber:[],remark:''})
            //     ErrorModal.hide()
            //   },
            // })
          },
        });
      } else if (
        this.props.postAgentBetCode === 400000 ||
        this.props.postAgentBetCode === 400123
      ) {
        SuccessModal.show({
          action: () => {
            this.setState({isLoadingfix: false})
            this.props.history.push(routes.bet.path);
            SuccessModal.hide();
          },
          title: "ไม่สามารถยืนยันรายการได้ เนื่องจากหมดเวลารับแทง",
          description: " ",
          actionText: "เข้าใจ",
          classTextTitle: "primary-text",
        });
      } else if (this.props.postAgentBetCode === 400132) {
        ErrorModal.show({
          action: () => {
            this.setState({isLoadingfix: false})
            this.fetchGetLotteryRateReduce()
            ErrorModal.hide();
          },
          actionText: 'ตกลง',
          description: get(
            responseMessage(),
            this.props.postAgentBetCode,
            responseMessage()[0]
          ),
        });
      } else {
        ErrorModal.show({
          action: () => {
            this.setState({isLoadingfix: false})
            ErrorModal.hide();
          },
          description: get(
            responseMessage(),
            this.props.postAgentBetCode,
            responseMessage()[0]
          ),
        });
      }
    }

    if (
      prevProps.getAgentIsFetching !== this.props.getAgentIsFetching &&
      !this.props.getAgentIsFetching
    ) {
      if (!isEmpty(this.props.getAgentData.dataList)) {
        const round = date.formatThai(get(this.props.lottoScheduleV2Data, "[0].endTime"), "ddMMMMyyyy")
        const dataName = transformer.nameLotto(
          get(this.props.getAgentData, "dataList[0].name"),
          true,
          true
        );
        const dataCustomerName = get(
          this.props.getAgentData,
          "dataList[0].customerName"
        );
        const dateNow = date.formatThai( get(this.props.getAgentData, "dataList[0].createdAt"), "ddMMyyyy")

        const nameFile = `${dataName}_${dataCustomerName}_${dateNow}`;
        pdfMake
          .createPdf(
            docDefinition(
              this.props.getAgentData.dataList,
              this.state.showPay,
              round,
              "asdsd",
              0
            ) as any
          )
          .download(nameFile);
      }
    }

    if (
      prevProps.lottoScheduleV2IsFetching !==
      this.props.lottoScheduleV2IsFetching &&
      !this.props.lottoScheduleV2IsFetching
    ) {
      this.countingdown();
    }

    document.onkeydown = (keyDownEvent) => {
      let focused = document.activeElement?.id;
      if (
        this.checkWaitingTime()
        // || this.checkTimeout()
      ) {
        setTimeout(() => this.iconCloseRef.current?.focus(), 10);
      } else {
        if (keyDownEvent.code === "Tab" || keyDownEvent.code === "Enter" || keyDownEvent.code === "NumpadEnter") {
          const checkWinNumber = (val?: string) =>
            val === "calculate" ||
            val === "back-number" ||
            val === "btn-win-number1" ||
            val === "btn-win-number2" ||
            val === "btn-win-number3" ||
            val === "btn-win-number4" ||
            val === "btn-win-number5" ||
            val === "btn-win-number6" ||
            val === "btn-win-number7" ||
            val === "btn-win-number8" ||
            val === "btn-win-number9" ||
            val === "btn-win-number0";
          switch (focused) {
            case "icon-close":
            case "modeBetTWO":
            case "modeBetTHREE":
            case "modeBetSIXBACK":
            case "modeBet19DOOR":
            case "modeBetRUNNUMBER":
            case "modeBetWINNUMBER":
              break;
            case "cancel-list-bet":
            case "btn-double":
              break;
            case "number":
              if (keyDownEvent.code === "Tab" || keyDownEvent.code === "Enter" || keyDownEvent.code === "NumpadEnter")
                setTimeout(() => this.inputUpRef.current?.focus(), 0);
              break;
            case "inputUp":
              if (this.state.modeBet === "SIXBACK" && this.checkAddBill()) {
                this.addBill();
              }
              else if (this.state.activeMenu === 'แทงเร็ว') {
                setTimeout(() => this.inputDownToastRef.current?.focus(), 0);
              }
              else if (this.state.activeMenu === constants.pastePooi) {
                setTimeout(() => this.inputDownRef.current?.focus(), 0);
              }
              break;
            case "inputTextArea":
              if (keyDownEvent.code === "Tab" || keyDownEvent.code === "Enter" || keyDownEvent.code === "NumpadEnter")
                setTimeout(() => this.inputUpRef.current?.focus(), 0)
              break;
            case "inputDown":
              if (keyDownEvent.code === "Tab" || keyDownEvent.code === "Enter" || keyDownEvent.code === "NumpadEnter")
                setTimeout(() => this.inputToastRef.current?.focus(), 0)
              break;
            case "inputDownToast":
              if (this.checkAddBill()) {
                this.addBill();
              }
              break;
            case "inputToast":
              if (this.checkAddBill()) {
                this.addBill();
              }
              break;
            case "add-bill":
              break;

            case "comment":
            case "clear-bill":
            case "back":
              break;
            case "submit":
              if (keyDownEvent.code === "Tab")
                setTimeout(() => this.iconCloseRef.current?.focus(), 10)
              break;

            default:
              if (
                keyDownEvent.code === "Tab" &&
                !focused?.includes("delete-bill") &&
                !checkWinNumber(focused)
              )
                setTimeout(() => this.iconCloseRef.current?.focus(), 10);
              break;
          }
        }
        if (keyDownEvent.code === "Space") {
          if ((this.state.modeBet === "TWO" || this.state.modeBet === "THREE") && (focused !== 'comment')) {
            this.calcNumberBack()
          } else if (this.state.modeBet === "WINNUMBER") {
            this.calcBackWinNumber()
          }
        }
      }
    };

    if (
      prevProps.postCancelBillIsFetching !==
      this.props.postCancelBillIsFetching &&
      !this.props.postCancelBillIsFetching
    ) {
      if (this.props.postCancelBillError !== "") {
        ErrorModal.show({
          action: () => ErrorModal.hide(),
          description: get(
            responseMessage(),
            this.props.postCancelBillCode,
            responseMessage()[0]
          ),
        });
      } else {
        SuccessModal.show({
          action: () => {
            SuccessModal.hide()
            this.props.getMe()
            this.props.getGetBetGroup({ page: 1, limit: 5 })
          },
          title: constants.titlePooiSuccess,
          description: constants.cancelPooiSuccess,
        });
      }
    }
  }

  componentWillUnmount() {
    this.clearLocalInterval();
  }

  fetchGetLotteryRateReduce = async () => {
    this.props.onChangeIsLoaddingAction(true)
    const query: IV2QueryGetRateReduceHost = {
      // name: 'YEGEE_001',
      name: this.props.location.state.targetLotto.name,
    };
    await fetchRateReduceLotteryAgentHost(query)
      .then((res) => {
        this.setState({ dataRateReduceLottery: res.data.data });
      })
      .catch((err: AxiosError<IAPIResponse>) => {
        if (get(err, 'response.data.code', 0) !== responseCode.OK) {
          ErrorModal.show({
            action: ErrorModal.hide,
            description: get(
              responseMessage(),
              get(err, 'response.data.code', 0),
              responseMessage()[0]
            ),
          });
        }
      })
      .finally(() => {
        this.props.onChangeIsLoaddingAction(false);
      });
  }

  checkWaitingTime = () => {
    let startTime = get(this.props.lottoScheduleV2Data, "[0].startTime", "");
    let endTime = get(this.props.lottoScheduleV2Data, "[0].endTime", "");
    let name = get(this.props.lottoScheduleV2Data, "[0].name", "");
    if (name.includes("YEGEE")) {
      return false;
    } else {
      return (
        getUnixTime(date.calibratingTime(startTime!)) >
        getUnixTime(new Date()) &&
        getUnixTime(date.calibratingTime(endTime!)) > getUnixTime(new Date())
      );
    }
  };

  checkTimeout = () => {
    let endTime = get(this.props.lottoScheduleV2Data, "[0].endTime", "");
    return (
      getUnixTime(date.calibratingTime(endTime!)) < getUnixTime(new Date())
    );
  };

  changeModeToType = () =>
    this.state.modeBet === "19DOOR"
      ? "TWO"
      : this.state.modeBet === "SIXBACK"
        ? "THREE"
        : this.state.modeBet === "RUNNUMBER"
          ? "RUN"
          : this.state.modeBet === "WINNUMBER" && this.state.winTwoNumber
            ? "TWO"
            : this.state.modeBet === "WINNUMBER" && !this.state.winTwoNumber
              ? "THREE"
              : this.state.modeBet;

  getNumberListFromListBill = (listBill: IItemBill[]) => {
    const listNumber = listBill.reduce((result, item) => {
      item.number.map((num) => {
        if (Number(item.up) > 0) {
          result.push({
            number: num,
            money: Number(item.up),
            remark: this.state.remark,
            type: `${item.type === "19DOOR"
              ? "TWO"
              : item.type === "SIXBACK"
                ? "THREE"
                : item.type === "RUNNUMBER"
                  ? "RUN"
                  : item.type === "WINNUMBER" && num.length === 2
                    ? "TWO"
                    : item.type === "WINNUMBER" && num.length === 3
                      ? "THREE"
                      : item.type
              }_UP` as TLottoGameType,
            slug: this.props.match.params.type as TLottoType,
          });
        }

        if (Number(item.down) > 0 && item.type !== "THREE") {
          result.push({
            number: num,
            money: Number(item.down),
            remark: this.state.remark,
            type: `${item.type === "19DOOR"
              ? "TWO"
              : item.type === "SIXBACK"
                ? "THREE"
                : item.type === "RUNNUMBER"
                  ? "RUN"
                  : item.type === "WINNUMBER" && num.length === 2
                    ? "TWO"
                    : item.type === "WINNUMBER" && num.length === 3
                      ? "THREE"
                      : item.type
              }_DOWN` as TLottoGameType,
            slug: this.props.match.params.type as TLottoType,
          });
        }

        if (
          Number(item.toast) > 0 &&
          (item.type === "THREE" ||
            item.type === "WINNUMBER" ||
            item.type === "SIXBACK")
        ) {
          result.push({
            number: num,
            money: Number(item.toast),
            remark: this.state.remark,
            type: "THREE_TOAST" as TLottoGameType,
            slug: this.props.match.params.type as TLottoType,
          });
        }
        return "";
      });
      return result;
    }, [] as IV2PostAgentLotteryBet[]);
    return listNumber
  }

  setListBet = () => {
    let listNumber = this.getNumberListFromListBill(this.state.listBill)
    this.setState({ listNumberBet: sortBy(listNumber, ["type"]) });
  };

  clearLocalInterval = () => {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
    }
  }

  countingdown = () => {
    let start = get(this.props.lottoScheduleV2Data, "[0].startTime");
    let expire = get(this.props.lottoScheduleV2Data, "[0].endTime");
    let slug = get(this.props.lottoScheduleV2Data, "[0].name");
    this.clearLocalInterval();
    const resultTiming = this.checkWaitingTime()
      ? getUnixTime(new Date()) - getUnixTime(date.calibratingTime(start!))
      : getUnixTime(date.calibratingTime(start!)) - getUnixTime(new Date());
    const selectedTime =
      resultTiming < 0 && !this.checkWaitingTime() ? expire : start;
    const expireTime = (slug as any)?.includes("LOTTER_YEGEE") ? expire : selectedTime;
    const expireMoment = date.calibratingTime(expireTime!);
    this.intervalId = setInterval(() => {
      const differentSecond = differenceInSeconds(expireMoment, new Date());
      const days = Math.floor(differentSecond / 86400);
      const hours = Math.floor((differentSecond / 3600) % 24);
      const minutes = Math.floor((differentSecond / 60) % 60);
      const seconds = Math.floor(differentSecond % 60);
      if (
        (hours! <= 0 && minutes! <= 0 && seconds <= 0) ||
        isNaN(hours!) ||
        isNaN(minutes!) ||
        isNaN(seconds!)
      ) {
        this.setState({
          timeCounting: {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
          },
        });
        this.clearLocalInterval();
        setTimeout(() => {
          if (!this.checkTimeout()) {
            this.props.getDataV2LotteryList(this.props.match.params.type);
          } else {
          }
        }, 1500);
      } else {
        this.setState({
          timeCounting: {
            days: days!,
            hours: hours!,
            minutes: minutes!,
            seconds: seconds!,
          },
        });
      }
    }, 1000);
  };

  statusText = (): string => {
    if (this.state.timeCounting.days > 0) {
      return `${number.padNumber(String(this.state.timeCounting.days), 2)} ${constants.day
        }`;
    }
    if (
      this.state.timeCounting.days <= 0 &&
      this.state.timeCounting.hours <= 0 &&
      this.state.timeCounting.minutes <= 0 &&
      this.state.timeCounting.seconds <= 0
    ) {
      return constants.timeups;
    }
    return `${number.padNumber(
      String(this.state.timeCounting.hours),
      2
    )}:${number.padNumber(
      String(this.state.timeCounting.minutes),
      2
    )}:${number.padNumber(String(this.state.timeCounting.seconds), 2)}`;
  };

  sixBack = (val: string) => {
    const sixBackNumList = [
      val[0] + val[1] + val[2],
      val[0] + val[2] + val[1],
      val[1] + val[0] + val[2],
      val[1] + val[2] + val[0],
      val[2] + val[0] + val[1],
      val[2] + val[1] + val[0],
    ];
    return uniq<string>(sixBackNumList);
  };

  loopNineteenDoor = (val: string) => {
    const number = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    let listOne = number.map((item) => item + val);
    let listTwo = number.reduce((result, item) => {
      if (val !== item) {
        result.push(val + item);
      }
      return result;
    }, [] as string[]);
    return listOne.concat(listTwo).sort();
  };

  renderWinNumber = (number: string[]) => {
    // const number = ['1','2','3','4','5','6','7','8','9','0']
    return number.map((item) => {
      let indexNumber = this.state.inputWinNumber.lastIndexOf(item);
      return (
        <div className="btn-win-number" key={"btn-win-number" + item}>
          <Button
            id={"btn-win-number" + item}
            text={item}
            onClick={() => {
              if (indexNumber === -1) {
                if (this.state.inputWinNumber.length <= 6) {
                  this.setState({
                    inputWinNumber: this.state.inputWinNumber + item,
                  });
                }
              } else {
                this.setState({
                  inputWinNumber: this.state.inputWinNumber.replace(item, ""),
                });
              }
            }}
            textColor={
              indexNumber === -1 ? colors.PRIMARY_TEXT : colors.PRIMARY_BG
            }
            backgroundColor={
              indexNumber === -1 ? colors.TERTIARY_GREEN : colors.PRIMARY_GREEN
            }
          >
            {indexNumber !== -1 && (
              <h6 className="position-index body-4">{indexNumber + 1}</h6>
            )}
          </Button>
        </div>
      );
    });
  };

  calculateWinNumber = (input: string) => {
    let twoWin = input.split("").reduce((result, item, index) => {
      if (this.state.winDouble) {
        result.push(`${item}${item}`);
      }
      for (let j = index + 1; j < input.length; j++) {
        result.push(`${item}${input[j]}`);
      }
      return result;
    }, [] as string[]);
    let threeWin = input.split("").reduce((result, item, index) => {
      if (this.state.winDouble) {
        result.push(`${item}${item}${item}`);
      }
      for (let j = index + 1; j < input.length; j++) {
        if (this.state.winDouble) {
          result.push(`${item}${item}${input[j]}`);
        }
        for (let k = j; k < input.length; k++) {
          if (input[j] === input[k] && this.state.winDouble) {
            result.push(`${item}${input[j]}${input[k]}`);
          } else if (input[j] !== input[k]) {
            result.push(`${item}${input[j]}${input[k]}`);
          }
        }
      }
      return result;
    }, [] as string[]);

    return this.setState(
      {
        listNumber: this.state.listNumber.concat(
          this.state.winTwoNumber ? twoWin : threeWin
        ),
      }
      // ()=>{ this.setState({listNumber:this.state.listNumber.filter((item, index)=>this.state.listNumber.indexOf(item) === index)})}
    );
  };

  textLabelInputUp = () =>
    `${this.state.modeBet === "RUNNUMBER" ? "วิ่ง" : ""}บน`;

  textLabelInputDownToast = () => {
    return this.state.modeBet === "THREE" ||
      (this.state.modeBet === "WINNUMBER" && !this.state.winTwoNumber)
      ? "โต๊ด"
      : `${this.state.modeBet === "RUNNUMBER" ? "วิ่ง" : ""}ล่าง`;
  };

  errorMsgInputValue = (value: string, type: TLottoGameType) => {
    let betMax = get(
      this.props.lottoScheduleV2Data,
      `[0].${camelCase("bet_max_" + type)}`,
      0
    );
    let betMin = get(
      this.props.lottoScheduleV2Data,
      `[0].${camelCase("bet_min_" + type)}`,
      0
    );

    if (isEmpty(value) || Number(value) === 0) {
      return "";
    } else {
      if (Number(value) > betMax) {
        return `แทงสูงสุด ${number.addComma(betMax)} บาท`;
      }
      if (Number(value) < betMin) {
        return `แทงขั้นต่ำ ${number.addComma(betMin)} บาท`;
      }
      return "";
    }
  };


  checkInputBet = () => {
    let up = this.errorMsgInputValue(
      this.state.inputUp,
      `${this.changeModeToType()}_UP` as TLottoGameType
    );
    let down = this.errorMsgInputValue(
      this.state.inputDownToast,
      `${this.changeModeToType()}_${this.changeModeToType() === "THREE" ? "TOAST" : "DOWN"
      }` as TLottoGameType
    );

    if (this.state.activeMenu === constants.pastePooi) {
      if (
        isEmpty(this.state.inputUp) &&
        isEmpty(this.state.inputDown) &&
        isEmpty(this.state.inputToast)
      ) {
        this.setState({
          errorInputUp: constants.pleasBet,
          errorInputDown: constants.pleasBet,
          errorInputToast: constants.pleasBet,
        });
      }

    } else {
      if (isEmpty(this.state.inputUp) && isEmpty(this.state.inputDownToast)) {
        this.setState({
          errorInputDownToast: constants.pleasBet,
          errorInputUp: constants.pleasBet,
        });
      } else {
        if (isEmpty(up)) {
          this.setState({ errorInputUp: "" });
        } else {
          this.setState({ errorInputUp: up });
        }
        if (isEmpty(down)) {
          this.setState({ errorInputDownToast: "" });
        } else {
          this.setState({ errorInputDownToast: down });
        }
      }
    }
  };

  checkAddBill = () => {
    let up = this.errorMsgInputValue(
      this.state.inputUp,
      `${this.changeModeToType()}_UP` as TLottoGameType
    );
    let down = this.errorMsgInputValue(
      this.state.inputDownToast,
      `${this.changeModeToType()}_${this.changeModeToType() === "THREE" ? "TOAST" : "DOWN"
      }` as TLottoGameType
    );
    if (isEmpty(this.state.listNumber)) {
      return false;
    } else {
      if (this.state.activeMenu === constants.pastePooi) {
        if (
          ((!isEmpty(this.state.inputUp) && Number(this.state.inputUp) !== 0) ||
            (!isEmpty(this.state.inputDown) &&
              Number(this.state.inputDown) !== 0) ||
            (!isEmpty(this.state.inputToast) &&
              Number(this.state.inputToast) !== 0))
        ) {
          return true;
        }
      } else {
        if (
          isEmpty(up) &&
          isEmpty(down) &&
          ((!isEmpty(this.state.inputUp) && Number(this.state.inputUp) !== 0) ||
            (!isEmpty(this.state.inputDownToast) &&
              Number(this.state.inputDownToast) !== 0))
        ) {
          return true;
        }
      }
      this.checkInputBet();
      return false;
    }
  };

  calcNumberBack = () => {
    let addNumberTwo = this.state.listNumber.reduce((result, element) => {
      if (element[0] !== element[1] && !isNaN(Number(element)) && element.length === 2) {
        result.push(element[1] + element[0]);
      }
      return result;
    }, [] as string[]);
    let addNumberThree = this.state.listNumber.reduce((result, element) => {
      if (!isNaN(Number(element)) && element.length === 3) {
        result = result.concat(this.sixBack(element).slice(1));
      }
      return result;
    }, [] as string[]);
    let setData = this.state.listNumber.concat(
      this.state.modeBet === constants.modeBet[0].key
        ? addNumberTwo
        : addNumberThree
    );
    this.setState({ listNumber: setData });
  };

  calcBackWinNumber = () => {
    if (!isEmpty(this.state.listNumber)) {
      if (this.state.winTwoNumber) {
        const backNumber = this.state.listNumber.reduce((result, val) => {
          const backVal = String(sum(reverse(split(val, ""))));
          if (backVal !== val) {
            result.push(backVal);
          }
          return result;
        }, [] as string[]);
        this.setState({ listNumber: this.state.listNumber.concat(backNumber) });
      } else {
        const backNumber = this.state.listNumber.reduce((result, val) => {
          const backVal = this.sixBack(val);
          result = result.concat(backVal);
          return result;
        }, [] as string[]);
        this.setState({ listNumber: backNumber });
      }
    }
  };

  getUpDownOrToast = (type: TLottoGameType) => {
    let gameType: 'toast' | 'down' | 'up' = 'down'
    if (type.includes('TOAST')) {
      gameType = 'toast'
    } else if (type.includes('DOWN')) {
      gameType = 'down'
    } else if (type.includes('UP')) {
      gameType = 'up'
    }
    return gameType
  }

  getLengthFromType = (type: IModeBet) => {
    switch (type) {
      case 'RUNNUMBER':
        return 1
      case 'TWO':
        return 2
      case '19DOOR':
        return 2
      case 'WINNUMBER':
        return 2
      case 'THREE':
        return 3
      case 'SIXBACK':
        return 3
      default:
        break;
    }
  }

  getRelevantPriceFromGameType = (type: IModeBet) => {
    let priceType: ('up' | 'down' | 'toast')[] = []
    if ((type === 'RUNNUMBER' || type === 'TWO' || type === '19DOOR' || type === 'WINNUMBER') && this.state.winTwoNumber) {
      priceType = ['up', 'down']
    } else {
      priceType = ['up', 'toast']
    }
    return priceType
  }

  getNumberBanList = (filter?: 'closed') => {
    const { dataRateReduceLottery } = this.state
    const allRateReduce = [
      ...get(dataRateReduceLottery, 'ratereduceThreeUp', []),
      ...get(dataRateReduceLottery, 'ratereduceThreeToast', []),
      ...get(dataRateReduceLottery, 'ratereduceTwoUp', []),
      ...get(dataRateReduceLottery, 'ratereduceTwoDown', []),
      ...get(dataRateReduceLottery, 'ratereduceRunUp', []),
      ...get(dataRateReduceLottery, 'ratereduceRunDown', []),
    ]
    let numberBanList: IV2RateReduceNumber[] = allRateReduce
    if (filter === 'closed') {
      numberBanList = allRateReduce
        .filter(
          (num) =>
            num.ratereduceIsBanned ||
            num.ratereduceIsNumholFull ||
            num.ratereduceIsHostBanned
        )
    }
    const filteredBanList = numberBanList.map((num) => ({
      number: num.ratereduceNumber,
      type: num.ratereduceBetType,
      rate: num.ratereduceValue,
    }))

    return filteredBanList
  }
  getNumberBanFilteredListBill = (newListBill: IItemBill[]) => {
    const allClosedNumberList = this.getNumberBanList('closed')
    const warnNumberList: string[] = []
    if (allClosedNumberList.length > 0) {
      //เช็คเลขอั้น
      const allClosedNumberGroup = groupBy(allClosedNumberList, 'number')
      const bothClosedNumberList = Object.keys(
        Object.fromEntries(
          Object.entries(allClosedNumberGroup).filter(
            ([_, value]) => value.length === 2
          )
        )
      )
      newListBill.forEach((newBill) => {
        newBill.number = newBill.number.filter((num) => {
          //filter เลขที่ปิดทั้งบนล่างออก แล้วขึ้นเตือน
          if (bothClosedNumberList.includes(num)) {
            warnNumberList.push(num)
            return false
          }
          return true
        })

        const listNumberfromNewBill = this.getNumberListFromListBill([{ ...newBill }])
        listNumberfromNewBill.forEach((num) => {
          const blockedNumber = allClosedNumberList.find(
            (n) => n.number === num.number && n.type === num.type
          )
          if (!blockedNumber)
            return
          warnNumberList.push(blockedNumber.number)
          newBill.number = newBill.number.filter((num) => num !== blockedNumber.number)
          //ถ้าเลขปิดข้างเดียวจะเอาออกจากบิล แล้วไป push บิลใหม่ต่อข้างล่าง
          const blockedNumberType = this.getUpDownOrToast(blockedNumber.type)
          const existNewBillObjIndex = newListBill.findIndex(
            (bill) =>
              bill[blockedNumberType] === '0' &&
              this.getLengthFromType(bill.type) === blockedNumber.number.length
          )
          if (existNewBillObjIndex !== -1) {
            newListBill[existNewBillObjIndex].number.push(
              blockedNumber.number
            )
          } else {
            const newBillObj: IItemBill = {
              ...newBill,
              number: [blockedNumber.number],
              [blockedNumberType]: '0',
            }
            newListBill.push(newBillObj)
          }
        })
      })
    }
    const cleanedNewListBill = newListBill.filter((newBill) => {
      if (newBill.number.length === 0)
        return false
      const priceType = this.getRelevantPriceFromGameType(newBill.type)
      if (newBill[priceType[0]] === '0' && newBill[priceType[1]] === '0') {
        return false
      }
      return true
    })

    return { cleanedNewListBill, warnNumberList }
  }

  checkLenNumber = (type: IModeBet, numList: string[]) => {
    let lenNumber = type === "TWO" || type === "19DOOR"
      ? 2
      : type === "THREE" || type === "SIXBACK"
        ? 3
        : 1
    return numList.filter(
      (number) => number.length === lenNumber
    )
  }

  addBill = () => {
    if (this.checkAddBill()) {
      const newListBill: IItemBill[] = []
      const filterNumber = this.state.listNumber.filter((number) =>
        number.match(/^\d+$/)
      )
      const array1Digits = filterNumber.filter(
        (number) => number.length === 1
      )
      const array2Digits = filterNumber.filter(
        (number) => number.length === 2
      )
      const array3Digits = filterNumber.filter(
        (number) => number.length === 3
      )
      if (this.state.activeMenu === constants.pastePooi) {
        const upPrice = this.state.inputUp
        const downPrice = this.state.inputDown
        const toastPrice = this.state.inputToast
        if (
          !isEmpty(array1Digits) ||
          !isEmpty(array2Digits) ||
          !isEmpty(array3Digits)
        ) {
          if (!isEmpty(array1Digits)) {
            newListBill.push({
              type: 'RUNNUMBER',
              up: isEmpty(upPrice) ? '0' : upPrice,
              down: isEmpty(downPrice) ? '0' : downPrice,
              toast: isEmpty(toastPrice) ? '0' : toastPrice,
              number: array1Digits,
            });
          }
          if (!isEmpty(array2Digits)) {
            newListBill.push({
              type: "TWO",
              up: isEmpty(upPrice) ? "0" : upPrice,
              down: isEmpty(downPrice) ? "0" : downPrice,
              toast: isEmpty(toastPrice) ? "0" : toastPrice,
              number: array2Digits,
            })
          }
          if (!isEmpty(array3Digits)) {
            newListBill.push({
              type: "THREE",
              up: isEmpty(upPrice) ? "0" : upPrice,
              down: isEmpty(downPrice) ? "0" : downPrice,
              toast: isEmpty(toastPrice) ? "0" : toastPrice,
              number: array3Digits,
            })
          }
        }
        this.setState({ textAreaValue: "", })
      } else {
        if (
          this.state.modeBet === constants.modeBet[0].key ||
          this.state.modeBet === constants.modeBet[3].key ||
          this.state.modeBet === constants.modeBet[4].key ||
          (this.state.modeBet === constants.modeBet[5].key &&
            this.state.winTwoNumber)
        ) {
          newListBill.push({
            type: this.state.modeBet,
            up: isEmpty(this.state.inputUp) ? "0" : this.state.inputUp,
            down: isEmpty(this.state.inputDownToast)
              ? "0"
              : this.state.inputDownToast,
            toast: "0",
            number: this.state.winTwoNumber && this.state.modeBet === constants.modeBet[5].key ? this.state.listNumber : this.checkLenNumber(this.state.modeBet, filterNumber),
          })
        } else if (
          this.state.modeBet === constants.modeBet[1].key ||
          (this.state.modeBet === constants.modeBet[5].key &&
            !this.state.winTwoNumber)
        ) {
          newListBill.push({
            type: this.state.modeBet,
            up: isEmpty(this.state.inputUp) ? "0" : this.state.inputUp,
            down: "0",
            toast: isEmpty(this.state.inputDownToast)
              ? "0"
              : this.state.inputDownToast,
            number: this.state.modeBet === constants.modeBet[5].key ? this.state.listNumber : this.checkLenNumber(this.state.modeBet, filterNumber),
          })
        } else if (this.state.modeBet === constants.modeBet[2].key) {
          newListBill.push({
            type: this.state.modeBet,
            up: isEmpty(this.state.inputUp) ? "0" : this.state.inputUp,
            down: "0",
            toast: "0",
            number: this.checkLenNumber(this.state.modeBet, filterNumber),
          })
        }
      }

      const { cleanedNewListBill, warnNumberList } = this.getNumberBanFilteredListBill(newListBill)
      const setFocusNumberInput = (time: number = 100) => {
        setTimeout(() => {
          this.numberInputRef.current?.focus();
        }, time);
      }
      const addNewBill = () => {
        this.setState(
          {
            listBill: [...this.state.listBill].concat(cleanedNewListBill),
            listNumber: [],
            listNumberTemp: [],
            inputUp: '',
            inputDown: '',
            inputToast: '',
            inputDownToast: '',
            inputNumber: '',
            inputWinNumber: '',
            errorInputDownToast: '',
            errorInputUp: '',
            errorInputDown: '',
            errorInputToast: '',
            clickAddbill: false,
          },
          () => {
            setFocusNumberInput()
          }
        )
      }

      if (warnNumberList.length > 0) {
        ConfirmNumberBanWarningModal.show({
          textSmall: true,
          size: "md",
          numberList: uniq<string>(warnNumberList),
          whiteSpace: 'pre-line',
          action: () => {
            addNewBill()
            setFocusNumberInput(300)
            ConfirmNumberBanWarningModal.hide()
          },
          cancelAction: () => {
            setFocusNumberInput(300)
            ConfirmNumberBanWarningModal.hide();
          },
        })
      } else {
        addNewBill()
      }
    } else {
      this.setState({ clickAddbill: true });
    }
  };

  checkNotAddBill = (action: () => void, description: string) => {
    if (isEmpty(this.state.listNumber)) {
      action();
    } else {
      ConfirmModal.show({
        textSmall: true,
        size: "md",
        title: constants.tesxtConfirmNotAddBillTitle,
        description: description,
        action: () => {
          action();
          ConfirmModal.hide();
        },
        cancelAction: ConfirmModal.hide,
      });
    }
  };

  listLoaderNumber = () => {
    const updatedCustomStyle = []
    const listNumberLength = this.state.textAreaValue
    let numbers
    const sizeLoader = this.state.modeBet === 'TWO' || this.state.modeBet === '19DOOR'
      ? 'M'
      : this.state.modeBet === 'THREE' || this.state.modeBet === 'SIXBACK' ? 'L' : 'S'
    switch (this.state.modeBet) {
      case 'TWO':
        numbers = listNumberLength.match(/\b\d{2}\b/g)?.length
        break
      case 'THREE':
        numbers = listNumberLength.match(/\b\d{3}\b/g)?.length
        break
      case 'SIXBACK':
        numbers = listNumberLength.match(/\b\d{3}\b/g)?.length
        break
      case '19DOOR':
        numbers = listNumberLength.match(/\b\d{2}\b/g)?.length
        break
      case 'RUNNUMBER':
        numbers = listNumberLength.match(/\b\d{1}\b/g)?.length
        break
      default:
        numbers = listNumberLength.match(/\d+/g)?.length
        break
    }
    if (numbers) {
      for (let i = 0; i < numbers; i++) {
        const newObj = {
          col: i,
          color: colors.PRIMARY_TEXT,
          show: true,
          size: sizeLoader
        }
        updatedCustomStyle.push(newObj)
      }
    }
    return updatedCustomStyle
  }


  fastBet = () => {
    return (
      <>
        <div className="group-btn-modeBet">
          {constants.modeBet.map((item, index) => {
            return (
              <div
                key={index + item.key}
                className={`btn-modeBet ${item.key === this.state.modeBet ? "active" : ""
                  }`}
              >
                <Button
                  id={"modeBet" + item.key}
                  text={item.label}
                  onClick={() => {
                    this.checkNotAddBill(
                      () =>
                        this.setState({
                          winTwoNumber: true,
                          modeBet: item.key,
                          listNumber: [],
                          inputUp: "",
                          inputDownToast: "",
                          inputNumber: "",
                          inputWinNumber: "",
                          errorInputDownToast: "",
                          errorInputUp: "",
                        }),
                      constants.tesxtConfirmNotAddBillDescription
                    );
                  }}
                  textColor={
                    item.key === this.state.modeBet
                      ? colors.PRIMARY_BG
                      : colors.PRIMARY_TEXT
                  }
                  backgroundColor={colors.TERTIARY_GREEN}
                  disabled={
                    this.checkWaitingTime()
                    // || this.checkTimeout()
                  }
                // backgroundHoverColor={colors.SECONDARY_GREEN}
                />
              </div>
            );
          })}
        </div>
        <div className="row box-list-bet">
          <div className="col">
            {this.state.modeBet === constants.modeBet[5].key && (
              <div className="row">
                <div className="col-6 mb-2 checkbox">
                  <CheckBox
                    id={`winTwoNumber-two`}
                    value={this.state.winTwoNumber}
                    onClick={(e) => {
                      this.setState({
                        winTwoNumber: e.target.checked,
                        listNumber: [],
                      });
                    }}
                  />
                  <div className="body-4 ml-2">จับวิน 2 ตัว</div>
                </div>
                <div className="col-6 mb-2 checkbox">
                  <CheckBox
                    id={`winTwoNumber-three`}
                    value={!this.state.winTwoNumber}
                    onClick={(e) => {
                      this.setState({
                        winTwoNumber: !e.target.checked,
                        listNumber: [],
                      });
                    }}
                  />
                  <div className="body-4 ml-2">จับวิน 3 ตัว</div>
                </div>
                <div className="col-6 mb-2 checkbox">
                  <CheckBox
                    id={`winDouble`}
                    value={!this.state.winDouble}
                    onClick={(e) => {
                      this.setState({ winDouble: !e.target.checked });
                    }}
                  />
                  <div className="body-4 ml-2">จับวินไม่รวมเลขเบิ้ล</div>
                </div>
                <div className="col-6 mb-2 checkbox">
                  <CheckBox
                    id={`not-winDouble`}
                    value={this.state.winDouble}
                    onClick={(e) => {
                      this.setState({ winDouble: e.target.checked });
                    }}
                  />
                  <div className="body-4 ml-2">จับวินรวมเลขเบิ้ล</div>
                </div>
                <div className="col-12 mb-2">
                  <h6>กรุณาเลือกตัวเลขที่ต้องการจับ 2 - 7 ตัวเลข</h6>
                </div>
              </div>
            )}
            <div className="row">
              {this.state.modeBet === constants.modeBet[5].key && (
                <>
                  <div className="col-auto group-btn-winNumber">
                    {this.renderWinNumber(["1", "2", "3", "4", "5"])}
                    <br />
                    {this.renderWinNumber(["6", "7", "8", "9", "0"])}
                  </div>
                  <div className="col-auto calculate-winNumber">
                    <Button
                      id="calculate"
                      text="คำนวณ"
                      onClick={() => {
                        if (this.state.inputWinNumber.length >= 2) {
                          this.calculateWinNumber(this.state.inputWinNumber);
                        }
                      }}
                      textColor={colors.PRIMARY_BG}
                    />
                  </div>
                </>
              )}
              <div className="col-12 list-bet">
                {/* {
                  this.props.postPastePooiIsFetching && (
                    <ListNumberLoader
                      customStyle={[this.listLoaderNumber()]}
                    />
                  )
                } */}
                {this.state.listNumber.map((item, index) => {
                  let lengthNumber = this.state.modeBet === "TWO" || this.state.modeBet === "19DOOR"
                    ? 2
                    : this.state.modeBet === "THREE" || this.state.modeBet === "SIXBACK"
                      ? 3
                      : 1
                  return (
                    <div
                      className={((!isNaN(Number(item)) && item.length === lengthNumber) || this.state.modeBet === 'WINNUMBER') ? 'number-bet' : 'number-bet-error'}
                      key={index + "number-bet"}
                      onClick={() => {
                        let newListNumber = this.state.listNumber;
                        newListNumber.splice(index, 1);
                        this.setState({ listNumber: newListNumber });
                      }}
                    >
                      {item}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="col-auto cancel-list-bet">
            <Button
              id="cancel-list-bet"
              text="ยกเลิก"
              onClick={() => {
                this.checkNotAddBill(() => {
                  let up = this.errorMsgInputValue(
                    this.state.inputUp,
                    `${this.changeModeToType()}_UP` as TLottoGameType
                  );
                  let down = this.errorMsgInputValue(
                    this.state.inputDownToast,
                    `${this.changeModeToType()}_${this.changeModeToType() === "THREE" ? "TOAST" : "DOWN"
                    }` as TLottoGameType
                  );
                  // this.checkInputBet()
                  if (isEmpty(up)) {
                    this.setState({ errorInputUp: "" });
                  } else {
                    this.setState({ errorInputUp: up });
                  }
                  if (isEmpty(down)) {
                    this.setState({ errorInputDownToast: "" });
                  } else {
                    this.setState({ errorInputDownToast: down });
                  }
                  this.setState({ listNumber: [], inputWinNumber: "" });
                }, constants.tesxtConfirmCancel);
              }}
              textColor={colors.PRIMARY_BG}
              backgroundColor={colors.PRIMARY_RED}
              backgroundHoverColor={colors.PRIMARY_RED}
            >
              <ResponsiveIcon icon={Bin} alt="bin-icon" className="bin-icon" />
            </Button>

            {this.state.modeBet === constants.modeBet[5].key && (
              <div className="mt-1">
                <Button
                  id="back-number"
                  text="กลับเลขวิน"
                  onClick={() => {
                    this.calcBackWinNumber();
                  }}
                  textColor={colors.PRIMARY_GREEN}
                  backgroundColor={colors.TRANSPARENT}
                  backgroundHoverColor={colors.PRIMARY_GREEN}
                />
              </div>
            )}
          </div>
        </div>
        {(this.state.modeBet === constants.modeBet[0].key ||
          this.state.modeBet === constants.modeBet[1].key) && (
            <div className="btn-double mb-2">
              <Button
                id="btn-double"
                text={
                  this.state.modeBet === constants.modeBet[0].key
                    ? "เลขเบิ้ล"
                    : "เลขตอง"
                }
                onClick={() => {
                  let setData = this.state.listNumber;

                  this.setState(
                    {
                      listNumber: setData.concat(
                        this.state.modeBet === constants.modeBet[0].key
                          ? constants.numberDouble
                          : constants.numberTong
                      ),
                    },
                    () => {
                      // console.log(this.state.listNumber);
                    }
                    // , ()=>{ this.setState({listNumber:this.state.listNumber.filter((item, index)=>this.state.listNumber.indexOf(item) === index)}) }
                  );
                }}
                textColor={colors.PRIMARY_TEXT}
                backgroundColor={colors.TERTIARY_GREEN}
                disabled={
                  this.checkWaitingTime()
                  // || this.checkTimeout()
                }
              >
                <ResponsiveIcon
                  icon={Plus}
                  alt="plus-icon"
                  className="plus-icon"
                />
              </Button>
            </div>
          )}
        <div className="row input-number-prict">
          {this.state.modeBet !== "WINNUMBER" && (
            <div className="col">
              <div className="body-5 text-title mt-1">ใส่เลข</div>
              <Input
                disabled={
                  this.checkWaitingTime()
                  // || this.checkTimeout()
                }
                id="number"
                name="number"
                type="text"
                value={this.state.inputNumber}
                onPaste={(e) => {
                  const value = e.clipboardData.getData('text')
                  if (!isEmpty(value)) {
                    this.onPasteNumberBet(value)
                  }
                  e.preventDefault()
                }}
                onChange={(e) => {
                  this.setState(
                    { inputNumber: e.target.value.replace(/[^0-9]/g, "") },
                    () => {
                      let input = this.state.inputNumber;
                      let setData = this.state.listNumber;
                      if (this.state.modeBet === "TWO" && input.length === 2) {
                        setData = this.state.listNumber.concat([input]);
                        this.setState({ inputNumber: "" });
                      } else if (
                        this.state.modeBet === "THREE" &&
                        input.length === 3
                      ) {
                        setData = this.state.listNumber.concat([input]);
                        this.setState({ inputNumber: "" });
                      } else if (
                        this.state.modeBet === "SIXBACK" &&
                        input.length === 3
                      ) {
                        setData = this.state.listNumber.concat(
                          this.sixBack(input)
                        );
                        this.setState({ inputNumber: "" });
                      } else if (
                        this.state.modeBet === "19DOOR" &&
                        input.length === 1
                      ) {
                        setData = this.state.listNumber.concat(
                          this.loopNineteenDoor(input)
                        );
                        this.setState({ inputNumber: "" });
                      } else if (
                        this.state.modeBet === "RUNNUMBER" &&
                        input.length === 1
                      ) {
                        setData = this.state.listNumber.concat([input]);
                        this.setState({ inputNumber: "" });
                      }
                      this.setState({ listNumber: setData });
                    }
                  );
                }}
                ref={this.numberInputRef}
                errorMessage={
                  this.state.clickAddbill ? this.state.errorInputNumber : ""
                }
                error={
                  !isEmpty(
                    this.state.clickAddbill ? this.state.errorInputNumber : ""
                  )
                }
              // spaceeError
              />
            </div>
          )}
          {(this.state.modeBet === constants.modeBet[0].key ||
            this.state.modeBet === constants.modeBet[1].key) && (
              <div className="col-auto">
                <Button
                  id="back-number"
                  text="กลับเลข"
                  onClick={() => {
                    this.calcNumberBack();
                  }}
                  textColor={colors.PRIMARY_TEXT}
                  backgroundColor={colors.TERTIARY_GREEN}
                  disabled={
                    this.checkWaitingTime() ||
                    // || this.checkTimeout()
                    isEmpty(this.state.listNumber)
                  }
                  buttonClass={`${isEmpty(this.state.listNumber) && "disabled-back-number"
                    }`}
                />
              </div>
            )}
          <div
            className={`col ${this.state.modeBet !== "SIXBACK" ? "pr-0" : "pl-0"
              } ${this.state.modeBet !== "TWO" &&
              this.state.modeBet !== "THREE" &&
              this.state.modeBet !== "WINNUMBER" &&
              "pl-0"
              } `}
          >
            <div className="body-5 text-title text-center mt-1">
              {this.textLabelInputUp()}
            </div>
            <InputNumber
              id="inputUp"
              name="inputUp"
              decimalScale={0}
              placeholder={""}
              allowNegative={false}
              allowLeadingZeros={false}
              value={this.state.inputUp === 0 ? "" : String(this.state.inputUp)}
              onValueChange={(values) => {
                this.setState(
                  {
                    inputUp: values.value,
                  },
                  () => {
                    if (
                      (!isEmpty(values.value) || Number(values.value) !== 0) &&
                      isEmpty(
                        this.errorMsgInputValue(
                          this.state.inputDownToast,
                          `${this.changeModeToType()}_${this.changeModeToType() === "THREE"
                            ? "TOAST"
                            : "DOWN"
                          }` as TLottoGameType
                        )
                      )
                    ) {
                      this.setState({ errorInputDownToast: "" });
                    }
                    this.setState({
                      errorInputUp: this.errorMsgInputValue(
                        this.state.inputUp,
                        `${this.changeModeToType()}_UP` as TLottoGameType
                      ),
                    });
                  }
                );
              }}
              thousandSeparator
              getInputRef={this.inputUpRef}
              errorMessage={this.state.errorInputUp}
              error={!isEmpty(this.state.errorInputUp)}
              disabled={
                this.checkWaitingTime()
                // || this.checkTimeout()
              }
            />
          </div>
          {this.state.modeBet !== constants.modeBet[2].key && (
            <div className="col">
              <div className="body-5 text-title text-center mt-1">
                {this.textLabelInputDownToast()}
              </div>
              <InputNumber
                id="inputDownToast"
                name="inputDownToast"
                decimalScale={0}
                placeholder=""
                allowNegative={false}
                allowLeadingZeros={false}
                value={
                  this.state.inputDownToast === 0
                    ? ""
                    : String(this.state.inputDownToast)
                }
                onValueChange={(values) => {
                  this.setState(
                    {
                      inputDownToast: values.value,
                    },
                    () => {
                      if (
                        (!isEmpty(values.value) ||
                          Number(values.value) !== 0) &&
                        isEmpty(
                          this.errorMsgInputValue(
                            this.state.inputUp,
                            `${this.changeModeToType()}_UP` as TLottoGameType
                          )
                        )
                      ) {
                        this.setState({ errorInputUp: "" });
                      }
                      this.setState({
                        errorInputDownToast: this.errorMsgInputValue(
                          this.state.inputDownToast,
                          `${this.changeModeToType()}_${this.changeModeToType() === "THREE"
                            ? "TOAST"
                            : "DOWN"
                          }` as TLottoGameType
                        ),
                      });
                    }
                  );
                  // }
                }}
                thousandSeparator
                errorMessage={this.state.errorInputDownToast}
                error={!isEmpty(this.state.errorInputDownToast)}
                getInputRef={this.inputDownToastRef}
              />
            </div>
          )}
          <div className="col-auto add-bill">
            <Button
              id="add-bill"
              text="เพิ่มบิล"
              onClick={() => {
                this.addBill();
              }}
              textColor={colors.PRIMARY_TEXT}
              backgroundColor={colors.TERTIARY_GREEN}
              disabled={
                this.checkWaitingTime()
                // || this.checkTimeout()
              }
            >
              <ResponsiveIcon
                icon={Plus}
                alt="plus-icon"
                className="plus-icon"
              />
            </Button>
          </div>
        </div>

        <div className="box-bill">
          {isEmpty(this.state.listBill) ? (
            <h6 className="error-text">{this.state.errorBillList}</h6>
          ) : (
            this.state.listBill.map((item, index) => {
              let lableType = constants.modeBet.find(
                (element) => element.key === item.type
              );
              let textUpDownToast =
                item.type === "TWO" ||
                  item.type === "19DOOR" ||
                  (item.type === "WINNUMBER" &&
                    get(item.number, "[0].length") === 2)
                  ? "บน x ล่าง"
                  : item.type === "SIXBACK" ||
                    item.type === "THREE" ||
                    // (item.type==='WINNUMBER'&& get(item.number,"[0].length")===3)?'บน x ล่าง x โต๊ด':
                    (item.type === "WINNUMBER" &&
                      get(item.number, "[0].length") === 3)
                    ? "บน x โต๊ด"
                    : item.type === "RUNNUMBER"
                      ? "วิ่งบน x วิ่งล่าง"
                      : "";
              let valUpDownToast =
                item.type === "TWO" ||
                  item.type === "19DOOR" ||
                  item.type === "RUNNUMBER" ||
                  (item.type === "WINNUMBER" &&
                    get(item.number, "[0].length") === 2)
                  ? `${item.up} x ${item.down}`
                  : item.type === "SIXBACK" ||
                    item.type === "THREE" ||
                    // (item.type==='WINNUMBER'&& get(item.number,"[0].length")===3)?`${item.up} x ${item.down} x ${item.toast}`:''
                    (item.type === "WINNUMBER" &&
                      get(item.number, "[0].length") === 3)
                    ? `${item.up} x ${item.toast}`
                    : "";
              return (
                <div className="row item-bill" key={"bill" + index}>
                  <div className="col">
                    <div className="row bill">
                      <div className="col-2 header-bill">
                        <div className="body-4">{lableType?.label}</div>
                        <div className="body-4">{textUpDownToast}</div>
                        <div className="body-4">{valUpDownToast}</div>
                      </div>
                      <div className="col body-bill">
                        {item.number.map((number, index) => (
                          <div
                            className="body-4 number-bill"
                            key={index + number}
                          >
                            {number}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-auto">
                    <Button
                      id={"delete-bill" + index}
                      text="ลบ"
                      type="underline"
                      // disabled={this.state.AddNews === ""}
                      textColor={colors.PRIMARY_RED}
                      backgroundColor={colors.TRANSPARENT}
                      backgroundHoverColor={colors.TRANSPARENT}
                      onClick={() => {
                        ConfirmModal.show({
                          size: "md",
                          title: "ลบชุดการเดิมพัน",
                          description: "คุณแน่ใจหรือว่าจะลบข้อมูลดังกล่าว",
                          action: () => {
                            let newListBill = this.state.listBill;
                            newListBill.splice(index, 1);
                            this.setState({ listBill: newListBill }, () => {
                              this.setListBet();
                              // setTimeout(()=> this.iconCloseRef.current?.focus() ,1000)
                              ConfirmModal.hide();
                            });
                          },
                          cancelAction: () => {
                            // setTimeout(()=> this.iconCloseRef.current?.focus() ,300)
                            ConfirmModal.hide();
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              );
            })
          )}
        </div>
      </>
    );
  };

  renderTableHead = (values: Array<IHeaderLottoTable>) => {
    const mapHeadTable = map(
      values,
      (data: IHeaderLottoTable, index: number) => (
        <th className={`text-center`} key={index}>
          <h5 className="text-header secondary-text">{data.name}</h5>
        </th>
      )
    );
    return mapHeadTable;
  };

  listLottoRate = (): ILottoRateTable[] => {
    let data = get(this.props.lottoScheduleV2Data, "[0]");
    return [
      {
        id: 5,
        type: "THREE_UP",
        rate: get(data, "betRateThreeUp", 0).toFixed(2),
        minimum: `${number.decimalsFormatComma(
          get(data, "betMinThreeUp", 0),
          0
        )} - ${number.decimalsFormatComma(get(data, "betMaxThreeUp", 0), 0)}`,
        precent: get(data, "disThreeUp", 0).toFixed(2),
      },
      {
        id: 3,
        type: "THREE_TOAST",
        rate: get(data, "betRateThreeToast", 0).toFixed(2),
        minimum: `${number.decimalsFormatComma(
          get(data, "betMinThreeToast", 0),
          0
        )} - ${number.decimalsFormatComma(
          get(data, "betMaxThreeToast", 0),
          0
        )}`,
        precent: get(data, "disThreeToast", 0).toFixed(2),
      },
      {
        id: 4,
        type: "TWO_UP",
        rate: get(data, "betRateTwoUp", 0).toFixed(2),
        minimum: `${number.decimalsFormatComma(
          get(data, "betMinTwoUp", 0),
          0
        )} - ${number.decimalsFormatComma(get(data, "betMaxTwoUp", 0), 0)}`,
        precent: get(data, "disTwoUp", 0).toFixed(2),
      },
      {
        id: 1,
        type: "TWO_DOWN",
        rate: get(data, "betRateTwoDown", 0).toFixed(2),
        minimum: `${number.decimalsFormatComma(
          get(data, "betMinTwoDown", 0),
          0
        )} - ${number.decimalsFormatComma(get(data, "betMaxTwoDown", 0), 0)}`,
        precent: get(data, "disTwoDown", 0).toFixed(2),
      },
      {
        id: 6,
        type: "RUN_UP",
        rate: get(data, "betRateRunUp", 0).toFixed(2),
        minimum: `${number.decimalsFormatComma(
          get(data, "betMinRunUp", 0),
          0
        )} - ${number.decimalsFormatComma(get(data, "betMaxRunUp", 0), 0)}`,
        precent: get(data, "disRunUp", 0).toFixed(2),
      },
      {
        id: 2,
        type: "RUN_DOWN",
        rate: get(data, "betRateRunDown", 0).toFixed(2),
        minimum: `${number.decimalsFormatComma(
          get(data, "betMinRunDown", 0),
          0
        )} - ${number.decimalsFormatComma(get(data, "betMaxRunDown", 0), 0)}`,
        precent: get(data, "disRunDown", 0).toFixed(2),
      },
    ];
  };

  renderTableRateBody = (values: ILottoRateTable[]) => {
    const dataEmpty = (
      <tr>
        <td className="text-center" colSpan={7}>
          {constants.dataNotFound}
        </td>
      </tr>
    );
    const mapData = map(values, (data: ILottoRateTable, index: number) => {
      const commonClass = this.props.lottoScheduleV2IsFetching ? 'tertiary-text' : ''
      return (
        <tr
          className={`row-rate-list primary-bg`}
          key={index}
        >
          <td className={`text-center ${commonClass}`}>
          <span>{get(CODE_TO_TYPENAME_NUMBER, data.type)}</span>
          </td>
           <td className={`text-center ${commonClass}`}>
            <span>{number.decimalsFormatComma(data.rate)}</span>
          </td>
           <td className={`text-center ${commonClass}`}>
            <span>{data.precent}</span>
          </td>
           <td className={`text-center ${commonClass}`}>
            <span>{data.minimum}</span>
          </td>
        </tr>
      )
    })
    const loadingTable= (
      <tr>
        <td className="text-center" colSpan={7}>
        <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
        </td>
      </tr>
    )
    return this.props.lottoScheduleV2IsFetching ? loadingTable : !isEmpty(values) ? mapData : dataEmpty
  };

  listLottoHistory = (): ILottoHistoryTable[] => {
    const list = this.props.getV2ResultData.dataList.filter(
      (item, index) => index <= 4
    );
    return list.map((item) => {
      const findType = (type: TLottoGameType) =>
        item.lottoResult.find((element) => element.type === type);
      return {
        date: date.formatThai(item.createdAt, 'd MMM yy'),
        threeup: get(findType("THREE_UP"), "number", ""),
        twoup:
          get(findType("THREE_UP"), "number[1]", "") +
          get(findType("THREE_UP"), "number[2]", ""),
        twodown: get(findType("TWO_DOWN"), "number", ""),
      };
    });
  };

  renderTableHistoryBody = (values: ILottoHistoryTable[]) => {
    const dataEmpty = (
      <tr>
        <td className="text-center" colSpan={7}>
          {constants.dataNotFound}
        </td>
      </tr>
    )
    const loadingTable= (
      <tr>
        <td className="text-center" colSpan={7}>
        <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
        </td>
      </tr>
    )
    const mapData = map(values, (data: ILottoHistoryTable, index: number) => {
      return (
        <tr
          className={`row-history-list primary-bg`}
          key={index}
        >
          <td className="text-center">
            <span>
              {transformer.nameLotto(
                this.props.location.state.targetLotto.name,
                true,
                true
              )}
            </span>
          </td>
          <td className="text-center">
            <span>{data.date}</span>
          </td>
          <td className="text-center">
            <span>{data.threeup}</span>
          </td>
          <td className="text-center">
            <span>{data.twoup}</span>
          </td>
          <td className="text-center">
            <span>{data.twodown}</span>
          </td>
        </tr>
      );
    })
    return this.props.getV2ResultIsFetching ? loadingTable : !isEmpty(values) ? mapData : dataEmpty
  };

  renderTablePopularBody = (values: ILottoPopularTable[]) => {
    const dataEmpty = (
      <tr>
        <td className="text-center" colSpan={7}>
          {constants.dataNotFound}
        </td>
      </tr>
    );
    const mapData = map(values, (data: ILottoPopularTable, index: number) => {
      return (
        <tr
          className={`row-popular-list primary-bg`}
          key={index}
        // onClick={() => this.handleClickUser(data)}
        >
          <td className="text-center">
            <span>{get(CODE_TO_TYPENAME_NUMBER, data.type)}</span>
          </td>
          <td className="text-center">
            <span>
              {data.sale.map(
                (item, index) => `${index !== 0 ? "," + item : item}`
              )}
            </span>
          </td>
          <td className="text-center">
            <span>
              {data.close.map(
                (item, index) => `${index !== 0 ? "," + item : item}`
              )}
            </span>
          </td>
        </tr>
      );
    });
    const renderBody = !isEmpty(values) ? mapData : dataEmpty;
    return renderBody;
  };

  listLottoBill = (): ILottoBillTable[] => {
    return this.props.betGroupV2Data.dataList.map((item, index) => {
      return {
        number: item.agentShareId.toString(),
        customerId: item.customerId,
        time: item.createdAt,
        market: transformer
          .nameLotto(item.name, false, true)
          .replace("หวย", ""),
        item: item.totalCountBet.toString(),
        bth: item.totalBet.toFixed(2).toString(),
        comment: item.customerName.toString(),
        status: item.customerPayStatus,
        name: item.name,
      };
    });
  };

  renderTableBillBody = (values: ILottoBillTable[]) => {
    const dataEmpty = (
      <tr>
        <td className="text-center" colSpan={7}>
          {constants.dataNotFound}
        </td>
      </tr>
    )
    const loadingTable= (
      <tr>
        <td className="text-center" colSpan={7}>
        <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
        </td>
      </tr>
    )
    const mapData = map(values, (data: ILottoBillTable, index: number) => {
      return (
        <tr
          className={`row-bill-list ${data.status === "CANCEL" ? 'secondary-red-bg' : 'primary-bg'}`}
          key={index}
        // onClick={() => this.handleClickUser(data)}
        >
          <td className="text-center">
            <span>
              {
                data.status === "CANCEL" && (
                  <FontAwesomeIcon
                    id={"cancel" + index}
                    className={`num-icon`}
                    icon={faTimes}
                  />
                )
              }
              {data.number}
            </span>
          </td>
          <td className="text-center">
            <span>
              {date.formatThai(data.time, 'd MMM yy HH:mm')}
            </span>
          </td>
          <td className="text-center">
            <span>{data.market}</span>
          </td>
          <td className="text-center">
            <span>{data.item}</span>
          </td>
          <td className="text-center">
            <span>{number.decimalsFormatComma(data.bth)}</span>
          </td>
          <td className="text-center">
            <span>{data.comment}</span>
          </td>
          <td className="text-center">
            <span>
              <FontAwesomeIcon
                id={"cancel" + index}
                className={`bin-icon ${data.status === "CANCEL" ? 'disable' : ''}`}
                icon={faTrash}
                onClick={() => {
                  if (data.status === "WAIT" || data.status === 'SUCCESS') {
                    CancelBetModal.show({
                      size: "md",
                      title: constants.cancleBet,
                      description: constants.confirmCancleBet,
                      action: () => {
                        this.props.postCancelBill({
                          agentShareId: Number(data.number),
                          customerId: data.customerId,
                          name: data.name,
                          mode: 'CANCEL_PER_CLOTTERY'
                        });
                        CancelBetModal.hide();
                      },
                      cancelAction: CancelBetModal.hide,
                    });
                  }

                }}
              />
            </span>
          </td>
        </tr>
      );
    });
    return this.props.betGroupV2IsFetching ? loadingTable : !isEmpty(values) ? mapData : dataEmpty
  };

  listLottoConfirmฺBill = (): ILottoConfirmBillTable[] => {
    const allNumberBanList = this.getNumberBanList()
    let data = this.state.listNumberBet.map((item) => {
      const foundNumberBan = allNumberBanList.find(
        (num) => num.number === item.number && num.type === item.type
      )
      let dis = get(
        this.props.lottoScheduleV2Data,
        `[0].${camelCase("dis" + item.type)}`,
        0
      );
      let rate = 0
      if (foundNumberBan) {
        rate = foundNumberBan.rate
      } else {
        rate = get(
          this.props.lottoScheduleV2Data,
          `[0].${camelCase("bet_rate" + item.type)}`,
          0
        );
      }
      let calcDis = (item.money * dis) / 100;
      return {
        type: item.type,
        number: item.number,
        bet: item.money,
        rate: rate,
        discount: calcDis,
        total: item.money - calcDis,
      };
    });
    return data;
  };

  renderTableConfirmBillBody = (values: ILottoConfirmBillTable[]) => {
    const dataEmpty = (
      <tr>
        <td className="text-center" colSpan={7}>
          {constants.dataNotFound}
        </td>
      </tr>
    );
    const mapData = map(
      values,
      (data: ILottoConfirmBillTable, index: number) => {
        return (
          <tr
            className={`row-bill-confirm ${index % 2 === 0 ? "row-color" : ""}`}
            key={index}
          // onClick={() => this.handleClickUser(data)}
          >
            <td className="text-center">
              <span>{get(CODE_TO_TYPENAME, data.type, "")}</span>
            </td>
            <td className="text-center">
              <span>{data.number}</span>
            </td>
            <td className="text-center">
              <span style={{
                color: data.bet > (get(
                  this.props.lottoScheduleV2Data,
                  `[0].${camelCase("bet_max_" + data.type)}`,
                  0
                )) ? colors.PRIMARY_RED : ''
              }}>{
                  data.bet > (get(
                    this.props.lottoScheduleV2Data,
                    `[0].${camelCase("bet_max_" + data.type)}`,
                    0
                  )) ? (number.decimalsFormatComma(data.bet) + ` ( แทงได้สูงสุด ${(number.decimalsFormatComma(get(
                    this.props.lottoScheduleV2Data,
                    `[0].${camelCase("bet_max_" + data.type)}`,
                    0
                  )))} บาท )`) : number.decimalsFormatComma(data.bet)

                  // 
                }
              </span>
            </td>
            <td className="text-center">
              <span>{number.decimalsFormatComma(data.rate)}</span>
            </td>
            <td className="text-center">
              <span>{number.decimalsFormatComma(data.discount)}</span>
            </td>
            <td className="text-center">
              <span>{number.decimalsFormatComma(data.total)}</span>
            </td>
            <td className="text-center">
              <span className="cancel-bill-confirm">
                <Button
                  id="cancel-bill-confirm"
                  text=""
                  onClick={() => {
                    ConfirmModal.show({
                      size: "md",
                      title: "ลบเลขเดิมพัน",
                      description: "คุณแน่ใจหรือว่าจะลบข้อมูลดังกล่าว",
                      action: () => {
                        this.state.listNumberBet.splice(index, 1);
                        this.setState(
                          { listNumberBet: this.state.listNumberBet },
                          () => {
                            ConfirmModal.hide();
                            if (this.state.listNumberBet.length === 0) {
                              this.setState({ previewMode: false });
                              this.setListBet();
                            } else {
                              this.setState({ isOverBetMax: 0 }, () => {
                                this.state.listNumberBet.map((item) => {
                                  if (item.money > (get(
                                    this.props.lottoScheduleV2Data,
                                    `[0].${camelCase("bet_max_" + item.type)}`,
                                    0
                                  ))) {
                                    this.setState((prevState: INewBetMakingContainerState) => ({
                                      isOverBetMax: prevState.isOverBetMax + 1,
                                    }))
                                  }
                                  return ''
                                })
                              })

                            }
                          }
                        );
                      },
                      cancelAction: () => {
                        ConfirmModal.hide();
                      },
                    });
                  }}
                  textColor={colors.PRIMARY_BG}
                  backgroundColor={colors.PRIMARY_RED}
                  backgroundHoverColor={colors.PRIMARY_RED}
                >
                  <ResponsiveIcon
                    icon={Bin}
                    alt="bin-icon"
                    className="bin-icon"
                  />
                </Button>
              </span>
            </td>
          </tr>
        );
      }
    );
    const renderBody = !isEmpty(values) ? mapData : dataEmpty;
    return renderBody;
  };

  totalPrice = () => {
    let price = 0;
    if (this.state.activeMenu === constants.pastePooi) {
      this.state.listBill.map((item) => {
        return (price =
          price +
          (Number(item.type === 'THREE' ? 0 : item.down) + Number(item.up) + Number((item.type === 'TWO' || item.type === 'RUNNUMBER') ? 0 : item.toast)) *
          item.number.length);
      });
    }
    else {
      this.state.listBill.map((item) => {
        return (price =
          price +
          (Number(item.type === 'THREE' ? 0 : item.down) + Number(item.up) + Number((item.type === 'TWO' || item.type === 'RUNNUMBER') ? 0 : item.toast)) *
          item.number.length);
      });
    }
    return `รวม ${number.decimalsFormatComma(price.toString())} บาท`;
  };

  handleOnClickBreadcrumb = (path: string) => {
    if (path === "back-bet-lotto") {
      this.setState({ previewMode: false });
    } else {
      this.props.history.replace(path);
    }
  };

  findFirstRepeatedChar(s: string) {
    for (let i = 0; i < s.length; i++) {
      if (s[i] === " " && s[i + 1] === " ") {
        return true;
      }
    }

    return false;
  }

  checkRemark = () => {
    if (isEmpty(this.state.remark)) {
      this.setState({ errorRemark: constants.errorRemark });
    }
    else {
      this.setState({ errorRemark: "" });
    }
  }

  checkListBill = () => {
    if (isEmpty(this.state.listBill)) {
      this.setState({ errorBillList: constants.errorListBill });
    } else {
      this.setState({ errorBillList: "" });
    }
  };

  description = () => {
    let dataLotto = get(this.props.lottoScheduleV2Data, "[0]", "");
    let checkWait =
      (dataLotto as any).isOpen &&
      (dataLotto as any).status === "OPEN" &&
      !isEmpty((dataLotto as any).startTime) &&
      this.checkWaitingTime();
    return isEmpty((dataLotto as any).endTime)
      ? "-"
      : date.formatThai(checkWait ? (dataLotto as any).startTime : (dataLotto as any).endTime, 'dd MMM yy')
  };

  renderLandingMenu = () => {
    const mappingMenu = map(constants.menuList, (name: string) => (
      <div
        className={`p4-x normal-menu m1-r ${name === 'ตาราง' ? 'disable-menu' : this.state.activeMenu === name ? "active-menu" : ""
          }`}
        key={`${name}`}
        id={`${name}`}
        onClick={() => {
          if (this.state.activeMenu !== name && name !== 'ตาราง') {
            this.checkNotAddBill(
              () =>
                this.setState({
                  // modeBet: item.key,
                  listNumber: [],
                  listNumberTemp: [],
                  inputUp: "",
                  inputDown: "",
                  inputToast: "",
                  inputDownToast: "",
                  inputNumber: "",
                  inputWinNumber: "",
                  errorInputDownToast: "",
                  errorInputUp: "",
                  errorInputDown: "",
                  errorInputToast: "",
                  errorInputNumber: "",
                  activeMenu: name,
                  textAreaValue: "",
                }),
              constants.tesxtConfirmNotAddBillDescription
            );
          }
        }}
      >
        <div className={`d-flex flex-column`}>
          <h6 style={name === 'ตาราง' ? { color: '#A7ABB7' } : {}}>
            {name}</h6>
        </div>
      </div>
    ));
    return <div className="row p2-l disable">{mappingMenu}</div>
  }

  extractNumberBet(rawData: string) {
    let regexData = rawData.replace(/\r?\n/g, "\n")
    const resp: IV2RespExtractReplaceLotteryNumber[] = []
    let stackD = ''
    let stackT = ''
    for (let i = 0; i < regexData.length; i++) {
      if (/(\s|\r)/.test(regexData[i])) {
        if (stackD) {
          resp.push({ extractData: stackD, isValid: stackD.length <= 3 ? true : false })
          stackD = ''
        }
        if (stackT) {
          resp.push({ extractData: stackT, isValid: false })
          stackT = ''
        }
        continue
      }
      if (/\d/.test(regexData[i])) {
        stackD += regexData[i]
      } else {
        stackT += regexData[i]
      }
      if (/\d/.test(regexData[i]) && /\D/.test(regexData[i + 1])) {
        resp.push({ extractData: stackD, isValid: stackD.length <= 3 ? true : false })
        stackD = ''
        stackT = ''
      } else if (/\D/.test(regexData[i]) && /\d/.test(regexData[i + 1])) {
        resp.push({ extractData: stackT, isValid: false })
        stackD = ''
        stackT = ''
      } else if (!regexData[i + 1] && stackD.length > 0) {
        resp.push({ extractData: stackD, isValid: stackD.length <= 3 ? true : false })
        stackD = ''
        stackT = ''
      } else if (!regexData[i + 1] && stackT.length > 0) {
        resp.push({ extractData: stackT, isValid: false })
        stackD = ''
        stackT = ''
      }
    }
    return resp
  }

  onPasteNumberBet(event: string) {
    this.setState({
      textAreaValue: event,
    }
      , () => {
        if (!isEmpty(this.state.textAreaValue)) {
          this.setState({ listNumberTemp: this.extractNumberBet(event) }, () => {
            if ((this.state.modeBet === "SIXBACK" || this.state.modeBet === "19DOOR") && this.state.activeMenu !== constants.pastePooi) {
              const keyExtractor = (item: IV2RespExtractReplaceLotteryNumber) => item.extractData
              const validLengthCondition = (item: IV2RespExtractReplaceLotteryNumber) => item.extractData.length === (this.state.modeBet === "SIXBACK" ? 3 : 1)
              const validNumberCondition = (item: IV2RespExtractReplaceLotteryNumber) => !isNaN(Number(item.extractData))
              const modeSpecificFunction = this.state.modeBet === "SIXBACK" ? this.sixBack : this.loopNineteenDoor

              const filteredNumbers = this.state.listNumberTemp
                .filter(validLengthCondition)
                .filter(validNumberCondition)
                .flatMap((item) => modeSpecificFunction(item.extractData))

              const numListEx = this.state.listNumberTemp.map(keyExtractor)

              const result = [...filteredNumbers, ...numListEx.filter((item) => {
                return !filteredNumbers.includes(item) 
              })]
              this.setState({ listNumber:  this.state.listNumber.concat(result)})
            }
            else if (this.state.activeMenu === constants.pastePooi) {
              this.setState({ listNumber: this.state.listNumberTemp.map(number => number.extractData) })
            }
            else {
              this.setState({ listNumber: [...this.state.listNumber].concat(this.state.listNumberTemp.map(number => number.extractData)) })
            }
          })
        } else {
          this.setState({ listNumber: [], listNumberTemp: [] })
        }
      })
  }

  render() {
    let lottoCode: TLottoSlug = this.props.location.state.targetLotto.name;
    let targetLotto = this.props.location.state.targetLotto;

    const TableHeadConfirmBill = this.renderTableHead(mappingTheadConfirmฺBill);
    const TableBodyeConfirmBill = this.renderTableConfirmBillBody(
      this.listLottoConfirmฺBill()
    );

    const TableHeadRate = this.renderTableHead(mappingTheadLottoRate);
    const TableBodyRate = this.renderTableRateBody(this.listLottoRate());

    const TableHeadHistory = this.renderTableHead(mappingTheadLottoHistory);
    const TableBodyHistory = this.renderTableHistoryBody(
      this.listLottoHistory()
    );

    const TableHeadPopular = this.renderTableHead(mappingTheadLottoPopular);
    const TableBodyePopular = this.renderTablePopularBody(listLottoPopular);

    const TableHeadBill = this.renderTableHead(mappingTheadLottoฺBill);
    const TableBodyeBill = this.renderTableBillBody(this.listLottoBill());

    const nameLotto = get(
      CODE_TO_NAME,
      includes(this.props.location.state.targetLotto.name, "YEGEE")
        ? "YEGEE"
        : this.props.location.state.targetLotto.name
    );

    const RenderLadingMenu = this.renderLandingMenu

    const navigates: IBreadcrumbItem[] = [
      { label: constants.mainPageTitle, active: false, path: "/dashboard" },
      // { label: constants.bet, active: false, path: "/bet" },
      { label: constants.betSubTitle, active: false, path: "/bet" },
      { label: nameLotto, active: true },
    ];

    const navigatesConfirm: IBreadcrumbItem[] = [
      { label: constants.mainPageTitle, active: false, path: "/dashboard" },
      // { label: constants.bet, active: false, path: "/bet" },
      { label: constants.betSubTitle, active: false, path: "/bet" },
      { label: nameLotto, active: false, path: "back-bet-lotto" },
      { label: constants.confirmTitle, active: true },
    ];

    return (
      <div className="new-betMaking-container" ref={this.container}>
        <div className="paper-container">
          <div className="paper-body">
            <div className="row">
              <div className="col-6">
                <div
                  className="d-flex sub-menu"
                  style={{ width: "fit-content" }}
                >
                  <Breadcrumb
                    items={
                      this.state.previewMode ? navigatesConfirm : navigates
                    }
                    handleOnClickItem={this.handleOnClickBreadcrumb}
                  />
                </div>
                <div className="paper-title">
                  {this.state.previewMode
                    ? constants.confirmTitle
                    : constants.betSubTitle}
                </div>
                <div className="paper-description">
                  {
                    CODE_TO_NAME[
                    (includes(targetLotto.name, "YEGEE")
                      ? "YEGEE"
                      : targetLotto.name) as TLottoType
                    ]
                  }
                </div>
                <div
                  className={`advice ${!this.state.isShowAdvice && "isHide"}`}
                >
                  <div className="row-spacebar justify-content-between">
                    <h6 className="advice-text">คำแนะนำ</h6>
                    <div className="d-flex">
                      <button
                        id="icon-close"
                        className="btn-icon-close"
                        ref={this.iconCloseRef}
                        onClick={() => {
                          this.setState({ isShowAdvice: false });
                        }}
                      >
                        {" "}
                        <img
                          className="icon-close"
                          alt="icon-close"
                          src={Plus}
                        />{" "}
                      </button>
                    </div>
                  </div>
                  <div className="row-spacebar mt-2">
                    <div className="key-box enter">
                      Enter
                      <div className="key-box enter2"></div>
                    </div>
                    <div className="key-box tap ml-2">
                      <div className="text-tap">TAB</div>
                      <img className="icon-tap" alt="icon-tap" src={Tap} />
                    </div>
                    <h6 className="body-5 ml-3">
                      กดปุ่ม Enter/Tab เพื่อขยับช่องกรอกข้อมูล
                    </h6>
                  </div>
                  <div className="row-spacebar mt-2">
                    <div className="key-box">Space bar</div>
                    <h6 className="body-5 ml-3">
                      กดปุ่ม Spacebar เพื่อกลับเลข
                    </h6>
                  </div>
                </div>
                <div className="countdown-time mt-3">
                  <ResponsiveIcon
                    icon={Clock}
                    alt="clock"
                    className="clock-lotto-icon m1-r"
                  />
                  <div className="body-4-red mr-1">
                    {this.checkWaitingTime()
                      ? "กำลังเปิดในอีก: "
                      : "เวลาคงเหลือ: "}
                  </div>
                  <div className="body-4-red">{this.statusText()}</div>
                </div>

                <div className="detail-lotto">
                  <ResponsiveIcon
                    icon={get(targetLotto, "icon", "")}
                    alt="flag"
                    className={`lotto-flag ${Object.keys(collectBank).findIndex(
                      (item) => item === targetLotto.name
                    ) === -1
                      ? ""
                      : "flag-bank"
                      }`}
                  />
                  <div className="lotto-type">
                    <div>
                      {transformer.nameLotto(targetLotto.name, true, true)}
                    </div>
                    <div>งวด {this.description()}</div>
                  </div>
                </div>

                {!this.state.previewMode ? (
                  <div className="box-shadow-paste-pooi">
                    <div className="menu-list ">
                      <RenderLadingMenu />
                    </div>
                    {this.state.activeMenu === "แทงเร็ว" ? (
                      <div
                        className={`fast-bet ${this.checkWaitingTime() &&
                          // || this.checkTimeout()
                          "disable"
                          }`}
                      >
                        {this.checkWaitingTime() && (
                          // || this.checkTimeout()
                          <div className="disable-box"></div>
                        )}
                        <h4>แทงเร็ว</h4>
                        <h6>
                          {get(
                            CODE_TO_NAME,
                            lottoCode.includes("YEGEE") ? "YEGEE" : lottoCode,
                            ""
                          )}
                        </h6>
                        {this.fastBet()}
                      </div>
                    ) : this.state.activeMenu === "ตาราง" ? (
                      "-"
                    ) : (
                      <div className="paste-pooi">
                        <p style={{ fontSize: 12, marginBottom: 2 }}>{constants.pastePooi}</p>
                        <textarea
                          id={'inputTextArea'}
                          rows={4}
                          cols={58}
                          placeholder={constants.pastePooi}
                          onChange={(e) => this.onPasteNumberBet(e.target.value)}
                          value={this.state.textAreaValue}
                          ref={this.inputTextArea}
                        />
                        <div className="row box-list-bet">
                          <div className="col list-bet">
                            {/* {
                              this.props.postPastePooiIsFetching && (
                                <ListNumberLoader
                                  customStyle={[this.listLoaderNumber()]}
                                />
                              )
                            } */}
                            {this.state.listNumberTemp.map((item, index) => {
                              return (
                                <div
                                  className={item.isValid === true ? "number-bet" : "number-bet-error"}
                                  key={index + "number-bet"}
                                  onClick={() => {
                                    let newListNumberTemp = this.state.listNumberTemp;
                                    newListNumberTemp.splice(index, 1);
                                    this.setState({
                                      listNumberTemp: newListNumberTemp,
                                      listNumber: this.state.listNumberTemp.map(number => number.extractData)
                                    });
                                  }}
                                >
                                  {item.extractData}
                                </div>
                              )
                            })}
                          </div>
                        </div>

                        <div className="row input-number-prict">
                          <div className="col">
                            <div className="body-5 text-title text-center mt-1">
                              บน
                            </div>
                            <InputNumber
                              id="inputUp"
                              name="inputUp"
                              decimalScale={0}
                              placeholder={""}
                              allowNegative={false}
                              allowLeadingZeros={false}
                              value={
                                this.state.inputUp === 0
                                  ? ""
                                  : String(this.state.inputUp)
                              }
                              onValueChange={(values) => {
                                this.setState(
                                  {
                                    inputUp: values.value,
                                  },
                                );
                              }}
                              thousandSeparator
                              getInputRef={this.inputUpRef}
                              errorMessage={this.state.errorInputUp}
                              error={!isEmpty(this.state.errorInputUp)}
                              disabled={
                                this.checkWaitingTime()
                                // || this.checkTimeout()
                              }
                            />
                          </div>
                          <div className={`col px-0 `}>
                            <div className="body-5 text-title text-center mt-1">
                              ล่าง
                            </div>
                            <InputNumber
                              id="inputDown"
                              name="inputDown"
                              decimalScale={0}
                              placeholder={""}
                              allowNegative={false}
                              allowLeadingZeros={false}
                              value={
                                this.state.inputDown === 0
                                  ? ""
                                  : String(this.state.inputDown)
                              }
                              onValueChange={(values) => {
                                this.setState(
                                  {
                                    inputDown: values.value,
                                  },
                                );
                              }}
                              thousandSeparator
                              getInputRef={this.inputDownRef}
                              errorMessage={this.state.errorInputDown}
                              error={!isEmpty(this.state.errorInputDown)}
                              disabled={
                                this.checkWaitingTime()
                                // || this.checkTimeout()
                              }
                            />
                          </div>
                          <div className="col">
                            <div className="body-5 text-title text-center mt-1">
                              โต๊ด
                            </div>
                            <InputNumber
                              id="inputToast"
                              name="inputToast"
                              decimalScale={0}
                              placeholder=""
                              allowNegative={false}
                              allowLeadingZeros={false}
                              value={
                                this.state.inputToast === 0
                                  ? ""
                                  : String(this.state.inputToast)
                              }
                              onValueChange={(values) => {
                                this.setState(
                                  {
                                    inputToast: values.value,
                                  },
                                );
                                // }
                              }}
                              thousandSeparator
                              errorMessage={this.state.errorInputToast}
                              error={!isEmpty(this.state.errorInputToast)}
                              getInputRef={this.inputToastRef}
                            />
                          </div>

                          <div className="col-auto add-bill">
                            <Button
                              id="add-bill"
                              text="เพิ่มบิล"
                              onClick={() => {
                                this.addBill();
                              }}
                              textColor={colors.PRIMARY_TEXT}
                              backgroundColor={colors.TERTIARY_GREEN}
                              disabled={
                                this.checkWaitingTime()
                                // || this.checkTimeout()
                              }
                            >
                              <ResponsiveIcon
                                icon={Plus}
                                alt="plus-icon"
                                className="plus-icon"
                              />
                            </Button>
                          </div>
                        </div>

                        <div className="box-bill">
                          {isEmpty(this.state.listBill) ? (
                            <h6 className="error-text">
                              {this.state.errorBillList}
                            </h6>
                          ) : (
                            this.state.listBill.map((item, index) => {
                              let lableType = constants.modeBet.find(
                                (element) => element.key === item.type
                              );
                              let textUpDownToast =
                                item.type === "TWO" ||
                                  item.type === "19DOOR" ||
                                  (item.type === "WINNUMBER" &&
                                    get(item.number, "[0].length") === 2)
                                  ? "บน x ล่าง"
                                  : item.type === "SIXBACK" ||
                                    item.type === "THREE" ||
                                    // (item.type==='WINNUMBER'&& get(item.number,"[0].length")===3)?'บน x ล่าง x โต๊ด':
                                    (item.type === "WINNUMBER" &&
                                      get(item.number, "[0].length") === 3)
                                    ? "บน x โต๊ด"
                                    : item.type === "RUNNUMBER"
                                      ? "วิ่งบน x วิ่งล่าง"
                                      : "";
                              let valUpDownToast =
                                item.type === "TWO" ||
                                  item.type === "19DOOR" ||
                                  item.type === "RUNNUMBER" ||
                                  (item.type === "WINNUMBER" &&
                                    get(item.number, "[0].length") === 2)
                                  ? `${item.up} x ${item.down}`
                                  : item.type === "SIXBACK" ||
                                    item.type === "THREE" ||
                                    // (item.type==='WINNUMBER'&& get(item.number,"[0].length")===3)?`${item.up} x ${item.down} x ${item.toast}`:''
                                    (item.type === "WINNUMBER" &&
                                      get(item.number, "[0].length") === 3)
                                    ? `${item.up} x ${item.toast}`
                                    : "";
                              return (
                                <div
                                  className="row item-bill"
                                  key={"bill" + index}
                                >
                                  <div className="col">
                                    <div className="row bill">
                                      <div className="col-2 header-bill">
                                        <div className="body-4">
                                          {lableType?.label}
                                        </div>
                                        <div className="body-4">
                                          {textUpDownToast}
                                        </div>
                                        <div className="body-4">
                                          {valUpDownToast}
                                        </div>
                                      </div>
                                      <div className="col body-bill">
                                        {item.number.map((number, index) => (
                                          <div
                                            className="body-4 number-bill"
                                            key={index + number}
                                          >
                                            {number}
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-auto">
                                    <Button
                                      id={"delete-bill" + index}
                                      text="ลบ"
                                      type="underline"
                                      // disabled={this.state.AddNews === ""}
                                      textColor={colors.PRIMARY_RED}
                                      backgroundColor={colors.TRANSPARENT}
                                      backgroundHoverColor={colors.TRANSPARENT}
                                      onClick={() => {
                                        ConfirmModal.show({
                                          size: "md",
                                          title: "ลบชุดการเดิมพัน",
                                          description:
                                            "คุณแน่ใจหรือว่าจะลบข้อมูลดังกล่าว",
                                          action: () => {
                                            let newListBill = this.state
                                              .listBill;
                                            newListBill.splice(index, 1);
                                            this.setState(
                                              { listBill: newListBill },
                                              () => {
                                                this.setListBet();
                                                // setTimeout(()=> this.iconCloseRef.current?.focus() ,1000)
                                                ConfirmModal.hide();
                                              }
                                            );
                                          },
                                          cancelAction: () => {
                                            // setTimeout(()=> this.iconCloseRef.current?.focus() ,300)
                                            ConfirmModal.hide();
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              );
                            })
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="confirm">
                    <h4>กรุณายืนยันรายการ</h4>
                    <h5>
                      {get(
                        CODE_TO_NAME,
                        lottoCode.includes("YEGEE") ? "YEGEE" : lottoCode,
                        ""
                      )}
                    </h5>
                    <div className="table-container-confirm mt-2">
                      <div className="table-responsive-xl">
                        <table className="w-100">
                          <thead className="header-table-lotto-confirm">
                            <tr>{TableHeadConfirmBill}</tr>
                          </thead>
                          <tbody>{TableBodyeConfirmBill}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
                {!this.state.previewMode ? (
                  <>
                    <div className="comment">
                      <div
                        className={`text-nowrap title-comment body-5 mr-2 ${this.checkWaitingTime() &&
                          // || this.checkTimeout()
                          "gray-text"
                          }`}
                      >
                        ชื่อช่วยจำ(ชื่อลูกค้า):{" "}
                      </div>
                      <Input
                        id="comment"
                        name="comment"
                        type="text"
                        // label={constants.inputSearch}
                        // placeholder={constants.inputSearch}
                        value={this.state.remark}
                        onChange={(e) => {
                          this.setState({ remark: e.target.value }, () => {
                            this.checkRemark();
                            // this.functionCheckSpace(this.state.remark)
                          });
                        }}
                        onBlur={() => {
                          this.checkRemark();
                        }}
                        errorMessage={this.state.errorRemark}
                        renderError={true}
                        error={!isEmpty(this.state.errorRemark)}
                        disabled={
                          this.checkWaitingTime()
                          // || this.checkTimeout()
                        }
                      />
                    </div>
                    <div className="summary">
                      <h4
                        className={`${this.checkWaitingTime() &&
                          // || this.checkTimeout()
                          "disable-text"
                          }`}
                      >{`${CODE_TO_NAME[
                        (includes(targetLotto.name, "YEGEE")
                          ? "YEGEE"
                          : targetLotto.name) as TLottoType
                      ]
                        } - ${this.description()}`}</h4>
                      <h4
                        className={`${this.checkWaitingTime() &&
                          // || this.checkTimeout()
                          "disable-text"
                          }`}
                      >
                        {this.totalPrice()}
                      </h4>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="summary mt-3">
                      <h4>{`${this.state.listNumberBet.length} รายการ`}</h4>
                      <h4>{`ยอดเดิมพัน ${number.decimalsFormatComma(
                        sumBy(this.listLottoConfirmฺBill(), "bet")
                      )} บาท`}</h4>
                      <h4>
                        {`ส่วนลด `}
                        {
                          <span className="primary-red-text">
                            {number.decimalsFormatComma(
                              sumBy(this.listLottoConfirmฺBill(), "discount")
                            )}
                          </span>
                        }
                        {` บาท`}
                      </h4>
                      <h4>
                        {`รวม `}
                        {
                          <span className="secondary-purple-text">
                            {number.decimalsFormatComma(
                              sumBy(this.listLottoConfirmฺBill(), "bet") -
                              sumBy(this.listLottoConfirmฺBill(), "discount")
                            )}
                          </span>
                        }
                        {` บาท`}
                      </h4>
                      <h4 className="body-1 text-remark">{`ชื่อช่วยจำ: ${get(
                        this.state.listNumberBet,
                        "[0].remark",
                        ""
                      )}`}</h4>
                    </div>
                  </>
                )}
                <div className="summary-btn">
                  <div className="clear-bill">
                    <Button
                      id="clear-bill"
                      buttonClass={`width-btn ${isEmpty(this.state.listBill) &&
                        !(
                          this.checkWaitingTime()
                          // || this.checkTimeout()
                        ) &&
                        "bill-empty"
                        }`}
                      text={this.state.previewMode ? "ยกเลิก" : "ล้างตาราง"}
                      onClick={() => {
                        if (this.state.previewMode) {
                          ConfirmModal.show({
                            size: "md",
                            title: "ยกเลิกการเดิมพัน",
                            description: "คุณแน่ใจหรือว่าจะยกเลิกการเดิมพัน",
                            action: () => {
                              this.setState({
                                listBill: [],
                                listNumberBet: [],
                                previewMode: false,
                                listNumber: [],
                              });
                              ConfirmModal.hide();
                            },
                            cancelAction: ConfirmModal.hide,
                          });
                        } else {
                          if (isEmpty(this.state.listBill)) {
                            this.setState({
                              listBill: [],
                              listNumber: [],
                              listNumberBet: [],
                              previewMode: false,
                            });
                          } else {
                            ConfirmModal.show({
                              textSmall: true,
                              size: "md",
                              title: constants.tesxtConfirmNotAddBillTitle,
                              description: constants.tesxtConfirmClear,
                              action: () => {
                                this.setState({
                                  listBill: [],
                                  listNumber: [],
                                  listNumberBet: [],
                                  previewMode: false,
                                });
                                ConfirmModal.hide();
                              },
                              cancelAction: ConfirmModal.hide,
                            });
                          }
                        }
                      }}
                      textColor={colors.PRIMARY_BG}
                      backgroundColor={colors.PRIMARY_RED}
                      backgroundHoverColor={colors.PRIMARY_RED}
                      disabled={
                        this.checkWaitingTime() || isEmpty(this.state.listBill)
                        // || this.checkTimeout()
                      }
                    />
                  </div>
                  {this.state.previewMode && (
                    <div className="back">
                      <Button
                        id="back"
                        text="ย้อนกลับ"
                        buttonClass="width-btn"
                        onClick={() => {
                          this.setState({ previewMode: false });
                        }}
                        textColor={colors.PRIMARY_BG}
                        backgroundColor={colors.SECONDARY_TEXT}
                        backgroundHoverColor={colors.PRIMARY_TEXT}
                      />
                    </div>
                  )}
                  <div className={`submit`}>
                    <Button
                      id="submit"
                      text={this.state.previewMode ? "ยืนยัน" : "บันทึก"}
                      buttonClass={`width-btn ${isEmpty(this.state.listBill) &&
                        !(
                          this.checkWaitingTime()
                          // || this.checkTimeout()
                        ) &&
                        "bill-empty"
                        }`}
                      onClick={() => {
                        if (this.state.activeMenu === constants.pastePooi && (this.state.isOverBetMax > 0)) {
                          ErrorModal.show({
                            action: () => {
                              ErrorModal.hide();
                            },
                            description: 'กรุณาตรวจสอบยอดเดิมพันให้ถูกต้อง'
                          });
                        } else {
                          if (this.props.meData.permission !== "OWNER") {
                            if (this.state.previewMode) {
                              this.setState({isLoadingfix: true})
                              this.props.postAgentBet(this.state.listNumberBet);
                            } else {
                              this.checkRemark();
                              this.checkListBill();
                              if (
                                !isEmpty(this.state.remark) &&
                                !isEmpty(this.state.listNumberBet)
                              ) {
                                this.checkNotAddBill(() => {
                                  this.setListBet();
                                  this.setState({
                                    previewMode: true,
                                    listNumber: [],
                                  });
                                }, constants.tesxtConfirmSave);
                              }
                            }
                          }
                        }

                      }}
                      textColor={colors.PRIMARY_BG}
                      backgroundColor={
                        this.state.previewMode
                          ? colors.SECONDARY_PURPLE
                          : colors.PRIMARY_GREEN
                      }
                      backgroundHoverColor={
                        this.state.previewMode
                          ? colors.PRIMARY_PURPLE
                          : colors.SECONDARY_GREEN
                      }
                      disabled={
                        this.state.isLoadingfix ||
                        this.checkWaitingTime() ||
                        isEmpty(this.state.listBill) ||
                        // || this.checkTimeout()
                        !isEmpty(this.state.errorRemark) ||
                        this.props.meData.permission !== "AGENT" 
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="lotto-rate">
                  <h5>อัตราจ่าย</h5>
                  <div className="table-container">
                    <div className="table-responsive-xl">
                      <table className="w-100">
                        <thead className="thead-dark header-table-lotto-rate">
                          <tr>{TableHeadRate}</tr>
                        </thead>
                        <tbody>{TableBodyRate}</tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="lotto-history">
                  <h5 className="mb-2">สถิติย้อนหลัง</h5>
                  <div
                    className="table-container lotto-history-table"
                    onClick={() => {
                      this.props.history.push(
                        routes.lottoResultDetail.exactPath(
                          this.props.match.params.type
                        )
                      );
                    }}
                  >
                    <div className="table-responsive-xl">
                      <table className="w-100">
                        <thead className="thead-dark header-table-lotto-history">
                          <tr>{TableHeadHistory}</tr>
                        </thead>
                        <tbody>{TableBodyHistory}</tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="lotto-popular">
                  <h5 className="mb-2">เลขยอดนิยม</h5>
                  <div className="table-container">
                    <div className="table-responsive-xl">
                      <table className="w-100">
                        <thead className="header-table-lotto-popular">
                          <tr>{TableHeadPopular}</tr>
                        </thead>
                        <tbody>{TableBodyePopular}</tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="lotto-bill">
                  <div className="bill-all">
                    <h5>{`รายการบิลล่าสุด (แสดง  ${this.props.betGroupV2Data.totalRecord} รายการ)`}</h5>
                    <div className="box-btn-bill-all">
                      <Button
                        id="bill-all-btn"
                        text={"เพิ่มเติม"}
                        type="underline"
                        disabled={false}
                        textColor={colors.PRIMARY_GREEN}
                        backgroundColor={colors.TRANSPARENT}
                        backgroundHoverColor={colors.TRANSPARENT}
                        onClick={() => {
                          this.props.history.push(routes.betSumHistory.path);
                        }}
                      />
                    </div>
                  </div>
                  <div className="table-container">
                    <div className="table-responsive-xl">
                      <table className="w-100">
                        <thead className="header-table-lotto-bill">
                          <tr>{TableHeadBill}</tr>
                        </thead>
                        <tbody>{TableBodyeBill}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <NumbersBanTable
                  lottoScheduleV2Data={this.props.lottoScheduleV2Data}
                  lottoScheduleV2IsFetching={this.props.lottoScheduleV2IsFetching}
                  dataRateReduceLottery={this.state.dataRateReduceLottery}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default BetMakingContainer
