import { isEmpty, noop } from "lodash";
import "./ModalPrintBill.style.scss";
import { Modal } from "../..";
import React, { FC } from "react";
// import { Button } from "components";
import logo from "assets/images/logo/logothailandbet.png";
import { Button, CheckBox } from "components";
import colors from "constants/colors";
const constants = {
  title: "ออกใบเสร็จ",
  titleWeb: "ไทยแลนด์เบท",
  titleEng: "Thailand Bet",
  description: "โปรดเลือกรูปแบบใบเสร็จ",
  actionText: "ตกลง",
  printShowPay: "แสดงอัตราจ่าย",
  printNotShowPay: "ไม่แสดงอัตราจ่าย",
  cancel: "ยกเลิก",
};

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: IModalPrintBill = {
  title: constants.title,
  description: constants.description,
  actionText: constants.actionText,
  actionPrintShowPay() { noop() },
  actionPrintNotShowPay() { noop() },
  actionCancel() { noop() },
  actionModePrint() { noop() },
  children: <></>
};

const ModalPrintBill: FC<IModalPrintBill & DefaultProps> = (props) => {
  return (
    <div className="modal-custom-container border-rounded">
      <div className="row px-5 ml-2 mt-4">
        <div className="leading-logo-container m2-r">
          <img src={logo} className="logoMenu" alt="" />
          <div className="title-text-logo">
            <h5 className="m1-t">{constants.titleWeb}</h5>
            <h6 className="tertiary-text">{constants.titleEng}</h6>
          </div>
        </div>
      </div>
      <h2 className="mt-5 title-text">{props.title}</h2>
      <p className="mt-3 description-text">{props.description}</p>
      <div className="row justify-content-center">
        <div className="m1-b m2-t col-8">
          <Button
            id="success-ok-button"
            type="rectangle"
            onClick={() => { props.actionPrintShowPay!() }}
            text={constants.printShowPay}
            transitionType="typeOne"
            size="large"
          />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="m1-y col-8">
          <Button
            id="success-ok-button"
            type="rectangle"
            onClick={() => { props.actionPrintNotShowPay!() }}
            text={constants.printNotShowPay}
            transitionType="typeOne"
            size="large"
          />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="m1-y col-8">
          <Button
            id="cancel-button"
            type="rectangle"
            onClick={() => { props.actionCancel!() }}
            text={constants.cancel}
            transitionType="typeOne"
            backgroundColor={colors.TERTIARY_TEXT}
            backgroundHoverColor={colors.TERTIARY_TEXT}
            borderColor={colors.SECONDARY_TEXT}
            size="large"
          />
        </div>
      </div>
      <div className="row justify-content-center pb-4">
        <div className="m1-y col-8 d-flex">
          <CheckBox
            id={`show-modal`}
            onClick={(e:React.ChangeEvent<HTMLInputElement>) => {
              props.actionModePrint!(e.target.checked)
            }}
          />
          <span className="p1-l">เลือกไม่แสดงอีกในครั้งถัดไป</span>
        </div>
      </div>
    </div>
  );
};

ModalPrintBill.defaultProps = defaultProps;

const modalPrintBill = {
  show: (props: IModalPrintBill) => {
    return Modal.event("MODAL_OVER", {
      state: "show",
      extraProps: { size: isEmpty(props.size) ? "md" : props.size },
      component: <ModalPrintBill {...props} />,
    });
  },
  hide: () => Modal.event("MODAL_OVER", { state: "hide" }),
};

export default modalPrintBill;
