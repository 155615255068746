import "./error.style.scss";
import { noop } from "lodash";
import { Modal } from "../../";
import React, { FC } from "react";
import { Button } from "components";
import logo from "assets/images/logo/logothailandbet.png";

const constants = {
  title: "เกิดข้อผิดพลาด",
  description: "กรุณาตรวจสอบข้อมูลอีกครั้ง",
  actionText: "ลองใหม่อีกครั้ง",
  titleWeb: "ไทยแลนด์เบท",
  titleEng: "Thailand Bet",
};

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: IErrorModal = {
  title: constants.title,
  description: constants.description,
  actionText: constants.actionText,
  action() {
    noop();
  },
  errorCode: 0,
};

const ErrorModal: FC<IErrorModal & DefaultProps> = (props) => {
  return (
    <div className="error-modal-container border-rounded">
      <div className="row mr-0">
        <div className="leading-logo-container m2-x">
          <img src={logo} className="logoMenu" alt="" />
        </div>
        <div className="title-text-web">
          <h3 className="title">{constants.titleWeb}</h3>
          <h5 className="tertiary-text sub-title">{constants.titleEng}</h5>
        </div>
      </div>
      <h2 className="title-text primary-yellow-text m3-b">{props.title}</h2>
      <div className="d-flex flex-row justify-content-center m5-b">
        <p
          className="body-semi1 tertiary-text error-description-text mb-0"
          style={props.errorCode === 401112 ? { width: "65%" } : undefined}
        >
          {props.description}
        </p>
      </div>
      <div className="row justify-content-center">
        <div className="col-8">
          <Button
            id="error-ok-button"
            type="rectangle"
            onClick={props.action}
            text={props.actionText!}
            textSize={18}
            transitionType="typeOne"
          />
        </div>
      </div>
    </div>
  );
};

ErrorModal.defaultProps = defaultProps;

const errorModal = {
  show: (props: IErrorModal) => {
    return Modal.event("MODAL_OVER", {
      state: "show",
      extraProps: { size: "md" },
      component: <ErrorModal {...props} />,
    });
  },
  hide: () => Modal.event("MODAL_OVER", { state: "hide" }),
};

export default errorModal;
