import { Breadcrumb, ErrorModal, LoadingCircle, LottoCard } from 'components';
// import colors from 'constants/colors';
import { responseCode, responseMessage } from 'constants/response';
import { CODE_TO_NAME, LOTTO_FLAG_ALPHA } from 'constants/variables';
import { get, includes, isEmpty, map, noop, orderBy } from 'lodash';
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router';
import { date } from 'utils';
import LottoFlags from "assets/images/global/flags";
import routes from 'constants/routes';
import './lottoResult.style.scss'

type DefaultProps = Readonly<typeof defaultProps>;
const constants = {
   mainPageTitle:"ภาพรวม",
   betTitle:"การเดิมพัน",
   resultTitle:"ผลหวย",
   tabelLotto:"ตารางหวย",
   lottoShare: "หวยหุ้น",
   lottoYegee: "หวยยี่กี",
   lottoGeneral: "หวยทั่วไป",
 };
 

const defaultProps: ILottoResultContainerProps & ILottoResultContainerAction = {
   onChangeIsLoaddingAction() { noop() },
   getV2DataResult() { noop() },

   getV2ResultData: {
      page: 1,
      limit: 10,
      total: 0,
      totalRecord:0,
      dataList: [],
    },
    getV2ResultIsFetching: false,
    getV2ResultCode: 0,
    getV2ResultError: "",
};

export default class LottoResultContainer extends Component<
    ILottoResultContainerProps & ILottoResultContainerAction & DefaultProps & RouteComponentProps
> {
   static defaultProps = defaultProps;
   state: ILottoResultContainerState = {
      genLotto: [],
      shareLotto: [],
      yegeeLotto: [],
      limit:200
    };

   componentDidMount(){
      this.props.getV2DataResult({
         page:1,limit:this.state.limit
      })
   }

   componentDidUpdate(prevProps:ILottoResultContainerProps){
      if (this.props.getV2ResultIsFetching) {
         this.props.onChangeIsLoaddingAction(true);
      } else {
      this.props.onChangeIsLoaddingAction(false);
      }

      if ( prevProps.getV2ResultIsFetching !== this.props.getV2ResultIsFetching && !this.props.getV2ResultIsFetching ) {

         if ( this.props.getV2ResultCode !== responseCode.OK && this.props.getV2ResultError !== "" ) {
           ErrorModal.show({
             action: ErrorModal.hide,
             description: get( responseMessage(), this.props.getV2ResultCode, responseMessage()[0] ),
           });
         }
   
         const lottoShare = this.props.getV2ResultData.dataList.filter(
           (lotto) => lotto.name.includes("SHARE") && !lotto.name.includes("LAO")
         );
         const lottoYegee = this.props.getV2ResultData.dataList.filter((lotto) =>
           lotto.name.includes("YEGEE")
         );
         const lottoGeneral = this.props.getV2ResultData.dataList.filter(
           (lotto) => (!lotto.name.includes("YEGEE") && !lotto.name.includes("SHARE")) || lotto.name.includes("LAO")
         );
         this.setState({
            genLotto: lottoGeneral,
            shareLotto: lottoShare,
            yegeeLotto: lottoYegee
         });
       }
   }

   renderLottoList = (lottoList: IV2RespAgentLotteryResult[]) => {
      return map(lottoList, (lotto, index) => {
        const subtitle = 'ออกผลเมื่อ: '
  
      //   const backgroundColor = colors.TERTIARY_GREEN
  
        const description =
         isEmpty(lotto.createdAt) ? "-"
         : date.formatThai(lotto.createdAt, 'dd MMM yy HH:mm')

        const FlagIcon = get(LottoFlags[LOTTO_FLAG_ALPHA[includes(lotto.name,"YEGEE")?"YEGEE":lotto.name]],"Icon",'');

        const textNameLotto = includes(lotto.name,"YEGEE")
         ?`ยี่กี รอบที่ ${Number(lotto.name.replace("YEGEE_",''))}`
         :CODE_TO_NAME[lotto.name]
  
        return (
          <div
            className="col-auto lotto-box"
            key={`lotto-${lotto.name}-${index}`}
          >
            <LottoCard
              slug={("LOTTER_"+lotto.name) as TLottoSlug }
              id={`lotto-${lotto.name}`}
              onClick={()=>{
               this.props.history.push(
                  routes.lottoResultDetail.exactPath(lotto.name),{lotto}
                )
              }}
              title={textNameLotto}
              subtitle={subtitle}
              description={description}
              isOpen={true}
              status={'OPEN'}
              icon={FlagIcon}
              lottoResult={orderBy(lotto.lottoResult,['number.length'],['desc'])}
            // backgroundColor={backgroundColor}
            //   isCountingdown={false}
            //   closedStatusText={"constants.closedStatusLabel"}
            //   waitingStatusText={"constants.waitingStatusLabel"}
            //   openedStatusText={"constants.openedYeegeStatusLabel"}
            //   noAccessStatusText={"constants.noAccessStatusLabel"}
            //   expire={lotto.endTime}
            //   start={lotto.startTime}
            />
          </div>
        );
      });
    };

   render() {
      const navigates: IBreadcrumbItem[] = [
         { label: constants.mainPageTitle, active: false, path: "/dashboard" },
         // { label: constants.betTitle, active: false, path: "/bet" },
         { label: constants.resultTitle, active: true },
       ];
      return (
         <div className="paper-container lotto-result-container">
            <div className="row">
               <div className="col-12 col-md-6">
                  <div className="sub-menu" style={{ width: "fit-content" }}>
                  <Breadcrumb
                     items={navigates}
                     handleOnClickItem={(path: string) => this.props.history.replace(path)}
                  />
                  </div>
               </div>
            </div>
            <div className="paper-title">{constants.resultTitle}</div>
            <div className="paper-description">{constants.tabelLotto}</div>
            <div className="paper-body">
            <div className="box-shadow">
               <div className='max-width-box'>
                  <div className="row mr-xl-4">
                     <div className="col-12">
                        <h4>{constants.lottoGeneral}</h4>
                     </div>
                     {this.props.getV2ResultIsFetching && ( <div className="m2-l m1-t"><LoadingCircle /> </div>)}
                     {this.renderLottoList(this.state.genLotto)}
                  </div>
                  <div className="row m3-t mr-xl-4">
                     <div className="col-12">
                        <h4>{constants.lottoShare}</h4>
                     </div>
                     {this.props.getV2ResultIsFetching && ( <div className="m2-l m1-t"><LoadingCircle /> </div>)}
                     {this.renderLottoList(this.state.shareLotto)}
                  </div>
                  <div className="row m3-t mr-xl-4">
                     <div className="col-12">
                        <h4>{constants.lottoYegee}</h4>
                     </div>
                     {this.props.getV2ResultIsFetching && ( <div className="m2-l m1-t"><LoadingCircle /> </div>)}
                     {this.renderLottoList(this.state.yegeeLotto)}
                  </div>
               </div>
            </div>
            </div>
         </div>
      )
   }
}
