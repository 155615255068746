const rgba = (hex: string, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g)!.map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
}

const color = {
    rgba,
    PRIMARY_YELLOW: '#F2C94C',
    PRIMARY_BG: '#FFFFFF',
    SECONDARY_BG: '#F8F8F8',
    PRIMARY_TEXT: '#202020',
    SECONDARY_TEXT: '#424856',
    TERTIARY_TEXT: '#A7ABB7',
    TERTIARY_TEXT_TRANSPARENT: '#A7ABB733',
    QUATERNARY_TEXT: '#637381',
    GRAY_TEXT: '#919191',
    PRIMARY_PURPLE: '#714BDA',
    SECONDARY_PURPLE: '#845BDD',
    TERTIARY_PURPLE: '#E2DBF7',
    QUATERNARY_PURPLE: '#E0DBF7',
    ACTIVE_PURPLE: rgba('845BDD',0.08),
    PRIMARY_GREEN: '#6CCFB8',
    SECONDARY_GREEN: '#71CE99',
    TERTIARY_GREEN: '#DFF5EB',
    PRIMARY_RED: '#D93023',
    SECONDARY_RED: '#F3CECE',
    PRIMARY_HOVER: '#eeeef1',

    BLACK: '#000',

    TRANSPARENT: '#ffffff00',
    PURPLE: '#8165FF',
    PRIMARY_BORDER_COLOR: '#919EAB',
    SECONDARY_BORDER_COLOR: '#919EAB52',
    TEXT_BLUE: '#0000ff',
    DARK_GREEN: '#2DA661',
    DARK_GREEN_TEXT: '#01a982',
    BLUE:'#347ACA',
    BLUE_GREEN: '#6899A7',
    BLUE_TRAN: rgba('347ACA', 0.2)
}

export default color
