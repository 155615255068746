import React, { Component } from "react";
import Slider, { CustomArrowProps, Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.style.scss";
import { ReactComponent as NextArrow } from "assets/images/global/2FA/next-arrow.svg";
import { ReactComponent as PrevArrow } from "assets/images/global/2FA/prev-arrow.svg";
import { ResponsiveIcon } from "components";
import Step1 from "assets/images/global/2FA/step1.svg";
import Step2 from "assets/images/global/2FA/step2.svg";
import Step3 from "assets/images/global/2FA/step3.svg";
import Step4 from "assets/images/global/2FA/step4.svg";

const constants = {
  step1: "1 ถึง 4",
  step2: "2 ถึง 4",
  step3: "3 ถึง 4",
  step4: "4 ถึง 4",
}

const SlickArrowRight = ({
  currentSlide,
  slideCount,
  ...props
}: CustomArrowProps) => {
  return (
    <NextArrow
      {...props}
      className={`slick-next slick-arrow ${
        currentSlide === slideCount! - 1 ? "slick-disabled" : ""
      }`}
    />
  );
};

const SlickArrowLeft = ({
  currentSlide,
  slideCount,
  ...props
}: CustomArrowProps) => {
  return (
    <PrevArrow
      {...props}
      className={`slick-prev slick-arrow ${
        currentSlide === 0 ? "slick-disabled" : ""
      }`}
    />
  );
};

export default class Carousel extends Component {
  private slider1: any;
  private slider2: any;

  constructor(props: any) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
    };
  }

  state: ICarouselState = {
    nav1: null,
    nav2: null,
  };

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    const settingsNav1: Settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
    };

    const settingsNav2: Settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SlickArrowRight />,
      prevArrow: <SlickArrowLeft />,
    };

    return (
      <div className="all-slides">
        <Slider
          {...settingsNav1}
          asNavFor={this.state.nav2}
          ref={(slider) => (this.slider1 = slider)}
        >
          <div>
            <ResponsiveIcon
              icon={Step1}
              alt="authenticator"
              className="step-1-carousel w-75"
            />
          </div>
          <div>
            <ResponsiveIcon
              icon={Step2}
              alt="enter-key"
              className="step-2-carousel w-75"
            />
          </div>
          <div>
            <ResponsiveIcon
              icon={Step3}
              alt="enter-account"
              className="step-3-carousel w-75"
            />
          </div>
          <div>
            <ResponsiveIcon
              icon={Step4}
              alt="2FA-key"
              className="step-4-carousel w-75"
            />
          </div>
        </Slider>
        <Slider
          {...settingsNav2}
          asNavFor={this.state.nav1}
          ref={(slider) => (this.slider2 = slider)}
          className="slider-two m4-l"
        >
          <div key={1} className="text-center body-4">
            <p>{constants.step1}</p>
          </div>
          <div key={2} className="text-center body-4">
            <p>{constants.step2}</p>
          </div>
          <div key={3} className="text-center body-4">
            <p>{constants.step3}</p>
          </div>
          <div key={4} className="text-center body-4">
            <p>{constants.step4}</p>
          </div>
        </Slider>
      </div>
    );
  }
}
