export const MEMBERS_REQUEST = 'MEMBERS_REQUEST'
export const MEMBERS_SUCCESS = 'MEMBERS_SUCCESS'
export const MEMBERS_FAILURE = 'MEMBERS_FAILURE'

export const FINANCE_SUMMARY_REQUEST = 'FINANCE_SUMMARY_REQUEST'
export const FINANCE_SUMMARY_SUCCESS = 'FINANCE_SUMMARY_SUCCESS'
export const FINANCE_SUMMARY_FAILURE = 'FINANCE_SUMMARY_FAILURE'

export const AGENT_CHILD_REQUEST = 'AGENT_CHILD_REQUEST'
export const AGENT_CHILD_SUCCESS = 'AGENT_CHILD_SUCCESS'
export const AGENT_CHILD_FAILURE = 'AGENT_CHILD_FAILURE'

export const LISTMEMBERS_REQUEST = 'LISTMEMBERS_REQUEST'
export const LISTMEMBERS_SUCCESS = 'LISTMEMBERS_SUCCESS'
export const LISTMEMBERS_FAILURE = 'LISTMEMBERS_FAILURE'

export const DIAGRAM_LISTMEMBERS_REQUEST = 'DIAGRAM_LISTMEMBERS_REQUEST'
export const DIAGRAM_LISTMEMBERS_SUCCESS = 'DIAGRAM_LISTMEMBERS_SUCCESS'
export const DIAGRAM_LISTMEMBERS_FAILURE = 'DIAGRAM_LISTMEMBERS_FAILURE'

export const MEMBER_UPDATE_REQUEST = 'MEMBER_UPDATE_REQUEST'
export const MEMBER_UPDATE_SUCCESS = 'MEMBER_UPDATE_SUCCESS'
export const MEMBER_UPDATE_FAILURE = 'MEMBER_UPDATE_FAILURE'

export const MEMBER_Detail_REQUEST = 'MEMBER_Detail_REQUEST'
export const MEMBER_Detail_SUCCESS = 'MEMBER_Detail_SUCCESS'
export const MEMBER_Detail_FAILURE = 'MEMBER_Detail_FAILURE'

export const MEMBER_DELETE_REQUEST = 'MEMBER_DELETE_REQUEST'
export const MEMBER_DELETE_SUCCESS = 'MEMBER_DELETE_SUCCESS'
export const MEMBER_DELETE_FAILURE = 'MEMBER_DELETE_FAILURE'

export const POST_USER_REQUEST = 'POST_USER_REQUEST'
export const POST_USER_SUCCESS = 'POST_USER_SUCCESS'
export const POST_USER_FAILURE = 'POST_USER_FAILURE'

export const MEMBER_REPASSWORD_REQUEST = 'MEMBER_REPASSWORD_REQUEST'
export const MEMBER_REPASSWORD_SUCCESS = 'MEMBER_REPASSWORD_SUCCESS'
export const MEMBER_REPASSWORD_FAILURE = 'MEMBER_REPASSWORD_FAILURE'

export const membersState: ReducerState<IMembers> = {
    isFetching: false,
    code: 0,
    data: {
        childCount: 0,
        childCreditLimit: 0,
        childCreditTotal: 0,
        creditLimit: 0
    },
    error: ''
}

export const financeSummaryState: ReducerState<IV2RespAgentFinanceSummary> = {
    isFetching: false,
    code: 0,
    data: {
        myCreditTotal: 0,
        myCreditLimit: 0,
        myChildTotal: 0,
        myChildCreditTotal: 0,
        myProfitTotal: 0,
        myHostProfitTotal: 0,
        myLossTotal: 0,
        myHostLossTotal: 0,
        myBetTotal: 0,
        myCurrentBetTotal: 0,
        myChildBetTotal: 0,
        myChildCurrentBetTotal: 0,
        myChildComTotal: 0,
        myChildCurrentComTotal: 0,
        myChildBetCountTotal: 0,
        myChildCurrentBetCountTotal: 0,
    },
    error: ''
}

export const agentChildState: ReducerState<IPagination<IV2GetAgentChild>> = {
    isFetching: false,
    code: 0,
    data: {
        totalRecord:0,
        page: 1,
        limit: 10,
        total: 0,
        dataList: []
    },
    error: ''
}


export const diagramState: ReducerState<[]> = {
    isFetching: false,
    code: 0,
    data: [],
    error: "",
};

export const listMembersState: ReducerState<IPagination<IListMembers>> = {
    isFetching: false,
    code: 0,
    data: {
        totalRecord:0,
        page: 1,
        limit: 10,
        total: 0,
        dataList: []
    },
    error: ''
}

export const registerResState: ReducerState<IAPIResponse<object>> = {
    isFetching: false,
    code: 0,
    data: {
        data: {},
        code: 0,
        devMessage: ""
    },
    error: ''
}

export const MembersDetaiState: ReducerState<IAgentUpdate> = {
    isFetching: false,
    code: 0,
    data: {
        id: 0,
        permission: '',
        isAccess: false,
        line: "",
        currency: 'THB',
        creditLimit: 0.00,
        maxBet: 0.00,
        minBet: 0.00,
        discountPercentage: 0.00,
        lotteryConfig: [],
        name: "",
    },
    error: ''
}