import { uniq } from "lodash";

const setValuestoArray = (
  charlength: string[],
  rate: string,
  type: string,
  statusCloseRecieved: INumberBanManageLottoRecievedType
) => {
  let result = [];
  for (var i = 0; i < charlength.length; i++) {
    result.push({
      ratereduceValue: Number(rate),
      ratereduceBetType: type,
      ratereduceNumber: charlength[i],
      ratereduceIsBanned: (statusCloseRecieved as any)[type],
    });
  }
  return result;
};

const setValuestoArrayAllRelevant = (
  uniqNum: string[],
  values: INumberBanManageRateReduce,
  statusCloseRecieved: INumberBanManageLottoRecievedType
) => {
  let result: any[] = [];
  uniqNum!.map((item) => {
    return (
      item.length > 0 &&
      result.push(
        {
          ratereduceValue:
            item.length === 3
              ? Number(values.THREE_UP)!
              : item.length === 2
              ? Number(values.TWO_UP)!
              : Number(values.RUN_UP)!,
          ratereduceBetType:
            item.length === 3 ? "THREE_UP" : item.length === 2 ? "TWO_UP" : "RUN_UP",
          ratereduceNumber: item,
          ratereduceIsBanned:
            item.length === 3
              ? statusCloseRecieved.THREE_UP
              : item.length === 2
              ? statusCloseRecieved.TWO_UP
              : statusCloseRecieved.RUN_UP,
        },
        {
          ratereduceValue:
            item.length === 3
              ? Number(values.THREE_TOAST)!
              : item.length === 2
              ? Number(values.TWO_DOWN)!
              : Number(values.RUN_DOWN)!,
          ratereduceBetType:
            item.length === 3 ? "THREE_TOAST" : item.length === 2 ? "TWO_DOWN" : "RUN_DOWN",
          ratereduceNumber: item,
          ratereduceIsBanned:
            item.length === 3
              ? statusCloseRecieved.THREE_TOAST
              : item.length === 2
              ? statusCloseRecieved.TWO_DOWN
              : statusCloseRecieved.RUN_DOWN,
        }
      )
    );
  });

  return result;
};
const sixBack = (
  data: string,
  values: string,
  lottoType: TLottoGameType,
  statusCloseRecieved: INumberBanManageLottoRecievedType
) => {
  var arrResult: any[] = [];
  var arrNum = data.split("");
  var uniqNum = uniq([
    `${arrNum[0]}${arrNum[1]}${arrNum[2]}`,
    `${arrNum[0]}${arrNum[2]}${arrNum[1]}`,
    `${arrNum[1]}${arrNum[0]}${arrNum[2]}`,
    `${arrNum[1]}${arrNum[2]}${arrNum[0]}`,
    `${arrNum[2]}${arrNum[0]}${arrNum[1]}`,
    `${arrNum[2]}${arrNum[1]}${arrNum[0]}`,
  ]);

  arrResult = setValuestoArray(uniqNum, values, lottoType, statusCloseRecieved);
  return arrResult;
};

const reverseNum = (
  data: string,
  values: string,
  lottoType: TLottoGameType,
  statusCloseRecieved: INumberBanManageLottoRecievedType
) => {
  var dataReverse = data.split("").reverse().join("");
  if (data === dataReverse) {
    return [
      {
        ratereduceNumber: dataReverse,
        ratereduceValue: Number(values),
        ratereduceBetType: lottoType,
        ratereduceIsBanned: (statusCloseRecieved as any)[lottoType],
      },
    ];
  }
  return [
    {
      ratereduceValue: Number(values),
      ratereduceBetType: lottoType,
      ratereduceNumber: data,
      ratereduceIsBanned: (statusCloseRecieved as any)[lottoType],
    },
    {
      ratereduceValue: Number(values),
      ratereduceBetType: lottoType,
      ratereduceNumber: dataReverse,
      ratereduceIsBanned: (statusCloseRecieved as any)[lottoType],
    },
  ];
};

const nineteendoor = (
  data: string,
  values: string,
  lottoType: TLottoGameType,
  statusCloseRecieved: INumberBanManageLottoRecievedType
) => {
  var arrResult: any[] = [];
  var all19Num = [];
  var arrNum = data.split("");
  var firstDigit = arrNum[0];
  var secondDigit = arrNum[1];

  for (var i = 0; i < 10; i++) {
    var concatNum = firstDigit.concat(String(i));
    var concatNumReverse = String(i).concat(firstDigit);
    var concatNumSecond = secondDigit.concat(String(i));
    var concatNumSecondReverse = String(i).concat(secondDigit);
    all19Num.push(concatNum, concatNumSecond, concatNumReverse, concatNumSecondReverse);
  }

  var uniqNum = uniq(all19Num.sort());

  arrResult = setValuestoArray(uniqNum, values, lottoType, statusCloseRecieved);

  return arrResult;
};

const all2Relevant = (
  data: string,
  values: INumberBanManageRateReduce,
  statusCloseRecieved: INumberBanManageLottoRecievedType
) => {
  var arrResult: any[] = [];
  var arrNum = data.split("");
  var arrNumfor = [];
  var reverseNum = data.split("").reverse().join("");

  for (var i = 0; i < 10; i++) {
    var concatNum = data.concat(String(i));
    var concatNumReverse = String(i).concat(data);
    var concatNumSecond = reverseNum.concat(String(i));
    var concatNumSecondReverse = String(i).concat(reverseNum);
    arrNumfor.push(concatNum, concatNumReverse, concatNumSecond, concatNumSecondReverse);
  }

  var uniqNum = [
    uniq(arrNumfor.sort()),
    uniq([`${arrNum[0]}${arrNum[1]}`, `${arrNum[1]}${arrNum[0]}`, `${arrNum[0]}`, `${arrNum[1]}`]),
  ].flat();

  arrResult = setValuestoArrayAllRelevant(uniqNum, values!, statusCloseRecieved);

  return arrResult;
};

const all3Relevant = (
  data: string,
  values: INumberBanManageRateReduce,
  statusCloseRecieved: INumberBanManageLottoRecievedType
) => {
  var arrResult: any[] = [];
  var arrNum = data.split("");
  var uniqNum = uniq([
    `${arrNum[0]}${arrNum[1]}${arrNum[2]}`,
    `${arrNum[0]}${arrNum[2]}${arrNum[1]}`,
    `${arrNum[1]}${arrNum[0]}${arrNum[2]}`,
    `${arrNum[1]}${arrNum[2]}${arrNum[0]}`,
    `${arrNum[2]}${arrNum[0]}${arrNum[1]}`,
    `${arrNum[2]}${arrNum[1]}${arrNum[0]}`,
    `${arrNum[0]}${arrNum[1]}`,
    `${arrNum[0]}${arrNum[2]}`,
    `${arrNum[1]}${arrNum[0]}`,
    `${arrNum[1]}${arrNum[2]}`,
    `${arrNum[2]}${arrNum[0]}`,
    `${arrNum[2]}${arrNum[1]}`,
    `${arrNum[0]}`,
    `${arrNum[1]}`,
    `${arrNum[2]}`,
  ]);

  arrResult = setValuestoArrayAllRelevant(uniqNum, values!, statusCloseRecieved);
  return arrResult;
};

const all1Relevant = (
  data: string,
  values: INumberBanManageRateReduce,
  statusCloseRecieved: INumberBanManageLottoRecievedType
) => {
  var arrResult: any[] = [];
  var arrNumFor = [];

  arrNumFor.push(data);
  for (var i = 0; i < 10; i++) {
    for (var j = 0; j < 10; j++) {
      arrNumFor.push(data.concat(String(i)).concat(String(j)));
      arrNumFor.push(String(i).concat(data).concat(String(j)));
      arrNumFor.push(String(i).concat(String(j)).concat(data));
    }
  }

  for (var ii = 0; ii < 10; ii++) {
    arrNumFor.push(data.concat(String(ii)));
    arrNumFor.push(String(ii).concat(data));
  }

  var uniqNum = uniq(arrNumFor.sort());

  arrResult = setValuestoArrayAllRelevant(uniqNum, values!, statusCloseRecieved);
  return arrResult;
};
const calculateBet = {
  sixBack,
  reverseNum,
  nineteendoor,
  all2Relevant,
  all3Relevant,
  all1Relevant,
};

export default calculateBet;
