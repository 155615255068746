import axios from "axios";
import environment from "../../constants/environment";

const fetchGetMeSetting = () => {
  const endpoint = `${
    environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.getMeSetting}`;
  return axios.get(endpoint);
};

const fetchGetMe = () => {
  const endpoint = `${
    environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.getMe}`;
  return axios.get(endpoint); 
}

const fetchGetMeSettingLottery = () => {
  const endpoint = `${
    environment.environments[environment.environmentName].endpoint.url
  }${environment.endpoint.getMeSettingLottery}`;
  return axios.get(endpoint); 
}

export { fetchGetMeSetting, fetchGetMe, fetchGetMeSettingLottery };
