import { AxiosError , AxiosResponse } from 'axios'
import { createAsyncAction } from 'typesafe-actions'
import {
    PASTE_POOI_REQUEST,
    PASTE_POOI_SUCCESS,
    PASTE_POOI_FAILURE,
} from './constants'

const pastePooiAction = createAsyncAction(
    PASTE_POOI_REQUEST,
    PASTE_POOI_SUCCESS,
    PASTE_POOI_FAILURE,
)<any, AxiosResponse<IAPIResponse<IV2RespExtractReplaceLotteryNumber>>, AxiosError<IAPIResponse>>()

export { 
    pastePooiAction,
}