import React, { FunctionComponent, useState, useCallback } from "react";
import { TabMenuComponent } from "../TabMenu";
import { get, isEmpty, multiply, sum } from "lodash";
import { dateThai, number } from "utils";
import { STATUS_FINANCE } from "constants/variables";
import { LoadingCircle } from "components";

const constants = {
  tabTableLabel: "จากตาราง",
  tabShareLabel: "การแบ่งหุ้น",
  tabClearListLabel: "รายการเคลียร์",

  keepFight: "ถือสู้",
  share: "แบ่งหุ้น",
  totalBet: "ยอดเดิมพัน",
  discount: "ส่วนลด",
  commission: "ค่าคอม",
  totalKeep: "ยอดรับ",
  totalPaid: "ยอดจ่าย",
  totalWaitVerify: "ยอดทั้งหมดที่รอตรวจสอบ",
  numberList: "(จำนวนรายการ)",
  balance: "สรุปกำไร/ขาดทุน",
  status: "สถานะ",
  time: "เวลา",

  keepFightResult: "ผลถือสู้",
  shareResult: "ผลแบ่งหุ้น",
  totalHostResult: "ยอดจ่ายหัวสมาชิก",
  totalChildResult: "ยอดจ่ายลูกสมาชิก/ชื่อช่วยจำ",
  totalHostReceive: "ยอดรับหัวสมาชิก",
  totalChildReceive: "ยอดรับลูกสมาชิก/ชื่อช่วยจำ",

  customerAmount: "จำนวนชื่อช่วยจำ",
  paddingAmount: "จำนวนยังไม่รับ/จ่าย",
  completeAmount: "จำนวนรับ/จ่ายแล้ว",

  list: "รายการ",
};

const getNumberColorClass = (num: number) => {
  return num > 0
    ? "primary-green-text"
    : num < 0
      ? "primary-red-text"
      : "primary-text";
};

const FormTableData: FunctionComponent<IV2RespAgentFinanceHost> = ({
  betMe,
  betHost,
  discount,
  com,
  recieveHost,
  payHost,
  totalPayWait,
  countWait,
  status,
  createdAt,
  loading
}) => {
  // const totalKeep = sum([recieveHost || 0, recieveChild || 0])
  const totalKeep = recieveHost || 0;
  // const totalPaid = sum([payHost || 0, payChild || 0])
  const totalPaid = payHost || 0;
  const balance = number.calculator(`${recieveHost}-${payHost}`);

  const statusName = get(STATUS_FINANCE, `${status}.status`, "");
  const statusColor = get(STATUS_FINANCE, `${status}.color`, "");
  return (
    <table className="finance-host-detail-table">
      <thead>
        <tr>
          <th>{constants.keepFight}</th>
          <th>{constants.share}</th>
          <th>{constants.totalBet}</th>
          <th>{constants.discount}</th>
          <th>{constants.commission}</th>
          <th>{constants.totalKeep}</th>
          <th>{constants.totalPaid}</th>

          <div className="d-flex flex-column text-center">
            <th className="">{constants.totalWaitVerify}</th>

            <div className="remember-name" style={{ lineHeight: "8px" }}>
              {constants.numberList}
            </div>
          </div>
          <th>{constants.balance}</th>
          <th>{constants.status}</th>
          <th>{constants.time}</th>
        </tr>
      </thead>
      <tbody>
        {
          loading ? (
            <tr>
              <td className="text-center" colSpan={15}>
                <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
              </td>
            </tr>
          )
            : <tr>
              <td>{number.addComma(betMe || 0)}</td>
              <td>{number.addComma(betHost || 0)}</td>
              <td>{number.addComma(sum([betMe || 0, betHost || 0]))}</td>
              <td>{number.addComma(discount || 0)}</td>
              <td>{number.addComma(com || 0)}</td>
              <td className={getNumberColorClass(totalKeep)}>
                {number.addComma(totalKeep)}
              </td>
              <td className={totalPaid > 0 ? "primary-red-text" : "primary-text"}>
                {number.addComma(totalPaid < 0 ? 0 : totalPaid)}
              </td>
              <td
                className={
                  totalPayWait > 0 ? "primary-yellow-text" : "tertiary-text"
                }
              >
                {number.addComma(totalPayWait || 0)}
                <br />({number.addComma(countWait || 0)} {constants.list})
              </td>
              <td className={getNumberColorClass(balance)}>
                {number.addComma(balance)}
              </td>
              <td style={{ color: statusColor, fontFamily: "SukhumvitSet-Bold" }}>
                {statusName}
              </td>
              <td>{dateThai.formatThai(createdAt)}</td>
            </tr>
        }

      </tbody>
    </table>
  );
};

const ShareData: FunctionComponent<IV2RespAgentFinanceHost> = ({
  resultMe,
  resultHost,
  payHost,
  payChild,
  recieveHost,
  recieveChild,
  loading
}) => {
  const displayResultHost = multiply(resultHost, -1);

  return (
    <table className="finance-host-detail-table">
      <thead>
        <tr>
          <th>{constants.keepFightResult}</th>
          <th>{constants.shareResult}</th>
          <th>{constants.totalHostResult}</th>
          <th>{constants.totalChildResult}</th>
          <th>{constants.totalHostReceive}</th>
          <th>{constants.totalChildReceive}</th>
        </tr>
      </thead>
      <tbody>
        {
          loading ? (
            <tr>
              <td className="text-center" colSpan={15}>
                <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
              </td>
            </tr>
          ) : <tr>
            <td className={getNumberColorClass(resultMe || 0)}>
              {number.addComma(resultMe || 0)}
            </td>
            <td className={getNumberColorClass(displayResultHost || 0)}>
              {number.addComma(displayResultHost || 0)}
            </td>
            <td className={payHost > 0 ? "primary-red-text" : "primary-text"}>
              {number.addComma(payHost || 0)}
            </td>
            <td>{0}</td>
            {/* <td className={getNumberColorClass(payChild || 0)}>{number.addComma(payChild || 0)}</td> */}
            <td className={getNumberColorClass(recieveHost || 0)}>
              {number.addComma(recieveHost || 0)}
            </td>
            <td>{0}</td>
            {/* <td className={getNumberColorClass(recieveChild || 0)}>{number.addComma(recieveChild || 0)}</td> */}
          </tr>
        }

      </tbody>
    </table>
  );
};

const ClearData: FunctionComponent<IV2RespAgentFinanceHost> = ({
  totalCustomer,
  countWait,
  totalPay,
  loading
}) => {
  return (
    <div className="px-5">
      <table className="finance-host-detail-table">
        <thead>
          <tr>
            <th>{constants.customerAmount}</th>
            <th>{constants.paddingAmount}</th>
            <th>{constants.completeAmount}</th>
          </tr>
        </thead>
        <tbody>
          {
            loading ? (
              <tr>
                <td className="text-center" colSpan={15}>
                  <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
                </td>
              </tr>
            ) : <tr>
              <td>
                {number.addComma(totalCustomer || 0)} {constants.list}
              </td>
              <td>
                {number.addComma(countWait || 0)} {constants.list}
              </td>
              <td>
                {number.addComma(totalPay || 0)} {constants.list}
              </td>
            </tr>
          }

        </tbody>
      </table>
    </div>
  );
};

interface IFinanceDetailProps {
  financeDetailData: IV2RespAgentFinanceHost | null;
  loading: boolean
}

const FinanceDetailsComponent: FunctionComponent<IFinanceDetailProps> = ({
  financeDetailData,
  loading,
}) => {
  const [tabs] = useState([
    { name: constants.tabTableLabel, count: 0 },
    { name: constants.tabShareLabel, count: 0 },
    { name: constants.tabClearListLabel, count: 0 },
  ]);
  const [currentTab, setCurrentTab] = useState(constants.tabTableLabel);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOnChangeTab = useCallback(setCurrentTab, [currentTab]);

  if (isEmpty(financeDetailData)) {
    return null;
  }

  return (
    <div className="paper-body">
      <div className="box-shadow m3-t boxShadow-Border pt-0">
        <div className="menu-list p3-b">
          <TabMenuComponent tabs={tabs} onChangeTab={handleOnChangeTab} />
        </div>

        <div className="row">
          <div className="col">
            {currentTab === constants.tabTableLabel && financeDetailData && (
              <FormTableData {...financeDetailData} loading={loading} />
            )}
            {currentTab === constants.tabShareLabel && financeDetailData && (
              <ShareData {...financeDetailData} loading={loading} />
            )}
            {currentTab === constants.tabClearListLabel &&
              financeDetailData && <ClearData {...financeDetailData} loading={loading} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinanceDetailsComponent;
