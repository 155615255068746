import React, { ChangeEvent, FC, useState } from "react";
import "./textarea.style.scss";
import colors from "constants/colors";
import { isEmpty } from "lodash";

const defaultProps: ITextArea = {
  id: "",
  placeholder: "",
  value: "",
  name: "",
  onChange() { },
  onBlur() { },
  disabled: false,
  backgroundColor: colors.SECONDARY_BG,
  backgroundActiveColor: colors.PRIMARY_BG,
  backgroundHighlight: '',
  borderHighlight: '',
  error: false,
  errorMessage: "error",
  ref: { current: null },
  onfocus: false
};



const Input: FC<ITextArea> = (props) => {
  const {
    id,
    placeholder,
    name,
    onChange,
    onBlur,
    value,
    disabled,
    backgroundColor,
    backgroundActiveColor,
    error,
    errorMessage,
    backgroundHighlight,
    borderHighlight,
    ref,
    onfocus,
  } = props;

  const [isFocus, setIsFocus] = useState(false);
  const [dgColor, setBgColor] = useState(backgroundColor);

  const handleOnBlur = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (value === "") {
      setIsFocus(false);
      setBgColor(backgroundColor);
    }
    onBlur!(event);
  };

  const handleFocus = () => {
    setIsFocus(true);
    setBgColor(backgroundActiveColor);
  };

  if (onfocus) {
    ref.current?.focus()
  }

  return (
    <div className="textarea-container">
      <textarea
        id={id}
        name={name}
        value={value}
        style={{
          backgroundColor: isEmpty(backgroundHighlight) ? dgColor : backgroundHighlight,
          border: isEmpty(borderHighlight) ? '' : `3px solid ${borderHighlight}`
        }}
        onChange={onChange}
        disabled={disabled}
        ref={ref}
        className={`base-textarea p2
            ${backgroundHighlight}
            ${borderHighlight}
            ${backgroundColor}
            ${error ? " border-error" : "border-active"}`}
        placeholder={`${isFocus ? placeholder : placeholder}`}
        onFocus={handleFocus}
        onBlur={handleOnBlur}
      />
      {error && (
        <div className={`text-left p2-x`}>
          <h6 className="primary-red-text">{errorMessage}</h6>
        </div>
      )}
    </div>
  );
};

Input.defaultProps = defaultProps;

export default Input;
