import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { postChildSettingUpdateAction } from "../actionCreator";
import { fetchPostChildSettingUpdate } from "../services";

const postChildSettingUpdate = (data: IV2PostAgentChildSettingUpdate) => (dispatch: Dispatch) => {
    dispatch(postChildSettingUpdateAction.request({}))
    return fetchPostChildSettingUpdate(data)
    .then((res: AxiosResponse<{}>) => {
        dispatch(postChildSettingUpdateAction.success(res))
        return Promise.resolve()
    })
    .catch((error: AxiosError<IAPIResponse>) => {
        dispatch(postChildSettingUpdateAction.failure(error))
        return Promise.reject()
    })
}

export { postChildSettingUpdate }