import { combineReducers } from "redux"
import * as menuAction from "./actionCreator"
import { ActionType, createReducer } from "typesafe-actions"

export type MenuActionType = ActionType<typeof menuAction>
const modePrintBet = createReducer<{isShow: boolean}, MenuActionType>({isShow: false})
  .handleAction(
    menuAction.modePrintBetAction,
    (state: {isShow: boolean},action: MenuActionType) => {
      return {
        isShow: action.payload,
      };
    }
  )

export default combineReducers({
  modePrintBet,
});
