import { get, includes, isEmpty, isNull, keys, noop } from "lodash";
import "./clearBillFinanceChild.style.scss";
import { Modal } from "../..";
import React, { FC, useState } from "react";
import colors from "constants/colors";
import { Button, Input } from "components";
import logo from "assets/images/logo/logothailandbet.png";
import { dateThai, number } from "utils";
import { BANK_TYPE } from "constants/variables";
import color from "constants/colors";
const constants = {
  title: "เคลียร์ยอด",
  titleWeb: "ไทยแลนด์เบท",
  titleEng: "Thailand Bet",
  number: "เลขบิล:",
  lotto: "หวย",
  totalBet: "ยอดเดิมพัน",
  discount: "ส่วนลด",
  customerName: "ชื่อช่วยจำ",
  totalHostShare: "ยอดรับ/จ่าย",
  save: "บันทึกเพิ่มเติม",
  saveRequired: "โปรดกรอกบันทึกเพิ่มเติมเพื่อยืนยัน",
  description: "คุณแน่ใจว่าหรือว่าจะเคลียร์ยอด?",
  actionText: "ตกลง",
  cancel: "ยกเลิก",
  accept: "ตกลง",
  agentPayTaxId: "เลขรายการ:",
  pay: "ยอด:",
  note: "ข้อมูลเพิ่มเติม:",
  line: "ไอดีไลน์:",
  phoneNumber: "เบอร์:",
  createdAt: "เวลาแจ้ง:",
  subTitle: "บัญชีโอนเงิน",
  bankType: "ธนาคาร:",
  bankNumber: "เลขที่บัญชี:",
  bankName: "ชื่อบัญชี:",
  cancelClearBill: "ยกเลิกการเคลียร์ยอด",
};

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: IClearBillFinanceChildModal = {
  title: constants.title,
  description: constants.description,
  actionText: constants.actionText,
  action() {
    noop();
  },
  cancelAction() {
    noop();
  },
};

const ClearBillFinanceChildModal: FC<
  IClearBillFinanceChildModal & DefaultProps
> = (props) => {
  const [note, setNote] = useState("");
  const [confirm, setConfirm] = useState(false);
  const handleSubmit = () => {
    setConfirm(true);
    if (note !== "" && props.detailBill) {
      const req: IV2PostAgentFinanceChildApprove = {
        agentPayTxId: props.detailBill?.agentPayTxId,
        status: "SUCCESS",
        note,
      };
      props.action!(req);
    }
  };

  const numberSpace = (number: string) => {
    if (number.length === 10) {
      var number1 = number.slice(0, 3);
      var number2 = number.slice(3, 7);
      var number3 = number.slice(7, 10);
      return number1 + " " + number2 + " " + number3;
    }

    if (number.length === 12) {
      var number4 = number.slice(0, 1);
      var number5 = number.slice(1, 4);
      var number6 = number.slice(4, 8);
      var number7 = number.slice(8, 12);
      return number4 + " " + number5 + " " + number6 + " " + number7;
    }
    if (number === "-"||isNull(number)||isEmpty(number)) {
      return "-";
    }
  };
  const replaceBankType = (data: string) => {
    if(data==="-"||isNull(data)||isEmpty(data)){
      return "-"
    }
    var dataNew = data.slice(6);
    return dataNew;
  };
  return (
    <div className="clearbill-modal-container border-rounded">
      <div className="row mr-0">
        <div className="leading-logo-container m2-x">
          <img src={logo} className="logoMenu" alt="" />
        </div>
        <div className="title-text-web">
          <h3 className="title">{constants.titleWeb}</h3>
          <h5 className="tertiary-text sub-title">{constants.titleEng}</h5>
        </div>
      </div>
      <h2 className="title-text secondary-text">{props.title}</h2>

      <div className="container-detail m4-b">
        <div className="row list-summary-container no-gutters">
          <div className="col-12 d-flex m2-b flex-row">
            <div
              className="body-3b ellipsis-text"
              style={{ width: "20%", paddingLeft: "8%" }}
            >
              {constants.agentPayTaxId}
            </div>
            <div className="body-3 ellipsis-text" style={{ width: "21%" }}>
              {props.detailBill?.agentPayTxId}
            </div>
            <div className="body-3b ellipsis-text" style={{ width: "6%" }}>
              {constants.pay}
            </div>
            <div className="body-3 ellipsis-text" style={{ width: "19%" }}>
              {number.addComma(props.detailBill?.pay)}
            </div>
            <div className="body-3b ellipsis-text" style={{ width: "14%" }}>
              {constants.note}
            </div>
            <div className="body-3 ellipsis-text" style={{ width: "16%" }}>
              {props.detailBill?.note}
            </div>
          </div>

          <div className="col-12 d-flex m2-b flex-row">
            <div
              className="body-3b ellipsis-text"
              style={{ width: "17%", paddingLeft: "8%" }}
            >
              {constants.line}
            </div>
            <div className="body-3 ellipsis-text pr-2" style={{ width: "24%" }}>
              {isEmpty(get(props.detailBill, "line","-"))||isNull(get(props.detailBill, "line","-")) ? "-":props.detailBill?.line}
            </div>
            <div
              className="body-3b ellipsis-text"
              style={{ width: "6%" }}
            >
              {constants.phoneNumber}
            </div>
            <div className="body-3 ellipsis-text" style={{ width: "16%" }}>
              {isEmpty(get(props.detailBill, "phoneNumber","-"))||isNull(get(props.detailBill, "phoneNumber","-")) ? "-":props.detailBill?.phoneNumber}
            </div>
          </div>
        </div>
        <div className="row list-summary-container">
          <div className="col-12 d-flex m2-b flex-row">
            <div
              className="body-3b ellipsis-text"
              style={{ width: "17%", paddingLeft: "8%" }}
            >
              {constants.createdAt}
            </div>
            <div className="body-3 ellipsis-text" style={{ width: "20%" }}>
              {props.detailBill
                ? dateThai.formatThai(props.detailBill?.createdAt)
                : ""}
            </div>
          </div>
        </div>
      </div>
      <div className="secondary-text clear-bill-finance-child-subtitle-text">
        {constants.subTitle}
      </div>
      <div className="container-detail m4-b">
        <div className="row list-summary-container no-gutters">
          <div className="col-12 d-flex m2-b flex-row">
            <div
              className="body-3b ellipsis-text"
              style={{ width: "17%", paddingLeft: "8%" }}
            >
              {constants.bankType}
            </div>
            <div className="body-3 ellipsis-text pr-2" style={{ width: "24%" }}>
              {replaceBankType(
                includes(keys(BANK_TYPE), get(props.detailBill, "bankType", ""))
                  ? BANK_TYPE[
                      get(props.detailBill, "bankType", "GHBANK") as TBankType
                    ]
                  : get(props.detailBill, "bankType", "")
              )}
            </div>
            <div
              className="body-3b ellipsis-text"
              style={{ width: "10%" }}
            >
              {constants.bankNumber}
            </div>
            <div className="body-3 ellipsis-text" style={{ width: "16%" }}>
              {numberSpace(String(isEmpty(get(props.detailBill, "bankNumber","-"))||isNull(get(props.detailBill, "bankNumber","-")) ? "-":props.detailBill?.bankNumber))}
            </div>
            <div className="body-3b ellipsis-text" style={{ width: "8%" }}>
              {constants.bankName}
            </div>
            <div className="body-3 ellipsis-text" style={{ width: "20%" }}>
              {isEmpty(get(props.detailBill, "bankName","-"))||isNull(get(props.detailBill, "bankName","-")) ? "-":props.detailBill?.bankName}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex subtitle-2 m0-b">{constants.save}</div>
      <Input
        id="search-customerName"
        name="search-customerName"
        inputClassName="input-save border"
        placeholder={constants.save}
        backgroundColor={colors.PRIMARY_BG}
        value={note}
        onChange={(e) => {
          setNote(e.target.value);
        }}
        error={confirm && note === ""}
        errorMessage={constants.saveRequired}
        renderError={true}
      />

      <p className="body-semi1 tertiary-text p2-x">{props.description}</p>
      <div className="row justify-content-center">
        <div className="col p0-r">
          <Button
            id="confirm-button"
            type="rectangle"
            onClick={handleSubmit}
            text={constants.accept!}
            textSize={18}
            textColor={color.PRIMARY_BG}
            transitionType="typeOne"
          />
        </div>
        <div className="col">
          <Button
            id="confirm-button"
            type="rectangle"
            backgroundColor={colors.PRIMARY_RED}
            backgroundHoverColor={colors.SECONDARY_RED}
            onClick={props.cancelAction}
            text={constants.cancelClearBill}
            transitionType="typeOne"
            textSize={18}
            textColor={color.PRIMARY_BG}
            disabled={true}
          />
        </div>
        <div className="col">
          <Button
            id="confirm-button"
            type="rectangle"
            backgroundColor={colors.TERTIARY_TEXT}
            backgroundHoverColor={colors.TERTIARY_TEXT}
            borderColor={colors.SECONDARY_TEXT}
            onClick={props.cancelAction}
            text={constants.cancel!}
            transitionType="typeOne"
            textSize={18}
            textColor={color.PRIMARY_BG}
          />
        </div>
      </div>
    </div>
  );
};

ClearBillFinanceChildModal.defaultProps = defaultProps;

const modal = {
  show: (props: IClearBillFinanceChildModal) => {
    return Modal.event("MODAL_OVER", {
      state: "show",
      extraProps: { size: "xl" },
      component: <ClearBillFinanceChildModal {...props} />,
    });
  },
  hide: () => Modal.event("MODAL_OVER", { state: "hide" }),
};

export default modal;
