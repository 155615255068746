import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { postChildUpdateAction } from "../actionCreator";
import { fetchPostChildUpdate } from "../services";

const postChildUpdate = (data: IV2AgentChildUpdate) => (dispatch: Dispatch) => {
    dispatch(postChildUpdateAction.request({}))
    return fetchPostChildUpdate(data)
    .then((res: AxiosResponse<{}>) => {
        dispatch(postChildUpdateAction.success(res))
    })
    .catch((error: AxiosError<IAPIResponse>) => {
        dispatch(postChildUpdateAction.failure(error))
    })
}

export { postChildUpdate }