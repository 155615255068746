import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { getMeAction } from "../actionCreator";
import { fetchGetMe } from "../services";

const getMe = () => (dispatch: Dispatch) => {
    dispatch(getMeAction.request({}))
    return fetchGetMe()
    .then((res: AxiosResponse<IV2GetAgentMe>) => {
        dispatch(getMeAction.success(res))
    })
    .catch((error: AxiosError<IAPIResponse>) => {
        dispatch(getMeAction.failure(error))
    })
}

export { getMe };





