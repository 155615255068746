import axios from 'axios'
import environment from '../../constants/environment'

const fetchMain = () => {
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.main}`
    return axios.get(endpoint)
}
const fetchNews = (data: INewsRequest) => {
    const params = {
        params: {
            page: data.page,
            limit: data.limit
        }
    }
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.news}`
    return axios.get(endpoint, params)
}
const resetPassword = (data: IResetUserPasswordRequest) => {
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.resetPassword}`
    return axios.post(endpoint, data)
}
export {
    fetchMain,
    fetchNews,
    resetPassword
}