import React from "react"
import { FunctionComponent } from "react"
import "../../financeSumCustomer.style.scss"
import { number, dateThai } from "utils"
import { STATUS_FINANCE } from "constants/variables";
import { get, isEmpty } from "lodash";
import colors from 'constants/colors'
import { LoadingCircle } from "components";
const constants = {
  list: '(รายการ)',
  empty: 'ไม่มีข้อมูล...'
}
interface ITableMembers {
  column: Array<{
    codeName: string
    textalign: string
  }>
  dataList: Array<IV2RespAgentFinanceCustomer>
  onClick(data: IV2RespAgentFinanceCustomer, index: number): void
  loading: boolean
}

const Table: FunctionComponent<ITableMembers> = ({
  column,
  dataList,
  onClick,
  loading
}) => {



  const numberOfColor = (total: number) =>{
    return (
      total > 0 ? colors.SECONDARY_GREEN
      : total < 0 ? colors.PRIMARY_RED : colors.TERTIARY_TEXT
    )
  }

  const columns = (
    <thead>
      <tr className="tableHead">
        {column.map((data, index: number) => (
          <th key={index} className={`${data.textalign}`} >
            {
              index === 1
                ? <div className="d-flex flex-column">
                  <span>{data.codeName} </span>
                  <div className="remember-name" style={{ lineHeight: '8px' }}>{constants.list}</div>
                </div>
                : <span>{data.codeName}</span>
            }
          </th>
        ))}
      </tr>
    </thead>
  )


  const loadingTable= (
    <tr>
      <td className="text-center" colSpan={15}>
      <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
      </td>
    </tr>
  )
  const rows = (
    <tbody>
      {
        isEmpty(dataList) ?
          <tr>
            <td className="text-center color-row p-2" colSpan={15}>
              {constants.empty}
            </td>
          </tr>
          :
          dataList.map((data, index: number) => {
            const stName = get(STATUS_FINANCE, `${data.status}.status`, '')
            const stColor = get(STATUS_FINANCE, `${data.status}.color`, '')

            return (
              <tr className={`row-finance-customer`} key={index} onClick={() => onClick(data,index)}>
                <td className="text-left paddingRow">
                  <span className="pd-frist">{data.agentShareId}</span>
                </td>
                <td className="text-center" style={{width:'10%'}}>
                  <span>{data.totalCustomer}</span>
                </td>
                <td className="text-right">
                  <span>{number.addComma(data.betMe)}</span>
                </td>
                <td className="text-right">
                  <span>{number.addComma(data.betHost)}</span>
                </td>
                <td className="text-right">
                  <span>{number.addComma(number.calculator(`${data.betMe}+${data.betHost}`))}</span>
                </td>
                <td className="text-right">
                  <span>{number.addComma(data.discount)}</span>
                </td>
                <td className="text-right">
                  <span>{number.addComma(data.com)}</span>
                </td>
                <td className="text-right">
                  <span style={{color: numberOfColor(number.calculator(`${data.recieveHost}+${data.recieveChild}`))}} >
                    {number.addComma(number.calculator(`${data.recieveHost}+${data.recieveChild}`))}
                  </span>
                </td>
                <td className="text-right">
                  <span style={{color: number.calculator(`${data.payHost}+${data.payChild}`) !==0 ? colors.PRIMARY_RED : colors.TERTIARY_TEXT}}>
                    {number.addComma(number.calculator(`${data.payHost}+${data.payChild}`))}
                  </span>
                </td>
                <td style={{ width: '10%' }} className="text-right">
                  <span className="text-status" style={{ color: numberOfColor(data.resultMe) }}>
                    {number.addComma(data.resultMe)}
                  </span>
                </td>
                <td className="text-center">
                  <span className="text-status" style={{ color: stColor }}>{stName}</span>
                </td>
                <td className="text-center">
                  <span>{dateThai.formatThai(data.createdAt)}</span>
                </td>
              </tr>
            )
          })
      }
    </tbody>
  )

  return (
    <div className="table-container">
      <table className="col ">
        {columns}
        {loading ? loadingTable : rows}
      </table >
    </div>
  )
}

export default Table
