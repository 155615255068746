import { object, ObjectSchema } from "yup";
import { string } from "yup";

const requiredMessages = {
  password2FA: "กรุณากรอกตัวเลข 6 หลัก",
};

const typeMessages = {
  password2FA: "ต้องเป็นตัวเลขเท่านั้น",
};

const lengthMessages = {
  password2FA: "ต้องเป็นตัวเลข 6 หลักเท่านั้น",
};

const scheme: ObjectSchema<IRegister2FAForm & any> = object().shape({
  password2FA: string()
    .required(requiredMessages.password2FA)
    .matches(/^[0-9]+$/, typeMessages.password2FA)
    .min(6, lengthMessages.password2FA)
    .max(6, lengthMessages.password2FA),
});

export default scheme;
