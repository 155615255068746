import { connect } from "react-redux";
import EditUserHistoryContainer from "./EditUserHistory.container";
import editUserHistorySelector from "reduxs/editUserHistory/selector";
import editUserHistoryActions from "reduxs/editUserHistory/actions";

const mapStateToProps = (
  state: RootReducers
): IEditUserHistoryContainerProps => {
  const selector = editUserHistorySelector.editUserHistory(state);
  return {
    getEditUserHistoryListIsFetching: selector.isFetching,
    getEditUserHistoryListError: selector.error,
    getEditUserHistoryListCode: selector.code,
    getEditUserHistoryListData: selector.data,
  };
};

const mapDispatchToProps: IEditUserHistoryContainerActionProps = {
  getditUserHistory: (query) =>
    editUserHistoryActions.getEditUserHistory(query),
  clearEditUserHistory: editUserHistoryActions.clearEditUserHistory,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUserHistoryContainer);
