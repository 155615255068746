import React, { Component } from "react";
import { Breadcrumb, OrgChart } from "components";
import { RouteComponentProps } from "react-router-dom";
import { noop } from "lodash";

const constants = {
  title: "ข้อมูลสมาชิก",
  diagramLable: "ผังสายงาน",
};

const defaultProps: IMembersDiagramProps & IMembersDiagramActionProps = {
  membersDiagramCode: 0,
  membersDiagramData: [],
  membersDiagramError: "",
  membersDiagramIsFetching: false,
  getMemberDiagram() {
    noop();
  },
};

class MainContainer extends Component<
  IMembersDiagramProps & IMembersDiagramActionProps & RouteComponentProps
> {
  static defaultProps = defaultProps;
  state: IMembersDiagramState = {};

  componentDidMount() {
    this.props.getMemberDiagram();
  }

  componentDidUpdate(prevProps: IMembersDiagramProps) {}

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: "ข้อมูลสมาชิก", active: false },
      { label: "ผังสายงาน", active: true },
    ];
    return (
      <>
        <div className="paper-container main-container">
          <div className="row">
            <div className="col">
              <div className="sub-menu">
                <Breadcrumb items={navigates} />
              </div>
            </div>
          </div>
          <div className="paper-title">{constants.title}</div>
          <div className="paper-description">{constants.diagramLable}</div>
          <div className="paper-body">
            <div className="row">
              <div className="col-12">
                <OrgChart listDiagram={this.props.membersDiagramData} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default MainContainer;
