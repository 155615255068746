import { Dispatch } from "redux";
import { setAuthenticationAction } from "../actionCreator";
import { responseCode } from "../../../constants/response";

const setToken = (tokenData: IResponseAgentLogin) => (dispatch: Dispatch) => {
  dispatch(setAuthenticationAction.request({}));
  try {
    dispatch(setAuthenticationAction.success(tokenData));
    return Promise.resolve()
  } catch {
    dispatch(setAuthenticationAction.failure(responseCode.BAD_REQUEST));
     return Promise.reject()
  }
};

export { setToken };
