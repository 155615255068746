import { connect } from "react-redux"
import { postChildResetPassword } from "reduxs/child/childResetPassword/action"
import { onChangeIsLoaddingAction } from "../../reduxs/Loader/action"
import MembersResetPassword from "./MembersResetPassword.container"
import childSelector from "reduxs/child/selector"

const mapStateToProps = (state: RootReducers): IMemberChildResetPasswordProps  => {
    const resetPass = childSelector.memberRePassword(state)
    return {
      childResetPassCode: resetPass.code,
      childResetPassError:resetPass.error,
      childResetPassIsFetching: resetPass.isFetching,
      childResetPassData: resetPass.data,

    }
  }
const mapDispatchToProps: IMemberResetPasswordActionProps = {
    postChildResetPassword: postChildResetPassword,
    onChangeIsLoaddingAction,

}

export default connect(mapStateToProps,mapDispatchToProps)(MembersResetPassword)