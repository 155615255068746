import { connect } from "react-redux";
import NumbersHolesAddLotteryContainer from "./NumbersHolesAddLottery.container";
import { onChangeIsLoaddingAction } from "../../reduxs/Loader/action"


const mapStateToProps = (state: RootReducers) => {
  return {}
}
const mapDispatchToProps = {
  onChangeIsLoaddingAction,
}


export default connect(mapStateToProps,mapDispatchToProps)(NumbersHolesAddLotteryContainer)







