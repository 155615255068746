import React from "react"
import { FunctionComponent } from "react"
import "../BetLotteryGroup/betLotteryGroup.style.scss"
import { find, get, includes, isEmpty, isNil } from "lodash"
import { date, number } from "utils"
import { CODE_TO_NAME } from "constants/variables"



interface ITableMembers {
    trxData: IV3ExtractionGroupingLottery[]
    lottoSchedule: IV2RespAgentLotterySchedule[]
    position: number
}

const BetSumTotale: FunctionComponent<ITableMembers> = ({
    trxData,
    lottoSchedule,
    position,
}) => {
    const sumTotalBet = () => {
        const result = trxData
            .filter(item => item.trx.some(transaction => transaction.status !== 'CANCEL'))
            .map(item => {
                return number.decimalsFormatComma(
                    item.trx
                        .filter(transaction => transaction.status !== 'CANCEL')
                        .reduce((total, transaction) => total + parseFloat(String(transaction.totalBet)), 0)
                        .toFixed(2)
                );
            })
            .reduce((total, item) => total + parseFloat(String(item.replace(/,/g, ''))), 0)
        return number.decimalsFormatComma(result.toFixed(2))
    }
    const sumDiscount = () => {
        const result = trxData
            .filter(item => item.trx.some(transaction => transaction.status !== 'CANCEL'))
            .map(item =>
                number.decimalsFormatComma(
                    item.trx
                        .filter(transaction => transaction.status !== 'CANCEL')
                        .reduce((total, transaction) => total + parseFloat(String(transaction.totalDis)), 0)
                        .toFixed(2)
                )
            ).reduce((total, item) => total + parseFloat(String(item.replace(/,/g, ''))), 0)
        return  number.decimalsFormatComma(result.toFixed(2))
    }
    const sumTotalSum = () => {
        const result = trxData
            .filter(item => item.trx.some(transaction => transaction.status !== 'CANCEL'))
            .map(item =>
                number.decimalsFormatComma(
                    item.trx
                        .filter(transaction => transaction.status !== 'CANCEL')
                        .reduce((total, transaction) => total + parseFloat(String(transaction.totalSum)), 0)
                        .toFixed(2)
                )
            ).reduce((total, item) => total + parseFloat(String(item.replace(/,/g, ''))), 0)
        return number.decimalsFormatComma(result.toFixed(2))
    }
    const sumTotalResult = () => {
        const result = trxData
            .filter(item => item.trx.some(transaction => transaction.status !== 'CANCEL'))
            .map(item =>
                number.decimalsFormatComma(
                    item.trx
                        .filter(transaction => transaction.status !== 'CANCEL')
                        .reduce((total, transaction) => {
                            const totalResult = parseFloat(String(transaction.totalResult))
                            if (!isNaN(totalResult)) {
                                return total + totalResult
                            }
                            return total
                        }, 0)
                )
            ).reduce((total, item) => total + parseFloat(String(item.replace(/,/g, ''))), 0)
        return number.decimalsFormatComma(String(result.toFixed(2)))
    }

    const tableTotal = () => {
        const lottoScheduleObj = find(lottoSchedule, { 'name': trxData[position].trx[0].name })
        const formatEndTime = date.formatThai(get(lottoScheduleObj, 'endTime', ''), 'dd/MM/yyyy')
        const updatedAt = date.formatThai(trxData[position].trx[0].updatedAt, 'dd/MM/yyyy')

        return (
            <tr
                className={`tfoot-table tertiary-green-bg`}
                key={`Footer-table ${trxData[position].trx[0].customerId}`}
                style={{ height: 36 }}
            >
                <td className="text-left pl-3" style={{ width: 250 }}>
                    <span style={{ fontWeight: 600 }}>{`รวมทั้งหมด`}</span>
                </td>
                <td className="text-left hide-drag" style={{ width: 250 }}>
                    <span className="body-4-secondary-text tertiary-green-text">{(CODE_TO_NAME[includes(trxData[position].trx[0].name, "YEGEE") ? "YEGEE" : trxData[position].trx[0].name]).replace("หวย", "") + (includes(trxData[position].trx[0].name, "YEGEE") ? ` รอบที่ ${trxData[position].trx[0].name.replace("YEGEE_", '')}` : "")}</span>
                </td>
                <td className="text-left hide-drag" style={{ width: 200 }}>
                    <span className="body-4-secondary-text tertiary-green-text">{isNil(trxData[position].trx[0].totalResult) ? formatEndTime : updatedAt}</span>
                </td>
                <td className="text-right" style={{ width: 150 }}>
                    <span className="body-4-darkgreen">{sumTotalBet()}</span>
                </td>
                <td className="text-right" style={{ width: 200 }}>
                    <span className="body-4-red">{sumDiscount()}</span>
                </td>
                <td className="text-right" style={{ width: 200, }}>
                    <span className="body-4-primary-blue-green">{sumTotalSum()}</span>
                </td>
                <td className="text-right" style={{ width: 200 }}>
                    <span className="body-4-primary-blue-detail">{sumTotalResult()}</span>
                </td>
                <td className="text-left p2-l hide-drag" style={{ width: 400, }}>
                    <span className="tertiary-green-text">{trxData[position].trx[0].customerName.replace(/\p{So}/gu, '@@')}</span>
                </td>
                <td className="text-center hide-drag" style={{ width: 150 }}>
                    <span className="body-4-primary-blue-detail tertiary-green-text">ดูรายละเอียด</span>
                </td>
            </tr>
        )
    }

    return (
        <>
            <div className="table-container">
                <div className="table-responsive-xl">
                    <table className="w-100" style={{ borderBottom: 'unset' }}>
                        <tfoot>{!isEmpty(trxData) && (tableTotal())}</tfoot>
                    </table>
                </div>
            </div>
        </>
    )
}

export default BetSumTotale
