import { AxiosError, AxiosResponse } from "axios";
import { createAsyncAction } from "typesafe-actions";
import {
  GET_FINANCE_CUSTOMER_REQUEST,
  GET_FINANCE_CUSTOMER_SUCCESS,
  GET_FINANCE_CUSTOMER_FAILURE,
  GET_FINANCE_CHILD_REQUEST,
  GET_FINANCE_CHILD_SUCCESS,
  GET_FINANCE_CHILD_FAILURE,
  GET_FINANCE_CUSTOMER_TX_REQUEST,
  GET_FINANCE_CUSTOMER_TX_SUCCESS,
  GET_FINANCE_CUSTOMER_TX_FAILURE,
  GET_FINANCE_CHILD_ME_REQUEST,
  GET_FINANCE_CHILD_ME_SUCCESS,
  GET_FINANCE_CHILD_ME_FAILURE,
  GET_FINANCE_HOST_ME_REQUEST,
  GET_FINANCE_HOST_ME_SUCCESS,
  GET_FINANCE_HOST_ME_FAILURE,
  POST_FINANCE_CUSTOMER_TX_REQUEST,
  POST_FINANCE_CUSTOMER_TX_SUCCESS,
  POST_FINANCE_CUSTOMER_TX_FAILURE,
  POST_FINANCE_CUSTOMER_TX_CLEAR,
  POST_FINANCE_CHILD_APPROVE_REQUEST,
  POST_FINANCE_CHILD_APPROVE_SUCCESS,
  POST_FINANCE_CHILD_APPROVE_FAILURE,
  POST_FINANCE_CHILD_REQUEST_REQUEST,
  POST_FINANCE_CHILD_REQUEST_SUCCESS,
  POST_FINANCE_CHILD_REQUEST_FAILURE,
  POST_FINANCE_HOST_REQUEST,
  POST_FINANCE_HOST_SUCCESS,
  POST_FINANCE_HOST_FAILURE,

} from "./constants";

const financeCustomerAction = createAsyncAction(
  GET_FINANCE_CUSTOMER_REQUEST,
  GET_FINANCE_CUSTOMER_SUCCESS,
  GET_FINANCE_CUSTOMER_FAILURE
)<
  any,
  AxiosResponse<IAPIResponse<IPagination<IV2RespAgentFinanceCustomer>>>,
  AxiosError<IAPIResponse>
>();

const financeCustomerTxAction = createAsyncAction(
  GET_FINANCE_CUSTOMER_TX_REQUEST,
  GET_FINANCE_CUSTOMER_TX_SUCCESS,
  GET_FINANCE_CUSTOMER_TX_FAILURE,
)<any, AxiosResponse<IAPIResponse<IPagination<IV2RespAgentFinanceCustomerTx[]>>>, AxiosError<IAPIResponse>>()


const financeChildAction = createAsyncAction(
  GET_FINANCE_CHILD_REQUEST,
  GET_FINANCE_CHILD_SUCCESS,
  GET_FINANCE_CHILD_FAILURE
)<
  any,
  AxiosResponse<IAPIResponse<IPagination<IV2RespAgentFinanceChild>>>,
  AxiosError<IAPIResponse>
>();

const financeChildMeAction = createAsyncAction(
  GET_FINANCE_CHILD_ME_REQUEST,
  GET_FINANCE_CHILD_ME_SUCCESS,
  GET_FINANCE_CHILD_ME_FAILURE
)<
  any,
  AxiosResponse<IAPIResponse<IPagination<IV2RespAgentFinanceChildMe>>>,
  AxiosError<IAPIResponse>
>();

const financeHostMeAction = createAsyncAction(
  GET_FINANCE_HOST_ME_REQUEST,
  GET_FINANCE_HOST_ME_SUCCESS,
  GET_FINANCE_HOST_ME_FAILURE
)<
  any,
  AxiosResponse<IAPIResponse<IPagination<IV2RespAgentFinanceHostMe>>>,
  AxiosError<IAPIResponse>
>();



const financeCustomerpostTxAction = createAsyncAction(
  POST_FINANCE_CUSTOMER_TX_REQUEST,
  POST_FINANCE_CUSTOMER_TX_SUCCESS,
  POST_FINANCE_CUSTOMER_TX_FAILURE,
  POST_FINANCE_CUSTOMER_TX_CLEAR
)<any, AxiosResponse<IAPIResponse<object>>, AxiosError<IAPIResponse>,any>();

const financeChildApproveAction = createAsyncAction(
  POST_FINANCE_CHILD_APPROVE_REQUEST,
  POST_FINANCE_CHILD_APPROVE_SUCCESS,
  POST_FINANCE_CHILD_APPROVE_FAILURE
)<any, AxiosResponse<IV2RespAgentFinanceChildApprove>, AxiosError<IAPIResponse>>()

const financeChildRequestAction = createAsyncAction(
  POST_FINANCE_CHILD_REQUEST_REQUEST,
  POST_FINANCE_CHILD_REQUEST_SUCCESS,
  POST_FINANCE_CHILD_REQUEST_FAILURE,
)<any, AxiosResponse<IV2RespAgentFinanceChildRequest>, AxiosError<IAPIResponse>>()

const financeHostAction = createAsyncAction(
  POST_FINANCE_HOST_REQUEST,
  POST_FINANCE_HOST_SUCCESS,
  POST_FINANCE_HOST_FAILURE
)<
  any,
  AxiosResponse<IAPIResponse<IPagination<IV2RespAgentFinanceHost>>>,
  AxiosError<IAPIResponse>
>()

export {
  financeCustomerAction,
  financeChildAction,
  financeCustomerTxAction,
  financeChildMeAction,
  financeChildApproveAction,
  financeHostAction,
  financeCustomerpostTxAction,
  financeChildRequestAction,
  financeHostMeAction,
};
