import { connect } from "react-redux";
import MainContainer from "./membersDiagram.container";
import membersSelector from "../../reduxs/members/selector";
import { getMembersDiagram } from "../../reduxs/members/diagram/action";

const mapStateToProps = (state: RootReducers): IMembersDiagramProps => {
  const memberDiagramState = membersSelector.membersDiagram(state);
  return {
    membersDiagramData: memberDiagramState.data,
    membersDiagramCode: memberDiagramState.code,
    membersDiagramError: memberDiagramState.error,
    membersDiagramIsFetching: memberDiagramState.isFetching,
  };
};
const mapDispatchToProps: IMembersDiagramActionProps = {
  getMemberDiagram: getMembersDiagram,
};
export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);
