import routes from "constants/routes"
import colors from "constants/colors"
import React, { Component } from "react"
import "./financeSumCustomer.style.scss"
import { Table } from "./components"
import { get, isEmpty, noop, pickBy } from "lodash"
import { RouteComponentProps } from "react-router-dom"
import { handleOnClickBreadcrumb, transformer } from "utils"
import { responseCode, responseMessage } from "constants/response"
import { format } from 'date-fns'
import {
  Breadcrumb,
  ErrorModal,
  Panginate,
  Dropdown,
  DatePickerNew
} from "components"

const defaultProps: IFinanceCustomerProps & IFinanceCustomerActionProps = {
  getFinanceCustomer() { noop() },
  onChangeIsLoaddingAction() { noop() },
  financeCustomerCode: 0,
  financeCustomerError: "",
  financeCustomerIsFetching: false,
  financeCustomerData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
}

const constants = {
  title: "รับ/จ่าย ลูกค้า",
  subTitle: "สรุปยอดรวมลูกค้า",
  listSumTotal: 'รายการสรุปยอดบิล',
  status: "สถานะ",
  sort: "ตัวเลือกการค้นหา",
  rowPerPage: "จำนวนรายการต่อหน้า :",
  dataEmpty: "ไม่มีข้อมูล...",
  headTable: [
    { codeName: "เลขบิล", textalign: "text-left pd-frist" },
    { codeName: "จำนวนชื่อช่วยจำ", textalign: "text-center" },
    { codeName: "ถือสู้", textalign: "text-right" },
    { codeName: "แบ่งหุ้น", textalign: "text-right" },
    { codeName: "ยอดเดิมพัน", textalign: "text-right" },
    { codeName: "ส่วนลด", textalign: "text-right" },
    { codeName: "ค่าคอม", textalign: "text-right" },
    { codeName: "ยอดรับ", textalign: "text-right" },
    { codeName: "ยอดจ่าย", textalign: "text-right" },
    { codeName: "สรุปกำไร/ขาดทุน", textalign: "text-right" },
    { codeName: "สถานะ", textalign: "text-center" },
    { codeName: "เวลา", textalign: "text-center" },
  ],
  statusMembers: {
    title: "สถานะสมาชิก",
  },
  breadcrumb: {
    main: "ภาพรวม",
    membersList: "รับ/จ่าย ลูกค้า",
  },
}

class MembersListContainer extends Component<
  IFinanceCustomerProps &
  IFinanceCustomerActionProps &
  RouteComponentProps<any, any, { memberData: IV2GetAgentChild }> & { history: History },
  IFinanceCustomerState
> {
  static defaultProps = defaultProps
  state: IFinanceCustomerState = {
    currentPage: 1,
    currentRowPerPage: 10,
    statusActive: "",
    dataQuery: {
      limit: 10,
      page: 1,
      status: '',
      startTime: "",
      endTime: "",
    },
    dateStart: null,
    dateEnd: null,
    startPage: 1,
    endPage: 0,
  }


  getFinanceCustomer = () => {
    this.props.getFinanceCustomer(pickBy(this.state.dataQuery))
  }

  componentDidMount() {
    this.getFinanceCustomer()
  }
  componentDidUpdate(prevProps: IFinanceCustomerProps) {
    if (
      prevProps.financeCustomerIsFetching !== this.props.financeCustomerIsFetching &&
      !this.props.financeCustomerIsFetching
    ) {
      this.props.onChangeIsLoaddingAction(false)
      if (this.props.financeCustomerCode === responseCode.OK) {
        if (isEmpty(this.props.financeCustomerData.dataList)) {
          this.setState({ startPage: 0, endPage: 0 });
        } else {
          this.setState({
            startPage:
              this.state.currentRowPerPage * (this.state.dataQuery.page - 1) +
              1,
            endPage:
              this.state.currentRowPerPage * (this.state.currentPage + 1) >=
                this.props.financeCustomerData.totalRecord
                ? this.props.financeCustomerData.totalRecord
                : this.state.currentRowPerPage * this.state.dataQuery.page,
          });
        }
      }
      else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            this.props.financeCustomerCode,
            responseMessage()[0]
          ),
        });
      }
    }
  }


  handleQueryDropdownChange = (value: string) => {
    this.setState(
      {
        statusActive: value,
        dataQuery: {
          ...this.state.dataQuery,
          status: value === 'ALL' ? '' : value,
          page: 1,
        },
      },
      () => {
        this.getFinanceCustomer()
      }
    )
  }

  handleClickChangePage = ({ selected: selectedPage }: ISelectPage) => {
    this.setState({ currentPage: selectedPage }, () => {
      this.setState(
        {
          dataQuery: {
            ...this.state.dataQuery,
            page: this.state.currentPage + 1,
          },
        },
        () => {
          this.getFinanceCustomer()
        }
      )
    })
  }

  onClickRowBillList = (data: IV2RespAgentFinanceCustomer, index: number) => {
    const query = new URLSearchParams(transformer.snakecaseTransform({ agent_share_id: data.agentShareId })).toString()

    this.props.history.push(`${routes.financeCustomerDetails.path}?${query}`, 
    { ...this.state.dataQuery,
      agent_share_id: data.agentShareId, 
      rowIndex: index,
      totalRecord: this.props.financeCustomerData.dataList.length
     })
  }

  renderFilter = () => (
    <div className="filterBox">
      <div className="row ">
        <div className="col-4 px-0">
            <DatePickerNew
              btnClear
              isShowIcon
              value={this.state.dateStart}
              placeholder="วันเริ่มต้น"
              format="DD MMM YYYY"
              editable={false}
              onChange={(value: any) => {
                if (value !== null) {
                  let startDate = new Date(Number(value))
                  this.setState(
                    {
                      dateStart: value,
                      dataQuery: {
                        ...this.state.dataQuery,
                        startTime: format(startDate, "ddMMyyyy"),
                      }
                    }, 
                    () => { 
                      this.getFinanceCustomer()
                    }
                  )
                }else{
                  this.setState({
                    dateStart: value,
                    dataQuery: {
                    ...this.state.dataQuery,
                    startTime: ''
                    }
                  },()=>{this.getFinanceCustomer()})
                }
              }}
            />
        </div>
        <div className="col-4 pr-0">
            <DatePickerNew
              btnClear
              isShowIcon
              value={this.state.dateEnd}
              placeholder="วันสิ้นสุด"
              format="DD MMM YYYY"
              editable={false}
              onChange={(value: any) => {
                let endDate = new Date(Number(value))
                if (value !== null) {
                  this.setState(
                    {
                      dataQuery: {
                        ...this.state.dataQuery,
                        endTime: format( endDate, "ddMMyyyy" ),
                      }
                    }, 
                    () => { 
                      this.setState({dateEnd: value})
                      this.getFinanceCustomer()
                    }
                  )
                }else{
                  this.setState({
                    dateEnd: value,
                    dataQuery: {
                    ...this.state.dataQuery,
                    endTime: ''
                    }
                  },()=>{this.getFinanceCustomer()})
                }
              }}
            />
        </div>
        <div className="col-4 pr-0">
          <div className="dropdown-border">
            <Dropdown
              menuStyles={{ width: '100%' }}
              options={[
                {
                  value: "ALL",
                  label: "ทั้งหมด",
                  disabled: false,
                },
                {
                  value: "WAIT",
                  label: "ยังไม่เคลียร์",
                  searchableName: "DESC",
                  disabled: false,
                },
                {
                  value: "PENDING",
                  label: "ทำรายการค้างไว้",
                  searchableName: "ASC",
                  disabled: false,
                },
                {
                  value: "SUCCESS",
                  label: "เคลียร์",
                  disabled: false,
                },

              ]}
              controlBackgroundColor={colors.PRIMARY_BG}
              handleOnChange={(target: IOptions) => {
                this.handleQueryDropdownChange(
                  String(target.value)
                )
              }}
              valueContainerStyles={{fontSize: 14}}
              value={this.state.statusActive}
              placeholder={constants.status}
            />
          </div>
        </div>
      </div>
    </div>
  )
  renderTableMembers = () => {
    return (
      <>
        <Table
          loading={this.props.financeCustomerIsFetching}
          column={constants.headTable}
          dataList={this.props.financeCustomerData.dataList}
          onClick={this.onClickRowBillList}
        />
      </>
    )
  }


  renderDropDownBox = () => {
    return (
      <>
        <h6>{constants.rowPerPage}</h6>
        <div className="dropdown-rowPerPage">
          <Dropdown
            menuPlacement={"top"}
            options={[
              { value: 5, label: "5", disabled: false },
              { value: 10, label: "10", disabled: false },
              { value: 20, label: "20", disabled: false },
              { value: 50, label: "50", disabled: false },
              { value: 100, label: "100", disabled: false },
            ]}
            controlBackgroundColor={colors.PRIMARY_BG}
            placeholder={String(this.state.currentRowPerPage)}
            handleOnChange={(target: IOptions) => {
              this.setState(
                {
                  currentPage: 1,
                  currentRowPerPage: Number(target.value),
                  dataQuery: {
                    ...this.state.dataQuery,
                    page: 1,
                    limit: Number(target.value),
                  },
                },
                () => {
                  this.getFinanceCustomer()
                }
              );
            }}
          />
        </div>
      </>
    )
  }
  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.breadcrumb.main, path: "/", active: false },
      { label: constants.breadcrumb.membersList, active: true },
    ]

    const RenderFilter = this.renderFilter()
    const RenderTableMembers = this.renderTableMembers()
    const RenderDropDownBox = this.renderDropDownBox
    return (
      <>
        <div className="paper-container finance-sum-customer-container">
          <div className="row">
            <div className="col">
              <div className="sub-menu">
                <Breadcrumb
                  items={navigates}
                  handleOnClickItem={handleOnClickBreadcrumb}
                />
              </div>
            </div>
          </div>
          <div className="paper-title">{constants.title}</div>
          <div className="pr-3 paper-description">{constants.subTitle}</div>
          <div className="paper-body">
            <div className="box-shadow m3-t boxShadow-Border">
              <div className="d-flex m3-b">
                <div className="paper-body-title">
                  {constants.listSumTotal}
                </div>
              </div>
              <div className="subtitle-2 m1-b">{constants.sort}</div>
              <div className="row">
                <div className="col">{RenderFilter}</div>
              </div>
              <div className="row ">
                <div className="col  mt-4">{RenderTableMembers}</div>
              </div>
              <div className='row'>
                <div className="col d-flex justify-content-end align-items-center ">
                  <RenderDropDownBox />
                </div>
                <h6 className='d-flex justify-content-end align-items-center'>
                  {`${this.state.startPage} - ${this.state.endPage} 
                       of ${this.props.financeCustomerData.totalRecord}`}
                </h6>
                <div className='col-auto'>
                  <Panginate
                    pageTotal={this.props.financeCustomerData.total}
                    initialPage={this.state.dataQuery.page - 1}
                    pageRangeDisplayed={this.state.currentPage}
                    onPageChange={this.handleClickChangePage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default MembersListContainer
