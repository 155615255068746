import { AxiosError, AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { ActionType, createReducer } from "typesafe-actions";
import { responseConverter } from "utils";
import * as hostAction from "./actionCreator";
import { hostState } from "./constants";

export type HostActionType = ActionType<typeof hostAction>;
const host = createReducer<ReducerState<IV2GetAgentHost>, HostActionType>(
  hostState
)
  .handleAction(
    hostAction.getHostAction.request,
    (state: ReducerState<IV2GetAgentHost>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    hostAction.getHostAction.success,
    (state: ReducerState<IV2GetAgentHost>, action: HostActionType) => {
      const payload: AxiosResponse<IAPIResponse<{}>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: { ...state.data, ...payload.data.data },
        error: "",
      };
    }
  )
  .handleAction(
    hostAction.getHostAction.failure,
    (state: ReducerState<IV2GetAgentHost>, action: HostActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );

export default combineReducers({
    host,
})