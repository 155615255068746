import flagImage from "assets/images/global/flags";
import { Collapse } from "components";
import { CODE_TO_NAME, LOTTO_FLAG_ALPHA } from "constants/variables";
import { find, isEmpty, isEqual } from "lodash";
import React, { Component } from "react";
import "./settingRateConfig.style.scss";

const constants = {
  numberPit: "ลิมิตการแทงต่อหมายเลข (หลุมเลข)",
  fightingLottoConfig: "ตั้งสู้ตามประเภท"
};

type DefaultProps = Readonly<typeof defaultProps>;
const defaultProps: ISettingRateConfigProps = {
  settingRateConfig: [],
};

class SettingRateConfigComponent extends Component<
  ISettingRateConfigProps & DefaultProps
> {
  static defaultProps = defaultProps;
  state: ISettingRateConfigState = {
    settingRateListConf: [],
    isExpandNumberPit: false,
    isExpandFightingLotto: false,
    isWaitTransitionNumberPit: false,
    isWaitTransitionFightingLotto: false,
    expanderControlNumberPit: [],
    expanderControlFightingLotto: [],
  };

  componentDidMount() {
    const transitionElement = document.getElementsByClassName(
      "user-info-description-container"
    )
    const numberPitTransitionElement = transitionElement[0]
    numberPitTransitionElement.addEventListener("transitionend", () => {
      if (!this.state.isExpandNumberPit) {
        this.setState({ isWaitTransitionNumberPit: false });
      }
    });

    const fightingLottoTransitionElement = transitionElement[1]
    fightingLottoTransitionElement.addEventListener("transitionend", () => {
      if (!this.state.isExpandFightingLotto) {
        this.setState({isWaitTransitionFightingLotto: false})
      }
    })
  }

  componentDidUpdate(
    prevProps: ISettingRateConfigProps,
    prevState: ISettingRateConfigState
  ) {
    if (prevProps.settingRateConfig !== this.props.settingRateConfig) {
      this.handleExpandCtrl(this.props.settingRateConfig)
    }

    if (this.state.isExpandNumberPit !== prevState.isExpandNumberPit) {
      if (this.state.isExpandNumberPit) {
        this.setState({ isWaitTransitionNumberPit: true });
      }
    }

    if (this.state.isExpandFightingLotto !== prevState.isExpandFightingLotto) {
      if (this.state.isExpandFightingLotto) {
        this.setState({isWaitTransitionFightingLotto: true})
      }
    }
  }

  handleExpandCtrl = (settingRate: ISettingRateConfig[]) => {
    const expanderControl = this.props.settingRateConfig.map<ExpanderControl>(
      (data) => ({
        name: data.name,
        isExpand: false,
      })
    )
    this.setState({
      expanderControlNumberPit: expanderControl,
      expanderControlFightingLotto: expanderControl,
      settingRateConf: this.props.settingRateConfig,
    })
  }

  clickNumberPit = () => {
    this.setState({ isExpandNumberPit: !this.state.isExpandNumberPit });
  };

  clickFightingLotto = () => {
    this.setState({ isExpandFightingLotto: !this.state.isExpandFightingLotto})
  }

  renderNumberPit = () => {
    return this.props.settingRateConfig.map((data, index) => {
      const expandObj = find<ExpanderControl>(
        this.state.expanderControlNumberPit,
        (item) => isEqual(item.name, data.name)
      )
      const isExpanded = isEmpty(expandObj) ? false: expandObj?.isExpand

      const onPressExpand = () => {
        const newObj = this.state.expanderControlNumberPit.filter(
          (item) => item.name !== expandObj?.name
        )
        this.setState({
          expanderControlNumberPit: [
            ...newObj,
            {
              ...expandObj,
              isExpand: !expandObj?.isExpand,
            }
          ]
        })
      }
      const icon = flagImage[LOTTO_FLAG_ALPHA[data.name]].Icon
      return (
        <Collapse
          key={index}
          isExpand={isExpanded!}
          name={CODE_TO_NAME[data.name]}
          icon={icon}
          onClick={onPressExpand}
          classHeader="p1 primary-bg"
        >
          <div>Number Pit</div>
        </Collapse>
      )
    })
  };

  renderFightingLotto = () => {
    return this.props.settingRateConfig.map((data, index) => {
      const expandObj = find<ExpanderControl>(
        this.state.expanderControlFightingLotto,
        (item) => isEqual(item.name, data.name)
      )
      const isExpanded = isEmpty(expandObj) ? false: expandObj?.isExpand

      const onPressExpand = () => {
        const newObj = this.state.expanderControlFightingLotto.filter(
          (item) => item.name !== expandObj?.name
        )
        this.setState({
          expanderControlFightingLotto: [
            ...newObj,
            {
              ...expandObj,
              isExpand: !expandObj?.isExpand,
            }
          ]
        })
      }
      const icon = flagImage[LOTTO_FLAG_ALPHA[data.name]].Icon
      return (
        <Collapse
          key={index}
          isExpand={isExpanded!}
          name={CODE_TO_NAME[data.name]}
          icon={icon}
          onClick={onPressExpand}
          classHeader="p1 primary-bg"
        >
          <div>Fighting</div>
        </Collapse>
      )
    })
  }

  handleNumberPitSubBackground = () => {
    if (this.state.isWaitTransitionNumberPit) {
      return "sub-background-number-pit";
    }
    return "";
  };

  handleFightingLottoSubBackground = () => {
    if (this.state.isWaitTransitionFightingLotto) {
      return "sub-background-fighting-lotto"
    }
    return "";
  }

  render() {
    const RenderNumberPit = this.renderNumberPit();
    const RenderFightingLotto = this.renderFightingLotto();

    return (
      <div className="setting-rate-config-container">
        <div className="row pt-3 pb-3">
          <div className="col number-pit-column number-pit-group">
            <div className={this.handleNumberPitSubBackground()} />
            <Collapse
              isExpand={this.state.isExpandNumberPit}
              name={constants.numberPit}
              onClick={this.clickNumberPit}
              classHeader="tertiary-bg p2"
            >
              {RenderNumberPit}
            </Collapse>
          </div>
        </div>
        <div className="row pt-3 pb-3">
          <div className="col fighting-lotto-column fighting-lotto-group">
            <div className={this.handleFightingLottoSubBackground()} />
            <Collapse
            isExpand={this.state.isExpandFightingLotto}
            name={constants.fightingLottoConfig}
            onClick={this.clickFightingLotto}
            classHeader="tertiary-bg p2"
            >
              {RenderFightingLotto}
            </Collapse>
          </div>
        </div>
      </div>
    );
  }
}

export default SettingRateConfigComponent;
