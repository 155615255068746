import { get } from 'lodash'
import { getEditUserHistoryListState } from './constants'

const editUserHistory = (state: RootReducers): ReducerState<APIPagination<IEditUserHistory>> =>
    get(state, 'editUserHistory.editUserHistoryList', getEditUserHistoryListState)


const userSelector = {
    editUserHistory,
}

export default userSelector