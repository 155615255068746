
import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { postChildResetPasswordAction } from "../actionCreator";
import { fetchPostChildResetPasswod } from "../services";

const postChildResetPassword = (data: IV2AgentChildPasswordUpdate) => (dispatch: Dispatch) => {
    dispatch(postChildResetPasswordAction.request({}))
    return fetchPostChildResetPasswod(data)
    .then((res: AxiosResponse<{}>) => {
        dispatch(postChildResetPasswordAction.success(res))
    })
    .catch((error: AxiosError<IAPIResponse>) => {
        dispatch(postChildResetPasswordAction.failure(error))
    })
}

export { postChildResetPassword }