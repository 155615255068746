import React, { FunctionComponent, useState, useEffect } from 'react'
import { Breadcrumb, Button, ConfirmModal } from "components"
import { handleOnClickBreadcrumb, number } from 'utils'
import { FinanceDetailsComponent } from './components/FinanceDetails'
import { BalanceListComponent } from './components/BalanceList'
import { BetMeListComponent } from './components/BetMeList'
import { BetChildListComponent } from './components/BetChildList'
import routes from 'constants/routes'
import './financeHostDetail.style.scss'
import { useDispatch, useSelector } from 'react-redux'
import { find, findIndex, get } from 'lodash'
import { Redirect, useParams, RouteComponentProps } from 'react-router-dom'
import { fetchGetFinanceHost } from 'reduxs/finance/services'
import { loaderAction } from 'reduxs/Loader/actionCreator'

const constants = {
    dashboardPage: 'ภาพรวม',
    financePage: 'การเงิน',
    payoutPage: 'รับ/จ่าย หัวสมาชิก',
    balanceClearPage: 'เคลืยร์ยอดหัวสมาชิก',
    pageTitle: 'เคลียร์ยอดหัวสมาชิก',
    billNumber: 'เลขบิล',
    balanceRemaining: 'ยอดรับ/จ่ายคงเหลือ',
    nextBill: 'ถัดไป',
    prevBill: 'ก่อนหน้า',
    confirmNextBill: 'บิลปัจจุบันคือ รายการบิลสุดท้ายรายการถัดไปของท่าน จะกลับไปเริ่มต้นบิลที่ หนึ่งใหม่อีกครั้ง',
    confirm: "ยืนยัน",
    confirmAtFirstBill: "ยืนยันไปที่บิลแรก"
}

const FinanceHostDetail: FunctionComponent<RouteComponentProps> = ({ history }) => {

    const [financeDetailData, setFinanceDetailData] = useState<IV2RespAgentFinanceHost | null>(null)
    const [currentIndex, setCurrentIndex] = useState(-1)
    const [totalRecord, setTotalRecord] = useState(0)
    const [loadingFinance, setLoadingFinance] = useState(false)
    const dispatch = useDispatch()

    const loginPermission = useSelector((state) => get(state, 'me.me.data.permission', {}))

    const { id: agentShareId } = useParams<{ id: string }>()

    const navigates: IBreadcrumbItem[] = [
        { label: constants.dashboardPage, path: routes.Dashboard.path, active: false },
        { label: constants.payoutPage, path: routes.financeSummaryHost.path, active: false },
        { label: constants.balanceClearPage, active: true },
    ]

    useEffect(() => {
        setLoadingFinance(true)
        dispatch(loaderAction.success(true))
        getDetail()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOnReloadData = () => {
        getDetail()
    }

    const getDetail = () => {
        if (!agentShareId) { return }
        fetchGetFinanceHost({ limit: 99999 })
            .then((response) => {
                const financeDetailDatas = get(response, 'data.data.dataList', [])
                const dataTotalRecord = get(response, 'data.data.totalRecord', 0)
                const financeDetailData = find(financeDetailDatas, ['agentShareId', Number(agentShareId)])
                if (financeDetailData) {
                    const index = findIndex(financeDetailDatas, ['agentShareId', Number(agentShareId)])
                    setFinanceDetailData(financeDetailData)
                    setCurrentIndex(index)
                    setTotalRecord(dataTotalRecord)
                } else {
                    history.replace(routes.financeSummaryHost.path)
                }
            })
            .catch((error) => console.log(error))
            .finally(() => { 
                setLoadingFinance(false)
                dispatch(loaderAction.success(false)) 
            })
    }

    const handleGetNextOrPrevBillData = (state: 'next' | 'prev') => {
        if (!agentShareId) { return }
        if ((currentIndex + 1) >= totalRecord) {
            ConfirmModal.show({
                size: "md",
                acceptText: constants.confirm,
                title: constants.confirmNextBill,
                description: constants.confirmAtFirstBill,
                action: () => {
                    getNextOrPrevBillData(state, true)
                },
                cancelAction: () => {
                    ConfirmModal.hide()
                }
            })
        } else {
            getNextOrPrevBillData(state)
        }
    }

    const getNextOrPrevBillData = (state: 'next' | 'prev', loader = false) => {
        if (loader) { dispatch(loaderAction.success(true)) }
        fetchGetFinanceHost({ limit: 99999 })
            .then((response) => {
                const financeDetailData = get(response, 'data.data.dataList', [])
                const index = findIndex(financeDetailData, ['agentShareId', Number(agentShareId)])
                if (index < 0) {
                    const defaultAgentShareId = get(financeDetailData, '0.agentShareId', '')
                    if (defaultAgentShareId) {
                        return history.replace(`${routes.financeHost.exactPath}/${defaultAgentShareId}`)
                    }
                    return history.replace(routes.financeSummaryHost.path)
                } else {
                    const targetIndex = state === 'next'
                        ? ((index + 1) + 1) > financeDetailData.length ? 0 : (index + 1)
                        : (index - 1) < 0 ? financeDetailData.length - 1 : (index - 1)
                    const defaultAgentShareId = get(financeDetailData, `${targetIndex}.agentShareId`, '')
                    if (defaultAgentShareId) {
                        history.replace(`${routes.financeHost.exactPath}/${defaultAgentShareId}`)
                        return history.go(0)
                    }
                    return history.replace(routes.financeSummaryHost.path)
                }

            })
            .catch((error) => console.log(error))
            .finally(() => {
                ConfirmModal.hide()
                if (loader) { dispatch(loaderAction.success(false)) }
            })
    }

    if (!agentShareId) { return <Redirect to={routes.financeSummaryHost.path} /> }

    const remainingBalance = financeDetailData ? number.calculator(`${financeDetailData.recieveHost}-${financeDetailData.payHost}`) : 0

    return (
        <div className="paper-container finance-host-detail-container">
            <div className="row">
                <div className="col">
                    <div className="sub-menu">
                        <Breadcrumb items={navigates} handleOnClickItem={handleOnClickBreadcrumb} />
                    </div>
                </div>
            </div>
            <div className='paper-with-button-container'>
                <div>
                    <div className="paper-title">{constants.pageTitle}</div>
                    <div className="pr-3 paper-description page-subtitle">{constants.billNumber} <span className='bill-number-value'>{agentShareId}</span> {constants.balanceRemaining} {financeDetailData ? (<span className={`title-remaining-value ${remainingBalance > 0 ? 'primary-green-text' : remainingBalance < 0 ? 'primary-red-text' : 'tertiary-text'}`}>{number.addComma(remainingBalance)}</span>) : ''}</div>
                </div>
                <div className='bill-indeacator-button-container'>
                    {totalRecord > 1 && (
                        <>
                            {(currentIndex) > 0 && (
                                <Button
                                    id="prev-button-id"
                                    size="moreMedium"
                                    textSize={18}
                                    text={constants.prevBill}
                                    onClick={() => handleGetNextOrPrevBillData('prev')} />
                            )}
                            <Button
                                id="next-button-id"
                                size="moreMedium"
                                textSize={18}
                                text={constants.nextBill}
                                onClick={() => handleGetNextOrPrevBillData('next')} />
                        </>
                    )}
                </div>
            </div>
            <FinanceDetailsComponent financeDetailData={financeDetailData} loading={loadingFinance} />
            <BalanceListComponent financeDetailData={financeDetailData} onRefreshData={handleOnReloadData} />
            <BetMeListComponent agentShareId={agentShareId} />
            {loginPermission !== 'AGENT' && (<BetChildListComponent agentShareId={agentShareId} />)}
        </div>
    )
}

export default FinanceHostDetail