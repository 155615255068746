import { connect } from 'react-redux'
import { onChangeIsLoaddingAction } from 'reduxs/Loader/action'
import NumberBan from './NumberBan.container'
import numberBanSelector from "../../reduxs/numbersBan/selector"
import { getBanLottery, postCancelNumberBan } from "reduxs/numbersBan/action";

const mapStateToProps = (state: RootReducers) : INumberBanProps => {
const numberBanList = numberBanSelector.numberBanLotteryList(state)
return {
    getNumberBanLotteryListisFetching : numberBanList.isFetching,
    getNumberBanLotteryList :  numberBanList.data as any,
    getNumberBanLotteryCode : numberBanList.code,
    getNumberBanLotteryError : numberBanList.error,
}
}

const mapDispatchToProps : INumberBanActionProps = {
    onChangeIsLoaddingAction,
    getBanLottery,
    postCancelNumberBan

}

export default connect(mapStateToProps,mapDispatchToProps)(NumberBan)