import { Breadcrumb, Button, ErrorModal, LoadingCircle } from "components";

// import th from "date-fns/locale/th";
import { get, isEmpty, isNull, map, noop } from "lodash";
import React from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import "./numbersholes.style.scss";
import { NumberHolesListComponent } from "./components/NumbersHolesList";
import routes from "constants/routes";
import { AxiosError } from "axios";
import { responseMessage } from "constants/response";
import { responseConverter } from "utils";
import { fetchAgentHoles } from "reduxs/numbersHoles/services";

type DefaultProps = Readonly<typeof defaultProps>;
const constants = {
  mainPageTitle: "ภาพรวม",
  numberHolesTitle: "หลุมเลข",
  manageNumberHolesDesc: "จัดการหลุมเลข",
  dataNotFound: "ไม่มีข้อมูลหลุม โปรดเพิ่มหวยที่จะควบคุมด้วยระบบหลุมเลข",
  addLotto: "เพิ่มหวย",
  lotto: "หวย",
  holeSize: "ขนาดหลุม",
  totalNumberinHole: "เลขในหลุมทั้งหมด",
};
const mappingTypeNumbersHoles: IHeaderNumbersHolesTable[] = [
  { id: 1, name: "3 บน", type: "THREE_UP", length: 3, typeUP: true },
  { id: 2, name: "3 โต๊ด", type: "THREE_TOAST", length: 3, typeUP: false },
  { id: 3, name: "2 บน", type: "TWO_UP", length: 2, typeUP: true },
  { id: 4, name: "2 ล่าง", type: "TWO_DOWN", length: 2, typeUP: false },
  { id: 5, name: "วิ่งบน", type: "RUN_UP", length: 1, typeUP: true },
  { id: 6, name: "วิ่งล่าง", type: "RUN_DOWN", length: 1, typeUP: false },
];

const defaultProps: INumbersHolesContainerProps & INumbersHolesContainerAction = {
  getMe() {
    noop();
  },
  onChangeIsLoaddingAction() {
    noop();
  },
};

export default class NumbersHolesContainer extends Component<
  INumbersHolesContainerProps & INumbersHolesContainerAction & DefaultProps & RouteComponentProps
> {
  static defaultProps = defaultProps;

  state: INumbersHolesContainerState = {
    isAccordionShow: false,
    getNumbersHolesLottoData: [],
    isCurrentLotto: false,
    loading: false,
    loadingNumHolesList: false
  };

  componentDidMount() {
    this.fetchNumbersHolesLotto();
    window.addEventListener("beforeunload", this.handleBeforUnload);
    // if (this.props.history.action !== "PUSH") {
    // this.props.history.replace({ ...this.props.history, state: undefined });
    // }
  }

  handleBeforUnload = () => {
    const pathName = window.location.pathname;
    if (pathName === "/numbers-holes") {
      // this.props.history.replace({ ...this.props.history, state: undefined });
      window.history.replaceState(null, "", "/");
      // this.props.history.replace({});
      window.removeEventListener("beforeunload", this.handleBeforUnload);
    }
  };

  mergeLottoToArray = () => {
    let locationState = this.props.location.state;
    if (!isEmpty(locationState)) {
      const newObject = {
        numholfacId: null,
        agentLotsetId: locationState.agentLotsetId,
        name: locationState.name,
        countSpecialNumber: 0,
        numholfacLimitThreeUp: 1000000,
        numholfacLimitThreeToast: 1000000,
        numholfacLimitTwoUp: 1000000,
        numholfacLimitTwoDown: 1000000,
        numholfacLimitRunUp: 1000000,
        numholfacLimitRunDown: 1000000,
        totalActiveNumberPercentage: 0,
        numholfacCreatedAt: "",
        numholfacUpdatedAt: "",
      };
      this.setState({
        getNumbersHolesLottoData: [newObject, ...this.state.getNumbersHolesLottoData],
      });
    }
  };
  fetchNumbersHolesLotto = () => {
    this.setState({loading: true})
    fetchAgentHoles()
      .then((response) => {
        this.setState(
          {
            getNumbersHolesLottoData: get(response, "data.data", []),
          },
          () => {
            this.mergeLottoToArray();
          }
        );
      })
      .catch((error: AxiosError<IAPIResponse>) => {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            responseConverter.getMessage(error).code,
            responseMessage()[0]
          ),
        });
      })
      .finally(() => {
        this.setState({loading: false})
      });
  };

  renderTableHead = (values: Array<IHeaderNumbersHolesTable>) => {
    const mapHeadTable = map(values, (data: IHeaderNumbersHolesTable) => {
      return (
        <th className="text-right td-span" key={data.id} style={{ width: "10%" }}>
          <h5 className={`text-header secondary-text`}>{data.name}</h5>
        </th>
      );
    });

    return (
      <>
        <tr>
          <th rowSpan={2} className="text-center" style={{ width: "15%" }}>
            <h5
              className={`text-header secondary-text`}
              style={{ fontSize: "14px", lineHeight: "18px" }}
            >
              {constants.lotto}
            </h5>
          </th>
          <th colSpan={6} className="text-center">
            <h5
              className={`text-header secondary-text`}
              style={{ fontSize: "14px", paddingLeft: "calc(9% - 5px)" }}
            >
              {constants.holeSize}
            </h5>
          </th>
          <th rowSpan={2} className="text-center" style={{ width: "15%" }}>
            <h5
              className={`text-header secondary-text`}
              style={{ fontSize: "14px", lineHeight: "18px" }}
            >
              {constants.totalNumberinHole}
            </h5>
          </th>
        </tr>
        <tr>{mapHeadTable}</tr>
      </>
    );
  };
  renderTableBody = (values: IV2GetHolesAgent[]) => {
    const dataEmpty = (
      <tr className="tableisEmpty" style={{ height: 42.67 }}>
        <td className="text-center tertiary-text" colSpan={8}>
          {constants.dataNotFound}
        </td>
      </tr>
    )
    
    const loadingTable= (
      <tr>
        <td className="text-center" colSpan={8}>
        <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
        </td>
      </tr>
    )
    
    
    const handleCancel = (action: string) => {
      if (action === "CANCEL") {
        this.setState({
          getNumbersHolesLottoData: [...this.state.getNumbersHolesLottoData].slice(1),
        });
      }
      this.setState({ isCurrentLotto: true });
    };

    const mapData = map(values, (item: IV2GetHolesAgent) => {
      const statusNewLotto = !isEmpty(this.props.location.state) && isNull(item.numholfacId);
      return (
        <NumberHolesListComponent
          data={item}
          key={`${item.agentLotsetId}`}
          isCurrentLotto={this.state.isCurrentLotto}
          isStatusNewLotto={statusNewLotto}
          fetchNumbersHolesLotto={this.fetchNumbersHolesLotto}
          handleCancel={handleCancel}
          onChangeIsLoaddingAction={this.loadingAction}
          loading={this.state.loadingNumHolesList}
        />
      );
    });
    return this.state.loading ? loadingTable : !isEmpty(values) ? mapData : dataEmpty;
  };

  loadingAction = ()=>{
    this.setState({loadingNumHolesList: !this.state.loadingNumHolesList})
  }

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.mainPageTitle, active: false, path: "/dashboard" },
      { label: constants.numberHolesTitle, active: true },
    ];
    const TableHead = this.renderTableHead(mappingTypeNumbersHoles);
    const TableBody = this.renderTableBody(this.state.getNumbersHolesLottoData);

    return (
      <div className="paper-container numbersholes-container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="sub-menu" style={{ width: "fit-content" }}>
              <Breadcrumb
                items={navigates}
                handleOnClickItem={(path: string) => this.props.history.replace(path)}
              />
            </div>
          </div>
        </div>
        <div className="paper-title">{constants.numberHolesTitle}</div>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="paper-description">{constants.manageNumberHolesDesc}</div>
          <div className="d-flex flex-row align-items-center">
            <Button
              id={"add-member"}
              size={"moreMedium"}
              textSize={18}
              text={constants.addLotto}
              onClick={() => {
                this.props.history.push(routes.NumbersHolesAddLottery.path);
              }}
            />
          </div>
        </div>
        <div className="paper-body">
          <div className="box-shadow">
            <div className="row">
              <div className="col-12">
                <div className="table-container">
                  <div className="table-responseive-xl">
                    <table className="w-100">
                      <thead className="header-table">{TableHead}</thead>
                      <tbody>{TableBody}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
