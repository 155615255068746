import { string, object, ObjectSchema, ref } from "yup";
import {
  SPECIAL_ENG_AND_SPECIAL_CHAR,
} from "constants/regex";

const requiredMessages = {
  password: "กรุณากรอกรหัสผ่านใหม่",
  confirmPassword: "กรุณายืนยันรหัสผ่านใหม่",
};

const lengthMessages = {
  password: "รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร",
  confirmPassword: "ยืนยันรหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร",
};

const constants = {
  englishRequire: "กรอกเฉพาะภาษาอังกฤษ ตัวเลข หรือตัวอักษรพิเศษ !@#$%&*",
}



const scheme: ObjectSchema<IResetPasswordForm & any> = object().shape({
  password: string()
    .required(requiredMessages.password)
    .matches(SPECIAL_ENG_AND_SPECIAL_CHAR, constants.englishRequire)
    .min(6, lengthMessages.password),
  confirmPassword: string()
    .required(requiredMessages.confirmPassword)
    .matches(SPECIAL_ENG_AND_SPECIAL_CHAR, constants.englishRequire)
    .oneOf([ref("password"), null], requiredMessages.confirmPassword)
    .min(6, lengthMessages.confirmPassword)
    .when("password", {
      is: (val: string) => (val && val.length > 5 ? true : false),
      then: string().oneOf([ref("password")], "รหัสผ่านไม่ตรงกัน"),
    }),
});

export default scheme;
