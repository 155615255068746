import { date } from 'utils'

const formatThai = (dateTime: string) => {
    return date.formatThai(dateTime, 'dd MMM yyyy')
}

const dateThai = {
    formatThai,
};

export default dateThai;