import {
  BillSummaryList,
  Breadcrumb,
  Button,
  ConfirmModal,
  MemberBetList,
  ReceiveAndPayList,
} from "components";
import MemberBetListComponent from "components/MemberBetList/MemberBetList.component";
import ReceiveAndPayListComponent from "components/ReceiveAndPayList/ReceiveAndPayList.component";
import color from "constants/colors";
import { responseCode } from "constants/response";
import routes from "constants/routes";
import { meDataInitialValue } from "constants/variables";
import { findIndex, get, isEmpty, noop, omit } from "lodash";
import React, { Component } from "react";
import { RefObject } from "react";
import { RouteComponentProps } from "react-router-dom";
import { handleOnClickBreadcrumb, number, transformer } from "utils";
import "./financeChild.style.scss";

const constants = {
  title: "เคลียร์ยอดลูกสมาชิก",
  subTitle: (agentShareId: number) =>
    `เลขบิล ${agentShareId} ยอดรับ/จ่ายคงเหลือ`,
  breadcrumb: {
    main: "ภาพรวม",
    finance: "การเงิน",
    membersList: "รับ/จ่าย ลูกสมาชิก",
    financeChild: "เคลียร์ยอดลูกสมาชิก",
  },
  previous: "ก่อนหน้า",
  next: "ถัดไป",
  confirmNextBill: 'บิลปัจจุบันคือ รายการบิลสุดท้ายรายการถัดไปของท่าน จะกลับไปเริ่มต้นบิลที่ หนึ่งใหม่อีกครั้ง',
};

const defaultProps: IFinanceChildProps & IFinanceChildActionProps = {
  getMe(){
    noop()
  },
  financeChildCode: 0,
  financeChildData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  financeChildProps: {
    isFetching: false,
    code: 0,
    data: {
      page: 1,
      limit: 10,
      total: 0,
      dataList: [],
      totalRecord: 0,
    },
    error: "",
  },
  financeChildError: "",
  financeChildIsFetching: false,
  getFinanceChild() {
    noop();
  },
  onChangeIsLoaddingAction() {
    noop();
  },
  financeChildMeCode: 0,
  financeChildMeData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  dataReseive: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  financeChildMeError: "",
  financeChildMeIsFetching: false,
  getFinanceChildMe() {
    noop();
  },
  meData: meDataInitialValue,
  childBetCode: 0,
  childBetData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  childBetError: "",
  childBetIsFetching: false,
  getChildBet(query?) {
    noop();
  },
  agentChildCode: 0,
  agentChildData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  agentChildError: "",
  agentChildIsFetching: false,
  getChildMembers() {
    noop();
  },
  financeChildApproveCode: 0,
  financeChildApproveData: {
    agentPayTxId: 0,
  },
  financeChildApproveError: "",
  financeChildApproveIsFetching: false,
  postFinanceChildApprove() {
    noop();
  },
  postFinanceChildRequest() {
    noop()
  },
  financeChildRequestCode: 0,
  financeChildRequestData: {
    agentPayTxId: 0
  },
  financeChildRequestError: "",
  financeChildRequestIsFetching: false,
};

class FinanceChildContainer extends Component<
  IFinanceChildProps & IFinanceChildActionProps & RouteComponentProps,
  IFinanceChildState
> {
  static defaultProps = defaultProps;
  receiveAndPayListRef: RefObject<ReceiveAndPayListComponent> = React.createRef()
  memberBetListRef: RefObject<MemberBetListComponent> = React.createRef()
  state: IFinanceChildState = {
    totalRecordChild: 0,
    actionButton: "",
    queryObj: {
      agentShareId: 0,
      childAgentId: 0,
    },
    totalRecord: {
      receive: 0,
      pay: 0,
    },
    isFirstBill: false,
    queryStoreFinance: {
      limit: 9999,
      page: 1,
      rowIndex: 0,
      agentShareId: 0,
      childAgentId: 0,
    },
    financeChildDataState: [],
  };

  componentDidMount() {
    const queryObj: any = transformer.queryToObject();
    if (isEmpty(queryObj)) {
      this.props.history.push(routes.Dashboard.path);
    } else {
      const locationState: IV2QueryAgentFinanceChild &
        IQueryPagination & { rowIndex: number } = this.props.location.state;
      this.props.onChangeIsLoaddingAction(true);
      this.setState(
        {
          totalRecordChild: get(locationState, 'totalRecordChild', 0),
          queryStoreFinance: locationState,
          isFirstBill: locationState?.rowIndex + ((this.props.financeChildData.page - 1) * this.props.financeChildData.limit) === 0 ? true : false,
          queryObj: {
            ...this.state.queryObj,
            agentShareId: Number(queryObj.agentShareId),
            childAgentId: Number(queryObj.childAgentId),
          },
        },
        () => {
          this.props
            .getFinanceChild({
              agentShareId: Number(queryObj.agentShareId),
              page: 1,
              limit: 10,
            })
            .then(() => {
              this.setState({
                financeChildDataState: this.props.financeChildData.dataList,
              });
            });
        }
      );
    }
  }

  componentDidUpdate(prevProps: IFinanceChildProps) {
    if (
      prevProps.financeChildIsFetching !== this.props.financeChildIsFetching &&
      !this.props.financeChildIsFetching
    ) {
      this.props.onChangeIsLoaddingAction(false);
    }

    if (
      prevProps.financeChildMeIsFetching !==
      this.props.financeChildMeIsFetching &&
      !this.props.financeChildMeIsFetching
    ) {
      this.props.onChangeIsLoaddingAction(false);
    }

    if (
      prevProps.childBetIsFetching !== this.props.childBetIsFetching &&
      !this.props.childBetIsFetching
    ) {
      this.props.onChangeIsLoaddingAction(false);
    }
  }

  getCreditColor = (data: number) => {
    const creditColor =
      data > 0
        ? color.SECONDARY_GREEN
        : data < 0
          ? color.PRIMARY_RED
          : color.TERTIARY_TEXT;
    return creditColor;
  };

  getNextOrPreviousData = () => {
    let query = omit(this.state.queryStoreFinance, [
      "agent_share_id",
      "rowIndex",
    ]) as IQueryPagination & IV2QueryAgentFinanceChild;

    query.limit = 9999

    this.props.getFinanceChild({ ...query, limit: 999, page: 1 }).then(() => {
      this.props.onChangeIsLoaddingAction(false);

      if (this.props.financeChildCode === responseCode.OK) {
        if (this.props.financeChildData.dataList.length === 1) {

          this.setState({
            financeChildDataState: this.props.financeChildData.dataList,
          });
        } else {
          const totalFinanceChild = this.props.financeChildData.totalRecord;
          let curentFinance = findIndex(this.props.financeChildData.dataList, {
            agentShareId: this.state.queryObj.agentShareId,
          });
          curentFinance =
            this.state.actionButton === "NEXT"
              ? curentFinance + 1
              : curentFinance - 1;
          this.setState({ isFirstBill: curentFinance === 0 ? true : false });
          if (curentFinance === totalFinanceChild) {
            ConfirmModal.show({
              size: "md",
              acceptText: "ยืนยัน",
              title: constants.confirmNextBill,
              description: 'ยืนยันไปที่บิลแรก',
              action: () => {
                curentFinance = 0
                this.setState({ isFirstBill: true })
                const financeNext = this.props.financeChildData.dataList[
                  curentFinance
                ];
                this.setState({
                  financeChildDataState: [financeNext],
                  queryObj: {
                    ...this.state.queryObj,
                    agentShareId: financeNext.agentShareId,
                  },
                })
                ConfirmModal.hide()

              },
              cancelAction: () => {
                ConfirmModal.hide()
              }
            })
          } else if (this.state.actionButton === "NEXT") {
            const financeNext = this.props.financeChildData.dataList[
              curentFinance
            ];
            this.setState({
              financeChildDataState: [financeNext],
              queryObj: {
                ...this.state.queryObj,
                agentShareId: financeNext.agentShareId,
              },
            }, () => {
              //Finance Child Me
              this.props
                .getFinanceChildMe({
                  agentId: this.props.meData.agentId,
                  page: 1,
                  limit: 10,
                  childAgentShareId: financeNext.agentShareId,
                })
                .then((payRecord: number) => {
                  return Promise.all([
                    this.props.getFinanceChildMe({
                      childAgentId: financeNext.childAgentId,
                      page: 1,
                      limit: 10,
                      childAgentShareId: financeNext.agentShareId,
                    }),
                    // payRecord,
                  ]);
                })
                .then((records: number[]) => {
                  // this.setState({
                  //   totalRecord: {
                  //     ...this.state.totalRecord,
                  //     receive: records[0],
                  //     pay: records[1],
                  //   },
                  // });
                });
            });
          } else if (this.state.actionButton === "PREV") {
            const financePrev = this.props.financeChildData.dataList[
              curentFinance
            ];
            this.setState({
              financeChildDataState: [financePrev],
              queryObj: {
                ...this.state.queryObj,
                agentShareId: financePrev.agentShareId,
              },
            });
          }
        }
      }
    });
  };

  renderNextBill = () => {
    if (this.state.isFirstBill && this.state.totalRecordChild === 1) {
      return <></>
    }
    else if (!this.state.isFirstBill) {
      return (
        <>
          <Button
            id="previous-finance-child"
            text={constants.previous}
            borderRadius={"8px"}
            buttonClass={"previous-finance-child-button"}
            backgroundColor={color.PRIMARY_GREEN}
            textSize={18}
            backgroundHoverColor={color.SECONDARY_GREEN}
            transitionType="typeOne"
            onClick={() => {
              this.setState({ actionButton: "PREV" });
              this.getNextOrPreviousData();
              setTimeout(() => {
                this.receiveAndPayListRef.current?.getNextOrPreviousData()
                this.memberBetListRef.current?.getNextOrPreviousData()
              }, 250)
            }}
          />
          <Button
            id="next-finance-child"
            text={constants.next}
            borderRadius={"8px"}
            buttonClass={"next-finance-child-button"}
            backgroundColor={color.PRIMARY_GREEN}
            textSize={18}
            backgroundHoverColor={color.SECONDARY_GREEN}
            transitionType="typeOne"
            onClick={() => {
              this.setState({ actionButton: "NEXT" });
              this.getNextOrPreviousData();
              setTimeout(() => {
                this.receiveAndPayListRef.current?.getNextOrPreviousData()
                this.memberBetListRef.current?.getNextOrPreviousData()
              }, 250)
            }}
          />
        </>
      )
    }
    else{
      return (
        <Button
        id="next-finance-child"
        text={constants.next}
        borderRadius={"8px"}
        buttonClass={"next-finance-child-button"}
        backgroundColor={color.PRIMARY_GREEN}
        textSize={18}
        backgroundHoverColor={color.SECONDARY_GREEN}
        transitionType="typeOne"
        onClick={() => {
          this.setState({ actionButton: "NEXT" });
          this.getNextOrPreviousData();
          setTimeout(() => {
            this.receiveAndPayListRef.current?.getNextOrPreviousData()
            this.memberBetListRef.current?.getNextOrPreviousData()
          }, 250)
        }}
      />
      )
    }
  }

  handleOnReloadData = (agentShareIdNa : number) => {
    this.props
    .getFinanceChild({
      agentShareId: agentShareIdNa,
      page: 1,
      limit: 10,
    })
    .then(() => {
      this.setState({
        financeChildDataState:this.props.financeChildData.dataList   
      }, ()=>{
        this.props.getMe()
      });
    });
  }

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.breadcrumb.main, path: "/", active: false },
      {
        label: constants.breadcrumb.membersList,
        path: routes.financeSummaryChild.path,
        active: false,
      },
      { label: constants.breadcrumb.financeChild, active: true },
    ];
    const RenderNextBill = this.renderNextBill
    return (
      <>
        <div className="paper-container finance-child-container">
          <div className="row">
            <div className="col">
              <div className="sub-menu">
                <Breadcrumb
                  items={navigates}
                  handleOnClickItem={handleOnClickBreadcrumb}
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between align-items-start m3-t">
            <div className="d-flex flex-column">
              <div className="paper-title" style={{ marginTop: 0 }}>
                {constants.title}
              </div>
              <div className="pr-3 paper-description">
                {`${constants.subTitle(this.state.queryObj.agentShareId)}  `}
                <span
                  style={{
                    color: this.getCreditColor(
                      number.calculator(
                        `${this.state.financeChildDataState[0]?.payHost}-
                        ${this.state.financeChildDataState[0]?.recieveHost}`
                      )
                    ),
                  }}
                >
                  {number.addComma(number.calculator(
                    `${this.state.financeChildDataState[0]?.payHost}-
                    ${this.state.financeChildDataState[0]?.recieveHost}`
                  ))}
                </span>
              </div>
            </div>
            <div className="d-flex flex-row">
            <RenderNextBill/>
            </div>
          </div>
          <div className="paper-body">
            <div className="box-shadow m3-t boxShadow-Border">
              <div className="row">
                <div className="col">
                  <BillSummaryList
                    loading={this.props.financeChildIsFetching}
                    dataList={this.state.financeChildDataState}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="paper-body">
            <div className="box-shadow m3-t boxShadow-Border">
              <div className="row">
                <div className="col">
                  <ReceiveAndPayList
                    financeChildProps={this.props.financeChildProps}
                    ref={this.receiveAndPayListRef}
                    financeChildMeData={this.props.financeChildMeData}
                    getFinanceChild={this.props.getFinanceChild}
                    getFinanceChildMe={this.props.getFinanceChildMe}
                    financeChildMeCode={this.props.financeChildMeCode}
                    financeChildMeIsFetching={
                      this.props.financeChildMeIsFetching
                    }
                    meData={this.props.meData}
                    getChildMembers={this.props.getChildMembers}
                    agentChildCode={this.props.agentChildCode}
                    agentChildIsFetching={this.props.agentChildIsFetching}
                    agentChildData={this.props.agentChildData}
                    financeChildApproveCode={this.props.financeChildApproveCode}
                    financeChildApproveIsFetching={
                      this.props.financeChildApproveIsFetching
                    }
                    financeChildApproveData={this.props.financeChildApproveData}
                    postFinanceChildApprove={this.props.postFinanceChildApprove}
                    dataReseive={this.props.dataReseive}
                    postFinanceChildRequest={this.props.postFinanceChildRequest}
                    // postFinanceChildRequestCode={this.props.postFinanceChildRequestCode}
                    financeChildRequestCode={this.props.financeChildRequestCode}
                    financeChildRequestData={this.props.financeChildRequestData}
                    financeChildRequestIsFetching={this.props.financeChildRequestIsFetching}
                    queryObj={this.state.queryObj}
                    queryStoreFinance={this.state.queryStoreFinance}
                    financeChildDataState={this.state.financeChildDataState}

                    onRefreshData={this.handleOnReloadData}
                  />
                </div>
              </div>
            </div>
          </div>
          {this.props.meData.permission !== "AGENT" && (
            <div className="paper-body">
              <div className="box-shadow m3-t boxShadow-Border">
                <div className="row">
                  <div className="col">
                    <MemberBetList
                      clickRoute={(key: IOptions, data: IV2RespAgentChildBet) => {
                        this.props.history.push(routes.membersList.path + transformer.urlSearchParams({ childAgentId: data.childAgentId }))
                      }}
                      ref={this.memberBetListRef}
                      childBetData={this.props.childBetData}
                      getChildBet={this.props.getChildBet}
                      childBetIsFetching={this.props.childBetIsFetching}
                      childBetCode={this.props.childBetCode}
                      queryStoreFinance={this.state.queryStoreFinance}
                      financeChildDataState={this.state.financeChildDataState}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default FinanceChildContainer;
