import "./detailBetGroup.style.scss"
import { Modal } from "../.."
import React, { FC } from "react"
import colors from "constants/colors"
import { get, includes, isEmpty, isNil, map, noop } from "lodash"
import { CODE_TO_NAME, CODE_TO_TYPENAME_NUMBER } from "constants/variables";
// import { addYears, format } from "date-fns";
import { date, number } from "utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

const constants = {
  empty: 'ไม่มีข้อมูล...',
  close: 'ปิด'
}

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: IDetailBetGroupModal = {
  nameLotto: 'GOVN',
  dataBetDetail: [],
  cancelAction() {
    noop();
  },
  stLottoSchedule: 'OPEN',
  stBillGroup: ''
};

declare interface IHeaderDetailBetGroup {
  name: string
  textalign: string
  width?: number
}


const mappingTheadLottoฺBill: IHeaderDetailBetGroup[] = [
  { name: "ประเภท @ หมายเลข", textalign: 'text-center', width: 250 },
  { name: "ยอด", textalign: 'text-right', width: 250 },
  { name: "ส่วนลด", textalign: 'text-right', width: 200 },
  { name: "รวม", textalign: 'text-right', width: 150 },
  { name: "จ่าย", textalign: 'text-right', width: 200 },
  { name: "สถานะ", textalign: 'text-center', width: 200 },
];


type StatusName = 'CANCEL' | 'WAIT_RESULT' | 'SEND_POOI' | 'SUCCESS';

const statusName: { [status in StatusName]:
  { name: string; color: string; textColor: string } } =
{
  WAIT_RESULT: { name: 'กำลังออกรางวัล', color: colors.BLUE_GREEN, textColor: colors.PRIMARY_BG },
  CANCEL: { name: 'ยกเลิกโพย', color: colors.PRIMARY_RED, textColor: colors.PRIMARY_BG },
  SEND_POOI: { name: 'ส่งโพย', color: colors.PRIMARY_YELLOW, textColor: colors.SECONDARY_TEXT },
  SUCCESS: { name: 'ออกรางวัล', color: colors.BLUE, textColor: colors.PRIMARY_BG },
}

const DetailBetGroupModal: FC<IDetailBetGroupModal & DefaultProps> = (props) => {

  const tableHead = () => {
    const mapHeadTable = map(
      mappingTheadLottoฺBill,
      (data: IHeaderDetailBetGroup, index: number) => {
        if (index === 4) {
          return <th className={`${data.textalign}`} key={index} style={{ width: data.width }}>
            <h5 className={`text-header d-flex justify-content-between m1-l`}>
              {data.name}
              <span>รวม</span>
            </h5>
          </th>
        }
        return <th className={`${data.textalign}`} key={index} style={{ width: data.width }}>
          <h5 className={`text-header ${index === 0 && "ml-3"}`}>{data.name}</h5>
        </th>
      }
    );
    return (
      <thead className='header-table-lotto-bill' style={{ backgroundColor: colors.PRIMARY_TEXT }}>
        <tr>{mapHeadTable}</tr>
      </thead>
    )
  }

  const tableBody = () => {
    const dataEmpty = (
      <tr>
        <td className="text-center" colSpan={15}>
          {constants.empty}
        </td>
      </tr>
    );
    const mapData = map(props.dataBetDetail, (item: IV3RespGetDetailExtractionAgentBetLottery, index: number) => {
      const stBetSum = isNil(item.betResult)
        ? ''
        : Number(item.betResult) === 0
          ? item.status === 'CANCEL' ? '' : 'ไม่ถูกรางวัล'
          : 'ถูกรางวัล'
      const trColor = stBetSum === 'ถูกรางวัล' ? colors.BLUE_TRAN : ''
      const txtColor = stBetSum === 'ถูกรางวัล' ? colors.BLUE : colors.PRIMARY_RED
      return (
        <tr
          className={`row-bill-list`}
          key={`${item.betNumber} ${index}`}
          style={{ height: 36, backgroundColor: trColor }}
        >
          <td className="text-center pl-3">
            {`${CODE_TO_TYPENAME_NUMBER[item.type]} @ ${item.betNumber}`}
          </td>
          <td className="text-right">
            <span className="body-4-darkgreen"> {number.decimalsFormatComma(item.betValue)} </span>
          </td>
          <td className="text-right">
            <span className="body-4-red">{number.decimalsFormatComma(item.betDiscount)}</span>
          </td>
          <td className="text-right">
            <span className="body-4-primary-blue-green">{number.decimalsFormatComma(item.betSum)}</span>
          </td>
          <td className="text-right">
            <span className="body-4-primary-blue-green d-flex justify-content-between m1-l">
              <span className="primary-text">
                {Number(item.betRate).toFixed(0)}
              </span>
              {number.decimalsFormatComma((Number(item.betValue) * Number(item.betRate)).toFixed(2))}
            </span>
          </td>
          <td className="text-right">
            <span className="body-4-primary-blue-detail d-flex justify-content-between m1-l">
              <span style={{ color: txtColor }}>
                {item.status !== 'CANCEL' && stBetSum}
              </span>
              {(item.status !== 'CANCEL' && Number(item.betResult) > 0) && (number.decimalsFormatComma(item.betResult))}
            </span>
          </td>
        </tr>
      );
    })
    const renderBody = !isEmpty(props.dataBetDetail) ? mapData : dataEmpty
    return renderBody;
  }

  const sumBetValue = () => {
    return number.decimalsFormatComma(props.dataBetDetail
      .filter(transaction => transaction.status !== 'CANCEL')
      .reduce((total, transaction) => total + parseFloat(String(transaction.betValue)), 0)
      .toFixed(2))
  }
  const sumBetDiscount = () => {
    return number.decimalsFormatComma(props.dataBetDetail
      .filter(transaction => transaction.status !== 'CANCEL')
      .reduce((total, transaction) => total + parseFloat(String(transaction.betDiscount)), 0)
      .toFixed(2))
  }
  const totalBetSumDis = () => {
    return number.decimalsFormatComma(props.dataBetDetail
      .filter(transaction => transaction.status !== 'CANCEL')
      .reduce((total, transaction) => total + parseFloat(String(transaction.betSum)), 0)
      .toFixed(2))
  }
  const totalBetSum = () => {
   return number.decimalsFormatComma(
      props.dataBetDetail
        .filter(transaction => transaction.status !== 'CANCEL')
        .reduce((total, transaction) => total + parseFloat(String(transaction.betValue)) * parseFloat(String(transaction.betRate)), 0)
        .toFixed(2)
    )
  }
  const sumTotalResult = () => {
    const total = props.dataBetDetail
    .filter(transaction => transaction.status !== 'CANCEL')
    .reduce((accumulator, transaction) => {
      const totalResult = parseFloat(String(transaction.betResult))
      if (!isNaN(totalResult)) {
        return accumulator + totalResult
      }
      return accumulator
    }, 0)
    return number.decimalsFormatComma(String(total.toFixed(2)))
  }
  const tableTotal = () => {
    return (
      <tr
        className={'total-bill'}
        key={'Footer-table'}
        style={{ height: 36, backgroundColor: colors.SECONDARY_BG }}
      >
        <td className="text-center pl-3">
          <h6>{`รวม @ ${Number(number.decimalsFormatComma(props.dataBetDetail.length))} รายการ`}</h6>
        </td>
        <td className="text-right">
          <span className="body-4-darkgreen"> {sumBetValue()} </span>
        </td>
        <td className="text-right">
          <span className="body-4-red">{sumBetDiscount()}</span>
        </td>
        <td className="text-right">
          <span className="body-4-primary-blue-green">{totalBetSumDis()}</span>
        </td>
        <td className="text-right">
          <span className="body-4-primary-blue-green d-flex justify-content-between m1-l">
            <span className="primary-text">
              {/* {Number(item.betRate).toFixed(0)} */}
            </span>
            {totalBetSum()}
          </span>
        </td>
        <td className="text-right">
          <span className="body-4-primary-blue-detail d-flex justify-content-between m1-l">
            <span>
            </span>
            {trxName === 'ออกรางวัล' && sumTotalResult()}
          </span>
        </td>
      </tr>
    )
  }

  const stLabel = () => {
    if (props.stBillGroup === 'CANCEL') {
      return statusName['CANCEL']
    }
    else if (isNil(get(props.dataBetDetail, '[0].betResult', null)) && props.stLottoSchedule === 'OPEN') {
      return statusName['SEND_POOI']
    }
    else if (isNil(get(props.dataBetDetail, '[0].betResult', null)) && props.stLottoSchedule === 'CLOSE') {
      return statusName['WAIT_RESULT']
    }
    return statusName['SUCCESS']
  }

  const dateLotto = date.formatThai(get(props.dataBetDetail, '[0].createdAt', ''), 'dd-MM-yyyy')
  const trxName = get(stLabel(), 'name', '')
  const trxColor = get(stLabel(), 'color', '')
  const textColor = get(stLabel(), 'textColor', '')
  return (
    <div className={`detail-bet-container border-rounded`} >
      <div className="row">
        <div className="col d-flex align-items-center m1-b">
          <h3 className="title-text">
            {`${(CODE_TO_NAME[includes(props.nameLotto, "YEGEE")
              ? "YEGEE"
              : props.nameLotto]).replace("หวย", "") + (includes(props.nameLotto, "YEGEE")
                ? ` รอบที่ ${props.nameLotto.replace("YEGEE_", '')}` : "")
              } - ${dateLotto}`
            }
          </h3>

          <div className="status-keep m2-l" style={{ backgroundColor: trxColor, color: textColor }}>{trxName}</div>

        </div>
        <div className="col-1 d-flex justify-content-end">
        <div style={{ fontWeight: 400 }}>
            {
                <FontAwesomeIcon
                  onClick={props.cancelAction}
                  id={"cancel"}
                  className={`num-icon`}
                  icon={faTimes}
                />
            }
          </div>
        </div>
       

      </div>
      <div className="table-container">
        <div className="table-responsive-xl">
          <table className="w-100">
            {tableHead()}
            <tbody>{tableBody()}</tbody>
            <tfoot>{!isEmpty(props.dataBetDetail) && (tableTotal())}</tfoot>
          </table>
        </div>
      </div>
      <div className="row justify-content-end">
        <div className="col-2 m2-t d-flex justify-content-end">
          <div className="status-close" onClick={props.cancelAction} style={{ backgroundColor: colors.BLUE }}>{constants.close}</div>
        </div>
      </div>
    </div>
  );
};

DetailBetGroupModal.defaultProps = defaultProps

const detailBetGroupModalModal = {
  show: (props: IDetailBetGroupModal) => {
    return Modal.event("MODAL_OVER", {
      state: "show",
      extraProps: { size: isEmpty(props.size) ? "xl" : props.size },
      component: <DetailBetGroupModal {...props} />,
    });
  },
  hide: () => Modal.event("MODAL_OVER", { state: "hide" }),
};

export default detailBetGroupModalModal
