import {
  Breadcrumb,
  Button,
  ButtonRadio,
  CheckBox,
  DatePickerNew,
  Dropdown,
  ErrorModal,
  LoadingCircle,
} from "components";
import { CODE_TO_NAME, meDataInitialValue } from "constants/variables";
import { Component } from "react";
import { RouteComponentProps } from "react-router";
import "./betSummaryResult.style.scss";
import SumTotalBetLotto from "./components/SumTotalBetLotto";
import SumTotalBetMemberList from "./components/SumTotalBetMemberList";
import {
  fetchGetLottoBetLotto,
  fetchGetLottoBetMember,
} from "reduxs/lotto/bet/services";
import BreadcrumbMember from "./components/BreadcrumbMember/BreadcrumbMember.component";
import { IBreadcrumbMemberItem } from "./components/BreadcrumbMember/BreadcrumbMember.component";
import BetLotteryGroupRow from "./components/BetLotteryGroup/BetLotteryGroupRow";
import {
  find,
  get,
  includes,
  isEmpty,
  isEqual,
  isNull,
  keys,
  last,
  map,
  noop,
  remove,
} from "lodash";
import { endOfDay, endOfWeek, format, startOfDay, startOfWeek } from "date-fns";
import BetSumTotal from "./components/BetLotteryGroup/BetSumTotal";
import { responseCode, responseMessage } from "constants/response";
import { DetailBetGroup } from "components/Modal";
import { thai_th } from "constants/thai_th";
import colors from "constants/colors";

const listOptionSearchRow1: { [type in string]: string } = {
  TODAY: "วันนี้",
  THIS_WEEK: "สัปดาห์นี้",
  MONTH: "เดือน",
};

const listOptionSearchRow2: { [type in string]: string } = {
  YESTERDAY: "เมื่อวาน",
  LAST_WEEK: "สัปดาห์ที่แล้ว",
  SINCE: "ตั้งแต่",
};

type DefaultProps = Readonly<typeof defaultProps>;
const constants = {
  mainPageTitle: "ภาพรวม",
  betTitle: "แพ้-ชนะ สุทธิ",
  subTitle: "รายละเอียดยอดผลหวยทั้งหมด",
  dataNotFound: "ไม่พบข้อมูล...",
  criteria: "ตัวเลือกการค้นหา",
  lottoCriteria: "ตัวเลือกหวย",
  selectAll: "เลือกทั้งหมด",
  clearAll: "ล้างทั้งหมด",
  search: "ค้นหาแพ้-ชนะ สุทธิ",
};

const defaultProps: IBetSummaryResultContainerProps &
  IBetSummaryResultContainerAction = {
  userMe: meDataInitialValue,
  onChangeIsLoaddingAction() {
    noop();
  },
  getBetExtraction() {
    noop();
  },
  getBetExtractionDetail() {
    noop();
  },
  getBetExtractionData: [],
  getBetExtractionIsFetching: false,
  getBetExtractionCode: 0,
  getBetExtractionError: "",

  getBetExtractionDetailData: [],
  getBetExtractionDetailIsFetching: false,
  getBetExtractionDetailCode: 0,
  getBetExtractionDetailError: "",

  lottoScheduleV2Data: [],
  lottoScheduleV2IsFetching: false,
  lottoScheduleV2Code: 0,
  lottoScheduleV2Error: "",
};
export default class BetSummaryResultContainer extends Component<
  IBetSummaryResultContainerProps &
    IBetSummaryResultContainerAction &
    DefaultProps &
    RouteComponentProps<{}, {}>,
  IBetSummaryResultContainerState
> {
  static defaultProps = defaultProps;

  state: IBetSummaryResultContainerState = {
    responseLottoBetLotto: { lotteryList: [] },
    responseLottoBetMember: { memberList: [] },
    isLoadingBetList: false,
    isLoadingBetMember: false,
    isLoadingTableGroup: false,
    manageChildList: [
      {
        userName: this.props.userMe.username,
        lotteryName: "",
        childAgentId: 0,
      },
    ],
    nameBetDetail: "GOVN",
    stBillGroup: "",
    querySearch: {
      startCreatedAt: format(
        startOfDay(new Date()),
        "yyyy'-'MM'-'dd'T'00:00:00"
      ),
      endCreatedAt: format(endOfDay(new Date()), "yyyy'-'MM'-'dd'T'23:59:59"),
    },
    isShowTableGroup: false,
    selectedOptionSearch: "TODAY",
    searchMonths: -1,
    dateStart: null,
    dateEnd: null,
    selectedLottoName: [],
  };

  componentDidUpdate(prevProps: IBetSummaryResultContainerProps) {
    if (
      prevProps.getBetExtractionIsFetching !==
        this.props.getBetExtractionIsFetching &&
      !this.props.getBetExtractionIsFetching
    ) {
      if (this.props.getBetExtractionCode === responseCode.OK) {
        this.setState(
          { isLoadingTableGroup: true, isShowTableGroup: true },
          () => {
            setTimeout(() => {
              this.setState({ isLoadingTableGroup: false });
            }, 1000);
          }
        );
      }
    }
    if (
      prevProps.getBetExtractionDetailIsFetching !==
        this.props.getBetExtractionDetailIsFetching &&
      !this.props.getBetExtractionDetailIsFetching
    ) {
      this.props.onChangeIsLoaddingAction(false);
      if (this.props.getBetExtractionDetailCode === responseCode.OK) {
        const lottoSchedule = find(this.props.lottoScheduleV2Data, {
          name: this.state.nameBetDetail,
        });
        DetailBetGroup.show({
          cancelAction: () => {
            DetailBetGroup.hide();
          },
          nameLotto: this.state.nameBetDetail,
          dataBetDetail: this.props.getBetExtractionDetailData,
          stLottoSchedule: get(lottoSchedule, "status", "OPEN"),
          stBillGroup: this.state.stBillGroup,
        });
      } else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            this.props.getBetExtractionDetailCode,
            responseMessage()[0]
          ),
        });
      }
    }
  }

  getLottoBetMember = (query?: IV3QueryGetLotteryBetMember) => {
    this.setState({ isLoadingBetMember: true });
    fetchGetLottoBetMember(query)
      .then((response) => {
        this.setState({ responseLottoBetMember: response.data.data });
      })
      .catch((error) => {
        this.setState({ responseLottoBetMember: { memberList: [] } });
      })
      .finally(() => {
        setTimeout(() => {
          this.setState({ isLoadingBetMember: false });
        }, 1000);
      });
  };

  getLottoBetLotto = (query?: IV3QueryGetLotteryBetAgent) => {
    this.setState({ isLoadingBetList: true });
    fetchGetLottoBetLotto(query)
      .then((response) => {
        this.setState({ responseLottoBetLotto: response.data.data });
      })
      .catch((error) => {
        this.setState({ responseLottoBetLotto: { lotteryList: [] } });
      })
      .finally(() => {
        setTimeout(() => {
          this.setState({ isLoadingBetList: false });
        }, 1000);
      });
  };

  handleOnClickLottoChild = (
    childAgentId: number,
    lotteryName: string,
    memberUsername: string,
    memberPermission: string
  ) => {
    if (memberPermission !== "AGENT") {
      this.setState(
        (prevState) => {
          const memberList = {
            userName: memberUsername,
            lotteryName,
            childAgentId,
          };
          const newManageChildList: any = [
            ...prevState.manageChildList,
            memberList,
          ];
          return { manageChildList: newManageChildList };
        },
        () => {
          const query = {
            ...this.state.querySearch,
            betTransactionStatus: ["WINNER", "LOSER"],
            childAgentId,
            lotteryName,
          };
          this.getLottoBetLotto(query);
          this.getLottoBetMember(query);
        }
      );
    } else {
      const queryData = {
        ...this.state.querySearch,
        childAgentId: childAgentId,
        lotteryName: lotteryName,
        betTransactionStatus: ["WINNER", "LOSER"],
      };
      this.setState(
        (prevState) => {
          const memberList = {
            userName: memberUsername,
            lotteryName,
            childAgentId,
          };
          const newManageChildList = [...prevState.manageChildList, memberList];
          return {
            manageChildList: newManageChildList,
            querySearch: queryData,
            isLoadingTableGroup: true,
          };
        },
        () => {
          this.props.getBetExtraction(queryData);
        }
      );
    }
  };

  handleOnClickSumTotalChild = (
    childAgentId: number,
    memberUsername: string,
    memberPermission: string
  ) => {
    if (memberPermission !== "AGENT") {
      this.setState(
        (prevState) => {
          const memberList = {
            userName: memberUsername,
            lotteryName: this.state.selectedLottoName,
            childAgentId,
          };
          const newManageChildList = [...prevState.manageChildList, memberList];
          return { manageChildList: newManageChildList };
        },
        () => {
          const query: IV3QueryGetLotteryBetAgent = {
            ...this.state.querySearch,
            lotteryName: this.state.selectedLottoName,
            betTransactionStatus: ["WINNER", "LOSER"],
          };
          this.getLottoBetLotto({ ...query, childAgentId });
          this.getLottoBetMember({ ...query, childAgentId });
        }
      );
    } else {
      const lastestChild = last(this.state.manageChildList)
      const queryData = {
        ...this.state.querySearch,
        lotteryName: lastestChild?.lotteryName || '',
        childAgentId: childAgentId,
        betTransactionStatus: ["WINNER", "LOSER"],
      };

      this.setState(
        (prevState) => {
          const memberList = {
            userName: memberUsername,
            lotteryName: lastestChild?.lotteryName || '',
            childAgentId,
          };
          const newManageChildList = [...prevState.manageChildList, memberList];
          return {
            manageChildList: newManageChildList,
            querySearch: queryData,
            isLoadingTableGroup: true,
          };
        },
        () => {
          this.props.getBetExtraction(queryData);
        }
      );
    }
  };

  handleOnSelectAllLotto = () => {
    this.setState({ selectedLottoName: keys(CODE_TO_NAME) });
  };

  handleOnDeSelectAllLotto = () => {
    this.setState({ selectedLottoName: [] });
  };

  handleOnSelectLottoName = (name: string) => {
    if (includes(this.state.selectedLottoName, name)) {
      const removedExisting = remove(
        this.state.selectedLottoName,
        (lottoname) => !isEqual(lottoname, name)
      );
      this.setState({
        selectedLottoName: removedExisting,
      });
    } else {
      this.setState((state) => ({
        selectedLottoName: [...state.selectedLottoName, name],
      }));
    }
  };

  transformDataAndSetActiveLabel(
    data: SummaryChildListDetail[]
  ): IBreadcrumbMemberItem[] {
    const transformedData = data.map((item, index) => {
      const key = item.userName;
      const isActive = index !== data.length - 1;
      return { label: key, active: isActive, data: item, index };
    });
    return transformedData;
  }

  handleOnClickBreadcrumbBet = (
    query: SummaryChildListDetail,
    index: number
  ) => {
    const { childAgentId, lotteryName } = query;
    if (query.childAgentId === 0) {
      const localQuery = {
        ...this.state.querySearch,
        childAgentId: 0,
        betTransactionStatus: ["WINNER", "LOSER"],
        lotteryName: this.state.selectedLottoName,
      };
      this.getLottoBetLotto(localQuery);
      this.getLottoBetMember(localQuery);
      this.setState((prevstate) => ({
        querySearch: { ...prevstate.querySearch, childAgentId },
        manageChildList: this.state.manageChildList.slice(0, 1),
        isShowTableGroup: false,
      }));
    } else {
      const localQuery = {
        ...this.state.querySearch,
        betTransactionStatus: ["WINNER", "LOSER"],
        childAgentId,
        ...(lotteryName ? { lotteryName } : {}),
      };
      this.getLottoBetLotto(localQuery);
      this.getLottoBetMember(localQuery);
      this.setState({
        manageChildList: this.state.manageChildList.slice(0, index + 1),
        isShowTableGroup: false,
      });
    }
  };

  getNameHeaderTable = () => {
    return this.state.manageChildList.length > 1
      ? this.state.manageChildList[this.state.manageChildList.length - 1]
          .userName
      : this.props.userMe.username;
  };

  detailBetGroup = (query: IV3QueryGetDetailExtractionAgentBetLottery) => {
    const childAgentId = this.state.manageChildList[
      this.state.manageChildList.length - 1
    ].childAgentId;
    this.setState({
      nameBetDetail: query.name as TLottoType,
      stBillGroup: query.betTransactionStatus!,
    });
    this.props.getBetExtractionDetail({ ...query, childAgentId, betTransactionStatus: ["WINNER", "LOSER"] });
    this.props.onChangeIsLoaddingAction(true);
  };

  handleClickButtonRadio = (val: string) => {
    if (this.state.selectedOptionSearch === val) {
      this.setState({
        selectedOptionSearch: "",
        searchMonths: -1,
        dateStart: null,
        dateEnd: null,
        querySearch: {
          ...this.state.querySearch,
          startCreatedAt: "",
          endCreatedAt: "",
        },
      });
    }
  };

  handleOnChangeButtonRadio = (val: ISelectedOptionSearch) => {
    let startDate: Date = new Date();
    let endDate: Date = new Date();
    this.setState({ dateStart: null, dateEnd: null, searchMonths: -1 });
    if (val === "MONTH" || val === "SINCE") {
      this.setState({ selectedOptionSearch: val });
    } else {
      if (val === "TODAY") {
        startDate = new Date();
        endDate = new Date();
      } else if (val === "YESTERDAY") {
        startDate = new Date(new Date().setDate(new Date().getDate() - 1));
        endDate = new Date(new Date().setDate(new Date().getDate() - 1));
      } else if (val === "THIS_WEEK") {
        startDate = new Date(startOfWeek(new Date(), { weekStartsOn: 0 }));
        endDate = new Date(endOfWeek(new Date(), { weekStartsOn: 0 }));
      } else if (val === "LAST_WEEK") {
        startDate = new Date(
          startOfWeek(new Date(new Date().setDate(new Date().getDate() - 7)), {
            weekStartsOn: 0,
          })
        );
        endDate = new Date(
          endOfWeek(new Date(new Date().setDate(new Date().getDate() - 7)), {
            weekStartsOn: 0,
          })
        );
      }
      this.setState({
        selectedOptionSearch: val,
        querySearch: {
          ...this.state.querySearch,
          startCreatedAt: format(startDate, "yyyy'-'MM'-'dd'T'00:00:00"),
          endCreatedAt: format(endDate, "yyyy'-'MM'-'dd'T'23:59:59"),
        },
      });
    }
  };

  handleOnSearchSummaryResult = () => {
    const query = {
      ...this.state.querySearch,
      betTransactionStatus: ["WINNER", "LOSER"],
      lotteryName: this.state.selectedLottoName,
    };
    this.getLottoBetLotto(query);
    this.getLottoBetMember(query);
  };

  renderTableMembers = () => {
    const dataEmpty = (
      <table>
        <tbody>
          <tr>
            <td className="text-center" colSpan={15}>
              {"ไม่พบข้อมูล..."}
            </td>
          </tr>
        </tbody>
      </table>
    );
    const loadingTable = (
      <table>
        <tbody>
          <tr>
            <td className="text-center" colSpan={15}>
              <div className="m1-t d-flex justify-content-center">
                <LoadingCircle />{" "}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );

    const mapData = map(
      this.props.getBetExtractionData,
      (item: IV3ExtractionGroupingLottery, index: number) => {
        return (
          <div key={index}>
            <h5 className="primary-text m1-b m1-t" key={index}>
              {CODE_TO_NAME[
                includes(item.name, "YEGEE") ? "YEGEE" : item.name
              ].replace("หวย", "") +
                (includes(item.name, "YEGEE")
                  ? ` รอบที่ ${item.name.replace("YEGEE_", "")}`
                  : "")}
            </h5>
            <BetLotteryGroupRow
              loading={this.props.getBetExtractionIsFetching}
              lottoSchedule={this.props.lottoScheduleV2Data}
              trxData={item.trx}
              onClick={this.detailBetGroup}
            />
          </div>
        );
      }
    );
    const renderTable = this.props.getBetExtractionIsFetching
      ? loadingTable
      : !isEmpty(mapData)
      ? mapData
      : dataEmpty;
    return <div className="m1-b">{renderTable}</div>;
  };

  renderSumTotal = (position: number) => {
    return (
      <div className="m1-t">
        <BetSumTotal
          position={position}
          lottoSchedule={this.props.lottoScheduleV2Data}
          trxData={this.props.getBetExtractionData}
        />
      </div>
    );
  };

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.mainPageTitle, active: false, path: "/dashboard" },
      { label: constants.betTitle, active: true },
    ];

    const TableMembersRow = this.renderTableMembers;

    const navigatesMemberList: IBreadcrumbMemberItem[] = this.transformDataAndSetActiveLabel(
      this.state.manageChildList
    );
    return (
      <div className="paper-container bet-waiting-result-container">
        <div className="row">
          <div className="col-12">
            <div className="sub-menu">
              <Breadcrumb
                items={navigates}
                handleOnClickItem={(path: string) =>
                  this.props.history.replace(path)
                }
              />
            </div>
          </div>
        </div>
        <div className="paper-title">{constants.betTitle}</div>
        <div className="paper-description">{constants.subTitle}</div>
        <div className="paper-body">
          {(this.state.manageChildList || []).length <= 1 && (
            <div className="box-shadow mb-3">
              <div className="row">
                <div className="col-12">
                  <h6 className="text-bold">{constants.criteria}</h6>
                </div>
              </div>
              <div className="row mt-2 d-flex align-items-start box-search">
                <div className="col">
                  <div className="row">
                    <div className="box-buttonRadio ml-3">
                      <ButtonRadio
                        value={this.state.selectedOptionSearch}
                        datas={Object.keys(listOptionSearchRow1).map(
                          (item, index) => {
                            return {
                              id: item,
                              value: item,
                              text:
                                listOptionSearchRow1[
                                  item as ISelectedOptionSearch
                                ],
                            };
                          }
                        )}
                        className="row justify-content-between"
                        classNameRadio="buttonRadio-item px-0"
                        name="listOptionSearch"
                        onClick={this.handleClickButtonRadio}
                        onChange={this.handleOnChangeButtonRadio}
                      />
                    </div>
                    <div className="col-6 box-search-item">
                      <div
                        className={`dropdown-box ${
                          this.state.selectedOptionSearch !== "MONTH" &&
                          "disable"
                        }`}
                      >
                        <Dropdown
                          value={
                            this.state.searchMonths === -1
                              ? undefined
                              : this.state.searchMonths
                          }
                          options={thai_th.months.map((item, index) => {
                            return {
                              value: index,
                              label: item[0],
                              disabled: false,
                            };
                          })}
                          controlBackgroundColor={
                            this.state.selectedOptionSearch !== "MONTH"
                              ? colors.SECONDARY_BG
                              : colors.PRIMARY_BG
                          }
                          placeholder="ค้นหาเดือน"
                          disable={this.state.selectedOptionSearch !== "MONTH"}
                          menuStyles={{ width: "100%" }}
                          handleOnChange={(target: IOptions) => {
                            let startDate = new Date(
                              new Date().getFullYear() -
                                (new Date().getMonth() < Number(target.value)
                                  ? 1
                                  : 0),
                              Number(target.value),
                              1
                            );
                            let endDate = new Date(
                              new Date().getFullYear() -
                                (new Date().getMonth() < Number(target.value)
                                  ? 1
                                  : 0),
                              Number(target.value) + 1,
                              0
                            );
                            this.setState({
                              searchMonths: Number(target.value),
                              querySearch: {
                                ...this.state.querySearch,
                                startCreatedAt: format(
                                  startDate,
                                  "yyyy'-'MM'-'dd'T'00:00:00"
                                ),
                                endCreatedAt: format(
                                  endDate,
                                  "yyyy'-'MM'-'dd'T'23:59:59"
                                ),
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 d-flex align-items-center box-search">
                    <div className="box-buttonRadio ml-3">
                      <ButtonRadio
                        value={this.state.selectedOptionSearch}
                        datas={Object.keys(listOptionSearchRow2).map(
                          (item, index) => {
                            return {
                              id: item.toString(),
                              value: item,
                              text:
                                listOptionSearchRow2[
                                  item as ISelectedOptionSearch
                                ],
                            };
                          }
                        )}
                        className="row justify-content-between"
                        classNameRadio="buttonRadio-item px-0"
                        name="listOptionSearch"
                        onClick={this.handleClickButtonRadio}
                        onChange={this.handleOnChangeButtonRadio}
                      />
                    </div>
                    <div className="col-3 box-search-item">
                      <DatePickerNew
                        isShowIcon
                        value={this.state.dateStart}
                        placeholder="วันเริ่มต้น"
                        format="DD MMM YYYY"
                        editable={false}
                        // render={<img src={calendar}  alt="calendar-icon"/>}
                        onChange={(value: any) => {
                          if (value !== null) {
                            let startDate = new Date(Number(value));
                            this.setState({
                              dateStart: value,
                              dateEnd: isNull(this.state.dateEnd)
                                ? (new Date() as any)
                                : new Date(this.state.dateEnd),
                              querySearch: {
                                ...this.state.querySearch,
                                startCreatedAt: format(
                                  startDate,
                                  "yyyy'-'MM'-'dd'T'00:00:00"
                                ),
                                endCreatedAt: format(
                                  isNull(this.state.dateEnd)
                                    ? new Date()
                                    : new Date(this.state.dateEnd),
                                  "yyyy'-'MM'-'dd'T'23:59:59"
                                ),
                              },
                            });
                          }
                        }}
                        // plugins={[<TimePicker hideSeconds />]}
                        // errorMessage={t(get(formik, "errors.publishedDate", "global.notError")!)}
                        disabled={this.state.selectedOptionSearch !== "SINCE"}
                      />
                    </div>
                    <div className="col-3 box-search-item">
                      <DatePickerNew
                        isShowIcon
                        value={this.state.dateEnd}
                        placeholder="วันสิ้นสุด"
                        format="DD MMM YYYY"
                        editable={false}
                        onChange={(value: any) => {
                          if (value !== null) {
                            let startDate = new Date(Number(value));
                            this.setState({
                              dateEnd: value,
                              querySearch: {
                                ...this.state.querySearch,
                                startCreatedAt: format(
                                  isNull(this.state.dateStart)
                                    ? new Date()
                                    : new Date(this.state.dateStart),
                                  "yyyy'-'MM'-'dd'T'00:00:00"
                                ),
                                endCreatedAt: format(
                                  startDate,
                                  "yyyy'-'MM'-'dd'T'23:59:59"
                                ),
                              },
                            });
                          }
                        }}
                        // plugins={[<TimePicker hideSeconds />]}
                        // errorMessage={t(get(formik, "errors.publishedDate", "global.notError")!)}
                        disabled={
                          this.state.selectedOptionSearch !== "SINCE" ||
                          isNull(this.state.dateStart)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-5" />
              </div>
              <div className="row mt-4 d-flex align-items-start box-search">
                <div className="col">
                  <h6 className="text-bold">{constants.lottoCriteria}</h6>
                </div>
              </div>
              <div className="row mt-3 d-flex align-items-start box-search">
                <div className="col box-checkbox-item align-items-start justify-content-start">
                  <Button
                    buttonClass="selectbutton"
                    id={`select-all-lotto`}
                    text={constants.selectAll}
                    backgroundColor={colors.PRIMARY_GREEN}
                    backgroundHoverColor={colors.SECONDARY_GREEN}
                    onClick={this.handleOnSelectAllLotto}
                  />
                  <Button
                    buttonClass="selectbutton"
                    id={`clear-all-lotto`}
                    text={constants.clearAll}
                    backgroundColor={colors.PRIMARY_RED}
                    backgroundHoverColor={colors.PRIMARY_RED}
                    onClick={this.handleOnDeSelectAllLotto}
                  />
                </div>
              </div>
              <div className="row mt-3 d-flex align-items-start box-search">
                <div className="col box-checkbox-item">
                  {map(["GOVN"], (lotto, index) => (
                    <div
                      key={`${lotto}-${index}`}
                      className="d-flex justify-content-start align-items-center mr-3"
                      style={{ minWidth: "166px" }}
                    >
                      <CheckBox
                        id={`${lotto}-${index}`}
                        value={includes(this.state.selectedLottoName, lotto)}
                        onClick={() => this.handleOnSelectLottoName(lotto)}
                      />
                      <span className="pl-1">
                        {get(CODE_TO_NAME, lotto, "")}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="row mt-2 d-flex align-items-start box-search">
                <div className="col box-checkbox-item">
                  {map(
                    keys(CODE_TO_NAME),
                    (lotto, index) =>
                      !includes(["GOVN"], lotto) && (
                        <div
                          key={`${lotto}-${index}`}
                          className="d-flex justify-content-start align-items-center mr-3"
                          style={{ minWidth: "175px" }}
                        >
                          <CheckBox
                            id={`${lotto}-${index}`}
                            value={includes(
                              this.state.selectedLottoName,
                              lotto
                            )}
                            onClick={() => this.handleOnSelectLottoName(lotto)}
                          />
                          <span className="pl-1">
                            {get(CODE_TO_NAME, lotto, "")}
                          </span>
                        </div>
                      )
                  )}
                </div>
              </div>
              <div className="row mt-4 d-flex align-items-start box-search">
                <div className="col box-checkbox-item align-items-start justify-content-start">
                  <Button
                    buttonClass="selectbutton"
                    id={`search-all-lotto`}
                    text={constants.search}
                    size="large"
                    backgroundColor={colors.PRIMARY_PURPLE}
                    backgroundHoverColor={colors.SECONDARY_PURPLE}
                    onClick={this.handleOnSearchSummaryResult}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="box-shadow">
            <div className="row">
              <div className="col-12">
                <div className="box-user">
                  <BreadcrumbMember
                    items={navigatesMemberList}
                    handleOnClickItem={(
                      query: SummaryChildListDetail,
                      index: number
                    ) => this.handleOnClickBreadcrumbBet(query, index)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {!this.state.isShowTableGroup ? (
                  <div className="summary-table-container mt-3">
                    <SumTotalBetLotto
                      localQuery={this.state.querySearch}
                      userName={this.getNameHeaderTable()}
                      childNestedList={this.state.manageChildList}
                      lotteryList={this.state.responseLottoBetLotto.lotteryList}
                      handleOnClickChild={this.handleOnClickLottoChild}
                      loading={this.state.isLoadingBetList}
                    />
                    <SumTotalBetMemberList
                      userName={this.getNameHeaderTable()}
                      lotteryฺBetMemberList={
                        this.state.responseLottoBetMember.memberList
                      }
                      handleOnClickChild={this.handleOnClickSumTotalChild}
                      loading={this.state.isLoadingBetMember}
                    />
                  </div>
                ) : this.state.isLoadingTableGroup ? (
                  <div className="m2-l m1-t">
                    {" "}
                    <LoadingCircle />{" "}
                  </div>
                ) : (
                  <div className="lotto-bill">
                    {this.renderSumTotal(0)}
                    <TableMembersRow />
                    {this.renderSumTotal(
                      this.props.getBetExtractionData.length - 1
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
