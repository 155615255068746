import { createAsyncAction } from 'typesafe-actions'
import { AxiosError, AxiosResponse } from 'axios'
import {
    AUTOCOMPLETE_REQUEST,
    AUTOCOMPLETE_SUCCESS,
    AUTOCOMPLETE_FAILURE
} from './constants'


const autocompleteAction = createAsyncAction(
    AUTOCOMPLETE_REQUEST,
    AUTOCOMPLETE_SUCCESS,
    AUTOCOMPLETE_FAILURE
)<any, AxiosResponse<IAPIResponse<IPagination<IAutocomplete>>>, AxiosError<IAPIResponse>>()

export {
    autocompleteAction
}