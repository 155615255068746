import { AxiosError, AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { ActionType, createReducer } from "typesafe-actions";
import { responseConverter } from "../../../utils";
import * as betAction from "./actionCreator";
import {
  historyState,
  makeState,
  resultState,
  historySummaryDetailState,
  historySummaryState,
  betGroupState,
  betState,
  getBetState,
  getV2ResultState,
  betExtractionState,
  betExtractionDetailState
} from "./constants";
import rate from "./rate/reducers";

export type BetActionType = ActionType<typeof betAction>;

const make = createReducer<ReducerState<any>, BetActionType>(makeState)
  .handleAction(
    betAction.postMakeAction.request,
    (state: ReducerState<any>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    betAction.postMakeAction.success,
    (state: ReducerState<any>, action: BetActionType) => {
      const payload: AxiosResponse<IAPIResponse<any>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
      };
    }
  )
  .handleAction(
    betAction.postMakeAction.failure,
    (state: ReducerState<any>, action: BetActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );

const historySummaryDetail = createReducer<
  ReducerState<IResponseAgentQueryLotteryHistorySummaryDetail>,
  BetActionType
>(historySummaryDetailState)
  .handleAction(
    betAction.getHistorySummaryDetailAction.request,
    (state: ReducerState<IResponseAgentQueryLotteryHistorySummaryDetail>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    betAction.getHistorySummaryDetailAction.success,
    (
      state: ReducerState<IResponseAgentQueryLotteryHistorySummaryDetail>,
      action: BetActionType
    ) => {
      const payload: AxiosResponse<
        IAPIResponse<IResponseAgentQueryLotteryHistorySummaryDetail>
      > = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
      };
    }
  )
  .handleAction(
    betAction.getHistoryAction.failure,
    (
      state: ReducerState<IResponseAgentQueryLotteryHistorySummaryDetail>,
      action: BetActionType
    ) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )
  .handleAction(
    betAction.getHistorySummaryDetailAction.cancel,
    () => historySummaryDetailState
  );

const historySummary = createReducer<
  ReducerState<IPagination<IResponseAgentQueryLotteryHistorySummary>>,
  BetActionType
>(historySummaryState)
  .handleAction(
    betAction.getHistorySummaryAction.request,
    (state: ReducerState<IPagination<IResponseAgentQueryLotteryHistorySummary>>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    betAction.getHistorySummaryAction.success,
    (
      state: ReducerState<IPagination<IResponseAgentQueryLotteryHistorySummary>>,
      action: BetActionType
    ) => {
      const payload: AxiosResponse<
        IAPIResponse<IPagination<IResponseAgentQueryLotteryHistorySummary>>
      > = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
      };
    }
  )
  .handleAction(
    betAction.getHistorySummaryAction.failure,
    (
      state: ReducerState<IPagination<IResponseAgentQueryLotteryHistorySummary>>,
      action: BetActionType
    ) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )
  .handleAction(
    betAction.getHistorySummaryAction.cancel,
    () => historySummaryState
  )

const history = createReducer<
  ReducerState<IPagination<IResponseAgentBetLotteryHistoryList>>,
  BetActionType
>(historyState)
  .handleAction(
    betAction.getHistoryAction.request,
    (state: ReducerState<IPagination<IResponseAgentBetLotteryHistoryList>>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    betAction.getHistoryAction.success,
    (
      state: ReducerState<IPagination<IResponseAgentBetLotteryHistoryList>>,
      action: BetActionType
    ) => {
      const payload: AxiosResponse<
        IAPIResponse<IPagination<IResponseAgentBetLotteryHistoryList>>
      > = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
      };
    }
  )
  .handleAction(
    betAction.getHistoryAction.failure,
    (
      state: ReducerState<IPagination<IResponseAgentBetLotteryHistoryList>>,
      action: BetActionType
    ) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );

const betGroup = createReducer<ReducerState<IPagination<IV2RespAgentLotteryBetGroup>>, BetActionType>(betGroupState)
  .handleAction(
    betAction.getBetGroupAction.request,
    (state: ReducerState<IPagination<IV2RespAgentLotteryBetGroup>>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    betAction.getBetGroupAction.success,
    (
      state: ReducerState<IPagination<IV2RespAgentLotteryBetGroup>>,
      action: BetActionType
    ) => {
      const payload: AxiosResponse<
        IAPIResponse<IPagination<IV2RespAgentLotteryBetGroup>>
      > = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
        error: '',
      };
    }
  )
  .handleAction(
    betAction.getBetGroupAction.failure,
    (
      state: ReducerState<IPagination<IV2RespAgentLotteryBetGroup>>,
      action: BetActionType
    ) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )

const getV2AgentBet = createReducer<ReducerState<IPagination<IV2RespAgentLotteryBetMe>>, BetActionType>(getBetState)
  .handleAction(
    betAction.getV2AgentBetAction.request,
    (state: ReducerState<IPagination<IV2RespAgentLotteryBetMe>>) => {
      return { ...state, isFetching: true };
    }
  )
  .handleAction(
    betAction.getV2AgentBetAction.success,
    (
      state: ReducerState<IPagination<IV2RespAgentLotteryBetMe>>,
      action: BetActionType
    ) => {
      const payload: AxiosResponse<IAPIResponse<IPagination<IV2RespAgentLotteryBetMe>>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
      };
    }
  )
  .handleAction(
    betAction.getV2AgentBetAction.failure,
    (
      state: ReducerState<IPagination<IV2RespAgentLotteryBetMe>>,
      action: BetActionType
    ) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )
  .handleAction(betAction.getV2AgentBetAction.cancel, () => getBetState)


const postV2AgentBet = createReducer<ReducerState<{}>, BetActionType>(betState)
  .handleAction(
    betAction.postV2AgentBetAction.request,
    (state: ReducerState<{}>) => { return { ...state, isFetching: true, }; }
  )
  .handleAction(
    betAction.postV2AgentBetAction.success,
    (state: ReducerState<{}>, action: BetActionType) => {
      const payload: AxiosResponse<IAPIResponse<{}>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
        error: ''
      };
    }
  )
  .handleAction(
    betAction.postV2AgentBetAction.failure,
    (state: ReducerState<{}>, action: BetActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );


const result = createReducer<
  ReducerState<IResponseLudensBetResult[]>,
  BetActionType
>(resultState)
  .handleAction(
    betAction.getResultAction.request,
    (state: ReducerState<IResponseLudensBetResult[]>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    betAction.getResultAction.success,
    (
      state: ReducerState<IResponseLudensBetResult[]>,
      action: BetActionType
    ) => {
      const payload: AxiosResponse<IAPIResponse<IResponseLudensBetResult[]>> =
        action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
      };
    }
  )
  .handleAction(
    betAction.getResultAction.failure,
    (
      state: ReducerState<IResponseLudensBetResult[]>,
      action: BetActionType
    ) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )
  .handleAction(
    betAction.clearBetResultAction,
    (state: ReducerState<IResponseLudensBetResult[]>) => {
      return {
        ...resultState,
      };
    }
  );

const resultV2 = createReducer<ReducerState<IPagination<IV2RespAgentLotteryResult>>, BetActionType>(getV2ResultState)
  .handleAction(
    betAction.getV2ResultAction.request,
    (state: ReducerState<IPagination<IV2RespAgentLotteryResult>>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    betAction.getV2ResultAction.success,
    (
      state: ReducerState<IPagination<IV2RespAgentLotteryResult>>,
      action: BetActionType
    ) => {
      const payload: AxiosResponse<IAPIResponse<IPagination<IV2RespAgentLotteryResult>>> =
        action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
      };
    }
  )
  .handleAction(
    betAction.getV2ResultAction.failure,
    (
      state: ReducerState<IPagination<IV2RespAgentLotteryResult>>,
      action: BetActionType
    ) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )
// .handleAction(
//   betAction.clearBetResultAction,
//   (state: ReducerState<IResponseLudensBetResult[]>) => {
//     return {
//       ...resultState,
//     };
//   }
// );


const getV3BetExtraction = createReducer<
  ReducerState<IV3ExtractionGroupingLottery[]>, BetActionType>(betExtractionState)
  .handleAction(
    betAction.getBetExtractionAction.request,
    (state: ReducerState<IV3ExtractionGroupingLottery[]>) => {
      return {
        ...state,
        data: [],
        isFetching: true,
      };
    }
  )
  .handleAction(
    betAction.getBetExtractionAction.success,
    (state: ReducerState<IV3ExtractionGroupingLottery[]>, action: BetActionType) => {
      const payload: AxiosResponse<IAPIResponse<IV3ExtractionGroupingLottery[]>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
        error: "",
      };
    }
  )
  .handleAction(
    betAction.getBetExtractionAction.failure,
    (state: ReducerState<IV3ExtractionGroupingLottery[]>, action: BetActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )
  
  const getV3BetExtractionDetail = createReducer<
  ReducerState<IV3RespGetDetailExtractionAgentBetLottery[]>, BetActionType>(betExtractionDetailState)
  .handleAction(
    betAction.getBetExtractionDetailAction.request,
    (state: ReducerState<IV3RespGetDetailExtractionAgentBetLottery[]>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    betAction.getBetExtractionDetailAction.success,
    (state: ReducerState<IV3RespGetDetailExtractionAgentBetLottery[]>, action: BetActionType) => {
      const payload: AxiosResponse<IAPIResponse<IV3RespGetDetailExtractionAgentBetLottery[]>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
        error: "",
      };
    }
  )
  .handleAction(
    betAction.getBetExtractionDetailAction.failure,
    (state: ReducerState<IV3RespGetDetailExtractionAgentBetLottery[]>, action: BetActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )
export default combineReducers({
  rate,
  make,
  history,
  result,
  historySummaryDetail,
  historySummary,
  betGroup,
  postV2AgentBet,
  getV2AgentBet,
  resultV2,
  getV3BetExtraction,
  getV3BetExtractionDetail
});
