import KBANK from './KBANK.png'
import BAY from './BAY.png'
import KTB from './KTB.png'
import SCB from './SCB.png'
import BBL from './BBL.png'
import KKP from './KKP.png'
import CIMBT from './CIMBT.png'
import TISCO from './TISCO.png'
import UOBT from './UOBT.png'
import TCD from './TCD.png'
import ICBCT from './ICBCT.png'
import GSB from './GSB.png'
import ISBT from './ISBT.png'
import BAAC from './BAAC.png'
import TTB from './TTB.png'
import GHB from './GHB.png'
import { BANK_TYPE } from 'constants/variables'

const collectBank: IImageSet = {
  BAY: {
    Icon: BAY,
    key: 'BAY',
    name: BANK_TYPE.BAY,
  },
  TTB: {
    Icon: TTB,
    key: 'TTB',
    name: BANK_TYPE.TTB
  },
  BBL: {
    Icon: BBL,
    key: 'BBL',
    name: BANK_TYPE.BBL,
  },
  KBANK: {
    Icon: KBANK,
    key: 'KBANK',
    name: BANK_TYPE.KBANK,
  },
  KTB: {
    Icon: KTB,
    key: 'KTB',
    name: BANK_TYPE.KTB,
  },
  SCB: {
    Icon: SCB,
    key: 'SCB',
    name: BANK_TYPE.SCB,
  },
  KKP: {
    Icon: KKP,
    key: 'KKP',
    name: BANK_TYPE.KKP,
  },
  CIMBT: {
    Icon: CIMBT,
    key: 'CIMBT',
    name: BANK_TYPE.CIMBT,
  },
  TISCO: {
    Icon: TISCO,
    key: 'TISCO',
    name: BANK_TYPE.TISCO,
  },
  UOBT: {
    Icon: UOBT,
    key: 'UOBT',
    name: BANK_TYPE.UOBT,
  },
  TCD: {
    Icon: TCD,
    key: 'TCD',
    name: BANK_TYPE.TCD,
  },
  ICBCT: {
    Icon: ICBCT,
    key: 'ICBCT',
    name: BANK_TYPE.ICBCT,
  },
  GSB: {
    Icon: GSB,
    key: 'GSB',
    name: BANK_TYPE.GSB,
  },
  ISBT: {
    Icon: ISBT,
    key: 'ISBT',
    name: BANK_TYPE.ISBT,
  },
  BAAC: {
    Icon: BAAC,
    key: 'BAAC',
    name: BANK_TYPE.BAAC,
  },
  GHBANK: {
    Icon: GHB,
    key: 'GHBANK',
    name: BANK_TYPE.GHBANK,
  },

}

export default collectBank