import { AxiosError, AxiosResponse } from 'axios'
import { combineReducers } from 'redux'
import { ActionType, createReducer } from 'typesafe-actions'
import { responseConverter } from '../../utils'
import * as membersAction from './actionCreator'
import {
    membersState,
    listMembersState,
    diagramState,
    registerResState,
    MembersDetaiState,
    agentChildState,
    financeSummaryState,
} from './constants'

export type MembersActionType = ActionType<typeof membersAction>


const financeSummary = createReducer<
  ReducerState<IV2RespAgentFinanceSummary>,MembersActionType>(financeSummaryState)
  .handleAction(
    membersAction.financeSummaryAction.request,
    (state: ReducerState<IV2RespAgentFinanceSummary>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    membersAction.financeSummaryAction.success,
    (state: ReducerState<IV2RespAgentFinanceSummary>, action: MembersActionType) => {
      const payload: AxiosResponse<IAPIResponse<IV2RespAgentFinanceSummary>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
      };
    }
  )
  .handleAction(
    membersAction.financeSummaryAction.failure,
    (state: ReducerState<IV2RespAgentFinanceSummary>, action: MembersActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )


const agentChildList = createReducer<
  ReducerState<IPagination<IV2GetAgentChild>>,MembersActionType>(agentChildState)
  .handleAction(
    membersAction.agentChildAction.request,
    (state: ReducerState<IPagination<IV2GetAgentChild>>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    membersAction.agentChildAction.success,
    (state: ReducerState<IPagination<IV2GetAgentChild>>, action: MembersActionType) => {
      const payload: AxiosResponse<IAPIResponse<IPagination<IV2GetAgentChild>>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
      };
    }
  )
  .handleAction(
    membersAction.agentChildAction.failure,
    (state: ReducerState<IPagination<IV2GetAgentChild>>, action: MembersActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )

const memberSummary = createReducer<ReducerState<IMembers>, MembersActionType>(membersState)
    .handleAction(membersAction.membersAction.request, (state: ReducerState<IMembers>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(membersAction.membersAction.success, (state: ReducerState<IMembers>, action: MembersActionType) => {
        const payload: AxiosResponse<IAPIResponse<IMembers>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data: payload.data.data,
        }
    })
    .handleAction(membersAction.membersAction.failure, (state: ReducerState<IMembers>, action: MembersActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        }
    })

const membersDiagramReducer = createReducer<ReducerState<[]>, MembersActionType>(diagramState)
    .handleAction(membersAction.memberDiagramsAction.request, (state: ReducerState<[]>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(membersAction.memberDiagramsAction.success, (state: ReducerState<[]>, action: MembersActionType) => {
        const payload: AxiosResponse<IAPIResponse<[]>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data: payload.data.data,
        }
    })
    .handleAction(membersAction.memberDiagramsAction.failure, (state: ReducerState<[]>, action: MembersActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        }
    })

const memberList = createReducer<ReducerState<IPagination<IListMembers>>, MembersActionType>(listMembersState)
    .handleAction(membersAction.listMembersAction.request, (state: ReducerState<IPagination<IListMembers>>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(membersAction.listMembersAction.success, (state: ReducerState<IPagination<IListMembers>>, action: MembersActionType) => {
        const payload: AxiosResponse<IAPIResponse<IPagination<IListMembers>>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data: payload.data.data,
        }
    })
    .handleAction(membersAction.listMembersAction.failure, (state: ReducerState<IPagination<IListMembers>>, action: MembersActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        }
    })


const memberRegister = createReducer<ReducerState<IAPIResponse<object>>, MembersActionType>(registerResState)
    .handleAction(membersAction.postUserAction.request, (state: ReducerState<IAPIResponse<object>>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(membersAction.postUserAction.success, (state: ReducerState<IAPIResponse<object>>, action: MembersActionType) => {
        const payload: AxiosResponse<IAPIResponse<IAPIResponse<object>>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data: payload.data,
        }
    })
    .handleAction(membersAction.postUserAction.failure, (state: ReducerState<IAPIResponse<object>>, action: MembersActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)

        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        }
    })

const memberUpdate = createReducer<ReducerState<IAPIResponse<object>>, MembersActionType>(registerResState)
    .handleAction(membersAction.memberUpdateAction.request, (state: ReducerState<IAPIResponse<object>>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(membersAction.memberUpdateAction.success, (state: ReducerState<IAPIResponse<object>>, action: MembersActionType) => {
        const payload: AxiosResponse<IAPIResponse<IAPIResponse<object>>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data: payload.data,
        }
    })
    .handleAction(membersAction.memberUpdateAction.failure, (state: ReducerState<IAPIResponse<object>>, action: MembersActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)

        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        }
    })

const memberDelete = createReducer<ReducerState<IAPIResponse<object>>, MembersActionType>(registerResState)
    .handleAction(membersAction.memberDeleteeAction.request, (state: ReducerState<IAPIResponse<object>>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(membersAction.memberDeleteeAction.success, (state: ReducerState<IAPIResponse<object>>, action: MembersActionType) => {
        const payload: AxiosResponse<IAPIResponse<IAPIResponse<object>>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data: payload.data,
        }
    })
    .handleAction(membersAction.memberDeleteeAction.failure, (state: ReducerState<IAPIResponse<object>>, action: MembersActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)

        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        }
    })

const membersDetail = createReducer<ReducerState<IAgentUpdate>, MembersActionType>(MembersDetaiState)
    .handleAction(membersAction.memberDetailAction.request, (state: ReducerState<IAgentUpdate>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(membersAction.memberDetailAction.success, (state: ReducerState<IAgentUpdate>, action: MembersActionType) => {
        const payload: AxiosResponse<IAPIResponse<IAgentUpdate>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data: payload.data.data,
        }
    })
    .handleAction(membersAction.memberDetailAction.failure, (state: ReducerState<IAgentUpdate>, action: MembersActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        }
    })

const memberRePassword = createReducer<ReducerState<IAPIResponse<object>>, MembersActionType>(registerResState)
    .handleAction(membersAction.memberRePasswordAction.request, (state: ReducerState<IAPIResponse<object>>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(membersAction.memberRePasswordAction.success, (state: ReducerState<IAPIResponse<object>>, action: MembersActionType) => {
        const payload: AxiosResponse<IAPIResponse<IAPIResponse<object>>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data: payload.data,
        }
    })
    .handleAction(membersAction.memberRePasswordAction.failure, (state: ReducerState<IAPIResponse<object>>, action: MembersActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)

        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        }
    })

export default combineReducers({
    agentChildList,
    memberList,
    memberUpdate,
    memberDelete,
    membersDetail,
    memberSummary,
    financeSummary,
    memberRegister,
    memberRePassword,
    membersDiagramReducer,
})