export const NUMBER = /^[0-9]*$/g
export const DECIMAL_DITGITS = /(\.\d+)+/g
export const COMMA = /[,]/g
export const ENG = /^[a-zA-Z0-9]+$/g
export const ENG_NO_NUMBER = /^[a-zA-Z]+$/g
export const LOWERCASE = /^[a-z0-9]+$/g
export const ESCAPED_TIMEZONE = /\S{1,19}/g
export const SPACE = /\s+/g
export const ZERO = /^[1-9][0-9]*$/
export const SPECIAL_ENG = /^[a-zA-Z0-9!@#$%^&*)(+=._-]+$/g
export const SPECIAL_ENG_AND_SPECIAL_CHAR = /^[a-zA-Z0-9!@#$%&*]+$/g
export const ENG_THAI_AND_SPACE = /[^\u0E00-\u0E7Fa-zA-Z ]/g
export const NO_WHITESPACE = /^[\S]+$/g
export const CHECK_NUM_POINT = /[^0-9.-]/g
export const CHECK_POINT = /\./g
export const CHECK_HYPHEN = /(?!^)-/g
export const CHECK_ONLY_NUMBER = /^\d+$/

export const CHECK_SPECIAL_CHARACTERS = /^[\u0E00-\u0E7Fa-zA-Z0-9]*$/g
export const CHECK_SPECIAL_CHARACTERS_SPACE = /^[\u0E00-\u0E7Fa-zA-Z0-9\s]*$/g

export const LEADING_ZERO = /^0+/g