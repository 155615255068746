import { AxiosError, AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import { financeCustomerAction,financeCustomerTxAction,financeCustomerpostTxAction } from '../actionCreator'
import { fetchGetFinanceCustomer, fetchGetFinanceCustomerTx, PostFinanceCustomerTx } from '../services'


const getFinanceCustomer = (query:  IQueryPagination | IV2QueryAgentFinanceCustomer ) => (dispatch: Dispatch) =>{
    dispatch(financeCustomerAction.request({}))
    return fetchGetFinanceCustomer(query)
        .then((res:AxiosResponse<IAPIResponse<IPagination<IV2RespAgentFinanceCustomer>>>) => {
            dispatch(financeCustomerAction.success(res))
        })
        .catch((error: AxiosError<IAPIResponse>) => dispatch(financeCustomerAction.failure(error)))
}

const getFinanceCustomerTx = (query:  IQueryPagination | IV2QueryAgentFinanceCustomerTx ) => (dispatch: Dispatch) =>{
    dispatch(financeCustomerTxAction.request({}))
    return fetchGetFinanceCustomerTx(query)
        .then((res:AxiosResponse<IAPIResponse<IPagination<IV2RespAgentFinanceCustomerTx[]>>>) => {
            dispatch(financeCustomerTxAction.success(res))
        })
        .catch((error: AxiosError<IAPIResponse>) => dispatch(financeCustomerTxAction.failure(error)))
}
const postFinanceCustomerTx = (data: IV2PayAgentFinanceCustomerTx) => (dispatch: Dispatch) => {
    dispatch(financeCustomerpostTxAction.request({}))
    return PostFinanceCustomerTx(data)
        .then((res: AxiosResponse<IAPIResponse<object>>) => {
            dispatch(financeCustomerpostTxAction.success(res))
        })
        .catch((error: AxiosError<IAPIResponse>) => dispatch(financeCustomerpostTxAction.failure(error)))
}

const clearPostFinanceCustomerTx = () => (dispatch: Dispatch) =>
    dispatch(financeCustomerpostTxAction.cancel({}))

export {
    getFinanceCustomer,
    getFinanceCustomerTx,
    postFinanceCustomerTx,
    clearPostFinanceCustomerTx
}