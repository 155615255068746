import "./NumbersBanTable.style.scss"
import React, { Component } from "react"
import { filter, findIndex, get, groupBy, isEmpty, map, sortBy } from "lodash"
import number from "utils/number"
import { LoadingCircle } from "components"

const constants = {
  dataNotFound: "ไม่พบข้อมูล",
  number: 'เลข',
  payRate: 'อัตราจ่าย (บาท)',
}

const numberBanTableHeader: NumberBanTableHeader = {
  'THREE_TYPE': {
    label: '3 ตัว',
    length: 3,
    lottoTypes: [
      { name: '3 บน', type: 'THREE_UP' },
      { name: '3 โต๊ด', type: 'THREE_TOAST' },
    ],
  },
  'TWO_TYPE': {
    label: '2 ตัว',
    length: 2,
    lottoTypes: [
      { name: '2 บน', type: 'TWO_UP' },
      { name: '2 ล่าง', type: 'TWO_DOWN' },
    ],
  },
  'ONE_TYPE': {
    label: 'เลขวิ่ง',
    length: 1,
    lottoTypes: [
      { name: 'วิ่งบน', type: 'RUN_UP' },
      { name: 'วิ่งล่าง', type: 'RUN_DOWN' },
    ],
  }
}

type DefaultProps = Readonly<typeof defaultProps>
const defaultProps: NumbersBanTableProps = {
  lottoScheduleV2Data: [],
  dataRateReduceLottery: {
    name: '',
    ratereduceThreeUp: [],
    ratereduceThreeToast: [],
    ratereduceTwoUp: [],
    ratereduceTwoDown: [],
    ratereduceRunUp: [],
    ratereduceRunDown: [],
  },
  lottoScheduleV2IsFetching: false
}

class NumbersBanTable extends Component<
  NumbersBanTableProps & DefaultProps,
  NumbersBanTableState
> {
  static defaultProps = defaultProps

  state: NumbersBanTableState = {
    lottoModeTypeSelect: "THREE_TYPE",
  }
  
  renderTableHead = (values: NumberBanTableHeader) => {
    const filterType = values[this.state.lottoModeTypeSelect].lottoTypes

    const mapHeadTable = map(filterType, (data) => {
      return (
        <th key={`table-head-${data.name}`}>
          <h5 className={`pb-2`}>{data.name}</h5>
        </th>
      )
    })

    return (
      <>
        <tr className="row-title-header">
          <th rowSpan={2}>
            <h5>{constants.number}</h5>
          </th>
          <th colSpan={2}>
            <h5 className={`pt-2`}>{constants.payRate}</h5>
          </th>
        </tr>
        <tr className="row-title-header">{mapHeadTable}</tr>
      </>
    )
  }
  
  getBetRate = (betType: TLottoGameType) => {
    const betTypeToMap: Record<TLottoGameType, string> = {
      THREE_UP: "betRateThreeUp",
      THREE_TOAST: "betRateThreeToast",
      TWO_UP: "betRateTwoUp",
      TWO_DOWN: "betRateTwoDown",
      RUN_UP: "betRateRunUp",
      RUN_DOWN: "betRateRunDown",
      FOUR_SUITE: ""
    }
    return get(this.props.lottoScheduleV2Data[0], (betTypeToMap)[betType], 0)
  }

  getNumberStatus = (item: IV2RateReduceNumber) => {
    if (
      item.ratereduceIsHostBanned ||
      item.ratereduceIsNumholFull ||
      item.ratereduceIsBanned
    ) {
      return 'closed'
    } else if (
      item.ratereduceValue !==
      this.getBetRate(item.ratereduceBetType as TLottoGameType)
    ) {
      return 'red'
    } else {
      return ''
    }
  }

  getNumberText = (item: IV2RateReduceNumber) => {
    if (
      item.ratereduceIsBanned ||
      item.ratereduceIsNumholFull ||
      item.ratereduceIsHostBanned
    ) {
      return 'ปิดรับ'
    } else {
      return number.decimalsFormatCommaWithOutZero(item.ratereduceValue)
    }
  }

  getOtherLottoType = (item: TLottoGameType) => {
    const lottoTypes: Record<TLottoGameType, TLottoGameType> = {
      THREE_UP: 'THREE_TOAST',
      THREE_TOAST: 'THREE_UP',
      TWO_UP: 'TWO_DOWN',
      TWO_DOWN: 'TWO_UP',
      RUN_UP: 'RUN_DOWN',
      RUN_DOWN: 'RUN_UP',
      FOUR_SUITE: 'TWO_UP',
    }
    return lottoTypes[item]
  }

  createPlaceholderData = (type: TLottoGameType, number: string) => {
    const placeholder: IV2RateReduceNumber = {
      ratereduceId: -1,
      ratereduceRatereducefacId: -1,
      ratereduceBetType: type,
      ratereduceNumber: number,
      ratereduceValue: this.getBetRate(type),
      ratereduceIsNumholFull: false,
      ratereduceIsBanned: false,
      ratereduceIsHostBanned: false,
      ratereduceCreatedAt: new Date(),
      ratereduceUpdatedAt: new Date(),
    }
    return placeholder
  }
  
  renderTableBody = (rateReduceApiObj: IV2GetRateReduceHost) => {
    const dataEmpty = (
      <tr className="tableisEmpty" style={{ height: 42.67 }}>
        <td className="text-center tertiary-text" colSpan={3}>
          {constants.dataNotFound}
        </td>
      </tr>
    )
    const loadingTable= (
      <tr>
        <td className="text-center" colSpan={7}>
        <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
        </td>
      </tr>
    )
    
    const allRateReduce = [
      ...get(rateReduceApiObj, 'ratereduceThreeUp', []),
      ...get(rateReduceApiObj, 'ratereduceThreeToast', []),
      ...get(rateReduceApiObj, 'ratereduceTwoUp', []),
      ...get(rateReduceApiObj, 'ratereduceTwoDown', []),
      ...get(rateReduceApiObj, 'ratereduceRunUp', []),
      ...get(rateReduceApiObj, 'ratereduceRunDown', []),
    ]
    const allRateReduceGroupByNumber = groupBy(allRateReduce, "ratereduceNumber")
    const numberLengthFilter = numberBanTableHeader[this.state.lottoModeTypeSelect].length
    const filteredAllRateReduce = filter(
      allRateReduceGroupByNumber,
      (_, key) => key.length === numberLengthFilter
    )
    const sortedFilteredAllRateReduce = sortBy(
      filteredAllRateReduce,
      (numObj) => get(numObj, '[0].ratereduceNumber', 0)
    )
    const mapData = map(sortedFilteredAllRateReduce, (rateReduceDatas: IV2RateReduceNumber[]) => {
      const ratereduceNumber = rateReduceDatas[0].ratereduceNumber
      let upIndex = findIndex(rateReduceDatas, i => i.ratereduceBetType.includes("UP"))
      let downIndex = Number(!upIndex)
      if (rateReduceDatas.length === 1) {
        rateReduceDatas[1] = this.createPlaceholderData(
          this.getOtherLottoType(rateReduceDatas[0].ratereduceBetType as TLottoGameType),
          rateReduceDatas[0].ratereduceNumber,
        )
        if (upIndex === -1) upIndex = 1
      }

      return (
        <tr
          className={`row-number-item`}
          key={`row-number-${ratereduceNumber}`}
        >
          <td className="text-center secondary-text">
            <h5 className={`number`}>{ratereduceNumber}</h5>
          </td>
          <td className="text-center secondary-text ">
            <div className="item-number">
              <h5 className={`${this.getNumberStatus(rateReduceDatas[upIndex])}`}>
                {this.getNumberText(rateReduceDatas[upIndex])}
              </h5>
            </div>
          </td>
          <td className="text-center secondary-text ">
            <div className="item-number">
              <h5 className={`${this.getNumberStatus(rateReduceDatas[downIndex])}`}>
                {this.getNumberText(rateReduceDatas[downIndex])}
              </h5>
            </div>
          </td>
        </tr>
      )
    })
    return this.props.lottoScheduleV2IsFetching ? loadingTable : !isEmpty(filteredAllRateReduce) ? mapData : dataEmpty
  }

  render() {
    const TableHead = this.renderTableHead(numberBanTableHeader)
    const TableBody = this.renderTableBody(this.props.dataRateReduceLottery)

    return (
      <div className="number-ban-bet-making">
        <h5 className="mb-2">รายการเลขอั้น</h5>
        <div className="box-shadow pb-2">
          <div className="head-title-mode">
            {map(numberBanTableHeader, (item, key) => {
              const { label } = item
              return (
                <h5
                  key={`numberBanTableHeader-${label}`}
                  className={`text-header primary-text ${this.state.lottoModeTypeSelect === key ? "open" : ""
                    }`}
                  onClick={() => this.setState({ lottoModeTypeSelect: key as TLottoModeGameType })}
                >
                  {label}
                </h5>
              )
            })}
          </div>
          <div className="row">
            <div className="col-12">
              <div className="table-container">
                <div className="table-responseive-xl">
                  <table className="w-100 m1-t">
                    <thead className="header-table">{TableHead}</thead>
                    <tbody>{TableBody}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NumbersBanTable
