import { Breadcrumb, Dropdown, ErrorModal, Panginate } from "components";
import color from "constants/colors";
import { get, isEmpty, noop } from "lodash";
import React, { Component } from "react";
import { Table } from "./components";
import { RouteComponentProps } from "react-router-dom";
import { handleOnClickBreadcrumb } from "utils";
import "./financeSummaryChild.style.scss";
import { responseCode, responseMessage } from "constants/response";
import routes from "constants/routes";
import {  transformer } from 'utils';


const constants = {
  title: "รับ/จ่าย ลูกสมาชิก",
  subTitle: "สรุปยอดรวมลูกสมาชิก",
  breadcrumb: {
    main: "ภาพรวม",
    finance: "การเงิน",
    membersList: "รับ/จ่าย ลูกสมาชิก",
  },
  sort: "ตัวเลือกการค้นหา",
  status: "สถานะ",
  headTable: [
    { codeName: "เลขบิล", textalign: "text-left pd-frist" },
    { codeName: "ยูสเซอร์", textalign: "text-left" },
    { codeName: "ยอดเดิมพัน", textalign: "text-right" },
    { codeName: "ส่วนลด", textalign: "text-right" },
    { codeName: "ค่าคอม", textalign: "text-right" },
    { codeName: "ยอดรับ", textalign: "text-right" },
    { codeName: "ยอดจ่าย", textalign: "text-right" },
    { codeName: "ยอดทั้งหมดที่รอตรวจสอบ", textalign: "text-center" },
    { codeName: "สรุปกำไร / ขาดทุน", textalign: "text-right" },
    { codeName: "สถานะ", textalign: "text-center" },
    { codeName: "เวลา", textalign: "text-center" },
    { codeName: "", textalign: "text-center" },
  ],
  rowPerPage: "จำนวนรายการต่อหน้า :",
  financeSummaryChild: "รายการสรุปยอดบิล",
};

const defaultProps: IFinanceSummaryChildProps &
  IFinanceSummaryChildActionProps = {
  financeChildCode: 0,
  financeChildData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  financeChildError: "",
  financeChildIsFetching: false,
  getFinanceChild() {
    noop();
  },
  onChangeIsLoaddingAction() {
    noop();
  },
};

class FinanceSummaryChildContainer extends Component<
  IFinanceSummaryChildProps &
  IFinanceSummaryChildActionProps &
  RouteComponentProps,
  IFinanceSummaryChildState
> {
  static defaultProps = defaultProps;
  state: IFinanceSummaryChildState = {
    statusActive: "",
    dataQuery: {
      limit: 10,
      page: 1,
    },
    currentPage: 1,
    currentRowPerPage: 10,
    startPage: 1,
    endPage: 0,
  };

  componentDidMount() {
    this.props.getFinanceChild();
    }

  componentDidUpdate(prevProps: IFinanceSummaryChildProps) {
    if (
      prevProps.financeChildIsFetching !== this.props.financeChildIsFetching &&
      !this.props.financeChildIsFetching
    ) {
      this.props.onChangeIsLoaddingAction(false);
      if (this.props.financeChildCode === responseCode.OK) {
        if (isEmpty(this.props.financeChildData.dataList)) {
          this.setState({ startPage: 0, endPage: 0 });
        } else {
          this.setState({
            startPage:
              this.state.currentRowPerPage * (this.state.dataQuery.page - 1) +
              1,
            endPage:
              this.state.currentRowPerPage * (this.state.currentPage + 1) >=
                this.props.financeChildData.totalRecord
                ? this.props.financeChildData.totalRecord
                : this.state.currentRowPerPage * this.state.dataQuery.page,
          });
        }
      } else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            this.props.financeChildCode,
            responseMessage()[0]
          ),
        });
      }
    }
  }

  getFinanceChild = () => {
    this.props.getFinanceChild(this.state.dataQuery);
  };

  renderFilter = () => (
    <div className="filterBox">
      <div className="row">
        <div className="col-12 px-0">
          <h5>{constants.financeSummaryChild}</h5>
        </div>
      </div>
      <div className="row m3-t">
        <div className="col-12 px-0">
          <div className="subtitle-2">{constants.sort}</div>
          <div className="dropdown-border">
            <Dropdown
              menuStyles={{ width: '100%' }}
              options={[
                {
                  value: "ALL",
                  label: "ทั้งหมด",
                  disabled: false,
                },
                {
                  value: "WAIT",
                  label: "ยังไม่เคลียร์",
                  searchableName: "DESC",
                  disabled: false,
                },
                {
                  value: "PENDING",
                  label: "ทำรายการค้างไว้",
                  searchableName: "ASC",
                  disabled: false,
                },
                {
                  value: "SUCCESS",
                  label: "เคลียร์",
                  disabled: false,
                },
              ]}
              controlBackgroundColor={color.PRIMARY_BG}
              handleOnChange={(target: IOptions) => {
                this.handleQueryDropdownChange(String(target.value));
              }}
              value={this.state.statusActive}
              placeholder=
              {<span className="placeholderDropdown"> {constants.status}</span>}
            />
          </div>
        </div>
      </div>
    </div>
  );

  handleQueryDropdownChange = (value: string) => {
    this.setState(
      {
        statusActive: value,
        dataQuery: {
          ...this.state.dataQuery,
          status: value === "ALL" ? "" : value,
          page: 1,
        },
      },
      () => {
        this.getFinanceChild();
      }
    );
  };

  onClickRowMembersList = (index: number, data: IV2RespAgentFinanceChild) => {
    this.props.history.push(
      routes.financeChild.exactPath(data.agentShareId, data.childAgentId),
      {
        ...this.state.dataQuery,
        agent_share_id: data.agentShareId,
        rowIndex: index,
        totalRecordChild: this.props.financeChildData.dataList.length
      }
    );
  };

  callBackDropdownSumGroup = (target: IOptions, data: IV2RespAgentFinanceChild) => {
    if (target.value === 'betSumHistory') {
     this.props.history.push(routes.betSumHistory.path,({agentShareId:data.agentShareId} ))
   } 
     else if (target.value === 'memberInfo') {
      this.props.history.push( routes.membersList.path+transformer.urlSearchParams({childAgentId:data.childAgentId}))                    
   }
 }
 
  renderTableMembers = () => {
    return (
      <>
        <Table
          loading={this.props.financeChildIsFetching}
          column={constants.headTable}
          dataList={this.props.financeChildData.dataList}
          onClick={this.onClickRowMembersList}
          callBackDropdown={this.callBackDropdownSumGroup}
        />
      </>
    );
  };

  renderDropDownBox = () => {
    return (
      <>
        <h6>{constants.rowPerPage}</h6>
        <div className="dropdown-rowPerPage">
          <Dropdown
            menuPlacement={"top"}
            options={[
              { value: 5, label: "5", disabled: false },
              { value: 10, label: "10", disabled: false },
              { value: 20, label: "20", disabled: false },
              { value: 50, label: "50", disabled: false },
              { value: 100, label: "100", disabled: false },
            ]}
            controlBackgroundColor={color.PRIMARY_BG}
            placeholder={String(this.state.currentRowPerPage)}
            handleOnChange={(target: IOptions) => {
              this.setState(
                {
                  currentPage: 1,
                  currentRowPerPage: Number(target.value),
                  dataQuery: {
                    ...this.state.dataQuery,
                    page: 1,
                    limit: Number(target.value),
                  },
                },
                () => {
                  this.getFinanceChild();
                }
              );
            }}
          />
        </div>
      </>
    );
  };

  handleClickChangePage = ({ selected: selectedPage }: ISelectPage) => {
    this.setState({ currentPage: selectedPage }, () => {
      this.setState(
        {
          dataQuery: {
            ...this.state.dataQuery,
            page: this.state.currentPage + 1,
          },
        },
        () => {
          this.getFinanceChild();
        }
      );
    });
  };

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.breadcrumb.main, path: "/", active: false },
      { label: constants.breadcrumb.membersList, active: true },
    ];
    const RenderFilter = this.renderFilter();
    const RenderTableMembers = this.renderTableMembers();
    const RenderDropDownBox = this.renderDropDownBox;

    return (
      <>
        <div className="paper-container finance-summary-child-container">
          <div className="row">
            <div className="col">
              <div className="sub-menu">
                <Breadcrumb
                  items={navigates}
                  handleOnClickItem={handleOnClickBreadcrumb}
                />
              </div>
            </div>
          </div>
          <div className="paper-title">{constants.title}</div>
          <div className="pr-3 paper-description">{constants.subTitle}</div>
          <div className="paper-body">
            <div className="box-shadow m3-t boxShadow-Border">
              <div className="row">
                <div className="col">{RenderFilter}</div>
              </div>
              <div className="row">
                <div className="col  mt-4">{RenderTableMembers}</div>
              </div>
              <div className="row">
                <div className="col d-flex justify-content-end align-items-center ">
                  <RenderDropDownBox />
                  <h6 className="d-flex justify-content-end align-items-center m3-l">
                    {`${this.state.startPage} - ${this.state.endPage} 
                       of ${this.props.financeChildData.totalRecord || 0}`}
                  </h6>
                  <div className='col-auto'>
                    <Panginate
                      pageTotal={this.props.financeChildData.total}
                      initialPage={this.state.dataQuery.page - 1}
                      pageRangeDisplayed={this.state.currentPage}
                      onPageChange={this.handleClickChangePage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FinanceSummaryChildContainer;
