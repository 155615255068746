import { connect } from "react-redux";
import BetHistoryContainer from "./BetHistory.container";
import { getDataHistorySummary, clearDataHistorySummary } from "reduxs/lotto/bet/historySummary/action";
import {
  getDataHistorySummaryDetail,
  clearDataHistorySummaryDetail,
} from "reduxs/lotto/bet/historySummaryDetail/action";
import { onChangeIsLoaddingAction } from "../../reduxs/Loader/action";
import betSelector from "reduxs/lotto/bet/selector";

const mapStateToProps = (state: RootReducers): IBetHistoryProps => {
  const betHistoryListState = betSelector.historySummary(state);
  const betHistorySummaryDetailSelector = betSelector.historySummaryDetail(
    state
  );
  return {
    listBetHistoryCode: betHistoryListState.code,
    listBetHistoryError: betHistoryListState.error,
    listBetHistoryIsFetching: betHistoryListState.isFetching,
    listBetHistoryData: betHistoryListState.data,
    betHistorySummaryDetailCode: betHistorySummaryDetailSelector.code,
    betHistorySummaryDetailData: betHistorySummaryDetailSelector.data,
    betHistorySummaryDetailError: betHistorySummaryDetailSelector.error,
    betHistorySummaryDetailIsFetching:
      betHistorySummaryDetailSelector.isFetching,
  };
};

const mapDispatchToProps: IBetHistoryActionProps = {
  getDataHistory: getDataHistorySummary,
  onChangeIsLoaddingAction: onChangeIsLoaddingAction,
  getDataHistorySummaryDetail: getDataHistorySummaryDetail,
  clearDataHistorySummaryDetail: clearDataHistorySummaryDetail,
  clearDataHistory: clearDataHistorySummary,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BetHistoryContainer);
