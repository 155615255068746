import { AxiosError,AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { fetchCustomer, fetchCustomerNote, fetchPostCustomerNote } from "./services";
import { customerAction, customerNoteAction, postcustomerNoteAction } from "./actionCreator";


const getCustomer = (query: IV2RespAgentCustomer ) => (dispatch: Dispatch) =>{
    dispatch(customerAction.request({}))
    return fetchCustomer(query)
    .then((res:AxiosResponse<IAPIResponse<IPagination<IV2RespAgentCustomer>>>) => {
           dispatch(customerAction.success(res))
        })
        .catch((error: AxiosError<IAPIResponse>) => dispatch(customerAction.failure(error)))
}

const getCustomerNote = (query: IQueryPagination&IV2QueryAgentCustomerNote ) => (dispatch: Dispatch) =>{
    dispatch(customerNoteAction.request({}))
    return fetchCustomerNote(query)
    .then((res:AxiosResponse<IAPIResponse<IQueryPagination&IV2RespAgentCustomerNote>>) => {
           dispatch(customerNoteAction.success(res))
        })
        .catch((error: AxiosError<IAPIResponse>) => dispatch(customerNoteAction.failure(error)))
}

const postCustomerNote = (query:IV2PostAgentCustomerNote ) => (dispatch: Dispatch) =>{
    dispatch(postcustomerNoteAction.request({}))
    return fetchPostCustomerNote(query)
    .then((res:AxiosResponse<IAPIResponse<IV2RespPostAgentCustomerNote>>) => {
           dispatch(postcustomerNoteAction.success(res))
        })
        .catch((error: AxiosError<IAPIResponse>) => dispatch(postcustomerNoteAction.failure(error)))
}


export {
    getCustomer,
    getCustomerNote,
    postCustomerNote
}