import { noop } from "lodash";
import "./warning.style.scss";
import { Modal } from "../..";
import React, { FC } from "react";
import { Button } from "components";
import logo from "assets/images/logo/logothailandbet.png";
const constants = {
  title: "เกิดข้อผิดพลาด",
  titleWeb: "ไทยแลนด์เบท",
  titleEng: "Thailand Bet",
  description: "กรุณาตรวจสอบข้อมูลอีกครั้ง",
  actionText: "ลองใหม่อีกครั้ง",
};

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: IWarningModal = {
  title: constants.title,
  description: constants.description,
  actionText: constants.actionText,
  action() {
    noop();
  },
};

const WarningModal: FC<IWarningModal & DefaultProps> = (props) => {
  return (
    <div className="warning-modal-container border-rounded">
      <div className="row">
        <div className="leading-logo-container m2-r">
          <img src={logo} className="logoMenu" alt="" />
        </div>
        <div className="title-text">
          <h3 className="m2-t">{constants.titleWeb}</h3>
          <h5 className="tertiary-text">{constants.titleEng}</h5>
        </div>
      </div>
      <h2 className="primary-yellow-text p1-t">{props.title}</h2>
      <p className="tertiary-text body-1 description">{props.description}</p>
      <div className="m3-y">
        <Button
          id="warning-button"
          type="rectangle"
          onClick={props.action}
          text={props.actionText!}
        />
      </div>
    </div>
  );
};

WarningModal.defaultProps = defaultProps;

const warningModal = {
  show: (props: IWarningModal) => {
    return Modal.event("MODAL_OVER", {
      state: "show",
      extraProps: { size: "sm" },
      component: <WarningModal {...props} />,
    });
  },
  hide: () => Modal.event("MODAL_OVER", { state: "hide" }),
};

export default warningModal;
