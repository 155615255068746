import { PageSize } from "pdfmake/interfaces";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFontsTH from "assets/pdfmake-build/vfs_fonts";
import { get, groupBy, maxBy, sumBy } from "lodash";
import { date, number, transformer } from "utils";
import colors from 'constants/colors'

pdfMake.vfs = pdfFontsTH;
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew.ttf',
    bold: 'THSarabunNew Bold.ttf',
    italics: 'THSarabunNew Italic.ttf',
    bolditalics: 'THSarabunNew BoldItalic.ttf'
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
}

let headerTable = ['บน', 'ล่าง', 'โต๊ด']

const dd = (value: IV2RespAgentLotteryBetMe[], showPay: boolean, round: string = "dd MMMM yyyy", name: string, agentId: number) => {
  let listNameLotto = Object.keys(groupBy(value, "name")).map((item, index) => ((index === 0 ? '' : ', ') + transformer.nameLotto(item as TLottoType, false, true)))
  const val = {
    listHeadTable: Object.keys(groupBy(value, 'type')),
    listNumber: groupBy(value, 'number'),
    showPay: showPay,
    round: round,
    name: name,
    time: date.formatThai(get(value, "[0].createdAt"), 'dd/MM/yyyy HH:mm'),
    billNumber: agentId,
    allPay: number.decimalsFormatComma(sumBy(value, (i) => { return i.totalBet })),
    // allPay:number.decimalsFormatComma(get(maxBy(value,(item)=>item.rate),'rate','0')),
    type: listNameLotto
    // type: get(value, "[0].name") as TLottoType
  }

  let headerConst: Array<string | object> = [{ text: 'ลำดับ', margin: [0, 5, 0, 10] }, { text: 'เลข', margin: [0, 5, 0, 10] }]
  let textHeadTable = headerTable.reduce((result, item, index) => {
    headerConst = index === 0 ? headerConst : []
    result = result.concat(val.showPay ? (headerConst).concat([{ text: item, margin: [0, 5, 0, 10] }, { text: 'จ่าย', color: colors.TERTIARY_TEXT, margin: [0, 5, 0, 10] }]) : (headerConst).concat([{ text: item, margin: [0, 5, 0, 10] }]))
    return result;
  }, [] as Array<string | object>);

  let lengthHeadTable = textHeadTable.map(() => '*')

  let listNumber = Object.keys(val.listNumber).map((item, index) => {
    let data = get(val.listNumber, item, [])
    let groupType = Object.values(groupBy(data, "type")).map((item) => {
      return {
        type: get(item, '[0]type', ''),
        batAll: number.decimalsFormatComma(sumBy(item, (i) => { return i.totalBet })),
        payAll: number.decimalsFormatComma(get(maxBy(item, (i) => i.rate), 'rate', '')),
      }
    })
    return [
      (index + 1).toString(),
      { text: item.toString(), color: colors.SECONDARY_PURPLE, bold: true, margin: [0, 0, 0, 10], }
    ].concat(headerTable.reduce((result, item) => {
      let value = groupType.find(element => {
        let type: string[] = element.type.split('_')
        return type[type.length - 1] === (item === 'ล่าง' ? 'DOWN' : item === 'โต๊ด' ? 'TOAST' : 'UP')
      })
      let batAll = get(value, 'batAll', '')
      let payAll = get(value, 'payAll', '')
      result = result.concat(val.showPay ? [batAll, { text: payAll, color: colors.TERTIARY_TEXT }] : [batAll])
      return result;
    }, [] as Array<string | any>))
  })
  const ddContent = () => [
    {
      columns: [
        {
          text: "ใบเสร็จ",
          alignment: "center",
          bold: true,
          fontSize: 36,
        },
      ],
    },
    {
      columns: [
        {
          width: 'auto',
          text: "หวย",
          margin: [0, 8, 0, 0],
        },
        {
          width: 'auto',
          text: val.type,
          margin: [5, 8, 0, 0],
          bold: true,
          lineHeight: 1,
        },
      ],
    },
    {
      columns: [
        {
          width: 'auto',
          text: "งวดวันที่",
          margin: [0, 3, 0, 0],
        },
        {
          width: '*',
          text: val.round,
          margin: [5, 3, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: 'auto',
          text: "สมาชิก",
          margin: [0, 8, 0, 0],
        },
        {
          width: 'auto',
          text: val.name,
          margin: [5, 8, 0, 0],
          bold: true,
        },
      ],
    },
    {
      columns: [
        {
          width: 'auto',
          text: "เวลาออกบิล",
          margin: [0, 8, 0, 0],
        },
        {
          width: 'auto',
          text: val.time,
          margin: [5, 8, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: 'auto',
          text: "บิลเลขที่",
          fontSize: 14,
          margin: [0, 8, 0, 0],
        },
        {
          width: 'auto',
          text: val.billNumber,
          fontSize: 14,
          margin: [15, 8, 0, 0],
        },
        {
          width: 69,
          text: "จำนวนเงินรวม",
          fontSize: 14,
          margin: [15, 8, 0, 0],
        },
        {
          width: 'auto',
          text: val.allPay,
          margin: [15, 8, 0, 0],
          fontSize: 14,
          bold: true,
          color: colors.SECONDARY_PURPLE
        },
        {
          width: 'auto',
          text: "บาท",
          fontSize: 14,
          margin: [15, 8, 0, 5],
        },
      ],
    },
    {
      layout: {
        hLineWidth: function (i: number) {
          return (i === 0 || i === 1) ? 1 : 0;
        },
        vLineWidth: function () {
          return 0
        },
        hLineColor: colors.TERTIARY_TEXT
      },
      table: {
        headerRows: 1,
        widths: lengthHeadTable,
        body: [textHeadTable].concat(listNumber)
      }
    }
  ];


  const docDefinition = {
    pageSize: "A4" as PageSize,
    pageMargins: [20, 20, 20, 0] as [number, number, number, number],
    content: ddContent(),
    defaultStyle: {
      font: "THSarabunNew",
      fontSize: 16,
      lineHeight: 0.65,
    },
  };
  return docDefinition;
};

export default dd;
