import { AxiosError, AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { ActionType, createReducer } from "typesafe-actions";
import { responseConverter } from "utils";
import * as meAction from "./actionCreator";
import { meSettingLotteryState, meSettingState, meState } from "./constants";

export type MeActionType = ActionType<typeof meAction>;
const setting = createReducer<ReducerState<IV2GetAgentMeSetting>, MeActionType>(
  meSettingState
)
  .handleAction(
    meAction.getMeSettingAction.request,
    (state: ReducerState<IV2GetAgentMeSetting>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    meAction.getMeSettingAction.success,
    (state: ReducerState<IV2GetAgentMeSetting>, action: MeActionType) => {
      const payload: AxiosResponse<IAPIResponse<IV2GetAgentMeSetting>> =
        action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: { ...state.data, ...payload.data.data },
      };
    }
  )
  .handleAction(
    meAction.getMeSettingAction.failure,
    (state: ReducerState<IV2GetAgentMeSetting>, action: MeActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );

const me = createReducer<ReducerState<IV2GetAgentMe>, MeActionType>(meState)
  .handleAction(
    meAction.getMeAction.request,
    (state: ReducerState<IV2GetAgentMe>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    meAction.getMeAction.success,
    (state: ReducerState<IV2GetAgentMe>, action: MeActionType) => {
      const payload: AxiosResponse<IAPIResponse<IV2GetAgentMe>> =
        action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: { ...state.data, ...payload.data.data },
        error: "",
      };
    }
  )
  .handleAction(
    meAction.getMeAction.failure,
    (state: ReducerState<IV2GetAgentMe>, action: MeActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );

const settingLottery = createReducer<
  ReducerState<IV2AgentMeSettingLottery>,
  MeActionType
>(meSettingLotteryState)
  .handleAction(
    meAction.getMeSettingLotteryAction.request,
    (state: ReducerState<IV2AgentMeSettingLottery>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    meAction.getMeSettingLotteryAction.success,
    (state: ReducerState<IV2AgentMeSettingLottery>, action: MeActionType) => {
      const payload: AxiosResponse<IAPIResponse<IV2AgentMeSettingLottery>> =
        action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: { ...state.data, ...payload.data.data },
        error: "",
      };
    }
  )
  .handleAction(
    meAction.getMeSettingLotteryAction.failure,
    (state: ReducerState<IV2AgentMeSettingLottery>, action: MeActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );

export default combineReducers({
  setting,
  me,
  settingLottery,
});
