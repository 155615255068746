import React, { FC } from "react";
import { isEmpty, noop } from "lodash";
import { Modal } from "../..";
import "./confirmNumberBanWarning.style.scss";
import { Button } from "components";
import logo from "assets/images/logo/logothailandbet.png";
import colors from "constants/colors";

const constants = {
  title: "ไ่ม่สามารถเพิ่มเลขปิดรับแทงได้",
  description: "เลขดังกล่าวถูกปิดรับแทง\nคุณจะเพิ่มบิลโดยไม่มีเลขดังกล่าวหรือจะกลับไปแก้ไข",
  actionText: "ตกลง เพิ่มบิลโดยไม่มีเลขปิดรับ",
  titleWeb: "ไทยแลนด์เบท",
  titleEng: "Thailand Bet",
  cancel: "ยกเลิกและกลับไปแก้ไข",
  accept: "ตกลง เพิ่มบิลโดยไม่มีเลขปิดรับ",
};

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: IInfoModal = {
  title: constants.title,
  description: constants.description,
  actionText: constants.actionText,
  cancelText: constants.cancel,
  action() {
    noop();
  },
  cancelAction() {
    noop();
  },
  titleStyle: '',
  whiteSpace:'normal',
  numberList: [],
};

const ConfirmNumberBanWarningModal: FC<IInfoModal & DefaultProps> = (props) => {
  const {whiteSpace} =props
  return (
    <div className={`confirm-number-ban-modal-container border-rounded`} style={{whiteSpace}} >
      <div className="row mr-0">
        <div className="leading-logo-container m2-x">
          <img src={logo} className="logoMenu" alt="logo Thailand bet" />
        </div>
        <div className="title-text-web">
          <h3 className="title">{constants.titleWeb}</h3>
          <h5 className="tertiary-text sub-title">{constants.titleEng}</h5>
        </div>
      </div>
      <h2 className={`title-text secondary-text ${props.textSmall&&"text-small"} whitespace ${props.titleStyle}`}>{props.title}</h2>
      {props.numberList ? (
        <div className="number-list-container">
          {props.numberList.map((num, i) => (
            <div
              className="number-list-number"
              key={`confirm-number-ban-modal-num-${num}-${i}`}
            >
              {num}
            </div>
          ))}
        </div>
        ) : ''}
      <p className={`body-semi1 tertiary-text ${props.textSmall&&"text-small"}`}>{props.description}</p>
      <div className="row justify-content-center">
        <div className="col pr-0">
          <Button
            id="confirm-number-ban-button"
            type="rectangle"
            onClick={props.action}
            text={props.actionText!}
            textSize={18}
            transitionType="typeOne"
          />
        </div>
        <div className="col">
          <Button
            id="confirm-number-ban-button"
            type="rectangle"
            backgroundColor={colors.TERTIARY_TEXT}
            backgroundHoverColor={colors.TERTIARY_TEXT}
            borderColor={colors.SECONDARY_TEXT}
            onClick={props.cancelAction}
            text={props.cancelText!}
            transitionType="typeOne"
            textSize={18}
          />
        </div>
      </div>
    </div>
  );
};

ConfirmNumberBanWarningModal.defaultProps = defaultProps;

const confirmNumberBanWarningModal = {
  show: (props: IInfoModal) => {
    return Modal.event("MODAL_OVER", {
      state: "show",
      extraProps: { size: isEmpty(props.size)?"md":props.size },
      component: <ConfirmNumberBanWarningModal {...props} />,
    });
  },
  hide: () => Modal.event("MODAL_OVER", { state: "hide" }),
};

export default confirmNumberBanWarningModal;
