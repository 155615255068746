import { AxiosError, AxiosResponse } from 'axios'
import { combineReducers } from 'redux'
import { ActionType, createReducer } from 'typesafe-actions'
import { responseConverter } from '../../utils'
import * as editUserHistoryAction from './actionCreator'
import { getEditUserHistoryListState } from './constants'

export type UserActionType = ActionType<typeof editUserHistoryAction>

const getEditUserHistoryList = createReducer<ReducerState<IPagination<IEditUserHistory>>, UserActionType>(getEditUserHistoryListState)
    .handleAction(editUserHistoryAction.getEditUserHistoryAction.request, (state: ReducerState<IPagination<IEditUserHistory>>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(editUserHistoryAction.getEditUserHistoryAction.success, (
        state: ReducerState<IPagination<IEditUserHistory>>,
        action: UserActionType) => {
        const payload: AxiosResponse<IAPIResponse<IPagination<IEditUserHistory>>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data: payload.data.data,
        }
    })
    .handleAction(editUserHistoryAction.getEditUserHistoryAction.failure, (state: ReducerState<IPagination<IEditUserHistory>>, action: UserActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        }
    })
    .handleAction(editUserHistoryAction.getEditUserHistoryAction.cancel, () => getEditUserHistoryListState)

export default combineReducers({
    editUserHistoryList: getEditUserHistoryList,
})