import {
  AGENT_STATUS_TO_NAME,
  LEVEL_TYPE_CHANGE_WORD,
  meDataInitialValue,
} from "constants/variables";
import { format } from "date-fns";
import { th } from "date-fns/locale";
import { get } from "lodash";
import React, { Component } from "react";
import { date, number } from "utils";
import "./userSpecificInfo.style.scss";
import { LoadingCircle } from "components";

const constants = {
  userSpecificInfoTitle: "ข้อมูลจำเพาะ",
  status: "สถานะ",
  noOfMember: "จำนวนลูกสมาชิก",
  permission: "สิทธิการใช้งาน",
  profitAndLoss: "ยอดกำไร / ขาดทุน",
  profitAndLossHostReceived: "ยอดกำไร / ขาดทุนที่หัวสมาชิกได้รับ",
  currentCredit: "จำนวนเครดิตปัจจุบัน / จำกัด",
  creditToMember: "เครดิตทั้งหมดที่ผู้ใช้มอบให้ลูกสมาชิก",
  betTransaction: "ยอดรวมเดิมพัน",
  lastBillTotal: "ยอดรวมเดิมพันปัจจุบันในบิลล่าสุด",
  totalBet: "ยอดรวมครั้งการเดิมพัน",
  currentTotalBet: "ยอดรวมปัจจุบันครั้งการเดิมพัน",
};

const defaultProps: IUserSpecificInfoProps = {
  meData: meDataInitialValue,
  loading: false
};

class UserSpecificInfoComponent extends Component<
  IUserSpecificInfoProps,
  IUserSpecificInfoState
> {
  static defaultProps = defaultProps;
  state: IUserSpecificInfoState = {};

  render() {
    const { meData, loading } = this.props;
    return (
      <div className="paper-body user-specific-info-container">
        <div className="row mt-4">
          <div className="col">
            <div className="box-shadow">
              <div className="row">
                <div className="col-12 pr-3 paper-body-title">
                  
                <div className="d-flex align-items-center">
                <div className="userSpecificInfoTitleHeadText">{constants.userSpecificInfoTitle}</div>  
                  {loading && ( <div className="m1-l"><LoadingCircle /> </div>)}
                </div>
                </div>
                <div className="col-12 ">
                  <div className="content-text mt-1">
                    {`อัพเดทเมื่อ ${format(
                      date.calibratingTime(meData.updatedAt, true),
                      "dd MMM yyyy HH:mm",
                      { locale: th }
                    )}`}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4 m3-t">
                  <div className="d-flex flex-column">
                    <div className="content-text">{constants.status}</div>
                    <div className={`${loading ? 'tertiary-text' : 'paper-body-title'} mt-1`}>
                      {
                        AGENT_STATUS_TO_NAME[
                          get(meData, "agentStatus", "NORMAL") as TAgentStatus
                        ]
                      }
                    </div>
                  </div>
                </div>
                <div className="col-4 m3-t">
                  <div className="d-flex flex-column">
                    <div className="content-text">{constants.noOfMember}</div>
                    <div className={`${loading ? 'tertiary-text' : 'paper-body-title'} mt-1`}>{`${meData.childTotal} คน`}</div>
                  </div>
                </div>
                <div className="col-4 m3-t">
                  <div className="d-flex flex-column">
                    <div className="content-text">{constants.permission}</div>
                    <div className={`${loading ? 'tertiary-text' : 'paper-body-title'} mt-1`}>{`${
                      LEVEL_TYPE_CHANGE_WORD[meData.permission as TLevelType]
                    }`}</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4 m3-t">
                  <div className="d-flex flex-column">
                    <div className="content-text">
                      {constants.profitAndLoss}
                    </div>
                    <div className={`${loading ? 'tertiary-text' : 'paper-body-title'} mt-1`}>{`${number.addComma(
                      meData.profitTotal
                    )} / ${number.addComma(meData.lossTotal)} บาท`}</div>
                  </div>
                </div>
                <div className="col-4 m3-t">
                  <div className="d-flex flex-column">
                    <div className="content-text">
                      {constants.profitAndLossHostReceived}
                    </div>
                    <div className={`${loading ? 'tertiary-text' : 'paper-body-title'} mt-1`}>{`${number.addComma(
                      meData.profitBenefitTotal
                    )} / ${number.addComma(meData.lossBenefitTotal)} บาท`}</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4 m3-t">
                  <div className="d-flex flex-column">
                    <div className="content-text">
                      {constants.currentCredit}
                    </div>
                    <div className={`${loading ? 'tertiary-text' : 'paper-body-title'} mt-1`}>{`${number.addComma(
                      meData.credit
                    )} / ${number.addComma(meData.creditLimit)} บาท`}</div>
                  </div>
                </div>
                <div className="col-4 m3-t">
                  <div className="d-flex flex-column">
                    <div className="content-text">
                      {constants.creditToMember}
                    </div>
                    <div className={`${loading ? 'tertiary-text' : 'paper-body-title'} mt-1`}>{`${number.addComma(
                      meData.creditShareLimit
                    )} บาท`}</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4 m3-t">
                  <div className="d-flex flex-column">
                    <div className="content-text">
                      {constants.betTransaction}
                    </div>
                    <div className={`${loading ? 'tertiary-text' : 'paper-body-title'} mt-1`}>{`${number.addComma(
                      meData.betTotal
                    )} บาท`}</div>
                  </div>
                </div>
                <div className="col-4 m3-t">
                  <div className="d-flex flex-column">
                    <div className="content-text">
                      {constants.lastBillTotal}
                    </div>
                    <div className={`${loading ? 'tertiary-text' : 'paper-body-title'} mt-1`}>{`${number.addComma(
                      meData.currentBetTotal
                    )} บาท`}</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4 m3-t">
                  <div className="d-flex flex-column">
                    <div className="content-text">{constants.totalBet}</div>
                    <div className={`${loading ? 'tertiary-text' : 'paper-body-title'} mt-1`}>{`${number.addComma(
                      meData.betCountTotal
                    )} ครั้ง`}</div>
                  </div>
                </div>
                <div className="col-4 m3-t">
                  <div className="d-flex flex-column">
                    <div className="content-text">
                      {constants.currentTotalBet}
                    </div>
                    <div className={`${loading ? 'tertiary-text' : 'paper-body-title'} mt-1`}>{`${number.addComma(
                      meData.currentBetCountTotal
                    )} ครั้ง`}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserSpecificInfoComponent;
