import React, { FC } from "react";
import { noop } from "lodash";
import "./checkBox.style.scss";

const defaultProps: ICheckBox = {
  id: "",
  isChecked: false,
  onClick() {},
  disabled: false,
  className: ""
};

const CheckBoxComponent: FC<ICheckBox> = (props) => {
  const { id, onClick, disabled, value, className } = props;

  return (
      <section>
        <div className={`${className} check-box-container ${disabled ? "disabled-check" : ""}`}>
          <input
            id={id}
            name={id}
            type="checkbox"
            onClick={disabled ? noop : onClick}
            disabled={disabled}
            // defaultChecked={isChecked}
            checked={value}
            onChange={()=>{}}
          />
          <label htmlFor={id} className={`${disabled ? "disabled-check" : ""}`}></label>
        </div>
      </section>
  );
};

CheckBoxComponent.defaultProps = defaultProps;

export default CheckBoxComponent;
