import { AxiosError, AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { ActionType, createReducer } from "typesafe-actions";
import { responseConverter } from "utils";
import * as yegeeAction from "./actionCreator";
import {
  yegeeGameHistoryState,
  yegeeGameListState,
  yegeeGameState,
  yegeePlayedListState,
  yegeePlayState,
  yegeeSumState,
} from "./constants";

export type yegeeActionType = ActionType<typeof yegeeAction>;

const gameList = createReducer<ReducerState<ILottoGame[]>, yegeeActionType>(
  yegeeGameListState
)
  .handleAction(
    yegeeAction.getYeegeGameListAction.request,
    (state: ReducerState<ILottoGame[]>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    yegeeAction.getYeegeGameListAction.success,
    (state: ReducerState<ILottoGame[]>, action: yegeeActionType) => {
      const payload: AxiosResponse<IAPIResponse<ILottoGame[]>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
      };
    }
  )
  .handleAction(
    yegeeAction.getYeegeGameListAction.failure,
    (state: ReducerState<ILottoGame[]>, action: yegeeActionType) => {
      const payload: AxiosError<IAPIResponse<ILottoGame[]>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )
  .handleAction(
    yegeeAction.updateYegeeGameListAction.request,
    (state: ReducerState<ILottoGame[]>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    yegeeAction.updateYegeeGameListAction.success,
    (state: ReducerState<ILottoGame[]>, action: yegeeActionType) => {
      const payload: IAPIResponse<ILottoGame[]> = action.payload;
      return {
        ...state,
        isFetching: false,
        code: payload.code,
        data: payload.data,
      };
    }
  )
  .handleAction(
    yegeeAction.updateYegeeGameListAction.failure,
    (state: ReducerState<ILottoGame[]>, action: yegeeActionType) => {
      const payload: IAPIResponse<ILottoGame[]> = action.payload;
      return {
        ...state,
        isFetching: false,
        code: payload.code,
        error: payload.devMessage,
      };
    }
  );

const gameHistory = createReducer<
  ReducerState<IYegeeGameHistory[]>,
  yegeeActionType
>(yegeeGameHistoryState)
  .handleAction(
    yegeeAction.postYegeeGameHistoryAction.request,
    (state: ReducerState<any>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    yegeeAction.postYegeeGameHistoryAction.success,
    (state: ReducerState<IYegeeGameHistory[]>, action: yegeeActionType) => {
      const payload: AxiosResponse<IAPIResponse<IYegeeGameHistory[]>> =
        action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        data: payload.data.data,
        code: convertedResponse.code,
      };
    }
  )
  .handleAction(
    yegeeAction.postYegeeGameHistoryAction.failure,
    (state: ReducerState<IYegeeGameHistory[]>, action: yegeeActionType) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )
  .handleAction(
    yegeeAction.clearYegeeGameHistoryAction,
    (state: ReducerState<IYegeeGameHistory[]>) => {
      return {
        ...yegeeGameHistoryState,
      };
    }
  );

const play = createReducer<ReducerState<IYegeePlay>, yegeeActionType>(
  yegeePlayState
)
  .handleAction(
    yegeeAction.postYegeePlayAction.request,
    (state: ReducerState<IYegeePlay>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    yegeeAction.postYegeePlayAction.success,
    (state: ReducerState<IYegeePlay>, action: yegeeActionType) => {
      const payload: AxiosResponse<IAPIResponse<IYegeePlay>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        data: payload.data.data,
        code: convertedResponse.code,
      };
    }
  )
  .handleAction(
    yegeeAction.postYegeePlayAction.failure,
    (state: ReducerState<IYegeePlay>, action: yegeeActionType) => {
      const payload: AxiosResponse<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );

const sum = createReducer<ReducerState<string>, yegeeActionType>(yegeeSumState)
  .handleAction(
    yegeeAction.getYegeeSumAction.request,
    (state: ReducerState<string>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    yegeeAction.getYegeeSumAction.success,
    (state: ReducerState<string>, action: yegeeActionType) => {
      const payload: AxiosResponse<IAPIResponse<string>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
      };
    }
  )
  .handleAction(
    yegeeAction.getYegeeSumAction.failure,
    (state: ReducerState<string>, action: yegeeActionType) => {
      const payload: AxiosResponse<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )
  .handleAction(
    yegeeAction.updateYegeeSumAction.request,
    (state: ReducerState<string>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    yegeeAction.updateYegeeSumAction.success,
    (state: ReducerState<string>, action: yegeeActionType) => {
      const payload: IAPIResponse<string> = action.payload;
      return {
        ...state,
        isFetching: false,
        code: payload.code,
        data: payload.data,
      };
    }
  )
  .handleAction(
    yegeeAction.updateYegeeSumAction.failure,
    (state: ReducerState<string>, action: yegeeActionType) => {
      const payload: IAPIResponse<string> = action.payload;
      return {
        ...state,
        isFetching: false,
        code: payload.code,
        error: payload.devMessage,
      };
    }
  )
  .handleAction(
    yegeeAction.clearYegeeSumAction,
    (state: ReducerState<string>) => {
      return {
        ...yegeeSumState,
      };
    }
  );

const playedList = createReducer<ReducerState<IYegeePlay[]>, yegeeActionType>(
  yegeePlayedListState
)
  .handleAction(
    yegeeAction.getPlayedYegeeListAction.request,
    (state: ReducerState<IYegeePlay[]>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    yegeeAction.getPlayedYegeeListAction.success,
    (state: ReducerState<IYegeePlay[]>, action: yegeeActionType) => {
      const payload: AxiosResponse<IAPIResponse<IYegeePlay[]>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        data: payload.data.data,
        code: convertedResponse.code,
      };
    }
  )
  .handleAction(
    yegeeAction.getPlayedYegeeListAction.failure,
    (state: ReducerState<IYegeePlay[]>, action: yegeeActionType) => {
      const payload: AxiosResponse<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )
  .handleAction(
    yegeeAction.updatePlayedYegeeListAction.request,
    (state: ReducerState<IYegeePlay[]>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    yegeeAction.updatePlayedYegeeListAction.success,
    (state: ReducerState<IYegeePlay[]>, action: yegeeActionType) => {
      const payload: IAPIResponse<IYegeePlay[]> = action.payload;
      return {
        ...state,
        isFetching: false,
        code: payload.code,
        data: payload.data,
      };
    }
  )
  .handleAction(
    yegeeAction.updatePlayedYegeeListAction.failure,
    (state: ReducerState<IYegeePlay[]>, action: yegeeActionType) => {
      const payload: IAPIResponse<IYegeePlay[]> = action.payload;
      return {
        ...state,
        isFetching: false,
        code: payload.code,
        error: payload.devMessage,
      };
    }
  )
  .handleAction(
    yegeeAction.clearPlayedYegeeListAction,
    (state: ReducerState<IYegeePlay[]>) => {
      return {
        ...yegeePlayedListState,
      };
    }
  );

const game = createReducer<ReducerState<ILottoGame>, yegeeActionType>(
  yegeeGameState
)
  .handleAction(
    yegeeAction.getYegeeGameAction.request,
    (state: ReducerState<ILottoGame>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    yegeeAction.getYegeeGameAction.success,
    (state: ReducerState<ILottoGame>, action: yegeeActionType) => {
      const payload: AxiosResponse<IAPIResponse<ILottoGame>> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        data: payload.data.data,
        code: convertedResponse.code,
      };
    }
  )
  .handleAction(
    yegeeAction.getYegeeGameAction.failure,
    (state: ReducerState<ILottoGame>, action: yegeeActionType) => {
      const payload: AxiosResponse<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  )
  .handleAction(
    yegeeAction.updateYegeeGameAction.request,
    (state: ReducerState<ILottoGame>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    yegeeAction.updateYegeeGameAction.success,
    (state: ReducerState<ILottoGame>, action: yegeeActionType) => {
      const payload: IAPIResponse<ILottoGame> = action.payload;
      return {
        ...state,
        isFetching: false,
        code: payload.code,
        data: payload.data,
      };
    }
  )
  .handleAction(
    yegeeAction.updateYegeeGameAction.failure,
    (state: ReducerState<ILottoGame>, action: yegeeActionType) => {
      const payload: IAPIResponse<ILottoGame> = action.payload;
      return {
        ...state,
        isFetching: false,
        code: payload.code,
        error: payload.devMessage,
      };
    }
  );

export default combineReducers({
  gameList,
  gameHistory,
  play,
  sum,
  playedList,
  game,
});
