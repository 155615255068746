import React, { FC } from "react"
import "./LoadingCircle.style.scss"

const defaultProps: ILoadingCircle = {
  loadingStyle: ''
}

const LoadingCircle: FC<ILoadingCircle> = (props) => {

    const {
      loadingStyle,
    } = props

  return (
    <div className="loading-circle-container">
      <div className={`spnr ${loadingStyle}`}></div>
    </div>
  )
}

LoadingCircle.defaultProps = defaultProps
export default LoadingCircle
