import { Formik, FormikProps } from "formik";
import HomeResetPasswordComponents from "./components";
import React, { Component } from "react";
import initialResetPassword from "./model/initialValues";
import schemeResetPassword from "./model/scheme";
import { noop, get } from "lodash";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { responseCode, responseMessage } from "constants/response";
import { ConfirmModal, ErrorModal, SuccessModal } from "components";

const ResetPassword = HomeResetPasswordComponents.ResetUserPassword;

const constants = {
  resetPasswordSuccess: "เปลี่ยนรหัสผ่านสำเร็จ",
  resetPasswordConfirm: "คุณแน่ใจหรือว่าจะเปลี่ยนรหัสผ่าน",
  resetPasswordError: "เปลี่ยนรหัสผ่านไม่สำเร็จ",
};

type DefaultProps = Readonly<typeof defaultProps>;
const defaultProps: IResetUserPasswordProps & IResetUserPasswordActionProps = {
  onChangeIsLoaddingAction() {
    noop();
  },
  resetPassword() {
    noop();
  },
  resetPasswordCode: 0,
  resetPasswordIsFetching: false,
  resetPasswordError: "",
  resetPasswordData: {
    data: {},
    code: 0,
    devMessage: "",
  }
};

class HomeResetUserPasswordContainer extends Component<
  IResetUserPasswordProps &
  IResetUserPasswordActionProps &
  DefaultProps &
  RouteComponentProps<{}, {}, {}>
> {
  static defaultProps = defaultProps;
  state: IResetPasswordContainerState = {};

  componentDidMount() {
    this.props.onChangeIsLoaddingAction(false);
  }

  componentDidUpdate(prevProps: IResetUserPasswordProps) {
    if (
      this.props.resetPasswordIsFetching
    ) {
      this.props.onChangeIsLoaddingAction(true);
    } else {
      this.props.onChangeIsLoaddingAction(false);
    }

    if (
      prevProps.resetPasswordIsFetching !== this.props.resetPasswordIsFetching &&
      !this.props.resetPasswordIsFetching
    ) {
      if (this.props.resetPasswordData.code === responseCode.OK) {
        SuccessModal.show({
          action: () => {
            SuccessModal.hide()
            this.props.history.replace("/dashboard")
          },
          description: constants.resetPasswordSuccess,
        });
      } else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            this.props.resetPasswordCode,
            constants.resetPasswordError  
            ),
        });
      }
    }
  }

  onSubmitPassword = (values: IResetUserPasswordRequest) => {
    ConfirmModal.show({
      action: () => {
        this.props.resetPassword(values)
        ConfirmModal.hide()
      },
      cancelAction: () => {
        ConfirmModal.hide();
      },
      description: constants.resetPasswordConfirm
    })
  };

  ResetPasswordFormik = () => {
    const ResetPasswordForm = (formProps: FormikProps<IResetUserPasswordRequest>) => {
      return <ResetPassword {...formProps} />
    }
    return (
      <Formik
        initialValues={initialResetPassword}
        validationSchema={schemeResetPassword}
        enableReinitialize
        onSubmit={this.onSubmitPassword}
      >
        {ResetPasswordForm}
      </Formik>
    );
  };
  render() {
    const ResetPasswordFormik = this.ResetPasswordFormik;
    return <div>{ResetPasswordFormik()}</div>;
  }
}

export default withRouter(HomeResetUserPasswordContainer);
