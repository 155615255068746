import { get } from 'lodash'
import { initialMenuState, initialShowMenuState } from './constants'

const menu = (state: IMenuActiveControl) : IMenuActiveControl =>
    get(state, 'menu.menuReducers', initialMenuState)
const showMenu = (state: IShowMenu) : IShowMenu =>
    get(state, 'menu.groupShowReducers', initialShowMenuState)


const menuSelector = {
    menu,
    showMenu
}
export default menuSelector