import React, { useState } from "react"
import { FunctionComponent } from "react"
import "../betWaitingResult.style.scss"
import { isEmpty, map } from "lodash"
import { LoadingCircle } from "components"
import { fetchGetLottoBetMember } from "reduxs/lotto/bet/services"
import TableCollapsible from "./TableCollapsible"
import { endOfMonth, format, startOfMonth } from "date-fns"

const constants = {
    dataNotFound: "ไม่พบข้อมูล...",
}
interface ITableLottoBetLotto {
    lotteryList: IV3LotteryBetAgentLotteryList[]
    childNestedList: ChildListDetail[]
    userName: string
    loading: boolean
    handleOnClickChild: (childAgentId: number, lotteryName: string, memberUsername: string, memberPermission: string) => void
}

const SumTotalBetLotto: FunctionComponent<ITableLottoBetLotto> = ({
    lotteryList,
    childNestedList,
    loading,
    userName,
    handleOnClickChild
}) => {

    const [isLoading, setIsLoading] = useState(false)
    const defaultMemberList: IV3RespGetLotteryBetMember = { memberList: [] }
    const [lotteryMemberList, setLotteryMemberList] = useState<{ [key in string]: IV3RespGetLotteryBetMember }>({ "DEFAULT": defaultMemberList })

    const getLottoBetMember = (lotteryName: string) => {
        let query: IV3QueryGetLotteryBetMember = {
            betTransactionStatus: 'WAIT',
            startCreatedAt: format(startOfMonth(new Date()), "yyyy'-'MM'-'dd'T'00:00:00"),
            endCreatedAt: format(endOfMonth(new Date()), "yyyy'-'MM'-'dd'T'23:59:59")
        }
        if (childNestedList.length > 1) {
            query = { ...query,childAgentId: childNestedList[childNestedList.length - 1].childAgentId }
        }

        fetchGetLottoBetMember({ ...query, lotteryName: [lotteryName] })
            .then((response) => {
                if (response) {
                    const data = response.data.data;
                    setLotteryMemberList(prevState => ({
                        ...prevState,
                        [lotteryName]: data
                    }))
                }
            })
            .catch((error) => console.log(error))
            .finally(() => {
                setTimeout(() => {
                    setIsLoading(false)
                }, 300)
            })
    }

    const TableHead = () => {
        return <thead className='header-table table-bordered'>
            <tr className="text-center">
                <th rowSpan={2} colSpan={2}>ชนิดหวย</th>
                <th rowSpan={2}>ยอดทั้งหมด</th>
                <th colSpan={3}>สมาชิก</th>
                <th colSpan={3}>{userName}</th>
                <th colSpan={3}>บริษัท</th>
            </tr>
            <tr className="text-center">
                <th >ส่งออก</th>
                <th>คอม</th>
                <th>รวม</th>
                <th>ถือหุ้น</th>
                <th>คอม</th>
                <th>รวม</th>
                <th>ถือหุ้น</th>
                <th>คอม</th>
                <th>รวม</th>
            </tr>
        </thead>
    }


    const tableBody = () => {
        const dataEmpty = (
            <tr>
                <td className="text-center" colSpan={15}>
                    {constants.dataNotFound}
                </td>
            </tr>
        )
        const loadingTable = (
            <tr>
                <td className="text-center" colSpan={15}>
                    <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
                </td>
            </tr>
        )
        const mapData = map(lotteryList, (item: IV3LotteryBetAgentLotteryList, index: number) => {
            return (
                <TableCollapsible
                    key={index}
                    handleOnClickChild={handleOnClickChild}
                    action={getLottoBetMember}
                    userName={userName}
                    loading={isLoading}
                    lottoBet={item}
                    lotteryMemberList={lotteryMemberList[`${item.lotteryName}`]}
                />
            )
        })
        return loading ? loadingTable : !isEmpty(lotteryList) ? mapData : dataEmpty
    }

    return (
        <div className="table-responsive-xl mb-3">
            <table className="w-100">
                <TableHead />
                <tbody className='table-bordered'>{tableBody()}</tbody>
            </table>
        </div>
    )
}

export default SumTotalBetLotto
