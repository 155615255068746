import { AxiosError, AxiosResponse } from "axios";
import { createAsyncAction } from "typesafe-actions";
import {
  GET_CHILD_DETAIL_FAILURE,
  GET_CHILD_DETAIL_REQUEST,
  GET_CHILD_DETAIL_SUCCESS,
  GET_CHILD_BET_REQUEST,
  GET_CHILD_BET_SUCCESS,
  GET_CHILD_BET_FAILURE,
  POST_CHILD_CREDIT_UPDATE_FAILURE,
  POST_CHILD_CREDIT_UPDATE_REQUEST,
  POST_CHILD_CREDIT_UPDATE_SUCCESS,
  POST_CHILD_FAILURE,
  POST_CHILD_REQUEST,
  POST_CHILD_SETTING_LOTTERY_UPDATE_FAILURE,
  POST_CHILD_SETTING_LOTTERY_UPDATE_REQUEST,
  POST_CHILD_SETTING_LOTTERY_UPDATE_SUCCESS,
  POST_CHILD_SETTING_UPDATE_FAILURE,
  POST_CHILD_SETTING_UPDATE_REQUEST,
  POST_CHILD_SETTING_UPDATE_SUCCESS,
  POST_CHILD_SUCCESS,
  POST_CHILD_UPDATE_FAILURE,
  POST_CHILD_UPDATE_REQUEST,
  POST_CHILD_UPDATE_SUCCESS,
  POST_CHILD_RESET_PASSWORD_REQUEST,
  POST_CHILD_RESET_PASSWORD_SUCCESS,
  POST_CHILD_RESET_PASSWORD_FAILURE,
} from "./constants";

const postChildAction = createAsyncAction(
  POST_CHILD_REQUEST,
  POST_CHILD_SUCCESS,
  POST_CHILD_FAILURE
)<any, AxiosResponse<{}>, AxiosError<IAPIResponse>>();

const getChildDetailAction = createAsyncAction(
  GET_CHILD_DETAIL_REQUEST,
  GET_CHILD_DETAIL_SUCCESS,
  GET_CHILD_DETAIL_FAILURE
)<
  any,
  AxiosResponse<
    IPagination<
      IV2GetAgentChild &
      IV2GetAgentChildSettingUpdate &
      IV2PostAgentChildSettingLotteryUpdate
    >
  >,
  AxiosError<IAPIResponse>
>();

const getChildBetAction = createAsyncAction(
  GET_CHILD_BET_REQUEST,
  GET_CHILD_BET_SUCCESS,
  GET_CHILD_BET_FAILURE
)<
  any,
  AxiosResponse<IPagination<IV2RespAgentChildBet>>,
  AxiosError<IAPIResponse>
>();

const postChildSettingLotteryUpdateAction = createAsyncAction(
  POST_CHILD_SETTING_LOTTERY_UPDATE_REQUEST,
  POST_CHILD_SETTING_LOTTERY_UPDATE_SUCCESS,
  POST_CHILD_SETTING_LOTTERY_UPDATE_FAILURE
)<any, AxiosResponse<{}>, AxiosError<IAPIResponse>>();

const postChildUpdateAction = createAsyncAction(
  POST_CHILD_UPDATE_REQUEST,
  POST_CHILD_UPDATE_SUCCESS,
  POST_CHILD_UPDATE_FAILURE
)<any, AxiosResponse<{}>, AxiosError<IAPIResponse>>();

const postChildResetPasswordAction = createAsyncAction(
  POST_CHILD_RESET_PASSWORD_REQUEST,
  POST_CHILD_RESET_PASSWORD_SUCCESS,
  POST_CHILD_RESET_PASSWORD_FAILURE
)<any, AxiosResponse<{}>, AxiosError<IAPIResponse>>();


const postChildSettingUpdateAction = createAsyncAction(
  POST_CHILD_SETTING_UPDATE_REQUEST,
  POST_CHILD_SETTING_UPDATE_SUCCESS,
  POST_CHILD_SETTING_UPDATE_FAILURE,
)<any, AxiosResponse<{}>, AxiosError<IAPIResponse>>();

const postChildCreditUpdateAction = createAsyncAction(
  POST_CHILD_CREDIT_UPDATE_REQUEST,
  POST_CHILD_CREDIT_UPDATE_SUCCESS,
  POST_CHILD_CREDIT_UPDATE_FAILURE,
)<any, AxiosResponse<{}>, AxiosError<IAPIResponse>>();

export {
  postChildAction,
  getChildDetailAction,
  postChildSettingLotteryUpdateAction,
  postChildUpdateAction,
  postChildSettingUpdateAction,
  postChildCreditUpdateAction,
  postChildResetPasswordAction,
  getChildBetAction
};
