export const POST_OWNER_UPDATE_REQUEST = "POST_OWNER_UPDATE_REQUEST"
export const POST_OWNER_UPDATE_SUCCESS = "POST_OWNER_UPDATE_SUCCESS"
export const POST_OWNER_UPDATE_FAILURE = "POST_OWNER_UPDATE_FAILURE"

export const POST_OWNER_SETTING_REQUEST = "POST_OWNER_SETTING_REQUEST"
export const POST_OWNER_SETTING_SUCCESS = "POST_OWNER_SETTING_SUCCESS"
export const POST_OWNER_SETTING_FAILURE = "POST_OWNER_SETTING_FAILURE"

export const POST_OWNER_SETTING_LOTTERY_UPDATE_REQUEST = "POST_OWNER_SETTING_LOTTERY_UPDATE_REQUEST"
export const POST_OWNER_SETTING_LOTTERY_UPDATE_SUCCESS = "POST_OWNER_SETTING_LOTTERY_UPDATE_SUCCESS"
export const POST_OWNER_SETTING_LOTTERY_UPDATE_FAILURE = "POST_OWNER_SETTING_LOTTERY_UPDATE_FAILURE"

export const ownerUpdateState: ReducerState<{}> = {
    isFetching: false,
    code: 0,
    data: {},
    error: ""
}

export const ownerSettingState: ReducerState<{}> = {
    isFetching: false,
    code: 0,
    data: {},
    error: ""
}

export const ownerSettingLotteryUpdateState: ReducerState<{}> = {
    isFetching: false,
    code: 0,
    data: {},
    error: ""
}