import { CODE_TO_NAME, CODE_TO_TYPENAME_LOWER_CASE} from "./variables";

export const responseMessage = (
  lotteryName?: TLottoType,
  betType?: TBetResultLowerCase
): { [type in number]: string } => {
  const lottery_name = lotteryName ? CODE_TO_NAME[lotteryName] : "";
  const bet_type = betType ? CODE_TO_TYPENAME_LOWER_CASE[betType] : "";
  return {
    0: "กรุณาลองใหม่อีกครั้ง",
    200: "สำเร็จ",
    400: "คำร้องขอไม่สมบูรณ์",
    401: "ปฏิเสธสิทธิการเข้าใช้",
    403: "การเข้าถึง ถึงขีดจำกัด",
    404: "ไม่พบข้อมูล",
    408: "หมดเวลา กรุณาลองใหม่อีกครั้ง",
    500: "เกิดข้อผิดพลาดบน Server",
    503: "ไม่สามารถติดต่อกับผู้ให้บริการได้",
    200000: "สำเร็จ",
    400000: "คำร้องขอไม่สมบูรณ์",
    400001: "พารามิเตอร์ไม่สมบูรณ์",
    400002: "กรุณาเปิดใช้งาน 2FA ",
    400003: "บัญชีของคุณได้ทำการเปิดใช้งาน 2FA แล้ว",
    400100: "ไม่สามารถสร้างผู้ใช้ได้เนื่องจากมีผู้ใช้อยู่ในระบบแล้ว",
    400101: "จำนวนคำขอถึงขีดจำกัดแล้วโปรดลองใหม่อีกครั้ง",
    400102: "เครดิตไม่พอ",
    400103: "ไม่สามารถใช้หมายเลขโทรศัพท์ที่ซ้ำกันได้",
    400104: "หมดเวลายืนยัน OTP หรือ OTP ไม่ถูกต้อง โปรดขอ OTP ใหม่อีกครั้ง"/* "กรอก OTP ผิด 3 ครั้ง โปรดลองใหม่อีกครั้งหลังจากนี้ 10 นาที" */,
    400105: "ไม่สามารถอัปเดตเวลาเปิดรับแทงหวยได้เนื่องจากเวลาเริ่มต้นเปิดรับแทงไม่เป็นอนาคต",
    400106: "ไม่สามารถใช้หมายเลขบัญชีธนาคารซ้ำกันในระบบได้",
    400107: "ไม่สามารถอัปเดตบัญชีระบบที่ใช้งานอยู่ได้",
    400108: `คุณทำรายการแจ้งจ่ายได้เพียง 1 ครั้ง`, 
    400109: "ถอนได้ 100,000 ต่อธุรกรรมเท่านั้น",
    400110: "ไม่สามารถจองธุรกรรมที่ถูกจองไว้แล้วได้",
    400111: "ไม่สามารถยกเลิกการจองธุรกรรมได้",
    400112: "เวลาเริ่มต้นต้องน้อยกว่าเวลาสิ้นสุด",
    400113: "ผลหวยซ้ำ",
    400114: "รหัสผ่านไม่ถูกต้อง",
    400115: "ไม่สามารถแก้ไขข้อมูลได้เมื่อสถานะเป็นอัตโนมัติ",
    400116: "เลขบัญชีธนาคารซ้ำ",
    400117: "ไม่สามารถอัปเดตระดับอัตราจ่ายนี้ได้",
    400118: "ไม่สามารถฝาก 0 หน่วยและน้อยกว่า 0 หน่วยได้",
    400119: "ไม่มีธุรกรรมการรอถอรเงินในระบบ",
    400120: "ห้ามเดิมพันโดยตั้งใจหาผลประโยชน์จากระบบค่าแนะนำ", 
    400121: "รหัสผู้ใช้หมดอายุการใช้งาน",
    400122: "กระเป๋าเงินแนะนำสมาชิกสามารถถอนได้เมื่อกระเป๋าเงินมีมากกว่า 300 หรือเท่ากับ 300",
    400123: "เกมยี่กีรอบดังกล่าวปิดแล้ว",
    400132: "ไม่สามารถเดิมพันได้ เนื่องจากมีเลขที่ถูกปิดรับแทงอยู่ โปรดตรวจสอบรายการเลขอั้นอีกครั้ง",
    400133: "ไม่สามารถชำระกับยอดที่เคลียร์แล้ว",
    400135: "ไม่สามารถใช้ชื่อผู้ใช้ซ้ำกันในระบบได้",
    400137: "OTP ถึงขีดจำกัด โปรดลองอีกครั้งหลังจาก 10 นาที",
    400139: "ไม่สามารถเดิมพันได้เนื่องจากหลุมของเลขเต็มแล้ว",
    400147: "การแบ่งถือสู้ต้องเท่ากับหรือมากกว่าค่าต่ำสุดที่หัวกำหนดมาให้",
    400148: `การตั้งค่าอัตราจ่าย${bet_type}ใน${lottery_name}ต้องน้อยกว่าหรือเท่ากับอัตราจ่ายที่หัวกำหนดให้`,
    400149: "การตั้งค่าแทงสูงสุดในตั้งค่าภาพรวมต้องน้อยกว่าหรือเท่ากับเมื่อเทียบกับเงื่อนไขของหัวสมาชิก",
    400150: "การตั้งค่าแทงต่ำสุดในตั้งค่าภาพรวมต้องมากกว่าหรือเท่ากับเมื่อเทียบกับเงื่อนไขของหัวสมาชิก",
    400151: `การตั้งอัตราจ่าย${bet_type}ใน${lottery_name} ผิดรูปแบบ`,
    400200: "ห้ามเปลี่ยนคนแนะนำสมาชิกเป็นตัวเองและคนที่ตัวเองชวน",
    400210: "จำนวนครั้งการเปลี่ยนคนแนะนำสมาชิกถึงขีดสูงสุดแล้ว",
    400211: "จำนวนครั้งการเปลี่ยนชื่อบัญชีธนาคารถึงขีดสูงสุดแล้ว",
    400212: "จำนวนครั้งการเปลี่ยนเลขบัญชีธนาคารถึงขีดสูงสุดแล้ว",
    400220: "สามารถเปลี่ยนเลขบัญชีธนาคารได้มากที่สุด 2 ตัวเลขต่อครั้ง",
    400221: "เครดิตไม่เพียงพอ",
    400222: "รหัสผ่านเดิม กรุณาเปลี่ยนรหัสผ่านใหม่",
    400226: "ไม่สามารถดำเนินการได้เนื่องจากหวยปิดรับแทงแล้ว",
    401000: "ไม่ผ่านการอนุมัติ",
    401001: "ปฏิเสธสิทธิการเข้าใช้",
    401002: "ต้องการยืนยันตัวตน",
    401110: "รหัสผิด",
    401111: "OTP ไม่ถูกต้อง",
    401112: "หมายเลขเบอร์โทรศัพท์ผิดรูปแบบต้องมี 10 หลักและเริ่มต้นด้วย 0",
    401113: "uuid ผิดรูปแบบ",
    401114: "token ไม่สามารถใช้งานได้",
    401115: /* "format ของ username agent ไม่ถูกต้อง" */ "รูปแบบของชื่อผู้ใช้ไม่ถูกต้อง",
    401116: "รูปแบบ password ไม่ถูกต้อง ต้องไม่น้อยกว่า 6 หลักและไม่เกิน 128 หลัก ห้ามมีช่องว่าง",
    401117: "ตั้งค่าการอนุญาตการใช้งานผิดพลาด ต้องเป็นค่าตรรกะศาสตร์เท่านั้น",
    401118: "สกุลเงินดังกล่าวไม่มีอยู่ในระบบ",
    401119: "ค่าเดิมพันสูงสุดไม่ตรงตามเงื่อนไข โปรดกรอกค่าเดิมพันสูงสุดให้มากกว่า Min bet และไม่มากกว่าวงเงินเครดิต",
    401120: "ค่าเดิมพันต่ำสุดไม่ตรงตามเงื่อนไข โปรดกรอกค่าเดิมพันต่ำสุดให้น้อยกว่า Max bet และไม่น้อยกว่าหรือเท่ากับ 0",
    401121: "เปอร์เซนต์ส่วนลดผิดรูปแบบ",
    401122: "ชื่อลอตเตอรี่ไม่ครบ",
    401123: `สถานะของลอตเตอรี่${lottery_name} ไม่ถูกต้อง`,
    401124: "ส่วนแบ่งหัวหรือถือสู้ไม่ถูกต้องตามรูปแบบของเปอร์เซนต์",
    401125: "ค่าบังคับสู้ไม่ถูกต้องตามรูปแบบของเปอร์เซนต์",
    401126: "การตั้งค่ารับของไม่ถูกต้องตามรูปแบบของตรรกะศาสตร์",
    401127: `การตั้งค่าประเภทการแทงของ${lottery_name}ไม่ครบ`,
    401128: `การตั้งค่าแทงสูงสุด${bet_type}ผิดรูปแบบใน${lottery_name}`,
    401129: `การตั้งค่าแทงสูงสุด${bet_type}ใน${lottery_name}ต้องเท่ากับหรือน้อยกว่าการตั้งแทงสูงสุดของค่าเริ่มต้น`,
    401130: `การตั้งค่าแทงสูงสุด${bet_type}ใน${lottery_name}ต้องมากกว่าการตั้งค่าแทงต่ำสุด`,
    401131: `การตั้งค่าแทงต่ำสุด${bet_type}ผิดรูปแบบใน${lottery_name}`,
    401132: `การตั้งค่าแทงต่ำสุด${bet_type}ใน${lottery_name}ต้องน้อยกว่าหรือเท่ากับแทงสูงสุดในประเภทนี้`,
    401133: `การตั้งค่าส่วนลด${bet_type}ผิดรูปแบบใน${lottery_name}`,
    401134: "การตั้งค่าส่วนลดต้องน้อยกว่าหรือเท่ากับส่วนลดตั้งต้น",
    401135: "จำเป็นต้องปิดบิลที่ค้างก่อน ทำรายการใหม่",
    401136: "ธุรกรรมดังกล่าวต้องมีสถานะเป็นโอนยอด",
    401137: "ธุรกรรมจะเสร็จสมบูรณ์เมื่อเครดิตกู้น้อยกว่าหรือเท่ากับวงเงินสินเชื่อ",
    401138: 'การเดิมพันเกินขีดจำกัดสูงสุด',
    401140: "ไม่สามารถตั้งค่าเกินขีดจำกัดที่ตั้งไว้",
    401141: "การเดิมพันน้อยกว่าขีดจำกัดต่ำสุด",
    401143: "ตั้งเดิมพันสูงสุดมากกว่าหัวกำหนดไม่ได้",
    401144: `การตั้งค่าแทงต่ำสุด ${bet_type} ใน ${lottery_name} ต้องมากกว่าหรือเท่ากับเมื่อเทียบกับเงื่อนไขของหัวสมาชิก `,
    401145: "การตั้งค่าคอมมิชชั่นต้องน้อยกว่าหรือเท่ากับค่าคอมมิชชั่นของหัวสมาชิก",
    401146: 'ไม่สามารถตั้งถือสู้น้อยกว่าบังคับสู้ได้',
    401147: 'ไม่สามารถตั้งถือสู้น้อยกว่าบังคับสู้ได้',
    401149: 'ไม่สามารถตั้งค่าแทงสูงสุดมากกว่าค่าแทงสูงสุดของหัวสมาชิกได้',
    401160: "จ่ายเกินยอดที่มีอยู่จริง",
    401162: "คุณจะแจ้งจ่ายได้เมื่อผลหวยทุกตัวในบิลนี้ออกผลแล้ว",
    403000: "การเข้าถึง ถึงขีดจำกัด",
    404000: "ไม่พบข้อมูล",
    404001: "ไม่พบบัญชีผู้ใช้",
    404002: "ไม่พบการขอทำธุรกรรมกระเป๋าเงิน",
    404003: "ไม่พบรายการธุรกรรมการเงิน",
    404004: "ไม่พบบัญชีลูกค้า",
    404005: "ไม่พบบัญชีระบบ",
    404006: "ไม่พบการเปิดทำธุรกรรมการเงิน",
    404007: "ไม่พบการร้องขอยืนยัน OTP",
    404008: "ไม่พบอัตราจ่าย โปรดลองใหม่อีกครั้ง",
    404009: "ไม่พบข้อมูลการยิงเลขยี่กี",
    404010: "ไม่พบกระเป๋าเงิน",
    404011: "ไม่พบประเภทอัตราจ่าย",
    404012: "ไม่พบชุดโพย",
    404013: "ไม่พบโพย",
    404014: "ไม่พบสิทธิการเปลี่ยนรหัส",
    404015: "ไม่พบผู้แนะนำสมัครสมาชิก",
    404016: "ไม่พบข้อมูลยี่กีในรอบดังกล่าว",
    404017: "หัวสมาชิกถูกระงับสิทธิ",
    500000: "เกิดข้อผิดพลาดบน Server",
    500001: "เกิดข้อผิดพลาดการอัปเดต transaction",
    500002: "เกิดข้อผิดพลาดการอัปเดต wallet transaction",
    500003: "เกิดข้อผิดพลาดการอัปเดต wallet",
    500004: "เกิดข้อผิดพลาดการอัปเดตการตั้งค่า affilate",
    500005: "เกิดข้อผิดพลาดการอัปเดตชุดโพย",
    500006: "เกิดข้อผิดพลาดการอัปเดตโพย",
    500007: "เกิดข้อผิดพลาดการอัปเดทบัญชีระบบ",
    500008: "เกิดข้อผิดพลาดในการสลับบัญชีระบบที่ใช้งานปัจจุบันเป็นบัญชีใหม่",
    500009: "เกิดข้อผิดพลาดในการอัปเดตบัญชีระบบที่ใช้งานอยู่",
    500010: "เกิดข้อผิดพลาดในการอัปเดตกระเป๋าเงินผู้ใช้",
    500011: "เกิดข้อผิดพลาดในการอัปเดตกระเป๋าเงินระบบ",
    500012: "เกิดข้อผิดพลาดในการอัปเดตกระเป๋าเงินระบบแนะนำสมาชิก",
    500013: "เกิดข้อผิดพลาดในการอัปเดตรหัสผ่าน",
    500100: "เกิดข้อผิดพลาดการสร้าง Pre Wallet",
    500101: "เกิดข้อผิดพลาดการสร้างรายการเดิมพัน",
    500102: "เกิดข้อผิดพลาดการยิงเลขยี่กี",
    500103: "เกิดข้อผิดพลาดการสร้างชุดโพย",
    500104: "เกิดข้อผิดพลาดการสร้างโพย",
    500105: "เกิดข้อผิดพลาดการสร้างบัญชีระบบ",
    500106: "เกิดข้อผิดพลาดการสร้างรายการธุรกรรม",
    500107: "เกิดข้อผิดพลาดการสร้างสิทธิในการแก้ไขรหัสผ่าน",
    500200: "เกิดข้อผิดพลาดของข้อมูลภายในไม่สามารถขอร้องขอได้",
    500300: "เกิดข้อผิดพลาดในการลบโพย",
    500301: "เกิดข้อผิดพลาดในการลบบัญชีระบบ",
    500302: "เกิดข้อผิดพลาดในการลบสิทธิเปลี่ยนรหัส",
    500303: "เกิดข้อผิดพลาดในการลบผู้ใช้",
    500900: "ไม่มีบัญชีระบบที่ใช้งานอยู่ โปรดติดต่อผู้ดูแลระบบ",
    503000: "ไม่สามารถติดต่อกับผู้ให้บริการได้",
    503001: "ไม่สามารถติดต่อกับผู้ให้บริการ OTP ได้",
  };
};

export const responseCode = {
  OK: 200000,
  UNAUTHORIZED: 401001,
  NOT_FOUND: 404,
  BAD_REQUEST: 400,
  REQUEST_TIMEOUT: 408,
  GONE: 410,
  INTERNAL_SERVER_ERROR: 500,
  UNDEFINED: 0,
  PASSWORD_EXPIRE: 400121,
  ALREADY_REGISTER2FA: 400003,
};
