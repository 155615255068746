import React, { FC } from "react";
import { isEmpty } from "lodash";

const defaultProps: IResponsiveIcon = {
  icon: "",
  alt: "",
  className: "",
};

const ResponsiveIcon: FC<IResponsiveIcon> = ({ icon, alt, className }) => {
  if (typeof icon === "string") {
    if (!isEmpty(icon)) {
      if (icon === "no-icon") {
        return <></>;
      } else {
        return <img alt={alt} className={className} src={icon} />;
      }
    } else {
      return (
        <div
          className="d-flex flex-row m2-r"
          style={{
            backgroundColor: "#000",
            borderRadius: "50%",
            width: "50px",
            height: "50px",
          }}
        />
      );
    }
  } else if (typeof icon === "object") {
    return (
      <img
        alt={alt}
        className={className}
        src={icon.x1}
        srcSet={`${icon.x2} 2x, ${icon.x3} 3x`}
      />
    );
  }
  return <></>;
};

ResponsiveIcon.defaultProps = defaultProps;

export default ResponsiveIcon;
