import React, { Component } from "react"
import "./menu.style.scss"
import { get, isEmpty, noop } from "lodash"
import number from "utils/number"
import logo from "assets/images/logo/yak.png"
import arrow from "assets/images/global/icon/arrow.png"
import { RouteComponentProps } from "react-router-dom"
import { Dropdown } from "components/Dropdown"

import routes from "constants/routes"
import { meDataInitialValue, PERMISSION } from "constants/variables"

const defaultProps: IMenuProps & IMenuActionProps = {
  menuActive: "main",

  mainData: {
    username: "",
    permission: "MEMBER",
    line: "",
    status: "",
    childCount: 0,
    currency: "THB",
    betResultTotal: 0,
    betWaitTotal: 0,
    maxBet: 0,
    minBet: 0,
    childCreditTotal: 0,
    credit: 0,
    creditLimit: "",
  },
  meData: meDataInitialValue,

  onChangeMenu() {
    noop()
  },
  logout() {
    noop()
  },
}


const listMenu: IListMenu[] = [
  {
    name: "dashboard",
    label: "ภาพรวม",
    child: [],
  },
  {
    name: "bet",
    label: "การเดิมพัน",
    child: [
      {
        name: "bet-report",
        label: "คาดคะเนได้/เสีย",
        // isList: true,
      },
      {
        name: "bet",
        label: "แทงหวย",
      },
      {
        name: "lotto-result",
        label: "ผลหวย",
        // isList: true,
      },
      {
        name: "bet-me-history",
        label: "รายการเดิมพัน",
        // disable: false,
      },
      {
        name: "bet-waiting-result",
        label: "รอผลเดิมพัน"
        // isList: true,
      },
      {
        name: "bet-summary-result",
        label: "แพ้-ชนะ สุทธิ",
        // isList: true,
      },
      // {
      //   name: "bet-summary-history",
      //   label: "สรุปการเดิมพัน (เก่า)",
      //   // disable: false,
      // },
      {
        name: "bet-child-history",
        label: "รายการเดิมพันสมาชิก",
        // disable: false,
      },
      {
        name: "numbers-holes",
        label: "หลุมเลข",
        // disable: false,
      },
      {
        name: "numbers-ban",
        label: "รายการเลขอั้น",
        // disable: false,
      },
      {
        name: "bet-lottery-group",
        label: "รายการแทง",
      },
    ],
  },
  // {
  //   name: "finance",
  //   label: "การเงิน",
  //   child: [{
  //     name: "finance/host",
  //     label: "รับ/จ่าย หัวสมาชิก",
  //   }, {
  //     name: "finance/summary-child",
  //     label: "รับ/จ่าย ลูกสมาชิก",
  //   }, {
  //     name: "finance/summary-customer",
  //     label: "รับ/จ่าย ลูกค้า",
  //   }],
  // },
  {
    name: "members",
    label: "สมาชิก",
    child: [
      {
        name: "members-list",
        label: "รายการสมาชิก",
      },
      {
        name: "customer-list",
        label: "รายการลูกค้า",
      },
    ],
  },
  {
    name: "user/detail",
    label: "ตั้งค่า",
    child: [],
  },
]

class Menu extends Component<
  IMenuProps & IMenuActionProps & RouteComponentProps
> {
  static defaultProps = defaultProps;
  state: INewMenuState = {
    loginAsAgent: false,
    date: new Date().toLocaleString('th'),
    countDown: setInterval(() => {
      this.setState({ date: new Date().toLocaleString('th') })
    }, 1000)
  };



  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    clearInterval(this.state.countDown);
  }

  handleRouteClick = () => this.props.history.push(routes.userDetail.path);

  render() {
    return (
      <div className="menu-navbar">
        <div className="tap-logo d-flex flex-row justify-content-between">
          <div
            className="d-flex flex-row align-items-center clickLogo"
            onClick={() => this.props.history.push(routes.Dashboard.path)}
          >
            <img src={logo} className="logoMenu" alt="log thb" />
            <div>
              <h5 className="text-logo">Thailand Bet Agent</h5>
              <div className='date'>{this.state.date}</div>
            </div>
          </div>

          <div className="d-flex flex-row">
            <div
              className="body-4 mr-1 d-flex flex-row align-items-center clickLogo"
              onClick={() => this.handleRouteClick()}
            >
              Username:
            </div>
            <h5
              className="text-logo clickLogo"
              style={{ marginRight: 44 }}
              onClick={() => this.handleRouteClick()}
            >{` ${this.props.meData.username}`}</h5>
            <div
              className="body-4 mr-1 d-flex flex-row align-items-center clickLogo"
              onClick={() => this.handleRouteClick()}
            >
              สิทธิ:
            </div>
            <h5
              className="text-logo clickLogo"
              style={{ marginRight: 44 }}
              onClick={() => this.handleRouteClick()}
            >
              {PERMISSION[this.props.meData.permission as TPermissionStatus]}
            </h5>
            <div
              className="body-4 mr-1 d-flex flex-row align-items-center clickLogo"
              onClick={() => this.handleRouteClick()}
            >
              เครดิต:
            </div>
            <h5
              className="text-logo clickLogo"
              onClick={() => this.handleRouteClick()}
            >
              {number.decimalsFormatComma(String(this.props.meData.credit))}
            </h5>
          </div>
        </div>
        <div className="tap-menu">
          {listMenu.map((item, index) => (
            <div key={"box-menu" + index} className="box-menu">
              {!isEmpty(item.child) ? (
                <Dropdown
                  menuStyles={{ width: 250 }}
                  placeholder={
                    <h5 className="menu-placeholder-text">{item.label}</h5>
                  }

                  options={
                    this.props.meData.permission === "AGENT"
                      ? item.child.map((item) => {
                        if (listMenu[2].child[0].name === "members-list") {
                          listMenu[2].child.splice(0, 1);
                        }
                        if (listMenu[1].child[0].name === "bet-report") {
                          listMenu[1].child.splice(0, 1);
                        }
                        return {
                          value: item.name,
                          label: get(item, "label", ""),
                          disabled: item.disable ? true : false,
                        };
                      })
                      : this.props.meData.permission === "SUPER_AGENT" || this.props.meData.permission === "OWNER"  ? 
                      item.child.map((item) => {
                        if (listMenu[1].child[1].name === "bet") {
                          listMenu[1].child.splice(1, 1);
                        }
                        return {
                          value: item.name,
                          label: get(item, "label", ""),
                          disabled: item.disable ? true : false,
                        };
                      })
                      :item.child.map((item) => {
                        return {
                          value: item.name,
                          label: get(item, "label", ""),
                          disabled: item.disable ? true : false,
                        };
                      })
                  }
                  handleOnChange={(target) => {
                    this.props.onChangeMenu!(target.value);
                    this.props.history.push(`/${get(target, "value", "")}`);
                  }}
                  iconClose={arrow}
                />
              ) : (
                <h5
                  className="menu"
                  onClick={() => {
                    if (item.name !== "404") {
                      this.props.history.push(`/${get(item, "name", "")}`);
                    }
                  }}
                >
                  {item.label}
                </h5>
              )}
            </div>
          ))}
          <div className="box-menu ml-auto">
            <h5
              className="menu"
              onClick={() => {
                this.props.logout()
              }}
            >
              ออกจากระบบ
            </h5>
          </div>
        </div>
      </div>
    )
  }
}

export default Menu
