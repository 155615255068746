import React, { ChangeEvent, FC, useState } from "react";
import colors from "constants/colors";
import "./input.style.scss";
import { LoadingCircle } from "components/LoadingCircle";

const defaultProps: IInput = {
  id: "",
  placeholder: "",
  label: "",
  type: "text",
  value: "",
  name: "",
  onPaste() { },
  onChange() { },
  onBlur() { },
  onFocus() { },
  disabled: false,
  backgroundColor: colors.SECONDARY_BG,
  backgroundActiveColor: colors.PRIMARY_BG,
  error: false,
  errorMessage: "error",
  inputButton: false,
  ref: { current: null },
  borderRadius: "0px",
  borderWidth: "0px",
  borderStyle: "none",
  borderColor: "transparent",
  setPadding: "",
  inputClassName: "",
  readOnly: false,
  renderError: true,
  bgContainer: '',
  disableInputBorder: false,
  hidePlaceholder: false,
  LoadingButton: false,
  loadingStyles: ''
};

const Input: FC<IInput> = React.forwardRef((props, ref: any) => {
  const {
    id,
    placeholder,
    name,
    type,
    onPaste,
    onChange,
    onBlur,
    onFocus,
    value,
    disabled,
    backgroundColor,
    backgroundActiveColor,
    error,
    errorMessage,
    inputButton,
    children,
    borderRadius,
    borderWidth,
    borderStyle,
    borderColor,
    setPadding,
    inputClassName,
    readOnly,
    renderError,
    bgContainer,
    disableInputBorder,
    hidePlaceholder,
    LoadingButton,
    loadingStyles
  } = props;

  const [isFocus, setIsFocus] = useState(false);
  const [dgColor, setBgColor] = useState(backgroundColor);

  if (ref !== null) {
    /* ref.current?.focus(); */
  }

  const handleOnBlur = (event: ChangeEvent<HTMLInputElement>) => {
    if (value === "") {
      setIsFocus(false);
      setBgColor(backgroundColor);
    }
    onBlur!(event);
  };

  const handleFocus = (event: ChangeEvent<HTMLInputElement>) => {
    setIsFocus(true);
    setBgColor(backgroundActiveColor);
    onFocus!(event)
  };

  return (
    <div
      className={`${inputButton ? "inputButton-container" : "input-container"}`}
      style={{
        borderRadius: `${borderRadius}`,
        borderWidth: `${borderWidth}`,
        borderStyle: `${borderStyle}`,
        borderColor: `${borderColor}`,
        width: "100%",
        background: `${bgContainer}`
      }}
    >
      {LoadingButton && <div className={`${loadingStyles}`}><LoadingCircle /></div> }
      <input
        onPaste={onPaste}
        id={id}
        ref={ref}
        type={type}
        name={name}
        value={value}
        readOnly={readOnly}
        style={
          !disabled
            ? { backgroundColor: dgColor, padding: `${setPadding}` }
            : { backgroundColor: dgColor, padding: `${setPadding}` }
        }
        onChange={onChange}
        disabled={disabled}
        className={`base-input p2
            ${backgroundColor}
            ${error ? "border-error" : "border-active"} ${inputClassName} 
            ${disabled && "input-disabled"} ${!disabled && readOnly && 'input-default-cursor'}
            ${disableInputBorder && 'border-none'}`}
        placeholder={`${isFocus ? hidePlaceholder ? '' : placeholder : placeholder}`}
        onFocus={handleFocus}
        onBlur={handleOnBlur}
        autoComplete="new-password"
      />
      {inputButton && children}
      {renderError && <div className={`text-left error-box`} style={{ marginTop: 6 }}>
        <h6 className="primary-red-text">{error ? errorMessage : "\u00A0"}</h6>
      </div>}
    </div>
  );
});

Input.defaultProps = defaultProps;

export default Input;
