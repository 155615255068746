import React, { FC, useEffect, useState } from "react";
import { ResponsiveIcon } from "components";
import { isEmpty, isNaN, isNil, unionBy } from "lodash";
import colors from "constants/colors";
import "./lottoCard.style.scss";
import { date, number } from "utils";
import getUnixTime from "date-fns/getUnixTime";
import { differenceInSeconds } from "date-fns";
import collectBank from "assets/images/global/bank";

const constants = {
  day: "วัน",
  timeups: "XX:XX:XX",
};

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: ILottoCard = {
  id: "",
  title: "",
  slug: null,
  subtitle: "",
  isCountingdown: false,
  start: "",
  expire: "",
  status: "UNKNOWN",
  isOpen: false,
  openedStatusText: "",
  waitingStatusText: "",
  closedStatusText: "",
  description: "",
  backgroundColor: colors.SECONDARY_BG,
  icon: "",
  noAccessStatusText: "",
  lottoResult: [],
  refreshLotto() {},
};

const LottoCard: FC<ILottoCard & DefaultProps> = (props) => {
  const {
    id,
    slug,
    title,
    subtitle,
    isCountingdown,
    start,
    expire,
    status,
    isOpen,
    description,
    icon,
    noAccessStatusText,
    onClick,
    lottoResult,
    refreshLotto,
  } = props;

  let intervalId: NodeJS.Timeout | null = null;

  const [remain, setRemain] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const clearLocalInterval = () => {
    if (intervalId !== null) {
      clearInterval(intervalId);
    }
  };

  const countingdown = () => {
    clearLocalInterval();
    if (isCountingdown) {
      // const resultTiming = getUnixTime(date.calibratingTime(start!)) - getUnixTime(new Date());
      const resultTiming = checkWaitingTime(start!) ? getUnixTime(new Date()) - getUnixTime(date.calibratingTime(start!)) : getUnixTime(date.calibratingTime(start!)) - getUnixTime(new Date());
      const selectedTime = (resultTiming < 0 && !checkWaitingTime(start!)) ? expire : start;
      const expireTime = slug?.includes("LOTTER_YEGEE") ? expire : selectedTime;
      const expireMoment = date.calibratingTime(expireTime!);
      intervalId = setInterval(() => {
        const differentSecond = differenceInSeconds(expireMoment, new Date());
        const days = Math.floor(differentSecond / 86400);
        const hours = Math.floor((differentSecond / 3600) % 24);
        const minutes = Math.floor((differentSecond / 60) % 60);
        const seconds = Math.floor(differentSecond % 60);
        if (
          (hours! <= 0 && minutes! <= 0 && seconds <= 0) ||
          isNaN(hours!) ||
          isNaN(minutes!) ||
          isNaN(seconds!)
        ) {
          setRemain({
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
          });
          clearLocalInterval();
        } else {
          setRemain({
            days: days!,
            hours: hours!,
            minutes: minutes!,
            seconds: seconds!,
          });
        }
      }, 1000);
    }
  };

  useEffect(() => {
    if (isCountingdown) {
      countingdown();
    }
    return clearLocalInterval;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCountingdown, remain]);

  const handleOnClick = () => {
    if (typeof onClick === "function") {
      onClick();
    }
  };

  const statusText = (): any => {
    if (isCountingdown && isEmpty(lottoResult)) {
      if (status === "NOT_ACCESS") {
        return noAccessStatusText || "";
      }
      if (remain.days > 0) {
        return `${number.padNumber(String(remain.days), 2)} ${constants.day}`;
      }
      if (
        remain.days <= 0 &&
        remain.hours <= 0 &&
        remain.minutes <= 0 &&
        remain.seconds <= 0
      ) {
        return constants.timeups;
      }
      return `${number.padNumber(String(remain.hours), 2)}:${number.padNumber(String(remain.minutes), 2)}:${number.padNumber(String(remain.seconds), 2)}`;
    }
    if (!isEmpty(lottoResult)) {
      return unionBy(lottoResult,'type')?.reduce((result, item, index) => {
          result = result + '<span>' + (isNil(item.number) ? item.type.includes('THREE') ? 'XXX' : item.type.includes('FOUR') ? 'XXXX' : 'XX' : item.number) + '</span>'
          return result.toString()
      }, '' as string)
    }
    return "-";
  };

  const closeLotto = (): 'result' | 'lotto-close' | 'close' | 'open' | 'wait' => {
    const resultTiming = checkWaitingTime(start!) ? getUnixTime(new Date()) - getUnixTime(date.calibratingTime(start!)) : getUnixTime(date.calibratingTime(start!)) - getUnixTime(new Date());
    const selectedTime = (resultTiming < 0 && !checkWaitingTime(start!)) ? expire : start;
    const expireTime = slug?.includes("LOTTER_YEGEE") ? expire : selectedTime;
    const expireMoment = date.calibratingTime(expireTime!);
    const differentSecond = differenceInSeconds(expireMoment, new Date());
    const minutes = Math.floor((differentSecond / 60) % 60);

    if(minutes < 0 && isEmpty(lottoResult) && status ==='OPEN' && slug?.includes("LOTTER_YEGEE")) {
      if (typeof refreshLotto === "function") {
        refreshLotto(slug!)
      }
    }
    if (isOpen) {

      if (status === 'OPEN') {
        if (isCountingdown) {
          if (
            remain.days <= 0 &&
            remain.hours <= 0 &&
            remain.minutes <= 0 &&
            remain.seconds <= 0
          ) { return 'result' }
        }
        // return 'open'
        if (!isEmpty(start) && checkWaitingTime(start!)) {
          return 'wait'
        } else {
          return 'open'
        }
      }
      else if (status === 'WAIT') {
        //[ หวย ] => ปิด **ยังไม่เปิดรับแทง
        return 'lotto-close'
      }
      else if (status === 'CLOSE') {
        //[ หวย ] => แสดงหวยผล
        return 'result'
      }
      else {
        //[ หวย ] => ไม่เปิด
        return 'lotto-close'
      }
    } else {
      //[ หัว ] => ไม่เปิดให้  **ปิดการใช้งาน
      return 'close'
    }
  };

  const checkWaitingTime = (time: string) => {
    return (getUnixTime(date.calibratingTime(time)) > getUnixTime(new Date()))
  }

  return (
    <div
      className={`lotto-card-container ${typeof onClick === "function" ? "" : "disabledLotto"
        } ${closeLotto() === 'wait' ? 'wait' : closeLotto() !== 'open' ? 'closed' : ''}`}
      // style={{ backgroundColor: cardBackgroundColor() }}
      onClick={() => { if (closeLotto() === 'open' || closeLotto() === 'wait') { handleOnClick() } }}
      id={id}
    >
      <div className={`lotto-action-text-wrapper align-items-start  ${closeLotto() === 'wait' ? 'wait' : closeLotto() !== 'open' ? 'closed' : ''}`}>
        <div className="box-icon">
          {/* <div style={{position:"absolute",top:-8}}>{closeLotto()+'---'+isOpen+'---'+status}</div> */}
          <ResponsiveIcon
            icon={icon!}
            alt="flag"
            className={`lotto-action-card-flag ${Object.keys(collectBank).findIndex((item) => ("LOTTER_" + item) === slug) === -1 ? "" : "flag-bank"} ${slug} ${closeLotto()}`}
          />
        </div>
        <div id="detail-lotto" className="d-flex flex-column w-100">
          <h3 className="d-flex flex secondary-text text-nowrap text-title" style={{marginTop : subtitle===''? 12 : 0}}>{title}</h3>
          <h5 className="text-description text-nowrap"> {subtitle} <span className="body-3 text-nowrap text-description"> {description}</span> </h5>
          {isEmpty(lottoResult)
            ? subtitle === '' ? <></> : <h2 className={`d-flex flex text-nowrap text-status ${closeLotto() === 'wait' ? "tertiary-text" : "secondary-green-text"}`} >{statusText()}</h2>
            :  <h2 className="d-flex flex secondary-green-text text-nowrap justify-content-between text-status" dangerouslySetInnerHTML={{ __html: statusText() }} />
          }
        </div>
      </div>
      {((closeLotto() !== 'open') && (closeLotto() !== 'result') && (closeLotto() !== 'wait')) && <div className={`lotto-card-closed`}>
        <div className="body">
          <h2 className="text-center text-closeLotto">{closeLotto() === 'close' ? 'ปิดการใช้งาน' : 'ยังไม่เปิดรับแทง'}</h2>
          <h5 className="text-center text-bold text-closeLotto-description">{closeLotto() === 'close' ? 'หากต้องการใช้งานโปรดติดต่อหัวสมาชิก' : 'หากมีข้อสงสัยสอบถามหัวสมาชิก'}</h5>
        </div>
      </div>}
    </div>
  );
};

LottoCard.defaultProps = defaultProps;

export default LottoCard;
