import { Formik, FormikProps } from "formik";
import HomeResetPasswordComponents from "./components";
import React, { Component } from "react";
import initialResetPassword from "./model/initialValues";
import schemeResetPassword from "./model/scheme";
import { get, noop, trim } from "lodash";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ErrorModal, ConfirmModal } from "components";
import { responseCode, responseMessage } from "constants/response";
import { EUserStatus } from "utils/enum";

const ResetPassword = HomeResetPasswordComponents.ResetPassword;

type DefaultProps = Readonly<typeof defaultProps>;
const defaultProps: IResetPasswordContainerProps &
  IResetPasswordContainerActionProps = {
  loginInitialPassword() {
    noop();
  },
  loginPassword() {
    noop();
  },
  onChangeIsLoaddingAction() {
    noop();
  },
  loginCode: 0,
  loginError: "",
  loginIsFetching: false,
  userStatus: "UNKNOWN",
  qrcode: "",
  secret: "",
  verify: false,
};

class HomeResetPasswordContainer extends Component<
  IResetPasswordContainerProps &
    IResetPasswordContainerActionProps &
    DefaultProps &
    RouteComponentProps<{}, {}, IRouteLogin>
> {
  static defaultProps = defaultProps;

  state: IResetPasswordContainerState = {
    status: "UNKNOWN",
  };

  componentDidMount() {
    const { history, location, onChangeIsLoaddingAction } = this.props;
    onChangeIsLoaddingAction(false);
    if (this.props.userStatus === EUserStatus.PASSWORD_EXPIRED) {
      this.setState({ status: this.props.userStatus }, () => {
        if (
          this.state.status !== EUserStatus.PASSWORD_EXPIRED ||
          this.props.history.action === "POP"
        ) {
          history.push("/", { from: location });
        }
      });
    } else if (this.props.userStatus === EUserStatus.PRE_REGISTERED) {
      this.setState({ status: this.props.userStatus }, () => {
        if (
          this.state.status !== EUserStatus.PRE_REGISTERED ||
          this.props.history.action === "POP"
        ) {
          history.push("/", { from: location });
        }
      });
    } else {
      history.push("/", { from: location });
    }
  }

  componentDidUpdate(prevProps: IResetPasswordContainerProps) {
    if (
      prevProps.loginIsFetching !== this.props.loginIsFetching &&
      !this.props.loginIsFetching
    ) {
      if (
        this.props.loginCode !== responseCode.OK &&
        this.props.loginError !== "" &&
        this.props.loginCode !== responseCode.PASSWORD_EXPIRE
      ) {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            this.props.loginCode,
            responseMessage()[0]
          ),
        });
      }
    }
  }

  onSubmitPreRegistered = (values: IResetPasswordForm) => {
    const { history, location } = this.props;

    const loginInitialPasswordData: IResetPasswordForm = {
      password: trim(values.password),
      confirmPassword: trim(values.confirmPassword),
    };

    ConfirmModal.show({
      action: () => {
        this.props.loginInitialPassword(
          loginInitialPasswordData,
          history,
          location
        );
      },
      cancelAction: () => {
        ConfirmModal.hide();
      },
    });
  };

  onSubmitPasswordExpired = (values: IResetPasswordForm) => {
    const { history, location } = this.props;

    const loginPasswordData: IResetPasswordForm = {
      password: trim(values.password),
      confirmPassword: trim(values.confirmPassword),
    };

    ConfirmModal.show({
      action: () => {
        this.props.loginPassword(loginPasswordData, history, location);
      },
      cancelAction: () => {
        ConfirmModal.hide();
      },
    });
  };

  ResetPasswordFormik = () => {
    const ResetPasswordForm = (formProps: FormikProps<IResetPasswordForm>) => {
      return <ResetPassword {...formProps} {...this.state} />;
    };
    return (
      <Formik
        initialValues={initialResetPassword}
        validationSchema={schemeResetPassword}
        enableReinitialize
        onSubmit={
          this.props.userStatus === EUserStatus.PRE_REGISTERED
            ? this.onSubmitPreRegistered
            : this.onSubmitPasswordExpired
        }
      >
        {ResetPasswordForm}
      </Formik>
    );
  };
  render() {
    const ResetPasswordFormik = this.ResetPasswordFormik;
    return (
      <div>
        <ResetPasswordFormik />
      </div>
    );
  }
}

export default withRouter(HomeResetPasswordContainer);
