import React, { Component } from "react";
import { Breadcrumb, Input, Panginate } from "components";
import { isEmpty, map, noop } from "lodash";
import number from "utils/number";
import { responseCode } from "constants/response";
import errorModal from "components/Modal/components/Error/Error.component";
import { format } from "date-fns";
import { th } from "date-fns/locale";
import { AGENT_FINANCE_TYPE } from "constants/variables";
import { RouteComponentProps } from "react-router";
import "./transactionList.style.scss";

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: ITransactionListsContainerProps &
  ITransactionListsContainerAction = {
  TransactionLists: [{}],
  getTransactionLists() {
    noop();
  },
  cancelTransactionLists() {
    noop();
  },
  getTransactionListsCode: 0,
  getTransactionListsError: "",
  getTransactionListsIsFetching: false,
};

const constants = {
  TransactionListTitle: "บัญชีการเงิน",
  TransactionListSubtitle: "บัญชี",
  InputSearchPlaceholder: "ค้นหารหัสผู้ใช้ / เบอร์ติดต่อ",
  dataNotFound: "ไม่พบข้อมูล...",
};

const mapTransactionListTableName: {
  [type in IResponseAgentFinList as string]: string;
} = {
  status: "กิจกรรม",
  updatedAt: "วันที่เดินรายการ",
  value: "ยอด",
  remark: "หมายเหตุ",
};

class TransactionListsContainer extends Component<
  DefaultProps & RouteComponentProps,
  ITransactionListsContainerState
> {
  static defaultProps = defaultProps;

  state: ITransactionListsContainerState = {
    currentPage: { selected: 1 },
    dataList: [],
    page: {
      limit: 10,
      page: 1,
      search: "",
    },
    queryTimeout: setTimeout(() => {
      noop();
    }, 0),
  };

  componentDidMount() {
    const { page } = this.state;
    this.props.getTransactionLists(page);
  }

  componentDidUpdate(prevProps: ITransactionListsContainerProps) {
    if (
      prevProps.getTransactionListsIsFetching !==
        this.props.getTransactionListsIsFetching &&
      !this.props.getTransactionListsIsFetching
    ) {
      if (this.props.getTransactionListsCode === responseCode.OK) {
        this.setState((_, props) => ({
          dataList: props.TransactionLists.dataList,
        }));
      } else {
        errorModal.show({
          description: this.props.getTransactionListsError,
          action: errorModal.hide,
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.cancelTransactionLists();
  }

  handlePageClick = ({ selected: selectedPage }: ISelectPage) => {
    this.setState({ currentPage: { selected: selectedPage } }, () => {
      this.setState(
        (prevState) => ({
          page: {
            ...prevState.page,
            page: prevState.currentPage.selected + 1,
          },
        }),
        () => {
          this.props.getTransactionLists(this.state.page);
        }
      );
    });
  };
  tableHead = (values: object) =>
    map(values, (data: IResponseAgentFinList, keys) => (
      <th
        key={keys}
        style={{
          width:
            keys === "status"
              ? "10%"
              : keys === "updatedAt"
              ? "30%"
              : keys === "value"
              ? "20%"
              : keys === "remark"
              ? "40%"
              : "auto",
        }}
      >
        <div
          className={`col
        ${keys === "status" ? "text-left" : ""}
        ${keys === "updatedAt" ? "text-center" : ""}
        ${keys === "value" ? "text-right" : ""}
        ${keys === "remark" ? "text-center" : ""}
        `}
        >
          {data}
        </div>
      </th>
    ));
  tableBody = (values: IResponseAgentFinList[]) => {
    const dataEmpty = (
      <tr>
        <td className="text-center row-members-list color-row p-2" colSpan={7}>
          {constants.dataNotFound}
        </td>
      </tr>
    );
    const mapData = map(
      values,
      (dataSet: IResponseAgentFinList, index: number) => {
        const data:IResponseAgentFinList = {
          ...dataSet,
          status: dataSet.remark.startsWith('ส่ง') ? 'BALANCE_OUTCOME' : dataSet.status
        }
        return (
          <tr
            key={index}
            className={`row-members-list ${
              index % 2 === 0 ? "color-row" : ""
            } w-100`}
          >
            <td className="text-left">
              <span className="mt-1 pt-1 font-weight-bold col">
                {AGENT_FINANCE_TYPE[data.status as TAgentFinanceType]}
              </span>
            </td>
            <td className="text-center">
              <span className="col">
                {format(new Date(data.updatedAt), "dd MMM yy - HH:mm", {
                  locale: th,
                })}
              </span>
            </td>
            <td className={`text-right`}>
              <span
                className={`font-weight-bold col ${
                  data.status === "BALANCE_INCOME" ||
                  data.status === "UPGRADE_LIMIT_CREDIT_CHILD"
                    ? "text-success"
                    : ""
                }
                  ${
                    data.status === "PAY_COMPLETE" ||
                    data.status === "DOWNGRADE_LIMIT_CREDIT_CHILD"
                      ? "text-danger"
                      : ""
                  }
                  `}
              >
                {number.decimalsFormatComma(String(data.value))}
              </span>
            </td>
            <td>
              <span className="offset-2 col">{data.remark}</span>
            </td>
          </tr>
        );
      }
    );
    return !isEmpty(values) ? mapData : dataEmpty;
  };
  handleOnChangeSearch = (
    values: string,
    id: React.FormEvent<HTMLInputElement>
  ) => {
    const searchKeys = `${id.currentTarget.id}`;
    this.setState(
      (prevState) => ({
        page: {
          ...prevState.page,
          page: this.state.currentPage.selected + 1,
          [searchKeys]: values,
        },
      }),
      () => {
        clearTimeout(this.state.queryTimeout);
        this.setState({
          queryTimeout: setTimeout(() => {
            this.props.getTransactionLists(this.state.page);
          }, 2000),
        });
      }
    );
  };
  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.replace(path);
  };
  render() {
    const navigates: IBreadcrumbItem[] = [
      {
        label: constants.TransactionListTitle,
        active: false,
        path: `/credits`,
      },
      {
        label: constants.TransactionListSubtitle,
        active: true,
      },
    ];
    const { dataList, page, currentPage } = this.state;
    const HeadTable = this.tableHead(mapTransactionListTableName);
    const TableBody = this.tableBody(dataList);
    return (
      <div className="paper-container transactionlist-container">
        <div className="row">
          <div className="col">
            <div className="sub-menu">
              <Breadcrumb
                items={navigates}
                handleOnClickItem={this.handleOnClickBreadcrumb}
              />
            </div>
            <div className="paper-title">{constants.TransactionListTitle}</div>
            <div className="inline pr-3 paper-description">
              {constants.TransactionListSubtitle}
            </div>
            <div className="paper-body">
              <div className="row m3-b">
                <div className="col">
                  <div className="box-shadow">
                    <div className="row  d-flex flex-row-reverse">
                      <div className="col-12 col-xs-8 col-md-6 col-lg-4 text-right">
                        <Input
                          id={`search`}
                          name="inputSearchPlaceHolder"
                          type="text"
                          placeholder={constants.InputSearchPlaceholder}
                          onChange={(e) =>
                            this.handleOnChangeSearch(e.target.value, e)
                          }
                          value={page.search}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col mt-4">
                        <div className="table-container">
                          <div className="table-responsive-xl">
                            <table className="col">
                              <thead>
                                <tr>{HeadTable}</tr>
                              </thead>
                              <tbody>{TableBody}</tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Panginate
                          pageTotal={this.props.TransactionLists.total}
                          initialPage={page.page - 1}
                          pageRangeDisplayed={currentPage.selected}
                          onPageChange={this.handlePageClick}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default TransactionListsContainer;
