import axios from 'axios'
import { transformer } from 'utils'
import environment from '../../constants/environment'

const fetchCredits = () => {
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.credits}`
    return axios.get(endpoint)
}

const fetchTransactionLists = (query: ISearchAgentFinancePayListQuery) => {
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.transectionLists}`
    return axios.get(`${endpoint}${transformer.urlSearchParams(query)}`)
}

const fetchTransferCredits = (query: ISearchAgentFinancePayListQuery) => {
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.transferCredits}`
    return axios.get(`${endpoint}${transformer.urlSearchParams(query)}`)
}

const fetchTransferConfirmations = (query: ISearchAgentFinancePayListQuery) => {
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.transferConfirmations}`
    return axios.get(`${endpoint}${transformer.urlSearchParams(query)}`)
}

const fetPostTransferCreditDescription = (body: IAgentFinancePayDetailQuery) => {
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.transferCreditDescription}`
    return axios.post(`${endpoint}`,body)
}

const fetPostPayRequest = (body: IAgentPayRequest) => {
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.payRequest}`
    return axios.post(`${endpoint}`,body)
}

const fetPostPayAccept = (body: IAgentPayAccept) => {
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.payAccept}`
    return axios.post(`${endpoint}`,body)
}

export {
    fetchCredits,
    fetchTransactionLists,
    fetchTransferCredits,
    fetPostPayAccept,
    fetPostPayRequest,
    fetPostTransferCreditDescription,
    fetchTransferConfirmations
}