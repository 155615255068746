import React from "react"
import { FunctionComponent } from "react"
import "../../financeSummaryHost.style.scss"
import { number, dateThai } from "utils"
import point from "assets/images/global/icon/point.png"
import { STATUS_FINANCE, FINANCE_HOST_LIST } from "constants/variables"
import { get, isEmpty } from "lodash"
import colors from "constants/colors"
import { Dropdown, LoadingCircle } from 'components'

const constants = {
  list: "(จำนวนรายการ)",
  empty: "ไม่มีข้อมูล...",
};
interface ITableMembers {
  column: Array<{
    codeName: string;
    textalign: string;
  }>;
  dataList: Array<IV2RespAgentFinanceHost>;
  onClick(index: any, data: any): void;
  callBackDropdown(key: IOptions, data: IV2RespAgentFinanceHost): void
  loading: boolean
}

const Table: FunctionComponent<ITableMembers> = ({
  column,
  dataList,
  onClick,
  callBackDropdown,
  loading
}) => {
  const columns = (
    <thead>
      <tr className="tableHead">
        {column.map((data, index: number) => (
          <th key={index} className={data.textalign}>
            {index === 8 ? (
              <div className="d-flex flex-column">
                <span>{data.codeName} </span>
                <div className="total-list" style={{ lineHeight: "8px" }}>
                  {constants.list}
                </div>
              </div>
            ) : (
              <span>{data.codeName}</span>
            )}
          </th>
        ))}
      </tr>
    </thead>
  );


  const handleChildClick = (event: any) => {
    //stop onlick parent
    event.stopPropagation()
  };

  const loadingTable= (
    <tr>
      <td className="text-center" colSpan={15}>
      <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
      </td>
    </tr>
  )
  
  const rows = (
    <tbody>
      {isEmpty(dataList) ? (
        <tr>
          <td className="text-center color-row p-2" colSpan={15}>
            {constants.empty}
          </td>
        </tr>
      ) : (
        dataList.map((data, index: number) => {
          const stName = get(STATUS_FINANCE, `${data.status}.status`, "");
          const stColor = get(STATUS_FINANCE, `${data.status}.color`, "");
          const stPayColor = data.totalPayWait + data.totalPayWait === 0 ? colors.TERTIARY_TEXT : colors.PRIMARY_YELLOW
          const creditColor = (data: number) =>
            data > 0
              ? colors.SECONDARY_GREEN
              : data < 0
                ? colors.PRIMARY_RED
                : colors.TERTIARY_TEXT;
          return (
            <tr
              className={`row-finance-host`}
              key={index}
              onClick={() => onClick(index, data)}
            >
              <td className="text-left paddingRow">
                <span className="pd-frist">{data.agentShareId}</span>
              </td>
              <td className="text-right">
                <span>{number.addComma(data.betMe)}</span>
              </td>
              <td className="text-right">
                <span>{number.addComma(data.betHost)}</span>
              </td>
              <td className="text-right">
                <span>{number.addComma(number.calculator(`${data.betMe}+${data.betHost}`))}</span>
              </td>
              <td className="text-right">
                <span>{number.addComma(data.discount)}</span>
              </td>
              <td className="text-right">
                <span>{number.addComma(data.com)}</span>
              </td>
              <td className="text-right">
                <span
                  className=""
                  style={{
                    color: creditColor(data.recieveHost),
                  }}
                >
                  {number.addComma(data.recieveHost)}
                </span>
              </td>
              <td className="text-right">
                <span
                  className={(data.payHost || 0) > 0 ? 'primary-red-text' : 'primary-text'}
                >
                  {number.addComma((data.payHost || 0) < 0 ? 0 : (data.payHost || 0))}
                </span>
              </td>
              <td className="text-center">
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <span className="text-status" style={{ color: stPayColor }}>
                    {number.addComma(data.totalPayWait)}
                  </span>
                  <span
                    className=""
                    style={{ color: stPayColor }}
                  >{`(${number.addComma(data.countWait)} รายการ)`}</span>
                </div>
              </td>
              <td style={{ width: "10%" }} className="text-right">
                <span
                  className="text-status"
                  style={{
                    color: creditColor(number.calculator(`${data.recieveHost || 0}-${data.payHost || 0}`)),
                  }}
                >
                  {number.addComma(number.calculator(`${data.recieveHost || 0}-${data.payHost || 0}`))}
                </span>
              </td>
              <td className="text-center">
                <span className="text-status" style={{ color: stColor }}>
                  {stName}
                </span>
              </td>
              <td className="text-center">
                <span>{dateThai.formatThai(data.createdAt)}</span>
              </td>
              <td className="text-letf d-flex justify-content-center" >
                <span className="" onClick={handleChildClick}>
                  <Dropdown
                    isManualOpenMenu
                    options={Object.keys(FINANCE_HOST_LIST).map((item) => { return { value: item, label: FINANCE_HOST_LIST[item], disabled: item === 'CancelBill' } })}
                    iconOpen={point}
                    iconClose={point}
                    hoverElementColor={colors.PRIMARY_HOVER}
                    iconStyles={{ width: 4, height: 16 }}
                    controlBackgroundColor={colors.PRIMARY_BG}
                    menuStyles={{ width: "175px", left: "-150px" }}
                    valueContainerStyles={{
                      padding: "0px",
                      maxWidth: "0px",
                    }}
                    handleOnChange={(target: IOptions) => {
                      callBackDropdown(target, data)
                    }}
                  />
                </span>
              </td>
            </tr>
          );
        })
      )}
    </tbody>
  );

  return (
    <div className="table-container">
      <table className="col">
        {columns}
        {loading ? loadingTable : rows}
      </table>
    </div>
  );
};

export default Table;
