import {
  Breadcrumb,
  ErrorModal,
  LottoCard,
  ConfirmModalNumberBanAddLottery,
  LoadingCircle,
} from "components";
// import colors from 'constants/colors'
import { responseCode, responseMessage } from "constants/response";
import { CODE_TO_NAME, LOTTO_FLAG_ALPHA } from "constants/variables";
// import { addYears, format } from 'date-fns'
// import th from 'date-fns/locale/th'
import { get, includes, map, noop } from "lodash";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
// import { date } from 'utils'
import LottoFlags from "assets/images/global/flags";
import routes from "constants/routes";
import "./NumbersBanAddLottery.style.scss";
import {
  fetchAgentBanLottery,
  postAddBanLottery,
} from "reduxs/numbersBan/services";
import { AxiosError } from "axios";
// import lotto from "reduxs/lotto/selector";

type DefaultProps = Readonly<typeof defaultProps>;
const constants = {
  mainPageTitle: "ภาพรวม",
  betTitle: "การเดิมพัน",
  resultTitle: "ผลหวย",
  tabelLotto: "ตารางหวย",
  lottoShare: "หวยหุ้น",
  lottoYegee: "หวยยี่กี",
  lottoGeneral: "หวยทั่วไป",

  numberBan: "เลขปิดรับ",
  addLottery: "เพิ่มหวย",
  chooseLottorforControlBan: "เลือกหวยเพื่อควบคุมด้วยระบบเลขปิดรับ",

  titleTextaddLottery: "คุณต้องการเพิ่มหวยเข้าระบบเลขอั้น",
  descriptionAddLottery: "คุณต้องการอั้นเลขกับหวยดังกล่าว ใช่หรือไม่?",
};

const defaultProps: INumbersBanAddLotteryProps & INumbersBanAddLotteryAction = {
  onChangeIsLoaddingAction() {
    noop();
  },
};

export default class NumbersBanAddLottery extends Component<
  INumbersBanAddLotteryProps &
    INumbersBanAddLotteryAction &
    DefaultProps &
    RouteComponentProps
> {
  static defaultProps = defaultProps;
  state: INumbersBanAddLotteryState = {
    genLotto: [],
    shareLotto: [],
    yegeeLotto: [],
    loading: false
  };

  componentDidMount() {
    this.getBanLotteryAgent();
  }

  getBanLotteryAgent = async () => {
    this.setState({loading: true})
    await fetchAgentBanLottery()
      .then((res) => {
        console.log("res", res);
        const lottoShare = res.data.data.filter(
          (lotto) => lotto.name.includes("SHARE") && !lotto.name.includes("LAO")
        );
        const lottoYegee = res.data.data.filter((lotto) =>
          lotto.name.includes("YEGEE")
        );
        const lottoGeneral = res.data.data.filter(
          (lotto) =>
            (!lotto.name.includes("YEGEE") && !lotto.name.includes("SHARE")) ||
            lotto.name.includes("LAO")
        );

        this.setState({
          genLotto: lottoGeneral,
          shareLotto: lottoShare,
          yegeeLotto: lottoYegee,
        });
      })
      .catch((err: AxiosError<IAPIResponse>) => {
        if (get(err, "response.data.code", 0) !== responseCode.OK) {
          ErrorModal.show({
            action: ErrorModal.hide,
            description: get(
              responseMessage(),
              get(err, "response.data.code", 0),
              responseMessage()[0]
            ),
          });
        }
      });
      this.setState({loading: false})
  };

  renderLottoList = (lottoList: IV2GetRateReduceLotteryAgentAdd[]) => {
    return map(lottoList, (lotto, index) => {
      console.log("lotto", lottoList);
      const FlagIcon = get(
        LottoFlags[
          LOTTO_FLAG_ALPHA[
            includes(lotto.name, "YEGEE") ? "YEGEE" : (lotto.name as TLottoType)
          ]
        ],
        "Icon",
        ""
      );

      const textNameLotto = includes(lotto.name, "YEGEE")
        ? `ยี่กี รอบที่ ${Number(lotto.name.replace("YEGEE_", ""))}`
        : CODE_TO_NAME[lotto.name as TLottoType];

      return (
        <div
          className="col-auto lotto-box"
          key={`lotto-${lotto.name}-${index}`}
        >
          <LottoCard
            slug={("LOTTER_" + lotto.name) as TLottoSlug}
            id={`lotto-${lotto.name}`}
            onClick={() => {
              ConfirmModalNumberBanAddLottery.show({
                action: async () => {
                  await postAddBanLottery({
                    agentLotsetId: lotto.agentLotsetId,
                  });
                  ConfirmModalNumberBanAddLottery.hide();
                  this.props.history.push(routes.numberBan.path);
                },
                cancelAction: () => {
                  ConfirmModalNumberBanAddLottery.hide();
                },
                title: constants.titleTextaddLottery,
                description: constants.descriptionAddLottery,
                moreMarginDescription: true,
                acceptText: "ใช่ เพิ่มหวยเข้าระบบเลขปิดรับ",
              });
            }}
            title={textNameLotto}
            subtitle={""}
            isOpen={true}
            status={lotto.isRatereducefacAdded ? "CLOSE" : "OPEN"}
            icon={FlagIcon}
          />
        </div>
      );
    });
  };

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.mainPageTitle, active: false, path: "/dashboard" },
      { label: constants.numberBan, path: "/numbers-ban", active: false },
      { label: constants.addLottery, active: true },
    ];
    return (
      <div className="paper-container lotto-result-container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="sub-menu" style={{ width: "fit-content" }}>
              <Breadcrumb
                items={navigates}
                handleOnClickItem={(path: string) =>
                  this.props.history.replace(path)
                }
              />
            </div>
          </div>
        </div>
        <div className="paper-title">{constants.addLottery}</div>
        <div className="paper-description">
          {constants.chooseLottorforControlBan}
        </div>
        <div className="paper-body">
          <div className="box-shadow">
            <div className="max-width-box">
              <div className="row mr-xl-4">
                <div className="col-12">
                  <h4>{constants.lottoGeneral}</h4>
                </div>
                {this.state.loading && ( <div className="m2-l m1-t"><LoadingCircle /> </div>)}
                {this.renderLottoList(this.state.genLotto)}
              </div>
              <div className="row m3-t mr-xl-4">
                <div className="col-12">
                  <h4>{constants.lottoShare}</h4>
                </div>
                {this.state.loading && ( <div className="m2-l m1-t"><LoadingCircle /> </div>)}
                {this.renderLottoList(this.state.shareLotto)}
              </div>
              <div className="row m3-t mr-xl-4">
                <div className="col-12">
                  <h4>{constants.lottoYegee}</h4>
                </div>
                {this.state.loading && ( <div className="m2-l m1-t"><LoadingCircle /> </div>)}
                {this.renderLottoList(this.state.yegeeLotto)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
