import { Breadcrumb } from "components";
import { meDataInitialValue } from "constants/variables";
import { Form, Formik, FormikProps } from "formik";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { handleOnClickBreadcrumb } from "utils";
import { HostInfo } from "./components/HostInfo";
import { UserSpecificInfo } from "./components/UserSpecificInfo";
import "./UserDetailNote.style.scss";
import schemeUserDetail from "./model/scheme";
import { cloneDeep, isEmpty, noop, map } from "lodash";
// import color from "constants/colors";
import { TableMenuList } from "./components";

const constants = {
  addNote: "บันทึกข้อมูล",
  menuList: ["หัวสมาชิก", "ลูกสมาชิก", "ลูกค้า"],
  breadcrumb: {
    addNote: "บันทึกประวัติ",
    main: "ภาพรวม",
    userInfo: "ข้อมูลส่วนตัว",
  },
  title: "ข้อมูลส่วนตัว",
  changePassword: "เปลี่ยนรหัสผ่าน",
  postOwnerSettingLotteryUpdateSuccess: "แก้ไขข้อมูลการตั้งค่าหวยสำเร็จ",
  saveList: "รายการบันทึกข้อความ",
};

const defaultProps: IUserDetailNoteActionProps & IUserDetailNoteProps = {
  onChangeIsLoaddingAction() {
    noop();
  },
  meData: meDataInitialValue,
  meIsFetching: false,
  ownerUpdateCode: 0,
  ownerUpdateError: "",
  ownerUpdateIsFetching: false,
  postOwnerUpdate() {
    noop();
  },
  getMe() {
    noop();
  },
  getMeSetting() {
    noop();
  },
  meSettingCode: 0,
  meSettingData: {
    agentId: 0,
    setBetMaxLimit: 0,
    setBetMinLimit: 0,
    setComPercentage: 0,
  },
  meSettingError: "",
  meSettingIsFetching: false,
  ownerSettingCode: 0,
  ownerSettingError: "",
  ownerSettingIsFetching: false,
  postOwnerSetting() {
    noop();
  },
  ownerSettingLotteryUpdateCode: 0,
  ownerSettingLotteryUpdateError: "",
  ownerSettingLotteryUpdateIsFetching: false,
  postOwnerSettingLotteryUpdate() {
    noop();
  },
  getMeSettingLottery() {
    noop();
  },
  meSettingLotteryCode: 0,
  meSettingLotteryData: {
    agentId: 0,
    lotterySetting: [],
  },
  meSettingLotteryError: "",
  meSettingLotteryIsFetching: false,
  getHost() {
    noop();
  },
  hostCode: 0,
  hostData: {
    hostAgentId: 0,
    hostBankName: "",
    hostBankNumber: "",
    hostBankType: "",
    hostLine: "",
    hostPhoneNumber: "",
    hostUsername: "",
  },
  hostError: "",
  hostIsFetching: false,

  financeChildMeCode:0,
  financeChildMeError: "",
  financeChildMeIsFetching: false,
  financeChildMeData:{
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  getFinanceChildMe(){
    noop()
  },
  getFinanceHostMe(){
    noop()
  },
  hostMeData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  hostMeCode:0,
  hostMeError:"",
  hostMeIsFetching:false,
   customerNoteCode: 0,
  customerNoteError: "",
  customerNoteIsFetching: false,
  customerNoteData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  getCustomerNote() {
    noop();
  },
};

class UserDetailNoteContainer extends Component<
  IUserDetailNoteActionProps & IUserDetailNoteProps & RouteComponentProps,
  IUserDetailNoteState
> {
  static defaultProps = defaultProps;
  state: IUserDetailNoteState = {
    activeMenu: "หัวสมาชิก",
    formikState: {
      phoneNumber: "",
      bankName: "",
      bankType: "PleaseChoose",
      bankNumber: "",
      betMaxLimit: 0,
      betMinLimit: 0,
      com: 0,
    },
  };

  componentDidMount() {
    this.props.onChangeIsLoaddingAction(true);
    this.props.getMeSetting();
    this.props.getHost();
    this.props.getMe();
  }

  componentDidUpdate(prevProps: IUserDetailNoteProps) {
    if (
      prevProps.meSettingIsFetching !== this.props.meSettingIsFetching &&
      !this.props.meSettingIsFetching
    ) {
      this.props.getMeSettingLottery();
    }

    if (
      prevProps.meSettingLotteryIsFetching !==
        this.props.meSettingLotteryIsFetching &&
      !this.props.meSettingLotteryIsFetching
    ) {
      this.props.onChangeIsLoaddingAction(false);
      const { meData, meSettingData } = this.props;
      const clonedFormikState = cloneDeep(this.state.formikState);
      if (!isEmpty(meData)) {
        clonedFormikState.phoneNumber = meData.phoneNumber;
        clonedFormikState.bankName = meData.bankName;
        clonedFormikState.bankNumber = meData.bankNumber;
        clonedFormikState.bankType = meData.bankType;
        clonedFormikState.betMaxLimit =
          meSettingData.setBetMaxLimit > 1000
            ? 1000
            : meSettingData.setBetMaxLimit;
        clonedFormikState.betMinLimit =
          meSettingData.setBetMinLimit > 1000
            ? 1000
            : meSettingData.setBetMinLimit;
        clonedFormikState.com = meSettingData.setComPercentage;

        this.setState({ formikState: clonedFormikState });
      }
    }
  }

  handleRouteClick = (id: number, username: string) => {
    this.props.history.push("/user/reset-password", { id, username });
  };

  renderUserDetailFormik = () => {
    const UserDetailForm = (
      formikProps: FormikProps<IUserDetailFormikProps>
    ) => {
      return (
        <Form>
          <UserSpecificInfo meData={this.props.meData}  loading={this.props.meIsFetching}/>
          <HostInfo
            getHost={this.props.getHost}
            hostData={this.props.hostData}
            hostIsFetching={this.props.hostIsFetching}
            hostCode={this.props.hostCode}
            meData={this.props.meData}
          />
        </Form>
      );
    };
    return (
      <Formik
        initialValues={this.state.formikState}
        enableReinitialize
        validationSchema={schemeUserDetail(
          this.props.meSettingData,
          this.props.meData
        )}
        onSubmit={(values) => {}}
      >
        {UserDetailForm}
      </Formik>
    );
  };

  renderLandingMenu = () => {
    const mappingMenu = map(constants.menuList, (name: string) => (
      <div
        className={`m5-r`}
        key={`${name}`}
        id={`${name}`}
        onClick={() => {
          if (this.state.activeMenu !== name) {
            this.setState({ activeMenu: name });
          }
        }}
      >
        <div className="d-flex flex-column">
          <h6 className={this.state.activeMenu === name ? "active-menu" : ""}>
            {name}
          </h6>
        </div>
      </div>
    ));
    return <div className="row p2-l ">{mappingMenu}</div>;
  };
  renderTableMenuList = () => {
    return (
      <>
        <TableMenuList
          onload={this.props.onChangeIsLoaddingAction}
          activeMenu={this.state.activeMenu}

          getHostMeData={this.props.getFinanceHostMe}
          hostMeCode= {this.props.hostMeCode}
          hostMeError={this.props.hostMeError}
          hostMeIsFetching={this.props.hostMeIsFetching}
          hostMeData={this.props.hostMeData}         
          locationData={this.props.hostData.hostAgentId}

          locationDataAgentId={this.props.meData.agentId}
          financeChildMeCode={this.props.financeChildMeCode}
          financeChildMeError={this.props.financeChildMeError}
          financeChildMeIsFetching={this.props.financeChildMeIsFetching}
          financeChildMeData={this.props.financeChildMeData}
          getFinanceChildMe={this.props.getFinanceChildMe}

          customerNoteCode={this.props.customerNoteCode}
          customerNoteError={this.props.customerNoteError}
          customerNoteIsFetching={this.props.customerNoteIsFetching}
          customerNoteData={this.props.customerNoteData}
          getCustomerNote={this.props.getCustomerNote}
        />
      </>
    );
  };

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.breadcrumb.main, path: "/", active: false },
      {
        label: constants.breadcrumb.userInfo, path: "/user/detail",
        active: false,
      },
      {
        label: constants.breadcrumb.addNote,
        active: true,
      },
    ];
    const UserDetailFormik = this.renderUserDetailFormik;
    const RenderTableMenuList = this.renderTableMenuList;
    const RenderLadingMenu = this.renderLandingMenu;

    return (
      <div className="paper-container userDetailNote-container">
        <div className="row">
          <div className="col">
            <div className="sub-menu">
              <Breadcrumb
                items={navigates}
                handleOnClickItem={handleOnClickBreadcrumb}
              />
            </div>
          </div>
        </div>
        <div className="paper-title">{constants.title}</div>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="paper-description">{`${this.props.meData.username}`}</div>
          <div className="d-flex flex-row align-items-center">
            <div
              className="change-password-text pr-3"
              onClick={() => {
                this.handleRouteClick(
                  this.props.meData.agentId,
                  this.props.meData.username
                );
              }}
            >
              {constants.changePassword}
            </div>
          </div>
        </div>
        <UserDetailFormik />

        <div className="paper-body">
          <div className="box-shadow">
            <div className="addNote">{constants.addNote}</div>
            <div className="menu-list m3-t ">
              {/* <div className="p3-y">  */}
              <RenderLadingMenu />
              {/* </div> */}
             
            </div>
            <RenderTableMenuList />
          </div>
        </div>
      </div>
    );
  }
}

export default UserDetailNoteContainer;
