import "./Dashboard.style.scss"
import { format } from "date-fns"
import { th } from "date-fns/locale"
import { date, number } from "utils"
import routes from "constants/routes"
import React, { Component } from "react"
import { noop, get, isEmpty, isNull } from "lodash"
import { BANK_TYPE } from "../../constants/variables"
import { RouteComponentProps } from "react-router-dom"
import { faEye } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { PERMISSION, AGENT_STATUS } from "../../constants/variables"
import { LoadingCircle } from "components"
import { AnnounceNewLottoModal } from "components/Modal"

type DefaultProps = Readonly<typeof defaultProps>;
const defaultProps: IDashboardProps & IDashboardActionProps = {
  getMe() {
    noop();
  },
  onChangeIsLoaddingAction() { },
  getHost() {
    noop();
  },
  getMeSetting() {
    noop();
  },
  getMeData: {
    agentId: 0,
    username: "",
    permission: "",
    phoneNumber: "",
    credit: 0,
    bankType: "",
    bankName: "",
    bankNumber: "",
    isAccess: false,
    agentStatus: "",
    name: "",
    creditLimit: 0,
    creditShareLimit: 0,
    betTotal: 0,
    currentBetTotal: 0,
    betCountTotal: 0,
    currentBetCountTotal: 0,
    profitTotal: 0,
    profitBenefitTotal: 0,
    lossTotal: 0,
    lossBenefitTotal: 0,
    childTotal: 0,
    createdAt: "",
    updatedAt: "",
  },
  getMeDataIsFetching: false,
  getHostData: {
    hostAgentId: 0,
    hostUsername: "",
    hostPhoneNumber: "",
    hostLine: "",
    hostBankType: "",
    hostBankName: "",
    hostBankNumber: "",
  },
  getHostDataIsFetching: false
};

const constants = {
  times: "ครั้ง",
  Baht: "บาท",
  titie: "ภาพรวม",
  dataDetial: "ดูข้อมูลส่วนตัว",
  infoDetail: "ข้อมูลภาพรวม",
  updateAt: "อัพเดทเมื่อ",
  status: "สิทธิ",
  normal: "ปกติ",
  userStatus: "สถานะผู้ใช้",
  totalCrediitLimit: "จำนวนเครดิตปัจจุบัน / จำกัด",
  profitLoss: "ยอดกำไร / ขาดทุน",
  profitLossOwner: "ยอดกำไร / ขาดทุนที่หัวสมาชิกได้รับ",
  totalBet: "ยอดรวมเดิมพัน",
  allTotalBet: "ยอดรวมเดิมพันปัจจุบันในบิลล่าสุด",
  frequencyBet: "จำนวนครั้งการเดิมพัน",
  ownerDetail: "ข้อมูลหัวสมาชิก",
  ownerUser: "ชื่อผู้ใช้ในระบบ",
  ownerPhone: "เบอร์โทรศัพท์",
  ownerLineId: "ไลน์ไอดี",
  ownerBank: "ธนาคาร",
  ownerBankAccountName: "ชื่อเจ้าของบัญชี",
  ownerBankId: "หมายเลขบัญชี",
};

class Dashboard extends Component<
  IDashboardActionProps & IDashboardProps & DefaultProps & RouteComponentProps
> {
  state = {
    clickShowPass: false,
  };
  componentDidMount() {
    this.props.getMeSetting();
    this.props.getHost();
    // confirmModal.hide();
    this.props.getMe();
      if (localStorage.getItem("isShowNewLotto") === 'false' || localStorage.getItem("isShowNewLotto") === '' || isNull(localStorage.getItem("isShowNewLotto"))) {
        AnnounceNewLottoModal.show({
          title: 'ประกาศ',
          acceptText: 'เข้าใจแล้ว',
          action: () => {
            AnnounceNewLottoModal.hide()
            localStorage.setItem("isShowNewLotto", JSON.stringify(true))
          },
        })
      }
  }

  showNumberSpace(number: string) {
    var last1Digit = number.slice(-1);
    var last2Digit = number.slice(-3, -1);
    if (number.length === 10) {
      var number1 = number.slice(0, 3);
      var number2 = number.slice(3, 7);
      return number1 + " " + number2 + " " + last2Digit + " " + last1Digit;
    }

    if (number.length === 12) {
      var number4 = number.slice(0, 1);
      var number5 = number.slice(1, 4);
      var number6 = number.slice(4, 8);
      var number11 = number.slice(8, 9);
      return (
        number4 +
        " " +
        number5 +
        " " +
        number6 +
        " " +
        number11 +
        last2Digit +
        " " +
        last1Digit
      );
    }

    if (number.length === 15) {
      var number7 = number.slice(0, 3);
      var number8 = number.slice(3, 7);
      var number9 = number.slice(7, 11);
      var number10 = number.slice(11, 12);
      return (
        number7 +
        " " +
        number8 +
        " " +
        number9 +
        " " +
        number10 +
        last2Digit +
        " " +
        last1Digit
      );
    }

    if (number === "-") {
      return "-";
    }
  }
  numberSpace(number: string) {
    var last1Digit = number.slice(-1);
    var last2Digit = number.slice(-3, -1);
    if (number.length === 10) {
      var number1 = number.slice(0, 3);
      number1 = number1.replace(number1, "xxx");
      var number2 = number.slice(3, 7);
      number2 = number2.replace(number2, "xxxx");
      return number1 + " " + number2 + " " + last2Digit + " " + last1Digit;
    }

    if (number.length === 12) {
      var number4 = number.slice(0, 1);
      number4 = number4.replace(number4, "x");
      var number5 = number.slice(1, 4);
      number5 = number5.replace(number5, "xxx");
      var number6 = number.slice(4, 8);
      number6 = number6.replace(number6, "xxxx");
      var number11 = number.slice(8, 9);
      number11 = number11.replace(number11, "x");
      return (
        number4 +
        " " +
        number5 +
        " " +
        number6 +
        " " +
        number11 +
        last2Digit +
        " " +
        last1Digit
      );
    }

    if (number.length === 15) {
      var number7 = number.slice(0, 3);
      number7 = number7.replace(number7, "xxx");
      var number8 = number.slice(3, 7);
      number8 = number8.replace(number8, "xxxx");
      var number9 = number.slice(7, 12);
      number9 = number9.replace(number9, "xxxx");
      var number10 = number.slice(11, 12);
      number10 = number10.replace(number10, "x");
      return (
        number7 +
        " " +
        number8 +
        " " +
        number9 +
        " " +
        number10 +
        last2Digit +
        " " +
        last1Digit
      );
    }

    if (number === "-") {
      return "-";
    }
  }

  handleRouteClick = () => this.props.history.push(routes.userDetail.path);
  replaceBankType = (data: string) => {
    if (data === "-" || isNull(data) || isEmpty(data)) {
      return "-"
    }
    var dataNew = data.slice(6)
    return dataNew
  }
  render() {

    return (
      <div className="paper-container dashboard-container">
        <div className="paper-title mt-0 titleText">{constants.titie}</div>
        <div className="d-flex flex-row justify-content-between">
          <div className="paper-description">
            {this.props.getMeData.name} ({this.props.getMeData.username})
          </div>
          <div
            className="dataDetail pr-3"
            onClick={() => this.handleRouteClick()}
          >
            {constants.dataDetial}
          </div>
        </div>

        <div className="paper-body">
          <div className="box-shadow">
            <div className="row">
              <div className="col-12 pr-3 paper-body-title ">
                <div className="d-flex align-items-center">
                  <div className="titleHeadText"> {constants.infoDetail}</div>
                  {this.props.getMeDataIsFetching && ( <div className="m1-l"><LoadingCircle /> </div>)}
                </div>
              </div>
              <div className="col-12 m1-t ">
                <div className="content-text headText">
                  {`${constants.updateAt} ${format(
                    date.calibratingTime(this.props.getMeData?.updatedAt, true),
                    "dd MMM yyyy HH:mm",
                    { locale: th }
                  )}`}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-3 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text headText">
                    {constants.status}
                  </div>
                  <div className={`${this.props.getMeDataIsFetching ? 'tertiary-text' : 'paper-body-title valueText'} m1-t`}>
                    {
                      PERMISSION[
                      get(
                        this.props.getMeData,
                        "permission",
                        "UNKNOWN"
                      ) as TPermissionStatus
                      ]
                    }
                  </div>
                </div>
              </div>

              <div className="col-3 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text headText">
                    {constants.userStatus}
                  </div>
                  <div className={`${this.props.getMeDataIsFetching ? 'tertiary-text' : 'paper-body-title valueText'} m1-t`}>
                    {
                      AGENT_STATUS[
                      get(
                        this.props.getMeData,
                        "agentStatus",
                        "REGISTERED"
                      ) as TAgentStatus
                      ]
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-3 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text headText">
                    {constants.totalCrediitLimit}
                  </div>
                  <div className={`${this.props.getMeDataIsFetching ? 'tertiary-text' : 'paper-body-title valueText'} m1-t`}>
                    {number.addComma(get(this.props.getMeData, "credit", 0))} /{" "}
                    {number.addComma(
                      get(this.props.getMeData, "creditLimit", 0)
                    )}{" "}
                    {constants.Baht}
                  </div>
                </div>
              </div>

              <div className="col-3 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text headText">
                    {constants.profitLoss}
                  </div>
                  <div className={`${this.props.getMeDataIsFetching ? 'tertiary-text' : 'paper-body-title valueText'} m1-t`}>
                    {number.addComma(
                      get(this.props.getMeData, "profitTotal", 0)
                    )}{" "}
                    /{" "}
                    {number.addComma(get(this.props.getMeData, "lossTotal", 0))}{" "}
                    {constants.Baht}
                  </div>
                </div>
              </div>

              <div className="col-3 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text headText">
                    {constants.profitLossOwner}
                  </div>
                  <div className={`${this.props.getMeDataIsFetching ? 'tertiary-text' : 'paper-body-title valueText'} m1-t`}>
                    {number.addComma(
                      get(this.props.getMeData, "profitBenefitTotal", 0)
                    )}{" "}
                    /{" "}
                    {number.addComma(
                      get(this.props.getMeData, "lossBenefitTotal", 0)
                    )}{" "}
                    {constants.Baht}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-3 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text headText">
                    {constants.totalBet}
                  </div>
                  <div className={`${this.props.getMeDataIsFetching ? 'tertiary-text' : 'paper-body-title valueText'} m1-t`}>
                    {number.addComma(get(this.props.getMeData, "betTotal", 0))}{" "}
                    {constants.Baht}
                  </div>
                </div>
              </div>

              <div className="col-3 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text headText">
                    {constants.allTotalBet}
                  </div>
                  <div className={`${this.props.getMeDataIsFetching ? 'tertiary-text' : 'paper-body-title valueText'} m1-t`}>
                    {number.addComma(
                      get(this.props.getMeData, "currentBetTotal", 0)
                    )}{" "}
                    {constants.Baht}
                  </div>
                </div>
              </div>

              <div className="col-3 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text headText">
                    {constants.frequencyBet}
                  </div>
                  <div className={`${this.props.getMeDataIsFetching ? 'tertiary-text' : 'paper-body-title valueText'} m1-t`}>
                    {number.addComma(
                      get(this.props.getMeData, "betCountTotal", 0)
                    )}{" "}
                    {constants.times}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="paper-body ">
          <div className="box-shadow ">
            <div className="row ">
              <div className="col-12 pr-3 paper-body-title ">
              <div className="d-flex align-items-center">
              <div className="titleHeadText"> {constants.ownerDetail}</div>
                  {this.props.getHostDataIsFetching && ( <div className="m1-l"><LoadingCircle /> </div>)}
                </div>
              </div>

              <div className="col-3 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text headText">
                    {constants.ownerUser}
                  </div>
                  <div className={`${this.props.getHostDataIsFetching ? 'tertiary-text' : 'paper-body-title valueText'} m1-t`}>
                    {isEmpty(get(this.props.getHostData, "hostUsername", "-"))
                      ? "-"
                      : this.props.getHostData.hostUsername}
                  </div>
                </div>
              </div>

              <div className="col-3 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text headText">
                    {constants.ownerPhone}
                  </div>
                  <div className={`${this.props.getHostDataIsFetching ? 'tertiary-text' : 'paper-body-title valueText'} m1-t`}>
                    {number.formatPhoneNumberSpace(
                      isEmpty(
                        get(this.props.getHostData, "hostPhoneNumber", "-")
                      ) || isNull(
                        get(this.props.getHostData, "hostPhoneNumber", "-")
                      )
                        ? "-"
                        : this.props.getHostData.hostPhoneNumber
                    )}{" "}
                  </div>
                </div>
              </div>

              <div className="col-3 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text headText">
                    {constants.ownerLineId}
                  </div>
                  <div className={`${this.props.getHostDataIsFetching ? 'tertiary-text' : 'paper-body-title valueText'} m1-t`}>
                    {isEmpty(get(this.props.getHostData, "hostLine", "-"))
                      ? "-"
                      : this.props.getHostData.hostLine}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-3 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text headText">
                    {constants.ownerBank}
                  </div>
                  <div className={`${this.props.getHostDataIsFetching ? 'tertiary-text' : 'paper-body-title valueText'} m1-t`}>
                    {this.replaceBankType(
                      get(
                        BANK_TYPE,
                        `[${this.props.getHostData.hostBankType}]`,
                        "-"
                      )
                    )}

                  </div>
                </div>
              </div>

              <div className="col-3 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text headText">
                    {constants.ownerBankAccountName}
                  </div>
                  <div className={`${this.props.getHostDataIsFetching ? 'tertiary-text' : 'paper-body-title valueText'} m1-t`}>
                    {isEmpty(get(this.props.getHostData, "hostBankName", "-"))
                      || isNull(get(this.props.getHostData, "hostBankName", "-"))
                      ? "-"
                      : this.props.getHostData.hostBankName}
                  </div>
                </div>
              </div>

              <div className="col-3 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text headText">
                    {constants.ownerBankId}
                  </div>
                  <div className={`${this.props.getHostDataIsFetching ? 'tertiary-text' : 'paper-body-title valueText'} m1-t`}>
                    {this.state.clickShowPass === false ? (
                      <div className="boxWidth d-flex justify-content-between"
                        style={{ width: (!isNull(this.props.getHostData.hostBankNumber) || !isEmpty(this.props.getHostData.hostBankNumber)) && this.props.getHostData.hostBankNumber.length === 10 ? 130 : 155 }}
                      >
                        {this.numberSpace(
                          isEmpty(
                            get(this.props.getHostData, "hostBankNumber", "-")
                          ) || isNull(
                            get(this.props.getHostData, "hostBankNumber", "-")
                          )
                            ? "-"
                            : this.props.getHostData.hostBankNumber
                        )}{" "}

                        <span className="iconHover">
                          {this.props.getMeData.permission === "OWNER" || isEmpty(get(this.props.getHostData, "hostBankNumber", "-")) || isNull(get(this.props.getHostData, "hostBankNumber", "-"))
                            ? (
                              ""
                            ) : (
                              <div >
                                <FontAwesomeIcon
                                  icon={faEye}
                                  onClick={() => {
                                    this.setState({ clickShowPass: true });
                                  }}
                                />
                              </div>
                            )}

                        </span>

                      </div>
                    ) : (
                      <div className="boxWidth  d-flex justify-content-between"
                        style={{ width: (!isNull(this.props.getHostData.hostBankNumber) || !isEmpty(this.props.getHostData.hostBankNumber)) && this.props.getHostData.hostBankNumber.length === 10 ? 130 : 155 }} >
                        {this.showNumberSpace(
                          isEmpty(
                            get(this.props.getHostData, "hostBankNumber", "-")
                          ) || isNull(
                            get(this.props.getHostData, "hostBankNumber", "-")
                          )

                            ? "-"
                            : this.props.getHostData.hostBankNumber
                        )}
                        <span className="iconHover">
                          {this.props.getMeData.permission === "OWNER" || isEmpty(get(this.props.getHostData, "hostBankNumber", "-")) || isNull(get(this.props.getHostData, "hostBankNumber", "-"))
                            ? (
                              ""
                            ) : (
                              <div >
                                <FontAwesomeIcon
                                  icon={faEye}
                                  onClick={() => {
                                    this.setState({ clickShowPass: false });
                                  }}
                                />
                              </div>
                            )}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
