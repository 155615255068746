import "./CustomerList.style.scss";
import colors from "constants/colors";
import React, { Component } from "react";
import { get, isEmpty, isNull, noop, pickBy } from "lodash";
import { RouteComponentProps } from "react-router-dom";
import { number, handleOnClickBreadcrumb, dateThai } from "utils";
import {
  Input,
  Breadcrumb,
  SkeletonLoader,
  ErrorModal,
  Panginate,
  Dropdown,
  LoadingCircle,
} from "components";
import routes from "constants/routes";
import { responseCode, responseMessage } from "constants/response";

const defaultProps: ICustomerProps & ICustomerActionProps = {
  getCustomer() {
    noop()
  },
  onChangeIsLoaddingAction() { },
  customerCode: 0,
  customerError: "",
  customerIsFetching: false,
  customerData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
};

const constants = {
  placeholderSearch: "ชื่อผู้ใช้",
  titlePage: "ข้อมูลลูกค้า",
  descriptionPage: "รายการลูกค้า",
  humans: "คน",
  baht: "บาท",
  search: "ค้นหา",
  sort: "เรียงโดย",
  inputSearch: "ค้นหาสมาชิก",
  rowPerPage: "จำนวนรายการต่อหน้า :",
  dataEmpty: "ไม่มีข้อมูล...",
  headTable: [
    { codeName: "ชื่อช่วยจำ", textalign: "text-left paddingtableHead" },
    { codeName: "เบอร์ติดต่อ", textalign: "text-left" },
    { codeName: "ไลน์ไอดี", textalign: "text-left" },
    { codeName: "ยอดเดิมพันทั้งหมด", textalign: "text-right", style: { width: `12%` } },
    { codeName: "ผลลัพธ์ทั้งหมด", textalign: "text-right ", style: { width: `12%` } },
    { codeName: "กำไร", textalign: "text-right paddingtableCol" },
    { codeName: "ขาดทุน", textalign: "text-right paddingtableCol2" },
    { codeName: "อัพเดทล่าสุด", textalign: "text-left paddingtableCol", style: { width: `10%` } },
  ],

  breadcrumb: {
    main: "ภาพรวม",
    customerList: "รายการลูกค้า",
  },
};

class CustomerList extends Component<
  ICustomerProps &
  ICustomerActionProps &
  RouteComponentProps>
{
  static defaultProps = defaultProps;
  state = {
    currentPage: 0,
    currentRowPerPage: 10,
    childSort: "",
    dataQuery: {
      childAgentStatus: "",
      childIsAccess: "",
      limit: 10,
      page: 1,
      customerName: "",
      sortCustomerBetTotal: "",
      sortCustomerTotalProfit: "",
      sortCustomerTotalLoss: "",
      sortCustomerName: "",
      sortCustomerUpdatedAt: "DESC",
    },
    startPage: 1,
    endPage: 0,

    queryTimeout: setTimeout(() => {
      noop();
    }, 0),
    reqMemberDetail: {
      id: -99,
    },
    isSearch: false,
  };


  componentDidMount() {
    this.props.getCustomer();
  }

  componentDidUpdate(prevProps: ICustomerProps) {

    if (
      prevProps.customerIsFetching !== this.props.customerIsFetching &&
      !this.props.customerIsFetching
    ) {

      if (
        this.props.customerCode !== responseCode.OK &&
        this.props.customerError !== ""
      ) {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            this.props.customerCode,
            responseMessage()[0]
          ),
        });
      }
      if (this.props.customerCode === responseCode.OK) {
        if (isEmpty(this.props.customerData.dataList)) {
          this.setState({ startPage: 0, endPage: 0 });
        } else {

          this.setState({
            startPage:
              this.state.currentRowPerPage * (this.state.dataQuery.page - 1) +
              1,
            endPage:
              this.state.currentRowPerPage * (this.state.currentPage + 1) >=
                this.props.customerData.totalRecord
                ? this.props.customerData.totalRecord
                : this.state.currentRowPerPage * this.state.dataQuery.page,
          });
        }
      }
    }

  }

  handleClickChangePage = ({ selected: selectedPage }: ISelectPage) => {
    this.setState({ currentPage: selectedPage }, () => {
      this.setState(
        {
          dataQuery: {
            ...this.state.dataQuery,
            page: this.state.currentPage + 1,
          },
        },
        () => {
          this.props.getCustomer(pickBy(this.state.dataQuery))
        }
      );
    });
  };

  handleOnChangeSearch = (value: string) => {
    this.setState(
      {
        dataQuery: {
          ...this.state.dataQuery,
          customerName: value,
        },
        isSearch: true,
      },
      () => {
        clearTimeout(this.state.queryTimeout);
        this.setState({
          queryTimeout: setTimeout(() => {
            this.setState({
              isSearch: false,
            });
            this.props.getCustomer(pickBy(this.state.dataQuery))
          }, 1000),
        });
      }
    );
  };

  onClickRowMembersList = (index: number, customerId: number) => {
    this.props.history.push(routes.customerDetail.path, customerId);
  };

  tableMembersBody = () => {
    if (this.state.isSearch) {
      return (
        <tr className={`row-members-list color-row`}>
          <td className="text-center color-row p-2" colSpan={15}>
            <SkeletonLoader customStyle={[[{ col: 12, show: true }]]} />
          </td>
        </tr>
      );
    } else {
      const loadingTable = (
        <tr>
          <td className="text-center" colSpan={15}>
            <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
          </td>
        </tr>
      )

      const dataEmpty = (
        <tr>
          <td className="text-center" colSpan={15}>
            {constants.dataEmpty}
          </td>
        </tr>
      )

      const mapData = this.props.customerData.dataList.map((data, index: number) => {
        return (
          <tr className={`row-members-list `} key={index}
            onClick={() => this.onClickRowMembersList(index, data.customerId)}
          >
            <td className="text-left paddingRow">
              <span className="paddingtableHead">{data.customerName}</span>
            </td>
            <td className="text-left ">
              <span>
                {isNull(data.customerPhoneNumber) ? '-' : data.customerPhoneNumber}
                {/* {number.formatPhoneNumberSpace("0946501881")} */}
                {/* {number.formatPhoneNumberSpace(data.customerPhoneNumber)} */}
              </span>
            </td>
            <td className="text-left">
              <span>
                {get(data, 'customerline', '-')}
              </span>
            </td>
            <td className="text-right">
              <span>{number.addComma(data.customerCurrentTotalBet)}  {constants.baht} </span>
            </td>
            <td className="text-right">
              <span>{number.addComma(data.customerCurrentTotalResult)}  {constants.baht}</span>
            </td>
            <td className="text-right">
              <span className="paddingtableCol">{number.addComma(data.customerTotalProfit)} {constants.baht}</span>
            </td>

            <td className="text-right paddingtableCol2">
              <span>{number.addComma(data.customerTotalLoss)} {constants.baht}</span>
            </td>
            <td className="text-left">
              <span className="paddingtableCol">{dateThai.formatThai(data.customerUpdatedAt)}</span>
            </td>
          </tr>
        )
      });
      return this.props.customerIsFetching ? loadingTable : !isEmpty(mapData) ? mapData : dataEmpty;
    }
  };

  renderFilter = () => (
    <div className="filterBox">
      <div className="row ">
        <div className="col firstCol">
          <div className="search-box">
            <div className="head-text-filter-box">{constants.search}</div>
            <div className="inputBox">
              <Input
                backgroundColor={colors.PRIMARY_BG}
                borderRadius="4px"
                inputClassName="inputtt"
                setPadding="6px"
                placeholder={constants.placeholderSearch}
                size={"large"}
                value={this.state.dataQuery.customerName}
                id={""}
                name={""}
                onChange={(e) => {
                  this.handleOnChangeSearch(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-2 lastCol">
          <div className="head-text-filter-box">{constants.sort}</div>
          <div className="dropdownBorder2">
            <Dropdown
              menuStyles={{ width: '100%' }}
              options={[
                {
                  value: "DESC",
                  label: "อัพเดทล่าสุด",
                  searchableName: "sortCustomerUpdatedAt",
                  disabled: false,
                },
                {
                  value: "ASC",
                  label: "ชื่อ",
                  searchableName: "sortCustomerName",
                  disabled: false,
                },
                {
                  value: "DESC",
                  label: "ยอดเดิมพันสูงสุด",
                  searchableName: "sortCustomerBetTotal",
                  disabled: false,
                },
                {
                  value: "ASC",
                  label: "ยอดเดิมพันต่ำสุด",
                  searchableName: "sortCustomerBetTotal",
                  disabled: false,
                },
                {
                  value: "DESC",
                  label: "กำไรสูงสุด",
                  searchableName: "sortCustomerTotalProfit",
                  disabled: false,
                },
                {
                  value: "ASC",
                  label: "กำไรต่ำสุด",
                  searchableName: "sortCustomerTotalProfit",
                  disabled: false,
                },

                {
                  value: "ASC",
                  label: "ขาดทุนสูงสุด",
                  searchableName: "sortCustomerTotalLoss",
                  disabled: false,
                },
                {
                  value: "DESC",
                  label: "ขาดทุนต่ำสุด",
                  searchableName: "sortCustomerTotalLoss",
                  disabled: false,
                },
              ]}
              controlBackgroundColor={colors.PRIMARY_BG}
              handleOnChange={(target: IOptions) => {
                const sort = String(target.searchableName)
                const resetDataSort = {
                  sortCustomerBetTotal: "",
                  sortCustomerTotalProfit: "",
                  sortCustomerTotalLoss: "",
                  sortCustomerName: "",
                  sortCustomerUpdatedAt: "",
                };
                this.setState(
                  {
                    currentPage: 0,
                    childSort: target.label,
                    dataQuery: {
                      ...this.state.dataQuery,
                      ...resetDataSort,
                      [sort]: target.value,
                      page: 1,
                    },
                  },
                  () => {
                    this.props.getCustomer(pickBy(this.state.dataQuery))
                  }
                );
              }}
              placeholder={
                this.state.childSort === "" ? "ชื่อ" : this.state.childSort
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
  renderTableMembers = () => (
    <div className="table-container">
      <div className="table-responsive-xl">
        <table className="col ">
          <thead className="">
            <tr className="tableHead">
              {constants.headTable.map((data, index: number) => (
                <th key={index} className={data.textalign} style={data.style}>
                  <span>{data.codeName}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{this.tableMembersBody()}</tbody>
        </table>
      </div>
    </div>
  );

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.breadcrumb.main, path: "/", active: false },
      { label: constants.breadcrumb.customerList, active: true },
    ];

    const RenderFilter = this.renderFilter();
    const RenderTableMembers = this.renderTableMembers();
    return (
      <>
        <div className="paper-container customer-container">
          <div className="row">
            <div className="col">
              <div className="sub-menu">
                <Breadcrumb
                  items={navigates}
                  handleOnClickItem={handleOnClickBreadcrumb}
                />
              </div>
            </div>
          </div>
          <div className="paper-title titleText">{constants.titlePage}</div>
          <div className="pr-3 paper-description">{constants.descriptionPage}</div>

          <div className="paper-body ">
            <div className="box-shadow m3-t boxShadow-Border">
              <div className="row">
                <div className="col">{RenderFilter}</div>
              </div>
              <div className="row ">
                <div className="col">{RenderTableMembers}</div>
              </div>

              <div className="row">
                <div className="col d-flex justify-content-end align-items-center ">
                  <h5 className="rowperpageFont">{constants.rowPerPage}</h5>
                  <div className="dropdown-rowPerPage ">
                    <Dropdown
                      menuPlacement={"top"}
                      options={[
                        { value: 5, label: "5", disabled: false },
                        { value: 10, label: "10", disabled: false },
                        { value: 20, label: "20", disabled: false },
                        { value: 50, label: "50", disabled: false },
                        { value: 100, label: "100", disabled: false },
                      ]}
                      controlBackgroundColor={colors.PRIMARY_BG}
                      placeholder={String(this.state.currentRowPerPage)}
                      handleOnChange={(target: IOptions) => {
                        this.setState(
                          {
                            currentPage: 0,
                            currentRowPerPage: Number(target.value),
                            dataQuery: {
                              ...this.state.dataQuery,
                              page: 1,
                              limit: Number(target.value),
                            },
                          },
                          () => {

                            this.props.getCustomer(pickBy(this.state.dataQuery))
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <h5 className="d-flex justify-content-end align-items-center rowperpageFont">
                  {`${this.state.startPage} - ${this.state.endPage}  of ${this.props.customerData.totalRecord}`}

                </h5>
                <div className="col-auto">
                  <Panginate
                    pageTotal={this.props.customerData.total}
                    initialPage={this.state.dataQuery.page - 1}
                    pageRangeDisplayed={this.state.currentPage}
                    onPageChange={this.handleClickChangePage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CustomerList;
