import React, { Component } from "react";
import { Breadcrumb, LabelBox } from "components";
import "./credits.style.scss";
import { map, noop } from "lodash";
import { RouteComponentProps } from "react-router-dom";
import errorModal from "components/Modal/components/Error/Error.component";
import { responseCode } from "constants/response";
import { number } from "utils";

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: ICreditContainerProps & ICreditContainerActionProps = {
  getCredits() {
    noop();
  },
  clearCredits() {
    noop();
  },
  credits: {
    betWaitTotal: 0,
    childCreditTotal: 0,
    credit: "",
    creditLimit: "",
    currency: "",
    dividendBalance: 0,
    maxBet: "",
    minBet: "",
    outstandingBalance: 0,
    totalMember: 0,
  },
  getCreditCode: 0,
  getCreditError: "",
  getCreditIsFetching: false,
};

const constants = {
  detailFinanceTitle: "ข้อมูลการเงิน",
  FinanceTitle: "บัญชีการเงิน",
  FinanceSubTitle: "รายละเอียด",
};

const mapCreditDetailName: {
  [type in IResponseAgentFinanceMe as string]: string;
} = {
  currency: "สกุลเงิน",
  totalMember: "ยอดสมาชิก",
  creditLimit: "เครดิตจำกัด",
  outstandingBalance: "ยอดค้างชำระ",
  betWaitTotal: "ยอดเดิมพันคงค้าง",
  credit: "เครดิตคงเหลือ",
  dividendBalance: "ยอดค้างปันผล",
  childCreditTotal: "เครดิตรวมสมาชิก",
  minBet: "การเดิมพันต่ำสุด/สูงสุด",
  maxBet: "ค่าสูงสุดการเดิมพัน",
};

class CreditsContainer extends Component<DefaultProps & RouteComponentProps> {
  static defaultProps = defaultProps;

  state: ICreditContainerState = {
    dataList: {
      betWaitTotal: 0,
      childCreditTotal: 0,
      credit: "",
      creditLimit: "",
      currency: "",
      dividendBalance: 0,
      maxBet: "",
      minBet: "",
      outstandingBalance: 0,
      totalMember: 0,
    },
  };

  componentDidMount() {
    this.props.getCredits();
  }

  componentDidUpdate(prevProps: ICreditContainerProps) {
    if (
      prevProps.getCreditIsFetching !== this.props.getCreditIsFetching &&
      !this.props.getCreditIsFetching
    )
      if (this.props.getCreditCode === responseCode.OK) {
        this.setState((_, props) => ({ dataList: props.credits }));
      } else {
        errorModal.show({
          description: this.props.getCreditError,
          action: errorModal.hide,
        });
      }
  }

  componentWillUnmount() {
    this.props.clearCredits();
  }

  renderCreditDetail = (value: IResponseAgentFinanceMe) =>
    map(value, (data, keys: string) => (
      <div className="col-4" key={keys}>
        {keys !== "maxBet" && keys !== "minBet" ? (
          <LabelBox
            textLabel={mapCreditDetailName[keys]}
            textValue={
              keys === "currency" || keys === "totalMember"
                ? data
                : number.decimalsFormatComma(data as string)
            }
          />
        ) : (
          keys === "minBet" && (
            <LabelBox
              textLabel={mapCreditDetailName[keys]}
              textValue={`${number.decimalsFormatComma(
                value.minBet
              )} / ${number.decimalsFormatComma(value.maxBet)}`}
            />
          )
        )}
      </div>
    ));

  onNavigate = () => this.props.history.push("/transferConfirmation");
  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.replace(path);
  };

  render() {
    const { dataList } = this.state;
    const RenderCreditDetail = this.renderCreditDetail(dataList);
    const navigates: IBreadcrumbItem[] = [
      { label: constants.FinanceTitle, active: false, path: `/credits` },
      { label: constants.FinanceSubTitle, active: true},
    ];
    return (
      <>
        <div className="paper-container">
          <div className="credit-container">
            <div className="row">
              <div className="col">
                <div className="sub-menu">
                  <Breadcrumb
                    items={navigates}
                    handleOnClickItem={this.handleOnClickBreadcrumb}
                  />
                </div>
              </div>
            </div>
            <div className="paper-title">{constants.FinanceTitle}</div>
            <div className="inline pr-3 paper-description">
              {constants.FinanceSubTitle}
            </div>
            <div className="paper-body">
              <div className="row">
                <div className="col-10">
                  <div className="box-shadow">
                    <div className="inline pr-3 paper-body-title">
                      {constants.detailFinanceTitle}
                    </div>
                    <div className="row">{RenderCreditDetail}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default CreditsContainer;
