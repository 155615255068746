import { get, map } from 'lodash'
import React, { FunctionComponent, useState } from 'react'

interface ITabItem {
    name: string
    count: number
}

interface ITabMenuProps {
    tabs: ITabItem[]
    onChangeTab: (tabName: string) => void
}

const TabMenuComponent: FunctionComponent<ITabMenuProps> = ({
    tabs = [],
    onChangeTab = () => { },
}) => {

    const [activeTab, setActiveTab] = useState(get(tabs, '0.name', ''))

    const handleOnChangeTab = (tabName: string) => {
        setActiveTab(tabName)
        onChangeTab(tabName)
    }

    const mappingMenu = map(tabs, (tab: ITabItem) => (
        <div className={`d-flex m5-r tab-button ${(activeTab === tab.name) ? "active-menu" : ""}`} key={`tab-${tab.name}`} id={`tab-${tab.name}-id`} onClick={() => handleOnChangeTab(tab.name)}>
            <div className={`d-flex flex-row align-items-center justify-content-center `}>
                <h6>{tab.name}</h6>
                {tab.count > 0 && <></>
                    // (<div className='number-box'>{tab.count}</div>)
                }
            </div>
        </div >
    ))

    return <div className="row p2-l">{mappingMenu}</div>
}

export default TabMenuComponent