import React, { SFC, Fragment } from "react";
import { noop } from "lodash";
import colors from "constants/colors";
import { ALink } from "components";
import "./breadcrumb.style.scss";

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: IBreadcrumbProps = {
  items: [],
  handleOnClickItem() {
    noop();
  },
};


const Breadcrumb: SFC<IBreadcrumbProps & DefaultProps> = (props) => {
  
  const RenderBreadcrumbItems = () => {
    const ItemsComponent = props.items.map((item, index) => {
      const breadcrumbFont = index === 0 
      const breadcrumEnd = index === props.items.length - 1

      return (
        <Fragment key={`${item.path}-${index}`}>
          <span className={`button-link-text-bold ${breadcrumbFont ?'':'p1-x'} ${item.active ? "breadcrumb-slash-active" : "breadcrumb-slash"} `}>
            {breadcrumbFont ? "" : ">"}
          </span>
          <ALink
            id={`breadcrumb-${index}`}
            bold
            color={!item.active ? colors.PRIMARY_GREEN : colors.TERTIARY_TEXT}
            fontSize={14}
            disabled={!item.active}
            path={item.path}
            key={`${item.label}-${index}`}
            onClick={() => !breadcrumEnd && (props.handleOnClickItem!(item.path)) }
          >
            {item.label}
          </ALink>
        </Fragment>
      );
    });

    return <div className="breadcrumb-wrapper">{ItemsComponent}</div>;
  };

  return (
    <div className="breadcrumb-container">
      <RenderBreadcrumbItems />
    </div>
  );
};

export default Breadcrumb;
