import axios from "axios";
import { transformer } from "utils";
import environment from "../../constants/environment";

//API AGENT 97 ขอข้อมูลหวยที่ควบคุมด้วยระบบอั้นเลขทั้งหมด
const fetchGetRateReduceLotteryAgent = (query: IV2QueryGetRateReduceLotteryAgent) => {
  const convertQueryToString = new URLSearchParams(
    transformer.snakecaseTransform(query)
  ).toString();
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${
    environment.endpoint.getAgentLotteryRateReduce
  }`;
  return axios.get<IAPIResponse<IV2GetRateReduceLotteryAgent[]>>(
    `${endpoint}?${convertQueryToString}`
  );
};
//API AGENT 98 อัพเดทระบบอั้นหวย
const updateRateReduceLotteryAgentUpdate = (query: IV2UpdateRateReduceLotteryAgent) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${
    environment.endpoint.postAgentLotteryRateReduceUpdate
  }`;
  return axios.post<IAPIResponse<IV2GetRateReduceLotteryAgent>>(`${endpoint}`, query);
};
//API AGENT 99 ขอข้อมูลหวยที่ควบคุมด้วยระบบอั้นเลขทั้งหมด
const fetchRateReduceLotteryAgentHost = (query: IV2QueryGetRateReduceHost) => {
  const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${
    environment.endpoint.postAgentLotteryRateReduceHost
  }`;
  return axios.post<IAPIResponse<IV2GetRateReduceHost>>(`${endpoint}`, query);
};


export {
  fetchGetRateReduceLotteryAgent,
  updateRateReduceLotteryAgentUpdate,
  fetchRateReduceLotteryAgentHost
};
