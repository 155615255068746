import axios, { AxiosResponse } from "axios"
import environment from '../../constants/environment'
import { transformer } from 'utils'

//56.GET: api/v2/agent/customer ขอดูรายละเอียดระหว่างคนในระบบกับนอกระบบ
const fetchCustomer = (query: IV2QueryAgentCustomer & IPaginationQuery<any>): Promise<AxiosResponse<IAPIResponse<IPagination<IV2RespAgentCustomer>>>> => {
    const convertQueryToString = new URLSearchParams(transformer.snakecaseTransform(query)).toString()
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.getCustomer}`
    return axios.get(`${endpoint}?${convertQueryToString}`)
}

//57.GET: api/v2/agent/customer/note ดูบันทึกประวัติ หรือข้อความเตือนความจำต่อผู้ใช้นอกระบบ
const fetchCustomerNote = (query: IV2QueryAgentCustomerNote) => {
    const convertQueryToString = new URLSearchParams(transformer.snakecaseTransform(query)).toString()
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.getCustomerNote}`
    return axios.get(`${endpoint}?${convertQueryToString}`)
}

//79.POST: api/v2/agent/customer/note บันทึกประวัติ หรือข้อความเตือนความจำต่อผู้ใช้นอกระบบ
const fetchPostCustomerNote = (query: IV2PostAgentCustomerNote) => {
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.getCustomerNote}`
    return axios.post(`${endpoint}`, query)
}


export {
    fetchCustomer,
    fetchCustomerNote,
    fetchPostCustomerNote,
  
}