import { connect } from "react-redux";
import FinanceChildContainer from "./FinanceChild.container";
import { onChangeIsLoaddingAction } from "../../reduxs/Loader/action";
import { getFinanceChild } from "../../reduxs/finance/child/action";
import { getFinanceChildMe } from "../../reduxs/finance/childMe/action";
import membersSelector from "../../reduxs/members/selector";
import { getChildBet } from "../../reduxs/child/childBet/action";
import { getChildMembers } from "../../reduxs/members/memberSummary/action";
import { postFinanceChildApprove } from "reduxs/finance/childApprove/action";
import { postFinanceChildRequest } from "reduxs/finance/childRequest/action";
import { getMe } from "reduxs/me/me/action";


const mapStateToProps = (state: RootReducers): IFinanceChildProps => {
  const agentChildList = membersSelector.memberChild(state);
  return {
    financeChildCode: state.finance.financeChild.code,
    financeChildError: state.finance.financeChild.error,
    financeChildIsFetching: state.finance.financeChild.isFetching,
    financeChildData: state.finance.financeChild.data,

    financeChildProps:state.finance.financeChild,

    financeChildMeCode: state.finance.financeChildMe.code,
    financeChildMeError: state.finance.financeChildMe.error,
    financeChildMeIsFetching: state.finance.financeChildMe.isFetching,
    financeChildMeData: state.finance.financeChildMe.data,
    dataReseive: state.finance.financeChildMe.dataReseive,
    meData: state.me.me.data,
 
    childBetCode: state.child.getChildBet.code,
    childBetError: state.child.getChildBet.error,
    childBetIsFetching: state.child.getChildBet.isFetching,
    childBetData: state.child.getChildBet.data,

    agentChildCode: agentChildList.code,
    agentChildError: agentChildList.error,
    agentChildIsFetching: agentChildList.isFetching,
    agentChildData: agentChildList.data,

    financeChildApproveCode: state.finance.financeChildApprove.code,
    financeChildApproveError: state.finance.financeChildApprove.error,
    financeChildApproveIsFetching: state.finance.financeChildApprove.isFetching,
    financeChildApproveData: state.finance.financeChildApprove.data,

    financeChildRequestCode: state.finance.financeChildRequest.code,
    financeChildRequestError: state.finance.financeChildRequest.error,
    financeChildRequestIsFetching: state.finance.financeChildRequest.isFetching,
    financeChildRequestData: state.finance.financeChildRequest.data,
  };
};

const mapDispatchToProps: IFinanceChildActionProps = {
  getMe,
  onChangeIsLoaddingAction,
  getFinanceChild,
  getFinanceChildMe,
  getChildBet,
  getChildMembers,
  postFinanceChildApprove,
  postFinanceChildRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinanceChildContainer);
