import { RouteComponentProps } from "react-router-dom";
import React, { Component } from "react";
import {
  Breadcrumb,
  Button,
  ResponsiveIcon,
  Dropdown,
  ConfirmModal,
  ErrorModal,
  LoadingCircle,
} from "components";
import { handleOnClickBreadcrumb } from "utils";
import "./NumberBan.style.scss";
import { map, isEmpty, includes, get, noop } from "lodash";
import { CODE_TO_SHORT_NAME, LOTTO_FLAG_ALPHA } from "constants/variables";
import LottoFlags from "assets/images/global/flags";
import point from "assets/images/global/icon/point.png";
import colors from "constants/colors";
import routes from "constants/routes";
import { responseCode, responseMessage } from "constants/response";
import collectBank from "assets/images/global/bank";
type DefaultProps = Readonly<typeof defaultProps>;
const defaultProps: INumberBanProps & INumberBanActionProps = {
  getBanLottery() {
    noop();
  },
  postCancelNumberBan() {
    noop();
  },
  onChangeIsLoaddingAction() {},
  getNumberBanLotteryList: [],
  getNumberBanLotteryListisFetching: false,
  getNumberBanLotteryCode: 0,
  getNumberBanLotteryError: "",
};

const constants = {
  breadcrumb: {
    main: "ภาพรวม",
    numberBan: "รายการเลขอั้น",
  },

  title: "รายการเลขอั้น",
  overviewNumberBan: "ภาพรวมเลขอั้น",
  addLottery: "เพิ่มหวย",
  fullSlip: "เต็มหลุม",
  close: "ปิดรับ",
  headClose: "หัวปิดรับ",
  ban: "อั้น",
  dataNotFound: "ไม่มีข้อมูลเลขอั้น โปรดเพิ่มหวยที่จะควบคุมด้วยระบบเลขอั้น",
  cancelNumbersBan: "คุณต้องการยกเลิกปิดรับเลข",
  cancelNumbersBanDescrip: "คุณต้องการยกเลิกการปิดรับเลข?",
};

const mappingTypeNumbersHoles: IHeaderTableNumberBan[] = [
  { name: "หวย", width: "20%" },
  { name: "3 บน", type: "THREE_UP", width: "11.6%" },
  { name: "3 โต๊ด", type: "THREE_TOAST", width: "11.6%" },
  { name: "2 บน", type: "TWO_UP", width: "11.6%" },
  { name: "2 ล่าง", type: "TWO_DOWN", width: "11.6%" },
  { name: "วิ่งบน", type: "RUN_UP", width: "11.6%" },
  { name: "วิ่งล่าง", type: "RUN_DOWN", width: "11.6%" },
  { name: "เฉลี่ยปิดรับ", width: "10%" },
];

class NumberBan extends Component<
  INumberBanState &
    INumberBanActionProps &
    INumberBanProps &
    DefaultProps &
    RouteComponentProps
> {
  state: INumberBanState = {
    lotteryNumberBan: [],
  };

  DROPDOWN_MENU: {
    name: string;
    value: string;
  }[] = [
    {
      name: "จัดการเลขอั้น",
      value: "NumbersBanManage",
    },
    {
      name: "จัดการหลุมเลข",
      value: "NumbersHoleManage",
    },
    {
      name: "ยกเลิกการปิดรับเลขทั้งหมด",
      value: "CancleAllNumbersBan",
    },
  ];

  componentDidMount() {
    this.props.getBanLottery();
  }

  componentDidUpdate(prevProps: INumberBanProps) {
    if (
      prevProps.getNumberBanLotteryListisFetching !==
        this.props.getNumberBanLotteryListisFetching &&
      !this.props.getNumberBanLotteryListisFetching
    ) {
      if (
        this.props.getNumberBanLotteryCode !== responseCode.OK &&
        this.props.getNumberBanLotteryError !== ""
      ) {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            this.props.getNumberBanLotteryCode,
            responseMessage()[0]
          ),
        });
      } else {
        this.setState({ lotteryNumberBan: this.props.getNumberBanLotteryList });
      }
    }
  }

  onClickDropdown = (target: IOptions, item: IV2GetRateReduceLotteryAgent) => {
    if (target.value === "NumbersBanManage") {
      this.props.history.push(routes.numberBanManage.path, {
        ratereducefacId: item.ratereducefacId,
        ratereducefacAgentLotsetId: item.ratereducefacAgentLotsetId,
        name: item.name,
      });
    } else if (target.value === "NumbersHoleManage") {
      this.props.history.push(routes.numbersHolesManage.path, {
        agentLotsetId: item.ratereducefacAgentLotsetId,
        name: item.name,
      });
    } else {
      ConfirmModal.show({
        action: async () => {
          await this.props.postCancelNumberBan({
            ratereducefacId: item.ratereducefacId,
            ratereducefacAgentLotsetId: item.ratereducefacAgentLotsetId,
            name: item.name,
            isBanCancel: true,
          });
          this.props.getBanLottery();
          ConfirmModal.hide();
        },
        cancelAction: () => {
          ConfirmModal.hide();
        },
        title: constants.cancelNumbersBan,
        description: constants.cancelNumbersBanDescrip,
        moreMarginDescription: true,
      });
    }
  };

  renderTableHead = (values: Array<IHeaderTableNumberBan>) => {
    const mapHeadTable = map(values, (data: IHeaderTableNumberBan, index) => {
      return (
        <th
          className="text-center td-span"
          key={index}
          style={{ width: data.width }}
        >
          <h5 className={`text-header secondary-text`}>{data.name}</h5>
        </th>
      );
    });

    return (
      <>
        <tr>{mapHeadTable}</tr>
      </>
    );
  };

  renderTableBody = (values: any) => {
    const dataEmpty = (
      <tr className="tableisEmpty" style={{ height: 42.67 }}>
        <td className="text-center tertiary-text" colSpan={12}>
          {constants.dataNotFound}
        </td>
      </tr>
    )

    const loadingTable= (
      <tr>
        <td className="text-center" colSpan={15}>
        <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
        </td>
      </tr>
    )
 

    const mapData = map(values, (item) => {
      const slug = ("LOTTER_" + item.name) as TLottoSlug;
      const flag_bank =
        Object.keys(collectBank).findIndex((item) => "LOTTER_" + item === slug) === -1
          ? ""
          : "flag-bank";
      return (
        <tr
          className={`row-numbersholes-list`}
          style={{
            height:
              isEmpty(item.ratereduceRunDown) &&
              isEmpty(item.ratereduceRunUp) &&
              isEmpty(item.ratereduceThreeToast) &&
              isEmpty(item.ratereduceThreeUp) &&
              isEmpty(item.ratereduceTwoUp) &&
              isEmpty(item.ratereduceTwoDown)
                ? 59
                : 200,
          }}
        >
          <td className="text-left">
            <div className="lotto-item p3-l">
              <div className="box-icon">
                <ResponsiveIcon
                  icon={
                    get(
                      LottoFlags[
                        LOTTO_FLAG_ALPHA[
                          includes(item.name, "YEGEE")
                            ? "YEGEE"
                            : (item.name as TLottoType)
                        ]
                      ],
                      "Icon",
                      ""
                    )!
                  }
                  alt="flag"
                  className={`lotto-action-card-flag ${flag_bank} ${slug}`}
                />
              </div>
              <div className="d-flex flex-column align-items-start">
                <span className="lotto-header-item">
                  {includes(item.name, "YEGEE")
                    ? `ยี่กี รอบที่ ${Number(item.name.replace("YEGEE_", ""))}`
                    : CODE_TO_SHORT_NAME[item.name as TLottoType]}
                </span>
              </div>
            </div>
          </td>
          {isEmpty(item.ratereduceRunDown) &&
          isEmpty(item.ratereduceRunUp) &&
          isEmpty(item.ratereduceThreeToast) &&
          isEmpty(item.ratereduceThreeUp) &&
          isEmpty(item.ratereduceTwoUp) &&
          isEmpty(item.ratereduceTwoDown) ? (
            <>
              {" "}
              <td className="text-center td-span">
                {<div className="new-create-box" />}
              </td>
              <td className="text-center td-span">
                <div className="new-create-box" />
              </td>
              <td className="text-center td-span">
                <div className="new-create-box" />
              </td>
              <td className="text-center td-span">
                <div className="new-create-box" />
              </td>
              <td className="text-center td-span">
                <div className="new-create-box" />
              </td>
              <td className="text-center td-span">
                <div className="new-create-box" />
              </td>
            </>
          ) : (
            <>
              {" "}
              <td className="td-span">
                {
                  <div className="numbers-ban-box">
                     {item.ratereduceThreeUp.map(
                    (subItem: IV2RateReduceNumber, index: number, array: any) => {
                      const element =
                        subItem.ratereduceNumber.charAt(0) ===
                        (array[index - 1]
                          ? array[index - 1].ratereduceNumber.charAt(0)
                          : "0") ? (
                          <span
                            style={{
                              color: subItem.ratereduceIsHostBanned
                                ? colors.PRIMARY_YELLOW 
                                : subItem.ratereduceIsBanned
                                ? colors.PRIMARY_RED
                                : subItem.ratereduceIsNumholFull
                                ? colors.SECONDARY_PURPLE
                                : colors.SECONDARY_TEXT,
                            }}
                          >
                            {subItem.ratereduceNumber}{" "}
                          </span>
                        ) : (
                          <>
                            <div className={"new-digit-num"}></div>
                            <span
                              style={{
                                color: subItem.ratereduceIsHostBanned
                                ? colors.PRIMARY_YELLOW 
                                : subItem.ratereduceIsBanned
                                ? colors.PRIMARY_RED
                                : subItem.ratereduceIsNumholFull
                                ? colors.SECONDARY_PURPLE
                                : colors.SECONDARY_TEXT,
                              }}
                            >
                              {subItem.ratereduceNumber}{" "}
                            </span>
                          </>
                        );
                      return element;
                    }
                  )}
                  </div>
                }
              </td>
              <td className="td-span">
                <div className="numbers-ban-box">
                {item.ratereduceThreeToast.map(
                    (subItem: IV2RateReduceNumber, index: number, array: any) => {
                      const element =
                        subItem.ratereduceNumber.charAt(0) ===
                        (array[index - 1]
                          ? array[index - 1].ratereduceNumber.charAt(0)
                          : "0") ? (
                          <span
                            style={{
                              color: subItem.ratereduceIsHostBanned
                                ? colors.PRIMARY_YELLOW 
                                : subItem.ratereduceIsBanned
                                ? colors.PRIMARY_RED
                                : subItem.ratereduceIsNumholFull
                                ? colors.SECONDARY_PURPLE
                                : colors.SECONDARY_TEXT,
                            }}
                          >
                            {subItem.ratereduceNumber}{" "}
                          </span>
                        ) : (
                          <>
                            <div className={"new-digit-num"}></div>
                            <span
                              style={{
                                color: subItem.ratereduceIsHostBanned
                                ? colors.PRIMARY_YELLOW 
                                : subItem.ratereduceIsBanned
                                ? colors.PRIMARY_RED
                                : subItem.ratereduceIsNumholFull
                                ? colors.SECONDARY_PURPLE
                                : colors.SECONDARY_TEXT,
                              }}
                            >
                              {subItem.ratereduceNumber}{" "}
                            </span>
                          </>
                        );
                      return element;
                    }
                  )}
                </div>
              </td>
              <td className="td-span">
                <div className="numbers-ban-box">
                  {item.ratereduceTwoUp.map(
                    (subItem: IV2RateReduceNumber, index: number, array: any) => {
                      const element =
                        subItem.ratereduceNumber.charAt(0) ===
                        (array[index - 1]
                          ? array[index - 1].ratereduceNumber.charAt(0)
                          : "0") ? (
                          <span
                            style={{
                              color: subItem.ratereduceIsHostBanned
                                ? colors.PRIMARY_YELLOW 
                                : subItem.ratereduceIsBanned
                                ? colors.PRIMARY_RED
                                : subItem.ratereduceIsNumholFull
                                ? colors.SECONDARY_PURPLE
                                : colors.SECONDARY_TEXT,
                            }}
                          >
                            {subItem.ratereduceNumber}{" "}
                          </span>
                        ) : (
                          <>
                            <div className={"new-digit-num"}></div>
                            <span
                              style={{
                                color: subItem.ratereduceIsHostBanned
                                ? colors.PRIMARY_YELLOW 
                                : subItem.ratereduceIsBanned
                                ? colors.PRIMARY_RED
                                : subItem.ratereduceIsNumholFull
                                ? colors.SECONDARY_PURPLE
                                : colors.SECONDARY_TEXT,
                              }}
                            >
                              {subItem.ratereduceNumber}{" "}
                            </span>
                          </>
                        );
                      return element;
                    }
                  )}
                </div>
              </td>
              <td className="td-span">
                <div className="numbers-ban-box">
                {item.ratereduceTwoDown.map(
                    (subItem: IV2RateReduceNumber, index: number, array: any) => {
                      const element =
                        subItem.ratereduceNumber.charAt(0) ===
                        (array[index - 1]
                          ? array[index - 1].ratereduceNumber.charAt(0)
                          : "0") ? (
                          <span
                            style={{
                              color: subItem.ratereduceIsHostBanned
                              ? colors.PRIMARY_YELLOW 
                              : subItem.ratereduceIsBanned
                              ? colors.PRIMARY_RED
                              : subItem.ratereduceIsNumholFull
                              ? colors.SECONDARY_PURPLE
                              : colors.SECONDARY_TEXT,
                            }}
                          >
                            {subItem.ratereduceNumber}{" "}
                          </span>
                        ) : (
                          <>
                            <div className={"new-digit-num"}></div>
                            <span
                              style={{
                                color: subItem.ratereduceIsHostBanned
                                ? colors.PRIMARY_YELLOW 
                                : subItem.ratereduceIsBanned
                                ? colors.PRIMARY_RED
                                : subItem.ratereduceIsNumholFull
                                ? colors.SECONDARY_PURPLE
                                : colors.SECONDARY_TEXT,
                              }}
                            >
                              {subItem.ratereduceNumber}{" "}
                            </span>
                          </>
                        );
                      return element;
                    }
                  )}
                </div>
              </td>
              <td className="td-span">
                <div className="numbers-ban-box">
                  {item.ratereduceRunUp.map((subItem: IV2RateReduceNumber) => (
                    <span
                      style={{
                        color: subItem.ratereduceIsHostBanned
                                ? colors.PRIMARY_YELLOW 
                                : subItem.ratereduceIsBanned
                                ? colors.PRIMARY_RED
                                : subItem.ratereduceIsNumholFull
                                ? colors.SECONDARY_PURPLE
                                : colors.SECONDARY_TEXT,
                      }}
                    >
                      {subItem.ratereduceNumber}{" "}
                    </span>
                  ))}
                </div>
              </td>
              <td className="td-span">
                <div className="numbers-ban-box">
                  {item.ratereduceRunDown.map((subItem: IV2RateReduceNumber) => (
                    <span
                      style={{
                        color: subItem.ratereduceIsHostBanned
                        ? colors.PRIMARY_YELLOW 
                        : subItem.ratereduceIsBanned
                        ? colors.PRIMARY_RED
                        : subItem.ratereduceIsNumholFull
                        ? colors.SECONDARY_PURPLE
                        : colors.SECONDARY_TEXT,
                      }}
                    >
                      {subItem.ratereduceNumber}{" "}
                    </span>
                  ))}
                </div>
              </td>
            </>
          )}

          <td className="text-center td-span">
            <div className="d-flex align-items-center justify-content-between">
              <div></div>
              <span style={{ marginLeft: 25 , fontWeight: item.ratereducefacBannedAvg === 100 ? 700 : 400 }}>
                {item.ratereducefacBannedAvg}%
              </span>
              <div onClick={(e) => e.stopPropagation()}>
                <Dropdown
                  isManualOpenMenu
                  options={this.DROPDOWN_MENU.map((item) => {
                    return {
                      value: item.value,
                      label: (
                        <div className="dropdowncontainer">{item.name}</div>
                      ),
                      disabled: false,
                    };
                  })}
                  iconOpen={point}
                  iconClose={point}
                  iconStyles={{ width: 4, height: 16 }}
                  controlBackgroundColor={colors.PRIMARY_BG}
                  menuStyles={{ width: "160px", left: "-170px", top: "0px" }}
                  valueContainerStyles={{
                    padding: "0px",
                    maxWidth: "0px",
                  }}
                  handleOnChange={(target: IOptions) => {
                    console.log("targethandleOnChange", target);
                    this.onClickDropdown(target, item);
                  }}
                />
              </div>
            </div>
          </td>
        </tr>
      );
    });
    return this.props.getNumberBanLotteryListisFetching ? loadingTable : !isEmpty(values) ? mapData : dataEmpty
  };

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.breadcrumb.main, path: "/", active: false },
      { label: constants.breadcrumb.numberBan, active: true },
    ];

    const TableHead = this.renderTableHead(mappingTypeNumbersHoles);
    const TableBody = this.renderTableBody(this.state.lotteryNumberBan);
    return (
      <div className="paper-container numbers-ban-container">
        <div className="row">
          <div className="col">
            <div className="sub-menu">
              <Breadcrumb
                items={navigates}
                handleOnClickItem={handleOnClickBreadcrumb}
              />
            </div>
          </div>
        </div>

        <div className="d-flex flex-row justify-content-between mt-4">
          <div>
            <div className="paper-title mt-0">{constants.title}</div>
            <div className="paper-description">
              {constants.overviewNumberBan}
            </div>
          </div>
          <div className="d-flex flex-row align-items-center">
            <Button
              id={"add-member"}
              size={"moreMedium"}
              textSize={18}
              text={constants.addLottery}
              onClick={() => {
                this.props.history.push(routes.NumbersBanAddLottery.path);
              }}
            />
          </div>
        </div>

        <div className="paper-body">
          <div className="box-shadow">
            <div className="d-flex flex-row justify-content-end ">
              <div className="d-flex ">
                <div className="purple-rectangle"></div>
                <span className="px-1 examineFont">{constants.fullSlip}</span>
              </div>
              <div className="d-flex">
                <div className="red-rectangle"></div>
                <span className="px-1 examineFont">{constants.close}</span>
              </div>
              <div className="d-flex">
                <div className="yellow-rectangle"></div>
                <span className="px-1 examineFont">{constants.headClose}</span>
              </div>
              <div className="d-flex">
                <div className="black-rectangle"></div>
                <span className="pl-1 examineFont">{constants.ban}</span>
              </div>
            </div>

            {/* ตาราง */}
            <div className="row" style={{ marginTop: 17 }}>
              <div className="col-12">
                <div className="table-container">
                  <div className="table-responseive-xl">
                    <table className="w-100">
                      <thead className="header-table">{TableHead}</thead>
                      <tbody>{TableBody}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NumberBan;
