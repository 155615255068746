import { AxiosError, AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { ActionType, createReducer } from "typesafe-actions";
import { responseConverter } from "../../../../utils";
import * as betRateAction from "./actionCreator";
import { betRateState, betNumberState } from "./constants";

export type BetRateActionType = ActionType<typeof betRateAction>;

const betRate = createReducer<
  ReducerState<IResponseAgentLotteryRateClient[]>,
  BetRateActionType
>(betRateState)
  .handleAction(
    betRateAction.getRateAction.request,
    (state: ReducerState<IResponseAgentLotteryRateClient[]>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    betRateAction.getRateAction.success,
    (
      state: ReducerState<IResponseAgentLotteryRateClient[]>,
      action: BetRateActionType
    ) => {
      const payload: AxiosResponse<
        IAPIResponse<IResponseAgentLotteryRateClient[]>
      > = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
      };
    }
  )
  .handleAction(
    betRateAction.getRateAction.failure,
    (
      state: ReducerState<IResponseAgentLotteryRateClient[]>,
      action: BetRateActionType
    ) => {
      const payload: AxiosError<
        IAPIResponse<IResponseAgentLotteryRateClient[]>
      > = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );

const betNumber = createReducer<
  ReducerState<IResponseAgentLotteryRateNumber[]>,
  BetRateActionType
>(betNumberState)
  .handleAction(
    betRateAction.getNumberAction.request,
    (state: ReducerState<IResponseAgentLotteryRateNumber[]>) => {
      return {
        ...state,
        isFetching: true,
      };
    }
  )
  .handleAction(
    betRateAction.getNumberAction.success,
    (
      state: ReducerState<IResponseAgentLotteryRateNumber[]>,
      action: BetRateActionType
    ) => {
      const payload: AxiosResponse<
        IAPIResponse<IResponseAgentLotteryRateNumber[]>
      > = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        data: payload.data.data,
      };
    }
  )
  .handleAction(
    betRateAction.getNumberAction.failure,
    (
      state: ReducerState<IResponseAgentLotteryRateNumber[]>,
      action: BetRateActionType
    ) => {
      const payload: AxiosError<IAPIResponse> = action.payload;
      const convertedResponse = responseConverter.getMessage(payload);
      return {
        ...state,
        isFetching: false,
        code: convertedResponse.code,
        error: convertedResponse.message,
      };
    }
  );

export default combineReducers({
  betRate,
  betNumber,
});
