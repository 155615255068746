import { noop } from "lodash";
import React, { Component } from "react";
import { IncomeExpenseTable } from "../incomeExpenseTable";
import { BetTable } from "../betTable";
import { NoteTable } from "../NoteTable";

const defaultProps: ITableMenuListProps = {
  activeMenu: "",
  getBetGroup() {
    noop();
  },
  onChangeIsLoaddingAction() {},
  locationData: 0,
  onload() {},
  betgroupIsFetching: false,
  betgroupCode: 0,
  betgroupError: "",
  betGroupData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  getFinanceCustomerTx() {
    noop();
  },
  customerTxIsFetching: false,
  customerTxCode: 0,
  customerTxError: "",
  customerTxData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  postcustomerNote() {
    noop();
  },
  getCustomerNote() {
    noop();
  },

  customerNoteIsFetching: false,
  customerNoteCode: 0,
  customerNoteError: "",
  customerNoteData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  postcustomerNoteCode: 0,
  postcustomerNoteError: "",
  postcustomerNoteIsFetching: false,
  clickChangeRoute(){
    noop()
  },
  clickChangeRoute2(){
    noop()
  }
};

class TableMenuList extends Component<ITableMenuListProps> {
  static defaultProps = defaultProps;


  renderTable = (activeMenu: string) => {
    if (activeMenu === "รายรับรายจ่าย") {
      return (
        //   <></>
        <IncomeExpenseTable
          clickChangeRoute={this.props.clickChangeRoute}  
          locationData={this.props.locationData}
          getBetGroup={this.props.getBetGroup}
          onload={this.props.onload}
          betgroupCode={this.props.betgroupCode}
          betgroupError={this.props.betgroupError}
          betgroupIsFetching={this.props.betgroupIsFetching}
          betGroupData={this.props.betGroupData}
        />
      );
    }
    if (activeMenu === "การเดิมพัน") {
      return (
        // <></>
        <BetTable
          clickChangeRoute={this.props.clickChangeRoute2}  
          locationData={this.props.locationData}
          getFinanceCustomerTx={this.props.getFinanceCustomerTx}
          onload={this.props.onload}
          customerTxCode={this.props.customerTxCode}
          customerTxError={this.props.customerTxError}
          customerTxIsFetching={this.props.customerTxIsFetching}
          customerTxData={this.props.customerTxData}
        />
      );
    }
    if (activeMenu === "ดูบันทึกประวัติ") {
      return (
        <NoteTable
          locationData={this.props.locationData}
          getCustomerNote={this.props.getCustomerNote}
          onload={this.props.onload}
          customerNoteCode={this.props.customerNoteCode}
          customerNoteError={this.props.customerNoteError}
          customerNoteIsFetching={this.props.customerNoteIsFetching}
          customerNoteData={this.props.customerNoteData}
          postcustomerNote={this.props.postcustomerNote}
          postcustomerNoteCode={this.props.postcustomerNoteCode}
          postcustomerNoteError={this.props.postcustomerNoteError}
          postcustomerNoteIsFetching={this.props.postcustomerNoteIsFetching}
        />
      );
    }
  };
  render() {
    const activeMenu = this.props.activeMenu;
    return (
      <div
        className={`table-container ${
          activeMenu === "รายการเคลียร์" && "table-clear"
        }`}
      >
        <table className="col ">{this.renderTable(activeMenu)}</table>
      </div>
    );
  }
}

export default TableMenuList;
