import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import {
  getYegeeSumAction,
  unlistenYegeeSumSocketAction,
  updateYegeeSumAction,
  listenYegeeSumSocketAction,
  clearYegeeSumAction,
} from "../actionCreator";
import { fetchGetYegeeSum } from "../services";

const getYegeeSum = (query: ILottoRoundQuery) => async (dispatch: Dispatch) => {
  dispatch(getYegeeSumAction.request({}));
  return fetchGetYegeeSum(query)
    .then((res: AxiosResponse<IAPIResponse<string>>) => {
      dispatch(getYegeeSumAction.success(res));
    })
    .catch((error: AxiosError<IAPIResponse>) => {
      dispatch(getYegeeSumAction.failure(error));
    });
};

const updateYegeeSum = (res: IAPIResponse<string>) => (dispatch: Dispatch) => {
  dispatch(updateYegeeSumAction.request({}));
  dispatch(updateYegeeSumAction.success(res));
};

const listenYeegeSumSocket = (query: ILottoRoundQuery) => (
  dispatch: Dispatch
) => {
  dispatch(listenYegeeSumSocketAction(query));
};

const unlistenYegeeSumSocket = (query: ILottoRoundQuery) => (
  dispatch: Dispatch
) => {
  dispatch(unlistenYegeeSumSocketAction(query));
};

const clearYegeeSum = () => (dispatch: Dispatch) => {
  dispatch(clearYegeeSumAction({}));
};

export {
  getYegeeSum,
  updateYegeeSum,
  unlistenYegeeSumSocket,
  listenYeegeSumSocket,
  clearYegeeSum,
};
