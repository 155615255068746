export const CREDITS_REQUEST = "CREDITS_REQUEST";
export const CREDITS_SUCCESS = "CREDITS_SUCCESS";
export const CREDITS_FAILURE = "CREDITS_FAILURE";
export const CREDITS_CANCEL = "CREDITS_CANCEL";

export const TRANSACTIONLIST_REQUEST = "TRANSACTIONLIST_REQUEST";
export const TRANSACTIONLIST_SUCCESS = "TRANSACTIONLIST_SUCCESS";
export const TRANSACTIONLIST_FAILURE = "TRANSACTIONLIST_FAILURE";
export const TRANSACTIONLIST_CANCEL = "TRANSACTIONLIST_CANCEL";

export const TRANSFERCREDITS_REQUEST = "TRANSFERCREDITS_REQUEST";
export const TRANSFERCREDITS_SUCCESS = "TRANSFERCREDITS_SUCCESS";
export const TRANSFERCREDITS_FAILURE = "TRANSFERCREDITS_FAILURE";
export const TRANSFERCREDITS_CANCEL = "TRANSFERCREDITS_CANCEL";

export const TRANSFERCONFIRMATION_REQUEST = "TRANSFERCONFIRMATION_REQUEST";
export const TRANSFERCONFIRMATION_SUCCESS = "TRANSFERCONFIRMATION_SUCCESS";
export const TRANSFERCONFIRMATION_FAILURE = "TRANSFERCONFIRMATION_FAILURE";
export const TRANSFERCONFIRMATION_CANCEL = "TRANSFERCONFIRMATION_CANCEL";

export const TRANSFERCREDITDESCRIPTION_REQUEST =
  "TRANSFERCREDITDESCRIPTION_REQUEST";
export const TRANSFERCREDITDESCRIPTION_SUCCESS =
  "TRANSFERCREDITDESCRIPTION_SUCCESS";
export const TRANSFERCREDITDESCRIPTION_FAILURE =
  "TRANSFERCREDITDESCRIPTION_FAILURE";
export const TRANSFERCREDITDESCRIPTION_CANCEL =
  "TRANSFERCREDITDESCRIPTION_CANCEL";

export const PAYREQUEST_REQUEST = "PAYREQUEST_REQUEST";
export const PAYREQUEST_SUCCESS = "PAYREQUEST_SUCCESS";
export const PAYREQUEST_FAILURE = "PAYREQUEST_FAILURE";

export const PAYACCEPT_REQUEST = "PAYACCEPT_REQUEST";
export const PAYACCEPT_SUCCESS = "PAYACCEPT_SUCCESS";
export const PAYACCEPT_FAILURE = "PAYACCEPT_FAILURE";

export const creditsState: ReducerState<IResponseAgentFinanceMe> = {
  isFetching: false,
  code: 0,
  data: {
    betWaitTotal: 0,
    childCreditTotal: 0,
    credit: "",
    creditLimit: "",
    currency: "",
    dividendBalance: 0,
    maxBet: "",
    minBet: "",
    outstandingBalance: 0,
    totalMember: 0,
  },
  error: "",
};

export const transactionListsState: ReducerState<
  IPagination<IResponseAgentFinList>
> = {
  isFetching: false,
  code: 0,
  data: {
  totalRecord:0,
    dataList: [],
    total: 0,
    limit: 0,
    page: 0,
  },
  error: "",
};

export const transactionState: ReducerState<IResponseAgentFinList[]> = {
  isFetching: false,
  code: 0,
  data: [],
  error: "",
};

export const transferCreditsState: ReducerState<IResponseAgentFinancePay[]> = {
  isFetching: false,
  code: 0,
  data: [],
  error: "",
};
export const transferCreditListState: ReducerState<
  IPagination<IResponseAgentFinancePay>
> = {
  isFetching: false,
  code: 0,
  data: {
    totalRecord:0,
    dataList: [],
    total: 0,
    limit: 0,
    page: 0,
  },
  error: "",
};

export const transferConfirmationsState: ReducerState<
  IResponseAgentRecvList[]
> = {
  isFetching: false,
  code: 0,
  data: [],
  error: "",
};

export const transferConfirmationListState: ReducerState<
  IPagination<IResponseAgentRecvList>
> = {
  isFetching: false,
  code: 0,
  data: {
    totalRecord:0,
    dataList: [],
    total: 0,
    limit: 0,
    page: 0,
  },
  error: "",
};

export const transferCreditDescriptionState: ReducerState<
  IResponseAgentFinancePayDetail[]
> = {
  isFetching: false,
  code: 0,
  data: [],
  error: "",
};

export const PayRequestState: ReducerState<IAgentPayRequest> = {
  isFetching: false,
  code: 0,
  data: {
    id: 0,
    balance: 0,
  },
  error: "",
};

export const PayAcceptState: ReducerState<IAgentPayAccept> = {
  isFetching: false,
  code: 0,
  data: {
    id: 0,
    status: "",
  },
  error: "",
};