import { connect } from "react-redux";
import { getMe } from "reduxs/me/me/action";
import lottoSelector from "reduxs/lotto/selector";
import NumberBanManageContainer from "./NumberBanManage.container";
import { getDataV2LotteryList } from "reduxs/lotto/list/action";
const mapStateToProps = (state: RootReducers): INumberBanManageContainerProps => {
  const lottoScheduleV2 = lottoSelector.listV2(state);
  return {
    lottoScheduleV2Data: lottoScheduleV2.data,
    lottoScheduleV2IsFetching: lottoScheduleV2.isFetching,
    lottoScheduleV2Code: lottoScheduleV2.code,
    lottoScheduleV2Error: lottoScheduleV2.error,
  };
};

const mapDispatchToProps: INumberBanManageContainerActionProps = {
  getMe,
  getDataV2LotteryList
};

export default connect(mapStateToProps, mapDispatchToProps)(NumberBanManageContainer);
