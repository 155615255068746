import React, { Component } from "react";
import { isEqual, isEmpty, noop, isNull } from "lodash";
import routes from "configs/routes";
import { Modal, AlertNotification } from "components";
import {
  BrowserRouter as Router,
  RouteComponentProps,
  Redirect,
  Switch,
  Route,
} from "react-router-dom";
import { Menu } from "./components/NewMenu";
import { Loader } from "containers/Loader";
import { ConnectedRouter } from "connected-react-router";
import { history } from "configs/store";
import "./Root.style.scss";
import { EUserStatus } from "utils/enum";
import { meDataInitialValue } from "constants/variables";
import ReactTooltip from "react-tooltip";
import { Toaster } from 'react-hot-toast'
import { AnnounceNewLottoModal } from "components/Modal";
const defaultProps: IRootProps & IRootActionProps = {
  getMe(){
    noop()
  },
  resetLogin() {
    noop();
  },
  getDataMain() {
    noop();
  },
  menuActive: "main",
  onChangeMenu() {},
  onChangeShowMenu() {},
  isShowMenu: false,
  mainCode: 0,
  mainError: "",
  mainIsFetching: false,
  mainData: {
    username: "",
    permission: "MEMBER",
    line: "",
    status: "",
    childCount: 0,
    currency: "THB",
    betResultTotal: 0,
    betWaitTotal: 0,
    maxBet: 0,
    minBet: 0,
    childCreditTotal: 0,
    credit: 0,
    creditLimit: "",
  },
  loginCode: 0,
  verify: false,
  logout() {
    noop();
  },
  connectSocket() {
    noop();
  },
  meData: meDataInitialValue,
};

class RootContainer extends Component<
  IRootProps & IRootActionProps,
  IRootStates
> {
  static defaultProps = defaultProps;

  state: IRootStates = {};

  componentDidMount() {
    if (!isEmpty(this.props.accessToken) && this.props.verify) {
      /* this.props.getDataMain(); */
      this.props.getMe()
      if (localStorage.getItem("isShowNewLotto") === 'false' || localStorage.getItem("isShowNewLotto") === '' || isNull(localStorage.getItem("isShowNewLotto"))) {
        AnnounceNewLottoModal.show({
          title: 'ประกาศ',
          acceptText: 'เข้าใจแล้ว',
          action: () => {
            AnnounceNewLottoModal.hide()
            localStorage.setItem("isShowNewLotto", JSON.stringify(true))
          },
        })
      }
    }
    /* this.props.connectSocket(); */
  }

  renderGuardRoute = ({
    component: RouteComponent,
    name,
    path,
    exact,
  }: IRoutes) => {
    const renderRoute = (routeProps: RouteComponentProps) => {
      if (isEmpty(this.props.accessToken)) {
        return (
          <Redirect
            to={{ pathname: "/", state: { from: routeProps.location } }}
          />
        );
      } 
      if (!this.props.verify) {
        if (this.props.userStatus !== EUserStatus.PASSWORD_EXPIRED) {
          return (
            <Redirect
              to={{ pathname: "/", state: { from: routeProps.location } }}
            />
          );
        } 
        return (
          <Redirect
            to={{ pathname: "/reset-password", state: { from: routeProps.location } }}
          />
        );
      }
      return (
        <>
          {this.isShowMenu() ? (
            <Menu
              menuActive={this.props.menuActive}
              onChangeMenu={this.props.onChangeMenu}
              mainData={this.props.mainData}
              logout={this.props.logout}
              meData={this.props.meData}
              {...routeProps}
            />
          ) : (
            ""
          )}
          <RouteComponent {...routeProps} />
        </>
      );
    };

    return (
      <Route
        key={`${name}-page`}
        exact={exact}
        path={path}
        render={renderRoute}
      />
    );
  };

  renderRedirectRoute = ({
    component: RouteComponent,
    name,
    path,
    exact,
  }: IRoutes) => {
    const renderRoute = (routeProps: RouteComponentProps) => {
      if (!isEmpty(this.props.accessToken)) {
        if (this.props.verify) {
          /* this.props.connectSocket(); */
          return (
            <Redirect
              to={{ pathname: "/dashboard", state: { from: routeProps.location } }}
            />
          );
        }
      }
      return <RouteComponent {...routeProps} />;
    };

    return (
      <Route
        key={`${name}-page`}
        exact={exact}
        path={path}
        render={renderRoute}
      />
    );
  };

  isShowMenu = () => {
    return (
      !isEmpty(this.props.accessToken) &&
      this.props.verify &&
      this.props.pathname !== "/resetUserPassword"
    );
  };

  renderPageElement = () => (
    <ConnectedRouter history={history}>
      <Switch>
        {routes.map((route) =>
          isEqual(route.name, "404") ? (
            <Route key={`${route.name}-page`} component={route.component} />
          ) : route.private ? (
            this.renderGuardRoute(route)
          ) : (
            this.renderRedirectRoute(route)
          )
        )}
      </Switch>
    </ConnectedRouter>
  );

  render() {
    const PageElement = this.renderPageElement;

    return (
      <>
        <Router>
          <div className="root">
            <Toaster  position="top-right"/>
            <PageElement />
            <Loader isCenter={!this.isShowMenu()} />
          </div>
        </Router>
        <AlertNotification.Core />
        <ReactTooltip/>
        <Modal.Core event="MODAL" />
        <Modal.Core event="MODAL_OVER" />
      </>
    );
  }
}

export default RootContainer;
