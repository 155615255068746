export const PASTE_POOI_REQUEST = 'PASTE_POOI_REQUEST'
export const PASTE_POOI_SUCCESS = 'PASTE_POOI_SUCCESS'
export const PASTE_POOI_FAILURE = 'PASTE_POOI_FAILURE'

export const pastePooiState : ReducerState<IV2RespExtractReplaceLotteryNumber[]>={
    isFetching:false,
    code:0,
    data:[],
    error:"",

}