import { AxiosError, AxiosResponse } from 'axios'
import { createAsyncAction } from 'typesafe-actions'
import {
    MEMBERS_REQUEST,
    MEMBERS_SUCCESS,
    MEMBERS_FAILURE,

    FINANCE_SUMMARY_REQUEST,
    FINANCE_SUMMARY_SUCCESS,
    FINANCE_SUMMARY_FAILURE,

    AGENT_CHILD_REQUEST,
    AGENT_CHILD_SUCCESS,
    AGENT_CHILD_FAILURE,

    LISTMEMBERS_REQUEST,
    LISTMEMBERS_SUCCESS,
    LISTMEMBERS_FAILURE,

    DIAGRAM_LISTMEMBERS_REQUEST,
    DIAGRAM_LISTMEMBERS_SUCCESS,
    DIAGRAM_LISTMEMBERS_FAILURE,

    MEMBER_DELETE_REQUEST,
    MEMBER_DELETE_SUCCESS,
    MEMBER_DELETE_FAILURE,

    MEMBER_Detail_REQUEST,
    MEMBER_Detail_SUCCESS,
    MEMBER_Detail_FAILURE,

    POST_USER_REQUEST,
    POST_USER_SUCCESS,
    POST_USER_FAILURE,

    MEMBER_UPDATE_REQUEST,
    MEMBER_UPDATE_SUCCESS,
    MEMBER_UPDATE_FAILURE,

    MEMBER_REPASSWORD_REQUEST,
    MEMBER_REPASSWORD_SUCCESS,
    MEMBER_REPASSWORD_FAILURE,
} from './constants'

const membersAction = createAsyncAction(
    MEMBERS_REQUEST,
    MEMBERS_SUCCESS,
    MEMBERS_FAILURE,
)<any, AxiosResponse<IAPIResponse<IMembers>>, AxiosError<IAPIResponse>>()

const agentChildAction = createAsyncAction(
    AGENT_CHILD_REQUEST,
    AGENT_CHILD_SUCCESS,
    AGENT_CHILD_FAILURE,
)<any, AxiosResponse<IAPIResponse<IPagination<IV2GetAgentChild>>>, AxiosError<IAPIResponse>>()


const financeSummaryAction = createAsyncAction(
    FINANCE_SUMMARY_REQUEST,
    FINANCE_SUMMARY_SUCCESS,
    FINANCE_SUMMARY_FAILURE,
)<any, AxiosResponse<IAPIResponse<IV2RespAgentFinanceSummary>>, AxiosError<IAPIResponse>>()


const memberDiagramsAction = createAsyncAction(
    DIAGRAM_LISTMEMBERS_REQUEST,
    DIAGRAM_LISTMEMBERS_SUCCESS,
    DIAGRAM_LISTMEMBERS_FAILURE,
)<any, AxiosResponse<IAPIResponse<[]>>, AxiosError<IAPIResponse>>()

const listMembersAction = createAsyncAction(
    LISTMEMBERS_REQUEST,
    LISTMEMBERS_SUCCESS,
    LISTMEMBERS_FAILURE,
)<any, AxiosResponse<IAPIResponse<IPagination<IListMembers>>>, AxiosError<IAPIResponse>>()

const postUserAction = createAsyncAction(
    POST_USER_REQUEST,
    POST_USER_SUCCESS,
    POST_USER_FAILURE,
)<any, AxiosResponse<IAPIResponse<object>>, AxiosError<IAPIResponse>>()


const memberUpdateAction = createAsyncAction(
    MEMBER_UPDATE_REQUEST,
    MEMBER_UPDATE_SUCCESS,
    MEMBER_UPDATE_FAILURE,
)<any, AxiosResponse<IAPIResponse<object>>, AxiosError<IAPIResponse>>()

const memberDetailAction = createAsyncAction(
    MEMBER_Detail_REQUEST,
    MEMBER_Detail_SUCCESS,
    MEMBER_Detail_FAILURE,
)<any, AxiosResponse<IAPIResponse<IAgentUpdate>>, AxiosError<IAPIResponse>>()

const memberDeleteeAction = createAsyncAction(
    MEMBER_DELETE_REQUEST,
    MEMBER_DELETE_SUCCESS,
    MEMBER_DELETE_FAILURE,
)<any, AxiosResponse<IAPIResponse<object>>, AxiosError<IAPIResponse>>()

const memberRePasswordAction = createAsyncAction(
    MEMBER_REPASSWORD_REQUEST,
    MEMBER_REPASSWORD_SUCCESS,
    MEMBER_REPASSWORD_FAILURE,
)<any, AxiosResponse<IAPIResponse<object>>, AxiosError<IAPIResponse>>()

export {
    membersAction,
    financeSummaryAction,
    agentChildAction,
    memberDiagramsAction,
    listMembersAction,
    postUserAction,
    memberUpdateAction,
    memberDetailAction,
    memberDeleteeAction,
    memberRePasswordAction
}