import { get } from 'lodash'
import {
    membersState,
    listMembersState,
    registerResState,
    MembersDetaiState,
    agentChildState,
    financeSummaryState,
} from './constants'


const memberSummary = (state: RootReducers): ReducerState<IMembers> =>
    get(state, 'members.memberSummary', membersState)

const membersDiagram = (state: RootReducers): ReducerState<[]> =>
    get(state, 'members.membersDiagramReducer', [])

const memberList = (state: RootReducers): ReducerState<APIPagination<IListMembers>> =>
    get(state, 'members.memberList', listMembersState)

const memberUpdate = (state: RootReducers): ReducerState<IAPIResponse<object>> =>
    get(state, 'members.memberUpdate', registerResState)

const memberRegister = (state: RootReducers): ReducerState<IAPIResponse<object>> =>
    get(state, 'members.memberRegister', registerResState)

const memberDelete = (state: RootReducers): ReducerState<IAPIResponse<object>> =>
    get(state, 'members.memberDelete', registerResState)

const membersDetail = (state: RootReducers): ReducerState<IAgentUpdate> =>
    get(state, 'members.membersDetail', MembersDetaiState)

const memberChild = (state: RootReducers): ReducerState<APIPagination<IV2GetAgentChild>> =>
    get(state, 'members.agentChildList', agentChildState)

const me = (state: RootReducers) =>
    get(state, 'me.me', [])

const financeSummary = (state: RootReducers) =>
    get(state, "members.financeSummary", financeSummaryState)

const membersSelector = {
    memberChild,
    memberSummary,
    membersDiagram,
    memberList,
    memberUpdate,
    memberRegister,
    memberDelete,
    membersDetail,
    me,
    financeSummary
}
export default membersSelector