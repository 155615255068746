import { AxiosError, AxiosResponse } from 'axios'
import { combineReducers } from 'redux'
import { ActionType, createReducer } from 'typesafe-actions'
import { responseConverter } from '../../utils'
import * as mainAction from './actionCreator'
import { mainState, newsState, resetPasswordState } from './constants'

export type MainActionType = ActionType<typeof mainAction>
const dataMain = createReducer<ReducerState<IMain>, MainActionType>(mainState)
    .handleAction(mainAction.mainAction.request, (state: ReducerState<IMain>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(mainAction.mainAction.success, (state: ReducerState<IMain>, action: MainActionType) => {
        const payload: AxiosResponse<IAPIResponse<IMain>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data: payload.data.data,
        }
    })
    .handleAction(mainAction.mainAction.failure, (state: ReducerState<IMain>, action: MainActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        }
    })
    .handleAction(mainAction.mainAction.cancel, () => mainState);

const dataNews = createReducer<ReducerState<IPagination<INews>>, MainActionType>(newsState)
    .handleAction(mainAction.newsAction.request, (state: ReducerState<IPagination<INews>>) => {
        return {
            ...state,
            isFetching: true,
        }
    })
    .handleAction(mainAction.newsAction.success, (state: ReducerState<IPagination<INews>>, action: MainActionType) => {
        const payload: AxiosResponse<IAPIResponse<IPagination<INews>>> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data: payload.data.data,
        }
    })
    .handleAction(mainAction.newsAction.failure, (state: ReducerState<IPagination<INews>>, action: MainActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload
        const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        }
    })

const resetPassword = createReducer<ReducerState<IAPIResponse<object>>, MainActionType>(resetPasswordState)
    .handleAction(mainAction.resetPasswordAction.request, (state: ReducerState<IAPIResponse<object>>) => {
        return {
            ...state,
            isFetching: true,
        };
    }
    )
    .handleAction(mainAction.resetPasswordAction.success, (state: ReducerState<IAPIResponse<object>>, action: MainActionType) => {
        const payload: AxiosResponse<IAPIResponse<IAPIResponse<object>>> =
            action.payload;
        const convertedResponse = responseConverter.getMessage(payload);
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data: payload.data,
            error:"",
        };
    }
    )
    .handleAction(mainAction.resetPasswordAction.failure, (state: ReducerState<IAPIResponse<object>>, action: MainActionType) => {
        const payload: AxiosError<IAPIResponse> = action.payload;
        const convertedResponse = responseConverter.getMessage(payload);

        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        };
    }
    );

export default combineReducers({
    dataMain,
    dataNews,
    resetPassword
})