import { AxiosError, AxiosResponse } from 'axios'
import { createAsyncAction } from 'typesafe-actions'
import{
    NUMBER_BAN_REQUEST,
    NUMBER_BAN_SUCCESS,
    NUMBER_BAN_FAILURE,
    CANCEL_NUMBER_BAN_REQUEST,
    CANCEL_NUMBER_BAN_SUCCESS,
    CANCEL_NUMBER_BAN_FAILURE,
} from './constants'

const getNumberBanLotterytAction = createAsyncAction(
    NUMBER_BAN_REQUEST,
    NUMBER_BAN_SUCCESS,
    NUMBER_BAN_FAILURE,
)<any, AxiosResponse<IAPIResponse<IV2GetRateReduceLotteryAgent[]>>, AxiosError<IAPIResponse>>()


const postCancelNumberBanAction = createAsyncAction(
    CANCEL_NUMBER_BAN_REQUEST,
    CANCEL_NUMBER_BAN_SUCCESS,
    CANCEL_NUMBER_BAN_FAILURE,
)<any, AxiosResponse<IAPIResponse<IV2GetRateReduceLotteryAgent>>,AxiosError<IAPIResponse>>()

export {
    getNumberBanLotterytAction,
    postCancelNumberBanAction
}