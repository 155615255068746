import './dropdown.style.scss'
import React, { FC, useEffect, useState } from 'react'
import Select, { components, DropdownIndicatorProps } from 'react-select'
import colors from 'constants/colors'
import { isEmpty } from 'lodash'

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps: IDropdown = {
  menuPlacement : 'bottom',
  placeholder: '',
  controlBackgroundColor: colors.PRIMARY_GREEN,
  singleValueTextColor: colors.BLACK,
  options: [],
  handleOnChange: () => {},
  iconOpen: "",
  iconClose: "",
  menuStyles: {
    width: 80,
  },
  menuListStyles: undefined,
  optionStyles:{},
  iconStyles:{},
  valueContainerStyles:{},
  disable:false,
  hoverElementColor: '',
  isManualOpenMenu:false
}

const Dropdown: FC<IDropdown & DefaultProps> = (props) => {
  const {
    hoverElementColor,
    menuPlacement,
    placeholder,
    controlBackgroundColor,
    singleValueTextColor,
    options,
    optionStyles,
    handleOnChange,
    value,
    iconOpen,
    iconClose,
    disable,
    menuStyles,
    valueContainerStyles,
    iconStyles,
    // isManualOpenMenu
  } =
    props

  const [/* isOpen */, setisOpen] = useState(false)

  useEffect(() => {
    window.addEventListener("click",setClose);
    return () => {
      window.removeEventListener("click",setClose);
    }
  }, [])
  
  const setClose = () => setisOpen(false)

  const DropdownIndicator = (props: DropdownIndicatorProps) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {!isEmpty(iconOpen) && !isEmpty(iconClose) ? (
            <img
              style={iconStyles}
              alt="alt-text"
              src={props.selectProps.menuIsOpen ? iconOpen : iconClose}
              className={`icon-OpenClose`}
            />
          ) : (
            <img alt="alt-text" src={iconClose} style={iconStyles} className={`icon-Open`}/>
          )}
        </components.DropdownIndicator>
      )
    );
  };

  const Indicator = {
    IndicatorSeparator: () => null,
  };

  const IndicatorCustom = {
    IndicatorSeparator: () => null,
    DropdownIndicator: DropdownIndicator,
  };

  // const manualOpenMenu = isManualOpenMenu?{
  //   menuIsOpen:isOpen,
  //   onMenuOpen:()=>setisOpen(true)
  // }:{}

  return (
    <Select
      menuPlacement={menuPlacement}
      placeholder={<div className="select-placeholder-text">{placeholder}</div>}
      value={options?.filter(val=>{
        return val.value===value
      })}
      components={
        isEmpty(iconOpen) && isEmpty(iconClose) ? Indicator : IndicatorCustom
      }
      styles={{
        control: (provided:any) => ({
          ...provided,
          backgroundColor: !isEmpty(hoverElementColor) ? 'unset' : controlBackgroundColor,
          boxShadow: "none",
          border: 0,
          cursor: "pointer",
          "&:hover": {
            backgroundColor: hoverElementColor
          },
          height: '35px',
          minHeight: '35px !important',
        }),
        valueContainer:(provided:any)=>({
          ...provided,
          ...valueContainerStyles,
        }),
        menu: (provided: any, state) => ({
          ...provided,
          ...menuStyles,
          zIndex: 99,
          backgroundColor:colors.PRIMARY_BG,
          color: colors.PRIMARY_TEXT,
        }),
        singleValue: (provided:any) => ({
          ...provided,
          color: singleValueTextColor
        }),
        input: (provided:any) => ({
          ...provided,
          color: colors.PRIMARY_TEXT
          
        }),
        option: (styles:any,state) => {
          return {
            ...styles,
            whiteSpace: 'nowrap',
            color: state.isDisabled? colors.TERTIARY_TEXT : colors.PRIMARY_TEXT,
            backgroundColor: state.isSelected ? colors.SECONDARY_GREEN : colors.PRIMARY_BG,
            '&:hover': {
              backgroundColor: state.isDisabled ? '' :colors.PRIMARY_GREEN
            },
            cursor: state.isDisabled ? 'no-drop': '',
            ...optionStyles
          };
        },
      }}
      options={options}
      onChange={handleOnChange}
      isSearchable={false}
      isOptionDisabled={(option) => option.disabled}
      isDisabled={disable}
      // {...manualOpenMenu}
    />
  )
}

Dropdown.defaultProps = defaultProps

export default Dropdown
