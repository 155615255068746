import React, { FC, useState } from "react"
import "./buttonToggle.style.scss"
import { noop } from "lodash";


const defaultProps: IButtonToggle = {
  id: 'btn-toggle',
  textSwitch: ['textOne,textSecond'],
  buttonClass: '',
  action(value:string) {
    noop();
  },
};

const Button: FC<IButtonToggle> = (props) => {
  const {
    id,
    textSwitch,
    buttonClass,
    action
  } = props

  const containerClass = `button-toggle-container ${buttonClass}`
  const [active, setActive] = useState(textSwitch[0])


  const handleOnClick = (value: string) => {
    setActive(value)
    action(value)
  }
  return (
    <>
      <div className={containerClass} id={id}>
        <div
          className={`left-box ${active === textSwitch[0] ? 'active' : ''}`}
          onClick={() => handleOnClick(textSwitch[0])}
        >
          {textSwitch[0]}
        </div>
        <div
          className={`right-box ${active === textSwitch[1] ? 'active' : ''}`}
          onClick={() => handleOnClick(textSwitch[1])}
        >
          {textSwitch[1]}
        </div>
      </div>
    </>
  )
}

Button.defaultProps = defaultProps

export default Button;
