import { Breadcrumb, Dropdown, ErrorModal, Panginate } from "components"
import color from "constants/colors"
import { get, isEmpty, noop } from "lodash"
import React, { Component } from "react"
import { Table } from "./components"
import { RouteComponentProps } from "react-router-dom"
import { handleOnClickBreadcrumb, transformer } from "utils"
import "./financeSummaryHost.style.scss"
import { responseCode, responseMessage } from "constants/response"
import routes from "constants/routes"
import { fetchGetHost } from 'reduxs/host/services'
const constants = {
  title: "รับ/จ่าย หัวสมาชิก",
  subTitle: "สรุปยอดรวมหัวสมาชิก",
  listBill: 'รายการสรุปยอดบิล',
  breadcrumb: {
    main: "ภาพรวม",
    finance: "การเงิน",
    membersList: "รับ/จ่าย หัวสมาชิก",
  },
  sort: "ตัวเลือกการค้นหา",
  status: "สถานะ",
  headTable: [
    { codeName: "เลขบิล", textalign: "text-left pd-frist" },
    { codeName: "ถือสู้", textalign: "text-right" },
    { codeName: "แบ่งหุ้น", textalign: "text-right" },
    { codeName: "ยอดเดิมพัน", textalign: "text-right" },
    { codeName: "ส่วนลด", textalign: "text-right" },
    { codeName: "ค่าคอม", textalign: "text-right" },
    { codeName: "ยอดรับ", textalign: "text-right" },
    { codeName: "ยอดจ่าย", textalign: "text-right" },
    { codeName: "ยอดทั้งหมดที่รอตรวจสอบ", textalign: "text-center" },
    { codeName: "สรุปกำไร / ขาดทุน", textalign: "text-right" },
    { codeName: "สถานะ", textalign: "text-center" },
    { codeName: "เวลา", textalign: "text-center" },
    { codeName: "", textalign: "text-center" },
  ],
  rowPerPage: "จำนวนรายการต่อหน้า :",
};

const defaultProps: IFinanceSummaryHostProps &
  IFinanceSummaryHostActionProps = {
  financeHostCode: 0,
  financeHostData: {
    page: 1,
    limit: 10,
    total: 0,
    totalRecord: 0,
    dataList: [],
  },
  financeHostError: "",
  financeHostIsFetching: false,
  getFinanceHost() {
    noop();
  },
  onChangeIsLoaddingAction() {
    noop();
  },
};

class FinanceSummaryHostContainer extends Component<
  IFinanceSummaryHostProps &
  IFinanceSummaryHostActionProps &
  RouteComponentProps,
  IFinanceSummaryHostState
> {
  static defaultProps = defaultProps;
  state: IFinanceSummaryHostState = {
    statusActive: "",
    dataQuery: {
      status: '',
      limit: 10,
      page: 1,
    },
    currentPage: 1,
    currentRowPerPage: 10,
    startPage: 1,
    endPage: 0,
    hostData: {}
  };

  componentDidMount() {
    this.props.getFinanceHost()
    fetchGetHost()
    .then((response) => { if (get(response, 'data.data', null)) { this.setState({hostData: response.data.data}) } })
    .catch((error) => console.log(error))
  }

  componentDidUpdate(prevProps: IFinanceSummaryHostProps) {
    if (
      prevProps.financeHostIsFetching !== this.props.financeHostIsFetching &&
      !this.props.financeHostIsFetching
    ) {
      if (this.props.financeHostCode === responseCode.OK) {
        if (isEmpty(this.props.financeHostData.dataList)) {
          this.setState({ startPage: 0, endPage: 0 });
        } else {
          this.setState({
            startPage:
              this.state.currentRowPerPage * (this.state.dataQuery.page - 1) +
              1,
            endPage:
              this.state.currentRowPerPage * (this.state.currentPage + 1) >=
                this.props.financeHostData.totalRecord
                ? this.props.financeHostData.totalRecord
                : this.state.currentRowPerPage * this.state.dataQuery.page,
          });
        }
      } else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: get(
            responseMessage(),
            this.props.financeHostCode,
            responseMessage()[0]
          ),
        });
      }
    }
  }

  getFinanceHost = () => {
    this.props.getFinanceHost(this.state.dataQuery);
  };

  
  callBackDropdownSumGroup = (target: IOptions, data: IV2RespAgentFinanceHost) => {
     if (target.value === 'BetSumHistory') {
      this.props.history.push(routes.betSumHistory.path+transformer.urlSearchParams({agentShareId:data.agentShareId} ))
    } 
      else if (target.value === 'FinanceHost') {
      this.props.history.push(`${routes.financeHost.exactPath}/${data.agentShareId}`)
    } else if (target.value === 'FinanceChild') {
      this.props.history.push(routes.financeSummaryChild.path + transformer.urlSearchParams({ agentShareId: data.agentShareId }))
    } else if (target.value === 'CancelBill') {
    }
  }

  renderFilter = () => (
    <div className="filterBox">
      <div className="row ">
        <div className="col-2 px-0">
          <div className="paper-body-title">{constants.listBill}</div>
          <div className="subtitle-2 m3-t">{constants.sort}</div>
          <div className="dropdown-border">
            <Dropdown
              menuStyles={{ width: '100%' }}
              options={[
                {
                  value: "ALL",
                  label: "ทั้งหมด",
                  disabled: false,
                },
                {
                  value: "WAIT",
                  label: "ยังไม่เคลียร์",
                  searchableName: "DESC",
                  disabled: false,
                },
                {
                  value: "PENDING",
                  label: "ทำรายการค้างไว้",
                  searchableName: "ASC",
                  disabled: false,
                },
                {
                  value: "SUCCESS",
                  label: "เคลียร์",
                  disabled: false,
                },
              
              ]}
              controlBackgroundColor={color.PRIMARY_BG}
              handleOnChange={(target: IOptions) => {
                this.handleQueryDropdownChange(String(target.value));
              }}
              value={this.state.statusActive}
              placeholder={constants.status}
            />
          </div>
        </div>
      </div>
    </div>
  );

  handleQueryDropdownChange = (value: string) => {
    this.setState(
      {
        statusActive: value,
        dataQuery: {
          ...this.state.dataQuery,
          status: value === "ALL" ? "" : value,
          page: 1,
        },
      },
      () => {
        this.getFinanceHost();
      }
    );
  };

  
  onClickRowMembersList = (index:number, data: IV2RespAgentFinanceHost) => {
    // const query = new URLSearchParams(transformer.snakecaseTransform({ agent_share_id: data.agentShareId })).toString()
    this.props.history.push(`${routes.financeHost.exactPath}/${data.agentShareId}`,{rowIndex: index})
  };

  renderTableMembers = () => {
    return (
      <>
        <Table
          loading={this.props.financeHostIsFetching}
          column={constants.headTable}
          dataList={this.props.financeHostData.dataList}
          onClick={this.onClickRowMembersList}
          callBackDropdown={this.callBackDropdownSumGroup}
        />
      </>
    );
  };

  renderDropDownBox = () => {
    return (
      <>
        <h6>{constants.rowPerPage}</h6>
        <div className="dropdown-rowPerPage">
          <Dropdown
            // menuPlacement={"top"}
            options={[
              { value: 5, label: "5", disabled: false },
              { value: 10, label: "10", disabled: false },
              { value: 20, label: "20", disabled: false },
              { value: 50, label: "50", disabled: false },
              { value: 100, label: "100", disabled: false },
            ]}
            controlBackgroundColor={color.PRIMARY_BG}
            placeholder={String(this.state.currentRowPerPage)}
            handleOnChange={(target: IOptions) => {
              this.setState(
                {
                  currentPage: 1,
                  currentRowPerPage: Number(target.value),
                  dataQuery: {
                    ...this.state.dataQuery,
                    page: 1,
                    limit: Number(target.value),
                  },
                },
                () => {
                  this.getFinanceHost();
                }
              );
            }}
          />
        </div>
      </>
    );
  };

  handleClickChangePage = ({ selected: selectedPage }: ISelectPage) => {
    this.setState({ currentPage: selectedPage }, () => {
      this.setState(
        {
          dataQuery: {
            ...this.state.dataQuery,
            page: this.state.currentPage + 1,
          },
        },
        () => {
          this.getFinanceHost();
        }
      );
    });
  };

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.breadcrumb.main, path: "/", active: false },
      // { label: constants.breadcrumb.finance, active: false },
      { label: constants.breadcrumb.membersList, active: true },
    ];
    const RenderFilter = this.renderFilter();
    const RenderTableMembers = this.renderTableMembers();
    const RenderDropDownBox = this.renderDropDownBox;

    return (
      <>
        <div className="paper-container finance-summary-host-container">
          <div className="row">
            <div className="col">
              <div className="sub-menu">
                <Breadcrumb
                  items={navigates}
                  handleOnClickItem={handleOnClickBreadcrumb}
                />
              </div>
            </div>
          </div>
          <div className="paper-title">{constants.title}</div>
          <div className="pr-3 paper-description">{`${constants.subTitle} ${get(this.state.hostData,'hostUsername','') || ''}`}</div>
          <div className="paper-body">
            <div className="box-shadow m3-t boxShadow-Border">
              <div className="row">
                <div className="col">{RenderFilter}</div>
              </div>
              <div className="row">
                <div className="col  mt-4">{RenderTableMembers}</div>
              </div>
              <div className="row m1-t">
                <div className="col d-flex justify-content-end align-items-center ">
                  <RenderDropDownBox />
                  <h6 className="d-flex justify-content-end align-items-center m3-l">
                    {`${this.state.startPage} - ${this.state.endPage} 
                       of ${this.props.financeHostData.totalRecord || ""}`}
                  </h6>
                  <Panginate
                    pageTotal={this.props.financeHostData.total}
                    initialPage={this.state.dataQuery.page - 1}
                    pageRangeDisplayed={this.state.currentPage}
                    onPageChange={this.handleClickChangePage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FinanceSummaryHostContainer;
