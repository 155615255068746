import { get } from "lodash";
import { loginState, tokenState } from "./constants";

const login = (state: RootReducers): ReducerState<IResponseAgentLogin> =>
  get(state, "authentication.login", loginState);

const token = (state: RootReducers): IToken =>
  get(state, "authentication.token", tokenState);

const authentication = {
  login,
  token,
};
export default authentication;
