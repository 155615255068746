import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { fetchTransactionLists } from "../services";
import { transactionAction } from "../actionCreator";
import { loaderAction } from "../../Loader/actionCreator";

const getTransaction = (query: ISearchAgentFinancePayListQuery) => async (
  dispatch: Dispatch
) => {
  dispatch(loaderAction.success(true));
  dispatch(transactionAction.request({}))
  await fetchTransactionLists(query)
    .then(
      (
        response: AxiosResponse<
          IAPIResponse<IPagination<IResponseAgentFinList>>
        >
      ) => {
        dispatch(loaderAction.success(false));
        return dispatch(transactionAction.success(response));
      }
    )
    .catch((error: AxiosError<APIResponse>) => {
      dispatch(loaderAction.success(false));
      return dispatch(transactionAction.failure(error));
    });
};
const cancelTransaction = () => (dispatch: Dispatch) =>
  dispatch(transactionAction.cancel({}));

export { getTransaction, cancelTransaction };
