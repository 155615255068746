import { connect } from "react-redux";
import HomeContainer from "./Home.container";
import authSelector from "reduxs/authentication/selector";
import {
  login,
  resetAuthenticationKey,
  resetLogin,
} from "reduxs/authentication/login/action";

const mapStateToProps = (state: RootReducers): ILoginContainerProps => {
  const loginState = authSelector.login(state);
  return {
    loginCode: loginState.code,
    loginError: loginState.error,
    loginIsFetching: loginState.isFetching,
    userStatus: loginState.data.status,
    qrcode: loginState.data.qrcode,
    secret: loginState.data.secret,
    verify: loginState.data.verify,
  };
};

const mapDispatchToProps: ILoginContainerActionProps = {
  login,
  resetLogin,
  resetAuthenticationKey,
};
export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
