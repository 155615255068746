import { get } from 'lodash'
import {
    numberBanState
} from './constants'

const numberBanLotteryList = (state: RootReducers) =>
    get(state, "numberBan.numberBan", numberBanState)


const numberBanSelector = {
    numberBanLotteryList
}
export default numberBanSelector