import { connect } from "react-redux";
import BetResultContainer from "./BetResult.container";

// Backup
/* import { getDataBetHistory } from "../../reduxs/bet/betHistory/action";
import { getBetResult } from "../../reduxs/bet/betResult/action";
import betHitorySelector from "../../reduxs/bet/selector"; */

// New Redux
import { getDataHistory } from "reduxs/lotto/bet/history/action";
import { getDataResult } from "reduxs/lotto/bet/result/action";
import { getGame } from 'reduxs/lotto/game/action'
import betSelector from "reduxs/lotto/bet/selector";
import lottoSelector from "reduxs/lotto/selector";
import yegeeSelector from "reduxs/lotto/yegee/selector";
import {
  clearYegeeGameHistory,
  postYegeeGameHistory,
} from "reduxs/lotto/yegee/gameHistory/action";

const mapStateToProps = (state: RootReducers): IBetResultContainerProps => {
  const betHistory = betSelector.history(state);
  const betResult = betSelector.result(state);
  const gameHistory = yegeeSelector.gameHistory(state);
  const lottoGame = lottoSelector.game(state)
  return {
    getBetHistoryListsCode: betHistory.code,
    betHistoryLists: betHistory.data,
    getBetHistoryListsError: betHistory.error,
    getBetHistoryListsIsFetching: betHistory.isFetching,
    getBetResultCode: betResult.code,
    betResultLists: betResult.data,
    getBetResultError: betResult.error,
    getBetResultIsFetching: betResult.isFetching,
    gameHistoryCode: gameHistory.code,
    gameHistoryError: gameHistory.error,
    gameHistoryIsFetching: gameHistory.isFetching,
    gameHistoryData: gameHistory.data,
    lottoGameCode: lottoGame.code,
    lottoGameData: lottoGame.data,
    lottoGameError: lottoGame.error,
    lottoGameIsFetching: lottoGame.isFetching,
  };
};

const mapDispatchToProps: IBetResultContainerAction = {
  /* getBetHistoryLists: (query) => getDataBetHistory(query),
  getBetResultLists: (query) => getBetResult(query), */

  // New redux
  clearYegeeGameHistory: clearYegeeGameHistory,
  postYegeeGameHistory: postYegeeGameHistory,
  getBetHistoryLists: (query) => getDataHistory(query),
  getBetResultLists: (query) => getDataResult(query),
  getGame: getGame
};

export default connect(mapStateToProps, mapDispatchToProps)(BetResultContainer);
