import axios from "axios"
import environment from "constants/environment"

const cancelBill = (data : IV2PostAgentLotteryRefund) => {
    const endpoint = `${environment.environments[environment.environmentName].endpoint.url}${environment.endpoint.postCancelBill}`
    return axios.post(endpoint, data)
}

export {
    cancelBill
}