import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { getMeSettingAction } from "../actionCreator";
import { fetchGetMeSetting } from "../services";

const getMeSetting = () => (dispatch: Dispatch) => {
  dispatch(getMeSettingAction.request({}));
  return fetchGetMeSetting()
    .then((res: AxiosResponse<IV2GetAgentMeSetting>) => {
      dispatch(getMeSettingAction.success(res));
      return Promise.resolve()
    })
    .catch((error: AxiosError<IAPIResponse>) => {
      dispatch(getMeSettingAction.failure(error));
      return Promise.reject();
    });
};

export { getMeSetting };
