import "./NumbersHolesManageList.style.scss"
import React, { Component } from "react"
import { Button, Input, InputNumber, LoadingCircle } from "components"
import { get, includes, noop, size } from "lodash"
import colors from "constants/colors"
import number from "utils/number"
import { NumberFormatValues } from "react-number-format"
import { List, ListRowProps, WindowScroller } from "react-virtualized"
import { CHECK_ONLY_NUMBER, LEADING_ZERO } from "constants/regex"

const constants = {
  edit: "แก้ไข",
  submit: "บันทึก",
  up: "บน",
  toast: "โต๊ด",
  down: "ล่าง",
  num: "เลข",
  numInHoleOutOfMax: "เลขในหลุม / ขนาดหลุม",
  moneyLimitInput: 1000000000,
}
type DefaultProps = Readonly<typeof defaultProps>
const defaultProps: INumberHolesManageListProps = {
  title: "",
  numbersHolesData: [],
  menuTitle: [],
  defaultQuery: null,
  onSubmit() {
    noop()
  },
  onGetNewData() {
    noop()
  },
  loading: false
}

class NumbersHolesManageList extends Component<
  INumberHolesManageListProps & DefaultProps,
  INumberHolesManageListState
> {
  static defaultProps = defaultProps

  searchTimeout: NodeJS.Timeout | null = null
  state: INumberHolesManageListState = {
    searchInput: "",
    searchQuery: "",
    selectedMenuIndex: 0,
    isEditing: false,
    numbersHolesData: [],
    editData: {},
    dataLength: 0,

  }

  componentDidMount(): void {
    if (this.props.defaultQuery) {
      const { defaultQuery, menuTitle } = this.props
      this.setState({
        searchInput: defaultQuery.number || '',
        searchQuery: defaultQuery.number || '',
        selectedMenuIndex: menuTitle.indexOf(defaultQuery.type)
      })
    }
  }

  componentDidUpdate(prevProps: INumberHolesManageListProps): void {
    if (prevProps.numbersHolesData !== this.props.numbersHolesData) {
      if (this.state.dataLength === 0) {
        this.setState({ dataLength: this.getSelectedDataLength(this.state.searchQuery) })
      }
      this.reRenderWindowScroller()
    }
  }

  getSelectedNumberHoleData = () =>
    this.props.numbersHolesData[this.state.selectedMenuIndex] || []

  getSelectedNumberHoleDataByNumber = (index: number) =>
    this.getSelectedNumberHoleData()[index]

  getSelectedNumberHoleDataDigit = () =>
    String(this.getSelectedNumberHoleData().length).length - 1

  getSelectedDataLength = (searchString: string) => {
    const dataLength = this.getSelectedNumberHoleData().filter((data) =>
      includes(this.formatPadNumber(data.numholNumber), searchString)).length
    return dataLength
  }

  handleMenuOnClick = (menuIndex: number) => {
    this.setState({
      selectedMenuIndex: menuIndex,
      searchInput: '',
      searchQuery: '',
      isEditing: false,
      editData: [],
      dataLength: size(this.getSelectedNumberHoleData())
    })
    this.props.onGetNewData()
    this.reRenderWindowScroller()
  }

  reRenderWindowScroller = () => {
    const scrollY = window.scrollY
    window.scrollBy(0, 5)
    window.scrollTo(0, scrollY)
  }

  handleEditButton = () => {
    const { isEditing, selectedMenuIndex, editData } = this.state
    if (!isEditing) {
      this.setState({ isEditing: true })
    } else {
      this.props.onSubmit(this.props.menuTitle[selectedMenuIndex], editData)
      this.setState({
        isEditing: false,
        editData: []
      })
    }
    this.reRenderWindowScroller()
  }

  handleSearchNumberHole = (searchString: string, delay?: number) => {
    //input only number and limit length
    const isnum = CHECK_ONLY_NUMBER.test(searchString)
    if (
      (searchString.length !== 0 && !isnum) ||
      searchString.length > this.getSelectedNumberHoleDataDigit()
    ) {
      return
    }
    this.setState({
      searchInput: searchString,
      editData: []
    })

    clearTimeout(this.searchTimeout!)
    this.searchTimeout = setTimeout(() => {
      //ต้องเซ็ต dataLength เอาไว้ให้ตัว ReactVisualize ใช้
      const dataLength = this.getSelectedDataLength(searchString)
      this.setState({ searchQuery: searchString, dataLength: dataLength })
      this.props.onGetNewData()
      this.reRenderWindowScroller()
    }, delay || 0)
  }

  limitMoney = (numbers: NumberFormatValues) => {
    const { floatValue } = numbers
    if (floatValue! <= 9999999999) {
      return floatValue! <= 9999999999
    } else if (floatValue === undefined) {
      return true
    } else {
      return false
    }
  }

  formatThousandSeparator = (val: number) => {
    return val.toLocaleString("en-US")
  }

  formatPadNumber = (input: number | string) => {
    const formattedNum = number.padNumber(
      String(input),
      this.getSelectedNumberHoleDataDigit()
    )
    return formattedNum
  }

  handleEditData = (editNumber: number, input: NumberFormatValues) => {
    let inputUpdate = input.value
    let inputUpdateAsNumber = input.floatValue || 0
    let isEqualToDefault = false
    const prevInput = this.getNumberHoleBetLimit(editNumber)
    const defaultNumberHoleData = this.getSelectedNumberHoleDataByNumber(editNumber)
    if (inputUpdateAsNumber > constants.moneyLimitInput) {
      inputUpdate = String(constants.moneyLimitInput)
    }

    //ลบเลข 0 ถ้าใส่ค่ามาเพื่อไม่ให้กลายเป็นหลัก 10
    if (prevInput === '0' && !LEADING_ZERO.test(inputUpdate)) {
      inputUpdate = inputUpdate.replace(/0$/, '')
    }

    //ถ้าเลขเท่ากับ dafault เอาค่าออกจาก array editData
    if (inputUpdate === String(defaultNumberHoleData.numholBetLimit)) {
      isEqualToDefault = true
    }
    this.setState((prevState) => {
      const editData = { ...prevState.editData }
      if (isEqualToDefault) {
        delete editData[editNumber]
      } else {
        const newEditDataObj: INumberHolesManageListEditData = {
          editInput: inputUpdate || '0',
          numholId: get(defaultNumberHoleData, 'numholId', null)
        }
        editData[editNumber] = newEditDataObj
      }
      return ({
        editData
      })
    }, () => {
      this.reRenderWindowScroller()
    })
  }

  getNumberHoleDataDisplay = () => {
    const numberHoleDataList = this.getSelectedNumberHoleData()
    if (!this.state.searchQuery)
      return numberHoleDataList

    return numberHoleDataList.filter((data) =>
      includes(this.formatPadNumber(data.numholNumber), this.state.searchQuery)
    )
  }

  getNumberHoleBetLimit = (index: number) => {
    const currEditData: string = get(this.state.editData, `${index}.editInput`, '')
    if (currEditData) {
      return currEditData
    } else {
      return String(get(this.getSelectedNumberHoleDataByNumber(index), `numholBetLimit`, 0))
    }
  }

  getMenuName = (menuType: TLottoGameType) => {
    if (menuType.includes('TOAST')) {
      return constants.toast
    } else if (menuType.includes('UP')) {
      return constants.up
    } else if (menuType.includes('DOWN')) {
      return constants.down
    }
    return ''
  }

  renderNumberHoleRow = ({ index, style }: ListRowProps) => {
    const numberHoleData = this.getNumberHoleDataDisplay()[index]

    const numholNumber = get(numberHoleData, 'numholNumber', null)
    const numholBetTotal = get(numberHoleData, 'numholBetTotal', 0)
    const numholBetLimit = get(numberHoleData, 'numholBetLimit', 0)
    const numholIsMax = get(numberHoleData, 'numholIsMax', 0)

    const numholNumberAsNumber = Number(numholNumber)
    const paddedNumber = this.formatPadNumber(numholNumberAsNumber)
    const rowClassName = `numbers-hole-manage-table-row
      ${numholIsMax ? 'pool-max' : ''}
    `

    return (
      <div
        key={`numbersHolesData-${this.props.title}-${index}`}
        style={style}
        className={rowClassName}
      >
        <div>{paddedNumber}</div>
        <div className="number-hole-row-data-wrap">
          <span>{this.formatThousandSeparator(numholBetTotal)}/</span>
          {this.state.isEditing ? (
            <div className={"input-wrap"}>
              <InputNumber
                id={`numbersHolesData[${paddedNumber}].poolSize-${this.props.title}`}
                name={`numbersHolesData[${paddedNumber}].poolSize-${this.props.title}`}
                borderWidth="1px"
                borderStyle="solid"
                borderColor={colors.SECONDARY_BORDER_COLOR}
                disableInputBorder
                borderRadius="4px"
                value={this.getNumberHoleBetLimit(numholNumberAsNumber)}
                onValueChange={(e) => this.handleEditData(numholNumberAsNumber, e)}
                thousandSeparator
                allowNegative={false}
                setPadding="12px"
                isAllowed={this.limitMoney}
              />
            </div>
          ) : (
            this.formatThousandSeparator(numholBetLimit)
          )}
        </div>
      </div>
    )
  }

  renderNumbersHolesList() {
    return (
      <WindowScroller>
        {({ height, isScrolling, registerChild, scrollTop }) => (
          <div className="numbers-hole-manage-table">
            <div className="numbers-hole-manage-table-header">
              <span>{constants.num}</span>
              <span>{constants.numInHoleOutOfMax}</span>
            </div>
            <div className="numbers-hole-manage-table-body">
              {
                this.props.loading  ? <div className="p1-y">
                  <div className="d-flex justify-content-center"><LoadingCircle /> </div>
                </div> : <div ref={registerChild}>
                  <List
                    autoHeight
                    height={height}
                    isScrolling={isScrolling}
                    rowCount={this.state.dataLength}
                    rowHeight={72}
                    rowRenderer={this.renderNumberHoleRow}
                    scrollTop={scrollTop}
                    width={100}
                  />
                </div>
              }

            </div>
          </div>
        )}
      </WindowScroller>
    )
  }

  render() {
    return (
      <div className="paper-body">
        <div className={`px-2 pt-0 boxShadow-Border ${this.getSelectedNumberHoleData().length === 0 ? 'pb-2' : ''
          }`}
        >
          <div className="numbers-hole-manage-list">
            <div className="header paper-title d-flex align-items-center justify-content-between m2-x mt-0">
              <h4>{this.props.title}</h4>
              <Button
                id="numbers-hole-manage-list-edit"
                text={this.state.isEditing ? constants.submit : constants.edit}
                onClick={this.handleEditButton}
                size="small"
                buttonClass="edit-button"
                buttonType="submit"
              />
            </div>
            <div className="menu-tab subtitle-2 d-flex align-items-center m2-x">
              {this.props.menuTitle.map((menu, i) => (
                <div
                  key={`${this.props.title}-menu-item-${menu}`}
                  className={`menu-tab-item ${this.state.selectedMenuIndex === i
                    ? 'selected'
                    : 'quaternary-text'
                    }`}
                  onClick={() => this.handleMenuOnClick(i)}
                >
                  {this.getMenuName(menu)}
                </div>
              ))}
            </div>
            <div className="numbers-hole-search d-flex align-items-center">
              <Input
                bgContainer={colors.PRIMARY_BG}
                borderWidth="1px"
                borderStyle="solid"
                borderColor={colors.SECONDARY_BORDER_COLOR}
                disableInputBorder
                renderError={false}
                backgroundColor={colors.PRIMARY_BG}
                borderRadius="4px"
                inputClassName="input-search"
                setPadding="12px"
                placeholder="ค้นหาเลข"
                value={this.state.searchInput}
                id={""}
                name={""}
                onChange={(e) => {
                  this.handleSearchNumberHole(e.target.value, 1000)
                }}
              />
            </div>
            {this.renderNumbersHolesList()}
          </div>
        </div>
      </div>
    )
  }
}

export default NumbersHolesManageList
