import { connect } from "react-redux";
import TransferConfirmationContainer from "./TransferConfirmation.container";
import {
  getTransferConfirmation,
  cancelTranfersConfirmation,
} from "../../reduxs/credits/transferConfirmation/action";
import { postPayAccept } from "../../reduxs/credits/payAccept/action";
import transferConfirmationSelector from "../../reduxs/credits/selector";

const mapStateToProps = (
  state: RootReducers
): ITransferConfirmationContainerProps => {
  const transferConfirmation = transferConfirmationSelector.transferConfirmations(
    state
  );
  const payAccept = transferConfirmationSelector.payAccepts(state);
  return {
    TransferConfirmationLists: transferConfirmation.data,
    getTransferConfirmationListsCode: transferConfirmation.code,
    getTransferConfirmationListsError: transferConfirmation.error,
    getTransferConfirmationListsIsFetching: transferConfirmation.isFetching,
    PayRequestRestoreState: payAccept.data,
    postPayAcceptCode: payAccept.code,
    postPayAcceptError: payAccept.error,
    postPayAcceptIsFetching: payAccept.isFetching,
  };
};

const mapDispatchToProps: ITransferConfirmationContainerAction = {
  getTransferConfirmationLists: (query) => getTransferConfirmation(query),
  postPayAccept: (body) => postPayAccept(body),
  cancelTransferConfirmationLists: cancelTranfersConfirmation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransferConfirmationContainer);
