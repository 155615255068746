import { string, object, ObjectSchema } from "yup";

const requiredMessages = {
  username: "กรุณากรอกชื่อผู้ใช้",
  password: "กรุณากรอกรหัสผ่าน",
};

const scheme: ObjectSchema<ILoginForm & any> = object().shape({
  username: string().required(requiredMessages.username),
  password: string().required(requiredMessages.password),
});

export default scheme;
