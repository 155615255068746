import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { cancelBill } from "./services"
import { cancelBillAction } from "./actionCreator"

const postCancelBill = (data : IV2PostAgentLotteryRefund) => (dispatch : Dispatch) =>{
    dispatch(cancelBillAction.request({}))
    return cancelBill(data)
    .then((res:AxiosResponse<IAPIResponse<IV2PostAgentLotteryRefund>>)=>{
        dispatch(cancelBillAction.success(res))
    })
    .catch((error : AxiosError<IAPIResponse>) => dispatch(cancelBillAction.failure(error)))
}

export{
    postCancelBill,
}