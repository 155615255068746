import React from "react"
import { number, dateThai } from "utils"
import { get, includes, isEmpty } from "lodash"
import { Dropdown, Button, LoadingCircle } from 'components'
import colors from 'constants/colors'
import { FunctionComponent } from "react"
import "../../financeCustomerDetails.style.scss"
import point from "assets/images/global/icon/point.png"
import { STATUS_FINANCE_ME, FINANCE_CUSTOMER_LIST, CODE_TO_NAME } from "constants/variables"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

const constants = {
  empty: 'ไม่มีข้อมูล...',
  clear: 'เคลียร์',
  clearAll: 'เคลียร์ทั้งหมด'
}
interface ITableSumGroup {
  column: Array<{
    codeName: string
    textalign: string
  }>
  dataClearAll: Array<IV2RespAgentLotteryBetGroup>
  dataList: Array<IV2RespAgentLotteryBetGroup>
  onClick(data: IV2RespAgentLotteryBetGroup): void
  onClearAll(data: Array<IV2RespAgentLotteryBetGroup>): void
  onClickRow(data: IV2RespAgentLotteryBetGroup, index: number): void
  callBackDropdown(key: IOptions, data: IV2RespAgentLotteryBetGroup): void
  activeRow: number
  loading: boolean
}

const Table: FunctionComponent<ITableSumGroup> = ({
  column,
  dataList,
  dataClearAll,
  onClick,
  onClearAll,
  onClickRow,
  callBackDropdown,
  activeRow,
  loading
}) => {

  const handleChildClick = (event: any) => {
    event.stopPropagation()
  };

  const filterDataStClear = (dataList: Array<IV2RespAgentLotteryBetGroup>) => {
    const dataFilter = dataList.filter((item) => {
      return item.lotteryStatus === 'SUCCESS' && item.customerPayStatus === 'WAIT'
    })
    return dataFilter
  }

  const loadingTable = (
    <tr>
      <td className="text-center" colSpan={15}>
        <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
      </td>
    </tr>
  )
  
  const columns = (
    <thead>
      <tr className="tableHead">
        {column.map((data, index: number) => {
          return data.codeName === 'clearAll'
            ?
            <th key={index} className={data.textalign}>
              <Button
                disabled={filterDataStClear(dataClearAll).length === 0}
                backgroundColor={colors.DARK_GREEN}
                id={"clear"}
                size={"small"}
                textSize={14}
                buttonClass={'p0'}
                text={constants.clearAll}
                onClick={() => {
                  const clearList = filterDataStClear(dataClearAll)
                  onClearAll(clearList)
                }}
              />
            </th>
            : <th key={index} className={data.textalign}>
              <span>{data.codeName}</span>
            </th>
        }
        )}
      </tr>
    </thead>
  )


  const rows = (
    <tbody>
      {
        isEmpty(dataList) ?
          <tr>
            <td className="text-center color-row p-2" colSpan={15}>
              {constants.empty}
            </td>
          </tr>
          :
          dataList.map((data, index: number) => {
            const stName = get(STATUS_FINANCE_ME, `${data.customerPayStatus}.status`, '')
            const isWaitSum = data.lotteryStatus === 'SUCCESS' && data.customerPayStatus === 'WAIT'
            const stWait = data.lotteryStatus === 'WAIT'
            const stPayCancel = data.customerPayStatus === "CANCEL"
            const stColor = get(STATUS_FINANCE_ME, `${data.customerPayStatus}.color`, '')
            const creditColor = (data.totalHostShare + data.totalMeShare) > 0 ? colors.SECONDARY_GREEN
              : (data.totalHostShare + data.totalMeShare) < 0 ? colors.PRIMARY_RED : colors.TERTIARY_TEXT

            return (
              <tr className={`row-finance-customer ${data.customerPayStatus === "CANCEL" ? 'secondary-red-bg' : ' primary-bg'} ${activeRow === index && ('active-purple-bg')}`} key={index} onClick={() => onClickRow(data, index)}>
                <td className="text-left pd-frist">
                  {
                    stPayCancel && (
                      <FontAwesomeIcon
                        id={"cancel" + index}
                        className={`num-icon `}
                        icon={faTimes}
                      />
                    )
                  }
                  <span style={{ paddingLeft: '2px' }}>{data.customerName}</span>
                </td>
                <td className="text-left ">
                  <span>{(includes(data.name, "YEGEE") ? `ยี่กี รอบที่ ${Number(data.name.replace("YEGEE_", ''))}` : CODE_TO_NAME[data.name]).replace('หวย', '')}</span>
                </td>
                <td className="text-right">
                  <span>{number.addComma(data.totalCountBet)}</span>
                </td>
                <td className="text-right">
                  <span>{number.addComma(data.totalBet)}</span>
                </td>
                <td className="text-right">
                  <span>{number.addComma(data.totalDis)}</span>
                </td>
                <td className="text-right">
                  <span >{number.addComma(data.totalCom)}</span>
                </td>
                <td className="text-right">
                  <span style={{ color: creditColor }}>{number.addComma(number.calculator(`${data.totalHostShare}+${data.totalMeShare}`))}</span>
                </td>
                <td className="text-center">
                  <span className="text-status" style={{ color: stWait ? colors.PRIMARY_YELLOW : stColor }}>
                    {stPayCancel ? '' : stWait ? 'รอผล' : stName}
                  </span>
                </td>
                <td className="text-center">
                  <span>{dateThai.formatThai(data.createdAt)}</span>
                </td>
                <td className="text-center" onClick={handleChildClick}>
                  {
                    data.customerPayStatus === 'SUCCESS' && data.lotteryStatus === 'SUCCESS'
                      ? <span className="button-clear-text tertiary-text">{stName}</span>
                      : isWaitSum ? <Button
                        id={"clear"}
                        size={"small"}
                        textSize={14}
                        buttonClass={'p0'}
                        text={constants.clear}
                        onClick={() => onClick(data)}
                      />
                        : <></>
                  }
                </td>
                <td className="text-letf d-flex justify-content-center" onClick={handleChildClick}>
                  <span >
                    <Dropdown
                      isManualOpenMenu={true}
                      options={Object.keys(FINANCE_CUSTOMER_LIST).map((item) => { return { value: item, label: FINANCE_CUSTOMER_LIST[item], disabled: item === 'CancelBill' ? data.customerPayStatus === "WAIT" ? false : true : false } })}
                      iconOpen={point}
                      iconClose={point}
                      valueContainerStyles={{
                        padding: "0px",
                        maxWidth: "0px",
                      }}
                      hoverElementColor={colors.PRIMARY_HOVER}
                      controlBackgroundColor={colors.PRIMARY_BG}
                      menuStyles={{ width: "175px", left: "-150px" }}
                      handleOnChange={(target: IOptions) => {
                        callBackDropdown(target, data)
                      }}
                    />
                  </span>
                </td>
              </tr>
            )
          })
      }
    </tbody>
  )

  return (
    <div className="table-container">
      <table className="col ">
        {columns}
        { loading ? loadingTable : rows}
      </table >
    </div>
  )
}

export default Table
