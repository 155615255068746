import { Breadcrumb, LottoCard } from "components";
import color from "constants/colors";
import routes from "constants/routes";
import { LOTTO_SLUG_NAME } from "constants/variables";
import { format } from "date-fns";
import { th } from "date-fns/locale";
import { isEmpty, noop } from "lodash";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { date } from "utils";

const constants = {
  lottoLabel: "แทงหวย",
  lottoYegee: "หวยยี่กี",
  back: "ย้อนกลับ",
  round: "รอบที่",
  closedStatus: "หมดเวลา",
  closedMakeTime: "เวลาที่ปิดรับ: ",
  betTitle: "การเดิมพัน",
  betSubTitle: "เดิมพัน",
  placeBet: "แทงหวย",
  closedStatusLabel: "ปิดรับแทง",
  waitingStatusLabel: "รอเปิดรับแทง",
  openedYeegeStatusLabel: "24 ชม.",
  noAccessStatusLabel: "ห้ามใช้"
};

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps: IBetChildrenProps & IBetChildrenActionProps = {
  getDataYegeeGameList() {
    noop();
  },
  onChangeIsLoaddingAction() {
    noop();
  },
  yegeeGameData: [],
  yegeeGameIsFetching: false,
  yegeeGameCode: 0,
  yegeeGameError: "",
};

class BetChildrenContainer extends Component<
  IBetChildrenProps &
  IBetChildrenActionProps &
  DefaultProps &
  RouteComponentProps<{ type: TLottoSlug }, {}, IBetMakingContainerRouteProps>
> {
  static defaultProps = defaultProps;

  componentDidMount() {
    this.props.onChangeIsLoaddingAction(false);
    if (this.props.match.params.type === "LOTTER_YEGEE") {
      this.props.onChangeIsLoaddingAction(true);
      this.props.getDataYegeeGameList();
    }
  }

  componentDidUpdate(prevProps: IBetChildrenProps) {
    if (
      prevProps.yegeeGameIsFetching !== this.props.yegeeGameIsFetching &&
      !this.props.yegeeGameIsFetching
    ) {
      this.props.onChangeIsLoaddingAction(false);
    }
  }

  componentWillUnmount() {
    this.props.onChangeIsLoaddingAction(false);
  }

  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.replace(path);
  };

  renderSubLottoList = () => {
    if (!isEmpty(this.props.yegeeGameData)) {
      const ListComponent = this.props.yegeeGameData.map(
        (yegee: ILottoGame, index) => {
          const yegeeRound = `${constants.round} ${yegee.round}`;
          const expireTime = format(
            date.calibratingTime(yegee.endTime),
            "dd MMM yyyy H:mm",
            { locale: th }
          );
          const backgroundColor =
            yegee.status === "OPEN"
              ? color.TERTIARY_GREEN
              : yegee.status === "NOT_ACCESS" ? color.SECONDARY_RED : color.TERTIARY_TEXT_TRANSPARENT;

          const handleOnClickPlay = (game: ILottoGame) => {
            this.props.history.push(
              routes.betMaking.exactPath(this.props.match.params.type),
              {
                selectedLottoGame: game,
                targetLotto: {
                  ...yegee,
                  isCountingDown: yegee.status === "OPEN",
                  closedStatusText: constants.closedStatus,
                  waitingStatusText: constants.waitingStatusLabel,
                  openedStatusText: constants.openedYeegeStatusLabel,
                  description: expireTime,
                  subtitle: constants.closedMakeTime,
                  backgroundColor: backgroundColor,
                  icon: "no-icon",
                  code: "LOTTER_YEGEE",
                  yegeeRound: yegeeRound,
                  noAccessStatusText: constants.noAccessStatusLabel
                },
              }
            );
          };
          return (
            <div
              className="col-12 col-md-6 col-lg-4 m2-t"
              key={`sub-${yegee.round}-${index}`}
            >
              <LottoCard
                slug={yegee.code || "LOTTER_YEGEE"}
                id={`yegee-round-${yegee.round}`}
                onClick={() => handleOnClickPlay(yegee)}
                title={yegeeRound}
                subtitle={constants.closedMakeTime}
                backgroundColor={backgroundColor}
                status={yegee.status}
                isCountingdown={yegee.status === "OPEN"}
                closedStatusText={constants.closedStatus}
                noAccessStatusText={constants.noAccessStatusLabel}
                description={expireTime}
                expire={yegee.endTime}
                start={yegee.startTime}
                icon="no-icon"
              />
            </div>
          );
        }
      );
      return <div className="row">{ListComponent}</div>;
    }
    return <></>;
  };

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.betTitle, active: false, path: "/bet" },
      { label: constants.betSubTitle, active: false, path: "/bet" },
      {
        label: `รอบ${LOTTO_SLUG_NAME["LOTTER_YEGEE"]}`,
        active: true,
      },
    ];
    return (
      <div className="paper-container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="sub-menu">
              <Breadcrumb
                items={navigates}
                handleOnClickItem={this.handleOnClickBreadcrumb}
              />
            </div>
          </div>
        </div>
        <div className="paper-title">{constants.betTitle}</div>
        <div className="paper-description">{constants.placeBet}</div>
        <div className="row m4-t mr-xl-4">
          <div className="col-12 m2-t">
            <h4>{constants.lottoYegee}</h4>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">{this.renderSubLottoList()}</div>
        </div>
      </div>
    );
  }
}

export default BetChildrenContainer;
