import { get } from "lodash";
import { betRateState, betNumberState } from "./constants";

const betRate = (
  state: RootReducers
): ReducerState<IResponseAgentLotteryRateClient[]> => {
  return get(state, "lotto.bet.rate.betRate", betRateState);
};

const betNumber = (
  state: RootReducers
): ReducerState<IResponseAgentLotteryRateNumber[]> => {
  return get(state, "lotto.bet.rate.betNumber", betNumberState);
};

const rate = {
  betRate,
  betNumber,
};

export default rate;
