import { CODE_TO_NAME } from 'constants/variables';
import { camelCase, snakeCase, keys, split, head, last, get, map, includes } from 'lodash'

const isArray = (array: any) => Array.isArray(array)

const isObject = (object: any) => object === Object(object) && !isArray(object) && typeof object !== 'function';

const camelcaseTransform = (data: any): { [name: string]: any } | [] => {
  if (isObject(data)) {
    const objectData = data as { [name: string]: any }
    const newObject: { [name: string]: any } = {}
    keys(objectData).forEach((key) => {
      newObject[camelCase(key)] = camelcaseTransform(objectData[key]);
    })
    return newObject
  } else if (isArray(data)) {
    const arrayData = data as []
    const newArray = arrayData.map((i) => camelcaseTransform(i))
    return newArray
  }
  return data
}

const snakecaseTransform = (data: any): { [name: string]: any } | [] => {
  if (isObject(data)) {
    const objectData = data as { [name: string]: any }
    const newObject: { [name: string]: any } = {}
    keys(objectData).forEach((key) => {
      newObject[snakeCase(key)] = snakecaseTransform(objectData[key]);
    })
    return newObject
  } else if (isArray(data)) {
    const arrayData = data as []
    const newArray = arrayData.map((i) => snakecaseTransform(i))
    return newArray
  }
  return data
}

const hiddenString = (text: string): string => {
  const splitedString = split(text, '')
  const firstString = head(splitedString)
  const lastString = last(splitedString)
  return `${firstString}****${lastString}`
}

const urlSearchParams = <T = any>(data: T): string => {
  const transformedData = snakecaseTransform(data)

  const dataKeys = keys(transformedData) || []
  const params = new URLSearchParams()
  map(dataKeys, (key) => {
    
    const queryValue = get(transformedData, key, '')
    if (Array.isArray(queryValue)) {
      params.append(key, queryValue.join(','))
      
    }
    else if (queryValue) {
      params.append(key, queryValue)
    }
  })
  if (params.toString()) {
    return `?${params.toString()}`
  }
  return ''
}

const queryToObject = (): any => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return params
}

const nameLotto = (type: TLottoType, fullName: boolean = false, addRound: boolean = false): string => {
  let fullNameLotto = CODE_TO_NAME[includes(type, "YEGEE") ? "YEGEE" : type]
  let nameLotto = CODE_TO_NAME[includes(type, "YEGEE") ? "YEGEE" : type].replace("หวย", '')
  let round = includes(type, "YEGEE") ? ` รอบที่ ${Number(type.replace("YEGEE_", ''))}` : ""
  return (fullName ? fullNameLotto : nameLotto) + (addRound ? round : '')
}


const transformer = {
  camelcaseTransform,
  snakecaseTransform,
  urlSearchParams,
  hiddenString,
  queryToObject,
  nameLotto
}
export default transformer

