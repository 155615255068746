
import { AxiosError,AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { fetchGetFinanceHostMe ,fetchGetFinanceHost} from "../services";
import { financeHostMeAction,financeHostAction } from "../actionCreator";


const getFinanceHostMe = (query:IQueryPagination& IV2QueryAgentFinanceHostMe ) => (dispatch: Dispatch) =>{
    dispatch(financeHostMeAction.request({}))
    return fetchGetFinanceHostMe(query)
    .then((res:AxiosResponse<IAPIResponse<IPagination<IV2RespAgentFinanceHostMe>>>) => {
           dispatch(financeHostMeAction.success(res))
        })
        .catch((error: AxiosError<IAPIResponse>) => dispatch(financeHostMeAction.failure(error)))
}


const getFinanceHost = (query:  IQueryPagination | IV2QueryAgentFinanceHost ) => (dispatch: Dispatch) =>{
    dispatch(financeHostAction.request({}))
    return fetchGetFinanceHost(query)
        .then((res:AxiosResponse<IAPIResponse<IPagination<IV2RespAgentFinanceHost>>>) => {
            dispatch(financeHostAction.success(res))
        })
        .catch((error: AxiosError<IAPIResponse>) => dispatch(financeHostAction.failure(error)))
}

export {
    getFinanceHost,
    getFinanceHostMe
}