import { Dispatch } from 'redux'
import { menuAction, menuShowAction } from './actionCreator'

const onChangeMenuAction = (menu: IMenuList) => (dispatch: Dispatch) => {
      dispatch(menuAction.request({}))
      try {
          dispatch(menuAction.success(menu))
      } catch {
          dispatch(menuAction.failure({}))
      }
  }

const onChangeShowMenuAction = (groupMune: boolean) => (dispatch: Dispatch) => {
    dispatch(menuShowAction.request({}))
    try {
        dispatch(menuShowAction.success(groupMune))
    } catch {
        dispatch(menuShowAction.failure({}))
    }
}


export {
  onChangeMenuAction,
  onChangeShowMenuAction
}