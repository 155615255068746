import { RouteComponentProps } from 'react-router-dom';
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction, Dispatch } from "redux";
import { get } from "lodash";
import {
  loginAction,
  loginInitialPasswordAction,
  resetLoginAction,
  resetAuthenticationKeyAction,
  createOTPAction,
  loginPasswordAction,
  setPasswordExpiredAction,
  // register2FAAction,
  verify2FAAction,
  validate2FAAction,
} from "../actionCreator";

import { loginState } from "../constants";

import {
  fetchLogin,
  fetchLoginInitialPassword,
  fetchCreateOTP,
  fetchLoginPassword,
  // fetchPostRegister2FA,
  // fetchPostVerify2FA,
  fetchPostValidate2FA,
} from "../services";
import { setToken } from "../token/action";
import { responseCode, responseMessage } from "constants/response";
import { EUserStatus } from "utils/enum";
import {
  // Authentication2FAModal,
  ErrorModal, SuccessModal
} from "components";
import { ThunkDispatch } from "redux-thunk";
import { getMe } from 'reduxs/me/me/action';
import routes from 'constants/routes';

const login = (
  loginData: IAgentLogin,
  history: RouteComponentProps["history"],
  location: RouteComponentProps['location']
) => async (
  dispatch: ThunkDispatch<RootReducers, never, AnyAction>,
  getState: () => RootReducers
) => {
    dispatch(loginAction.request({}));
    return fetchLogin(loginData)
      .then((response: AxiosResponse<IAPIResponse<IResponseAgentLogin>>) => {
        dispatch(loginAction.success(response));
        return get(response, "data.data", { token: "" });
      })
      .then((loginResponseData: any) => { // IResponseAgentLogin
        setToken(loginResponseData)(dispatch)
        const loginState: ReducerState<IResponseAgentLogin> = getState()
          .authentication.login;
        if (loginState.code === responseCode.OK) {
          if (
            loginState.data.status === EUserStatus.PASSWORD_EXPIRED ||
            loginState.data.status === EUserStatus.PRE_REGISTERED
          ) {
            history.push("/reset-password", {
              username: loginData.username,
              password: loginData.password,
              from: location,
            });
          }

          else if (loginState.data.status === EUserStatus.NORMAL) {
            dispatch(verify2FAAction.success({ verify: true }));
            history.push(routes.Dashboard.path);
          }
          else {
            history.push("/", { from: location });
          }
        }
      })
      .catch((error: AxiosError<IAPIResponse>) => {
        dispatch(loginAction.failure(error));
        if (
          get(error, "response.data.code", 0) === responseCode.PASSWORD_EXPIRE
        ) {
          const loginData = get(error, "response.data.data", loginState);
          setToken(loginData)(dispatch);
        }
      });
  };

const loginInitialPassword = (
  loginInitialPasswordData: IAgentInitialPassword,
  history: RouteComponentProps["history"],
  location: RouteComponentProps["location"]
) => async (
  dispatch: ThunkDispatch<RootReducers, never, AnyAction>,
  getState: () => RootReducers
) => {
    dispatch(loginInitialPasswordAction.request({}));
    return fetchLoginInitialPassword(loginInitialPasswordData)
      .then((response: AxiosResponse<IAPIResponse<IResponseAgentInitialPassword>>) => {
        dispatch(loginInitialPasswordAction.success(response));
        const loginState: ReducerState<IResponseAgentLogin> = getState().authentication.login;
        if (loginState.code === responseCode.OK) {
          SuccessModal.show({
            action: () => {
              SuccessModal.hide()
              history.push(routes.login.path);
            },
            description: "เปลี่ยนรหัสผ่านสำเร็จ",
          });
        } else {
          get(responseMessage(), loginState.code, responseMessage()[0]);
        }
      }
      )
      .catch((error: AxiosError<IAPIResponse>) => {
        dispatch(loginInitialPasswordAction.failure(error));
      });
  };

const createOTP = (createOTPData: ICreateOTPRequest) => (
  dispatch: Dispatch
) => {
  dispatch(createOTPAction.request({}));
  return fetchCreateOTP(createOTPData)
    .then((response: AxiosResponse<IAPIResponse<ICreateOTP>>) => {
      dispatch(createOTPAction.success(response));
    })
    .catch((error: AxiosError<IAPIResponse>) => {
      dispatch(createOTPAction.failure(error));
    });
};

const loginPassword = (
  loginPasswordData: IAgentInitialPassword,
  history: RouteComponentProps["history"],
  location: RouteComponentProps["location"]
) => async (
  dispatch: ThunkDispatch<RootReducers, never, AnyAction>,
  getState: () => RootReducers
) => {
    dispatch(loginPasswordAction.request({}));
    return fetchLoginPassword(loginPasswordData)
      .then((response: AxiosResponse<IAPIResponse<IResponseAgentPassword>>) => {
        dispatch(loginPasswordAction.success(response));
        const loginState: ReducerState<IResponseAgentLogin> = getState()
          .authentication.login;
        if (loginState.code === responseCode.OK) {
          SuccessModal.show({
            action: () => {
              SuccessModal.hide();
              dispatch(verify2FAAction.success({ verify: true }));
              // getMe()(dispatch)

            },
            description: "เปลี่ยนรหัสผ่านสำเร็จ",
          });
        }
      })
      .catch((error: AxiosError<IAPIResponse>) => {
        dispatch(loginPasswordAction.failure(error));
      });
  };

const resetLogin = () => (dispatch: Dispatch) => {
  dispatch(resetLoginAction({}));
};

const resetAuthenticationKey = () => (dispatch: Dispatch) => {
  dispatch(resetAuthenticationKeyAction({}));
};

const setPasswordExpired = (
  errorResponse: APIResponse<IResponseAPIPasswordExpired>,
  history: RouteComponentProps["history"]
) => (dispatch: Dispatch, getState: () => RootReducers) => {
  dispatch(setPasswordExpiredAction.request({}));
  dispatch(setPasswordExpiredAction.success(errorResponse));
  history.push("/reset-password", {
    from: getState().router.location,
  });
  if (getState().authentication.login.code !== responseCode.OK) {
    ErrorModal.show({
      action: ErrorModal.hide,
      description: get(
        responseMessage(),
        getState().authentication.login.code,
        responseMessage()[0]
      ),
    });
  }
};

// const register2FA = (
//   history: RouteComponentProps["history"],
//   location: RouteComponentProps["location"]
// ) => async (
//   dispatch: ThunkDispatch<RootReducers, never, AnyAction>,
//   getState: () => RootReducers
// ) => {
//     dispatch(register2FAAction.request({}));
//     return fetchPostRegister2FA()
//       .then((response: AxiosResponse<IAPIResponse<IResponse2FARegister>>) => {
//         dispatch(register2FAAction.success(response));

//         const loginState: ReducerState<IResponseAgentLogin> = getState()
//           .authentication.login;
//         if (loginState.code === responseCode.OK) {
//           history.push("/register/2FA", {
//             qrcode: loginState.data.qrcode || "",
//             secret: loginState.data.secret || "",
//             from: location,
//             userStatus: EUserStatus.WAIT_SECRET,
//           });
//         } else if (loginState.code === responseCode.ALREADY_REGISTER2FA) {
//           Authentication2FAModal.show({
//             // Props passed to the modal
//             verify2FA: (password: string, handle2FAError: Function) =>
//               dispatch(verify2FA(password, handle2FAError)),
//             verify: loginState.data.verify,

//             cancelAction: () => {
//               Authentication2FAModal.hide();
//             },
//           });
//         } else {
//           get(responseMessage(), loginState.code, responseMessage()[0]);
//         }
//       })
//       .catch((error: AxiosError<IAPIResponse>) => {
//         dispatch(register2FAAction.failure(error));
//       });
//   };

// const verify2FA = (password: string, handle2FAError: Function) => async (
//   dispatch: Dispatch,
//   getState: () => RootReducers
// ) => {
//   dispatch(verify2FAAction.request({}));
//   return fetchPostVerify2FA(password)
//     .then((response: AxiosResponse<IAPIResponse<IResponse2FAVerify>>) => {
//       dispatch(verify2FAAction.success(response));
//       getMe()(dispatch)
//       const loginState: ReducerState<IResponseAgentLogin> = getState()
//         .authentication.login;
//       if (!loginState.data.verify) {
//         handle2FAError();
//       }
//     })
//     .catch((error: AxiosError<IAPIResponse>) => {
//       dispatch(verify2FAAction.failure(error));
//     });
// };

const validate2FA = (password: string) => async (dispatch: Dispatch) => {
  dispatch(validate2FAAction.request({}));
  return fetchPostValidate2FA(password)
    .then((response: AxiosResponse<IAPIResponse<IResponse2FAVerify>>) => {
      getMe()(dispatch)
      dispatch(validate2FAAction.success(response));
    })
    .catch((error: AxiosError<IAPIResponse>) => {
      dispatch(validate2FAAction.failure(error));
    });
};

export {
  login,
  resetLogin,
  resetAuthenticationKey,
  loginInitialPassword,
  createOTP,
  loginPassword,
  setPasswordExpired,
  // register2FA,
  // verify2FA,
  validate2FA,
};
