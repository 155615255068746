import React from "react"
import { FunctionComponent } from "react"
import "../BetLotteryGroup/betLotteryGroup.style.scss"
import { find, get, includes, isEmpty, isNil, map } from "lodash"
import { date, number } from "utils"
import { CODE_TO_NAME } from 'constants/variables'
import colors from 'constants/colors'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { LoadingCircle } from "components"
const constants = {
    empty: 'ไม่มีข้อมูล...',
    cancleBet: "ยืนยันยกเลิกโพย",
    confirmCancleBet:
        "ข้อมูลการเดิมพันของลูกค้าในงวดดังกล่าวจะถูกยกเลิก คุณแน่ใจที่จะยกเลิกโพยหรือไม่",
}
interface ITableMembers {
    trxData: IV3RespGetExtractionAgentBetLottery[]
    onClick(querys: IV3QueryGetDetailExtractionAgentBetLottery): void
    lottoSchedule: IV2RespAgentLotterySchedule[]
    loading: boolean
}

declare interface IHeaderLotterBetGroup {
    id: number
    name: string
    textalign: string
    width?: number
}

const mappingTheadLottoฺBill: IHeaderLotterBetGroup[] = [
    { id: 1, name: "วันที่", textalign: 'text-left', width: 250 },
    { id: 2, name: "ชนิดหวย", textalign: 'text-left', width: 250 },
    { id: 3, name: "งวดวันที่", textalign: 'text-left', width: 200 },
    { id: 4, name: "ยอด", textalign: 'text-right', width: 150 },
    { id: 5, name: "ส่วนลด", textalign: 'text-right', width: 200 },
    { id: 6, name: "รวม", textalign: 'text-right', width: 200 },
    { id: 7, name: "แพ้/ชนะ", textalign: 'text-right', width: 200 },
    { id: 8, name: "หมายเหตุ", textalign: 'text-center', width: 400 },
    { id: 9, name: "#", textalign: 'text-center', width: 150 },
];


const Table: FunctionComponent<ITableMembers> = ({
    lottoSchedule,
    trxData,
    onClick,
    loading
}) => {
    const tableHead = () => {
        const mapHeadTable = map(
            mappingTheadLottoฺBill,
            (data: IHeaderLotterBetGroup, index: number) => {
                return <th className={`${data.textalign}`} key={index} style={{ width: data.width }}>
                    <h5 className={`text-header secondary-text ${index === 0 && "ml-3"}`}>{data.name}</h5>
                </th>
            }
        );
        return (
            <thead className='header-table-lotto-bill'>
                <tr>{mapHeadTable}</tr>
            </thead>
        )
    }

    const sumTotalBet = () => {
        return number.decimalsFormatComma(trxData
            .filter(transaction => transaction.status !== 'CANCEL')
            .reduce((total, transaction) => total + parseFloat(String(transaction.totalBet)), 0)
            .toFixed(2))
    }
    const sumDiscount = () => {
        return number.decimalsFormatComma(trxData
            .filter(transaction => transaction.status !== 'CANCEL')
            .reduce((total, transaction) => total + parseFloat(String(transaction.totalDis)), 0)
            .toFixed(2))
    }
    const sumTotalSum = () => {
        return number.decimalsFormatComma(
            trxData
                .filter(transaction => transaction.status !== 'CANCEL')
                .reduce((total, transaction) => total + parseFloat(String(transaction.totalSum)), 0)
                .toFixed(2)
        )
    }
    const sumTotalResult = () => {
        const total = trxData
            .filter(transaction => transaction.status !== 'CANCEL')
            .reduce((accumulator, transaction) => {
                const totalResult = parseFloat(String(transaction.totalResult))
                if (!isNaN(totalResult)) {
                    return accumulator + totalResult
                }
                return accumulator
            }, 0)
        return number.decimalsFormatComma(String(total.toFixed(2)))
    }

    const tableBody = () => {
        const dataEmpty = (
            <tr>
                <td className="text-center" colSpan={15}>
                    {constants.empty}
                </td>
            </tr>
        );

        const loadingTable= (
            <tr>
              <td className="text-center" colSpan={15}>
              <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
              </td>
            </tr>
          )
          
        const mapData = map(trxData, (item: IV3RespGetExtractionAgentBetLottery, index: number) => {
            let data = {
                agentShareId: item.agentShareId,
                customerId: item.customerId,
                customerName: item.customerName,
                name: item.name,
                totalBet: item.totalBet,
                totalDis: item.totalDis,
                totalSum: item.totalSum,
                totalResult: item.totalResult,
                status: item.status,
                createdAt: `${date.formatThai(item.createdAt, 'dd/MM/yyyy')}, ${date.formatThai(item.createdAt, 'HH:mm:ss')}`,
                updatedAt: `${date.formatThai(item.updatedAt, 'dd/MM/yyyy')}`
            }
            const lottoScheduleObj = find(lottoSchedule, { 'name': item.name })
            const formatEndTime = date.formatThai(get(lottoScheduleObj, 'endTime', ''), 'dd/MM/yyyy')

            const stColor = isNil(data.totalResult) ? '' : Number(data.totalResult) === 0 ? colors.PRIMARY_RED : colors.BLUE
            const totalResult = isNil(data.totalResult) ? '' : Number(data.totalResult) === 0 ? item.status === 'CANCEL' ? '' : 'ไม่ถูกรางวัล' : number.decimalsFormatComma(data.totalResult)
            const stColorRow = item.status === 'CANCEL' ? colors.SECONDARY_RED : Number(data.totalResult) > 0 ? colors.BLUE_TRAN : ''

            return (
                <tr
                    className={`row-bill-list`}
                    key={`${item.name} ${index}`}
                    style={{ height: 36, background: stColorRow }}
                >
                    <td className="text-left pl-3">
                        <span style={{ fontWeight: 400 }}>
                            {
                                item.status === "CANCEL" && (
                                    <FontAwesomeIcon
                                        id={"cancel" + index}
                                        className={`num-icon`}
                                        icon={faTimes}
                                    />
                                )
                            }
                            {`${data.createdAt}`}
                        </span>
                    </td>
                    <td className="text-left">
                        <span className="body-4-secondary-text">{(CODE_TO_NAME[includes(data.name, "YEGEE") ? "YEGEE" : data.name]).replace("หวย", "") + (includes(data.name, "YEGEE") ? ` รอบที่ ${data.name.replace("YEGEE_", '')}` : "")}</span>
                    </td>
                    <td className="text-left">
                        <span className="body-4-secondary-text">{isNil(data.totalResult) ? formatEndTime : data.updatedAt}</span>
                    </td>
                    <td className="text-right">
                        <span className="body-4-darkgreen">{number.decimalsFormatComma(data.totalBet)}</span>
                    </td>
                    <td className="text-right">
                        <span className="body-4-red">{number.decimalsFormatComma(data.totalDis)}</span>
                    </td>
                    <td className="text-right">
                        <span className="body-4-primary-blue-green">{number.decimalsFormatComma(data.totalSum)}</span>
                    </td>

                    <td className="text-right">
                        <span style={{ color: stColor }}>{totalResult}</span>
                    </td>
                    <td className="text-left p2-l">
                        <span>{data.customerName}</span>
                    </td>
                    <td className="text-center" style={{ cursor: 'pointer' }}>
                        <div className="detail-or-cancel">
                            <span className="body-4-primary-blue-detail" onClick={() => {
                                onClick({ createdAt: item.createdAt, customerId: String(item.customerId), name: item.name, betTransactionStatus: item.status })
                            }}>ดูรายละเอียด
                            </span>
                        </div>

                    </td>
                </tr>
            );
        })
        return loading ? loadingTable : !isEmpty(trxData) ? mapData : dataEmpty;
    }

    const tableTotal = () => {
        return (
            <tr
                className={`row-bill-list primary-bg`}
                key={`Footer-table ${trxData[0].customerId}`}
                style={{ height: 36 }}
            >
                <td className="text-left pl-3">
                    รวม
                </td>
                <td className="text-left">
                </td>
                <td className="text-left">
                </td>
                <td className="text-right">
                    <span className="body-4-darkgreen">{sumTotalBet()}</span>
                </td>
                <td className="text-right">
                    <span className="body-4-red">{sumDiscount()}</span>
                </td>
                <td className="text-right">
                    <span className="body-4-primary-blue-green">{sumTotalSum()}</span>
                </td>
                <td className="text-right">
                    <span className="body-4-primary-blue-detail">{sumTotalResult()}</span>
                </td>
            </tr>
        )
    }

    return (
        <>
            <div className="table-container">
                <div className="table-responsive-xl">
                    <table className="w-100">
                        {tableHead()}
                        <tbody>{tableBody()}</tbody>
                        <tfoot>{!isEmpty(trxData) && (tableTotal())}</tfoot>
                    </table>
                </div>
            </div>
        </>
    )
}

export default Table
