import { get } from 'lodash'
import { mainState, newsState, resetPasswordState } from './constants'

const main = (state: RootReducers): ReducerState<IMain> =>
    get(state, 'main.dataMain', mainState)
const news = (state: RootReducers): ReducerState<IPagination<INews>> =>
    get(state, 'main.dataNews', newsState)
const resetPassword = (state: RootReducers): ReducerState<IAPIResponse<object>> =>
    get(state, 'main.resetPassword', resetPasswordState)

const mainSelector = {
    main,
    news,
    resetPassword
}
export default mainSelector