import { AxiosError, AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import { fetchCredits } from '../services'
import { creditAction } from '../actionCreator'
import { loaderAction } from '../../Loader/actionCreator'

const getCredits = () => async (dispatch: Dispatch) => {
    dispatch(loaderAction.success(true))
    dispatch(creditAction.request({}))
    await fetchCredits()
        .then((response: AxiosResponse<IAPIResponse<IResponseAgentFinanceMe>>) => {
            dispatch(loaderAction.success(false))
            return dispatch(creditAction.success(response))
        })
        .catch((error: AxiosError<APIResponse>) => {
        dispatch(loaderAction.success(false))
        return dispatch(creditAction.failure(error))})
}

const clearCredits = () => async (dispatch: Dispatch) =>
    dispatch(creditAction.cancel({}))


export {
    clearCredits,
    getCredits
}