import React from "react"
import { FunctionComponent } from "react"
import "../../financeCustomerDetails.style.scss"
import { number, dateThai } from "utils"
import { STATUS_FINANCE } from "constants/variables";
import { get, isEmpty } from "lodash";
import colors from 'constants/colors'
import { LoadingCircle } from "components";
const constants = {
  list: '(รายการ)',
  empty: 'ไม่มีข้อมูล...'
}
interface ITableMembers {
  activeMenu: string
  column: Array<{
    codeName: string
    textalign: string
  }>
  columnShare: Array<{
    codeName: string
    textalign: string
  }>
  columnClear: Array<{
    codeName: string
    textalign: string
  }>
  dataList: Array<IV2RespAgentFinanceCustomer>
  onClick(data: IV2RespAgentFinanceCustomer): void
  loading: boolean
}

const Table: FunctionComponent<ITableMembers> = ({
  activeMenu,
  column,
  columnShare,
  columnClear,
  dataList,
  onClick,
  loading
}) => {



  const numberOfColor = (total: number) => {
    return (
      total > 0 ? colors.SECONDARY_GREEN
        : total < 0 ? colors.PRIMARY_RED : colors.TERTIARY_TEXT
    )
  }


  
  const renderTable = (activeMenu: string) => {

    const loadingTable = (
      <tr>
        <td className="text-center" colSpan={15}>
          <div className="m1-t d-flex justify-content-center"><LoadingCircle /> </div>
        </td>
      </tr>
    )

    if (activeMenu === 'จากตาราง') {
      const columns = (
        <thead>
          <tr className="th-sum-customer">
            {column.map((data, index: number) => (
              <th key={index} className={data.textalign} style={{ width: index === 0 ? '3%' : '7%' }}>
                <span>{data.codeName}</span>
                {/* {
                  index === 1
                    ? <div className="d-flex flex-column">
                      <span>{data.codeName} </span>
                      <div className="remember-name" style={{ lineHeight: '8px' }}>{constants.list}</div>
                    </div>
                    : <span>{data.codeName}</span>
                } */}
              </th>
            ))}
          </tr>
        </thead>
      )
      const rows = (
        <tbody>
          {
            isEmpty(dataList) ?
              <tr>
                <td className="text-center color-row p-2" colSpan={15}>
                  {constants.empty}
                </td>
              </tr>
              :
              dataList.map((data, index: number) => {
                const stName = get(STATUS_FINANCE, `${data.status}.status`, '')
                const stColor = get(STATUS_FINANCE, `${data.status}.color`, '')
                const creditColor = (number.calculator(`(${data.recieveHost}+${data.recieveChild})-(${data.payHost}+${data.payChild})`)) > 0 ? colors.SECONDARY_GREEN
                  : (number.calculator(`(${data.recieveHost}+${data.recieveChild})-(${data.payHost}+${data.payChild})`)) < 0 ? colors.PRIMARY_RED : colors.TERTIARY_TEXT
                return (
                  <tr className={`th-sum-customer`} key={index} onClick={() => onClick(data)}>
                    <td className="text-center">
                      <span className="">{number.addComma(data.betMe)}</span>
                    </td>
                    <td className="text-center ">
                      <span>{number.addComma(data.betHost)}</span>
                    </td>
                    <td className="text-center">
                      <span>{number.addComma(number.calculator(`${data.betMe}+${data.betHost}`))}</span>
                    </td>
                    <td className="text-center">
                      <span >{number.addComma(data.discount)}</span>
                    </td>
                    <td className="text-center">
                      <span>{number.addComma(data.com)}</span>
                    </td>
                    <td className="text-center">
                      <span className="secondary-green-text">{number.addComma(number.calculator(`${data.recieveHost}+${data.recieveChild}`))}</span>
                    </td>
                    <td className="text-center">
                      <span className="primary-red-text">{number.addComma(number.calculator(`${data.payHost}+${data.payChild}`))}</span>
                    </td>
                    <td style={{ width: '10%' }} className="text-center">
                      <span className="text-status" style={{ color: creditColor }}>{number.addComma(number.calculator(`(${data.recieveHost}+${data.recieveChild})-(${data.payHost}+${data.payChild})`))}</span>
                    </td>
                    <td className="text-center">
                      <span className="text-status" style={{ color: stColor }}>{stName}</span>
                    </td>
                    <td className="text-center">
                      <span>{dateThai.formatThai(data.createdAt)}</span>
                    </td>
                  </tr>
                )
              })
          }
        </tbody>
      )
      return (<>
        {columns}
        { loading ? loadingTable : rows}
      </>
      )
    }
    if (activeMenu === 'การแบ่งหุ้น') {
      const columns = (
        <thead>
          <tr className="th-sum-customer">
            {columnShare.map((data, index: number) => (
              <th key={index} className={data.textalign} style={{ width: index === 0 ? '3%' : '7%' }}>
                <span>{data.codeName}</span>
              </th>
            ))}
          </tr>
        </thead>
      )
      const rows = (
        <tbody>
          {
            isEmpty(dataList) ?
              <tr>
                <td className="text-center color-row p-2" colSpan={15}>
                  {constants.empty}
                </td>
              </tr>
              :
              dataList.map((data, index: number) => {
                return (
                  <tr className={`row-sum-customer`} key={index} onClick={() => onClick(data)}>
                    <td className="text-center">
                      <span className="">{number.addComma(data.resultMe)}</span>
                    </td>
                    <td className="text-center ">
                      <span style={{ color: numberOfColor(data.resultHost) }}>
                        {number.addComma(data.resultHost)}
                      </span>
                    </td>
                    <td className="text-center">
                      <span style={{ color: colors.PRIMARY_RED }}>
                        {number.addComma(data.payHost)}
                      </span>
                    </td>
                    <td className="text-center">
                      <span style={{ color: colors.PRIMARY_RED }}>
                        {number.addComma(data.payChild)}
                      </span>
                    </td>
                    <td className="text-center">
                      <span style={{ color: colors.SECONDARY_GREEN }}>
                        {number.addComma(data.recieveHost)}
                      </span>
                    </td>
                    <td className="text-center">
                      <span style={{ color: colors.SECONDARY_GREEN }}>
                        {number.addComma(data.recieveChild)}
                      </span>
                    </td>
                  </tr>
                )
              })
          }
        </tbody>
      )
      return (<>{columns}  { loading ? loadingTable : rows}</>)
    }
    if (activeMenu === 'รายการเคลียร์') {
      const columns = (
        <thead>
          <tr className="th-sum-customer">
            {columnClear.map((data, index: number) => (
              <th key={index} className={data.textalign} style={{ width: '10%' }}>
                <span>{data.codeName}</span>
              </th>
            ))}
          </tr>
        </thead>
      )
      const rows = (
        <tbody>
          {
            isEmpty(dataList) ?
              <tr>
                <td className="text-center color-row p-2" colSpan={15}>
                  {constants.empty}
                </td>
              </tr>
              :
              dataList.map((data, index: number) => {
                return (
                  <tr className={`th-sum-customer`} key={index} onClick={() => onClick(data)}>
                    <td className="text-center">
                      <span>{`${number.addComma(data.totalCustomer)} รายการ`}</span>
                    </td>
                    <td className="text-center ">
                      <span>
                        {`${number.addComma(data.totalWait)} รายการ`}
                      </span>
                    </td>
                    <td className="text-center">
                      <span>
                        {`${number.addComma(data.totalPayed)} รายการ`}
                      </span>
                    </td>
                  </tr>
                )
              })
          }
        </tbody>
      )
      return (<>{columns}  { loading ? loadingTable : rows}</>)
    }
  }






  return (
    <div className={`table-container ${activeMenu === 'รายการเคลียร์' && ('table-clear')}`}>
      <table className="col ">
        {renderTable(activeMenu)}
      </table >
    </div>
  )
}

export default Table
