import { connect } from "react-redux";
import { getDataYegeeGameList } from "reduxs/lotto/yegee/gameList/action";
import { onChangeIsLoaddingAction } from "reduxs/Loader/action";
import BetChildrenContainer from "./BetChildren.container";
import yegeeSelector from "reduxs/lotto/yegee/selector";

const mapStateToProps = (state: RootReducers): IBetChildrenProps => {
  const yegeeGame = yegeeSelector.gameList(state);
  return {
    yegeeGameData: yegeeGame.data,
    yegeeGameIsFetching: yegeeGame.isFetching,
    yegeeGameCode: yegeeGame.code,
    yegeeGameError: yegeeGame.error,
  };
};

const mapDispatchToProps: IBetChildrenActionProps = {
  getDataYegeeGameList,
  onChangeIsLoaddingAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BetChildrenContainer);
