import { AxiosError, AxiosResponse } from "axios";
import { createAction, createAsyncAction } from "typesafe-actions";
import {
  MAKE_BET_LOTTO_REQUEST,
  MAKE_BET_LOTTO_SUCCESS,
  MAKE_BET_LOTTO_FAILURE,
  GET_HISTORY_REQUEST,
  GET_HISTORY_SUCCESS,
  GET_HISTORY_FAILURE,
  GET_RESULT_REQUEST,
  GET_RESULT_SUCCESS,
  GET_RESULT_FAILURE,
  CLEAR_BET_RESULT,
  GET_HISTORY_SUMMARY_DETAIL_REQUEST,
  GET_HISTORY_SUMMARY_DETAIL_SUCCESS,
  GET_HISTORY_SUMMARY_DETAIL_FAILURE,
  CLEAR_HISTORY_SUMMARY_DETAIL,
  GET_HISTORY_SUMMARY_REQUEST,
  GET_HISTORY_SUMMARY_SUCCESS,
  GET_HISTORY_SUMMARY_FAILURE,
  CLEAR_HISTORY_SUMMARY,
  GET_V2_BET_GROUP_REQUEST,
  GET_V2_BET_GROUP_SUCCESS,
  GET_V2_BET_GROUP_FAILURE,

  POST_V2_AGENT_BET_REQUEST,
  POST_V2_AGENT_BET_SUCCESS,
  POST_V2_AGENT_BET_FAILURE,

  GET_V2_AGENT_BET_REQUEST,
  GET_V2_AGENT_BET_SUCCESS,
  GET_V2_AGENT_BET_FAILURE,
  GET_V2_AGENT_BET_CLEAR,

  GET_V2_RESULT_REQUEST,
  GET_V2_RESULT_SUCCESS,
  GET_V2_RESULT_FAILURE,

  GET_V3_BET_EXTRACTION_REQUEST,
  GET_V3_BET_EXTRACTION_SUCCESS,
  GET_V3_BET_EXTRACTION_FAILURE,

  GET_V3_BET_EXTRACTION_DETAIL_REQUEST,
  GET_V3_BET_EXTRACTION_DETAIL_SUCCESS,
  GET_V3_BET_EXTRACTION_DETAIL_FAILURE
} from "./constants";
import { getRateAction, getNumberAction } from "./rate/actionCreator";

const postMakeAction = createAsyncAction(
  MAKE_BET_LOTTO_REQUEST,
  MAKE_BET_LOTTO_SUCCESS,
  MAKE_BET_LOTTO_FAILURE
)<any, AxiosResponse<IAPIResponse<any>>, AxiosError<IAPIResponse<any>>>();

const getHistoryAction = createAsyncAction(
  GET_HISTORY_REQUEST,
  GET_HISTORY_SUCCESS,
  GET_HISTORY_FAILURE
)<
  any,
  AxiosResponse<
    IAPIResponse<APIPagination<IResponseAgentBetLotteryHistoryList>>
  >,
  AxiosError<IAPIResponse>
>();

const getBetGroupAction = createAsyncAction(
  GET_V2_BET_GROUP_REQUEST,
  GET_V2_BET_GROUP_SUCCESS,
  GET_V2_BET_GROUP_FAILURE,
)<
  any,
  AxiosResponse<IAPIResponse<APIPagination<IV2RespAgentLotteryBetGroup>>>,
  AxiosError<IAPIResponse>
  >();

const postV2AgentBetAction = createAsyncAction(
  POST_V2_AGENT_BET_REQUEST,
  POST_V2_AGENT_BET_SUCCESS,
  POST_V2_AGENT_BET_FAILURE
)<
  any,
  AxiosResponse<IAPIResponse<IV2RespAgentLotteryBet>>,
  AxiosError<IAPIResponse>
>();

const getV2AgentBetAction = createAsyncAction(
  GET_V2_AGENT_BET_REQUEST,
  GET_V2_AGENT_BET_SUCCESS,
  GET_V2_AGENT_BET_FAILURE,
  GET_V2_AGENT_BET_CLEAR
)<
  any,
  AxiosResponse<IAPIResponse<IPagination<IV2RespAgentLotteryBetMe>>>,
  AxiosError<IAPIResponse>
,any>();




const getHistorySummaryAction = createAsyncAction(
  GET_HISTORY_SUMMARY_REQUEST,
  GET_HISTORY_SUMMARY_SUCCESS,
  GET_HISTORY_SUMMARY_FAILURE,
  CLEAR_HISTORY_SUMMARY
)<
  any,
  AxiosResponse<
    IAPIResponse<APIPagination<IResponseAgentQueryLotteryHistorySummary>>
  >,
  AxiosError<IAPIResponse>,
  any
>();

const getHistorySummaryDetailAction = createAsyncAction(
  GET_HISTORY_SUMMARY_DETAIL_REQUEST,
  GET_HISTORY_SUMMARY_DETAIL_SUCCESS,
  GET_HISTORY_SUMMARY_DETAIL_FAILURE,
  CLEAR_HISTORY_SUMMARY_DETAIL
)<
  any,
  AxiosResponse<IAPIResponse<IResponseAgentQueryLotteryHistorySummaryDetail>>,
  AxiosError<IAPIResponse>,
  any
>();

const getResultAction = createAsyncAction(
  GET_RESULT_REQUEST,
  GET_RESULT_SUCCESS,
  GET_RESULT_FAILURE
)<
  any,
  AxiosResponse<IAPIResponse<IResponseLudensBetResult[]>>,
  AxiosError<IAPIResponse>
>();

const clearBetResultAction = createAction(CLEAR_BET_RESULT)<any>();

const getV2ResultAction = createAsyncAction(
  GET_V2_RESULT_REQUEST,
  GET_V2_RESULT_SUCCESS,
  GET_V2_RESULT_FAILURE
)<
  any,
  AxiosResponse<IAPIResponse<IPagination<IV2RespAgentLotteryResult>>>,
  AxiosError<IAPIResponse>
>();

const getBetExtractionAction = createAsyncAction(
  GET_V3_BET_EXTRACTION_REQUEST,
  GET_V3_BET_EXTRACTION_SUCCESS,
  GET_V3_BET_EXTRACTION_FAILURE
)<
  any,
  AxiosResponse<IAPIResponse<IV3ExtractionGroupingLottery[]>>,
  AxiosError<IAPIResponse>
>();
const getBetExtractionDetailAction = createAsyncAction(
  GET_V3_BET_EXTRACTION_DETAIL_REQUEST,
  GET_V3_BET_EXTRACTION_DETAIL_SUCCESS,
  GET_V3_BET_EXTRACTION_DETAIL_FAILURE
)<
  any,
  AxiosResponse<IAPIResponse<IV3RespGetDetailExtractionAgentBetLottery[]>>,
  AxiosError<IAPIResponse>
>();

export {
  postMakeAction,
  getHistoryAction,
  getResultAction,
  getRateAction,
  getNumberAction,
  clearBetResultAction,
  getHistorySummaryDetailAction,
  getHistorySummaryAction,
  getBetGroupAction,
  postV2AgentBetAction,
  getV2AgentBetAction,
  getV2ResultAction,
  getBetExtractionAction,
  getBetExtractionDetailAction
};
