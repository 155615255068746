import React, { FC, Fragment } from "react"
import { noop } from "lodash"
import colors from "constants/colors"
import "./breadcrumbMember.style.scss"

type DefaultProps = Readonly<typeof defaultProps>

export interface IBreadcrumbMemberItem {
  label: string
  active?: boolean
  data: ChildListDetail
}

export interface IBreadcrumbMemberProps {
  items: IBreadcrumbMemberItem[]
  handleOnClickItem(query: ChildListDetail, index: number): void
}

const defaultProps: IBreadcrumbMemberProps = {
  items: [],
  handleOnClickItem() {
    noop()
  },
}


const BreadcrumbMember: FC<IBreadcrumbMemberProps & DefaultProps> = (props) => {

  const RenderBreadcrumbItems = () => {

    const ItemsComponent = props.items.map((item, index) => {
      const { label,data } = item
      const breadcrumbFont = index === 0
      const breadcrumEnd = index === props.items.length - 1
      const isActive = item.active
      const activeColor = isActive ? colors.PRIMARY_GREEN : colors.TERTIARY_TEXT
      const textColor = props.items.length === 1  ? colors.PRIMARY_GREEN : activeColor
      return (
        <Fragment key={`${label}-${index}`}>
          <span className={`button-link-text-bold ${breadcrumbFont ? '' : 'p1-x'} ${isActive ? "breadcrumbMember-slash-active" : "breadcrumbMember-slash"} `}
            style={{ color: textColor }}
          >
            {breadcrumbFont ? "" : ">"}
          </span>
          <span className={isActive ? "breadcrumbMember-label" : ""} onClick={() => !breadcrumEnd && (props.handleOnClickItem!(data,index))} style={{ color: textColor }}>{label}</span>
        </Fragment>
      )
    })

    return <div className="breadcrumbMember-wrapper">{ItemsComponent}</div>;
  }

  return (
    <div className="breadcrumbMember-container">
      <RenderBreadcrumbItems />
    </div>
  );
};

export default BreadcrumbMember;
