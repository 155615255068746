import { connect } from "react-redux"
import MembersListContainer from "./FinanceCustomerDetails.container"
import financeSelector from "../../reduxs/finance/selector"
import { getFinanceCustomer, postFinanceCustomerTx, clearPostFinanceCustomerTx } from "../../reduxs/finance/customer/action"
import { onChangeIsLoaddingAction } from "../../reduxs/Loader/action"
import { getGetBetGroup, getAgentBet, clearGetAgentBet } from "reduxs/lotto/bet/bet/action"
import betGroup from "reduxs/lotto/bet/selector"
import lottoSelector from "reduxs/lotto/selector";
import { getDataV2LotteryList } from "reduxs/lotto/list/action";
import { getMe } from "reduxs/me/me/action";
import { postCancelBill } from "reduxs/cancelBill/action";


const mapStateToProps = (state: RootReducers): IFinanceCustomerDetailsProps => {
  const financeCustomerData = financeSelector.fianceCustomer(state)
  const financeCustomerTxPostData = financeSelector.postfinanceCustomerPostTx(state)
  const betGroupSelector = betGroup.betGroup(state)
  const agentBetSelector = betGroup.getAgentBet(state);
  const lottoScheduleV2 = lottoSelector.listV2(state);
  return {
    financeCustomerCode: financeCustomerData.code,
    financeCustomerError: financeCustomerData.error,
    financeCustomerIsFetching: financeCustomerData.isFetching,
    financeCustomerData: financeCustomerData.data,

    betGroupData: betGroupSelector.data,
    betGroupIsFetching: betGroupSelector.isFetching,
    betGroupCode: betGroupSelector.code,
    betGroupError: betGroupSelector.error,

    agentData: agentBetSelector.data,
    agentIsFetching: agentBetSelector.isFetching,
    agentCode: agentBetSelector.code,
    agentError: agentBetSelector.error,
    meData: state.me.me.data,
    financeCustomerTxPostCode: financeCustomerTxPostData.code,
    financeCustomerTxPostIsFetching: financeCustomerTxPostData.isFetching,
    financeCustomerTxPostError: financeCustomerTxPostData.error,
    financeCustomerTxPostData: financeCustomerTxPostData.data,

    lottoScheduleV2Data: lottoScheduleV2.data,
    lottoScheduleV2IsFetching: lottoScheduleV2.isFetching,
    lottoScheduleV2Code: lottoScheduleV2.code,
    lottoScheduleV2Error: lottoScheduleV2.error,

    postCancelBillCode: state.cancelBill.cancelBill.code,
    postCancelBillIsFetching : state.cancelBill.cancelBill.isFetching,
    postCancelBillError: state.cancelBill.cancelBill.error,
  }
}
const mapDispatchToProps: IFinanceCustomerDetailsActionProps = {
  getMe,
  getFinanceCustomer,
  postFinanceCustomerTx,
  getGetBetGroup,
  getAgentBet,
  onChangeIsLoaddingAction,
  getDataV2LotteryList,
  clearGetAgentBet,
  clearPostFinanceCustomerTx,
  postCancelBill,
}
export default connect(mapStateToProps, mapDispatchToProps)(MembersListContainer)
