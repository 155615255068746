import { AxiosError,AxiosResponse } from "axios";
import { combineReducers } from "redux";
import{ActionType, createReducer} from "typesafe-actions"
import { responseConverter } from "utils";
import * as pastePooiAction from "./actionCreator"
import {
    pastePooiState
} from "./constants"

export type pastePooiActionType = ActionType<typeof pastePooiAction>

const pastePooi = createReducer<ReducerState<{}>,pastePooiActionType>(pastePooiState)
.handleAction(
    pastePooiAction.pastePooiAction.request,
    (state: ReducerState<{}>) => {
        return {
            ...state,
            isFetching: true,
        };
    }
).handleAction(
    pastePooiAction.pastePooiAction.success,
    (state: ReducerState<{}>, action:pastePooiActionType) => {
       const payload : AxiosResponse<IAPIResponse<IV2RespExtractReplaceLotteryNumber[]>> = action.payload
       const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            data: payload.data.data,
            error: "",
        };
    }
)
.handleAction(
    pastePooiAction.pastePooiAction.failure,
    (state: ReducerState<{}>, action:pastePooiActionType) => {
       const payload : AxiosError<IAPIResponse> = action.payload
       const convertedResponse = responseConverter.getMessage(payload)
        return {
            ...state,
            isFetching: false,
            code: convertedResponse.code,
            error: convertedResponse.message,
        };
    }
)

export default combineReducers({
    pastePooi
})