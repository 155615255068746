import React, { Component } from "react";
import {
  Breadcrumb,
  Button,
  Input,
  InputNumber,
  Panginate,
  SuccessModal,
} from "components";
import "./transferCredits.connect.style.scss";
import { get, isEmpty, map, noop } from "lodash";
import number from "utils/number";
import { RouteComponentProps } from "react-router-dom";
import colors from "constants/colors";
import errorModal from "components/Modal/components/Error/Error.component";
import { responseCode } from "constants/response";

const defaultProps: ITransferCreditContainerProps &
  ITransferCreditContainerAction = {
  TransferCreditLists: [{}],
  getTransferCreditLists() {
    noop();
  },
  cancelTransferCreditLists() {
    noop();
  },
  postPayRequest() {
    noop();
  },
  PayRequestRestoreState: {},
  PostPayRequestCode: 0,
  postPayRequestError: "",
  postPayRequestIsFetching: false,
  getTransferCreditListsCode: 0,
  getTransferCreditListsError: "",
  getTransferCreditListsIsFetching: false,
};

type DefaultProps = Readonly<typeof defaultProps>;

const mapTransferCreditTableName: {
  [type in ITransferCreditMockRequest as string]: string;
} = {
  username: "ชื่อผู้ใช้",
  phoneNumber: "เบอร์ติดต่อ",
  payBalance: "ยอดปัจจุบัน",
  creditLimit: "เครดิตจำกัดรวม",
  betList: "",
  name: "",
};

const constants = {
  transferCreditsTitle: "บัญชีการเงิน",
  transferCreditsSubtitle: "โอนยอด",
  inputSearchPlaceHolder: "ค้นหารหัสผู้ใช้ / เบอร์ติดต่อ",
  inputTransferPlaceHolder: "ระบุยอดโอน",
  dataNotFound: "ไม่พบข้อมูล...",
  buttonDetailTitle: "รายละเอียด",
  buttonTransferTitle: "โอนยอด",
};

class TransferCreditsContainer extends Component<
  DefaultProps & RouteComponentProps,
  ITransferCreditContainerState
> {
  static defaultProps = defaultProps;

  state: ITransferCreditContainerState = {
    selectedUser: {
      id: 0,
      username: "",
      phoneNumber: "",
      payBalance: 0,
      creditLimitDiff: "",
      name: "",
      payBody: {
        id: 0,
        balance: "",
      },
    },
    currentPage: { selected: 1 },
    page: {
      search: "",
      page: 1,
      limit: 10,
    },
    queryTimeout: setTimeout(() => {
      noop();
    }, 0),
    dataList: [],
    bodyPostPayReq: {
      id: 0,
      balance: 0,
    },
  };

  componentDidMount() {
    const { page } = this.state;
    this.props.getTransferCreditLists(page);
  }

  componentDidUpdate(prevProps: ITransferCreditContainerProps) {
    if (
      prevProps.getTransferCreditListsIsFetching !==
        this.props.getTransferCreditListsIsFetching &&
      !this.props.getTransferCreditListsIsFetching
    ) {
      if (this.props.getTransferCreditListsCode === responseCode.OK) {
        this.setState((_, props) => ({
          dataList: props.TransferCreditLists.dataList,
        }));
      } else {
        errorModal.show({
          description: this.props.getTransferCreditListsError,
          action: errorModal.hide,
        });
      }
    }
    if (
      prevProps.postPayRequestIsFetching !==
        this.props.postPayRequestIsFetching &&
      !this.props.postPayRequestIsFetching
    )
      if (this.props.PostPayRequestCode === responseCode.OK) {
        SuccessModal.show({
          description: "",
          action: SuccessModal.hide,
        });
        this.props.getTransferCreditLists(this.state.page);
      } else {
        errorModal.show({
          description: this.props.postPayRequestError,
          action: errorModal.hide,
        });
      }
  }

  componentWillUnmount() {
    this.props.cancelTransferCreditLists();
  }

  handlePageClick = ({ selected: selectedPage }: ISelectPage) => {
    this.setState({ currentPage: { selected: selectedPage } }, () => {
      this.setState(
        (prevState) => ({
          page: {
            ...prevState.page,
            page: prevState.currentPage.selected + 1,
          },
        }),
        () => {
          this.props.getTransferCreditLists(this.state.page);
        }
      );
    });
  };

  tableBody = (values: ITransferCreditMockRequest[]) => {
    const dataEmpty = (
      <tr>
        <td className="text-center row-members-list color-row p-2" colSpan={7}>
          {constants.dataNotFound}
        </td>
      </tr>
    );
    const mapData = map(
      values,
      (data: ITransferCreditMockRequest, index: number) => {
        return (
          <tr
            key={index}
            className={`row-members-list ${index % 2 === 0 ? "color-row" : ""} w-100`}
          >
            <td className="text-left">
              <span className="col-2 mt-1 pt-1">{data.username}
              <span className="col-2 mt-1 pt-1 pl-0 font-weight-bold">({data.name})</span>
              </span>
            </td>
            <td className="text-center">
              <span className="col-2">
                {number.formatPhoneNumber(data.phoneNumber)}
              </span>
            </td>
            <td className="text-right">
              <span
                className={`col-2 font-weight-bold ${
                  data.payBalance !== 0 ? "text-danger" : ""
                }`}
              >
                {number.decimalsFormatComma(String(data.payBalance))}
              </span>
            </td>
            <td className="text-right">
              <span className="col-2 font-weight-bold">
                {number.decimalsFormatComma(String(data.creditLimitDiff))}
              </span>
            </td>
            <td className="text-right">
              <div className="row">
                <div className="col py-1">
                  <InputNumber
                    thousandSeparator
                    allowNegative={false}
                    decimalScale={2}
                    key={index}
                    id={`${index}`}
                    name={`input-${index}`}
                    type="text"
                    placeholder={constants.inputTransferPlaceHolder}
                    onValueChange={({ value }) =>
                      this.handleOnchangePayValue(value, index)
                    }
                    value={get(data.payBody, "balance", "")}
                  />
                </div>
              </div>
            </td>
            <td>
              <div className="container">
                <div className="row">
                  <div className="col px-0 mx-1 my-1">
                    <Button
                      id={`button-${index}`}
                      text={constants.buttonTransferTitle}
                      disabled={
                        isEmpty(data.payBody) || data.payBody!.balance === ""
                      }
                      onClick={() => this.onSubmitPay(data)}
                    />
                  </div>
                  <div className="col px-0 mx-1 my-1">
                    <Button
                      backgroundColor={colors.GRAY_TEXT}
                      backgroundHoverColor={colors.SECONDARY_TEXT}
                      id={`button-${index}`}
                      text={constants.buttonDetailTitle}
                      onClick={() => this.handleOnClickDescPage(data)}
                    />
                  </div>
                </div>
              </div>
            </td>
          </tr>
        );
      }
    );
    return !isEmpty(values) ? mapData : dataEmpty;
  };

  tableHead = (values: object) =>
    map(values, (data, keys) => (
      <th
        className={`${keys !== "username" ? "text-right" : "text-left"} ${
          keys === "phoneNumber" && `text-center`
        }`}
        key={keys}
      >
        <div className="col">{data}</div>
      </th>
    ));

  handleOnClickDescPage = (id: ITransferCreditMockRequest) => {
    const navigateAddress = `/transferCreditDescription`;
    this.props.history.replace(navigateAddress, id);
  };

  onSubmitPay = (values: ITransferCreditMockRequest) => {
    
    this.setState(
      {
        bodyPostPayReq: {
          ...values.payBody!,
          id: values.id,
          balance: Number(values.payBody!.balance),
        },
      },
      () => {
        this.props.postPayRequest(this.state.bodyPostPayReq);
        this.setState({
          selectedUser: values,
        });
        values.payBody = {
          balance: "",
          id: values.id,
        };
      }
    );
  };

  handleOnchangePayValue = (values: string, id: number) => {
    let createQuery = this.state.dataList;
    createQuery[id].payBody = {
      balance: values,
      id: id,
    };
    this.setState({
      dataList: [...createQuery],
    });
  };

  handleOnChangeSearch = (
    values: string,
    id: React.FormEvent<HTMLInputElement>
  ) => {
    const searchKeys = `${id.currentTarget.id}`;
    this.setState(
      (prevState) => ({
        page: {
          ...prevState.page,
          page: prevState.currentPage.selected + 1,
          [searchKeys]: values,
        },
      }),
      () => {
        clearTimeout(this.state.queryTimeout);
        this.setState({
          queryTimeout: setTimeout(() => {
            this.props.getTransferCreditLists(this.state.page);
          }, 2000),
        });
      }
    );
  };

  handleOnClickBreadcrumb = (path: string) => {
    this.props.history.replace(path);
  };

  render() {
    const navigates: IBreadcrumbItem[] = [
      {
        label: constants.transferCreditsTitle,
        active: false,
        path: `/credits`,
      },
      {
        label: constants.transferCreditsSubtitle,
        active: true,
      },
    ];
    const { dataList, page, currentPage } = this.state;
    const HeadTable = this.tableHead(mapTransferCreditTableName);
    const TableBody = this.tableBody(dataList);
    return (
      <div className="paper-container transfercredits-container">
        <div className="row">
          <div className="col">
            <div className="sub-menu">
              <Breadcrumb
                items={navigates}
                handleOnClickItem={this.handleOnClickBreadcrumb}
              />
            </div>
            <div className="paper-title">{constants.transferCreditsTitle}</div>
            <div className="inline pr-3 paper-description">
              {constants.transferCreditsSubtitle}
            </div>
            <div className="paper-body">
              <div className="row m3-b">
                <div className="col">
                  <div className="box-shadow">
                    <div className="row  d-flex flex-row-reverse">
                      <div className="col-12 col-xs-8 col-md-6 col-lg-4 text-right">
                        <Input
                          id={`search`}
                          name="inputSearchPlaceHolder"
                          type="text"
                          placeholder={constants.inputSearchPlaceHolder}
                          onChange={(e) =>
                            this.handleOnChangeSearch(e.target.value, e)
                          }
                          value={page.search}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col mt-4">
                        <div className="table-container">
                          <div className="table-responsive-xl">
                            <table className="col">
                              <thead>
                                <tr>{HeadTable}</tr>
                              </thead>
                              <tbody>{TableBody}</tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Panginate
                          pageTotal={this.props.TransferCreditLists.total}
                          initialPage={page.page - 1}
                          pageRangeDisplayed={currentPage.selected}
                          onPageChange={this.handlePageClick}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default TransferCreditsContainer;
