import { createAsyncAction } from 'typesafe-actions'
import {
    ON_CHANGE_MENU_REQUEST,
    ON_CHANGE_MENU_SUCCESS,
    CLEAR_MENU,
    ON_CHANGE_SHOW_MENU_REQUEST,
    ON_CHANGE_SHOW_MENU_SUCCESS,
    CLEAR_SHOW_MENU
} from './constants'


const menuAction = createAsyncAction(
    ON_CHANGE_MENU_REQUEST,
    ON_CHANGE_MENU_SUCCESS,
    CLEAR_MENU,
)<any, IMenuList, any>()

const menuShowAction = createAsyncAction(
    ON_CHANGE_SHOW_MENU_REQUEST,
    ON_CHANGE_SHOW_MENU_SUCCESS,
    CLEAR_SHOW_MENU,
)<any, boolean, any>()

export {
    menuAction,
    menuShowAction
}