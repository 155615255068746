import { AxiosError, AxiosResponse } from "axios"
import { Dispatch } from "redux"
import { getBetExtractionAction, getBetExtractionDetailAction } from "../actionCreator"
import { fetchGetBetExtraction, fetchGetBetExtractionDetail} from "../services"



const getBetExtraction = (query: IV3QueryGetExtractionAgentBetLottery ) => (dispatch: Dispatch) =>{
  dispatch(getBetExtractionAction.request({}))
  return fetchGetBetExtraction(query)
      .then((res:AxiosResponse<IAPIResponse<IV3ExtractionGroupingLottery[]>>) => {
          dispatch(getBetExtractionAction.success(res))
      })
      .catch((error: AxiosError<IAPIResponse>) => dispatch(getBetExtractionAction.failure(error)))
}

const getBetExtractionDetail = (query: IV3QueryGetDetailExtractionAgentBetLottery ) => (dispatch: Dispatch) =>{
    dispatch(getBetExtractionDetailAction.request({}))
    return fetchGetBetExtractionDetail(query)
        .then((res:AxiosResponse<IAPIResponse<IV3RespGetDetailExtractionAgentBetLottery[]>>) => {
            dispatch(getBetExtractionDetailAction.success(res))
        })
        .catch((error: AxiosError<IAPIResponse>) => dispatch(getBetExtractionDetailAction.failure(error)))
  }

export { getBetExtraction, getBetExtractionDetail }
