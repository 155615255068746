import { AxiosError, AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import { memberUpdateAction, postUserAction, memberDetailAction, memberDeleteeAction, memberRePasswordAction } from '../actionCreator'
import { fetchMemberUpdate, fetchMemberRegister, fetchMemberDetail, fetchMemberDelete, fetchMemberRePassword } from '../services'



const postMemberRegister = (data: ICreateMember) => (dispatch: Dispatch) => {
    dispatch(postUserAction.request({}))
    return fetchMemberRegister(data)
        .then((res: AxiosResponse<IAPIResponse<object>>) => {
            dispatch(postUserAction.success(res))
        })
        .catch((error: AxiosError<IAPIResponse>) => dispatch(postUserAction.failure(error)))
}

const postMemberUpdate = (data: IAgentUpdate) => (dispatch: Dispatch) => {
    dispatch(memberUpdateAction.request({}))
    return fetchMemberUpdate(data)
        .then((res: AxiosResponse<IAPIResponse<object>>) => {
            dispatch(memberUpdateAction.success(res))
        })
        .catch((error: AxiosError<IAPIResponse>) => dispatch(memberUpdateAction.failure(error)))
}

const getMemberDetail = (data: IAgentMemberDetail) => (dispatch: Dispatch) => {
    dispatch(memberDetailAction.request({}))
    return fetchMemberDetail(data)
        .then((res: AxiosResponse<IAPIResponse<IAgentUpdate>>) => {
            dispatch(memberDetailAction.success(res))
        })
        .catch((error: AxiosError<IAPIResponse>) => dispatch(memberDetailAction.failure(error)))
}

const postMemberDelete = (data: IAgentMemberDetail) => (dispatch: Dispatch) => {
    dispatch(memberDeleteeAction.request({}))
    return fetchMemberDelete(data)
        .then((res: AxiosResponse<IAPIResponse<object>>) => {
            dispatch(memberDeleteeAction.success(res))
        })
        .catch((error: AxiosError<IAPIResponse>) => dispatch(memberDeleteeAction.failure(error)))
}

const postMemberRePassword = (data: IAgentMemberDetail) => (dispatch: Dispatch) => {
    dispatch(memberRePasswordAction.request({}))
    return fetchMemberRePassword(data)
        .then((res: AxiosResponse<IAPIResponse<object>>) => {
            dispatch(memberRePasswordAction.success(res))
        })
        .catch((error: AxiosError<IAPIResponse>) => dispatch(memberRePasswordAction.failure(error)))
}
export {
    postMemberRegister,
    postMemberUpdate,
    getMemberDetail,
    postMemberDelete,
    postMemberRePassword
}