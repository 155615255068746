import { AxiosError, AxiosResponse } from "axios";
import { createAction, createAsyncAction } from "typesafe-actions";
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  LOGIN_REQUEST_INITIAL_PASSWORD,
  LOGIN_SUCCESS_INITIAL_PASSWORD,
  LOGIN_FAILURE_INITIAL_PASSWORD,
  SET_AUTHENTINATION_KEY_REQUEST,
  SET_AUTHENTINATION_KEY_SUCCESS,
  SET_AUTHENTINATION_KEY_FAILURE,
  CREATE_OTP_REQUEST,
  CREATE_OTP_SUCESS,
  CREATE_OTP_FAILURE,
  LOGIN_REQUEST_PASSWORD,
  LOGIN_SUCCESS_PASSWORD,
  LOGIN_FAILURE_PASSWORD,
  RESET_LOGIN,
  RESET_AUTHENTICATION_KEY,
  RESET_OTP,
  SET_PASSWORD_EXPIRED_REQUEST,
  SET_PASSWORD_EXPIRED_SUCCESS,
  SET_PASSWORD_EXPIRED_FAILURE,
  REGISTER_2FA_REQUEST,
  REGISTER_2FA_SUCCESS,
  REGISTER_2FA_FAILURE,
  VERIFY_2FA_REQUEST,
  VERIFY_2FA_SUCCESS,
  VERIFY_2FA_FAILURE,
  VALIDATE_2FA_REQUEST,
  VALIDATE_2FA_SUCCESS,
  VALIDATE_2FA_FAILURE,
} from "./constants";

const loginAction = createAsyncAction(
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE
)<
  any,
  AxiosResponse<IAPIResponse<IResponseAgentLogin>>,
  AxiosError<IAPIResponse>,
  AxiosError<IAPIResponse<IResponseAgentLogin>>
>();

const logoutAction = createAsyncAction(
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE
)<any, number, number>();

const setAuthenticationAction = createAsyncAction(
  SET_AUTHENTINATION_KEY_REQUEST,
  SET_AUTHENTINATION_KEY_SUCCESS,
  SET_AUTHENTINATION_KEY_FAILURE
)<any, IResponseAgentLogin, number>();


const loginInitialPasswordAction = createAsyncAction(
  LOGIN_REQUEST_INITIAL_PASSWORD,
  LOGIN_SUCCESS_INITIAL_PASSWORD,
  LOGIN_FAILURE_INITIAL_PASSWORD
)<
  any,
  AxiosResponse<IAPIResponse<IResponseAgentInitialPassword>>,
  AxiosError<IAPIResponse>
>();

const createOTPAction = createAsyncAction(
  CREATE_OTP_REQUEST,
  CREATE_OTP_SUCESS,
  CREATE_OTP_FAILURE
)<any, AxiosResponse<IAPIResponse<ICreateOTP>>, AxiosError<IAPIResponse>>();

const loginPasswordAction = createAsyncAction(
  LOGIN_REQUEST_PASSWORD,
  LOGIN_SUCCESS_PASSWORD,
  LOGIN_FAILURE_PASSWORD
)<any, AxiosResponse<IAPIResponse<IResponseAgentPassword>>, AxiosError<IAPIResponse>>();

const resetLoginAction = createAction(RESET_LOGIN)<any>();

const resetAuthenticationKeyAction = createAction(
  RESET_AUTHENTICATION_KEY
)<any>();

const resetOTPAction = createAction(RESET_OTP)<ICreateOTP>();

const setPasswordExpiredAction = createAsyncAction(
  SET_PASSWORD_EXPIRED_REQUEST,
  SET_PASSWORD_EXPIRED_SUCCESS,
  SET_PASSWORD_EXPIRED_FAILURE,
)<any, APIResponse<IResponseAPIPasswordExpired>, AxiosError<IAPIResponse>>()

const register2FAAction = createAsyncAction(
  REGISTER_2FA_REQUEST,
  REGISTER_2FA_SUCCESS,
  REGISTER_2FA_FAILURE,
)<any, AxiosResponse<IAPIResponse<IResponse2FARegister>>, AxiosError<IAPIResponse>>()

const verify2FAAction = createAsyncAction(
  VERIFY_2FA_REQUEST,
  VERIFY_2FA_SUCCESS,
  VERIFY_2FA_FAILURE,
)<any, IResponse2FAVerify, number>()

const validate2FAAction = createAsyncAction(
  VALIDATE_2FA_REQUEST,
  VALIDATE_2FA_SUCCESS,
  VALIDATE_2FA_FAILURE,
)<any, AxiosResponse<IAPIResponse<IResponse2FAVerify>>, AxiosError<IAPIResponse>>()

export {
  loginAction,
  logoutAction,
  setAuthenticationAction,
  resetLoginAction,
  resetOTPAction,
  resetAuthenticationKeyAction,
  createOTPAction,
  loginInitialPasswordAction,
  loginPasswordAction,
  setPasswordExpiredAction,
  register2FAAction,
  verify2FAAction,
  validate2FAAction
};
