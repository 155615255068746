import { AxiosError, AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import { getEditUserHistoryAction } from './actionCreator'
import { fetchGetEditUserHistory } from './services'
import { loaderAction } from '../Loader/actionCreator'


const getEditUserHistory = (userQuery: IEditUserHistoryQuery) => async (dispatch: Dispatch) => {
    dispatch(getEditUserHistoryAction.request({}))
    dispatch(loaderAction.success(true))
    await fetchGetEditUserHistory(userQuery)
        .then((response: AxiosResponse<IAPIResponse<IPagination<IEditUserHistory>>>) => {
            dispatch(loaderAction.success(false))
            return dispatch(getEditUserHistoryAction.success(response))
        })
        .catch((error: AxiosError<IAPIResponse>) => {
            dispatch(loaderAction.success(false))
            return dispatch(getEditUserHistoryAction.failure(error))
        })
}

const clearEditUserHistory = () => (dispatch: Dispatch) =>
    dispatch(getEditUserHistoryAction.cancel({}))


const editUserHistory = {
    getEditUserHistory,
    clearEditUserHistory,
}

export default editUserHistory

