import { connect } from "react-redux";
import { getChildDetail } from "reduxs/child/childDetail/action";
import MemberDetailContainer from "./MemberDetail.container";
import { onChangeIsLoaddingAction } from "../../reduxs/Loader/action";
import { getMeSetting } from "reduxs/me/setting/action";
import { postChildUpdate } from "reduxs/child/childUpdate/action";
import { postChildSettingLotteryUpdate } from "reduxs/child/childSettingLotteryUpdate/action";
import { getMeSettingLottery } from "reduxs/me/settingLottery/action"
import { postChildSettingUpdate } from "reduxs/child/childSettingUpdate/action";
import { postChildCreditUpdate } from "reduxs/child/childCreditUpdate/action";
import { getMe } from "reduxs/me/me/action";

const mapStateToProps = (state: RootReducers): IMemberDetailProps => {
  return {
    childDetailCode: state.child.childDetail.code,
    childDetailError: state.child.childDetail.error,
    childDetailData: state.child.childDetail.data,
    childDetailIsFetching: state.child.childDetail.isFetching,
    meData: state.me.me.data,
    meSettingCode: state.me.setting.code,
    meSettingError: state.me.setting.error,
    meSettingIsFetching: state.me.setting.isFetching,
    meSettingData: state.me.setting.data,
    childUpdateCode: state.child.childUpdate.code,
    childUpdateError: state.child.childUpdate.error,
    childUpdateIsFetching: state.child.childUpdate.isFetching,
    childSettingLotteryUpdateCode: state.child.childSettingLotteryUpdate.code,
    childSettingLotteryUpdateIsFetching: state.child.childSettingLotteryUpdate.isFetching,
    childSettingLotteryUpdateError: state.child.childSettingLotteryUpdate.error,
    meSettingLotteryCode: state.me.settingLottery.code,
    meSettingLotteryError: state.me.settingLottery.error,
    meSettingLotteryIsFetching: state.me.settingLottery.isFetching,
    meSettingLotteryData: state.me.settingLottery.data,
    childSettingUpdateCode: state.child.childSettingUpdate.code,
    childSettingUpdateError: state.child.childSettingUpdate.error,
    childSettingUpdateIsFetching: state.child.childSettingUpdate.isFetching,
    childCreditUpdateCode: state.child.childCreditUpdate.code,
    childCreditUpdateError: state.child.childCreditUpdate.error,
    childCreditUpdateIsFetching: state.child.childCreditUpdate.isFetching,
  };
};
const mapDispatchToProps: IMemberDetailActionProps = {
  getChildDetail,
  onChangeIsLoaddingAction,
  getMeSetting,
  postChildUpdate,
  postChildSettingLotteryUpdate,
  getMeSettingLottery,
  postChildSettingUpdate,
  postChildCreditUpdate,
  getMe
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberDetailContainer);
