import { AGENT_STATUS_TO_NAME } from "constants/variables";
import { format } from "date-fns";
import { th } from "date-fns/locale";
import { get } from "lodash";
import React from "react";
import { Component } from "react";
import { date, number } from "utils";
import "./memberSpecificInfo.style.scss";
import { LoadingCircle } from "components";

const constants = {
  memberSpecificInfoTitle: "ข้อมูลจำเพาะ",
  status: "สถานะผู้ใช้คนนี้",
  noOfMember: "จำนวนลูกสมาชิก",
  profitAndLoss: "ยอดกำไร / ขาดทุนผู้ใช้คนนี้",
  profitAndLossHostReceived: "ยอดกำไร / ขาดทุนที่คุณได้รับ",
  currentCredit: "จำนวนเครดิตปัจจุบัน / เครดิตจำกัด",
  creditToMember: "เครดิตทั้งหมดที่ผู้ใช้คนนี้มอบให้ลูกสมาชิก",
  creditToAllMembers: "จำนวนเครดิตคงเหลือที่ผู้ใช้คนนี้สามารถมอบให้ลูกสมาชิก",
  betTransaction: "ยอดรวมเดิมพันของลูกสมาชิกและผู้ใช้คนนี้",
  allBetTransaction: "ยอดรวมเดิมพันทั้งหมดของลูกสมาชิกผู้ใช้คนนี้",
  lastBillTotal: "ยอดรวมที่ค้างอยู่ในบิลล่าสุดของลูกสมาชิกและผู้ใช้คนนี้",
  allMembersBetTransaction: "ยอดเดิมพันปัจจุบันทั้งหมดของลูกสมาชิกผู้ใช้คนนี้",
  totalBet: "ยอดรวมครั้งที่เคยเดิมพันทั้งหมด",
  currentTotalBet: "ยอดรวมเดิมพันที่ค้างอยู่ในปัจจุบันของลูกสมาชิกและผู้ใช้คนนี้",
};

const defaultProps: IMemberSpecificInfoProps = {
  childDetailData: [],
  loading: false
};

class MemberSpecificInfoComponent extends Component<
  IMemberSpecificInfoProps,
  IMemberSpecificInfoState
> {
  static defaultProps = defaultProps;
  state: IMemberSpecificInfoState = {};

  render() {
    const { childDetailData, loading } = this.props

    return (
      <div className="paper-body member-specific-info-container">
        <div className="row mt-4">
          <div className="col">
            <div
              className="box-shadow"
            >
              <div className="row">
                <div className="col-12 pr-3 paper-body-title">
                  <div className="d-flex align-items-center">
                    {constants.memberSpecificInfoTitle}
                    {loading && (<div className="m1-l"><LoadingCircle /> </div>)}
                  </div>
              </div>
              <div className="col-12 m1-t">
                <div className="content-text">
                  {`อัพเดทเมื่อ ${format(date.calibratingTime(childDetailData[0]?.childUpdatedAt, true), 'd MMM yyyy HH:mm', { locale: th })}`}
                </div>
              </div>
              <div className="col-3 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text">{constants.status}</div>
                  <div className={`${loading ? 'tertiary-text' : 'paper-body-title'} mt-1`}>{AGENT_STATUS_TO_NAME[get(childDetailData[0], 'childAgentStatus', 'NORMAL')]}</div>
                </div>
              </div>
              <div className="col-3 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text">{constants.noOfMember}</div>
                  <div className={`${loading ? 'tertiary-text' : 'paper-body-title'} mt-1`}>{`${childDetailData[0]?.childChildTotal} คน`}</div>
                </div>
              </div>
              <div className="col-3 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text">
                    {constants.profitAndLoss}
                  </div>
                  <div className={`${loading ? 'tertiary-text' : 'paper-body-title'} mt-1`}>{`${number.addComma(childDetailData[0]?.childProfitTotal)} / ${number.addComma(childDetailData[0]?.childLossTotal)} บาท`}</div>
                </div>
              </div>
              <div className="col-3 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text">
                    {constants.profitAndLossHostReceived}
                  </div>
                  <div className={`${loading ? 'tertiary-text' : 'paper-body-title'} mt-1`}>{`${number.addComma(childDetailData[0]?.childProfitBenefitTotal)} / ${number.addComma(childDetailData[0]?.childLossBenefitTotal)} บาท`}</div>
                </div>
              </div>
              <div className="col-3 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text">
                    {constants.currentCredit}
                  </div>
                  <div className={`${loading ? 'tertiary-text' : 'paper-body-title'} mt-1`}>{`${number.addComma(childDetailData[0]?.childCredit)} / ${number.addComma(childDetailData[0]?.childCreditLimit)} บาท`}</div>
                </div>
              </div>
              <div className="col-3 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text">
                    {constants.creditToMember}
                  </div>
                  <div className={`${loading ? 'tertiary-text' : 'paper-body-title'} mt-1`}>{`${number.addComma(childDetailData[0]?.childOfChildCreditLimit)} บาท`}</div>
                </div>
              </div>
              <div className="col-3 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text">
                    {constants.creditToAllMembers}
                  </div>
                  <div className={`${loading ? 'tertiary-text' : 'paper-body-title'} mt-1`}>{`${number.addComma(childDetailData[0]?.childCreditShareLimit)} บาท`}</div>
                </div>
              </div>
              <div className="col-3"></div>
              <div className="col-3 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text">
                    {constants.betTransaction}
                  </div>
                  <div className={`${loading ? 'tertiary-text' : 'paper-body-title'} mt-1`}>{`${number.addComma(childDetailData[0]?.childBetTotal)} บาท`}</div>
                </div>
              </div>
              <div className="col-3 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text">
                    {constants.allBetTransaction}
                  </div>
                  <div className={`${loading ? 'tertiary-text' : 'paper-body-title'} mt-1`}>{`${number.addComma(childDetailData[0]?.childOfChildBetTotal)} บาท`}</div>
                </div>
              </div>
              <div className="col-3 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text">
                    {constants.lastBillTotal}
                  </div>
                  <div className={`${loading ? 'tertiary-text' : 'paper-body-title'} mt-1`}>{`${number.addComma(childDetailData[0]?.childCurrentBetTotal)} บาท`}</div>
                </div>
              </div>
              <div className="col-3"></div>
              <div className="col-3 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text">
                    {constants.allMembersBetTransaction}
                  </div>
                  <div className={`${loading ? 'tertiary-text' : 'paper-body-title'} mt-1`}>{`${number.addComma(childDetailData[0]?.childOfChildCurrentBetTotal)} บาท`}</div>
                </div>
              </div>
              <div className="col-3 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text">
                    {constants.totalBet}
                  </div>
                  <div className={`${loading ? 'tertiary-text' : 'paper-body-title'} mt-1`}>{`${number.addComma(childDetailData[0]?.childBetCountTotal)} ครั้ง`}</div>
                </div>
              </div>
              <div className="col-3 m3-t">
                <div className="d-flex flex-column">
                  <div className="content-text">
                    {constants.currentTotalBet}
                  </div>
                  <div className={`${loading ? 'tertiary-text' : 'paper-body-title'} mt-1`}>{`${number.addComma(childDetailData[0]?.childCurrentBetCountTotal)} ครั้ง`}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div >
    );
  }
}

export default MemberSpecificInfoComponent;
