import { Dispatch } from "redux";
import { transferConfirmationAction } from "../actionCreator";
import { loaderAction } from "../../Loader/actionCreator";
import { fetchTransferConfirmations } from "../services";
import { AxiosError, AxiosResponse } from "axios";

const getTransferConfirmation = (
  query: ISearchAgentFinancePayListQuery
) => async (dispatch: Dispatch) => {
  dispatch(loaderAction.success(true));
  dispatch(transferConfirmationAction.request({}));
  await fetchTransferConfirmations(query)
    .then(
      (
        response: AxiosResponse<
          IAPIResponse<IPagination<IResponseAgentRecvList>>
        >
      ) => {
        dispatch(loaderAction.success(false));
        return dispatch(transferConfirmationAction.success(response));
      }
    )
    .catch((error: AxiosError<APIResponse>) => {
      dispatch(loaderAction.success(false));
      return dispatch(transferConfirmationAction.failure(error));
    });
};

const cancelTranfersConfirmation = () => (dispatch: Dispatch) =>
  dispatch(transferConfirmationAction.cancel({}));

export { getTransferConfirmation, cancelTranfersConfirmation };
