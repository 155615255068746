import "./createMember.style.scss";
import { interactive } from "utils";
import colors from "constants/colors";
import schemeCreateUser from "./model/scheme";
import { responseCode, responseMessage } from "constants/response";
import { FormikProps, Formik, Form } from "formik";
import { meDataInitialValue } from "constants/variables";
import { RouteComponentProps } from "react-router-dom";
import { handleOnClickBreadcrumb } from "utils";
import { noop, isEmpty, forOwn, pick, map, omit, mapValues, cloneDeep, every, isUndefined } from "lodash";
import React, { Component, createRef, RefObject } from "react";
import Select from "react-select";
import {
  LEVEL_TYPE_PREMISSION,
  STATUS_SELECT,
} from "constants/variables";
import {
  Input,
  Button,
  Breadcrumb,
  InputNumber,
  ConfirmModal,
  ErrorModal,
  SuccessModal,
  InputElements,
  LoadingCircle,
  LoadingButton,
} from "components";
import color from "constants/colors";
import { createMemberDropdownStyles } from "./CreateMemberDropdown.styles";
import { SPECIAL_ENG } from "constants/regex";
import routes from "constants/routes";
import { NewLottoBetConfig } from "./components/NewLottoBetConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// import copy from "copy-to-clipboard";
const constants = {
  copyShow: 'คัดลอกแล้ว!',
  title: "ข้อมูลสมาชิก",
  rePassword: "เปลี่ยนรหัสผ่านใหม่",
  description: "สร้างสมาชิก",

  titleUserInfo: "ข้อมูลผู้ใช้",
  permission: "สิทธิการใช้งาน *",
  name: "ชื่อผู้ใช้",
  username: "ชื่อผู้ใช้ *",
  phoneNumber: "เบอร์ติดต่อ",

  password: "รหัสผ่าน *",
  passwordPlaceholder: "รหัสผ่าน",

  confirmPassword: "ยืนยันรหัสผ่าน *",
  confirmPasswordPlaceholder: "ยืนยันรหัสผ่าน",

  line: "ไลน์ไอดี",
  creditLimit: "วงเงิน",
  placeholderCreditLimit: "กรุณากรอกวงเงิน",
  isAccess: "สถานะการใช้งาน *",
  titleBet: "หวยออนไลน์",
  betSetting: "ตั้งค่าหวย",
  otp: "หมายเลข OTP",
  placeholderOTP: "หมายเลข OTP",
  resendOTP: "ขอ OTP ใหม่",
  requestOTP: "ขอ OTP",
  copy: "Copy",
  requestOTPSuccessTitle: "ส่ง OTP สำเร็จ",
  requestOTPSuccess: "ระบบได้ส่ง OTP ไปยังหมายเลขที่ระบุไว้เรียบร้อย กรุณารอสักครู่",
  submit: "สร้างสมาชิก",
  cancel: "ยกเลิก",
  deleteMembers: "ลบสมาชิก",

  level: "สิทธิ",
  status: "ห้ามใช้งาน",
  placeholderName: "กรุณากรอกรหัสผู้ใช้",
  placeholderUsername: "ชื่อผู้ใช้",
  placeholderLine: "ไลน์ไอดี",
  placeholderPhoneNumber: "เบอร์ติดต่อ",
  preSeleteCopy: "เลือกผู้ใช้ที่ต้องการคัดลอก",

  createSuccess: "เพิ่มสมาชิกสำเร็จ",
  createConfirm: "คุณแน่ใจหรือไม่ว่าจะสร้างสมาชิก",

  deleteSuccess: "ลบสมาชิกสำเร็จ",
  deleteConfirm: "คุณแน่ใจหรือว่าจะลบสมาชิก",

  updateSuccess: "อัพเดทข้อมูลสมาชิกสำเร็จ",
  updateConfirm: "คุณแน่ใจหรือว่าจะอัพเดทข้อมูลสมาชิก",

  rePasswordSuccess: "รหัสผ่านใหม่ถูกส่งหาสมาชิกด้วย sms เรียบร้อยแล้ว",
  rePasswordConfirm: "คุณแน่ใจหรือว่าจะเปลี่ยนรหัสผ่าน ของ",

  cancelConfirm: "คุณแน่ใจหรือว่าจะยกเลิกแก้ไข",

  breadcrumb: {
    main: "ภาพรวม",
    membersList: "รายการสมาชิก",
    addMembers: "สร้างสมาชิก",
  },

  helperName: "ชื่อช่วยจำ *",
  helperNamePlaceholder: "ชื่อช่วยจำ",

  titleFinanceInfo: "ข้อมูลการเงิน",

  postChildSuccess: "บันทึกข้อมูลสมาชิกเรียบร้อยแล้ว",
  postChildSuccessTitle: "สร้างสมาชิกสำเร็จ",

  noInfo: "ไม่มีข้อมูล",
};

const defaultProps: IEditUserActionProps & IEditUserProps = {
  postRegisterUser() {
    noop();
  },
  postMemberUpdate() {
    noop();
  },
  postMemberDelete() {
    noop();
  },
  postMemberRePassword() {
    noop();
  },
  createOTP() {
    noop();
  },
  getDataMain() {
    noop();
  },
  getMemberDetail() {
    noop();
  },
  onChangeIsLoaddingAction() {
    noop();
  },
  getMeSetting() {
    noop();
  },
  postOtp(data) {
    noop();
  },
  getMeSettingLottery() {
    noop();
  },
  postChild(data) {
    noop();
  },

  mainCode: 0,
  mainError: "",
  mainIsFetching: false,
  mainData: {
    username: "Host name",
    permission: "MEMBER",
    line: "",
    status: "",
    childCount: 0,
    currency: "THB",
    betResultTotal: 0,
    betWaitTotal: 0,
    maxBet: 0,
    minBet: 0,
    childCreditTotal: 0,
    credit: 0,
    creditLimit: "",
  },

  memberRegisterCode: 0,
  memberRegisterIsFetching: false,
  memberRegisterError: "",
  memberRegisterData: {
    data: {},
    code: 0,
    devMessage: "",
  },

  memberRePasswordCode: 0,
  memberRePasswordIsFetching: false,
  memberRePasswordError: "",
  memberRePasswordData: {
    data: {},
    code: 0,
    devMessage: "",
  },

  memberDeleteCode: 0,
  memberDeleteIsFetching: false,
  memberDeleteError: "",
  memberDeleteData: {
    data: {},
    code: 0,
    devMessage: "",
  },

  memberUpdateCode: 0,
  memberUpdateIsFetching: false,
  memberUpdateError: "",
  memberUpdateData: {
    data: {},
    code: 0,
    devMessage: "",
  },

  meSettingCode: 0,
  meSettingIsFetching: false,
  meSettingError: "",
  meSettingData: {
    agentId: 0,
    setBetMaxLimit: 100,
    setBetMinLimit: 1,
    setComPercentage: 0,
  },

  memberDetailCode: 0,
  memberDetailIsFetching: false,
  memberDetailError: "",
  memberDetailData: {
    id: 0,
    permission: "PleaseChoose",
    isAccess: false,
    line: "",
    currency: "THB",
    creditLimit: 0.0,
    maxBet: 0.0,
    minBet: 0.0,
    discountPercentage: 0.0,
    lotteryConfig: [],
    name: "",
  },
  meCode: 0,
  meData: meDataInitialValue,
  meError: "",
  meIsFetching: false,
  otpCode: 0,
  otpData: {
    sendCount: 0,
    smsRef: "",
  },
  otpError: "",
  otpIsFetching: false,
  childCode: 0,
  childData: {},
  childError: "",
  childIsFetching: false,
  meSettingLotteryCode: 0,
  meSettingLotteryData: {
    agentId: 0,
    lotterySetting: [],
  },
  meSettingLotteryError: "",
  meSettingLotteryIsFetching: false,
};
class EditUserContainer extends Component<
  IEditUserActionProps & IEditUserProps & RouteComponentProps
> {
  otpInputRef: RefObject<HTMLInputElement> = createRef();
  errorRef: RefObject<HTMLDivElement> = createRef();

  betConfigRef: RefObject<HTMLDivElement> = createRef();
  static defaultProps = defaultProps;
  state: IEditUserState = {
    createMemberData: {
      name: "",
      username: "",
      password: "",
      confirmPassword: "",
      permission: "PleaseChoose",
      isAccess: true,
      otp: "",
      phoneNumber: "",
      line: "",
      currency: "THB",
      betMaxLimit: 122,
      betMinLimit: 1,
      discountPercentage: 0,
      lotterySetting: [],
      creditLimit: 0,
      com: 0,
      bankName: "",
      bankNumber: "",
      bankType: "PleaseChoose",
      hostSharePercentage: 0,
      fixSharePercentage: 0,
      discountAll: "",
      childLotsetIsOpen: false,
      childLotsetKeep: false,
      meShare: 0,
      mustReciv: 0,
      minBet: 0,

      childLotsetBetRateRunDown: 1,
      childLotsetBetRateRunUp: 1,
      childLotsetBetRateThreeToast: 1,
      childLotsetBetRateThreeUp: 1,
      childLotsetBetRateTwoDown: 1,
      childLotsetBetRateTwoUp: 1,

      childLotsetDisRunDown: 0,
      childLotsetDisRunUp: 0,
      childLotsetDisThreeToast: 0,
      childLotsetDisThreeUp: 0,
      childLotsetDisTwoDown: 0,
      childLotsetDisTwoUp: 0,

      childLotsetShareComRunDown: 0,
      childLotsetShareComRunUp: 0,
      childLotsetShareComThreeToast: 0,
      childLotsetShareComThreeUp: 0,
      childLotsetShareComTwoDown: 0,
      childLotsetShareComTwoUp: 0,

      childLotsetBetMinThreeUp: 1,
      childLotsetBetMinThreeToast: 1,
      childLotsetBetMinTwoUp: 1,
      childLotsetBetMinTwoDown: 1,
      childLotsetBetMinRunUp: 1,
      childLotsetBetMinRunDown: 1,

      childLotsetBetMaxThreeUp: 1000,
      childLotsetBetMaxThreeToast: 1000,
      childLotsetBetMaxTwoUp: 1000,
      childLotsetBetMaxTwoDown: 1000,
      childLotsetBetMaxRunUp: 1000,
      childLotsetBetMaxRunDown: 1000,

      childLotsetBetMinThreeUpAPI: 0,
      childLotsetBetMinThreeToastAPI: 0,
      childLotsetBetMinTwoUpAPI: 0,
      childLotsetBetMinTwoDownAPI: 0,
      childLotsetBetMinRunUpAPI: 0,
      childLotsetBetMinRunDownAPI: 0,

      childLotsetBetMaxThreeUpAPI: 0,
      childLotsetBetMaxThreeToastAPI: 0,
      childLotsetBetMaxTwoUpAPI: 0,
      childLotsetBetMaxTwoDownAPI: 0,
      childLotsetBetMaxRunUpAPI: 0,
      childLotsetBetMaxRunDownAPI: 0,

      childLotsetBetRateThreeUpAPI: 0,
      childLotsetBetRateThreeToastAPI: 0,
      childLotsetBetRateTwoUpAPI: 0,
      childLotsetBetRateTwoDownAPI: 0,
      childLotsetBetRateRunUpAPI: 0,
      childLotsetBetRateRunDownAPI: 0,
    },
    isOtpClicked: false,
    copyShow: "",
    hidePass: false,
    hideConfirmPass: false,

  };


  componentDidMount() {
    if (this.props.meData.permission === "MEMBER") {
      this.props.history.push("/");
    } else {
      this.props.getMeSetting();
      this.props.getMeSettingLottery();
    }
  }
  componentDidUpdate(prevProps: IEditUserProps, prevState: IEditUserState) {
    if (
      prevProps.otpIsFetching !== this.props.otpIsFetching &&
      !this.props.otpIsFetching
    ) {
      if (this.props.otpCode === responseCode.OK) {
        this.setState({ isOtpClicked: true });
        SuccessModal.show({
          title: constants.requestOTPSuccessTitle,
          action: SuccessModal.hide,
          description: constants.requestOTPSuccess,
        });
      } else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: responseMessage()[this.props.otpCode],
          errorCode: this.props.otpCode,
        });
      }
    }

    if (
      prevProps.meSettingIsFetching !== this.props.meSettingIsFetching &&
      !this.props.meSettingIsFetching
    ) {

      const clonedCreateMemberData = cloneDeep(this.state.createMemberData);
      clonedCreateMemberData.betMinLimit =
        this.props.meSettingData.setBetMinLimit > 1000
          ? 1000
          : this.props.meSettingData.setBetMinLimit;
      clonedCreateMemberData.betMaxLimit =
        this.props.meSettingData.setBetMaxLimit > 1000
          ? 1000
          : this.props.meSettingData.setBetMaxLimit;
      this.setState({ createMemberData: clonedCreateMemberData });
    }

    if (
      prevProps.memberRegisterIsFetching !==
      this.props.memberRegisterIsFetching &&
      !this.props.memberRegisterIsFetching
    ) {
      if (this.props.memberRegisterCode === responseCode.OK) {
        this.props.history.replace("/members", {
          msgModal: constants.createSuccess,
        });
      } else {
        if (!isEmpty(this.props.memberRegisterError)) {
          ErrorModal.show({
            action: ErrorModal.hide,
            description: this.props.memberRegisterError,
          });
        }
      }
    }

    if (
      prevProps.memberRePasswordIsFetching !==
      this.props.memberRePasswordIsFetching &&
      !this.props.memberRePasswordIsFetching
    ) {
      if (this.props.memberRePasswordCode === responseCode.OK) {
        this.props.history.replace("/members", {
          msgModal: constants.rePasswordSuccess,
        });
      } else {
        if (!isEmpty(this.props.memberRePasswordError)) {
          ErrorModal.show({
            action: ErrorModal.hide,
            description: this.props.memberRePasswordError,
          });
        }
      }
    }

    if (
      prevProps.memberUpdateIsFetching !== this.props.memberUpdateIsFetching &&
      !this.props.memberUpdateIsFetching
    ) {
      if (this.props.memberUpdateCode === responseCode.OK) {
        this.props.history.replace("/members", {
          msgModal: constants.updateSuccess,
        });
      } else {
        if (!isEmpty(this.props.memberUpdateError)) {
          ErrorModal.show({
            action: ErrorModal.hide,
            description: this.props.memberUpdateError,
          });
        }
      }
    }

    if (
      prevProps.memberDeleteIsFetching !== this.props.memberDeleteIsFetching &&
      !this.props.memberDeleteIsFetching
    ) {
      if (this.props.memberDeleteCode === responseCode.OK) {
        this.props.history.replace("/members", {
          msgModal: constants.deleteSuccess,
        });
      } else {
        if (!isEmpty(this.props.memberDeleteError)) {
          ErrorModal.show({
            action: ErrorModal.hide,
            description: this.props.memberDeleteError,
          });
        }
      }
    }

    if (
      prevProps.childIsFetching !== this.props.childIsFetching &&
      !this.props.childIsFetching
    ) {
      if (this.props.childCode === responseCode.OK) {
        SuccessModal.show({
          title: constants.postChildSuccessTitle,
          action: () => {
            SuccessModal.hide()
            localStorage.removeItem('lotterySettingState')
            this.props.history.push(routes.membersList.path)
          },
          description: constants.postChildSuccess,
        });
      } else if (this.props.childCode === 400104) {
        ErrorModal.show({
          action: () => {
            ErrorModal.hide()
            setTimeout(() => {
              if (this.otpInputRef.current) {
                this.otpInputRef.current.scrollIntoView({ behavior: "smooth" })
              }
            }, 300)
          },
          actionText: 'ขอ OTP ใหม่อีกครั้ง',
          description: this.props.childError,
        });
      } else {
        ErrorModal.show({
          action: ErrorModal.hide,
          description: responseMessage()[
            this.props.childCode || 0
          ],
        });
      }
    }
  }

  getChildSelectedLottoState = (selectedLotto: IV2LotterySetting[] = []) => {
    return selectedLotto;
  };

  submitFormik = async (values: ICreateMember) => {
    const removedIsSavedLotterySetting = map(values.lotterySetting, (item) => {
      return omit(item, ["isSaved", "isNotSavedYet"]);
    });
    const postChildBody = ({
      ...(pick(values, [
        "username",
        "name",
        "password",
        "permission",
        "isAccess",
        "otp",
        "phoneNumber",
        "line",
        "bankType",
        "bankName",
        "bankNumber",
        "creditLimit",
        "betMaxLimit",
        "betMinLimit",
        "com",
      ]) as IV2PostAgentChild),
      lotterySetting: removedIsSavedLotterySetting,
    })
    const numberedPostChildBody = mapValues(postChildBody, (val, key, obj) => {
      if (key.includes("Limit") || key.includes("com")) {
        return ((obj as any)[key] = Number(val));
      } else if (key === "username") {
        return ((obj as any)[key] = `${this.props.meData.username}${val}`);
      } else {
        return ((obj as any)[key] = val);
      }
    });

    const checkObjectIsNotUndefiend = Object.entries(numberedPostChildBody).reduce((obj, [key, val]) => {
      if (isUndefined(val) || val === "PleaseChoose") {
        delete (obj as any)[key];
      } else if (val !== "") {
        (obj as any)[key] = val;
      }
      return obj;
    }, {});
    this.props.postChild(checkObjectIsNotUndefiend as IV2PostAgentChild);
  };


  onPressCopy = (link: string) => {
    navigator.clipboard.writeText(this.props.meData.username + link).then(() => {
      this.setState({ copyShow: constants.copyShow }, () => {
        // setTimeout(()=>{
        //   this.setState({copyShow:''})
        // },1500)
      })
    })
  }

  setDefaulLotterySettingShareCom = (lotterySetting:IV2LotterySetting[]) => {
    return lotterySetting.map(setting => ({
      ...setting,
      childLotsetShareComThreeToast: 0,
      childLotsetShareComThreeUp: 0,
      childLotsetShareComTwoDown: 0,
      childLotsetShareComTwoUp: 0,
      childLotsetShareComRunUp: 0,
      childLotsetShareComRunDown: 0,
    }));
  };


  renderEditUserFormik = () => {
    const CreateUserForm = (formikProps: FormikProps<ICreateMember>) => {
      const gameList =
        LEVEL_TYPE_PREMISSION[
        (this.props.meData.permission as LEVEL_TYPE_PREMISSION) || "AGENT"
        ];
      const formatStatusSelect = () => {
        let formattedStatusSelect: any[] = [];
        forOwn(STATUS_SELECT, function (values, keys) {
          formattedStatusSelect.push({
            label: values,
            value: keys,
            disabled: false,
          });
        });
        return formattedStatusSelect;
      };
      // console.log(formikProps.values.lotterySetting)
      const formattedStatusSelect = formatStatusSelect();

      const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
      } = formikProps;

      return (
        <Form>
          <div className="paper-body">
            <div className="row mt-4">
              <div className="col">
                <div className="box-shadow">
                  <div className="row">
                    <div className="col pr-3 paper-body-title">
                      <div className="d-flex align-items-center">
                        {constants.titleUserInfo}
                        {this.props.meIsFetching && (<div className="m1-l"><LoadingCircle /> </div>)}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 mt-4">
                      <label className="label-input">
                        {constants.phoneNumber}
                      </label>
                      <div className="position-relative">
                        <InputNumber
                          style={{ width: "100%" }}
                          id="phoneNumber"
                          name="phoneNumber"
                          format="### ### ####"
                          decimalScale={0}
                          allowLeadingZeros={false}
                          allowNegative={false}
                          value={values.phoneNumber}
                          onBlur={handleBlur}
                          errorMessage={errors.phoneNumber}
                          backgroundColor={colors.TRANSPARENT}
                          placeholder={constants.placeholderPhoneNumber}
                          error={!!errors.phoneNumber && touched.phoneNumber}
                          onValueChange={({ value }) =>
                            setFieldValue("phoneNumber", value)
                          }
                          inputClassName="input-otp"
                        />
                        <LoadingButton
                          loading={this.props.otpIsFetching}
                          id="request-otp-button"
                          onClick={() => {
                            if (
                              !!!errors.phoneNumber &&
                              !isEmpty(values.phoneNumber)
                            ) {
                              this.props.postOtp({
                                phoneNumber: values.phoneNumber,
                              });
                            }
                          }}
                          buttonType="button"
                          text={constants.requestOTP}
                          textSize={12}
                          lineHeight={"12px"}
                          buttonClass="request-otp-button"
                          textColor={colors.PRIMARY_PURPLE}
                        />
                      </div>
                    </div>

                    <div className="col-4 mt-4">
                      <label className="label-input">{constants.otp}</label>
                      <Input
                        id="otp"
                        name="otp"
                        ref={this.otpInputRef}
                        value={values.otp}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          let newValue = e.target.value.replace(/\D/g, "");
                          newValue.length < 7
                            ? setFieldValue("otp", newValue)
                            : noop();
                        }}
                        inputButton={true}
                        inputClassName="input-otp"
                        errorMessage={errors.otp}
                        backgroundColor={colors.TRANSPARENT}
                        placeholder={constants.placeholderOTP}
                        error={!!errors.otp && touched.otp}
                        disabled={!this.state.isOtpClicked}
                      />
                    </div>
                    <div className="col-4 mt-4"></div>
                    <div className="col-4" style={{ marginTop: 6 }}>
                      <label className="label-input">
                        {constants.permission}
                      </label>
                      <Select
                        styles={createMemberDropdownStyles(
                          false
                        )}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        placeholder={constants.level}
                        name="permission"
                        options={gameList.map((item) => {
                          return {
                            value: item,
                            label:
                              item === "SUPER_AGENT"
                                ? "Master"
                                : item === "AGENT"
                                  ? "Agent" : item === "SENIOR" ? "Senior"
                                    : "Member",
                            disabled: item === "SENIOR",
                          };
                        })}
                        isOptionDisabled={(option) => option.disabled}
                        onChange={(e) => {
                          formikProps.setFieldValue("permission", e?.value);
                        }}
                        noOptionsMessage={() => constants.noInfo}
                      />
                      {!!errors.permission && touched.permission && (
                        <h6 className="primary-red-text m1-t">
                          {errors.permission}
                        </h6>
                      )}
                    </div>
                    <div className="col-4" style={{ marginTop: 6 }}>
                      <label className="label-input">
                        {constants.isAccess}
                      </label>
                      <Select
                        styles={createMemberDropdownStyles(
                          false
                        )}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        placeholder={""}
                        name="isAccess"
                        options={formattedStatusSelect}
                        defaultValue={formattedStatusSelect[1]}
                        onChange={(e) => {
                          if (e!.value === "CLOSE") {
                            formikProps.setFieldValue("isAccess", false);
                          } else {
                            formikProps.setFieldValue("isAccess", true);
                          }
                        }}
                        noOptionsMessage={() => constants.noInfo}
                      />
                      {!!errors.isAccess && touched.isAccess && (
                        <h6 className="primary-red-text m1-t">
                          {errors.isAccess}
                        </h6>
                      )}
                    </div>
                    <div className="col-4" style={{ marginTop: 6 }}></div>
                    <div className="col-4 mt-4">
                      <label className="label-input">
                        {constants.username}
                      </label>
                      <div className="position-relative input-username">
                        <InputElements
                          id="username"
                          name="username"
                          onBlur={handleBlur}
                          onChange={(e: any) => {
                            if (
                              e.target.value.match(SPECIAL_ENG) ||
                              isEmpty(e.target.value)
                            ) {
                              setFieldValue("username", e.target.value);
                              this.setState({ copyShow: '' })
                            }
                          }}
                          value={values.username}
                          placeholder={constants.placeholderUsername}
                          error={!!errors.username && touched.username}
                          errorMessage={errors.username}
                          backgroundColor={colors.TRANSPARENT}
                          inputClassName="input-username"
                          startText={this.props.meData.username}
                        />
                        {/* <div className="position-absolute username-text">
                          {this.props.meData.username}
                        </div> */}
                        <Button
                          id="copy-button"
                          onClick={() => this.onPressCopy(values.username)}
                          buttonType="button"
                          text={constants.copy}
                          textSize={12}
                          lineHeight={"12px"}
                          buttonClass="copy-button"
                          textColor={colors.SECONDARY_TEXT}
                        />
                      </div>
                      <h6 className={`${(!!errors.username && touched.username) ? 'primary-red-text' : 'primary-green-text'} mt-1`}>
                        {!!errors.username && touched.username ? errors.username : !isEmpty(this.state.copyShow) ? this.state.copyShow : "\u00A0"}
                      </h6>
                    </div>
                    <div className="col-4 mt-4 input-password">
                      <label className="label-input">
                        {constants.password}
                      </label>

                      <InputElements
                        id="password"
                        name="password"
                        style={{ width: "100%" }}
                        onBlur={handleBlur}
                        placeholder={constants.passwordPlaceholder}
                        inputClassName="input-otp"
                        backgroundColor={color.TRANSPARENT}
                        value={values.password}
                        onChange={handleChange}
                        errorMessage={errors.password}
                        error={!!errors.password && touched.password}
                        type={this.state.hidePass ? "text" : "password"}
                        endText={<FontAwesomeIcon
                          className="icon-eye"
                          icon={this.state.hidePass ? faEye : faEyeSlash}
                          onClick={() => {
                            this.setState({ hidePass: !this.state.hidePass });
                          }}
                        />}
                      />
                      <h6 className="primary-red-text text-left mt-1">
                        {!!errors.password && touched.password
                          ? errors.password
                          : "\u00A0"}
                      </h6>
                    </div>
                    <div className="col-4 mt-4 input-password">
                      <label className="label-input">
                        {constants.confirmPassword}
                      </label>
                      <InputElements
                        id="confirmPassword"
                        name="confirmPassword"
                        placeholder={constants.confirmPasswordPlaceholder}
                        inputClassName="input-otp"
                        backgroundColor={color.TRANSPARENT}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.confirmPassword}
                        errorMessage={errors.confirmPassword}
                        error={
                          !!errors.confirmPassword && touched.confirmPassword
                        }
                        type={this.state.hidePass ? "text" : "password"}
                        endText={<FontAwesomeIcon
                          className="icon-eye"
                          icon={this.state.hidePass ? faEye : faEyeSlash}
                          onClick={() => {
                            this.setState({ hidePass: !this.state.hidePass });
                          }}
                        />}
                      />
                      <h6 className="primary-red-text text-left mt-1">
                        {!!errors.confirmPassword && touched.confirmPassword
                          ? errors.confirmPassword
                          : "\u00A0"}
                      </h6>
                    </div>

                    <div className="col-6" style={{ marginTop: 6 }}>
                      <label className="label-input">
                        {constants.helperName}
                      </label>
                      <Input
                        id="name"
                        name="name"
                        type="text"
                        placeholder={constants.helperNamePlaceholder}
                        inputClassName="input-otp"
                        backgroundColor={colors.TRANSPARENT}
                        onChange={handleChange}
                        value={values.name}
                        onBlur={handleBlur}
                        errorMessage={errors.name}
                        error={!!errors.name && touched.name}
                      />
                    </div>
                    <div
                      className={`col-6 state-user ${values.isAccess ? "" : "selector-danger"
                        }`}
                      style={{ marginTop: 6 }}
                    >
                      <label className="label-input">{constants.line}</label>
                      <Input
                        id="line"
                        name="line"
                        type="text"
                        placeholder={constants.placeholderLine}
                        value={values.line}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errorMessage={errors.line}
                        error={!!errors.line && touched.line}
                        inputClassName="input-otp"
                        backgroundColor={colors.TRANSPARENT}
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="mt-4">
                  <FinanceInfo
                    {...(formikProps as FormikProps<any>)}
                    meSettingData={this.props.meSettingData}
                    meData={this.props.meData}
                    isOtpClicked={this.state.isOtpClicked}
                  />
                </div> */}

                <div className="mt-4" ref={this.betConfigRef}>
                  <NewLottoBetConfig
                    {...formikProps}
                    lotterySetting={this.setDefaulLotterySettingShareCom(this.props.meSettingLotteryData.lotterySetting)}
                    meSettingData={this.props.meSettingData}
                    creditLimit={values.creditLimit}
                    minBetHost={this.props.mainData.minBet}
                    isOtpClicked={this.state.isOtpClicked}
                    getChildSelectedLottoState={this.getChildSelectedLottoState}
                    onChangeIsLoaddingAction={this.props.onChangeIsLoaddingAction}
                    mode={"CREATE"}
                    meSettingLotteryData={this.props.meSettingLotteryData}
                    meSettingLotteryCode={this.props.meSettingLotteryCode}
                    meSettingLotteryIsFetching={this.props.meSettingLotteryIsFetching}
                    meData={this.props.meData}
                  />
                </div>
                <div className="row pt-3">
                  <div className="col">
                    <Button
                      buttonType="submit"
                      id="save"
                      size="large"
                      textSize={18}
                      text={constants.submit}
                      onClick={() => {
                        if (!isEmpty(errors)) {
                          interactive.scrollTo(this.errorRef);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="row mt-2 justify-content-center">
                  <div className="col-auto text-center primary-red-text">
                    <Button
                      id="reset"
                      text={constants.cancel}
                      type="underline"
                      textSize={18}
                      textColor={colors.PRIMARY_GREEN}
                      backgroundColor={color.TRANSPARENT}
                      backgroundHoverColor={color.TRANSPARENT}
                      onClick={() => {
                        ConfirmModal.show({
                          action: () => {
                            this.props.history.replace(routes.membersList.path);
                            ConfirmModal.hide();
                          },
                          cancelAction: () => {
                            ConfirmModal.hide();
                          },
                          description: constants.cancelConfirm,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      );
    };
    return (
      <Formik
        initialValues={this.state.createMemberData}
        validationSchema={schemeCreateUser(
          this.props.mainData.username,
          this.props.meSettingData,
          this.props.meData
        )}
        enableReinitialize
        onSubmit={(values) => {
          if (!every(values.lotterySetting, ['isNotSavedYet', false])) {
            ErrorModal.show({
              action: () => {
                ErrorModal.hide()
                setTimeout(() => {
                  if (this.betConfigRef.current) {
                    this.betConfigRef.current.scrollIntoView({ behavior: "smooth" })
                  }
                }, 300)
              },
              description: "มีหวยที่ยังไม่บันทึก กรุณาบันทึกหวยก่อนสร้างสมาชิก",
              actionText: "ตกลง"
            });
          } else {
            ConfirmModal.show({
              action: () => {
                this.submitFormik(values);
                ConfirmModal.hide();
              },
              cancelAction: () => {
                ConfirmModal.hide();
              },
              description: constants.createConfirm,
            });
          }
        }}
      >
        {CreateUserForm}
      </Formik>
    );
  };

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.breadcrumb.main, path: routes.Dashboard.path, active: false },
      {
        label: constants.breadcrumb.membersList,
        path: routes.membersList.path,
        active: false,
      },
      { label: constants.breadcrumb.addMembers, active: true },
    ];
    const EditUserFormik = this.renderEditUserFormik;
    return (
      <>
        <div className="paper-container userinfo-container">
          <div className="row">
            <div className="col">
              <div className="sub-menu" ref={this.errorRef}>
                <Breadcrumb
                  items={navigates}
                  handleOnClickItem={handleOnClickBreadcrumb}
                />
              </div>
            </div>
          </div>
          <div className="paper-title">{constants.title}</div>
          <div className="pr-3 paper-description">{constants.description}</div>
          <EditUserFormik />
        </div>
      </>
    );
  }
}

export default EditUserContainer;
