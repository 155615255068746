import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { postYegeeGameHistoryAction, clearYegeeGameHistoryAction } from '../actionCreator';
import { fetchPostYegeeGameHistory } from '../services';

const postYegeeGameHistory = (request: IYegeeGameHistoryRequest) => async (dispatch: Dispatch) => {
    dispatch(postYegeeGameHistoryAction.request({}))
    return fetchPostYegeeGameHistory(request)
        .then((res: AxiosResponse<IAPIResponse<IYegeeGameHistory>>) => {
            dispatch(postYegeeGameHistoryAction.success(res))
        })
        .catch((error: AxiosError<IAPIResponse>) => {
            dispatch(postYegeeGameHistoryAction.failure(error))
        })
}

const clearYegeeGameHistory = () => (dispatch: Dispatch) => {
    dispatch(clearYegeeGameHistoryAction({}))
}

export {
    postYegeeGameHistory,
    clearYegeeGameHistory
}
