import React, { Component } from "react";
import { Input, Panginate } from "components";
import { withRouter, RouteComponentProps } from "react-router-dom";
import "./historyBox.style.scss";

const defaultProps: IHistoryBoxSelectProps<IEditUserHistory> = {
  pagination: {
    allPageData: [],
    currentPage: {
      selected: 0,
    },
    pageCount: 0,
    currentPageData: [],
  },
  handlePageClick() {},
};

const headTable = {
  user: "รหัสผู้ใช้",
  recentModDate: "วันที่แก้ไขล่าสุด",
  behaviour: "พฤติกรรม",
  category: "ประเภท",
  description: "รายละเอียด",
};

class HistoryBox extends Component<
  IHistoryBoxSelectProps<IEditUserHistory> &
    RouteComponentProps<{}, {}, IHistoryBoxLocationState>,
  IHistoryBoxSelectState
> {
  static defaultProps = defaultProps;
  state: IHistoryBoxSelectState = {
    inputText: "",
    startDate: "",
    endDate: "",
  };

  handleTextInput = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ inputText: event.currentTarget.value });
  };

  handleDateInput = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.id === "startdate") {
      this.setState({ startDate: event.currentTarget.value });
    } else {
      this.setState({ endDate: event.currentTarget.value });
    }
  };

  handleRowClick = (index: number) => {
    const { user } = this.props.pagination.currentPageData[index];
    const { history } = this.props;
    history.push("/editUserHistoryDetails", { user: user! });
  };

  historyTableBody = (inputData: IEditUserHistory[]) =>
    inputData.map((data: IHistoryDataTable, index: number) => (
      <tr
        className={` ${index % 2 === 0 ? "historyColor-row" : ""}`}
        key={index}
        onClick={
          this.props.type === "editUserHistory"
            ? () => this.handleRowClick(index)
            : undefined
        }
        style={{
          cursor: this.props.type === "editUserHistory" ? "pointer" : "",
        }}
      >
        {this.props.type === "editUserHistory" && (
          <td className="text-left">
            <span className="col-2">{data.user}</span>
          </td>
        )}
        <td className="text-left">
          <span className="col-2">{data.recentModDate}</span>
        </td>
        <td className="text-left">
          <span className="col-2">{data.recentModPerson}</span>
        </td>
        <td className="text-left">
          <span className="col-2">{data.behaviour}</span>
        </td>
        {this.props.type === "editUserHistoryDetails" && (
          <td className="text-left">
            <span className="col-2">{data.category}</span>
          </td>
        )}
        <td className="text-left">
          <span className="col-4">{data.description}</span>
        </td>
      </tr>
    ));

  render() {
    const HistoryTableBody = this.historyTableBody(
      this.props.pagination.currentPageData
    );
    return (
      <div className="box-shadow">
        <div className="row">
          <div className="offset-1 col">
            <Input
              id="searchbar"
              name="search"
              type="text"
              placeholder="ค้นหาสมาชิก / พฤติกรรม / รายละเอียด"
              onChange={this.handleTextInput}
              value={this.state.inputText}
            />
          </div>
          <div className="col">
            <Input
              id="startdate"
              name="search"
              type="date"
              onChange={this.handleDateInput}
              value={this.state.startDate}
            />
          </div>
          <p className="align-middle">-</p>
          <div className="col">
            <Input
              id="enddate"
              name="search"
              type="date"
              onChange={this.handleDateInput}
              value={this.state.endDate}
            />
          </div>
        </div>
        <div className="row" style={{ height: "500px" }}>
          <div className="col mt-4">
            <div className="historyTable-container">
              <table className="col">
                <thead>
                  <tr>
                    {this.props.type === "editUserHistory" && (
                      <th className="text-center userColumn">
                        <span className="col-2">{headTable.user}</span>
                      </th>
                    )}
                    <th className="text-center recentModDateColumn">
                      <span className="col-2">{headTable.recentModDate}</span>
                    </th>
                    <th className="text-center behaviourColumn">
                      <span className="col-2">{headTable.behaviour}</span>
                    </th>
                    {this.props.type === "editUserHistoryDetails" && (
                      <th className="text-center categoryColumn">
                        <span className="col-2">{headTable.category}</span>
                      </th>
                    )}
                    <th className="text-center descriptionColumn">
                      <span className="col-2">{headTable.description}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>{HistoryTableBody}</tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Panginate
              pageTotal={this.props.pagination.pageCount}
              initialPage={0}
              marginPagesDisplayed={0}
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={""}
              pageRangeDisplayed={this.props.pagination.currentPage.selected}
              onPageChange={this.props.handlePageClick}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(HistoryBox);
