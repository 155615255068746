export const GET_YEGEE_GAME_LIST_REQUEST = "GET_YEGEE_GAME_LIST_REQUEST";
export const GET_YEGEE_GAME_LIST_SUCCESS = "GET_YEGEE_GAME_LIST_SUCCESS";
export const GET_YEGEE_GAME_LIST_FAILURE = "GET_YEGEE_GAME_LIST_FAILURE";

export const UPDATE_YEGEE_GAME_LIST_REQUEST = "UPDATE_YEGEE_GAME_LIST_REQUEST";
export const UPDATE_YEGEE_GAME_LIST_SUCCESS = "UPDATE_YEGEE_GAME_LIST_SUCCESS";
export const UPDATE_YEGEE_GAME_LIST_FAILURE = "UPDATE_YEGEE_GAME_LIST_FAILURE";

export const POST_YEGEE_GAME_HISTORY_REQUEST = "POST_YEGEE_GAME_HISTORY_REQUEST";
export const POST_YEGEE_GAME_HISTORY_SUCCESS = "POST_YEGEE_GAME_HISTORY_SUCCESS";
export const POST_YEGEE_GAME_HISTORY_FAILURE = "POST_YEGEE_GAME_HISTORY_FAILURE";
export const POST_YEGEE_GAME_HISTORY_CANCEL = "POST_YEGEE_GAME_HISTORY_CANCEL";
export const CLEAR_YEGEE_GAME_HISTORY = "CLEAR_YEGEE_GAME_HISTORY";

export const POST_PLAY_YEGEE_GAME_REQUEST = "POST_PLAY_YEGEE_GAME_REQUEST";
export const POST_PLAY_YEGEE_GAME_SUCCESS = "POST_PLAY_YEGEE_GAME_SUCCESS";
export const POST_PLAY_YEGEE_GAME_FAILURE = "POST_PLAY_YEGEE_GAME_FAILURE";
export const POST_PLAY_YEGEE_GAME_CANCEL = "POST_PLAY_YEGEE_GAME_CANCEL";

export const GET_YEGEE_SUM_REQUEST = "GET_YEGEE_SUM_REQUEST";
export const GET_YEGEE_SUM_SUCCESS = "GET_YEGEE_SUM_SUCCESS";
export const GET_YEGEE_SUM_FAILURE = "GET_YEGEE_SUM_FAILURE";
export const GET_YEGEE_SUM_CANCEL = "GET_YEGEE_SUM_CANCEL";

export const LISTEN_YEGEE_SUM_SOCKET = "LISTEN_YEGEE_SUM_SOCKET";
export const UNLISTEN_YEGEE_SUM_SOCKET = "UNLISTEN_YEGEE_SUM_SOCKET";

export const UPDATE_YEGEE_SUM_REQUEST = 'UPDATE_YEGEE_SUM_REQUEST'
export const UPDATE_YEGEE_SUM_SUCCESS = 'UPDATE_YEGEE_SUM_SUCCESS'
export const UPDATE_YEGEE_SUM_FAILURE = 'UPDATE_YEGEE_SUM_FAILURE'
export const CLEAR_YEGEE_SUM = 'CLEAR_YEGEE_SUM'

export const GET_PLAYED_YEGEE_LIST_REQUEST = "GET_PLAYED_YEGEE_LIST_REQUEST"
export const GET_PLAYED_YEGEE_LIST_SUCCESS = "GET_PLAYED_YEGEE_LIST_SUCCESS"
export const GET_PLAYED_YEGEE_LIST_FAILURE = "GET_PLAYED_YEGEE_LIST_FAILURE"
export const GET_PLAYED_YEGEE_LIST_CANCEL = "GET_PLAYED_YEGEE_LIST_CANCEL"

export const LISTEN_PLAYED_YEGEE_LIST_SOCKET = "LISTEN_PLAYED_YEGEE_LIST_SOCKET";
export const UNLISTEN_PLAYED_YEGEE_LIST_SOCKET = "UNLISTEN_PLAYED_YEGEE_LIST_SOCKET"

export const UPDATE_PLAYED_YEGEE_LIST_REQUEST = "UPDATE_PLAYED_YEGEE_LIST_REQUEST"
export const UPDATE_PLAYED_YEGEE_LIST_SUCCESS = "UPDATE_PLAYED_YEGEE_LIST_SUCCESS"
export const UPDATE_PLAYED_YEGEE_LIST_FAILURE = "UPDATE_PLAYED_YEGEE_LIST_FAILURE"
export const CLEAR_PLAYED_YEGEE_LIST = "CLEAR_PLAYED_YEGEE_LIST"

export const GET_YEGEE_GAME_REQUEST = "GET_YEGEE_GAME_REQUEST"
export const GET_YEGEE_GAME_SUCCESS = "GET_YEGEE_GAME_SUCCESS"
export const GET_YEGEE_GAME_FAILURE = "GET_YEGEE_GAME_FAILURE"
export const GET_YEGEE_GAME_CANCEL = "GET_YEGEE_GAME_CANCEL"

export const UPDATE_YEGEE_GAME_REQUEST = "UPDATE_YEGEE_GAME_REQUEST"
export const UPDATE_YEGEE_GAME_SUCCESS = "UPDATE_YEGEE_GAME_SUCCESS"
export const UPDATE_YEGEE_GAME_FAILURE = "UPDATE_YEGEE_GAME_FAILURE"

export const yegeeGameListState: ReducerState<ILottoGame[]> = {
  isFetching: false,
  code: 0,
  data: [],
  error: "",
};

export const yegeeGameHistoryState: ReducerState<IYegeeGameHistory[]> = {
  isFetching: false,
  code: 0,
  data: [],
  error: "",
}

export const yegeePlayState: ReducerState<IYegeePlay> = {
  isFetching: false,
  code: 0,
  data: {},
  error: "",
}

export const yegeeSumState: ReducerState<string> = {
  isFetching: false,
  code: 0,
  data: '0',
  error: '',
}

export const yegeePlayedListState: ReducerState<IYegeePlay[]> = {
  isFetching: false,
  code: 0,
  data: [],
  error: '',
}

export const yegeeGameState: ReducerState<ILottoGame> = {
  isFetching: false,
  code: 0,
  data: {
    id: 0,
    round: '',
    endTime: '',
    startTime: '',
    createdAt: '',
    status: "UNKNOWN",
  },
  error: '',
}