import { Breadcrumb, Button,
  // FinanceInfo,
  //  NewLottoBetConfig 
  } from "components";
import { meDataInitialValue } from "constants/variables";
import { Form, Formik, FormikProps } from "formik";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { handleOnClickBreadcrumb } from "utils";
import { UserInfo } from "./components/UserInfo";
import { UserSpecificInfo } from "./components/UserSpecificInfo";
import { HostInfo } from "./components/HostInfo";
import { NewLottoBetConfig } from "./components/NewLottoBetConfig";
import "./userDetail.style.scss";
import schemeUserDetail from "./model/scheme";
import { cloneDeep, isEmpty, noop } from "lodash";
import color from "constants/colors";

const constants = {
  breadcrumb: {
    main: "ภาพรวม",
    userInfo: "ข้อมูลส่วนตัว",
  },
  title: "ข้อมูลส่วนตัว",
  changePassword: "เปลี่ยนรหัสผ่าน",
  postOwnerSettingLotteryUpdateSuccess: "แก้ไขข้อมูลการตั้งค่าหวยสำเร็จ",
  saveList: "รายการบันทึกข้อความ"
};

const defaultProps: IUserDetailActionProps & IUserDetailProps = {
  onChangeIsLoaddingAction() {
    noop();
  },
  meData: meDataInitialValue,
  meIsFetching: false,
  ownerUpdateCode: 0,
  ownerUpdateError: "",
  ownerUpdateIsFetching: false,
  postOwnerUpdate() {
    noop();
  },
  getMe() {
    noop();
  },
  getMeSetting() {
    noop();
  },
  meSettingCode: 0,
  meSettingData: {
    agentId: 0,
    setBetMaxLimit: 0,
    setBetMinLimit: 0,
    setComPercentage: 0,
  },
  meSettingError: "",
  meSettingIsFetching: false,
  ownerSettingCode: 0,
  ownerSettingError: "",
  ownerSettingIsFetching: false,
  postOwnerSetting() {
    noop();
  },
  ownerSettingLotteryUpdateCode: 0,
  ownerSettingLotteryUpdateError: "",
  ownerSettingLotteryUpdateIsFetching: false,
  postOwnerSettingLotteryUpdate() {
    noop();
  },
  getMeSettingLottery() {
    noop();
  },
  meSettingLotteryCode: 0,
  meSettingLotteryData: {
    agentId: 0,
    lotterySetting: [],
  },
  meSettingLotteryError: "",
  meSettingLotteryIsFetching: false,
  getHost() {
    noop();
  },
  hostCode: 0,
  hostData: {
    hostAgentId: 0,
    hostBankName: "",
    hostBankNumber: "",
    hostBankType: "",
    hostLine: "",
    hostPhoneNumber: "",
    hostUsername: "",
  },
  hostError: "",
  hostIsFetching: false,
};

class UserDetailContainer extends Component<
  IUserDetailActionProps & IUserDetailProps & RouteComponentProps,
  IUserDetailState
> {
  static defaultProps = defaultProps;
  state: IUserDetailState = {
    formikState: {
      phoneNumber: "",
      bankName: "",
      bankType: "PleaseChoose",
      bankNumber: "",
      betMaxLimit: 0,
      betMinLimit: 0,
      com: 0,
    },
  };

  componentDidMount() {
    this.props.getMeSetting();
    this.props.getHost();
    this.props.getMe();
  }

  componentDidUpdate(prevProps: IUserDetailProps) {
    if (
      prevProps.meSettingIsFetching !== this.props.meSettingIsFetching &&
      !this.props.meSettingIsFetching
    ) {
      this.props.getMeSettingLottery();
    }

    if (
      prevProps.meSettingLotteryIsFetching !==
        this.props.meSettingLotteryIsFetching &&
      !this.props.meSettingLotteryIsFetching
    ) {
      const { meData, meSettingData } = this.props;
      const clonedFormikState = cloneDeep(this.state.formikState);
      if (!isEmpty(meData)) {
        clonedFormikState.phoneNumber = meData.phoneNumber;
        clonedFormikState.bankName = meData.bankName;
        clonedFormikState.bankNumber = meData.bankNumber;
        clonedFormikState.bankType = meData.bankType;
        clonedFormikState.betMaxLimit =
          meSettingData.setBetMaxLimit > 1000
            ? 1000
            : meSettingData.setBetMaxLimit;
        clonedFormikState.betMinLimit =
          meSettingData.setBetMinLimit > 1000
            ? 1000
            : meSettingData.setBetMinLimit;
        clonedFormikState.com = meSettingData.setComPercentage;

        this.setState({ formikState: clonedFormikState });
      }
    }
  }

  handleRouteClick = (id: number, username: string) => {
    this.props.history.push("/user/reset-password", { id, username });
  };

  renderUserDetailFormik = () => {
    const UserDetailForm = (
      formikProps: FormikProps<IUserDetailFormikProps>
    ) => {
      return (
        <Form>
          <UserSpecificInfo meData={this.props.meData} loading={this.props.meIsFetching} />
            <HostInfo
              meData={this.props.meData}
              getHost={()=>{}}
              hostData={this.props.hostData}
              hostIsFetching={this.props.hostIsFetching}
              hostCode={this.props.hostCode}
            />

          <UserInfo
            {...formikProps}
            meData={this.props.meData}
            postOwnerUpdate={this.props.postOwnerUpdate}
            postOwnerIsFetching={this.props.ownerUpdateIsFetching}
            postOwnerCode={this.props.ownerUpdateCode}
            getMe={this.props.getMe}
          />
          {/* <div className="paper-body">
            <div className="row mt-4">
              <div className="col">
                <FinanceInfo
                  {...(formikProps as FormikProps<any>)}
                  isOtpClicked={true}
                  meData={this.props.meData}
                  meSettingData={this.props.meSettingData}
                  mode={"UPDATE"}
                  postOwnerSetting={this.props.postOwnerSetting}
                  ownerSettingIsFetching={this.props.ownerSettingIsFetching}
                  ownerSettingCode={this.props.ownerSettingCode}
                  getMeSetting={this.props.getMeSetting}
                  meSettingCode={this.props.meSettingCode}
                  meSettingIsFetching={this.props.meSettingIsFetching}
                />
              </div>
            </div>
          </div> */}
          <div className="paper-body">
            <div className="row mt-4">
              <div className="col">
                <NewLottoBetConfig
                  {...(formikProps as FormikProps<any>)}
                  lotterySetting={ this.props.meSettingLotteryData.lotterySetting }
                  meSettingData={this.props.meSettingData}
                  // minBetHost={this.props.mainData.minBet}
                  isOtpClicked={true}
                  getChildSelectedLottoState={()=>{}}
                  mode={"CREATE"}
                  meSettingLotteryData={this.props.meSettingLotteryData}
                  meSettingLotteryCode={this.props.meSettingLotteryCode}
                  meSettingLotteryIsFetching={ this.props.meSettingLotteryIsFetching }
                  meData={this.props.meData}

                  postOwnerSetting={this.props.postOwnerSetting}
                  ownerSettingIsFetching={this.props.ownerSettingIsFetching}
                  ownerSettingCode={this.props.ownerSettingCode}
                  getMeSetting={this.props.getMeSetting}

                  postOwnerSettingLotteryUpdate={ this.props.postOwnerSettingLotteryUpdate }
                  ownerSettingLotteryUpdateIsFetching={ this.props.ownerSettingLotteryUpdateIsFetching }
                  ownerSettingLotteryUpdateCode={ this.props.ownerSettingLotteryUpdateCode }
                  ownerSettingLotteryUpdateError={ this.props.ownerSettingLotteryUpdateError }
                  
                  getMeSettingLottery={this.props.getMeSettingLottery}
                  onChangeIsLoaddingAction={this.props.onChangeIsLoaddingAction}
                />
              </div>
            </div>
          </div>
        </Form>
      );
    };
    return (
      <Formik
        initialValues={this.state.formikState}
        enableReinitialize
        validationSchema={schemeUserDetail(
          this.props.meSettingData,
          this.props.meData
        )}
        onSubmit={(values) => {}}
      >
        {UserDetailForm}
      </Formik>
    );
  };

  render() {
    const navigates: IBreadcrumbItem[] = [
      { label: constants.breadcrumb.main, path: "/", active: false },
      {
        label: constants.breadcrumb.userInfo,
        active: true,
      },
    ];
    const UserDetailFormik = this.renderUserDetailFormik;

    return (
      <div className="paper-container user-detail-container">
        <div className="row">
          <div className="col">
            <div className="sub-menu">
              <Breadcrumb
                items={navigates}
                handleOnClickItem={handleOnClickBreadcrumb}
              />
            </div>
          </div>
        </div>
        <div className="paper-title">{constants.title}</div>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="paper-description">{`${this.props.meData.username}`}</div>
          <div className="d-flex flex-row align-items-center">
            <Button
              id="save-list"
              text={constants.saveList}
              borderRadius={"8px"}
              buttonClass={"save-list-button"}
              backgroundColor={color.SECONDARY_PURPLE}
              textSize={18}
              backgroundHoverColor={color.TERTIARY_PURPLE}
              transitionType="typeOne"
              onClick={() => { this.props.history.push("/user/detail/note");}}
            />
            <div
              className="change-password-text pr-3"
              onClick={() => {
                this.handleRouteClick(
                  this.props.meData.agentId,
                  this.props.meData.username
                );
              }}
            >
              {constants.changePassword}
            </div>
          </div>
        </div>
        <UserDetailFormik />
      </div>
    );
  }
}

export default UserDetailContainer;
