import { connect } from "react-redux"
import ManageNews from "./ManageNews.container"
import { getDataMain } from "../../reduxs/main/main/action"
import manageNewsSelector from "../../reduxs/manageNewsMe/selector"
import { onChangeIsLoaddingAction } from "../../reduxs/Loader/action"

import {
  getNewsMe,
  createNewsMe,
  updateNewsMe,
  deleteNewsMe,
} from "../../reduxs/manageNewsMe/action"

const mapStateToProps = (state: RootReducers): IManageNewsProps => {
  const manageNewsState = manageNewsSelector.news(state)
  const manageNewsAddState = manageNewsSelector.addNews(state)
  const manageNewsUpdateState = manageNewsSelector.updateNews(state)
  const manageNewsDeleteState = manageNewsSelector.deleteNews(state)
  return {
    newsMeCode: manageNewsState.code,
    newsMeError: manageNewsState.error,
    newsMeIsFetching: manageNewsState.isFetching,
    newsMeData: manageNewsState.data,

    newsMeAddCode: manageNewsAddState.code,
    newsMeAddIsFetching: manageNewsAddState.isFetching,
    newsMeAddError: manageNewsAddState.error,
    newsMeAddData: manageNewsAddState.data,

    newsMeUpdateCode: manageNewsUpdateState.code,
    newsMeUpdateIsFetching: manageNewsUpdateState.isFetching,
    newsMeUpdateError: manageNewsUpdateState.error,
    newsMeUpdateData: manageNewsUpdateState.data,

    newsMeDeleteCode: manageNewsDeleteState.code,
    newsMeDeleteIsFetching: manageNewsDeleteState.isFetching,
    newsMeDeleteError: manageNewsDeleteState.error,
    newsMeDeleteData: manageNewsDeleteState.data,

    mainCode: state.main.dataMain.code,
    mainError: state.main.dataMain.error,
    mainIsFetching: state.main.dataMain.isFetching,
    mainData: state.main.dataMain.data,
  }
}
const mapDispatchToProps: IManageNewsActionProps = {
  getNewsMe: getNewsMe,
  addNewsMe: createNewsMe,
  updateNewsMe: updateNewsMe,
  deleteNewsMe: deleteNewsMe,
  getDataMain: getDataMain,
  onChangeIsLoaddingAction: onChangeIsLoaddingAction,
}
export default connect(mapStateToProps, mapDispatchToProps)(ManageNews)
