import { initialMenuState, initialShowMenuState } from "./constants";
import { combineReducers } from "redux";
import * as menuAction from "./actionCreator";
import { ActionType, createReducer } from "typesafe-actions";

export type MenuActionType = ActionType<typeof menuAction>;
const menuReducers = createReducer<IMenuActiveControl, MenuActionType>(
  initialMenuState
)
  .handleAction(
    menuAction.menuAction.success,
    (state: IMenuActiveControl, action: MenuActionType) => {
      const payload: IMenuList = action.payload;
      return {
        ...state,
        menuActive: payload,
      };
    }
  )
  .handleAction(
    menuAction.menuAction.failure,
    (state: IMenuActiveControl, action: MenuActionType) => state
  );

const groupShowReducers = createReducer<IShowMenu, MenuActionType>(
  initialShowMenuState
)
  .handleAction(
    menuAction.menuShowAction.success,
    (state: IShowMenu, action: MenuActionType) => {
      const payload: boolean = action.payload;
      return {
        ...state,
        isShowMenu: payload,
      };
    }
  )
  .handleAction(
    menuAction.menuAction.failure,
    (state: IShowMenu, action: MenuActionType) => state
  );

export default combineReducers({
  menuReducers,
  groupShowReducers,
});
