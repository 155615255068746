import { AxiosError, AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import { newsMeGetAction, newsMeAddAction ,newsMeUpdateAction,newsMeDeleteAction } from './actionCreator'
import { fetchGetNewsMe , fetchAddNewsMe,fetchUpdateNewsMe,fetchDeleteNewsMe } from './services'

const getNewsMe = (data:ISearchAgentNewsMe) => (dispatch: Dispatch) =>{
    dispatch(newsMeGetAction.request({}))
    return fetchGetNewsMe(data)
        .then((res:AxiosResponse<IAPIResponse<IPagination<INews>>>) => {
            dispatch(newsMeGetAction.success(res))
        })
        .catch((error: AxiosError<IAPIResponse>) => dispatch(newsMeGetAction.failure(error)))
    }

const createNewsMe = (data:ICreateAgentNewsMe) => (dispatch: Dispatch) =>{
    dispatch(newsMeAddAction.request({}))
    return fetchAddNewsMe(data)
        .then((res:AxiosResponse<IAPIResponse<object>>) => {
            dispatch(newsMeAddAction.success(res))
        })
        .catch((error: AxiosError<IAPIResponse>) => dispatch(newsMeAddAction.failure(error)))
    }

const updateNewsMe = (data:ICreateAgentNewsMe&IUpdateDeleteAgentNewsMe) => (dispatch: Dispatch) =>{
    dispatch(newsMeUpdateAction.request({}))
    return fetchUpdateNewsMe(data)
        .then((res:AxiosResponse<IAPIResponse<object>>) => {
            dispatch(newsMeUpdateAction.success(res))
        })
        .catch((error: AxiosError<IAPIResponse>) => dispatch(newsMeUpdateAction.failure(error)))
    }

const deleteNewsMe = (data:IUpdateDeleteAgentNewsMe) => (dispatch: Dispatch) =>{
    dispatch(newsMeDeleteAction.request({}))
    return fetchDeleteNewsMe(data)
        .then((res:AxiosResponse<IAPIResponse<object>>) => {
            dispatch(newsMeDeleteAction.success(res))
        })
        .catch((error: AxiosError<IAPIResponse>) => dispatch(newsMeDeleteAction.failure(error)))
    }
    

export {
    getNewsMe,
    createNewsMe,
    updateNewsMe,
    deleteNewsMe
}